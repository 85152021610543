import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import { resetAppState } from 'App/redux/appSlice';

type BlockApprovalSliceState = Approval.ApprovalSummary;

const SLICE_NAME = 'BLOCK_APPROVAL';
const initialState: BlockApprovalSliceState = {
  selected: [],
  summary: {
    noPermissions: {},
    hasSuggestions: {},
    isApproved: {},
    isOpen: {},
  },
  allSelected: false,
};

// #region AsyncThunks
// #endregion

// #region Selectors
// #endregion

// #region Slice
const BlockApprovalSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    loadNodesForApproval: (state, action: PayloadAction<BlockApprovalSliceState>) => {
      state.selected = action.payload.selected;
      state.summary = action.payload.summary;
      state.allSelected = action.payload.allSelected;
    },
    cleanBlockApprovalState: (state) => {
      state.selected = [];
      state.allSelected = false;
    },
    setSelectedNodesForApproval: (
      state,
      action: PayloadAction<BlockApprovalSliceState['selected'] | undefined>,
    ) => {
      const newSelection = action.payload;

      if (newSelection && newSelection.length > 0) {
        state.selected = newSelection;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export default BlockApprovalSlice.reducer;
// #endregion

// #region Actions
export const { loadNodesForApproval, cleanBlockApprovalState, setSelectedNodesForApproval } =
  BlockApprovalSlice.actions;
// #endregion
