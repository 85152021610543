import { WidgetViewModel } from './WidgetViewModel';
import { EquationOptionsWidgetView } from '../../Views';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class EquationsOptionsWidgetModel extends WidgetViewModel<'equationOptions'> {
  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetDataMapper['equationOptions']['view'],
    props: Editor.Visualizer.WidgetDataMapper['equationOptions']['props'],
  ) {
    super(Visualizer, refView, props);

    this.container.dataset.type = 'equationOptions';
  }
  render() {
    // set widget position
    const offsets = EditorDOMUtils.getOffsets(this.refView.children[0].children[0]);
    if (offsets) {
      this.container.style.position = 'absolute';
      this.container.style.top = `${offsets.top}px`;

      if (this.Visualizer.getLayoutType?.() === 'PAGE') {
        this.container.style.left = `calc(50% - ${offsets.width / 2}px)`;
      } else {
        this.container.style.left = `${offsets.left}px`;
      }

      this.container.style.height = `${offsets.height}px`;
    }

    this.renderReactComponent(<EquationOptionsWidgetView offsets={offsets} />);
  }
}
