import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import ShapeDataProvider from '../../../ShapeData';

import Background from '../../../Background';
import TextBody from '../../../TextBody/TextBody';

type TitleShapeProps = {
  shape: Presentation.Data.Shape;
};

const PADDING = {
  x: 8,
  y: 4,
};

const TitleShape = ({ shape }: TitleShapeProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  const position = useMemo(() => {
    return {
      top: shape.properties.xfrm?.off?.y ?? 0,
      left: shape.properties.xfrm?.off?.x ?? 0,
    };
  }, [shape]);

  useLayoutEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setSize({ width: rect.width + PADDING.x * 2, height: rect.height + PADDING.y * 2 });
    }
  }, []);

  return (
    <ShapeDataProvider shape={shape}>
      <div
        style={{
          position: 'relative',
          top: position.top,
          left: position.left,
          width: size.width,
          height: size.height,
          maxWidth: shape.properties.xfrm?.ext?.cx ?? 0,
          maxHeight: shape.properties.xfrm?.ext?.cy ?? 0,
          zIndex: 1,
        }}
      >
        <svg
          width={shape.properties.xfrm?.ext?.cx ?? 0}
          height={shape.properties.xfrm?.ext?.cy ?? 0}
        >
          <Background
            position={{ top: 0, left: 0 }}
            size={size}
            fill={shape.properties.fill}
            outline={shape.properties.ln}
            targetId="title"
          />
          <foreignObject x={0} y={0} width={'100%'} height={'100%'} overflow="visible">
            <div
              style={{
                padding: `${PADDING.y}px ${PADDING.x}px`,
              }}
            >
              {shape.text && (
                <div
                  style={{
                    width: 'fit-content',
                    height: 'fit-content',
                    maxWidth: shape.properties.xfrm?.ext?.cx ?? 0,
                    maxHeight: shape.properties.xfrm?.ext?.cy ?? 0,
                    overflowWrap: 'break-word',
                  }}
                  ref={ref}
                >
                  <TextBody text={shape.text} />
                </div>
              )}
            </div>
          </foreignObject>
        </svg>
      </div>
    </ShapeDataProvider>
  );
};

export default TitleShape;
