import { BaseTypedEmitter } from '_common/services/Realtime';
import { v4 as uuidV4 } from 'uuid';
import { ListData, ListIndexData } from '../../models';

const EMPTY_LIST_INDEX = {
  order: [],
  data: {},
};

export default class List extends BaseTypedEmitter<{
  READY: () => void;
  LOADED: (data: Partial<ListData> | null) => void;
  UPDATED: (data: Partial<ListData> | null, blocks?: string[]) => void;
  UPDATED_INDEXING: (data: Partial<ListData> | null, blocks?: string[]) => void;
}> {
  id: string;
  private templateList: Partial<ListData> = {};
  documentList: Partial<ListData> = {};
  private listIndexing: ListIndexData = EMPTY_LIST_INDEX;
  private mergedList: Partial<ListData> = {};

  constructor(
    id: string,
    templateList: Partial<ListData> = {},
    documentList: Partial<ListData> = {},
    listIndexing: ListIndexData = EMPTY_LIST_INDEX,
  ) {
    super();
    this.id = id;
    this.templateList = templateList;
    this.documentList = documentList;
    this.listIndexing = listIndexing;
    this.joinListData();
  }

  static generateListId() {
    return uuidV4();
  }

  get data() {
    return this.mergedList;
  }

  get style() {
    return this.mergedList.style;
  }

  get styleOverrides() {
    return this.mergedList.so || {};
  }

  get nodes() {
    return this.listIndexing.order;
  }

  getNodeNumbering(nodeId: string) {
    return this.listIndexing.data[nodeId] || null;
  }

  levelsOverrides() {
    return Object.keys(this.styleOverrides);
  }

  private joinListData() {
    this.mergedList = {
      ...this.templateList,
      ...this.documentList,
      n: this.listIndexing.order,
    };
  }

  updateListIndexing(listIndexing?: ListIndexData) {
    if (listIndexing) {
      this.listIndexing = listIndexing;
      this.joinListData();
      this.emit('UPDATED', this.mergedList);
    }
    return this;
  }

  loadDocumentList(list: Partial<ListData> = {}) {
    this.documentList = list;
    this.joinListData();
    this.emit('UPDATED', this.mergedList, this.listIndexing.order);
    return this;
  }

  loadTemplateList(list: Partial<ListData> = {}) {
    this.templateList = list;
    this.joinListData();
    this.emit('UPDATED', this.mergedList, this.listIndexing.order);
    return this;
  }

  duplicate(empty = true, restart = true) {
    const _data = JSON.parse(JSON.stringify(this.mergedList));
    if (empty) {
      _data.n = [];
    }
    if (restart) {
      _data.so = {
        0: [
          {
            sto: 1,
          },
        ],
        1: [
          {
            sto: 1,
          },
        ],
        2: [
          {
            sto: 1,
          },
        ],
        3: [
          {
            sto: 1,
          },
        ],
        4: [
          {
            sto: 1,
          },
        ],
        5: [
          {
            sto: 1,
          },
        ],
        6: [
          {
            sto: 1,
          },
        ],
        7: [
          {
            sto: 1,
          },
        ],
        8: [
          {
            sto: 1,
          },
        ],
      };
    }
    return _data;
  }
}
