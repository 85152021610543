import appEvents, { AppEvents } from 'App/appEvents';

export const openModal: AppEvents['MODAL_OPEN'] = (param) => {
  appEvents.emit('MODAL_OPEN', param);
};

export const updateModal: AppEvents['MODAL_UPDATE'] = (param) => {
  appEvents.emit('MODAL_UPDATE', param);
};

export const closeModal: AppEvents['MODAL_CLOSE'] = (param) => {
  appEvents.emit('MODAL_CLOSE', param);
};

export const closeAllModals: AppEvents['MODAL_CLOSE_ALL'] = () => {
  appEvents.emit('MODAL_CLOSE_ALL');
};
