import { CSSProperties } from 'react';
import { Button, Tag } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { clearFilterIdentity, clearFilterValue, selectHasFilters } from '../../FilterSlice';

import FormattedDate from '_common/components/FormattedDate/FormattedDate';
import {
  DateRange,
  FilterIdentity,
  FilterName,
  FILTERS,
  FilterType,
  TRANSLATIONS,
  TYPES,
} from '../../FilterController';

import styles from './FilterDisplay.module.scss';

type FilterDisplayProps = {
  identity: FilterIdentity;
  direction?: 'row' | 'column';
  margin?: CSSProperties['margin'];
};

const FilterDisplay = ({
  identity,
  direction = 'row',
  margin = '3rem 0 0 0',
}: FilterDisplayProps) => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters[identity]);
  const hasActiveFilters = useSelector((state) => selectHasFilters(state, state.filters[identity]));
  const nonGenericIdentity = (identity.match(/[a-zA-Z]*-/i)?.[0] as FilterIdentity) ?? identity;

  if (!hasActiveFilters) {
    return null;
  }

  const handleClearFilter = (filter: FilterName, value?: ValueOf<FilterType>) => {
    dispatch(clearFilterValue({ identity, filter, value }));
  };

  const handleClearIdentity = () => {
    dispatch(clearFilterIdentity({ identity }));
  };

  const renderDateRangeLabel = ({ startISO, endISO }: { startISO?: ISODate; endISO?: ISODate }) => {
    if (startISO && endISO) {
      return (
        <FormattedMessage
          id="START_DATE_TO_END_DATE"
          values={{
            startDate: <FormattedDate date={startISO} type="numeric" />,
            endDate: <FormattedDate date={endISO} type="numeric" />,
          }}
        />
      );
    } else if (startISO) {
      return (
        <FormattedMessage
          id="START_DATE_TO_PRESENT"
          values={{
            startDate: <FormattedDate date={startISO} type="numeric" />,
          }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="UNTIL_END_DATE"
          values={{
            endDate: <FormattedDate date={endISO} type="numeric" />,
          }}
        />
      );
    }
  };

  const renderFilterLabel = (typedFilter: FilterName) => {
    //If only has one filter, so filterName label shouldn't appear (no need to identify)
    return FILTERS[nonGenericIdentity].length > 1 ? (
      <>
        <FormattedMessage id={TRANSLATIONS[typedFilter]} />
        {`: `}
      </>
    ) : null;
  };

  const renderTags = () =>
    Object.entries(filters).map(([filter, value]) => {
      const typedFilter = filter as FilterName;

      if (TYPES.dateRange.includes(typedFilter)) {
        const dateRangeFilter = value as DateRange; //Since we checked it is indeed a dateRange, cast value as a DateRange
        if (!dateRangeFilter) {
          return null;
        }

        return (
          <Tag
            key={`activeFilter-${typedFilter}`}
            size="small"
            onClick={() => handleClearFilter(typedFilter)}
            testId={`${identity}-activeFilter-${typedFilter}`}
          >
            {renderFilterLabel(typedFilter)}
            {renderDateRangeLabel(dateRangeFilter)}
          </Tag>
        );
      } else {
        const typedValue = value as Exclude<typeof value, DateRange>;
        return Array.isArray(typedValue) ? (
          typedValue.map((mappedValue, index) => {
            return (
              <Tag
                key={`activeFilter-${typedFilter}-${index}`}
                size="small"
                onClick={() => handleClearFilter(typedFilter, mappedValue.value)}
                testId={`${identity}-activeFilter-${typedFilter}-${index}`}
              >
                {renderFilterLabel(typedFilter)}
                {mappedValue.label}
              </Tag>
            );
          })
        ) : (
          <Tag
            key={`activeFilter-${typedFilter}`}
            size="small"
            onClick={() => handleClearFilter(typedFilter, typedValue.value)}
            testId={`${identity}-activeFilter-${typedFilter}`}
          >
            {renderFilterLabel(typedFilter)}
            {typedValue.label}
          </Tag>
        );
      }
    });

  const renderClearButton = () => (
    <Button
      size="small"
      variant="link"
      onClick={handleClearIdentity}
      testId={`${identity}-clearAll`}
    >
      <FormattedMessage id="CLEAR_ALL" />
    </Button>
  );

  if (direction === 'row') {
    return (
      <div className={styles.root} style={{ margin }} data-testid={`${identity}-filterDisplay`}>
        <div className={styles.tagZone}>
          <div className={styles.label}>
            <FormattedMessage id="APPLIED_FILTERS" />:
          </div>
          {renderTags()}
        </div>
        {renderClearButton()}
      </div>
    );
  }

  return (
    <div
      className={`${styles.root} ${styles.column}`}
      style={{ margin }}
      data-testid={`${identity}-filterDisplay`}
    >
      <div className={styles.header}>
        <div className={styles.label}>
          <FormattedMessage id="APPLIED_FILTERS" />
        </div>
        {renderClearButton()}
      </div>
      <div className={styles.tagZone}>{renderTags()}</div>
    </div>
  );
};

export default FilterDisplay;
