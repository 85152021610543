import { FormattedMessage } from 'react-intl';

import { navigateToMyFiles } from 'router/history';
import { useSelector, useDispatch } from '_common/hooks';
import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { setRedirectValidSession } from 'App/redux/appSlice';
import { switchAccount, signedOut } from 'Auth/redux/authSlice';

import UserRow from './UserRow';

import styles from './SavedAccounts.module.scss';

const SavedAccounts = () => {
  const dispatch = useDispatch();

  const accounts = useSelector(selectAccounts);
  const userId = useSelector((state) => state.auth.userId);

  const handleSwitchAccount = (key: UserId) => {
    dispatch(switchAccount({ user: accounts[key] }));
  };

  const handleAddAccount = () => {
    dispatch(setRedirectValidSession(false));
    dispatch(signedOut());
    navigateToMyFiles();
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <FormattedMessage id="SAVED_ACCOUNTS" />
      </div>
      <div className={styles.list}>
        {Object.keys(accounts).map(
          (key) =>
            +key !== +userId && (
              <UserRow
                key={key}
                account={accounts[key]}
                online={accounts[key].online}
                onClick={() => handleSwitchAccount(key)}
                testId={`user-${key}`}
              />
            ),
        )}
      </div>
      <UserRow onClick={handleAddAccount} testId="add-account" />
    </div>
  );
};

export default SavedAccounts;
