import { UIEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { selectTrackedActionsActive } from 'Editor/redux/EditorStatusSlice';
import { selectFilteredTrackedActions } from 'Editor/redux/TrackedActionsSlice';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState } from '_common/components';
import { TrackedActionCard } from 'Editor/components';

import styles from './TrackedActionList.module.scss';
import interactionControllerStyles from '_common/components/OnboardingOverlay/InteractionController.module.scss';

const SLICE_CHUNK = 20;

const TrackedActions = () => {
  const intl = useIntl();
  const listRef = useRef(null);
  const scrollFlag = useRef(false);

  const trackedActions = useSelector(selectFilteredTrackedActions);
  const activeTrackedAction = useSelector(selectTrackedActionsActive);
  const hideAllTrackedActions = useSelector((state) => state.editor.trackedActions.hideAll);
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorTrackPanel),
  );

  const [sliceIndex, setSliceIndex] = useState(0);

  useEffect(() => {
    if (scrollFlag.current) {
      const id = `TrackedAction#${activeTrackedAction[0]}`;
      const element = document.querySelector(`*[id="${id}"]`);
      if (element) {
        element.scrollIntoView({ block: 'center' });
      }
      scrollFlag.current = false;
    }
  }, [sliceIndex]);

  useEffect(() => {
    if (activeTrackedAction.length > 0) {
      const index = trackedActions.order.indexOf(activeTrackedAction[0]);
      if (index >= sliceIndex && index < sliceIndex + SLICE_CHUNK) {
        const id = `TrackedAction#${activeTrackedAction[0]}`;
        const element = document.querySelector(`*[id="${id}"]`);
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
      } else {
        scrollFlag.current = true;
        setSliceIndex(Math.max(index - SLICE_CHUNK / 2, 0));
      }
    }
  }, [activeTrackedAction]);

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.scrollHeight - target.scrollTop < target.clientHeight + 150) {
      setSliceIndex(
        Math.min(sliceIndex + SLICE_CHUNK / 2, trackedActions.order.length - SLICE_CHUNK),
      );
    } else if (target.scrollTop < 150) {
      setSliceIndex(Math.max(sliceIndex - SLICE_CHUNK / 2, 0));
    }
  };

  const renderEmptyState = () => (
    <div className={styles.emptyView}>
      <EmptyState
        size="medium"
        title={intl.formatMessage({ id: 'NO_CHANGES_IN_THE_DOCUMENT' })}
        testId="no-changes-found-in-the-document"
      >
        <FormattedMessage id="NO_CHANGES_IN_THE_DOCUMENT_MESSAGE" />
      </EmptyState>
    </div>
  );

  if (trackedActions.order.length === 0) {
    if (hasActiveFilters) {
      return <EmptyFilteredState identity="editorTrackPanel" size="medium" />;
    }
    return renderEmptyState();
  } else if (hideAllTrackedActions) {
    return renderEmptyState();
  }

  return (
    <>
      <div
        id={interactionControllerStyles.skipControl}
        className={styles.root}
        onScroll={onScroll}
        ref={listRef}
        data-testid="tracked-action-list"
      >
        {trackedActions.order
          .slice(
            Math.max(sliceIndex - SLICE_CHUNK, 0),
            Math.min(sliceIndex + SLICE_CHUNK, trackedActions.order.length),
          )
          .filter((id) => trackedActions.actions[id])
          .map((id) => (
            <div key={id} style={{ marginTop: '1rem' }}>
              <TrackedActionCard
                panel
                trackedAction={trackedActions.actions[id]}
                selected={activeTrackedAction.includes(id)}
                testId={`sidepanel-suggestion-card-${id}`}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default TrackedActions;
