import { NodeDataBuilder } from 'Editor/services/DataManager';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { Command } from '../Command';

const IMAGE_MAX_SIZE = 500;

export class InsertImageCommand extends Command {
  image: File;

  constructor(context: Editor.Edition.Context, image: File) {
    super(context);

    this.image = image;
  }

  async handleExec(): Promise<void> {
    const imageData = await this.uploadImage(this.image);

    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    let width: number = 500;
    let height: number = 500;
    if (imageData.dimensions.width >= imageData.dimensions.height) {
      width = Math.min(IMAGE_MAX_SIZE, imageData.dimensions.width);
      height =
        width === imageData.dimensions.width
          ? imageData.dimensions.height
          : (imageData.dimensions.height * IMAGE_MAX_SIZE) / imageData.dimensions.width;
    } else {
      height = Math.min(IMAGE_MAX_SIZE, imageData.dimensions.height);
      width =
        height === imageData.dimensions.height
          ? imageData.dimensions.width
          : (imageData.dimensions.width * IMAGE_MAX_SIZE) / imageData.dimensions.height;
    }

    const image = NodeDataBuilder.buildData({
      type: 'img',
      properties: {
        w: width,
        h: height,
        es: imageData.reference,
      },
    });

    if (image) {
      const result = NodeUtils.closestOfTypeByPath(
        this.actionContext.baseData,
        this.actionContext.range.start.p,
        ['p'],
      );
      const closest = result?.data;

      if (closest) {
        let command = this.context.commandFactory?.getCommand('INSERT_INLINE');
        if (command) {
          command.setActionContext(this.actionContext);
          await command.exec(image);
        }
      } else {
        const builder = new NodeDataBuilder('p');
        builder.addChildData(image);
        const paragraph = builder.build();

        if (paragraph && paragraph.id && this.context.contentManipulator) {
          this.context.contentManipulator.insertBlock(this.actionContext, paragraph, 'AFTER');

          this.handleSuggestionsUpdate();

          this.applySelection();

          this.createPatch();
        }
      }
    }
  }
}
