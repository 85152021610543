import { FC } from 'react';
import ChartBase from '../ChartBase/ChartBase';
import useBoxWhiskerSeries, { defaultBprderColors } from '../series/useBoxWhiskerSeries';
import { useChartColor, useChartOutline, useChartPlotArea } from '../hooks';
import useOutline from '../../useOutline';

const defaultAxisLineColor = {
  fill: {
    color: { mods: [], base: 'D9D9D9' },
    type: 'solid',
  },
};

type BoxWhiskerChartProps = {
  shape: Presentation.Data.ChartShape;
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.BoxplotSeriesOption[] | undefined => {
  const { boxWhiskersSeries } = useBoxWhiskerSeries();

  const series: echarts.BoxplotSeriesOption[] = [];

  //@ts-expect-error this isn't supported yet
  if (shape.chartSpace.chart.plotArea.plotAreaRegion.series[0].layoutId === 'boxWhisker') {
    const chartSeries = boxWhiskersSeries({ chart: shape.chartSpace });
    chartSeries.forEach((ser) => {
      series.push(ser);
    });
  }
  return series;
};

const BoxWhiskerChart: FC<BoxWhiskerChartProps> = ({ shape }) => {
  const series = useSeries({ shape });
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  //@ts-expect-error this isn't supported yet
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea?.plotAreaRegion?.plotSurface);
  //@ts-expect-error axis is not supported yet
  const xAxisInfo = shape.chartSpace.chart.plotArea?.axis?.[0];
  //@ts-expect-error axis is not supported yet
  const yAxisInfo = shape.chartSpace.chart.plotArea?.axis?.[1];
  const { parseOutline } = useOutline();
  const outlineXAxis = parseOutline(xAxisInfo?.properties?.ln);
  const outlineYAxis = parseOutline(yAxisInfo?.properties?.ln);
  const textXAxisOutline = chartOutline(
    xAxisInfo?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
  );
  const textYAxisOutline = chartOutline(
    yAxisInfo?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
  );

  const uniqueNames: string[] = [];

  //@ts-expect-error this isn't supported yet
  shape.chartSpace.chartData?.data[0].dim[0].lvl[0].pt.filter((item: { content: any }) => {
    if (!uniqueNames.includes(item.content)) {
      uniqueNames.push(item.content);
      return true;
    }
    return false;
  });

  let dataValues: number[] = [];
  series?.[0].data?.map((value, id) =>
    //@ts-expect-error
    value.map((el, i) => {
      //@ts-expect-error
      if (i !== 0 && i < value.length - 1) {
        //@ts-expect-error
        dataValues.push({ number: el, index: id });
      }
      return null;
    }),
  );

  const handleDataValues = () => {
    return dataValues.map((value) => {
      //@ts-expect-error
      return [value.index, value.number];
    });
  };

  const handleSeries = () => {
    const outline = chartOutline(
      //@ts-expect-error this isn't supported yet
      shape.chartSpace.chart.plotArea.plotAreaRegion.series[0]?.properties?.ln,
    );
    if (shape.chartSpace.chartData?.data.length > 1) {
      return series;
    } else {
      return [
        //@ts-expect-error can be undefined
        ...series,
        {
          type: 'scatter',
          //@ts-expect-error this isn't supported yet
          symbol: shape.chartSpace.chart.plotArea.plotAreaRegion.series[0].layoutPr.visibility
            .nonoutliers
            ? 'circle'
            : 'none',
          symbolSize: 5,
          data: handleDataValues(),
          itemStyle: {
            ...outline,
            borderColor:
              outline?.borderColor ??
              //@ts-expect-error
              chartColor(defaultBprderColors[0]?.properties?.fill),
          },
        },
      ];
    }
  };

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              //@ts-expect-error
              color: outlineXAxis.stroke ?? chartColor(defaultAxisLineColor?.fill),
            },
          },
          axisLabel: {
            //@ts-expect-error
            color:
              chartColor(xAxisInfo?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill) ??
              '#000000',
            //@ts-expect-error color only supports string (solid color)
            textBorderColor: textXAxisOutline?.borderColor,
            textBorderWidth: textXAxisOutline?.borderWidth ?? 0.75,
            //@ts-expect-error can be undefined
            textBorderType: [textXAxisOutline?.borderType],
            textBorderCap: textXAxisOutline?.borderCap,
          },
          data: uniqueNames,
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              //@ts-expect-error
              color: outlineYAxis.stroke ?? chartColor(defaultAxisLineColor?.fill),
            },
          },
          axisLabel: {
            //@ts-expect-error
            color:
              chartColor(yAxisInfo?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill) ??
              '#000000',
            //@ts-expect-error color only supports string (solid color)
            textBorderColor: textYAxisOutline?.borderColor,
            textBorderWidth: textYAxisOutline?.borderWidth ?? 0.75,
            //@ts-expect-error can be undefined
            textBorderType: [textYAxisOutline?.borderType],
            textBorderCap: textYAxisOutline?.borderCap,
          },
        },
        series: handleSeries(),
      }}
    />
  );
};

export default BoxWhiskerChart;
