import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { navigateToSettings } from 'router/history';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './ActionsCell.module.scss';

type ActionsCellProps = {
  role: Role;
};

const ActionsCell = ({ role }: ActionsCellProps) => {
  const dispatch = useDispatch();

  const handleSettingsClick = () => {
    navigateToSettings('role', role.id);
  };

  const handleDeleteClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'settings.tenant.deleteRoleTitle',
          titleValues: { name: role.name },
          message: 'settings.tenant.deleteRoleMessage',
          messageValues: { name: role.name },
          confirmButtonTextId: 'settings.tenant.deleteRoleConfirm',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'deletedRole',
          actionValue: { roleId: role.id },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  return (
    <div className={styles.root}>
      <Button
        size="small"
        style={{ marginRight: '2rem' }}
        onClick={handleSettingsClick}
        testId={`${role.id}-settings-button`}
      >
        <FormattedMessage id="global.settings" />
      </Button>
      <Button
        variant="danger"
        testId={`${role.id}-delete-button`}
        size="small"
        onClick={handleDeleteClick}
      >
        <FormattedMessage id="global.delete" />
      </Button>
    </div>
  );
};

export default ActionsCell;
