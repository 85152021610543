import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useSelector } from '_common/hooks';
import { navigateToSpaces, navigateToObject, navigateToShared } from 'router/history';
import { useGetObjectQuery } from 'App/redux/objectApi';
import { ActionBar } from '_common/components';
// import { useGetObjectPathQuery } from '_common/services/api/ObjectApi';

const Header = () => {
  const intl = useIntl();
  const { id: objectId, type: objectType }: { id: ObjectId; type: ObjectType } = useParams();
  // const { data } = useGetObjectPathQuery(objectId, { skip: !objectId || objectType === 'space' });
  // console.log('Header', data);
  const { data: object } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId || !objectType },
  );
  const shared = useSelector((state) => state.table.identity.shared);

  if (!object) {
    return null;
  }

  const handleGoTo = (element: { id: string; name?: string }) => {
    if (object && element.id === object.id) {
      if (object.type === 'space') {
        navigateToSpaces();
      } else if (object.parent) {
        navigateToObject('folder', object.parent);
      } else if (shared && shared.list && shared.list.includes(objectId)) {
        navigateToShared();
      } else if (object.type === 'folder') {
        navigateToObject('folder', object.id);
      } else {
        navigateToObject('space', object.space);
      }
    }
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: object.id,
            name:
              object.type === 'space' && object.personal
                ? intl.formatMessage({ id: 'spaces.personalSpace' })
                : object.name,
          },
          {
            id: 'settings',
            name: intl.formatMessage({ id: 'global.settings' }),
          },
        ]}
        onBreadcrumbElementClick={handleGoTo}
      />
    </ActionBar>
  );
};

export default Header;
