import { createContext, useContext } from 'react';
import { v4 as uuid } from 'uuid';

import { AdvancedFilter, FILTER_OPTIONS } from './AdvancedFilterOptions';

type AdvancedFilterContextValue = {
  advancedFilter: AdvancedFilter;
  setOption: (newOption: AdvancedFilter['option']) => void;
  setOperator: (newOperator: AdvancedFilter['operator']) => void;
  setMetadataOption: (newOption: AdvancedFilter['metadataOption']) => void;
  setValue: (newValue: AdvancedFilter['value']) => void;
  setAuxValue: (newValue: AdvancedFilter['auxValue']) => void;
  setQuery: (newQuery: AdvancedFilter['query']) => void;
};

const AdvancedFilterContext = createContext<AdvancedFilterContextValue>({
  advancedFilter: { id: uuid(), option: FILTER_OPTIONS[0].value },
  setOption: () => {},
  setOperator: () => {},
  setMetadataOption: () => {},
  setValue: () => {},
  setAuxValue: () => {},
  setQuery: () => {},
});

export const useAdvancedFilterContext = () => {
  return useContext(AdvancedFilterContext);
};

export default AdvancedFilterContext;
