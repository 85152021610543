import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'AUDIT_LOG';

export type AuditLogSliceState = {
  nodesFilters: Request.FilterParams['filter_values'] | undefined;
};

export const initialState: AuditLogSliceState = {
  nodesFilters: undefined,
};

const AuditLogSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setNodesFilters: (state, action: PayloadAction<AuditLogSliceState['nodesFilters']>) => {
      state.nodesFilters = action.payload;
    },
    clearNodesFilters: (state) => {
      state.nodesFilters = undefined;
    },
  },
});

export const { setNodesFilters, clearNodesFilters } = AuditLogSlice.actions;

export default AuditLogSlice.reducer;
