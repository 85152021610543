import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Collapser.module.scss';

import { Button } from 'dodoc-design-system';

const Collapser = () => {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.collapser}>
      <div>
        <Button
          size="medium"
          variant="link"
          icon={open ? 'AngleDownBlue' : 'AngleRightBlue'}
          onClick={handleOnClick}
          testId={`${open ? 'read-less' : 'read-more'}-button`}
        >
          <FormattedMessage
            id={
              open
                ? 'READ_LESS_ABOUT_THIRD_PART_APPLICATIONS'
                : 'READ_MORE_ABOUT_THIRD_PART_APPLICATIONS'
            }
          />
        </Button>
      </div>
      {open && (
        <div className={styles.information}>
          <div>
            <FormattedMessage id="DODOC_OFFERS_LINKS_TO_OTHER_THIRD_PARTY" />
          </div>
          <div>
            <FormattedMessage id="DODOC_IS_NOT_RESPONSIBLE_FOR_CONTENT_OF_LINKED_THIRD_PART" />
          </div>
          <div>
            <FormattedMessage id="SECURITY_AND_PRIVACY_POLICIES_MAY_BE_DIFFERENT_THAN_DODOC" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Collapser;
