import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { paths } from '_types/api';

import { setCurrentAppPage } from 'App/redux/appSlice';
import { toggleInfoPanelOpen } from 'Groups/redux/GroupsPageSlice';

import { InformationPanel, IntlErrorBoundary } from '_common/components';

import Header from './Header';
import List from './List';

import styles from './GroupsPage.module.scss';

const GroupsPage = () => {
  const dispatch = useDispatch();

  const infoPanelOpen = useSelector((state) => state.groups.infoPanelOpen);

  const [permissions, setPermissions] =
    useState<
      paths['/api/object/group/list']['get']['responses']['200']['content']['multipart/form-data']['permissions']
    >();

  useEffect(() => {
    dispatch(setCurrentAppPage('/groups'));
  }, []);

  const handleToggleInfoPanel = () => {
    dispatch(toggleInfoPanelOpen());
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <IntlErrorBoundary size="small" margin="0 0 0 7rem">
            <Header permissions={permissions} />
          </IntlErrorBoundary>
        </div>
        <div className={styles.content}>
          <List onPermissionsChange={setPermissions} />
          <InformationPanel
            isOpen={infoPanelOpen}
            onClose={handleToggleInfoPanel}
            testId="groups-information-panel"
          />
        </div>
      </div>
    </>
  );
};

export default GroupsPage;
