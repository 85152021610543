import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import AnnotationsPanel from './AnnotationsPanel';

const AnnotationsPanelBoundary = () => (
  <IntlErrorBoundary size="medium" margin="16rem 0 0 0">
    <AnnotationsPanel />
  </IntlErrorBoundary>
);

export default AnnotationsPanelBoundary;
