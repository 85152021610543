import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Modal, ProgressBar } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import styles from './PDFExportingDocumentModal.module.scss';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

const MODAL = 'PDFExportingDocumentModal';

const PDFExportingDocumentModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);

  const onClose = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} width="60rem" testId="exporting-modal" onClose={onClose}>
      <Modal.Header onClose={onClose}>
        <FormattedMessage id="EXPORTING" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <Icon icon="FilePDFRed" size={96} margin="0 0 4rem 0" />
          <ProgressBar
            color="primaryLight"
            size="medium"
            label={intl.formatMessage({ id: 'PREPARING_DOCUMENT_FOR_EXPORT' })}
            testId="preparing-export-progressBar"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PDFExportingDocumentModal;
