import { forwardRef, isValidElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Notification } from 'dodoc-design-system';
import { NotificationProps } from 'dodoc-design-system/build/types/Components/Notification/Notification';

import { useToastSystem } from './ToastSystemContext';
import appEvents from 'App/appEvents';

type FooterContentObject = {
  label: string;
  callback: () => void;
};

export type ToastProps = {
  id: string;
  title: string;
  message: string;
  titleValues?: Record<string, string>;
  messageValues?: Record<string, string>;
  footerContent?: JSX.Element | FooterContentObject;
  timeout?: number;
  persist?: boolean;
  singleton?: boolean;
  testId?: string;
} & Pick<NotificationProps, 'type'>;

export type ToastElement = Omit<ToastProps, 'id' | 'singleton' | 'testId'> & {
  /**
   * By giving a specific id means the Toast is a singleton.
   *
   * __Note:__ Singletons reset their own timer if you invoke a new notify with their id
   */
  id?: string;
};

const Toast = forwardRef<HTMLDivElement, ToastProps>(
  (
    {
      id,
      type,
      title,
      message,
      titleValues,
      messageValues,
      footerContent,
      timeout = 5000,
      persist,
      singleton,
      testId = '',
    },
    ref,
  ) => {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const { removeToast } = useToastSystem();

    /**
     * Subscribed to reset event only if toast is a singleton
     * Timeout will reset upon receiving the event
     * timeoutId is a dependancy in order for the handle to get the right value when processing the reset
     */
    useEffect(() => {
      if (singleton) {
        appEvents.on('NOTIFY_RESET', handleResetTimeout);

        return () => {
          appEvents.off('NOTIFY_RESET', handleResetTimeout);
        };
      }
    }, [timeoutId]);

    const handleResetTimeout = (toastId: ToastElement['id']) => {
      if (toastId === id) {
        handleTimeout();
      }
    };

    useEffect(() => {
      handleTimeout();

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, []);

    const handleTimeout = () => {
      if (persist) {
        return;
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        handleClose();
      }, timeout);

      setTimeoutId(newTimeoutId);
    };

    const handleClose = () => {
      removeToast(id);
    };

    const isFooterContentObject = (
      footerContent: ToastElement['footerContent'],
    ): footerContent is FooterContentObject => {
      return !isValidElement(footerContent);
    };

    const handleFooterContent = () => {
      if (!footerContent) {
        return;
      }

      return isFooterContentObject(footerContent) ? (
        <Button size="small" onClick={footerContent.callback} testId={`${testId}-button`}>
          {footerContent.label}
        </Button>
      ) : (
        footerContent
      );
    };

    return (
      <Notification ref={ref} type={type} testId={testId}>
        <div data-testid={testId}>
          <Notification.Header onClose={handleClose}>
            <FormattedMessage id={title} values={titleValues} />
          </Notification.Header>
          <Notification.Body>
            <FormattedMessage id={message} values={messageValues} />
          </Notification.Body>
          {footerContent ? (
            <Notification.Footer>{handleFooterContent()}</Notification.Footer>
          ) : null}
        </div>
      </Notification>
    );
  },
);

export default Toast;
