import { PreviewLists } from 'Editor/components';
import styles from './NewListModal.module.scss';

type CustomizePaneProps = {
  list: {
    [key: string]: Editor.ListItem[];
  };
  listItems: Editor.ListItem[];
  selectedPreview: string | null;
  handleChangeSelectedPreview: (element: string) => void;
};

const CustomizePane = ({
  list,
  listItems,
  selectedPreview,
  handleChangeSelectedPreview,
}: CustomizePaneProps) => {
  return (
    <div className={styles.customizePane}>
      {Object.keys(list).map((element) => {
        return (
          <div
            key={element}
            onClick={() => handleChangeSelectedPreview(element)}
            className={`${styles.customizableItem} ${
              selectedPreview === element && styles.selected
            }`}
          >
            <PreviewLists list={selectedPreview !== element ? list[element] : listItems} />
          </div>
        );
      })}
    </div>
  );
};

export default CustomizePane;
