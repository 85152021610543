import { FormattedMessage } from 'react-intl';

import style from './Information.module.scss';

const Information = () => {
  return (
    <div className={style.information}>
      <div className={style.title}>
        <FormattedMessage id="global.information" />
      </div>
      <div className={style.body}>
        <FormattedMessage id="USERS_CSV_FILE_MUST_HAVE_TABLE" />
      </div>
    </div>
  );
};

export default Information;
