import { mix } from 'mixwith';
import EditionMode from '../EditionMode';
import BackspaceHandler from './handlers/BackspaceHandler';
import JoinContentHandler from './helpers/JoinContentHandler';
import RemoveContentHandler from './helpers/RemoveContentHandler';
import SplitContentHandler from './helpers/SplitContentHandler';
import DefaultHandler from './handlers/DefaultHandler';
import DeleteHandler from './handlers/DeleteHandler';
import EnterHandler from './handlers/EnterHandler';
import TabHandler from './handlers/TabHandler';
import InsertBlockHandler from './handlers/InsertBlockHandler';
import InsertInlineHandler from './handlers/InsertInlineHandler';
import CopyPasteHandler from './handlers/CopyPasteHandler';
import CompositionHandler from './handlers/CompositionHandler';
import FieldsHandler from './handlers/FieldsHandler';
import {
  InsertElementHelper,
  SplitSelectionHelper,
  JoinSelectionHelper,
  RemoveSelectionHelper,
  SuggestionsHelper,
  TableOperations,
  TableStylesOperations,
  StructureOperations,
  CommentsEditionHandler,
  HyperlinksEditionHandler,
  CitationsEditionHandler,
  FieldsEditionHandler,
  TablesEditionHandler,
  NotesEditionManager,
  EquationsEditionHandler,
  ImagesEditionHandler,
  CommonOperations,
  ImageOperations,
} from '../_Common';

export default class NormalMode extends mix(EditionMode).with(
  EquationsEditionHandler,
  NotesEditionManager,
  CommentsEditionHandler,
  HyperlinksEditionHandler,
  CitationsEditionHandler,
  FieldsEditionHandler,
  TablesEditionHandler,
  ImagesEditionHandler,
  CommonOperations,
  ImageOperations,
  StructureOperations,
  TableOperations,
  TableStylesOperations,
  InsertElementHelper,
  SplitSelectionHelper,
  JoinSelectionHelper,
  RemoveSelectionHelper,
  SuggestionsHelper,
  JoinContentHandler,
  RemoveContentHandler,
  SplitContentHandler,
  BackspaceHandler,
  DefaultHandler,
  DeleteHandler,
  EnterHandler,
  TabHandler,
  InsertBlockHandler,
  InsertInlineHandler,
  CompositionHandler,
  CopyPasteHandler,
  FieldsHandler,
) {}
