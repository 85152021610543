export default class ErrorInvalidPath extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = 'ErrorInvalidPath';
    this.message = 'Invalid range!';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorInvalidPath);
    }
  }
}
