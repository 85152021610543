import { useMemo } from 'react';
import { useGetPublicProfilesQuery } from '_common/services/api/publicProfilesApi';
import { useSelector } from './redux';

type MultipleProfilesResult = {
  profiles: Record<UserId, ParsedProfile<UserPublicProfile>>;
  removedProfiles: Record<UserId, boolean>;
};

const usePublicProfiles = (ids: UserId[] = []) => {
  const authenticated = useSelector((state) => state.auth.authenticated);
  const { data } = useGetPublicProfilesQuery(ids, { skip: !authenticated || ids.length === 0 });

  const result = useMemo<MultipleProfilesResult>(() => {
    if (!data || !ids.length) {
      return { profiles: {}, removedProfiles: {} };
    }

    const profiles = data.reduce<MultipleProfilesResult['profiles']>((profileList, profile) => {
      profileList[profile.id] = profile;
      return profileList;
    }, {});

    const removedProfiles: MultipleProfilesResult['removedProfiles'] = {};
    ids.forEach((id: UserId) => {
      if (!profiles[id]) {
        removedProfiles[id] = true;
      }
    });

    return { profiles, removedProfiles };
  }, [data, ids]);

  return result;
};

export default usePublicProfiles;
