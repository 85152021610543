import { useMemo, useState } from 'react';
import { Icon } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

import { paths } from '_types/api';

import AffiliationField from './AffiliationField/AffiliationField';

import styles from './Affiliation.module.scss';
import { useGetAffiliationsListQuery } from 'Settings/pages/TenantSettingsPage/Affiliations/AffiliationsApi';

type AffiliationProps = {
  affiliation: Author.Affiliation;
  index: number;
  remove: (removingIndex: number) => void;
  updateAffiliation: (
    params: paths['/api/object/document/{document_id}/author/update']['post']['requestBody']['content']['multipart/form-data'],
  ) => void;
};

const FIELDS: Affiliation.Field[] = [
  'department',
  'function',
  'organization',
  'company',
  'site',
  'address1',
  'address2',
  'state',
  'zip_code',
  'country',
  'extra',
];

const Affiliation = ({ affiliation, index, remove, updateAffiliation }: AffiliationProps) => {
  const { data: fieldOptions } = useGetAffiliationsListQuery();

  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    let optionsByField: Record<Affiliation.Field, undefined | { label: string; value: string }[]> =
      {
        department: undefined,
        function: undefined,
        organization: undefined,
        company: undefined,
        site: undefined,
        address1: undefined,
        address2: undefined,
        state: undefined,
        zip_code: undefined,
        country: undefined,
        extra: undefined,
      };
    fieldOptions &&
      (Object.keys(fieldOptions) as Affiliation.Field[]).forEach((field) => {
        if (field === 'address1' || field === 'address2' || field === 'extra') {
          return;
        }

        optionsByField[field] =
          fieldOptions[field]?.map((option) => ({
            label: option,
            value: option,
          })) || [];
      });

    return optionsByField;
  }, [fieldOptions]);

  const toggle = () => setIsOpen(!isOpen);

  const handleUpdateAffiliation = ({ path, value }: Parameters<typeof updateAffiliation>[0]) => {
    updateAffiliation({ path: `affiliation.${index}.${path}`, value });
  };

  return (
    <div className={styles.root}>
      <div className={styles.info} onClick={toggle}>
        <div className={`${styles.angleIcon} ${!isOpen && styles.isAngleClose}`} data-testid="author-affiliation-angle-icon">
          <Icon icon="AngleDownBlue" size={24} />
        </div>
        <FormattedMessage id="AFFILIATION_NUMBER" values={{ number: index + 1 }} />
        {index !== 0 && (
          <div
            className={styles.close}
            onClick={(e) => {
              remove(index);
              e.stopPropagation();
            }}
            data-testid={`author-affiliation-${index}-remove-icon`}
          >
            <Icon icon="CloseGrey" size={24} />
          </div>
        )}
      </div>
      {isOpen && (
        <div className={styles.fields}>
          {FIELDS.map((field) => (
            <AffiliationField
              key={`author-affiliation-${field}`}
              field={field}
              initialValue={affiliation[field]}
              options={options[field]}
              updateAffiliation={handleUpdateAffiliation}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Affiliation;
