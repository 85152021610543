import { Input, Tooltip } from 'dodoc-design-system';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useEditObjectMutation } from '_common/services/api/ObjectApi';
import styles from './Title.module.scss';

type EditableNameProps = {
  object: doDOC.BaseObject;
  isRenaming?: boolean;
  onRenamingChange?: (payload: boolean) => void;
};

const EditableName = ({ object, isRenaming = false, onRenamingChange }: EditableNameProps) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [editObject] = useEditObjectMutation();
  const [renaming, setRenaming] = useState(false);
  const [name, setName] = useState(object.name);
  const [hovering, setHovering] = useState(false);

  const disabled = (['admin', 'owner', 'edit'] as const).every(
    (permission) => !object.user_permissions.includes(permission),
  );

  useEffect(() => {
    if (isRenaming) {
      startRenaming();
    } else {
      setRenaming(false);
    }
  }, [isRenaming]);

  useEffect(() => {
    if (renaming && inputRef.current) {
      inputRef.current.focus();
    }
    onRenamingChange?.(renaming);
  }, [renaming]);

  const startRenaming = () => {
    if (!disabled) {
      setRenaming(true);
      setHovering(false);
    }
  };

  const validation = useMemo(() => {
    const invalidCharactersRegex = /[<>"\\:*?/|]/;

    if (name.length === 0) {
      return intl.formatMessage({ id: 'validation.name.insertName' });
    } else if (invalidCharactersRegex.test(name)) {
      return intl.formatMessage({ id: 'RENAME_FILE_INPUT_ERROR' });
    } else if (name.length > 146) {
      return intl.formatMessage({ id: 'DOCUMENT_TITLE_LIMIT_CHARACTER' });
    }
    return '';
  }, [name]);

  const handleRename = () => {
    setRenaming(false);
    if (validation) {
      setName(object.name);
    } else if (name !== object.name) {
      editObject({ object_id: object.id, object_type: object.type, name });
    }
  };

  return (
    <div
      className={renaming ? styles.input : styles.text}
      onClick={startRenaming}
      data-testid="name-container"
    >
      {renaming ? (
        <Tooltip content={validation} visible={!!validation} testId="rename-validations-tooltip">
          <div>
            <Input
              ref={inputRef}
              value={name}
              onChange={(e) => setName(e.target.value)}
              onEnterKey={handleRename}
              onBlur={handleRename}
              error={!!validation}
              size="medium"
              placeholder=""
              clearable={false}
              testId="name"
            />
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          content={intl.formatMessage({ id: 'RENAME_FILE' })}
          testId="rename-tooltip"
          disabled={disabled}
          visible={hovering}
        >
          <div
            className={styles.name}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            data-testid="name-tooltip-reference"
          >
            {object.name}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default EditableName;
