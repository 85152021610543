//https://github.com/reduxjs/redux-toolkit/discussions/1163#discussioncomment-876186
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { QueryHooks } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useRef, useState } from 'react';

type GetNodeTypeFromEndpoint<Endpoint> = Endpoint extends QueryHooks<
  QueryDefinition<any, any, string, infer ResultType, string>
>
  ? ResultType extends { nodes: infer NodeType }
    ? NodeType
    : ResultType
  : never;

// infer result type from endpoint - there is probably a better way of doing this
type GetResultTypeFromEndpoint<Endpoint> = Endpoint extends QueryHooks<
  QueryDefinition<any, any, string, infer ResultType, string>
>
  ? ResultType
  : never;

type GetQueryArgTypeFromEndpoint<Endpoint> = Endpoint extends QueryHooks<
  QueryDefinition<infer QueryArg, any, string, any, string>
>
  ? QueryArg
  : never;

interface UseInfiniteQueryOptions</* ResultType, */ QueryArg /* , NodeType */> {
  // getNextPageParams(args: QueryArg, lastResult: ResultType): QueryArg | null; // turn this on and add a default if not every endpoint follows the Request.AllListParams interface
  // getNodesFromResultData(result: ResultType): NodeType[]; // Turn this on and add a default if not every endpoint return the list in the `nodes` property
  params: QueryArg;
}

function useInfiniteQuery<
  Endpoint extends QueryHooks<QueryDefinition<any, any, any, any, any>>,
  ResultType = GetResultTypeFromEndpoint<Endpoint>,
  QueryArg = GetQueryArgTypeFromEndpoint<Endpoint>,
  NodeType = GetNodeTypeFromEndpoint<Endpoint>,
>(
  endpoint: Endpoint,
  options: UseInfiniteQueryOptions</* ResultType,  */ QueryArg /* , NodeType */>,
) {
  const nextPageParams = useRef<QueryArg | null>(null);
  const shouldReset = useRef(false);
  const [nodes, setNodes] = useState<NodeType[]>([]);
  const [trigger, result] = endpoint.useLazyQuery();

  useEffect(() => {
    shouldReset.current = true;
    trigger(options.params);
  }, [options.params]);

  useEffect(() => {
    if (!result.isSuccess) {
      return;
    }
    // nextPageParams.current = options.getNextPageParams(result.originalArgs, result.data);
    nextPageParams.current =
      result.data.nodes.length === result.originalArgs.size
        ? { ...result.originalArgs, offset: result.originalArgs.offset + result.originalArgs.size }
        : null;
    // const newNodes = options.getNodesFromResultData(result.data);
    const newNodes = result.data.nodes;
    if (shouldReset.current) {
      shouldReset.current = false;
      setNodes(newNodes);
    } else {
      setNodes([...nodes, ...newNodes]);
    }
  }, [result.data]);

  return {
    ...result,
    data: {
      ...(result.data as ResultType),
      nodes: process.env.NODE_ENV === 'test' ? ((result?.data?.nodes ?? []) as NodeType[]) : nodes,
    },
    nodes,
    isLoading: result.isFetching && nodes === null,
    hasNextPage: nextPageParams.current !== null,
    fetchNextPage() {
      if (nextPageParams.current !== null) {
        trigger(nextPageParams.current, true);
      }
    },
  };
}
export default useInfiniteQuery;
