import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { BaseRangeFixer } from '../BaseRangeFixer';

export class NonCollapsedSelectionFixer extends BaseRangeFixer {
  protected options: Editor.Selection.FixerOptions = {
    suggestionMode: false,
    forceTextAsWrap: false,
    containerPosition: undefined,
    forceWrapAsText: false,
    forceNonEditableDirection: null,
    isDelete: false,
    isBackspace: false,
  };
  updated: boolean = false;

  constructor(args: Partial<Editor.Selection.FixerOptions>) {
    super();
    this.options = {
      ...this.options,
      ...args,
    };
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    throw new Error('Method not implemented.');
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    let startContainer = range.startContainer;

    let ancestorContainer =
      EditorDOMUtils.closestMultiBlockContainerElement(startContainer) ||
      EditorDOMUtils.getContentContainer(startContainer);

    if (startContainer === ancestorContainer && startContainer.childNodes.length > 0) {
      let childOffset = range.startOffset;
      let caretPosition: DoDOCSelection.CaretPosition = 'START';
      let caretOffset;

      if (childOffset >= startContainer.childNodes.length) {
        childOffset = range.startOffset - 1;
        caretPosition = 'INDEX';
        caretOffset = startContainer.childNodes[childOffset].childNodes.length;
      }

      startContainer = startContainer.childNodes[childOffset];
      range.setRangeStart(startContainer, caretPosition, caretOffset);

      this.updated = true;
    }

    let endContainer = range.endContainer;

    ancestorContainer =
      EditorDOMUtils.closestMultiBlockContainerElement(endContainer) ||
      EditorDOMUtils.getContentContainer(endContainer);

    if (endContainer === ancestorContainer && endContainer.childNodes.length > 0) {
      let childOffset = range.endOffset;
      let caretPosition: DoDOCSelection.CaretPosition = 'START';
      let caretOffset;

      if (childOffset >= endContainer.childNodes.length) {
        childOffset -= 1;
        caretPosition = 'INDEX';
        caretOffset = endContainer.childNodes[childOffset].childNodes.length;
      } else {
        caretPosition = 'END';
      }

      childOffset = range.endOffset - 1;

      if (childOffset < 0) {
        childOffset = 0;
        caretPosition = 'START';
      }

      endContainer = endContainer.childNodes[childOffset];
      range.setRangeEnd(endContainer, caretPosition, caretOffset);

      this.updated = true;
    }
  }
}
