/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommentBuilder } from '../../models';
import { BaseController } from '../BaseController';

export class CommentsController extends BaseController {
  protected comments?: Presentation.Model.Comments;

  async start(documentId: string) {
    this.comments = this.Data.models?.get('COMMENTS', `PC${documentId}`);
    await this.comments?.awaitForEvent('LOADED');
    if (this.comments?.get([]) === undefined) {
      this.comments?.create({
        parent_id: documentId,
        id: `PC${documentId}`,
        _id: `PC${documentId}`,
        sld: {},
      });
    }
  }

  builder() {
    return CommentBuilder.new();
  }

  addComment(data: Common.OnlyRequire<Presentation.Data.Comment, 'content' | 'anchor'>) {
    if (!this.Data.users?.loggedUserId) {
      return Promise.reject(new Error('No user information available!'));
    }
    return this.comments?.addComment({
      ...data,
      authorId: this.Data.users?.loggedUserId,
    });
  }

  editComment(data: Presentation.Data.Comment) {
    return this.comments?.editComment(data);
  }

  deleteComment(data: Common.OnlyRequire<Presentation.Data.Comment, 'id' | 'anchor'>) {
    return this.comments?.changeStatus(data, 'REJECTED');
  }

  changePriority(
    slideId: string,
    commentId: Presentation.Data.Comment['id'],
    priority: Presentation.Model.Comments.CommentPriority,
  ) {
    return this.comments?.changePriority(slideId, commentId, priority);
  }

  resolveComment(slideId: string, commentId: Presentation.Data.Comment['id']) {
    return this.comments?.resolveComment(slideId, commentId);
  }

  voteComment(
    slideId: string,
    commentId: Presentation.Data.Comment['id'],
    value: Presentation.Model.Comments.CommentVoteType['value'],
  ) {
    return this.comments?.voteComment(
      slideId,
      commentId,
      this.Data.users?.loggedUserId as string,
      value,
    );
  }

  replyToComment(
    slideId: string,
    commentId: string,
    replyContent: Presentation.Model.Comments.ContentsType['content'],
  ) {
    return this.comments?.replyToComment(
      slideId,
      commentId,
      this.Data.users?.loggedUserId as string,
      replyContent,
    );
  }

  deleteReplyComment(slideId: string, commentId: string, replyId: string) {
    return this.comments?.deleteReplyComment(slideId, commentId, replyId);
  }

  editReplyComment(
    slideId: string,
    commentId: string,
    replyId: Presentation.Model.Comments.Reply['id'],
    replyContent: Presentation.Model.Comments.ContentsType['content'],
  ) {
    return this.comments?.editReplyComment(slideId, commentId, replyId, replyContent);
  }

  voteReply(
    slideId: string,
    commentId: string,
    replyId: string,
    value: Presentation.Model.Comments.CommentVoteType['value'],
  ) {
    return this.comments?.voteReply(
      slideId,
      commentId,
      this.Data.users?.loggedUserId as string,
      replyId,
      value,
    );
  }

  stop(): void {}

  destroy(): void {
    //
  }
}
