import { useDispatch, useSelector } from '_common/hooks';
import { setZoomValue } from 'PDF/redux/PDFGeneralSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';
import { Footer, Help, ZoomControls } from '_common/suite/components';
import { InteractionController } from '_common/components';
import { Divider } from 'dodoc-design-system';

const PDFFooter = () => {
  const dispatch = useDispatch();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const zoom = useSelector((state) => state.pdf.general.zoom);

  const resetOnboarding = () => {
    sendOnboardingStatus({ target: 'dopdf', step: 'beginning' });
  };

  const changeZoom = (newZoom: number) => {
    dispatch(setZoomValue(newZoom));
  };

  return (
    <Footer.Right>
      <InteractionController environment="dopdf" style={{ alignItems: 'center', height: '100%' }}>
        <InteractionController
          environment="dopdf"
          rules={[{ interaction: 'pdf_allow_zoom' }]}
          style={{ alignItems: 'center', height: '100%' }}
        >
          <ZoomControls zoom={zoom} changeZoom={changeZoom} />
        </InteractionController>
        <Divider vertical margin="0 1rem" />
        <Help resetOnboarding={resetOnboarding} />
      </InteractionController>
    </Footer.Right>
  );
};

export default PDFFooter;
