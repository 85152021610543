//@ts-expect-error
import uuid from 'uuid/v4';

export class DOMUtils {
  private static MEASURE_RATIO: any = {
    rem: {
      rem: 1,
      px: 8,
    },
    px: {
      rem: 1 / 8,
      px: 1,
      pt: 0.75,
      cm: 0.02645833,
    },
    cm: {
      cm: 1,
      pt: 28.346456693,
      px: 1 / 0.02645833,
    },
    in: {
      in: 1,
      pt: 72,
    },
    pt: {
      pt: 1,
      cm: 1 / 28.346456693,
      px: 1 / 0.75,
      in: 1 / 72,
    },
  };

  static convertUnitTo(
    value?: string | number,
    originUnit?: Common.Utils.MeasureUnit,
    convertionUnit: Common.Utils.MeasureUnit = 'pt',
    toFixed: number = 0,
  ) {
    if (value == null) {
      return NaN;
    }

    if (!originUnit && typeof value === 'string') {
      const indexOfUnit = value.search(/cm|in|pt|px/);
      if (indexOfUnit > 0) {
        originUnit = value.substring(indexOfUnit) as Common.Utils.MeasureUnit;
      }
      value = value.replace(/cm|in|pt|px/g, '');
    }

    let _value: number = NaN;
    if (typeof value === 'string') {
      _value = parseFloat(value);
    } else {
      _value = value;
    }

    if (originUnit && !Number.isNaN(_value) && originUnit.match(/^(cm|in|pt|px)$/)) {
      return +(DOMUtils.MEASURE_RATIO[originUnit][convertionUnit] * _value).toFixed(toFixed);
    }

    return _value;
  }

  static rgbToHex(rgb: string) {
    if (rgb) {
      let color = rgb
        .replace('rgba', '')
        .replace('rgb', '')
        .replace('(', '')
        .replace(')', '')
        .split(',');

      const hex0 = `0${parseInt(color[0], 10).toString(16).toUpperCase()}`.slice(-2);
      const hex1 = `0${parseInt(color[1], 10).toString(16).toUpperCase()}`.slice(-2);
      const hex2 = `0${parseInt(color[2], 10).toString(16).toUpperCase()}`.slice(-2);

      return `#${hex0}${hex1}${hex2}`;
    }
    return null;
  }

  static generateRandomNodeId() {
    return `ddc${uuid()}`;
  }

  static generateUUID() {
    return uuid();
  }

  static parentContainsNode(parent: Node | null, node: Node | null) {
    return parent instanceof Node && node !== null && parent !== node
      ? parent.contains(node)
      : false;
  }

  static closest(
    thisNode: Node | null,
    ancestorTags: string[] | string,
    pageNode: Node | null = document.body,
  ): Node | null {
    if (thisNode) {
      let node: Node | null = thisNode.nodeType === 3 ? thisNode.parentNode : thisNode;
      let tags: string[] = [];
      if (typeof ancestorTags === 'string') {
        tags = ancestorTags.split(',');
      } else if (Array.isArray(ancestorTags)) {
        tags = ancestorTags;
      }

      if (node) {
        const numberTags = tags.length;
        let i;
        for (i = 0; i < numberTags; i += 1) {
          if (node.nodeName === tags[i].trim()) {
            return node;
          }
        }
        let thisParentNode: Node | null = node.parentNode;
        while (thisParentNode && thisParentNode !== pageNode) {
          for (i = 0; i < numberTags; i += 1) {
            if (thisParentNode.nodeName === tags[i].trim()) {
              return thisParentNode;
            }
          }
          node = thisParentNode;
          thisParentNode = node.parentNode;
        }
      }
    }
    return null;
  }

  static closestThatMatch(
    thisNode: Node | null,
    selector: string,
    pageNode: Node | null = document.body,
  ): Node | null {
    if (!thisNode) {
      return null;
    }
    let node: Node | null = thisNode.nodeType === 3 ? thisNode.parentNode : thisNode;
    if (!node) {
      return null;
    }
    let thisParentNode = node.parentNode as HTMLElement;
    while (thisParentNode && thisParentNode !== pageNode) {
      if (thisParentNode.matches(selector)) {
        return thisParentNode;
      }
      node = thisParentNode;
      thisParentNode = node.parentNode as HTMLElement;
    }
    return null;
  }

  static findFirstLevelChildNode(containerNode: Node | null, childNode: Node | null) {
    if (!containerNode || !childNode) {
      return null;
    }
    if (containerNode === childNode || !DOMUtils.parentContainsNode(containerNode, childNode)) {
      return null;
    }
    let node = childNode;
    while (node && DOMUtils.parentContainsNode(containerNode, node.parentNode)) {
      node = node.parentNode as Node;
    }
    return node;
  }

  static findFirstLevelChildNodes(containerNode: Node | null, childNodes: (Node | null)[]): Node[] {
    const firstLevelChildNodeArray: Node[] = [];

    for (let i = 0; i < childNodes.length; i++) {
      const firstLevelChildNode = DOMUtils.findFirstLevelChildNode(containerNode, childNodes[i]);

      if (firstLevelChildNode && !firstLevelChildNodeArray.includes(firstLevelChildNode)) {
        firstLevelChildNodeArray.push(firstLevelChildNode);
      }
    }

    return firstLevelChildNodeArray;
  }

  static filterAndAdjustDOMRectList(rects: Rect[] | DOMRectList) {
    const filteredRects: Rect[] = [];

    let previousRect: Rect | undefined;

    let addRect = false;

    // filter rects and group orverlapping rects
    for (let i = 0; i < rects.length; i++) {
      const r = rects[i];

      addRect = false;

      if (r.width > 0) {
        if (previousRect /* && previousRect.left === r.left && previousRect.right === r.right */) {
          const heightDiff = previousRect.height * 0.3;
          const bottomDiff = Math.abs(previousRect.bottom - r.bottom);
          const topDiff = Math.abs(previousRect.top - r.top);

          let intersect = null;
          if (previousRect.left <= r.left) {
            intersect = previousRect.right - r.left;
          } else {
            intersect = r.right - previousRect.left;
          }

          if (bottomDiff <= heightDiff && topDiff <= heightDiff && intersect >= -5) {
            if (previousRect.bottom < r.bottom) {
              filteredRects[filteredRects.length - 1].bottom = r.bottom;
            }

            if (previousRect.top > r.top) {
              filteredRects[filteredRects.length - 1].top = r.top;
            }

            filteredRects[filteredRects.length - 1].height =
              filteredRects[filteredRects.length - 1].bottom -
              filteredRects[filteredRects.length - 1].top;

            if (previousRect.left > r.left) {
              filteredRects[filteredRects.length - 1].left = r.left;
            }

            if (previousRect.right < r.right) {
              filteredRects[filteredRects.length - 1].right = r.right;
            }

            filteredRects[filteredRects.length - 1].width =
              filteredRects[filteredRects.length - 1].right -
              filteredRects[filteredRects.length - 1].left;

            previousRect = filteredRects[filteredRects.length - 1];
          } else {
            addRect = true;
          }
        } else {
          addRect = true;
        }

        if (addRect) {
          previousRect = {
            left: r.left,
            bottom: r.bottom,
            right: r.right,
            top: r.top,
            width: r.width,
            height: r.height,
          };
          filteredRects.push(previousRect);
        }
      }
    }

    // TODO: this can be improved
    // adjust sibling rect limits
    let prevRect = filteredRects[0],
      nextRect;
    for (let i = 1; i < filteredRects.length; i++) {
      prevRect = filteredRects[i - 1];
      nextRect = filteredRects[i];

      // check if is same line

      const diff = nextRect.top - prevRect.bottom;

      if (Math.abs(diff) <= prevRect.height * 0.1 && Math.abs(diff) <= nextRect.height * 0.1) {
        if (diff < 0) {
          nextRect.top = prevRect.bottom;
          nextRect.height = nextRect.bottom - nextRect.top;
        } else if (diff > 0) {
          prevRect.bottom = prevRect.bottom + diff / 2;
          prevRect.height = prevRect.bottom - prevRect.top;

          nextRect.top = prevRect.bottom;
          nextRect.height = nextRect.bottom - nextRect.top;
        }
      }
    }

    return filteredRects;
  }

  /**
   * This function will calculate the distance, in px, between 2 elements on the Y axis.
   * It uses the bounding client rects of each element and doesn't take into account which one is after the other
   * It currently is very dumb, so take precaution when using it
   *
   * @param {HTMLElement} a The element that is below
   * @param {HTMLElement} b The element that is on top
   */
  static getVerticalDistanceBetween(a: HTMLElement, b: HTMLElement) {
    const rectA = a.getBoundingClientRect();
    const rectB = b.getBoundingClientRect();

    return rectA.top - rectB.top + rectB.height;
  }
}
