export const BULLET_SYMBOLS = ['8226', '8211', '176', '8594', '8658', '8901', '9830'];

export const MORE_BULLET_SYMBOLS = [
  // ['160'],
  // ['173'],
  '34',
  // finance
  '162',
  '8364',
  '163',
  '165',
  // signs
  '169',
  '174',
  '8482',
  '8240',
  '181',
  '183',
  '8226',
  '8230',
  '8242',
  '8243',
  '167',
  '182',
  '223',
  // quotations
  '8249',
  '8250',
  '171',
  '187',
  '8216',
  '8217',
  '8220',
  '8221',
  '8218',
  '8222',
  '60',
  '62',
  '8804',
  '8805',
  '8211',
  '8212',
  '175',
  '8254',
  '164',
  '166',
  '168',
  '161',
  '191',
  '710',
  '732',
  '176',
  '8722',
  '177',
  '247',
  '8260',
  '215',
  '185',
  '178',
  '179',
  '188',
  '189',
  '190',
  // math / logical
  '402',
  '8747',
  '8721',
  '8734',
  '8730',
  '8764',
  '8773',
  '8776',
  '8800',
  '8801',
  '8712',
  '8713',
  '8715',
  '8719',
  '8743',
  '8744',
  '172',
  '8745',
  '8746',
  '8706',
  '8704',
  '8707',
  '8709',
  '8711',
  '8727',
  '8733',
  '8736',
  // undefined
  '180',
  '184',
  '170',
  '186',
  '8224',
  '8225',
  // alphabetical special chars
  '192',
  '193',
  '194',
  '195',
  '196',
  '197',
  '256',
  '198',
  '199',
  '200',
  '201',
  '202',
  '203',
  '274',
  '204',
  '205',
  '206',
  '207',
  '298',
  '208',
  '209',
  '210',
  '211',
  '212',
  '213',
  '214',
  '216',
  '332',
  '338',
  '352',
  '217',
  '218',
  '219',
  '220',
  '362',
  '221',
  '376',
  '562',
  '222',
  '224',
  '225',
  '226',
  '227',
  '228',
  '229',
  '257',
  '230',
  '231',
  '232',
  '233',
  '234',
  '235',
  '275',
  '236',
  '237',
  '238',
  '239',
  '299',
  '240',
  '241',
  '242',
  '243',
  '244',
  '245',
  '246',
  '248',
  '333',
  '339',
  '353',
  '249',
  '250',
  '251',
  '252',
  '363',
  '253',
  '254',
  '255',
  '563',
  '913',
  '914',
  '915',
  '916',
  '917',
  '918',
  '919',
  '920',
  '921',
  '922',
  '923',
  '924',
  '925',
  '926',
  '927',
  '928',
  '929',
  '931',
  '932',
  '933',
  '934',
  '935',
  '936',
  '937',
  '945',
  '946',
  '947',
  '948',
  '949',
  '950',
  '951',
  '952',
  '953',
  '954',
  '955',
  '956',
  '957',
  '958',
  '959',
  '960',
  '961',
  '962',
  '963',
  '964',
  '965',
  '966',
  '967',
  '968',
  '969',
  // symbols
  '8501',
  '982',
  '8476',
  '978',
  '8472',
  '8465',
  // arrows
  '8592',
  '8593',
  '8594',
  '8595',
  '8596',
  '8629',
  '8656',
  '8657',
  '8658',
  '8659',
  '8660',
  '8756',
  '8834',
  '8835',
  '8836',
  '8838',
  '8839',
  '8853',
  '8855',
  '8869',
  '8901',
  '8968',
  '8969',
  '8970',
  '8971',
  '9001',
  '9002',
  '9674',
  '9824',
  '9827',
  '9829',
  '9830',
  '111',
  '9632',
  // ['8194'],
  // ['8195'],
  // ['8201'],
  // ['8204'],
  // ['8205'],
  // ['8206'],
  // ['8207'],
];
