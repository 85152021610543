import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, RadioButton } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { closeModal } from '_common/modals/ModalsSlice';

import styles from './DeleteCellsModal.module.scss';
import { notify } from '_common/components/ToastSystem';

const DeleteCellsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.DeleteCellsModal);

  const OPTIONS = [
    {
      label: intl.formatMessage({ id: 'SHIFT_CELLS_LEFT' }),
      id: 'scl',
      description: intl.formatMessage({
        id: 'SELECTED_CELLS_WILL_BE_DELETED_ADJECENT_WILL_SHIFT_LEFT',
      }),
    },
    {
      label: intl.formatMessage({ id: 'SHIFT_CELLS_UP' }),
      id: 'scu',
      description: intl.formatMessage({
        id: 'SELECTED_CELLS_WILL_BE_DELETED_CELLS_BELOW_WILL_SHIFT_UP',
      }),
    },
    {
      label: intl.formatMessage({ id: 'DELETE_ENTIRE_ROW' }),
      id: 'der',
      description: intl.formatMessage({
        id: 'THE_ENTIRE_ROW_CONTAINING_SELECTED_CELLS_WILL_BE_DELETED',
      }),
    },
    {
      label: intl.formatMessage({ id: 'DELETE_ENTIRE_COLUMN' }),
      id: 'dec',
      description: intl.formatMessage({
        id: 'THE_ENTIRE_COLUMN_CONTAINING_SELECTED_CELLS_WILL_BE_DELETED',
      }),
    },
  ];
  const [selected, setSelected] = useState(OPTIONS[0].id);

  const close = () => {
    dispatch(closeModal('DeleteCellsModal'));
    setSelected(OPTIONS[0].id);
  };

  const handleDeleteCells = () => {
    const manager = EditorManager.getInstance();
    switch (selected) {
      case 'scl':
        manager.deleteCells('SHIFT_LEFT');
        notify({
          type: 'success',
          title: 'CELLS_REMOVED',
          message: 'CELLS_REMOVED_MESSAGE',
        });
        break;
      case 'scu':
        manager.deleteCells('SHIFT_UP');
        notify({
          type: 'success',
          title: 'CELLS_REMOVED',
          message: 'CELLS_REMOVED_MESSAGE',
        });
        break;
      case 'der':
        manager.deleteRows();
        break;
      case 'dec':
        manager.deleteColumns();
        break;
      default:
        break;
    }
    close();
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="delete-cells">
      <Modal.Header onClose={close}>
        <FormattedMessage id="DELETE_CELLS" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.root}>
          <div className={styles.description}>
            <FormattedMessage id="DELETING_CELLS_AFFECTING_ADJACENT_SELECT_OPTION" />
          </div>
          <div className={styles.options}>
            {OPTIONS.map(({ id, label, description }) => {
              return (
                <div key={id}>
                  <RadioButton
                    checked={selected === id}
                    onChange={() => setSelected(id)}
                    size="x-small"
                    testId={`delete-cells-modal-${label
                      .toLowerCase()
                      .replaceAll(' ', '-')}-radio-button`}
                  >
                    {label}
                  </RadioButton>
                  <div className={styles.optionDescription}>{description}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="delete-cells-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleDeleteCells}
          testId="delete-cells-modal-submit-button"
        >
          <FormattedMessage id="DONE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCellsModal;
