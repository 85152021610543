import { CSSProperties, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { merge } from 'lodash';

import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import NumberingUtils from '_common/utils/NumberingUtils';
import { cloneObject } from 'Presentation/utils';
import { isCharBullet, isNoBullet, isNumberedBullet, isPictureBullet } from './bulletUtils';
import PictureBullet from './PictureBullet';
import useTextProperties from '../hooks/useTextProperties';

type BulletProps = {
  paragraph: Presentation.Data.ParagraphShape;
  level: number | undefined;
  index: number | undefined;

  listStyle: Presentation.Data.TextBody['listStyle'];
};

const Bullet = ({ paragraph, index, level, listStyle }: BulletProps) => {
  /*
   * Sources:
   *  - ECMA part 1 p3245 - 21.1.2.4 Bullets and Numbering
   *
   
   * Supported bullet properties:
   * - buAutoNum
   * - startAt
   * - type
   * - buBlip
   * - buChar
   * - buClr
   * - buClrTx
   * - buFont (with the same problems and features as text font family)
   * - buFontTx
   * - buNone
   * - buSzPct
   * - buSzTx
   * - lstStyle
   * - follow some other properties of the first child of the paragraph (dont know where a source for this is) 
   
   * TODO:PRESENTATION:BULLET Unsupported bullet properties:
   * - buSzPts (not sure how to apply this in ppt. Could only find the text percentage (buSzPct) option)
   
   *  TODO:PRESENTATION:BULLET Known issues:
   *  - Dimensions of bullet picture. Not sure on how the dimensions are defined
   */

  const { color } = useSlideData();
  const { getTextStyle } = useTextProperties();

  const ref = useRef<HTMLSpanElement>(null);
  const [bulletRect, setBulletRect] = useState<DOMRect>();

  const paragraphFirstChildStyle = useMemo(() => {
    return getTextStyle({ paragraph, run: paragraph.childNodes?.[0] });
  }, [paragraph]);

  useLayoutEffect(() => {
    const current = ref?.current;
    if (current) {
      setBulletRect(current.getBoundingClientRect());
    }
  }, []);

  const paragraphProperties = useMemo(() => {
    if (listStyle) {
      let listStyleIdx: number | string | undefined = undefined;
      if (paragraph.properties.lvl != null) {
        listStyleIdx = paragraph.properties.lvl + 1;
      } else if (level != null) {
        listStyleIdx = Math.max(1, level + 1);
      }

      if (listStyleIdx != null) {
        let style = listStyle[listStyleIdx];
        if (style) {
          style = merge(cloneObject(style), paragraph.properties);
          return style;
        }
      }
    }

    return paragraph.properties;
  }, [paragraph, listStyle]);

  const bullet = useMemo<Presentation.Data.Bullet | undefined>(() => {
    if (!paragraph.childNodes?.length) {
      return undefined;
    }

    const { properties } = paragraph;

    let bullet: Presentation.Data.Bullet | undefined = undefined;

    if (paragraphProperties.bullet != null) {
      bullet = paragraphProperties.bullet;
    }

    if (properties.bullet) {
      bullet = { ...bullet, ...properties.bullet };
    }

    return bullet;
  }, [paragraph]);

  const symbol = useMemo(() => {
    if (!bullet?.symbol) {
      return undefined;
    }

    if (bullet.symbol?.none) {
      return undefined;
    }

    let symbol: { type: 'text' | 'picture'; content: string } | undefined = undefined;

    if (isCharBullet(bullet.symbol)) {
      symbol = { type: 'text', content: bullet.symbol.char };
    } else if (isNumberedBullet(bullet.symbol)) {
      let type: string | undefined = undefined;
      let suffix = '';
      let prefix = '';

      switch (bullet.symbol.numberingFormat) {
        case 'alphaLcParenBoth': {
          type = 'la';
          prefix = '(';
          suffix = ')';
          break;
        }
        case 'alphaLcParenR': {
          type = 'la';
          suffix = ')';
          break;
        }
        case 'alphaLcPeriod': {
          type = 'la';
          suffix = '.';
          break;
        }
        case 'alphaUcParenBoth': {
          type = 'ua';
          prefix = '(';
          suffix = ')';
          break;
        }
        case 'alphaUcParenR': {
          type = 'ua';
          suffix = ')';
          break;
        }
        case 'alphaUcPeriod': {
          type = 'ua';
          suffix = '.';
          break;
        }
        case 'arabicParenBoth': {
          type = 'd';
          prefix = '(';
          suffix = ')';
          break;
        }
        case 'arabicParenR': {
          type = 'd';
          suffix = ')';
          break;
        }
        case 'arabicPeriod': {
          type = 'd';
          suffix = '.';
          break;
        }
        case 'arabicPlain': {
          type = 'd';
          break;
        }
        case 'romanLcParenBoth': {
          type = 'lr';
          prefix = '(';
          suffix = ')';
          break;
        }
        case 'romanLcParenR': {
          type = 'lr';
          suffix = ')';
          break;
        }
        case 'romanLcPeriod': {
          type = 'lr';
          suffix = '.';
          break;
        }
        case 'romanUcParenBoth': {
          type = 'ur';
          prefix = '(';
          suffix = ')';
          break;
        }
        case 'romanUcParenR': {
          type = 'ur';
          suffix = ')';
          break;
        }
        case 'romanUcPeriod': {
          type = 'ur';
          suffix = '.';
          break;
        }
        case 'arabic1Minus':
        case 'arabic2Minus':
        case 'arabicDbPeriod':
        case 'arabicDbPlain':
        case 'circleNumDbPlain':
        case 'circleNumWdBlackPlain':
        case 'circleNumWdWhitePlain':
        case 'ea1ChsPeriod':
        case 'ea1ChsPlain':
        case 'ea1ChtPeriod':
        case 'ea1ChtPlain':
        case 'ea1JpnChsDbPeriod':
        case 'ea1JpnKorPeriod':
        case 'ea1JpnKorPlain':
        case 'hebrew2Minus':
        case 'hindiAlpha1Period':
        case 'hindiAlphaPeriod':
        case 'hindiNumParenR':
        case 'hindiNumPeriod':
        case 'thaiAlphaParenBoth':
        case 'thaiAlphaParenR':
        case 'thaiAlphaPeriod':
        case 'thaiNumParenBoth':
        case 'thaiNumParenR':
        case 'thaiNumPeriod':
        default: {
          /*
           * TODO:PRESENTATION:LISTS proccess this formats:
           * ECMA Office Open XML Part 1 - Fundamentals and markup
           * page 3063
           * 20.1.10.61 ST_TextAutonumberScheme (Text Auto-number Schemes)
           */
          type = 'd';
          suffix = '.';
          break;
        }
      }

      if (type) {
        symbol = {
          type: 'text',
          content: `${prefix}${NumberingUtils.represent(type, index, 'aa')}${suffix}`,
        };
      }
    } else if (isPictureBullet(bullet.symbol)) {
      symbol = { type: 'picture', content: bullet.symbol.source };
    } else if (isNoBullet(bullet.symbol)) {
      if (bullet.symbol.noBullet) {
        return undefined;
      }
    }

    return symbol;
  }, [bullet]);

  const style = useMemo<CSSProperties | undefined>(() => {
    if (!bullet) {
      return undefined;
    }

    /*
     * Some styles of the first child of the paragraph are inherited by the bullet:
     * - font family
     * - font color
     * - font size
     * - bold
     * - italic
     * TODO:PRESENTATION:BULLET There might be more styles that are inherited, couldnt find a source for this
     */

    const sizePercentage = (bullet.size?.percentage ?? 100) / 100;

    let fontFamily = paragraphFirstChildStyle.fontFamily;
    let fontColor = paragraphFirstChildStyle.color;
    let fontSize =
      typeof paragraphFirstChildStyle.fontSize === 'string'
        ? parseFloat(paragraphFirstChildStyle.fontSize)
        : paragraphFirstChildStyle.fontSize;

    fontSize = fontSize != null ? fontSize * sizePercentage : fontSize;

    if (bullet.font?.value && !bullet.font.sameAsText) {
      //pptx sends these value, bue they arent use as the font. It fallbacks to the default font family
      if (
        bullet.font.value !== '+mj-lt' &&
        bullet.font.value !== '+mj-ea' &&
        bullet.font.value !== '+mj-cs' &&
        bullet.font.value !== '+mn-lt' &&
        bullet.font.value !== '+mn-ea' &&
        bullet.font.value !== '+mn-cs'
      ) {
        fontFamily = `"${bullet.font.value}"`;
      }
    }

    if (bullet.color && !bullet.color.sameAsText) {
      fontColor = color(bullet.color);
    }

    const marginRight =
      bulletRect?.width != null
        ? Math.abs(paragraphProperties?.indent ?? 0) - bulletRect.width
        : undefined;

    return {
      fontFamily,
      fontSize: `${fontSize}px`,
      color: fontColor,
      fontWeight: paragraphFirstChildStyle.fontWeight,
      fontStyle: paragraphFirstChildStyle.fontStyle,
      width: fontSize != null ? `${fontSize * 0.7}px` : undefined, //width of image
      height: fontSize != null ? `${fontSize * 0.7}px` : undefined, //height of image
      marginRight:
        marginRight != null
          ? marginRight < 0
            ? Math.max(0, marginRight)
            : marginRight
          : undefined,
      userSelect: 'none',
    };
  }, [bullet, bulletRect]);

  if (!symbol || index == null) {
    return null;
  }

  return (
    <span data-type="bullet" style={{ ...style, width: undefined, height: undefined }} ref={ref}>
      {symbol?.type === 'text' && symbol.content}

      {symbol?.type === 'picture' && (
        <PictureBullet source={symbol.content} width={style?.width} height={style?.height} />
      )}
    </span>
  );
};

export default Bullet;
