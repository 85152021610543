import { useIntl } from 'react-intl';
import { Accordion, Select } from 'dodoc-design-system';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useDispatch, useSelector } from '_common/hooks';
import { Logger } from '_common/services';

import {
  DateRange,
  FilterIdentity,
  FilterName,
  OptionByType,
  SettingByType,
  TRANSLATIONS,
  TYPES,
} from '../../FilterController';
import { setFilter } from '../../FilterSlice';
import { useMemo } from 'react';

const SelectHandler = ({
  identity,
  filter,
  filterQuantity,
  options,
  settings,
  testId,
}: {
  identity: FilterIdentity;
  filter: FilterName;
  filterQuantity: number;
  options: OptionByType['select'];
  settings: SettingByType['select'];
  testId: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const currentValue = useSelector((state) => {
    const value = state.filters[identity]?.[filter];
    return value as Exclude<typeof value, DateRange>; //Cast to avoid typescript false calls due to DateRange
  });
  const isMulti = TYPES.multiSelect.includes(filter);

  const translatedOptions = useMemo(
    () =>
      options?.map((option) => ({
        ...option,
        label: intl.formatMessage({ id: `${option.label}` }),
      })),
    [options],
  );

  const selectedValue = useMemo(() => {
    if (!currentValue || !translatedOptions) {
      return [];
    }

    if (Array.isArray(currentValue)) {
      return translatedOptions.filter((option) =>
        currentValue.map((filterValue) => filterValue.value).includes(option.value),
      );
    } else {
      return translatedOptions.find((option) => option.value === currentValue.value) ?? [];
    }
  }, [currentValue]);

  if (!translatedOptions) {
    Logger.error('[SelectHandler] - No options provided, unable to render filter: ', filter);
    return null;
  }

  const handleValueChange = (newSelection: SelectOption | SelectOption[] | null) => {
    if (!newSelection) {
      dispatch(setFilter({ identity, filter, value: null }));
    } else if (Array.isArray(newSelection)) {
      dispatch(
        setFilter({
          identity,
          filter,
          value: newSelection.map((selection) => ({
            value: selection.value,
            label: selection.label,
          })),
        }),
      );
    } else {
      dispatch(
        setFilter({
          identity,
          filter,
          value: { value: newSelection.value, label: newSelection.label },
        }),
      );
    }
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: TRANSLATIONS[filter] })}
      initialCollapsed={!currentValue}
      collapsable={filterQuantity > 1}
      testId={`${testId}-accordion`}
    >
      <Select
        isMulti={isMulti}
        width="100%"
        value={selectedValue}
        options={translatedOptions}
        onChange={handleValueChange}
        size="medium"
        testId={testId}
        filterOption={(option, inputValue) => {
          return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }}
        placeholder={intl.formatMessage({
          id: settings?.placeholder ?? 'object.selectOption',
        })}
        multiOverflowLabel={
          isMulti && settings?.multiOverflowLabel
            ? intl.formatMessage(
                { id: settings.multiOverflowLabel },
                { total: Array.isArray(selectedValue) ? selectedValue.length : 0 },
              )
            : undefined
        }
        selectedOptionsGroupLabel={
          isMulti && settings?.selectedOptionsGroupLabel
            ? intl.formatMessage({ id: settings.selectedOptionsGroupLabel })
            : undefined
        }
        listOptionsGroupLabel={
          isMulti && settings?.listOptionsGroupLabel
            ? intl.formatMessage({ id: settings.listOptionsGroupLabel })
            : undefined
        }
      />
    </Accordion>
  );
};

export default SelectHandler;
