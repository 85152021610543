import { useIntl } from 'react-intl';
import Switch, { SwitchProps } from 'Settings/components/NotificationSettings/Switch/Switch';
import { useEditUserSettingsMutation } from '_common/services/api/UserApi';

export type ChannelsProps<
  N extends keyof ApiSchemas['UserSettingsSchema']['notifications'],
  C extends ApiSchemas['UserSettingsSchema']['notifications'][N],
> = {
  notificationId: N;
  channels: C;
  dataTestId: { [x in keyof ChannelsProps<N, C>['channels']]: SwitchProps['testId'] };
};

const LOCKED_CHANNELS: {
  [key in keyof ApiSchemas['UserSettingsSchema']['notifications']]?: {
    app?: boolean;
    email?: boolean;
  };
} = {
  profile: {
    app: true,
    email: true,
  },
  mentions: {
    app: true,
  },
};

const Channels = <
  N extends keyof ApiSchemas['UserSettingsSchema']['notifications'],
  C extends ApiSchemas['UserSettingsSchema']['notifications'][N],
>({
  notificationId,
  channels,
  dataTestId,
}: ChannelsProps<N, C>) => {
  const intl = useIntl();
  const [editUserSettings] = useEditUserSettingsMutation();

  const onChannelChange = (channel: 'app' | 'email') => {
    editUserSettings({ [`notifications.${notificationId}.${channel}`]: !channels[channel] });
  };

  return (
    <div>
      <Switch
        label={intl.formatMessage({ id: 'IN_APP' })}
        onChange={() => {
          onChannelChange('app');
        }}
        disabled={LOCKED_CHANNELS[notificationId]?.app}
        active={channels.app}
        testId={dataTestId.app}
      />
      <Switch
        label={intl.formatMessage({ id: 'Email' })}
        onChange={() => {
          onChannelChange('email');
        }}
        disabled={LOCKED_CHANNELS[notificationId]?.email || !channels.app}
        active={channels.email}
        margin={'1rem 0 0 0'}
        testId={dataTestId.email}
        inAppActive={channels.app}
      />
    </div>
  );
};

export default Channels;
