import api from '_common/services/api/api';

const PresentationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImage: builder.query<string, ApiOperations['get_image']['parameters']['path']>({
      query: ({ presentation_id, reference_id }) => ({
        url: `/object/presentation/${presentation_id}/image/${reference_id}/get`,
        errorsExpected: [404],
        config: {
          responseType: 'blob',
        },
      }),
      transformResponse: (avatar: Blob) => {
        return URL.createObjectURL(avatar);
      },
      providesTags: (result, error, args) => [
        { type: 'file', id: args.presentation_id + args.reference_id },
      ],
    }),
    presentationPreview: builder.query<
      ApiOperations['pptx_preview']['responses']['200']['content']['application/json'],
      ApiOperations['pptx_preview']['parameters']['path']
    >({
      query: ({ object_id }) => ({
        url: `/object/file/${object_id}/preview`,
        config: {
          responseType: 'blob',
        },
      }),
      transformResponse: async (response: Blob) => {
        /**
         * Endpoint response is either a PDF document or a JSON object, which indicates the PPT->PDF convert status
         * [responseType: 'blob'] will convert the response to a Blob
         * When endpoint response is supposed to be the JSON object, this will also be returned as a Blob,
         * JSON.parse(Blob.text()) will return the JSON object, if not possible it means the Blob is the PDF document
         */
        try {
          return JSON.parse(await response.text());
        } catch (e) {
          return response;
        }
      },
    }),
    exportPresentation: builder.mutation<
      ApiSchemas['JobSchema'],
      { object_id: string; keep_tasks: boolean }
    >({
      query: ({ object_id, keep_tasks }) => ({
        url: `/object/presentation/${object_id}/export`,
        method: 'POST',
        body: { keep_tasks },
      }),
    }),
    downloadPresentation: builder.mutation<Blob, { object_id: string }>({
      query: ({ object_id }) => ({
        url: `/object/presentation/${object_id}/export/get`,
        method: 'GET',
        config: {
          responseType: 'blob',
        },
      }),
    }),
  }),
});

export const {
  useGetImageQuery,
  usePresentationPreviewQuery,
  useExportPresentationMutation,
  useDownloadPresentationMutation,
} = PresentationApi;

export default PresentationApi;
