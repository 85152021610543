import { FormattedMessage } from 'react-intl';
import { SectionHeader } from 'dodoc-design-system';

import { LanguageSelect } from 'Editor/components';

type ParagraphLanguageProps = {
  setLanguage: (language: Services.Language['value']) => void;
  languages: Services.Language[];
  currentLanguage?: Services.Language;
};

const ParagraphLanguage = ({ setLanguage, languages, currentLanguage }: ParagraphLanguageProps) => {
  const handleLanguageChange = (newLanguage: Services.Language) => {
    setLanguage(newLanguage.value);
  };

  return (
    <>
      <SectionHeader margin="4rem 0 2rem 0">
        <FormattedMessage
          id={currentLanguage?.supported ? 'PARAGRAPH_LANGUAGE' : 'DOCUMENT_LANGUAGE'}
        />
      </SectionHeader>
      <LanguageSelect
        options={languages}
        value={currentLanguage}
        onChange={handleLanguageChange}
        size="medium"
        testId="spell-check-language-select"
      />
    </>
  );
};

export default ParagraphLanguage;
