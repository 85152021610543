import { Root, createRoot } from 'react-dom/client';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'Intl/Intl';
import { store } from '_common/redux';

class ReactUtils {
  static renderReactComponent(component: ReactNode, root: Root) {
    root.render(
      <Provider store={store}>
        <IntlProvider locale={store.getState().intl.locale}>{component}</IntlProvider>
      </Provider>,
    );
  }

  static createReactRoot(container: HTMLElement) {
    return createRoot(container);
  }
}

export default ReactUtils;
