import { useEffect, useMemo } from 'react';
import { Select } from 'dodoc-design-system';

import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

import { HandlerProps, SelectOption, STATUS_BEHAVIOUR } from '../AdvancedFilterOptions';
import { useAdvancedFilterContext } from '../AdvancedFilterContext';

import styles from './Handlers.module.scss';

type StatusHandlerProps = {
  testId?: string;
};

const StatusHandler = ({ sequence, testId }: HandlerProps & StatusHandlerProps) => {
  const { data: statuses } = useGetElementStatusListQuery();

  const { advancedFilter, setValue, setQuery } = useAdvancedFilterContext();

  const { option, metadataOption, value } = advancedFilter;
  const mergedOption = metadataOption ? `${option}.${metadataOption}` : option;

  const TRANSLATED_OPTIONS: SelectOption[] = useMemo(() => {
    return statuses
      ? statuses.ids
          .filter((id) => {
            return statuses.entities[id] && !statuses.entities[id]?.deleted;
          })
          .map((id) => ({
            value: id.toString(),
            label: statuses.entities[id]?.name ?? '',
          }))
      : [];
  }, [statuses]);

  useEffect(() => {
    if (!sequence.type || !value) {
      setQuery(undefined);
      return;
    }

    setQuery(STATUS_BEHAVIOUR({ field: mergedOption, value: value }));
  }, [value]);

  const handleChangeStatus = (newSelectValue: SelectOption) => {
    setValue(newSelectValue.label);
  };

  return (
    <div className={styles.handlerRoot} data-testid={testId}>
      <Select
        clearable={false}
        width="20rem"
        size="medium"
        options={TRANSLATED_OPTIONS}
        onChange={handleChangeStatus}
        value={TRANSLATED_OPTIONS.find((option) => option.label === value) || null} //Force select UI cleanup by sending null
        testId={`${testId}-options`}
      />
    </div>
  );
};

export default StatusHandler;
