import { ReactNode } from 'react';
import cx from 'classnames';

import { useSuiteApp } from '_common/suite/SuiteContext/SuiteContext';

import styles from './PanelHeading.module.scss';

type PanelHeadingProps = {
  editor?: boolean;
  children: ReactNode;
  //Reference: OnboardingEditor>Standalone, used to get dynamic position of element
  id?: string;
};

const PanelHeading = ({ children, id }: PanelHeadingProps) => {
  const suiteApp = useSuiteApp();

  return (
    <div id={id} className={cx(styles.panelheading, { [styles.editor]: suiteApp === 'document' })}>
      {children}
    </div>
  );
};

export default PanelHeading;
