import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

const SLICE_NAME = 'FIND_AND_REPLACE_SLICE';

export type InstanceType = { id?: string } & Editor.Data.FindAndReplace.WordOccurence;

export type FindAndReplaceSliceState = {
  totalResults: number;
  currentResult: number | null;
  currentCanBeReplaced: boolean;
  instances: InstanceType[];
  selectedText: string;
};

const initialState: FindAndReplaceSliceState = {
  totalResults: -1,
  currentResult: -1,
  currentCanBeReplaced: true,
  instances: [],
  selectedText: '',
};

const FindAndReplaceSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSelectedText: (
      state,
      action: PayloadAction<Pick<FindAndReplaceSliceState, 'selectedText'>>,
    ) => {
      state.selectedText = action.payload.selectedText;
    },
    setCurrentResult: (
      state,
      action: PayloadAction<
        Pick<FindAndReplaceSliceState, 'currentResult' | 'currentCanBeReplaced'>
      >,
    ) => {
      state.currentResult = action.payload.currentResult;
      state.currentCanBeReplaced = action.payload.currentCanBeReplaced;
    },
    setResultNumber: (
      state,
      action: PayloadAction<Pick<FindAndReplaceSliceState, 'currentResult' | 'totalResults'>>,
    ) => {
      state.totalResults = action.payload.totalResults;
      state.currentResult = action.payload.currentResult;
    },
    setInstances: (
      state,
      action: PayloadAction<
        Pick<FindAndReplaceSliceState, 'currentResult' | 'instances' | 'currentCanBeReplaced'>
      >,
    ) => {
      state.currentResult = action.payload.currentResult;
      state.instances = action.payload.instances;
      state.totalResults = action.payload.instances?.length;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const { setCurrentResult, setResultNumber, setInstances, setSelectedText } =
  FindAndReplaceSlice.actions;

export default FindAndReplaceSlice.reducer;
