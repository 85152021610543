import { Link } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

type SpaceCellProps = {
  object: Objekt;
};

const SpaceCell = ({ object }: SpaceCellProps) => {
  return (
    <Link size="small" href={`/storage/space/${object.space.id}`} testId="space-link">
      {object.space.personal ? <FormattedMessage id="spaces.personalSpace" /> : object.space.name}
    </Link>
  );
};

export default SpaceCell;
