import { MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { Switch, Tooltip, Icon } from 'dodoc-design-system';

export type ActiveDetailsProps = {
  toggle: MouseEventHandler<HTMLElement>;
  active: boolean;
  tooltipContent: string; //TODO:TS Set type to TranslationId when translation Ids are all inline
  testId: string;
};

const ActiveDetails = ({ toggle, active, tooltipContent, testId }: ActiveDetailsProps) => {
  const intl = useIntl();
  return (
    <>
      <div style={{ margin: '0 1rem' }}>
        <Switch
          active={active}
          onChange={toggle}
          size="large"
          labelPlacement="right"
          testId={testId}
        >
          {intl.formatMessage({
            id: 'global.active',
          })}
        </Switch>
      </div>
      <Tooltip
        content={intl.formatMessage({
          id: tooltipContent,
        })}
        testId={`${testId}-information-tooltip`}
      >
        <div style={{ display: 'flex', flex: '1' }}>
          <Icon icon="InformationBlue" size={16} />
        </div>
      </Tooltip>
    </>
  );
};

export default ActiveDetails;
