import { NodeUtils } from 'Editor/services/DataManager';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

export class DeleteRowsOperation extends BaseOperation<Editor.Data.Node.Model> {
  shiftCells: Editor.Edition.ShiftCellsType;
  tableId: string;
  indexRows: number[] = [];

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    shiftCells: Editor.Edition.ShiftCellsType,
    selectedCells?: Editor.Edition.CellInfo[],
    indexRows?: number[],
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.shiftCells = shiftCells;

    if (indexRows?.length) {
      this.indexRows = indexRows;
    } else if (selectedCells?.length) {
      this.indexRows = TableUtils.getRowsIndex(selectedCells);
    }

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.tableId && this.indexRows) {
      const baseData = this.model.selectedData();

      if (!baseData) {
        return this;
      }

      const tableInfo = this.model.getChildInfoById(this.tableId);
      const table: Editor.Data.Node.Data = tableInfo.data;
      const tbody = table.childNodes?.[0];

      const headCellToUpdate: any = {};
      const headCellToRemove: string[] = [];

      const tablePath = this.model.findPath(this.model.KEYS.ID, table.id);
      tablePath.pop(); // remove path id

      const rows = tbody?.childNodes;

      if (!rows || !rows.length) {
        return this;
      }

      for (let i = 0; i < this.indexRows.length; i++) {
        const row = rows[this.indexRows[i]];

        // head cells checked per row
        const headCellChecked: string[] = [];

        // check for merged cells
        if (row.childNodes) {
          for (let j = 0; j < row.childNodes.length; j++) {
            const cell = row.childNodes[j];
            const cellPath = this.model.findPath('id', cell.id);

            if (cell) {
              if (cell.properties?.['head-id']) {
                const headCellPath = this.model.findPath('id', cell.properties?.['head-id']);

                if (headCellPath.length) {
                  const headCell = NodeUtils.getChildDataByPath(baseData, headCellPath);
                  const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);
                  const cellIndexs = NodeUtils.getCurrentAndParentIndexByPath(cellPath);

                  if (
                    headCell?.id &&
                    headCellIndexs.parentIndex !== cellIndexs.parentIndex &&
                    !headCellChecked.includes(headCell.id)
                  ) {
                    // add headcell to update
                    if (!headCellToRemove.includes(headCell.id)) {
                      if (headCellToUpdate[headCell.id] != null) {
                        headCellToUpdate[headCell.id].properties.rs -= 1;
                      } else {
                        headCellToUpdate[headCell.id] = {
                          properties: { rs: headCell?.properties?.rs - 1 },
                        };
                      }
                    }

                    headCellChecked.push(headCell.id);
                  }
                }
              } else if (cell?.properties?.rs > 1 && cell.id) {
                // add headcell to remove
                headCellToRemove.push(cell.id);

                if (headCellToUpdate[cell.id]) {
                  delete headCellToUpdate[cell.id];
                }
              }
            }
          }
        }

        // remove row
        const rowPath = this.model.findPath(this.model.KEYS.ID, row.id);
        rowPath.pop(); // remove path id

        const rowData = this.model.get(rowPath);

        // adjust row path based on delete offset
        // i works has row offset
        rowPath[rowPath.length - 1] = +rowPath[rowPath.length - 1] - i;

        this.ops.push(RealtimeOpsBuilder.listDelete(rowData, rowPath));
      }

      // build ops for removed headcells
      const removeLength = headCellToRemove.length;
      for (let i = 0; i < removeLength; i++) {
        const headCellPath = this.model.findPath('id', headCellToRemove[i]);

        const headCell = NodeUtils.getChildDataByPath(baseData, headCellPath);
        const rowToUpdate = rows[this.indexRows[this.indexRows.length - 1] + 1];
        const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);

        if (
          rowToUpdate != null &&
          rowToUpdate.childNodes &&
          headCellIndexs?.currentIndex != null &&
          headCellIndexs.parentIndex != null
        ) {
          const newHeadCellPath = this.model.findPath(this.model.KEYS.ID, rowToUpdate.id);
          const newHeadCelIndexs = NodeUtils.getCurrentAndParentIndexByPath(newHeadCellPath);
          const rowToUpdateIndexs = NodeUtils.getCurrentAndParentIndexByPath(newHeadCellPath);

          newHeadCellPath.pop(); // remove path id
          newHeadCellPath.pop(); // remove row index
          newHeadCellPath.push(this.indexRows[0]); // add row index with offset
          newHeadCellPath.push(this.model.KEYS.CHILDNODES); // add path to cells
          newHeadCellPath.push(headCellIndexs?.currentIndex); // add cell index
          newHeadCellPath.push(this.model.KEYS.PROPERTIES); // add path to properties

          const cellId = rowToUpdate.childNodes[headCellIndexs?.currentIndex].id;

          if (cellId && rowToUpdateIndexs.currentIndex != null) {
            const newHeadCellData = this.model.getChildDataById(cellId);
            if (!newHeadCelIndexs || newHeadCelIndexs.parentIndex === null) {
              return this;
            }

            const newRowSpanValue =
              headCell?.properties?.rs -
              (rowToUpdateIndexs.currentIndex - headCellIndexs.parentIndex);

            // if new row span value is equal or inferior to 0,
            // means that all rows merged were deleted so no cells need update
            if (newRowSpanValue > 0) {
              // remove display false
              this.ops.push(
                RealtimeOpsBuilder.objectDelete(newHeadCellData[this.model.KEYS.PROPERTIES]?.d, [
                  ...newHeadCellPath,
                  'd',
                ]),
              );

              // remove head-id
              this.ops.push(
                RealtimeOpsBuilder.objectDelete(
                  newHeadCellData[this.model.KEYS.PROPERTIES]?.['head-id'],
                  [...newHeadCellPath, 'head-id'],
                ),
              );

              // add new row span
              if (newRowSpanValue > 1) {
                this.ops.push(
                  RealtimeOpsBuilder.objectInsert(newRowSpanValue, [...newHeadCellPath, 'rs']),
                );
              }

              // copy colspan
              const colspan = headCell?.properties?.cs;
              if (colspan > 1) {
                this.ops.push(RealtimeOpsBuilder.objectInsert(colspan, [...newHeadCellPath, 'cs']));
              }

              // update headIds value
              for (let x = 0; x < newRowSpanValue; x++) {
                for (let y = 0; y < colspan; y++) {
                  if (x !== 0 || y !== 0) {
                    this.ops.push(
                      RealtimeOpsBuilder.objectReplace(headCellToRemove[i], newHeadCellData.id, [
                        ...tablePath,
                        this.model.KEYS.CHILDNODES,
                        0,
                        this.model.KEYS.CHILDNODES,
                        this.indexRows[0] + x, // path offset
                        this.model.KEYS.CHILDNODES,
                        headCellIndexs.currentIndex + y,
                        this.model.KEYS.PROPERTIES,
                        'head-id',
                      ]),
                    );
                  }
                }
              }

              // if new head cell is empty, add an empty paragraph
              if (newHeadCellData.childNodes && newHeadCellData.childNodes.length === 0) {
                const paragraph = NodeDataBuilder.buildData({
                  type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                  parent_id: newHeadCellData.id,
                });

                newHeadCellPath.pop(); // remove properties path
                newHeadCellPath.push(this.model.KEYS.CHILDNODES); // add child nodes path
                newHeadCellPath.push(0); // add base index

                this.ops.push(RealtimeOpsBuilder.listInsert(paragraph, newHeadCellPath));
              }
            }
          }
        }
      }

      // build ops for updated headcells
      const keyIds = Object.keys(headCellToUpdate);

      for (let i = 0; i < keyIds.length; i++) {
        const updatedData = headCellToUpdate[keyIds[i]];

        const headCellPropPath = this.model.findPath(this.model.KEYS.ID, keyIds[i]);
        headCellPropPath.pop(); // remove path id
        headCellPropPath.push(this.model.KEYS.PROPERTIES); // path to properties
        headCellPropPath.push('rs');

        if (updatedData.properties.rs > 0) {
          const op = this.model.buildOp(headCellPropPath, updatedData.properties.rs);
          if (op != null) {
            this.ops.push(op);
          }
        } else {
          const op = this.model.buildOp(headCellPropPath, null);
          if (op != null) {
            this.ops.push(op);
          }
        }
      }
    }

    return this;
  }
}
