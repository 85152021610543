import { Slider as BaseSlider } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import styles from './Slider.module.scss';

import type { SliderProps } from 'dodoc-design-system/build/types/Components/Slider/Slider';
import { updateZoomValue } from 'Editor/redux/EditorStatusSlice';

const Slider = () => {
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.editor.status.zoom);
  const max = 200;
  const min = 50;
  const increment = 10;

  const handleChange: SliderProps['onChange'] = (value) => {
    dispatch(updateZoomValue(Number(value)));
  };

  return (
    <div className={styles.root}>
      <BaseSlider
        variant="discrete"
        min={min}
        max={max}
        step={increment}
        value={Number((zoom * 100).toFixed(0))}
        rangeValues={false}
        onChange={handleChange}
        testId="zoom-slider"
      />
    </div>
  );
};

export default Slider;
