import { createSlice } from '@reduxjs/toolkit';

type TasksSliceState = {
  isListMode: boolean;
};

const initialState: TasksSliceState = {
  isListMode: false,
};

const TasksSlice = createSlice({
  name: 'presentation_task',
  initialState,
  reducers: {
    toggleIsListMode: (state) => {
      state.isListMode = !state.isListMode;
    },
  },
});

export default TasksSlice;

export const { toggleIsListMode } = TasksSlice.actions;
