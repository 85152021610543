import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, usePopper, Toggle, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { openModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

const Table = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const selectedTable = useSelector((state) => state.editor.status.selection.TABLE);

  const manager = EditorManager.getInstance();
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
    disabled: isReadOnlyMode || !selectedTable,
  });

  const openTableProperties = () => {
    dispatch(openModal('TablePropertiesModal'));
  };
  const handleAddCaption = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editMode: false } }));
  };

  const handleSetRowAsTableHeader = () => {
    if (selectedTable) {
      manager.setTableProperties({
        ROW: {
          headerRow: { value: true },
        },
        TABLE: {},
        CELL: {},
        COLUMN: {},
      });
    }
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={isOpen}
        disabled={isReadOnlyMode || isPageLayout || !selectedTable}
        margin="0 1rem 0 0"
        {...referenceProps}
        testId="topbar-menu-table"
      >
        <FormattedMessage id="editor.menu.table.value" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-table-dropdown">
        <Dropdown.SubMenu
          itemContent={`${intl.formatMessage({ id: 'INSERT_ROW' })}...`}
          testId="topbar-menu-table-insert-row"
        >
          <Dropdown.Item
            onClick={manager.insertRowBefore}
            testId="topbar-menu-table-insert-row-above"
          >
            <FormattedMessage id="ABOVE" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={manager.insertRowAfter}
            testId="topbar-menu-table-insert-row-below"
          >
            <FormattedMessage id="BELOW" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.SubMenu
          itemContent={`${intl.formatMessage({ id: 'INSERT_COLUMN' })}...`}
          testId="topbar-menu-table-insert-column"
        >
          <Dropdown.Item
            onClick={manager.insertColumnLeft}
            testId="topbar-menu-table-insert-column-left"
          >
            <FormattedMessage id="LEFT" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={manager.insertColumnRight}
            testId="topbar-menu-table-insert-column-right"
          >
            <FormattedMessage id="RIGHT" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Divider />
        <Dropdown.SubMenu
          itemContent={`${intl.formatMessage({ id: 'global.delete' })}...`}
          testId="topbar-menu-table-delete"
        >
          <Dropdown.Item
            onClick={() => dispatch(openModal('DeleteCellsModal'))}
            testId="topbar-menu-table-delete-cells"
          >
            <FormattedMessage id="CELLS" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => manager.deleteRows()}
            testId="topbar-menu-table-delete-rows"
          >
            <FormattedMessage id="ROW" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => manager.deleteColumns()}
            testId="topbar-menu-table-delete-columns"
          >
            <FormattedMessage id="COLUMN" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => manager.deleteTable()}
            testId="topbar-menu-table-delete-table"
          >
            <FormattedMessage id="TABLE" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Divider />
        <Dropdown.Item
          onClick={() => manager.mergeCells()}
          disabled={selectedTable?.selectedCells && selectedTable?.selectedCells?.length <= 1}
          testId="topbar-menu-table-merge-cells"
        >
          <FormattedMessage id="editor.menu.table.mergeCells" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => manager.splitCells()}
          disabled={
            selectedTable?.selectedCells?.length === undefined ||
            selectedTable.selectedCells.length > 1
          }
          testId="topbar-menu-table-split-cells"
        >
          <FormattedMessage id="editor.menu.table.splitCells" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item onClick={handleAddCaption} testId="topbar-menu-table-insert-caption">
          <FormattedMessage id="INSERT_CAPTION" />
        </Dropdown.Item>
        <Tooltip
          content={intl.formatMessage({ id: 'SET_ROW_AS_TABLE_HEADER_DISABLED_WARNING' })}
          disabled={selectedTable?.rows.fromStart}
          placement="right"
          testId="topbar-menu-table-set-row-as-table-header-tooltip"
        >
          <Dropdown.Item
            onClick={handleSetRowAsTableHeader}
            testId="topbar-menu-table-set-row-as-table-header"
            disabled={!selectedTable?.rows.fromStart}
          >
            <FormattedMessage id="SET_ROW_AS_TABLE_HEADER" />
          </Dropdown.Item>
        </Tooltip>
        <Dropdown.Item onClick={openTableProperties} testId="topbar-menu-table-table-properties">
          <FormattedMessage id="editor.menu.table.tableProperties" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Table;
