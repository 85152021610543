import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PublicLinkPage from './pages/PublicLinkPage/PublicLinkPage';
import { navigateToMyFiles } from 'router/history';
import ErrorView from '../_common/components/ErrorView/ErrorView';
import styles from './PublicContainer.module.scss';

const PublicContainer = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.root}>
      <Switch>
        <Route exact path={`${match.path}/:type(link)/:hash`}>
          <PublicLinkPage />
        </Route>
        <Route path="*">
          <ErrorView
            error={{
              status: 404,
            }}
            onClick={navigateToMyFiles}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default PublicContainer;
