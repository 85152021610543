import NumberingUtils from '_common/utils/NumberingUtils';
import Units from 'Editor/services/_Common/Units';
import styles from './PreviewLists.module.scss';

type ListValues = Pick<
  Editor.ListItem,
  | 'level'
  | 'start_from'
  | 'type'
  | 'incl_prev_lvls'
  | 'paragraph_style'
  | 'char_before'
  | 'char_after'
  | 'indentation_left'
  | 'indentation_right'
  | 'font_family'
  | 'list_type'
> & {
  numbering_type?: { value: string };
  special_indent?: { value: string };
  special_indent_value?: string | null;
  default?: boolean;
};

const DEFAULT_VALUES: ListValues[] = [
  {
    level: 0,
    type: '',
    start_from: undefined,
    char_before: '',
    char_after: '',
    indentation_left: `0 cm`,
    indentation_right: '0 cm',
  },
  {
    level: 1,
    type: '',
    start_from: undefined,
    char_before: '',
    char_after: '',
    indentation_left: `0 cm`,
    indentation_right: '0 cm',
  },
  {
    level: 2,
    type: '',
    start_from: undefined,
    char_before: '',
    char_after: '',
    indentation_left: `0 cm`,
    indentation_right: '0 cm',
  },
];

const LEVEL = ['8.5', '5.5', '2.5'];

type PreviewListsProps = {
  list: Editor.ListItem[];
};

const PreviewLists = ({ list }: PreviewListsProps) => {
  const linesIndex = [0, 1, 1, 2, 0];
  const previewList = DEFAULT_VALUES.map((listItem, index) => {
    const outlineLevel = list.find(
      (definition) => definition.level === index && !definition.default,
    );
    return outlineLevel || listItem;
  });

  const getBarSize: (key: 'indentation_left', i: number) => void = (
    key: 'indentation_left',
    i: number,
  ) => {
    if (i !== 0) {
      const previousIndentVal = previewList[i - 1][key];
      const currentIndentVal = previewList[i][key];

      const previousIndentValue = previousIndentVal.slice(-2);
      const currentIndentUnit = currentIndentVal.slice(-2);

      const newPreviousIndentVal = previousIndentVal.replace(/\s/g, '').slice(0, -2);
      const newCurrentIndentVal = currentIndentVal.replace(/\s/g, '').slice(0, -2);

      const newPreviousIndentInRem =
        Units.convert(previousIndentValue, Units.TYPE.REM, newPreviousIndentVal) * 0.0833;
      const newCurrentIndentInRem =
        Units.convert(currentIndentUnit, Units.TYPE.REM, newCurrentIndentVal) * 0.0833;

      if (newPreviousIndentInRem === newCurrentIndentInRem) {
        return getBarSize(key, i - 1);
      }

      return LEVEL[i];
    }

    return LEVEL[0];
  };

  const getStartFromValue = (i: number, index: number) => {
    if (previewList[i].start_from) {
      if (!Number(previewList[i].start_from)) {
        return previewList[i].start_from;
      }
      const numToRepresent =
        index === 2 || index === 4
          ? Number(previewList[i].start_from) + 1
          : previewList[i].start_from;
      return NumberingUtils.represent(previewList[i]?.numbering_type, numToRepresent);
    }

    return '';
  };

  return (
    <>
      {linesIndex.map((i, index) => {
        const value = Number(previewList[i].type);
        return (
          // eslint-disable-next-line
          <div key={index} className={styles.lineContainer}>
            <div
              className={styles.typePreview}
              style={{ fontFamily: previewList[i].font_family || '' }}
            >
              {previewList[i].type
                ? `${previewList[i].char_before}${
                    previewList[i].type ? String.fromCharCode(value) : ''
                  }${previewList[i].char_after}`
                : `${previewList[i].char_before || ''}${getStartFromValue(i, index)}${
                    previewList[i].char_after || ''
                  }`}
            </div>
            <div
              style={{ width: `${getBarSize('indentation_left', i)}rem` }}
              className={styles.linePreview}
            />
          </div>
        );
      })}
    </>
  );
};

export default PreviewLists;
