import { usePDFContext } from 'PDF/PDFContext';
import { RefObject, useEffect, useCallback } from 'react';
import {
  RichFormat,
  RichTextEditorHandler,
} from '_common/components/RichTextEditor/RichTextEditor';
import { useAnnotationContext } from './AnnotationContext';

const useRTEEventHandler = (editorRef: RefObject<RichTextEditorHandler>) => {
  const pdfManager = usePDFContext();
  const { isSelected } = useAnnotationContext();

  const handleEditTextboxStyle = useCallback(
    <F extends keyof RichFormat>({ property, value }: { property: F; value: RichFormat[F] }) => {
      switch (property) {
        case 'bold':
        case 'italic':
        case 'underline':
        case 'color':
        case 'fontfamily':
        case 'fontsize':
          if (value) {
            editorRef.current?.addMarkStyle(property, value as any);
          } else {
            editorRef.current?.removeMarkStyle(property);
          }
          break;
        case 'align':
          editorRef.current?.addBlockStyle(property, value as any);
          break;
      }
    },
    [],
  );

  useEffect(() => {
    if (isSelected) {
      pdfManager.on('EDIT_TEXTBOX', handleEditTextboxStyle);

      return () => {
        pdfManager.off('EDIT_TEXTBOX', handleEditTextboxStyle);
      };
    }
  }, [isSelected]);
};

export default useRTEEventHandler;
