import { useIntl } from 'react-intl';
import { Input, SectionHeading } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { useDispatch } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';

import { FilterPopover } from '_common/components';

export type HeaderProps = {
  searchValue?: string;
  onSearchChange: (newSearch: string) => void;
};

const HeaderContent = ({ onSearchChange, searchValue }: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleValueChange: InputProps['onChange'] = (e) => {
    onSearchChange(e.target.value);
  };

  const openFormModal = () => {
    dispatch(openModal('UserManagementFormModal'));
  };

  const openUploadModal = () => {
    dispatch(openModal('UploadUsersModal'));
  };

  return (
    <SectionHeading
      margin="3rem 0 0 0"
      title={intl.formatMessage({ id: 'spaces.users' })}
      size="large"
      search={
        <Input
          size="medium"
          prefix="NavSearchBlue"
          placeholder={intl.formatMessage({
            id: 'PLACEHOLDER_SEARCH_USERS',
          })}
          onChange={handleValueChange}
          value={searchValue}
          testId="users-header-search"
        />
      }
      searchFilters={
        <FilterPopover
          identity="tenantSettings_users"
          userPermissions={{
            options: [{ value: 'tenant_admin', label: 'ADMINISTRATOR' }],
          }}
        />
      }
      buttonLeftProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'UPLOAD_USERS' }),
        margin: '0 1rem 0 0',
        onClick: openUploadModal,
        testId: 'users-header-upload-button',
      }}
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'global.addUser' }),
        onClick: openFormModal,
        variant: 'primary',
        testId: 'users-header-add-button',
      }}
      testId="users-header"
    />
  );
};

export default HeaderContent;
