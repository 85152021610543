import { BaseTypedEmitter } from '_common/services/Realtime';
import { uniq, cloneDeep, reduce } from 'lodash';
import { ListStyleData, Structure, Template } from '../../models';
import { ListStyle, MergedListStylesData, ListStylesDictionay } from '.';

export default class ListStylesManager extends BaseTypedEmitter<{
  LIST_STYLE_ADD: (id: string, data: ListStyle) => void;
  LIST_STYLE_REMOVE: (id: string) => void;
  LIST_STYLE_UPDATE: (id: string, data: ListStyle) => void;
  LOAD_LIST_STYLES: (data: MergedListStylesData) => void;
  BEFORE_LIST_STYLE_UPDATE: () => void;
  AFTER_LIST_STYLE_UPDATE: (listStyleId: string, data: ListStyle) => void;
}> {
  private template?: Template;
  private structure?: Structure;
  private templateStyles: ListStylesDictionay = {};
  private documentStyles: ListStylesDictionay = {};
  private mergedStyles: MergedListStylesData = {};

  constructor(templateObject?: Template, structureObject?: Structure) {
    super();
    if (templateObject) {
      this.template = templateObject;
    }
    if (structureObject) {
      this.structure = structureObject;
    }
    this.setupHandlers();
  }

  private setupHandlers() {
    this.template?.on('LOADED', (data: any) => {
      this.loadTemplateStyles(this.template?.listStyles);
    });
    this.structure?.on('UPDATED', (data, ops) => {
      let element: any;
      for (
        let index = 0;
        ops && index < (ops as Array<Realtime.Core.RealtimeOps>).length;
        index++
      ) {
        element = ops[index];
        if (element.p[0] === 'l_st') {
          if (element.p.length === 2) {
            if (element.oi) {
              this.appendDocumentStyle(element.p[1], element.oi);
            } else if (element.od) {
              this.removeDocumentStyle(element.p[1]);
            }
          } else if (element.p.length > 2) {
            this.appendDocumentStyle(
              element.p[1],
              this.structure?.get(element.p) as unknown as ListStyleData,
            );
          } else {
            this.loadDocumentStyles((this.structure?.get(['l_st']) || {}) as ListStylesDictionay);
          }
        }
      }
    });
  }

  private joinStyles() {
    const newListStyles = uniq([
      ...Object.keys(this.templateStyles),
      ...Object.keys(this.documentStyles),
    ]);
    const listStylesIds = uniq([...newListStyles, ...Object.keys(this.mergedStyles)]);
    let styleId;
    for (let index = 0; index < listStylesIds.length; index++) {
      styleId = listStylesIds[index];
      if (newListStyles.includes(styleId)) {
        this.mergedStyles[styleId] = new ListStyle(
          this.templateStyles[styleId],
          this.documentStyles[styleId],
        );
      }
      if (!newListStyles.includes(styleId) && this.mergedStyles[styleId]) {
        delete this.mergedStyles[styleId];
      }
    }
    return newListStyles;
  }

  get loaded() {
    return this.structure?.loaded && this.template?.loaded;
  }

  bindToTemplate(templateObject?: Template) {
    if (templateObject) {
      this.template = templateObject;
      this.template.on('LOADED', (data: any) => {
        this.loadTemplateStyles(this.template?.listStyles);
      });
      if (this.template?.loaded) {
        this.templateStyles = cloneDeep(this.template?.listStyles);
      }
    }
    return this;
  }

  bindToStructure(structureObject?: Structure) {
    if (structureObject) {
      this.structure = structureObject;
      this.structure.on('LOADED', (data: any) => {
        this.loadDocumentStyles((this.structure?.get(['l_st']) || {}) as ListStylesDictionay);
      });
      this.structure.on('UPDATED', (data, ops) => {
        let element: any;
        for (
          let index = 0;
          ops && index < (ops as Array<Realtime.Core.RealtimeOps>).length;
          index++
        ) {
          element = ops[index];
          if (element.p[0] === 'l_st') {
            if (element.p.length === 2) {
              if (element.oi) {
                this.appendDocumentStyle(element.p[1], element.oi);
              } else if (element.od) {
                this.removeDocumentStyle(element.p[1]);
              }
            } else if (element.p.length > 2) {
              this.appendDocumentStyle(
                element.p[1],
                this.structure?.get(element.p) as unknown as ListStyleData,
              );
            } else {
              this.loadDocumentStyles((this.structure?.get(['l_st']) || {}) as ListStylesDictionay);
            }
          }
        }
      });
      if (this.structure?.loaded) {
        this.documentStyles = cloneDeep(this.structure?.get(['l_st']));
      }
    }
    return this;
  }

  start() {
    this.joinStyles();
    this.loadStyles();
  }

  style(styleId?: string) {
    return styleId ? this.mergedStyles[styleId] || null : null;
  }

  styles() {
    return this.mergedStyles;
  }

  listStyleExists(styleId: string) {
    return !!this.mergedStyles[styleId];
  }

  private loadStyles() {
    this.emit('LOAD_LIST_STYLES', this.mergedStyles);
  }

  private loadTemplateStyles(templateStyles: ListStylesDictionay) {
    this.templateStyles = templateStyles;
    this.joinStyles();
    this.loadStyles();
  }

  private loadDocumentStyles(documentStyles: ListStylesDictionay) {
    this.documentStyles = cloneDeep(documentStyles);
    this.joinStyles();
    this.loadStyles();
  }

  private appendDocumentStyle(styleId: string, styleData: ListStyleData) {
    const styleExists = !!this.mergedStyles[styleId];

    this.documentStyles[styleId] = cloneDeep(styleData);

    if (styleExists) {
      this.mergedStyles[styleId].loadDocumentStyle(styleData);
      this.emit('LIST_STYLE_UPDATE', styleId, this.mergedStyles[styleId]);
    } else {
      this.mergedStyles[styleId] = new ListStyle(
        this.templateStyles[styleId],
        this.documentStyles[styleId],
      );
      this.emit('LIST_STYLE_ADD', styleId, this.mergedStyles[styleId]);
    }
  }

  private removeDocumentStyle(styleId: string) {
    const styleExists = !!this.mergedStyles[styleId];
    delete this.documentStyles[styleId];

    if (styleExists) {
      this.mergedStyles[styleId].loadDocumentStyle();
    }

    if (this.templateStyles[styleId]) {
      this.emit('LIST_STYLE_UPDATE', styleId, this.mergedStyles[styleId]);
    } else {
      delete this.mergedStyles[styleId];
      this.emit('LIST_STYLE_REMOVE', styleId);
    }
  }

  parsedFrontStyles() {
    return reduce(
      this.mergedStyles,
      (result: any, value: ListStyle, key: string) => {
        result[key] = value.parseFront();
        return result;
      },
      {},
    );
  }

  async createListStyle(style: Editor.Data.Structure.StyleFrontOutline, id?: string) {
    const listStyleId = id || ListStyle.generateStyleId();
    await this.structure?.set(['l_st', listStyleId], ListStyle.parseBack(style));
    return listStyleId;
  }

  //TODO: update type
  async updateListStyle(styleId: string, style: Editor.Data.Structure.StyleFrontOutline) {
    await this.structure?.set(['l_st', styleId], ListStyle.parseBack(style));
    const listStyle = this.style(styleId);
    if (listStyle) {
      this.emit('AFTER_LIST_STYLE_UPDATE', styleId, listStyle);
    }
  }

  destroy() {
    super.destroy();
  }
}
