import { NodeUtils } from 'Editor/services/DataManager';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

export class InsertRowOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  rowsIndex: number[];
  selectedCells: Editor.Edition.CellInfo[];
  before: boolean;
  rowHeigth?: string | number;
  mergeUnselectedCells?: boolean;
  rowToInsert?: Editor.Data.Node.Data;
  headCellToUpdate: {
    [index: string]: {
      properties: { rs: number };
    };
  } = {};
  refRow?: Editor.Data.Node.Data;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
    rowsIndex: number[],
    {
      before,
      rowHeigth,
      mergeUnselectedCells = false,
      rowToInsert,
    }: {
      before: boolean;
      rowHeigth?: string | number;
      mergeUnselectedCells?: boolean;
      rowToInsert?: Editor.Data.Node.Data;
    },
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.rowsIndex = rowsIndex.length ? rowsIndex : TableUtils.getRowsIndex(this.selectedCells);
    this.before = before;
    this.rowHeigth = rowHeigth;
    this.mergeUnselectedCells = mergeUnselectedCells;
    this.rowToInsert = rowToInsert;
    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.rowsIndex) {
      const baseData = this.model.selectedData();

      if (!baseData) {
        return this;
      }

      const newRowData = this.getNewRowData(baseData);

      if (newRowData && this.refRow) {
        const refRowPath = this.model.findPath(this.model.KEYS.ID, this.refRow.id);
        refRowPath.pop(); // remove path id

        if (!this.before) {
          refRowPath[refRowPath.length - 1] = +refRowPath[refRowPath.length - 1] + 1; // increment index
        }

        this.ops.push(RealtimeOpsBuilder.listInsert(newRowData, refRowPath));

        // build ops for updated headcells
        const keyIds = Object.keys(this.headCellToUpdate);

        for (let k = 0; k < keyIds.length; k++) {
          const updatedData = this.headCellToUpdate[keyIds[k]];

          const headCellPropPath = this.model.findPath(this.model.KEYS.ID, keyIds[k]);
          headCellPropPath.pop(); // remove path id
          headCellPropPath.push(this.model.KEYS.PROPERTIES); // path to properties
          headCellPropPath.push('rs');

          if (updatedData.properties.rs > 0) {
            const op = this.model.buildOp(headCellPropPath, updatedData.properties.rs);
            if (op != null) {
              this.ops.push(op);
            }
          }
        }
      }
    }
    return this;
  }

  getNewRowData(baseData: Editor.Data.Node.Data) {
    const tableInfo = this.model.getChildInfoById(this.tableId);

    const table: Editor.Data.Node.Data = tableInfo.data;
    const tableBody = table.childNodes?.[0];

    const selectedCellIds: string[] = [];

    for (let i = 0; i < this.selectedCells.length; i++) {
      selectedCellIds.push(this.selectedCells[i].id);
    }

    if (tableBody && tableBody.childNodes?.length) {
      this.refRow = this.before
        ? tableBody?.childNodes[this.rowsIndex[0]]
        : tableBody?.childNodes[this.rowsIndex[this.rowsIndex.length - 1]];

      let newRowData: Editor.Data.Node.Data | undefined;
      if (this.rowToInsert != null) {
        newRowData = { ...this.rowToInsert, parent_id: this.refRow.parent_id };
      } else {
        const newRowBuilder = new NodeDataBuilder(
          ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE,
        );

        const parentId = this.refRow.parent_id;
        if (parentId) {
          newRowBuilder.addKeyValue('parent_id', parentId);
        }

        const rh = this.rowHeigth != null ? this.rowHeigth : this.refRow.properties?.rh;

        newRowBuilder.addProperty('rh', rh);

        if (this.refRow.childNodes) {
          for (let j = 0; j < this.refRow.childNodes.length; j++) {
            const cell = this.refRow.childNodes[j];

            if (NodeUtils.isTableCellData(cell)) {
              cell.properties.rs = cell.properties.rs ? cell.properties.rs : 1;
              cell.properties.cs = cell.properties.cs ? cell.properties.cs : 1;

              const clonedProperties = { ...cell.properties };
              delete clonedProperties['head-id'];
              delete clonedProperties.d;
              delete clonedProperties.cs;
              delete clonedProperties.rs;

              const cellPath = this.model.findPath('id', cell.id);

              const cellBuilder = new NodeDataBuilder(ELEMENTS.TableCellElement.ELEMENT_TYPE);

              cellBuilder.addKeyValue('parent_id', newRowBuilder.getId());
              cellBuilder.properties = clonedProperties;

              if (cell.properties?.['head-id']) {
                const headCellPath = this.model.findPath('id', cell.properties?.['head-id']);

                const headCell = NodeUtils.getChildDataByPath(baseData, headCellPath);

                const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);
                const cellIndexs = NodeUtils.getCurrentAndParentIndexByPath(cellPath);

                if (
                  headCell?.id &&
                  headCell.properties &&
                  headCellIndexs.currentIndex != null &&
                  headCellIndexs.parentIndex != null
                ) {
                  headCell.properties.rs = headCell.properties.rs ? headCell.properties.rs : 1;
                  headCell.properties.cs = headCell.properties.cs ? headCell.properties.cs : 1;

                  if (headCellIndexs.parentIndex === cellIndexs.parentIndex) {
                    if (!this.before && headCell?.properties?.rs > 1) {
                      cellBuilder.addProperty('head-id', headCell.id);
                      cellBuilder.addProperty('d', false);

                      if (!this.headCellToUpdate[headCell.id]) {
                        // add headcell to update
                        this.headCellToUpdate[headCell.id] = {
                          properties: { rs: +headCell?.properties?.rs + 1 },
                        };
                      }
                    } else if (headCell?.properties?.cs > 1) {
                      cellBuilder.addProperty(
                        'head-id',
                        newRowBuilder.getChild(headCellIndexs.currentIndex)?.id,
                      );
                      cellBuilder.addProperty('d', false);
                    }
                  } else if (
                    cell.id &&
                    headCellIndexs.parentIndex + +headCell?.properties?.rs - 1 ===
                      cellIndexs.parentIndex &&
                    !(this.mergeUnselectedCells && !selectedCellIds.includes(cell.id))
                  ) {
                    if (!this.before && headCell?.properties?.cs > 1) {
                      if (headCellIndexs.currentIndex === cellIndexs.currentIndex) {
                        cellBuilder.addProperty('cs', headCell?.properties?.cs);
                      } else {
                        cellBuilder.addProperty(
                          'head-id',
                          newRowBuilder.getChild(headCellIndexs.currentIndex)?.id,
                        );
                        cellBuilder.addProperty('d', false);
                      }
                    }
                    if (this.before) {
                      cellBuilder.addProperty('head-id', headCell.id);
                      cellBuilder.addProperty('d', false);

                      if (!this.headCellToUpdate[headCell.id]) {
                        // add headcell to update
                        this.headCellToUpdate[headCell.id] = {
                          properties: { rs: +headCell?.properties?.rs + 1 },
                        };
                      }
                    }
                  } else {
                    cellBuilder.addProperty('head-id', headCell.id);
                    cellBuilder.addProperty('d', false);

                    if (!this.headCellToUpdate[headCell.id]) {
                      // add headcell to update
                      this.headCellToUpdate[headCell.id] = {
                        properties: { rs: +headCell.properties?.rs + 1 },
                      };
                    }
                  }
                }
              } else if (
                cell &&
                cell.id &&
                !this.before &&
                (cell.properties?.rs > 1 ||
                  (this.mergeUnselectedCells && !selectedCellIds.includes(cell.id)))
              ) {
                cellBuilder.addProperty('head-id', cell.id);
                cellBuilder.addProperty('d', false);

                if (!this.headCellToUpdate[cell.id]) {
                  // add headcell to update
                  this.headCellToUpdate[cell.id] = {
                    properties: { rs: +cell.properties.rs + 1 },
                  };
                }
              } else if (cell.properties.cs > 1) {
                cellBuilder.addProperty('cs', cell.properties.cs);
              }

              if (!cellBuilder.getProperties()?.['head-id']) {
                const firstChild = cell.childNodes?.[0];
                let properties = {};

                if (
                  firstChild?.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE &&
                  firstChild?.properties
                ) {
                  properties = firstChild?.properties;
                }

                // add a paragraph to cell
                const childData = NodeDataBuilder.buildData({
                  type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                  parent_id: cellBuilder.getId(),
                  properties,
                });
                if (childData) {
                  cellBuilder.addChildData(childData);
                }
              }

              const cellData = cellBuilder.build();
              if (cellData) {
                newRowBuilder.addChildData(cellData);
              }
            }
          }
        }
        newRowData = newRowBuilder.build();
      }
      return newRowData;
    }
  }
}
