import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { uniq } from 'lodash';
import { Modal, Button, Icon } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useSelector } from '_common/hooks';
import { getDocumentObject, selectDocumentUsers } from 'Editor/redux/EditorStatusSlice';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import { SearchUser, UserCard } from '_common/components';

import styles from './TaskWatchModal.module.scss';

const TaskWatchModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.TaskWatchModal);
  const task = useSelector((state) => state.editor.tasks.tasks[state.modals.TaskWatchModal.taskId]);
  const document = useSelector(getDocumentObject);
  const usersList = useSelector(selectDocumentUsers);

  const [selectedWatchers, setSelectedWatchers] = useState<
    {
      value: string;
      label: string;
      type: string;
    }[]
  >([]);

  const watchers = useMemo(() => {
    return task.w
      .filter((id) => id !== task.asg && id !== task.u)
      .map((id) => ({ value: id, label: id, type: 'user' }));
  }, [task.w, task.asg, task.u]);

  const possibleWatchers = useMemo(() => {
    return uniq([...document.owners, ...usersList])
      .filter(
        (id) =>
          id !== task.asg && id !== task.u && !watchers.some((watcher) => watcher.value === id),
      )
      .map((id) => ({ id, type: 'user' }));
  }, [document.owners, usersList, task.asg, task.u, watchers]);

  const close = () => {
    dispatch(closeAndResetModal('TaskWatchModal'));
  };

  const handleRemoveWatch = (user: string) => {
    EditorManager.getInstance().removeWatchFromTask(task.id, user);
  };

  if (!task) {
    if (isOpen) {
      close();
    }
    return null;
  }

  return (
    <Modal open={!!isOpen} onClose={close} width="54rem" testId="task-watch">
      <Modal.Header onClose={close}>
        <FormattedMessage id="TASK_WATCH_MODAL_HEADER" values={{ number: task.i }} />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <SearchUser
          escapeClearsValue
          value={selectedWatchers}
          onChange={(users, actionMeta) => {
            setSelectedWatchers(users);
            if (actionMeta?.action === 'select-option' && actionMeta?.option) {
              EditorManager.getInstance().watchTask(task.id, actionMeta.option.value);
            } else if (actionMeta?.action === 'deselect-option' && actionMeta.option) {
              EditorManager.getInstance().removeWatchFromTask(task.id, actionMeta.option.value);
            } else if (actionMeta?.action === 'remove-value' && actionMeta.removedValue) {
              EditorManager.getInstance().removeWatchFromTask(
                task.id,
                actionMeta.removedValue.value,
              );
            } else if (actionMeta?.action === 'clear' && actionMeta.removedValues) {
              actionMeta.removedValues.forEach((removedWatcher) => {
                EditorManager.getInstance().removeWatchFromTask(task.id, removedWatcher.value);
              });
            }
          }}
          onMenuClose={() => {
            setSelectedWatchers([]);
          }}
          size="medium"
          placeholder={intl.formatMessage({ id: 'USER_OR_EMAIL_ADDRESS' })}
          width="100%"
          menuPosition="fixed"
          options={possibleWatchers}
          editor
          isMulti
          searchable
          testId="task-watch"
        />
        <div className={styles.label}>
          <FormattedMessage id="TASK_WATCH_MODAL_LABEL" />:
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <UserCard userId={task.u} />
            <div className={styles.user_label}>
              <FormattedMessage id="TASK_CREATOR" />
            </div>
          </div>
          {task.asg && (
            <div className={styles.row}>
              <UserCard userId={task.asg} />
              <div className={styles.user_label}>
                <FormattedMessage id="TASK_ASSIGNEE" />
              </div>
            </div>
          )}
          {task.w &&
            task.w.length > 0 &&
            task.w
              .filter((id: UserId) => id !== task.asg && id !== task.u)
              .map((id: UserId) => (
                <div className={styles.row} key={id}>
                  <UserCard userId={id} editor />
                  <div style={{ cursor: 'pointer' }} onClick={() => handleRemoveWatch(id)}>
                    <Icon icon="CloseGrey" size={24} />
                  </div>
                </div>
              ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="task-watch-modal-done-button">
          <FormattedMessage id="DONE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskWatchModal;
