import { useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Select, Button, Icon, Divider } from 'dodoc-design-system';
import { uniq } from 'lodash';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { clearFilterIdentity, selectHasFilters } from '_common/components/Filters/FilterSlice';
import { selectIsPageLayout, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';

import { PanelContent, PanelHeading } from '_common/suite/components';
import { FilterDisplay, FilterPopover } from '_common/components';

import styles from './CrossReferencesTab.module.scss';

const CrossReferencesTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const list = useSelector((state) => Object.keys(state.editor.toc.data));
  const data = useSelector((state) => state.editor.toc.data);
  const isReadOnlyMode = useSelector((state) => selectReadOnlyMode(state));
  const isPageLayout = useSelector(selectIsPageLayout);
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorDocCaption),
  );
  const filters = useSelector((state) => state.filters.editorDocCaption);

  const captionsFormats = [
    {
      value: 'lnt',
      label: intl.formatMessage({ id: 'ENTIRE_CAPTION' }),
    },
    {
      value: 'ln',
      label: intl.formatMessage({ id: 'ONLY_LABEL_AND_NUMBER' }),
    },
    {
      value: 't',
      label: intl.formatMessage({ id: 'ONLY_CAPTION_TEXT' }),
    },
    {
      value: 'pn',
      label: intl.formatMessage({ id: 'PAGE_NUMBER' }),
    },
    {
      value: 'cab',
      label: intl.formatMessage({ id: 'ABOVE_BELOW' }),
    },
  ];
  const headingsFormats = [
    {
      value: 't',
      label: intl.formatMessage({ id: 'HEADING_TEXT' }),
    },
    {
      value: 'hn',
      label: intl.formatMessage({ id: 'HEADING_NUMBER' }),
    },
    {
      value: 'hnn',
      label: intl.formatMessage({ id: 'HEADING_NUMBER_NO_CONTEXT' }),
    },
    {
      value: 'hnf',
      label: intl.formatMessage({ id: 'HEADING_NUMBER_FULL_CONTEXT' }),
    },
    {
      value: 'hab',
      label: intl.formatMessage({ id: 'ABOVE_BELOW' }),
    },
  ];
  const [captionSelected, setCaptionSelected] = useState<{ value: string; label: string }>({
    value: '',
    label: '',
  });
  const [typeSelected, setTypeSelected] = useState({
    value: 'caption',
    label: intl.formatMessage({ id: 'editor.toolbar.contentStructure.CAPTION' }),
  });
  const [formatSelected, setFormatSelected] = useState(
    typeSelected.value === 'caption' ? captionsFormats[0] : headingsFormats[0],
  );
  const [checkInsertAsLink, setCheckInsertAsLink] = useState(true);
  useEffect(() => {
    setCaptionSelected({
      value: '',
      label: '',
    });
    if (typeSelected.value === 'heading') {
      dispatch(clearFilterIdentity({ identity: 'editorDocCaption', noFeedback: true }));
    }
    setFormatSelected(typeSelected.value === 'caption' ? captionsFormats[0] : headingsFormats[0]);
  }, [typeSelected]);
  const captionsList: { value: string; label: string }[] = useMemo(() => {
    const filterValues = {
      captionLabel: filters.captionLabel?.map((filterValue) => filterValue.value),
    };

    return uniq(list)
      .filter((id) => {
        if (data[id]) {
          if (typeSelected.value === 'heading') {
            return data[id].type === 'p' && data[id].st !== 't';
          }
          if (typeSelected.value === 'caption') {
            //If no active filters, accept all valid captions
            if (!hasActiveFilters) {
              return data[id].type === 'f';
            }

            //If has filters, accept only those which label is same as selected value
            const captionLabel = data[id].label;
            return (
              captionLabel && filterValues.captionLabel?.includes(captionLabel.toLocaleLowerCase())
            );
          }
        }
        return false;
      })
      .reduce((previous: { value: string; label: string }[], current: string) => {
        previous.push({ label: data[current].content, value: data[current].target });
        return previous;
      }, []);
  }, [list, data, typeSelected.value, filters]);
  const selectCaption = (option: { value: string; label: string }) => {
    setCaptionSelected(option);
  };

  const handlePreviewFromFormat = () => {
    if (captionSelected && captionSelected.label) {
      if (
        typeSelected.value === 'heading' &&
        formatSelected.value === 'hn' &&
        !EditorManager.getInstance().hasStyleAList(data[captionSelected.value.split(':')[1]]?.st)
      ) {
        return undefined;
      }
      return (
        EditorManager.getInstance().getCrossReferencePresentationText({
          target: captionSelected.value,
          format: formatSelected.value,
          origin: EditorManager.getInstance().getSelectedLevel0Nodes()?.[0],
        }) || undefined
      );
    }
    return intl.formatMessage({ id: 'CROSS_REFERENCE_PREVIEW' });
  };

  const validateFormatReference = () => {
    return !handlePreviewFromFormat();
  };

  const handleInsert = () => {
    const options: Editor.Data.CrossReferences.PresentationTextOptionsType = {
      label: typeSelected.value,
      link: checkInsertAsLink,
      //@ts-expect-error fix types
      format: formatSelected.value,
      //@ts-expect-error fix types
      target: captionSelected.value,
    };
    EditorManager.getInstance().insertCrossReference(options);
  };

  const defaultLabels = [
    {
      value: 'caption',
      label: intl.formatMessage({ id: 'editor.toolbar.contentStructure.CAPTION' }),
    },
    { value: 'heading', label: intl.formatMessage({ id: 'HEADING' }) },
  ];

  const getEmptyTitle = () => {
    if (typeSelected.value === 'heading') {
      return 'NO_HEADINGS';
    }
    if (list.length === 0) {
      return 'NO_CAPTIONS';
    }
    return 'NO_CAPTIONS_FOUND';
  };

  const getEmptyMessage = () => {
    if (typeSelected.value === 'heading') {
      return 'NO_HEADINGS_MESSAGE';
    }
    if (list.length === 0) {
      return 'NO_CAPTIONS_MESSAGE';
    }
    return 'NO_CAPTIONS_FOUND_MESSAGE';
  };
  return (
    <>
      <PanelHeading>
        <FormattedMessage id="CROSS_REFERENCES" />
      </PanelHeading>
      <PanelContent testId="cross-references-panel">
        <div className={styles.root}>
          <div className={styles.rootPreview}>
            <div className={styles.title}>{intl.formatMessage({ id: 'PREVIEW' })}</div>
            <Divider margin="0 0 2rem 0" />
            <div
              className={`${styles.previewCrossReference} ${
                validateFormatReference() && styles.noSupport
              }`}
            >
              {validateFormatReference() ? (
                <div className={styles.warningState}>
                  <Icon icon="Warning" size={32} />
                  <div className={`${styles.text} ${styles.empty}`}>
                    {intl.formatMessage({ id: 'EMPTY_CROSS_REFERENCE' })}
                  </div>
                </div>
              ) : (
                <div
                  title={handlePreviewFromFormat()}
                  className={`${
                    captionSelected && captionSelected.label ? styles.text : styles.noPreview
                  }`}
                >
                  {handlePreviewFromFormat()}
                </div>
              )}
            </div>
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <div className={styles.title}>{intl.formatMessage({ id: 'REFERENCE_TYPE' })}</div>
            <Divider margin="0 0 2rem 0" />
            <Select
              options={defaultLabels}
              value={defaultLabels.find((option) => option.value === typeSelected.value)}
              onChange={setTypeSelected}
              size="large"
              width="100%"
              clearable={false}
              testId="cross-references-panel-reference-type"
            />
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <div className={styles.title}> {intl.formatMessage({ id: 'REFERENCE_FORMAT' })}</div>
            <Divider margin="0 0 2rem 0" />
            <Select
              options={typeSelected.value === 'caption' ? captionsFormats : headingsFormats}
              size="large"
              value={
                typeSelected.value === 'caption'
                  ? captionsFormats.find((option) => option.value === formatSelected.value)
                  : headingsFormats.find((option) => option.value === formatSelected.value)
              }
              onChange={setFormatSelected}
              width="100%"
              clearable={false}
              testId="cross-references-panel-reference-format"
            />
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className={styles.title}>
                {intl.formatMessage({
                  id: typeSelected.value === 'caption' ? 'DOCUMENT_CAPTIONS' : 'DOCUMENT_HEADINGS',
                })}
              </div>
              {typeSelected.value === 'caption' && (
                <FilterPopover
                  identity="editorDocCaption"
                  popperSettings={{ placement: 'bottom-start' }}
                  toggleVariant="link"
                  captionLabel={{
                    options: [
                      { value: 'table', label: 'TABLE' },
                      { value: 'equation', label: 'EQUATION' },
                      { value: 'figure', label: 'FIGURE' },
                    ],
                  }}
                />
              )}
            </div>
            <Divider margin="0 0 2rem 0" />
            <FilterDisplay identity="editorDocCaption" direction="column" margin="0 0 2rem 0" />
            <div
              className={`${captionsList.length > 0 ? styles.captionsList : styles.emptyList}`}
              data-testid="cross-references-panel-document-captions-list"
            >
              {captionsList.length > 0 &&
                captionsList.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => selectCaption(option)}
                    className={`${styles.captionElement} ${
                      captionSelected && captionSelected.value === option.value && styles.selected
                    }`}
                    data-testid={`${option.label}-document-caption`}
                  >
                    {option.label}
                  </div>
                ))}
              {captionsList.length === 0 && (
                <div className={styles.emptyCaptionsList}>
                  <div className={styles.emptyCaptionsListTitle}>
                    {intl.formatMessage({ id: getEmptyTitle() })}
                  </div>
                  <div className={styles.emptyCaptionsListMessage}>
                    {intl.formatMessage({ id: getEmptyMessage() })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <Checkbox
              size="small"
              checked={checkInsertAsLink ? 'checked' : 'unchecked'}
              onChange={() => {
                setCheckInsertAsLink(!checkInsertAsLink);
              }}
              testId="cross-references-panel-insert-as-link-checkbox"
            >
              <span className={styles.checkboxLabel}>
                {intl.formatMessage({ id: 'INSERT_AS_LINK' })}
              </span>
            </Checkbox>
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={() => {
                dispatch(setSidebarView(null));
              }}
              size="medium"
              testId="cross-references-panel-cancel-button"
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              variant="primary"
              size="medium"
              margin="0 0 0 1rem"
              onClick={handleInsert}
              disabled={
                !(captionSelected && captionSelected.label) ||
                validateFormatReference() ||
                isReadOnlyMode ||
                isPageLayout
              }
              testId="cross-references-panel-insert-button"
            >
              <FormattedMessage id="global.insert" />
            </Button>
          </div>
        </div>
      </PanelContent>
    </>
  );
};

export default CrossReferencesTab;
