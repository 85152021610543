import type { AuthFooterProps } from 'Auth/components/AuthFooter/AuthFooter';
import styles from './Copyright.module.scss';
/**
 * Displays the copyright string
 *
 * @author    José Nunes <jnunes@dodoc.com>
 * @copyright 2017 doDOC
 */
const Copyright = ({ hero, white }: AuthFooterProps) => (
  <span
    className={`${styles.root} ${hero ? styles.hero : undefined} ${
      white ? styles.white : undefined
    }`}
  >{`© doDOC ${new Date().getFullYear()}`}</span>
);

export default Copyright;
