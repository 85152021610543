import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Button, Modal, Divider, Input, EmptyState } from 'dodoc-design-system';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { useDebounce, usePublicProfiles } from '_common/hooks';
import UserCard from '_common/components/UserCard/UserCard';
import styles from './LikesModal.module.scss';

import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

const MODAL = 'LikesModal';

export type LikeParent = 'comment' | 'change' | 'reply' | 'annotation';

const PLACEHOLDER: { [key in LikeParent]: string } = {
  comment: 'SEARCH_USERS_WHO_LIKED_THIS_COMMENT',
  change: 'SEARCH_USERS_WHO_LIKED_THIS_CHANGE',
  reply: 'SEARCH_USERS_WHO_LIKED_THIS_REPLY',
  annotation: 'SEARCH_USERS_WHO_LIKED_THIS_COMMENT',
};

const LikesModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const votes = useSelector((state) => state.modals[MODAL].votes);
  const type = useSelector((state) => state.modals[MODAL].type);
  //@ts-expect-error vote is any because votes is any: Should be fixed once modal props have types
  const { profiles } = usePublicProfiles(votes?.map((vote) => vote.user));

  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<typeof votes>([]);
  const debouncedSearch = useDebounce(search, 250);

  useMemo(() => {
    //@ts-expect-error {user} is any because votes is any: Should be fixed once modal props have types
    const filteredUsers = votes?.filter(({ user }) =>
      profiles[user]?.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );
    if (filteredUsers) {
      //@ts-expect-error (a, b) are any because filteredUsers is any: Should be fixed once modal props have types
      filteredUsers.sort((a, b) => new Date(a.time) - new Date(b.time));
      setFilteredUsers(filteredUsers);
    }
  }, [debouncedSearch]);

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (isOpen && votes) {
      if (votes.length > 0) {
        setFilteredUsers(votes);
      }
    }
  }, [isOpen, votes]);

  const close = () => {
    setFilteredUsers([]);
    setSearch('');
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="60rem" height="60rem" open={!!isOpen} onClose={close} testId="likes">
      <Modal.Header onClose={close}>
        <FormattedMessage id="LIKES_ON_CARD" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.search}>
          <Input
            prefix="NavSearchBlue"
            size="large"
            placeholder={
              type in PLACEHOLDER
                ? intl.formatMessage({ id: PLACEHOLDER[type as keyof typeof PLACEHOLDER] })
                : ''
            }
            value={search}
            onChange={handleSearchChange}
            testId="likes-search"
          />
        </div>

        <div className={styles.container}>
          {filteredUsers.length < 1 && search !== '' && (
            <EmptyState
              size="large"
              icon="NoSearchResults"
              title={intl.formatMessage({
                id: 'NO_USERS_FOUND',
              })}
              testId="no-users-found"
            >
              <FormattedMessage id="NO_USERS_WHO_LIKED_MATCH_FILTER" />
            </EmptyState>
          )}
          {filteredUsers.length > 0 && (
            <div className={styles.list}>
              <Divider />
              {/*@ts-expect-error user is any because filteredUsers is any: Should be fixed once modal props have types*/}
              {filteredUsers.map((user) => (
                <div key={user.user} className={styles.row}>
                  <div className={styles.item}>
                    <UserCard userId={user.user} editor size="large" />
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="likes-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LikesModal;
