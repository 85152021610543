import { ELEMENTS } from 'Editor/services/consts';
import ViewModelValidations from 'Editor/services/VisualizerManager/ViewModels/ViewModelValidations';
import { EditorDOMElements, EditorDOMUtils } from '../../DOM';
import { EditorRange } from '../EditorRange';
import { NodeUtils } from 'Editor/services/DataManager';
import { PathUtils } from './PathUtils';
import { NodeDataIterator } from 'Editor/services/DataManager/models/Node/NodeDataIterator';
import { ErrorInvalidPath } from 'Editor/services/Edition_v2/Errors';
import EditorManager from 'Editor/services/EditorManager';

export class JsonRange
  implements Editor.Selection.RangeData, Editor.Selection.IAcceptEditorVisitor
{
  start: Editor.Selection.Position;
  end: Editor.Selection.Position;

  private debug: boolean = true;

  static getPositionFromNodeOffset(
    container: Node,
    containerOffset: number,
    ancestorContainer?: Node | null,
  ): Editor.Selection.Position | null {
    if (!ancestorContainer) {
      ancestorContainer = EditorDOMUtils.getContentContainer(container);
    }

    const closestApprovedElement = EditorDOMUtils.closest(container, ELEMENTS.ApprovedElement.TAG);
    if (EditorDOMElements.isApprovedElement(closestApprovedElement)) {
      ancestorContainer = closestApprovedElement.contentContainer;
    }

    let node: Node | null = container;
    let offset: number = containerOffset;

    let closest: Node | null;

    let block: Editor.Visualizer.BaseView | null = null;

    if (node === ancestorContainer) {
      if (containerOffset > 0 && containerOffset <= node.childNodes.length) {
        node = node.childNodes[containerOffset - 1];
        offset = node.childNodes.length;
      } else {
        node = node.childNodes[0];
        offset = 0;
      }
    } else {
      let childToCheck = node;
      // check if container is a frontend only node
      while (
        (closest = EditorDOMUtils.closest(
          node,
          EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS,
          ancestorContainer,
        ))
      ) {
        if (closest.parentNode) {
          if (EditorDOMUtils.isAtEndOfNode(closest, childToCheck, offset)) {
            offset = Array.from(closest.parentNode.childNodes).indexOf(closest as ChildNode) + 1;
          } else {
            offset = Array.from(closest.parentNode.childNodes).indexOf(closest as ChildNode);
          }
          childToCheck = closest;
          node = closest.parentNode;
        }
      }
    }

    if (
      (node === ancestorContainer || node?.parentNode === ancestorContainer) &&
      node instanceof HTMLElement
    ) {
      block = node;
    } else {
      block = EditorDOMUtils.findFirstLevelChildNode(
        ancestorContainer,
        node,
      ) as Editor.Visualizer.BaseView;
    }

    if (block) {
      let jsonPosition: Editor.Selection.Position = {
        b: block.id,
        p: [],
      };

      if (node != null && node !== block) {
        // push initial offset
        if (node instanceof Text) {
          jsonPosition.p.push(offset);
        } else {
          // adjust offset for sibling frontend only nodes
          const childNodes = node.childNodes;
          let checkOffset = offset;
          for (let i = 0; i < childNodes.length; i++) {
            const child = childNodes[i];
            if (
              child instanceof Element &&
              EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.nodeName) &&
              i <= checkOffset &&
              offset > 0
            ) {
              offset -= 1;
            }
          }

          jsonPosition.p.push(offset);
        }

        while (node != null && block.contains(node)) {
          if (node instanceof Text) {
            jsonPosition.p.unshift('content');
          }

          if (node instanceof Element) {
            jsonPosition.p.unshift('childNodes');
          }

          if (node.parentNode && node !== block) {
            const parentChildNodes = node.parentNode.childNodes as NodeListOf<Node>;

            let index = Array.from(parentChildNodes).indexOf(node);
            let checkOffset = index;
            // adjust offset for sibling frontend only nodes
            for (let i = 0; i < parentChildNodes.length; i++) {
              const child = parentChildNodes[i];
              if (
                child instanceof Element &&
                EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.nodeName) &&
                i <= checkOffset &&
                index > 0
              ) {
                index -= 1;
              }
            }

            jsonPosition.p.unshift(index);
          }

          node = node.parentNode;
        }
      } else {
        if (offset >= 0 && offset <= block.childNodes.length) {
          let checkOffset = offset;
          // adjust offset for sibling frontend only nodes
          for (let i = 0; i < block.childNodes.length; i++) {
            const child = block.childNodes[i];
            if (
              block.nodeName === ELEMENTS.ParagraphElement.TAG &&
              child instanceof Element &&
              EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(child.nodeName) &&
              i <= checkOffset &&
              offset > 0
            ) {
              offset -= 1;
            }
          }

          jsonPosition.p = ['childNodes', offset];
        }
      }

      // handle view split points
      const viewModel = block.vm;
      if (
        jsonPosition.p.length > 0 &&
        ViewModelValidations.isBlockViewModel(viewModel) &&
        viewModel.hasSplitViews()
      ) {
        let transformedPath = viewModel.transformPathWithSplitPoints(block, jsonPosition.p, 'ADD');
        if (transformedPath) {
          jsonPosition.p = transformedPath;
        }
      }

      return jsonPosition;
    }

    return null;
  }

  static getNodeOffsetFromPosition(
    position: Editor.Selection.Position,
    options?: Editor.Selection.SerializeToDOMRangeOptions,
  ) {
    const blockNode = document.getElementById(position.b) as Editor.Visualizer.BaseView;

    // temporary solution to get view model
    let blockViewModel = EditorManager.getInstance().visualizerManager?.getViewModelById(
      blockNode.id,
    );

    if (!blockViewModel) {
      blockViewModel = blockNode?.vm;
    }

    if (ViewModelValidations.isBlockViewModel(blockViewModel)) {
      const splitViews = blockViewModel.splitViews;
      if (splitViews.length) {
        let data;
        let path: Editor.Selection.Path | null = position.p;
        for (let i = 0; i < splitViews.length; i++) {
          path = PathUtils.transformPath(path, splitViews[i].splitPoint);
          if (path) {
            data = JsonRange.getNodeOffsetFromViewPath(
              splitViews[i].view,
              path,
              options,
              splitViews.length > 1,
            );
          }

          if (data && data.node != null && data.offset != null) {
            return data;
          }
        }
      } else {
        return JsonRange.getNodeOffsetFromViewPath(blockNode, position.p, options);
      }
    }

    return {};
  }

  static getNodeOffsetFromViewPath(
    view: Editor.Visualizer.BaseView,
    path: Realtime.Core.RealtimePath,
    options: Editor.Selection.SerializeToDOMRangeOptions = {},
    isSplitView: boolean = false,
  ) {
    if (path) {
      let node: Node | null = view;
      let offset: number | null = null;

      if (node) {
        let lastKey: string | number | null = null;

        for (let i = 0; i < path.length; i++) {
          const key = path[i];

          if ((lastKey === 'childNodes' || lastKey === 'content') && !isNaN(+key)) {
            if (lastKey === 'childNodes' && node instanceof Element) {
              if (+key <= node.childNodes.length) {
                let childNodes: NodeListOf<ChildNode> = node.childNodes;

                offset = +key;

                // check if it is last element
                if (
                  (EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS.includes(view.nodeName) &&
                    i !== path.length - 1) ||
                  !EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS.includes(view.nodeName)
                ) {
                  // adjust offset for frontend only elements
                  for (let j = 0; j < childNodes.length; j++) {
                    const element = childNodes[j] as Node;
                    if (
                      EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(element.nodeName) &&
                      element.parentNode
                    ) {
                      const index = Array.from(element.parentNode.childNodes).indexOf(
                        element as ChildNode,
                      );

                      if (offset < childNodes.length && index <= offset) {
                        offset += 1;
                      }
                    }
                  }
                }

                if (
                  childNodes[offset] != null &&
                  ((options.selectionPath && i < path.length - 1) || !options.selectionPath) // for selection the path needs to be outside of the elements
                ) {
                  node = childNodes[offset];
                  offset = 0;
                } else if (
                  // TODO evaluate this FIX and do unit tests for this
                  childNodes.length > 0 &&
                  isSplitView &&
                  (offset > 1 || (offset === 1 && !EditorDOMElements.isParagraphElement(node))) && // fix when split elements have only 1 childnode on the first page
                  offset > childNodes.length - 1
                ) {
                  node = null;
                  offset = null;
                  break;
                }
              } else {
                offset = null;
                break;
              }
            } else if (lastKey === 'content' && node instanceof Text) {
              if (+key <= node.length) {
                offset = +key;
              } else {
                offset = null;
                break;
              }
            }
          }

          if (key === 'childNodes' || key === 'content') {
            lastKey = key;
          } else {
            lastKey = null;
          }
        }

        return { node, offset };
      }
    }

    return {};
  }

  static buildFromDOMRange(range: Range): JsonRange {
    let start: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.startContainer,
      range.startOffset,
    );

    let end: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.endContainer,
      range.endOffset,
    );

    if (start != null && end != null) {
      return new JsonRange(start, end);
    } else {
      throw new ErrorInvalidPath('Invalid range!');
    }
  }

  static buildFromRangeData(
    range: Editor.Selection.RangeData,
    options?: Editor.Selection.SerializeToDOMRangeOptions,
  ): JsonRange {
    return new JsonRange(range.start, range.end);
  }

  constructor(start: Editor.Selection.Position, end?: Editor.Selection.Position | null) {
    this.start = start;
    this.end = end || start;
  }

  setStart(start: Editor.Selection.Position) {
    this.start = JSON.parse(JSON.stringify(start));
  }

  setEnd(end: Editor.Selection.Position) {
    this.end = JSON.parse(JSON.stringify(end));
  }

  cloneRange() {
    const cloneStart = JSON.parse(JSON.stringify(this.start));
    const cloneEnd = JSON.parse(JSON.stringify(this.end));
    return new JsonRange(cloneStart, cloneEnd);
  }

  accept(visitor: Editor.Selection.Range.IEditorRangeVisitor) {
    visitor.visitJsonRange(this);
  }

  get collapsed() {
    return this.isCollapsed();
  }

  serializeStartToNodeOffset() {
    return JsonRange.getNodeOffsetFromPosition(this.start);
  }

  serializeEndToNodeOffset() {
    return JsonRange.getNodeOffsetFromPosition(this.end);
  }

  isCollapsed(): boolean {
    // check start and end model id
    if (this.start.b !== this.end.b) {
      return false;
    }

    return PathUtils.isPathEqual(this.start.p, this.end.p);
  }

  collapse(toStart?: boolean) {
    if (toStart) {
      this.end = JSON.parse(JSON.stringify(this.start));
    } else {
      this.start = JSON.parse(JSON.stringify(this.end));
    }
  }

  collapseToStart() {
    this.collapse(true);
  }

  collapseToEnd() {
    this.collapse(false);
  }

  updateStartPosition(start: Editor.Selection.Position) {
    this.start = JSON.parse(JSON.stringify(start));
  }

  updateEndPosition(end: Editor.Selection.Position) {
    this.end = JSON.parse(JSON.stringify(end));
  }

  updateRangePositions(start: Editor.Selection.Position, end?: Editor.Selection.Position) {
    this.updateStartPosition(start);
    if (end) {
      this.updateEndPosition(end);
    } else {
      this.updateEndPosition(start);
    }
  }

  updateFromDOMRange(range: Range) {
    let start: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.startContainer,
      range.startOffset,
    );

    let end: Editor.Selection.Position | null = JsonRange.getPositionFromNodeOffset(
      range.endContainer,
      range.endOffset,
    );

    if (start != null && end != null) {
      this.start = start;
      this.end = end;
    } else {
      throw new ErrorInvalidPath('Invalid range!');
    }
  }

  serializeToDOMRange(
    options?: Editor.Selection.SerializeToDOMRangeOptions,
  ): Editor.Selection.EditorRange {
    const range = new EditorRange();

    let start = JsonRange.getNodeOffsetFromPosition(this.start, options);
    let end = JsonRange.getNodeOffsetFromPosition(this.end, options);

    if (start.node != null && start.offset != null && end.node != null && end.offset != null) {
      range.setStart(start.node, start.offset);
      range.setEnd(start.node, start.offset);

      if (range.comparePoint(end.node, end.offset) >= 0) {
        range.setEnd(end.node, end.offset);
      } else {
        range.setStart(end.node, end.offset);
      }
    } else {
      logger.warn('JsonRange Node not found!', this.start, start, this.end, end);
      // throw new Error('Node not found!');
    }

    return range;
  }

  serializeToRangeData(): Editor.Selection.RangeData {
    return {
      start: JSON.parse(JSON.stringify(this.start)),
      end: JSON.parse(JSON.stringify(this.end)),
      collapsed: this.collapsed,
    };
  }

  compare(documentNodes: string[], jsonRangeToCompare: JsonRange): number {
    if (documentNodes && documentNodes.length > 0 && jsonRangeToCompare) {
      const thisStartIndex = documentNodes.indexOf(this.start.b);
      const thisEndIndex = documentNodes.indexOf(this.end.b);

      const otherStartIndex = documentNodes.indexOf(jsonRangeToCompare.start.b);
      const otherEndIndex = documentNodes.indexOf(jsonRangeToCompare.end.b);

      if (thisStartIndex >= 0 && thisEndIndex >= 0 && otherStartIndex >= 0 && otherEndIndex >= 0) {
        if (thisStartIndex < otherStartIndex && thisEndIndex < otherEndIndex) {
          // this range is before other range
          return -1;
        } else if (thisStartIndex > otherStartIndex && thisEndIndex > otherEndIndex) {
          // this range is after other range
          return 1;
        } else if (thisStartIndex < otherStartIndex && thisEndIndex > otherEndIndex) {
          // this range contains other range
          return 0;
        } else if (thisStartIndex > otherStartIndex && thisEndIndex < otherEndIndex) {
          // this range is contained by other range
          return 0;
        } else if (thisStartIndex === otherStartIndex && thisEndIndex > otherEndIndex) {
          // this range contains other range with match start
          const result = PathUtils.comparePath(this.start.p, jsonRangeToCompare.start.p);
          return result > 0 ? result : 0;
        } else if (thisStartIndex === otherStartIndex && thisEndIndex < otherEndIndex) {
          // this range is contained by other range with match start
          const result = PathUtils.comparePath(this.start.p, jsonRangeToCompare.start.p);
          return result < 0 ? result : 0;
        } else if (thisStartIndex < otherStartIndex && thisEndIndex === otherEndIndex) {
          // this range contains other range with match end
          const result = PathUtils.comparePath(this.end.p, jsonRangeToCompare.end.p);
          return result < 0 ? result : 0;
        } else if (thisStartIndex > otherStartIndex && thisEndIndex === otherEndIndex) {
          // this range is contained by other range with match end
          const result = PathUtils.comparePath(this.end.p, jsonRangeToCompare.end.p);
          return result > 0 ? result : 0;
        } else if (thisStartIndex === otherStartIndex && thisEndIndex === otherEndIndex) {
          // ranges are within the same block
          const resultStart = PathUtils.comparePath(this.start.p, jsonRangeToCompare.start.p);
          const resultEnd = PathUtils.comparePath(this.end.p, jsonRangeToCompare.end.p);

          if (resultStart < 0 && resultEnd < 0) {
            // this range is before other range
            return -1;
          } else if (resultStart > 0 && resultEnd > 0) {
            // this range is after other range
            return 1;
          } else if ((resultStart <= 0 && resultEnd >= 0) || (resultStart >= 0 && resultEnd <= 0)) {
            // ranges are contained within each other or are equal
            return 0;
          }
        }
      }
    }

    throw new Error('Invalid ranges to compare!');
  }

  getCommonAncestorPath() {
    if (this.start.b === this.end.b) {
      return PathUtils.getCommonAncestorPath(this.start.p, this.end.p);
    }

    return [];
  }

  getNodes(
    dataManager: Editor.Data.API,
    typesToFilter: Editor.Data.Node.DataTypes[] = [],
    validation?: (
      baseData: Editor.Data.Node.Data,
      childData: Editor.Data.Node.Data,
      childPath: Editor.Selection.Path,
    ) => boolean,
  ): Editor.Selection.NodesInfo[] {
    let allNodes = false;
    if (typesToFilter.length === 0) {
      allNodes = true;
    }

    let childNodes: Editor.Selection.NodesInfo[] = [];

    let blockIdsToIterate = [];
    if (this.start.b !== this.end.b) {
      const structureBlocks = dataManager.structure.getDocumentNodes();
      const startIndex = structureBlocks.indexOf(this.start.b);
      const endIndex = structureBlocks.indexOf(this.end.b);

      blockIdsToIterate = structureBlocks.slice(startIndex, endIndex + 1);
    } else {
      blockIdsToIterate = [this.start.b];
    }

    for (let i = 0; i < blockIdsToIterate.length; i++) {
      const blockModel = dataManager.nodes.getNodeModelById(blockIdsToIterate[i]);
      const baseData = blockModel?.selectedData();

      if (!baseData) {
        continue;
      }

      let startPath: Editor.Selection.Path = [];
      let endPath: Editor.Selection.Path = [];

      if (baseData.id === this.start.b) {
        startPath = this.start.p;
      } else {
        startPath = []; // important to include the base element itself
      }

      if (baseData.id === this.end.b) {
        endPath = this.end.p;
      } else {
        endPath = ['childNodes', baseData.childNodes?.length || 0];
      }

      const nodeDataIterator = new NodeDataIterator(baseData, startPath, endPath);

      while (nodeDataIterator.hasNext()) {
        const next = nodeDataIterator.next();
        if (next && (typesToFilter.includes(next.data.type) || allNodes)) {
          if (validation) {
            if (validation(baseData, next.data, next.path)) {
              childNodes.push({
                baseData,
                childData: next.data,
                childPath: next.path,
              });
            }
          } else {
            childNodes.push({
              baseData,
              childData: next.data,
              childPath: next.path,
            });
          }
        }
      }
    }

    return childNodes;
  }

  isEmpty(dataManager: Editor.Data.API): boolean {
    const textContent = this.getTextContent(dataManager);

    if (textContent.trim() !== '') {
      return false;
    }

    const elementsInfo = this.getNodes(dataManager, NodeUtils.INLINE_NON_EDITABLE_TYPES);

    return elementsInfo.length === 0;
  }

  getTextContent(dataManager: Editor.Data.API): string {
    let textContent: string = '';

    const textElementsInfo = this.getNodes(
      dataManager,
      ['text'],
      (
        baseData: Editor.Data.Node.Data,
        childData: Editor.Data.Node.Data,
        childPath: Editor.Selection.Path,
      ) => {
        // validation function
        return !NodeUtils.closestOfTypeByPath(
          baseData,
          childPath,
          NodeUtils.INLINE_NON_STYLABLE_TYPES,
        );
      },
    );

    for (let i = textElementsInfo.length - 1; i >= 0; i--) {
      const textInfo = textElementsInfo[i];

      let startOffset: number | undefined;
      let endOffset: number | undefined;

      if (!NodeUtils.isTextData(textInfo.childData)) {
        continue;
      }

      if (
        PathUtils.isChildPath(textInfo.childPath, this.start.p) &&
        PathUtils.isChildPath(textInfo.childPath, this.end.p)
      ) {
        // start and end within same element
        if (this.start.p.includes('content')) {
          startOffset = Number(this.start.p[this.start.p.length - 1]);
        } else {
          startOffset = 0;
        }

        if (this.end.p.includes('content')) {
          endOffset = Number(this.end.p[this.end.p.length - 1]);
        } else {
          endOffset = textInfo.childData.content.length;
        }
      } else if (PathUtils.isChildPath(textInfo.childPath, this.start.p)) {
        // start within element
        if (this.start.p.includes('content')) {
          startOffset = Number(this.start.p[this.start.p.length - 1]);
        } else {
          startOffset = 0;
        }

        endOffset = textInfo.childData.content.length;
      } else if (PathUtils.isChildPath(textInfo.childPath, this.end.p)) {
        // end within element
        startOffset = 0;

        if (this.end.p.includes('content')) {
          endOffset = Number(this.end.p[this.end.p.length - 1]);
        } else {
          endOffset = textInfo.childData.content.length;
        }
      } else {
        startOffset = 0;
        endOffset = textInfo.childData.content.length;
      }

      if (!isNaN(startOffset) && !isNaN(endOffset)) {
        textContent += textInfo.childData.content.slice(startOffset, endOffset);
      }
    }

    return textContent;
  }

  static splitRangeByTypes(
    dataManager: Editor.Data.API,
    originalRange: JsonRange,
    typesToFilter: Editor.Data.Node.DataTypes[] = NodeUtils.BLOCK_TEXT_TYPES,
    options: {
      onlyContainerLevel?: boolean;
      useSelectedCells?: boolean;
    } = {},
  ) {
    let blocksData = JsonRange.filterElementDataFromRange(
      dataManager,
      originalRange,
      typesToFilter,
      options,
    );

    let splitedRangesData: {
      range: JsonRange;
      filteredData: Editor.Data.Node.Data;
      filteredDataPath: Editor.Selection.Path;
    }[] = [];

    for (let i = 0; i < blocksData.length; i++) {
      let startPosition: Editor.Selection.Position | undefined;

      if (
        blocksData[i].baseData.id === originalRange.start.b &&
        PathUtils.isChildPath(blocksData[i].childPath, originalRange.start.p) &&
        !blocksData[i].isSelectedCell
      ) {
        const blockId = blocksData[i].baseData.id;
        if (blockId) {
          startPosition = {
            b: blockId,
            p: [...originalRange.start.p],
          };
        }
      } else {
        const blockId = blocksData[i].baseData.id;
        if (blockId) {
          startPosition = {
            b: blockId,
            p: [...blocksData[i].childPath, 'childNodes', 0],
          };
        }
      }

      let endPosition: Editor.Selection.Position | undefined;

      if (
        blocksData[i].baseData.id === originalRange.end.b &&
        PathUtils.isChildPath(blocksData[i].childPath, originalRange.end.p) &&
        !blocksData[i].isSelectedCell
      ) {
        const blockId = blocksData[i].baseData.id;
        if (blockId) {
          endPosition = {
            b: blockId,
            p: [...originalRange.end.p],
          };
        }
      } else {
        const blockId = blocksData[i].baseData.id;
        const childNodes = blocksData[i].childData.childNodes || [];
        if (blockId) {
          if (NodeUtils.isParagraphMarker(childNodes[childNodes.length - 1])) {
            endPosition = {
              b: blockId,
              p: [...blocksData[i].childPath, 'childNodes', childNodes.length - 1],
            };
          } else {
            endPosition = {
              b: blockId,
              p: [...blocksData[i].childPath, 'childNodes', childNodes.length],
            };
          }
        }
      }

      if (startPosition && endPosition) {
        splitedRangesData.push({
          range: new JsonRange(startPosition, endPosition),
          filteredData: blocksData[i].childData,
          filteredDataPath: blocksData[i].childPath,
        });
      }
    }

    return splitedRangesData;
  }

  static filterDocumentElementsFromRange(
    dataManager: Editor.Data.API,
    rangeToFilter: Editor.Selection.JsonRange,
    typesToFilter: Editor.Data.Node.DataTypes[] = NodeUtils.BLOCK_TEXT_TYPES,
  ) {
    let blocksData: {
      baseData: Editor.Data.Node.Data;
      childData: Editor.Data.Node.Data;
      childPath: Editor.Selection.Path;
      isSelectedCell?: boolean;
    }[] = [];

    const documentNodes = dataManager.structure.structureModel?.childNodes;

    if (documentNodes?.length) {
      const startIndex = documentNodes.indexOf(rangeToFilter.start.b);
      const endIndex = documentNodes.indexOf(rangeToFilter.end.b);

      const nodes = documentNodes.slice(startIndex, endIndex + 1);

      for (let n = 0; n < nodes.length; n++) {
        const model = dataManager.nodes.getNodeModelById(nodes[n]);
        const data = model?.selectedData();
        if (
          (data && typesToFilter.includes(data.type)) ||
          (NodeUtils.isTrackedData(data) &&
            data.childNodes?.length &&
            typesToFilter.includes(data.childNodes[0].type))
        ) {
          blocksData.push({
            baseData: data,
            childData: data,
            childPath: [],
          });
        }
      }
    }

    return blocksData;
  }

  static filterContainerElementsFromRange(
    baseData: Editor.Data.Node.Data,
    rangeToFilter: Editor.Selection.JsonRange,
    typesToFilter: Editor.Data.Node.DataTypes[] = NodeUtils.BLOCK_TEXT_TYPES,
  ) {
    let blocksData: {
      baseData: Editor.Data.Node.Data;
      childData: Editor.Data.Node.Data;
      childPath: Editor.Selection.Path;
      isSelectedCell?: boolean;
    }[] = [];

    if (baseData.id === rangeToFilter.start.b && rangeToFilter.start.b === rangeToFilter.end.b) {
      const closestContainer = NodeUtils.closestOfTypeByPath(
        baseData,
        rangeToFilter.getCommonAncestorPath(),
        NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
      );

      if (closestContainer) {
        let startChildPath = rangeToFilter.start.p.slice(0, closestContainer.path.length + 2);
        let endChildPath = rangeToFilter.end.p.slice(0, closestContainer.path.length + 2);

        let startInfo = NodeUtils.getParentChildInfoByPath(baseData, startChildPath);
        let endInfo = NodeUtils.getParentChildInfoByPath(baseData, endChildPath);

        if (
          startInfo &&
          endInfo &&
          closestContainer.data === startInfo.parentData &&
          startInfo.parentData === endInfo.parentData &&
          !isNaN(startInfo.childIndex) &&
          !isNaN(endInfo.childIndex)
        ) {
          const parentData = startInfo.parentData;
          const parentPath = startInfo.parentPath;
          for (let i = startInfo.childIndex; i <= endInfo.childIndex; i++) {
            const childData = parentData.childNodes?.[i];
            if (
              (childData && typesToFilter.includes(childData.type)) ||
              (NodeUtils.isTrackedData(childData) &&
                childData.childNodes?.length &&
                typesToFilter.includes(childData.childNodes[0].type))
            ) {
              blocksData.push({
                baseData: baseData,
                childData: childData,
                childPath: [...parentPath, 'childNodes', i],
              });
            }
          }
        }
      } else {
        blocksData.push({
          baseData: baseData,
          childData: baseData,
          childPath: [],
        });
      }
    }

    return blocksData;
  }

  static filterElementDataFromRange(
    dataManager: Editor.Data.API,
    originalRange: JsonRange,
    typesToFilter: Editor.Data.Node.DataTypes[] = NodeUtils.BLOCK_TEXT_TYPES,
    options: {
      onlyContainerLevel?: boolean;
      useSelectedCells?: boolean;
    } = {},
  ): {
    baseData: Editor.Data.Node.Data;
    childData: Editor.Data.Node.Data;
    childPath: Editor.Selection.Path;
    isSelectedCell?: boolean;
  }[] {
    let elementsData: {
      baseData: Editor.Data.Node.Data;
      childData: Editor.Data.Node.Data;
      childPath: Editor.Selection.Path;
      isSelectedCell?: boolean;
    }[] = [];

    let rangesToFilter: { range: JsonRange; isSelectedCell?: boolean }[] = [];

    if (originalRange.start.b === originalRange.end.b && options.useSelectedCells) {
      // single block

      const blockModel = dataManager.nodes.getNodeModelById(originalRange.start.b);
      const baseData = blockModel?.selectedData();

      if (blockModel && baseData) {
        const closestTable = NodeUtils.closestOfTypeByPath(
          baseData,
          originalRange.getCommonAncestorPath(),
          [ELEMENTS.TableElement.ELEMENT_TYPE],
        );

        if (closestTable) {
          let selectedCellsIds: string[] = [];

          // get table element
          const tableElement = EditorDOMUtils.getNode(closestTable.data.id);
          // get selected cells ids
          if (EditorDOMElements.isTableElement(tableElement)) {
            selectedCellsIds = tableElement.getSelectedCellsIds();
          }

          if (selectedCellsIds.length > 1) {
            for (let i = 0; i < selectedCellsIds.length; i++) {
              const cellInfo = blockModel.getChildInfoById(selectedCellsIds[i]);
              if (PathUtils.isValidSelectionPath(cellInfo.path)) {
                const startPath: Editor.Selection.Path = [...cellInfo.path, 'childNodes', 0];
                const endPath: Editor.Selection.Path = [
                  ...cellInfo.path,
                  'childNodes',
                  cellInfo.data.childNodes?.length || 0,
                ];
                const range = new JsonRange(
                  { b: blockModel.id, p: startPath },
                  { b: blockModel.id, p: endPath },
                );
                rangesToFilter.push({ range, isSelectedCell: true });
              }
            }
          }
        }
      }
    }

    if (rangesToFilter.length === 0) {
      rangesToFilter = [{ range: originalRange }];
    }

    // get nodes from range
    for (let r = 0; r < rangesToFilter.length; r++) {
      const rangeInfo = rangesToFilter[r];

      const blockModel = dataManager.nodes.getNodeModelById(rangeInfo.range.start.b);
      const baseData = blockModel?.selectedData();

      if (!baseData) {
        continue;
      }

      if (!options.onlyContainerLevel) {
        // all nodes
        let nodesInfo = rangeInfo.range.getNodes(dataManager, typesToFilter);

        if (nodesInfo.length === 0) {
          const closest = NodeUtils.closestOfTypeByPath(
            baseData,
            rangeInfo.range.getCommonAncestorPath(),
            typesToFilter,
          );
          if (closest) {
            nodesInfo = [
              {
                baseData,
                childData: closest.data,
                childPath: closest.path,
              },
            ];
          }
        }

        if (nodesInfo.length > 0) {
          if (rangeInfo.isSelectedCell) {
            elementsData.push(
              ...nodesInfo.map((info) => {
                return {
                  ...info,
                  isSelectedCell: true,
                };
              }),
            );
          } else {
            elementsData.push(...nodesInfo);
          }
        }
      } else {
        // only container level
        const isAtStart = NodeUtils.isPathAtContentStart(baseData, rangeInfo.range.start.p);
        const isAtEnd = NodeUtils.isPathAtContentEnd(baseData, rangeInfo.range.end.p);
        if (
          isAtStart &&
          isAtEnd &&
          NodeUtils.isMultiBlockContainerData(baseData) &&
          !NodeUtils.isTableData(baseData)
        ) {
          elementsData.push(
            ...JsonRange.filterDocumentElementsFromRange(
              dataManager,
              rangeInfo.range,
              typesToFilter,
            ),
          );
        } else if (rangeInfo.range.start.b === rangeInfo.range.end.b) {
          let nodesData = JsonRange.filterContainerElementsFromRange(
            baseData,
            rangeInfo.range,
            typesToFilter,
          );
          if (rangeInfo.isSelectedCell) {
            nodesData = nodesData.map((data) => {
              return {
                ...data,
                isSelectedCell: true,
              };
            });
          }
          elementsData.push(...nodesData);
        } else {
          elementsData.push(
            ...JsonRange.filterDocumentElementsFromRange(
              dataManager,
              rangeInfo.range,
              typesToFilter,
            ),
          );
        }
      }
    }

    return elementsData;
  }

  static isSelectionEditable(dataManager: Editor.Data.API, range: JsonRange) {
    if (range.start.b === range.end.b) {
      return dataManager.nodes.isNodeEditable(range.start.b);
    } else {
      const blocks = JsonRange.filterDocumentElementsFromRange(
        dataManager,
        range,
        NodeUtils.BLOCK_TYPES,
      );
      for (let i = 0; i < blocks.length; i++) {
        const blockId = blocks[i].baseData.id;
        if (!blockId || !dataManager.nodes.isNodeEditable(blockId)) {
          return false;
        }
      }
    }
    return true;
  }
}
