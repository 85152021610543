import { CSSProperties } from 'react';

import SectionHeading from '_common/components/AuditLog/SectionHeading/SectionHeading';
import Table from '_common/components/AuditLog/Table/Table';
import { useObject } from '_common/hooks';
import FilterDisplay from '../Filters/Components/FilterDisplay/FilterDisplay';

import AuditLogContext from './AuditLogContext';

export type AuditLogProps = {
  objectId: ObjectId;
  objectType: 'document' | 'dopdf' | 'presentation';
  navigateToObject: ({
    objectType,
    objectId,
  }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node';
    objectId: ObjectId;
  }) => void;
  noFlex?: boolean;
} & Pick<CSSProperties, 'margin'>;

const AuditLog = ({ objectId, objectType, margin, noFlex, navigateToObject }: AuditLogProps) => {
  const { data } = useObject({ object_id: objectId, object_type: objectType });
  if (!data) {
    return null;
  }
  return (
    <AuditLogContext
      objectId={objectId}
      objectType={objectType}
      navigateToObject={navigateToObject}
    >
      <div style={{ margin }}>
        <SectionHeading
          objectId={objectId}
          objectType={objectType}
          objectCreationDate={data.time.creation}
          margin="0 0 1rem 0"
        />
      </div>
      <FilterDisplay identity="auditLog" />
      <Table noFlex={noFlex} />
    </AuditLogContext>
  );
};

export default AuditLog;
