/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { DoPDFDocument } from '_common/services/Realtime';
import { BaseController } from '../BaseController';

export class VersionsController extends BaseController {
  private doc?: DoPDFDocument;

  start(documentId: string): void {
    this.registerTransportEvents({
      'NEW:VERSION': this.handleEventNewDocumentVersion.bind(this),
    });
    this.doc = this.Data.models?.get(this.Data?.models.TYPE_NAME.DOCUMENT, documentId);
  }

  private handleEventNewDocumentVersion() {
    this.Data.events?.emit('FORCE_REMOTE_RELOAD');
  }

  saveVersion(description: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'SAVE:VERSION',
        {
          description,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  editVersionDescription(description: string, index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'EDIT:VERSION',
        {
          description,
          versionIndex: index,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  async restoreVersion(index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'RESTORE:VERSION',
        {
          versionIndex: index,
        },
        (response) => {
          if (response.success) {
            this.loadVersion(null);
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  async loadVersion(index: number | null) {
    let availableVersions = this.doc?.versions || [];
    let oldVersion = this.Data.context?.version;
    let version = null;
    if (index !== null && index !== undefined) {
      version = availableVersions[index];
    }
    if (oldVersion && version) {
      if (oldVersion?.creation !== version?.creation) {
        // load version
        await this.Data.models?.setModelsVersion(version);
        if (this.Data.context) {
          this.Data.context.version = version;
        }
      }
    } else if (!oldVersion && version) {
      // load version
      if (this.Data.context) {
        this.Data.context.version = version;
      }
      await this.Data.models?.setModelsVersion(version);
    } else if (oldVersion && !version) {
      if (this.Data.context) {
        this.Data.context.version = version;
      }
      await this.Data.models?.setModelsVersion(null);
    }
  }

  getVersion() {
    return this.Data.context?.version;
  }

  hasLoadedVersion() {
    return !!this.Data.context?.version;
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
  }
}
