import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import { useParams } from 'react-router';

import { useDispatch, useEffectOnUpdate, useSelector } from '_common/hooks';
import { PublicLinkService } from '_common/services';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { listObjects } from '_common/components/Table/TableSlice';
import { useGetPublicLinkListQuery } from './PublicLinkSettingsApi';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import { ThunksTable } from '_common/components/Table2';
import Cell from '_common/components/Table2/Cells';

import PublicLinkHeading from './PublicLinkHeading';
import StatusCell from './Cells/StatusCell';
import SettingsCell from './Cells/SettingsCell/SettingsCell';
import MoreOptions from './Cells/MoreOptions';

import styles from './PublicLinkView.module.scss';

const PAGE_IDENTITY = 'publicLink';

const PublicLinkView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id }: { id: ObjectId } = useParams();
  const { data: dict } = useGetPublicLinkListQuery({
    objectId: id,
    params: {
      offset: 0,
      size: 200,
    },
  });

  const list = useSelector((state) => state.table.identity.publicLink.list);
  const appData = useSelector((state) => state.app.data);

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (params: any) => {
      const resp = await new PublicLinkService().listPublicLinks(id, params);
      return {
        ...resp,
        data: { nodes: resp.data },
      };
    },
    [dict, id],
  );

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  useEffectOnUpdate(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'FILTER',
        request: { offset: 0 },
      }),
    );
  }, [fetchObjects]);
  //#endregion

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'global.name' }),
        width: 296,
        flex: true,
      },
      {
        id: 'dateCreation',
        header: intl.formatMessage({ id: 'global.dateCreated' }),
        width: 200,
      },
      {
        id: 'downloads',
        header: intl.formatMessage({ id: 'storage.modals.share.downloads' }),
        width: 160,
      },
      {
        id: 'status',
        header: intl.formatMessage({ id: 'storage.browserHeader.status' }),
        width: 160,
      },
      {
        id: 'copyList',
        width: 106,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list.map((objectId) => {
      const object = appData[objectId];
      return {
        id: object.id,
        name: <Cell testId={`${objectId}-name-column`}>{object.name}</Cell>,
        dateCreation: (
          <Cell testId={`${objectId}-date-creation-column`}>
            <Cell.DateTime
              date={{ date: object.time.creation }}
              time={{ time: object.time.creation }}
            />
          </Cell>
        ),
        downloads: <Cell testId={`${objectId}-downloads-column`}>{object.downloads}</Cell>,
        status: (
          <Cell testId={`${objectId}-status-column`}>
            <StatusCell object={object} />
          </Cell>
        ),
        copyList: (
          <Cell testId={`${objectId}-clipboard-column`}>
            <SettingsCell object={object} />
          </Cell>
        ),
        suffix: (
          <Cell testId={`${objectId}-suffix-column`}>
            <MoreOptions object={object} />
          </Cell>
        ),
      };
    });
  }, [list, appData]);

  const renderEmptyState = () => {
    return (
      <div className={styles.empty}>
        <EmptyState
          size="large"
          icon="EmptyFolder"
          title="No public links"
          footer={<FormattedMessage id="settings.publicLink.newPublicLink" />}
          onClick={() => {
            dispatch(
              openAndUpdateModal({
                modal: 'PublicLinkModal',
                data: {
                  objectId: id,
                  mode: 'create',
                },
              }),
            );
          }}
          testId="public-link-empty-state"
        >
          <div style={{ width: '50rem' }}>
            <FormattedMessage id="NO_PUBLIC_LINKS_ASSOCIATED_WITH_FILE" />
          </div>
        </EmptyState>
      </div>
    );
  };

  return (
    <>
      <div className={styles.publicLinkContainer}>
        <IntlErrorBoundary fallbackType="sectionHeading">
          <PublicLinkHeading />
        </IntlErrorBoundary>
      </div>

      <ThunksTable
        identity={PAGE_IDENTITY}
        fetchObjects={fetchObjects}
        columns={columns}
        value={value}
        lazyLoading
        renderEmptyState={renderEmptyState}
        testId="publicLinkView"
        selectable={false}
        margin="3rem 0 0 0"
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        renderFooter={() => null}
      />
    </>
  );
};

export default PublicLinkView;
