export default class ErrorCannotRemoveContent extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = 'ErrorCannotRemoveContent';
    this.message = 'Cannot remove content!';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorCannotRemoveContent);
    }
  }
}
