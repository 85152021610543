import { Route, Switch, useRouteMatch } from 'react-router-dom';
import FilePage from './pages/FilePage/FilePage';

const FileContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/:id`}>
        <FilePage />
      </Route>
    </Switch>
  );
};

export default FileContainer;
