import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, PasswordInput, Popover, Button, usePopper } from 'dodoc-design-system';

import { navigateToMyFiles } from 'router/history';
import { useDispatch, useEffectOnUpdate, useSelector } from '_common/hooks';
import { PasswordCheck } from '_common/components';
import { signedOut } from 'Auth/redux/authSlice';
import SecondaryAuthPageContainer from 'Auth/components/SecondaryAuthPageContainer/SecondaryAuthPageContainer';
import { useGetCurrentUserQuery, useUpdatePasswordMutation } from '_common/services/api/authority';

const UpdatePasswordPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isUpdatePasswordForced = useSelector((state) => state.auth.isUpdatePasswordForced);
  const { data: currentUser } = useGetCurrentUserQuery();
  const [updatePassword] = useUpdatePasswordMutation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [validations, setValidations] = useState({
    newPassword: '',
    confirmedPassword: '',
  });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isUpdatePasswordForced) {
      navigateToMyFiles();
    }
  }, [isUpdatePasswordForced]);

  useEffectOnUpdate(() => {
    checkValidations();
  }, [newPassword, confirmedPassword]);

  const checkValidations = () => {
    const newValidations = {
      newPassword: '',
      confirmedPassword: '',
    };

    if (!newPassword || newPassword === '') {
      newValidations.newPassword = intl.formatMessage({ id: 'ERROR_REQUIRED_INPUT' });
    }
    if (!confirmedPassword || confirmedPassword === '') {
      newValidations.confirmedPassword = intl.formatMessage({ id: 'ERROR_REQUIRED_INPUT' });
    }
    if (newPassword && confirmedPassword && newPassword !== confirmedPassword) {
      newValidations.confirmedPassword = intl.formatMessage({
        id: 'ERROR_PASSWORDS_DO_NOT_MATCH',
      });
    }

    setValidations(newValidations);
  };

  const rules = (currentUser && currentUser.other.password_validators) || [];

  const { popperProps, referenceProps } = usePopper({
    disabled: rules.length === 0,
    placement: 'left-start',
    trigger: 'focus',
    skidding: 5,
    distance: 30,
  });

  const handleInputChange = (value: string, name: 'confirmedPassword' | 'newPassword') => {
    switch (name) {
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'confirmedPassword':
        setConfirmedPassword(value);
        break;
    }
  };

  const handleUpdatePassword = () => {
    updatePassword({ new: newPassword });
  };

  const handleSignedOut = () => {
    dispatch(signedOut());
  };

  return (
    <SecondaryAuthPageContainer title="UPDATE_PASSWORD" description="UPDATE_PASSWORD_DESCRIPTION">
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'NEW_PASSWORD' })}
        feedback={validations.newPassword}
        testId="new-password-input-field"
      >
        <PasswordInput
          name="newPassword"
          error={!!validations.newPassword}
          value={newPassword}
          onChange={(e) => handleInputChange(e.target.value, 'newPassword')}
          autoCompleteOff
          placeholder={intl.formatMessage({ id: 'NEW_PASSWORD_PLACEHOLDER' })}
          size="large"
          {...referenceProps}
          onBlur={() => {
            checkValidations();
            referenceProps.onBlur();
          }}
          testId="new-password"
        />
        <Popover {...popperProps} testId="password-checker-popper">
          <PasswordCheck rules={rules} password={newPassword} setDisabled={setDisabled} />
        </Popover>
      </InputField>
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'CONFIRM_PASSWORD' })}
        feedback={validations.confirmedPassword}
        testId="confirm-password-input-field"
      >
        <PasswordInput
          name="confirmedPassword"
          size="large"
          value={confirmedPassword}
          onChange={(e) => handleInputChange(e.target.value, 'confirmedPassword')}
          placeholder={intl.formatMessage({ id: 'CONFIRM_PASSWORD_PLACEHOLDER' })}
          error={!!validations.confirmedPassword}
          onEnterKey={handleUpdatePassword}
          testId="confirm-password"
        />
      </InputField>
      <Button
        variant="primary"
        fullWidth
        size="large"
        onClick={handleUpdatePassword}
        disabled={disabled || !newPassword || newPassword !== confirmedPassword}
        testId="update-password-button"
        margin="1rem 0 2rem"
      >
        <FormattedMessage id="CHANGE_PASSWORD" />
      </Button>
      <Button
        size="medium"
        variant="link"
        onClick={handleSignedOut}
        testId="sign-in-to-dodoc-button"
      >
        <FormattedMessage id="SIGN_IN_TO_DODOC" />
      </Button>
    </SecondaryAuthPageContainer>
  );
};

export default UpdatePasswordPage;
