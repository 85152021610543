import { BasePositionIterator } from '.';

export class CharacterPositionIterator extends BasePositionIterator {
  protected incrementOffset: number = 1;

  constructor(iteratorData: Editor.Data.Node.NavigationData[], startPath: Editor.Selection.Path) {
    super(iteratorData, startPath);

    this.incrementOffset = 1;
  }

  protected getOffset(forward: boolean = true): number {
    if (forward) {
      return this.workingOffsets.content + this.incrementOffset;
    } else {
      return this.workingOffsets.content - this.incrementOffset;
    }
  }
}
