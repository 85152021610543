import { useDispatch, useSelector } from '_common/hooks';
import EditableNoteCard from './EditableNoteCard';
import ViewNoteCard from './ViewNoteCard';
import { selectUser } from 'Editor/redux/EditorStatusSlice';
import { useEffect, useRef } from 'react';
import { setNoteOverlayData } from 'Editor/redux/NotesSlice';

export type NoteCardProps = {
  note: Editor.Note;
  selected?: boolean;
  sidebar?: boolean;
  doc: Objekt;
  isReadOnlyMode: boolean;
  ref?: React.RefObject<HTMLSpanElement>;
};

const NoteCard = ({ note, selected, sidebar, doc, isReadOnlyMode }: NoteCardProps) => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.editor.notes.overlay.type);
  const operation = useSelector((state) => state.editor.notes.overlay.operation);
  const user = useSelector(selectUser);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sidebar) {
      return undefined;
    }
    const handleClickOutside = (e: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(e.target as HTMLElement)) {
        dispatch(setNoteOverlayData({ offsets: null, selected: null, type: undefined }));
      }
    };
    const editorContainer = document.getElementById('EditorContainer');
    editorContainer?.addEventListener('click', handleClickOutside);
    // Specify how to clean up after this effect:
    return () => {
      editorContainer?.removeEventListener('click', handleClickOutside);
    };
  }, []);

  if (selected && operation === 'edit') {
    return (
      <EditableNoteCard
        operation={operation}
        note={note}
        testId={`note-${note.id}-card`}
        type={type}
        user={user.id}
        selected={selected}
        sidebar={sidebar}
      />
    );
  }

  if (note) {
    return (
      <ViewNoteCard
        testId={`note-${note.id}-card`}
        selected={selected}
        note={note}
        sidebar={sidebar}
        doc={doc}
        isReadOnlyMode={isReadOnlyMode}
        ref={cardRef}
      />
    );
  }

  return null;
};

export default NoteCard;
