import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BaseViewBuilder, BaseViewElement, TrackDeleteElement } from '..';

export class TrackDeleteViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.TrackDeleteElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // element_reference
    element_reference: this.GENERIC_ATTRIBUTE_MAPPER.element_reference,
    // author
    author: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('author')) {
          builder.addProperty('author', node.getAttribute('author'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.author) {
          node.setAttribute('author', json.properties?.author);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('author');
      },
    },
    // user (legacy support)
    user: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('user') && !node.hasAttribute('author')) {
          builder.addProperty('author', node.getAttribute('user'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.user && !json.properties?.author) {
          node.setAttribute('author', json.properties?.user);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('user');
      },
    },
    // replacewith
    replacewith: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('replacewith')) {
          builder.addProperty('replacewith', node.getAttribute('replacewith'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.replacewith) {
          node.setAttribute('replacewith', json.properties?.replacewith);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('replacewith');
      },
    },
    // replacewithsibling
    replacewithsibling: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('replacewithsibling')) {
          builder.addProperty('replacewithsibling', node.getAttribute('replacewithsibling'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.replacewithsibling) {
          node.setAttribute('replacewithsibling', json.properties?.replacewithsibling);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('replacewithsibling');
      },
    },
    //section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    const node = DOMElementFactory.buildElement(
      ELEMENTS.TrackDeleteElement.TAG,
    ) as TrackDeleteElement;

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    node.preRender();

    return node;
  }
}
