import { useEffect } from 'react';
import { useDocumentTitle } from './DocumentTitleContext';

type DocumentTitleProps = {
  title: string;
  children: JSX.Element;
};

const DocumentTitle = ({ title, children }: DocumentTitleProps) => {
  const { push, pop } = useDocumentTitle();

  useEffect(() => {
    if (document.title !== title) {
      push(title);

      return () => {
        pop();
      };
    }
  }, [title]);

  return <>{children}</>;
};

export default DocumentTitle;
