import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Tooltip } from 'dodoc-design-system';
import dayjs from 'dayjs';

type StatusCellProps = {
  object: Objekt;
};

const StatusCell = ({ object }: StatusCellProps) => {
  const intl = useIntl();

  const isExpired = () => {
    return object.expiration.active && dayjs(object.expiration.expiration).isBefore(dayjs());
  };

  if (isExpired()) {
    return (
      <>
        <div style={{ marginRight: '1rem' }}>
          <FormattedMessage id="settings.publicLink.expired" />
        </div>
        <Tooltip
          content={intl.formatMessage({
            id: 'settings.publicLink.tooltips.expiredTooltip',
          })}
          testId={`row-${object.id}-status-expired-tooltip`}
        >
          <div style={{ display: 'flex' }}>
            <Icon icon="InformationBlue" size={16} />
          </div>
        </Tooltip>
      </>
    );
  }
  return (
    <>
      <div>
        <FormattedMessage id={object.expiration.active ? 'global.active' : 'global.inactive'} />
      </div>
    </>
  );
};

export default StatusCell;
