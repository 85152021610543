import { FormattedMessage, useIntl } from 'react-intl';
import { uniq } from 'lodash';

import { useSelector } from '_common/hooks';
import { selectCollaborators } from 'App/redux/appSlice';

import FormattedDate from '_common/components/FormattedDate/FormattedDate';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';

import styles from './Details.module.scss';

const SpaceDetails = () => {
  const intl = useIntl();

  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);
  const collaborators = useSelector((state) => selectCollaborators(state, elementId));

  const getOwner = () => {
    return <UsernameLabel userId={object?.owner} />;
  };

  const getCreationDate = () => {
    if (!object?.time?.creation) {
      return null;
    }

    return <FormattedDate date={object.time.creation} />;
  };

  const getSharedWithUsers = () => {
    if (!object?.permissions) {
      return null;
    }
    if (collaborators.length > 0) {
      return intl.formatMessage(
        { id: 'spaces.counterUsers' },
        {
          users: uniq(collaborators).length,
        },
      );
    }

    return intl.formatMessage({ id: 'spaces.counterUsers' }, { users: 0 });
  };

  const getSharedWithGroups = () => {
    if (!object?.permissions) {
      return null;
    }

    const { permissions } = object;

    const collaborators = [
      ...Object.keys(permissions.groups),
      ...Object.keys(permissions.roles.groups),
    ];

    if (collaborators.length > 0) {
      return intl.formatMessage(
        { id: 'spaces.counterGroups' },
        {
          groups: uniq(collaborators).length,
        },
      );
    }

    return intl.formatMessage({ id: 'spaces.counterGroups' }, { groups: 0 });
  };

  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <FormattedMessage id="storage.browserSidebar.owner" />
      </div>
      <div className={styles.value} data-testid="information-panel-owner-value">
        {getOwner()}
      </div>
      <div className={styles.label}>
        <FormattedMessage id="storage.browserSidebar.creationDate" />
      </div>
      <div className={styles.value} data-testid="information-panel-creation-date-value">
        {getCreationDate()}
      </div>
      <div className={styles.label}>
        <FormattedMessage id="storage.browserHeader.sharedWith" />
      </div>
      <div className={styles.value} data-testid="information-panel-shared-with-users-value">
        {getSharedWithUsers()}
      </div>
      <div className={styles.label} />
      <div className={styles.value} data-testid="information-panel-shared-with-groups-value">
        {getSharedWithGroups()}
      </div>
    </div>
  );
};

export default SpaceDetails;
