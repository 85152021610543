import { MouseEventHandler, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import Metadata from '../Metadata/Metadata';
import Title from './Title/Title';
import Tabs, { TabType } from './Tabs/Tabs';
import Collaborators from './Collaborators/Collaborators';
import ObjectPanel from './Panels/ObjectPanel';
import SpacePanel from './Panels/SpacePanel';
import GroupPanel from './Panels/GroupPanel';

import styles from './InformationPanel.module.scss';

export type InformationPanelProps = {
  onClose: MouseEventHandler<HTMLSpanElement>;
  testId: string;
};

const InformationPanel = ({ onClose, testId }: InformationPanelProps) => {
  const intl = useIntl();

  const selectedElements = Object.keys(useSelector((state) => state.table.selected));
  const object = useSelector((state) => state.app.data[selectedElements[0]]);

  const [selectedTab, setSelectedTab] = useState<TabType>('general');

  const handleTabSelectionChanged = (selectedTab: TabType) => {
    setSelectedTab(selectedTab);
  };

  const renderBodyByType = () => {
    switch (object.type) {
      case 'file':
      case 'document':
      case 'folder':
      case 'dopdf':
      case 'presentation':
        return <ObjectPanel object={object} />;

      case 'space':
        return <SpacePanel object={object as Space} />;

      case 'group':
        return <GroupPanel object={object as Group} />;

      default:
        return null;
    }
  };

  return selectedElements.length === 1 && !!object ? (
    <div className={styles.content} data-testid={`${testId}-content`}>
      <Title onClose={onClose} />
      <Tabs selectedTab={selectedTab} onChange={handleTabSelectionChanged} />
      <div className={styles.body}>
        {selectedTab === 'general' ? (
          <>
            <Collaborators />

            {renderBodyByType()}
          </>
        ) : (
          <Metadata id={selectedElements[0]} />
        )}
      </div>
    </div>
  ) : (
    <div className={styles.emptyState} data-testid={`${testId}-emptystate`}>
      {selectedElements.length === 0 ? (
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'NO_ELEMENTS_SELECTED' })}
          testId="information-panel-no-element-selected"
        >
          <FormattedMessage id="INFORMATION_SELECT_AN_ELEMENT" />
        </EmptyState>
      ) : (
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'MULTIPLE_ELEMENTS_SELECTED' })}
          testId="information-panel-multiple-elements-selected"
        >
          <FormattedMessage id="INFORMATION_SELECT_A_SINGLE_ELEMENT" />
        </EmptyState>
      )}
    </div>
  );
};

export default InformationPanel;
