import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { createGroup } from 'Groups/redux/GroupsPageSlice';

import { Checkbox, Modal, Button, Input, InputField } from 'dodoc-design-system';

const MODAL = 'CreateGroupModal';

const CreateGroupModal = () => {
  const intl = useIntl();

  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const userId = useSelector((state) => state.auth.userId);

  // local
  const [groupName, setGroupName] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [validations, setValidations] = useState<{ name?: string }>({ name: '' });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isOpen]);

  const handleOnNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGroupName(value);

    // validations
    const validations = { name: '' };
    if (e.target.value.length === 0) {
      validations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (value.length > 35) {
      validations.name = intl.formatMessage({ id: 'validation.name.longName' }, { length: 35 });
    } else {
      validations.name = '';
    }

    if (Object.keys(validations).length > 0) {
      setValidations(validations);
    }
  };

  const handleOnClickCreate = () => {
    if (groupName && groupName.length > 0) {
      if (isChecked) {
        dispatch(createGroup({ name: groupName, userId }));
      } else {
        dispatch(createGroup({ name: groupName }));
      }
      close();
    } else {
      // TODO: show validation error
    }
  };

  const handleOnClickCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setIsChecked(!isChecked);
  };

  const close = () => {
    setGroupName('');
    setIsChecked(false);
    setValidations({ name: '' });
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="create-group">
      <Modal.Header onClose={close}>
        <FormattedMessage id="groups.createGroup" />
      </Modal.Header>
      <Modal.Body>
        <InputField
          label={intl.formatMessage({ id: 'groups.groupNameTitle' })}
          size="large"
          feedback={validations.name ? validations.name : undefined}
          testId="create-group-name"
        >
          <Input
            ref={inputRef}
            size="large"
            value={groupName}
            placeholder={intl.formatMessage({
              id: 'groups.groupNamePlaceholder',
            })}
            onChange={handleOnNameChange}
            error={!!validations.name}
            onEnterKey={handleOnClickCreate}
            testId="create-group-name"
          />
        </InputField>

        <div>
          <Checkbox checked={isChecked ? 'checked' : 'unchecked'} onChange={handleOnClickCheck} testId="create-group-make-yourself-part-of-group-checkbox">
            <FormattedMessage id="groups.partOftheGroup" />
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="create-group-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleOnClickCreate}
          disabled={groupName === '' || !!validations.name}
          testId="create-group-submit-button"
        >
          <FormattedMessage id="global.create" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGroupModal;
