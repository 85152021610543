//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { NodeModel } from 'Editor/services/DataManager';

export default Mixin(
  (superclass: any) =>
    class StructureOperations extends superclass {
      destroy() {
        super.destroy();
      }

      removeBlockNodeOperation(actionContext: ActionContext, elements: string[] | string) {
        // TODO: validate if is a block element
        // TODO: check action context to consider offsets???

        let nodesToRemove;
        if (Array.isArray(elements)) {
          nodesToRemove = elements;
        } else {
          nodesToRemove = [elements];
        }

        for (let i = 0; i < nodesToRemove.length; i++) {
          this.dataManager?.structure?.removeChild(nodesToRemove[i], 0, {
            source: 'LOCAL_RENDER_OLD',
          });
        }

        actionContext.jsonChanges = true;
      }

      inserBlockNodeOperation(
        actionContext: ActionContext,
        data: Editor.Data.Node.Data | null,
        referenceElement: HTMLElement | null,
        position: 'BEFORE' | 'AFTER' = 'BEFORE',
      ) {
        // TODO: validate if type is allowed under page
        // TODO: check action context to consider offsets???

        if (data) {
          let referenceId;

          if (referenceElement) {
            referenceId = referenceElement.id;

            const enclosed_element = referenceElement.getAttribute('enclosed_element');
            if (enclosed_element) {
              let element = document.getElementById('enclosed_element');
              if (element) {
                referenceId = element.id;
              }
            }
          }

          if (position === 'AFTER' && referenceId != null) {
            referenceId = this.dataManager.structure.getNextChildId(referenceId);
          }

          const nodeModel = this.dataManager.models.get(
            this.dataManager?.models.TYPE_NAME.NODE,
            data.id,
          ) as NodeModel;

          nodeModel.create(data);

          // build and apply ops
          this.dataManager.structure.addChildBefore(data.id, referenceId, {
            source: 'LOCAL_RENDER_OLD',
          });

          actionContext.jsonChanges = true;
        }
      }
    },
);
