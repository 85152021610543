import ReactDOM from 'react-dom';
import { Dropdown, Toggle, usePopper } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import { useDeleteDeviceMutation, useDeleteSessionMutation } from '../UserSettingsApi';

type MoreOptionsCellProps = {
  data: Session;
  group: 'session' | 'device';
};

const MoreOptionsCell = ({ data, group }: MoreOptionsCellProps) => {
  const [deleteSession] = useDeleteSessionMutation();
  const [deleteDevice] = useDeleteDeviceMutation();
  const { isOpen, referenceProps, popperProps } = usePopper();

  return (
    <div>
      <Toggle
        size="small"
        variant="link"
        isToggled={isOpen}
        icon="MoreOptions"
        testId={`userPreferences-${group}-${data.token}-options-toggle`}
        {...referenceProps}
      />
      {ReactDOM.createPortal(
        <>
          <Dropdown {...popperProps}  testId={`userPreferences-${group}-${data.token}-options-dropdown`}>
            <Dropdown.Item
              size="large"
              onClick={() => {
                if (group === 'session') {
                  deleteSession(data.token);
                } else {
                  deleteDevice(data.token);
                }
              }}
              testId="remove-dropdown-item"
            >
              <FormattedMessage id="global.remove" />
            </Dropdown.Item>
          </Dropdown>
        </>,
        document.getElementById('ModalPortal') ?? document.body,
      )}
    </div>
  );
};

export default MoreOptionsCell;