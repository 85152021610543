import { useMemo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Select } from 'dodoc-design-system';
import {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useDispatch, useSelector } from '_common/hooks';

import { selectWordCountHasSelection } from 'Editor/redux/EditorStatusSlice';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { selectNavigationElements } from 'Editor/redux/NavigationSlice';

import Column, { RowObject } from './Column/Column';

import styles from './WordCountModal.module.scss';
import EditorManager from 'Editor/services/EditorManager';

type GroupedSelectOptions = NonNullable<SelectProps<SelectOption>['options']>;

const WordCountModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { documentOption, selectionOption } = useMemo(
    () => ({
      documentOption: { label: intl.formatMessage({ id: 'ALL_DOCUMENT' }), value: 'all' },
      selectionOption: { label: intl.formatMessage({ id: 'SELECTION' }), value: 'selection' },
    }),
    [],
  );

  const isOpen = useSelector((state) => state.modals.open.WordCountModal);
  const summaries = useSelector((state) => state.editor.wordCount.summary);
  const hasSelection = useSelector(selectWordCountHasSelection);
  const navElements = useSelector(selectNavigationElements);

  const [selectedOption, setSelectedOption] = useState<SelectOption>(
    hasSelection ? selectionOption : documentOption,
  );

  const [summaryList, setSummaries] = useState<{
    document: Editor.Data.Proofing.FullSummary | null;
    selection: Editor.Data.Proofing.FullSummary | null;
    outlineList: Record<string, Editor.Data.Proofing.FullSummary> | null;
  }>({ document: null, selection: null, outlineList: null });
  useEffect(() => {
    const loadSummary = async () => {
      const selection = (await EditorManager.getInstance().getCountSelectionElements()) ?? null;

      if (!active) {
        return;
      }

      setSummaries({ document: summaries.document, selection, outlineList: null });
    };

    let active = true;
    loadSummary();
    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    setSummaries((prev) => {
      return {
        ...prev,
        document: summaries.document,
      };
    });
    return () => {};
  }, [summaries]);

  const { textValues, otherContentValues } = useMemo<{
    textValues: RowObject[];
    otherContentValues: RowObject[];
  }>(() => {
    let countSummary: Editor.Data.Proofing.FullSummary | null;

    switch (selectedOption.value) {
      case documentOption.value:
        countSummary = summaryList.document;
        break;
      case selectionOption.value:
        countSummary = summaryList.selection;
        break;
      default:
        countSummary = navElements.find((data) => data.id === selectedOption.value)?.wc ?? null;
        break;
    }

    return {
      textValues: [
        {
          label: intl.formatMessage({ id: 'WORDS' }),
          quantity: countSummary?.WORDS ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'CHARACTERS_WITH_SPACES' }),
          quantity: countSummary?.CHARACTERS_WITH_SPACES ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'CHARACTERS_WITH_NO_SPACES' }),
          quantity: countSummary?.CHARACTERS_NO_SPACES ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'PARAGRAPHS' }),
          quantity: countSummary?.PARAGRAPHS ?? 0,
        },
      ],
      otherContentValues: [
        {
          label: intl.formatMessage({ id: 'REFERENCES' }),
          quantity: countSummary?.REFERENCES ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'FIGURES' }),
          quantity: countSummary?.FIGURES ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'TABLES' }),
          quantity: countSummary?.TABLES ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'EQUATIONS' }),
          quantity: countSummary?.EQUATIONS ?? 0,
        },
        {
          label: intl.formatMessage({ id: 'SMART_OBJECTS' }),
          quantity: countSummary?.SMART_OBJECTS ?? 0,
        },
      ],
    };
  }, [selectedOption, summaryList]);

  const options = useMemo<GroupedSelectOptions>(() => {
    const dynOptions: GroupedSelectOptions = [
      {
        label: intl.formatMessage({ id: 'NAVIGATION_PANE' }),
        options: navElements.map((navElement) => ({
          label: navElement.content,
          value: navElement.id,
        })),
      },
    ];
    return hasSelection
      ? [documentOption, selectionOption, ...dynOptions]
      : [documentOption, ...dynOptions];
  }, [hasSelection, navElements]);

  const close = () => {
    dispatch(closeAndResetModal('WordCountModal'));
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="92rem" testId="word-count">
      <Modal.Header onClose={close}>
        <FormattedMessage id="WORD_COUNT" />
      </Modal.Header>
      <Modal.Body>
        <Select
          width="41rem"
          value={selectedOption}
          options={options}
          onChange={setSelectedOption}
          clearable={false}
          testId="count-location"
        />
        <div className={styles.groupContent}>
          <Column
            title={intl.formatMessage({ id: 'TEXT' })}
            information={intl.formatMessage({
              id: 'TEXT_COUNT_CONSIDERS_ONLY_CHARACTERS_CONTAINED_WITHIN',
            })}
            rows={textValues}
          />
          <Column title={intl.formatMessage({ id: 'OTHER_CONTENT' })} rows={otherContentValues} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="word-count-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WordCountModal;
