import Option from './Option/Option';

import styles from './OptionsList.module.scss';

type OptionsListProps = {
  field: Affiliation.Field;
  options?: Affiliation.Value;
};

const OptionsList = ({ field, options }: OptionsListProps) => {
  const getItemTestId = (index: number) => {
    return `tag-aff-${field.toLocaleLowerCase()}-${index}`;
  };

  return (
    <div className={styles.list_options}>
      {options?.map((option, index) => (
        <div key={option} data-testid={getItemTestId(index)}>
          <Option key={option} field={field} option={option} testId={getItemTestId(index)} />
        </div>
      ))}
    </div>
  );
};

export default OptionsList;
