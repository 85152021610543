import { useMemo } from 'react';
import { Select } from 'dodoc-design-system';
import { dayjsWithTimezone } from 'utils';
import {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';

export const TIMEZONES: Partial<Record<Timezone, string>> = {
  'Pacific/Pago_Pago': 'Pago Pago',
  'US/Hawaii': 'Hawaii Time',
  'US/Pacific': 'Pacific Time',
  'America/Tijuana': 'Pacific Time - Tijuana',
  'US/Mountain': 'Mountain Time',
  'US/Arizona': 'Mountain Time - Arizona',
  'America/Chihuahua': 'Mountain Time - Chihuahua, Mazatlan',
  'US/Central': 'Central Time',
  'America/Mexico_City': 'Central Time - Mexico City',
  'America/Regina': 'Central Time - Regina',
  'America/Guatemala': 'Guatemala',
  'America/Bogota': 'Bogota',
  'US/Eastern': 'Eastern Time',
  'America/Lima': 'Lima',
  'America/Caracas': 'Caracas',
  'America/Halifax': 'Atlantic Time - Halifax',
  'America/Guyana': 'Guyana',
  'America/La_Paz': 'La Paz',
  'America/Buenos_Aires': 'Buenos Aires',
  'America/Godthab': 'Godthab',
  'America/Montevideo': 'Montevideo',
  'Canada/Newfoundland': 'Newfoundland Time - St. Johns',
  'America/Santiago': 'Santiago',
  'America/Sao_Paulo': 'Sao Paulo',
  'Atlantic/South_Georgia': 'South Georgia',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde',
  'Africa/Casablanca': 'Casablanca',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Europe/London': 'London',
  'Africa/Monrovia': 'Monrovia',
  'Africa/Algiers': 'Algiers',
  'Europe/Amsterdam': 'Amsterdam',
  'Europe/Berlin': 'Berlin',
  'Europe/Brussels': 'Brussels',
  'Europe/Budapest': 'Budapest',
  'Europe/Belgrade': 'Central European Time - Belgrade',
  'Europe/Prague': 'Central European Time - Prague',
  'Europe/Copenhagen': 'Copenhagen',
  'Europe/Madrid': 'Madrid',
  'Europe/Paris': 'Paris',
  'Europe/Rome': 'Rome',
  'Europe/Stockholm': 'Stockholm',
  'Europe/Vienna': 'Vienna',
  'Europe/Warsaw': 'Warsaw',
  'Europe/Athens': 'Athens',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Johannesburg': 'Johannesburg',
  'Europe/Helsinki': 'Helsinki',
  'Europe/Kiev': 'Kiev',
  'Europe/Kaliningrad': 'Moscow-01 - Kaliningrad',
  'Europe/Riga': 'Riga',
  'Europe/Sofia': 'Sofia',
  'Europe/Tallinn': 'Tallin',
  'Europe/Vilnius': 'Vilnius',
  'Europe/Istanbul': 'Istanbul',
  'Asia/Baghdad': 'Baghdad',
  'Africa/Nairobi': 'Nairobi',
  'Europe/Minsk': 'Minsk',
  'Asia/Riyadh': 'Riyadh',
  'Europe/Moscow': 'Moscow+00 - Moscow',
  'Asia/Tehran': 'Tehran',
  'Asia/Baku': 'Baku',
  'Europe/Samara': 'Moscow+01 - Samara',
  'Asia/Tbilisi': 'Tbilisi',
  'Asia/Yerevan': 'Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Karachi': 'Karachi',
  'Asia/Yekaterinburg': 'Moscow+02 - Yekaterinburg',
  'Asia/Tashkent': 'Tashkent',
  'Asia/Colombo': 'Colombo',
  'Asia/Almaty': 'Almaty',
  'Asia/Dhaka': 'Dhaka',
  'Asia/Rangoon': 'Rangoon',
  'Asia/Bangkok': 'Bangkok',
  'Asia/Jakarta': 'Jakarta',
  'Asia/Krasnoyarsk': 'Moscow+04 - Krasnoyarsk',
  'Asia/Shanghai': 'China Time - Beijing',
  'Asia/Hong_Kong': 'Hong Kong',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur',
  'Asia/Irkutsk': 'Moscow+05 - Irkutsk',
  'Asia/Singapore': 'Singapore',
  'Asia/Taipei': 'Taipei',
  'Asia/Ulaanbaatar': 'Ulaanbaatar',
  'Australia/Perth': 'Western Time - Perth',
  'Asia/Yakutsk': 'Moscow+06 - Yakutsk',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Tokyo',
  'Australia/Darwin': 'Central Time - Darwin',
  'Australia/Brisbane': 'Eastern Time - Brisbane',
  'Pacific/Guam': 'Guam',
  'Asia/Magadan': 'Moscow+07 - Magadan',
  'Asia/Sakhalin': 'Moscow+07 - Yuzhno-Sakhalinsk',
  'Pacific/Port_Moresby': 'Port Moresby',
  'Australia/Adelaide': 'Central Time - Adelaide',
  'Australia/Hobart': 'Eastern Time - Hobart',
  'Australia/Sydney': 'Eastern Time - Melbourne, Sydney',
  'Pacific/Guadalcanal': 'Guadalcanal',
  'Pacific/Noumea': 'Noumea',
  'Pacific/Majuro': 'Majuro',
  'Asia/Kamchatka': 'Moscow+09 - Petropavlovsk-Kamchatskiy',
  'Pacific/Auckland': 'Auckland',
  'Pacific/Fakaofo': 'Fakaofo',
  'Pacific/Fiji': 'Fiji',
  'Pacific/Tongatapu': 'Tongatapu',
  'Pacific/Apia': 'Apia',
};

export const parseTimezoneLabel = (timezone: Timezone) => {
  let now = dayjsWithTimezone(undefined, timezone);

  const hours = Math.abs(Math.floor(now.utcOffset() / 60)).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  const mins = Math.abs(now.utcOffset() % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  return `(GMT${now.utcOffset() < 0 ? '-' : '+'}${hours}:${mins}) ${TIMEZONES[timezone]}`;
};

type TimezoneSelectProps<
  Option extends SelectOption = SelectOption & { offset: number },
  IsMulti extends boolean = false,
> = {
  onChange: (newTimezone: Timezone) => void;
  value?: Timezone;
} & Omit<SelectProps<Option, IsMulti>, 'options' | 'searchable' | 'value' | 'onChange'>;

const TimezoneSelect = ({ onChange, ...props }: TimezoneSelectProps) => {
  const options = useMemo(() => {
    return Object.keys(TIMEZONES)
      .reduce((selectOptions, timezone) => {
        const typedTimezone = timezone in TIMEZONES ? (timezone as Timezone) : undefined;

        if (typedTimezone) {
          selectOptions.push({
            label: parseTimezoneLabel(typedTimezone),
            value: typedTimezone,
            offset: dayjsWithTimezone(undefined, typedTimezone).utcOffset(),
          });
        }
        return selectOptions;
      }, [] as (SelectOption & { offset: number })[])
      .sort((a, b) => a.offset - b.offset);
  }, []);

  return (
    <Select
      {...props}
      options={options}
      onChange={(selectedOption) => {
        onChange(selectedOption.value as Timezone);
      }}
      searchable
      value={options.find((option) => option.value === props.value)}
      testId="timezone"
    />
  );
};

export default TimezoneSelect;
