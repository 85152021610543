/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Annotations } from '../../models';
import { BaseController } from '../BaseController';
import { AnnotationsFactory } from './AnnotationsFactory';

export class AnnotationsController extends BaseController {
  private annotations?: Annotations;

  private annotationsFactory: AnnotationsFactory;

  constructor(Data: PDF.Data.State) {
    super(Data);

    this.annotationsFactory = new AnnotationsFactory(Data);

    this.loadAnnotations = this.loadAnnotations.bind(this);
    this.updatedAnnotations = this.updatedAnnotations.bind(this);
  }

  async start(documentId: string) {
    this.annotations = this.Data.models?.get('ANNOTATIONS', `AN${this.Data.context.documentId}`);
    this.annotations?.on('LOADED', this.loadAnnotations).on('UPDATED', this.updatedAnnotations);
    if (this.annotations?.loaded) {
      this.loadAnnotations();
    }
  }

  stop(): void {}

  destroy(): void {
    if (this.annotations) {
      this.annotations.off('LOADED', this.loadAnnotations);
      this.annotations.off('UPDATED', this.updatedAnnotations);
    }
  }

  private loadAnnotations() {
    this.Data.emit?.('LOAD_ANNOTATIONS', this.annotations?.getAll() || []);
  }

  private updatedAnnotations(
    data: any,
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ) {
    this.Data.emit?.('LOAD_ANNOTATIONS', this.annotations?.getAll() || []);
  }

  getAnnotations(pageNumber: number) {
    return this.Data.models?.getPageAnnotations(this.annotations?.id || '', pageNumber);
  }

  createNewAnnotation(creationData: PDF.AnnotationCreationData) {
    const annotation = this.annotationsFactory.getAnnotation(creationData);
    return this.annotations?.createNewAnnotation(annotation);
  }

  editAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    data: Partial<PDF.Annotation>,
  ) {
    return this.annotations?.editAnnotation(pageNumber, id, data);
  }

  editAnnotationContent(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    content: PDF.Annotation.ContentsType['content'],
  ) {
    return this.annotations?.editAnnotationContent(pageNumber, id, content);
  }

  deleteAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    return this.annotations?.deleteAnnotation(pageNumber, id);
  }

  replyToAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    replyContent: PDF.Annotation.ContentsType['content'],
  ) {
    return this.annotations?.replyToAnnotation(
      pageNumber,
      id,
      this.Data.users?.loggedUserId,
      replyContent,
    );
  }

  voteReplyToAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
    value: PDF.Annotation.Vote['value'],
  ) {
    return this.annotations?.voteReplyToAnnotation(
      pageNumber,
      annotationId,
      replyId,
      this.Data.users?.loggedUserId as string,
      value,
    );
  }

  editReplyAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
    content: PDF.Annotation.ContentsType['content'],
  ) {
    return this.annotations?.editReplyAnnotation(pageNumber, annotationId, replyId, content);
  }

  deleteReplyAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    replyId: PDF.Annotation.Reply['id'],
  ) {
    return this.annotations?.deleteReplyAnnotation(pageNumber, annotationId, replyId);
  }

  voteAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    annotationId: PDF.Annotation['id'],
    value: PDF.Annotation.Vote['value'],
  ) {
    return this.annotations?.voteAnnotation(
      pageNumber,
      annotationId,
      this.Data.users?.loggedUserId as string,
      value,
    );
  }

  resolveAnnotation(pageNumber: PDF.Annotation['pageNumber'], id: PDF.Annotation['id']) {
    return this.annotations?.resolveAnnotation(pageNumber, id);
  }

  changeAnnotationPriority(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    priority: PDF.Annotation['priority'],
  ) {
    return this.annotations?.changeAnnotationPriority(pageNumber, id, priority);
  }

  editAnnotationRect(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    boundingRect: PDF.AnnotationCreationData['boundingRect'],
    pageRect: PDF.AnnotationCreationData['pageRect'],
    viewportScale: PDF.AnnotationCreationData['viewportScale'],
  ) {
    const rect = this.annotationsFactory.getRelativeRectFromDOMRect(
      boundingRect,
      pageRect,
      viewportScale,
    );

    return this.annotations?.editAnnotationRect(pageNumber, id, rect);
  }

  changeTaskStatus(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    status: PDF.Annotation.TaskStatus,
  ) {
    return this.annotations?.changeTaskStatus(pageNumber, id, status);
  }

  async editTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    data: PDF.Annotation.EditTaskData,
  ) {
    return this.annotations?.editTask(pageNumber, id, data);
  }

  watchTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    watcherId: string = this.Data.users?.loggedUserId as string,
  ) {
    return this.annotations?.watchTask(pageNumber, id, watcherId);
  }

  removeWatchFromTask(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    watcherId: string = this.Data.users?.loggedUserId as string,
  ) {
    return this.annotations?.removeWatchFromTask(pageNumber, id, watcherId);
  }

  moveAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    delta: { x: number; y: number },
  ) {
    return this.annotations?.moveAnnotation(pageNumber, id, delta);
  }

  moveLineAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    position: 'start' | 'end',
    delta: { x: number; y: number },
  ) {
    return this.annotations?.moveLineAnnotation(pageNumber, id, position, delta);
  }

  resizeAnnotation(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    delta: { width: number; height: number; left: number; bottom: number },
  ) {
    return this.annotations?.resizeAnnotation(pageNumber, id, delta);
  }

  editAnnotationColor(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    newColor: PDF.Annotation['color'],
  ) {
    return this.annotations?.editAnnotationColor(pageNumber, id, newColor);
  }

  editAnnotationBorder(
    pageNumber: PDF.Annotation['pageNumber'],
    id: PDF.Annotation['id'],
    newBorder: PDF.Annotation['border'],
  ) {
    return this.annotations?.editAnnotationBorder(pageNumber, id, newBorder);
  }
}
