import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import Navigation from './Navigation';
import styles from './Navigation.module.scss';

const NavigationBoundary = () => (
  <div className={styles.navigation}>
    <IntlErrorBoundary size="medium" margin="16.5rem 0 0 0">
      <Navigation />
    </IntlErrorBoundary>
  </div>
);

export default NavigationBoundary;
