import { useState } from 'react';
import { useDownloadQuery } from '_common/services/api/FileApi';
import PreviewControls from './PreviewControls/PreviewControls';

type ImagePreviewProps = {
  object: doDOC.File;
};

const ImagePreview = ({ object }: ImagePreviewProps) => {
  const { data } = useDownloadQuery({ object_id: object.id });
  const [zoom, setZoom] = useState({ label: '100%', value: '1' });

  if (data) {
    const url = window.URL.createObjectURL(data);
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <img style={{ zoom: zoom.value }} src={url} alt={`${object.name} preview`} />
        <PreviewControls zoom={zoom} setZoom={setZoom} showPagination={false} object={object} />
      </div>
    );
  }

  return null;
};

export default ImagePreview;
