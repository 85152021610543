import { ChangeEventHandler, MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Button, Input, TextArea, InputField, ProgressBar } from 'dodoc-design-system';

import formatBytes from 'utils/formatBytes';

import { useSelector } from '_common/hooks';
import { Modals } from '_common/modals/ModalsSliceTypes';

import styles from '../CheckInModal.module.scss';

type UploadCleanProps = {
  reset: MouseEventHandler<HTMLSpanElement>;
  name: string;
  editing?: boolean;
  toggleEditingName: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onInputChanged: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  comment?: string;
  modal: Modals;
};

const UploadClean = ({
  reset,
  name,
  editing,
  toggleEditingName,
  onInputChanged,
  comment,
  modal,
}: UploadCleanProps) => {
  const intl = useIntl();

  // redux
  const loading = useSelector((state) => state.modals[modal].loading);
  const file = useSelector((state) => state.modals[modal].file);
  const uploadPercentage = useSelector((state) => state.modals[modal].uploadPercentage);

  const { size } = file;

  return (
    <div>
      <div className={styles.clean}>
        <span className={styles.clearButton} onClick={reset}>
          <Icon icon="CloseGrey" size={24} />
        </span>
        <Icon icon="Document" size={96} />
        {editing ? (
          <div>
            <Input
              name="name"
              onChange={onInputChanged}
              value={name}
              size="medium"
              placeholder=""
              testId="name"
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <Button size="medium" variant="primary" onClick={toggleEditingName} testId="save-button">
                <FormattedMessage id="global.save" />
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex' }}>
              <span className={styles.value}>{name}</span>
              <span className={styles.append}>{`(${formatBytes(size)})`}</span>
            </div>
            {uploadPercentage === 0 && (
              <div className={styles.link} onClick={toggleEditingName}>
                <FormattedMessage id="storage.modals.checkIn.click" />
              </div>
            )}
          </div>
        )}
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1.5rem',
            }}
          >
            <ProgressBar
              progress={uploadPercentage}
              testId="check-in-modal-uploading-clean-progressBar"
            />
          </div>
        )}
      </div>
      <div className={styles.comment}>
        <InputField
          size="large"
          label={intl.formatMessage({
            id: 'storage.modals.checkIn.leaveComment',
          })}
          testId="comment-field"
        >
          <TextArea
            size="large"
            name="comment"
            value={comment}
            placeholder={intl.formatMessage({
              id: 'storage.modals.checkIn.leaveCommentPlaceholder',
            })}
            onChange={onInputChanged}
            testId="leave-a-comment-textarea"
          />
        </InputField>
      </div>
    </div>
  );
};

export default UploadClean;
