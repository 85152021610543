import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useSelector } from '_common/hooks';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setWordHasChanged } from '../../SpellcheckSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import Suggestion from '../Suggestion/Suggestion';

import styles from './OccurrenceActions.module.scss';

type Occurrence = Editor.SpellCheck.Occurrence;

//TODO: Check if occurrence type
type OccurrenceActionsProps = {
  occurrence?: Occurrence;
  handleFindNext: (fromStart?: boolean) => void;
};

const OccurrenceActions = ({ occurrence, handleFindNext }: OccurrenceActionsProps) => {
  const dispatch = useDispatch();

  const wordHasChanged = useSelector((state) => state.editor.spellcheck.wordHasChanged);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);

  useEffect(() => {
    dispatch(setWordHasChanged(false));
    setActiveSuggestionIndex(0);
  }, [occurrence]);

  const handleChangeAllOccurrences = async () => {
    if (occurrence) {
      try {
        const data = await EditorManager.getInstance().findMatch(occurrence.word, true, true);

        if (data?.matches) {
          dispatch(
            openAndUpdateModal({
              modal: 'ConfirmationModal',
              data: {
                title: 'CHANGE_ALL_OCCURRENCES_OF_ERROR',
                message: 'CONFIRM_CHANGE_QUANTITY_OCCURRENCES_TO_NEW_WORD',
                messageValues: {
                  noOccurrences: data.matches.length,
                  error: occurrence.word,
                  newWord: occurrence.suggestions[activeSuggestionIndex],
                },
                confirmButtonTextId: 'CHANGE_ALL_OCCURRENCES',
                confirmButtonType: 'primary',
                cancelButtonShow: true,
                cancelButtonTextId: 'global.cancel',
                actionCode: 'spellcheckChangeAll',
                actionValue: {
                  rangeData: occurrence.rangeData,
                  word: occurrence.word,
                  suggestion: occurrence.suggestions[activeSuggestionIndex],
                },
              },
            }),
          );
        }
      } catch (e) {
        notify({
          type: 'error',
          title: 'CANNOT_COMPLETE_THIS_ACTION',
          message: 'IF_PROBLEM_PERSISTS_CONTACT_SUPPORT',
        });
      }
    }
  };

  const handleChangeOccurrence = (activeSuggestion = activeSuggestionIndex) => {
    if (occurrence) {
      if (
        EditorManager.getInstance().restoreSelectionToError(occurrence.rangeData, occurrence.word)
      ) {
        EditorManager.getInstance()
          .replaceWord(occurrence, occurrence.suggestions[activeSuggestion])
          .then(() => {
            handleFindNext();
          })
          .catch(() => {
            notify({
              type: 'error',
              title: 'CANNOT_COMPLETE_THIS_ACTION',
              message: 'IF_PROBLEM_PERSISTS_CONTACT_SUPPORT',
            });
          });
      } else {
        dispatch(setWordHasChanged(true));
      }
    }
  };

  const handleIgnoreAllOccurrences = () => {
    if (occurrence) {
      if (
        EditorManager.getInstance().restoreSelectionToError(occurrence.rangeData, occurrence.word)
      ) {
        EditorManager.getInstance()
          .ignoreAllOccurrences(occurrence.word, occurrence.lang)
          .then(() => {
            notify({
              type: 'success',
              title: 'IGNORED_ALL_OCCURRENCES_OF_A_WORD',
              message: 'ALL_OCCURRENCES_OF_A_WORD_WERE_IGNORED',
              messageValues: { word: occurrence.word },
            });
            handleFindNext();
          })
          .catch(() => {
            notify({
              type: 'error',
              title: 'CANNOT_COMPLETE_THIS_ACTION',
              message: 'IF_PROBLEM_PERSISTS_CONTACT_SUPPORT',
            });
          });
      } else {
        dispatch(setWordHasChanged(true));
      }
    }
  };

  const handleIgnoreOccurrence = () => {
    handleFindNext();
  };

  const handleAddWordToDictionary = () => {
    if (occurrence) {
      if (
        EditorManager.getInstance().restoreSelectionToError(occurrence.rangeData, occurrence.word)
      ) {
        EditorManager.getInstance()
          .addWordToDictionary(occurrence.word, occurrence.lang)
          .then(() => {
            handleFindNext();
            notify({
              type: 'success',
              title: 'WORD_ADDED_TO_DICTIONARY',
              message: 'THE_WORD_WAS_ADDED_TO_DICTIONARY',
              messageValues: { word: occurrence.word },
            });
          })
          .catch(() => {
            notify({
              type: 'error',
              title: 'CANNOT_COMPLETE_THIS_ACTION',
              message: 'IF_PROBLEM_PERSISTS_CONTACT_SUPPORT',
            });
          });
      } else {
        dispatch(setWordHasChanged(true));
      }
    }
  };

  const setActive = (index: number) => {
    if (occurrence) {
      if (
        EditorManager.getInstance().restoreSelectionToError(occurrence.rangeData, occurrence.word)
      ) {
        setActiveSuggestionIndex(index);
      } else {
        dispatch(setWordHasChanged(true));
      }
    }
  };

  return (
    <>
      <div>
        <FormattedMessage id="SUGGESTIONS_SPELL_CHECK" />
      </div>
      {occurrence && occurrence.suggestions?.length > 0 ? (
        <div className={styles.suggestions}>
          {wordHasChanged ? (
            <div className={styles.errorMessage}>
              <FormattedMessage id="THIS_WORD_HAS_EITHER_BEEN_EDITED_OR_REMOVED" />
            </div>
          ) : (
            occurrence?.suggestions?.map((suggestion: string, index: number) => {
              return (
                <Suggestion
                  key={`suggestion-${suggestion}-${index}`}
                  suggestion={suggestion}
                  active={index === activeSuggestionIndex}
                  index={index}
                  setActive={setActive}
                  handleChangeOccurrence={handleChangeOccurrence}
                />
              );
            })
          )}
        </div>
      ) : (
        <div className={styles.no_suggestions}>
          <FormattedMessage id="THERE_ARE_NO_SUGGESTIONS_FOR_WORD_AND_DICTIONARY" />
        </div>
      )}
      <div className={styles.buttons}>
        <Button
          size="medium"
          onClick={handleChangeAllOccurrences}
          fullWidth
          margin="0 1rem 1.5rem 0"
          disabled={
            wordHasChanged ||
            !(occurrence && occurrence.suggestions?.length > 0) ||
            isReadOnlyMode ||
            isPageLayout
          }
          testId="spell-check-change-all-button"
        >
          <FormattedMessage id="CHANGE_ALL" />
        </Button>
        <Button
          size="medium"
          onClick={() => handleChangeOccurrence()}
          fullWidth
          disabled={
            wordHasChanged ||
            !(occurrence && occurrence.suggestions?.length > 0) ||
            isReadOnlyMode ||
            isPageLayout
          }
          testId="spell-check-change-button"
        >
          <FormattedMessage id="CHANGE" />
        </Button>
      </div>
      <div className={styles.buttons}>
        <Button
          size="medium"
          onClick={handleIgnoreAllOccurrences}
          fullWidth
          margin="0 1rem 1rem 0"
          testId="spell-check-ignore-all-button"
        >
          <FormattedMessage id="IGNORE_ALL" />
        </Button>
        <Button
          size="medium"
          onClick={handleIgnoreOccurrence}
          fullWidth
          testId="spell-check-ignore-button"
        >
          <FormattedMessage id="IGNORE" />
        </Button>
      </div>
      <div>
        <Button
          size="medium"
          onClick={handleAddWordToDictionary}
          fullWidth
          testId="spell-check-add-to-dictionary-button"
        >
          <FormattedMessage id="ADD_WORD_TO_DICTIONARY" />
        </Button>
      </div>
      <div className={styles.untrackedNote}>
        <FormattedMessage id="NOTE_ACTIONS_CANNOT_BE_UNDONE_AND_UNTRACKED" />
      </div>
    </>
  );
};

export default OccurrenceActions;
