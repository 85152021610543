import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Input, InputField, Select } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { parseMeasurement } from 'utils';

import styles from './TabStopField.module.scss';

export type TempTabStop = {
  value?: string | number;
  measure?: Editor.Tabulations.Measure;
  hasError?: boolean;
};

type TabStopFieldProps = {
  initialValue?: TempTabStop['value'];
  initialMeasure?: TempTabStop['measure'];
  fieldLabel?: string;
  selectWidth: string;
  onChange: (data: TempTabStop) => void;
  testId: string;
};

const DEFAULT_VALUE: number = 1.27;
const DEFAULT_MEASURE: Editor.Tabulations.Measure = 'cm';
const DEFAULT_MIN: { value: number; unit: Editor.Tabulations.Measure } = { value: 0, unit: 'cm' };
const DEFAULT_MAX: { value: number; unit: Editor.Tabulations.Measure } = {
  value: 55.87,
  unit: 'cm',
};

const TabStopField = ({
  initialValue,
  initialMeasure,
  fieldLabel,
  selectWidth,
  onChange,
  testId,
}: TabStopFieldProps) => {
  const intl = useIntl();

  const MEASURES_OPTIONS: {
    value: Editor.Tabulations.Measure;
    label: string | Editor.Tabulations.Measure;
  }[] = [
    { value: 'cm', label: `${intl.formatMessage({ id: 'CENTIMETERS' })} (cm)` },
    { value: 'in', label: `${intl.formatMessage({ id: 'INCHES' })} (in)` },
    { value: 'pt', label: `${intl.formatMessage({ id: 'POINTS' })} (pt)` },
    { value: 'px', label: `${intl.formatMessage({ id: 'PIXELS' })} (px)` },
  ];

  const [value, setValue] = useState<string | number | undefined>(initialValue);
  const [measure, setMeasure] = useState<Editor.Tabulations.Measure>(
    initialMeasure ?? DEFAULT_MEASURE,
  );
  const [lastValidDefaultStop, setLastValidDefaultStop] = useState<string | number>(
    initialValue ?? DEFAULT_VALUE,
  );
  const [feedback, setFeedback] = useState<string>();

  useEffect(() => {
    let hasError = false;
    const parsedMax =
      parseMeasurement(`${DEFAULT_MAX.value}`, measure, {
        defaultUnit: DEFAULT_MAX.unit,
        max: undefined,
      }) ?? DEFAULT_MAX.value;
    const parsedMin =
      parseMeasurement(`${DEFAULT_MIN.value}`, measure, {
        defaultUnit: DEFAULT_MIN.unit,
        min: undefined,
      }) ?? DEFAULT_MIN.value;

    if (value != null && measure != null && value !== '') {
      if (+value < parsedMin || +value > parsedMax) {
        hasError = true;
      } else {
        hasError = false;
        setLastValidDefaultStop(+value);
      }
    }
    if (hasError) {
      setFeedback(
        intl.formatMessage({ id: 'CHOOSE_A_VALUE_BETWEEN' }, { min: parsedMin, max: parsedMax }),
      );
    } else {
      setFeedback(undefined);
    }
    onChange({ value, measure, hasError });
  }, [value, measure]);

  const handleBlur: InputProps['onBlur'] = () => {
    if (value == null || value === '') {
      setValue(lastValidDefaultStop);
    }
  };

  const handleDefaultTabStopChange: InputProps['onChange'] = (e) => {
    setValue(e.target.value);
  };

  return (
    <InputField
      width="100%"
      size="large"
      label={fieldLabel}
      feedback={feedback}
      testId={`${testId}-field`}
    >
      <div className={styles.inputs}>
        <Input
          type="number"
          allowDecimal
          value={value !== undefined ? `${value}` : undefined}
          onBlur={handleBlur}
          onChange={handleDefaultTabStopChange}
          size="large"
          placeholder={intl.formatMessage({ id: 'ENTER_TAB_STOP_VALUE' })}
          margin="0 2rem 0 0"
          width="100%"
          error={!!feedback}
          testId={`${testId}-field-value`}
        />
        <Select
          width={selectWidth}
          //@ts-expect-error Value is be different from an option item
          options={MEASURES_OPTIONS}
          value={
            MEASURES_OPTIONS.find((option) => option.value === measure)
              ? {
                  value: measure,
                  label: measure,
                }
              : undefined
          }
          onChange={(newValue) => {
            let parsedValue = parseMeasurement(
              `${value}`,
              newValue.value as Editor.Tabulations.Measure,
              {
                defaultUnit: measure,
                max: undefined,
              },
            );

            setValue(parsedValue ?? undefined);
            if (parsedValue) {
              setLastValidDefaultStop(parsedValue);
            }
            setMeasure(newValue.value as Editor.Tabulations.Measure);
          }}
          menuPosition="fixed"
          menuWidth="22rem"
          clearable={false}
          testId={`${testId}-field-unit`}
        />
      </div>
    </InputField>
  );
};

export default TabStopField;
