import { Dropdown, Toggle, usePopper } from 'dodoc-design-system';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type WopiActionsProps = {
  object: doDOC.File;
};

const WopiActions = ({ object }: WopiActionsProps) => {
  const dispatch = useDispatch();
  const { popperProps, referenceProps, isOpen } = usePopper();
  const userId = useSelector((state) => state.auth.userId);

  const openInWopi = (action: string, app: string) => {
    const wopiAccess = localStorage.getItem(`${userId}-WOPI_ALLOW_ACCESS`);

    if (wopiAccess) {
      window.open(`/extensions/wopi/${object.id}/${app}/${action}`);
    } else {
      dispatch(
        openAndUpdateModal({
          modal: 'WOPIRedirectionModal',
          data: { app, action, id: object.id },
        }),
      );
    }
  };

  const options = useMemo(() => {
    const options: { app: 'Word' | 'Excel' | 'PowerPoint'; action: string }[] = [];
    const wopiActions = object.wopi?.actions;
    if (wopiActions) {
      wopiActions
        .filter(({ name }) => ['view', 'edit'].includes(name))
        .forEach(({ app, name }) => {
          options.push({ app, action: name });
        });
    }
    return options;
  }, [object]);

  if (options.length > 0) {
    return (
      <>
        <Toggle
          variant="link"
          icon="NavMoreOptions"
          size="medium"
          margin="0 0 0 0.5rem"
          isToggled={isOpen}
          testId="object-preview-wopi-toggle"
          {...referenceProps}
        />
        <Dropdown width="42rem" {...popperProps} testId="object-preview-wopi-dropdown">
          {options.map(({ action, app }) => {
            return (
              <Dropdown.Item
                key={`${app}-${action}`}
                size="large"
                onClick={() => openInWopi(action, app)}
                prefixIcon={app}
                testId={`object-preview-wopi-${action}`}
              >
                <FormattedMessage
                  id={`WOPI_${action.toUpperCase()}`}
                  values={{ application: app }}
                />
              </Dropdown.Item>
            );
          })}
        </Dropdown>
      </>
    );
  }

  return null;
};

export default WopiActions;
