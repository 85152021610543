import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '..';

export class TabViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.TabElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // subtype
    st: {
      parse(node, builder, attributeName?) {
        if (node.dataset.st != null) {
          builder.addProperty('st', node.dataset.st);
        }
      },
      render(json, node, baseNodeData?, attributeName?, options?) {
        if (json.properties?.st != null) {
          node.dataset.st = json.properties.st;
        }
      },
      remove(node, attributeName?, options?) {
        delete node.dataset.st;
      },
    },
    r: {
      parse(node, builder, attributeName?) {
        if (node.dataset.r != null) {
          builder.addProperty('r', node.dataset.r);
        }
      },
      render(json, node, baseNodeData?, attributeName?, options?) {
        if (json.properties?.r != null) {
          node.dataset.r = json.properties.r;
        }
      },
      remove(node, attributeName?, options?) {
        delete node.dataset.r;
      },
    },
    a: {
      parse(node, builder, attributeName?) {
        if (node.dataset.a != null) {
          builder.addProperty('a', node.dataset.a);
        }
      },
      render(json, node, baseNodeData?, attributeName?, options?) {
        if (json.properties?.a != null) {
          node.dataset.a = json.properties.a;
        }
      },
      remove(node, attributeName?, options?) {
        delete node.dataset.a;
      },
    },
    l: {
      parse(node, builder, attributeName?) {
        if (node.dataset.l != null) {
          builder.addProperty('l', node.dataset.l);
        }
      },
      render(json, node, baseNodeData?, attributeName?, options?) {
        if (json.properties?.l != null) {
          node.dataset.l = json.properties.l;
        }
      },
      remove(node, attributeName?, options?) {
        delete node.dataset.l;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data) {
    const node = DOMElementFactory.buildElement(ELEMENTS.TabElement.TAG);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}
