import { Logger } from '_common/services';
import { Command } from '../Command';
import HistoryManager from 'Editor/services/HistoryManager/HistoryManager';

export class RedoCommand extends Command {
  protected options?: Editor.Edition.HistoryCommandOptions;

  constructor(context: Editor.Edition.Context, options?: Editor.Edition.HistoryCommandOptions) {
    super(context, { scrollIntoSelection: false });

    this.options = options;
  }

  async handleExec() {
    if (this.context.debug) {
      Logger.trace('RedoCommand exec', this);
    }

    // TODO: refactor history manager into commands
    await HistoryManager.getInstance().redo(this.options);
  }
}
