import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ObjectPreviewState = {
  id: string | null;
};

const initialState: ObjectPreviewState = {
  id: null,
};

const ObjectPreviewSlice = createSlice({
  name: 'ObjectPreview',
  initialState,
  reducers: {
    setObjectPreview: (state, action: PayloadAction<{ id: ObjectPreviewState['id'] }>) => {
      state.id = action.payload.id;
    },
  },
});

export const { setObjectPreview } = ObjectPreviewSlice.actions;

export default ObjectPreviewSlice.reducer;
