import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

type SliceState = {
  visible: boolean;
};

const initialState: SliceState = {
  visible: true,
};

const hiddenContentSlice = createSlice({
  name: 'EDITOR_HIDDEN_CONTENT',
  initialState,
  reducers: {
    setVisibleState: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const { setVisibleState } = hiddenContentSlice.actions;

export default hiddenContentSlice.reducer;
