import PDFNotificationsCenter from 'PDF/PDFNotificationsCenter';
import { InteractionController } from '_common/components';
import { useSelector } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import {
  AvatarList,
  EditableName,
  ExportButton,
  Logo,
  ObjectStatus,
  ShareButton,
  Title,
  UserCenter,
} from '_common/suite/components';

const PDFTitle = () => {
  const usersOnline = useSelector((state) => state.pdf.general.usersOnline);
  const object = useSuiteObject();
  // TODO: change this be in a centralized onboarding controller
  // will have to use another way to ref the intended html element
  /* const statusLabelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (statusLabelRef?.current) {
      dispatch(
        setPulseData({
          statusLabelRect: {
            top: statusLabelRef.current.offsetTop,
            left: statusLabelRef.current.offsetLeft,
            height: statusLabelRef.current.offsetHeight,
            width: statusLabelRef.current.offsetWidth,
          },
        }),
      );
    }
  }, [object?.name]); */

  return (
    <Title divider>
      <InteractionController environment="dopdf" style={{ width: '100%', alignItems: 'center' }}>
        <Logo object={object} />
        <EditableName object={object} />
        <ObjectStatus object={object} />
        <AvatarList users={usersOnline ?? []} />
        <ShareButton object={object} />
        <ExportButton object={object} />
        <PDFNotificationsCenter />
        <UserCenter />
      </InteractionController>
    </Title>
  );
};

export default PDFTitle;
