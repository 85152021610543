import { Dispatch, useEffect, SetStateAction, RefObject } from 'react';
import { useSelector } from '_common/hooks';

const LANGUAGES = [
  { value: 'latex', label: 'LaTeX' },
  { value: 'mathml', label: 'MathML' },
  { value: 'asciimath', label: 'ASCIImath' },
] as const;

const useEquation = (
  text: string,
  language: typeof LANGUAGES[number]['value'],
  ref: RefObject<HTMLDivElement>,
  setValid: Dispatch<SetStateAction<boolean>>,
) => {
  const isOpen = useSelector((state) => state.modals.open.EquationsModal);

  useEffect(() => {
    if (isOpen) {
      let isValid;

      if (ref.current) {
        ref.current.innerHTML = '';
      }

      if (text && ref.current) {
        try {
          ref.current.appendChild(MathJax.mathml2svg(text));
          MathJax.startup.document.clear();
          MathJax.startup.document.updateDocument();

          isValid = !ref.current.querySelector('.noError');
        } catch (error) {
          isValid = false;
        } finally {
          if (!isValid) {
            ref.current.innerHTML = '';
          }
          setValid(!!isValid);
        }
      }
    }
  }, [text, language, isOpen]);
};

export default useEquation;
