import { useEffect } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import { closeModal } from '_common/modals/ModalsSlice';
import { getTrackingState } from 'Editor/redux/TrackingSlice';
import EditorManager from 'Editor/services/EditorManager';
import styles from './SuggestionModeLockInfoModal.module.scss';

const SuggestionModeLockInfoModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.SuggestionModeLockInfoModal);
  const tracking = useSelector(getTrackingState);
  const documentId = useSelector((state) => state.editor.status.documentId);
  const userId = useSelector((state) => state.auth.userId);

  const persistData = () => {
    window.localStorage.setItem(`${userId}-${documentId}-lock-${tracking.lock}-read`, `true`);
    window.localStorage.setItem(`${userId}-${documentId}-lock-${!tracking.lock}-read`, `false`);
  };

  useEffect(() => {
    return () => {
      persistData();
    };
  }, []);

  const close = () => {
    persistData();
    dispatch(closeModal('SuggestionModeLockInfoModal'));
    EditorManager.getInstance().restore();
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="suggestion-mode-lock-info">
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={
            tracking.lock
              ? 'SUGGESTIONS.SUGGESTIONS_LOCKED_TITLE'
              : 'SUGGESTIONS.SUGGESTIONS_UNLOCKED_TITLE'
          }
        />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          {tracking.lock ? (
            <FormattedMessage
              id="SUGGESTIONS.SUGGESTIONS_LOCKED_MESSAGE"
              values={{
                activeState: tracking.state ? (
                  <b>{intl.formatMessage({ id: 'global.on' }).toUpperCase()}</b>
                ) : (
                  <b>{intl.formatMessage({ id: 'global.off' }).toUpperCase()}</b>
                ),
              }}
            />
          ) : (
            <FormattedMessage id="SUGGESTIONS.SUGGESTIONS_UNLOCKED_MESSAGE" />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="medium"
          variant="primary"
          onClick={close}
          testId="suggestion-mode-lock-info-back-to-document-button"
        >
          <FormattedMessage id="BACK_TO_DOCUMENT" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuggestionModeLockInfoModal;
