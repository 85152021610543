import { useIntl } from "react-intl"
import { toRoman } from "utils"
import styles from './Title.module.scss';

type NoteTilteProps = {
  type: 'footnote' | 'endnote';
  order: number;
}

const NoteTitle = ({ type, order }: NoteTilteProps) => {
  const intl = useIntl();

  return (
    <div className={styles.title}>
      <div className={styles.order}>{`#${type === 'footnote' ? order : toRoman(order)}`}</div>
      <div className={styles.label}>{`${intl.formatMessage({ id: type.toUpperCase() })}`}</div>
    </div>
  )
}

export default NoteTitle;