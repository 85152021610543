import { useIntl } from 'react-intl';
import { Tag } from 'dodoc-design-system';
import { TagProps } from 'dodoc-design-system/build/types/Components/Tag/Tag';
import styles from './Tags.module.scss';

type Props = {
  tags: Tag[];
  removeTag: (value: Tag) => void;
  canEdit: boolean;
  testId?: string;
};

const Tags = ({ tags, removeTag, canEdit, testId }: Props) => {
  const intl = useIntl();

  // TODO: Refactor usages of the <Tags /> component so this handle is no longer necessary
  const handleRemoveTag: TagProps['onClick'] = (_, value) => {
    if (removeTag) {
      removeTag(value);
    }
  };
  return (
    <div className={styles.root}>
      {tags.map((tag) => (
        <Tag
          key={tag}
          disabled={!canEdit || !removeTag}
          onClick={handleRemoveTag}
          value={tag}
          margin="0 1rem 1rem 0"
          tooltipProps={{
            content: intl.formatMessage({ id: 'global.remove' }),
            placement: 'bottom',
          }}
          testId={`${testId}-${tag}-tag`}
        >
          {tag}
        </Tag>
      ))}
    </div>
  );
};

export default Tags;
