import { forwardRef, Ref } from 'react';
import Toggle, { ToggleProps } from './Toggle';
import CommentCard from 'Presentation/Sidebar/CommentsPanel/CommentCard/CommentCard';
import TaskCard from 'Presentation/Cards/TaskCard';

type ViewCardToggleProps = {
  object: Presentation.Data.Comment | Presentation.Data.Task;
} & Omit<ToggleProps, 'children'>;

const ViewCardToggle = forwardRef(
  ({ type, object, ...toggleProps }: ViewCardToggleProps, ref: Ref<HTMLDivElement>) => {
    const renderCard = () => {
      switch (type) {
        case 'comment': {
          return <CommentCard comment={object as Presentation.Data.Comment} />;
        }
        case 'task': {
          return (
            <TaskCard
              task={object as Presentation.Data.Task}
              testId={`slide-task-card-${object.id}`}
            />
          );
        }
        default: {
          return null;
        }
      }
    };

    return (
      <Toggle type={type} ref={ref} {...toggleProps}>
        {renderCard()}
      </Toggle>
    );
  },
);

export default ViewCardToggle;
