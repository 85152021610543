import { useDebounce, useDispatch } from '_common/hooks';
import { Input } from 'dodoc-design-system';
import { CSSProperties, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

type SearchProps = {
  search: ActionCreatorWithOptionalPayload<string | undefined>;
  margin: CSSProperties['margin'];
};

const Search = ({ search, margin }: SearchProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [value, setValue] = useState<string>();

  const debounceInputValue = useDebounce(value, 200);

  const handleSearchByChange: InputProps['onChange'] = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    dispatch(search(debounceInputValue));
  }, [debounceInputValue]);

  return (
    <Input
      size="medium"
      placeholder={intl.formatMessage({ id: 'SEARCH_COMMENTS' })}
      value={value}
      prefix="NavSearchGrey"
      onChange={handleSearchByChange}
      testId="sidebar-comments-search"
      disabled={false}
      margin={margin}
    />
  );
};

export default Search;
