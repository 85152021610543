import { BaseOperation } from '../BaseOperation';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

export class UpdateRowHeightsOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  rowHeights: number[];

  constructor(baseModel: Editor.Data.Node.Model, tableId: string, rowHeights: number[]) {
    super(baseModel);

    this.tableId = tableId;
    this.rowHeights = rowHeights;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const tableInfo = this.model.getChildInfoById(this.tableId);
    const table: Editor.Data.Node.Data = tableInfo.data;
    const tableBody = table.childNodes?.[0];
    const rowsData = tableBody?.childNodes;

    if (rowsData) {
      for (let i = 0; i < this.rowHeights.length; i++) {
        const row = rowsData[i];
        if (row) {
          let rh = row.properties?.rh;

          if (rh != null) {
            if (+rh !== this.rowHeights[i]) {
              this.ops.push(
                RealtimeOpsBuilder.objectReplace(rh, this.rowHeights[i], [
                  ...tableInfo.path,
                  this.model.KEYS.CHILDNODES,
                  '0',
                  this.model.KEYS.CHILDNODES,
                  `${i}`,
                  this.model.KEYS.PROPERTIES,
                  'rh',
                ]),
              );
            }
          } else {
            this.ops.push(
              RealtimeOpsBuilder.objectInsert(this.rowHeights[i], [
                ...tableInfo.path,
                this.model.KEYS.CHILDNODES,
                '0',
                this.model.KEYS.CHILDNODES,
                `${i}`,
                this.model.KEYS.PROPERTIES,
                'rh',
              ]),
            );
          }
        }
      }

      const op = this.getObjectOperationforPathValue(table?.properties?.ar, false, [
        ...tableInfo.path,
        this.model.KEYS.PROPERTIES,
        'ar',
      ]);
      if (op) {
        this.ops.push(op);
      }
    }

    return this;
  }
}
