import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { notify } from '_common/components/ToastSystem';
import { InstanceService } from '_common/services';

import { clearSelection, removeFromList } from '_common/components/Table/TableSlice';

const SLICE_NAME = 'RECYCLE_PAGE';

// #region AsyncThunks
export const restoreObject = createAsyncThunk(
  `${SLICE_NAME}/restoreObject`,
  async ({ objectId }: { objectId: ObjectId }, thunkAPI) => {
    try {
      await new InstanceService({ errorsExpected: [403, 404] }).restoreRecycle(objectId);

      notify({
        type: 'success',
        title: 'global.success',
        message: 'storage.notifications.restore.messageSuccess',
      });

      thunkAPI.dispatch(removeFromList({ identity: 'recycle', objectId }));
      thunkAPI.dispatch(clearSelection());
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        switch (error?.response?.status) {
          case 403:
            notify({
              type: 'error',
              title: 'global.error',
              message: 'storage.notifications.restore.insufficientPermissions',
            });
            break;
          case 404:
            notify({
              type: 'error',
              title: 'global.error',
              message: 'storage.notifications.restore.missingOrignialLocation',
            });
            break;
        }
      }
    }
  },
);
// #endregion

// #region Selectors
// #endregion

// #region Slice
const RecyclePageSlice = createSlice({
  name: SLICE_NAME,
  initialState: {},
  reducers: {},
});

export default RecyclePageSlice.reducer;
// #endregion
