import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Tooltip, Checkbox } from 'dodoc-design-system';
import styles from './TemplateOptions.module.scss';

type Options = {
  show?: boolean;
  update?: boolean;
  template?: MyAny; //TODO:TS this should have the type of DataManager template
};
export type TemplateOptionsProps = {
  options: Options;
  setOptions: (props: Options) => void;
};

const TemplateOptions = ({ options, setOptions }: TemplateOptionsProps) => {
  const intl = useIntl();

  const handleOnChange = () => {
    setOptions({ ...options, update: !options.update });
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <FormattedMessage id="TEMPLATE_OPTIONS" />
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          checked={options.update ? 'checked' : 'unchecked'}
          onChange={handleOnChange}
          size="x-small"
          testId="automatically-update-document-styles-checkbox"
        >
          <FormattedMessage id="AUTOMATICALLY_UPDATE_DOCUMENT_STYLES" />
        </Checkbox>
        <Tooltip
          placement="bottom"
          content={intl.formatMessage({ id: 'AUTOMATICALLY_UPDATE_DOCUMENT_STYLES_TOOLTIP' })}
          testId="automatically-update-document-styles-information-tooltip"
        >
          <span className={styles.icon}>
            <Icon icon="InformationBlue" size={16} />
          </span>
        </Tooltip>
      </div>
      <div className={styles.templateInfo}>
        <FormattedMessage id="WORD_TEMPLATE" />
        <div className={styles.name} title={options.template}>
          {options.template}
        </div>
      </div>
    </div>
  );
};

export default TemplateOptions;
