import { NodeUtils } from 'Editor/services/DataManager';
import { PathUtils } from 'Editor/services/_Common/Selection';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';
import { TableStylesOperations } from './TableStylesOperations';

export class DistributeOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  cellsIndex?: number[];
  selectedCells: Editor.Edition.CellInfo[];
  distributeType: Editor.Edition.DistributeType;
  rowsHeigth: number[];
  columnWidth: number[];
  tableInfo?: {
    data: Editor.Data.Node.Data;
    path: Realtime.Core.RealtimePath;
  };
  totalTableHeigth: number;
  totalTableWidth: number;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
    distributeType: Editor.Edition.DistributeType,
    rowsHeigth: number[],
    columnWidth: number[],
  ) {
    super(baseModel);
    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.distributeType = distributeType;
    this.cellsIndex = TableUtils.getCellsIndex(selectedCells);
    this.rowsHeigth = rowsHeigth;
    this.columnWidth = columnWidth;

    this.totalTableHeigth = this.rowsHeigth.reduce((total, rowHeigth) => total + rowHeigth, 0);
    this.totalTableWidth = this.columnWidth.reduce((total, columnWidth) => total + columnWidth, 0);

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const baseData = this.model.selectedData();

    if (!baseData) {
      return this;
    }

    this.tableInfo = this.model.getChildInfoById(this.tableId);

    if (PathUtils.isValidSelectionPath(this.tableInfo.path)) {
      if (this.tableInfo.data && this.tableInfo.data.childNodes?.length) {
        const tableCells = NodeUtils.querySelectorInData(this.tableInfo.data, ['tblc']);
        const cellsInfo = TableUtils.getCellInfo(tableCells);
        const rowsIndex = TableUtils.getRowsIndex(cellsInfo);
        const columnsIndex = TableUtils.getCellsIndex(cellsInfo);
        const tableId = this.tableInfo.data.id;
        const selectedCellsIds: string[] = [];

        for (let i = 0; i < tableCells.length; i++) {
          const cell = tableCells[i].data;

          if (cell && cell.id) {
            selectedCellsIds.push(cell.id);
          }
        }

        const tableWidthType: Editor.Data.Node.TableWidthTypes =
          this.tableInfo.data.properties?.w?.t;
        const tableWidthValue: number = this.tableInfo.data.properties?.w?.v;

        if (tableId) {
          if (this.distributeType === 'ROWS') {
            this.distributeRows(this.model, tableId, selectedCellsIds, rowsIndex, columnsIndex);
          } else if (this.distributeType === 'COLUMNS') {
            this.distributeColumns(
              this.model,
              tableId,
              selectedCellsIds,
              tableWidthType,
              tableWidthValue,
              rowsIndex,
              columnsIndex,
            );
          }
        }
      }
    }

    return this;
  }

  private distributeColumns(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCellsIds: string[],
    tableWidthType: Editor.Data.Node.TableWidthTypes,
    oldTableWidthValue: number,
    rowsIndex: number[],
    columnsIndex: number[],
  ) {
    /* RULES

    # if TABLE is in percentage:
      - All columns will be converted to percentage (even if some of them are absolute)

    # if TABLE is in absolute:
      - All columns will be converted to absolute (even if some of them are in percentage)

    # if TABLE is in auto: 
      - All columns will be converted to absolute (even if some of them are in percentage) and the TABLE stays auto

    # if TABLE is nil: 
      - All columns will be converted to absolute (even if some of them are in percentage) and the TABLE change to auto
    */

    if (this.tableInfo) {
      const tableWidthValue = tableWidthType === 'pct' ? oldTableWidthValue : this.totalTableWidth;
      let columnWidthValue: number;

      columnWidthValue = this.calculateColumnWidth(
        tableWidthType,
        tableWidthValue,
        columnsIndex.length,
      );

      const propertiesData = this.getColumnPropertiesData(
        tableWidthType,
        tableWidthValue,
        columnWidthValue,
      );

      const operation = new TableStylesOperations(
        baseModel,
        tableId,
        propertiesData,
        selectedCellsIds,
        rowsIndex,
        columnsIndex,
      );
      this.ops = operation.getOps();
    }
  }

  private getColumnPropertiesData(
    widthType: Editor.Data.Node.TableWidthTypes,
    tableWidthValue: number,
    columnWidthValue: number,
  ) {
    const columnType: Editor.Data.Node.TableWidthTypes = widthType === 'pct' ? 'pct' : 'abs';

    return {
      TABLE: {
        autoResize: false,
        width: {
          value: {
            v: widthType === 'nil' || widthType === 'auto' ? 0 : tableWidthValue,
            t: widthType === 'nil' ? 'auto' : widthType,
          },
        },
      },
      ROW: {},
      COLUMN: {
        width: {
          value: {
            v: columnWidthValue,
            t: columnType,
          },
        },
      },
      CELL: {},
    };
  }

  private getRowPropertiesData(rowHeight: number) {
    const rowHeightType: Editor.Data.Node.RowHeightTypes = 'abs';

    return {
      TABLE: {
        autoResize: false,
      },
      ROW: {
        height: {
          value: {
            v: rowHeight,
            t: rowHeightType,
          },
        },
      },

      COLUMN: {},
      CELL: {},
    };
  }

  private calculateColumnWidth(
    widthType: Editor.Data.Node.TableWidthTypes,
    tableWidthValue: number,
    columnsLength: number,
  ): number {
    if (widthType === 'pct') {
      // percentage
      return 1 / columnsLength;
    } else {
      // absolute
      return tableWidthValue / columnsLength;
    }
  }

  private calculateRowHeight(): number {
    let higer: number = 0;
    let media: number = this.totalTableHeigth / this.rowsHeigth.length;

    if (this.tableInfo) {
      const rows = this.tableInfo.data.childNodes?.[0].childNodes;

      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];

          const rhData = row.properties?.rh ? row.properties.rh : 0;
          const rowHeight = this.rowsHeigth[i];

          const overflow = rowHeight > rhData;

          if (overflow && rowHeight > media && rowHeight > higer) {
            higer = rowHeight;
          }
        }
      }
    }

    return higer > media ? higer : media;
  }

  private distributeRows(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCellsIds: string[],
    rowsIndex: number[],
    columnsIndex: number[],
  ) {
    if (this.tableInfo) {
      const rowHeight = this.calculateRowHeight();
      const propertiesData = this.getRowPropertiesData(rowHeight);

      const operation = new TableStylesOperations(
        baseModel,
        tableId,
        propertiesData,
        selectedCellsIds,
        rowsIndex,
        columnsIndex,
      );
      this.ops = operation.getOps();
    }
  }
}
