import { useCallback } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';

import File from './Dropdowns/File';
import Edit from './Dropdowns/Edit';
import Insert from './Dropdowns/Insert';
import Review from './Dropdowns/Review';
import Format from './Dropdowns/Format';
import Table from './Dropdowns/Table';
import Help from './Dropdowns/Help';
import View from './Dropdowns/View';

import MissingFontsLabel from './MissingFontsLabel/MissingFontsLabel';
import IntegrationiEnvisionLogo from './IntegrationiEnvisionLogo/IntegrationiEnvisionLogo';

import { selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import EditorManager from 'Editor/services/EditorManager/EditorManager';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import { InteractionController } from '_common/components';

const Menu = () => {
  const dispatch = useDispatch();

  const isiEnvision = useSelector(selectIsIEnvision);
  const temporaryComment = useSelector((state) => state.editor.comments.insert);
  const sidebar = useSelector((state) => state.editor.sidebar);
  const isPageLayout = useSelector(selectIsPageLayout);

  const handleInsertComment = useCallback(() => {
    if (isPageLayout) {
      dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
      dispatch(setSidebarView('REVIEW'));

      EditorManager.getInstance().addTemporaryComment();
    } else if (temporaryComment.inserting && temporaryComment?.reference) {
      EditorManager.getInstance().focusComment(temporaryComment.reference);
    } else {
      if (sidebar.view) {
        if (sidebar.view !== 'REVIEW') {
          dispatch(setSidebarView('REVIEW'));
        }
        if (sidebar.tabs.review !== 'comments') {
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        }
      }

      EditorManager.getInstance().addTemporaryComment();
    }
  }, [sidebar.view, sidebar.tabs.review, temporaryComment, isPageLayout]);

  return (
    <InteractionController environment="editor" style={{ width: '100%', alignItems: 'center' }}>
      <File />
      <Edit />
      <Insert insertComment={handleInsertComment} />
      <Review insertComment={handleInsertComment} />
      <Format />
      <Table />
      <View />
      <Help />
      {!isiEnvision && <MissingFontsLabel />}
      <IntegrationiEnvisionLogo />
    </InteractionController>
  );
};

export default Menu;
