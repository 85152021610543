import type { ConfigType } from 'dayjs';

import { dayjsWithTimezone } from 'utils';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

export type FormattedTimeProps = {
  /**
   * normal will show the seconds and ignore the meridiem (AM/PM)
   * Example: 10:20:32
   *
   * meridiem will not show the secconds and show the meridiem (AM/PM) if it's 12h
   * Example: 10:20 AM
   *
   * @default "normal"
   */
  type?: 'normal' | 'meridiem';
  time: ConfigType;
};

const FORMATS = {
  '12h': {
    normal: 'hh:mm:ss',
    meridiem: 'hh:mm A',
  },
  '24h': {
    normal: 'HH:mm:ss',
    meridiem: 'HH:mm',
  },
};

const FormattedTime = ({ type = 'normal', time }: FormattedTimeProps) => {
  const { data: user } = useGetCurrentUserQuery();

  if (!user) {
    return null;
  }

  const dayjs = dayjsWithTimezone(time, user.profile.timezone);

  return <span>{dayjs.format(FORMATS[user.profile.time_format][type])}</span>;
};

export default FormattedTime;
