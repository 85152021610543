import { Button, Modal } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks/redux';

import { closeAndResetModal, closeModal, isModalOpen, openModal } from '_common/modals/ModalsSlice';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { exportCitations } from 'Editor/redux/CitationsSlice';

const MODAL = 'ReplaceReferenceLibraryModal';

const ReplaceReferenceLibraryModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => isModalOpen(state.modals, MODAL));
  const id = useSelector((state) => state.modals.ReplaceReferenceLibraryModal.id);
  const objectId = useSelector((state) => state.modals.ReplaceReferenceLibraryModal.objectId);
  const document = useSelector((state) => getDocumentObject(state));
  const data = useSelector((state) => state.app.data);
  const close = () => {
    dispatch(closeModal(MODAL));
  };

  const handleReplaceReferenceLibrary = () => {
    close();
    dispatch(
      exportCitations({
        documentId: document.id,
        params: {
          citations: id,
          extension: 'endnote',
          target: objectId[0],
          name: data[objectId].name.endsWith('xml')
            ? data[objectId].name
            : `${data[objectId].name}.xml`,
        },
      }),
    ).then(() => {
      dispatch(closeAndResetModal('CitationsModal'));
    });
  };

  const handleAddReferenceToExistingLibrary = () => {
    close();
    dispatch(
      exportCitations({
        documentId: document.id,
        params: {
          citations: id,
          extension: 'endnote',
          update: true,
          target: objectId[0],
          name: data[objectId].name.endsWith('xml')
            ? data[objectId].name
            : `${data[objectId].name}.xml`,
        },
      }),
    ).then(() => {
      dispatch(closeAndResetModal('CitationsModal'));
    });
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="91rem" testId="replace-reference-library">
      <Modal.Header size="medium">
        <FormattedMessage id="REPLACE_REFERENCE_LIBRARY" />
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="REPLACE_REFERENCE_LIBRARY_MESSAGE" />
      </Modal.Body>
      <Modal.Footer alignment="space-between">
        <Button
          size="medium"
          variant="link"
          onClick={() => {
            close();
            dispatch(openModal('CitationsModal'));
          }}
          testId="replace-reference-library-modal-go-back-button"
        >
          <FormattedMessage id="GO_BACK" />
        </Button>
        <div>
          <Button size="medium" onClick={handleAddReferenceToExistingLibrary} testId="replace-reference-library-modal-add-reference-to-library-button">
            <FormattedMessage id="ADD_REFERENCE_TO_EXISTING_LIBRARY" />
          </Button>
          <Button size="medium" onClick={handleReplaceReferenceLibrary} testId="replace-reference-library-modal-replace-reference-library-button">
            <FormattedMessage id="REPLACE_REFERENCE_LIBRARY" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplaceReferenceLibraryModal;
