import { useEffect, memo } from 'react';
import { useSelector } from '_common/hooks';
import Note from './Note';
import Slides from './Slides';
import styles from './Slides.module.scss';
import { IntlErrorBoundary } from '_common/components';
import { usePan } from '_common/suite/hooks';
import cn from 'classnames';
import { usePresentationManager } from 'Presentation/PresentationManager';

const Content = () => {
  const presentationManager = usePresentationManager();

  const presentationId = useSelector((state) => state.presentation.general.presentationId);
  const showNotes = useSelector((state) => state.presentation.general.showNotes);
  const inPanMode = useSelector((state) => state.presentation.general.cursorMode) === 'pan';
  const currentSlide = useSelector((state) => state.presentation.general.currentSlide);
  const { ref: outerRef, isPanning } = usePan(inPanMode);

  useEffect(() => {
    if (presentationId && presentationManager) {
      presentationManager.initializeShortcutsManager();
    }
    return () => {
      if (presentationManager) {
        presentationManager.destroyShortcutsManager();
      }
    };
  }, [presentationId, presentationManager]);

  return (
    <div className={styles.content}>
      <div
        className={cn(styles.slides, { [styles.pan]: inPanMode, [styles.panning]: isPanning })}
        data-testid="slides-container"
        ref={outerRef}
        id="slidesContainer"
      >
        <IntlErrorBoundary
          fallbackType="content"
          mockProps={{ width: '600px', height: '240px', margin: 'auto' }}
          key={currentSlide}
        >
          <div id="presentationSlides" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Slides />
          </div>
        </IntlErrorBoundary>
      </div>
      {showNotes && <Note />}
    </div>
  );
};

export default memo(Content);
