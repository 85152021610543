import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import styles from './DownloadElement.module.scss';
import { dayjs, dayjsWithTimezone } from 'utils';
import { Icon } from 'dodoc-design-system';
import { PublicLinkInfo, useDownloadPublicLinkFileMutation } from '../PublicLinkPageApi';

const DownloadElement = ({ data }: { data: PublicLinkInfo }) => {
  const intl = useIntl();
  const { hash } = useParams<{ hash: string }>();
  const [downloadPublicLinkFile] = useDownloadPublicLinkFileMutation();
  const expirationDate = dayjsWithTimezone(
    data?.link?.expiration.expiration,
    dayjs.tz.guess() as Timezone,
  )
    .hour(23)
    .minute(55);

  const handleDownload = () => {
    downloadPublicLinkFile({
      filename: data.object ? data.object.name : '',
      hash,
      password: data.password,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <Icon size={96} icon="File" />
      </div>

      <p>
        {data?.object?.name}
        <br />
        <span data-testid="public-link-expiration-date">
          {data?.link?.expiration.expiration &&
            data.link.expiration.active &&
            `${intl.formatMessage({ id: 'public.willExpire' })}
              ${expirationDate.from(dayjs())}`}
        </span>
      </p>

      <span
        onClick={handleDownload}
        className={styles.link}
        style={{ fontSize: '2rem' }}
        data-testid="public-link-download-button"
      >
        <FormattedMessage id="public.downloadFile" />
      </span>
    </div>
  );
};

export default DownloadElement;
