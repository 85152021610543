import { FormattedDate, FormattedTime, UsernameLabel } from '_common/components';

import styles from './UserPresentation.module.scss';

type VersionUserPresentationProps = {
  version: Card.Version;
};

const VersionUserPresentation = ({ version }: VersionUserPresentationProps) => {
  return (
    <div>
      <div className={styles.user}>{<UsernameLabel userId={version?.creator} />}</div>
      <div className={styles.date}>
        <FormattedDate date={version?.creation} />{' '}
        <FormattedTime time={version?.creation} type="meridiem" />
      </div>
    </div>
  );
};

export default VersionUserPresentation;
