import { CSSProperties, useEffect, useState } from 'react';
import { usePopper } from 'dodoc-design-system';
import { PageViewport } from 'pdfjs-dist';

import { useDispatch } from '_common/hooks';
import { selectAnnotation } from 'PDF/redux/PDFAnnotationsSlice';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import PDFPageContent from './PDFPage';
import useCursorMode from './useCursorMode';
import PDFContextMenu from './PDFContextMenu';
import { CONTENT_PAGE_GAP_SIZE } from 'PDF/consts';

type PDFPageProps = {
  index: number;
  style: CSSProperties;
};

const PDFPage = ({ style, index }: PDFPageProps) => {
  const dispatch = useDispatch();

  const attributes = useCursorMode();
  const { popperProps, referenceProps } = usePopper({ trigger: 'contextmenu' });

  const [pdfViewport, setPdfViewport] = useState<PageViewport | null>(null);

  const pageNum = index + 1;

  useEffect(() => {
    if (popperProps.isOpen) {
      dispatch(selectAnnotation(null));
    }
  }, [popperProps.isOpen]);

  const id = `page_${index + 1}`;

  return (
    <div
      style={
        style
          ? {
              ...style,
              top: +style.top! + CONTENT_PAGE_GAP_SIZE,
              height: +style.height! - CONTENT_PAGE_GAP_SIZE,
            }
          : undefined
      }
    >
      <div
        id={id}
        key={id}
        data-page-num={pageNum}
        data-viewport-scale={pdfViewport?.scale}
        style={{ height: `${pdfViewport?.height}px`, width: `${pdfViewport?.width}px` }}
        {...attributes}
        ref={referenceProps.ref}
        onContextMenuCapture={referenceProps.onContextMenuCapture}
      >
        <IntlErrorBoundary
          size="medium"
          fallbackType="pdfpage"
          mockProps={{ scale: pdfViewport?.scale }}
        >
          <PDFPageContent index={index} pdfViewport={pdfViewport} setPdfViewport={setPdfViewport} />
        </IntlErrorBoundary>
      </div>
      <PDFContextMenu popperProps={popperProps} pageNum={pageNum} />
    </div>
  );
};

export default PDFPage;
