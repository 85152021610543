import { useIntl } from 'react-intl';
import { Tooltip } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import { ToolbarToggleButton } from 'Editor/components';

const BoldToggleButton = () => {
  const intl = useIntl();

  const isBoldActive = useSelector((state) => state.editor.toolbar.bold);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const onClick = () => {
    EditorManager.getInstance().toggleSelectionStyle(StylesUtils.STYLES.BOLD, !isBoldActive);
  };

  return (
    <Tooltip content={intl.formatMessage({ id: 'editor.menu.format.bold' })} testId="bold-tooltip">
      <ToolbarToggleButton
        isActive={isBoldActive}
        toggleButtonClicked={onClick}
        icon="Bold"
        disabled={isReadOnlyMode || isPageLayout}
        testId="bold"
      />
    </Tooltip>
  );
};

export default BoldToggleButton;
