import { faker } from '@faker-js/faker';
import { createMockedObjectId, createMockedTimeObject, createMockedUserId } from './common';

function createMockedModel(overwrites?: Partial<doDOC.Model>): doDOC.Model {
  const owner = overwrites?.owner || createMockedUserId();
  return {
    id: createMockedObjectId(),
    name: faker.random.words(2),
    owner,
    creator: owner,
    permissions: {
      users: {},
      groups: {},
      roles: {
        users: {},
        groups: {},
      },
    },
    time: createMockedTimeObject(),
    ...overwrites,
  };
}

function createBaseObject(
  overwrites?: Partial<ApiSchemas['BaseObjectSchema']>,
): ApiSchemas['BaseObjectSchema'] {
  const model = createMockedModel(overwrites);
  return {
    ...model,
    parent: null,
    space: '',
    status: 'active',
    tags: [],
    events: {
      due: null,
      warnings: [],
    },
    lifecycle: {},
    user_permissions: ['owner'],
    owners: [model.owner],
    links: [],
    description: faker.lorem.lines(2),
    subject_permissions: {},
    shared_with: [],
    ...overwrites,
  };
}

export function createMockedSpace(overwrites?: Partial<doDOC.Space>): doDOC.Space {
  const owner = overwrites?.owner || createMockedUserId();
  return {
    creator: owner,
    description: faker.lorem.lines(2),
    id: createMockedObjectId(),
    name: faker.commerce.department(),
    owner,
    permissions: {
      groups: {},
      users: {},
      roles: {
        users: {},
        groups: {},
      },
    },
    tags: [],
    time: createMockedTimeObject(),
    subject_permissions: {},
    user_permissions: ['owner'],
    personal: false,
    type: 'space',
    shared_with: [],
    ...overwrites,
  };
}

export function createMockedGroup(overwrites?: Partial<doDOC.Group>): doDOC.Group {
  const owner = overwrites?.owner || createMockedUserId();
  return {
    creator: owner,
    description: faker.lorem.lines(2),
    granted: [],
    id: createMockedObjectId(),
    name: faker.commerce.department(),
    owner,
    permissions: {
      groups: {},
      users: {},
      roles: {
        users: {},
        groups: {},
      },
    },
    tags: [],
    time: createMockedTimeObject(),
    user_permissions: ['owner'],
    type: 'group',
    users: [],
    ...overwrites,
  };
}

export function createMockedPersonalSpace(overwrites?: Partial<doDOC.Space>): doDOC.Space {
  const owner = overwrites?.owner || createMockedUserId();
  return createMockedSpace({
    creator: owner,
    name: `_p__${owner}`,
    owner,
    user_permissions: ['owner'],
    personal: true,
    ...overwrites,
  });
}

export function createMockedFolder(overwrites?: Partial<doDOC.Folder>): doDOC.Folder {
  return {
    ...createBaseObject(overwrites),
    type: 'folder',
    ...overwrites,
  };
}

export function createMockedFile(overwrites?: Partial<doDOC.File>): doDOC.File {
  const baseObject = createBaseObject(overwrites);
  return {
    ...baseObject,
    type: 'file',
    original_name: baseObject.name,
    size: 10,
    mime: {
      type: 'image/png',
    },
    ...overwrites,
  };
}

export function createMockedDocxFile(overwrites?: Partial<doDOC.File>): doDOC.File {
  return createMockedFile({
    name: faker.system.commonFileName('docx'),
    ...overwrites,
    mime: {
      type: 'application/msword',
    },
    ...overwrites,
  });
}

export function createMockedPdfFile(overwrites?: Partial<doDOC.File>): doDOC.File {
  return createMockedFile({
    name: faker.system.commonFileName('pdf'),
    ...overwrites,
    mime: {
      type: 'application/pdf',
    },
    ...overwrites,
  });
}

export function createMockedPptxFile(overwrites?: Partial<doDOC.File>): doDOC.File {
  return createMockedFile({
    name: faker.system.commonFileName('pptx'),
    ...overwrites,
    mime: {
      type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    ...overwrites,
  });
}

export function createMockedImageFile(overwrites?: Partial<doDOC.File>): doDOC.File {
  return createMockedFile({
    name: faker.system.commonFileName('png'),
    ...overwrites,
    mime: {
      type: 'image/png',
    },
    ...overwrites,
  });
}

export function createMockedDoPDF(overwrites?: Partial<doDOC.PDF>): doDOC.PDF {
  return {
    ...createBaseObject(overwrites),
    type: 'dopdf',
    content: {
      annotations: '',
      structure: '',
    },
    name: faker.system.commonFileName('pdf'),
    ...overwrites,
  };
}

export function createdMockedDocument(overwrites?: Partial<doDOC.Document>): doDOC.Document {
  return {
    ...createBaseObject(overwrites),
    type: 'document',
    keywords: [],
    authors: [],
    attachments: {},
    metadata: {},
    content: {
      citations: '',
      comments: '',
      notes: '',
      structure: '',
    },
    tracking: {
      state: false,
      lock: false,
    },
    has_source: false,
    ...overwrites,
  };
}

export function createMockedPresentation(
  overwrites?: Partial<doDOC.Presentation>,
): doDOC.Presentation {
  return {
    ...createBaseObject(overwrites),
    type: 'presentation',
    attachments: {},
  };
}

export function createMockedTemplate(
  overwrites?: Partial<doDOC.Tenant.Template>,
): doDOC.Tenant.Template {
  return {
    category: faker.lorem.word(),
    change_log: faker.lorem.text(),
    description: faker.lorem.text(),
    id: createMockedObjectId(),
    latest_version: faker.lorem.text(),
    name: faker.lorem.text(),
    status: faker.helpers.arrayElement<doDOC.Tenant.Template['status']>([
      'installed',
      'not_installed',
      'outdated',
    ]),
    ...overwrites,
  };
}

export function createMockedTemplatesList({
  quantity,
}: {
  quantity: number;
}): doDOC.Tenant.Template[] {
  const templates: doDOC.Tenant.Template[] = [];

  const states: doDOC.Tenant.Template['status'][] = ['installed', 'not_installed', 'outdated'];

  for (let i = 0; i < quantity; i++) {
    if (states[i]) {
      templates.push(createMockedTemplate({ status: states[i] }));
    } else {
      templates.push(createMockedTemplate());
    }
  }
  return templates;
}
export function createMockedReferenceStylesList({
  quantity,
  referenceStyles,
}: {
  quantity?: number;
  referenceStyles?: Partial<doDOC.Tenant.Template>[];
}): doDOC.Tenant.Template[] {
  if (referenceStyles != null) {
    return referenceStyles.map((referenceStyle) => createMockedTemplate(referenceStyle));
  } else {
    const templates: doDOC.Tenant.Template[] = [];
    const states: doDOC.Tenant.Template['status'][] = ['installed', 'not_installed', 'outdated'];

    for (let i = 0; i < (quantity ?? 0); i++) {
      if (states[i]) {
        templates.push(createMockedTemplate({ status: states[i] }));
      } else {
        templates.push(createMockedTemplate());
      }
    }
    return templates;
  }
}

export function createObjectStatus(overwrites?: Partial<doDOC.Object.Status>): doDOC.Object.Status {
  const color: doDOC.Object.Status['color'] = faker.helpers.arrayElement<
    doDOC.Object.Status['color']
  >(['blue', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'yellow']);
  return {
    allow_change: faker.datatype.boolean(),
    allow_delete: faker.datatype.boolean(),
    allow_edit: faker.datatype.boolean(),
    allow_move: faker.datatype.boolean(),
    color,
    confirm_input: faker.datatype.boolean(),
    deleted: faker.datatype.boolean(),
    fade: faker.datatype.boolean(),
    id: faker.datatype.uuid(),
    name: faker.lorem.word({ length: { min: 1, max: 16 } }),
    position: '',
    read_only: faker.datatype.boolean(),
    visible: faker.datatype.boolean(),
    ...overwrites,
  };
}

export function createAffiliation({
  overwrites,
  quantity,
}: {
  overwrites?: Partial<doDOC.Editor.Affiliation>;
  quantity: number;
}): doDOC.Editor.Affiliation {
  const generateUniqueArray = (generator: () => string) => {
    const uniqueStrings: Set<string> = new Set();

    let timeout = 0;

    while (uniqueStrings.size < quantity) {
      const generatedString = generator();
      if (!uniqueStrings.has(generatedString)) {
        timeout = 0;
      } else {
        timeout++;
      }

      if (timeout < 100) {
        uniqueStrings.add(generatedString);
      } else {
        uniqueStrings.add(generatedString + faker.random.numeric(4));
        timeout = 0;
      }
    }

    return Array.from(uniqueStrings);
  };

  return {
    address1: generateUniqueArray(faker.address.streetAddress),
    address2: generateUniqueArray(faker.address.streetAddress),
    company: generateUniqueArray(faker.company.name),
    country: generateUniqueArray(faker.address.country),
    department: generateUniqueArray(faker.name.jobArea),
    organization: generateUniqueArray(faker.name.jobType),
    site: generateUniqueArray(faker.internet.url),
    state: generateUniqueArray(faker.address.state),
    zip_code: generateUniqueArray(faker.address.zipCode),
    function: generateUniqueArray(faker.name.jobTitle),
    extra: generateUniqueArray(faker.animal.type),
    ...overwrites,
  };
}

//#region Recycle
export function createMockedRecycleObject(
  overwrites?: Partial<Omit<doDOC.Recycle, 'object'> & { object: Partial<doDOC.Recycle> }>,
): doDOC.Recycle {
  const ownerId = createMockedUserId();

  const createObject = faker.helpers.arrayElement([
    createMockedDoPDF,
    createMockedDocxFile,
    createMockedFile,
    createMockedFolder,
  ]);

  const object = createObject();
  return {
    type: 'recycle',
    id: createMockedObjectId(),
    creator: ownerId,
    owner: ownerId,
    //@ts-expect-error API:RecycleSchema is wrong
    user_permissions: ['owner'],
    tags: [],
    time: createMockedTimeObject(),
    granted: faker.helpers.arrayElements([
      'delete',
      'comment',
      'owner',
      'edit',
      'approve',
      'add_permission',
      'remove_permission',
      'import',
      'export',
    ] as const),
    ...overwrites,
    object: {
      //@ts-expect-error API:RecycleSchema is wrong
      name: object.name,
      //@ts-expect-error API:RecycleSchema is wrong
      parent: object.parent,
      space: createMockedSpace(),
      status: object.status,
      type: object.type,
      ...overwrites?.object,
    },
  };
}

export function createMockedRecycleList({ quantity }: { quantity: number }): doDOC.Recycle[] {
  const recycle: doDOC.Recycle[] = [];

  for (let i = 0; i < quantity; i++) {
    if (i === 0) {
      //Always create an object with a parent and personal space
      recycle.push(
        createMockedRecycleObject({
          //@ts-expect-error API:RecycleSchema is wrong
          object: { parent: createMockedFolder(), space: { personal: true } },
        }),
      );
    } else {
      recycle.push(createMockedRecycleObject());
    }
  }
  return recycle;
}
//#endregion

export function createMockedAuthor(): ApiSchemas['AuthorSchema'] {
  return {
    id: createMockedObjectId(),
    affiliation: [],
    corresponding: false,
    email: [faker.internet.email()],
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    middle_name: faker.name.middleName(),
    phone: [faker.phone.number()],
  };
}

export function createMockedSavedFilter(
  overwrites?: Partial<ApiSchemas['SearchFilterSchema']>,
): ApiSchemas['SearchFilterSchema'] {
  const creator = overwrites?.creator ?? createMockedUserId();

  return {
    id: createMockedObjectId(),
    creator,
    owner: creator,
    name: faker.name.jobTitle(),
    conditions: [],
    permissions: {
      groups: {},
      roles: {
        groups: {},
        users: {},
      },
      users: {},
    },
    time: createMockedTimeObject(),
    type: 'searchfilter',
    user_permissions: ['owner'],
    ...overwrites,
  };
}
