import { useIntl, FormattedMessage } from 'react-intl';
import { permissionTranslation } from 'utils';

import GroupLabel from '_common/components/Labels/GroupLabel';
import RoleLabel from '_common/components/Labels/RoleLabel';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';

type AuditActionLabelProps = {
  auditAction: ActionLog;
};

const AuditActionLabel = ({ auditAction }: AuditActionLabelProps) => {
  const intl = useIntl();

  const getAuditLabel = () => {
    switch (auditAction.action) {
      //#region Comments
      case 'comment_created': // New
        return <FormattedMessage id="ADDED_COMMENT" />;
      case 'comment_resolved': // Status update
        return <FormattedMessage id="RESOLVED_COMMENT" />;
      case 'comment_reply': // Replies
        return <FormattedMessage id="REPLIED_TO_COMMENT" />;
      case 'comment_deleted': // Deletions
        return <FormattedMessage id="DELETED_COMMENT" />;
      //#endregion
      //#region Tasks
      case 'task_created': // New
        return <FormattedMessage id="CREATED_TASK" />;
      case 'task_assignee_updated': // Add Assign
        return (
          <FormattedMessage
            id="ASSIGNED_TASK_TO"
            values={{
              assignee: (
                <UsernameLabel
                  userId={(auditAction.extra as ApiSchemas['TaskAssigneeActionExtra']).new_assignee}
                />
              ),
            }}
          />
        );
      case 'task_status_changed': // Status update
        let statusMessage;
        switch ((auditAction.extra as ApiSchemas['TaskStatusChangedActionExtra']).new_status) {
          case 'td':
            statusMessage = <FormattedMessage id="TODO" />;
            break;
          case 'pr':
            statusMessage = <FormattedMessage id="IN_PROGRESS" />;
            break;
          case 'd':
            statusMessage = <FormattedMessage id="DONE" />;
            break;
        }
        return (
          <FormattedMessage
            id="UPDATED_TASK_STATUS_TO"
            values={{
              status: statusMessage,
            }}
          />
        );
      case 'task_replied': // Replies
        return <FormattedMessage id="REPLIED_ON_TASK" />;
      case 'task_deleted': // Deletions
        return <FormattedMessage id="DELETED_TASK" />;
      //#endregion
      //#region Suggestions
      case 'suggestion_accepted': // Acceptance
        return <FormattedMessage id="ACCEPTED_SUGGESTION" />;
      case 'suggestion_rejected': // Rejection
        return <FormattedMessage id="REJECTED_SUGGESTION" />;
      case 'suggestion_replied': // Replies
        return <FormattedMessage id="REPLIED_ON_SUGGESTION" />;
      //#endregion
      //#region Access and Permissions
      case 'permission_added': // Add permission | Add group permission
        return (
          <FormattedMessage
            id="GRANTED_PERMISSION_TO_SUBJECT"
            values={{
              permission: intl.formatMessage({
                id: permissionTranslation(
                  (auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).code,
                ),
              }),
              subject:
                (auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).to === 'users' ? (
                  <UsernameLabel
                    userId={
                      (auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).subject
                    }
                  />
                ) : (
                  <GroupLabel
                    groupId={
                      (auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).subject
                    }
                  />
                ),
            }}
          />
        );
      // Remove permission | Remove group permission
      case 'permission_removed': {
        const subject =
          (auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).to === 'users' ? (
            <UsernameLabel
              userId={(auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).subject}
            />
          ) : (
            <GroupLabel
              groupId={(auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).subject}
            />
          );

        if ((auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).code) {
          return (
            <FormattedMessage
              id="REVOKED_PERMISSION_TO_SUBJECT"
              values={{
                permission: intl.formatMessage({
                  id: permissionTranslation(
                    (auditAction.extra as ApiSchemas['ObjectPermissionActionExtra']).code,
                  ),
                }),
                subject,
              }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="REVOKED_ALL_PERMISSIONS_TO_SUBJECT"
              values={{
                subject,
              }}
            />
          );
        }
      }

      case 'role_added': // Add role | Add group role
        return (
          <FormattedMessage
            id="GRANTED_ROLE_TO_SUBJECT"
            values={{
              role: (
                <RoleLabel
                  roleId={(auditAction.extra as ApiSchemas['RolePermissionActionExtra']).role}
                />
              ),
              subject:
                (auditAction.extra as ApiSchemas['RolePermissionActionExtra']).to === 'users' ? (
                  <UsernameLabel
                    userId={(auditAction.extra as ApiSchemas['RolePermissionActionExtra']).subject}
                  />
                ) : (
                  <GroupLabel
                    groupId={(auditAction.extra as ApiSchemas['RolePermissionActionExtra']).subject}
                  />
                ),
            }}
          />
        );
      case 'role_removed': // Remove role | Remove group role
        return (
          <FormattedMessage
            id="REVOKED_ROLE_TO_SUBJECT"
            values={{
              role: (
                <RoleLabel
                  roleId={(auditAction.extra as ApiSchemas['RolePermissionActionExtra']).role}
                />
              ),
              subject:
                (auditAction.extra as ApiSchemas['RolePermissionActionExtra']).to === 'users' ? (
                  <UsernameLabel
                    userId={(auditAction.extra as ApiSchemas['RolePermissionActionExtra']).subject}
                  />
                ) : (
                  <GroupLabel
                    groupId={(auditAction.extra as ApiSchemas['RolePermissionActionExtra']).subject}
                  />
                ),
            }}
          />
        );
      //#endregion
      //#region Content
      case 'node_approved': // Approve content
        return <FormattedMessage id="APPROVED_CONTENT" />;
      case 'node_reopened': // Reopen content
        return <FormattedMessage id="REOPENED_CONTENT" />;
      case 'node_permission_added': // Add content permissions
        return (
          <FormattedMessage
            id="GRANTED_CONTENT_PERMISSION_TO_USER"
            values={{
              permission: intl.formatMessage({
                id: permissionTranslation(
                  (auditAction.extra as ApiSchemas['NodePermissionActionExtra']).code,
                ),
              }),
              user: (
                <UsernameLabel
                  userId={(auditAction.extra as ApiSchemas['NodePermissionActionExtra']).subject}
                />
              ),
            }}
          />
        );
      case 'node_permission_removed': // Remove content permissions
        return (
          <FormattedMessage
            id="REVOKED_CONTENT_PERMISSION_TO_USER"
            values={{
              permission: intl.formatMessage({
                id: permissionTranslation(
                  (auditAction.extra as ApiSchemas['NodePermissionActionExtra']).code,
                ),
              }),
              user: (
                <UsernameLabel
                  userId={(auditAction.extra as ApiSchemas['NodePermissionActionExtra']).subject}
                />
              ),
            }}
          />
        );
      //#endregion
      //#region Track Changes
      case 'lock_unlock_suggestions': // Lock | Unlock
        return (
          <FormattedMessage
            id={
              (auditAction.extra as ApiSchemas['SuggestionsLockStateActionExtra']).lock
                ? 'LOCKED_SUGGESTIONS'
                : 'UNLOCKED_SUGGESTIONS'
            }
          />
        );
      //#endregion
      //#region Versioning
      case 'saved_version': // Save a version
        return <FormattedMessage id="SAVED_VERSION" />;
      case 'restored_version': // Restore a version
        return <FormattedMessage id="RESTORED_VERSION" />;
      case 'new_document_version': // Upload a new version
        return <FormattedMessage id="UPLOADED_VERSION" />;
      //#endregion
      //#region Explorer related
      case 'created': // Created element
        const extra = auditAction.extra as ApiSchemas['CreateActionExtra'];
        if (extra.source) {
          return <FormattedMessage id="CONVERTED_A_DOCUMENT" />;
        } else {
          return <FormattedMessage id="CREATED_DOCUMENT" />;
        }
      case 'downloaded': // Download file
        return <FormattedMessage id="DOWNLOADED_FILE" />;
      case 'edited': // Rename element | Edit description | Edit due date | Edit reminder date
        const editActions = (auditAction.extra as ApiSchemas['EditActionExtra']).fields;
        const editAction = editActions.length > 0 ? editActions[0] : null;

        if (editAction) {
          switch (editAction.field) {
            case 'name':
              return <FormattedMessage id="RENAMED_DOCUMENT" />;
            case 'description':
              return <FormattedMessage id="EDITED_DESCRIPTION_OF_DOCUMENT" />;
            case 'events.due':
              if (editAction.old) {
                if (editAction.new) {
                  return <FormattedMessage id="EDITED_DUEDATE_OF_DOCUMENT" />;
                }
                return <FormattedMessage id="REMOVED_DUE_DATE" />;
              }
              return <FormattedMessage id="ADDED_DUE_DATE" />;
            case 'events.warning':
              return editAction.new.length > editAction.old.length ? (
                <FormattedMessage id="ADDED_REMINDER_DATE" />
              ) : (
                <FormattedMessage id="REMOVED_REMINDER_DATE" />
              );
          }
        }
        return '';
      case 'status_changed': // Change status
        return <FormattedMessage id="UPDATED_STATUS_OF_DOCUMENT" />;
      case 'moved': // Move
        return <FormattedMessage id="MOVED_DOCUMENT" />;
      case 'copied': // Copy
        return <FormattedMessage id="COPIED_DOCUMENT" />;
      //#endregion
      //#region Tags
      case 'tag_added': // Add
        return <FormattedMessage id="ADDED_TAG" />;
      case 'tag_removed': // Remove
        return <FormattedMessage id="REMOVED_TAG" />;
      //#endregion
      //#region Wild
      case 'template_changed': // Apply styling template
        return <FormattedMessage id="CHANGED_TEMPLATE" />;

      case 'exported': // Export document
        return (
          <FormattedMessage
            id="EXPORTED_DOCUMENT"
            values={{
              location:
                (auditAction.extra as ApiSchemas['ExportActionExtra'])?.third_party ||
                intl.formatMessage({ id: 'COMPUTER' }),
            }}
          />
        );

      case 'imported': // Import document
        return <FormattedMessage id="IMPORTED_A_DOCUMENT" />;
      //#endregion
      //#region Annotations
      case 'annotation_created': // New
        return <FormattedMessage id="ADDED_COMMENT" />;
      case 'annotation_resolved': // Status update
        return <FormattedMessage id="RESOLVED_COMMENT" />;
      case 'annotation_replied': // Replies
        return <FormattedMessage id="REPLIED_TO_COMMENT" />;
      case 'annotation_deleted': // Deletions
        return <FormattedMessage id="DELETED_COMMENT" />;
      //#endregion
    }
  };

  return <>{getAuditLabel()}</>;
};

export default AuditActionLabel;
