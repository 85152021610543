import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import UserCard from '_common/components/UserCard/UserCard';

import styles from './UserRow.module.scss';

type UserRowProps = {
  account?: Account;
  onClick?: MouseEventHandler<HTMLDivElement>;
  online?: boolean;
  testId: string;
};

const UserRow = ({ account, onClick, online, testId }: UserRowProps) => {
  return (
    <div className={styles.root} onClick={onClick} data-testid={`${testId}-row`}>
      {account ? (
        <UserCard
          userId={account.id}
          online={online}
          titleStyle={{ fontWeight: 500 }}
          size="medium"
        />
      ) : (
        <div className={styles.addAccount}>
          <Icon size={32} icon="InviteUser" margin="0 1rem 0 0" />
          <FormattedMessage id="ADD_ANOTHER_ACCOUNT" />
          ...
        </div>
      )}
    </div>
  );
};

export default UserRow;
