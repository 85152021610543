import { ReactNode } from 'react';
import { IntlErrorBoundary } from '_common/components';
import styles from './Sidebar.module.scss';

type SidebarProps = {
  children: ReactNode;
};

const Sidebar = ({ children }: SidebarProps) => {
  return (
    <div className={styles.sidebar}>
      <IntlErrorBoundary size="xsmall" collapsed tooltipPlacement="left" margin="auto">
        {children}
      </IntlErrorBoundary>
    </div>
  );
};

export default Sidebar;
