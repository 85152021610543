import { CSSProperties, memo, ReactNode, useEffect, useMemo, MouseEventHandler } from 'react';
import cx from 'classnames';
import styles from './Annotation.module.scss';
import { usePopper } from 'dodoc-design-system';
import AnnotationContextMenu from './AnnotationContextMenu';
import { useScrollToAnnotationElement, useDispatch } from '_common/hooks';
import { selectAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import { useAnnotationBox, useAnnotationContext } from './AnnotationContext';
import DropdownContext from './BaseAnnotationDropdownContext';

type BaseAnnotationProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
};

const BaseAnnotation = ({ children, onClick, onDoubleClick }: BaseAnnotationProps) => {
  const { annotation, isSelected, viewport } = useAnnotationContext();
  const box = useAnnotationBox();
  const popper = usePopper({ trigger: 'contextmenu' });
  const dispatch = useDispatch();
  useScrollToAnnotationElement(annotation, isSelected);

  useEffect(() => {
    if (popper.isOpen) {
      dispatch(selectAnnotation(null));
    }
  }, [popper.isOpen]);

  const canMove = useMemo(() => {
    return (
      annotation.subtype === 'Circle' ||
      annotation.subtype === 'FreeText' ||
      annotation.subtype === 'Ink' ||
      annotation.subtype === 'Line' ||
      annotation.subtype === 'Square'
    );
  }, [annotation.subtype]);

  const style: CSSProperties = {
    left: box.left,
    width: box.width,
    height: box.height,
  };

  if (annotation.subtype === 'FreeText') {
    style.top = viewport.height - box.bottom - box.height;
  } else {
    style.bottom = box.bottom;
  }

  return (
    <DropdownContext.Provider value={popper}>
      <div
        className={cx(styles.base, {
          [styles.selected]: annotation.subtype !== 'FreeText' && isSelected,
          [styles.canMove]: canMove,
        })}
        style={style}
        {...popper.referenceProps}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        id={annotation.id}
        data-testid={`${annotation.subtype}-${annotation.id}`}
      >
        {children}
        <AnnotationContextMenu annotation={annotation} />
      </div>
    </DropdownContext.Provider>
  );
};

export default memo(BaseAnnotation);
