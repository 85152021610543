import api from '_common/services/api/api';
import { paths } from '_types/api';

const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApiVersion: builder.query<
      paths['/api/setup']['get']['responses']['200']['content']['application/json'],
      void
    >({
      query: () => ({
        url: `/setup`,
      }),
    }),
  }),
});

// Export queries and mutations
export const { useGetApiVersionQuery } = appApi;

export default appApi;
