import { FormattedMessage } from 'react-intl';
import { Dropdown, Divider, usePopper, Icon } from 'dodoc-design-system';
import EditorManager from 'Editor/services/EditorManager';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { selectDocumentStyles } from 'Editor/redux/StylesSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useSelector, useDispatch } from '_common/hooks';
import DocumentStylePreview from 'Editor/components/DocumentStylePreview/DocumentStylePreview';
import styles from './ContentStructure.module.scss';
import { useEffect } from 'react';
import { completeAction, resetAction } from 'App/redux/onboardingSlice';

const ContentStructure = () => {
  const dispatch = useDispatch();
  const documentStyles = useSelector((state) => selectDocumentStyles(state));
  const selectedStyle = useSelector((state) => state.editor.status.selectedStyle);
  const isReadOnlyMode = useSelector((state) => selectReadOnlyMode(state));
  const isPageLayout = useSelector(selectIsPageLayout);
  const { popperProps, referenceProps, close } = usePopper({
    disabled: isReadOnlyMode || isPageLayout,
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (popperProps.isOpen) {
      dispatch(completeAction('editor_writing_heading_open'));
    } else {
      dispatch(resetAction('editor_writing_heading_open'));
    }
  }, [popperProps.isOpen]);

  const openRenameModal = (name: string) => {
    dispatch(
      openAndUpdateModal({
        modal: 'RenameDocumentStylesModal',
        data: {
          isOpen: true,
          styleId: selectedStyle.id,
          name,
        },
      }),
    );
  };

  const applyToSelection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    styleId: string,
  ) => {
    dispatch(completeAction('editor_writing_heading_select'));
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.applyDocumentStyleToSelection({ style: styleId });
    }
  };

  const updateStyleFromSelection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    styleId: string,
  ) => {
    dispatch(completeAction('editor_writing_heading_select'));
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.updateDocumentStyleFromSelection(styleId);
    }
  };

  const { data, list } = documentStyles;
  return (
    <>
      <div
        className={`${styles.toggleDropdown} ${
          (isReadOnlyMode || isPageLayout) && styles.disabled
        }`}
        data-testid="content-structure"
        id="headingSelect"
        {...referenceProps}
      >
        <div className={styles.text}>
          {/** @ts-expect-error state.editor.status.selectedStyle and selectDocumentStyles has to be typed */}
          {`${selectedStyle && data && data[selectedStyle.id] ? data[selectedStyle.id].alias : ''}${
            selectedStyle && selectedStyle.changed ? '*' : ''
          }`}
        </div>
        <div className={styles.arrowDiv}>
          <Icon icon="AngleDownGrey" size={24} />
        </div>
      </div>
      <Dropdown {...popperProps} testId="content-structure-dropdown">
        {list &&
          list.slice(0, 5).map((styleId: string, index: number) => (
            <div key={data[styleId].n}>
              {index > 0 && <Divider margin="0" />}
              <Dropdown.SubMenu
                itemContent={
                  <DocumentStylePreview id={styleId} style={data[styleId]}>
                    {data[styleId].alias}
                  </DocumentStylePreview>
                }
                onClick={(event) => {
                  applyToSelection(event, styleId);
                  close();
                }}
                testId="style-dropdown-submenu"
                id={styleId === 'h3' ? 'headingTargetAction' : undefined}
              >
                <Dropdown.Item
                  onClick={(event) => applyToSelection(event, styleId)}
                  testId="apply-dropdown-item"
                >
                  <FormattedMessage id="APPLY" values={{ name: data[styleId].alias }} />
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(event) => updateStyleFromSelection(event, styleId)}
                  testId="apply-from-selection-dropdown-item"
                >
                  <FormattedMessage id="UPDATE_FROM_SELECTION" />
                </Dropdown.Item>
                <Divider />
                <Dropdown.Item
                  onClick={() => openRenameModal(data[styleId].n)}
                  testId="rename-style-dropdown-item"
                >
                  <FormattedMessage id="RENAME_STYLE" />
                </Dropdown.Item>
              </Dropdown.SubMenu>
            </div>
          ))}
      </Dropdown>
    </>
  );
};

export default ContentStructure;
