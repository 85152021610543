import { FilterPopover } from '_common/components';
import { Settings } from '_common/components/Filters/FilterController';
import { useSuiteObject } from '_common/suite/SuiteContext/SuiteContext';

type CommentFiltersProps = {
  categories: Settings;
};

const CommentFilters = ({ categories }: CommentFiltersProps) => {
  const suiteObject = useSuiteObject();

  return (
    <FilterPopover
      identity={
        suiteObject.type === 'presentation' ? 'presentationCommentPanel' : 'reviewerCommentPanel'
      }
      popperSettings={{ placement: 'bottom-start' }}
      toggleVariant="link"
      variant="reviewer"
      persist
      {...categories}
    />
  );
};

export default CommentFilters;
