import { FormattedMessage } from 'react-intl';
import { Divider } from 'dodoc-design-system';
import { usePublicProfile, useSelector } from '_common/hooks';

import styles from './ApprovalNote.module.scss';
import FormattedTime from '_common/components/FormattedTime/FormattedTime';
import FormattedDate from '_common/components/FormattedDate/FormattedDate';

const ApprovalNote = () => {
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  const approval = object.lifecycle?.approvals?.[0]; //Get most recent status (assumed its always the first )
  const ownerProfile = usePublicProfile(approval?.author ?? object.owner);
  if (object.status !== 'approved' || approval?.action !== 'approved' || !ownerProfile) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Divider margin="0 1rem" />
      <div className={styles.approvalNoteWrapper}>
        <div className={styles.header}>
          <FormattedMessage id="storage.browserSidebar.approvalNote" />
        </div>
        <div className={styles.body}>{approval.comment}</div>
        <div className={styles.footer}>
          <FormattedMessage
            id="APPROVED_BY_NAME_AT_DATE"
            values={{
              name: ownerProfile.name,
              date: (
                <>
                  <FormattedTime time={approval.date} type="meridiem" />{' '}
                  <FormattedDate date={approval.date} type="expansive" />
                </>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalNote;
