import { ReactNode } from 'react';

import styles from './Section.module.scss';

type SectionProps = {
  children: ReactNode;
};

const Section = ({ children }: SectionProps) => {
  return <div className={styles.root}>{children}</div>;
};

export default Section;
