import { EmptyFilteredState, FilterDisplay } from '_common/components';
import { useDispatch, usePublicProfiles, useSelector } from '_common/hooks';
import { EmptyCardListState, Switch } from 'dodoc-design-system';
import { Comments } from '_common/suite/components';
import { FormattedMessage, useIntl } from 'react-intl';
import CommentCard from './CommentCard';
import styles from './CommentsPanel.module.scss';
import Search from '_common/suite/components/Panel/Comments/Search/Search';
import { setCommentsSearchBy, toggleIsListMode } from 'Presentation/CommentsSlice';
import { useMemo } from 'react';
import { useCommentsAuthors, useFilteredComments, useActiveComments } from 'Presentation/hooks';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import type { UserOption } from '_common/components/SearchUser/SearchUser';
import { useTotalMatchedFilters } from '_common/components/Filters';
import useAllComments from 'Presentation/hooks/useAllComments';

const CommentsPanel = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const allComments = useAllComments();
  const filteredComments = useFilteredComments();
  const commentsList = useActiveComments();
  const totalCommentsFilters = useTotalMatchedFilters({ identity: 'presentationCommentPanel' });
  const users = useCommentsAuthors();
  const creating = useSelector((state) => state.presentation.general.creating);
  const searchBy = useSelector((state) => state.presentation.comments.searchBy);
  const { profiles } = usePublicProfiles(users?.map((user) => user.id));
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.presentationCommentPanel),
  );
  const isListMode = useSelector((state) => state.presentation.comments.isListMode);

  const userOptions = useMemo(() => {
    const options: UserOption[] = [];

    users?.forEach((user) => {
      const option: UserOption = {
        label: '',
        id: '',
        value: '',
        name: '',
        type: 'user',
      };
      if (user.imported) {
        option.label = user.id;
        option.id = 'IMPORTED_USER';
        option.imported = true;
      } else {
        option.label = profiles[user.id]?.name;
        option.email = profiles[user.id]?.email;
        option.id = user.id;
      }
      option.value = user.id;
      options.push(option);
    });

    return options;
  }, [users, profiles]);

  const renderEmptyState = () => {
    if (!creating) {
      if (searchBy) {
        return (
          <div className={styles.emptyStateSearchBy} data-testid="comments-empty-search">
            <FormattedMessage id="NO_MATCHES_FOUND" />
          </div>
        );
      }

      return <EmptyFilteredState identity="presentationCommentPanel" size="medium" />;
    }
  };

  const toggleListMode = () => {
    dispatch(toggleIsListMode());
  };

  return (
    <Comments
      commentsCategories={{
        commentStatus: {
          options: [
            {
              value: 'ACCEPTED',
              label: 'editor.sidebar.review.filter.status.resolved',
              labelNumber: allComments?.filter((comment) => comment.status === 'ACCEPTED').length,
            },
            {
              value: 'REJECTED',
              label: 'editor.sidebar.review.filter.status.deleted',
              labelNumber: allComments?.filter((comment) => comment.status === 'REJECTED').length,
            },
          ],
        },
        cardPriority: {
          options: [
            {
              value: 'LOW',
              label: 'editor.sidebar.review.filter.priority.low',
              labelNumber: allComments.filter((comment) => comment.priority === 'LOW').length,
            },
            {
              value: 'MEDIUM',
              label: 'editor.sidebar.review.filter.priority.medium',
              labelNumber: allComments.filter((comment) => comment.priority === 'MEDIUM').length,
            },
            {
              value: 'HIGH',
              label: 'editor.sidebar.review.filter.priority.high',
              labelNumber: allComments.filter((comment) => comment.priority === 'HIGH').length,
            },
          ],
        },
        author: { options: userOptions, settings: { editorAvatar: true } },
      }}
    >
      <div className={styles.content}>
        {commentsList && commentsList.length === 0 && !hasActiveFilters && !creating ? (
          <div className={styles.emptyState} data-testid="no-comments-yet">
            <EmptyCardListState size="medium" testId="no-comments-empty-card-list-state">
              <FormattedMessage id="NO_COMMENTS_YET" />
            </EmptyCardListState>
          </div>
        ) : (
          <>
            <Search margin="1rem 0 2rem 0" search={setCommentsSearchBy} />
            <div className={styles.commentsControl}>
              <div className={styles.commentsCounter}>
                {totalCommentsFilters > 0 || searchBy ? (
                  <FormattedMessage
                    id="Y_OF_X_COMMENTS"
                    values={{ value: filteredComments.length, total: commentsList.length }}
                  />
                ) : (
                  <FormattedMessage
                    id="X_COMMENTS_IN_THE_DOCUMENT"
                    values={{ total: commentsList.length }}
                  />
                )}
              </div>
              <Switch
                size="medium"
                labelPlacement="left"
                onChange={toggleListMode}
                active={isListMode}
                testId={`comments-panel-list-mode-switch`}
              >
                {intl.formatMessage({ id: 'LIST_MODE' })}
              </Switch>
            </div>
            <FilterDisplay
              identity="presentationCommentPanel"
              direction="column"
              margin="0 0 2rem 0rem"
            />

            <div data-testid="comments-list" className={styles.commentsList}>
              {creating?.type === 'comment' && (
                <div style={{ marginBottom: '1rem' }}>
                  <CommentCard isTemporary sidebar />
                </div>
              )}
              {filteredComments.length
                ? filteredComments.map((comment) => {
                    return <CommentCard key={comment.id} sidebar comment={comment} />;
                  })
                : renderEmptyState()}
            </div>
          </>
        )}
      </div>
    </Comments>
  );
};

export default CommentsPanel;
