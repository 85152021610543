/* eslint-disable class-methods-use-this */
import { Mixin } from 'mixwith';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { Logger } from '_common/services';
import { ELEMENTS } from 'Editor/services/consts';

export default Mixin(
  (superclass) =>
    class extends superclass {
      parseNonEditableElement(representation, elementType) {
        const builder = new ElementNodeBuilder();
        builder.addElementType(elementType);

        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(elementType);

        if (
          elementType === ELEMENTS.PageBreakElement.ELEMENT_TYPE ||
          elementType === ELEMENTS.ColumnBreakElement.ELEMENT_TYPE ||
          elementType === ELEMENTS.SectionBreakElement.ELEMENT_TYPE
        ) {
          // page break
          Object.keys(mapper).forEach((prop) => {
            try {
              mapper[prop].parse(representation, builder);
            } catch (e) {
              Logger.captureException(e);
            }
          });
        } else {
          if (representation.attributes.parent_id) {
            builder.addParentId(representation.attributes.parent_id.nodeValue);
          }
          if (representation.attributes.id) {
            builder.addId(representation.attributes.id.nodeValue);
          }
        }

        return builder.getNode();
      }
    },
);
