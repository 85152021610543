import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { navigateToUserSettings } from 'router/history';
import { useSelector, usePublicProfile, useDispatch } from '_common/hooks';

import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';
import UserAvatar from '_common/components/UserAvatar/UserAvatar';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';

import styles from './ActiveAccount.module.scss';

type ActiveAccountProps = { toggle: () => void };

const ActiveAccount = ({ toggle }: ActiveAccountProps) => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);
  const profile = usePublicProfile(userId);

  const goToUserSettings = () => {
    dispatch(
      setSelectedTab({ menuId: 'userSettingsPage', selectedTab: 'user-preferences-general' }),
    );
    navigateToUserSettings();
    toggle();
  };

  if (!userId || !profile) {
    return null;
  }

  return (
    <div className={styles.root}>
      <UserAvatar userId={userId} size="extraLarge" online />
      <div className={styles.info}>
        <div className={styles.username} title={profile.name}>
          <UsernameLabel userId={userId} />
        </div>
        {profile.email && (
          <div className={styles.email} title={profile.email}>
            {profile.email}
          </div>
        )}
      </div>

      <div>
        <Button
          size="small"
          variant="link"
          onClick={goToUserSettings}
          margin="1rem 0 0 0"
          testId="user-popover-settings-button"
        >
          <FormattedMessage id="VIEW_PROFILE" />
        </Button>
      </div>
    </div>
  );
};

export default ActiveAccount;
