import { useEffect } from 'react';
import { useDispatch } from '_common/hooks';
import { Icon } from 'dodoc-design-system';
import { getPublicGroup } from 'App/redux/publicSlice';
import { useSelector } from '_common/hooks/redux';
import styles from './GroupCard.module.scss';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

type Props = {
  id: Uuid;
  appendToName?: ReactNode;
};

const GroupCard = ({ id, appendToName }: Props) => {
  const dispatch = useDispatch();
  const group = useSelector((state) => state.public.groups.profiles[id]);

  useEffect(() => {
    if (!group) {
      dispatch(getPublicGroup(id));
    }
  }, [group, dispatch, id]);

  if (!group) {
    return <div style={{ minHeight: '4rem' }} />;
  }

  return (
    <div className={styles.root} data-testid="group-card">
      <div className={styles.avatar}>
        <Icon size={32} icon="Groups" />
      </div>
      <div className={styles.container}>
        <div className={styles.name}>
          <span style={{ fontWeight: 600 }}>{group.name}</span>
          {appendToName}
        </div>
        {group.users && (
          <div className={styles.number}>
            <FormattedMessage id="NUMBER_OF_USERS" values={{ number: group.users.length }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupCard;
