import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';
import TableContent, { TableProps } from './TableContent';

const Table = (props: TableProps) => {
  return (
    <IntlErrorBoundary size="large" margin="8.5rem 0 0 0">
      <TableContent {...props} />
    </IntlErrorBoundary>
  );
};

export default Table;
