import { DataManagerError, DataManagerErrorPayload } from './DataManagerError';

type NotFoundErrorPayload = DataManagerErrorPayload & {};

export class NotFoundError extends DataManagerError {
  data: NotFoundErrorPayload;

  constructor(msg: string, data: NotFoundErrorPayload = {}) {
    super(msg);
    this.data = {
      ...data,
      type: 'NOT_FOUND',
    };
  }
}
