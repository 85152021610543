import { FormattedMessage } from 'react-intl';
import { Button, Divider } from 'dodoc-design-system';

import { navigateToAdmin } from 'router/history';
import { useDispatch } from '_common/hooks';
import { setRedirectValidSession } from 'App/redux/appSlice';
import { useGetCurrentUserQuery, useSignOutMutation } from '_common/services/api/authority';

import ActiveAccount from './ActiveAccount/ActiveAccount';
import SavedAccounts from './SavedAccounts/SavedAccounts';

import styles from './UserPopover.module.scss';

type UserPopoverProps = {
  toggle: () => void;
};

const UserPopover = ({ toggle }: UserPopoverProps) => {
  const dispatch = useDispatch();
  const [signOut] = useSignOutMutation();

  const { data: currentUser } = useGetCurrentUserQuery();

  const goToAdministration = () => {
    if (currentUser?.other.admin_url) {
      navigateToAdmin(currentUser?.other.admin_url);
    }
  };

  const handleLogOut = () => {
    signOut(currentUser?.profile.id ?? '');
    dispatch(setRedirectValidSession(false));
  };

  return (
    <div className={styles.root}>
      <ActiveAccount toggle={toggle} />
      <Divider margin="1rem 0" />
      <SavedAccounts />
      <Divider margin="1rem 0" />
      <div className={styles.footer}>
        {currentUser?.is_superuser && (
          <Button
            fullWidth
            size="small"
            onClick={goToAdministration}
            margin="0 0 1rem 0"
            testId="user-popover-administration-button"
          >
            <FormattedMessage id="storage.dropdowns.userProfile.administration" />
          </Button>
        )}
        <Button fullWidth size="small" onClick={handleLogOut} testId="user-popover-sign-out-button">
          <FormattedMessage id="storage.dropdowns.userProfile.logout" />
        </Button>
      </div>
    </div>
  );
};

export default UserPopover;
