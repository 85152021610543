import React, { useState, useEffect } from 'react';
import { ExtensionsService } from '_common/services';
import styles from './Template.module.scss';

type TemplateProps = {
  template: DocumentTemplate;
  selectTemplate: (value: DocumentTemplate) => void;
  active: boolean;
};

const Template = ({ template, selectTemplate, active }: TemplateProps) => {
  const [thumbnail, setThumbnail] = useState<string | undefined>('');
  const onImageClick = () => {
    selectTemplate({ ...template, thumbnail });
  };

  useEffect(() => {
    if (template.id !== '_no_template') {
      new ExtensionsService().previewTemplate(template.id).then((response) => {
        const url = URL.createObjectURL(response.data); // create <img> with src set to the blob
        setThumbnail(url);
      });
    }
  }, [template.id]);
  return (
    <div className={`${styles.root} ${active && styles.active}`} data-testid={`${template.name}-template`}>
      <div className={styles.img_container} onClick={onImageClick}>
        {template.id !== '_no_template' ? (
          <img className={`${styles.img}`} alt={template.name} src={thumbnail} />
        ) : (
          <div className={`${styles.img} ${styles.noTemplate}`} />
        )}
      </div>
      <div className={styles.name}>{template.name}</div>
    </div>
  );
};

const Memo = React.memo(Template);
Memo.displayName = 'Template';
export default Memo;
