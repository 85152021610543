import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement, EquationElement } from '..';

export class EquationViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<EquationElement>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<EquationElement> = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: EquationElement) => {
        node.setAttribute('element_type', ELEMENTS.EquationElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // equation
    equation: {
      parse: (node: EquationElement, builder: ElementNodeBuilder) => {
        const equation = node.equation;
        if (equation) {
          builder.addProperty('equation', equation);
        }
      },
      render: (json: Editor.Data.Node.Data, node: EquationElement) => {
        if (json.properties?.equation) {
          node.equation = json.properties?.equation;
        }
      },
      remove: (node: EquationElement) => {
        node.removeAttribute('equation');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren() {
    return true;
  }

  build(json: Editor.Data.Node.Data) {
    const node = DOMElementFactory.buildElement(ELEMENTS.EquationElement.TAG) as EquationElement;

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    node.renderEquation();

    return node;
  }
}
