import { ChangeEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './ImageToolbar.module.scss';

const ImageToolbar = () => {
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const handleInsertCaption = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editMode: false } }));
  };

  const handleChangeImage: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    const manager = EditorManager.getInstance();
    if (files?.[0]) {
      manager.handleChangeImage(files[0]);
    }
  };

  return (
    <div className={styles.root}>
      <Button
        variant="link"
        upload
        onChange={handleChangeImage}
        accept=".jpeg,.jpg,.bmp,.tiff,.gif,.png,.eps"
        disabled={isReadOnlyMode || isPageLayout}
        style={{
          boxShadow: '0 0 0 0',
          background: 'none',
        }}
        size="small"
        testId="toolbar-figure-replaceFigure"
      >
        <FormattedMessage id="editor.toolbar.replaceImage" />
      </Button>
      <Divider vertical margin="0 0.5rem" />
      <Button
        variant="link"
        onClick={handleInsertCaption}
        disabled={isReadOnlyMode || isPageLayout}
        style={{
          boxShadow: '0 0 0 0',
          background: 'none',
        }}
        size="small"
        testId="toolbar-figure-addCaption"
      >
        <FormattedMessage id="EDITOR_ADD_CAPTION" />
      </Button>
    </div>
  );
};

export default ImageToolbar;
