import { ChangeEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordInput, Button, InputField, Icon } from 'dodoc-design-system';

import styles from './PasswordRequiredElement.module.scss';

type PasswordRequiredElementProps = {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  errorState?: string;
};

const PasswordRequiredElement = ({
  password,
  setPassword,
  errorState,
}: PasswordRequiredElementProps) => {
  const intl = useIntl();

  const [value, setValue] = useState(password);

  const handleOnPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  const handleSubmitPassword = () => {
    setPassword(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <Icon icon="Lock" size={240} />
      </div>

      <span data-testid="public-link-protection-description">
        <div className={styles.text}>
          <FormattedMessage id="public.protected.protected1" />
        </div>
        <p>
          <FormattedMessage id="public.protected.protected2" />
        </p>
      </span>
      <div style={{ marginBottom: '2rem' }}>
        <InputField
          label={intl.formatMessage({ id: 'PASSWORD' })}
          size="large"
          feedback={
            errorState === 'wrong' ? intl.formatMessage({ id: 'settings.password.invalid' }) : ''
          }
          testId="public-link-password"
        >
          <PasswordInput
            size="large"
            placeholder={intl.formatMessage({ id: 'INSERT_YOUR_PASSWORD' })}
            value={value}
            onChange={handleOnPasswordChange}
            error={errorState === 'wrong'}
            width="37.5rem"
            testId="public-link-password"
          />
        </InputField>
        <Button
          size="medium"
          variant="primary"
          disabled={!value}
          onClick={handleSubmitPassword}
          fullWidth
          testId="public-link-download-button"
        >
          <FormattedMessage id="public.unlockBtn" />
        </Button>
      </div>
    </div>
  );
};

export default PasswordRequiredElement;
