import { useIntl, FormattedMessage } from 'react-intl';

import styles from './ErrorReport.module.scss';

type Entry = {
  row: string;
  errors: Partial<Record<UserManagementField, UserManagementFieldError>>;
};

export type ErrorReportProps = {
  entries: Entry[];
};

export const TEST_IDS = {
  id: 'settings.users.upload.errorReport',
  row: (row: Entry['row']) => `settings.users.upload.errorReport.row.${row}`,
  error: ({
    row,
    field,
    error,
  }: Pick<Entry, 'row'> & {
    field: keyof Entry['errors'];
    error: Entry['errors'][keyof Entry['errors']];
  }) => `settings.users.upload.errorReport.${row}.${field}.${error}`,
};

const ErrorReport = ({ entries }: ErrorReportProps) => {
  const intl = useIntl();
  return entries?.length > 0 ? (
    <div className={styles.errorReport} data-testid={TEST_IDS.id}>
      <div className={styles.header}>
        <FormattedMessage id="ERROR_REPORT" />
      </div>
      <div className={styles.list}>
        {entries.map((entry) => {
          const errors = entry?.errors;

          return (
            errors && (
              <div key={entry.row} className={styles.row}>
                <div className={styles.title} data-testid={TEST_IDS.row(entry.row)}>
                  <FormattedMessage id="ROW" /> {entry.row}
                </div>
                <div className={styles.errors}>
                  {(Object.keys(errors) as Array<keyof typeof errors>).map((error) => {
                    let field = '';
                    const msg: Partial<TranslationMessage> = {};
                    switch (error) {
                      case 'first_name': {
                        field = 'FIRST_NAME';
                        break;
                      }
                      case 'last_name': {
                        field = 'LAST_NAME';
                        break;
                      }
                      case 'email': {
                        field = 'EMAIL_ADDRESS';
                        break;
                      }
                      default:
                        break;
                    }

                    switch (errors[error]) {
                      case 'missing': {
                        msg.id = 'FIELD_IS_EMPTY';
                        msg.values = {
                          field: intl.formatMessage({
                            id: field,
                          }),
                        };
                        break;
                      }
                      case 'exists': {
                        msg.id = 'FIELD_ALREADY_EXISTS';
                        msg.values = {
                          field: intl
                            .formatMessage({
                              id: field,
                            })
                            .toLowerCase(),
                        };
                        break;
                      }

                      case 'invalid': {
                        msg.id = 'FIELD_IS_INVALID';
                        msg.values = {
                          field: intl.formatMessage({
                            id: field,
                          }),
                        };
                        break;
                      }
                      default:
                        break;
                    }
                    return (
                      <div key={error}>
                        <div
                          className={styles.error}
                          data-testid={TEST_IDS.error({
                            row: entry.row,
                            field: error,
                            error: errors[error],
                          })}
                        >
                          <FormattedMessage id={msg.id} values={msg.values} />.
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  ) : null;
};

export default ErrorReport;
