import DOMSanitizer from 'Editor/services/DOMUtilities/DOMSanitizer/DOMSanitizer';

export class ElementNodeBuilder {
  private node: Editor.Data.Node.Data;

  constructor(elementType: string = '') {
    this.node = {
      childNodes: [],
      properties: {},
      type: elementType as Editor.Data.Node.DataTypes,
    };
  }

  getType() {
    return this.node.type || '';
  }

  getNode() {
    return this.node;
  }

  addElementType(elementType: Editor.Data.Node.DataTypes) {
    this.node.type = elementType;
  }

  addStatus(status: string) {
    this.node.status = status;
  }

  addChild(child: Editor.Data.Node.Data) {
    if (this.node.type !== 'redacted') {
      this.node.childNodes?.push(child);
    }
  }

  getChild(index: number) {
    return this.node?.childNodes?.[index];
  }

  addProperty(key: string, value: any) {
    if (!this.node.properties) {
      this.node.properties = {};
    }

    if (value === 'true') {
      this.node.properties[key] = true;
      return;
    }
    if (value === 'false') {
      this.node.properties[key] = false;
      return;
    }
    this.node.properties[key] = value;
  }

  addClipboardProperty(key: string, value: any) {
    if (!this.node.clipboardProps) {
      this.node.clipboardProps = {};
    }

    if (value === 'true') {
      this.node.clipboardProps[key] = true;
      return;
    }
    if (value === 'false') {
      this.node.clipboardProps[key] = false;
      return;
    }
    this.node.clipboardProps[key] = value;
  }

  getProperties() {
    return this.node.properties;
  }

  addStyleProperty(style: any, nodeName: any) {
    if (!this.node.properties) {
      this.node.properties = {};
    }

    // We create a dummy element and give it the node style to avoid removing properties from the node style itself
    const dummy = document.createElement('div');
    const dummyStyle = dummy.style;
    dummyStyle.cssText = style.cssText;
    const allowedProperties: any = DOMSanitizer.allowedStylePropertiesForTag(nodeName);
    if (allowedProperties) {
      const length = dummyStyle.length;
      for (let i = 0; i < length; i++) {
        const property = dummyStyle[0];
        if (!allowedProperties[property]) {
          dummyStyle.removeProperty(property);
        }
      }
      this.node.properties.style = dummyStyle.cssText;
    }
  }

  addParentId(parentId: string) {
    this.node.parent_id = parentId || undefined;
  }

  addId(id: string) {
    this.node.id = id || undefined;
  }

  getId() {
    return this.node.id;
  }

  addDataset(dataset: DOMStringMap) {
    if (!this.node.properties) {
      this.node.properties = {};
    }
    if (dataset) {
      const keys = Object.keys(dataset);
      let i;
      for (i = 0; i < keys.length; i++) {
        const property = keys[i];
        const value = dataset[property];

        if (value) {
          switch (property) {
            case 'alignment':
              this.node.properties.a = this.parseAlignment(value);
              break;
            case 'spaceBefore':
              this.node.properties.sb = +value;
              break;
            case 'spaceAfter':
              this.node.properties.sa = +value;
              break;
            case 'lineHeight':
              this.node.properties.lh = +value;
              break;
            case 'leftIndentation': {
              if (!this.node.properties.ind) {
                this.node.properties.ind = {};
              }
              this.node.properties.ind.l = +value;
              break;
            }
            case 'rightIndentation': {
              if (!this.node.properties.ind) {
                this.node.properties.ind = {};
              }
              this.node.properties.ind.r = +value;
              break;
            }
            case 'specialIndent': {
              if (!this.node.properties.sp_ind) {
                this.node.properties.sp_ind = {};
              }
              this.node.properties.sp_ind.t = value as Editor.Data.Node.SpecialIndentation['t'];
              break;
            }
            case 'specialIndentValue': {
              if (!this.node.properties.sp_ind) {
                this.node.properties.sp_ind = {};
              }
              this.node.properties.sp_ind.v = +value;
              break;
            }
            case 'prefix':
              break;
            case 'styleId':
              break;
            case 'backgroundColor':
              this.node.properties.bg = value;
              break;
            default:
              this.node.properties[property] = value;
              break;
          }
        }
      }
    }
  }

  validate() {
    return this.node.id && this.node.parent_id && this.node.type;
  }

  // eslint-disable-next-line
  parseAlignment(value?: string) {
    switch (value) {
      case 'left':
        return 'l';
      case 'right':
        return 'r';
      case 'center':
        return 'c';
      case 'justify':
        return 'j';
      default:
        // TODO report invalid alignment value
        return 'l';
    }
  }

  addTasks(task: string) {
    if (!this.node.tasks) {
      this.node.tasks = [];
    }
    this.node.tasks.push(task);
  }

  addKeyValue<K extends keyof Editor.Data.Node.Data>(
    key: K,
    value: Editor.Data.Node.Data[K] | null,
  ) {
    if (value != null) {
      this.node[key] = value;
    }
  }
}
