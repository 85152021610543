import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import styles from './SubOptions.module.scss';

import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';
import type { Menu } from 'Editor/modals/ExportIntegrationModal/Menus/MenuTypes';

type Element = ExportIntegrationModal.Element;

export type SubOptionsProps<T extends ExportIntegrationModal.ExportType> = {
  elem: Element;
  selectedSubOption?: {
    id: ExportIntegrationModal.Element['id'] | '';
    name: ExportIntegrationModal.Element['name'] | '';
  };
  setSelectedSubOption?: (value: SubOptionsProps<T>['selectedSubOption']) => void;
} & Pick<Menu.BaseProps<T>, 'info' | 'setSelectedOption'>;

const SubOptions = <T extends ExportIntegrationModal.ExportType>({
  elem,
  info,
  selectedSubOption,
  setSelectedSubOption,
  setSelectedOption,
}: SubOptionsProps<T>) => {
  const SUB_OPTIONS: (Omit<Element, 'id'> & { id?: Element['id'] })[] = [
    {
      name: 'recent',
      icon: 'VHSidebar',
      callback: (elem) => info.listRecentsContent({ ...elem, subOption: 'recent' }),
    },
    {
      name: 'favourites',
      icon: 'Favorite',
      callback: (elem) => info.listFavouritesContent({ ...elem, subOption: 'favourites' }),
    },
  ];

  const handleClickOnSubOption = (element: typeof SUB_OPTIONS[number]) => {
    if (setSelectedSubOption) {
      if (selectedSubOption?.id !== elem.id || selectedSubOption.name !== element.name) {
        setSelectedSubOption({ id: elem.id, name: element.name });
        setSelectedOption(elem);
        element.callback && element.callback(elem);
      }
    }
  };

  return (
    <div className={styles.subOptionsContainer}>
      {SUB_OPTIONS.map((element) => {
        return (
          <div
            className={`${styles.subOption} ${selectedSubOption?.id === elem.id &&
              selectedSubOption.name === element.name &&
              styles.selected
              }`}
            key={element.name}
            onClick={() => handleClickOnSubOption(element)}
          >
            <div style={{ marginRight: '1rem' }}>
              <Icon icon={element.icon ?? 'Folder'} size={24} />
            </div>
            <FormattedMessage id={element.name.toUpperCase()} />
          </div>
        );
      })}
    </div>
  );
};

export default SubOptions;
