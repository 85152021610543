import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

const SLICE_NAME = 'EDITOR_SECTIONS';

type SliceState = {
  [key in ObjectId]: {
    id: string;
    props: {
      [key in ObjectId]: string;
    };
  };
};

const initialState: SliceState = {};

const sectionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    loadSectionProperties: (
      state,
      action: PayloadAction<
        {
          id: string;
          props: {
            [key in ObjectId]: string;
          };
        }[]
      >,
    ) => {
      const section = action.payload.reduce<SliceState>((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      return { ...state, ...section };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const { loadSectionProperties } = sectionsSlice.actions;

export default sectionsSlice.reducer;
