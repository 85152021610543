import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { NodeUtils } from 'Editor/services/DataManager';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

export class InsertColumnOperation extends BaseOperation<Editor.Data.Node.Model> {
  before: boolean;
  tableId: string;
  cellsIndex?: number[];
  selectedCells: Editor.Edition.CellInfo[];
  columnWidth?: Editor.Data.Node.TableWidth;
  mergeUnselectedCells?: boolean;
  pageWidth?: number;
  headCellToUpdate: {
    [index: string]: {
      properties: { cs: number };
    };
  } = {};

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
    pageWidth: number | undefined,
    cellsIndex: number[],
    {
      before,
      columnWidth,
      mergeUnselectedCells = false,
    }: {
      before: boolean;
      columnWidth?: Editor.Data.Node.TableWidth;
      mergeUnselectedCells?: boolean;
    },
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.before = before;
    this.mergeUnselectedCells = mergeUnselectedCells;
    this.columnWidth = columnWidth;
    this.pageWidth = pageWidth;

    this.cellsIndex = cellsIndex.length ? cellsIndex : TableUtils.getCellsIndex(this.selectedCells);
    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.cellsIndex) {
      const tableInfo: {
        data: Editor.Data.Node.Data;
        path: Realtime.Core.RealtimePath;
      } = this.model.getChildInfoById(this.tableId);

      const baseData = this.model.selectedData();

      if (!baseData) {
        return this;
      }

      const refCellIndex = this.before
        ? this.cellsIndex[0]
        : this.cellsIndex[this.cellsIndex.length - 1];

      const columnCells = this.getColumnCells(baseData, tableInfo.data, refCellIndex);
      const indexToAdd = this.before ? refCellIndex : refCellIndex + 1;

      // build ops to add cell
      for (let l = 0; l < columnCells.length; l++) {
        if (columnCells[l] != null) {
          this.ops.push(
            RealtimeOpsBuilder.listInsert(columnCells[l], [
              ...tableInfo.path,
              this.model.KEYS.CHILDNODES,
              0,
              this.model.KEYS.CHILDNODES,
              l,
              this.model.KEYS.CHILDNODES,
              indexToAdd,
            ]),
          );
        }
      }

      // build ops for updated headcells
      const keyIds = Object.keys(this.headCellToUpdate);
      for (let k = 0; k < keyIds.length; k++) {
        const updatedData = this.headCellToUpdate[keyIds[k]];

        const headCellPropPath = this.model.findPath(this.model.KEYS.ID, keyIds[k]);
        headCellPropPath.pop(); // remove path id
        headCellPropPath.push(this.model.KEYS.PROPERTIES); // path to properties
        headCellPropPath.push('cs');

        if (updatedData.properties.cs > 0) {
          const op = this.model.buildOp(headCellPropPath, updatedData.properties.cs);
          if (op != null) {
            this.ops.push(op);
          }
        }
      }

      if (NodeUtils.isTableData(tableInfo.data) && this.pageWidth) {
        const columnsWeights = TableUtils.findColumnsWeights(
          tableInfo.data,
          [refCellIndex],
          this.pageWidth,
        );

        const tableWidth = tableInfo.data.properties?.w;

        let op;
        if (
          tableWidth &&
          (tableWidth.t === 'abs' || tableWidth.t === 'pct') &&
          this.model.id === this.tableId &&
          columnsWeights[refCellIndex] != null
        ) {
          const value = +(tableWidth.v + tableWidth.v * columnsWeights[refCellIndex]).toFixed(3);
          op = this.getObjectOperationforPathValue(tableWidth, { t: tableWidth.t, v: value }, [
            ...tableInfo.path,
            this.model.KEYS.PROPERTIES,
            'w',
          ]);
        } else if (!tableWidth || tableWidth.t !== 'auto') {
          op = this.getObjectOperationforPathValue(tableWidth, { t: 'auto', v: 0 }, [
            ...tableInfo.path,
            this.model.KEYS.PROPERTIES,
            'w',
          ]);
        }

        if (op) {
          this.ops.push(op);
        }
      }
    }

    return this;
  }

  getColumnCells(
    baseData: Editor.Data.Node.Data,
    table: Editor.Data.Node.Data,
    refCellIndex: number,
  ): (Editor.Data.Node.Data | null)[] {
    const tableBody = table.childNodes?.[0];
    let columnCells: (Editor.Data.Node.Data | null)[] = [];
    const selectedCellIds: string[] = [];

    for (let i = 0; i < this.selectedCells.length; i++) {
      selectedCellIds.push(this.selectedCells[i].id);
    }

    if (tableBody && tableBody.childNodes?.length) {
      const rows = tableBody.childNodes;
      const rowsLength = rows.length;

      for (let j = 0; j < rowsLength; j++) {
        const row = rows[j];

        if (row.childNodes) {
          const refCell = row.childNodes[refCellIndex];

          if (NodeUtils.isTableCellData(refCell) && refCell.id) {
            const refCellPath = this.model.findPath('id', refCell.id);

            refCell.properties.rs = refCell.properties.rs ? refCell.properties.rs : 1;
            refCell.properties.cs = refCell.properties.cs ? refCell.properties.cs : 1;

            const clonedProperties: Editor.Data.Node.TableCellProperties = {
              ...refCell.properties,
            };
            delete clonedProperties['head-id'];
            delete clonedProperties.d;
            delete clonedProperties.cs;
            delete clonedProperties.rs;

            const cellBuilder = new NodeDataBuilder(ELEMENTS.TableCellElement.ELEMENT_TYPE);

            cellBuilder.addKeyValue('parent_id', row.id);
            cellBuilder.addKeyValue('properties', clonedProperties);

            const headId = refCell.properties?.['head-id'];

            if (headId) {
              const headCellPath = this.model.findPath('id', headId);
              const headCell = NodeUtils.getChildDataByPath(baseData, headCellPath);

              const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);
              const refCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(refCellPath);

              if (
                headCell?.id &&
                headCell.properties &&
                headCellIndexs.currentIndex != null &&
                headCellIndexs.parentIndex != null
              ) {
                headCell.properties.rs = headCell.properties.rs ? headCell.properties.rs : 1;
                headCell.properties.cs = headCell.properties.cs ? headCell.properties.cs : 1;

                if (headCellIndexs.currentIndex === refCellIndexs.currentIndex) {
                  if (!this.before && headCell.properties?.cs > 1) {
                    cellBuilder.addProperty('head-id', headCell.id);
                    cellBuilder.addProperty('d', false);

                    if (!this.headCellToUpdate[headCell.id]) {
                      // add headcell to update
                      this.headCellToUpdate[headCell.id] = {
                        properties: { cs: +headCell.properties?.cs + 1 },
                      };
                    }
                  } else if (headCell.properties?.rs > 1) {
                    cellBuilder.addProperty('head-id', columnCells[headCellIndexs.parentIndex]?.id);
                    cellBuilder.addProperty('d', false);
                  }
                } else if (
                  headCellIndexs.currentIndex + +headCell.properties?.cs - 1 ===
                    refCellIndexs.currentIndex &&
                  !(this.mergeUnselectedCells && !selectedCellIds.includes(refCell.id))
                ) {
                  if (!this.before && headCell.properties?.rs > 1) {
                    if (headCellIndexs.parentIndex === refCellIndexs.parentIndex) {
                      cellBuilder.addProperty('rs', headCell.properties?.rs);
                    } else {
                      cellBuilder.addProperty(
                        'head-id',
                        columnCells[headCellIndexs.parentIndex]?.id,
                      );
                      cellBuilder.addProperty('d', false);
                    }
                  }
                  if (this.before) {
                    cellBuilder.addProperty('head-id', headCell.id);
                    cellBuilder.addProperty('d', false);

                    if (!this.headCellToUpdate[headCell.id]) {
                      // add headcell to update
                      this.headCellToUpdate[headCell.id] = {
                        properties: { cs: +headCell.properties?.cs + 1 },
                      };
                    }
                  }
                } else {
                  cellBuilder.addProperty('head-id', headCell.id);
                  cellBuilder.addProperty('d', false);

                  if (!this.headCellToUpdate[headCell.id]) {
                    // add headcell to update
                    this.headCellToUpdate[headCell.id] = {
                      properties: { cs: +headCell.properties?.cs + 1 },
                    };
                  }
                }
              }
            } else if (
              !this.before &&
              (refCell.properties?.cs > 1 ||
                (this.mergeUnselectedCells && !selectedCellIds.includes(refCell.id)))
            ) {
              cellBuilder.addProperty('head-id', refCell.id);
              cellBuilder.addProperty('d', false);

              if (!this.headCellToUpdate[refCell.id]) {
                // add headcell to update
                this.headCellToUpdate[refCell.id] = {
                  properties: { cs: +refCell.properties?.cs + 1 },
                };
              }
            } else if (refCell.properties?.rs > 1) {
              cellBuilder.addProperty('rs', refCell.properties?.rs);
            }

            const properties = cellBuilder.getProperties();
            if (!properties['head-id']) {
              const firstChild = refCell.childNodes?.[0];
              let properties: Editor.Data.Node.Data['properties'] = {};

              if (firstChild?.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE) {
                properties = firstChild?.properties;
              }

              if (this.columnWidth != null && properties) {
                properties.w = {
                  t: this.columnWidth.t,
                  v: this.columnWidth.v,
                };
              }

              // add a paragraph to cell
              const childData = NodeDataBuilder.buildData({
                type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                parent_id: cellBuilder.getId(),
                properties,
              });
              if (childData) {
                cellBuilder.addChildData(childData);
              }
            }

            const cell = cellBuilder.build();

            if (cell) {
              columnCells.push(cell);
            }
          } else {
            columnCells.push(null);
          }
        }
      }
    }
    return columnCells;
  }
}
