const DEFAULT_TAB_STOP_VALUE = 36; // pt

export class TabulationUtils {
  static get DEFAULT_TAB_STOP() {
    return DEFAULT_TAB_STOP_VALUE;
  }

  static mergeTabStops(...args: Editor.Data.TabStop[][]) {
    if (args.length <= 0) {
      return [];
    }
    if (args.length === 1) {
      return args[0];
    }
    const ordered: Editor.Data.TabStop[] = [];
    const toInsert = args.flat();
    let element;
    if (ordered.length === 0 && toInsert.length) {
      //* KickStart in case of empty ordered array
      ordered.push(toInsert.shift() as Editor.Data.TabStop);
    }
    whileLoop: while (toInsert.length) {
      element = toInsert.shift();
      if (!element) {
        continue;
      }
      for (let index = ordered.length - 1; index >= 0; index--) {
        const orderedElement = ordered[index];
        if (element.v === orderedElement.v) {
          if (element.t === 'cl') {
            ordered.splice(index, 1);
          } else {
            ordered.splice(index, 1, element);
          }
          continue whileLoop;
        } else if (element.v && element.v > orderedElement.v) {
          ordered.splice(index + 1, 0, element);
          continue whileLoop;
        } else if (index === 0) {
          ordered.unshift(element);
        }
      }
    }
    return ordered;
  }
}
