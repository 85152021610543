import { merge } from 'lodash';
import { BaseTypedEmitter } from '../BaseTypedEmitter';
import { DataObject } from './DataObject';
import { RealtimeObject } from './RealtimeObject';
// import { BaseTypedEmitter, DataObject, RealtimeObject } from '_common/services/Realtime';

export type CompoundableObject = Realtime.Core.RealtimeObject | Realtime.Core.DataObject;
export type CompoundDescriptor<T> = {
  element: CompoundableObject;
  p: Realtime.Core.RealtimePath;
  d: keyof T;
};

export class CompoundObject<T extends any> extends BaseTypedEmitter<{
  UPDATE: (data: T | null) => void;
  NEEDS_EXTERNAL_ACTION: (actionType: 'TEMP_BLOCK_CREATE', payload: any) => void;
}> {
  id?: string;
  protected data: T | null;
  protected compoundElements: CompoundDescriptor<T>[];

  constructor(id?: string) {
    super();
    this.id = id;
    this.data = null;
    this.compoundElements = [];
    this.handleCompoundableUpdate = this.handleCompoundableUpdate.bind(this);
  }

  protected handleCompoundableUpdate() {
    const compounded = {};
    for (let index = 0; index < this.compoundElements.length; index++) {
      const object = this.compoundElements[index];
      let data: Partial<T> = {};
      if (object.element instanceof RealtimeObject) {
        data[object.d] = object.element.get(object.p);
      } else if (object.element instanceof DataObject) {
        data[object.d] = object.element.getWithPath(object.p);
      }
      if (data) {
        this.joinData(compounded, data);
      }
    }
    this.data = JSON.parse(JSON.stringify(compounded));
    this.emit('UPDATE', this.data);
  }

  private handleRealtimeCompoundableUpdate(compoundPath: any) {
    return (data: any, ops: any) => {
      if (
        ops.some(
          (val: Realtime.Core.RealtimeOp) =>
            val.p.includes(compoundPath) || compoundPath.includes(val.p),
        )
      ) {
        this.handleCompoundableUpdate();
      }
    };
  }

  private async appendCompoundableObject(object: CompoundDescriptor<T>) {
    // TODO: VALIDATE DUPLICATE OBJECTS
    if (object.element instanceof RealtimeObject) {
      if (!object.element.loaded) {
        await object.element.awaitForEvent('LOADED');
      }
      object.element.on('UPDATED', this.handleRealtimeCompoundableUpdate(object.p));
    } else if (object.element instanceof DataObject) {
      if (object.element.loaded) {
        object.element.on('LOADED', this.handleCompoundableUpdate);
        object.element.on('UPDATE', this.handleCompoundableUpdate);
      }
    }
    this.compoundElements.push(object);
  }

  protected joinData(destination: any, origin: any) {
    if (origin) {
      merge(destination, origin);
    }
  }

  get(thisPath?: any) {
    let data: any;
    const selectedData = this.data;
    if (selectedData != null) {
      data = selectedData;
      if (thisPath) {
        for (let index = 0; index < thisPath.length; index++) {
          const key: string | number = thisPath[index];
          data = data ? data[key] : undefined;
        }
      }
    }
    return data;
  }

  compound(elements: CompoundDescriptor<T>[]) {
    for (let index = 0; index < elements.length; index++) {
      this.appendCompoundableObject(elements[index]);
    }
    this.handleCompoundableUpdate();
  }
}
