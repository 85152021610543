import { FormattedMessage } from 'react-intl';

import { useSelector } from '_common/hooks';

import FormattedDate from '_common/components/FormattedDate/FormattedDate';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';

import styles from './Details.module.scss';

const GroupDetails = () => {
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  const getOwner = () => {
    return <UsernameLabel userId={object?.owner} />;
  };

  const getCreationDate = () => {
    if (!object?.time?.creation) {
      return null;
    }

    return <FormattedDate date={object.time.creation} />;
  };

  const getAdministratorsCount = () => {
    return Object.keys(object.permissions.users).length + 1;
  };

  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <FormattedMessage id="storage.browserSidebar.owner" />
      </div>
      <div className={styles.value}>{getOwner()}</div>
      <div className={styles.label}>
        <FormattedMessage id="storage.browserSidebar.creationDate" />
      </div>
      <div className={styles.value}>{getCreationDate()}</div>
      <div className={styles.label}>
        <FormattedMessage id="groups.administrators" />
      </div>
      <div className={styles.value}>{getAdministratorsCount()}</div>
      <div className={styles.label}>
        <FormattedMessage
          id="groups.header.member"
          values={{ membersCount: object.users.length }}
        />
      </div>
      <div className={styles.value}>{object.users.length}</div>
    </div>
  );
};

export default GroupDetails;
