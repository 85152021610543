import { setAppVersion } from 'Auth/redux/localStorageSlice';
import { resetAppState, setAppLoading } from 'App/redux/appSlice';
import getConfig from 'dodoc.config';
import { Middleware } from 'redux';
import { RootState } from '.';

/**
 * Store Middleware function to validate app version
 */
export const validateAppVersion: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (action.type === setAppLoading.type /* && process.env.NODE_ENV === 'production' */) {
    const localVersion = store.getState().localStorage.version;
    if (localVersion) {
      if (localVersion !== getConfig().version) {
        store.dispatch(resetAppState());
        store.dispatch(setAppVersion(getConfig().version));
      }
    } else {
      store.dispatch(setAppVersion(getConfig().version));
    }
  }

  return next(action);
};
