import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, InputField, Input, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks/redux';

import { closeAndResetModal, closeModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { exportCitations } from 'Editor/redux/CitationsSlice';

const RenameEndNoteFileModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [name, setName] = useState('');

  const document = useSelector(getDocumentObject);
  const isOpen = useSelector((state) => state.modals.open.RenameEndNoteFileModal);
  const title = useSelector((state) => state.modals.RenameEndNoteFileModal.title);
  const actionButtonLabel = useSelector(
    (state) => state.modals.RenameEndNoteFileModal.actionButtonLabel,
  );
  const type = useSelector((state) => state.modals.RenameEndNoteFileModal.type);
  const selectedCitations = useSelector((state) => state.modals.RenameEndNoteFileModal.citations);
  const citations = useSelector((state) => state.modals.CitationsModal);
  const target = useSelector((state) => state.modals.RenameEndNoteFileModal.target);

  const cancel = () => {
    dispatch(closeModal('RenameEndNoteFileModal'));
    dispatch(
      openAndUpdateModal({
        modal: 'CitationsModal',
        data: {
          ...citations,
        },
      }),
    );
    setName('');
  };

  const close = () => {
    setName('');
    dispatch(closeAndResetModal('CitationsModal'));
    dispatch(closeModal('RenameEndNoteFileModal'));
  };

  const handleRename = () => {
    if (type === 'download') {
      dispatch(
        exportCitations({
          documentId: document.id,
          params: {
            citations: selectedCitations,
            extension: 'endnote',
            name: name.endsWith('xml') ? name : `${name}.xml`,
          },
        }),
      ).then(() => {
        close();
      });
    } else {
      dispatch(
        exportCitations({
          documentId: document.id,
          params: {
            citations: selectedCitations,
            extension: 'endnote',
            target: target,
            name: name.endsWith('xml') ? name : `${name}.xml`,
          },
        }),
      ).then(() => {
        close();
      });
    }
  };

  return (
    <Modal open={!!isOpen} width="60rem" onClose={cancel} testId="rename-endNote-file">
      <Modal.Header onClose={cancel}>
        <FormattedMessage id={title} />
      </Modal.Header>
      <Modal.Body>
        <InputField label="File name" size="large" testId="name-of-the-file-field" required="required">
          <Input
            placeholder={intl.formatMessage({ id: 'WRITE_THE_NAME_OF_THE_FILE' })}
            onChange={(e) => setName(e.target.value)}
            value={name}
            testId="name-of-the-file"
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={cancel} testId="rename-endNote-file-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button size="medium" variant="primary" onClick={handleRename} disabled={name.length === 0} testId="rename-endNote-file-submit-button">
          <FormattedMessage id={actionButtonLabel} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameEndNoteFileModal;
