import { ReactNode, useState, useEffect } from 'react';
import { ProgressBar, Table as BaseTable } from 'dodoc-design-system';
import { AxiosResponse } from 'axios';

import { useDispatch, useSelector } from '_common/hooks';
import { setSelected, listObjects, lazyLoad } from '_common/components/Table/TableSlice';

import Footer from './Footer/Footer';

import styles from './Table.module.scss';

import type { TableProps as BaseTableProps } from 'dodoc-design-system/build/types/Components/Table/Table';

export type ThunkTableProps<Value extends { id: string }[] = { id: string }[]> = {
  // Define if table rows can be selected
  selectable?: boolean;

  loadingLabel: string;

  renderEmptyState?: () => ReactNode;
  renderFooter?: () => ReactNode;
} & BaseTableProps<Value> &
  (
    | {
        // Table identity in order to differenciate content from the other tables
        identity: Table.Identity;
        lazyLoading?: boolean;
        fetchObjects: (params: Request.AllListParams) => Promise<AxiosResponse<unknown>>;
      }
    | {
        identity?: undefined;
        lazyLoading?: undefined;
        fetchObjects?: undefined;
      }
  );

const ThunkTable = ({
  identity,
  selectable = true,
  fetchObjects,
  renderEmptyState,
  renderFooter = () => <Footer />,
  lazyLoading,
  loadingLabel,
  ...props
}: ThunkTableProps) => {
  const getPersistedRequestState = () => {
    if (identity) {
      const localStorageItem = localStorage.getItem(identity);
      if (!localStorageItem) {
        return {};
      }

      return JSON.parse(localStorageItem); // Holds order and filters
    }
  };

  const dispatch = useDispatch();
  const contentLoading = useSelector((state) => state.table.contentLoading);
  const requestState = useSelector((state) => state.table.request); // Holds offset and size
  const identityState = useSelector((state) =>
    identity ? state.table.identity[identity] : undefined,
  );
  const selected = useSelector((state) => state.table.selected);

  const [selection, setSelection] = useState<{ id: string }[]>([]);
  const [anchor, setAnchor] = useState(0);

  useEffect(() => {
    setSelection(
      Object.keys(selected).map((selectedId) => {
        return { id: selectedId };
      }),
    );
  }, [selected]);

  const handleOrderUpdate = (newOrder: Request.OrderParams) => {
    const request = { ...requestState, ...getPersistedRequestState(), ...newOrder, offset: 0 };

    if (identity) {
      dispatch(
        listObjects({
          identity,
          fetch: fetchObjects,
          request,
          cause: 'ORDER',
        }),
      );
    }
  };
  return (
    <div className={styles.root}>
      {contentLoading && (
        <div className={styles.loading}>
          <ProgressBar label={loadingLabel} testId={`table-${identity}-progressBar`} />
        </div>
      )}

      {(!props?.value || props.value.length < 1) && !contentLoading && renderEmptyState?.()}

      {!contentLoading && props?.value && props.value.length > 0 && (
        <div className={styles.content}>
          <BaseTable
            selectionMode={selectable ? 'multiple' : undefined}
            selection={selection}
            {...props}
            onCheckboxClick={
              selectable
                ? (_, rowData) => {
                    let selected = [];
                    const indexInSelection = selection.findIndex((row) => row.id === rowData.id);
                    const indexOfRow = props.value?.findIndex((row) => row.id === rowData.id) ?? 0;
                    if (indexInSelection === -1) {
                      selected = [...selection, rowData];
                      setAnchor(indexOfRow);
                    } else {
                      const newSelection = [...selection];
                      newSelection.splice(indexInSelection, 1);
                      selected = newSelection;
                      setAnchor(0);
                    }
                    setSelection(selected);
                    const selectedIds: Record<string, boolean> = {};
                    selected.forEach((row) => {
                      selectedIds[row.id] = true;
                    });
                    dispatch(setSelected(selectedIds));
                  }
                : undefined
            }
            onRowClick={(e) => {
              if (
                props.value &&
                selectable &&
                (props.selectionMode === 'multiple' || props.selectionMode === undefined)
              ) {
                let newSelection: typeof selection = [];
                const { originalEvent, data } = e;
                const indexOfRow = props.value?.findIndex((row) => row.id === data.id) ?? 0;

                if (originalEvent.shiftKey) {
                  newSelection = newSelection.concat(selection);
                  for (
                    let i = Math.min(anchor, indexOfRow);
                    i <= Math.max(anchor, indexOfRow);
                    i++
                  ) {
                    if (!newSelection.find((row) => row.id === props.value?.[i].id)) {
                      newSelection.push(props.value[i]);
                    }
                  }
                } else if (originalEvent.ctrlKey) {
                  const indexInSelection = selection.findIndex((row) => row.id === data.id);
                  newSelection = newSelection.concat(selection);

                  if (indexInSelection > -1) {
                    newSelection.splice(indexInSelection, 1);
                  } else {
                    newSelection.push(data);
                  }
                  setAnchor(indexOfRow);
                } else {
                  newSelection.push(data);
                  setAnchor(indexOfRow);
                }

                const selectedIds: Record<string, boolean> = {};
                newSelection.forEach((row) => {
                  selectedIds[row.id] = true;
                });
                dispatch(setSelected(selectedIds));
                setSelection(newSelection);
              }
              if (props.onRowClick) {
                props.onRowClick(e);
              }
            }}
            onSelectionChange={
              selectable
                ? (e) => {
                    const selectedIds: Record<string, boolean> = {};
                    e.value.forEach((row: { id: string }) => {
                      selectedIds[row.id] = true;
                    });

                    if (props.selectionMode === 'multiple' || props.selectionMode === undefined) {
                      if (e.type !== 'row') {
                        setSelection(e.value);
                        dispatch(setSelected(selectedIds));
                      }
                    } else {
                      if (Array.isArray(e.value)) {
                        setSelection(e.value);
                      } else {
                        setSelection([e.value]);
                      }
                      dispatch(setSelected(selectedIds));
                    }
                  }
                : undefined
            }
            virtualScrollerOptions={
              process.env.NODE_ENV === 'test'
                ? undefined
                : {
                    onLazyLoad: (e) => {
                      const { last } = e;
                      if (identityState?.hasNextPage && last === props.value?.length && identity) {
                        const request = {
                          ...requestState,
                          ...getPersistedRequestState(),
                        };

                        dispatch(
                          lazyLoad({
                            identity,
                            fetch: fetchObjects,
                            request,
                          }),
                        );
                      }
                    },
                    itemSize: 48,
                    lazy:
                      lazyLoading &&
                      identityState?.hasNextPage &&
                      !identityState?.isNextPageLoading,
                    ...props.virtualScrollerOptions,
                  }
            }
            onOrder={handleOrderUpdate}
            order={{
              order_field: getPersistedRequestState()?.order_field,
              order_type: getPersistedRequestState()?.order_type,
            }}
          />
        </div>
      )}

      {!contentLoading && renderFooter()}
    </div>
  );
};

export default ThunkTable;
