import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import {
  selectReferenceStyleById,
  useInstallReferenceStyleMutation,
} from 'Settings/pages/TenantSettingsPage/ReferencesStyles/ReferenceStylesApi';

import styles from './ActionsCell.module.scss';

type ActionsCellProps = {
  refStyleId: string;
};

const ActionsCell = ({ refStyleId }: ActionsCellProps) => {
  const dispatch = useDispatch();

  const refStyle = useSelector((state) => selectReferenceStyleById(state, refStyleId));
  const [installRefStyle] = useInstallReferenceStyleMutation();

  if (!refStyle) {
    return null;
  }

  const handleUninstallClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'UNINSTALL_REFERENCE_STYLE',
          titleValues: { title: refStyle.name },
          message: 'UNINSTALL_REFERENCE_STYLE_MESSAGE',
          messageValues: { refStyle: refStyle.name },
          confirmButtonTextId: 'UNINSTALL_REFERENCE_STYLE',
          confirmButtonTextValues: {},
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'uninstallReferenceStyle',
          actionValue: { id: refStyleId, name: refStyle.name },
          headerType: 'information',
          cancelButtonShow: true,
          width: '60rem',
        },
      }),
    );
  };
  const handleInstallClick = () => {
    installRefStyle({ id: refStyleId, name: refStyle.name });
  };
  const handleUpdateClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ExtensionUpdateInformationModal',
        data: {
          id: refStyleId,
          type: 'referenceStyle',
        },
      }),
    );
  };

  const getButtonProps = () => {
    switch (refStyle.status) {
      case 'installed':
      case 'outdated':
        return (
          <div className={styles.button}>
            <Button
              variant="danger"
              onClick={handleUninstallClick}
              size="small"
              fullWidth
              testId="uninstallButton"
            >
              <FormattedMessage id="UNINSTALL" />
            </Button>
          </div>
        );
      case 'not_installed':
        return (
          <div className={styles.button}>
            <Button
              variant="primary"
              onClick={handleInstallClick}
              size="small"
              fullWidth
              testId="installButton"
            >
              <FormattedMessage id="INSTALL" />
            </Button>
          </div>
        );
      default:
        throw Error(`Unknown Extension state ${refStyle.status}`);
    }
  };

  return (
    <div className={styles.root}>
      {refStyle.status === 'outdated' && (
        <div className={styles.button}>
          <Button size="small" onClick={handleUpdateClick} fullWidth testId="updateButton">
            <FormattedMessage id="UPDATE" />
          </Button>
        </div>
      )}
      {getButtonProps()}
    </div>
  );
};

export default ActionsCell;
