import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, InputField } from 'dodoc-design-system';

import { MeasureInput } from '_common/components';
import { MeasureInputProps } from '_common/components/MeasureInput/MeasureInput';

import { convertToDataUnit, convertToDisplayUnit } from '../utils';

import styles from './Section.module.scss';

type PaddingProps = Pick<Editor.Styles.TableProperties, 'padding'> & SectionProps;

/**
 * Received values unit is pt
 * Value should be displayed in cm, so must convert to cm
 * When updating data convert value back to point
 */

const Padding = ({ padding, updateData }: PaddingProps) => {
  const intl = useIntl();

  const [localTop, setLocalTop] = useState(convertToDisplayUnit(padding?.top?.value));
  const [localRight, setLocalRight] = useState(convertToDisplayUnit(padding?.right?.value));
  const [localBottom, setLocalBottom] = useState(convertToDisplayUnit(padding?.bottom?.value));
  const [localLeft, setLocalLeft] = useState(convertToDisplayUnit(padding?.left?.value));

  const handleTopChange: MeasureInputProps['onChange'] = (newValue) => {
    setLocalTop(+newValue);
    updateData({
      property: 'padding',
      value: { ...padding, top: { value: convertToDataUnit(+newValue) } },
    });
  };
  const handleRightChange: MeasureInputProps['onChange'] = (newValue) => {
    setLocalRight(+newValue);
    updateData({
      property: 'padding',
      value: { ...padding, right: { value: convertToDataUnit(+newValue) } },
    });
  };
  const handleBottomChange: MeasureInputProps['onChange'] = (newValue) => {
    setLocalBottom(+newValue);
    updateData({
      property: 'padding',
      value: { ...padding, bottom: { value: convertToDataUnit(+newValue) } },
    });
  };
  const handleLeftChange: MeasureInputProps['onChange'] = (newValue) => {
    setLocalLeft(+newValue);
    updateData({
      property: 'padding',
      value: { ...padding, left: { value: convertToDataUnit(+newValue) } },
    });
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'PADDING' })}
      initialCollapsed={false}
      contentMargin="1rem"
      testId="tableProperties-padding-accordion"
    >
      <div className={styles.root}>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'TOP' })}
            testId="tableProperties-padding-top-field"
          >
            <div className={styles.row}>
              <MeasureInput
                size="medium"
                value={`${localTop}`}
                onChange={handleTopChange}
                clearable={false}
                placeholder=""
                valueSuffix="cm"
                avoidEmpty
                min={0}
                testId="tableProperties-padding-top"
              />
            </div>
          </InputField>
          <InputField
            label={intl.formatMessage({ id: 'LEFT' })}
            testId="tableProperties-padding-left-field"
          >
            <div className={styles.row}>
              <MeasureInput
                size="medium"
                value={`${localLeft}`}
                onChange={handleLeftChange}
                clearable={false}
                placeholder=""
                valueSuffix="cm"
                avoidEmpty
                min={0}
                testId="tableProperties-padding-left"
              />
            </div>
          </InputField>
        </div>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'BOTTOM' })}
            testId="tableProperties-padding-bottom-field"
          >
            <div className={styles.row}>
              <MeasureInput
                size="medium"
                value={`${localBottom}`}
                onChange={handleBottomChange}
                clearable={false}
                placeholder=""
                valueSuffix="cm"
                avoidEmpty
                min={0}
                testId="tableProperties-padding-bottom"
              />
            </div>
          </InputField>
          <InputField
            label={intl.formatMessage({ id: 'RIGHT' })}
            testId="tableProperties-padding-right-field"
          >
            <div className={styles.row}>
              <MeasureInput
                size="medium"
                value={`${localRight}`}
                onChange={handleRightChange}
                clearable={false}
                placeholder=""
                valueSuffix="cm"
                avoidEmpty
                min={0}
                testId="tableProperties-padding-right"
              />
            </div>
          </InputField>
        </div>
      </div>
    </Accordion>
  );
};

export default Padding;
