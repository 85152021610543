import { CSSProperties, useMemo } from 'react';
import { useSlideData } from '../../SlideData';

const useStyle = (
  styleProperties?: Presentation.Data.Table.Cell.Style,
  tcPr?: Presentation.Data.Table.CellProperties,
) => {
  const { color } = useSlideData();
  const { addUnsupportedElement } = useSlideData();

  const getTextStyle = (tcTxStyle?: Presentation.Data.Table.Cell.Style['tcTxStyle']) => {
    const style: CSSProperties = {};
    if (tcTxStyle) {
      if (tcTxStyle?.color) {
        style.color = color(tcTxStyle.color);
      }
      if (tcTxStyle?.b) {
        style.fontWeight = 'bold';
      }
    }

    return style;
  };

  const style = useMemo(() => {
    const textStyle = getTextStyle(styleProperties?.tcTxStyle);
    const style: CSSProperties = { ...textStyle };

    //Default margin values
    style.paddingTop = '4.8px';
    style.paddingBottom = '4.8px';
    style.paddingRight = '9.6px';
    style.paddingLeft = '9.6px';

    if (tcPr) {
      if (tcPr.vert) {
        let vertType = '';
        switch (tcPr.vert) {
          case 'vert':
            vertType = '90°';
            break;
          case 'vert270':
            vertType = '270°';
            break;
          case 'wordArtVert':
            vertType = 'Stacked';
            break;
          case 'wordArtVertRtl':
            vertType = 'Stacked Right-to-left';
            break;
        }

        if (vertType) {
          addUnsupportedElement(`Table - Text Direction - ${vertType}`);
        }
      }

      if (tcPr.cell3d?.bevel) {
        addUnsupportedElement(`Table Effect - Bevel`);
      }

      if (tcPr.marT || tcPr.marT === 0) {
        style.paddingTop = `${tcPr.marT}px`;
      }
      if (tcPr.marR || tcPr.marR === 0) {
        style.paddingRight = `${tcPr.marR}px`;
      }
      if (tcPr.marB || tcPr.marB === 0) {
        style.paddingBottom = `${tcPr.marB}px`;
      }
      if (tcPr.marL || tcPr.marL === 0) {
        style.paddingLeft = `${tcPr.marL}px`;
      }
      switch (tcPr.anchor) {
        case 'b':
          style.verticalAlign = 'bottom';
          break;
        case 'ctr':
          style.verticalAlign = 'center';
          break;
        case 't':
          style.verticalAlign = 'top';
          break;
        case 'dist':
        case 'just':
          console.warn('tcPr.anchor', tcPr.anchor, 'TBI');
          break;
        default:
          style.verticalAlign = 'top';
          break;
      }
    }
    return style;
  }, [styleProperties, tcPr]);

  return { style, getTextStyle };
};

export default useStyle;
