import BaseController from '../BaseController';

type FindReplaceOptions = {
  matchCase: boolean;
  wholeWords: boolean;
  suggesting?: boolean;
};

export class FindAndReplaceController extends BaseController {
  start(): void {}

  stop(): void {}

  destroy(): void {}

  findInDocument(
    text: string,
    options: FindReplaceOptions,
  ): Promise<Editor.Data.FindAndReplace.WordOccurence[]> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'FIND:ALL:IN:DOCUMENT',
        {
          document: this.Data.context?.document?.id,
          term: text,
          options,
        },
        (response) => {
          if (response.success) {
            resolve(response.payload as Editor.Data.FindAndReplace.WordOccurence[]);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  replaceInDocument(
    occurrence: any,
    newTerm: string,
    options: FindReplaceOptions,
  ): Promise<Editor.Data.FindAndReplace.WordOccurence[]> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'REPLACE:IN:DOCUMENT',
        {
          occurence: occurrence,
          newTerm,
          options,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload as Editor.Data.FindAndReplace.WordOccurence[]);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  replaceAllInDocument(term: string, newTerm: string, options: FindReplaceOptions): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'REPLACE:ALL:IN:DOCUMENT',
        {
          term,
          newTerm,
          options,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
