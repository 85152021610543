import { ChangeEventHandler, CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { Input, SectionHeading } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type FieldHeadingProps = {
  field: Affiliation.Field;
  search: string;
  margin: CSSProperties['margin'];
  onFilterChange: (filterValue: string) => void;
};

const FieldHeading = ({ field, search, margin, onFilterChange }: FieldHeadingProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    onFilterChange(e.target.value);

  const nameField = () => {
    if (field === 'address1') {
      return 'address 1st line';
    }
    if (field === 'address2') {
      return 'address 2nd line';
    }
    if (field === 'zip_code') {
      return 'zip code';
    }
    return field;
  };
  return (
    <SectionHeading
      sticky
      title={nameField().slice(0, 1).toUpperCase() + nameField().slice(1)}
      search={
        <Input
          prefix="NavSearchGrey"
          placeholder={intl.formatMessage({ id: 'FILTER_OPTIONS' })}
          value={search}
          onChange={handleFilterChange}
          size="medium"
          testId={`header-affiliation-${field.toLocaleLowerCase()}-search`}
        />
      }
      buttonRightProps={{
        size: 'medium',
        onClick: () =>
          dispatch(
            openAndUpdateModal({
              modal: 'AddAffiliationModal',
              data: {
                field,
              },
            }),
          ),
        children: intl.formatMessage({ id: 'NEW' }),
        testId: `header-affiliation-${field.toLocaleLowerCase()}-new-button`,
      }}
      margin={margin}
      testId={`header-affiliation-${field.toLocaleLowerCase()}`}
    />
  );
};

export default FieldHeading;
