import { useGetImageQuery } from 'Presentation/api';
import { useSuiteObject } from '_common/suite';

type PictureBulletProps = {
  source: string;
  width: string | number | undefined;
  height: string | number | undefined;
};

const PictureBullet = ({ source, width, height }: PictureBulletProps) => {
  const { id } = useSuiteObject();
  const { data } = useGetImageQuery({ presentation_id: id, reference_id: source });

  // "alt" text will break the calculation of the bullet margin right
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={data} width={width} height={height} />;
};

export default PictureBullet;
