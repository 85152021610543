/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { BaseTypedEmitter } from '_common/services/Realtime';

class PDFDataManagerEmitter extends BaseTypedEmitter<PDF.PDFDataManagerEvents> {}

export class EventsController {
  ee: PDFDataManagerEmitter;

  constructor() {
    this.ee = new PDFDataManagerEmitter();
  }

  start(): void {}

  stop(): void {}

  destroy(): void {
    this.ee.destroy();
  }

  on<E extends PDF.PDFDataManagerEventName, H extends PDF.PDFDataManagerEvents[E]>(
    event: E,
    handler: H,
  ) {
    return this.ee.on(event, handler);
  }

  off<E extends PDF.PDFDataManagerEventName, H extends PDF.PDFDataManagerEvents[E]>(
    event: E,
    handler: H,
  ) {
    return this.ee.off(event, handler);
  }

  emit<E extends PDF.PDFDataManagerEventName, H extends PDF.PDFDataManagerEvents[E]>(
    event: E,
    ...args: Parameters<H>
  ) {
    return this.ee.emit(event, ...args);
  }
}
