import { useIntl } from 'react-intl';
import { usePublicGroup } from '_common/hooks';

type GroupLabelProps = {
  groupId?: ObjectId;
};

const GroupLabel = ({ groupId = '' }: GroupLabelProps) => {
  const intl = useIntl();
  const { group, removed } = usePublicGroup(groupId, [404]);

  return <>{removed ? intl.formatMessage({ id: 'REMOVED_GROUP' }) : group?.name}</>;
};

export default GroupLabel;
