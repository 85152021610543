import { useSelector } from '_common/hooks';

import BannerContent from './Banner';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';

const NewVersionAvailableBanner = () => {
  const versionWarning = useSelector((state) => state.app.versionWarning);

  if (process.env.NODE_ENV === 'test') {
    return null;
  }

  return (
    <IntlErrorBoundary
      fallbackType="banner"
      mockProps={{ isOpen: versionWarning.isOpen, variant: 'information' }}
    >
      <BannerContent />
    </IntlErrorBoundary>
  );
};

export default NewVersionAvailableBanner;
