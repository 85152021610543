import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import type { SectionElement } from './SectionViewElement/SectionElement';

export class SectionViewBuilder {
  build(sectionId?: string): SectionElement {
    const element = DOMElementFactory.buildElement(ELEMENTS.SectionElement.TAG) as SectionElement;

    if (sectionId != null) {
      element.section = sectionId;
    }

    return element;
  }
}
