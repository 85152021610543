import { Popover, usePopper } from 'dodoc-design-system';
import { UserPopover } from '_common/components';
import { useIEnvision, useSelector } from '_common/hooks';
import SuiteAvatar from '../SuiteAvatar/SuiteAvatar';

const UserCenter = () => {
  const isIEnvision = useIEnvision();
  const userId = useSelector((state) => state.auth.userId);
  const { referenceProps, popperProps, close } = usePopper({
    placement: 'bottom-end',
    disabled: isIEnvision,
  });
  return (
    <>
      <div
        {...referenceProps}
        style={{ cursor: isIEnvision ? 'auto' : 'pointer' }}
        data-testid="user-popover-toggle"
      >
        <SuiteAvatar margin="0 0 0 1rem" userId={userId} />
      </div>
      <Popover {...popperProps} testId="user-profile-popper">
        <UserPopover toggle={close} />
      </Popover>
    </>
  );
};

export default UserCenter;
