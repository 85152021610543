import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import TasksPanel, { TasksPanelProps } from './TasksPanel';

const TasksPanelBoundary = (props: TasksPanelProps) => (
  <IntlErrorBoundary size="medium" margin="16rem 0 0 0">
    <TasksPanel {...props} />
  </IntlErrorBoundary>
);

export default TasksPanelBoundary;
