import { memo } from 'react';
import { Sidebar } from '_common/suite/components';
import PresentationSidebar from './Sidebar';

const SidebarBoundary = () => {
  return (
    <Sidebar>
      <PresentationSidebar />
    </Sidebar>
  );
};

export default memo(SidebarBoundary);
