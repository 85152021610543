import { useSelector } from '_common/hooks';

import BannerContent from './Banner';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';

const ConnectivityIssuesBanner = () => {
  const connectivityIssues = useSelector((state) => state.app.connectivityIssues);

  if (process.env.NODE_ENV === 'test') {
    return null;
  }

  return (
    <IntlErrorBoundary
      fallbackType="banner"
      mockProps={{ isOpen: !!connectivityIssues, variant: 'warning' }}
    >
      <BannerContent />
    </IntlErrorBoundary>
  );
};

export default ConnectivityIssuesBanner;
