import { BaseOperation } from '../BaseOperation';

export class ParseFigureForParagaphOperation extends BaseOperation<Editor.Data.Node.Model> {
  private figurePath: Editor.Selection.Path;

  constructor(baseModel: Editor.Data.Node.Model, figurePath: Editor.Selection.Path) {
    super(baseModel);
    this.figurePath = figurePath;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    let level0Data = this.model.selectedData();

    if (level0Data) {
      if (level0Data.childNodes?.[0] && level0Data.childNodes?.[0].type === 'image-element') {
        let op = this.getObjectOperationforPathValue(level0Data.childNodes[0].type, 'img', [
          ...this.figurePath,
          'childNodes',
          0,
          'type',
        ]);

        if (op) {
          this.ops.push(op);
        }
      }

      let op = this.getObjectOperationforPathValue(level0Data.type, 'p', [
        ...this.figurePath,
        'type',
      ]);
      if (op) {
        this.ops.push(op);
      }
    }

    return this;
  }
}
