import { path } from 'd3';
import { ellipseArcTo, pin } from './utils';

type RectangleProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'rect'
    | 'roundRect'
    | 'snip1Rect'
    | 'snip2SameRect'
    | 'snip2DiagRect'
    | 'snipRoundRect'
    | 'round1Rect'
    | 'round2SameRect'
    | 'round2DiagRect';
};

const getAdjstByType = (type: RectangleProps['type']) => {
  switch (type) {
    case 'roundRect':
    case 'snip1Rect':
    case 'snip2SameRect':
    case 'round1Rect':
    case 'round2SameRect':
    case 'round2DiagRect':
      return { adj1: 16667, adj2: 0 };
    case 'snip2DiagRect':
      return { adj1: 0, adj2: 16667 };
    case 'snipRoundRect':
      return { adj1: 16667, adj2: 16667 };
    case 'rect':
      return { adj1: 0, adj2: 0 };
  }
};

const generateRectanglePath = ({
  size,
  type,
  adjst,
}: RectangleProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side */
  const ss = Math.min(w, h);

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;

  switch (type) {
    case 'rect': {
      const d = path();
      d.rect(l, t, w, h);
      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b } };
    }
    case 'roundRect': {
      const a = pin(0, adj1, 50000);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const y2 = b - x1;
      const il = (x1 * 29289) / 100000;
      const ir = r - il;
      const ib = b - il;

      const dMain = path();
      dMain.moveTo(l, x1);
      ellipseArcTo(dMain, x1, x1, 'cd2', 'cd4', l, x1);
      dMain.lineTo(x2, t);
      ellipseArcTo(dMain, x1, x1, '3cd4', 'cd4', x2, t);
      dMain.lineTo(r, y2);
      ellipseArcTo(dMain, x1, x1, 0, 'cd4', r, y2);
      dMain.lineTo(x1, b);
      ellipseArcTo(dMain, x1, x1, 'cd4', 'cd4', x1, b);
      dMain.closePath();

      return { paths: [{ d: dMain.toString() }], textBounds: { l: il, t: il, r: ir, b: ib } };
    }
    case 'snip1Rect': {
      const a = pin(0, adj1, 50000);
      const dx1 = (ss * a) / 100000;
      const x1 = r - dx1;
      const it = dx1 / 2;
      const ir = (x1 + r) / 2;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x1, t);
      d.lineTo(r, dx1);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: l, t: it, r: ir, b } };
    }
    case 'snip2SameRect': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const tx1 = (ss * a1) / 100000;
      const tx2 = r - tx1;
      const bx1 = (ss * a2) / 100000;
      const bx2 = r - bx1;
      const by1 = b - bx1;
      const d = tx1 - bx1;
      const dx = d > 0 ? tx1 : bx1;
      const il = dx / 2;
      const ir = r - il;
      const it = tx1 / 2;
      const ib = (by1 + b) / 2;

      const dPath = path();
      dPath.moveTo(tx1, t);
      dPath.lineTo(tx2, t);
      dPath.lineTo(r, tx1);
      dPath.lineTo(r, by1);
      dPath.lineTo(bx2, b);
      dPath.lineTo(bx1, b);
      dPath.lineTo(l, by1);
      dPath.lineTo(l, tx1);
      dPath.closePath();

      return { paths: [{ d: dPath.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'snip2DiagRect': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const lx1 = (ss * a1) / 100000;
      const lx2 = r - lx1;
      const ly1 = b - lx1;
      const rx1 = (ss * a2) / 100000;
      const rx2 = r - rx1;
      const ry1 = b - rx1;
      const d = lx1 - rx1;
      const dx = d > 0 ? lx1 : rx1;
      const il = dx / 2;
      const ir = r - il;
      const ib = b - il;

      const dPath = path();
      dPath.moveTo(lx1, t);
      dPath.lineTo(rx2, t);
      dPath.lineTo(r, rx1);
      dPath.lineTo(r, ly1);
      dPath.lineTo(lx2, b);
      dPath.lineTo(rx1, b);
      dPath.lineTo(l, ry1);
      dPath.lineTo(l, lx1);
      dPath.closePath();

      return { paths: [{ d: dPath.toString() }], textBounds: { l: il, t: il, r: ir, b: ib } };
    }
    case 'snipRoundRect': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const x1 = (ss * a1) / 100000;
      const dx2 = (ss * a2) / 100000;
      const x2 = r - dx2;
      const il = (x1 * 29289) / 100000;
      const ir = (x2 + r) / 2;

      const dMain = path();
      dMain.moveTo(x1, t);
      dMain.lineTo(x2, t);
      dMain.lineTo(r, dx2);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.lineTo(l, x1);
      ellipseArcTo(dMain, x1, x1, 'cd2', 'cd4', l, x1);
      dMain.closePath();

      return { paths: [{ d: dMain.toString() }], textBounds: { l: il, t: il, r: ir, b } };
    }
    case 'round1Rect': {
      const a = pin(0, adj1, 50000);
      const dx1 = (ss * a) / 100000;
      const x1 = r - dx1;
      const idx = (dx1 * 29289) / 100000;
      const ir = r - idx;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(x1, t);
      ellipseArcTo(dMain, dx1, dx1, '3cd4', 'cd4', x1, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      return { paths: [{ d: dMain.toString() }], textBounds: { l, t, r: ir, b } };
    }
    case 'round2SameRect': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const tx1 = (ss * a1) / 100000;
      const tx2 = r - tx1;
      const bx1 = (ss * a2) / 100000;
      const by1 = b - bx1;
      const d = tx1 - bx1;
      const tdx = (tx1 * 29289) / 100000;
      const bdx = (bx1 * 29289) / 100000;
      const il = d > 0 ? tdx : bdx;
      const ir = r - il;
      const ib = b - bdx;

      const dPath = path();
      dPath.moveTo(tx1, t);
      dPath.lineTo(tx2, t);
      ellipseArcTo(dPath, tx1, tx1, '3cd4', 'cd4', tx2, t);
      dPath.lineTo(r, by1);
      ellipseArcTo(dPath, bx1, bx1, 0, 'cd4', r, by1);
      dPath.lineTo(bx1, b);
      ellipseArcTo(dPath, bx1, bx1, 'cd4', 'cd4', bx1, b);
      dPath.lineTo(l, tx1);
      ellipseArcTo(dPath, tx1, tx1, 'cd2', 'cd4', l, tx1);
      dPath.closePath();

      return { paths: [{ d: dPath.toString() }], textBounds: { l: il, t: tdx, r: ir, b: ib } };
    }
    case 'round2DiagRect': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const x1 = (ss * a1) / 100000;
      const y1 = b - x1;
      const a = (ss * a2) / 100000;
      const x2 = r - a;
      const dx1 = (x1 * 29289) / 100000;
      const dx2 = (a * 29289) / 100000;
      const d = dx1 - dx2;
      const dx = d > 0 ? dx1 : dx2;
      const ir = r - dx;
      const ib = b - dx;

      const dPath = path();
      dPath.moveTo(x1, t);
      dPath.lineTo(x2, t);
      ellipseArcTo(dPath, a, a, '3cd4', 'cd4', x2, t);
      dPath.lineTo(r, y1);
      ellipseArcTo(dPath, x1, x1, 0, 'cd4', r, y1);
      dPath.lineTo(a, b);
      ellipseArcTo(dPath, a, a, 'cd4', 'cd4', a, b);
      dPath.lineTo(l, x1);
      ellipseArcTo(dPath, x1, x1, 'cd2', 'cd4', l, x1);
      dPath.closePath();

      return { paths: [{ d: dPath.toString() }], textBounds: { l: dx, t: dx, r: ir, b: ib } };
    }
  }
};

export default generateRectanglePath;
