export default class SectionProperties {
  static Properties = Object.freeze({
    ORIENTATION: 'p_o',
    SECTION_START: 't',
  });

  static Orientation = Object.freeze({
    PORTRAIT: 'P',
    LANDSCAPE: 'L',
  });

  static Start = Object.freeze({
    CONTINUOUS: 'c',
    EVEN_PAGE: 'ep',
    NEXT_COLUMN: 'nc',
    NEXT_PAGE: 'np',
    ODD_PAGE: 'op',
  });
}
