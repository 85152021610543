import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Icon, Tooltip, Button } from 'dodoc-design-system';

import {
  getDocumentObject,
  removeAuthor,
  selectReadOnlyMode,
  reorderAuthors,
  updateMetaInformation,
} from 'Editor/redux/EditorStatusSlice';

import AuthorForm from './AuthorForm';

import styles from './Author.module.scss';
import { paths } from '_types/api';

type AuthorProps = {
  isLastAuthor?: Boolean;
  author: Author;
  authorIndex: number;
  main?: Boolean;
};

const Author = ({ isLastAuthor, author, authorIndex, main }: AuthorProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const docObject = useSelector(getDocumentObject);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);

  const [isOpen, setOpen] = useState(false);
  const [isRemoving, setRemoving] = useState(false);

  const authorName = [author.first_name, author.middle_name, author.last_name]
    .filter((n) => n)
    .join(' ');

  const handleRemoveAuthor = (authorIndex: number) => {
    dispatch(removeAuthor({ documentId: docObject.id, authorIndex }));
  };

  const handleUpdateMetaInformation = ({
    path,
    value,
  }: paths['/api/object/document/{document_id}/author/update']['post']['requestBody']['content']['multipart/form-data']) => {
    dispatch(
      updateMetaInformation({
        documentId: docObject.id,
        params: { path: `${authorIndex}.${path}`, value },
      }),
    );
  };

  const handleReorderAuthors = (params: { current: number; new: number }) => {
    dispatch(reorderAuthors({ documentId: docObject.id, params }));
  };

  return (
    <div className={styles.root}>
      {isRemoving ? (
        <div className={`${styles.userRow} ${styles.removing}`}>
          <div className={styles.confirmLabel}>
            <FormattedMessage id="REMOVE_AUTHOR" />
          </div>
          <Button
            variant="primary"
            margin="0 2rem 0 auto"
            size="small"
            onClick={() => handleRemoveAuthor(authorIndex)}
            disabled={isReadOnlyMode}
            testId="remove-author-confirm-button"
          >
            <FormattedMessage id="global.remove" />
          </Button>
          <Button
            size="small"
            onClick={() => setRemoving(!isRemoving)}
            testId="remove-author-cancel-button"
          >
            <FormattedMessage id="global.cancel" />
          </Button>
        </div>
      ) : (
        <div className={styles.authorContainer}>
          <div className={styles.infoContainer} data-testid={`author-${authorIndex + 1}`}>
            <div
              title={intl.formatMessage({ id: 'AUTHOR_TOOLTIP_INFORMATION' })}
              onClick={() => setOpen(!isOpen)}
              className={`${styles.angleIcon} ${!isOpen && styles.isAngleClose}`}
              data-testid="author-angle-icon"
            >
              <Icon icon="AngleDownBlue" size={24} />
            </div>
            <div className={styles.number}>{authorIndex + 1} - </div>
            <div className={styles.nameContainer}>
              <span className={`${!authorName && styles.unknownName}`} title={authorName || ''}>
                {authorName || intl.formatMessage({ id: 'UNKNOWN_AUTHOR' })}
              </span>
            </div>
            {main && (
              <div className={styles.mainAuthor} data-testid="main-author-icon">
                <Tooltip
                  content={intl.formatMessage({ id: 'MAIN_AUTHOR' })}
                  testId={`author-${authorIndex + 1}-main-author-tooltip`}
                >
                  <span>
                    <Icon icon="Users" size={32} />
                  </span>
                </Tooltip>
              </div>
            )}
            <div
              className={`${styles.mailIcon} ${author.corresponding && styles.corresponding} ${
                isReadOnlyMode && styles.disabled
              }`}
              onClick={() => {
                if (!isReadOnlyMode) {
                  handleUpdateMetaInformation({
                    path: 'corresponding',
                    // @ts-expect-error /api/object/document/{document_id}/author/update endpoint type is wrong, only accepts strings. Should accept boolean as well
                    value: !author.corresponding,
                  });
                }
              }}
              data-testid="corresponding-author-button"
            >
              <Tooltip
                content={intl.formatMessage({ id: 'CORRESPONDING_AUTHOR' })}
                disabled={isReadOnlyMode && !author.corresponding}
                testId={`author-${authorIndex + 1}-corresponding-author-tooltip`}
              >
                <span>
                  <Icon icon="Message" size={32} />
                </span>
              </Tooltip>
            </div>
            <Button
              size="medium"
              variant="link"
              icon="ElementListUpGrey"
              onClick={() => {
                if (authorIndex !== 0) {
                  setOpen(false);
                  handleReorderAuthors({ current: authorIndex, new: authorIndex - 1 });
                }
              }}
              disabled={isReadOnlyMode}
              testId="author-move-up-button"
            />
            <Button
              size="medium"
              variant="link"
              icon="ElementListDownGrey"
              onClick={() => {
                if (isLastAuthor) {
                  setOpen(false);
                  handleReorderAuthors({ current: authorIndex, new: authorIndex + 1 });
                }
              }}
              disabled={isReadOnlyMode}
              testId="author-move-down-button"
            />
            <Button
              variant="danger"
              margin="0 0 0 2rem"
              size="small"
              disabled={isReadOnlyMode}
              onClick={() => setRemoving(!isRemoving)}
              testId="remove-author-button"
            >
              <FormattedMessage id="global.remove" />
            </Button>
          </div>
        </div>
      )}
      {isOpen && <AuthorForm author={author} authorIndex={authorIndex} />}
    </div>
  );
};

export default Author;
