import Options, { Option } from '_common/suite/components/Card/Options/Options';

type TaskOption = Pick<Option, 'onClick' | 'disabled'>;

type TaskOptionsProps = {
  editTask: TaskOption;
  deleteTask: TaskOption;
  changeStatus: { onClick: (status: Card.Task.Status) => void; disabled: boolean };
  watchOptions: TaskOption;
  testId: string;
};

const STATUS: {
  [key in Card.Task.Status]: { label: string };
} = {
  td: { label: 'TODO' },
  pr: { label: 'IN_PROGRESS' },
  d: { label: 'auth.login.done' },
};

const TaskOptions = ({
  editTask,
  deleteTask,
  changeStatus,
  watchOptions,
  testId,
}: TaskOptionsProps) => {
  return (
    <Options
      editOption={{
        onClick: editTask.onClick,
        disabled: editTask.disabled,
        label: { id: 'EDIT_TASK' },
        order: 0,
      }}
      deleteOption={{
        onClick: deleteTask.onClick,
        disabled: deleteTask.disabled,
        label: { id: 'DELETE_TASK' },
        order: 1,
      }}
      moreOptions={[
        {
          label: { id: 'CHANGE_TASK_STATUS' },
          disabled: changeStatus.disabled,
          onClick: () => {},
          testId: `${testId}-changeStatus`,
          order: 2,
          options: (Object.keys(STATUS) as Array<keyof typeof STATUS>).map((status) => {
            return {
              label: { id: STATUS[status].label },
              disabled: changeStatus.disabled,
              onClick: () => changeStatus.onClick(status),
              testId: `${status}`,
            };
          }),
        },
        {
          label: { id: 'WATCH_OPTION' },
          disabled: watchOptions.disabled,
          onClick: watchOptions.onClick,
          order: 3,
          testId: 'watch',
        },
      ]}
      testId={testId}
    />
  );
};

export default TaskOptions;
