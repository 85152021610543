import { Icon, Tooltip } from 'dodoc-design-system';
import { selectIsPageLayout, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';
import { useDispatch, useSelector } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';
import styles from './Ruler.module.scss';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

const RulerGroup = ({ limits, marginSize }: { limits: number[]; marginSize: number }) => {
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const roundMargin = Math.round(marginSize * 100) / 100;
  const marginValue = `${roundMargin}`;
  const zoomvalue = useSelector((state) => state.editor.status.zoom);
  const splitValue = marginValue.split('.');
  const layout = useSelector((state) => state.editor.status.layout);
  const zoom = `${zoomvalue}`;
  const splitZoomValue = zoom.split('.');
  const value = (1 - zoomvalue) / 10;

  const defaultValue = 1.06;
  const handleZoomValue = () => {
    if (zoomvalue >= 2) {
      return defaultValue * zoomvalue + 0.115;
    } else if (zoomvalue === 0.5) {
      return defaultValue * zoomvalue - (value + 0.01);
    } else if (zoomvalue === 1) {
      return defaultValue * zoomvalue;
    } else if (zoomvalue < 1) {
      return defaultValue * zoomvalue - value;
    } else {
      return defaultValue * zoomvalue + Number(`0.0${splitZoomValue[1]}`);
    }
  };

  const openTabulationsModal = () => {
    if (!isReadOnlyMode && !isPageLayout) {
      dispatch(openModal('TabulationsModal'));
    }
  };

  const handleMarginCm = () => {
    const mmSize = () => {
      if (Number(splitValue[1]) < 10) {
        return [];
      } else if (Number(splitValue[1]) < 50) {
        return [1];
      } else if (Number(splitValue[1]) > 75) {
        return [1, 2, 3];
      } else {
        return [1, 2];
      }
    };
    return mmSize().map((_, i) => {
      return (
        <div
          key={i}
          className={styles.marginMm}
          style={{
            paddingRight: `${Number(handleZoomValue())}rem`,
          }}
        />
      );
    });
  };

  return (
    <div
      className={styles.groupsContainer}
      style={{ paddingLeft: layout === 'WEB' ? '1rem' : 0 }}
      onDoubleClick={openTabulationsModal}
    >
      {layout === 'PAGE' && (
        <div
          className={styles.marginMmContainer}
          style={{ paddingLeft: handleMarginCm().length > 2 ? '0.8rem' : 0 }}
        >
          {handleMarginCm()}
        </div>
      )}
      {limits.map((number) => {
        const value = number - Number(splitValue[0]);
        return (
          <div className={styles.group} key={number}>
            <div className={styles.cmNumber}>
              {value !== 0 && <span className={styles.label}>{value < 0 ? -value : value}</span>}
              <div
                className={`${value === 0 ? styles.first : styles.mm} ${styles.number}`}
                style={{
                  margin: `0 ${Number(handleZoomValue())}rem`,
                }}
              />
            </div>
            <div className={styles.cm}>
              <div
                className={styles.mm}
                style={{
                  paddingRight: `${Number(handleZoomValue())}rem`,
                }}
              />
              <div
                className={styles.mm}
                style={{
                  paddingLeft: `${Number(handleZoomValue())}rem`,
                }}
              />
              <div className={styles.mm} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Ruler = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const sectionProperties = useSelector(
    (state) => state.editor.status.selection.SECTION?.properties,
  );
  const indentation = useSelector((state) => state.editor.toolbar.indentation);
  const tabulations = useSelector((state) => state.editor.status.selection.TABULATIONS);
  const layout = useSelector((state) => state.editor.status.layout);
  const zoomvalue = useSelector((state) => state.editor.status.zoom);
  const [pageWidth, setPageWidth] = useState(sectionProperties?.sz?.w ?? 516);
  const [leftMargin, setLeftMargin] = useState(sectionProperties?.mar?.l ?? 66);
  const [rightMargin, setRightMargin] = useState(sectionProperties?.mar?.r ?? 66);
  const indentationLeft = EditorDOMUtils.convertUnitTo(
    indentation?.leftIndentation ?? 0,
    'cm',
    'pt',
  );
  const indentationRight = EditorDOMUtils.convertUnitTo(
    indentation?.rightIndentation ?? 0,
    'cm',
    'pt',
  );

  useEffect(() => {
    if (layout === 'PAGE') {
      if (
        sectionProperties &&
        sectionProperties?.sz?.w &&
        sectionProperties?.mar?.l &&
        sectionProperties?.mar?.r
      ) {
        setPageWidth(sectionProperties.sz.w);
        setLeftMargin(sectionProperties?.mar.l);
        setRightMargin(sectionProperties?.mar?.r);
      }
    } else {
      if (sectionProperties?.p_o === 'L') {
        setPageWidth(709.5);
        setLeftMargin(66);
        setRightMargin(66);
      } else {
        setPageWidth(516);
        setLeftMargin(66);
        setRightMargin(66);
      }
    }
  }, [layout, zoomvalue, sectionProperties]);

  const convertPageSize = EditorDOMUtils.convertUnitTo(pageWidth, 'pt', 'cm');
  const convertLeftMargin = EditorDOMUtils.convertUnitTo(leftMargin, 'pt', 'cm', 3);
  const marginRight = layout === 'WEB' ? pageWidth + leftMargin : pageWidth - rightMargin;
  const marginLeft = leftMargin;
  let pageLimits = [];
  const maxLimit =
    layout === 'WEB'
      ? convertPageSize + Math.floor(convertLeftMargin) * 2 + 2
      : convertPageSize + 1;
  for (let i = 0; i < maxLimit - 1; i++) {
    pageLimits.push(i);
  }

  const openPageSetup = () => {
    dispatch(setSelectedTab({ menuId: 'pageSetup', selectedTab: 'margins' }));
    dispatch(openModal('PageSetupModal'));
  };

  const convertValue = (value: number) => {
    return EditorDOMUtils.convertUnitTo(value, 'pt', 'px', 1);
  };

  const handleTabulationIcon = (type: string) => {
    switch (type) {
      case 'l':
        return 'TabulationLeft';
      case 'r':
        return 'TabulationRight';
      case 'c':
        return 'TabulationCenter';
      case 'b':
        return 'TabulationBarTab';
      default:
        return 'TabulationLeft';
    }
  };

  const handleTabulationLabel = (type: string) => {
    switch (type) {
      case 'l':
        return 'LEFT_TAB';
      case 'r':
        return 'RIGHT_TAB';
      case 'c':
        return 'CENTER_TAB';
      case 'b':
        return 'BAR_TAB';
      default:
        return 'LEFT_TAB';
    }
  };

  const handleTabulations = () => {
    return tabulations?.map((tabulation: Editor.Tabulations.CustomTabStop, i) => {
      const handleValue = () => {
        if (layout === 'WEB' || (leftMargin > 100 && rightMargin > 100)) {
          if (tabulation.type === 'r') {
            return 6;
          }
          if (tabulation.type === 'b') {
            return 2;
          } else {
            return 0;
          }
        } else {
          if (tabulation.type === 'r') {
            return 8;
          } else {
            return 6;
          }
        }
      };
      return (
        <div
          key={i}
          className={styles.tabulationIcon}
          style={{
            marginLeft:
              convertValue((marginLeft + (tabulation?.value ?? 0)) * zoomvalue) - handleValue(),
          }}
        >
          <Tooltip
            content={intl.formatMessage({ id: handleTabulationLabel(tabulation.type) })}
            placement="bottom"
            testId={`tabulation-${i}-tooltip`}
          >
            <Icon size={12} icon={handleTabulationIcon(tabulation.type)} />
          </Tooltip>
        </div>
      );
    });
  };

  const handleRulerSize = () => {
    if (layout === 'WEB') {
      return pageWidth + leftMargin + rightMargin;
    } else {
      return pageWidth;
    }
  };

  const handleIndentationIcon = useMemo(() => {
    if (indentationLeft === 0) {
      return 8;
    } else {
      if (layout === 'PAGE') {
        if (leftMargin > 100 && rightMargin > 100) {
          return 2;
        } else return 8;
      } else return 4;
    }
  }, [indentationLeft, indentationRight]);
  return (
    <div className={styles.root}>
      <div className={`${styles.container} ${layout === 'WEB' && styles.webLayout}`}>
        {layout === 'PAGE' && <div className={`${styles.rectangle} ${styles.left}`} />}
        <div
          className={styles.ruler}
          style={{ width: `${convertValue(handleRulerSize()) * zoomvalue}px` }}
        >
          {handleTabulations()}
          <div
            style={{
              marginLeft:
                convertValue((marginLeft + indentationLeft) * zoomvalue) - handleIndentationIcon,
            }}
            className={styles.indentationIcon}
          >
            <Tooltip
              content={intl.formatMessage({ id: 'LEFT_INDENT' })}
              placement="bottom"
              testId="left-indent-tooltip"
            >
              <Icon icon="Indentation" size={16} />
            </Tooltip>
          </div>
          <div
            style={{
              marginLeft: `${convertValue(marginRight - indentationRight) * zoomvalue - 8}px`,
            }}
            className={styles.indentationIcon}
          >
            <Tooltip
              content={intl.formatMessage({ id: 'RIGHT_INDENT' })}
              placement="bottom"
              testId="right-indent-tooltip"
            >
              <Icon icon="Indentation" size={16} />
            </Tooltip>
          </div>
          <RulerGroup
            limits={pageLimits || Array.from(Array(21).keys())}
            marginSize={convertLeftMargin}
          />
          <div
            className={styles.leftMargin}
            style={{ width: `${convertValue(marginLeft * zoomvalue)}px` }}
            onClick={openPageSetup}
          />
          <div
            className={styles.rightMargin}
            style={{
              marginLeft: `${convertValue(marginRight * zoomvalue)}px`,
              width: `${convertValue(rightMargin * zoomvalue)}px`,
            }}
            onClick={openPageSetup}
          />
        </div>
        <div className={styles.rectangle} />
      </div>
    </div>
  );
};

export default Ruler;
