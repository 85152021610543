import { Ref, forwardRef } from 'react';
import Toggle, { ToggleProps } from './Toggle';
import CommentCard from 'Presentation/Sidebar/CommentsPanel/CommentCard/CommentCard';
import TaskCard from 'Presentation/Cards/TaskCard';

type EditableCardToggleProps = (
  | {
      mode: 'edit';
      object: Presentation.Data.Comment | Presentation.Data.Task;
    }
  | { mode: 'create'; object?: never }
) &
  Omit<ToggleProps, 'children'>;

const EditableCardToggle = forwardRef(
  ({ type, object, mode, ...toggleProps }: EditableCardToggleProps, ref: Ref<HTMLDivElement>) => {
    const renderCard = () => {
      switch (type) {
        case 'comment': {
          if (mode === 'edit') {
            return <CommentCard comment={object as Presentation.Data.Comment} />;
          }
          return <CommentCard comment={object} isTemporary />;
        }
        case 'task': {
          if (mode === 'edit') {
            return (
              <TaskCard
                mode={mode}
                task={object as Presentation.Data.Task}
                testId={`slide-task-card-${object.id}`}
              />
            );
          }
          return <TaskCard mode="create" testId="slide-new-task" />;
        }
        default: {
          return null;
        }
      }
    };

    return (
      <Toggle type={type} ref={ref} {...toggleProps}>
        {renderCard()}
      </Toggle>
    );
  },
);

export default EditableCardToggle;
