import { ChangeEventHandler, ReactNode, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Icon } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { ELEMENTS } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';

import {
  openAndUpdateModal,
  openModal as deprecatedOpenModal,
  updateModal,
} from '_common/modals/ModalsSlice';
import {
  selectCanApprove,
  selectCanChangePermission,
  selectReadOnlyMode,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { setSelectedPermissions } from 'Editor/redux/PermissionsSlice';
import { setSelectedNodesForApproval } from 'Editor/redux/BlockApprovalSlice';
import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';
import { setNodesFilters } from '_common/components/AuditLog/AuditLogSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { selectedBulletList, selectedNumberedList } from 'Editor/redux/ToolbarSlice';
import { setPulseData } from 'App/redux/onboardingSlice';
import { getTrackingState } from 'Editor/redux/TrackingSlice';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';

import {
  ContextMenu,
  ContextMenuItem,
  ContextSubMenu,
  InteractionController,
} from '_common/components';

import styles from './EditorContextMenu.module.scss';
import { useSuiteObject } from '_common/suite';
import { openModal } from 'App/ModalContext/utils';
import { useCanUserPerform, useAuditLogModal } from 'Editor/hooks';
import { Logger } from '_common/services';

const CustomSubMenuTitle = ({ children }: { children: ReactNode }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {children}
      <div style={{ right: 8, position: 'absolute' }}>
        <Icon icon="AngleRightGrey" size={24} />
      </div>
    </div>
  );
};

const EditorContextMenu = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const object = useSuiteObject();
  const userCanComment = useCanUserPerform({ action: 'comment', byBlocks: true });
  const { openAuditLogModal } = useAuditLogModal();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const selection = useSelector((state) => state.editor.status.selection);
  const trackingIsOn = useSelector((state) => getTrackingState(state).state);
  const selectedCells = selection.TABLE ? selection.TABLE.selectedCells : [];

  const insertingComment = useSelector((state) => state.editor.comments.insert.inserting);
  const canApprove = useSelector(selectCanApprove);
  const canChangePermissions = useSelector(selectCanChangePermission);
  const inputRef = useRef<HTMLInputElement>(null);
  const manager = EditorManager.getInstance();
  const isOwner =
    object.user_permissions.includes('owner') || object.user_permissions.includes('admin');
  const isPageLayout = useSelector(selectIsPageLayout);
  const isiEnvision = useSelector(selectIsIEnvision);
  const bulletListSelected = useSelector((state) => selectedBulletList(state));
  const numberedListSelected = useSelector((state) => selectedNumberedList(state));
  const createTaskItemRef = useRef<HTMLDivElement>(null);
  const createCommentItemRef = useRef<HTMLDivElement>(null);
  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);
  const isEnvision = useSelector(selectIsIEnvision);

  const temporaryComment = useSelector((state) => state.editor.comments.insert);
  const sidebar = useSelector((state) => state.editor.sidebar);

  const canCreateTask = !isEnvision;

  const handleInsertComment = useCallback(() => {
    if (isPageLayout) {
      dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
      dispatch(setSidebarView('REVIEW'));

      EditorManager.getInstance().addTemporaryComment();
    } else if (temporaryComment.inserting && temporaryComment?.reference) {
      EditorManager.getInstance().focusComment(temporaryComment.reference);
    } else {
      if (sidebar.view) {
        if (sidebar.view !== 'REVIEW') {
          dispatch(setSidebarView('REVIEW'));
        }
        if (sidebar.tabs.review !== 'comments') {
          dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        }
      }

      EditorManager.getInstance().addTemporaryComment();
    }
  }, [sidebar.view, sidebar.tabs.review, temporaryComment, isPageLayout]);

  const onShowContextMenu = () => {
    if (createTaskItemRef?.current) {
      dispatch(
        setPulseData({
          contextMenuTaskItemRect: {
            top: createTaskItemRef.current.offsetTop,
            left: createTaskItemRef.current.offsetLeft,
            height: createTaskItemRef.current.offsetHeight,
            width: createTaskItemRef.current.offsetWidth,
          },
        }),
      );
    } else {
      dispatch(
        setPulseData({
          contextMenuTaskItemRect: undefined,
        }),
      );
    }

    if (createCommentItemRef?.current) {
      dispatch(
        setPulseData({
          contextMenuCommentItemRect: {
            top: createCommentItemRef.current.offsetTop,
            left: createCommentItemRef.current.offsetLeft,
            height: createCommentItemRef.current.offsetHeight,
            width: createCommentItemRef.current.offsetWidth,
          },
        }),
      );
    } else {
      dispatch(
        setPulseData({
          contextMenuCommentItemRect: undefined,
        }),
      );
    }
  };

  const onHideContextMenu = () => {
    dispatch(
      setPulseData({
        contextMenuTaskItemRect: undefined,
        contextMenuCommentItemRect: undefined,
      }),
    );
  };

  const handleClickEditEquations = () => {
    manager.restore();
    manager.editEquation();
  };

  const handleUploadFigure: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    if (files?.[0]) {
      let i;
      for (i = 0; i < files.length; i += 1) {
        if (selection.IMAGE) {
          //replace
          manager.handleChangeImage(files[i]);
        } else {
          //insert
          manager.handleInsertImage(files[i]);
        }
      }
    }

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleClickSectionProperties = () => {
    dispatch(deprecatedOpenModal('PageSetupModal'));
    dispatch(setSelectedTab({ menuId: 'pageSetup', selectedTab: 'sections' }));
  };

  const handleCaption = (mode: 'insert' | 'edit') => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editMode: mode !== 'insert' } }));
  };

  const handleAcceptSelectedChanged = () => {
    if (selection.TRACKED.length === 1) {
      EditorManager.getInstance().acceptAllTrackedActions(selection.TRACKED);
    } else {
      const message = isOwner
        ? 'ACCEPT_SELECTED_CHANGES_MESSAGE'
        : 'ACCEPT_ALL_YOUR_CHANGES_IN_THE_SELECTION';
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            headerType: 'information',
            cancelButtonShow: true,
            width: '56rem',
            title: 'ACCEPT_CHANGES',
            titleValues: { total: selection.TRACKED.length },
            message,
            confirmButtonTextId: 'ACCEPT_CHANGES',
            confirmButtonTextValues: { total: selection.TRACKED.length },
            actionCode: 'acceptAllTrackedActions',
            actionValue: { selectedActions: selection.TRACKED, ontoAll: isOwner },
          },
        }),
      );
    }
  };

  const handleRejectSelectedChanged = () => {
    if (selection.TRACKED.length === 1) {
      EditorManager.getInstance().rejectAllTrackedActions(selection.TRACKED);
    } else {
      const message = isOwner
        ? 'REJECT_SELECTED_CHANGES_MESSAGE'
        : 'REJECT_ALL_YOUR_CHANGES_IN_THE_SELECTION';

      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            headerType: 'information',
            cancelButtonShow: true,
            width: '56rem',
            title: 'REJECT_CHANGES',
            titleValues: { total: selection.TRACKED.length },
            message,
            confirmButtonTextId: 'REJECT_CHANGES',
            confirmButtonTextValues: { total: selection.TRACKED.length },
            actionCode: 'rejectAllTrackedActions',
            actionValue: { selectedActions: selection.TRACKED, ontoAll: isOwner },
          },
        }),
      );
    }
  };

  const handleResolveSelectedComments = () => {
    if (selection.COMMENT.length === 1) {
      EditorManager.getInstance()
        .resolveAllComments(selection.COMMENT)
        .then((...args) => {
          notify({
            type: 'success',
            title: 'global.comment',
            message: 'notifications.commentResolve.messageSuccess',
          });
        });
    } else {
      const message = isOwner
        ? 'RESOLVE_ALL_COMMENTS_IN_THE_SELECTION'
        : 'RESOLVE_ALL_YOUR_COMMENTS_IN_THE_SELECTION';

      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            headerType: 'information',
            cancelButtonShow: true,
            width: '56rem',
            title: 'RESOLVE_ALL_COMMENTS',
            message,
            confirmButtonTextId: 'RESOLVE_COMMENTS',
            actionCode: 'resolveAllComments',
            actionValue: { selectedComments: selection.COMMENT, ontoAll: isOwner },
          },
        }),
      );
    }
  };

  const handleDeleteSelectedComments = () => {
    if (selection.COMMENT.length === 1) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'DELETE_COMMENT',
            message: 'DELETING_THIS_COMMENT_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
            cancelButtonTextId: 'global.cancel',
            confirmButtonType: 'danger',
            confirmButtonTextId: 'global.delete',
            headerType: 'error',
            actionCode: 'deleteComment',
            actionValue: {
              commentId: selection.COMMENT[0].ref,
            },
            cancelButtonShow: true,
          },
        }),
      );
    } else {
      const message = isOwner
        ? 'DELETE_ALL_COMMENTS_IN_THE_SELECTION'
        : 'DELETE_ALL_YOUR_COMMENTS_IN_THE_SELECTION';

      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            confirmButtonType: 'primary',
            cancelButtonTextId: 'global.cancel',
            headerType: 'information',
            cancelButtonShow: true,
            width: '56rem',
            title: 'DELETE_ALL_COMMENTS',
            message,
            confirmButtonTextId: 'DELETE_COMMENTS',
            actionCode: 'deleteAllComments',
            actionValue: { selectedComments: selection.COMMENT, ontoAll: isOwner },
          },
        }),
      );
    }
  };

  const handleOpenHyperlink = () => {
    EditorManager.getInstance().openHyperlink();
  };

  const handleInsertHyperlink = () => {
    const data = EditorManager.getInstance().getLinkData();

    dispatch(
      updateModal({
        modal: 'HyperlinkModal',
        data: {
          ...data,
        },
      }),
    );
    dispatch(deprecatedOpenModal('HyperlinkModal'));
  };

  const handleEditHyperlink = () => {
    EditorManager.getInstance().editHyperlink();
  };

  const handleRemoveHyperlink = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'REMOVE_HYPERLINK',
          message: 'REMOVING_THE_HYPERLINK_WILL_REMOVED_THE_URL_CONFIRM',
          confirmButtonTextId: 'REMOVE_HYPERLINK',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'deleteHyperlink',
          headerType: 'error',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleOpenUpdateModal = (
    actionCode: 'updateReferenceSection' | 'updateReferences' | 'updateNotes' | 'updateListIndex',
  ) => {
    let title: string | null = null;
    let message: string | null = null;
    let confirmButtonTextId: string | null = null;
    let actionValue = null;

    switch (actionCode) {
      case 'updateReferenceSection': {
        title = 'UPDATE_REFERENCE_SECTION';
        message = 'DO_YOU_WANT_TO_UPDATE_REFERENCE_SECTION';
        confirmButtonTextId = 'UPDATE_REFERENCE_SECTION';
        break;
      }
      case 'updateReferences': {
        title = 'UPDATE_REFERENCES';
        message = 'UPDATE_REFERENCES_CONFIRMATION';
        confirmButtonTextId = 'UPDATE_REFERENCES';
        break;
      }
      case 'updateNotes': {
        const noteType: Notes.NoteType | null = selection.NOTE ? selection.NOTE.type : null;
        if (noteType) {
          if (noteType === 'endnote') {
            title = 'UPDATE_ENDNOTES';
            message = 'UPDATE_ENDNOTES_CONFIRMATION';
            confirmButtonTextId = 'UPDATE_ENDNOTES';
          } else {
            title = 'UPDATE_FOOTNOTES';
            message = 'UPDATE_FOOTNOTES_CONFIRMATION';
            confirmButtonTextId = 'UPDATE_FOOTNOTES';
          }
          actionValue = { noteType };
        }
        break;
      }
      case 'updateListIndex': {
        title = 'UPDATE_LIST';
        message = 'UPDATE_LIST_CONFIRMATION';
        confirmButtonTextId = 'UPDATE_LIST';
        break;
      }
      default: {
        break;
      }
    }

    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title,
          message,
          confirmButtonTextId,
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode,
          actionValue,
          headerType: 'information',
          cancelButtonShow: true,
          width: '60rem',
        },
      }),
    );
  };

  const handleViewPermissiosForCurrentSelection = () => {
    const nodes = EditorManager.getInstance().getSelectedLevel0Nodes();
    dispatch(setSidebarView('CONTENT_PERMISSIONS'));
    dispatch(setSelectedPermissions(nodes || []));
  };

  const handleApproveBlocksForCurrentSelection = () => {
    dispatch(setSidebarView('APPROVE_CONTENT'));

    const nodes = EditorManager.getInstance().getSelectedLevel0Nodes();
    if (nodes) {
      dispatch(setSelectedNodesForApproval(nodes));
    }
  };

  const handleSelectionAuditLog = () => {
    dispatch(setNodesFilters(selection.BLOCK_IDS));
    openAuditLogModal();
  };

  const handleOpenCustomizeListModal = () => {
    const handleListType = () => {
      if (bulletListSelected) {
        return 'bullet';
      }
      if (numberedListSelected) {
        return 'numbered';
      } else {
        return 'outline_numbered';
      }
    };

    dispatch(
      openAndUpdateModal({
        modal: 'NewListModal',
        data: {
          customize: true,
          type: handleListType(),
        },
      }),
    );
  };

  const handleSetRowAsTableHeader = () => {
    if (selection.TABLE) {
      EditorManager.getInstance().setTableProperties({
        ROW: {
          headerRow: { value: true },
        },
        TABLE: {},
        CELL: {},
        COLUMN: {},
      });
    }
  };

  const handleEditTOC = () => {
    openModal({
      modal: 'TOCModal',
      data: {
        blockId: selection.BLOCK?.id || '',
        tocId: selection.TOC?.id || '',
        tolId: selection.TOL?.id || '',
        table: selection.TOC ? 'contents' : 'captions',
      },
    });
  };
  const handleUpdateTOC = () => {
    const tableOfType = selection.TOL
      ? selection.TOL.label
        ? selection.TOL.label
        : 'captions'
      : 'contents';
    openModal({
      modal: 'ConfirmationModal',
      data: {
        title: 'UPDATE_TABLE_OF',
        titleValues: { contentType: tableOfType },
        message: 'ACTION_WILL_RESET_IMPORTED_TABLE_OF',
        messageValues: { contentType: tableOfType },
        cancelButtonTextId: 'global.cancel',
        confirmButtonType: 'primary',
        confirmButtonTextId: 'UPDATE_TABLE_OF',
        confirmButtonTextValues: { contentType: tableOfType },
        headerType: 'information',
        modalWidth: '70rem',
        onConfirm: async () => {
          try {
            let result: Promise<any> | null = null;
            if (selection.BLOCK && selection.TOC && selection.TOC.id) {
              result = EditorManager.getInstance().updateTOCSection(
                selection.BLOCK.id,
                selection.TOC.id,
              );
            }
            if (selection.BLOCK && selection.TOL && selection.TOL.id) {
              result = EditorManager.getInstance().updateTableOfLabels(
                selection.BLOCK.id,
                selection.TOL.id,
              );
            }

            if (result) {
              await result;
              notify({
                type: 'success',
                title: 'TABLE_OF_TYPE_UPDATED',
                titleValues: { contentType: tableOfType },
                message: 'TABLE_OF_TYPE_WAS_SUCCESSFULLY_UPDATED',
                messageValues: { contentType: tableOfType },
              });
            }
          } catch (e) {
            notify({
              type: 'error',
              title: 'notifications.genericError.title',
              message: 'notifications.genericError.message',
            });
            Logger.captureException(e);
          }
        },
      },
    });
  };

  const renderEquationsOptions = () => {
    return (
      <>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => handleCaption('insert')}
          dataTestId="insert-caption-dropdown-item"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={handleClickEditEquations}
          dataTestId="edit-caption-dropdown-item"
        >
          <FormattedMessage id="EDITOR_EDIT_EQUATION" />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderClipboardOptions = () => {
    return (
      <>
        <ContextMenuItem
          onClick={manager.handleCutMenuOption}
          disabled={isReadOnlyMode || isPageLayout}
          shortcut="X"
          dataTestId="cut-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.cut" />
        </ContextMenuItem>
        <ContextMenuItem
          onClick={manager.handleCopyMenuOption}
          shortcut="C"
          dataTestId="copy-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.copy" />
        </ContextMenuItem>
        <ContextMenuItem
          onClick={manager.handlePasteMenuOption}
          disabled={isReadOnlyMode || isPageLayout}
          shortcut="V"
          dataTestId="paste-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.paste" />
        </ContextMenuItem>
      </>
    );
  };

  const renderTableOptions = () => {
    return (
      <>
        <ContextSubMenu
          title={
            <CustomSubMenuTitle>
              <FormattedMessage id="INSERT_ROW" />
              ...
            </CustomSubMenuTitle>
          }
          dataTestId="insert-row-dropdown-submenu"
        >
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={manager.insertRowBefore}
            dataTestId="insert-row-above-dropdown-item"
          >
            <FormattedMessage id="ABOVE" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={manager.insertRowAfter}
            dataTestId="insert-row-below-dropdown-item"
          >
            <FormattedMessage id="BELOW" />
          </ContextMenuItem>
        </ContextSubMenu>
        <ContextSubMenu
          title={
            <CustomSubMenuTitle>
              <FormattedMessage id="INSERT_COLUMN" />
              ...
            </CustomSubMenuTitle>
          }
          dataTestId="insert-column-dropdown-submenu"
        >
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.insertColumnLeft();
            }}
            dataTestId="insert-column-left-dropdown-item"
          >
            <FormattedMessage id="LEFT" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.insertColumnRight();
            }}
            dataTestId="insert-column-right-dropdown-item"
          >
            <FormattedMessage id="RIGHT" />
          </ContextMenuItem>
        </ContextSubMenu>
        <Divider />
        <ContextSubMenu
          title={
            <CustomSubMenuTitle>
              <FormattedMessage id="global.delete" />
              ...
            </CustomSubMenuTitle>
          }
          dataTestId="delete-dropdown-submenu"
        >
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              dispatch(deprecatedOpenModal('DeleteCellsModal'));
            }}
            dataTestId="cells-dropdown-item"
          >
            <FormattedMessage id="CELLS" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.deleteRows();
            }}
            dataTestId="row-dropdown-item"
          >
            <FormattedMessage id="ROW" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.deleteColumns();
            }}
            dataTestId="column-dropdown-item"
          >
            <FormattedMessage id="COLUMN" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.deleteTable();
              // setOpen(false);
            }}
            dataTestId="table-dropdown-item"
          >
            <FormattedMessage id="TABLE" />
          </ContextMenuItem>
        </ContextSubMenu>
        <Divider />

        <ContextMenuItem
          disabled={
            isReadOnlyMode ||
            isPageLayout ||
            selectedCells.length <= 1 ||
            (selectedCells[0].isMergedCell &&
              selectedCells.length === selectedCells[0].colSpan * selectedCells[0].rowSpan)
          }
          onClick={() => {
            manager.mergeCells();
          }}
          dataTestId="merge-cells-dropdown-item"
        >
          <FormattedMessage id="editor.menu.table.mergeCells" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={
            isReadOnlyMode ||
            isPageLayout ||
            (selectedCells.length > 1 && !selectedCells[0].isMergedCell)
          }
          onClick={() => {
            dispatch(deprecatedOpenModal('SplitCellsModal'));
          }}
          dataTestId="split-cells-dropdown-item"
        >
          <FormattedMessage id="editor.menu.table.splitCells" />
        </ContextMenuItem>
        <Divider />
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => handleCaption('insert')}
          dataTestId="insert-caption-dropdown-item"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={!selection.TABLE?.rows.fromStart}
          dataTestId="set-row-as-table-header"
          onClick={handleSetRowAsTableHeader}
          tooltip={{
            content: intl.formatMessage({ id: 'SET_ROW_AS_TABLE_HEADER_DISABLED_WARNING' }),
            disabled: selection.TABLE?.rows.fromStart,
            placement: 'right',
            testId: 'set-row-as-table-header-tooltip',
          }}
        >
          <FormattedMessage id="SET_ROW_AS_TABLE_HEADER" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            dispatch(deprecatedOpenModal('TablePropertiesModal'));
          }}
          dataTestId="table-properties-dropdown-item"
        >
          <FormattedMessage id="TABLE_PROPERTIES" />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderImageOptions = () => {
    return (
      <>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => handleCaption('insert')}
          dataTestId="insert-caption-dropdown-item"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            inputRef.current?.click();
          }}
          dataTestId="replace-image-dropdown-item"
        >
          <FormattedMessage id="editor.toolbar.replaceImage" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            dispatch(deprecatedOpenModal('WrapTextOptionsModal'));
          }}
          dataTestId="wrap-text-options-item"
        >
          <FormattedMessage id="WRAP_TEXT_OPTIONS" />
          ...
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderHyperlinkOptions = () => {
    return (
      <>
        {!selection.CITATION && !selection.LIST && !selection.NOTE && <Divider />}
        <ContextMenuItem
          shortcut="Click"
          onClick={handleOpenHyperlink}
          dataTestId="open-hyperlink-dropdown-item"
        >
          <FormattedMessage id="OPEN_HYPERLINK" />
        </ContextMenuItem>
        <ContextMenuItem
          shortcut="K"
          disabled={isReadOnlyMode || isPageLayout}
          onClick={handleEditHyperlink}
          dataTestId="edit-hyperlink-dropdown-item"
        >
          <FormattedMessage id="EDIT_HYPERLINK" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={handleRemoveHyperlink}
          dataTestId="remove-hyperlink-cells-dropdown-item"
        >
          <FormattedMessage id="REMOVE_HYPERLINK" />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderReferenceSectionOptions = () => {
    return (
      <>
        <Divider />
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            if (trackingIsOn) {
              if (window.confirm('This action will not be tracked. Proceed?')) {
                handleOpenUpdateModal('updateReferenceSection');
              }
            } else {
              handleOpenUpdateModal('updateReferenceSection');
            }
          }}
          dataTestId="update-reference-section-dropdown-item"
        >
          <FormattedMessage id="UPDATE_REFERENCE_SECTION" />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderNotesOptions = () => {
    const noteType: Notes.NoteType | null = selection.NOTE ? selection.NOTE.type : null;
    if (!noteType) {
      return null;
    }
    return (
      <>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            handleOpenUpdateModal('updateNotes');
          }}
          dataTestId={`update-${noteType === 'endnote' ? 'endnote' : 'footnote'}-dropdown-item`}
        >
          <FormattedMessage id={noteType === 'endnote' ? 'UPDATE_ENDNOTES' : 'UPDATE_FOOTNOTES'} />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderListOptions = () => {
    return (
      <>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            handleOpenUpdateModal('updateListIndex');
          }}
          dataTestId="update-list-dropdown-item"
        >
          <FormattedMessage id="UPDATE_LIST" />
        </ContextMenuItem>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            handleOpenCustomizeListModal();
          }}
          dataTestId="customize-list-dropdown-item"
        >
          <FormattedMessage id="CUSTOMIZE_LIST" />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const renderReferencesOptions = () => {
    return (
      <>
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={() => {
            handleOpenUpdateModal('updateReferences');
          }}
          dataTestId="update-references-dropdown-item"
        >
          <FormattedMessage id="UPDATE_REFERENCES" />
        </ContextMenuItem>
        <Divider />
      </>
    );
  };

  const selectionCollapsed = manager.isSelectionCollapsed();

  return (
    <ContextMenu
      id="EditorContextMenu"
      className={`${styles.menu} ${(active.editor || started.editor) && styles.overlap}`}
      onShow={onShowContextMenu}
      onHide={onHideContextMenu}
    >
      {renderClipboardOptions()}
      {selection.TEXT && (
        <>
          <Divider />
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_contextMenu_createComment',
                actions: ['editor_comments_createComment'],
              },
            ]}
          >
            <div ref={createCommentItemRef} style={{ width: '100%' }}>
              <ContextMenuItem
                tooltip={{
                  placement: 'right',
                  disabled: !insertingComment,
                  content: intl.formatMessage({ id: 'CANNOT_CREATE_COMMENT_BECAUSE_ALREADY_OPEN' }),
                  testId: 'comment-dropdown-item-tooltip',
                }}
                disabled={!userCanComment}
                onClick={handleInsertComment}
                dataTestId="comment-dropdown-item"
              >
                <FormattedMessage id="editor.menu.insert.comment" />
              </ContextMenuItem>
            </div>
          </InteractionController>
          {(selection.CITATION || selection.LIST || selection.NOTE) && <Divider />}
        </>
      )}
      {selection.REFSECTION && renderReferenceSectionOptions()}
      {selection.NOTE && renderNotesOptions()}
      {selection.LIST && renderListOptions()}
      {selection.CITATION && renderReferencesOptions()}
      {selection.LINK && !selection.TOC && !selection.TOL && renderHyperlinkOptions()}
      {selection.FIELD && selection.FIELD.type === ELEMENTS.FieldElement.TYPES.CROSS_REFERENCE && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              dispatch(
                openAndUpdateModal({
                  modal: 'UpdateCrossReferencesModal',
                  data: {
                    single: true,
                    all: false,
                  },
                }),
              );
            }}
            dataTestId="update-this-cross-reference-item"
          >
            <FormattedMessage id="UPDATE_THIS_CROSS_REFERENCE" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              dispatch(
                openAndUpdateModal({
                  modal: 'UpdateCrossReferencesModal',
                  data: {
                    all: true,
                    single: false,
                  },
                }),
              );
            }}
            dataTestId="update-all-cross-references-item"
          >
            <FormattedMessage id="UPDATE_ALL_CROSS_REFERENCES" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              const crossReference =
                selection.FIELD != null ? document.getElementById(selection.FIELD.id) : undefined;
              if (crossReference?.dataset.ref) {
                dispatch(
                  openAndUpdateModal({
                    modal: 'EditCrossReferencesModal',
                    data: {
                      crossReferenceId: crossReference.dataset.ref
                        .split(':')
                        .filter((el, index) => index > 0)
                        .join(),
                      insertAsLink: crossReference.dataset.link === 'true',
                    },
                  }),
                );
              }
            }}
            dataTestId="edit-cross-reference-item"
          >
            <FormattedMessage id="EDIT_CROSS_REFERENCE" />
          </ContextMenuItem>
        </>
      )}
      {!selection.IMAGE && !selection.TABLE && !selection.TOC && !selection.TOL && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.startCreatingNote('footnote');
            }}
            dataTestId="footnote-dropdown-item"
          >
            <FormattedMessage id="editor.menu.insert.footnote" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.startCreatingNote('endnote');
            }}
            dataTestId="endnote-dropdown-item"
          >
            <FormattedMessage id="ENDNOTE" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              dispatch(setSidebarView('CROSS_REFERENCES'));
            }}
            dataTestId="cross-reference-dropdown-item"
          >
            <FormattedMessage id="CROSS_REFERENCE" />
          </ContextMenuItem>
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_contextMenu_createTask',
                actions: ['editor_tasks_createTask'],
              },
            ]}
          >
            <div ref={createTaskItemRef} style={{ width: '100%' }}>
              <ContextMenuItem
                tooltip={{
                  content: intl.formatMessage({
                    id: 'NO_PERMISSION_TO_PERFORM_ACTION',
                  }),
                  placement: 'right',
                  disabled: canCreateTask,
                  testId: 'create-task-dropdown-item-tooltip',
                }}
                disabled={isReadOnlyMode || !canCreateTask}
                onClick={manager.openCreateTaskOverlay}
                dataTestId="create-task-dropdown-item"
              >
                <FormattedMessage id="CREATE_TASK" />
              </ContextMenuItem>
            </div>
          </InteractionController>
          <Divider />
        </>
      )}
      {selection.TRACKED?.length > 0 && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode || !isOwner}
            onClick={handleAcceptSelectedChanged}
            dataTestId="accept-changes-dropdown-item"
          >
            <FormattedMessage id="ACCEPT_CHANGES" values={{ total: selection.TRACKED.length }} />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode}
            onClick={handleRejectSelectedChanged}
            dataTestId="reject-changes-dropdown-item"
          >
            <FormattedMessage id="REJECT_CHANGES" values={{ total: selection.TRACKED.length }} />
          </ContextMenuItem>
          {!selection.TOC && !selection.TOL && <Divider />}
        </>
      )}
      {!selection.TABLE && !selection.TOC && !selection.TOL && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode || !isOwner}
            onClick={handleSelectionAuditLog}
            dataTestId="see_selection_audit_log"
          >
            <FormattedMessage id="SEE_SELECTION_AUDIT_LOG" />
          </ContextMenuItem>
          <Divider />
        </>
      )}
      {selection.COMMENT?.length > 0 && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode}
            onClick={handleResolveSelectedComments}
            dataTestId="resolve-comments-dropdown-item"
          >
            <FormattedMessage
              id={selection.COMMENT?.length > 1 ? 'RESOLVE_COMMENTS' : 'RESOLVE_COMMENT'}
            />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode}
            onClick={handleDeleteSelectedComments}
            dataTestId="delete-comments-dropdown-item"
          >
            <FormattedMessage
              id={selection.COMMENT?.length > 1 ? 'DELETE_COMMENTS' : 'DELETE_COMMENT'}
            />
          </ContextMenuItem>
          <Divider />
        </>
      )}
      {selection.TABLE && renderTableOptions()}
      {selection.IMAGE && renderImageOptions()}
      {!selection.IMAGE && !selection.TABLE && !selection.TOC && !selection.TOL && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              manager.insertTable(3, 3);
            }}
            dataTestId="insert-table-dropdown-item"
          >
            <FormattedMessage id="editor.menu.insert.table" />
            &nbsp;
            <span>(3x3)</span>
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => {
              inputRef.current?.click();
            }}
            dataTestId="figure-dropdown-item"
          >
            <FormattedMessage id="editor.menu.insert.figure" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || selectionCollapsed || isPageLayout}
            onClick={manager.clearFormatting}
            dataTestId="clear-formatting-dropdown-item"
          >
            <FormattedMessage id="editor.menu.format.clearFormatting" />
          </ContextMenuItem>
          <Divider />
          <ContextMenuItem
            disabled={isReadOnlyMode}
            onClick={() =>
              dispatch(
                openAndUpdateModal({ modal: 'SetLanguageModal', data: { onSelection: true } }),
              )
            }
            dataTestId="set-language-of-selection-dropdown-item"
          >
            <FormattedMessage id="SET_LANGUAGE_OF_SELECTION" />
          </ContextMenuItem>
          <Divider />
        </>
      )}

      {selection.EQUATION && renderEquationsOptions()}
      {selection.FIELD && selection.FIELD.type === ELEMENTS.FieldElement.TYPES.CAPTION && (
        <>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => handleCaption('edit')}
            dataTestId="edit-caption-dropdown-item"
          >
            <FormattedMessage id="EDIT_CAPTION" />
          </ContextMenuItem>
          <Divider />
        </>
      )}
      {/* TODO: Manage TOC selection */}
      {((selection.TOC && selection.TOC.id) || (selection.TOL && selection.TOL.id)) && (
        <>
          <Divider />
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={() => handleEditTOC()}
            dataTestId="edit-toc-dropdown-item"
          >
            <FormattedMessage id="EDIT_TABLE_OF_CONTENTS" />
          </ContextMenuItem>
          <ContextMenuItem
            disabled={isReadOnlyMode || isPageLayout}
            onClick={handleUpdateTOC}
            overflowTooltip={{
              content: intl.formatMessage(
                { id: 'UPDATE_TABLE_OF' },
                {
                  contentType: selection.TOL
                    ? selection.TOL.label
                      ? selection.TOL.label
                      : 'captions'
                    : 'contents',
                },
              ),
              testId: 'update-toc',
            }}
            dataTestId="update-toc-dropdown-item"
          >
            <FormattedMessage
              id="UPDATE_TABLE_OF"
              values={{
                contentType: selection.TOL
                  ? selection.TOL.label
                    ? selection.TOL.label
                    : 'captions'
                  : 'contents',
              }}
            />
          </ContextMenuItem>
          <Divider />
        </>
      )}
      <ContextMenuItem
        onClick={handleClickSectionProperties}
        disabled={isiEnvision}
        dataTestId="section-properties-dropdown-item"
      >
        <FormattedMessage id="EDITOR_SECTION_PROPERTIES" />
      </ContextMenuItem>
      <ContextMenuItem
        disabled={!canApprove}
        onClick={handleApproveBlocksForCurrentSelection}
        dataTestId="approve-content-dropdown-item"
      >
        <FormattedMessage id="editor.menu.review.approveContent" />
      </ContextMenuItem>
      {!selection.LINK && !selection.TABLE && !selection.TOC && !selection.TOL && (
        <ContextMenuItem
          disabled={isReadOnlyMode || isPageLayout}
          onClick={handleInsertHyperlink}
          dataTestId="hyperlink-dropdown-item"
        >
          <FormattedMessage id="HYPERLINK" />
        </ContextMenuItem>
      )}
      <ContextMenuItem
        disabled={!canChangePermissions}
        onClick={handleViewPermissiosForCurrentSelection}
        dataTestId="content-permissions-dropdown-item"
      >
        <FormattedMessage id="CONTENT_PERMISSIONS" />
      </ContextMenuItem>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        accept=".jpeg,.jpg,.bmp,.tiff,.gif,.png,.eps"
        multiple
        type="file"
        onChange={(e) => {
          handleUploadFigure(e);
        }}
      />
    </ContextMenu>
  );
};

export default EditorContextMenu;
