import { selectCollaborators } from 'App/redux/appSlice';
import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';
import { usePresentationData } from 'Presentation/PresentationData';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { setSelectedCard, setCreating, setSelectedShape } from 'Presentation/PresentationSlice';
import { InteractionController, RichTextEditor } from '_common/components';
import { useDispatch, useSelector } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import { UserPresentation } from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { Button } from 'dodoc-design-system';
import { Ref, forwardRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { notify } from '_common/components/ToastSystem';
import { richTextToString, stringToRichText } from 'utils';
import { completeAction } from 'App/redux/onboardingSlice';

type EditableCommentCardProps = {
  id?: string;
  sidebar?: boolean;
  testId: string;
  comment?: Presentation.Data.Comment;
  setEditMode: (isEdit: boolean) => void;
} & (
  | { isTemporary?: true; comment?: undefined }
  | { isTemporary?: false; comment: Presentation.Data.Comment }
);

const EditableCommentCard = forwardRef(
  (
    { id, sidebar, testId, comment, setEditMode, isTemporary }: EditableCommentCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const object = useSuiteObject();
    const intl = useIntl();
    const dispatch = useDispatch();
    const manager = usePresentationManager();
    const { structure } = usePresentationData();
    const currentSlide = useSelector((state) => state.presentation.general.currentSlide) - 1;
    const user = useSelector((state) => state.auth.userId);
    const creating = useSelector((state) => state.presentation.general.creating);
    const [content, setContent] = useState(richTextToString(comment?.content.content));

    const collaborators = useSelector((state) => selectCollaborators(state, object.id));

    const handleCancelEdition = () => {
      setEditMode(false);
      dispatch(setCreating(null));
    };

    const handleEditContent = async () => {
      if (isTemporary) {
        const data = await manager.addComment({
          content: { dir: 'ltr', content: stringToRichText(content) },
          anchor: creating?.anchor ?? [{ id: structure.sldIdLst[currentSlide] }],
        });
        //@ts-expect-error
        const comment = Object.values(data?.data?.sld[structure.sldIdLst[currentSlide]]).pop();
        dispatch(setSelectedCard(comment?.id ?? null));
        dispatch(setCreating(null));
        dispatch(setSelectedShape(null));
        dispatch(completeAction('presentation_comments_createComment'));
      } else {
        if (comment) {
          await manager.editComment({
            ...comment,
            content: { dir: 'ltr', content: stringToRichText(content) },
          });
          dispatch(setSelectedCard(comment?.id));
          notify({
            type: 'success',
            title: intl.formatMessage({ id: 'COMMENT_EDITED' }),
            message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_EDITED' }),
          });
        }
      }
      setEditMode(false);
    };

    return (
      <Card
        id={isTemporary ? `comment-card` : id}
        sidebar={sidebar}
        selected
        testId={testId}
        ref={ref}
      >
        <Card.Header>
          <UserPresentation
            userId={isTemporary ? user : comment?.authorId}
            fixedName={isTemporary ? undefined : comment?.authorName}
            creationDate={comment?.time}
          />
        </Card.Header>
        <Card.Body>
          <InteractionController
            environment="presentation"
            rules={[
              {
                interaction: 'presentation_comment_save',
                actions: ['presentation_comments_createComment'],
              },
            ]}
          >
            <RichTextEditor
              initialValue={content}
              onChange={setContent}
              placeholder={intl.formatMessage({ id: 'storage.modals.approve.placeholder' })}
              mentionableUsers={collaborators}
              overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
              testId={`${testId}-rich-text-editor`}
            />
          </InteractionController>
        </Card.Body>
        <Card.Footer>
          <Card.Footer.Right>
            <Button
              variant="link"
              size="small"
              margin="0 1rem 0 0"
              onClick={handleCancelEdition}
              testId="comment-cancel-button"
              id={`${testId}-cancel-button`}
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <InteractionController
              environment="presentation"
              rules={[
                {
                  interaction: 'presentation_comment_save',
                  actions: ['presentation_comments_createComment'],
                },
              ]}
            >
              <Button
                variant="primary"
                size="small"
                onClick={handleEditContent}
                testId={`comment-${isTemporary ? 'create' : 'save'}-button`}
                disabled={isTemporary && !content}
                id={`${testId}-cta-button`}
              >
                <FormattedMessage id={isTemporary ? 'global.create' : 'global.save'} />
              </Button>
            </InteractionController>
          </Card.Footer.Right>
        </Card.Footer>
      </Card>
    );
  },
);

export default EditableCommentCard;
