import { ReactNode } from 'react';
import { navigateToError403 } from 'router/history';
import { useGetTenantSettingsQuery } from '_common/services/api/authority';

type ProductGateProps = {
  children: ReactNode;
  product: Extract<
    keyof AuthoritySchemas['UserProfileFields'],
    'product.pdf_reviewer' | 'product.presentation_reviewer'
  >;
};

const ProductGate = ({ children, product }: ProductGateProps) => {
  const { data, isSuccess } = useGetTenantSettingsQuery();

  if (data?.[product]) {
    return <>{children}</>;
  }

  if (isSuccess) {
    navigateToError403();
  }

  return null;
};

export default ProductGate;
