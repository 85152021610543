import { v4 as uuidV4 } from 'uuid';
import { merge, defaultsDeep } from 'lodash';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { parseMeasurement } from 'utils';
import { ListDefinitionType, ListStyleData } from '../../models';
import NumberingUtils from '_common/utils/NumberingUtils';
import { INDENT_TYPE } from 'Editor/services/consts';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

export type ListLevelMap = {
  [index: string]: string;
};

const EMPTY_STYLE = { ldef: {} };

export default class ListStyle {
  private templateStyle: ListStyleData = EMPTY_STYLE;
  private documentStyle: ListStyleData = EMPTY_STYLE;
  private mergedStyle: ListStyleData = EMPTY_STYLE;
  private mapper: ListLevelMap = {};

  constructor(templateStyle: ListStyleData, documentStyle: ListStyleData) {
    if (templateStyle) {
      this.templateStyle = templateStyle;
    }
    if (documentStyle) {
      this.documentStyle = documentStyle;
    }
    this.joinStyles();
  }

  get data() {
    return JSON.parse(JSON.stringify(this.mergedStyle));
  }

  get ldef() {
    return this.mergedStyle.ldef;
  }

  static generateStyleId() {
    return uuidV4();
  }

  static _getMapper(ldef: ListDefinitionType): ListLevelMap {
    const _mapper: ListLevelMap = {};
    const keys = Object.keys(ldef);
    for (let index = 0; index < keys.length; index++) {
      if (ldef[keys[index]].et) {
        let elementType: string = ldef[keys[index]].et as string;
        _mapper[elementType] = keys[index];
      }
    }
    return _mapper;
  }

  static parseBack(frontendOutline: any) {
    const backendOutline: ListStyleData = {
      ldef: {},
    };
    for (let index = 0; index < frontendOutline.length; index++) {
      const level = frontendOutline[index];

      backendOutline.ldef[`${level.level}`] = {
        // : definition for level 1
        st: level.start_from, // : numbering start
        et: level.paragraph_style ? level.paragraph_style.value : undefined, // : element type to apply,
        nf: level.numbering_type.value || level.numbering_type, // : numbering format
        tA: level.char_after, // : some kind of string template ??
        tB: level.char_before, // : some kind of string template ??
        ff: level.font_family,
        ind: {},
        sp_ind: {},
      };

      if (level.et) {
        backendOutline.ldef[`${level.level}`].paragraph_style = {
          value: level.et,
        };
      }

      if (level.indentation_left != null) {
        let indLeft = level.indentation_left;

        if (typeof level.indentation_left === 'string') {
          indLeft = EditorDOMUtils.convertUnitTo(level.indentation_left);
        }

        if (indLeft > 0) {
          backendOutline.ldef[`${level.level}`].ind.l = Math.min(
            StylesUtils.INDENTATION_LIMITS.LEFT.MANIPULATION_MAX,
            indLeft,
          );
        } else {
          backendOutline.ldef[`${level.level}`].ind.l = Math.max(
            StylesUtils.INDENTATION_LIMITS.LEFT.MANIPULATION_MIN,
            indLeft,
          );
        }
      }

      if (level.indentation_right != null) {
        let indRight = level.indentation_right;

        if (typeof level.indentation_right === 'string') {
          indRight = EditorDOMUtils.convertUnitTo(level.indentation_right);
        }

        if (indRight > 0) {
          backendOutline.ldef[`${level.level}`].ind.r = Math.min(
            StylesUtils.INDENTATION_LIMITS.RIGHT.MANIPULATION_MAX,
            indRight,
          );
        } else {
          backendOutline.ldef[`${level.level}`].ind.r = Math.max(
            StylesUtils.INDENTATION_LIMITS.RIGHT.MANIPULATION_MIN,
            indRight,
          );
        }
      }

      const specialIndent = level.special_indent?.value || level.special_indent;
      if (specialIndent != null && level.special_indent_value != null) {
        backendOutline.ldef[`${level.level}`].sp_ind.t = specialIndent;

        let spValue = level.special_indent_value;

        if (typeof level.special_indent_value === 'string') {
          spValue = EditorDOMUtils.convertUnitTo(level.special_indent_value);
        }

        if (spValue > 0) {
          spValue = Math.min(
            StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.MANIPULATION_MAX,
            spValue,
          );
        } else {
          spValue = Math.max(
            StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.MANIPULATION_MIN,
            spValue,
          );
        }
        backendOutline.ldef[`${level.level}`].sp_ind.v = spValue;

        if (specialIndent === INDENT_TYPE.HANGING) {
          //no bounds are checked for ind.l
          backendOutline.ldef[`${level.level}`].ind.l =
            (backendOutline.ldef[`${level.level}`].ind.l || 0) + spValue;
        }
      }

      if (level.type) {
        // : some kind of string template ??
        backendOutline.ldef[`${level.level}`].t = level.type;
      }

      let numbType = level.numbering_type?.value || level.numbering_type;
      if (numbType !== 'b') {
        if (!level.incl_prev_lvls) {
          backendOutline.ldef[`${level.level}`].rl = [`${level.level}`];
        } else {
          const rl = [];
          for (let j = 0; j <= index; j++) {
            rl.push(`${frontendOutline[j].level}`);
          }
          backendOutline.ldef[`${level.level}`].rl = rl;
        }

        // remove font family
        if (backendOutline.ldef[`${level.level}`].ff === 'Symbol') {
          delete backendOutline.ldef[`${level.level}`].ff;
        }
      }
    }
    return backendOutline;
  }

  static parseFront(backendOutline: ListStyleData) {
    const frontOutline: Editor.Data.Structure.StyleFrontOutline = [];
    if (!backendOutline.ldef) {
      return [];
    }
    const mlMapper = ListStyle._getMapper(backendOutline.ldef);
    const backendLevels = Object.keys(backendOutline.ldef);
    let listType;
    if (Object.keys(mlMapper).length) {
      listType = 'ml';
    } else if (backendOutline.ldef['0']?.nf === NumberingUtils.TYPE.BULLET) {
      listType = 'u';
    } else {
      listType = 'o';
    }

    for (let index = 0; index < backendLevels.length; index++) {
      // create a clone
      const level = JSON.parse(JSON.stringify(backendOutline.ldef[backendLevels[index]]));

      if (level.sp_ind && level.sp_ind.t === INDENT_TYPE.HANGING) {
        if (!level.ind) {
          level.ind = {};
        }
        level.ind.l = (level.ind.l || 0) - level.sp_ind.v; //doesn't it need to be string, because of parseMeasurement?
      }

      frontOutline.push({
        // : definition for level 1
        list_type: listType,
        level: parseInt(backendLevels[index], 10),
        numbering_type: level.nf,
        paragraph_style: level.et
          ? {
              value: level.et,
            }
          : null,
        incl_prev_lvls: !level.rl || level.rl.length > 1,
        type: level.t,
        start_from: level.st, // : numbering start
        char_before: level.tB || '',
        char_after: level.tA || '',
        indentation_left:
          level.ind && level.ind.l
            ? `${parseMeasurement(level.ind.l, 'cm', {
                defaultUnit: 'pt',
              })} cm`
            : '0 cm',
        indentation_right:
          level.ind && level.ind.r
            ? `${parseMeasurement(level.ind.r, 'cm', { defaultUnit: 'pt' })} cm`
            : '0 cm',
        special_indent: level.sp_ind ? level.sp_ind.t : 'none',
        special_indent_value: level.sp_ind
          ? `${parseMeasurement(level.sp_ind.v, 'cm', { defaultUnit: 'pt' })} cm`
          : '0 cm',
        font_family: level.ff || '',
      });
    }
    return frontOutline;
  }

  private joinStyles() {
    this.mergedStyle = merge(
      defaultsDeep({}, this.templateStyle, this.documentStyle),
      this.documentStyle,
    );
    this.mapper = ListStyle._getMapper(this.mergedStyle.ldef);
  }

  loadDocumentStyle(style: ListStyleData = EMPTY_STYLE) {
    this.documentStyle = style;
    this.joinStyles();
  }

  loadTemplateStyle(style: ListStyleData = EMPTY_STYLE) {
    this.templateStyle = style;
    this.joinStyles();
  }

  level(level: string | number) {
    return this.ldef[level] || {};
  }

  isBulletList() {
    return this.ldef['0'].nf === NumberingUtils.TYPE.BULLET;
  }

  isMultiLevelList() {
    return Object.keys(this.mapper).length;
  }

  getMultiLevelTypes() {
    return Object.keys(this.mapper);
  }

  getLevelForType(type: string) {
    return this.mapper[type] || '0';
  }

  getListDefenitionForStyleId(styleId: string) {
    return this.ldef[this.mapper[styleId]];
  }

  getPreviousLevelForStyleId(styleId: string) {
    const types = Object.keys(this.mapper);
    const indexOfType = types.indexOf(styleId);

    const previousIndex = indexOfType - 1;

    if (previousIndex >= 0) {
      return this.mapper[types[previousIndex]];
    }
    return this.mapper[types[0]];
  }

  getNextLevelForStyleId(styleId: string) {
    const types = Object.keys(this.mapper);
    const indexOfType = types.indexOf(styleId);

    const nextIndex = indexOfType + 1;

    if (nextIndex < types.length) {
      return this.mapper[types[nextIndex]];
    }
    return this.mapper[types[types.length - 1]];
  }

  parseFront() {
    return ListStyle.parseFront(this.mergedStyle);
  }

  parseBack() {
    return ListStyle.parseBack(this.mergedStyle);
  }
}
