import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import cx from 'classnames';
import styles from './CreationLayer.module.scss';
import {
  calculateRelativeCoordinates,
  getPathsForSVGCreation,
} from '../AnnotationsLayer/Annotation/Utils';
import { PageViewport } from 'pdfjs-dist';
import { setCreationSetting } from 'PDF/redux/PDFAnnotationsSlice';
import { usePDFContext } from 'PDF/PDFContext';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { COLORS_HEX_MAP } from 'PDF/services';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';

type CreationLayerProps = {
  viewport: PageViewport;
  pageNum: number;
};

const CreationLayer = ({ viewport, pageNum }: CreationLayerProps) => {
  const dispatch = useDispatch();
  const manager = usePDFContext();
  const ref = useRef<HTMLDivElement>(null);
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const strokeWidth = useSelector((state) => state.pdf.annotations.creation.strokeWidth);
  const stroke = useSelector((state) => state.pdf.annotations.creation.stroke);
  const fill = useSelector((state) => state.pdf.annotations.creation.fill);
  const start = useSelector((state) => state.pdf.annotations.creation.start);
  const activeInThisPage =
    useSelector((state) => state.pdf.annotations.creation.pageNum) === pageNum;
  const [inkLists, setInkLists] = useState<PDF.Annotation.Point[][]>([[]]);
  const [end, setEnd] = useState<PDF.Annotation.Point | null>(null);
  // const [lastInkAnnotationId, setLastInkAnnotationId] = useState<string | null>(null);
  const { data: userProfile } = useGetCurrentUserQuery();

  useEffect(() => {
    if (start && activeInThisPage) {
      ref.current?.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        ref.current?.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [start, activeInThisPage, end]);

  useEffect(() => {
    if (cursorMode === 'Ink') {
      setInkLists([[]]);
    }
  }, [cursorMode]);

  const handleMouseMove = (e: MouseEvent) => {
    if (ref.current) {
      const mouseCoords = calculateRelativeCoordinates(
        ref.current,
        e.clientX,
        e.clientY,
        viewport.scale,
      );
      if (mouseCoords) {
        if (cursorMode === 'Ink') {
          setInkLists((lists) => {
            const coords = { x: mouseCoords.left, y: mouseCoords.top };

            if (lists.length > 0) {
              const newInkLists = [...lists];
              const lastInkList = [...newInkLists[newInkLists.length - 1]];
              lastInkList.push(coords);
              newInkLists.splice(newInkLists.length - 1, 1);
              newInkLists.push(lastInkList);
              return newInkLists;
            }
            return [[coords]];
          });
        }
        setEnd({ x: mouseCoords.left, y: mouseCoords.top });
      }
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (start && end && userProfile) {
      switch (cursorMode) {
        case 'Arrow':
        case 'Line':
        case 'Circle':
        case 'Square':
        case 'Ink':
          // For extending ink annotation with extra ink lists
          // if (inkLists.length > 1 && lastInkAnnotationId) {
          //   manager.deleteAnnotation(pageNum, lastInkAnnotationId);
          // } else {
          //   setInkLists([[]]);
          //   setLastInkAnnotationId(null);
          // }
          /* const id = */
          manager.createShapeAnnotation(
            cursorMode,
            pageNum,
            start,
            end,
            inkLists.map((list) => list.filter((_, index) => index % 5 === 0)),
          );
          // if (cursorMode === 'Ink' && id) {
          //   setLastInkAnnotationId(id);
          //   setInkLists((inkLists) => [...inkLists, []]);
          // }
          break;
      }
    }
    dispatch(setCursorMode('normal'));
    setInkLists([[]]);
    dispatch(setCreationSetting({ start: null }));
    setEnd(null);
  };

  const ds = useMemo(() => {
    if (start && end) {
      // @ts-expect-error fix this type
      return getPathsForSVGCreation(cursorMode, start, end, viewport, inkLists);
    }
    return [];
  }, [start, end, inkLists]);

  if (start) {
    return (
      <div ref={ref} className={cx(styles.creationlayer, { [styles.creating]: !!start })}>
        <svg version="1.1" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          {ds.map((d) => (
            <path
              key={d}
              d={d}
              strokeWidth={strokeWidth * viewport.scale}
              stroke={stroke !== 'none' ? COLORS_HEX_MAP[stroke] : ''}
              fill={fill !== 'none' && cursorMode !== 'Ink' ? COLORS_HEX_MAP[fill] : 'transparent'}
            />
          ))}
        </svg>
      </div>
    );
  }
  return null;
};

export default memo(CreationLayer);
