import { RadioButton } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

import styles from './Answer.module.scss';

type AnswerProps<AnswerId extends string = string> = {
  id: AnswerId;
  label: TranslationMessage;
  checked: boolean;
  selectAnswer: (id: AnswerId) => void;
  testId: string;
};
const Answer = <AnswerId extends string = string>({
  label,
  testId,
  checked,
  id,
  selectAnswer,
}: AnswerProps<AnswerId>) => {
  const onChange = () => {
    selectAnswer(id);
  };

  return (
    <div className={styles.root}>
      <RadioButton
        size="small"
        checked={checked}
        onChange={onChange}
        testId={`answer-${testId}-radiobutton`}
      >
        <FormattedMessage id={label.id} values={label.values} />
      </RadioButton>
    </div>
  );
};

export default Answer;
