import { ChangeEventHandler, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DatePicker, Input, Select } from 'dodoc-design-system';

import { dayjs } from 'utils';

import { GENERIC_BEHAVIOUR, HandlerProps } from '../AdvancedFilterOptions';
import { useAdvancedFilterContext } from '../AdvancedFilterContext';

import styles from './Handlers.module.scss';
import { useState } from 'react';
import { useDebounce, useEffectOnUpdate } from '_common/hooks';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

type GenericHandlerProps = {
  testId?: string;
};

const GenericHandler = ({ sequence, testId }: HandlerProps & GenericHandlerProps) => {
  const intl = useIntl();

  const { advancedFilter, setOperator, setValue, setAuxValue, setQuery } =
    useAdvancedFilterContext();

  const { option, metadataOption, operator, value, auxValue } = advancedFilter;
  const mergedOption = metadataOption ? `${option}.${metadataOption}` : option;

  const [localValue, setLocalValue] = useState<string>();
  const debouncedValue = useDebounce(localValue, 300);

  useEffectOnUpdate(() => {
    if (value !== debouncedValue) {
      setValue(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLocalValue(value);
    if (!sequence.type || !operator || !value) {
      setQuery(undefined);
      return;
    }

    //Date's Exactly operator will be transformed into a Between
    if (sequence.type === 'date' && operator === 'eq') {
      setQuery(
        GENERIC_BEHAVIOUR[sequence.type]['in']({
          field: mergedOption,
          value: value,
          auxValue: dayjs(value).add(1, 'day').toISOString(),
        }),
      );
    } else if (operator !== 'in') {
      setQuery(
        GENERIC_BEHAVIOUR[sequence.type][operator]({
          field: mergedOption,
          value: value,
        }),
      );
    } else if (auxValue) {
      setQuery(
        GENERIC_BEHAVIOUR[sequence.type][operator]({
          field: mergedOption,
          value: value,
          auxValue: auxValue,
        }),
      );
    }
  }, [value, auxValue]);

  // This should never happen since 'sequence' is only non-undefined when option is selected
  // and 'sequence' is a required prop for this component, but double checking
  if (!sequence || !advancedFilter.option) {
    return null;
  }

  const handleStringChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setLocalValue(event.target.value);
  };

  const handleDateChange = (date: Date) => {
    setValue(date?.toISOString());
  };

  const handleAuxDateChange = (date: Date) => {
    setAuxValue(date?.toISOString());
  };

  const handleOperatorChange = (newSelectValue: SelectOption) => {
    setOperator(newSelectValue.value);
  };

  const renderValueInput = () => {
    // Only render input if an operator has been chosen
    if (!operator) {
      return null;
    }

    switch (sequence.type) {
      case 'string':
        return (
          <Input
            size="medium"
            placeholder={intl.formatMessage({ id: 'TYPE_HERE' })}
            onChange={handleStringChange}
            value={localValue}
            testId={`${testId}-value`}
          />
        );
      case 'date':
        return operator === 'in' ? (
          <div>
            <DatePicker
              dateRange
              onStartChange={handleDateChange}
              onEndChange={handleAuxDateChange}
              direction="horizontal"
              placeholderStart={intl.formatMessage({ id: 'START_DATE' })}
              placeholderEnd={intl.formatMessage({ id: 'END_DATE' })}
              startDate={value && dayjs(value).isValid() ? dayjs(value).toDate() : undefined}
              endDate={auxValue && dayjs(auxValue).isValid() ? dayjs(auxValue).toDate() : undefined}
              testId={`${testId}-datepicker`}
            />
          </div>
        ) : (
          <DatePicker
            onChange={handleDateChange}
            placeholder={intl.formatMessage({ id: 'DATE' })}
            selectedDate={value && dayjs(value).isValid() ? dayjs(value).toDate() : undefined}
            testId={`${testId}-datepicker`}
          />
        );
    }
  };

  return (
    <div className={styles.handlerRoot} data-testid={testId}>
      <Select
        clearable={false}
        width="20rem"
        size="medium"
        options={sequence.options}
        onChange={handleOperatorChange}
        value={sequence.options.find((seqOperator) => seqOperator.value === operator) || null} //Force select UI cleanup by sending null
        testId={`${testId}-operator`}
      />

      {renderValueInput()}
    </div>
  );
};

export default GenericHandler;
