import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PDFFindSliceState = {
  selected: string | null;
};

const SLICE_NAME = 'PDFFind';
const initialState: PDFFindSliceState = {
  selected: null,
};

// #region Slice
const PDFFindSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSelectedInstance: (state, action: PayloadAction<PDFFindSliceState['selected']>) => {
      state.selected = action.payload;
    },
  },
});

export default PDFFindSlice.reducer;
// #endregion

// #region Actions
export const { setSelectedInstance } = PDFFindSlice.actions;
// #endregion
