import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '..';

export class CitationViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.CitationElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // element_reference
    element_reference: this.GENERIC_ATTRIBUTE_MAPPER.element_reference,
    // display
    d: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.style.display) {
          builder.addProperty('d', !(node.style.display === 'none'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.d === false) {
          node.style.display = 'none';
        } else {
          node.style.removeProperty('display');
        }
      },
      remove: (node: HTMLElement) => {
        node.style.removeProperty('display');
      },
    },

    // clipboard only
    tempCitationInfo: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.tempCitationInfo) {
          builder.addClipboardProperty('tempCitationInfo', node.dataset.tempCitationInfo);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {},
      remove: (node: HTMLElement) => {
        delete node.dataset.tempCitationInfo;
      },
    },
  };

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  build(json: Editor.Data.Node.Data) {
    const node = DOMElementFactory.buildElement(ELEMENTS.CitationElement.TAG);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}
