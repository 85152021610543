import { ChangeEventHandler, UIEventHandler, useMemo, useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Input, EmptyState, ProgressBar } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import MetadataFields from '_common/components/MetadataFields/MetadataFields';
import useDocumentMetadata from '_common/hooks/useDocumentMetadata';
import { selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import MetadataOption from './MetadataOption/MetadataOption';
import styles from './Metadata.module.scss';
import { useSuiteObject } from '_common/suite';

type MetadataProps = {
  editMetadata?: boolean;
  setEditMetadata: (newValue: boolean) => void;
  setValidations: (newValidation: string) => void;
  validations?: string;
};

const Metadata = ({
  editMetadata,
  setEditMetadata,
  setValidations,
  validations,
}: MetadataProps) => {
  const intl = useIntl();
  const element = useSuiteObject();
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const { automatic, manual, isLoading } = useDocumentMetadata(element.id);
  const [activeOption, setActiveOption] = useState('MANUAL_METADATA');
  const [searchValue, setSearchValue] = useState('');
  const optionsRefs = {
    MANUAL_METADATA: useRef<HTMLDivElement>(null),
    AUTOMATIC_METADATA: useRef<HTMLDivElement>(null),
  };

  const { filteredManual, filteredAutomatic } = useMemo(() => {
    return {
      filteredAutomatic:
        searchValue === ''
          ? automatic
          : automatic.filter((m) => m.name.toLowerCase().includes(searchValue.toLowerCase())),
      filteredManual:
        searchValue === ''
          ? manual
          : manual.filter((m) => m.name.toLowerCase().includes(searchValue.toLowerCase())),
    };
  }, [searchValue, automatic, manual]);

  const handleOnOptionClick = (option?: keyof typeof optionsRefs) => {
    if (option && optionsRefs[option].current) {
      optionsRefs[option].current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const handleOnScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const container = e.currentTarget;
    if (container.scrollTop === 0) {
      setActiveOption('MANUAL_METADATA');
    } else {
      setActiveOption('AUTOMATIC_METADATA');
    }
  };

  const handleEdit = () => {
    setEditMetadata(true);
  };

  const getRef = (option: 'MANUAL_METADATA' | 'AUTOMATIC_METADATA') => {
    if (option === 'MANUAL_METADATA') {
      return optionsRefs['MANUAL_METADATA'];
    }
    if (option === 'AUTOMATIC_METADATA') {
      return optionsRefs['AUTOMATIC_METADATA'];
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <ProgressBar testId="loading-metadata-progressBar" />
        <div className={styles.message}>
          <FormattedMessage id="LOADING_METADATA" />
        </div>
      </div>
    );
  }

  if (editMetadata) {
    return (
      <>
        <div className={styles.editContainer}>
          <FormattedMessage id="EDIT_MANUAL_METADATA" />
        </div>
        {manual.map(
          (field) =>
            element.type === 'document' && (
              <MetadataFields
                key={field.id}
                setValidations={setValidations}
                validations={validations}
                element={element}
                metadata={field}
              />
            ),
        )}
      </>
    );
  }

  return (
    <div className={styles.menusContainer}>
      <div className={styles.menus}>
        <div className={styles.input}>
          <Input
            prefix="NavSearchGrey"
            placeholder="Search"
            value={searchValue}
            onChange={handleOnChange}
            size="large"
            testId="metadata-search"
          />
        </div>
        <div
          className={`${styles.option} ${activeOption === 'MANUAL_METADATA' && styles.active}`}
          onClick={() => {
            handleOnOptionClick('MANUAL_METADATA');
          }}
        >
          <FormattedMessage id="MANUAL_METADATA" />
        </div>
        <div
          className={`${styles.option} ${activeOption === 'AUTOMATIC_METADATA' && styles.active}`}
          onClick={() => {
            handleOnOptionClick('AUTOMATIC_METADATA');
          }}
        >
          <FormattedMessage id={'AUTOMATIC_METADATA'} />
        </div>
      </div>
      {filteredAutomatic.length > 0 || filteredManual.length > 0 ? (
        <div className={styles.list} onScroll={handleOnScroll}>
          {filteredManual.length > 0 && (
            <div>
              <div className={styles.metadataOption} ref={getRef('MANUAL_METADATA')}>
                <FormattedMessage id="MANUAL_METADATA" />
                <Button
                  size="small"
                  onClick={handleEdit}
                  disabled={isReadOnlyMode}
                  testId="edit-metadata-button"
                >
                  <FormattedMessage id="global.edit" />
                </Button>
              </div>
              {filteredManual.map((option) => {
                return <MetadataOption key={option.name} metadata={option} />;
              })}
            </div>
          )}
          {filteredAutomatic.length > 0 && (
            <div style={{ paddingTop: '3rem' }}>
              <div className={styles.metadataOption} ref={getRef('AUTOMATIC_METADATA')}>
                <FormattedMessage id="AUTOMATIC_METADATA" />
                <Button
                  size="small"
                  onClick={handleEdit}
                  disabled={isReadOnlyMode}
                  testId="edit-metadata-button"
                >
                  <FormattedMessage id="global.edit" />
                </Button>
              </div>
              {filteredAutomatic.map((option) => {
                return <MetadataOption key={option.name} metadata={option} />;
              })}
            </div>
          )}
        </div>
      ) : (
        <EmptyState
          size="large"
          icon="NoSearchResults"
          title={intl.formatMessage({ id: 'NO_METADATA_FOUND' })}
          testId="no-metadata-found"
        >
          <FormattedMessage id="NO_METADATA_FOUND_MESSAGE" values={{ string: searchValue }} />
        </EmptyState>
      )}
    </div>
  );
};

export default Metadata;
