import { useIntl } from 'react-intl';
import { Tooltip, Toggle } from 'dodoc-design-system';

import { navigateToUserSettings } from 'router/history';
import { useDispatch, useSelector } from '_common/hooks';
import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';

type SettingsProps = {
  testId: string;
  closePopover: () => void;
};

const Settings = ({ testId, closePopover }: SettingsProps) => {
  const isiEnvision = useSelector(selectIsIEnvision);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleGoToSettings = () => {
    dispatch(
      setSelectedTab({ menuId: 'userSettingsPage', selectedTab: 'user-preferences-notifications' }),
    );
    navigateToUserSettings();
    closePopover();
  };

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'GO_TO_NOTIFICATION_SETTINGS' })}
      placement="bottom"
      disabled={isiEnvision}
      testId={`${testId}-tooltip`}
    >
      <Toggle
        size="medium"
        variant="link"
        icon="Settings"
        onClick={handleGoToSettings}
        testId={testId}
        disabled={isiEnvision}
      />
    </Tooltip>
  );
};

export default Settings;
