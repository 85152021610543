import { ErrorCannotRemoveContent } from '../../Errors';
import { BackspaceCommand } from '../KeyDownCommands';
import { Logger } from '_common/services';

export class CutCommand extends BackspaceCommand {
  event: ClipboardEvent;

  constructor(context: Editor.Edition.Context, event: ClipboardEvent) {
    super(context);

    this.event = event;
  }

  async handleExec() {
    if (this.debug) {
      Logger.trace('CutCommand exec', this);
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.clipboard || !this.actionContext) {
      throw new Error('Invalid context');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      /**
       * This is the error CopyPasteHandler threw
       */
      throw new ErrorCannotRemoveContent();
    }

    this.context.clipboard.handleCut(this.event);

    await super.handleExec();
  }
}
