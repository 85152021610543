import { FormattedMessage, useIntl } from 'react-intl';
import { Switch } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import {
  selectFilteredTrackedActions,
  setHideTrackedActions,
} from 'Editor/redux/TrackedActionsSlice';

import styles from './HideTrackedActions.module.scss';

const HideTrackedActions = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorTrackPanel),
  );
  const trackedActions = useSelector(selectFilteredTrackedActions);
  const hideAllTrackedActions = useSelector((state) => state.editor.trackedActions.hideAll);

  const handleToggleHidden = () => {
    dispatch(setHideTrackedActions(!hideAllTrackedActions));
    EditorManager.getInstance().filterSuggestions(
      !hideAllTrackedActions ? [] : trackedActions.order,
    );
  };

  const getTrackedActionsNumber = () => {
    if (hasActiveFilters && !hideAllTrackedActions) {
      return (
        <FormattedMessage
          id="CURRENT_OUT_OF_TOTAL_CHANGES"
          values={{ current: trackedActions.order.length, total: trackedActions.total }}
        />
      );
    }

    if (trackedActions.total === 0 || hideAllTrackedActions) {
      return <FormattedMessage id="NO_CHANGES_IN_THE_DOCUMENT" />;
    }

    return <FormattedMessage id="editor.changes.total" values={{ total: trackedActions.total }} />;
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.quantityIndicator}
        data-testid={
          trackedActions.total === 0 || hideAllTrackedActions
            ? 'no-changes-in-the-document'
            : 'changes-in-the-document'
        }
      >
        {getTrackedActionsNumber()}
      </div>
      <Switch
        active={hideAllTrackedActions}
        onChange={handleToggleHidden}
        testId={'hide-all-tracked-actions-switch'}
        labelPlacement="left"
        size='medium'
      >
        {intl.formatMessage({ id: 'HIDE_ALL_CHANGES' })}
      </Switch>
    </div>
  );
};

export default HideTrackedActions;
