import { PresenceChannel } from '_common/services/Realtime';

export class Selection extends PresenceChannel<Editor.Data.Selection.Data> {
  getLocalSelectionData(): Editor.Data.Selection.Data | null {
    const localData = this.getLocalData();
    const keys = Object.keys(localData);
    if (keys.length) {
      return localData[keys[0]];
    }
    return null;
  }
}
