import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'dodoc-design-system';

import { useDebounce, useDispatch } from '_common/hooks';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { setAnnotationsSearchBy } from 'PDF/redux/PDFAnnotationsSlice';

import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

const Search = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { canAccess } = usePDFPermissions();

  const [value, setValue] = useState<string>();

  const debouncedInputValue = useDebounce(value, 200);

  const handleSearchByChange: InputProps['onChange'] = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    dispatch(setAnnotationsSearchBy(debouncedInputValue));
  }, [debouncedInputValue]);

  return (
    <Input
      size="medium"
      placeholder={intl.formatMessage({ id: 'SEARCH_COMMENTS' })}
      value={value}
      prefix="NavSearchGrey"
      onChange={handleSearchByChange}
      testId="sidebar-comments-search"
      disabled={!canAccess}
      margin="1rem"
    />
  );
};
export default Search;
