/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PDFDocument, PDFPage } from '../../models';
import { BaseController } from '../BaseController';
import { PDFFindVisitor } from './PDFFindVisitor';

class ProgressWard {
  static progress<T = void>(proms: Promise<T>[], notify: (value: number) => void) {
    let d = 0;
    notify(0);
    const length = proms.length;
    for (const p of proms) {
      // eslint-disable-next-line no-loop-func
      p.then((value: T) => {
        d++;
        notify(Math.ceil((d * 100) / length));
      });
    }
    return Promise.all(proms);
  }
}

export class FindController extends BaseController {
  pdfDocument?: PDFDocument;
  running: boolean;

  constructor(Data: PDF.Data.State) {
    super(Data);
    this.running = false;
  }

  async start(documentId: string) {
    this.pdfDocument = this.Data.models?.get('PDFDOCUMENT', documentId);
  }

  async find(params: PDF.Find.FindParams) {
    let visitor = new PDFFindVisitor(params);
    if (this.pdfDocument) {
      let jobs: any[] = [];
      const numPages = this.pdfDocument.numPages || 0;
      for (let index = 1; index <= numPages; index++) {
        jobs.push(
          this.Data?.models?.getPage(index).then((page: PDFPage) => {
            return page.accept(visitor);
          }),
        );
      }
      await ProgressWard.progress(jobs, (value) => {
        console.log(`Completed ${value}%`);
      });
    }
    this.running = true;
    this.Data.emit?.('LOAD_FIND_HITS', visitor.getRunInfo());
  }

  async reset() {
    if (this.pdfDocument) {
      let jobs: any[] = [];
      const numPages = this.pdfDocument.numPages || 0;
      for (let index = 1; index <= numPages; index++) {
        jobs.push(
          this.Data?.models?.getPage(index).then((page: PDFPage) => {
            return page.setFindHits(null);
          }),
        );
      }
      await ProgressWard.progress(jobs, (value) => {
        console.log(`Completed ${value}%`);
      });
    }
    this.running = false;
    this.Data.emit?.('LOAD_FIND_HITS', null);
  }

  stop(): void {
    this.running = false;
  }

  destroy(): void {
    //
  }
}
