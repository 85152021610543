import { memo } from 'react';
import { Toolbar } from '_common/suite/components';
import PresentationToolbar from './Toolbar';
import { InteractionController } from '_common/components';

const PresentationToolbarBoundary = () => {
  return (
    <InteractionController environment="presentation" style={{ gridArea: 'toolbar' }}>
      <Toolbar id="presentation-toolbar">
        <PresentationToolbar />
      </Toolbar>
    </InteractionController>
  );
};

export default memo(PresentationToolbarBoundary);
