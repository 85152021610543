import { notify } from '_common/components/ToastSystem';

export default class UploadManager {
  /**
   * This function checks that the image size is not larger than 64MB
   *
   * @param {object} thisFile
   * @return {boolean}
   */
  static acceptImageFileSize(thisFile: File): boolean {
    if (thisFile.size > 67108864) {
      notify({
        type: 'error',
        title: 'global.error',
        message: 'editor.errors.maxSize',
      });
      return false;
    }
    return true;
  }

  static readAsDataUrl(file: File, onLoad: () => {}) {
    const reader = new FileReader();
    reader.onload = onLoad;
    reader.readAsDataURL(file);
  }
}
