import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { closeModal } from '_common/modals/ModalsSlice';

import { Button, Modal } from 'dodoc-design-system';
import styles from './MissingFontsModal.module.scss';

const MODAL = 'MissingFontsModal';

const MissingFontsModal = () => {
  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const missingFonts = useSelector((state) => state.fonts.missing);

  const close = () => {
    dispatch(closeModal(MODAL));
  };
  return (
    <Modal width="62rem" open={!!isOpen} onClose={close} type="warning" testId="missing-fonts">
      <Modal.Header onClose={close}>
        <FormattedMessage id="MISSING_FONTS" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <FormattedMessage id="MISSING_FONTS_MODAL_BODY" />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage id="MISSING_FONTS" />:
        </div>
        {missingFonts.map((font) => (
          <div key={font.value} className={styles.font}>
            {font.label}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="missing-fonts-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MissingFontsModal;
