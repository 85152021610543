import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export type PDFOverlayProps = {
  children: ReactNode;
};

const PDFOverlay = ({ children }: PDFOverlayProps) => {
  const overlay = document.getElementById('pdf-overlay');
  if (overlay) {
    return ReactDOM.createPortal(children, overlay);
  }
  return null;
};

export default PDFOverlay;
