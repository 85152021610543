import { useSelector } from '_common/hooks';

import PermissionsContext from '../PermissionsContext';
import TableContent, { TableProps } from './TableContent';

const Table = (props: TableProps & { canEdit?: boolean }) => {
  const data = useSelector((state) => state.app.data);

  return (
    <PermissionsContext object={data[props.objectId]} canEdit={props.canEdit}>
      <TableContent {...props} />
    </PermissionsContext>
  );
};

export default Table;
