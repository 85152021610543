import EventsManager from 'Editor/services/EventsManager';
import { EditorSelectionUtils, JsonRange } from 'Editor/services/_Common/Selection';
import { BaseTypedEmitter } from '_common/services/Realtime';

interface Events {
  SELECTION_CHANGED: (
    range: Editor.Selection.JsonRange,
    modifers: Editor.Data.Selection.Modifiers,
  ) => void;
}

type Timeouts = {
  selectionChanged: NodeJS.Timeout | null;
  resetCount: NodeJS.Timeout | null;
  startTracker: NodeJS.Timeout | null;
};

const SELECTION_CHANGE_DEBOUNCE = 150;

export class SelectionTracker extends BaseTypedEmitter<Events> {
  private view?: Editor.Visualizer.BaseView;
  private timeouts: Timeouts;

  private changeCounts: number;

  private isRunning: boolean;

  private debug: boolean = false;

  constructor() {
    super();

    this.onSelectionChangedEvent = this.onSelectionChangedEvent.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);
    this.resetCount = this.resetCount.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);

    this.timeouts = {
      selectionChanged: null,
      resetCount: null,
      startTracker: null,
    };

    this.changeCounts = 0;
    this.isRunning = false;
  }

  bindView(view: Editor.Visualizer.BaseView) {
    this.view = view;
  }

  start() {
    if (this.debug) {
      logger.trace('selectionTracker start');
    }
    if (!this.isRunning) {
      this.addListeners();
      this.isRunning = true;
      this.timeouts.startTracker = null;
    }
  }

  stop() {
    if (this.debug) {
      logger.trace('selectionTracker stop');
    }
    this.removeListeners();

    this.isRunning = false;
  }

  debounceStart() {
    if (this.debug) {
      logger.trace('selectionTracker debounceStart');
    }
    if (this.timeouts.startTracker) {
      clearTimeout(this.timeouts.startTracker);
    }

    this.timeouts.startTracker = setTimeout(this.start, 50);
  }

  destroy() {
    this.stop();
    this.clearTimeouts();
    super.destroy();
  }

  clearTimeouts() {
    if (this.debug) {
      logger.trace('selectionTracker clearTimeouts');
    }
    const keys = Object.keys(this.timeouts) as (keyof Timeouts)[];

    for (let i = 0; i < keys.length; i++) {
      const value = this.timeouts[keys[i]];
      if (value) {
        clearTimeout(value);
        this.timeouts[keys[i]] = null;
      }
    }
  }

  private addListeners() {
    EventsManager.getInstance().on('DOCUMENT_SELECTION_CHANGE', this.onSelectionChangedEvent);
    // EventsManager.getInstance().on(EventsManager.MOUSE_DOWN, this._onMouseDown);
    // EventsManager.getInstance().on(EventsManager.MOUSE_UP, this._onMouseUp);
  }

  private removeListeners() {
    EventsManager.getInstance().removeListener(
      'DOCUMENT_SELECTION_CHANGE',
      this.onSelectionChangedEvent,
    );
    // EventsManager.getInstance().removeListener(EventsManager.MOUSE_DOWN, this._onMouseDown);
    // EventsManager.getInstance().removeListener(EventsManager.MOUSE_UP, this._onMouseUp);
  }

  onSelectionChangedEvent(event: Event) {
    if (this.changeCounts === 0) {
      this.selectionChanged();
      this.resetCount();
    } else {
      if (this.timeouts.selectionChanged) {
        clearTimeout(this.timeouts.selectionChanged);
      }

      if (this.timeouts.resetCount) {
        clearTimeout(this.timeouts.resetCount);
        this.timeouts.resetCount = null;
      }

      this.timeouts.selectionChanged = setTimeout(() => {
        this.selectionChanged();
        this.resetCount();
      }, SELECTION_CHANGE_DEBOUNCE);
    }

    this.changeCounts += 1;
  }

  resetCount() {
    if (this.timeouts.resetCount) {
      clearTimeout(this.timeouts.resetCount);
    }
    this.timeouts.resetCount = setTimeout(() => {
      this.timeouts.resetCount = null;

      if (this.changeCounts > 0) {
        this.changeCounts = 0;
      }
    }, SELECTION_CHANGE_DEBOUNCE);
  }

  debounceSelectionChanged(updateModifiers: boolean = true) {
    if (this.timeouts.selectionChanged) {
      clearTimeout(this.timeouts.selectionChanged);
    }

    this.timeouts.selectionChanged = setTimeout(() => {
      this.selectionChanged(updateModifiers);
    }, 150);
  }

  selectionChanged(updateModifiers: boolean = true) {
    if (this.debug) {
      logger.trace('selectionTracker selectionChanged', updateModifiers);
    }

    if (this.timeouts.selectionChanged) {
      clearTimeout(this.timeouts.selectionChanged);
    }
    this.timeouts.selectionChanged = null;

    const selection = EditorSelectionUtils.getSelection();
    const range = EditorSelectionUtils.getRange();
    if (range && this.isSelectionInContainer(range)) {
      const modifiersData: Editor.Data.Selection.Modifiers = {};
      if (updateModifiers) {
        // update modifiers data
        const clientRects = range.getClientRects();

        if (range.collapsed) {
          modifiersData.expandingDirection = null;
          modifiersData.cellSelection = false;

          if (clientRects.length > 0) {
            modifiersData.px = clientRects[0].x;
            modifiersData.py = clientRects[0].y;
          }
        } else {
          if (EditorSelectionUtils.isSelctionBackwards(selection)) {
            modifiersData.expandingDirection = 'backward';
            modifiersData.px = clientRects.length > 0 ? clientRects[0].left : null;
          } else {
            modifiersData.expandingDirection = 'forward';
            modifiersData.px =
              clientRects.length > 0 ? clientRects[clientRects.length - 1].right : null;
          }
        }
      }

      const jsonRange = JsonRange.buildFromDOMRange(range);

      this.emit('SELECTION_CHANGED', jsonRange, modifiersData);
    }
  }

  isSelectionInContainer(range: Range | undefined = EditorSelectionUtils.getRange()) {
    if (range && this.view?.contains(range.commonAncestorContainer)) {
      return true;
    }
    return false;
  }
}
