import { ReactNode } from 'react';
import { Card } from 'dodoc-design-system';

import styles from './Header.module.scss';
import { CardHeaderProps } from 'dodoc-design-system/build/types/Components/Card/CardHeader/CardHeader';

type HeaderProps = CardHeaderProps;

const Header = ({ children, ...props }: HeaderProps) => {
  return (
    <Card.Header size="medium" {...props}>
      <div className={styles.root}>{children}</div>
    </Card.Header>
  );
};

const Left = ({ children }: { children: ReactNode }) => {
  return <div className={styles.left}>{children}</div>;
};

const Right = ({ children }: { children: ReactNode }) => {
  return <div className={styles.right}>{children}</div>;
};

export default Object.assign(Header, {
  Left,
  Right,
});
