import { useState } from 'react';

import EditableReplyCard from './EditableReplyCard/EditableReplyCard';
import ViewReplyCard, { ViewReplyCardProps } from './ViewReplyCard/ViewReplyCard';

export type ReplyCardProps = {
  collaborators: UserId[];
  editReply: (payload: { replyId: string; newContent: string }) => void;
  testId: string;
} & Pick<
  ViewReplyCardProps,
  | 'reply'
  | 'voteReply'
  | 'deleteReply'
  | 'canEditReply'
  | 'canDeleteReply'
  | 'canVoteReply'
  | 'optionsToggleTooltip'
  | 'likeToggleTooltip'
>;

const ReplyCard = ({
  reply,
  collaborators,
  optionsToggleTooltip,
  likeToggleTooltip,
  editReply,
  deleteReply,
  voteReply,
  canEditReply,
  canDeleteReply,
  canVoteReply,
  testId,
}: ReplyCardProps) => {
  const [editMode, setEditMode] = useState(false);

  const handleApply = async (newContent: string) => {
    editReply({ replyId: reply.id, newContent });
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleStartEdit = () => {
    setEditMode(true);
  };

  return (
    <span data-testid={testId ? `${testId}-root` : undefined}>
      {editMode ? (
        <EditableReplyCard
          currentContent={reply.content.content ? JSON.stringify(reply.content.content) : ''}
          collaborators={collaborators}
          onApply={handleApply}
          onCancel={handleCancel}
          testId={testId}
        />
      ) : (
        <ViewReplyCard
          reply={reply}
          optionsToggleTooltip={optionsToggleTooltip}
          likeToggleTooltip={likeToggleTooltip}
          onStartEdit={handleStartEdit}
          deleteReply={deleteReply}
          voteReply={voteReply}
          canEditReply={canEditReply}
          canDeleteReply={canDeleteReply}
          canVoteReply={canVoteReply}
          testId={testId}
        />
      )}
    </span>
  );
};

export default ReplyCard;
