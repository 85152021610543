import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type FindContext = {
  matchCase: boolean;
  wholeWord: boolean;
  toggleMatchCase: () => void;
  toggleWholeWord: () => void;
};

type FindContextProps = {
  children: ReactNode;
};

const FindContext = createContext<FindContext | undefined>(undefined);

const FindProvider = ({ children }: FindContextProps) => {
  const [matchCase, setMatchCase] = useState(false);
  const [wholeWord, setWholeWord] = useState(false);

  const toggleMatchCase = () => {
    setMatchCase((prevState) => !prevState);
  };
  const toggleWholeWord = () => {
    setWholeWord((prevState) => !prevState);
  };

  const context = useMemo(() => {
    return { matchCase, wholeWord, toggleMatchCase, toggleWholeWord };
  }, [matchCase, wholeWord, toggleMatchCase, toggleWholeWord]);

  if (context) {
    return <FindContext.Provider value={context}>{children}</FindContext.Provider>;
  }
  return null;
};

export const useFind = () => {
  const context = useContext(FindContext);
  if (context === undefined) {
    throw new Error('useFind must be used within a FindProvider');
  }
  return context;
};

export default FindProvider;
