import { SelectionUtils } from '_common/DoDOCSelection';
import { PresentationRange } from './PresentationRange';

export class PresentationSelectionUtils extends SelectionUtils {
  static getRange(): Presentation.Selection.PresentationRange | undefined {
    const selection = SelectionUtils.getSelection();
    if (selection && selection.type !== 'None' && selection.rangeCount > 0) {
      return PresentationRange.fromNativeRange(selection.getRangeAt(0));
    }
    return undefined;
  }

  static createNewRange(): Presentation.Selection.PresentationRange {
    return new PresentationRange();
  }
}
