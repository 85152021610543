import { ProgressBar } from 'dodoc-design-system';
import NotificationSettingsRow from 'Settings/components/NotificationSettings/NotificationSettingsRow/NotificationSettingsRow';
import { NOTIFICATIONS_SETTINGS } from 'Settings/pages/TenantSettingsPage/General/NotificationSettings/NotificationSettings';
import Channels from 'Settings/pages/UserSettingsPage/UserNotifications/NotificationSettings/Channels/Channels';
import { useGetTenantNotificationSettingsQuery } from '_common/services/api/SystemApi';
import { useGetUserSettingsQuery } from '_common/services/api/UserApi';

import styles from './NotificationSettings.module.scss';

const NotificationSettings = () => {
  const { data: tenantData, isLoading: tenantIsLoading } = useGetTenantNotificationSettingsQuery();
  const { data: userData, isLoading: userIsLoading } = useGetUserSettingsQuery(undefined, { refetchOnMountOrArgChange: true });

  if (tenantIsLoading || userIsLoading) {
    return <ProgressBar testId="tenant-notification-settings-loading-progressBar" />;
  }

  if (!tenantData || !userData) {
    return null;
  }

  return (
    <div className={styles.container}>
      {(Object.keys(NOTIFICATIONS_SETTINGS) as Array<keyof typeof NOTIFICATIONS_SETTINGS>)
        .filter((notificationId) => tenantData.notifications[notificationId])
        .map((notificationId) => (
          <NotificationSettingsRow
            key={notificationId}
            id={notificationId}
            title={NOTIFICATIONS_SETTINGS[notificationId].title}
            description={NOTIFICATIONS_SETTINGS[notificationId].description}
          >
            <Channels
              key={`${notificationId}.channels`}
              notificationId={notificationId}
              channels={userData.notifications[notificationId]}
              dataTestId={{ app: `${notificationId}.app`, email: `${notificationId}.email` }}
            />
          </NotificationSettingsRow>
        ))}
    </div>
  );
};

export default NotificationSettings;
