import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

const SLICE_NAME = 'EDITOR_STYLES';

type DefaultStyle = Editor.Style.DefaultStyle;
type CustomStyle = Editor.Style.CustomStyle;

type StylesSliceState = {
  data: {
    [style in DefaultStyle | CustomStyle]: Editor.Style;
  };
  list: (DefaultStyle | CustomStyle)[];
};

const initialState: StylesSliceState = {
  data: {},
  list: [],
};

const StylesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    loadStyles: (state, action: PayloadAction<StylesSliceState['data']>) => {
      const customStyles: CustomStyle[] = [];
      (Object.keys(action.payload) as Array<keyof typeof action.payload>).forEach((style) => {
        if (!style.match(/^(h1|h2|h3|h4|h5|h6|p|t|a|fc|tc|ec)$/) && action.payload[style].n) {
          customStyles.push(style);
        }
      });
      customStyles.sort((a, b) => {
        const na = action.payload[a].n || '';
        const nb = action.payload[b].n || '';
        return na.toLowerCase() > nb.toLowerCase() ? 1 : -1;
      });
      const list = [
        'p',
        't',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'a',
        'fc',
        'tc',
        'ec',
        ...customStyles,
      ].filter((value) => action.payload[value]);

      state.data = action.payload;
      state.list = list;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const selectDocumentStyles = createSelector(
  [(state: RootState) => state.editor.styles.data, (state: RootState) => state.editor.styles.list],
  (data, list) => {
    return {
      list,
      data: Object.keys(data).reduce<{
        [id in typeof list[number]]: Editor.Style.ParsedStyle;
      }>((styles, id) => {
        const style = data[id];
        let name = style.n;

        if (style.b) {
          name = name.charAt(0).toUpperCase() + name.slice(1);
        }

        styles[id] = {
          ...style,
          alias: style.a ? style.a.split(',')[0] : name,
          fullName: `${name}${style.a ? `, ${style.a}` : ''}`,
        };
        return styles;
      }, {}),
    };
  },
);

export const { loadStyles } = StylesSlice.actions;

export default StylesSlice.reducer;
