import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { setRightPanel, setShowNav, setShowNotes } from 'Presentation/PresentationSlice';
import { WelcomeScreen } from '_common/components';
import OnboardingCards from './OnboardingCards';

const PresentationOnboarding = () => {
  const dispatch = useDispatch();

  const [skipping, setSkipping] = useState(false);

  const active = useSelector((state) => state.onboarding.active.presentation);
  const started = useSelector((state) => state.onboarding.started.presentation);
  const originalDoc = useSelector((state) => state.onboarding.currentDocument.presentation);
  const currentPresentationId = useSelector((state) => state.presentation.general.presentationId);

  useEffect(() => {
    if (
      (started || active) &&
      (currentPresentationId !== originalDoc?.id || originalDoc?.isOnboarding)
    ) {
      dispatch(setShowNav(false));
      dispatch(setShowNotes(false));
      dispatch(setRightPanel(null));
    }
  }, [started, active, originalDoc, currentPresentationId]);

  if (
    (!started && !active) ||
    !originalDoc ||
    (!originalDoc?.isOnboarding &&
      (originalDoc?.id === '' || currentPresentationId === originalDoc?.id))
  ) {
    return null;
  }

  return (
    <>
      {(active || (started && skipping)) && (
        <WelcomeScreen
          location="presentation"
          onSkip={setSkipping}
          labelsId={{
            title: 'WELCOME_TO_THE_PRESENTATION_REVIEWER',
            description: 'WELCOME_PRESENTATION_DESCRIPTION',
            cta: 'LEARN_MORE',
          }}
          hiddenElements={['presentationSlides']}
          showCurrentUser={false}
        />
      )}
      {(started || skipping) && <OnboardingCards skipping={skipping} setSkipping={setSkipping} />}
    </>
  );
};

export default PresentationOnboarding;
