import { ELEMENTS } from 'Editor/services/consts';
import { intersection, omit } from 'lodash';
import { Path } from 'sharedb';
import { RealtimeObject } from '_common/services/Realtime';
import { NodeModel, NodeUtils } from '../../models';
import BaseController from '../BaseController';

export class NodeController extends BaseController {
  start(documentId?: string, data?: Realtime.Core.Document.Data, user?: Realtime.Core.User): void {}

  stop(): void {}

  destroy(): void {}

  // subscriptions

  subscribeNode(nodeId: string) {
    // TODO: refactor to renderer view model
  }

  unsubscribeNode(nodeId: string) {
    // TODO: refactor to renderer view model
  }

  // TODO this needs to be reviewed when the object changes to redacted
  //      for the user.
  forceUpdate(nodeId: string) {
    const model = this.getNodeModelById(nodeId);
    return model?.forceUpdate();
  }

  setNodesVersion(nodeIds: string[]) {
    // const length = nodeIds.length;
    // let id;
    // for (let i = 0; i < length; i++) {
    //   id = nodeIds[i];
    //   const nodeModel = this.getNodeModelById(id);
    // TODO: subs
    // if (!this[_nodeSubs][id]) {
    //   this.subscribeNode(id);
    // }
    // TODO:
    // nodeModel?.loadVersion(this._loadedVersion);
    // }
  }

  clearNodesVersion(nodeIds: string[]) {
    const length = nodeIds.length;
    let id;
    for (let i = 0; i < length; i++) {
      id = nodeIds[i];
      const nodeModel = this.getNodeModelById(id);

      nodeModel?.backToCurrentVersion();
    }
  }

  isNodeLocked(nodeId: string, path = undefined) {
    const nodeModel = this.getNodeModelById(nodeId);
    if (nodeModel) {
      const lockData = nodeModel.getLockObject(path);
      const loggedUser = this.Data.users?.user;
      if (
        lockData &&
        lockData.lock &&
        lockData.lockExpiration &&
        new Date() < new Date(lockData.lockExpiration) &&
        loggedUser != null
      ) {
        return +lockData.lock !== +loggedUser.id;
      }
    }
    return false;
  }

  getNodeLock(nodeId: string) {
    const nodeModel = this.getNodeModelById(nodeId);
    if (nodeModel?.loaded) {
      const lockData = nodeModel.getLockObject();
      if (lockData && lockData.lock) {
        return lockData.lock;
      }
    }
    return false;
  }

  isNodeReadonly(nodeId: string) {
    const nodeModel = this.getNodeModelById(nodeId);
    return nodeModel?.isReadonly();
  }

  isNodeEditable(nodeId: string) {
    const nodeModel = this.getNodeModelById(nodeId);
    const data = nodeModel?.selectedData();

    const loggedUserId = this.Data?.users?.loggedUserId;

    let locked = false;
    //TODO: evaluates this in the future
    //validate locks only for paragraphs
    if (data?.type === 'p') {
      if (typeof data?.lock === 'string') {
        const lockData = data.lock.split('|');
        const lockuser = lockData[0];
        const expDate = Date.parse(lockData[1]);
        locked = loggedUserId != null && +lockuser !== +loggedUserId && Date.now() <= expDate;
      } else {
        locked = data?.lock;
      }
    }

    return (
      !data?.readonly &&
      !(data?.approvedBy.length > 0) &&
      !locked &&
      this.Data.permissions?.canUserPerform(nodeId, 'edit')
    );
  }

  getNodeTasks(nodeId: string) {
    const nodeModel = this.getNodeModelById(nodeId);
    return nodeModel?.get()?.tasks || [];
  }

  getNodePermissions(nodeId: string) {
    const nodeModel = this.getNodeModelById(nodeId);
    return {
      id: nodeId,
      permissions: nodeModel?.get()?.permissions,
    };
  }

  getLanguageForNode(nodeId: string) {
    const model = this.getNodeModelById(nodeId);
    if (model?.loaded && model.language) {
      return model.language;
    }

    return this.Data.document?.getDocumentLanguage();
  }

  getLanguageForNodes(nodeIds: string[]) {
    const languages: any = {};
    for (let index = 0; index < nodeIds.length; index++) {
      const code = this.getLanguageForNode(nodeIds[index])?.code;
      if (code) {
        languages[nodeIds[index]] = code;
      } else {
        languages[nodeIds[index]] = {};
      }
    }
    return languages;
  }

  async getNodePermissionsAsync(nodeId: string) {
    const model = this.getNodeModelById(nodeId);
    await model?.fetch();
    return {
      id: nodeId,
      permissions: model?.get()?.permissions,
    };
  }

  async getNodeApprovedAsync(nodeId: string) {
    const model = this.getNodeModelById(nodeId);
    await model?.fetch();
    const approvedBy = model?.get()?.approvedBy || [];
    return {
      id: nodeId,
      approved: approvedBy.length > 0,
    };
  }

  getApprovingUsers(nodeId: string) {
    const model = this.getNodeModelById(nodeId);
    return model?.get()?.approvedBy || [];
  }

  /**
   * @deprecated
   * @param nodeId
   * @param path
   * @param operation
   * @returns
   */
  getNodeForRenderer(nodeId: string, path: Path, operation: Realtime.Core.RealtimeOp) {
    if (!nodeId) {
      throw new Error('Node id not provided');
    }
    const nodeData = this.getNodeModelById(nodeId)?.get();

    if (!nodeData) {
      throw new Error(`Error fetching node with id ${nodeId}`);
    }

    if (!path) {
      return nodeData;
    }
    let iterator = nodeData;
    let value = null;
    let length = path.length;
    if (operation.ld || operation.li) {
      length -= 2;
    } else if (path.includes('id') || path.includes('parent_id')) {
      length -= 1;
    }
    // eslint-disable-next-line
    for (var i = 0; i < length; i++) {
      iterator = iterator[path[i]];
      if (!iterator) {
        break;
      }
      if (iterator.id) {
        value = iterator;
      }
    }
    return value || nodeData;
  }

  getNodeForParser(nodeId: string) {
    const model = this.getNodeModelById(nodeId);
    const modelData = model?.get();
    if (modelData) {
      return omit(
        {
          id: nodeId, // in case theres no loaded data
          ...modelData,
        },
        ['commentRefs', 'permissions', 'approvedBy', 'refs', 'lang'],
      );
    }
    return {
      id: nodeId,
    };
  }

  getNodeContents(node: Editor.Data.Node.Data): string {
    const queue = [node];
    let result = '';
    while (queue.length) {
      const element = queue.shift();
      if (element) {
        if (element.type === 'text') {
          result += element.content;
        } else if (
          !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
          !(
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
            element.properties &&
            intersection(
              Object.keys(element.properties),
              NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
            ).length > 0
          ) &&
          element.childNodes
        ) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    return result;
  }

  getProperParentForReferenceNode(element: Editor.Data.Node.Data, ref: any): Editor.Data.Node.Data {
    const queue = [
      {
        last: element,
        node: element,
      },
    ];
    let result = element;
    while (queue.length) {
      const queueElement = queue.shift();
      if (queueElement?.node != null || queueElement?.last != null) {
        const { node, last } = queueElement;
        if (node.id === ref) {
          result = last;
          break;
        }
        if (
          !NodeUtils.NON_CONTENT_ELEMENTS.includes(node.type) &&
          !(
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type] &&
            node.properties &&
            intersection(
              Object.keys(node.properties),
              NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type],
            ).length > 0
          ) &&
          node.childNodes
        ) {
          let properLast = last;
          if (node.type === 'p') {
            properLast = node;
          }
          queue.unshift(
            ...node.childNodes.map((value: any) => ({
              last: properLast,
              node: value,
            })),
          );
        }
      }
    }
    return result;
  }

  getContent(element: Editor.Data.Node.Data, ref: any): string | null {
    if (element.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      if (ref) {
        return this.getNodeContents(this.getProperParentForReferenceNode(element, ref));
      }
      return this.getNodeContents(element);
    }
    if (element.type === 'p') {
      return this.getNodeContents(element);
    }
    return null;
  }

  getNodeContentsAfterField(node: Editor.Data.Node.Data, fieldId: string): string {
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [this.getProperParentForReferenceNode(node, fieldId)];
    }
    let result = '';
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        result = '';
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  getNodeContentsBeforeField(node: Editor.Data.Node.Data, fieldId: string): string {
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [this.getProperParentForReferenceNode(node, fieldId)];
    }
    let result = '';
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        queue = [...(element.childNodes || [])];
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  async applyOpsToNode(
    nodeId: string,
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeObject<Editor.Data.Node.Data> | undefined> {
    const model = this.getNodeModelById(nodeId);
    if (model?.loaded) {
      return model.apply(ops, options);
    }
  }

  async revertOpsToNode(
    nodeId: string,
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ): Promise<RealtimeObject<Editor.Data.Node.Data> | undefined> {
    const model = this.getNodeModelById(nodeId);
    if (model?.loaded) {
      return model.revert(ops, options);
    }
  }

  setTaskOnNodes(taskId: string, nodeIds: string[]) {
    for (let index = 0; index < nodeIds.length; index++) {
      const model = this.getNodeModelById(nodeIds[index]);
      model?.addTask(taskId);
    }
  }

  canSetTaskOnNodes(nodeIds: string[]): boolean {
    if (nodeIds.length > 0) {
      for (let index = 0; index < nodeIds.length; index++) {
        if (!this.getNodeModelById(nodeIds[index])?.loaded) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  removeTaskFromNodes(taskId: string, nodeIds: string[]) {
    for (let index = 0; index < nodeIds.length; index++) {
      const model = this.getNodeModelById(nodeIds[index]);
      model?.removeTask(taskId);
    }
  }

  getNodeModelById(nodeId: string): NodeModel | undefined {
    return this.Data.models?.get('NODE', nodeId);
  }

  getPreviousModelById(refNodeId: string): NodeModel | undefined {
    const previousId = this.Data.structure?.getPreviousChildId(refNodeId);
    if (previousId) {
      return this.Data.models?.get('NODE', previousId);
    }

    return undefined;
  }

  getNextModelById(refNodeId: string): NodeModel | undefined {
    const nextId = this.Data.structure?.getNextChildId(refNodeId);
    if (nextId) {
      return this.Data.models?.get('NODE', nextId);
    }

    return undefined;
  }

  updateCustomTabs(nodeId: string, tabs: Editor.Data.TabStop[]) {
    return this.getNodeModelById(nodeId)?.updateCustomTabs(tabs);
  }

  addCustomTab(nodeId: string, tab: Editor.Data.TabStop) {
    return this.getNodeModelById(nodeId)?.addCustomTab(tab);
  }

  editCustomTabStop(nodeId: string, tab: Editor.Data.TabStop, value: Editor.Data.TabStop['v']) {
    return this.getNodeModelById(nodeId)?.editCustomTabStop(tab, value);
  }

  editCustomTabLeader(nodeId: string, tab: Editor.Data.TabStop, value: Editor.Data.TabStop['l']) {
    return this.getNodeModelById(nodeId)?.editCustomTabLeader(tab, value);
  }

  editCustomTabAlignment(
    nodeId: string,
    tab: Editor.Data.TabStop,
    value: Editor.Data.TabStop['t'],
  ) {
    return this.getNodeModelById(nodeId)?.editCustomTabAlignment(tab, value);
  }

  deleteCustomTab(nodeId: string, tab: Editor.Data.TabStop) {
    return this.getNodeModelById(nodeId)?.deleteCustomTab(tab);
  }

  setLanguageOnNodes(language: string, nodeIds: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODES:LANGUAGE:SET',
        {
          nodeIds,
          language,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getApprovedNodes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODES:APPROVED:GET',
        {},
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getApprovedNodesByStyleId(styleId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODES:GET:APPROVED:BY:STYLE',
        {
          styleId,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  private findLeftIndentationStep(current: number, increment: boolean) {
    const INDENTATION_MIN = NodeUtils.INDENTATION_LIMITS.LEFT.RENDER_MIN;
    const INDENTATION_MAX = NodeUtils.INDENTATION_LIMITS.LEFT.RENDER_MAX;
    const INDENTATION_VALUE = NodeUtils.INDENTATION_LIMITS.INDENTATION_STEP;

    const value = increment ? current + INDENTATION_VALUE : current;
    const rest = value % INDENTATION_VALUE;

    let result = current;

    if (Number(parseFloat(rest.toString()).toFixed(2)) === 0) {
      result = increment ? current + INDENTATION_VALUE : current - INDENTATION_VALUE;
    } else {
      result = increment ? value : value - rest;
    }

    if (result > 0) {
      return Math.min(INDENTATION_MAX, result);
    }
    return Math.max(INDENTATION_MIN, result);
  }

  changeLeftIndentation(
    id: string,
    pathToElement: Editor.Selection.Path,
    increment: boolean,
    options: Realtime.Core.RealtimeSourceOptions = { source: 'LOCAL_RENDER' },
  ) {
    const nodeModel = this.getNodeModelById(id);
    let nodeData: Editor.Data.Node.Data | undefined | null;

    if (pathToElement.length) {
      nodeData = nodeModel?.getChildDataByPath(pathToElement);
    } else {
      nodeData = nodeModel?.selectedData();
    }

    if (!nodeModel || !nodeData || !this.Data.styles) {
      return false;
    }

    if (NodeUtils.isParagraphData(nodeData)) {
      let currentLeftInd = 0;
      if (nodeData.properties.ind?.l) {
        currentLeftInd = nodeData.properties.ind.l;
      } else {
        const styleInd = this.Data.styles.documentStyles.hasStyleIndentation(nodeData.properties.s);
        if (styleInd?.l) {
          currentLeftInd = styleInd.l;
        }
      }

      const nextLeftIndent = this.findLeftIndentationStep(currentLeftInd, increment);

      if (nodeData.properties.ind) {
        nodeModel.set([...pathToElement, 'properties', 'ind', 'l'], nextLeftIndent, options);
      } else {
        nodeModel.set(
          [...pathToElement, 'properties', 'ind'],
          {
            l: nextLeftIndent,
          },
          options,
        );
      }
    }

    return false;
  }
}
