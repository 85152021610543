import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Divider, Toggle, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { setShowNotes, setZoom } from '../PresentationSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

import { Footer, Help, ZoomControls } from '_common/suite/components';
import { InteractionController } from '_common/components';

const PresentationFooter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [sendOnboardingStatus] = useOnboardingStatusMutation();

  const showNotes = useSelector((state) => state.presentation.general.showNotes);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  const changeZoom = (newZoom: number) => {
    dispatch(setZoom(newZoom));
  };

  const resetOnboarding = () => {
    sendOnboardingStatus({ target: 'presentation', step: 'beginning' });
  };

  return (
    <>
      <Footer.Left>
        <Tooltip
          content={intl.formatMessage({ id: 'NOTES_PANEL_TITLE' })}
          placement="right"
          testId="notes-tooltip"
        >
          <Toggle
            variant="ghost"
            isToggled={showNotes}
            onClick={() => dispatch(setShowNotes(!showNotes))}
            size="medium"
            icon={showNotes ? 'NoteBlue' : 'NoteGrey'}
            testId="notes-toggle"
            id="presentation-footer-notes-toggle"
          />
        </Tooltip>
      </Footer.Left>
      <Footer.Right>
        <InteractionController
          environment="presentation"
          rules={[{ interaction: 'presentation_allow_zoom' }]}
          style={{ alignItems: 'center', height: '100%' }}
        >
          <ZoomControls
            zoom={zoom}
            changeZoom={changeZoom}
            zoomValues={[0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 2.25, 2.5, 3, 3.25, 3.5, 4]}
          />
        </InteractionController>
        <Divider vertical margin="0 1rem" />
        <Help resetOnboarding={resetOnboarding} />
      </Footer.Right>
    </>
  );
};

export default memo(PresentationFooter);
