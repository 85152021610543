import { useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, EmptyState } from 'dodoc-design-system';
import dayjs from 'dayjs';

import { useSelector } from '_common/hooks';
import { selectDocumentCitations } from 'Editor/redux/CitationsSlice';

import { ReferenceCard } from 'Editor/components';
import { getSanitizedEntityName } from '_common/modals/CitationsModal/utils';

import styles from './LibraryCitationsList.module.scss';

type LibraryCitationsListProps = {
  edit: (id: string) => void;
  insert: (id: string) => void;
};

const LibraryCitationsList = ({ edit, insert }: LibraryCitationsListProps) => {
  const intl = useIntl();
  const containerRef = useRef(null);
  const [filter, setFilter] = useState('');

  const documentCitations = useSelector(selectDocumentCitations);

  const filteredOrder = documentCitations.list.filter((id: string) => {
    const { title, author: authors, year } = documentCitations.dict[id];
    if (!id || !documentCitations.dict[id]) {
      return [];
    }
    return (
      filter === '' ||
      (title && title.toLowerCase().includes(filter.toLowerCase())) ||
      (authors &&
        !!authors.find((author) =>
          getSanitizedEntityName(author)?.toLowerCase().includes(filter.toLowerCase()),
        )) ||
      (year && year.toString().includes(filter.toString()))
    );
  });

  const getReferencesNumber = () => {
    if (filter) {
      return (
        <FormattedMessage
          id="NUMBER_OF_FILTERED_REFERENCES"
          values={{ tasks: filteredOrder.length, total: documentCitations.list.length }}
        />
      );
    }

    return (
      <FormattedMessage
        id="REFERENCES_IN_THE_LIBRARY"
        values={{ length: documentCitations.list.length }}
      />
    );
  };

  const renderEmptyView = () => {
    return (
      <div className={styles.emptyView}>
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'editor.modals.citations.noReferencesFound' })}
          testId="no-references-found-in-library"
        >
          <FormattedMessage id="NO_REFERENCES_IN_LIBRARY_FILTERS" />
        </EmptyState>
      </div>
    );
  };

  return (
    <>
      <Input
        margin="2rem 0 0"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        prefix="NavSearchBlue"
        placeholder={intl.formatMessage({ id: 'global.search' })}
        size="medium"
        testId="citations-library-search"
      />
      <div className={styles.infoTasks}>
        <div className={styles.infoText}>{getReferencesNumber()}</div>
      </div>
      <div ref={containerRef} className={styles.root}>
        {filteredOrder.length > 0
          ? filteredOrder
              .sort((a: string | number, b: string | number) =>
                dayjs(documentCitations.dict[b].time.creation).isAfter(
                  documentCitations.dict[a].time.creation,
                )
                  ? 1
                  : -1,
              )
              .map((citationId: string) => {
                const citation = documentCitations.dict[citationId];
                return (
                  <div key={citation.id} className={styles.card}>
                    <ReferenceCard citationId={citationId} edit={edit} insert={insert} />
                  </div>
                );
              })
          : renderEmptyView()}
      </div>
    </>
  );
};

export default LibraryCitationsList;
