import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import styles from './ReopenBlock.module.scss';

import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS } from 'Editor/services/consts';
import { useDispatch, useSelector } from '_common/hooks';
import { closeModal } from '_common/modals/ModalsSlice';

const ReopenBlock = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open.ReopenBlock);
  const list = useSelector((state) => state.modals.ReopenBlock.list);
  const selected = useSelector((state) => state.editor.blockApproval.selected);

  const close = () => {
    dispatch(closeModal('ReopenBlock'));
  };

  const handleNodesReopened = () => {
    const nodeIds = [selected, ...list.map((i: { id: string }) => i.id)];

    EditorManager.getInstance().reopenNodes(nodeIds);
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="75rem" testId="reopen-block">
      <Modal.Header onClose={close}>
        <FormattedMessage id="UNAPPROVE_CROSS_REFERENCED_BLOCK" />
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormattedMessage id="UNAPPROVE_CROSS_REFERENCED_BLOCK_BODY" />
          <div className={styles.unapproving}>
            <FormattedMessage id="YOU_ARE_ALSO_UNAPPROVING" />
            {list.map((i: { content: string; id: string; type: string; index: string }) => {
              if (i.content === null) {
                return (
                  <div className={styles.noPermissions} key={i.id}>
                    <FormattedMessage id="NO_PERMISSION_TO_VIEW_THIS_CONTENT" />
                  </div>
                );
              }
              const showPrefix =
                i.type === ELEMENTS.FigureElement.ELEMENT_TYPE ||
                i.type === ELEMENTS.TableElement.ELEMENT_TYPE ||
                i.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE ||
                Object.values(ELEMENTS.ParagraphElement.HEADING_TYPES).includes(
                  i.type as Editor.Elements.ParagraphHeadingsType,
                );
              const showSeparator =
                (i.type === ELEMENTS.FigureElement.ELEMENT_TYPE ||
                  i.type === ELEMENTS.TableElement.ELEMENT_TYPE ||
                  i.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE ||
                  Object.values(ELEMENTS.ParagraphElement.HEADING_TYPES).includes(
                    i.type as Editor.Elements.ParagraphHeadingsType,
                  )) &&
                i.content.length > 0;
              return (
                <div className={styles.block} key={i.id}>
                  {showPrefix && (
                    <FormattedMessage
                      id={
                        Object.values(ELEMENTS.ParagraphElement.HEADING_TYPES).includes(
                          i.type as Editor.Elements.ParagraphHeadingsType,
                        )
                          ? 'HEADING'
                          : i.type.toUpperCase()
                      }
                    />
                  )}
                  {`${
                    showSeparator &&
                    (i.type === ELEMENTS.FigureElement.ELEMENT_TYPE ||
                      i.type === ELEMENTS.TableElement.ELEMENT_TYPE)
                      ? i.index
                      : ''
                  }${showSeparator ? ' - ' : ''}${i.content}`}
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="reopen-block-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button size="medium" variant="primary" onClick={handleNodesReopened} testId="reopen-block-submit-button">
          <FormattedMessage id="UNAPPROVE_ALL_BLOCKS" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReopenBlock;
