import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, DatePicker } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { DATE_FORMATS } from '_common/components/FormattedDate/FormattedDate';

import {
  FilterIdentity,
  FilterName,
  TRANSLATIONS,
  DateRange,
  SettingByType,
} from '../../FilterController';
import { setFilter } from '../../FilterSlice';

const DateRangeHandler = ({
  identity,
  filter,
  filterQuantity,
  settings,
  testId,
}: {
  identity: FilterIdentity;
  filter: FilterName;
  filterQuantity: number;
  settings: SettingByType['dateRange'];
  testId: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserQuery();
  const currentValue = useSelector((state) => state.filters[identity]?.[filter]) as DateRange;
  const { startDate, endDate } = useMemo(() => {
    return !currentValue
      ? { startDate: undefined, endDate: undefined }
      : {
          startDate: currentValue.startISO ? new Date(currentValue.startISO) : undefined,
          endDate: currentValue.endISO ? new Date(currentValue.endISO) : undefined,
        };
  }, [currentValue]);

  const clearFilterValue = () => {
    dispatch(
      setFilter({
        identity,
        filter,
        value: null,
      }),
    );
  };

  const handleStartDateChange = (newStartDate: Date | null) => {
    if (!newStartDate && !currentValue?.endISO) {
      clearFilterValue();
      return;
    }

    dispatch(
      setFilter({
        identity,
        filter,
        value: {
          startISO: newStartDate?.toISOString(),
          endISO: currentValue?.endISO,
        },
      }),
    );
  };

  const handleEndDateChange = (newEndDate: Date | null) => {
    if (!newEndDate && !currentValue?.startISO) {
      clearFilterValue();
      return;
    }

    dispatch(
      setFilter({
        identity,
        filter,
        value: {
          startISO: currentValue?.startISO,
          endISO: newEndDate?.toISOString(),
        },
      }),
    );
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: TRANSLATIONS[filter] })}
      initialCollapsed={!currentValue}
      collapsable={filterQuantity > 1}
      testId={`${testId}-accordion`}
    >
      <DatePicker
        dateRange
        direction="vertical"
        placeholderStart={DATE_FORMATS[
          currentUser?.profile.date_format || 'dm'
        ].numeric.toLowerCase()}
        placeholderEnd={DATE_FORMATS[
          currentUser?.profile.date_format || 'dm'
        ].numeric.toLowerCase()}
        onStartChange={handleStartDateChange}
        onEndChange={handleEndDateChange}
        startDate={startDate}
        endDate={endDate}
        minDate={settings?.minDate ? new Date(settings.minDate) : undefined}
        maxDate={settings?.maxDate ? new Date(settings.maxDate) : new Date()}
        testId={`${testId}-accordion-datepicker`}
      />
    </Accordion>
  );
};

export default DateRangeHandler;
