import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import usePDFData from './hooks/usePDFData';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { useSelector } from '_common/hooks';

/**
 * Assumes that an admin/owner can do everything, the remaining restrictions are:
 * @property canAccess - has access permission to the pdf
 * @property canShare - can't be from iEnvision
 * @property canChangeStatus - can't be from iEnvision
 * @property canComment - comment permission :)
 * @property canCreateAnnotation - edit permission
 * @property canCopyAnnotation - need to be author of specified annotation
 * @property canDeleteAnnotation - need to be author of specified annotation
 * @property canEditAnnotation - need to be author of specified annotation
 * @property canChangePriority - need to be author of specified annotation
 * @property canResolveAnnotation - need to be author of specified annotation
 * @property canChangeAnnotationStatus - need to be author of specified annotation
 * @property canWatchAnnotation - can watch an annotation
 * @property canEditAnnotationReply - need to be author of specified reply
 * @property canDeleteAnnotationReply - need to be author of specified reply
 * @property canSaveVersion - can save/edit versions of the pdf
 * @property canRestoreVersion - can restore a version of the pdf
 * @property canAccessVersionHistory - access version history permission
 * @property canAccessAuditLog - can access the audit log of the pdf
 * @property canAccessTasks - not integrated version, if it is, only acessable if tasks are present in document
 * @property canCreateTasks - canComment and not integrated version
 */
const PERMISSIONS = {
  canAccess: false,
  canShare: false,
  canChangeStatus: false,
  canComment: false,
  canCreateAnnotation: false,
  canCopyAnnotation: false,
  canDeleteAnnotation: (annotation: PDF.Annotation) => false,
  canEditAnnotation: (annotation: PDF.Annotation) => false,
  canChangePriority: (annotation: PDF.Annotation) => false,
  canResolveAnnotation: (annotation: PDF.Annotation) => false,
  canChangeAnnotationStatus: (annotation: PDF.Annotation) => false,
  canDeleteAnnotationReply: (reply: PDF.Annotation.Reply) => false,
  canWatchAnnotation: false,
  canEditAnnotationReply: (reply: PDF.Annotation.Reply) => false,
  canSaveVersion: false,
  canRestoreVersion: false,
  canAccessVersionHistory: false,
  canAccessAuditLog: false,
  canAccessTasks: false,
  canCreateTask: false,
};

const PDFPermissionsContext = createContext<typeof PERMISSIONS>({ ...PERMISSIONS });

const DEFAULT_PERMISSIONS: { [Property in ApiSchemas['UserPermissionsValues']]: boolean } = {
  admin: false,
  owner: false,
  access: false,
  edit: false,
  delete: false,
  approve: false,
  comment: false,
  add_permission: false,
  remove_permission: false,
  import: false,
  export: false,
};

const PDFPermisionsContextProvider = ({ children }: { children: ReactNode }) => {
  const data = usePDFData();
  const { data: user } = useGetCurrentUserQuery();

  const isEnvision = useSelector(selectIsIEnvision);
  const initiatedWithTasks = useSelector((state) => state.pdf.annotations.initiatedWithTasks);

  const isAuthor = (annotation: PDF.Annotation) => {
    if (user && annotation) {
      return annotation.authorId === user.profile.id;
    }

    return false;
  };

  const isAssignee = (annotation: PDF.Annotation) => {
    if (user && annotation) {
      if (annotation.subtype === 'Task') {
        return annotation.assignee === user.profile.id;
      }
    }

    return false;
  };

  const isReplyAuthor = (reply: PDF.Annotation.Reply) => {
    if (user && reply) {
      return reply.authorId === user.profile.id;
    }

    return false;
  };

  const permissions = useMemo(() => {
    const permissions = { ...PERMISSIONS };

    if (data && user) {
      const userPermissions = data.user_permissions.reduce(
        (permissions, permission) => {
          permissions[permission] = true;
          return permissions;
        },
        { ...DEFAULT_PERMISSIONS },
      );

      userPermissions.admin = user.is_admin || user.is_superuser;
      // There is nothing that an admin can do that an owner cannot
      const isBossMan = userPermissions.admin || userPermissions.owner;

      const isApproved = data.status === 'approved';

      //#region General
      permissions.canAccess = isBossMan || userPermissions.access;
      permissions.canShare = isBossMan;
      permissions.canComment =
        !isApproved && (isBossMan || userPermissions.comment || userPermissions.edit);
      permissions.canChangeStatus = isBossMan;
      permissions.canAccessAuditLog = isBossMan;
      //#endregion

      //#region Annotations
      permissions.canCreateAnnotation =
        !isApproved && (isBossMan || userPermissions.comment || userPermissions.edit);
      permissions.canChangePriority = (annotation: PDF.Annotation) =>
        !isApproved &&
        (isBossMan || (isAuthor(annotation) && (userPermissions.edit || userPermissions.comment)));
      permissions.canDeleteAnnotation = (annotation: PDF.Annotation) =>
        !isApproved &&
        (isBossMan || (isAuthor(annotation) && (userPermissions.edit || userPermissions.comment)));
      permissions.canEditAnnotation = (annotation: PDF.Annotation) =>
        !isApproved &&
        (isBossMan || (isAuthor(annotation) && (userPermissions.edit || userPermissions.comment)));
      permissions.canCopyAnnotation = isBossMan || userPermissions.comment || userPermissions.edit;
      permissions.canResolveAnnotation = (annotation: PDF.Annotation) =>
        !isEnvision &&
        !isApproved &&
        (isBossMan || (isAuthor(annotation) && (userPermissions.edit || userPermissions.comment)));
      permissions.canChangeAnnotationStatus = (annotation: PDF.Annotation) =>
        !isApproved &&
        (isBossMan ||
          ((isAuthor(annotation) || isAssignee(annotation)) &&
            (userPermissions.edit || userPermissions.comment)));
      permissions.canWatchAnnotation =
        !isApproved &&
        (isBossMan || userPermissions.comment || userPermissions.edit || userPermissions.access);
      permissions.canDeleteAnnotationReply = (reply: PDF.Annotation.Reply) =>
        !isApproved &&
        (isBossMan || (isReplyAuthor(reply) && (userPermissions.edit || userPermissions.comment)));
      permissions.canEditAnnotationReply = (reply: PDF.Annotation.Reply) =>
        !isApproved &&
        (isBossMan || (isReplyAuthor(reply) && (userPermissions.edit || userPermissions.comment)));
      //#endregion

      //#region Version History
      permissions.canAccessVersionHistory = isBossMan;
      permissions.canSaveVersion = !isApproved && isBossMan;
      permissions.canRestoreVersion = !isApproved && isBossMan;
      //#endregion

      permissions.canAccessTasks = !isEnvision || initiatedWithTasks;
      permissions.canCreateTask = permissions.canCreateAnnotation && !isEnvision;
    }

    return permissions;
  }, [data, user, initiatedWithTasks]);

  return (
    <PDFPermissionsContext.Provider value={permissions}>{children}</PDFPermissionsContext.Provider>
  );
};

export const usePDFPermissions = () => {
  return useContext(PDFPermissionsContext);
};

export default PDFPermisionsContextProvider;
