import { SelectionFixer } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';
import { Logger } from '_common/services';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { InsertElementOperation } from '../../Operations';

export class EnterCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.Context, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  private checkAtStartAtEnd(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
    options: Editor.Edition.InsertBlockOptions = {},
  ): boolean {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData ||
      !elementData.id
    ) {
      return false;
    }

    let path = ctx.range.start.p.slice(elementPath.length, ctx.range.start.p.length);

    if (NodeUtils.isPathAtContentStart(elementData, path)) {
      const paragraph = NodeDataBuilder.buildParagraph({
        parent_id: ctx.baseData.parent_id,
      });
      if (paragraph) {
        options.pathAfterInsert = 'START';
        this.context.contentManipulator.insertBlock(ctx, paragraph, 'BEFORE', options);
      }
      return true;
    } else if (NodeUtils.isPathAtContentEnd(elementData, path)) {
      const paragraph = NodeDataBuilder.buildParagraph({
        parent_id: ctx.baseData.parent_id,
      });
      if (paragraph) {
        options.pathAfterInsert = 'START';
        this.context.contentManipulator.insertBlock(ctx, paragraph, 'AFTER', options);
      }
      return true;
    }

    return false;
  }

  private handleMultiBlockContainer(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData ||
      !ctx.baseData.id
    ) {
      return false;
    }

    if (NodeUtils.isMultiBlockContainerData(elementData)) {
      if (NodeUtils.isTableCellData(elementData)) {
        const closestTable = NodeUtils.closestSiblingAncestorOfType(ctx.baseData, elementPath, [
          ELEMENTS.TableElement.ELEMENT_TYPE,
        ]);

        if (closestTable) {
          const subPathToCell = elementPath.slice(closestTable.path.length);

          // check if selection is in first cell at start
          const resultInfo = NodeUtils.getParentChildInfoByPath(closestTable.data, subPathToCell);

          const subPathToContent = ctx.range.start.p.slice(closestTable.path.length);

          if (
            resultInfo?.parentIndex === 0 &&
            resultInfo.childIndex === 0 &&
            NodeUtils.isPathAtContentStart(closestTable.data, subPathToContent)
          ) {
            let previousSibling: Editor.Data.Node.Data | undefined | null;
            if (closestTable.data.id === ctx.baseData.id) {
              const previousModel = this.context.DataManager.nodes.getPreviousModelById(
                ctx.baseData.id,
              );
              previousSibling = previousModel?.selectedData();
            } else {
              // const parentOfTable
              const parentInfo = NodeUtils.getParentChildInfoByPath(
                closestTable.data,
                closestTable.path,
              );
              if (parentInfo) {
                previousSibling = parentInfo.parentData.childNodes?.[parentInfo.childIndex - 1];
              }
            }

            if (!previousSibling) {
              ctx.range.updateRangePositions({
                b: ctx.baseModel.id,
                p: [...closestTable.path, 'childNodes', 0],
              });

              const paragraph = NodeDataBuilder.buildParagraph({
                parent_id: closestTable.data.parent_id,
              });
              if (paragraph) {
                return this.context.contentManipulator.insertBlock(ctx, paragraph, 'BEFORE');
              }
            }
          }
        }
      } else {
        return this.checkAtStartAtEnd(ctx, elementData, elementPath, { outsideContainer: true });
      }
    }
    return false;
  }

  private handleText(
    ctx: Editor.Edition.ActionContext,
    textData: Editor.Data.Node.Data,
    textPath: Editor.Selection.Path,
  ): boolean {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData ||
      !textData.id
    ) {
      return false;
    }

    const isList = this.context.DataManager.numbering.isListElement(textData.id);

    if (
      isList &&
      NodeUtils.isEmptyData(textData) &&
      !this.context.DataManager.numbering.isBlockInOutlineList(textData.id)
    ) {
      const listLevel = this.context.DataManager.numbering.getListLevelFromBlock(textData.id);
      if (listLevel && listLevel > 0) {
        this.context.DataManager.numbering.outdentNodes([
          {
            blockId: ctx.baseModel.id,
            id: textData.id,
          },
        ]);
      } else {
        this.context.DataManager.numbering.removeBlocksFromList([textData.id]);
      }

      return true;
    } else {
      // normalize text selection
      SelectionFixer.normalizeTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        },
        this.context.DataManager,
      );

      if (
        !!this.context.contentManipulator.splitBlockContent(ctx, ctx.range.start.p, {
          setSelectionAfter: true,
          checkEmpty: false,
        })
      ) {
        return true;
      }
    }
    return false;
  }

  private handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    ctx.setModelAndData(baseModel, baseData);

    const closestContainer = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
    ]);

    if (closestContainer && this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      if (this.handleMultiBlockContainer(ctx, closestContainer.data, closestContainer.path)) {
        return true;
      }
    }

    let result = NodeUtils.closestOfTypeByPath(baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_TYPES,
      'tblc',
    ]);

    if (result) {
      if (
        NodeUtils.isTableCellData(result.data) &&
        result.data.childNodes?.length === 0 &&
        result.path
      ) {
        // is table cell and does not have a paragraph
        const pathToInsert: Editor.Selection.Path = [...result.path, 'childNodes', 0];
        const paragraphData = NodeDataBuilder.buildParagraph({
          parent_id: result.data.id,
        });
        if (paragraphData) {
          new InsertElementOperation(baseModel, pathToInsert, paragraphData).apply();

          pathToInsert.push('childNodes', 0);

          ctx.range.updateRangePositions({
            b: ctx.range.start.b,
            p: pathToInsert,
          });

          ctx.refreshBaseData();

          result = {
            data: paragraphData,
            path: [...pathToInsert],
          };
        }
      }

      if (
        NodeUtils.isBlockTextData(result.data) &&
        this.context.DataManager.nodes.isNodeEditable(baseModel.id)
      ) {
        return this.handleText(ctx, result.data, result.path);
      } else {
        return this.checkAtStartAtEnd(ctx, result.data, result.path);
      }
    } else if (NodeUtils.isDoubleTypeData(baseData)) {
      return this.checkAtStartAtEnd(ctx, baseData, [], { outsideContainer: true });
    }

    return false;
  }

  private handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        forceTextAsWrap: true,
      },
      this.context.DataManager,
    );

    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  async handleExec(): Promise<void> {
    if (this.debug) {
      Logger.trace('EnterCommand exec', this);
    }

    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    // handle non collapsed selection
    if (!this.actionContext.range.collapsed) {
      if (!this.handleNonCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      if (!this.handleCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
