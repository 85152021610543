import { Mixin } from 'mixwith';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class FieldsHandler extends superclass {
      destroy() {
        super.destroy();
      }

      // options = {
      //   label: '',
      //   numberingType: '',
      //   text: '',
      //   position: '',
      //   chapterNumbering: {
      //     chapterType: '',
      //     separator: '',
      //   }
      // }

      // #################################################
      //                    captions
      // #################################################
      insertCaptionOnSelection(
        actionContext,
        baseNode,
        anchorNode,
        options = {},
        forceInsertAsBlock = false,
      ) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
          }
        }

        if (baseNode) {
          if (EditorDOMUtils.isClosestTextElementEditable(baseNode) && !forceInsertAsBlock) {
            // SELECTION IS A DEFAULT TEXT ELEMENT
            this._handleInsertCaptionOnTextElement(actionContext, baseNode, options);
          } else if (
            !EditorDOMUtils.isClosestBlockNodeEditable(baseNode) ||
            baseNode.tagName === ELEMENTS.FigureElement.TAG ||
            baseNode.tagName === ELEMENTS.TableElement.TAG ||
            forceInsertAsBlock
          ) {
            // SELECTION IS A NON-EDITABLE ELEMENT
            // SELECTION IS A FIGURE OR A TABLE
            this._handleInsertCaptionOnBlockElement(actionContext, baseNode, options);
          } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
            this._handleInsertCaptionOnContainerElement(
              actionContext,
              baseNode,
              anchorNode,
              options,
              forceInsertAsBlock,
            );
          }
        }
      }

      _handleInsertCaptionOnContainerElement(
        actionContext,
        baseNode,
        anchorNode,
        options = {},
        forceInsertAsBlock,
      ) {
        if (EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.insertCaptionOnSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            options,
            forceInsertAsBlock,
          );
        }
      }

      _handleInsertCaptionOnTextElement(actionContext, baseNode, options = {}) {
        let range = EditorSelectionUtils.getRange();

        if (range.collapsed) {
          // build caption
          const captionElements = DOMElementFactory.buildCaptionElements(options);

          //      insert elements
          let i = 0;
          for (i = 0; i < captionElements.length; i++) {
            this._handleInsertInlineDefaultNode(actionContext, baseNode, captionElements[i]);
          }

          // check for caption style
          let subType = options.ls || ELEMENTS.ParagraphElement.BASE_STYLES.FIGURE_CAPTION;

          if (!this.dataManager.styles.getDocumentStyleFromId(subType)) {
            subType = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
          }

          baseNode.dataset.styleId = subType;
        } else {
          //      collapse selection based on position
          if (options.position === 'above') {
            EditorSelectionUtils.collapseToStart();
          } else {
            EditorSelectionUtils.collapseToEnd();
          }

          //      fix selection
          EditorSelectionUtils.fixCollapsedTextSelection();

          // check subType
          if (!this.dataManager.styles.getDocumentStyleFromId(options.ls)) {
            options.ls = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
          }

          // build caption
          const captionBlock = DOMElementFactory.buildCaptionBlock(options);

          range = EditorSelectionUtils.getRange();

          //      split and insert element after before
          this._splitContentAndInsertNode(actionContext, captionBlock);
        }
      }

      _handleInsertCaptionOnBlockElement(actionContext, baseNode, options = {}) {
        let blockNode = baseNode;

        if (
          blockNode.parentNode !== this.page &&
          blockNode.parentNode.tagName !== ELEMENTS.TableCellElement.TAG
        ) {
          blockNode = EditorDOMUtils.findFirstLevelChildNode(this.page, baseNode);
        }

        // check subType
        if (!this.dataManager.styles.getDocumentStyleFromId(options.ls)) {
          options.ls = ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH;
        }

        // build caption
        const captionBlock = DOMElementFactory.buildCaptionBlock(options);

        //      collapse selection based on position
        if (options.position === 'above') {
          this._insertNewNodeBefore(actionContext, blockNode.parentNode, captionBlock, blockNode);
        } else {
          this._insertNewNodeAfter(actionContext, blockNode.parentNode, captionBlock, blockNode);
        }
      }
    },
);
