import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import FormattedDate from '_common/components/FormattedDate/FormattedDate';

import styles from './Deadline.module.scss';

export type DeadlineProps = {
  due: ISODate;
  in24Hours?: boolean;
  objectType: 'document' | 'task' | 'folder' | 'file' | 'dopdf';
};

const Deadline = ({ due, objectType, in24Hours = false }: DeadlineProps) => {
  const intl = useIntl();

  const getContent = () => {
    if ((due && objectType) || in24Hours) {
      let icon: 'Calendar' | 'CalendarWarning' = 'Calendar';
      let content: string | null = null;
      let relativeTimeLocale: ReactNode = '';

      if (in24Hours) {
        icon = 'CalendarWarning';
        relativeTimeLocale = intl.formatMessage({ id: 'IN_24_HOURS' });
      } else {
        relativeTimeLocale = intl.formatMessage(
          { id: 'ON_DATE' },
          { date: <FormattedDate date={due} /> },
        );
      }

      content = `THE_${objectType?.toUpperCase()}_IS_DUE_IN_X_Y`;

      if (content) {
        return (
          <div className={styles.root}>
            <div className={styles.content}>
              <Icon size={24} icon={icon} margin="0 1rem 0 0" />
              <FormattedMessage
                id={content}
                values={{
                  time: relativeTimeLocale,
                  b: (chunks: ReactNode | string) => <span className={styles.bold}>{chunks}</span>,
                }}
              />
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return <div>{getContent()}</div>;
};
export default Deadline;
