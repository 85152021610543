import { ELEMENTS } from 'Editor/services/consts';

// DEFAULT ELEMENTS

const ALLOWED_DEFAULT_ATTRIBUTES = {
  'paragraph-element': [
    'sct',
    'section',
    'st',
    'task',
    'data-style-id',
    'cp_list_id',
    'cp_list_level',
    'cp_list_style',
    'data-left-indentation',
    'data-right-indentation',
    'data-special-indent',
    'data-special-indent-value',
    'data-alignment',
    'data-line-height',
    'data-space-before',
    'data-space-after',
    'data-font-family',
    'data-font-size',
    'data-color',
    'data-bold',
    'data-italic',
    'data-underline',
    'data-strikethrough',
    'data-superscript',
    'data-subscript',
    'data-paragraph-color',
    'data-temp-style-id',
    'data-temp-cross-reference-id',
    'data-vanish',
  ],
  br: [],
  span: ['element_reference', 'class'],
  div: [],
  a: ['href', 'style'],
};

// TABLE ELEMENTS

const ALLOWED_TABLES_ATTRIBUTES = {
  [ELEMENTS.TableElement.TAG.toLowerCase()]: [
    'style',
    'sct',
    'task',
    'is',
    'data-width',
    'data-alignment',
    'cp_cell_borders',
    'cp_cell_paddings',
  ],
  style: [],
  [ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG.toLowerCase()]: ['style', 'is'],
  [ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG.toLowerCase()]: [
    /* 'style', */
    'is',
  ],
  [ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG.toLowerCase()]: [
    'style',
    'colspan',
    'rowspan',
    'is',
    'data-vanish',
    'data-rcs',
    'data-hr',
  ],
  [ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG.toLowerCase()]: ['style'],
  [ELEMENTS.TableCellElement.TAG.toLowerCase()]: [
    'style',
    'colspan',
    'rowspan',
    'head-id',
    'head-id-reference',
    'is',
    'data-alignment',
    'data-width',
  ],
};

const ALLOWED_TABLES_STYLES_ATTRIBUTES = {
  [ELEMENTS.TableElement.TAG.toLowerCase()]: {
    width: [/.*/m],
  },
  [ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER.TAG.toLowerCase()]: {
    visibility: [/.*/m],
  },
  [ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG.toLowerCase()]: {},
  [ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG.toLowerCase()]: {
    height: [/.*/m],
  },
  [ELEMENTS.TableElement.ELEMENTS.TABLE_HEADER_CELL.TAG.toLowerCase()]: {
    width: [/.*/m],
  },
  [ELEMENTS.TableCellElement.TAG.toLowerCase()]: {
    // width: [/.*/m],
    border: [/.*/m],
    padding: [/.*/m],
    'border-color': [/.*/m],
    'border-style': [/.*/m],
    'border-width': [/.*/m],
    'border-bottom': [/.*/m],
    'border-bottom-color': [/.*/m],
    'border-bottom-style': [/.*/m],
    'border-bottom-width': [/.*/m],
    'border-left': [/.*/m],
    'border-left-color': [/.*/m],
    'border-left-style': [/.*/m],
    'border-left-width': [/.*/m],
    'border-right': [/.*/m],
    'border-right-color': [/.*/m],
    'border-right-style': [/.*/m],
    'border-right-width': [/.*/m],
    'border-top': [/.*/m],
    'border-top-color': [/.*/m],
    'border-top-style': [/.*/m],
    'border-top-width': [/.*/m],
    'padding-bottom': [/.*/m],
    'padding-left': [/.*/m],
    'padding-right': [/.*/m],
    'padding-top': [/.*/m],
    display: [/.*/m],
    'background-color': [/.*/m],
    background: [/.*/m],
  },
};

// FIGURE ELEMENTS

const ALLOWED_FIGURES_ATTRIBUTES = {
  [ELEMENTS.FigureElement.TAG.toLowerCase()]: [
    'style',
    'hRelative',
    'vRelative',
    'task',
    'data-vanish',
    'data-alignment',
  ],
  img: ['src', 'style', 'class', 'width', 'height', 'placeholder'],
  figcaption: ['title', 'style'],
  'image-element': [
    'uploading',
    'upload_id',
    'localfile',
    'data-w',
    'data-h',
    'data-bd',
    'data-es',
    'data-f',
    'data-lc',
    'data-l',
    'data-os',
    'data-sr',
    'data-ao',
    'data-ax',
    'data-ay',
    'data-ox',
    'data-oy',
    'data-rfx',
    'data-rfy',
    'data-wr',
  ],
};

const ALLOWED_FIGURES_STYLES_ATTRIBUTES = {
  figcaption: {},
  [ELEMENTS.FigureElement.TAG.toLowerCase()]: {
    width: [/.*/m],
    height: [/.*/m],
    'margin-left': [/.*/m],
    'margin-right': [/.*/m],
    position: [/.*/m],
  },
  img: {
    width: [/.*/m],
    height: [/.*/m],
  },
  'image-element': {
    width: [/.*/m],
    height: [/.*/m],
  },
};

// CUSTOM ELEMENTS

const ALLOWED_CUSTOM_ATTRIBUTES = {
  'redacted-element': ['enclosed_element'],
  'readonly-element': ['enclosed_element'],
  'approved-element': ['enclosed_element'],
  'comment-element': ['class', 'element_reference', 'author'],
  'temp-comment-element': ['class', 'element_reference', 'author'],
  'page-break-element': ['data-vanish'],
  'section-break-element': ['sct', 'n_sct', 'data-vanish'],
  'column-break-element': [],
  'table-of-contents-element': ['data-vanish'],
  'keywords-element': ['data-vanish'],
  'authors-element': ['data-vanish'],
  'list-of-figures-element': ['data-vanish'],
  'list-of-tables-element': ['data-vanish'],
  'format-element': [
    'class',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'superscript',
    'subscript',
    'fontfamily',
    'fontsize',
    'color',
    'highlightcolor',
    'vanish',
  ],
  'citation-element': ['element_reference'],
  'citations-group-element': [],
  'symbol-element': ['fontfamily', 'content', 'fonthex'],
  'note-element': ['element_reference', 'type'],
  'cross-reference-element': ['target', 'lct', 'trg'],
  'equation-element': [], // TODO: copy paste?
  'field-element': [], // TODO: copy paste?
  [ELEMENTS.TabElement.TAG.toLocaleLowerCase()]: [], // TODO: copy paste?
  [ELEMENTS.ReferencesSectionElement.TAG.toLocaleLowerCase()]: [],
  [ELEMENTS.PlaceholderElement.TAG.toLocaleLowerCase()]: [],
};

// TRACKED ELEMENTS

const ALLOWED_TRACKED_ATTRIBUTES = {
  'track-ins-element': [
    'element_reference',
    'hide',
    'color',
    'backgroundcolor',
    'author',
    'replacewith',
    'merge',
    'breakline',
    'replacewithsibling',
    'sct',
    'n_sct',
    'user',
  ],
  'track-del-element': [
    'element_reference',
    'hide',
    'color',
    'backgroundcolor',
    'author',
    'replacewith',
    'merge',
    'replacewithsibling',
    'sct',
    'user',
  ],
};

const EVALUATE = {
  'format-element': {
    attributes: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'superscript',
      'subscript',
      'fontfamily',
      'fontsize',
      'color',
      'highlightcolor',
    ],
  },
  [ELEMENTS.FigureElement.TAG.toLowerCase()]: {
    attributes: ['style'],
  },
  img: {
    attributes: ['style'],
  },
  'paragraph-element': {
    attributes: ['style'],
  },
  [ELEMENTS.TableElement.TAG.toLowerCase()]: {
    attributes: ['style'],
  },
  [ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG.toLowerCase()]: {
    attributes: ['style'],
  },
  [ELEMENTS.TableCellElement.TAG.toLowerCase()]: {
    attributes: ['style'],
  },
  [ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG.toLowerCase()]: {
    attributes: ['style'],
  },
  li: {
    attributes: ['style'],
  },
};

const ALLOWED_ATTRIBUTES = {
  ...ALLOWED_DEFAULT_ATTRIBUTES,
  // ...ALLOWED_HEADERS_ATTRIBUTES,
  ...ALLOWED_TABLES_ATTRIBUTES,
  ...ALLOWED_FIGURES_ATTRIBUTES,
  ...ALLOWED_CUSTOM_ATTRIBUTES,
  ...ALLOWED_TRACKED_ATTRIBUTES,
};

const ALLOWED_STYLES = {
  ...ALLOWED_TABLES_STYLES_ATTRIBUTES,
  ...ALLOWED_FIGURES_STYLES_ATTRIBUTES,
};

class DOMSanitizer {
  static allowedAttributes() {
    return ALLOWED_ATTRIBUTES;
  }

  static allowedAttributesForTag(tag) {
    return ALLOWED_ATTRIBUTES[tag.toLowerCase()];
  }

  static allowedStylePropertiesForTag(tag) {
    return ALLOWED_STYLES[tag.toLowerCase()];
  }

  static evaluateMutation(mutation) {
    const allowedTypes = ['characterData', 'childList'];
    if (allowedTypes.includes(mutation.type)) {
      return true;
    }
    if (mutation.type === 'attributes') {
      const tagName = mutation.target.tagName.toLowerCase();
      const allowed =
        EVALUATE[tagName] &&
        EVALUATE[tagName].attributes &&
        EVALUATE[tagName].attributes.includes(mutation.attributeName);
      if (!allowed) {
        // console.log(mutation);
      }
      return allowed;
    }
    return false;
  }
}

export default DOMSanitizer;
