import { FormattedMessage } from 'react-intl';
import { navigateToMyFiles } from 'router/history';
import { Button } from 'dodoc-design-system';
import { useSelector } from '_common/hooks/redux';
import LogoContainer from '_common/components/LogoContainer/LogoContainer';
import { selectIsIEnvision } from 'App/redux/appSlice';
import AuthFooter from 'Auth/components/AuthFooter/AuthFooter';
import styles from './Error404.module.scss';

const Error404 = () => {
  const information = useSelector((state) => state.app.information);
  const isiEnvision = useSelector(selectIsIEnvision);
  const navigateToIEnvision = () => {
    if (information.extra) {
      window.location.href = `${information.extra.source}`;
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.logoContainer}>
        <LogoContainer size="small" color="white" expanded testId="error404-logo" />
      </div>
      <div className={styles.errorCode}>404</div>
      <div className={styles.title}>
        <FormattedMessage id="ERROR_404_TITLE" />
      </div>
      <div className={styles.description}>
        <FormattedMessage id="ERROR_404_DESCRIPTION" />
      </div>
      <Button
        size="medium"
        onClick={() => {
          isiEnvision ? navigateToIEnvision() : navigateToMyFiles();
        }}
        testId={`${isiEnvision ? 'back-to-ienvision' : 'go-back-to-explorer'}-button`}
      >
        <FormattedMessage id={isiEnvision ? 'BACK_TO_IENVISION' : 'GO_BACK_EXPLORER'} />
      </Button>
      <div className={styles.footer}>
        <AuthFooter white />
      </div>
    </div>
  );
};

export default Error404;
