import { FormattedMessage } from 'react-intl';

import { PanelContent, PanelHeading } from '_common/suite/components';
import BlockApprovalPanel from './BlockApprovalPanel/BlockApprovalPanel';

const ApproveContentTab = () => {
  return (
    <>
      <PanelHeading>
        <FormattedMessage id="editor.menu.review.approveContent" />
      </PanelHeading>
      <PanelContent testId="approve-content-panel">
        <BlockApprovalPanel />
      </PanelContent>
    </>
  );
};

export default ApproveContentTab;
