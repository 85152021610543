import { Select } from 'dodoc-design-system';
import { updateZoomValue } from 'Editor/redux/EditorStatusSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '_common/hooks';

import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

const defaultValues = [
  { value: '50', label: '50%' },
  { value: '100', label: '100%' },
  { value: '150', label: '150%' },
  { value: '200', label: '200%' },
];

const ZoomSelect = () => {
  const dispatch = useDispatch();
  const zoomValue = useSelector((state) => state.editor.status.zoom);
  const [selectZoomValue, setSelectZoomValue] = useState<SelectOption>();
  const [newValue, setNewValue] = useState('');
  const handleZoomValue = (values: SelectOption) => {
    const value = Number(values.value);
    dispatch(updateZoomValue(value));
  };

  useEffect(() => {
    const convertedValue = (zoomValue * 100).toFixed(0);
    setSelectZoomValue({ value: `${convertedValue}`, label: `${convertedValue}%` });
  }, [zoomValue]);

  return (
    <Select.Ghost
      size="medium"
      clearable={false}
      width="10rem"
      customStyles={{
        menu: (provided) => ({
          ...provided,
          right: 0,
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 1052,
        }),
      }}
      menuWidth="18rem"
      options={defaultValues}
      menuPosition="fixed"
      menuPlacement="top"
      value={
        defaultValues.find((defaultValue) => defaultValue.value === selectZoomValue?.value) ||
        selectZoomValue
      }
      onChange={handleZoomValue}
      creatable
      onInputChange={(e: string) => {
        setNewValue(e);
      }}
      inputValue={newValue}
      formatCreateLabel={(label) => label.endsWith('%') ? label : `${label}%`}
      testId='zoom'
      filterOption={() => true}
    />
  );
};

export default ZoomSelect;
