import { useEffect, useMemo } from 'react';

import TextBody from './TextBody/TextBody';
import ShapeDataProvider from './ShapeData';
import ShapeBackground from './ShapeBackground';
import ShapeForeignObject from './ShapeForeignObject';
import { useSlideData } from '../SlideData';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const CommonShape = ({ shape }: ShapeProps) => {
  const { addUnsupportedElement } = useSlideData();

  useEffect(() => {
    if (shape.useBgFill) {
      addUnsupportedElement('Shape - Shape Background Fill');
    }
  }, [shape]);

  const hasText = useMemo(
    () =>
      (shape.text?.childNodes?.reduce((totalPChildren, p) => {
        if (p.childNodes?.length) {
          return totalPChildren + p.childNodes.length;
        }

        return totalPChildren;
      }, 0) ?? 0) > 0,
    [shape.text],
  );

  return (
    <ShapeDataProvider shape={shape}>
      <ShapeBackground />
      {shape.text && hasText && (
        <ShapeForeignObject>
          <TextBody text={shape.text} />
        </ShapeForeignObject>
      )}
    </ShapeDataProvider>
  );
};

export default CommonShape;
