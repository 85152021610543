import { useDispatch, useSelector } from '_common/hooks';
import { merge } from 'lodash';
import { MAX_HSL_VALUES } from 'Presentation/consts';
import { usePresentationData } from 'Presentation/PresentationData';
import { addUnsupportedElement } from 'Presentation/PresentationSlice';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { setSlideFont } from 'Presentation/PresentationSlice';
import { useSlideLayout, useSlideMaster, useSlideSync, useTheme } from 'Presentation/SyncStore';
import { cloneObject } from 'Presentation/utils';
import { createContext, memo, ReactNode, useCallback, useContext, useMemo } from 'react';

type ThumbnailProps = { isThumbnailSlide?: boolean; thumbnailRect?: DOMRect };

type SlideContextValue =
  | {
      slide: Presentation.Data.Slide;
      slideLayout: Presentation.Data.SlideLayout;
      slideMaster: Presentation.Data.SlideMaster;
      theme: Presentation.Data.Theme;
      color: (color: Presentation.Data.Common.Color) => string;
      getFontFamily: ({
        font,
      }: {
        font: Presentation.Data.FontStyleRef | string | undefined;
      }) => string | undefined;
      getDefaultFontFamily: ({ shape }: { shape: Presentation.Data.Shape }) => string | undefined;
      addUnsupportedElement: (element: string) => void;
      slideId: string;
      slideScale: number;
    } & ThumbnailProps;

const SlideContext = createContext<SlideContextValue | undefined>(undefined);

type SlideDataProviderProps = {
  children: ReactNode;
  slideId: string;
} & ThumbnailProps;

const SlideDataProvider = ({
  children,
  slideId,
  isThumbnailSlide,
  thumbnailRect,
}: SlideDataProviderProps) => {
  const dispatch = useDispatch();
  const manager = usePresentationManager();
  const { theme: defaultTheme, structure, size } = usePresentationData();
  const slide = useSlideSync(slideId);
  const slideLayout = useSlideLayout(slide?.sldLayout);
  const slideMaster = useSlideMaster(slideLayout?.sldMaster);
  const theme = useTheme(slideMaster?.theme) ?? defaultTheme;

  const zoom = useSelector((state) => state.presentation.general.zoom);

  const slideScale = useMemo(() => {
    if (!isThumbnailSlide) {
      return zoom;
    }

    const thumbnailWidth = thumbnailRect?.width ?? 0;
    const originalWidth = size.width;

    const thumbnailScale = (originalWidth - thumbnailWidth) / originalWidth;

    return 1 - thumbnailScale;
  }, [zoom, isThumbnailSlide, thumbnailRect]);

  const colorMap = useMemo(() => {
    if (slide?.clrMap) {
      return slide.clrMap;
    }
    if (slideLayout?.clrMap) {
      return slideLayout.clrMap;
    }
    if (slideMaster) {
      return slideMaster.clrMap;
    }
    return {};
  }, [slide, slideLayout, slideMaster]);

  const color = useCallback(
    (color: Presentation.Data.Common.Color) => {
      if ('base' in color) {
        return `#${applyColorModifications(`#${color.base}`, color.mods)}`;
      }
      if ('reference' in color) {
        const mappedColor = colorMap[color.reference] ?? color.reference;
        if (theme.colorScheme[mappedColor]) {
          return `#${applyColorModifications(
            `#${theme.colorScheme[mappedColor].base}`,
            color.mods,
          )}`;
        }
      }

      return '#000000';
    },
    [theme, colorMap],
  );

  const getFontFamily = useCallback(
    ({ font }: { font: Presentation.Data.FontStyleRef | string | undefined }) => {
      let fontFamily: string | undefined = undefined;
      if (typeof font === 'string') {
        switch (font) {
          case '+mn-cs':
          case '+mn-ea':
          case '+mn-lt': {
            fontFamily = theme.fontScheme.minorFont.latin.font;
            break;
          }
          case '+mj-cs':
          case '+mj-ea':
          case '+mj-lt': {
            fontFamily = theme.fontScheme.majorFont.latin.font;
            break;
          }
          default: {
            fontFamily = font;
            break;
          }
        }
      } else {
        switch (font?.reference) {
          case 'major': {
            fontFamily = theme.fontScheme.majorFont.latin.font;
            break;
          }
          case 'minor': {
            fontFamily = theme.fontScheme?.minorFont.latin.font;
            break;
          }
        }
      }

      if (fontFamily) {
        /**
         * Validation to check if the font is supported
         */
        manager.getFontFamilyHelper()?.validateFontFamily(fontFamily);
        dispatch(setSlideFont({ slideId, font: fontFamily }));
      }

      return fontFamily;
    },
    [theme],
  );

  const getDefaultFontFamily = useCallback(
    ({ shape }: { shape: Presentation.Data.Shape }) => {
      const shapeListStyleFont = shape?.text?.listStyle?.['1']?.inlineProperties?.latin?.font;
      const slideMasterTitleFont =
        slideMaster?.txStyles?.title?.['1']?.inlineProperties?.latin?.font;
      const slideMasterBodyFont = slideMaster?.txStyles?.body?.['1']?.inlineProperties?.latin?.font;
      let fontFamily: string | undefined = undefined;

      //Check for shape style font
      if (shape.style?.fontRef) {
        fontFamily = getFontFamily({ font: shape.style?.fontRef });
      }
      //Check for shape list style font
      else if (
        shapeListStyleFont &&
        shapeListStyleFont !== '+mj-lt' &&
        shapeListStyleFont !== '+mn-lt'
      ) {
        fontFamily = shapeListStyleFont;
      }
      //Check for master slide or theme font
      else {
        let fontSource: string | undefined = undefined;
        switch (shape.nvProperties?.ph?.type) {
          case 'title':
          case 'ctrTitle': {
            fontSource = slideMasterTitleFont;
            break;
          }
          default: {
            fontSource = slideMasterBodyFont;
            break;
          }
        }
        fontFamily = getFontFamily({ font: fontSource });
      }

      return fontFamily ?? `Inter, "Open Sans", Helvetica, Arial, sans-serif`;
    },
    [theme, slideMaster],
  );

  const mergeListStyle = useCallback(
    (destination: Parameters<typeof merge>[0], source: Parameters<typeof merge>[1]) => {
      const mergedDestination = cloneObject(destination);

      Object.typedKeys(source).forEach((styleIdx) => {
        if (!mergedDestination[styleIdx]) {
          mergedDestination[styleIdx] = {};
        }

        merge(mergedDestination[styleIdx], source[styleIdx]);

        //#region Make a shallow copy of the following props instead of a deep copy
        if (source[styleIdx]?.bullet) {
          mergedDestination[styleIdx].bullet = {
            ...destination[styleIdx]?.bullet,
            ...source[styleIdx].bullet,
          };
        }

        if (source[styleIdx]?.inlineProperties) {
          mergedDestination[styleIdx].inlineProperties = {
            ...destination[styleIdx]?.inlineProperties,
            ...source[styleIdx].inlineProperties,
          };
        }
        //#endregion

        //#region Mutally exclusive properties

        /**
         * Some properties are mutually exclusive, so we have to make sure that only one of them is defined
         * If both are defined, we have to remove one of them.
         * The property from the highest level in the hierarchy ("destiation" variable) is removed
         * So we only inheirt the property from the "source" variable (lower level) if it is defined
         */

        if (source[styleIdx].lnSpcPct != null && mergedDestination[styleIdx].lnSpcAbs != null) {
          mergedDestination[styleIdx].lnSpcAbs = undefined;
        } else if (
          source[styleIdx].lnSpcAbs != null &&
          mergedDestination[styleIdx].lnSpcPct != null
        ) {
          mergedDestination[styleIdx].lnSpcPct = undefined;
        }

        if (source[styleIdx].spcBefPct != null && mergedDestination[styleIdx].spcBefAbs != null) {
          mergedDestination[styleIdx].spcBefAbs = undefined;
        } else if (
          source[styleIdx].spcBefAbs != null &&
          mergedDestination[styleIdx].spcBefPct != null
        ) {
          mergedDestination[styleIdx].spcBefPct = undefined;
        }

        if (source[styleIdx].spcAftfPct != null && mergedDestination[styleIdx].spcAftfAbs != null) {
          mergedDestination[styleIdx].spcAftfAbs = undefined;
        } else if (
          source[styleIdx].spcAftfAbs != null &&
          mergedDestination[styleIdx].spcAftfPct != null
        ) {
          mergedDestination[styleIdx].spcAftfPct = undefined;
        }
        //#endregion
      });

      return mergedDestination;
    },
    [],
  );
  const mergeBodyProperties = useCallback(
    (destination: Parameters<typeof merge>[0], source: Parameters<typeof merge>[1]) => {
      return { ...destination, ...source };
    },
    [],
  );

  /**
   * This function is used to inherit properties from parent shapes to child shapes.
   * It takes an object with a single property 'shape' which is of type Presentation.Data.Shape.
   * It returns a shape of type Presentation.Data.Shape with inherited properties
   * The properties are inherited from the slide layout, slide master and ppt structure.
   *
   * @param {Presentation.Data.Shape} shape - The shape from which properties are to be inherited.
   * @returns {Presentation.Data.Shape} - The shape with inherited properties.
   */
  const inheritProperties = useCallback(
    ({ shape }: { shape: Presentation.Data.Shape }): Presentation.Data.Shape => {
      if (!slideLayout || !slideMaster) {
        return shape;
      }

      if (shape.type === 'group_shape') {
        return {
          ...shape,
          shapes: shape.shapes.map((shape) => inheritProperties({ shape })),
        };
      }

      let computedShape: Presentation.Data.Shape = shape;
      let properties: Presentation.Data.ShapeProperties = {};
      let listStyle: Presentation.Data.ListStyles = merge({}, structure.txStyles);

      let bodyPr: Presentation.Data.TextBodyProperties = {};
      const ph = shape.nvProperties?.ph;

      if (slideMaster.txStyles) {
        let styles = undefined;
        switch (ph?.type) {
          case 'title':
          case 'hdr':
          case 'ctrTitle':
            // case 'subTitle':
            styles = slideMaster.txStyles.title;
            break;
          case 'body':
          case 'subTitle':
            // case 'chart':
            // case 'tbl':
            // case 'pic':
            // case 'media':
            // case 'dt':
            // case 'ftr':
            // case 'hdr':
            styles = slideMaster.txStyles.body;
            break;
          case 'clipArt':
          case 'dgm':
          case 'obj':
          case 'sldImg':
          case 'sldNum':
          case 'chart':
          case 'tbl':
          case 'pic':
          case 'media':
          case 'dt':
          case 'ftr':
            styles = slideMaster.txStyles.other;
            break;
          case undefined:
            if (ph) {
              switch (computedShape.type) {
                case 'shape': {
                  styles = slideMaster.txStyles.body;
                  break;
                }
                default: {
                  break;
                }
              }
            }
        }

        if (styles) {
          listStyle = mergeListStyle(cloneObject(listStyle), styles);
        }
      }

      if (ph) {
        let masterShape: Presentation.Data.Shape | undefined = undefined;
        let layoutShape: Presentation.Data.Shape | undefined = undefined;

        if (ph.type && ph.idx) {
          masterShape = slideMaster.spTree.shapes.find(
            (shape) =>
              shape.nvProperties?.ph?.type === ph.type && shape.nvProperties?.ph?.idx === ph.idx,
          );
          layoutShape = slideLayout.spTree.shapes.find(
            (shape) =>
              shape.nvProperties?.ph?.type === ph.type && shape.nvProperties?.ph?.idx === ph.idx,
          );

          if (!masterShape) {
            /* This seems to work for some situations, like when type="sldNum" or "ftr".
             * But not sure if its correct, i think it should only inherit properties from the same idx (?)
             */
            masterShape = slideMaster.spTree.shapes.find(
              (shape) => shape.nvProperties?.ph?.type === ph.type,
            );

            if (!masterShape) {
              masterShape = slideMaster.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.idx === ph.idx,
              );
            }
          }

          if (!layoutShape) {
            /* This seems to work for some situations, like when type="sldNum" or "ftr" and some other situations.
             * But not sure if its correct, i think it should only inherit properties from the same idx (?)
             */
            layoutShape = slideLayout.spTree.shapes.find(
              (shape) => shape.nvProperties?.ph?.type === ph.type,
            );
            if (!layoutShape) {
              layoutShape = slideLayout.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.idx === ph.idx,
              );
            }
          }
        } else if (ph.idx) {
          /**
           * Not sure if correct, but,
           * I believe if only "idx" is defined, and the shape type is 'table', then it shouldn't inherit from layout nor master
           * However its weird, because why would this kind of shape have the "idx" property defined then?
           * I'm assuming this because of the following situation:
           * File: AdVance_ID Week Oral_Final; slide 16:
           *  - If the table inherits the list styles from its slide layout shape, the text will be wrongly formatted
           */
          if (computedShape.type !== 'table') {
            masterShape = slideMaster.spTree.shapes.find(
              (shape) => shape.nvProperties?.ph?.idx === ph.idx,
            );
            layoutShape = slideLayout.spTree.shapes.find(
              (shape) => shape.nvProperties?.ph?.idx === ph.idx,
            );
          }
        } else if (ph.type) {
          masterShape = slideMaster.spTree.shapes.find(
            (shape) => shape.nvProperties?.ph?.type === ph.type,
          );
          layoutShape = slideLayout.spTree.shapes.find(
            (shape) => shape.nvProperties?.ph?.type === ph.type,
          );
        }

        if (masterShape) {
          /*
           * Not sure if correct, but
           * I think if only idx is present, it shouldnt inherit list styles nor bodyPr from master (?)
           * Situations:
           * File: Template9+-+Notes+&+Comments.pptx; slide 12:
           *
           *  - There is shape that has only idx="2", and if it inherits properties from slide master, it will be inheriting from a shape that has idx="2",
           *    but the shape type is "dt" (datetime placeholder) and the shape content its not datetime.
           *    If the shape inherits the properties from this slide master shape, it will be wrongly rendered because it will have the property anchor="ctr", and it shouldnt.
           *
           *  - There is shape that has only idx="4", and if it inherits list style from slide master, it will be inheriting from a shape that has idx="2",
           *    but the shape type is "sldNum" (slide number placeholder) and the shape content its not a slide number.
           *    If the shape inherits these properties from this slide master shape, it will be wrongly rendered because it will have the property algn="r", and it shouldnt.
           *
           * So, if the shape ph.type its defined, then it should inherit list style and bodyPr from master
           */
          if ((ph.idx && ph.type) || !ph.idx) {
            listStyle = mergeListStyle(cloneObject(listStyle), masterShape.text?.listStyle);
            bodyPr = mergeBodyProperties(cloneObject(bodyPr), masterShape.text?.bodyPr);
          }

          /**
           * Im not sure if the rule above also applies for the master shape properties
           * So far no problem has been found with this
           * But i believe the shape has to inherit the master shape properties
           * Situation:
           * File: Underlines.pptx; slide 2:
           *  - If the shapes dont inherit these properties from the master shape
           *  they wont have any property defined for the xfrm and they wont be rendered
           */
          properties = merge(cloneObject(properties), masterShape.properties);
        }

        if (layoutShape) {
          properties = merge(cloneObject(properties), layoutShape.properties);
          listStyle = mergeListStyle(cloneObject(listStyle), layoutShape.text?.listStyle);
          bodyPr = mergeBodyProperties(cloneObject(bodyPr), layoutShape.text?.bodyPr);
        }
      }
      properties = merge(cloneObject(properties), shape.properties);
      listStyle = merge(cloneObject(listStyle), shape.text?.listStyle);
      bodyPr = merge(cloneObject(bodyPr), shape.text?.bodyPr);

      if (computedShape.type === 'table') {
        computedShape = {
          ...computedShape,
          tbl: {
            ...computedShape.tbl,
            tr: computedShape.tbl.tr.map((tr) => {
              return {
                ...tr,
                cells: tr.cells.map((cell) => {
                  return { ...cell, text: { ...cell.text, listStyle, bodyPr } };
                }),
              };
            }),
          },
        };
      }

      return {
        ...computedShape,
        properties,
        text: computedShape.text ? { ...computedShape.text, listStyle, bodyPr } : undefined,
      };
    },
    [slideLayout, slideMaster, structure],
  );

  const shapes = useMemo<
    | {
        slide: Presentation.Data.Shape[];
        slideMaster: Presentation.Data.Shape[];
        slideLayout: Presentation.Data.Shape[];
      }
    | []
  >(() => {
    if (slide && slideLayout && slideMaster) {
      const showMasterSp = slideLayout.showMasterSp;
      return {
        /**
         * Id can be repeated between slides shapes, slideMaster shapes and layout shapes.
         * Identifying origin to provide context
         */
        slide: slide.spTree.shapes.map((slideShape) => ({
          ...inheritProperties({ shape: slideShape }),
          origin: 'slide',
        })),
        /**
         * Id can be repeated between slides shapes, slideMaster shapes and layout shapes.
         * Identifying origin to provide context
         */
        slideMaster: slideMaster.spTree.shapes
          .filter(
            (slideShape) =>
              !slideShape.nvProperties?.ph?.type &&
              slideShape.nvProperties?.userDrawn &&
              !slideShape.nvProperties?.cNvPr?.hidden &&
              (showMasterSp ||
                (showMasterSp === undefined && slideLayout.userDrawn) ||
                showMasterSp === undefined),
          )
          .map((slideShape) => ({ ...slideShape, origin: 'master' })),
        /**
         * Id can be repeated between slides shapes, slideMaster shapes and layout shapes.
         * Identifying origin to provide context
         */
        slideLayout: slideLayout.spTree.shapes
          .filter(
            (slideShape) =>
              !slideShape.nvProperties?.ph?.type &&
              !slideShape.nvProperties?.ph?.idx &&
              !slideShape.nvProperties?.cNvPr?.hidden,
          )
          .map((slideShape) => ({ ...slideShape, origin: 'layout' })),
      };
    }
    return [];
  }, [slideId, slide, slideLayout, slideMaster, structure]);

  const handleAddUnsupportedElement: SlideContextValue['addUnsupportedElement'] = (element) => {
    if (!isThumbnailSlide) {
      dispatch(addUnsupportedElement(element));
    }
  };

  if (slide && slideLayout && slideMaster) {
    return (
      <SlideContext.Provider
        value={{
          slide: {
            ...slide,
            spTree: {
              ...slide.spTree,
              //@ts-expect-error
              shapes: [...shapes.slideMaster, ...shapes.slideLayout, ...shapes.slide],
            },
          },
          slideLayout,
          slideMaster,
          theme,
          color,
          getFontFamily,
          getDefaultFontFamily,
          addUnsupportedElement: handleAddUnsupportedElement,
          isThumbnailSlide,
          slideId,
          thumbnailRect,
          slideScale,
        }}
      >
        {children}
      </SlideContext.Provider>
    );
  }
  return null;
};

export const useSlideData = () => {
  const context = useContext(SlideContext);
  if (context === undefined) {
    throw new Error('useSlideData can only be used in a SlideData');
  }
  return context;
};

const applyColorModifications = (
  color: string,
  modifications: Presentation.Data.Common.Color['mods'],
) =>
  (
    modifications?.reduce((modifiedColor, modification) => {
      const type = modification.type;
      const value = modification.val;

      switch (type) {
        case 'lumOff':
          modifiedColor = applyOff(modifiedColor, value, 'l');
          break;
        case 'lumMod':
          modifiedColor = applyMod(modifiedColor, value, 'l');
          break;
        case 'satOff':
          modifiedColor = applyOff(modifiedColor, value, 's');
          break;
        case 'satMod':
          modifiedColor = applyMod(modifiedColor, value, 's');
          break;
        case 'hueOff':
          modifiedColor = applyOff(modifiedColor, value, 'h');
          break;
        case 'hueMod':
          modifiedColor = applyMod(modifiedColor, value, 'h');
          break;
        case 'shade':
          modifiedColor = applyTintOrShade(modifiedColor, 'shade', value);
          break;
        case 'tint':
          modifiedColor = applyTintOrShade(modifiedColor, 'tint', value);
          break;
        case 'alpha':
          modifiedColor = applyAlpha(modifiedColor, value);
          break;
        case 'alphaOff':
          modifiedColor = applyAlphaOffset(modifiedColor, value);
          break;
        default:
          console.error('Invalid color modification type:', type, value);
          break;
      }

      return modifiedColor;
    }, color) ?? color
  ).replace('#', '');

function applyOff(color: string, offset: number, value: keyof HSL) {
  // // console.log('Applying offset to', value, color, offset);
  const hexColor = color.replace('#', '');
  const hsl = hexToHsl(hexColor);

  const modifiedValue = Math.max(0, Math.min(MAX_HSL_VALUES[value], hsl[value] + offset));
  // // console.log('result', { ...hsl, [value]: modifiedValue });
  return hslToHex({ ...hsl, [value]: modifiedValue });
}

function applyMod(color: string, factor: number, value: keyof HSL) {
  // // console.log('Applying mod to', value, color, factor);
  const hexColor = color.replace('#', '');
  const hsl = hexToHsl(hexColor);

  const modifiedValue = Math.max(
    0,
    Math.min(MAX_HSL_VALUES[value], Math.round(hsl[value] * factor * 0.01)),
  );

  // // console.log('result', { ...hsl, [value]: modifiedValue });
  return hslToHex({ ...hsl, [value]: modifiedValue });
}

// function applyShade(color: string, value: number) {
//   const hexColor = color.replace('#', '');
//   const hsl = hexToHsl(hexColor);

//   const modifiedLightness = Math.max(
//     0,
//     Math.min(MAX_HSL_VALUES.l, Math.round(hsl.l - hsl.l * value * 0.01)),
//   );

// //   // console.log('result', { ...hsl, [value]: modifiedValue });
//   return hslToHex({ ...hsl, l: modifiedLightness });
// }

// function applyTint(color: string, value: number) {
//   const hexColor = color.replace('#', '');
//   const hsl = hexToHsl(hexColor);

//   const modifiedLightness = Math.max(
//     0,
//     Math.min(MAX_HSL_VALUES.l, Math.round(hsl.l + hsl.l * value * 0.01)),
//   );

// //   // console.log('result', { ...hsl, [value]: modifiedValue });
//   return hslToHex({ ...hsl, l: modifiedLightness });
// }

/**
 * PRESENTATION:LIMITATION
 * This algorithm has limitations because we dont know how powerpoint calculates exactly the tint and shade
 * In some situations the colors are not the same as powerpoint, but they should be similar
 *
 * A change to tint calculation was made because of the following bugs:
 *  - https://envisionpharma.atlassian.net/browse/DDC-11735
 *  - https://envisionpharma.atlassian.net/browse/DDC-11631
 * If any other change has to be made, make sure to test the powerpoint slides:
 * - Render Text.pptx - slide 24
 * - Render Tables.pptx - slide 7, and all other slides
 * If you dont know where these files are, ask someone from QA
 */
function applyTintOrShade(color: string, type: 'tint' | 'shade', value: number) {
  const hexColor = color.replace('#', '');
  const hsl = hexToHsl(hexColor);

  // if (type === 'tint') {
  //   // Increasing lightness to create a tint
  //   hsl.l = Math.min(100, hsl.l + hsl.l * (validValue / 100));
  // } else if (type === 'shade') {
  //   // Decreasing lightness to create a shade
  //   hsl.l = Math.max(0, hsl.l - hsl.l * (validValue / 100));
  // }

  if (type === 'tint') {
    hsl.l = hsl.l * (value / 100) + (100 - value);
  } else {
    hsl.l -= value * 0.1;
  }

  return hslToHex(hsl);
}

function applyAlpha(color: string, value: number) {
  // Ensure alpha is within the valid range
  const clampedAlpha = Math.max(0, Math.min(100, value));

  // Convert alpha to a value between 0 and 255
  const alphaValue = Math.round((clampedAlpha / 100) * 255);

  // Convert alpha value to hex format
  const hexValue = alphaValue.toString(16).toUpperCase();

  // Pad the hex value with leading zeros if necessary
  const paddedHexValue = hexValue.length < 2 ? `0${hexValue}` : hexValue;

  // Return the hex value

  return color + paddedHexValue;
}

//TODO:PRESENTATION:COLOR:MODIFIER Check the necessity of all modifiers return the alpha hex values (#RRGGBBAA: Red Green Blue Alpha)
const applyAlphaOffset = (color: string, value: number) => {
  // Skip unnecessary logic since the offset is 0
  if (value === 0) {
    return color;
  }

  const hexColor = color.replace('#', '');
  // Ensure the hexadecimal color has an alpha value (#RRGGBBAA)
  if (hexColor.length < 8) {
    return color;
  }

  // Extract the alpha value from the hexadecimal color (which is the last two characters)
  const alpha = parseInt(hexColor.slice(-2), 16);

  // Transform the alpha value to a percentage to apply the offset and convert it back to decimal format
  const alphaPercent = (alpha / 255) * 100;
  const newAlpha = Math.max(0, Math.min(100, alphaPercent + value));
  const alphaDecimal = Math.round((newAlpha / 100) * 255);

  // Convert the new alpha value to hexadecimal format and return the updated color
  const alphaHex = alphaDecimal.toString(16).padStart(2, '0');
  return hexColor.slice(0, -2) + alphaHex;
};

function hexToHsl(hexColor: string) {
  // Remove the '#' symbol if present
  if (hexColor.startsWith('#')) {
    hexColor = hexColor.slice(1);
  }

  // Convert the hex color to RGB
  const rgb: number[] = hexColor.match(/\w{2}/g)?.map((x) => parseInt(x, 16)) ?? [];
  const [r, g, b] = rgb.map((x) => x / 255);

  // Find the maximum and minimum values for RGB
  const maxVal = Math.max(r, g, b);
  const minVal = Math.min(r, g, b);

  // Calculate the hue
  let h: number;
  if (maxVal === minVal) {
    h = 0; // Hue is undefined for achromatic colors
  } else if (maxVal === r) {
    h = ((60 * (g - b)) / (maxVal - minVal) + 360) % 360;
  } else if (maxVal === g) {
    h = ((60 * (b - r)) / (maxVal - minVal) + 120) % 360;
  } else {
    h = ((60 * (r - g)) / (maxVal - minVal) + 240) % 360;
  }

  // Calculate the lightness
  const l = (maxVal + minVal) / 2;

  // Calculate the saturation
  let s: number;
  if (maxVal === minVal) {
    s = 0; // Saturation is 0 for achromatic colors
  } else if (l <= 0.5) {
    s = (maxVal - minVal) / (2 * l);
  } else {
    s = (maxVal - minVal) / (2 - 2 * l);
  }

  // Return the HSL values
  return { h: Math.round(h), s: Math.round(s * 100), l: Math.round(l * 100) };
}

function hslToHex({ h, s, l }: HSL) {
  // Ensure hue is within 0-360 range
  h = (h + 360) % 360;

  // Ensure saturation and lightness are within 0-100 range
  s = Math.max(0, Math.min(100, s));
  l = Math.max(0, Math.min(100, l));

  // Convert saturation and lightness to values between 0 and 1
  s /= 100;
  l /= 100;

  // Calculate RGB values
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
  } else if (120 <= h && h < 180) {
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    b = x;
  }

  // Convert RGB values to 8-bit integers
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Convert RGB to hexadecimal string
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  return `${hexR}${hexG}${hexB}`;
}

export default memo(SlideDataProvider);
