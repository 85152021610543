import { Command } from '../Command';
import { UpdateTableSizeOperation } from '../../Operations/TableOperations';
import { ErrorElementNotEditable } from '../../Errors';

export class UpdateTableSizeCommand extends Command {
  tableId: string;
  height: number;
  width: Editor.Data.Node.TableWidth;

  constructor(
    context: Editor.Edition.Context,
    tableId: string,
    height: number,
    width: Editor.Data.Node.TableWidth,
  ) {
    super(context);

    this.tableId = tableId;
    this.height = height;
    this.width = width;
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }
    if (
      !(
        this.actionContext.baseModel.id === this.tableId ||
        this.actionContext.baseModel.getChildInfoById(this.tableId)
      )
    ) {
      throw new Error('Invalid data');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    const operation = this.applyOperations(this.actionContext.baseModel);
    operation.apply();

    // create patch
    this.createPatch();
  }

  private applyOperations(baseModel: Editor.Data.Node.Model) {
    return new UpdateTableSizeOperation(baseModel, this.tableId, this.height, this.width);
  }
}
