import getConfig from 'dodoc.config';

let store: Store;
export const injectStoreForLocalStorage = (_store: Store) => {
  store = _store;
};

const DEFAULT_LIST_PARAMS: Request.FilterParams & Request.OrderParams = {
  filter_fields: [],
  filter_values: [],
  order_field: '_id',
  order_type: 'desc',
};

class LocalStorage {
  private static get(key: string) {
    return window.localStorage.getItem(key);
  }
  private static set(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  static getListParams(identity: Table.Identity): Request.FilterParams & Request.OrderParams {
    const userId = store.getState().auth.userId;
    if (userId) {
      const storageKey = `${userId}-table-${identity}-params`;
      const params = LocalStorage.get(storageKey);

      if (params) {
        return JSON.parse(params);
      }
    }
    return { ...DEFAULT_LIST_PARAMS };
  }

  static setListParams(
    identity: Table.Identity,
    params: Request.FilterParams & Request.OrderParams,
  ) {
    const userId = store.getState().auth.userId;
    if (userId) {
      const storageKey = `${userId}-table-${identity}-params`;
      LocalStorage.set(storageKey, JSON.stringify(params));
    }
  }

  static setTenant(tenant: string) {
    LocalStorage.set('doDOC-Tenant', tenant);
  }

  static getTenant() {
    const storedTenant = LocalStorage.get('doDOC-Tenant');
    return storedTenant || getConfig().tenant || window.location.hostname;
  }
}

export default LocalStorage;
