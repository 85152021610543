import { InteractionController, IntlErrorBoundary } from '_common/components';
import Note from './Note';
import styles from './Note.module.scss';
import NoteDataProvider from './NoteData';
import { MouseEventHandler, useEffect, useMemo, useRef } from 'react';
import { useSelector } from '_common/hooks';

const MIN_HEIGHT = 56;

const NoteBoundary = () => {
  const presentationId = useSelector((state) => state.presentation.general.presentationId);
  const userId = useSelector((state) => state.auth.userId);

  const localNotesHeight = useMemo(() => {
    return window.localStorage.getItem(`${userId}-${presentationId}-notes-height`);
  }, []);

  const initialHeight = useMemo(() => {
    const localHeightPct = localNotesHeight != null ? +localNotesHeight : null;
    const localHeight =
      localHeightPct != null && !isNaN(localHeightPct)
        ? (localHeightPct / 100) * window.innerHeight
        : null;
    const maxHeight = window.innerHeight * 0.35;

    return localHeight != null
      ? Math.max(MIN_HEIGHT, Math.min(localHeight, maxHeight))
      : MIN_HEIGHT;
  }, [localNotesHeight]);

  const resizingRef = useRef(false);
  const resizerRef = useRef<HTMLDivElement>(null);
  const startYRef = useRef(0);
  const heightRef = useRef(initialHeight);

  useEffect(() => {
    const handleResize = () => {
      const heightPercentage = window.localStorage.getItem(
        `${userId}-${presentationId}-notes-height`,
      );
      if (heightPercentage) {
        const newHeight = (parseFloat(heightPercentage) / 100) * window.innerHeight;
        const clampedHeight = Math.max(MIN_HEIGHT, Math.min(newHeight, window.innerHeight * 0.35));
        heightRef.current = clampedHeight;
        resizerRef.current?.parentElement?.style.setProperty('height', `${clampedHeight}px`);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [userId, presentationId]);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMouseDown: MouseEventHandler = (e) => {
    if (e.target === resizerRef.current) {
      e.preventDefault();
      resizingRef.current = true;
      startYRef.current = e.clientY;
      document.body.style.setProperty('user-select', 'none');
    }
  };

  const handleMouseUp = () => {
    resizingRef.current = false;
    document.body.style.setProperty('user-select', null);
    const heightPercentage = (heightRef.current / window.innerHeight) * 100;
    window.localStorage.setItem(`${userId}-${presentationId}-notes-height`, `${heightPercentage}`);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (resizingRef.current && resizerRef.current) {
      requestAnimationFrame(() => {
        const newHeight = heightRef.current - (e.clientY - startYRef.current);

        if (resizerRef.current) {
          if (newHeight > MIN_HEIGHT && newHeight < window.innerHeight * 0.35) {
            heightRef.current = newHeight;
            startYRef.current = e.clientY;
            resizerRef.current.parentElement?.style.setProperty('height', `${newHeight}px`);
          }
        }
      });
    }
  };

  return (
    <InteractionController environment="presentation">
      <div id="notes-panel" className={styles.note} style={{ height: `${heightRef.current}px` }}>
        <div ref={resizerRef} className={styles.resizer} onMouseDown={handleMouseDown} />
        <IntlErrorBoundary size="small" margin="auto 2rem">
          <NoteDataProvider>
            <Note />
          </NoteDataProvider>
        </IntlErrorBoundary>
      </div>
    </InteractionController>
  );
};

export default NoteBoundary;
