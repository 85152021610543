import { useSelector } from '_common/hooks';

import { Panel } from '_common/suite/components';

import FindAndReplaceTab from './FindAndReplaceTab/FindAndReplaceTab';
import ContentPermissionsTab from './ContentPermissionsTab/ContentPermissionsTab';
import ApproveContentTab from './ApproveContentTab/ApproveContentTab';
import ReviewTab from './ReviewTab/ReviewTab';
import TasksTab from './TasksTab/TasksTab';
import ReferencesTab from './ReferencesTab/ReferencesTab';
import NotesTab from './NotesTab/NotesTab';
import NavigationTab from './NavigationTab/NavigationTab';
import CrossReferencesTab from './CrossRefrencesTab/CrossReferencesTab';
import SpellCheckTab from './SpellCheckTab/SpellCheckTab';
import DocumentStylesTab from './DocumentStylesTab/DocumentStylesTab';
import EditorVersionHistory from './VersionsTab/EditorVersionHistory';

const Sidepanel = () => {
  const activeView = useSelector((state) => state.editor.sidebar.view);

  const renderPanel = () => {
    switch (activeView) {
      case 'APPROVE_CONTENT':
        return <ApproveContentTab />;
      case 'CONTENT_PERMISSIONS':
        return <ContentPermissionsTab />;
      case 'REVIEW':
        return <ReviewTab />;
      case 'REFERENCES':
        return <ReferencesTab />;
      case 'NOTES':
        return <NotesTab />;
      case 'CROSS_REFERENCES':
        return <CrossReferencesTab />;
      case 'NAVIGATION':
        return <NavigationTab />;
      case 'VERSIONS':
        return <EditorVersionHistory />;
      case 'SPELL_CHECK':
        return <SpellCheckTab />;
      case 'TASKS':
        return <TasksTab />;
      case 'DOCUMENT_STYLES':
        return <DocumentStylesTab />;
      case 'FIND_AND_REPLACE':
        return <FindAndReplaceTab />;
      default:
        return null;
    }
  };

  return (
    <Panel side="right" width="47rem" key={`sidebar-panel-${activeView}`}>
      {renderPanel()}
    </Panel>
  );
};

export default Sidepanel;
