import { useState, CSSProperties, useMemo, useEffect } from 'react';
import { Toggle } from 'dodoc-design-system';
import { useDayjsWithLocale } from '_common/hooks';
import styles from './WeekSchedule.module.scss';

type WorkingDays = WorkingHours['days'];

export type WeekScheduleProps = {
  workingDays: WorkingDays;
  onChange: (newValue: WorkingDays) => void;
  disabled: boolean;
} & Pick<CSSProperties, 'margin'>;

const WeekSchedule = ({ margin, workingDays, disabled, onChange }: WeekScheduleProps) => {
  const dayjs = useDayjsWithLocale;
  const localeWeekDays = dayjs().weekdaysShort();

  const WEEK_DAYS = useMemo(() => {
    if (localeWeekDays?.length > 0) {
      return {
        sunday: { label: localeWeekDays[0].charAt(0).toLocaleUpperCase() },
        monday: { label: localeWeekDays[1].charAt(0).toLocaleUpperCase() },
        tuesday: { label: localeWeekDays[2].charAt(0).toLocaleUpperCase() },
        wednesday: { label: localeWeekDays[3].charAt(0).toLocaleUpperCase() },
        thursday: { label: localeWeekDays[4].charAt(0).toLocaleUpperCase() },
        friday: { label: localeWeekDays[5].charAt(0).toLocaleUpperCase() },
        saturday: { label: localeWeekDays[6].charAt(0).toLocaleUpperCase() },
      };
    }

    return {};
  }, [localeWeekDays]);

  const [workingDaysState, setWorkingDaysState] = useState<WorkingDays>(workingDays);

  useEffect(() => {
    if (workingDays) {
      setWorkingDaysState(workingDays);
    }
  }, [workingDays]);

  const handleClick = (dayId: keyof typeof WEEK_DAYS) => {
    if (workingDaysState) {
      const newValue = { ...workingDaysState, [dayId]: !workingDaysState[dayId] };

      setWorkingDaysState({ ...newValue });

      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <div className={styles.content} style={{ margin }}>
      {(Object.keys(WEEK_DAYS) as Array<keyof typeof WEEK_DAYS>).map((dayId, index) => {
        return (
          <Toggle
            key={dayId}
            variant="ghost"
            size="large"
            margin={index > 0 ? '0 0 0 0.5rem' : undefined}
            isToggled={workingDaysState?.[dayId]}
            onClick={() => {
              handleClick(dayId);
            }}
            disabled={!!disabled}
            style={{ width: '4rem', display: 'flex', justifyContent: 'center' }}
            testId={`week_day_${dayId}`}
          >
            {WEEK_DAYS[dayId]?.label}
          </Toggle>
        );
      })}
    </div>
  );
};

export default WeekSchedule;
