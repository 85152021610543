import { FormattedMessage } from 'react-intl';

import { Icon } from 'dodoc-design-system';

import SubOptions from 'Editor/modals/ExportIntegrationModal/Menus/Options/SubOptions/SubOptions';
import styles from './Options.module.scss';

import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';
import type { Menu } from 'Editor/modals/ExportIntegrationModal/Menus/MenuTypes';
import { SubOptionsProps } from 'Editor/modals/ExportIntegrationModal/Menus/Options/SubOptions/SubOptions';

export type OptionsProps<T extends ExportIntegrationModal.ExportType> = Menu.BaseProps<T> & {
  elem: ExportIntegrationModal.Element;
  onClick: (elem: ExportIntegrationModal.Element) => void;
} & Pick<SubOptionsProps<T>, 'selectedSubOption' | 'setSelectedSubOption'>;

const Options = <T extends ExportIntegrationModal.ExportType>({
  onClick,
  elem,
  info,
  selectedOption,
  isOptionOpen,
  setOptionState,
  utilsMenusComponent,
  selectedSubOption,
  setSelectedOption,
  setSelectedSubOption,
}: OptionsProps<T>) => {
  const ICONS: Record<ExportIntegrationModal.Option, IconTypes[24]> = {
    allFiles: 'Folder',
    recent: 'VHSidebar',
    favourites: 'Favorite',
  };

  return (
    <>
      <div
        style={{ cursor: info.data.loading ? 'auto' : 'cursor' }}
        className={`${styles.options} ${selectedOption.id === elem.id && styles.selected}`}
        onClick={info.data.loading ? undefined : () => onClick(elem)}
      >
        <div style={{ cursor: info.data.loading ? 'auto' : 'cursor' }} className={styles.arrowIcon}>
          {!utilsMenusComponent.withoutSubOptions ? (
            <div
              onClick={(e) => {
                setOptionState(!isOptionOpen);
                e.stopPropagation();
              }}
            >
              <Icon icon={isOptionOpen ? 'AngleDownGrey' : 'AngleRightGrey'} size={24} />
            </div>
          ) : (
            <Icon icon={ICONS[elem.id]} disabled={info.data.loading} size={24} />
          )}
        </div>
        <div className={`${styles.optionsLabels} ${info.data.loading && styles.loadingLabel}`}>
          {utilsMenusComponent.withoutSubOptions ? (
            elem.name
          ) : (
            <FormattedMessage id={elem.name.toUpperCase()} />
          )}
        </div>
      </div>
      {!utilsMenusComponent.withoutSubOptions && isOptionOpen && (
        <SubOptions
          elem={elem}
          info={info}
          selectedSubOption={selectedSubOption}
          setSelectedSubOption={setSelectedSubOption}
          setSelectedOption={setSelectedOption}
        />
      )}
    </>
  );
};

export default Options;
