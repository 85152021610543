import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { InputField, Select } from 'dodoc-design-system';
import { SectionProperties } from 'Editor/services/Model';
import { RadioButtonsField } from '_common/components';
import { RadioButtonValue } from '../PageSetupModal';

type SectionsProps = {
  applyTo: {
    handleChange: React.Dispatch<React.SetStateAction<RadioButtonValue>>;
    applyToOptions: RadioButtonValue[];
    applyToValue: RadioButtonValue;
  };
  sectionStart: 'np' | 'c' | 'op' | 'ep';
  setSectionStart: React.Dispatch<React.SetStateAction<'np' | 'c' | 'op' | 'ep'>>;
  isDisabled: boolean;
};

const Sections = ({ applyTo, sectionStart, setSectionStart, isDisabled }: SectionsProps) => {
  const intl = useIntl();
  const SECTION_START_LABEL = useMemo(
    () => ({
      [SectionProperties.Start.NEXT_PAGE]: intl.formatMessage({ id: 'EDITOR_NEXT_PAGE' }),
      [SectionProperties.Start.CONTINUOUS]: intl.formatMessage({ id: 'EDITOR_CONTINUOUS' }),
      [SectionProperties.Start.ODD_PAGE]: intl.formatMessage({ id: 'EDITOR_ODD_PAGE' }),
      [SectionProperties.Start.EVEN_PAGE]: intl.formatMessage({ id: 'EDITOR_EVEN_PAGE' }),
    }),
    [],
  );
  const SECTION_START = [
    {
      label: SECTION_START_LABEL[SectionProperties.Start.NEXT_PAGE],
      value: SectionProperties.Start.NEXT_PAGE,
    },
    {
      label: SECTION_START_LABEL[SectionProperties.Start.CONTINUOUS],
      value: SectionProperties.Start.CONTINUOUS,
    },
    {
      label: SECTION_START_LABEL[SectionProperties.Start.ODD_PAGE],
      value: SectionProperties.Start.ODD_PAGE,
    },
    {
      label: SECTION_START_LABEL[SectionProperties.Start.EVEN_PAGE],
      value: SectionProperties.Start.EVEN_PAGE,
    },
  ];

  return (
    <div>
      <InputField
        testId="sections-inputField"
        label={intl.formatMessage({ id: 'SECTION_START' })}
        size="large"
        margin="3rem 0 0 0"
        disabled={isDisabled}
      >
        <Select
          menuPosition="fixed"
          width="100%"
          size="large"
          options={SECTION_START}
          value={{ label: SECTION_START_LABEL[sectionStart], value: sectionStart }}
          onChange={(value) => {
            setSectionStart(value.value);
          }}
          clearable={false}
          testId="section-start"
          disabled={isDisabled}
        />
      </InputField>
      <RadioButtonsField
        options={applyTo.applyToOptions}
        value={applyTo.applyToValue}
        setValue={applyTo.handleChange}
        label="APLLY_TO"
        testId="apply-to"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default Sections;
