import { path } from 'd3';
import { constantToRad, ellipseArcTo } from './utils';

type ActionButtonsProps = {
  size: Presentation.Data.Common.Size;
  type:
    | 'actionButtonBackPrevious'
    | 'actionButtonForwardNext'
    | 'actionButtonBeginning'
    | 'actionButtonEnd'
    | 'actionButtonHome'
    | 'actionButtonInformation'
    | 'actionButtonReturn'
    | 'actionButtonMovie'
    | 'actionButtonDocument'
    | 'actionButtonSound'
    | 'actionButtonHelp'
    | 'actionButtonBlank';
};

const generateActionButtonPath = ({
  size,
  type,
}: ActionButtonsProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side */
  const ss = Math.min(w, h);

  switch (type) {
    case 'actionButtonBackPrevious': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g11, vc);
      dMain.lineTo(g12, g9);
      dMain.lineTo(g12, g10);
      dMain.closePath();

      const dSymbol = path();
      dSymbol.moveTo(g11, vc);
      dSymbol.lineTo(g12, g9);
      dSymbol.lineTo(g12, g10);
      dSymbol.closePath();

      const dOutline = path();
      dOutline.moveTo(g11, vc);
      dOutline.lineTo(g12, g9);
      dOutline.lineTo(g12, g10);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dSymbol.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonForwardNext': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g12, vc);
      dMain.lineTo(g11, g9);
      dMain.lineTo(g11, g10);
      dMain.closePath();

      const dSymbol = path();
      dSymbol.moveTo(g12, vc);
      dSymbol.lineTo(g11, g9);
      dSymbol.lineTo(g11, g10);
      dSymbol.closePath();

      const dOutline = path();
      dOutline.moveTo(g12, vc);
      dOutline.lineTo(g11, g10);
      dOutline.lineTo(g11, g9);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dSymbol.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonBeginning': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;
      const g13 = (ss * 3) / 4;
      const g14 = g13 / 8;
      const g15 = g13 / 4;
      const g16 = g11 + g14;
      const g17 = g11 + g15;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g17, vc);
      dMain.lineTo(g12, g9);
      dMain.lineTo(g12, g10);
      dMain.closePath();
      dMain.moveTo(g16, g9);
      dMain.lineTo(g11, g9);
      dMain.lineTo(g11, g10);
      dMain.lineTo(g16, g10);
      dMain.closePath();

      const dSymbol = path();
      dSymbol.moveTo(g17, vc);
      dSymbol.lineTo(g12, g9);
      dSymbol.lineTo(g12, g10);
      dSymbol.closePath();
      dSymbol.moveTo(g16, g9);
      dSymbol.lineTo(g11, g9);
      dSymbol.lineTo(g11, g10);
      dSymbol.lineTo(g16, g10);
      dSymbol.closePath();

      const dOutline = path();
      dOutline.moveTo(g17, vc);
      dOutline.lineTo(g12, g9);
      dOutline.lineTo(g12, g10);
      dOutline.closePath();
      dOutline.moveTo(g16, g9);
      dOutline.lineTo(g16, g10);
      dOutline.lineTo(g11, g10);
      dOutline.lineTo(g11, g9);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dSymbol.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonEnd': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;
      const g13 = (ss * 3) / 4;
      const g14 = (g13 * 3) / 4;
      const g15 = (g13 * 7) / 8;
      const g16 = g11 + g14;
      const g17 = g11 + g15;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g16, vc);
      dMain.lineTo(g11, g9);
      dMain.lineTo(g11, g10);
      dMain.closePath();
      dMain.moveTo(g17, g9);
      dMain.lineTo(g12, g9);
      dMain.lineTo(g12, g10);
      dMain.lineTo(g17, g10);
      dMain.closePath();

      const dSymbol = path();
      dSymbol.moveTo(g16, vc);
      dSymbol.lineTo(g11, g9);
      dSymbol.lineTo(g11, g10);
      dSymbol.closePath();
      dSymbol.moveTo(g17, g9);
      dSymbol.lineTo(g12, g9);
      dSymbol.lineTo(g12, g10);
      dSymbol.lineTo(g17, g10);
      dSymbol.closePath();

      const dOutline = path();
      dOutline.moveTo(g16, vc);
      dOutline.lineTo(g11, g10);
      dOutline.lineTo(g11, g9);
      dOutline.closePath();
      dOutline.moveTo(g17, g9);
      dOutline.lineTo(g12, g9);
      dOutline.lineTo(g12, g10);
      dOutline.lineTo(g17, g10);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dSymbol.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonHome': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;
      const g13 = (ss * 3) / 4;
      const g14 = g13 / 16;
      const g15 = g13 / 8;
      const g16 = (g13 * 3) / 16;
      const g17 = (g13 * 5) / 16;
      const g18 = (g13 * 7) / 16;
      const g19 = (g13 * 9) / 16;
      const g20 = (g13 * 11) / 16;
      const g21 = (g13 * 3) / 4;
      const g22 = (g13 * 13) / 16;
      const g23 = (g13 * 7) / 8;
      const g24 = g9 + g14;
      const g25 = g9 + g16;
      const g26 = g9 + g17;
      const g27 = g9 + g21;
      const g28 = g11 + g15;
      const g29 = g11 + g18;
      const g30 = g11 + g19;
      const g31 = g11 + g20;
      const g32 = g11 + g22;
      const g33 = g11 + g23;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(hc, g9);
      dMain.lineTo(g11, vc);
      dMain.lineTo(g28, vc);
      dMain.lineTo(g28, g10);
      dMain.lineTo(g33, g10);
      dMain.lineTo(g33, vc);
      dMain.lineTo(g12, vc);
      dMain.lineTo(g32, g26);
      dMain.lineTo(g32, g24);
      dMain.lineTo(g31, g24);
      dMain.lineTo(g31, g25);
      dMain.closePath();

      const dWalls = path();
      dWalls.moveTo(g32, g26);
      dWalls.lineTo(g32, g24);
      dWalls.lineTo(g31, g24);
      dWalls.lineTo(g31, g25);
      dWalls.closePath();
      dWalls.moveTo(g28, vc);
      dWalls.lineTo(g28, g10);
      dWalls.lineTo(g29, g10);
      dWalls.lineTo(g29, g27);
      dWalls.lineTo(g30, g27);
      dWalls.lineTo(g30, g10);
      dWalls.lineTo(g33, g10);
      dWalls.lineTo(g33, vc);
      dWalls.closePath();

      const dDoorAndRoof = path();
      dDoorAndRoof.moveTo(hc, g9);
      dDoorAndRoof.lineTo(g11, vc);
      dDoorAndRoof.lineTo(g12, vc);
      dDoorAndRoof.closePath();
      dDoorAndRoof.moveTo(g29, g27);
      dDoorAndRoof.lineTo(g30, g27);
      dDoorAndRoof.lineTo(g30, g10);
      dDoorAndRoof.lineTo(g29, g10);
      dDoorAndRoof.closePath();

      const dOutline = path();
      dOutline.moveTo(hc, g9);
      dOutline.lineTo(g31, g25);
      dOutline.lineTo(g31, g24);
      dOutline.lineTo(g32, g24);
      dOutline.lineTo(g32, g26);
      dOutline.lineTo(g12, vc);
      dOutline.lineTo(g33, vc);
      dOutline.lineTo(g33, g10);
      dOutline.lineTo(g28, g10);
      dOutline.lineTo(g28, vc);
      dOutline.lineTo(g11, vc);
      dOutline.closePath();
      dOutline.moveTo(g31, g25);
      dOutline.lineTo(g32, g26);
      dOutline.moveTo(g33, vc);
      dOutline.lineTo(g28, vc);
      dOutline.moveTo(g29, g10);
      dOutline.lineTo(g29, g27);
      dOutline.lineTo(g30, g27);
      dOutline.lineTo(g30, g10);
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dWalls.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dDoorAndRoof.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonInformation': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g11 = hc - dx2;
      const g13 = (ss * 3) / 4;
      const g14 = g13 / 32;
      const g17 = (g13 * 5) / 16;
      const g18 = (g13 * 3) / 8;
      const g19 = (g13 * 13) / 32;
      const g20 = (g13 * 19) / 32;
      const g22 = (g13 * 11) / 16;
      const g23 = (g13 * 13) / 16;
      const g24 = (g13 * 7) / 8;
      const g25 = g9 + g14;
      const g28 = g9 + g17;
      const g29 = g9 + g18;
      const g30 = g9 + g23;
      const g31 = g9 + g24;
      const g32 = g11 + g17;
      const g34 = g11 + g19;
      const g35 = g11 + g20;
      const g37 = g11 + g22;
      const g38 = (g13 * 3) / 32;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(hc, g9);
      const mainCircleArc = ellipseArcTo(dMain, dx2, dx2, '3cd4', 360, hc, g9);
      dMain.closePath();

      const dMainCircle = path();
      dMainCircle.moveTo(hc, g9);
      mainCircleArc.redraw(dMainCircle);
      dMainCircle.closePath();
      dMainCircle.moveTo(hc, g25);
      const letterCircleTopArc = ellipseArcTo(dMainCircle, g38, g38, '3cd4', 360, hc, g25);
      dMainCircle.moveTo(g32, g28);
      dMainCircle.lineTo(g32, g29);
      dMainCircle.lineTo(g34, g29);
      dMainCircle.lineTo(g34, g30);
      dMainCircle.lineTo(g32, g30);
      dMainCircle.lineTo(g32, g31);
      dMainCircle.lineTo(g37, g31);
      dMainCircle.lineTo(g37, g30);
      dMainCircle.lineTo(g35, g30);
      dMainCircle.lineTo(g35, g28);
      dMainCircle.closePath();

      const dLetter = path();
      dLetter.moveTo(hc, g25);
      letterCircleTopArc.redraw(dLetter);
      dLetter.moveTo(g32, g28);
      dLetter.lineTo(g35, g28);
      dLetter.lineTo(g35, g30);
      dLetter.lineTo(g37, g30);
      dLetter.lineTo(g37, g31);
      dLetter.lineTo(g32, g31);
      dLetter.lineTo(g32, g30);
      dLetter.lineTo(g34, g30);
      dLetter.lineTo(g34, g29);
      dLetter.lineTo(g32, g29);
      dLetter.closePath();

      const dOutline = path();
      dOutline.moveTo(hc, g9);
      mainCircleArc.redraw(dOutline);
      dOutline.closePath();
      dOutline.moveTo(hc, g25);
      letterCircleTopArc.redraw(dOutline);
      dOutline.moveTo(g32, g28);
      dOutline.lineTo(g35, g28);
      dOutline.lineTo(g35, g30);
      dOutline.lineTo(g37, g30);
      dOutline.lineTo(g37, g31);
      dOutline.lineTo(g32, g31);
      dOutline.lineTo(g32, g30);
      dOutline.lineTo(g34, g30);
      dOutline.lineTo(g34, g29);
      dOutline.lineTo(g32, g29);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dMainCircle.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dLetter.toString(),
            stroke: 'false',
            fillModifier: 'lighten',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonReturn': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;
      const g13 = (ss * 3) / 4;
      const g14 = (g13 * 7) / 8;
      const g15 = (g13 * 3) / 4;
      const g16 = (g13 * 5) / 8;
      const g17 = (g13 * 3) / 8;
      const g18 = g13 / 4;
      const g19 = g9 + g15;
      const g20 = g9 + g16;
      const g21 = g9 + g18;
      const g22 = g11 + g14;
      const g23 = g11 + g15;
      const g24 = g11 + g16;
      const g25 = g11 + g17;
      const g26 = g11 + g18;
      const g27 = g13 / 8;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g12, g21);
      dMain.lineTo(g23, g9);
      dMain.lineTo(hc, g21);
      dMain.lineTo(g24, g21);
      dMain.lineTo(g24, g20);
      ellipseArcTo(dMain, g27, g27, 0, 'cd4', g24, g20);
      dMain.lineTo(g25, g19);
      ellipseArcTo(dMain, g27, g27, 'cd4', 'cd4', g25, g19);
      dMain.lineTo(g26, g21);
      dMain.lineTo(g11, g21);
      dMain.lineTo(g11, g20);
      ellipseArcTo(dMain, g17, g17, 'cd2', constantToRad(-5400000), g11, g20);
      dMain.lineTo(hc, g10);
      ellipseArcTo(dMain, g17, g17, 'cd4', constantToRad(-5400000), hc, g10);
      dMain.lineTo(g22, g21);
      dMain.closePath();

      const dArrow = path();
      dArrow.moveTo(g12, g21);
      dArrow.lineTo(g23, g9);
      dArrow.lineTo(hc, g21);
      dArrow.lineTo(g24, g21);
      dArrow.lineTo(g24, g20);
      ellipseArcTo(dArrow, g27, g27, 0, 'cd4', g24, g20);
      dArrow.lineTo(g25, g19);
      ellipseArcTo(dArrow, g27, g27, 'cd4', 'cd4', g25, g19);
      dArrow.lineTo(g26, g21);
      dArrow.lineTo(g11, g21);
      dArrow.lineTo(g11, g20);
      ellipseArcTo(dArrow, g17, g17, 'cd2', constantToRad(-5400000), g11, g20);
      dArrow.lineTo(hc, g10);
      ellipseArcTo(dArrow, g17, g17, 'cd4', constantToRad(-5400000), hc, g10);
      dArrow.lineTo(g22, g21);
      dArrow.closePath();

      const dOutline = path();
      dOutline.moveTo(g12, g21);
      dOutline.lineTo(g22, g21);
      dOutline.lineTo(g22, g20);
      ellipseArcTo(dOutline, g17, g17, 0, 'cd4', g22, g20);
      dOutline.lineTo(g25, g10);
      ellipseArcTo(dOutline, g17, g17, 'cd4', 'cd4', g25, g10);
      dOutline.lineTo(g11, g21);
      dOutline.lineTo(g26, g21);
      dOutline.lineTo(g26, g20);
      ellipseArcTo(dOutline, g27, g27, 'cd2', constantToRad(-5400000), g26, g20);
      dOutline.lineTo(hc, g19);
      ellipseArcTo(dOutline, g27, g27, 'cd4', constantToRad(-5400000), hc, g19);
      dOutline.lineTo(g24, g21);
      dOutline.lineTo(hc, g21);
      dOutline.lineTo(g23, g9);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dArrow.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonMovie': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;
      const g13 = (ss * 3) / 4;
      const g14 = (g13 * 1455) / 21600;
      const g15 = (g13 * 1905) / 21600;
      const g16 = (g13 * 2325) / 21600;
      const g17 = (g13 * 16155) / 21600;
      const g18 = (g13 * 17010) / 21600;
      const g19 = (g13 * 19335) / 21600;
      const g20 = (g13 * 19725) / 21600;
      const g21 = (g13 * 20595) / 21600;
      const g22 = (g13 * 5280) / 21600;
      const g23 = (g13 * 5730) / 21600;
      const g24 = (g13 * 6630) / 21600;
      const g25 = (g13 * 7492) / 21600;
      const g26 = (g13 * 9067) / 21600;
      const g27 = (g13 * 9555) / 21600;
      const g28 = (g13 * 13342) / 21600;
      const g29 = (g13 * 14580) / 21600;
      const g30 = (g13 * 15592) / 21600;
      const g31 = g11 + g14;
      const g32 = g11 + g15;
      const g33 = g11 + g16;
      const g34 = g11 + g17;
      const g35 = g11 + g18;
      const g36 = g11 + g19;
      const g37 = g11 + g20;
      const g38 = g11 + g21;
      const g39 = g9 + g22;
      const g40 = g9 + g23;
      const g41 = g9 + g24;
      const g42 = g9 + g25;
      const g43 = g9 + g26;
      const g44 = g9 + g27;
      const g45 = g9 + g28;
      const g46 = g9 + g29;
      const g47 = g9 + g30;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g11, g39);
      dMain.lineTo(g11, g44);
      dMain.lineTo(g31, g44);

      dMain.lineTo(g32, g43);
      dMain.lineTo(g33, g43);
      dMain.lineTo(g33, g47);
      dMain.lineTo(g35, g47);
      dMain.lineTo(g35, g45);
      dMain.lineTo(g36, g45);
      dMain.lineTo(g38, g46);
      dMain.lineTo(g12, g46);
      dMain.lineTo(g12, g41);
      dMain.lineTo(g38, g41);
      dMain.lineTo(g37, g42);
      dMain.lineTo(g35, g42);
      dMain.lineTo(g35, g41);
      dMain.lineTo(g34, g40);
      dMain.lineTo(g32, g40);
      dMain.lineTo(g31, g39);
      dMain.closePath();

      const dCamera = path();
      dCamera.moveTo(g11, g39);
      dCamera.lineTo(g11, g44);
      dCamera.lineTo(g31, g44);
      dCamera.lineTo(g32, g43);
      dCamera.lineTo(g33, g43);
      dCamera.lineTo(g33, g47);
      dCamera.lineTo(g35, g47);
      dCamera.lineTo(g35, g45);
      dCamera.lineTo(g36, g45);
      dCamera.lineTo(g38, g46);
      dCamera.lineTo(g12, g46);
      dCamera.lineTo(g12, g41);
      dCamera.lineTo(g38, g41);
      dCamera.lineTo(g37, g42);
      dCamera.lineTo(g35, g42);
      dCamera.lineTo(g35, g41);
      dCamera.lineTo(g34, g40);
      dCamera.lineTo(g32, g40);
      dCamera.lineTo(g31, g39);
      dCamera.closePath();

      const dOutline = path();
      dOutline.moveTo(g11, g39);
      dOutline.lineTo(g31, g39);
      dOutline.lineTo(g32, g40);
      dOutline.lineTo(g34, g40);
      dOutline.lineTo(g35, g41);
      dOutline.lineTo(g35, g42);
      dOutline.lineTo(g37, g42);
      dOutline.lineTo(g38, g41);
      dOutline.lineTo(g12, g41);
      dOutline.lineTo(g12, g46);
      dOutline.lineTo(g38, g46);
      dOutline.lineTo(g36, g45);
      dOutline.lineTo(g35, g45);
      dOutline.lineTo(g35, g47);
      dOutline.lineTo(g33, g47);
      dOutline.lineTo(g33, g43);
      dOutline.lineTo(g32, g43);
      dOutline.lineTo(g31, g44);
      dOutline.lineTo(g11, g44);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dCamera.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonDocument': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const dx1 = (ss * 9) / 32;
      const g11 = hc - dx1;
      const g12 = hc + dx1;
      const g13 = (ss * 3) / 16;
      const g14 = g12 - g13;
      const g15 = g9 + g13;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g11, g9);
      dMain.lineTo(g14, g9);
      dMain.lineTo(g12, g15);
      dMain.lineTo(g12, g10);
      dMain.lineTo(g11, g10);
      dMain.closePath();

      const dPage = path();
      dPage.moveTo(g11, g9);
      dPage.lineTo(g14, g9);
      dPage.lineTo(g14, g15);
      dPage.lineTo(g12, g15);
      dPage.lineTo(g12, g10);
      dPage.lineTo(g11, g10);
      dPage.closePath();

      const dPageCorner = path();
      dPageCorner.moveTo(g14, g9);
      dPageCorner.lineTo(g14, g15);
      dPageCorner.lineTo(g12, g15);
      dPageCorner.closePath();

      const dOutline = path();
      dOutline.moveTo(g11, g9);
      dOutline.lineTo(g14, g9);
      dOutline.lineTo(g12, g15);
      dOutline.lineTo(g12, g10);
      dOutline.lineTo(g11, g10);
      dOutline.closePath();
      dOutline.moveTo(g12, g15);
      dOutline.lineTo(g14, g15);
      dOutline.lineTo(g14, g9);
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dPage.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dPageCorner.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonSound': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g10 = vc + dx2;
      const g11 = hc - dx2;
      const g12 = hc + dx2;
      const g13 = (ss * 3) / 4;
      const g14 = g13 / 8;
      const g15 = (g13 * 5) / 16;
      const g16 = (g13 * 5) / 8;
      const g17 = (g13 * 11) / 16;
      const g18 = (g13 * 3) / 4;
      const g19 = (g13 * 7) / 8;
      const g20 = g9 + g14;
      const g21 = g9 + g15;
      const g22 = g9 + g17;
      const g23 = g9 + g19;
      const g24 = g11 + g15;
      const g25 = g11 + g16;
      const g26 = g11 + g18;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g11, g21);
      dMain.lineTo(g11, g22);
      dMain.lineTo(g24, g22);
      dMain.lineTo(g25, g10);
      dMain.lineTo(g25, g9);
      dMain.lineTo(g24, g21);
      dMain.closePath();

      const dSpeaker = path();
      dSpeaker.moveTo(g11, g21);
      dSpeaker.lineTo(g11, g22);
      dSpeaker.lineTo(g24, g22);
      dSpeaker.lineTo(g25, g10);
      dSpeaker.lineTo(g25, g9);
      dSpeaker.lineTo(g24, g21);
      dSpeaker.closePath();

      const dOutline = path();
      dOutline.moveTo(g11, g21);
      dOutline.lineTo(g24, g21);
      dOutline.lineTo(g25, g9);
      dOutline.lineTo(g25, g10);
      dOutline.lineTo(g24, g22);
      dOutline.lineTo(g11, g22);
      dOutline.closePath();
      dOutline.moveTo(g26, g21);
      dOutline.lineTo(g12, g20);
      dOutline.moveTo(g26, vc);
      dOutline.lineTo(g12, vc);
      dOutline.moveTo(g26, g22);
      dOutline.lineTo(g12, g23);
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dSpeaker.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonHelp': {
      const dx2 = (ss * 3) / 8;
      const g9 = vc - dx2;
      const g11 = hc - dx2;
      const g13 = (ss * 3) / 4;
      const g14 = g13 / 7;
      const g15 = (g13 * 3) / 14;
      const g16 = (g13 * 2) / 7;
      const g19 = (g13 * 3) / 7;
      const g20 = (g13 * 4) / 7;
      const g21 = (g13 * 17) / 28;
      const g23 = (g13 * 21) / 28;
      const g24 = (g13 * 11) / 14;
      const g27 = g9 + g16;
      const g29 = g9 + g21;
      const g30 = g9 + g23;
      const g31 = g9 + g24;
      const g33 = g11 + g15;
      const g36 = g11 + g19;
      const g37 = g11 + g20;
      const g41 = g13 / 14;
      const g42 = (g13 * 3) / 28;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();
      dMain.moveTo(g33, g27);
      const outerTopArc = ellipseArcTo(dMain, g16, g16, 'cd2', 'cd2', g33, g27);
      const outerRightArc = ellipseArcTo(dMain, g14, g15, 0, 'cd4', outerTopArc.eX, outerTopArc.eY);
      const outerBottomArc = ellipseArcTo(
        dMain,
        g41,
        g42,
        '3cd4',
        constantToRad(-5400000),
        outerRightArc.eX,
        outerRightArc.eY,
      );
      dMain.lineTo(g37, g30);
      dMain.lineTo(g36, g30);
      dMain.lineTo(g36, g29);
      const innerBottomArc = ellipseArcTo(dMain, g14, g15, 'cd2', 'cd4', g36, g29);
      const innerRightArc = ellipseArcTo(
        dMain,
        g41,
        g42,
        'cd4',
        constantToRad(-5400000),
        innerBottomArc.eX,
        innerBottomArc.eY,
      );
      const innerTopArc = ellipseArcTo(
        dMain,
        g14,
        g14,
        0,
        constantToRad(-10800000),
        innerRightArc.eX,
        innerRightArc.eY,
      );
      dMain.closePath();
      dMain.moveTo(hc, g31);
      const symbolCircleArc = ellipseArcTo(dMain, g42, g42, '3cd4', 360, hc, g31);
      dMain.closePath();

      const dQuestionMark = path();
      dQuestionMark.moveTo(g33, g27);
      outerTopArc.redraw(dQuestionMark);
      outerRightArc.redraw(dQuestionMark);
      outerBottomArc.redraw(dQuestionMark);
      dQuestionMark.lineTo(g37, g30);
      dQuestionMark.lineTo(g36, g30);
      dQuestionMark.lineTo(g36, g29);
      innerBottomArc.redraw(dQuestionMark);
      innerRightArc.redraw(dQuestionMark);
      innerTopArc.redraw(dQuestionMark);
      dQuestionMark.closePath();
      dQuestionMark.moveTo(hc, g31);
      symbolCircleArc.redraw(dQuestionMark);
      dQuestionMark.closePath();

      const dOutline = path();
      dOutline.moveTo(g33, g27);
      outerTopArc.redraw(dOutline);
      outerRightArc.redraw(dOutline);
      outerBottomArc.redraw(dOutline);
      dOutline.lineTo(g37, g30);
      dOutline.lineTo(g36, g30);
      dOutline.lineTo(g36, g29);
      innerBottomArc.redraw(dOutline);
      innerRightArc.redraw(dOutline);
      innerTopArc.redraw(dOutline);
      dOutline.closePath();
      dOutline.moveTo(hc, g31);
      symbolCircleArc.redraw(dOutline);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          {
            d: dQuestionMark.toString(),
            stroke: 'false',
            fillModifier: 'darken',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'actionButtonBlank': {
      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b } };
    }
  }
};

export default generateActionButtonPath;
