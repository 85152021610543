import qs from 'qs';
import InstanceService from './InstanceService';

export type PublicLinkData = {
  name: string;
  can_download?: boolean;
  password?: string;
  password_active: boolean;
  expiration: Date | null;
  expiration_active: boolean;
  active: boolean;
};

/**
 * Public Link related requests
 */
export default class PublicLinkService extends InstanceService {
  // -----------------------------------------------------------------
  // public link endpoints
  PUBLICLINK_LIST = (id: ObjectId) => `/api/object/${id}/link/list`;

  PUBLICLINK_CREATE = (id: ObjectId) => `/api/object/${id}/link/create`;

  PUBLICLINK_DELETE = (id: ObjectId, linkId: string) => `/api/object/${id}/link/${linkId}/delete`;

  PUBLICLINK_EDIT = (linkId: string) => this.EDIT('link', linkId);

  PUBLICLINK_GET = (hash: string) => `/api/public/link/${hash}`;

  PUBLICLINK_DOWNLOAD = (hash: string) => `/api/public/link/${hash}/download`;

  /**
   * list public links
   */
  listPublicLinks(objectId: ObjectId, params: Request.AllListParams) {
    return this.get(this.PUBLICLINK_LIST(objectId), { params });
  }

  /**
   * create public links
   */
  createPublicLink(objectId: ObjectId, values: PublicLinkData) {
    return this.post(this.PUBLICLINK_CREATE(objectId), qs.stringify(values));
  }

  /**
   * delete public links
   */
  deletePublicLink(objectId: ObjectId, linkId: string) {
    return this.post(this.PUBLICLINK_DELETE(objectId, linkId));
  }

  /**
   * edit public links
   */
  editPublicLink(linkId: string, values: PublicLinkData) {
    return this.post(this.PUBLICLINK_EDIT(linkId), qs.stringify(values));
  }

  /**
   * get public link
   * no authorization needed
   */
  getPublicLink(hash: string, values: { password?: string }) {
    return this.post(this.PUBLICLINK_GET(hash), qs.stringify(values));
  }

  downloadPublicLinkFile(hash: string, values: { password?: string }) {
    return this.post(this.PUBLICLINK_DOWNLOAD(hash), qs.stringify(values), {
      responseType: 'blob',
    });
  }
}
