import { FormattedMessage, useIntl } from 'react-intl';
import { Divider } from 'dodoc-design-system';

import { navigateToMyFiles } from 'router/history';
import { useDispatch } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';

import styles from './SupportPopover.module.scss';

type SupportPopoverProps = {
  close: () => void;
};

const SupportPopover = ({ close }: SupportPopoverProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [sendEndboardingStatus] = useOnboardingStatusMutation();

  const handleOnboardingReset = () => {
    close();
    sendEndboardingStatus({ target: 'explorer', step: 'beginning' });
    navigateToMyFiles();
  };

  const handleAboutClicked = () => {
    dispatch(openModal('AboutModal'));
  };

  return (
    <div className={styles.popoverContainer}>
      <div className={styles.optionsContainer}>
        <a
          href="https://envisionpharma.service-now.com/dodoc "
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.buttonsContainer}>
            <span className={styles.text}>
              {intl.formatMessage({ id: 'editor.menu.help.helpCenter' })}
            </span>
          </div>
        </a>
        <div
          className={styles.buttonsContainer}
          onClick={() => {
            close();
            dispatch(openModal('SupportFormModal'));
          }}
        >
          <span className={styles.text}>
            {intl.formatMessage({ id: 'editor.menu.help.talkWithUs' })}
          </span>
        </div>
        <div className={styles.buttonsContainer} onClick={handleAboutClicked}>
          <span className={styles.text}>
            <FormattedMessage id="ABOUT_DODOC" />
          </span>
        </div>
        <Divider margin="1rem 0" />
        <div
          className={styles.buttonsContainer}
          onClick={handleOnboardingReset}
          data-testid="reset-onboarding"
        >
          <span className={styles.text}>{intl.formatMessage({ id: 'RESET_ONBOARDING' })}</span>
        </div>
        <a
          href="https://envisionpharma.service-now.com/dodoc?id=kb_category&kb_category=18727a1b1b119610784ceb91b24bcbb8 "
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.buttonsContainer}>
            <span className={styles.text}>{intl.formatMessage({ id: 'QUICK_START_GUIDE' })}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default SupportPopover;
