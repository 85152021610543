import { createContext, DependencyList, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LoadingPage from '_common/components/Loading/LoadingPage/LoadingPage';
import { ErrorScreen } from '_common/components/ErrorBoundary/ErrorBoundary';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal, openAndUpdateModal } from '_common/modals/ModalsSlice';

import { setPDFId } from './redux/PDFGeneralSlice';
import { PDFManager } from './services';

const PDFContext = createContext<PDFManager | undefined>(undefined);

const PDFContextProvider = ({
  children,
  initialValue,
}: {
  children: any;
  initialValue?: PDFManager;
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector((state) => state.localStorage.accounts);
  const pdfId = useSelector((state) => state.pdf.general.pdfId);
  const token = accounts[userId]?.token;
  const [pdfManager, setManager] = useState<PDFManager | null>(initialValue || null);
  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && userId && token) {
      dispatch(setPDFId(id));
      let instance = pdfManager || new PDFManager();
      instance.on('STATUS_CHANGED', (error: Error, status: PDF.PDFManagerStatus) => {
        if (error) {
          setLoading(false);
          setManager(null);
          switch (error.message) {
            case 'SOI_CONNECT_ERROR':
              dispatch(
                openAndUpdateModal({
                  modal: 'PDFConfirmationModal',
                  data: {
                    title: 'CONNECTION_ERROR',
                    message: 'CONNECTION_ERROR_MESSAGE',
                    confirmButtonType: 'primary',
                    confirmButtonTextId: 'RECONNECT',
                    actionCode: 'refresh',
                    cancelButtonShow: false,
                    modalWidth: 60,
                    persistent: true,
                  },
                }),
              );
              break;
            case 'DISCONNECTED':
              dispatch(
                openAndUpdateModal({
                  modal: 'PDFConfirmationModal',
                  data: {
                    title: 'DISCONNECTED',
                    message: 'DISCONNECTED_ERROR_MESSAGE',
                    confirmButtonType: 'primary',
                    confirmButtonTextId: 'RECONNECT',
                    actionCode: 'refresh',
                    cancelButtonShow: false,
                    modalWidth: 60,
                    persistent: true,
                  },
                }),
              );
              break;
            default:
              setError(error);
              break;
          }
        } else {
          dispatch(closeAndResetModal('PDFConfirmationModal'));
          setError(null);
          if (status === 'READY') {
            setLoading(false);
            setManager(instance);
          } else if (status === 'DESTROYED' || status === 'DESTROYING') {
            setLoading(false);
            setManager(null);
          }
        }
      });
      instance.connect(id, {
        id: userId,
        token,
      });
    }

    return () => {
      dispatch(setPDFId(null));
      if (pdfManager) {
        pdfManager.destroy();
      }
    };
  }, [id, userId, token]);

  useEffect(() => {
    if (pdfId && pdfManager) {
      pdfManager.initializeShortcutsManager();
    }
    return () => {
      if (pdfManager) {
        pdfManager.destroyShortcutsManager();
      }
    };
  }, [pdfId, pdfManager]);

  return (
    <PDFContext.Provider value={pdfManager!}>
      {error && <ErrorScreen />}
      {!error && pdfManager && children}
      {loading && <LoadingPage />}
    </PDFContext.Provider>
  );
};

export const usePDFContext = () => {
  const context = useContext(PDFContext);
  if (context === undefined) {
    throw new Error('usePDFContext can only be used in a PDFContextProvider');
  }

  return context;
};

export function usePDFDataEvents<
  E extends PDF.PDFDataManagerEventName,
  H extends PDF.PDFDataManagerEvents[E],
>(type: E, listener: H, deps: DependencyList = []) {
  const manager = useContext(PDFContext);
  useEffect(() => {
    manager?.getData()?.events.on(type, listener);
    return () => {
      manager?.getData()?.events.off(type, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, listener, ...deps]);
}

export function usePDFManagerEvents<
  E extends keyof PDF.PDFManagerEvents,
  H extends PDF.PDFManagerEvents[E],
>(type: E, listener: H, deps: DependencyList = []) {
  const manager = useContext(PDFContext);
  useEffect(() => {
    manager?.on(type, listener);
    return () => {
      manager?.off(type, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, listener, ...deps]);
}

export default PDFContextProvider;
