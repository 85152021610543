import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

export type MenuId = 'tenantSettings';

export type Menu = {
  id: MenuId;
  title: TranslationMessage;
  activeOption: string;
  options: {
    [key in string]: {
      id: string;
      label: TranslationMessage;
      expanded?: boolean;
      loading?: boolean;
    };
  };
};

type SliceState = {
  expanded: boolean;
  animated: boolean;
  menu: {
    isOpen: boolean;
    activeMenu: MenuId | undefined;
    menus: {
      [key in MenuId]: Menu;
    };
  };
};

const menus: SliceState['menu']['menus'] = {
  tenantSettings: {
    id: 'tenantSettings',
    title: { id: 'settings.tenant.header' },
    activeOption: 'general',
    options: {
      general: {
        id: 'general',
        label: { id: 'settings.tabs.general' },
      },
      users: {
        id: 'users',
        label: { id: 'settings.permissions.users.title' },
      },
      roles: {
        id: 'roles',
        label: { id: 'global.roles' },
      },
      tags: {
        id: 'tags',
        label: { id: 'settings.general.tags' },
      },
      elementStatus: {
        id: 'elementStatus',
        label: { id: 'ELEMENT_STATUS' },
      },
      templates: {
        id: 'templates',
        label: { id: 'TEMPLATES' },
      },
      referencesStyles: {
        id: 'referencesStyles',
        label: { id: 'REFERENCES_STYLES' },
      },
      affiliations: {
        id: 'affiliations',
        label: { id: 'AFFILIATIONS' },
      },
      metadata: {
        id: 'metadata',
        label: { id: 'METADATA' },
      },
    },
  },
};

const SLICE_NAME = 'SIDEBAR';
const INITIAL_STATE: SliceState = {
  expanded: true,
  animated: false,
  menu: {
    isOpen: false,
    activeMenu: undefined,
    menus,
  },
};

// #region Slice
const SidebarSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    toggleSidebarExpanded: (state) => {
      state.expanded = !state.expanded;
      state.animated = false;
    },
    setSidebarAnimatedState: (state, action: PayloadAction<SliceState['animated']>) => {
      state.animated = action.payload;
    },
    openSidebarMenu: (
      state,
      action: PayloadAction<{ menu: MenuId; activeOption?: Menu['activeOption'] }>,
    ) => {
      const { menu, activeOption } = action.payload;
      state.menu.activeMenu = menu;
      if (activeOption) {
        state.menu.menus[menu].activeOption = activeOption;
      }
      state.menu.isOpen = true;
      state.expanded = false;
      state.animated = true;
    },
    closeSidebarMenu: (state) => {
      state.menu.isOpen = false;
      state.expanded = true;
      state.animated = true;
      state.menu.activeMenu = undefined;
    },
    toggleSidebarMenu: (
      state,
      action: PayloadAction<{ menu?: MenuId; activeOption?: Menu['activeOption'] }>,
    ) => {
      const { menu, activeOption } = action.payload;
      if (state.menu.isOpen) {
        state.menu.isOpen = false;
        state.expanded = true;
      } else {
        if (menu) {
          state.menu.activeMenu = menu;
        }

        if (activeOption) {
          if (state.menu.activeMenu) {
            state.menu.menus[state.menu.activeMenu].activeOption = activeOption;
          }
        }

        state.menu.isOpen = true;
        state.expanded = false;
      }
      state.animated = true;
    },
    setMenuActiveOption: (
      state,
      action: PayloadAction<{ menu?: MenuId; activeOption: Menu['activeOption'] }>,
    ) => {
      const { menu, activeOption } = action.payload;

      if (menu) {
        state.menu.activeMenu = menu;
      }

      if (state.menu.activeMenu) {
        state.menu.menus[state.menu.activeMenu].activeOption = activeOption;
      }
    },
  },
});
const persistConfig = {
  key: 'sidebar',
  storage: storageSession,
  whitelist: ['expanded'],
};

const sidebarReducer = persistReducer(persistConfig, SidebarSlice.reducer);
// #endregion

// #region Selectors
const getMenus = (state: RootState) => state.sidebar.menu.menus;
const getActiveMenu = (state: RootState) => state.sidebar.menu.activeMenu;

export const selectActiveMenu = createSelector([getMenus, getActiveMenu], (menus, activeMenu) => {
  if (activeMenu) {
    return menus[activeMenu];
  }
  return undefined;
});

export const selectActiveMenuOption = createSelector([selectActiveMenu], (activeMenu) => {
  if (activeMenu) {
    return activeMenu.activeOption;
  }
  return undefined;
});

// #endregion

// #region Actions
export const {
  toggleSidebarExpanded,
  openSidebarMenu,
  closeSidebarMenu,
  toggleSidebarMenu,
  setMenuActiveOption,
  setSidebarAnimatedState,
} = SidebarSlice.actions;
// #endregion

export default sidebarReducer;
