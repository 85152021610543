import { ProgressBar } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import styles from './PublishVeevaLoading.module.scss';

const PublishVeevaLoading = () => {
  return (
    <div className={styles.root}>
      <ProgressBar testId="veeva-loading-progressBar" />
      <div className={styles.text}>
        <FormattedMessage id="EXPORTING_DOCUMENT" />
      </div>
    </div>
  );
};

export default PublishVeevaLoading;
