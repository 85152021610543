import { forwardRef, MouseEventHandler, Ref } from 'react';
import { Toggle } from 'dodoc-design-system';

import styles from './ReplyToggle.module.scss';

type ReplyToggleProps = {
  repliesCount: number;
  isToggled?: boolean;
  onToggleReplies: () => void;
  canComment: boolean;
  id: string;
  testId: string;
};

const ReplyToggle = forwardRef(
  (
    { repliesCount, isToggled, onToggleReplies, id, canComment, testId }: ReplyToggleProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const handleToggleReplies: MouseEventHandler<HTMLSpanElement> = (e) => {
      e.stopPropagation();

      onToggleReplies();
    };

    return (
      <div className={styles.replyCounter}>
        <Toggle
          size="medium"
          variant="link"
          isToggled={isToggled}
          disabled={repliesCount < 1 && !canComment}
          icon={isToggled ? 'ReplyBlue' : 'ReplyGrey'}
          onClick={handleToggleReplies}
          testId={`${testId}-reply-toggle`}
          ref={ref}
          id={id}
        />
        {repliesCount > 0 && repliesCount}
      </div>
    );
  },
);

export default ReplyToggle;
