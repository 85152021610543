/**
 * Extend object a with the properties of object b.
 * If there's a conflict, object b takes precedence.
 *
 * @param {object} a
 * @param {object} b
 */
export const extend = (a, b) => {
  // eslint-disable-next-line guard-for-in
  for (const i in b) {
    a[i] = b[i];
  }
  return a;
};

export const diffInOut = (arrayA, arrayB, verifyOrder = false) => {
  const arrayIntersection = arrayA.filter(value => arrayB.includes(value));
  const goingOut = arrayA.filter(x => !arrayIntersection.includes(x));
  const goingIn = arrayB.filter(x => !arrayIntersection.includes(x));
  let changedOrder = false;
  if (verifyOrder && (!goingOut.length || !goingIn.length)) {
    for (let index = 0; index < arrayA.length; index++) {
      if (arrayA[index] !== arrayB[index]) {
        changedOrder = true;
        break;
      }
    }
  }
  return {
    in: goingIn,
    out: goingOut,
    changedOrder,
  };
}
