import { useIntl } from 'react-intl';
import { Divider } from 'dodoc-design-system';
import styles from './NotificationSettingsRow.module.scss';

import type { ReactNode } from 'react';

export type NotificationRowProps = {
  id: string;
  title: string;
  description: string;
  children: ReactNode;
};

const NotificationSettingsRow = ({ id, title, description, children }: NotificationRowProps) => {
  const intl = useIntl();

  return (
    <div className={styles.row} key={id}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>{intl.formatMessage({ id: title })}</div>
          <div className={styles.description}>{intl.formatMessage({ id: description })}</div>
        </div>
        <div className={styles.children}>{children}</div>
      </div>
      <Divider margin="3rem 0 2rem 0" />
    </div>
  );
};

export default NotificationSettingsRow;
