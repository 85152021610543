import { CSSProperties, ReactNode } from 'react';
import Header from './Header/Header';

type SectionProps = {
  title?: string;
  children: ReactNode;
} & Pick<CSSProperties, 'margin'>;

const Section = ({ title, children, margin }: SectionProps) => {
  return (
    <div style={{ margin }}>
      {title ? <Header title={title} /> : null}
      {children}
    </div>
  );
};

export default Section;
