import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';
import styles from './Menu.module.scss';
import { updateModal } from '_common/modals/ModalsSlice';
import { useDispatch } from '_common/hooks';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type OptionProps = {
  option: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  };
  active: boolean;
  setActiveOption: (value: { identity?: string; label?: string; icon: IconTypes['24'] }) => void;
};

type MenuProps = {
  list: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  }[];
  selected: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  };
};

const Option = ({ option, active, setActiveOption }: OptionProps) => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    setActiveOption(option);
    dispatch(
      updateModal({ modal: 'CitationsModal', data: { navigation: { identity: option.identity } } }),
    );
  }, [option]);

  return (
    <div
      className={`${styles.option} ${active && styles.active}`}
      onClick={() => {
        onClick();
      }}
    >
      <Icon icon={option.icon} size={24} />
      <div className={styles.text}>
        <FormattedMessage id={`storage.sidebar.${option.label}`} />
      </div>
    </div>
  );
};

const Menu = ({ list, selected }: MenuProps) => {
  const [activeOption, setActiveOption] = useState(selected);
  return (
    <div>
      {list.map((option: { label?: string; identity?: string; icon: IconTypes['24'] }) => {
        return (
          <Option
            key={option.label}
            active={activeOption.label === option.label}
            option={option}
            setActiveOption={setActiveOption}
          />
        );
      })}
    </div>
  );
};

export default Menu;
