import { getNodeContent } from 'PDF/redux/PDFAnnotationsSlice';
import { useComments } from 'Presentation/SyncStore';
import { useSelector } from '_common/hooks';

const useSearchComments = () => {
  const comments = useComments();
  const searchBy = useSelector((state) => state.presentation.comments.searchBy);
  const state = useSelector((state) => state);

  if (!comments) {
    return [];
  }

  const filterComment = (comment: Presentation.Data.Comment, value: string) => {
    if (comment?.content?.content) {
      const content = comment.content.content
        .map((node) => {
          return getNodeContent(node, state);
        })
        .join(' ');
      return content.toLowerCase().includes(value.toLowerCase());
    }

    return false;
  };

  if (searchBy) {
    return Object.values(comments.data).filter((comment) => filterComment(comment, searchBy));
  }

  return Object.values(comments.data);
};

export default useSearchComments;
