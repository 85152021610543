import { CSSProperties, createContext, useContext, useMemo } from 'react';
import { useShapeData } from '../ShapeData';

type ITextBodyContext = {
  shape: Presentation.Data.Shape;
  text: Presentation.Data.TextBody;
  textStyle: CSSProperties | undefined;
  fontScale: number;
  lnSpcReduction: number;
};

type TextBodyProviderProps = {
  text: Presentation.Data.TextBody;
  textStyle?: CSSProperties;
  children: JSX.Element;
};

const TextBodyContext = createContext<ITextBodyContext | undefined>(undefined);

const TextBodyProvider = ({ text, textStyle, children }: TextBodyProviderProps) => {
  const { shape } = useShapeData();

  /*
   * The fontScale is a percentage value that represents the size of the font in relation to the default size.
   * A value of 100% scales the text to 100%, while a value of 1% scales the text to 1% of the default size.
   * If this attribute is omitted, then a value of 100% is implied.
   *
   */
  const fontScale = useMemo(() => {
    return (
      (text.bodyPr?.txAutoFit?.normalAutoFit?.fontScale ?? text.bodyPr?.fontScale ?? 100) / 100
    );
  }, [text]);

  /*
   * Specifies the percentage amount by which the line spacing of each paragraph in the text body is reduced.
   * This value is a percentage of the default line spacing value.
   * The reduction is applied by subtracting it from the original line spacing value.
   * If this attribute is omitted, then a value of 0% is implied.
   * This attribute applies only to paragraphs with percentage line spacing
   */
  const lnSpcReduction = useMemo(() => {
    return (text.bodyPr?.txAutoFit?.normalAutoFit?.lnSpcReduction ?? 0) / 100;
  }, [text]);

  return (
    <TextBodyContext.Provider value={{ shape, text, textStyle, fontScale, lnSpcReduction }}>
      {children}
    </TextBodyContext.Provider>
  );
};

export const useTextBody = () => {
  const context = useContext(TextBodyContext);
  if (context === undefined) {
    throw new Error('useTextBody must be used within a TextBodyProvider');
  }
  return context;
};

export default TextBodyProvider;
