import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToSettings } from 'router/history';
import { paths } from '_types/api';

import { openAndUpdateModal, openModal } from '_common/modals/ModalsSlice';
import { toggleInfoPanelOpen } from 'Groups/redux/GroupsPageSlice';
import { selectUserIsAdmin } from '_common/services/api/authority';

import { ActionBar } from '_common/components';

import styles from './GroupsPage.module.scss';

const PAGE_IDENTITY = 'groups';

type HeaderProps = {
  permissions?: paths['/api/object/group/list']['get']['responses']['200']['content']['multipart/form-data']['permissions'];
};

const Header = ({ permissions }: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedState = useSelector((state) => state.table.selected);
  const data = useSelector((state) => state.app.data);
  const identity = useSelector((state) => state.table.identity);
  const infoPanelOpen = useSelector((state) => state.groups.infoPanelOpen);
  const appLoading = useSelector((state) => state.app.loading.isOpen);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const handleOnClickGroupSettings = () => {
    selectedState &&
      Object.values(selectedState).length === 1 &&
      navigateToSettings('group', Object.keys(selectedState)[0]);
  };

  const openCreateGroupModal = () => {
    dispatch(openModal('CreateGroupModal'));
  };

  const handleToggleInfoPanel = () => {
    dispatch(toggleInfoPanelOpen());
  };

  const openDeleteGroupModal = () => {
    if (Object.keys(selectedState)[0]) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'DELETE_ITEM_QUANTITY',
            titleValues: { total: 1 },
            message: 'DELETING_THE_SELECTED_ITEM_QUANTITY_WILL_PERMANENTLY_REMOVE_CONFIRM',
            messageValues: { total: 1 },
            confirmButtonTextId: 'groups.deleteGroup',
            confirmButtonType: 'danger',
            cancelButtonTextId: 'global.cancel',
            actionCode: 'deleteGroup',
            actionValue: { groupId: Object.keys(selectedState)[0] },
            headerType: 'error',
            cancelButtonShow: true,
          },
        }),
      );
    }
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'groups',
            name: intl.formatMessage({ id: 'storage.sidebar.groups' }),
          },
        ]}
        pageLoading={appLoading}
      />
      <div className={styles.innerContainer}>
        {(userIsAdmin || permissions?.groups) && (
          <Toggle
            size="medium"
            variant="standard"
            icon="New"
            onClick={openCreateGroupModal}
            testId="groups-page-create-button"
          >
            <FormattedMessage id="global.create" />
          </Toggle>
        )}
        <Toggle
          size="medium"
          variant="standard"
          icon="Delete"
          disabled={
            Object.keys(selectedState).length !== 1 ||
            appLoading ||
            !selectedState ||
            (!Object.keys(selectedState).some((sKey) =>
              data[sKey]?.['user_permissions'].includes('admin'),
            ) &&
              !Object.keys(selectedState).some((sKey) =>
                data[sKey]?.['user_permissions'].includes('owner'),
              ))
          }
          onClick={openDeleteGroupModal}
          style={{ marginLeft: '0.5rem' }}
          testId="groups-page-delete-button"
        >
          <FormattedMessage id="global.delete" />
        </Toggle>

        <Toggle
          size="medium"
          variant="standard"
          icon="Settings"
          disabled={!(selectedState && Object.values(selectedState).length === 1)}
          onClick={handleOnClickGroupSettings}
          style={{ marginLeft: '0.5rem' }}
          testId="groups-page-settings-button"
        >
          <FormattedMessage id="global.settings" />
        </Toggle>
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip
            placement="bottom"
            content={intl.formatMessage({ id: 'global.information' })}
            testId="groups-page-information-tooltip"
          >
            <div style={{ marginLeft: '1rem' }}>
              <Toggle
                size="medium"
                variant="standard"
                icon="Information"
                isToggled={infoPanelOpen}
                onClick={handleToggleInfoPanel}
                disabled={identity[PAGE_IDENTITY]?.list.length === 0}
                testId="groups-page-information-toggle"
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </ActionBar>
  );
};

export default Header;
