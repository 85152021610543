import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { THUMBNAIL_LABEL_GAP, THUMBNAIL_LABEL_HEIGHT, THUMBNAIL_WIDTH } from 'Presentation/consts';
import { PanelHeading } from '_common/suite/components';
import { usePresentationData } from '../PresentationData';
import Thumbnail from './Thumbnail';
import styles from './Navigation.module.scss';
import { DOMUtils } from '_common/utils';

const Navigation = () => {
  const { size, structure } = usePresentationData();
  const itemSize =
    (THUMBNAIL_WIDTH * size.height) / size.width + THUMBNAIL_LABEL_GAP + THUMBNAIL_LABEL_HEIGHT;

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="THUMBNAILS" />
      </PanelHeading>
      <div className={styles.navigation}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={structure.sldIdLst.length}
              itemSize={DOMUtils.convertUnitTo(itemSize, 'pt', 'px')}
              width={width}
            >
              {Thumbnail}
            </List>
          )}
        </AutoSizer>
      </div>
    </>
  );
};

export default memo(Navigation);
