import { ModifiersFactory } from './Visitors/Modifiers';

export class SelectionModifier {
  protected Data: Editor.Data.API;

  constructor(Data: Editor.Data.API) {
    this.Data = Data;
  }

  modify(
    range: Editor.Selection.EditorRange | Editor.Selection.JsonRange,
    type: Editor.Selection.ModifierType,
    granularity: Editor.Selection.ModifierGranularity,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ) {
    range.accept(
      ModifiersFactory.getModifier(this.Data, type, granularity, direction, incrementOffset),
    );
  }
}
