import { useMemo } from 'react';
import { RadioButton } from 'dodoc-design-system';

import styles from './SynonymGroup.module.scss';

type SynonymGroupProps = {
  group: Synonym;
  query: string;
  onChange: (id: string, value: string) => void;
  selectedId: string;
  index: number;
};

const SynonymGroup = ({ group, query, onChange, selectedId, index }: SynonymGroupProps) => {
  const synonyms = useMemo(() => {
    return group.list.synonyms.split('|').map((synonym, i) => {
      const split = synonym.split('(');
      const value = split[0];
      const description = split[1] ? `(${split[1]}` : '';
      const id = index + value + i;
      return {
        id,
        label: `${value} ${description.length > 0 ? description : ''}`,
        value,
      };
    });
  }, []);

  return (
    <div key={group.list.synonyms} className={styles.group}>
      <p>
        {query} {group.list.category}:
      </p>
      <div className={styles.items}>
        {synonyms.map(({ id, value: radioValue, label }) => (
          <RadioButton
            checked={selectedId === id}
            key={id}
            onChange={() => onChange(id, radioValue)}
            size="small"
            testId={id}
          >
            {label}
          </RadioButton>
        ))}
      </div>
    </div>
  );
};

export default SynonymGroup;
