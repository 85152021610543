import { useMemo, useState } from 'react';

import { useDebounce, useSelector } from '_common/hooks';

import {
  ObjectHighlightRect,
  getHighlightRects,
} from 'Presentation/Slides/Slide/SlideOverlay/InlineCards/CardHighlight/utils';
import { HighlightMarker } from '_common/suite/components';
import { useSlideId } from 'Presentation/hooks';

const FindHighlights = () => {
  const currentSlideId = useSlideId();

  const findInstances = useSelector((state) => state.presentation.find.instances);
  const selectedInstance = useSelector((state) => state.presentation.find.selectedInstance);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  const [shapesRendered, setShapesRendered] = useState<boolean>(false);

  /**
   * When zoom changes UI will rerender
   * Debouncing zoom value and using it on the useMemo below will make sure we are getting the updated elements rects
   */
  const debouncedZoom = useDebounce(zoom, 0);

  const findHighlights = useMemo<ObjectHighlightRect<number>[] | undefined>(() => {
    /** Wait for shapes to be rendered */
    if (!shapesRendered) {
      setTimeout(() => setShapesRendered(true), 0);
      return undefined;
    }

    const slideRect = document.getElementById(currentSlideId);
    const slideRectBounds = slideRect?.getBoundingClientRect();
    const slideRectOffset = slideRectBounds && {
      top: slideRectBounds.top,
      left: slideRectBounds.left,
    };

    return findInstances
      ? findInstances
          .filter((instance) => instance.location[0].id === currentSlideId)
          .reduce<ObjectHighlightRect<number>[]>((highlights, instance) => {
            highlights.push({
              object: instance.id,
              rects: getHighlightRects({
                anchor: instance.location,
                negativeOffset: slideRectOffset,
              }),
            });
            return highlights;
          }, [])
      : [];
  }, [shapesRendered, findInstances, currentSlideId, debouncedZoom]);

  return (
    <>
      {findHighlights?.map(({ object, rects }, i) => (
        <HighlightMarker
          key={i}
          isActive={object === selectedInstance}
          defaultBackground="rgba(233, 241, 254, 0.8)"
          activeBackground="rgba(189, 212, 252, 0.8)"
          rect={rects[0]}
        />
      ))}
    </>
  );
};
export default FindHighlights;
