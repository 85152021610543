import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, InputField, Input } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';

import styles from './CaptionPreview.module.scss';

import { CheckboxProps } from 'dodoc-design-system/build/types/Components/Checkbox/Checkbox';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import NumberingUtils from '_common/utils/NumberingUtils';

export type CaptionPreviewProps = {
  captionLabelValue: SelectOption;
  captionNumberingValue: SelectOption;
  separatorsValue: CaptionsModal.SeparatorOption;
  chapterStylesValue: SelectOption;
  includeChapter: CheckboxProps['checked'];
  setDescription: (description: string) => void;
  descriptionError: string;
  outlineLists: MyAny; //TODO:TS this should be related to Editor/redux/selectors.getOutlineList()
};

const CaptionPreview = ({
  captionLabelValue,
  captionNumberingValue,
  includeChapter,
  separatorsValue,
  chapterStylesValue,
  setDescription,
  descriptionError,
  outlineLists,
}: CaptionPreviewProps) => {
  const [text, setText] = useState('');
  const [headingOutlineStyle, setHeadingOutlineStyle] = useState<MyAny>(''); //TODO:TS this should be related to Editor/redux/selectors.getOutlineList()

  useEffect(() => {
    let listId: string | null = null;
    let listStyle: string | null = null;
    if (includeChapter) {
      listId = EditorManager.getInstance().hasStyleAList(chapterStylesValue?.value);
    }
    if (listId) {
      listStyle = EditorManager.getInstance().getStyleIdForList(listId);
    }
    if (listStyle) {
      setHeadingOutlineStyle(
        outlineLists[listStyle]?.find(
          //@ts-expect-error once CaptionPreviewProps.outlineLists is typed this souldnt be any
          (style) => style.paragraph_style?.value === chapterStylesValue.value,
        ),
      );
    }
  }, [chapterStylesValue]);

  const chapterNumber = headingOutlineStyle
    ? NumberingUtils.represent(headingOutlineStyle.numbering_type.value, 1)
    : 0;
  useEffect(() => {
    setText(
      `${captionLabelValue.label} ${
        includeChapter === 'checked' ? `${chapterNumber}${separatorsValue.char}` : ''
      }${NumberingUtils.represent(captionNumberingValue.value, 1)}`,
    );
  }, [captionLabelValue, separatorsValue, includeChapter, captionNumberingValue, chapterNumber]);

  return (
    <div className={styles.rootPreview}>
      <div className={styles.title}>
        <FormattedMessage id="editor.toolbar.contentStructure.CAPTION" />
      </div>
      <Divider margin="0 0 2rem 0" />

      <InputField
        size="large"
        label=""
        feedback={descriptionError}
        testId="insertCaptionModal-preview-field"
      >
        <Input
          onChange={(e) => {
            setText(e.target.value);
            const description = e.target.value.split(
              `${captionLabelValue.label} ${
                includeChapter === 'checked' ? `${chapterNumber}${separatorsValue.char}` : ''
              }${NumberingUtils.represent(captionNumberingValue.value, 1)}`,
            );
            setDescription(description[1]);
          }}
          value={text}
          error={!!descriptionError}
          size="large"
          placeholder=""
          testId="insertCaptionModal-preview"
        />
      </InputField>
    </div>
  );
};

export default CaptionPreview;
