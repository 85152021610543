import { Command } from '../Command';
import { JsonRange, PathUtils, SelectionFixer } from 'Editor/services/_Common/Selection';
import { ReduxInterface } from 'Editor/services';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { NodeUtils } from 'Editor/services/DataManager';
import { ErrorCommentCreation } from '../../Errors';

export class AddTemporaryCommentCommand extends Command {
  async handleExec() {
    try {
      this.buildActionContext();

      if (
        !this.context.DataManager ||
        !this.context.DataManager.selection ||
        !this.context.selection?.modifiers ||
        !this.actionContext
      ) {
        throw new Error('Invalid Data');
      }

      if (
        !this.context.DataManager?.permissions.canUserPerform(
          this.actionContext.baseModel.id,
          'comment',
        )
      ) {
        throw new ErrorCommentCreation('User has no permissions to comment selection');
      }

      const jsonRange = this.actionContext.range;

      if (jsonRange.collapsed) {
        this.context.selection.modifiers.modify(jsonRange, 'move', 'word', 'backward');
        this.context.selection.modifiers.modify(jsonRange, 'expand', 'word', 'forward');
      } else {
        // fix non collapse selection
        SelectionFixer.normalizeTextSelection(jsonRange, {}, this.context.DataManager);
      }

      if (jsonRange.isEmpty(this.context.DataManager)) {
        throw new ErrorCommentCreation('Empty selection');
      }

      // get blocks from range
      const tempCommentReference = EditorDOMUtils.generateUUID();

      const rangesData = JsonRange.splitRangeByTypes(
        this.context.DataManager,
        jsonRange,
        NodeUtils.BLOCK_TEXT_TYPES,
      );

      const ranges = rangesData.map<Editor.Selection.RangeData>((value) => {
        return value.range;
      });

      if (rangesData.length) {
        await this.context.DataManager?.comments?.addTemporaryComment(tempCommentReference, ranges);

        const lastRange = ranges[ranges.length - 1];

        setTimeout(() => {
          const blockModel = this.context.DataManager?.nodes.getNodeModelById(lastRange.end.b);
          const baseData = blockModel?.selectedData();

          if (!blockModel || !baseData) {
            throw new Error('Invalid data');
          }

          let commentsData = NodeUtils.querySelectorInData(baseData, 'comment');
          let lastComment;
          for (let i = commentsData.length - 1; i >= 0; i--) {
            if (commentsData[i].data.properties?.element_reference === tempCommentReference) {
              lastComment = commentsData[i];
            }
          }

          if (lastComment) {
            let path = [...lastComment.path];
            path.push('childNodes');
            path.push(lastComment.data.childNodes?.length || 0);

            if (PathUtils.isValidSelectionPath(path)) {
              const jsonRange = new JsonRange({ b: blockModel.id, p: path });

              // apply new selection
              this.applySelection(jsonRange);
            }
          }
        }, 0);
      }

      ReduxInterface.openTemporaryCommentCard({
        reference: tempCommentReference,
        level0: this.actionContext.baseModel.id,
      });
    } catch (error) {
      logger.captureException(error);
      throw error;
    }
  }
}
