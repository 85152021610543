import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useParams } from 'react-router';

import TagSystem from '_common/components/TagSystem/TagSystem';
import UserCard from '_common/components/UserCard/UserCard';
import { DueDateSystem, LineEllipsis } from '_common/components';
import { Setting } from 'Settings/components';
import ApprovalComment from '_common/components/InfoPanels/ObjectPanel/Details/ApprovalComment/ApprovalComment';
import { Divider } from 'dodoc-design-system';

import styles from './GeneralSettings.module.scss';

type OpenGenericSettingModalProps = {
  header: string;
  label: string;
  value: string;
  inputPlaceholder: string;
  inputType: string;
  type: string | undefined;
  data: ObjectDict;
  action: string;
  actionValues: {
    objectId: string;
    objectType: string;
  };
};

const GeneralSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId }: { id: ObjectId } = useParams();

  const object = useSelector((state) => state.app.data[objectId]);
  const userPermissions = object.user_permissions;
  const isOwner = userPermissions.includes('admin') || userPermissions.includes('owner');
  const canEdit = isOwner || userPermissions.includes('edit');
  const hasApprovalData = object.status === 'approved' && object.lifecycle?.approvals?.length > 0;

  const openChangeManagerModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ChangeManagerModal',
        data: { name: object.name, data: object },
      }),
    );
  };

  const handleOpenGenericSettingModal = ({
    header,
    label,
    value,
    type,
    inputType,
    inputPlaceholder,
    data,
    action,
    actionValues,
  }: OpenGenericSettingModalProps) => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header,
          label,
          value,
          inputPlaceholder,
          inputType,
          type,
          data,
          action,
          actionValues,
        },
      }),
    );
  };

  const openEditName = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({ id: 'settings.name.header' }),
      label: intl.formatMessage({ id: 'EDIT_TITLE' }, { value: object.type }),
      value: object.name,
      inputPlaceholder: intl.formatMessage(
        { id: 'EDIT_TITLE_PLACEHOLDER' },
        { value: object.type },
      ),
      type: 'name',
      inputType: 'text',
      data: object,
      action: 'rename',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  const openEditDescription = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({ id: 'settings.description.header' }),
      label: intl.formatMessage({ id: 'EDIT_DESCRIPTION_LABEL' }, { value: object.type }),
      value: object.description,
      inputPlaceholder: intl.formatMessage(
        {
          id: 'EDIT_DESCRIPTION_PLACEHOLDER',
        },
        { value: object.type },
      ),
      type: 'description',
      inputType: 'textarea',
      data: object,
      action: 'editDescription',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  return (
    <>
      <div className={styles.root}>
        {/* Title */}
        <Setting
          label={intl.formatMessage({ id: 'settings.general.title' })}
          onEdit={isOwner && openEditName}
          onEditLabel="global.edit"
          testId="name"
        >
          {object.name}
        </Setting>
        {/* Creation */}
        <Setting label={intl.formatMessage({ id: 'global.creator' })} testId="creator">
          {<UserCard userId={object.creator} />}
        </Setting>
        {/* Owner */}
        <Setting
          manager
          label={intl.formatMessage({ id: 'global.owner' })}
          onEdit={openChangeManagerModal}
          onEditLabel="global.edit"
          testId="owner"
          isOwner={isOwner}
        >
          <UserCard userId={object.owner} />
        </Setting>
        {/* Description */}
        <Setting
          label={intl.formatMessage({ id: 'settings.general.description' })}
          onEdit={canEdit && openEditDescription}
          onEditLabel="global.edit"
          testId="description"
        >
          <LineEllipsis>
            {object.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
          </LineEllipsis>
        </Setting>
        {/* Tags */}
        <div className={styles.setting} data-testid="tags-row-container">
          <span className={styles.label}>
            <FormattedMessage id="settings.general.tags" />
          </span>
          <div className={styles.value}>
            <TagSystem
              objectId={objectId}
              placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
              testId="settings-page"
            />
          </div>
        </div>
        <Divider />
        {/** Approval Comment */}
        {hasApprovalData && (
          <>
            <div className={styles.setting}>
              <span className={styles.label}>
                <FormattedMessage id="storage.actionBar.filters.approved" />
              </span>
              <ApprovalComment objectId={objectId} />
            </div>
            <Divider />
          </>
        )}
        {/* DueDate/Warning Date */}
        <div className={styles.setting}>
          <div className={styles.dates}>
            <span className={styles.label}>
              <FormattedMessage id="settings.general.dueDate" />
            </span>
            {(canEdit || (object.events && object.events.warnings.length > 0)) && (
              <span
                className={styles.label}
                style={{
                  marginTop: canEdit ? '28px' : '2rem',
                }}
              >
                <FormattedMessage id="storage.browserSidebar.dueDate.warningDate" />
              </span>
            )}
          </div>
          <div className={styles.value}>
            <DueDateSystem objectId={object.id} canEdit={canEdit} />
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
};

export default GeneralSettings;
