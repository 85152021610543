import { InsertInlineCommand } from '../GenericCommands';
import { NodeDataBuilder } from 'Editor/services/DataManager';

export class InsertCrossReferenceCommand extends InsertInlineCommand {
  private options: Editor.Data.CrossReferences.PresentationTextOptionsType;

  constructor(
    context: Editor.Edition.Context,
    options: Editor.Data.CrossReferences.PresentationTextOptionsType,
  ) {
    super(context);
    this.options = options;
  }

  async handleExec(...args: any[]): Promise<void> {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid Context');
    }

    this.options.origin = this.actionContext.baseModel.id;

    const text =
      await this.context.DataManager.crossReferences.getAsyncCrossReferencePresentationText(
        this.options,
      );

    if (text) {
      const refBuilder = new NodeDataBuilder('f').addProperty('t', 'cr');

      if (this.options.format != null) {
        refBuilder.addProperty('f', this.options.format);
      }

      if (this.options.link) {
        refBuilder.addProperty('l', true);
      }

      if (this.options.target) {
        refBuilder.addProperty('r', this.options.target);
      }

      const textData = new NodeDataBuilder('text').setContent(text).build();
      if (textData) {
        refBuilder.addChildData(textData);
      }

      const refData = refBuilder.build();

      if (refData) {
        await super.handleExec(refData);
      }
    }
  }
}
