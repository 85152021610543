export const NodeKeys = Object.freeze({
  ID: 'id',
  PARENT_ID: 'parent_id',
  TYPE: 'type',
  CHILDNODES: 'childNodes',
  PROPERTIES: 'properties',
  REFS: 'refs',
  PERMISSIONS: 'permissions',
  APPROVEDBY: 'approvedBy',
  LOCK: 'lock',
  TASKS: 'tasks',
});
