import { useRef } from 'react';
import { Button, Input, Popover, usePopper } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

import styles from './SettingsCell.module.scss';

type SettingsCellProps = {
  object: Objekt;
};

const SettingsCell = ({ object }: SettingsCellProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const copyLinkPopper = usePopper({ placement: 'bottom-end' });

  const getPublicLink = () => {
    return `${window.location.origin}/public/${object.type}/${object.hash}`;
  };

  const handleOnCopyLink = () => {
    inputRef.current?.select();
    inputRef.current && navigator.clipboard.writeText(inputRef.current.value);
  };

  return (
    <>
      <Button
        variant="link"
        {...copyLinkPopper.referenceProps}
        size="small"
        testId="public-link-row-copy-button"
      >
        <FormattedMessage id="settings.publicLink.copyLink" />
      </Button>
      <Popover {...copyLinkPopper.popperProps} testId="public-link-copy-popper">
        <div
          className={styles.popover}
          style={{
            display: 'flex',
            padding: '1rem 2rem',
          }}
        >
          <Input
            value={getPublicLink()}
            ref={inputRef}
            size="medium"
            placeholder=""
            testId="public-link-row-copy"
            clearable={false}
          />
          <Button
            size="medium"
            variant="primary"
            onClick={handleOnCopyLink}
            testId="public-link-row-copy-clipboard"
            margin="0 0 0 1rem"
          >
            <FormattedMessage id="global.copyToClipboard" />
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default SettingsCell;
