import { useIntl } from 'react-intl';
import { Toggle, Tooltip } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { useSelector } from '_common/hooks';

import styles from './Layout.module.scss';

export const TEST_ID = {
  web: 'web-layout-toggle',
  page: 'page-layout-toggle',
};

const Layout = () => {
  const intl = useIntl();
  const layout = useSelector((state) => state.editor.status.layout);

  const handleWebLayoutChange = () => {
    if (layout !== 'WEB') {
      EditorManager.getInstance().changeLayout('WEB');
    }
  };
  const handlePageLayoutChange = () => {
    if (layout !== 'PAGE') {
      EditorManager.getInstance().changeLayout('PAGE');
    }
  };

  return (
    <div className={styles.root}>
      <Tooltip
        content={intl.formatMessage({ id: 'WEB_LAYOUT' })}
        placement="top"
        testId="web-layout-tooltip"
      >
        <Toggle
          variant="link"
          icon="WebLayout"
          size="small"
          isToggled={layout === 'WEB'}
          onClick={handleWebLayoutChange}
          testId={TEST_ID.web}
        />
      </Tooltip>
      <Tooltip
        content={intl.formatMessage({ id: 'PAGE_LAYOUT' })}
        placement="top"
        testId="page-layout-tooltip"
      >
        <Toggle
          variant="link"
          icon="PageLayout"
          size="small"
          margin="0 0 0 1rem"
          isToggled={layout === 'PAGE'}
          onClick={handlePageLayoutChange}
          testId={TEST_ID.page}
        />
      </Tooltip>
    </div>
  );
};

export default Layout;
