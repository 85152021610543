import { UsernameLabel } from '_common/components';
import styles from '../SearchPage.module.scss';

type MetadataCellProps = {
  object: Objekt;
  metadata: ApiSchemas['MetadataObjectSchema'];
};

const MetadataCell = ({ object, metadata }: MetadataCellProps) => {
  switch (metadata.data_type) {
    case 'user':
      return (
        <div className={styles.metadataCellEllipsis}>
          <UsernameLabel userId={object.metadata?.[metadata.id]} />
        </div>
      );
    default:
      return <div className={styles.metadataCellEllipsis}>{object.metadata?.[metadata.id]}</div>;
  }
};

export default MetadataCell;
