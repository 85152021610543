import Slider from './Slider/Slider';
import ZoomSelect from './ZoomSelect/ZoomSelect';

import styles from './Zoom.module.scss';

const Zoom = () => {
  return (
    <div className={styles.root}>
      <Slider />
      <ZoomSelect />
    </div>
  );
};

export default Zoom;
