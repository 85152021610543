import React, { useState, useRef, useLayoutEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  useCreateTagMutation,
  useGetTagsListQuery,
} from 'Settings/pages/TenantSettingsPage/Tags/TagsApi';

import { Button, Modal, Input, InputField } from 'dodoc-design-system';

const MODAL = 'CreateNewTagModal';

const CreateNewTagModal = () => {
  const intl = useIntl();
  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const { data: tags } = useGetTagsListQuery();
  const [mutCreateTag] = useCreateTagMutation();

  // local
  const [name, setName] = useState('');
  const [validations, setValidations] = useState({ name: '' });
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.value;
    setName(inputName);
    setValidations({ name: '' });

    const validations = { name: '' };
    if (inputName.length > 100) {
      validations.name = intl.formatMessage(
        {
          id: 'LONG_TAG_NAME',
        },
        { length: 100 },
      );
    }
    if (tags?.list.includes(inputName)) {
      validations.name = intl.formatMessage({
        id: 'TAG_ALREADY_EXISTS',
      });
    }

    if (Object.keys(validations).length > 0) {
      setValidations({ ...validations });
    }
  };

  const createNewTag = () => {
    mutCreateTag(name);
    close();
  };

  const close = () => {
    setName('');
    setValidations({ name: '' });
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} width="75rem" onClose={close} testId="create-new-tag-modal">
      <Modal.Header onClose={close}>
        <FormattedMessage id="TITLE_NEW_TAG" />
      </Modal.Header>
      <Modal.Body>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'LABEL_NEW_TAG' })}
          feedback={validations.name}
          testId="create-new-tag-name"
        >
          <Input
            ref={inputRef}
            value={name}
            size="large"
            placeholder={intl.formatMessage({ id: 'PLACEHOLDER_NEW_TAG' })}
            onChange={onNameChanged}
            error={!!validations.name}
            testId="create-new-tag-name"
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="create-new-tag-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={createNewTag}
          disabled={!!validations.name || name === ''}
          testId="create-new-tag-submit-button"
        >
          <FormattedMessage id="CREATE_TAG" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateNewTagModal;
