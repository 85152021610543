import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import styles from './ElementLabel.module.scss';

const TYPE_LABEL: Record<Editor.ToCItem['type'], TranslationId> = {
  figure: 'FIGURE',
  tbl: 'TABLE',
  citation: 'CITATION',
  p: 'P',
  f: 'FIGURE',
};

const SUBTYPE_LABEL: Record<Editor.ToCItem['st'], TranslationId> = {
  h1: 'HEADING1',
  h2: 'HEADING2',
  h3: 'HEADING3',
  t: 'MAIN-TITLE',
};

type ElementLabelProps = {
  element: Editor.ToCItem;
};

const ElementLabel = ({ element }: ElementLabelProps) => {
  if (!element) {
    return null;
  }

  if (element.content === null) {
    return (
      <div className={styles.redacted}>
        <Icon icon="NoPermissions" size={24} opacity={0.5} margin="0 1rem 0 0" />
        <FormattedMessage id="NO_PERMISSION_TO_VIEW_THIS_ELEMENT" />
      </div>
    );
  }

  return (
    <div className={styles.ellipsis}>
      {element.content ||
        (element.type === 'p'
          ? SUBTYPE_LABEL[element.st] && <FormattedMessage id={SUBTYPE_LABEL[element.st]} />
          : TYPE_LABEL[element.type] && <FormattedMessage id={TYPE_LABEL[element.type]} />)}
    </div>
  );
};

export default ElementLabel;
