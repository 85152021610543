import { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Modal, Button, InputField, Input, PasswordInput, DatePicker } from 'dodoc-design-system';
import { closeModal } from '_common/modals/ModalsSlice';
import {
  useCreatePublicLinkMutation,
  useEditPublicLinkMutation,
  useGetPublicLinkQuery,
} from 'Settings/pages/ObjectSettingsPage/PublicLinkView/PublicLinkSettingsApi';
import Block from './Block/Block';
import styles from './PublicLinkModal.module.scss';

import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import { PublicLinkData } from '_common/services/PublicLinkService';

const PublicLinkModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.PublicLinkModal);
  const objectId = useSelector((state) => state.modals.PublicLinkModal.objectId);
  const linkId = useSelector((state) => state.modals.PublicLinkModal.linkId);
  const mode = useSelector((state) => state.modals.PublicLinkModal.mode);
  const { data, refetch } = useGetPublicLinkQuery(linkId, { skip: mode === 'create' });

  const inputRef = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const [name, setName] = useState('');
  const [expirationDate, setExpirationDate] = useState<PublicLink['expiration']>({
    active: false,
    expiration: null,
  });
  const [passwordValue, setPasswordValue] = useState('');
  const [isPasswordActive, setIsPasswordActive] = useState(false);
  const [validations, setValidations] = useState<{ name?: string }>({
    name: '',
  });
  const [createPublicLink] = useCreatePublicLinkMutation();
  const [editPublicLink] = useEditPublicLinkMutation();

  useEffect(() => {
    if (isOpen) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      if (linkId && data) {
        if (mode === 'edit') {
          refetch();
        }
        setActive(data.active);
        setName(data.name);
        setExpirationDate(data.expiration);
        setIsPasswordActive(data.password.active);
      }
    }
  }, [isOpen, linkId, data]);

  const toggleExpirationDate = () => {
    setExpirationDate((state) => ({
      ...state,
      active: !state.active,
    }));
  };

  const togglePassword = () => {
    setIsPasswordActive((state) => !state);
  };

  const toggleActive = () => {
    setActive((state) => !state);
  };

  const handleOnNameChange: InputProps['onChange'] = (e) => {
    const value = e.target.value;
    setName(value);
    setValidations((state) => ({ ...state, name: '' }));
  };

  const handleOnDateChange = (date: Date | null) => {
    setExpirationDate((state) => ({
      ...state,
      expiration: date,
    }));
  };

  const handleOnPasswordChange: InputProps['onChange'] = (e) => {
    setPasswordValue(e.target.value);
  };

  const handleSubmitClick = () => {
    // TODO: validate password

    // validations
    const newValidations: typeof validations = {};
    if (name.length === 0 || name.trim() === '') {
      newValidations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (name.length < 3) {
      newValidations.name = intl.formatMessage({ id: 'validation.name.smallName' }, { length: 3 });
    } else if (name.length > 35) {
      newValidations.name = intl.formatMessage({ id: 'validation.name.longName' }, { length: 35 });
    }

    if (Object.keys(newValidations).length > 0) {
      setValidations(newValidations);
    } else {
      const values: PublicLinkData = {
        active,
        name,
        password: passwordValue,
        password_active: passwordValue?.length > 0 ? isPasswordActive : false,
        expiration: expirationDate.active ? expirationDate.expiration : null,
        expiration_active: expirationDate.expiration ? expirationDate.active : false,
      };
      if (mode === 'create') {
        createPublicLink({ objectId, values });
      } else {
        values.password_active = isPasswordActive;
        if (values.password === '' && values.password_active) {
          delete values.password;
        }
        editPublicLink({ linkId, values });
      }
      reset();
      close();
    }
  };

  const reset = () => {
    setActive(false);
    setName('');
    setExpirationDate({
      expiration: null,
      active: false,
    });
    setPasswordValue('');
    setIsPasswordActive(false);
  };

  const close = () => {
    dispatch(closeModal('PublicLinkModal'));
    reset();
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="public-link">
      <Modal.Header onClose={close}>
        {mode === 'create' ? (
          <FormattedMessage id="settings.publicLink.newPublicLink" />
        ) : (
          <FormattedMessage id="EDIT_PUBLIC_LINK" values={{ linkName: data?.name }} />
        )}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.row}>
          <InputField
            label={intl.formatMessage({ id: 'PUBLIC_LINK_NAME' })}
            feedback={validations.name ? validations.name : undefined}
            width="100%"
            required={intl.formatMessage({ id: 'REQUIRED' })}
            size="large"
            testId="public-link-modal-name-field"
          >
            <Input
              size="large"
              value={name}
              onChange={handleOnNameChange}
              placeholder={intl.formatMessage({
                id: 'settings.publicLink.insertPublicLinkName',
              })}
              ref={inputRef}
              error={!!validations.name}
              testId="public-link-modal-name"
            />
          </InputField>
        </div>
        <Block
          labelContent="settings.publicLink.publicLinkStatus"
          activeDetailsProps={{
            toggle: toggleActive,
            active: active,
            tooltipContent: 'settings.publicLink.tooltips.linkStatusTooltip',
            testId: 'public-link-modal-status-toggle',
          }}
        />
        <Block
          labelContent="global.expirationDate"
          activeDetailsProps={{
            toggle: toggleExpirationDate,
            active: expirationDate.active,
            tooltipContent: 'settings.publicLink.tooltips.expirationDateTooltip',
            testId: 'public-link-modal-expiration-date-toggle',
          }}
        >
          <div style={{ width: '32rem' }}>
            <DatePicker
              size="large"
              disabled={!expirationDate.active}
              placeholder={intl.formatMessage({ id: 'SELECT_DATE' })}
              filterDate={[
                {
                  filterType: 'sameOrAfter',
                  filterDate: new Date(),
                },
              ]}
              onChange={handleOnDateChange}
              selectedDate={expirationDate.expiration && new Date(expirationDate.expiration)}
              testId="public-link-modal-expiration-datepicker"
            />
          </div>
        </Block>
        <Block
          labelContent="PASSWORD"
          activeDetailsProps={{
            toggle: togglePassword,
            active: isPasswordActive,
            tooltipContent: 'settings.publicLink.tooltips.passwordTooltip',
            testId: 'public-link-modal-expiration-password-toggle',
          }}
        >
          <PasswordInput
            width="32rem"
            size="large"
            value={passwordValue}
            placeholder={intl.formatMessage({ id: 'PASSWORD' })}
            onChange={handleOnPasswordChange}
            disabled={!isPasswordActive}
            testId="public-link-modal-password"
          />
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="public-link-modal-close-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSubmitClick}
          testId="public-link-modal-submit-button"
        >
          <FormattedMessage
            id={
              mode === 'create'
                ? 'settings.publicLink.createPublicLink'
                : 'settings.general.saveSettings'
            }
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublicLinkModal;
