import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import styles from './BlockApprovalPanel.module.scss';

const BlockApprovalPanel = () => {
  const summary = useSelector((state) => state.editor.blockApproval.summary);
  const selected = useSelector((state) => state.editor.blockApproval.selected);
  const allSelected = useSelector((state) => state.editor.blockApproval.allSelected);
  const [reopenDisabled, setReopenDisabled] = useState(true);
  const [approveDisabled, setApproveDisabled] = useState(true);

  const manager = EditorManager.getInstance();

  useEffect(() => {
    manager.getApprovalSummary();
  }, []);

  useEffect(() => {
    if (selected.length === 0) {
      setReopenDisabled(true);
      setApproveDisabled(true);
    } else {
      setReopenDisabled(Object.keys(summary.isApproved).length <= 0);
      setApproveDisabled(Object.keys(summary.isOpen).length <= 0);
    }
  }, [selected, summary]);

  const handleApproveClicked = () => {
    manager.approveNodes(selected);
  };

  const handleReopenClicked = () => {
    manager.reopenNodes(selected);
  };

  const renderSelectionButtons = () => {
    return (
      <div className={styles.buttons}>
        <Button
          size="small"
          style={{ width: '100%' }}
          onClick={() => manager.selectAllForApproval()}
          disabled={allSelected}
          testId="approve-content-panel-select-all-blocks-button"
        >
          <FormattedMessage id="editor.sidebar.permissions.selectAll" />
        </Button>
        <Button
          style={{ marginLeft: '1rem', width: '100%' }}
          size="small"
          onClick={() => manager.deselectAllForApproval()}
          disabled={selected.length === 0}
          testId="approve-content-panel-deselect-blocks-button"
        >
          <FormattedMessage id="editor.sidebar.permissions.deselect" />
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {renderSelectionButtons()}
      <div className={styles.actions}>
        <Button
          variant="primary"
          size="small"
          style={{ width: '100%' }}
          disabled={approveDisabled}
          onClick={handleApproveClicked}
          testId="approve-content-panel-approve-button"
        >
          <FormattedMessage id="editor.sidebar.blockApproval.approve" />
        </Button>
        <Button
          style={{ marginLeft: '1rem', width: '100%' }}
          size="small"
          onClick={handleReopenClicked}
          disabled={reopenDisabled}
          testId="approve-content-panel-reopen-button"
        >
          <FormattedMessage id="editor.sidebar.blockApproval.reopen" />
        </Button>
      </div>
      {selected.length !== 0 && approveDisabled && reopenDisabled && (
        <div className={styles.empty}>
          <FormattedMessage id="editor.sidebar.blockApproval.incompatible" />
        </div>
      )}
    </div>
  );
};

export default BlockApprovalPanel;
