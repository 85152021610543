import { hexToRgb } from 'assets/colors';
import { useChartColor, useChartDefaultColors, useChartOutline, useDataLabel } from '../hooks';

const useFunnelSeries = () => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { dataLabel } = useDataLabel();
  const { defaultColors } = useChartDefaultColors();
  const funnelSeries = ({
    chart,
  }: {
    //@ts-expect-error this isn't supported yet
    chart: Presentation.Data.FunnelChart;
  }): echarts.FunnelSeriesOption[] => {
    const axisInfo = chart.chartSpace.chart.plotArea?.axis?.[0];
    const seriesInfo = chart.chartSpace.chart?.plotArea?.plotAreaRegion?.series?.[0];
    const labelsInfo = chart.chartSpace.chartData.data[0].dim[1].lvl[0].pt;
    //TO DO: remove the type of serie when we have the types for the Funnel chart
    return chart.chartSpace.chartData.data[0].dim.map((serie: any) => {
      if (serie.type === 'str') {
        const option: echarts.CustomSeriesOption = {
          type: 'custom',
          silent: true,
          //@ts-expect-error echarts types
          renderItem: function (params, api) {
            const x = api.value(0);
            const y = api.coord([x, 0])[1];
            //@ts-expect-error echarts types
            const height = api?.size([0, x])[1];
            //@ts-expect-error echarts types
            const width = api?.size([x, 0])[0];
            const dataIndex = params.dataIndex;
            const indexValue = labelsInfo.length - 1 - dataIndex;
            const data = seriesInfo?.dataPt;

            const isHexColorLight = (hex: string) => {
              if (hex) {
                const rgb = hexToRgb(hex);

                if (rgb) {
                  const luminance = (0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]) / 255;
                  return luminance > 0.5;
                }
              }
            };

            const handleItemColor = () => {
              if (
                data.filter((dataPt: { idx: number }) => dataPt.idx === indexValue)[0]?.properties
                  ?.fill
              ) {
                return chartColor(
                  data.filter((dataPt: { idx: number }) => dataPt.idx === indexValue)[0]?.properties
                    ?.fill,
                );
              } else if (seriesInfo?.properties?.fill?.type === 'solid') {
                return chartColor(seriesInfo?.properties.fill);
              } else if (chart.chartStyle.dataPoint.properties.fill?.type) {
                const fill =
                  chart.chartStyle.dataPoint.properties.fill?.type === 'solid' &&
                  chart.chartStyle.dataPoint.properties.fill?.color?.reference === 'phClr'
                    ? {
                        ...chart.chartStyle.dataPoint.properties.fill,
                        color: chart.colorStyle.color[0],
                      }
                    : chart.chartStyle.dataPoint.properties.fill;
                return chartColor(fill);
              } else {
                const fill =
                  defaultColors(1)[0]?.properties?.fill?.type === 'solid'
                    ? (defaultColors(1)[0]?.properties?.fill as Presentation.Data.Common.FillType)
                    : undefined;
                return chartColor(fill);
              }
            };

            const handleItemBorder = () => {
              if (
                data.filter((dataPt: { idx: number }) => dataPt.idx === indexValue)[0]?.properties
                  ?.ln
              ) {
                return chartOutline(
                  data.filter((dataPt: { idx: number }) => dataPt.idx === indexValue)[0]?.properties
                    ?.ln,
                );
              } else if (seriesInfo?.properties?.ln) {
                return chartOutline(seriesInfo?.properties?.ln);
              }
            };

            const handleLabelColor = () => {
              if (
                seriesInfo?.dataLabels?.dataLabel?.filter(
                  (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                )[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill
              ) {
                return chartColor(
                  seriesInfo?.dataLabels?.dataLabel?.filter(
                    (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                  )[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
                );
              } else if (seriesInfo?.dataLabels?.text) {
                return chartColor(
                  seriesInfo?.dataLabels?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
                );
              } else if (isHexColorLight(handleItemColor() as string)) {
                return '#000000';
              } else {
                return '#ffffff';
              }
            };
            const handleTextLabelBorder = () => {
              if (
                seriesInfo?.dataLabels?.dataLabel?.filter(
                  (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                )[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln
              ) {
                return chartOutline(
                  seriesInfo?.dataLabels?.dataLabel?.filter(
                    (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                  )[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
                );
              }
            };

            const handleLabelBorder = () => {
              if (
                seriesInfo?.dataLabels?.dataLabel?.filter(
                  (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                )[0]?.properties?.ln
              ) {
                return chartOutline(
                  seriesInfo?.dataLabels?.dataLabel?.filter(
                    (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                  )[0]?.properties?.ln,
                );
              } else if (seriesInfo?.dataLabels?.properties?.ln) {
                return chartOutline(seriesInfo?.dataLabels?.properties?.ln);
              } else if (
                seriesInfo?.dataLabels?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln
              ) {
                return chartOutline(
                  seriesInfo?.dataLabels?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
                );
              }
            };

            const outlineValues = (outlineProps: any) => {
              return {
                stroke: outlineProps?.borderColor,
                lineWidth: outlineProps?.borderWidth ?? 0.75,
                lineDash: outlineProps?.borderType,
                lineCap: outlineProps?.borderCap,
                lineJoin: outlineProps?.borderJoin,
                miterLimit: outlineProps?.borderMiterLimit,
              };
            };

            const gapWidth = axisInfo?.catScaling?.gapWidth * 100;
            const handleGap = () => {
              if (gapWidth > 50) {
                return gapWidth > 200 && gapWidth < 300 ? gapWidth / 3 + 5 : gapWidth / 2 + 5;
              } else {
                return gapWidth + 5;
              }
            };

            const label = dataLabel({
              dLbls: {
                dLblPos: 'outEnd',
                delete: false,
                showVal: seriesInfo.dataLabels?.visibility?.value,
                showCatName: seriesInfo.dataLabels?.visibility?.categoryName,
                showSerName: seriesInfo.dataLabels?.visibility?.seriesName,
                showLeaderLines: false,
                showLegendKey: false,
                leaderLines: {},
                serieValue: seriesInfo.txData.v,
                dLbl: [],
              },
              idx: indexValue,
              val: chart.chartSpace.chartData.data[0].dim[1].lvl[0].pt[indexValue]?.content,
              cat: chart.chartSpace.chartData.data[0].dim[0].lvl[0].pt[indexValue]?.content,
              defaultPosition: 'inside',
              chart,
            });
            //@ts-expect-error can be undefined
            const textWidth = label?.formatter(indexValue).length * 6.8;
            //@ts-expect-error echarts types
            const xValue = params.coordSys?.x + (params.coordSys?.width - width) / 2;
            const yValue = dataIndex === 0 ? y - height / 2 : y - height / 2 - dataIndex * height;
            const yPosition = y - height / 2;
            const maxValue = 350;
            const widthValue = width / 2;
            const textWidthValue = textWidth / 2;
            const heightValue = height / 2;

            const rectItem = {
              type: 'rect',
              shape: {
                x: xValue,
                y: gapWidth >= maxValue ? yValue + height / 3 + 5 : yValue + handleGap() / 4,
                width: width,
                height: gapWidth >= maxValue ? height / 5 : height - handleGap() / 2,
              },
              style: {
                fill: handleItemColor(),
                ...outlineValues(handleItemBorder()),
              },
            };

            const textItem = {
              type: 'text',
              style: {
                //@ts-expect-error can be undefined
                text: label?.formatter(indexValue),
                fill: handleLabelColor() ? handleLabelColor() : handleLabelColor,
                ...outlineValues(handleTextLabelBorder()),
                x: xValue + widthValue - textWidthValue + (textWidth > 50 ? 10 : 0),
                y:
                  dataIndex === 0
                    ? yPosition + heightValue - 4
                    : yPosition - dataIndex * height + heightValue - 4,
                backgroundColor: chartColor(
                  seriesInfo?.dataLabels?.dataLabel?.filter(
                    (dataLabel: { idx: number }) => dataLabel.idx === indexValue,
                  )[0]?.properties?.fill ?? seriesInfo?.dataLabels?.properties?.fill,
                ),
              },
            };

            const rectText = {
              type: 'rect',
              shape: {
                x: xValue + widthValue - textWidthValue + (textWidth > 50 ? 10 : 0),
                y:
                  dataIndex === 0
                    ? yPosition + heightValue - 5
                    : yPosition - dataIndex * height + heightValue - 5,
                width: textWidth,
                height: 14,
              },
              style: {
                fill: 'transparent',
                ...outlineValues(handleLabelBorder()),
              },
            };

            if (gapWidth < 312) {
              return {
                type: 'group',
                children: [rectItem, textItem, rectText],
              };
            } else {
              return {
                type: 'group',
                children: [rectItem],
              };
            }
          },
          data: serie.lvl[0].pt
            .map((pt: { idx: number; content: string }, index: string | number) => {
              return {
                name: chart.chartSpace.chartData.data[0].dim[1].lvl[0].pt[index]?.content,
                value: chart.chartSpace.chartData.data[0].dim[1].lvl[0].pt[index]?.content,
              };
            })
            .reverse(),
        };
        return option;
      }
      return null;
    });
  };

  return { funnelSeries };
};

export default useFunnelSeries;
