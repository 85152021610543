import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { v4 as uuid } from 'uuid';

import { InstanceService } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import {
  setForbiddenState,
  setNotFoundState,
  setBadRequestState,
  resetAppState,
  addData,
  setCleanState,
} from 'App/redux/appSlice';
import { addToListBulk, setList } from '_common/components/Table/TableSlice';
import {
  AdvancedFilter,
  FILTER_OPTIONS,
} from '_common/components/AdvancedFilterRow/AdvancedFilterOptions';

export type FilterObject = {
  id: string;
  name: string;
  filters: SearchCondition[] | string;
};

export type BaseCondition = {
  name: 'parent' | 'share';
  operator: 'eq';
  value: string;
};

export type SearchPageSliceState = {
  loading: boolean;

  baseCondition?: SearchCondition;
  initialParent?: Objekt;
  parent?: Objekt;

  triggerPage?: string;
  activeTab: 'spaces' | 'shared' | 'parent';

  savedFilters: {
    personal: { [key in FilterObject['id']]: FilterObject };
    tenant: { [key in FilterObject['id']]: FilterObject };
  };

  searchMode: 'normal' | 'advanced';
  searchQuery: string;
  advancedFilters: { [key in AdvancedFilter['id']]: AdvancedFilter };
  appliedFilterSet?: string;

  infoPanelOpen: boolean;

  request: {
    params: Request.OrderListParams & { lazyload?: boolean };
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    lazyload: boolean;
    total?: number;
  };
};

const SLICE_NAME = 'SearchPageSlice';
const INITIAL_STATE: SearchPageSliceState = {
  loading: true,

  baseCondition: undefined,
  initialParent: undefined,
  parent: undefined,

  triggerPage: undefined,
  activeTab: 'parent',

  savedFilters: {
    personal: {},
    tenant: {},
  },

  searchMode: 'normal',
  searchQuery: '',
  advancedFilters: {},
  appliedFilterSet: undefined,

  infoPanelOpen: false,

  request: {
    params: {
      offset: 0,
      size: 200,
      order_field: '_id',
      order_type: 'asc',
    },
    hasNextPage: true,
    isNextPageLoading: false,
    lazyload: false,
    total: undefined,
  },
};

// #region AsyncThunks
type SearchObjectsParams = {
  parent: SearchPageSliceState['parent'];
  query: SearchPageSliceState['searchQuery'];
  params: SearchPageSliceState['request']['params'] & { lazyload?: boolean };
};

export const searchObjects = createAsyncThunk(
  `${SLICE_NAME}/search`,
  async ({ parent, query, params }: SearchObjectsParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(setCleanState());

      const instanceService = new InstanceService({ errorsExpected: [400, 403, 404] });
      const requests = [
        instanceService.searchObject([], params, query, {
          signal: thunkAPI.signal,
        }),
      ];

      if (parent && Object.keys(parent).length) {
        requests.push(
          instanceService.getObjectData({ objectId: parent.id, objectType: parent.type }),
        );
      }

      const responses = await Promise.all(requests);

      // @ts-expect-error Missing endpoint type "/api/object/search"
      const { nodes, nodes_length } = responses[0].data;

      const results = nodes.reduce(
        (results: { list: ObjectList; data: ObjectDict }, item: Objekt) => {
          results.list.push(item.id);
          results.data[item.id] = item;
          return results;
        },
        { list: [], data: {} },
      );

      if (parent && responses[1]) {
        // @ts-expect-error Generic endpoint
        results.data[responses[1].data.id] = responses[1].data;
      }

      thunkAPI.dispatch(addData(results.data));
      if (params.lazyload) {
        thunkAPI.dispatch(addToListBulk({ identity: 'search', objects: results.list }));
      } else {
        thunkAPI.dispatch(
          setList({ identity: 'search', list: results.list, total: results.total }),
        );
      }

      return {
        results,
        params,
        total: nodes_length,
      };
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            thunkAPI.dispatch(setLoading(false));
            thunkAPI.dispatch(setBadRequestState());
            return;
          case 403:
            thunkAPI.dispatch(setLoading(false));
            thunkAPI.dispatch(setForbiddenState());
            return;
          case 404:
            thunkAPI.dispatch(setLoading(false));
            thunkAPI.dispatch(setNotFoundState());
            return;
          default:
            break;
        }

        throw error;
      }
    }
  },
);
//#endregion

export const loadPersonalSavedFilters = createAsyncThunk(
  `${SLICE_NAME}/loadPersonalSavedFilters`,
  async () => {
    const { data } = await new InstanceService().listPersonalSavedFilter();

    // Received data is an array but in order to have quick access an indexable object is preferable
    const indexableData: Record<FilterObject['id'], FilterObject> = {};

    // @ts-expect-error Missing endpoint type "/api/object/searchfilter/list"
    data.forEach(
      // @ts-expect-error Missing endpoint type "/api/object/searchfilter/list"
      (savedFilter) =>
        (indexableData[savedFilter.id] = {
          id: savedFilter.id,
          name: savedFilter.name,
          filters: savedFilter.conditions,
        }),
    );

    return {
      personalSavedFilters: indexableData,
    };
  },
);

export const savePersonalFilter = createAsyncThunk(
  `${SLICE_NAME}/saveFilters`,
  async (
    {
      name,
      filters,
    }: {
      name: FilterObject['name'];
      filters: FilterObject['filters'] | SearchPageSliceState['searchQuery'];
    },
    { getState },
  ) => {
    const { data: newSavedFilter } = await new InstanceService().createSavedFilter({
      name,
      filters,
    });

    const newIndexableData = { ...(getState() as RootState).search.savedFilters?.personal };
    // @ts-expect-error Missing endpoint type "/api/object/searchfilter/create"
    newIndexableData[newSavedFilter.id] = newSavedFilter;

    notify({
      type: 'success',
      title: 'COMBINATION_FILTERS_SAVED_TITLE',
      message: 'COMBINATION_FILTERS_SAVED_DESCRIPTION',
      messageValues: { filterSetName: name },
    });

    return {
      personalSavedFilters: newIndexableData,
    };
  },
);

export const deletePersonalSavedFilter = createAsyncThunk(
  `${SLICE_NAME}/deletePersonalSavedFilter`,
  async ({ filter }: { filter: FilterObject }, { getState }) => {
    await new InstanceService().deletePersonalSavedFilter({
      savedFilterId: filter.id,
    });

    notify({
      type: 'success',
      title: 'DELETE_SAVED_FILTER',
      message: 'SAVED_FILTER_DELETED',
      messageValues: { filterName: filter.name },
    });

    const newIndexableData = { ...(getState() as RootState).search.savedFilters.personal };
    delete newIndexableData[filter.id];

    return {
      personalSavedFilters: newIndexableData,
    };
  },
);

const SearchPageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<SearchPageSliceState['loading']>) => {
      state.loading = action.payload;
    },
    clearSearchData: (state) => {
      state.baseCondition = undefined;
      state.initialParent = undefined;
      state.parent = undefined;
    },
    setActiveTab: (state, action: PayloadAction<SearchPageSliceState['activeTab']>) => {
      state.activeTab = action.payload;
    },
    setSearchQuery: (
      state,
      action: PayloadAction<{ query: SearchPageSliceState['searchQuery'] }>,
    ) => {
      state.searchQuery = action.payload.query;
    },
    clearSearchQuery: (state) => {
      state.searchQuery = INITIAL_STATE.searchQuery;
    },
    updateRequest: (state, action: PayloadAction<Partial<SearchPageSliceState['request']>>) => {
      state.request = { ...state.request, ...action.payload };
    },
    setTriggerPage: (
      state,
      action: PayloadAction<Required<SearchPageSliceState>['triggerPage']>,
    ) => {
      state.triggerPage = action.payload;
    },
    setInitialParent: (
      state,
      action: PayloadAction<Required<SearchPageSliceState>['initialParent']>,
    ) => {
      state.initialParent = action.payload;
      state.parent = action.payload;
    },
    setBaseCondition: (
      state,
      action: PayloadAction<Required<SearchPageSliceState>['baseCondition']>,
    ) => {
      state.baseCondition = action.payload;
    },
    setSearchMode: (state, action: PayloadAction<SearchPageSliceState['searchMode']>) => {
      state.searchMode = action.payload;
    },
    setAdvancedFilters: (state, action: PayloadAction<SearchPageSliceState['advancedFilters']>) => {
      state.advancedFilters = action.payload;
    },
    addNewAdvancedFilter: (state) => {
      const currFilters = { ...state.advancedFilters };

      const newId = uuid();
      currFilters[newId] = { id: newId, option: FILTER_OPTIONS[0].value };

      state.advancedFilters = currFilters;
    },
    removeAdvancedFilter: (state, action: PayloadAction<{ filterId: AdvancedFilter['id'] }>) => {
      const { filterId } = action.payload;

      const currFilters = { ...state.advancedFilters };
      delete currFilters[filterId];

      state.advancedFilters = currFilters;
    },
    updateAdvancedFilter: (state, action: PayloadAction<{ updatedFilter: AdvancedFilter }>) => {
      const { updatedFilter } = action.payload;

      const currFilters = { ...state.advancedFilters };
      if (currFilters[updatedFilter.id]) {
        currFilters[updatedFilter.id] = updatedFilter;
      }

      state.advancedFilters = currFilters;
    },
    setAppliedFilterSet: (
      state,
      action: PayloadAction<SearchPageSliceState['appliedFilterSet']>,
    ) => {
      state.appliedFilterSet = action.payload;
    },
    setInfoPanelOpen: (state, action: PayloadAction<SearchPageSliceState['infoPanelOpen']>) => {
      state.infoPanelOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchObjects.pending, (state, action) => {
      if (!action.meta.arg.params.lazyload) {
        state.loading = true;
      }
      state.request.params.lazyload = action.meta.arg.params.lazyload;
      state.request.isNextPageLoading = true;
    });
    builder.addCase(searchObjects.fulfilled, (state, action) => {
      state.loading = false;
      if (state.request.params.lazyload) {
        state.request.params.offset += action.payload?.results.list.length;
      } else {
        state.request.params.offset = action.payload?.results.list.length;
      }
      state.request.hasNextPage =
        action.payload?.results.list.length === action.meta.arg.params.size;
      state.request.isNextPageLoading = false;
      state.request.params.lazyload = false;
      state.request.total = action.payload?.total;
    });
    builder.addCase(searchObjects.rejected, (state) => {
      state.loading = false;
      state.request.isNextPageLoading = false;
    });
    builder.addCase(resetAppState.type, () => {
      return INITIAL_STATE;
    });
    builder.addMatcher(
      isAnyOf(
        loadPersonalSavedFilters.fulfilled,
        savePersonalFilter.fulfilled,
        deletePersonalSavedFilter.fulfilled,
      ),
      (state, action) => {
        const { personalSavedFilters } = action.payload;

        state.savedFilters.personal = personalSavedFilters;
      },
    );
  },
});

export const searchPersistConfig = {
  key: 'search',
  storage: storageSession,
  whitelist: ['baseConditions', 'initialParent', 'parent', 'activeTab', 'triggerPage'],
};

export const {
  setLoading,
  clearSearchData,
  setActiveTab,
  setSearchQuery,
  clearSearchQuery,
  updateRequest,
  setTriggerPage,
  setInitialParent,
  setBaseCondition,
  setSearchMode,
  setAdvancedFilters,
  addNewAdvancedFilter,
  removeAdvancedFilter,
  updateAdvancedFilter,
  setAppliedFilterSet,
  setInfoPanelOpen,
} = SearchPageSlice.actions;

const searchReducer = persistReducer(searchPersistConfig, SearchPageSlice.reducer);

export default searchReducer;
