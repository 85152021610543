import { combineReducers } from 'redux';
import { intlReducer as intl } from 'react-intl-redux';
import { persistReducer } from 'redux-persist';
import localStorageMethod from 'redux-persist/lib/storage';
import searchReducer from 'Search/redux/SearchPageSlice';
import tableReducer from '_common/components/Table/TableSlice';
import hiddenContentReducer from 'Editor/redux/hiddenContentSlice';
import filtersReducer from '_common/components/Filters/FilterSlice';
import modalsReducer from '_common/modals/ModalsSlice';
import authReducer from 'Auth/redux/authSlice';
import localStorageReducer from 'Auth/redux/localStorageSlice';
import groupsReducer from 'Groups/redux/GroupsPageSlice';
import sharedListPageReducer from 'Shared/redux/SharedListPageSlice';
import storagePageReducer from 'Storage/pages/StoragePage/StoragePageSlice';
import sidebarReducer from '_common/components/Sidebar/SidebarSlice';
import onboardingReducer from 'App/redux/onboardingSlice';
import miniExplorerReducer from '_common/components/MiniExplorer/miniExplorerSlice';
import appReducer from 'App/redux/appSlice';
import publicReducer from 'App/redux/publicSlice';
import spacesListPageReducer from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import userManagement from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';
import SpellCheckReducer from 'Editor/pages/EditorPage/Sidepanel/SpellCheckTab/SpellcheckSlice';
import FindAndReplaceSliceReducer from 'Editor/redux/FindAndReplaceSlice';
import tabMenuReducer from '_common/components/TabMenu/TabMenuSlice';
import fontsReducer from 'App/redux/FontsSlice';
import listStylesReducer from 'Editor/redux/listStylesSlice';
import ToolbarReducer from 'Editor/redux/ToolbarSlice';
import notesReducer from 'Editor/redux/NotesSlice';
import api from '_common/services/api/api';
import authority from '_common/services/api/authority';
import navigationSliceReducer from 'Editor/redux/NavigationSlice';
import tocSliceReducer from 'Editor/redux/TocSlice';
import tasksSliceReducer from 'Editor/redux/TasksSlice';
import editorPermissionsReducer from 'Editor/redux/PermissionsSlice';
import trackingSliceReducer from 'Editor/redux/TrackingSlice';
import wordCountReducer from 'Editor/redux/wordCountSlice';
import trackedActionsSliceReducer from 'Editor/redux/TrackedActionsSlice';
import blockApprovalReducer from 'Editor/redux/BlockApprovalSlice';
import citationsReducer from 'Editor/redux/CitationsSlice';
import commentsReducer from 'Editor/redux/CommentsSlice';
import editorSidebarSliceReducer from 'Editor/redux/SidebarSlice';
import EditorStatusReducer from 'Editor/redux/EditorStatusSlice';
import stylesReducer from 'Editor/redux/StylesSlice';
import sectionsSliceReducer from 'Editor/redux/SectionsSlice';
import currentIntegrationReducer from 'Editor/redux/CurrentIntegrationSlice';
import editorLanguageReducer from 'Editor/redux/EditorLanguageSlice';
import notificationsReducer from '_common/components/Popovers/NotificationsPopover/NotificationsSlice';
import imageUploadSliceReducer from 'Editor/redux/ImageUploadSlice';
import AuditLogReducer from '_common/components/AuditLog/AuditLogSlice';
import PDFSliceReducer from 'PDF/redux/PDFGeneralSlice';
import PDFFindReducer from 'PDF/redux/PDFFindSlice';
import PDFAnnotationsReducer from 'PDF/redux/PDFAnnotationsSlice';
import realtime from '_common/services/api/realtime';
import objectPreviewReducer from '_common/components/ObjectPreview/ObjectPreviewSlice';
import PresentationSliceReducer from 'Presentation/PresentationSlice';
import TasksSlice from 'Presentation/TasksSlice';
import CommentsSlice from 'Presentation/CommentsSlice';
import FindSlice from 'Presentation/FindSlice';

export const intlPersistConfig = {
  key: 'intl',
  storage: localStorageMethod,
  whitelist: ['locale'],
};

const rootReducer = combineReducers({
  app: appReducer,
  auditLog: AuditLogReducer,
  auth: authReducer,
  editor: combineReducers({
    blockApproval: blockApprovalReducer,
    citations: citationsReducer,
    comments: commentsReducer,
    currentIntegration: currentIntegrationReducer,
    findAndReplace: FindAndReplaceSliceReducer,
    imageUpload: imageUploadSliceReducer,
    language: editorLanguageReducer,
    listStyles: listStylesReducer,
    navigation: navigationSliceReducer,
    notes: notesReducer,
    permissions: editorPermissionsReducer,
    sections: sectionsSliceReducer,
    sidebar: editorSidebarSliceReducer,
    spellcheck: SpellCheckReducer,
    status: EditorStatusReducer,
    styles: stylesReducer,
    tasks: tasksSliceReducer,
    toc: tocSliceReducer,
    toolbar: ToolbarReducer,
    trackedActions: trackedActionsSliceReducer,
    tracking: trackingSliceReducer,
    wordCount: wordCountReducer,
  }),
  filters: filtersReducer,
  fonts: fontsReducer,
  groups: groupsReducer,
  hiddenContent: hiddenContentReducer,
  intl: persistReducer(intlPersistConfig, intl),
  localStorage: localStorageReducer,
  miniExplorer: miniExplorerReducer,
  modals: modalsReducer,
  notifications: notificationsReducer,
  objectPreview: objectPreviewReducer,
  onboarding: onboardingReducer,
  pdf: combineReducers({
    general: PDFSliceReducer,
    find: PDFFindReducer,
    annotations: PDFAnnotationsReducer,
  }),
  presentation: combineReducers({
    general: PresentationSliceReducer,
    tasks: TasksSlice.reducer,
    comments: CommentsSlice.reducer,
    find: FindSlice.reducer,
  }),
  public: publicReducer,
  search: searchReducer,
  shared: sharedListPageReducer,
  sidebar: sidebarReducer,
  spaces: spacesListPageReducer,
  storage: storagePageReducer,
  table: tableReducer,
  tabMenu: tabMenuReducer,
  userManagement,
  [api.reducerPath]: api.reducer,
  [authority.reducerPath]: authority.reducer,
  [realtime.reducerPath]: realtime.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
