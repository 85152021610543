import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { ELEMENTS } from 'Editor/services/consts';

export class HTMLSelectionNormalizer {
  protected options: Editor.Selection.FixerOptions = {
    suggestionMode: false,
    forceTextAsWrap: false,
    containerPosition: undefined,
    forceWrapAsText: false,
    forceNonEditableDirection: null,
    isDelete: false, // for selections inside non-editable elements, it should not fix selection, except when it is at the end of that element
    isBackspace: false,
  };

  nonEditableElements: string[];
  wrapInlineElements: string[];
  textInlineElements: string[];
  possibleParagraphMarkers: string[];
  nonWrapinlineElements: string[];

  constructor(args: Partial<Editor.Selection.FixerOptions>) {
    this.options = {
      ...this.options,
      ...args,
    };

    this.nonEditableElements = [...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS];
    this.wrapInlineElements = [...EditorDOMElements.INLINE_WRAP_ELEMENTS];
    this.textInlineElements = [...EditorDOMElements.INLINE_TEXT_ELEMENTS];

    this.possibleParagraphMarkers = [
      ELEMENTS.TrackInsertElement.TAG,
      ELEMENTS.TrackDeleteElement.TAG,
    ];

    if (this.options.suggestionMode) {
      this.nonEditableElements.push(ELEMENTS.TrackDeleteElement.TAG);
      this.textInlineElements.push(ELEMENTS.TrackInsertElement.TAG);
    } else {
      this.wrapInlineElements.push(ELEMENTS.TrackInsertElement.TAG);
      this.textInlineElements.push(ELEMENTS.TrackDeleteElement.TAG);
    }

    if (this.options.forceTextAsWrap) {
      this.wrapInlineElements = [...this.wrapInlineElements, ...this.textInlineElements];
      this.textInlineElements = [];
    } else if (this.options.forceWrapAsText) {
      this.textInlineElements = [...this.textInlineElements, ...this.wrapInlineElements];
      this.wrapInlineElements = [];
    }

    this.nonWrapinlineElements = [...this.nonEditableElements, ...this.textInlineElements];
  }

  normalize(range: Editor.Selection.EditorRange) {
    let anchorNode: Node = range.startContainer;
    let anchorOffset: number = range.startOffset;
    let fixDirection: 'BACKWARD' | 'FORWARD' | null = null;

    if (this.options.containerPosition === 'end') {
      anchorNode = range.endContainer;
      anchorOffset = range.endOffset;
    }

    const result = this.getNodeOffsetToFix(range, anchorNode, anchorOffset);

    anchorNode = result.node;
    anchorOffset = result.offset;
    fixDirection = result.fixDirection;

    // drill down to the deepest child by direction
    if (fixDirection && fixDirection === 'BACKWARD') {
      const result = this.drillDownBackwards(anchorNode, anchorOffset);
      anchorNode = result.node;
      anchorOffset = result.offset;
    } else if (fixDirection === 'FORWARD') {
      const result = this.drillDownForward(anchorNode, anchorOffset);
      anchorNode = result.node;
      anchorOffset = result.offset;
    }

    this.applyNodeOffsetToRange(range, anchorNode, anchorOffset);
  }

  private drillDownToClosestChild(node: Node, offset: number) {
    if (
      node.childNodes[offset] != null &&
      !EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(node.childNodes[offset].nodeName)
    ) {
      // drill down to next
      node = node.childNodes[offset];
      offset = 0;
    } else if (
      node.childNodes[offset - 1] != null &&
      !EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(
        node.childNodes[offset - 1].nodeName,
      )
    ) {
      // drill down to previous
      const childNode = node.childNodes[offset - 1];
      const length = childNode instanceof Text ? childNode.length : childNode.childNodes.length;
      if (length) {
        node = childNode;
        offset = length;
      }
    }

    return { node, offset };
  }

  private drillDownBackwards(node: Node, offset: number) {
    while (
      node?.nodeType !== Node.TEXT_NODE &&
      node?.childNodes &&
      node.childNodes.length > 0 &&
      offset !== null &&
      offset >= 0 &&
      offset <= node.childNodes.length &&
      node.childNodes[offset - 1]
    ) {
      const childNode: Node = node.childNodes[offset - 1];
      if (
        !EditorDOMElements.isNodeInlineFrontendElement(childNode) &&
        !this.nonEditableElements.includes(childNode.nodeName) &&
        !this.wrapInlineElements.includes(childNode.nodeName) &&
        (childNode instanceof Text ||
          (EditorDOMElements.isSupportedElement(childNode) && childNode.isEditable !== false))
      ) {
        node = childNode;
        offset = node instanceof Text ? node.length : node.childNodes.length;
      } else if (
        EditorDOMElements.INLINE_LAST_CHILD_ELEMENTS.includes(childNode.nodeName) &&
        !this.options.isDelete &&
        !this.options.isBackspace
      ) {
        offset = offset - 1;
      } else {
        break;
      }
    }

    return { node, offset };
  }

  private drillDownForward(node: Node, offset: number) {
    while (
      node &&
      node.nodeType !== Node.TEXT_NODE &&
      node.childNodes.length > 0 &&
      offset !== null &&
      offset >= 0 &&
      offset <= node.childNodes.length &&
      node.childNodes[offset] &&
      !EditorDOMElements.isNodeInlineFrontendElement(node.childNodes[offset]) &&
      !this.nonEditableElements.includes(node.childNodes[offset].nodeName) &&
      !this.wrapInlineElements.includes(node.childNodes[offset].nodeName) &&
      (node.childNodes[offset] as Editor.Elements.BaseViewElement).isEditable !== false
    ) {
      node = node.childNodes[offset];
      offset = 0;
    }
    return { node, offset };
  }

  private getNodeOffsetToFix(
    range: Editor.Selection.EditorRange,
    anchorNode: Node,
    anchorOffset: number,
  ) {
    let blockNode: Node | null;
    let node: Node = anchorNode;
    let offset: number = anchorOffset;
    let fixDirection: 'BACKWARD' | 'FORWARD' | null = null;

    // find blockNode
    let ancestorContainer = EditorDOMUtils.getContentContainer(node);
    const closestContainer = EditorDOMUtils.closestMultiBlockContainerElement(
      range.commonAncestorContainer,
    );
    if (closestContainer) {
      blockNode = EditorDOMUtils.findFirstLevelChildNode(closestContainer, node);
    } else {
      blockNode = EditorDOMUtils.findFirstLevelChildNode(ancestorContainer, node);
    }

    if (blockNode && EditorDOMElements.isNodeBlockTextElement(blockNode)) {
      // fix anchor node to closest child
      if (node === blockNode) {
        const result = this.drillDownToClosestChild(node, offset);

        node = result.node;
        offset = result.offset;

        // update range
        this.applyNodeOffsetToRange(range, node, offset);
      }

      let closestInlineElement =
        EditorDOMUtils.closest(node, this.nonEditableElements) ||
        EditorDOMUtils.closest(node, this.nonWrapinlineElements);

      const closestInlineWrapElement = EditorDOMUtils.closest(node, [...this.wrapInlineElements]);

      if (
        node &&
        this.possibleParagraphMarkers.includes(node.nodeName) &&
        EditorDOMElements.isTrackedElement(node) &&
        node.isParagraphMarker()
      ) {
        // fix for paragraph markers
        fixDirection = 'BACKWARD';
        const childNodes = node.parentNode?.childNodes as NodeListOf<Node>;
        offset = Array.from(childNodes).indexOf(node);
        node = node.parentNode as Node;
      } else if (
        closestInlineWrapElement &&
        (!closestInlineElement ||
          !this.nonEditableElements.includes(closestInlineElement.nodeName)) &&
        closestInlineWrapElement.parentNode !== ancestorContainer &&
        (range.isAtNodeStart(closestInlineWrapElement, this.options.containerPosition) ||
          range.isAtNodeEnd(closestInlineWrapElement, this.options.containerPosition))
      ) {
        if (range.isAtNodeEnd(closestInlineWrapElement, this.options.containerPosition)) {
          // check for wrapper elements
          fixDirection = 'FORWARD';
          node = closestInlineWrapElement.parentNode as Node;

          const childNodes = node.childNodes as NodeListOf<Node>;
          offset = Array.from(childNodes).indexOf(closestInlineWrapElement) + 1;

          while (
            node &&
            this.wrapInlineElements.includes(node.nodeName) &&
            offset === node.childNodes?.length
          ) {
            const childNodes = node.parentNode?.childNodes as NodeListOf<Node>;
            offset = Array.from(childNodes).indexOf(node) + 1;
            node = node.parentNode as Node;
          }
        } else if (range.isAtNodeStart(closestInlineWrapElement, this.options.containerPosition)) {
          fixDirection = 'BACKWARD';
          node = closestInlineWrapElement.parentNode as Node;
          const childNodes = node.childNodes as NodeListOf<Node>;
          offset = Array.from(childNodes).indexOf(closestInlineWrapElement);

          while (node && this.wrapInlineElements.includes(node.nodeName) && offset === 0) {
            const childNodes = node.parentNode?.childNodes as NodeListOf<Node>;
            offset = Array.from(childNodes).indexOf(node);
            node = node.parentNode as Node;
          }
        }
      } else if (
        this.wrapInlineElements.includes(node.nodeName) &&
        offset > 0 &&
        offset < node.childNodes.length
      ) {
        fixDirection = 'BACKWARD';
      } else if (
        closestInlineElement != null &&
        (this.nonEditableElements.includes(closestInlineElement.nodeName) ||
          (closestInlineElement as Editor.Elements.BaseViewElement).isEditable === false) &&
        (!this.options.isDelete ||
          range.isAtNodeEnd(closestInlineElement, this.options.containerPosition)) &&
        (!this.options.isBackspace ||
          range.isAtNodeStart(closestInlineElement, this.options.containerPosition))
      ) {
        // check for citations

        if (
          closestInlineElement &&
          (EditorDOMElements.INLINE_LAST_CHILD_ELEMENTS.includes(closestInlineElement.nodeName) ||
            range.isAtNodeStart(closestInlineElement as Node, this.options.containerPosition) ||
            this.options.forceNonEditableDirection === 'BACKWARD')
        ) {
          fixDirection = 'BACKWARD';
          const previousSibling = EditorDOMUtils.findPreviousAncestorSibling(
            closestInlineElement,
            blockNode,
          );

          if (previousSibling) {
            if (
              !this.nonEditableElements.includes(previousSibling.nodeName) &&
              !this.wrapInlineElements.includes(previousSibling.nodeName)
            ) {
              node = previousSibling;
              offset = node instanceof Text ? node.length : node.childNodes.length;
            } else {
              node = previousSibling.parentNode as Node;
              const childNodes = node?.childNodes as NodeListOf<Node>;
              offset = Array.from(childNodes).indexOf(previousSibling) + 1;
            }
          } else {
            node = closestInlineElement?.parentNode as Node;
            const childNodes = node?.childNodes as NodeListOf<Node>;
            offset = Array.from(childNodes).indexOf(closestInlineElement);
          }
        } else if (
          this.options.forceNonEditableDirection !== 'BACKWARD' && // FORWARD or null
          closestInlineElement
        ) {
          fixDirection = 'FORWARD';
          const nextSibling = EditorDOMUtils.findNextAncestorSibling(
            closestInlineElement,
            blockNode,
          );

          if (nextSibling) {
            if (
              !this.nonEditableElements.includes(nextSibling.nodeName) &&
              !this.wrapInlineElements.includes(nextSibling.nodeName) &&
              !(EditorDOMElements.isTrackedElement(nextSibling) && nextSibling.isParagraphMarker())
            ) {
              node = nextSibling;
              offset = 0;
            } else {
              node = nextSibling.parentNode as Node;
              const childNodes = node?.childNodes as NodeListOf<Node>;
              offset = Array.from(childNodes).indexOf(nextSibling);
            }
          } else {
            node = closestInlineElement?.parentNode as Node;
            const childNodes = node?.childNodes as NodeListOf<Node>;
            offset = Array.from(childNodes).indexOf(closestInlineElement) + 1;
          }
        }
      } else if (
        this.textInlineElements.includes(node.nodeName) &&
        offset > 0 &&
        offset <= node.childNodes.length
      ) {
        fixDirection = 'BACKWARD';
      } else if (offset === 0 && !this.options.isBackspace && !this.options.isDelete) {
        // if anchor offset is 0 and there is a previous sibling that is editable
        // fixes selection to the previous text element
        let previousElement = EditorDOMUtils.findPreviousAncestorSibling(node, blockNode);

        if (
          previousElement &&
          (previousElement instanceof Text ||
            this.textInlineElements.includes(previousElement.nodeName))
          // && previousElement?.isVanish !== true TODO: Implement with hidden content full fucntionality
        ) {
          fixDirection = 'BACKWARD';
          node = previousElement;
          offset =
            previousElement instanceof Text
              ? previousElement.length
              : previousElement.childNodes.length;
        } else if (node instanceof Text || this.textInlineElements.includes(node.nodeName)) {
          fixDirection = 'FORWARD';
        }
      }
    }

    return { node, offset, fixDirection };
  }

  private applyNodeOffsetToRange(range: Editor.Selection.EditorRange, node: Node, offset: number) {
    if (this.options.containerPosition === 'start') {
      range.setStart(node, offset);
    } else if (this.options.containerPosition === 'end') {
      range.setEnd(node, offset);
    } else {
      range.setStart(node, offset);
      range.setEnd(node, offset);
    }
  }
}
