import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useDispatch, useSelector, usePublicGroup } from '_common/hooks';
import { navigateToMyFiles } from 'router/history';

import { setCurrentAppPage } from 'App/redux/appSlice';

import { TabMenu, MenuItem, ErrorView, IntlErrorBoundary, DocumentTitle } from '_common/components';

import GeneralView from './GeneralView';
import MembersView from './MembersView/MembersView';
import AdminsView from './AdminsView/AdminsView';

import styles from './GroupSettingsPage.module.scss';
import Header from './Header';

const GroupSettingsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id: groupId }: { id: ObjectId } = useParams();
  const { group: data } = usePublicGroup(groupId);

  const error = useSelector((state) => state.app.error);

  useEffect(() => {
    dispatch(setCurrentAppPage('/settings/group'));
  }, []);

  const checkErrorStatus = () => {
    return error.status === 400 || error.status === 403 || error.status === 404;
  };

  // check for forbidden view
  if (checkErrorStatus()) {
    return <ErrorView error={error} onClick={navigateToMyFiles} />;
  }

  if (!data) {
    return <div />;
  }

  return (
    <DocumentTitle title={`doDOC - ${data.name}`}>
      <div className={styles.root}>
        <div className={styles.header}>
          <IntlErrorBoundary size="small" margin="0 0 0 7rem">
            <Header />
          </IntlErrorBoundary>
        </div>
        <div className={styles.page}>
          <IntlErrorBoundary size="large" margin="13.5rem 0 0 0">
            <TabMenu menuId="groupSettings">
              <MenuItem text={intl.formatMessage({ id: 'settings.tabs.general' })} id="general">
                <GeneralView />
              </MenuItem>

              <MenuItem
                text={intl.formatMessage({ id: 'settings.tabs.admins' })}
                id="admins"
                noOverflow
              >
                <AdminsView />
              </MenuItem>

              <MenuItem
                text={intl.formatMessage({ id: 'groups.header.member' }, { membersCount: 0 })}
                id="members"
                noOverflow
              >
                <MembersView />
              </MenuItem>
            </TabMenu>
          </IntlErrorBoundary>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default GroupSettingsPage;
