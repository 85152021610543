import { useIntl } from 'react-intl';
import { ProgressBar } from 'dodoc-design-system';
import LogoContainer from '_common/components/LogoContainer/LogoContainer';

import styles from './LoadingPage.module.scss';

const LoadingPage = () => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <LogoContainer size="large" expanded color="white" testId="loading-page-logo" />
        <div style={{ marginTop: '2rem' }}>
          <ProgressBar
            color="primaryDark"
            size="medium"
            label={intl.formatMessage({ id: 'LOADING_DODOC' })}
            testId="loading-page-progressBar"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
