import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks/redux';
import { Button, Modal, Input, SectionHeader, InputField, Checkbox } from 'dodoc-design-system';

import { closeModal } from '_common/modals/ModalsSlice';

import EditorManager from 'Editor/services/EditorManager';

import styles from './CustomSpacingModal.module.scss';
import { useState } from 'react';

const CustomSpacingModal = () => {
  const intl = useIntl();

  const isOpen = useSelector((state) => state.modals.open.CustomSpacingModal);
  const spacing = useSelector((state) => state.modals.CustomSpacingModal.spacing);
  const [state, setState] = useState<Editor.Elements.SpacingProperties>(spacing);
  const dispatch = useDispatch();

  const handleBeforeAS = () => {
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      asb: !state.asb,
    }));
  };
  const handleAfterAS = () => {
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      asa: !state.asa,
    }));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleApply = () => {
    EditorManager.getInstance().lineSpaceCurrentSelection({
      ...state,
    });
    close();
  };

  const close = () => {
    dispatch(closeModal('CustomSpacingModal'));
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="65rem" testId="custom-spacing">
      <Modal.Header onClose={close}>
        <FormattedMessage id="editor.modals.customSpacing.header" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.section}>
          <SectionHeader>
            <FormattedMessage id="editor.modals.customSpacing.lineSpacing" />
          </SectionHeader>
        </div>
        <div className={styles.content}>
          <Input
            size="large"
            name="lineHeight"
            onChange={onInputChange}
            value={`${state.lineHeight}`}
            type="number"
            step="0.1"
            min="0"
            placeholder=""
            testId="custom-spacing-line-spacing"
          />
        </div>
        <div className={styles.section}>
          <SectionHeader>
            <FormattedMessage id="PARAGRAPH_SPACING" />
          </SectionHeader>
        </div>
        <div className={styles.content}>
          <div className={styles.paragraphSpacing}>
            <div className={styles.column}>
              <InputField
                label={intl.formatMessage({ id: 'editor.modals.customSpacing.spacingBefore' })}
                testId="space-before-field"
              >
                <Input
                  size="large"
                  name="spaceBefore"
                  onChange={onInputChange}
                  value={`${state.spaceBefore}`}
                  type="number"
                  step="1"
                  min="0"
                  disabled={!!state.asb}
                  placeholder=""
                  testId="custom-spacing-space-before"
                />
              </InputField>
              <Checkbox
                onChange={handleBeforeAS}
                checked={state.asb ? 'checked' : 'unchecked'}
                size="small"
                testId="custom-spacing-auto-spacing-checkbox"
              >
                <FormattedMessage id="AUTO_SPACING" />
              </Checkbox>
            </div>
            <div className={styles.column}>
              <InputField
                label={intl.formatMessage({ id: 'editor.modals.customSpacing.spacingAfter' })}
                testId="space-after-field"
              >
                <Input
                  size="large"
                  name="spaceAfter"
                  onChange={onInputChange}
                  value={`${state.spaceAfter}`}
                  type="number"
                  step="1"
                  min="0"
                  placeholder=""
                  disabled={!!state.asa}
                  testId="custom-spacing-space-after"
                />
              </InputField>
              <Checkbox
                onChange={handleAfterAS}
                checked={state.asa ? 'checked' : 'unchecked'}
                size="small"
                testId="custom-spacing-auto-spacing-checkbox"
              >
                <FormattedMessage id="AUTO_SPACING" />
              </Checkbox>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="custom-spacing-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button size="medium" variant="primary" onClick={handleApply} testId="custom-spacing-submit-button">
          <FormattedMessage id="APPLY_CHANGES" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomSpacingModal;
