import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, Tooltip, usePopper, Toggle } from 'dodoc-design-system';
import { AxiosError } from 'axios';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { navigateToMyFiles, navigateToObject, navigateToShared } from 'router/history';
import { InstanceService } from '_common/services';

import { openModal as deprecatedOpenModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { updateMiniExplorer } from '_common/components/MiniExplorer/miniExplorerSlice';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';
import { isChangeStatusEnabled, selectIsIEnvision } from 'App/redux/appSlice';
import { selectReadOnlyMode, setRenamingDocumentValue } from 'Editor/redux/EditorStatusSlice';
import {
  selectUserCurrentTenant,
  selectUserIsAdmin,
  useGetCurrentUserQuery,
  useGetLinksQuery,
} from '_common/services/api/authority';
import { useSuiteObject } from '_common/suite';
import { useAuditLogModal } from 'Editor/hooks';

const File = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserQuery();
  const currentTenant = useSelector(selectUserCurrentTenant);

  const { data: links } = useGetLinksQuery(
    { userId: currentUser?.profile.id ?? '', tenant: currentTenant?.name ?? '' },
    { skip: !currentUser?.other.tenants || !currentTenant },
  );

  const object = useSuiteObject();
  const { openAuditLogModal } = useAuditLogModal();
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const information = useSelector((state) => state.app.information);
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const statusChangeErrorId = useSelector((state) => isChangeStatusEnabled(state, object.id));
  const isiEnvision = useSelector(selectIsIEnvision);
  const [language, setLanguage] = useState();

  const { data: statuses } = useGetElementStatusListQuery();
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (isOpen) {
      setDocumentLanguage();
    }
  }, [isOpen]);

  const openMoveTo = () => {
    const current = object.parent
      ? {
          id: object.parent,
          type: 'folder',
        }
      : {
          id: object.space,
          type: 'space',
        };

    //@ts-expect-error Editor Selector isn't TS
    dispatch(updateMiniExplorer({ current }));
    dispatch(
      openAndUpdateModal({
        modal: 'MoveModal',
        data: {
          current,
          sources: [object.id],
        },
      }),
    );
  };

  const changeStatus = async () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ChangeElementStatusModal',
        data: {
          objectId: object.id,
          objectType: object.type,
        },
      }),
    );
  };

  const saveAs = () => {
    const current = object.parent
      ? {
          id: object.parent,
          type: 'folder',
        }
      : {
          id: object.space,
          type: 'space',
        };

    //@ts-expect-error Editor Selector isn't TS
    dispatch(updateMiniExplorer({ current }));
    dispatch(
      openAndUpdateModal({
        modal: 'SaveAsModal',
        data: {
          current,
          objectIds: [object.id],
        },
      }),
    );
  };

  const openDocumentLanguageModal = () => {
    dispatch(openAndUpdateModal({ modal: 'SetLanguageModal', data: { onSelection: false } }));
  };
  const openIntegrationModal = (exportType: string) => {
    if (exportType === 'veeva' || exportType === 'jti') {
      dispatch(
        openAndUpdateModal({
          modal: 'PublishVeevaModal',
          data: { id: object.id, explorer: false },
        }),
      );
    } else {
      dispatch(
        openAndUpdateModal({
          modal: 'ExportDocumentModal',
          data: {
            exportType,
            exporting: false,
            objectId: object.id,
          },
        }),
      );
    }
  };

  const setDocumentLanguage = () => {
    const defaultLanguage = EditorManager.getInstance().getSpellCheckDefaultLanguage();
    const languages = EditorManager.getInstance().getSpellCheckLanguages();

    setLanguage(
      //@ts-expect-error EditorManager not TS
      languages.find(({ value }) =>
        //@ts-expect-error Object not including lang
        object.lan ? value === object.lang.code : value === defaultLanguage,
      ),
    );
  };

  const handleOpenSaveVersion = () => {
    EditorManager.getInstance().openSaveVersion();
  };

  const handleImportNewVersion = () => {
    dispatch(deprecatedOpenModal('ImportNewVersionModal'));
  };

  const handleOpenVersionHistory = () => {
    EditorManager.getInstance().openVersionHistory();
  };

  const canPerformActions = !isiEnvision;

  const goBack = () => {
    if (!canPerformActions && information?.extra?.source) {
      window.location.href = `${information.extra.source}`;
    } else {
      const parentId = object.parent ? object.parent : object.space;
      const parentType = object.parent ? 'folder' : 'space';
      new InstanceService({ errorsExpected: [403] })
        .getObjectData({ objectId: parentId, objectType: parentType })
        .then(() => {
          navigateToObject(parentType, parentId);
        })
        .catch((error) => {
          if (InstanceService.isAxiosError(error)) {
            if ((error as AxiosError).response?.status === 403) {
              navigateToShared();
            } else {
              navigateToMyFiles();
            }
          }
        });
    }
  };

  const openDocumentDetailsModal = () => {
    dispatch(setSelectedTab({ menuId: 'documentDetails', selectedTab: 'general' }));
    dispatch(deprecatedOpenModal('DocumentDetailsModal'));
  };

  const openAuthorsAndKeywords = () => {
    dispatch(setSelectedTab({ menuId: 'documentDetails', selectedTab: 'authors' }));
    dispatch(deprecatedOpenModal('DocumentDetailsModal'));
  };

  const openTemplateSettings = () => {
    dispatch(setSelectedTab({ menuId: 'documentDetails', selectedTab: 'template' }));
    dispatch(deprecatedOpenModal('DocumentDetailsModal'));
  };

  const openPageSetup = () => {
    dispatch(deprecatedOpenModal('PageSetupModal'));
  };

  const isVeevaExportDisabled = () => {
    const status = statuses?.entities[object.status];
    return !status || (status.id !== 'approved' && status.name !== 'Archived');
  };

  const openDocumentAuditLog = () => {
    openAuditLogModal();
  };

  const renderChangeStatus = () => {
    return (
      <Tooltip
        placement="right"
        content={
          statusChangeErrorId.errorId
            ? intl.formatMessage({
                id: statusChangeErrorId.errorId,
              })
            : ''
        }
        disabled={!statusChangeErrorId.errorId}
        testId="change-status-dropdown-item-tooltip"
      >
        <span>
          <Dropdown.Item
            onClick={changeStatus}
            disabled={statusChangeErrorId.errorId !== undefined}
            testId="change-status-dropdown-item"
          >
            <FormattedMessage id="CHANGE_DOCUMENT_STATUS" />
          </Dropdown.Item>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={isOpen}
        margin="0 1rem 0 0"
        testId="file-button"
        {...referenceProps}
      >
        <FormattedMessage id="editor.menu.file.value" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-file">
        <Dropdown.Item
          onClick={() => openIntegrationModal('simple')}
          disabled={!canPerformActions}
          testId="export-dropdown-item"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="export-dropdown-item-tooltip"
          >
            <span>
              <FormattedMessage id="global.export" />
            </span>
          </Tooltip>
        </Dropdown.Item>

        {links?.providers && links.providers.length > 0 && (
          <Dropdown.SubMenu
            disabled={isReadOnlyMode && object.status !== 'approved'}
            itemContent={<FormattedMessage id="EXPORT_TO" />}
            testId="export-to-dropdown-submenu"
          >
            {links.providers.map((link) => {
              return (
                <Tooltip
                  key={link.name}
                  placement="right"
                  disabled={
                    (link.name !== 'veeva' && link.name !== 'jti') || !isVeevaExportDisabled()
                  }
                  content={intl.formatMessage({
                    id: 'This document does not have the required status to be exported to Veeva',
                  })}
                  testId={`export-to-${link.name}-dropdown-item-tooltip`}
                >
                  <div>
                    <Dropdown.Item
                      onClick={() => openIntegrationModal(link.name)}
                      disabled={
                        (link.name === 'veeva' || link.name === 'jti') && isVeevaExportDisabled()
                      }
                      testId={`export-to-${link.name}-dropdown-item`}
                    >
                      {link.label.charAt(0).toUpperCase() + link.label.slice(1)}
                    </Dropdown.Item>
                  </div>
                </Tooltip>
              );
            })}
          </Dropdown.SubMenu>
        )}
        <Divider />
        <Dropdown.Item
          onClick={openDocumentDetailsModal}
          disabled={!canPerformActions}
          testId="document-details-dropdown-item"
        >
          <Tooltip
            placement="right"
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="document-details-dropdown-item-tooltip"
          >
            <div>
              <FormattedMessage id="DOCUMENT_DETAILS" />
            </div>
          </Tooltip>
        </Dropdown.Item>
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="DOCUMENT_LANGUAGE" />}
          testId="document-language-dropdown-submenu"
        >
          {language && (
            <>
              {/* @ts-expect-error EditorManager not TS */}
              <Dropdown.Item testId="language-dropdown-item">{language.label}</Dropdown.Item>
              <Divider />
            </>
          )}
          <Dropdown.Item
            onClick={openDocumentLanguageModal}
            disabled={isReadOnlyMode}
            testId="change-language-dropdown-item"
          >
            <FormattedMessage id="CHANGE_LANGUAGE" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.Item
          onClick={openAuthorsAndKeywords}
          disabled={!canPerformActions}
          testId="authors-keywords-dropdown-item"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="authors-keywords-dropdown-item-tooltip"
          >
            <span>
              <FormattedMessage id="AUTHORS_AND_KEYWORDS" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={openTemplateSettings}
          disabled={!canPerformActions}
          testId="template-settings-dropdwon-item"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="template-settings-dropdwon-item-tooltip"
          >
            <span>
              <FormattedMessage id="TEMPLATE_SETTINGS" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        <Dropdown.Item onClick={openPageSetup} testId="page-steup-dropdown-item">
          <FormattedMessage id="PAGE_SETUP" />
        </Dropdown.Item>
        <Divider />
        {renderChangeStatus()}
        <Divider />
        <Dropdown.Item
          onClick={saveAs}
          disabled={!canPerformActions}
          testId="save-a-copy-dropdown-item"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="save-a-copy-dropdown-item-tooltip"
          >
            <span>
              <FormattedMessage id="SAVE_A_COPY" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={openMoveTo}
          disabled={isReadOnlyMode || !canPerformActions}
          testId="move-to-dropdown-item"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="move-to-dropdown-item-tooltip"
          >
            <span>
              <FormattedMessage id="storage.actionBar.actions.moveto" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => dispatch(setRenamingDocumentValue(true))}
          disabled={isReadOnlyMode || !canPerformActions}
          testId="rename-dropdown-item"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            disabled={canPerformActions}
            testId="rename-dropdown-item-tooltip"
          >
            <span>
              <FormattedMessage id="global.rename" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        {(object.user_permissions.includes('owner') || userIsAdmin) && canPerformActions && (
          <>
            <Divider />
            <Dropdown.Item
              onClick={handleImportNewVersion}
              disabled={isReadOnlyMode}
              testId="import-new-version-dropdown-item"
            >
              <FormattedMessage id="IMPORT_NEW_VERSION" />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleOpenSaveVersion}
              disabled={isReadOnlyMode}
              testId="save-version-dropdown-item"
            >
              <FormattedMessage id="SAVE_VERSION" />
            </Dropdown.Item>
          </>
        )}
        {(object.user_permissions.includes('owner') || userIsAdmin) &&
          object.lifecycle.versions &&
          object.lifecycle.versions.length > 0 && (
            <Dropdown.Item
              onClick={handleOpenVersionHistory}
              testId="view-version-history-dropdown-item"
            >
              <FormattedMessage id="VIEW_VERSION_HISTORY" />
            </Dropdown.Item>
          )}
        <Divider />
        {object.user_permissions.includes('owner') && (
          <>
            <Dropdown.Item onClick={openDocumentAuditLog} testId="audit-log-dropdown-item">
              <FormattedMessage id="DOCUMENT_AUDIT_LOG" />
            </Dropdown.Item>
            <Divider />
          </>
        )}
        <Dropdown.Item onClick={goBack} testId="back-to-dropdown-item">
          {!canPerformActions ? (
            <FormattedMessage id="BACK_TO_IENVISION" />
          ) : (
            <FormattedMessage id="editor.menu.file.backToExplorer" />
          )}
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default File;
