import { ChangeEventHandler, DragEvent, SetStateAction, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, Upload } from 'dodoc-design-system';

import { useDebounce, useDispatch, useSelector } from '_common/hooks';

import { updateModal } from '_common/modals/ModalsSlice';
import { selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { importCitations } from 'Editor/redux/CitationsSlice';

import CitationsList from '../CitationsList/CitationsList';
import CitationInformation from '../CitationInformation/CitationInformation';
import NavigateToDoDOC from '../NavigateToDoDOC/NavigateToDoDOC';

import styles from './ImportCitations.module.scss';
import { getSanitizedEntityName } from '../utils';

type ImportCitationsProps = {
  navigationDoDOC?: boolean;
  setNavigationDoDOC: (value: SetStateAction<boolean>) => void;
};

const ImportCitations = ({ navigationDoDOC, setNavigationDoDOC }: ImportCitationsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const citations = useSelector((state) => state.modals.CitationsModal.import) as {
    dropzone: boolean;
    loading: boolean;
    list: ObjectId[];
    dict: Record<ObjectId, Citation>;
  };
  const selected = useSelector((state) => state.table.selected);

  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 300);
  const filteredCitations = useMemo(
    () => ({
      ...citations,
      list: citations?.list?.filter((id) => {
        // @ts-expect-error CitationSchema issue: missing 'journal'
        const { title, author: authors, journal, year } = citations.dict[id];
        return (
          filter === '' ||
          (title && title.toLowerCase().includes(filter.toLowerCase())) ||
          (authors &&
            !!authors.find((author) =>
              getSanitizedEntityName(author)?.toLowerCase().includes(filter.toLowerCase()),
            )) ||
          (journal && journal.toLowerCase().includes(filter.toLowerCase())) ||
          (year && year.toString().includes(filter.toString()))
        );
      }),
    }),
    [citations, debouncedFilter],
  );

  const setDropzoneVisible = (visible: boolean) => {
    dispatch(
      updateModal({
        modal: 'CitationsModal',
        data: {
          import: {
            ...citations,
            dropzone: visible,
          },
        },
      }),
    );
  };

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilter(e.target.value);
  };

  const handleCitationsImport = (_: DragEvent<HTMLDivElement>, acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      dispatch(importCitations({ file: acceptedFiles[0] }));
    } else {
      setDropzoneVisible(false);
    }
  };

  const renderImportedContent = () => {
    if (navigationDoDOC) {
      return (
        <NavigateToDoDOC
          menuOptions={[
            { label: 'myFiles', icon: 'Folder', identity: 'storage' },
            { label: 'shared', icon: 'Share', identity: 'shared' },
            { label: 'spaces', icon: 'Folder', identity: 'spaces' },
          ]}
          menuSelected={{ label: 'myFiles', icon: 'Folder', identity: 'storage' }}
          title={intl.formatMessage({ id: 'IMPORT_FILES_FROM_DODOC' })}
        />
      );
    }
    return (
      <>
        <div className={styles.list}>
          <div className={styles.filter}>
            <Input
              prefix="NavSearchBlue"
              value={filter}
              onChange={handleFilterChange}
              placeholder={intl.formatMessage({ id: 'global.filter' })}
              disabled={isReadOnlyMode}
              width="100%"
              size="medium"
              testId="import-citations-search"
            />
          </div>
          <CitationsList
            loading={citations.loading}
            citations={filteredCitations}
            handleOnImportClick={() => {
              dispatch(
                updateModal({
                  modal: 'CitationsModal',
                  data: {
                    import: {
                      dropzone: true,
                    },
                  },
                }),
              );
            }}
          />
        </div>
        <div className={styles.information}>
          <CitationInformation
            citationId={Object.keys(selected)[0]}
            disabled
            multiple={Object.keys(selected).length > 1}
          />
        </div>
      </>
    );
  };

  const renderDropzone = () => {
    return (
      <div className={styles.dropzone}>
        <Upload
          onDrop={handleCitationsImport}
          accept=".bib,.xml"
          text={intl.formatMessage({ id: 'editor.modals.citations.dropFile' })}
          label={intl.formatMessage({ id: 'BROWSE_YOUR_COMPUTER' })}
          secondButtonLabel={intl.formatMessage({ id: 'BROWSE_DODOC' })}
          secondButtonOnClick={() => {
            setNavigationDoDOC(true);
            setDropzoneVisible(false);
          }}
          testId="upload-citations-input"
        />
      </div>
    );
  };

  return (
    <div className={`${styles.root} ${citations.dropzone && styles.drop}`}>
      {citations.dropzone ? renderDropzone() : renderImportedContent()}
    </div>
  );
};

export default ImportCitations;
