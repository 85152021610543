import { Section } from 'Editor/services/DataManager';
import { BaseViewModel } from '../BaseViewModel';

export class SectionViewModel extends BaseViewModel {
  typeName = 'SectionViewModel';

  protected model: Section;
  view?: HTMLStyleElement;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);

    this.model = this.Data.models.get(this.Data.models.TYPE_NAME.SECTION, id);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    this.model.on('UPDATED', this.handleModelUpdate);
    this.model.on('LOADED', this.handleModelLoad);
    this.buildView();
  }

  private getPropertiesStylesMap() {
    let cssRule = '';
    const properties = this.model?.properties;
    if (properties?.p_o) {
      if (properties?.p_o === 'L') {
        cssRule += `\t--sectionWidth: ${this.Data.sections.WEBLAYOUT_SECTION_SIZE.landscape.px}px;`;
      } else {
        cssRule += `\t--sectionWidth: ${this.Data.sections.WEBLAYOUT_SECTION_SIZE.portrait.px}px;`;
      }
    }

    if (properties?.sz?.w) {
      cssRule += `\n\t\t--section-width: ${properties?.sz.w}pt;`;

      let contentWidth = properties.sz.w - (properties?.mar?.l || 0) - (properties?.mar?.r || 0);
      cssRule += `\n\t\t--contentWidth: ${contentWidth}pt;`;
    }

    if (properties?.sz?.h) {
      cssRule += `\n\t\t--section-height: ${properties?.sz.h}pt;`;
    }
    if (properties?.mar?.t) {
      cssRule += `\n\t\t--section-margin-top: ${properties?.mar.t}pt;`;
    }
    if (properties?.mar?.r) {
      cssRule += `\n\t\t--section-margin-right: ${properties?.mar.r}pt;`;
    }
    if (properties?.mar?.b) {
      cssRule += `\n\t\t--section-margin-bottom: ${properties?.mar.b}pt;`;
    }
    if (properties?.mar?.l) {
      cssRule += `\n\t\t--section-margin-left: ${properties?.mar.l}pt;`;
    }
    if (properties?.mar?.h) {
      cssRule += `\n\t\t--section-header-margin: ${properties?.mar.h}pt;`;
    }
    if (properties?.mar?.f) {
      cssRule += `\n\t\t--section-footer-margin: ${properties?.mar.f}pt;`;
    }
    if (properties?.cols?.n) {
      cssRule += `\n\t\t--columns-number: ${properties?.cols.n};`;
    }
    if (properties?.cols?.s) {
      cssRule += `\n\t\t--columns-gap: ${properties?.cols.s}pt;`;
    }
    if (properties?.pnf?.st !== undefined) {
      cssRule += `\n\t\t--section-start-number: ${properties.pnf.st};`;
    }

    return cssRule;
  }

  private getCssRule() {
    let cssRule = `[section="${this.model?.id}"] {
      ${this.getPropertiesStylesMap()}
    }`;
    const pnf = this.model?.pageNumberFormat;
    if (pnf?.st !== undefined) {
      cssRule += `\n[n_sct="${this.model?.id}"] {
        counter-set: page calc(var(--section-start-number) - 1);
      }`;
    }
    return cssRule;
  }

  private append(newView: HTMLStyleElement) {
    if (this.view && this.view.parentNode) {
      this.view.parentNode.replaceChild(newView, this.view);
    }
    this.view = newView;
  }

  private buildView() {
    let newView: HTMLStyleElement;
    newView = document.createElement('style');
    newView.id = `${this.id}`;
    newView.dataset.name = `section style `;
    newView.appendChild(document.createTextNode(this.getCssRule()));
    this.append(newView);
    this.Visualizer.hooks.afterSectionUpdate?.trigger(this.model.id);
  }

  private handleModelUpdate(
    data: Editor.Data.Sections.Data | null,
    ops: Realtime.Core.RealtimeOps,
  ) {
    this.buildView();
  }

  private handleModelLoad() {
    this.buildView();
  }

  dispose() {
    if (this.model) {
      // unbind model
      this.model.off('UPDATED', this.handleModelUpdate);
      this.model.off('UPDATED', this.handleModelLoad);
    }
    if (this.view && this.view.parentNode) {
      this.view.parentNode.removeChild(this.view);
    }

    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
