import { ReactNode } from 'react';
import { Card } from 'dodoc-design-system';

import { CardFooterProps } from 'dodoc-design-system/build/types/Components/Card/CardFooter/CardFooter';

import styles from './Footer.module.scss';

type FooterProps = CardFooterProps;

const Footer = ({ children, ...props }: FooterProps) => {
  return (
    <Card.Footer size="large" {...props}>
      <div className={styles.root}>{children}</div>
    </Card.Footer>
  );
};

const Left = ({ children }: { children: ReactNode }) => {
  return <div className={styles.left}>{children}</div>;
};

const Right = ({ children }: { children: ReactNode }) => {
  return <div className={styles.right}>{children}</div>;
};

export default Object.assign(Footer, {
  Left,
  Right,
});
