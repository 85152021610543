import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { notify } from '_common/components/ToastSystem';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

const MODAL = 'UpdateCrossReferencesModal';
const UpdateCrossReferences = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const single = useSelector((state) => state.modals[MODAL].single);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const update = () => {
    if (single) {
      EditorManager.getInstance().updateCrossReference();
      notify({
        type: 'success',
        title: 'CROSS_REFERENCE_UPDATED_SINGLE_TITLE',
        message: 'CROSS_REFERENCE_UPDATED_SINGLE_MESSAGE',
      });
    } else {
      EditorManager.getInstance().updateAllCrossReferences();
      notify({
        type: 'success',
        title: 'CROSS_REFERENCES_UPDATED_ALL_TITLE',
        message: 'CROSS_REFERENCES_UPDATED_ALL_MESSAGE',
      });
    }
    close();
  };
  return (
    <Modal open={!!isOpen} onClose={close} width="54rem" testId="update-cross-references-modal">
      <Modal.Header onClose={close}>
        <FormattedMessage id="UPDATE_CROSS_REFERENCES" />
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id={
            single ? 'UPDATE_THIS_CROSS_REFERENCES_MESSAGE' : 'UPDATE_ALL_CROSS_REFERENCES_MESSAGE'
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>

        <Button size="medium" variant="primary" onClick={update} testId="cta-button">
          <FormattedMessage id="UPDATE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateCrossReferences;
