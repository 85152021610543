import { ChangeEventHandler, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'dodoc-design-system';

import { useSelector, useDebounce } from '_common/hooks';
import { selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { selectDocumentCitations } from 'Editor/redux/CitationsSlice';

import CitationsList from '../CitationsList/CitationsList';
import CitationInformation from '../CitationInformation/CitationInformation';
import NavigateToDoDOC from '../NavigateToDoDOC/NavigateToDoDOC';

import styles from './DocumentLibrary.module.scss';
import { getSanitizedEntityName } from '../utils';

type DocumentLibraryProps = {
  libraryExportMode?: boolean;
  librarySaveEndNoteMode?: boolean;
};

const DocumentLibrary = ({ libraryExportMode, librarySaveEndNoteMode }: DocumentLibraryProps) => {
  const intl = useIntl();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const insertedCitations = useSelector((state) => state.editor.citations.order);
  const citations = useSelector(selectDocumentCitations);
  const selected = useSelector((state) => state.table.selected);

  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 300);

  const filteredCitations = useMemo(
    () => ({
      ...citations,
      list: citations.list.filter((id) => {
        //@ts-expect-error CitationSchema issue: missing 'journal'
        const { title, author: authors, journal, year } = citations.dict[id];
        return (
          filter === '' ||
          (title && title.toLowerCase().includes(filter.toLowerCase())) ||
          (authors &&
            !!authors.find((author) =>
              getSanitizedEntityName(author)?.toLowerCase().includes(filter.toLowerCase()),
            )) ||
          (journal && journal.toLowerCase().includes(filter.toLowerCase())) ||
          (year && year.toString().includes(filter.toString()))
        );
      }),
    }),
    [citations, debouncedFilter],
  );

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilter(e.target.value);
  };

  if (librarySaveEndNoteMode) {
    return (
      <div className={styles.root}>
        <NavigateToDoDOC
          menuOptions={[
            { label: 'myFiles', icon: 'Folder', identity: 'storage' },
            { label: 'spaces', icon: 'Folder', identity: 'spaces' },
          ]}
          menuSelected={{ label: 'myFiles', icon: 'Folder', identity: 'storage' }}
          title={intl.formatMessage({ id: 'SAVE_REFERENCE_LIBRARY_AS_A_ENDNOTE_FILE_IN_DODOC' })}
        />
      </div>
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.list}>
        <div className={`${styles.filter} ${libraryExportMode && styles.export}`}>
          {libraryExportMode && (
            <div className={styles.title}>
              <FormattedMessage id="EXPORT_REFERENCE_LIBRARY" />
            </div>
          )}
          <Input
            prefix="NavSearchBlue"
            value={filter}
            onChange={handleFilterChange}
            placeholder={intl.formatMessage({
              id: libraryExportMode ? 'global.search' : 'Search citation library',
            })}
            disabled={isReadOnlyMode}
            width={libraryExportMode ? '36.25rem' : '100%'}
            size="medium"
            testId="document-library-search"
          />
        </div>
        <CitationsList
          citations={filteredCitations}
          libraryExportMode={libraryExportMode}
          insertedCitations={insertedCitations}
          hasFilters={filter.length > 0}
        />
      </div>
      <div className={styles.information}>
        <CitationInformation
          citationId={Object.keys(selected)[0]}
          disabled={isReadOnlyMode}
          multiple={libraryExportMode ? Object.keys(selected).length > 1 : false}
        />
      </div>
    </div>
  );
};

export default DocumentLibrary;
