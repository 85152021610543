import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, InputField } from 'dodoc-design-system';
import { closeModal } from '_common/modals/ModalsSlice';
import { LanguageSelect } from 'Editor/components';
import EditorManager from 'Editor/services/EditorManager';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import styles from './SetLanguageModal.module.scss';

const SetLanguageModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isOpen = useSelector((state) => state.modals.open.SetLanguageModal);
  const { onSelection } = useSelector((state) => state.modals.SetLanguageModal);
  const nodeLanguages = useSelector((state) => state.editor.status.selection.LANGUAGES);
  const nodeIds = useSelector((state) => state.editor.status.selection.BLOCK_IDS);
  const document = useSelector(getDocumentObject);

  const [language, setLanguage] = useState<Services.Language>({
    value: '',
    label: '',
    supported: true,
  });

  const setLanguageProxy = (value?: Services.Language) => {
    if (value) {
      setLanguage(value);
    } else {
      setLanguageValue(document?.lang ? document.lang.code : defaultLanguage);
    }
  };

  const defaultLanguage = EditorManager.getInstance().getSpellCheckDefaultLanguage();
  const languages: Services.Language[] = EditorManager.getInstance().getSpellCheckLanguages();

  const setLanguageValue = (code: string) => {
    if (code) {
      const value = languages.find(({ value }) => value === code);
      if (value) {
        setLanguage(value);
        return;
      }
    }
    setLanguage({ value: '', label: '', supported: true });
  };

  useEffect(() => {
    if (isOpen) {
      const defaultLang = document?.lang ? document.lang.code : defaultLanguage;
      const nodeIdsLanguages = nodeLanguages && Object.keys(nodeLanguages);
      if (onSelection) {
        if (nodeIdsLanguages && nodeIdsLanguages.length) {
          const allLanguages = Object.entries(nodeLanguages).map(([_, value]) => value);
          if (nodeIdsLanguages.length > 1) {
            if (allLanguages.every((language) => language === allLanguages[0]) && languages) {
              setLanguageValue(allLanguages[0]);
            } else {
              setLanguageValue(defaultLang);
            }
          } else {
            setLanguageValue(allLanguages[0]);
          }
        } else {
          setLanguageValue(defaultLang);
        }
      } else {
        setLanguageValue(defaultLang);
      }
    }
  }, [document, nodeLanguages, isOpen]);

  const close = () => {
    dispatch(closeModal('SetLanguageModal'));
  };

  const save = () => {
    const manager = EditorManager.getInstance();
    if (language.value) {
      if (onSelection) {
        manager.setLanguageOnNodes(language.value, nodeIds);
      } else {
        manager.setLanguageOnDocument(language.value);
      }
    }
    close();
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="58rem" testId="set-language">
      <Modal.Header onClose={close}>
        <FormattedMessage id="SET_LANGUAGE" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <InputField
          label={intl.formatMessage({ id: 'LANGUAGE' })}
          size="large"
          testId="language-field"
          feedback={false}
        >
          <LanguageSelect
            options={languages}
            value={language}
            onChange={setLanguageProxy}
            size="large"
            testId="language"
          />
        </InputField>
        <div className={styles.description}>
          {language.value && !language.supported && (
            <FormattedMessage id="SPELL_CHECK_LANGUAGE_NOT_SUPPORTED" />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="set-language-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={save}
          disabled={!language.value}
          testId="set-language-submit-button"
        >
          <FormattedMessage id="global.save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetLanguageModal;
