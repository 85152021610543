import { useDispatch, useSelector } from '_common/hooks';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import { Logger } from '_common/services';
import { closeModal } from '_common/modals/ModalsSlice';
import styles from './ClipboardInstallExtensionModal.module.scss';

// -------------- extensions url --------------
const CHROME_URL =
  'https://chrome.google.com/webstore/detail/dodoc-paste/ldcfpijeeomligejkphbjcoielolkaae';
const FIREFOX_URL = 'https://addons.mozilla.org/en-US/firefox/addon/dodoc-paste/';

const ClipboardInstallExtensionModal = () => {
  const isOpen = useSelector((state) => state.modals.open.ClipboardInstallExtensionModal);
  const platform = useSelector((state) => state.app.platform);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeModal('ClipboardInstallExtensionModal'));
  };

  const onClickInstall = () => {
    let url;

    if (platform.browser.chrome) {
      url = CHROME_URL;
    }

    if (platform.browser.firefox) {
      url = FIREFOX_URL;
    }

    if (url) {
      window.open(url, '_blank');
    } else {
      Logger.captureMessage('Invalid extension URL!');
    }
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="clipboard-install-extension">
      <Modal.Header onClose={close}>
        <FormattedMessage id="CLIPBOARD_INSTALL_EXTENSION_HEADER" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <FormattedMessage id="CLIPBOARD_INSTALL_EXTENSION_DESCRIPTION" />
        </div>
        <div className={styles.description}>
          <FormattedMessage id="CLIPBOARD_INSTALL_EXTENSION_DESCRIPTION2" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="clipboard-install-extension-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button size="medium" variant="primary" onClick={onClickInstall} testId="clipboard-install-extension-submit-button">
          <FormattedMessage id="CLIPBOARD_INSTALL_EXTENSION_BUTTON" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClipboardInstallExtensionModal;
