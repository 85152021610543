import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, TextArea, InputField } from 'dodoc-design-system';
import type { TextAreaProps } from 'dodoc-design-system/build/types/Components/TextArea/TextArea';

import objectApi from 'App/redux/objectApi';
import { useSuiteObject } from '_common/suite';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

const MODAL = 'SaveVersionModal';

export type SaveVersionModalProps = {
  saveVersion: (description: string) => void;
  editDescription?: (description: string, index: number) => void;
};

const SaveVersionModal = ({ saveVersion, editDescription }: SaveVersionModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = useSuiteObject();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const type = useSelector((state) => state.modals[MODAL].type);
  const index = useSelector((state) => state.modals[MODAL].index);
  const descriptionRedux = useSelector((state) => state.modals[MODAL].description);

  const [description, setDescription] = useState('');
  const [validations, setValidations] = useState<{ description?: string }>({});
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isOpen) {
      setDescription(descriptionRedux);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isOpen]);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const handleOnChange: TextAreaProps['onChange'] = (e) => {
    const descriptionValue = e.target.value;

    setDescription(descriptionValue);

    const newValidations: typeof validations = {};
    if (descriptionValue.length > 256) {
      newValidations.description = intl.formatMessage(
        {
          id: 'ERROR_VERSION_DESCRIPTION',
        },
        { length: 256 },
      );
    }

    setValidations(newValidations);
  };

  const handleSaveVersion = async () => {
    try {
      switch (type) {
        case 'saveVersion': {
          await saveVersion(description);
          notify({
            type: 'success',
            title: 'VERSION_SAVED',
            message: 'THE_VERSION_WAS_SUCCESSFULLY_SAVED',
          });
          break;
        }
        case 'editDescription': {
          if (editDescription) {
            await editDescription(description, index);
            notify({
              type: 'success',
              title: 'VERSION_DESCRIPTION_EDITED',
              message: 'THE_VERSION_DESCRIPTION_WAS_SUCCESSFULLY_EDITED',
            });
          }
          break;
        }
        default:
          break;
      }

      dispatch(objectApi.util.invalidateTags([{ type: object.type, id: object.id }]));
    } catch (e) {
      throw e;
    }

    close();
  };

  return (
    <Modal width="67rem" open={!!isOpen} onClose={close} testId="save-version-modal">
      <Modal.Header onClose={close}>
        <FormattedMessage id={type === 'saveVersion' ? 'SAVE_VERSION' : 'EDIT_DESCRIPTION'} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'VERSION_DESCRIPTION' })}
          feedback={validations.description}
          testId="save-version-modal-description"
        >
          <TextArea
            size="large"
            placeholder={
              type === 'saveVersion'
                ? intl.formatMessage({ id: 'INSERT_YOUR_DESCRIPTION' })
                : intl.formatMessage({ id: 'EDIT_VERSION_DESCRIPTION_PLACEHOLDER' })
            }
            onChange={handleOnChange}
            value={description}
            error={!!validations.description}
            ref={inputRef}
            testId="save-version-modal-description-textarea"
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="save-version-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSaveVersion}
          disabled={!!validations.description}
          testId="save-version-modal-save-button"
        >
          <FormattedMessage id="SAVE_VERSION" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveVersionModal;
