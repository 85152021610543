import { FormattedMessage } from 'react-intl';
import { Tag } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type OptionsProps = {
  field: Affiliation.Field;
  option: Affiliation.Value[number];
  testId: string;
};

const Option = ({ field, option, testId }: OptionsProps) => {
  const dispatch = useDispatch();

  const editOption = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'AddAffiliationModal',
        data: {
          operation: 'edit',
          field,
          option,
        },
      }),
    );
  };

  const deleteOption = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_SOMETHING_QUOTED',
          titleValues: { label: option },
          message: 'DELETING_THIS_AFFILIATION_FIELD_OPTION_WILL_REMOVE_CONFIRM',
          confirmButtonTextId: 'global.delete',
          cancelButtonTextId: 'global.cancel',
          confirmButtonType: 'danger',
          headerType: 'error',
          actionCode: 'deleteFieldOption',
          width: '60rem',
          actionValue: { field, value: option },
          cancelButtonShow: true,
        },
      }),
    );
  };
  return (
    //@ts-expect-error- onClick should be optional for this scenario
    <Tag
      variant="neutral"
      margin="0 1rem 2rem 0"
      dropdownItems={[
        {
          size: 'large',
          onClick: editOption,
          children: <FormattedMessage id="global.edit" />,
          testId: 'edit-item',
        },
        {
          size: 'large',
          onClick: deleteOption,
          children: <FormattedMessage id="global.delete" />,
          testId: 'delete-item',
        },
      ]}
      testId={testId}
    >
      {option}
    </Tag>
  );
};

export default Option;
