import { FormattedMessage } from 'react-intl';
import { Toggle } from 'dodoc-design-system';

import { useSelector, useDispatch } from '_common/hooks';
import {
  selectActiveMenuOption,
  setMenuActiveOption,
} from '_common/components/Sidebar/SidebarSlice';

type OptionProps = {
  id: string;
  label: TranslationMessage;
  testId: string;
};

const Option = ({ id, label, testId }: OptionProps) => {
  const dispatch = useDispatch();

  const activeOption = useSelector(selectActiveMenuOption);
  const appLoading = useSelector((state) => state.app.loading.isOpen);

  const activateOption = () => {
    if (activeOption !== id) {
      dispatch(setMenuActiveOption({ activeOption: id }));
    }
  };

  return (
    <Toggle
      variant="monochrome"
      size="large"
      loading={appLoading}
      onClick={activateOption}
      isToggled={activeOption === id}
      expanded
      testId={`sidebar-menu-option-${testId}`}
    >
      <FormattedMessage id={label.id} values={label.values} />
    </Toggle>
  );
};

export default Option;
