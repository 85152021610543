import PatternDefs from './PatternDefs';
import { useSlideData } from '../SlideData';

type PatternBackgroundProps = {
  background: Presentation.Data.Common.PatternFillType;
  size: Presentation.Data.Common.Size;
};

const PatternBackground = ({ background, size }: PatternBackgroundProps) => {
  const { slideId, isThumbnailSlide } = useSlideData();

  const thumbnailId = isThumbnailSlide ? `-thumbnail` : '';
  const fillId = `${slideId}${thumbnailId}-${background.preset}`;

  return (
    <>
      <defs>
        <PatternDefs id={fillId} background={background} />
      </defs>
      <rect width={size.width} height={size.height} fill={`url(#${fillId})`} />
    </>
  );
};

export default PatternBackground;
