import { Section } from 'Editor/services/DataManager';
import { ModelIndexer } from 'Editor/services/DataManager/controllers/Models/ModelIndexer';
import { Hook, ReadyState, ViewModelChildren } from '../../utils';
import { SectionViewModel } from 'Editor/services/VisualizerManager';

export class SectionsManager {
  private readyState?: ReadyState | null;
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private sectionsList?: ModelIndexer<'SECTION'>;
  private children: ViewModelChildren<SectionViewModel>;
  private view: HTMLElement;
  private reference: HTMLElement | null;

  constructor(dataManager: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;
    this.children = new ViewModelChildren<SectionViewModel>();
    this.handleSectionsLoaded = this.handleSectionsLoaded.bind(this);
    this.handleSectionsInserted = this.handleSectionsInserted.bind(this);
    this.handleSectionsRemoved = this.handleSectionsRemoved.bind(this);
    this.view = document.head;
    this.reference = document.getElementById('sections');
    this.createHooks();
  }

  private createHooks() {
    if (!this.Visualizer.hooks.afterSectionUpdate) {
      this.Visualizer.hooks.afterSectionUpdate = new Hook<'afterSectionUpdate'>();
    }
  }

  start(readyState: ReadyState) {
    this.readyState = readyState;
    this.sectionsList = this.Data.sections?.list;
    if (this.sectionsList) {
      this.sectionsList.on('LOADED', this.handleSectionsLoaded);
      this.sectionsList.on('INSERTED', this.handleSectionsInserted);
      this.sectionsList.on('REMOVED', this.handleSectionsRemoved);
    }
    if (this.sectionsList?.ready) {
      this.handleSectionsInserted(this.sectionsList.results);
      this.readyState?.setReady(SectionsManager.name);
      this.readyState = null;
    }
  }

  private handleSectionsLoaded(sections: Section[]) {
    this.readyState?.setReady(SectionsManager.name);
    this.readyState = null;
  }

  private handleSectionsInserted(sections: Section[]) {
    let model;
    let index;
    for (index = 0; index < sections.length; index++) {
      model = this.Visualizer.viewModelFactory?.getSection(sections[index].id);
      this.appendChild(model);
    }
  }

  private handleSectionsRemoved(sections: Section[]) {
    let index;
    for (index = 0; index < sections.length; index++) {
      let removed = this.children.removeById(sections[index].id);
      for (let j = 0; j < removed.length; j++) {
        removed[j].dispose();
      }
    }
  }

  private appendChild(child?: SectionViewModel) {
    if (child) {
      this.children.push(child);
      if (child.view) {
        if (this.reference) {
          this.view.insertBefore(child.view, this.reference);
        } else {
          this.view.appendChild(child.view);
        }
      }
    }
  }

  destroy() {
    if (this.sectionsList) {
      this.sectionsList.off('LOADED', this.handleSectionsLoaded);
      this.sectionsList.off('INSERTED', this.handleSectionsInserted);
      this.sectionsList.off('REMOVED', this.handleSectionsRemoved);
    }
    let children = this.children.removeAll();
    for (let index = 0; index < children.length; index++) {
      children[index].dispose();
    }
  }
}
