import { FormattedMessage } from 'react-intl';
import { Helper } from 'dodoc-design-system';

import { useGetRolesListQuery } from './RolesApi';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import RolesHeading from './RolesHeading/RolesHeading';
import RolesTable from './RolesTable/RolesTable';

import styles from './Roles.module.scss';

const Roles = () => {
  const { data: roles, isLoading } = useGetRolesListQuery();

  return (
    <div className={styles.rolesContainer}>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <RolesHeading />
      </IntlErrorBoundary>

      {roles?.list && roles.list.length > 0 ? (
        <IntlErrorBoundary size="large" margin="10rem 0 0 0">
          <RolesTable />
        </IntlErrorBoundary>
      ) : (
        !isLoading && (
          <div data-testid="rolesEmptyState">
            <Helper margin="2rem 0 0 0" testId="tenant-settings-page-no-roles-helper">
              <FormattedMessage id="EMPTY_ROLES_VIEW" />
            </Helper>
          </div>
        )
      )}
    </div>
  );
};

export default Roles;
