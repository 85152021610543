import { FormattedMessage } from 'react-intl';

import { PanelContent, PanelHeading } from '_common/suite/components';
import ContentPanel from './ContentPanel/ContentPanel';

const SpellCheckTab = () => {
  return (
    <>
      <PanelHeading>
        <FormattedMessage id="SPELL_CHECK" />
      </PanelHeading>
      <PanelContent testId="spellcheck-panel">
        <ContentPanel />
      </PanelContent>
    </>
  );
};

export default SpellCheckTab;
