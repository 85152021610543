import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  useGetTemplatesListQuery,
  useInstallTemplateMutation,
} from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';
import {
  selectReferenceStyleById,
  useInstallReferenceStyleMutation,
} from 'Settings/pages/TenantSettingsPage/ReferencesStyles/ReferenceStylesApi';

import styles from './ExtensionUpdateInformationModal.module.scss';

const MODAL = 'ExtensionUpdateInformationModal';

const ExtensionUpdateInformationModal = () => {
  const dispatch = useDispatch();

  const [installTemplate] = useInstallTemplateMutation();
  const [installReferenceStyle] = useInstallReferenceStyleMutation();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const type = useSelector((state) => state.modals.ExtensionUpdateInformationModal.type);
  const id = useSelector((state) => state.modals.ExtensionUpdateInformationModal.id);
  const { templateById: templateExtension } = useGetTemplatesListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, templateById: result.data?.extensions[id] }),
  });
  const referenceStyle = useSelector((state) => selectReferenceStyleById(state, id));

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const update = () => {
    if (type === 'template') {
      if (!templateExtension) {
        return null;
      }

      installTemplate({
        id,
        name: templateExtension.name,
        updating: true,
      });
    } else {
      if (!referenceStyle) {
        return null;
      }

      installReferenceStyle({
        id,
        name: referenceStyle.name,
        updating: true,
      });
    }
    close();
  };

  const handleBody = () => {
    if (type === 'template') {
      if (templateExtension) {
        return (
          <>
            <div className={styles.title}>
              <FormattedMessage
                id="WHATS_NEW_IN_VERSION"
                values={{ version: templateExtension.latest_version }}
              />
            </div>
            <ul className={styles.list}>
              {templateExtension.change_log.split('\n').map((change, index) => (
                // eslint-disable-next-line
                <li key={index} className={styles.item}>
                  {change}
                </li>
              ))}
            </ul>
          </>
        );
      }
    } else if (referenceStyle) {
      return (
        <>
          <div className={styles.title}>
            <FormattedMessage
              id="WHATS_NEW_IN_EDITION"
              values={{ refStyleName: referenceStyle.name }}
            />
          </div>
          <div className={styles.text}>{referenceStyle.change_log}</div>
          <div>
            <FormattedMessage id="DO_YOU_WANT_TO_UPDATE_THE_REFERENCE_STYLE" />
          </div>
        </>
      );
    }
  };

  if (type === 'template' && !templateExtension) {
    return null;
  }

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="extension-update-information">
      <Modal.Header onClose={close}>
        <FormattedMessage id="EXTENSION_UPDATE_INFORMATION" />
      </Modal.Header>
      <Modal.Body>{handleBody()}</Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="extension-update-information-cancel-button">
          <FormattedMessage id={type === 'template' ? 'global.close' : 'global.cancel'} />
        </Button>
        <Button size="medium" variant="primary" onClick={update} testId="extension-update-information-submit-button">
          <FormattedMessage id={type === 'template' ? 'UPDATE' : 'UPDATE_REFERENCE_STYLE'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtensionUpdateInformationModal;
