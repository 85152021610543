import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, Tooltip, usePopper, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  selectReadOnlyMode,
  selectCanApprove,
  selectCanChangePermission,
  updateZoomValue,
} from 'Editor/redux/EditorStatusSlice';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import EditorManager from 'Editor/services/EditorManager';
import { openModal } from '_common/modals/ModalsSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';

const View = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const information = useSelector((state) => state.app.information.actions);
  const canApprove = useSelector(selectCanApprove);
  const canChangePermissions = useSelector(selectCanChangePermission);
  const zoomValue: number = useSelector((state) => state.editor.status.zoom);
  const zoom = zoomValue * 100;
  const layout = useSelector((state) => state.editor.status.layout);
  const isEnvision = useSelector(selectIsIEnvision);
  const initiatedWithTasks = useSelector((state) => state.editor.status.initiatedWithTasks);

  const canAccessTasks = !isEnvision || initiatedWithTasks;

  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  const handleOpenApprove = () => dispatch(setSidebarView('APPROVE_CONTENT'));
  const handleOpenPermissions = () => dispatch(setSidebarView('CONTENT_PERMISSIONS'));
  const handleOpenSpellcheck = () => dispatch(setSidebarView('SPELL_CHECK'));
  const handleOpenWordCount = () => dispatch(openModal('WordCountModal'));
  const handleOpenFindAndReplace = () => dispatch(setSidebarView('FIND_AND_REPLACE'));
  const handleOpenTasks = () => dispatch(setSidebarView('TASKS'));
  const handleOpenComments = () => {
    dispatch(setSidebarView('REVIEW'));
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
  };
  const handleOpenTrackChanges = () => {
    dispatch(setSidebarView('REVIEW'));
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
  };
  const handleOpenReferences = () => dispatch(setSidebarView('REFERENCES'));
  const handleOpenNotes = () => dispatch(setSidebarView('NOTES'));
  const handleOpenCrossReferences = () => dispatch(setSidebarView('CROSS_REFERENCES'));
  const handleOpenStyles = () => dispatch(setSidebarView('DOCUMENT_STYLES'));
  const handleOpenNavigation = () => dispatch(setSidebarView('NAVIGATION'));
  const handleSelectZoomValue = (value: number) => {
    dispatch(updateZoomValue(value));
  };

  const handleWebLayoutChange = () => {
    if (layout !== 'WEB') {
      EditorManager.getInstance().changeLayout('WEB');
    }
  };
  const handlePageLayoutChange = () => {
    if (layout !== 'PAGE') {
      EditorManager.getInstance().changeLayout('PAGE');
    }
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={isOpen}
        margin="0 1rem 0 0"
        testId="view-button"
        {...referenceProps}
      >
        <FormattedMessage id="editor.menu.view.view" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-view">
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id={'LAYOUT'} />}
          placement="right"
          testId="topbar-menu-view-layout"
        >
          <Dropdown.Item
            prefixIcon={layout === 'PAGE' ? 'ResolveComment' : undefined}
            prefixIconColor="blue"
            onClick={handlePageLayoutChange}
            testId="topbar-menu-view-layout-page"
          >
            <FormattedMessage id="PAGE_LAYOUT" />
          </Dropdown.Item>
          <Dropdown.Item
            prefixIcon={layout === 'WEB' ? 'ResolveComment' : undefined}
            prefixIconColor="blue"
            onClick={handleWebLayoutChange}
            testId="topbar-menu-view-layout-web"
          >
            <FormattedMessage id="WEB_LAYOUT" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Divider />
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="ZOOM" />}
          testId="zoom-dropdown-submenu"
        >
          <Dropdown.Item
            onClick={() => handleSelectZoomValue(50)}
            prefixIcon={zoom === 50 ? 'ResolveComment' : undefined}
            prefixIconColor="blue"
            testId="50%-dropdown-item"
          >
            <FormattedMessage id="50%" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleSelectZoomValue(100)}
            prefixIcon={zoom === 100 ? 'ResolveComment' : undefined}
            prefixIconColor="blue"
            testId="100%-dropdown-item"
          >
            <FormattedMessage id="100%" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleSelectZoomValue(150)}
            prefixIcon={zoom === 150 ? 'ResolveComment' : undefined}
            prefixIconColor="blue"
            testId="150%-dropdown-item"
          >
            <FormattedMessage id="150%" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleSelectZoomValue(200)}
            prefixIcon={zoom === 200 ? 'ResolveComment' : undefined}
            prefixIconColor="blue"
            testId="200%-dropdown-item"
          >
            <FormattedMessage id="200%" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Divider />
        <Dropdown.Item
          onClick={handleOpenApprove}
          disabled={!canApprove}
          testId="topbar-menu-view-approve-content"
        >
          <Tooltip
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            //@ts-expect-error app.information.actions are typed as unknown
            disabled={information?.editor?.nodes?.approve === false || !information}
            testId="topbar-menu-view-approve-tooltip"
          >
            <span>
              <FormattedMessage id="editor.menu.review.approveContent" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleOpenPermissions}
          disabled={!canChangePermissions}
          testId="topbar-menu-view-permissions"
        >
          <Tooltip
            //@ts-expect-error app.information.actions are typed as unknown
            disabled={information?.editor?.nodes?.permissions === false || !information}
            content={intl.formatMessage({
              id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
            })}
            testId="topbar-menu-view-permissions-tooltip"
          >
            <span>
              <FormattedMessage id="global.permissions" />
            </span>
          </Tooltip>
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          disabled={isReadOnlyMode}
          onClick={handleOpenSpellcheck}
          testId="topbar-menu-view-spell-check"
        >
          <FormattedMessage id="SPELL_CHECK" />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleOpenWordCount} testId="topbar-menu-view-word-count">
          <FormattedMessage id="WORD_COUNT" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleOpenFindAndReplace}
          testId="topbar-menu-view-find-and-replace"
        >
          <FormattedMessage id="FIND_AND_REPLACE" />
        </Dropdown.Item>
        <Divider />

        <Tooltip
          content={intl.formatMessage({
            id: 'NO_PERMISSION_TO_PERFORM_ACTION',
          })}
          placement="right"
          disabled={canAccessTasks}
          testId="topbar-menu-view-tasks-tooltip"
        >
          <Dropdown.Item
            onClick={handleOpenTasks}
            testId="topbar-menu-view-tasks"
            disabled={!canAccessTasks}
          >
            <FormattedMessage id="TASKS" />
          </Dropdown.Item>
        </Tooltip>
        <Divider />
        <Dropdown.Item onClick={handleOpenComments} testId="comments-dropdown-item-comments">
          <FormattedMessage id="COMMENTS" />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleOpenTrackChanges} testId="topbar-menu-view-track-changes">
          <FormattedMessage id="TRACK_CHANGES" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item onClick={handleOpenReferences} testId="topbar-menu-references">
          <FormattedMessage id="REFERENCES" />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleOpenNotes} testId="topbar-menu-view-notes">
          <FormattedMessage id="NOTES_PANEL_TITLE" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleOpenCrossReferences}
          testId="topbar-menu-view-cross-references"
        >
          <FormattedMessage id="CROSS_REFERENCES" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item onClick={handleOpenStyles} testId="topbar-menu-view-paragraph-styles">
          <FormattedMessage id="PARAGRAPH_STYLES" />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleOpenNavigation} testId="topbar-menu-view-navigation">
          <FormattedMessage id="NAVIGATION_PANEL_TITLE" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default View;
