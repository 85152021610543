import { ReactElement, ReactNode } from 'react';
import { Divider, Tab, TabMenu as TabMenuWrapper } from 'dodoc-design-system';
import styles from './TabMenu.module.scss';
import { MenuItemRenderProps } from './MenuItem/MenuItem';
import { useDispatch, useSelector } from '_common/hooks';
import { setSelectedTab, TabMenuIdentity } from './TabMenuSlice';

type TabMenuProps = {
  menuId: TabMenuIdentity;
  children: ReactElement<MenuItemRenderProps>[] | ReactElement<MenuItemRenderProps>;
  extra?: ReactNode;
};

const TabMenu = ({ children, extra, menuId }: TabMenuProps) => {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.tabMenu[menuId]);

  const isActive = (index: number, tabId?: string) => {
    if (activeTab && !isNaN(+activeTab)) {
      return activeTab === index;
    }

    return activeTab ? activeTab === tabId : index === 0;
  };

  const renderTabs = () => {
    if (Array.isArray(children)) {
      return children
        .filter((tab) => tab && tab.props)
        .map((tab, index) => {
          const selectedState = isActive(index, tab.props.id);
          return (
            tab.props.hidden || (
              <div
                key={tab.props.text}
                className={`${styles.tab} ${children.length > 1 && styles.tabCursor}`}
              >
                <Tab
                  selected={selectedState}
                  onClick={(e) => {
                    if (!selectedState) {
                      dispatch(setSelectedTab({ menuId, selectedTab: tab.props.id || index }));
                      e.stopPropagation();
                    }
                  }}
                  testId={`${tab.props.id}-tab`}
                >
                  {tab.props.text}
                </Tab>
              </div>
            )
          );
        });
    }

    return <div className={`${styles.tab}`}>{children.props.text}</div>;
  };

  if (!children) {
    return null;
  }

  const renderSelectedTabContent = () => {
    if (!Array.isArray(children)) {
      return children;
    }

    if (children.length <= 0) {
      return null;
    }

    if (!isNaN(+(activeTab ?? 0))) {
      if (!children[+(activeTab ?? 0)]) {
        //Set first tab as selected if active by index is invalid
        dispatch(setSelectedTab({ menuId, selectedTab: 0 }));
        return null;
      }

      return children[+(activeTab ?? 0)];
    }

    const selectedChildIndex = children.findIndex((tab) => tab.props.id === activeTab);
    if (selectedChildIndex === -1) {
      //Set first tab as selected if active by id is invalid
      dispatch(setSelectedTab({ menuId, selectedTab: 0 }));
      return null;
    }

    return children[selectedChildIndex];
  };

  return (
    <>
      <div className={`${styles.tabs}`}>
        <TabMenuWrapper>{renderTabs()}</TabMenuWrapper>
        {extra}
      </div>
      <Divider margin="0" />

      {/* Render selected tab content */}
      {renderSelectedTabContent()}
    </>
  );
};

export default TabMenu;
