import List from './List/List';

import style from './History.module.scss';

const History = () => {
  return (
    <div className={style.history}>
      <List />
    </div>
  );
};

export default History;
