import { CharacterPositionIterator, WordPositionIterator } from '../../Iterators';

export abstract class BaseModifier implements Editor.Selection.Range.IEditorRangeVisitor {
  updated: boolean = false;

  protected Data: Editor.Data.API;

  protected type: Editor.Selection.ModifierType;
  protected granularity: Editor.Selection.ModifierGranularity;
  protected direction: Editor.Selection.ModifierDirection;

  constructor(
    Data: Editor.Data.API,
    type: Editor.Selection.ModifierType,
    granularity: Editor.Selection.ModifierGranularity,
    direction: Editor.Selection.ModifierDirection,
  ) {
    this.Data = Data;
    this.type = type;
    this.granularity = granularity;
    this.direction = direction;
  }

  abstract visitDoDOCRange(range: Editor.Selection.EditorRange): void;
  abstract visitJsonRange(range: Editor.Selection.JsonRange): void;

  protected collapseRange(range: Editor.Selection.EditorRange | Editor.Selection.JsonRange) {
    if (!range.collapsed) {
      if (
        this.Data.selection &&
        this.Data.selection.modifiersData.expandingDirection === 'backward'
      ) {
        range.collapse(true);
      } else {
        range.collapse();
      }
    }
  }

  protected getPositionIterator(
    navigationData: Editor.Data.Node.NavigationData[],
    path: Editor.Selection.Path,
  ) {
    switch (this.granularity) {
      case 'character':
        return new CharacterPositionIterator(navigationData, path);
      case 'word':
        return new WordPositionIterator(navigationData, path);
      default:
        return null;
    }
  }
}
