import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from '_common/services';

import { notify } from '_common/components/ToastSystem';

import {
  listObjects,
  lazyLoad,
  addToList,
  addToListBulk,
  removeFromList,
} from '_common/components/Table/TableSlice';

const SLICE_NAME = 'userManagement';

export type UserCreationParams = Pick<UserPublicProfile, 'first_name' | 'last_name' | 'email'>;

type userManagementSliceState = {
  users: Record<UserId, UserPublicProfileExtended>;
  tokens: Record<TokenId, TokenObject>;
  filters: Request.FilterParams;
};

const INITIAL_STATE: userManagementSliceState = {
  users: {},
  tokens: {},
  filters: {
    filter_fields: [],
    filter_values: [],
  },
};

export const createUser = createAsyncThunk(
  `${SLICE_NAME}/createUser`,
  async ({ params }: { params: UserCreationParams }, { dispatch }) => {
    const { data } = await new AuthService().createUser(params);

    // @ts-expect-error Missing endpoint type "/api/authority/user/create"
    const { id, date_joined }: { id: UserId | number; date_joined: string } = data;

    dispatch(addToList({ identity: 'userManagement/users', objectId: `${id}` }));

    const createdUser: Omit<UserPublicProfileExtended, 'is_admin' | 'username' | 'is_superuser'> = {
      first_name: params.first_name,
      last_name: params.last_name,
      email: params.email,
      id: `${id}`,
      date_joined,
      is_active: true,
      has_avatar: false,
    };

    notify({
      type: 'success',
      title: 'NEW_USER_ADDED_TO_TENANT',
      message: 'THE_USER_WAS_SUCCESSFULLY_ADDED_TO_THIS_TENANT',
      messageValues: { email: createdUser.email },
    });

    return createdUser;
  },
);

export const removeUser = createAsyncThunk(
  `${SLICE_NAME}/removeUser`,
  async (userId: UserId, { dispatch }) => {
    await new AuthService().removeUser(userId);

    dispatch(removeFromList({ identity: 'userManagement/users', objectId: userId }));

    return { userId };
  },
);

export const editUser = createAsyncThunk<
  // Return type of the payload creator
  { userId: UserId; params: Partial<UserCreationParams> },
  // First argument to the payload creator
  { userId: UserId; params: Partial<UserCreationParams> },
  // Types for ThunkAPI
  {
    state: RootState;
  }
>(`${SLICE_NAME}/editUser`, async ({ userId, params }, { getState }) => {
  await new AuthService().editUser(userId, params);

  notify({
    type: 'success',
    title: 'USER_INFORMATION_EDITED',
    message: 'THE_USER_INFORMATION__WAS_SUCCESSFULLY_EDITED',
    messageValues: { email: getState().userManagement.users[userId].email },
  });

  return { userId, params };
});

export const setUserActiveState = createAsyncThunk(
  `${SLICE_NAME}/setUserActiveState`,
  async ({ userId, active }: { userId: UserId; active: boolean }) => {
    await new AuthService().editUserState(userId, { active });

    return { userId, active };
  },
);

export const validateUsersCSV = createAsyncThunk(
  `${SLICE_NAME}/validateUsersCSV`,
  async (file: Blob) => {
    const response = await new AuthService().validateUsersCsv(file);

    return { response };
  },
);

export const addUsersCSV = createAsyncThunk(
  `${SLICE_NAME}/addUsersCSV`,
  async (file: Blob, { dispatch }) => {
    const users: UserPublicProfile[] = [];
    const ids: UserId[] = [];

    const { data: entries } = await new AuthService().createUsersCsv(file);

    // @ts-expect-error Missing endpoint type "/api/authority/user/create/csv"
    Object.keys(entries).forEach((row) => {
      // @ts-expect-error Missing endpoint type "/api/authority/user/create/csv"
      const entry = entries[row];

      if (entry.created) {
        entry.data.is_active = true;
        users.push(entry.data);
        ids.push(entry.data.id);
      }
    });

    dispatch(addToListBulk({ identity: 'userManagement/users', objects: ids }));

    return { users };
  },
);

export const removeUserActiveSession = createAsyncThunk(
  `${SLICE_NAME}/removeUserActiveSession`,
  async ({ userId, tokenId }: { userId: UserId; tokenId: string }, { dispatch }) => {
    await new AuthService().removeUserActiveSession({ userId, tokenId });

    dispatch(removeFromList({ identity: 'userManagement/tokens', objectId: tokenId }));

    return { tokenId };
  },
);

const userManagementSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateFilters(state, action: PayloadAction<userManagementSliceState['filters']>) {
      state.filters = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.users[action.payload.id] = {
        ...action.payload,
        id: action.payload.id,
        is_admin: false,
        is_superuser: false,
        username: '',
      };
    });
    builder.addCase(addUsersCSV.fulfilled, (state, action) => {
      const { users } = action.payload;

      users.forEach((user) => {
        state.users[user.id] = { ...user };
      });
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      const { userId } = action.payload;
      delete state.users[userId];
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      const { userId, params } = action.payload;
      state.users[userId] = { ...state.users[userId], ...params };
    });
    builder.addCase(setUserActiveState.fulfilled, (state, action) => {
      const { userId, active } = action.payload;
      state.users[userId].is_active = active;
    });
    builder.addCase(removeUserActiveSession.fulfilled, (state, action) => {
      const { tokenId } = action.payload;
      delete state.tokens[tokenId];
    });
    /*Table*/
    builder.addCase(listObjects.fulfilled, (state, action) => {
      if (action.payload.dataStorage === 'tenantUsers') {
        state.users = Object.assign(state.users, action.payload.dict);
      } else if (action.payload.dataStorage === 'tenantTokens') {
        state.tokens = Object.assign(state.tokens, action.payload.dict);
      }
    });
    builder.addCase(lazyLoad.fulfilled, (state, action) => {
      if (action.payload.dataStorage === 'tenantUsers') {
        state.users = Object.assign(state.users, action.payload.data);
      } else if (action.payload.dataStorage === 'tenantTokens') {
        state.tokens = Object.assign(state.tokens, action.payload.data);
      }
    });
  },
});

export const { updateFilters } = userManagementSlice.actions;

export default userManagementSlice.reducer;
