import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import styles from './DevicesTable.module.scss';
import Cell from '_common/components/Table2/Cells';
import { Table } from '_common/components/Table2';
import { useListDevicesQuery } from '../../UserSettingsApi';
import { Label } from 'dodoc-design-system';
import MoreOptionsCell from '../../Cells/MoreOptionsCell';
import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { useSelector } from '_common/hooks';
import UAParser from 'ua-parser-js';

const DevicesTable = () => {
  const intl = useIntl();
  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector(selectAccounts);

  const { data: devices, isFetching } = useListDevicesQuery();

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'settings.security.name' }),
        width: 176,
      },
      {
        id: 'platform',
        header: intl.formatMessage({ id: 'settings.security.platform' }),
        width: 184,
      },
      {
        id: 'lastAccess',
        header: intl.formatMessage({ id: 'settings.security.lastAccess' }),
        width: 192,
      },
      {
        id: 'ipAdress',
        header: intl.formatMessage({ id: 'settings.security.ipAdress' }),
        width: 168,
      },
      {
        id: 'current',
        width: 200,
      },
    ];
  }, []);

  const parsePlatformValue = (platform: string | undefined) => {
    const parser = new UAParser();
    if (platform) {
      parser.setUA(platform);
    }
    const result = parser.getResult();
    const str = intl.formatMessage(
      { id: 'settings.security.on' },
      {
        operatingSystem: result.os.name,
        browser: result.browser.name,
        version: result.browser.version,
      },
    );

    return str;
  };

  const value = useMemo(() => {
    return devices?.map((device) => {
      return {
        id: device.token,
        name: (
          <Cell testId={`${device.token}-name-column`} ellipsis>
            {device.name === 'AUTO_VERIFIED'
              ? intl.formatMessage({ id: 'global.notSpecified' })
              : device.name}
          </Cell>
        ),
        platform: (
          <Cell testId={`${device.token}-platform-column`} ellipsis>
            {parsePlatformValue(device.last_user_agent)}
          </Cell>
        ),
        lastAccess: (
          <Cell testId={`${device.token}-last-access-column`}>
            <Cell.DateTime date={{ date: device.creation }} time={{ time: device.creation }} />
          </Cell>
        ),
        ipAdress: (
          <Cell testId={`${device.token}-ip-adress-column`}>
            <div>{device.last_ip_address}</div>
          </Cell>
        ),
        current: device.token === accounts[userId].device && (
          <Cell testId={`${device.token}-current-column`}>
            <Label size="medium" fullWidth color="blue" testId="current-label">
              <FormattedMessage id="global.current" />
            </Label>
          </Cell>
        ),
        suffix: (
          <Cell testId={`${device.token}-suffix-column`}>
            <MoreOptionsCell group="device" data={device} />
          </Cell>
        ),
      };
    });
  }, [devices]);

  return (
    <div className={styles.root}>
      <Table
        identity="userPreferences/security_devices"
        columns={columns}
        value={value}
        renderFooter={() => null}
        isLoading={isFetching}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="userPreferences_security_devices"
        selectable={false}
        virtualized={false}
      />
    </div>
  );
};

export default DevicesTable;
