import PropTypes from 'prop-types';
import { CSSProperties } from 'react';

import styles from './Label.module.scss';

type LabelProps = {
  labelText: string;
  labelClassName?: string;
  style: CSSProperties;
};

const Label = ({ labelText, labelClassName, style }: LabelProps) => (
  <div className={`${styles.labelContainer} ${labelClassName}`} style={style}>
    {labelText}
  </div>
);

Label.propTypes = {
  labelText: PropTypes.string.isRequired,
};

export default Label;
