import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Tooltip } from 'dodoc-design-system';
import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

const GenericConfirmationModal = () => {
  const intl = useIntl();
  const modalData = useModalData('ConfirmationModal');

  const ctaButtonRef = useRef<HTMLButtonElement>(null);
  const ctaButtonTextRef = useRef<HTMLSpanElement>(null);

  const [isCtaButtonOverflowed, setIsCtaButtonOverflowed] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (ctaButtonTextRef && ctaButtonTextRef.current) {
        setIsCtaButtonOverflowed(
          ctaButtonTextRef.current.clientWidth - ctaButtonTextRef.current.scrollWidth < 0,
        );
      }
    }, 0);
  }, []);

  const applyHiddenToAllChildren = (element: Element) => {
    Array.from(element.childNodes).forEach((child) => {
      if (child instanceof HTMLElement) {
        child.style.overflow = 'hidden';
        child.style.textOverflow = 'ellipsis';
        child.style.whiteSpace = 'nowrap';
        applyHiddenToAllChildren(child);
      }
    });
  };

  useEffect(() => {
    if (ctaButtonRef.current) {
      /**
       * Have to set the overflow hidden to all children of the button to have the text is ellipsis
       */
      applyHiddenToAllChildren(ctaButtonRef.current);
    }
  }, [modalData]);

  if (!modalData) {
    return null;
  }

  const {
    persistent,
    modalWidth,
    headerType,
    title,
    titleValues,
    message,
    messageValues,
    messageChildren,
    confirmButtonType,
    confirmButtonTextId,
    confirmButtonTextValues,
    cancelButtonTextId,
    cancelButtonTextValues,
    onConfirm,
  } = modalData;

  const close = () => {
    closeModal('ConfirmationModal');
  };

  const confirm = () => {
    onConfirm();
    close();
  };

  return (
    <Modal
      open
      onClose={persistent ? undefined : close}
      width={modalWidth}
      type={headerType}
      testId="confirmation-modal"
    >
      <Modal.Header onClose={persistent ? undefined : close}>
        <FormattedMessage id={title} values={titleValues} />
      </Modal.Header>
      <Modal.Body>
        <span style={{ lineHeight: '3rem' }}>
          {messageChildren || <FormattedMessage id={message} values={messageValues} />}
        </span>
      </Modal.Body>
      <Modal.Footer>
        {!persistent && (
          <Button size="medium" onClick={close} testId="confirm-modal-close-button">
            <FormattedMessage id={cancelButtonTextId} values={cancelButtonTextValues} />
          </Button>
        )}
        <Tooltip
          content={intl.formatMessage({ id: confirmButtonTextId }, confirmButtonTextValues)}
          disabled={!isCtaButtonOverflowed}
          testId={'confirmation-modal-cta-button-tooltip'}
        >
          <Button
            ref={ctaButtonRef}
            size="medium"
            variant={confirmButtonType}
            onClick={confirm}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            testId="confirm-modal-submit-button"
          >
            <span ref={ctaButtonTextRef}>
              <FormattedMessage id={confirmButtonTextId} values={confirmButtonTextValues} />
            </span>
          </Button>
        </Tooltip>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericConfirmationModal;
