import { ReactNode } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { usePrimitiveUpdate, useSelector } from '_common/hooks';
import { useGetObjectQuery } from 'App/redux/objectApi';

import Snapshot from './Content/Snapshot/Snapshot';
import Status from './Content/Status/Status';
import Deadline from './Content/Deadline/Deadline';
import Priority from './Content/Priority/Priority';

import styles from './Body.module.scss';

import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

type BodyProps = {
  notificationId: string;
  targetObject?: ObjectParams;
} & Pick<CellMeasurerChildProps, 'measure'>;

const Body = ({ notificationId, targetObject, measure }: BodyProps) => {
  const notification = useSelector((state) => state.notifications.dict[notificationId]);
  const appData = useSelector((state) => state.app.data);
  const unknownObjects = useSelector((state) => state.app.unknownObjects);

  const notificationNeedsDueDate = () => {
    return notification.event === 'deadline_reminder';
  };

  const { data, isLoading } = useGetObjectQuery(targetObject ?? skipToken, {
    skip:
      !targetObject ||
      !!unknownObjects[targetObject.objectId] ||
      !notificationNeedsDueDate() ||
      (notificationNeedsDueDate() &&
        !!(notification.action.extra as ApiSchemas['DeadlineActionExtra'])?.due),
    selectFromResult: (result) => ({
      ...result,
      data:
        result.data ||
        (notification.action.target ? appData[notification.action.target] : undefined),
    }),
  });

  //#region Card measure handling
  usePrimitiveUpdate(() => {
    measure();
  }, isLoading);
  //#endregion

  const renderContent = () => {
    const { extra } = notification.action;
    let content: ReactNode | null = null;

    switch (notification.event) {
      //#region Element deadline
      case 'deadline_reminder':
      case 'deadline_24_reminder': {
        const deadlineExtra = extra as ApiSchemas['DeadlineActionExtra'];
        const type = deadlineExtra.type;

        content = (
          <Deadline
            objectType={type}
            due={deadlineExtra.due || data?.events?.due}
            in24Hours={notification.event === 'deadline_24_reminder'}
          />
        );

        break;
      }
      //#endregion

      //#region Element status
      case 'status_changed': {
        content = (
          <Status
            newStatusId={(extra as ApiSchemas['StatusChangeActionExtra']).new_id}
            oldStatusId={(extra as ApiSchemas['StatusChangeActionExtra']).old_id}
            testId={`notification-row-${notification.id}-document-status-change`}
          />
        );
        break;
      }
      //#endregion

      //#region User mention
      case 'user_mentioned': {
        const userMentionExtra = extra as ApiSchemas['UserMentionActionExtra'];
        if (userMentionExtra.card_type === 'suggestion') {
          content = (
            <Snapshot
              data={{ content: userMentionExtra?.content, type: userMentionExtra?.type }}
              type={userMentionExtra.card_type}
              testId={`snapshot-${notification.id}`}
            />
          );
        } else {
          content = (
            <Snapshot
              data={userMentionExtra?.snapshot || ''}
              type={userMentionExtra.card_type}
              testId={`snapshot-${notification.id}`}
            />
          );
        }

        break;
      }
      //#endregion

      //#region Document comments
      case 'comment_created':
      case 'comment_status_updated':
      case 'comment_reply':
      case 'comment_liked': {
        content = (
          <Snapshot
            data={(extra as ApiSchemas['CommentActionExtra']).snapshot}
            type="comment"
            testId={`snapshot-${notification.id}`}
          />
        );
        break;
      }

      case 'comment_priority_changed': {
        content = (
          <div className={styles.multiPreviews}>
            <Priority
              oldPriorityId={(extra as ApiSchemas['CommentPriorityActionExtra']).old_priority}
              newPriorityId={(extra as ApiSchemas['CommentPriorityActionExtra']).new_priority}
              type="comment"
            />
            <Snapshot
              data={(extra as ApiSchemas['CommentActionExtra']).snapshot}
              type="comment"
              testId={`snapshot-${notification.id}`}
            />
          </div>
        );

        break;
      }
      //#endregion

      //#region Document annotations
      case 'annotation_created':
      case 'annotation_status_changed': {
        const subType = (extra as ApiSchemas['AnnotationActionExtra']).sub_type;
        if (subType) {
          content = (
            <Snapshot
              data={(extra as ApiSchemas['AnnotationActionExtra']).snapshot}
              type="annotation"
              subType={subType}
              testId={`snapshot-${notification.id}`}
            />
          );
        }
        break;
      }

      case 'annotation_content_edited': {
        const annotationExtra = extra as ApiSchemas['AnnotationsContentEditedActionExtra'];
        const subType = annotationExtra.sub_type;
        if (subType) {
          content = (
            <Snapshot
              data={annotationExtra.new_snapshot}
              type="annotation"
              subType={subType}
              testId={`snapshot-${notification.id}`}
            />
          );
        }
        break;
      }

      case 'annotation_replied': {
        const subType = (extra as ApiSchemas['AnnotationActionExtra']).sub_type;
        if (subType) {
          content = (
            <Snapshot
              data={(extra as ApiSchemas['AnnotationRepliedActionExtra']).reply_snapshot}
              type="annotation"
              subType={subType}
              testId={`snapshot-${notification.id}`}
            />
          );
        }
        break;
      }

      case 'annotation_priority_changed': {
        const subType = (extra as ApiSchemas['AnnotationActionExtra']).sub_type;
        if (subType) {
          content = (
            <div className={styles.multiPreviews}>
              <Priority
                oldPriorityId={(extra as ApiSchemas['AnnotationPriorityActionExtra']).old_priority}
                newPriorityId={(extra as ApiSchemas['AnnotationPriorityActionExtra']).new_priority}
                type="annotation"
              />
              <Snapshot
                data={(extra as ApiSchemas['AnnotationActionExtra']).snapshot}
                type="annotation"
                subType={subType}
                testId={`snapshot-${notification.id}`}
              />
            </div>
          );
        }
        break;
      }

      //#endregion

      //#region Document suggestions
      case 'suggestion_status_updated':
      case 'suggestion_replied':
      case 'suggestion_liked': {
        const suggestionContent = extra as ApiSchemas['SuggestionActionExtra'];
        content = (
          <Snapshot
            data={{ content: suggestionContent.content, type: suggestionContent.type }}
            type="suggestion"
            testId={`snapshot-${notification.id}`}
          />
        );
        break;
      }
      //#endregion

      //#region Document tasks
      case 'task_deadline_reminder': {
        content = (
          <div className={styles.multiPreviews}>
            <Deadline
              objectType={'task'}
              due={(extra as ApiSchemas['TaskDeadlineActionExtra']).deadline}
              in24Hours
            />
            <Snapshot
              data={(extra as ApiSchemas['TaskDeadlineActionExtra']).snapshot}
              type="task"
              testId={`snapshot-${notification.id}`}
            />
          </div>
        );
        break;
      }
      case 'task_status_changed': {
        content = (
          <div className={styles.multiPreviews}>
            <Status
              newStatusId={(extra as ApiSchemas['TaskStatusChangedActionExtra']).new_status}
              oldStatusId={(extra as ApiSchemas['TaskStatusChangedActionExtra']).old_status}
              testId={`notification-row-${notification.id}-task-status-change`}
            />
            <Snapshot
              data={(extra as ApiSchemas['TaskStatusChangedActionExtra']).snapshot}
              type="task"
              testId={`snapshot-${notification.id}`}
            />
          </div>
        );
        break;
      }
      case 'task_deadline':
      case 'task_assignee_updated':
      case 'task_add_watcher':
      case 'task_remove_watcher':
      case 'task_reply_liked':
      case 'task_replied': {
        content = (
          <Snapshot
            data={(extra as ApiSchemas['TaskActionExtra']).snapshot}
            type="task"
            testId={`snapshot-${notification.id}`}
          />
        );
        break;
      }
      //#endregion

      default: {
        break;
      }
    }

    if (content) {
      return <div className={styles.root}>{content}</div>;
    }

    return null;
  };

  return <div>{renderContent()}</div>;
};

export default Body;
