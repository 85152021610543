/**
 * Explanation of constant value (3cd4):
 *
 *  Context: "Units are in 60'000 of a degree" AND Constant value of 3cd4 is 16'200'000
 *  Process to convert constant value to degree:
 *    60'000 * 360 = 21'600'000
 *    16'200'000 ÷ 21'600'000 = 0.75
 *    0.75 * 360 = 270 (degrees)
 *
 *  Ex: Given an angle with constant value of -10'800'000:
 *    -10'800'000 ÷ 21'600'000 = -0.5
 *    -0.5 * 360 = -180 (degrees)
 *
 * These values are in radian
 *
 * Source: Page 2988 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */
export const SHAPE_CONST = {
  cd: Math.PI * 2, // circle
  '7cd8': Math.PI * 1.75, // 7/8 of circle
  '3cd4': Math.PI * 1.5, // 3/4 of circle
  '5cd8': Math.PI * 1.25, // 5/8 of circle
  cd2: Math.PI, // 1/2 of circle
  '3cd8': Math.PI * 0.75, // 3/8 of circle
  cd4: Math.PI * 0.5, // 1/4 of circle
  cd8: Math.PI * 0.25, //1/8 of circle
} as const;
