import { useState } from 'react';
import { useDispatch, useSelector } from '_common/hooks/redux';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';

import ToolbarToggleButton from 'Editor/components/ToolbarToggleButton/ToolbarToggleButton';
import ColorsMenu from 'Editor/components/ColorsMenu/ColorsMenu';
import { Tooltip, Popover, usePopper } from 'dodoc-design-system';

const FontBackgroundColor = ({ isReadOnlyMode }: { isReadOnlyMode: boolean }) => {
  const dispatch = useDispatch();
  const fontBackgroundColor = useSelector((state) =>
    state.editor.toolbar.highlightColor
      ? state.editor.toolbar.highlightColor.replace(/ /g, '')
      : '',
  );
  const [documentColors, setDocumentColors] = useState<
    { r: string | number; g: string | number; b: string | number; rgb: string }[]
  >([]);

  const { isOpen, close, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  const onBackgroundColorChange = (color: string) => {
    EditorManager.getInstance().toggleSelectionStyle(StylesUtils.STYLES.HIGHLIGHTCOLOR, color);
  };

  const onClearBackgroundColor = () => {
    EditorManager.getInstance().removeSelectionStyle(StylesUtils.STYLES.HIGHLIGHTCOLOR);
  };

  const handleToggleClick = (onClick: { (): void }) => {
    const documentColors = EditorManager.getInstance().getDocumentColors();
    if (documentColors) {
      setDocumentColors(documentColors);
    }
    onClick();
  };

  const openColorPickerModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ColorPickerModal',
        data: {
          action: 'toggleSelectionStyle',
          actionValues: { style: StylesUtils.STYLES.HIGHLIGHTCOLOR },
        },
      }),
    );
  };

  return (
    <>
      <Tooltip content="Highlight Color" testId="font-background-tooltip">
        <div>
          <ToolbarToggleButton
            ref={referenceProps.ref}
            disabled={isReadOnlyMode}
            isActive={isOpen}
            toggleButtonClicked={() => handleToggleClick(referenceProps.onClickCapture)}
            icon="TextBckColor"
            color={fontBackgroundColor}
            testId="font-background"
          />
        </div>
      </Tooltip>
      <Popover {...popperProps} testId="highlight-color-popper">
        <ColorsMenu
          toggle={close}
          changeBackgroundColor={(color: string) => {
            onBackgroundColorChange(color);
            close();
          }}
          clearBackgroundColor={() => {
            onClearBackgroundColor();
            close();
          }}
          documentColors={documentColors}
          selected={fontBackgroundColor}
          setOpenColorPickerModal={openColorPickerModal}
          attribute={undefined}
        />
      </Popover>
    </>
  );
};

export default FontBackgroundColor;
