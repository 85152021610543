import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, SectionHeading as BaseSectionHeading } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import type { SectionHeadingProps as BaseSectionHeadingProps } from 'dodoc-design-system/build/types/Components/SectionHeading/SectionHeading';

import { useDebounce, useSelector, useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useFilterSelector } from '_common/components/Filters/FilterSlice';
import FilterPopover from '_common/components/Filters/Components/FilterPopover/FilterPopover';

import { useAuditLog } from '../AuditLogContext';
import useGetActionOptions from '../ActionSelect/useGetActionOptions';

export type SectionHeadingProps = Pick<BaseSectionHeadingProps<'large'>, 'margin'> & {
  objectId: ObjectId;
  objectType: ObjectType;
  objectCreationDate: string;
};

const SectionHeadingContent = ({
  objectId,
  objectType,
  objectCreationDate,
  margin,
}: SectionHeadingProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { selectFilterParams } = useFilterSelector();

  const { data } = useAuditLog();

  //Filters
  const headerFilterParams = useSelector((state) =>
    selectFilterParams(state, state.filters.auditLog),
  );
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const debouncedSearchValue = useDebounce(searchValue, 250);
  const options = useGetActionOptions({ objectType });
  const { setSearchActions } = useAuditLog();

  //Export
  const { params } = useAuditLog();
  const { filter_values, filter_fields } = params;

  const handleExportAudit = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'EXPORT_AUDIT_LOG',
          message: 'CONFIRM_EXPORT_AUDIT_LOG',
          confirmButtonTextId: 'EXPORT_AUDIT_LOG',
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'exportAuditLog',
          actionValue: {
            objectId,
            objectType,
            params: { filter_values, filter_fields },
          },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setSearchValue(e.target.value);
  };

  const getActionsBySearch = (search?: string) => {
    let actions: string[] = [''];
    if (search) {
      const query = search.toLocaleLowerCase();
      options.forEach((option) => {
        if (option.label.toLocaleLowerCase().includes(query) || option.value?.includes(query)) {
          actions[0] = (actions[0] ? `${actions[0]},` : '') + option.value;
        }
      });
      return actions;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!headerFilterParams) {
      setSearchValue(undefined);
    }
  }, [headerFilterParams]);

  useEffect(() => {
    setSearchActions(getActionsBySearch(debouncedSearchValue));
  }, [debouncedSearchValue]);

  return (
    <BaseSectionHeading
      sticky
      margin={margin}
      title={intl.formatMessage({ id: 'AUDIT_LOG' })}
      size="large"
      search={
        <Input
          size="medium"
          prefix="NavSearchBlue"
          placeholder={intl.formatMessage({
            id: 'SEARCH_BY_ACTION',
          })}
          onChange={handleSearchChange}
          value={searchValue}
          width="31rem"
          disabled={data?.length === 0 && !searchValue}
          testId="audit-log-search"
        />
      }
      searchFilters={
        <FilterPopover
          identity="auditLog"
          auditActions={{
            options,
            settings: {
              placeholder: 'SELECT_ACTION_TYPE',
              multiOverflowLabel: 'ACTIONS_SELECTED',
              selectedOptionsGroupLabel: 'SELECTED_ACTIONS',
              listOptionsGroupLabel: 'UNSELECTED_ACTIONS',
            },
          }}
          timestamp={{
            settings: {
              minDate: objectCreationDate,
            },
          }}
        />
      }
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'EXPORT_AUDIT_LOG' }),
        disabled: data?.length === 0,
        onClick: handleExportAudit,
        testId: 'audit-log-export',
      }}
      testId="audit-log-heading"
    />
  );
};

export default SectionHeadingContent;
