// eslint-disable-next-line
type ThirdPartyIcon = 'Google' | 'Facebook' | 'Linkedin' | 'Exostar' | 'ThirdParty' | string;
export const getThirdPartyIcon = (label: ThirdPartyIcon) => {
  switch (label) {
    case 'Google':
      return 'Google';
    case 'Facebook':
      return 'Facebook';
    case 'Linkedin':
      return 'LinkedIn';
    case 'Exostar':
      return 'ExoStar';
    case 'Envision 365':
      return 'EnvisionPharma';
    default:
      return null;
  }
};
