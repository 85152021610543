import { ChangeEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, InputField, TextArea, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { editDescription } from 'App/redux/appSlice';

import styles from './Description.module.scss';

type DescriptionProps = {
  canEdit?: boolean;
};

const Description = ({ canEdit }: DescriptionProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  const [edit, setEdit] = useState(false);
  const [currentDescription, setCurrentDescription] = useState(object?.description);

  useEffect(() => {
    setCurrentDescription(object?.description);
    setEdit(false);
  }, [elementId]);

  const handleEnableEdit = () => {
    setEdit(true);
  };

  const handleDisabledEdit = () => {
    setEdit(false);
  };

  const handleSaveDescription = () => {
    dispatch(
      editDescription({
        objectId: elementId,
        objectType: object.type,
        newDescription: currentDescription,
      }),
    );

    setEdit(false);
  };

  const handleSetDescription: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setCurrentDescription(e.target.value);
  };

  const renderDescription = () => {
    /**Edit and has permissions*/
    if (edit) {
      return (
        <>
          <InputField
            label={intl.formatMessage({ id: 'global.description' })}
            testId="information-panel-description-field"
          >
            <TextArea
              height="9rem"
              value={currentDescription}
              onChange={handleSetDescription}
              placeholder={intl.formatMessage({ id: 'INSERT_YOUR_TEXT' })}
              testId="information-panel-description-textarea"
            />
          </InputField>
          <div className={styles.actions}>
            <Button
              size="small"
              onClick={handleDisabledEdit}
              testId="information-panel-description-close-button"
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              size="small"
              variant="primary"
              onClick={handleSaveDescription}
              testId="information-panel-description-save-button"
            >
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div className={styles.editableDescription}>
          {canEdit ? (
            <>
              <div
                className={styles.descriptionLabel}
                data-testid="information-panel-description-label"
              >
                {object.description || intl.formatMessage({ id: 'ADD_DESCRIPTION' })}
              </div>
              <Toggle
                variant="link"
                size="medium"
                icon="PencilGrey"
                onClick={handleEnableEdit}
                testId="information-panel-edit-description-toggle"
              />
            </>
          ) : (
            <div
              className={`${styles.descriptionLabel} ${styles.noPermissions}`}
              data-testid="information-panel-description-label"
            >
              {object.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className={styles.root}>
      <Divider margin="0 1rem" />
      <div className={styles.descriptionWrapper}>{renderDescription()}</div>
    </div>
  );
};

export default Description;
