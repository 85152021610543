import { useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';
import styles from './Error403.module.scss';
import { Button, Icon, Logo } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import { navigateToMyFiles } from 'router/history';
import AuthFooter from 'Auth/components/AuthFooter/AuthFooter';

const Error403 = () => {
  const information = useSelector((state) => state.app.information);
  const isiEnvision = useSelector(selectIsIEnvision);
  const navigateToIEnvision = () => {
    if (information.extra) {
      window.location.href = `${information.extra.source}`;
    }
  };

  return (
    <div className={styles.root}>
      <Logo expanded size="large" testId="error403-logo" />
      <Icon margin="5rem 0 3rem 0" size={240} icon="Lock" />
      <div className={styles.message}>
        <FormattedMessage id="It seems you do not have permissions to see this page" />
      </div>
      <Button
        size="medium"
        variant="primary"
        onClick={() => {
          isiEnvision ? navigateToIEnvision() : navigateToMyFiles(false, true);
        }}
        margin="3rem 0 0 0"
        testId={`${isiEnvision ? 'back-to-ienvision' : 'back-to-your-space'}-buttton`}
      >
        <FormattedMessage id={isiEnvision ? 'BACK_TO_IENVISION' : 'errorView.clickToGoBack'} />
      </Button>
      <div className={styles.footer}>
        <AuthFooter />
      </div>
    </div>
  );
};

export default Error403;
