import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { closeModal } from 'App/ModalContext/utils';
import { useModalData } from 'App/ModalContext/ModalContext';

import { AuditLog } from '_common/components';

import styles from './DocumentAuditLogModal.module.scss';

const DocumentAuditLogModal = () => {
  const data = useModalData('DocumentAuditLogModal');

  if (!data) {
    return null;
  }

  const close = () => {
    closeModal('DocumentAuditLogModal');
  };

  return (
    <Modal
      open
      onClose={close}
      width="131rem"
      testId="document-audit-log"
      isVisible={data.isVisible}
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id="DOCUMENT_AUDIT_LOG" />
      </Modal.Header>
      <Modal.Body overflow="visible" className={styles.modalRoot}>
        <AuditLog
          objectId={data.objectId}
          objectType={data.objectType}
          navigateToObject={data.navigateToObject}
          noFlex
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="document-audit-log-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentAuditLogModal;
