import { FC } from 'react';
import * as echarts from 'echarts';

import { useChartColor, useChartOutline, useChartPlotArea, useFormatCode } from '../hooks';

import ChartBase from '../ChartBase/ChartBase';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import { indexedModEffects } from 'Presentation/utils';

type RadarChartProps = {
  shape: Presentation.Data.ChartShape;
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.RadarSeriesOption[] | undefined => {
  const { color } = useSlideData();
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();

  if (shape.chartSpace.chart.plotArea.chartTypes?.[0].type !== 'radar') {
    return undefined;
  }

  const chartTypeData: Presentation.Data.RadarChart = shape.chartSpace.chart.plotArea.chartTypes[0];
  return chartTypeData.ser.map((serie) => {
    const radarOutline = chartOutline(serie.properties?.ln);
    const labelOutline = chartOutline(serie?.dLbls?.properties?.ln);
    const labelTextOutline = chartOutline(
      serie?.dLbls?.text?.childNodes?.[0]?.properties.inlineProperties?.ln,
    );
    const markerOutline = chartOutline(serie?.marker?.properties?.ln);
    const { blur, outerShdw } = indexedModEffects(serie.properties?.effects);
    const dataValues = serie.val?.numRef?.numCache.pt.map((numVal) => numVal.v) ?? [];
    const data = [
      {
        value: [dataValues[0]].concat(dataValues.slice(1).reverse()),
        name: serie.tx?.strRef?.strCache?.pt[0].v,
        label: {
          show: serie.dLbls ? true : false,
          distance: 15,
          backgroundColor: chartColor(serie?.dLbls?.properties?.fill),
          color: chartColor(
            serie?.dLbls?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
          ),
          borderColor: labelOutline?.borderColor,
          borderWidth: labelOutline?.borderWidth,
          borderType: labelOutline?.borderType,
          cap: labelOutline?.borderCap,
          miterLimit: labelOutline?.borderMiterLimit,
          join: labelOutline?.borderJoin,
          textBorderColor: labelTextOutline?.borderColor,
          textBorderWidth: labelTextOutline?.borderWidth,
          textBorderType: labelTextOutline?.borderType,
        },
      },
    ];

    const option: echarts.RadarSeriesOption = {
      type: chartTypeData.type,
      name: serie.tx?.strRef?.strCache?.pt[0].v,
      id: serie.idx,
      symbol: serie.dLbls && serie?.marker?.symbol === 'none' ? 'marker' : serie?.marker?.symbol,
      symbolSize:
        !serie?.marker?.symbol || serie?.marker?.symbol === 'none' ? 0 : serie.marker.size,
      lineStyle: {
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        color: radarOutline?.borderColor,
        width: radarOutline?.borderWidth,
        type: radarOutline?.borderType,
        cap: radarOutline?.borderCap,
        join: radarOutline?.borderJoin,
        miterLimit: radarOutline?.borderMiterLimit,
        shadowBlur: blur?.rad ?? outerShdw?.blurRad,
        shadowColor: blur?.color ? color(blur?.color) : undefined,
      },
      itemStyle: {
        ...markerOutline,
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        color:
          (serie?.marker?.properties?.fill && serie?.marker?.properties?.fill?.type === 'none') ||
          !serie?.marker?.properties?.fill
            ? 'transparent'
            : chartColor(serie?.marker?.properties?.fill),
      },
      areaStyle: {
        //@ts-expect-error
        color:
          chartTypeData?.radarStyle === 'filled'
            ? chartColor(serie?.properties?.fill)
            : 'transparent',
        opacity: 1,
      },
      silent: true,
      //@ts-expect-error echarts types
      data: data,
    };

    return option;
  });
};

const RadarChart: FC<RadarChartProps> = ({ shape }) => {
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });
  const { addUnsupportedElement } = useSlideData();
  const { chartOutline } = useChartOutline();
  const { chartColor } = useChartColor();
  const { formatValue } = useFormatCode();

  const chart = shape.chartSpace.chart.plotArea;
  //@ts-expect-error
  const chartType: Presentation.Data.RadarChart = chart.chartTypes[0];
  const radarAxisOutline = chartOutline(chart.chartAxes[1]?.properties?.ln);
  const radarAxisSplitLineOutline = chartOutline(chart.chartAxes[1].majorGridlines?.properties?.ln);
  const dataValues = chartType.ser[0]?.cat?.numRef
    ? chartType.ser[0]?.cat?.numRef?.numCache?.pt.map((numVal) => numVal.v)
    : chartType.ser[0]?.cat?.strRef?.strCache?.pt.map((numVal) => numVal.v);
  const indicatorLabelValue = dataValues
    ? [dataValues[0]].concat(dataValues?.slice(1).reverse())
    : [];

  const allDataValues = series
    ? series
        .filter((s) => s.type === 'radar')
        .reduce((acc, s) => {
          s?.data?.forEach((d) => {
            //@ts-expect-error type data
            acc.push(...d.value);
          });
          return acc;
        }, [])
    : [];
  const maxValue = Math.max(...allDataValues);

  const handleMaxValue = () => {
    const maxValueString = maxValue.toString();
    if (maxValueString.length === 2) {
      if (parseInt(maxValueString[1]) > 0 && parseInt(maxValueString[1]) < 5) {
        return parseInt(maxValueString[0] + '5');
      } else {
        return Math.round(maxValue * 0.1) * 10;
      }
    } else {
      if (parseInt(maxValueString[1]) > 0 && parseInt(maxValueString[1]) < 5) {
        const newValue = maxValueString.replace(maxValueString[1], '5');
        return parseInt(newValue);
      } else {
        const firstNumbers = maxValueString.slice(0, 2);
        const roundNumber = Math.round(Number(firstNumbers) * 0.1) * 10;
        const newValue = maxValueString.replace(firstNumbers.toString(), roundNumber.toString());
        return parseInt(newValue);
      }
    }
  };

  const indicator = indicatorLabelValue.map((content, index) => {
    const labelOutline = chartOutline(
      chart.chartAxes[1]?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
    );
    const formatCode = chartType.ser[0].cat?.numRef?.numCache.formatCode;

    if (chart.chartAxes[0]?.tickLblPos === 'none') {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:RADAR:SERIESOPTIONS:CATEGORYLABELS
      addUnsupportedElement(`Chart - Radar - Category labels`);
    }

    return {
      name: chart.chartAxes[1].delete ? '' : formatValue(content, formatCode).content,
      max: handleMaxValue(),
      axisLabel: {
        show: index === 0 && chartType.radarStyle !== 'filled' ? true : false,
        color: chartColor(
          chart.chartAxes[1]?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
        ),
        backgroundColor: chartColor(chart.chartAxes[1]?.properties?.fill),
        formatter: (params: number) => {
          return Math.round(params);
        },
        textBorderColor: labelOutline?.borderColor,
        textBorderWidth: labelOutline?.borderWidth,
        textBorderType: labelOutline?.borderType,
        cap: labelOutline?.borderCap,
        miterLimit: labelOutline?.borderCap,
        join: labelOutline?.borderJoin,
      },
    };
  });
  const radarlabelOutline = chartOutline(
    chart.chartAxes[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
  );
  const radarInfo = {
    indicator: indicator,
    axisName: {
      show: true,
      color: chartColor(
        chart.chartAxes[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
      ),
      backgroundColor:
        chart.chartAxes[0]?.properties?.fill?.type !== 'none'
          ? chartColor(chart.chartAxes[0]?.properties?.fill)
          : 'transparent',
      textBorderColor: radarlabelOutline?.borderColor,
      textBorderWidth: radarlabelOutline?.borderWidth,
      textBorderType: radarlabelOutline?.borderType,
      cap: radarlabelOutline?.borderCap,
      miterLimit: radarlabelOutline?.borderCap,
      join: radarlabelOutline?.borderJoin,
    },
    axisLine: {
      show: chartColor(chart.chartAxes[1].properties?.ln?.fill) ? true : false,
      lineStyle: {
        color:
          //@ts-expect-error CHARTS:LIMITATION color doesnt support picture
          chart.chartAxes[1].properties?.ln?.fill.type !== 'none'
            ? chartColor(chart.chartAxes[1].properties?.ln?.fill)
            : chartColor(
                chart.chartAxes[0]?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
              ),
        width: radarAxisOutline?.borderWidth,
        type: radarAxisOutline?.borderType,
        cap: radarAxisOutline?.borderCap,
        join: radarAxisOutline?.borderJoin,
        miterLimit: radarAxisOutline?.borderMiterLimit,
      },
    },
    axisTick: {
      show:
        chart.chartAxes[1].majorTickMark !== 'none' &&
        //@ts-expect-error
        chart.chartAxes[1].properties?.ln?.fill?.type !== 'none',
      inside: chart.chartAxes[1].majorTickMark === 'in',
      lineStyle: {
        color: chartColor(chart.chartAxes[1].properties?.ln?.fill),
        width: radarAxisOutline?.borderWidth,
        type: radarAxisOutline?.borderType,
        cap: radarAxisOutline?.borderCap,
        join: radarAxisOutline?.borderJoin,
        miterLimit: radarAxisOutline?.borderMiterLimit,
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: chartColor(chart.chartAxes[1].majorGridlines?.properties?.ln?.fill),
        width: radarAxisSplitLineOutline?.borderWidth,
        type: radarAxisSplitLineOutline?.borderType,
        cap: radarAxisSplitLineOutline?.borderCap,
        join: radarAxisSplitLineOutline?.borderJoin,
        miterLimit: radarAxisSplitLineOutline?.borderMiterLimit,
      },
    },
    splitArea: {
      show: false,
    },
    splitNumber: 7,
  };

  const array1 = [];

  for (let i = 0; i <= 7; i++) {
    array1.push('');
  }

  let topValue = 6;
  let textValue = (handleMaxValue() / 7) * 8;

  const graphicValues = array1?.map(() => {
    topValue += 5.3;
    textValue -= handleMaxValue() / 7;
    return {
      type: 'text',
      right: '51%',
      top: `${topValue}% `,
      style: {
        text: `${textValue < 0 ? 0 : Math.floor(textValue)} `,
        backgroundColor: chartColor(chart.chartAxes[1]?.properties?.fill),
        fill: chartColor(
          chart.chartAxes[1]?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill,
        ),
      },
      z: 3,
    };
  });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        series: series,
        graphic:
          chart.chartAxes[1].delete || chartType.radarStyle !== 'filled' ? [] : graphicValues,
        //@ts-expect-error echarts types
        radar: {
          ...radarInfo,
        },
      }}
    />
  );
};

export default RadarChart;
