import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePublicProfiles } from '_common/hooks';

type MemberCellProps = {
  object: Objekt;
};

const MemberCell = ({ object }: MemberCellProps) => {
  const intl = useIntl();
  const { profiles } = usePublicProfiles(object.users);
  const [totalMembers, setTotalMembers] = useState(0);

  useEffect(() => {
    setTotalMembers(Object.keys(profiles).length);
  }, [profiles]);

  return (
    <>
      {`${totalMembers} ${intl
        .formatMessage(
          {
            id: 'groups.header.member',
          },
          {
            membersCount: totalMembers,
          },
        )
        .toLowerCase()}`}
    </>
  );
};

export default MemberCell;
