import { Mixin } from 'mixwith';
import { notify } from '_common/components/ToastSystem';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class InsertInlineHandler extends superclass {
      // ##########################################################
      //               handle insert inline element
      // ##########################################################
      /**
       * @param {ActionContext} actionContext
       * @param {*} newNode
       * @param {*} baseNode
       * @param {*} anchorNode
       */
      _handleInsertInlineDefaultNode(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions = {},
      ) {
        selectionOptions.suggestionMode = true;
        EditorSelectionUtils.fixCollapsedTextSelection(selectionOptions);

        if (selectionOptions.forceInlineSplit) {
          const range = EditorSelectionUtils.getRange();
          // split inline selection
          const { anchorNode, anchorOffset } = EditorSelectionUtils.splitInlineTextElements(
            baseNode,
            range.startContainer,
            range.startOffset,
          );

          if (anchorNode && anchorNode !== range.startContainer && baseNode.contains(anchorNode)) {
            EditorSelectionUtils.setCaret(anchorNode, 'INDEX', anchorOffset);
          }

          actionContext.addChangeUpdatedNode(baseNode);
        }

        if (selectionOptions.forceBlockSplit) {
          const { before } = this.handleTrackedEnterOnText(
            actionContext,
            baseNode,
            baseNode,
            anchorNode,
            true,
          );
          EditorSelectionUtils.setCaret(before, 'INSIDE_END');
        }

        anchorNode = EditorSelectionUtils.getSelection().anchorNode;
        const closest = EditorDOMUtils.closest(anchorNode, ELEMENTS.TrackInsertElement.TAG);
        if (
          closest &&
          this.isUserAuthor(closest) &&
          (this.isDeleteParagraphMarker(closest) || this.isAddParagraphMarker(closest))
        ) {
          const insertSuggestion = this.getTrackInsElement(actionContext.id);
          EditorDOMUtils.insertNodeBefore(closest.parentNode, insertSuggestion, closest);
          EditorSelectionUtils.setCaret(insertSuggestion, 'INSIDE_END');
        } else if (closest && this.isUserAuthor(closest)) {
          actionContext.addReferenceToRefresh(closest.getAttribute('element_reference'));
        } else if (
          anchorNode.parentNode !== this.page &&
          !EditorDOMElements.isNodeContainerElement(anchorNode.parentNode) &&
          EditorSelectionUtils.isSelectionAtStart(anchorNode) &&
          this.isNodeTrackedAction(anchorNode.previousSibling, ELEMENTS.TrackInsertElement.TAG)
        ) {
          EditorSelectionUtils.setCaret(anchorNode.previousSibling, 'INSIDE_END');
          actionContext.addReferenceToRefresh(
            anchorNode.previousSibling.getAttribute('element_reference'),
          );
        } else if (
          anchorNode.parentNode !== this.page &&
          !EditorDOMElements.isNodeContainerElement(anchorNode.parentNode) &&
          EditorSelectionUtils.isSelectionAtEnd(anchorNode) &&
          this.isNodeTrackedAction(anchorNode.nextSibling, ELEMENTS.TrackInsertElement.TAG)
        ) {
          EditorSelectionUtils.setCaret(anchorNode.nextSibling, 'INSIDE_START');
          actionContext.addReferenceToRefresh(
            anchorNode.nextSibling.getAttribute('element_reference'),
          );
        } else {
          const insertSuggestion = this.getTrackInsElement(
            this.getProperSuggestionRef(actionContext),
          );

          this.insertInlineNode(actionContext, insertSuggestion);
          EditorSelectionUtils.setCaret(insertSuggestion, 'INSIDE_END');
        }

        // insert normal text element
        if (typeof newNode === 'string') {
          this.insertTextInline(actionContext, newNode);
        } else {
          this.insertInlineNode(actionContext, newNode);
        }
        // EditorSelectionUtils.setCaret(newNode, 'END');
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} citationElement
       * @param {*} baseNode
       * @param {*} anchorNode
       */
      _handleInsertInlineCitationNode(
        actionContext,
        citationElement,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        const selection = EditorSelectionUtils.getSelection();
        let closestCitationGroup = EditorDOMUtils.findClosestCitationGroup(
          selection.anchorNode,
          selection.anchorOffset,
        );

        if (closestCitationGroup) {
          const citationReference = citationElement.getAttribute('element_reference');
          if (
            closestCitationGroup.querySelector(
              `citation-element[element_reference="${citationReference}"]`,
            )
          ) {
            notify({
              type: 'warning',
              title: 'global.warning',
              message: 'editor.warnings.insert',
            });
          } else {
            const closestSuggestion = this.hasClosestSuggestion(closestCitationGroup);

            if (closestSuggestion) {
              closestCitationGroup.addCitationElement(citationElement);
              actionContext.addChangeUpdatedNode(closestCitationGroup);
              actionContext.addReferenceToRefresh(
                closestSuggestion.getAttribute('element_reference'),
              );
            } else {
              const insertSuggestion = this.getTrackInsElement(actionContext.id);

              insertSuggestion.appendChild(citationElement);

              closestCitationGroup.addCitationElement(insertSuggestion);

              actionContext.addChangeUpdatedNode(insertSuggestion);

              insertSuggestion.setAttribute(
                'element_reference',
                this.getProperSuggestionRef(actionContext, insertSuggestion),
              );
            }

            EditorSelectionUtils.setCaret(closestCitationGroup, 'POST');
          }
        } else {
          closestCitationGroup = DOMElementFactory.buildElement(
            ELEMENTS.CitationsGroupElement.IDENTIFIER,
            {
              element_type: ELEMENTS.CitationsGroupElement.IDENTIFIER,
            },
          );
          closestCitationGroup.addCitationElement(citationElement);

          this._handleInsertInlineDefaultNode(
            actionContext,
            closestCitationGroup,
            baseNode,
            anchorNode,
            selectionOptions,
          );
          EditorSelectionUtils.setCaret(closestCitationGroup, 'POST');
        }
      }

      /**
       * handle insert inline in text element
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertInlineNodeOnTextElement(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        switch (newNode.tagName) {
          // case ELEMENTS.CitationsGroupElement.TAG:
          case ELEMENTS.CitationElement.TAG:
            this._handleInsertInlineCitationNode(
              actionContext,
              newNode,
              baseNode,
              anchorNode,
              selectionOptions,
            );
            break;
          default:
            this._handleInsertInlineDefaultNode(
              actionContext,
              newNode,
              baseNode,
              anchorNode,
              selectionOptions,
            );
            break;
        }
      }

      /**
       * handle insert inline in a figure
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertInlineNodeOnFigure(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        const closest = EditorDOMUtils.closest(anchorNode, ['FIGCAPTION']);
        if (closest && !closest.hasAttribute('lock')) {
          this._handleInsertInlineNodeOnTextElement(
            actionContext,
            newNode,
            baseNode,
            anchorNode,
            selectionOptions,
          );
        }
      }

      /**
       * handle insert inline in a table
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      _handleInsertInlineNodeOnTable(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        const closest = EditorDOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = EditorDOMUtils.findFirstLevelChildNode(closest, anchorNode);
            if (tdLevel0Node && tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
              this.handleInsertInlineNodeOnCollapsedSelection(
                actionContext,
                newNode,
                tdLevel0Node,
                anchorNode,
                selectionOptions,
              );
            }
          }
        }
      }

      _handleInsertInlineOnContainerElement(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions,
      ) {
        if (EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }

        let subLevel0Node;
        if (baseNode === anchorNode) {
          subLevel0Node = baseNode.firstChild;
        } else {
          subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        }

        if (subLevel0Node) {
          this.handleInsertInlineNodeOnCollapsedSelection(
            actionContext,
            newNode,
            subLevel0Node,
            anchorNode,
            selectionOptions,
          );
        }
      }

      /**
       * @description insert node inside a level0 node on collapsed selection
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       * @param {Node} baseNode
       * @param {Node} anchorNode
       */
      handleInsertInlineNodeOnCollapsedSelection(
        actionContext,
        newNode,
        baseNode,
        anchorNode,
        selectionOptions = {},
      ) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
              EditorSelectionUtils.fixCollapsedTextSelection({
                suggestionMode: true,
                forceTextAsWrap: !!selectionOptions.forceTextAsWrap,
              });
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this._handleInsertInlineNodeOnTextElement(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              // does nothing
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this._handleInsertInlineNodeOnFigure(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this._handleInsertInlineNodeOnTable(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
              this._handleInsertInlineOnContainerElement(
                actionContext,
                newNode,
                baseNode,
                anchorNode,
                selectionOptions,
              );
            }
          }
        }
      }

      /**
       * @description insert node inside a level0 node on non-collapsed selection
       * @param {ActionContext} actionContext
       * @param {Node} newNode
       */
      /* eslint-disable-next-line */
      handleInsertInlineNodeOnMultiSelection(actionContext, newNode, selectionOptions = {}) {
        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        actionContext.selectionBackwards = EditorSelectionUtils.isSelectionBackwards();

        EditorSelectionUtils.fixNonCollapsedTextSelection({
          suggestionMode: true,
          forceTextAsWrap: true,
        });

        if (this.removeSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
          const anchorNode = selection.anchorNode;

          this.handleInsertInlineNodeOnCollapsedSelection(
            actionContext,
            newNode,
            baseNode,
            anchorNode,
            selectionOptions,
          );
        }
      }
    },
);
