import { BaseTypedEmitter } from '_common/services/Realtime';

export class ReadyState extends BaseTypedEmitter<{
  READY: () => {};
}> {
  protected state: { [index: string]: boolean };
  protected pendingPromise?: Promise<void>;
  protected pendingResolve?: any;
  constructor() {
    super();
    this.state = {};
  }

  private allReady() {
    return Object.keys(this.state).every((name) => Boolean(this.state[name]));
  }

  _checkIsReady() {
    if (this.allReady()) {
      this.emit('READY');
      if (this.pendingResolve) {
        this.pendingResolve();
      }
    }
  }

  pending() {
    if (this.allReady()) {
      return Promise.resolve();
    }
    if (!this.pendingPromise) {
      this.pendingPromise = new Promise((resolve) => {
        this.pendingResolve = resolve;
      });
    }
    return this.pendingPromise;
  }

  register(name: string) {
    this.state[name] = false;
  }

  setReady(name: string) {
    this.state[name] = true;
    this._checkIsReady();
  }
}
