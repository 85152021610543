import { useIntl } from "react-intl";
import { useSelector } from "_common/hooks";

type PermissionsCellProps = {
  object: Objekt;
}

const PermissionsCell = ({ object }: PermissionsCellProps) => {
  const intl = useIntl();
  const loggedUserId = useSelector((state) => state.auth.userId);

  const renderPermission = () => {
    let permission = '';

    if (object.users.includes(loggedUserId)) {
      permission = intl.formatMessage(
        {
          id: 'groups.header.member',
        },
        { membersCount: 1 },
      );
    }

    if (object.permissions.users[loggedUserId]) {
      if (object.permissions.users[loggedUserId].includes('owner')) {
        permission = intl.formatMessage({
          id: 'groups.header.owner',
        });
      } else {
        permission = 'Other Role';
      }
    }

    if (object.owner === loggedUserId) {
      permission = intl.formatMessage({
        id: 'groups.header.owner',
      });
    }

    return permission;
  };

  return (
    <>{renderPermission()}</>
  );
};

export default PermissionsCell;