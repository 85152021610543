/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Structure } from '../../models';
import { BaseController } from '../BaseController';

export class StructureController extends BaseController {
  private structure?: Structure;

  async start(documentId: string) {
    this.structure = this.Data.models?.get('STRUCTURE', `PS${documentId}`);
    await this.structure?.awaitForEvent('LOADED');
  }

  get numSlides() {
    return this.structure?.slideCount || 0;
  }

  getSlideIndex(slideId: string) {
    return (this.structure?.get(['sldIdLst']) || []).indexOf(slideId);
  }

  stop(): void {}

  destroy(): void {
    //
  }
}
