import { Toggle } from 'dodoc-design-system';
import { ChangeEventHandler, useRef } from 'react';
import styles from './InsertImageButton.module.scss';

/* return (
  <Button
    variant="link"
    icon="EditorImage"
    upload
    accept=".jpeg,.jpg,.bmp,.tiff,.gif,.png,.eps"
    onChange={handleImageUpload}
    disabled={isReadOnlyMode}
  />
); */
type Props = {
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

const InsertImageButton = ({ disabled, onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <span className={styles.container}>
      <input
        ref={inputRef}
        className={styles.upload}
        disabled={disabled}
        type="file"
        onChange={onChange}
        accept=".jpeg,.jpg,.bmp,.tiff,.gif,.png,.eps"
      />
      <Toggle
        size="medium"
        variant="ghost"
        icon="EditorImage"
        disabled={disabled}
        onClick={(e) => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
        testId="insert-image-toggle"
      />
    </span>
  );
};

export default InsertImageButton;
