import { NodeUtils } from 'Editor/services/DataManager/models';
import { notify } from '_common/components/ToastSystem';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { InsertInlineCommand } from '../GenericCommands';

export class InsertCitationCommand extends InsertInlineCommand {
  citationId: string;

  constructor(context: Editor.Edition.Context, citationId: string) {
    super(context);

    this.citationId = citationId;
  }

  protected async handleExec(): Promise<void> {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.commandFactory ||
      !this.actionContext
    ) {
      throw new Error('Invalid context');
    }

    const closest = NodeUtils.closestSiblingAncestorOfType(
      this.actionContext.baseData,
      this.actionContext.range.start.p,
      ['citations-group'],
    );

    if (
      closest &&
      NodeUtils.checkPropertyValueExists(closest.data, 'element_reference', this.citationId)
    ) {
      notify({
        type: 'warning',
        title: 'global.warning',
        message: 'editor.warnings.insert',
      });
    } else {
      try {
        const citation = NodeDataBuilder.buildData({
          type: 'citation',
          properties: {
            element_reference: this.citationId,
          },
        });

        if (citation) {
          await this.context.DataManager?.citations.addCitationToDocument(this.citationId);

          let elementToInsert: Editor.Data.Node.Data | undefined = citation;

          if (!closest) {
            // if no citation group found
            elementToInsert = NodeDataBuilder.buildData({
              type: 'citations-group',
              childNodes: [citation],
              properties: {},
            });
          }

          if (elementToInsert) {
            await super.handleExec(elementToInsert);
          }
        }
      } catch (e) {
        throw e;
      }
    }
  }
}
