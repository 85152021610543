import styles from './LoadingIndicatorFull.module.scss';

/**
 * Displays a fullscreen loading indicator.
 *
 * @author    José Nunes <jnunes@dodoc.com>
 * @copyright 2017 doDOC
 */
const LoadingIndicatorFull = () => (
  <div className={styles.root}>
    <div className={styles.spinner}>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
    </div>
  </div>
);

export default LoadingIndicatorFull;
