import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Input, SectionHeading } from 'dodoc-design-system';

import { useGetTemplatesListQuery, useInstallAllTemplatesMutation } from '../TemplatesApi';

import { FilterPopover } from '_common/components';

type TemplatesHeadingProps = {
  category: Template['category'];
  search: string;
  onSearchChange: (search: string) => void;
  testId: string;
};

const TemplatesHeading = ({ category, search, onSearchChange, testId }: TemplatesHeadingProps) => {
  const intl = useIntl();

  const [installAll] = useInstallAllTemplatesMutation();

  const { data } = useGetTemplatesListQuery();
  const { outdated, not_installed } = useMemo(
    () => ({
      outdated: data
        ? Object.values(data.extensions)
            .filter((template) => template.status === 'outdated')
            .map((template) => template.id)
        : [],
      not_installed: data
        ? Object.values(data.extensions)
            .filter((template) => template.status === 'not_installed')
            .map((template) => template.id)
        : [],
    }),
    [data],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onSearchChange(e.target.value);

  const handleInstallAll = () => {
    installAll({ idList: not_installed });
  };

  const handleUpdateAll = () => {
    installAll({ idList: outdated, updating: true });
  };

  return (
    <SectionHeading
      sticky
      title={
        category === 'general'
          ? intl.formatMessage({ id: 'TEMPLATES' })
          : category.slice(0, 1).toUpperCase() + category.slice(1)
      }
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'INSTALL_ALL' }),
        onClick: handleInstallAll,
        disabled: not_installed.length === 0,
        testId: 'install-all-button',
      }}
      buttonLeftProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'UPDATE_ALL' }),
        onClick: handleUpdateAll,
        disabled: outdated.length === 0,
        testId: 'update-all-button',
      }}
      search={
        <Input
          prefix="NavSearchGrey"
          size="medium"
          placeholder={intl.formatMessage({ id: 'TEMPLATE_SEARCH_PLACEHOLDER' })}
          value={search}
          onChange={handleSearchChange}
          testId="templates-search"
        />
      }
      searchFilters={
        <FilterPopover
          identity={`templates-${category}`}
          templateStatus={{
            options: [
              { value: 'not_installed', label: 'NOT_INSTALLED' },
              { value: 'installed', label: 'INSTALLED' },
              { value: 'outdated', label: 'OUTDATED' },
            ],
          }}
        />
      }
      testId={testId}
    />
  );
};

export default TemplatesHeading;
