import Background from './Background';
import { useShapeData, useShapeProperties } from './ShapeData';

const ShapeBackground = () => {
  const { position, size } = useShapeData();
  const { ln, fill, geom, xfrm } = useShapeProperties();

  return (
    <Background
      geometry={geom}
      xfrm={xfrm}
      outline={ln}
      fill={fill}
      position={position}
      size={size}
    />
  );
};

export default ShapeBackground;
