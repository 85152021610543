import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ELEMENTS } from 'Editor/services/consts';
import { ElementNodeBuilder } from 'Editor/services/Model/Nodes/ElementNodeBuilder';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      parseHyperlinkElement(representation) {
        const builder = new ElementNodeBuilder();

        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.HyperlinkElement.ELEMENT_TYPE);

        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        this.parseChildren(representation, builder);

        return builder.getNode();
      }
    },
);
