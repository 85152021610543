import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from 'Editor/modals/ExportIntegrationModal/Content/EmptyView/EmptyView.module.scss';

import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';

type EmptyViewProps = {
  data: ExportIntegrationModal.IntegrationUtils[keyof ExportIntegrationModal.IntegrationUtils]['data'];
  name: EmptyViewProps['data']['path'][number]['name'];
};

const EmptyView = ({ data, name }: EmptyViewProps) => {
  let title: ReactNode = null;
  let description: ReactNode = null;
  const newName = name || 'this';

  if (data.searchValue !== '') {
    title = <FormattedMessage id="NET_DOCUMENTS_NO_RESULTS_TITLE" />;
    description = <FormattedMessage id="NET_DOCUMENTS_NO_RESULTS_DESCRIPTION" />;
  } else {
    title = <FormattedMessage id="NET_DOCUMENTS_EMPTY_FOLDER_TITLE" />;
    description = (
      <FormattedMessage id="NET_DOCUMENTS_EMPTY_FOLDER_DESCRIPTION" values={{ name: newName }} />
    );
  }

  return (
    <div className={styles.noResults}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default EmptyView;
