import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyCardListState, Switch } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  usePresentationCollaborators,
  useActiveTasks,
  useFilteredTasks,
  useAllTasks,
} from 'Presentation/hooks';
import { toggleIsListMode } from 'Presentation/TasksSlice';

import { EmptyFilteredState, FilterDisplay } from '_common/components';
import { PanelHeading, TaskFilters } from '_common/suite/components';
import TaskCard from 'Presentation/Cards/TaskCard';

import styles from './TasksPanel.module.scss';
import { useTotalMatchedFilters } from '_common/components/Filters';

const Tasks = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const tasks = useActiveTasks();
  const allTasks = useAllTasks();
  const filteredTasks = useFilteredTasks();
  const totalTasksFilters = useTotalMatchedFilters({ identity: 'presentationTaskPanel' });
  const collaborators = usePresentationCollaborators();

  const creating = useSelector((state) => state.presentation.general.creating);
  const isListMode = useSelector((state) => state.presentation.tasks.isListMode);

  const options = useMemo(() => {
    return collaborators.map((id) => ({
      id: id,
      value: id,
      type: 'user',
      label: '',
    }));
  }, [collaborators]);

  const toggleListMode = () => {
    dispatch(toggleIsListMode());
  };

  const renderEmptyState = () => {
    if (creating?.type !== 'task') {
      return (
        <EmptyFilteredState
          identity="presentationTaskPanel"
          size="medium"
          hideIcon
          margin="6rem 0 0 0"
          message={{ id: 'NO_RESULTS_FOUND_FILTER_TASKS' }}
        />
      );
    }
  };

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="TASKS" />
        <TaskFilters
          tasksList={allTasks}
          options={{
            users: options,
          }}
        />
      </PanelHeading>
      <div className={styles.content}>
        {!filteredTasks.length && !totalTasksFilters && creating?.type !== 'task' ? (
          <div className={styles.emptyState}>
            <EmptyCardListState size="medium" testId="tasks-panel-no-tasks-empty-card-list-state">
              <FormattedMessage id="TASKS_EMPTY_TITLE" />
            </EmptyCardListState>
            <div className={styles.description}>
              <FormattedMessage id="TO_CREATE_A_TASK_CLICK_ON_THE_TASK_BUTTON_IN_THE_TOOLBAR" />
            </div>
          </div>
        ) : (
          <>
            <div className={styles.tasksControl}>
              <div className={styles.tasksCounter}>
                {totalTasksFilters > 0 ? (
                  <FormattedMessage
                    id="Y_OF_X_TASKS"
                    values={{ value: filteredTasks?.length, total: tasks.length }}
                  />
                ) : (
                  <FormattedMessage id="X_TASKS_IN_THE_DOCUMENT" values={{ total: tasks.length }} />
                )}
              </div>
              <Switch
                size="medium"
                labelPlacement="left"
                onChange={toggleListMode}
                active={isListMode}
                testId={`tasks-panel-list-mode-switch`}
              >
                {intl.formatMessage({ id: 'LIST_MODE' })}
              </Switch>
            </div>
            <FilterDisplay
              identity="presentationTaskPanel"
              direction="column"
              margin="0 0 2rem 0rem"
            />
            <div className={styles.list}>
              {creating?.type === 'task' && (
                <TaskCard sidebar mode="create" testId="tasks-panel-new-task" />
              )}
              {filteredTasks.length
                ? filteredTasks?.map((task) => (
                    <TaskCard
                      key={`taskpanel-task-${task.id}`}
                      sidebar
                      task={task}
                      testId={`tasks-panel-card-${task.id}`}
                    />
                  ))
                : renderEmptyState()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Tasks;
