import { Pulse } from 'dodoc-design-system';

type OnboardingPulseWidgetViewProps = Onboarding.PulseProps & {
  testId?: string;
};

const OnboardingPulseWidgetView = ({
  position,
  direction,
  testId,
}: OnboardingPulseWidgetViewProps) => {
  return (
    <Pulse
      position={position ?? { top: '0px', left: '0px' }}
      direction={direction}
      testId={testId ?? 'pulse'}
    />
  );
};

export default OnboardingPulseWidgetView;
