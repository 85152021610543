import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, EmptyState, Modal } from 'dodoc-design-system';

import { useDispatch, useObject, useSelector } from '_common/hooks';

import { closeModal, openAndUpdateModal } from '_common/modals/ModalsSlice';

import MetadataFields from '_common/components/MetadataFields/MetadataFields';
import useMetadataList from '_common/hooks/useMetadataList';

const EditMetadata = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isOpen = useSelector((state) => state.modals.open.EditMetadata);
  const id = useSelector((state) => state.modals.EditMetadata.id);
  const object = useSelector((state) => state.app.data[id]);
  const { data: element } = useObject({ object_id: id, object_type: object.type });
  const exportDocument = useSelector((state) => state.modals.EditMetadata.export);

  const { manual } = useMetadataList();
  const currentPage = useSelector((state) => state.app.currentPage);
  const [errors, setErrors] = useState('');
  const close = () => {
    if (exportDocument) {
      dispatch(closeModal('EditMetadata'));
      dispatch(
        openAndUpdateModal({
          modal: 'PublishVeevaModal',
          data: {
            id,
            explorer: currentPage !== '/editor',
          },
        }),
      );
    } else {
      dispatch(closeModal('EditMetadata'));
    }
  };

  const modalBodyInformation = useMemo(() => {
    if (element?.type) {
      if (element?.type === 'document' && element?.metadata) {
        return manual.map(
          (field) =>
            element?.type === 'document' && (
              <MetadataFields
                key={field.id}
                validations={errors}
                element={element}
                setValidations={setErrors}
                metadata={field}
              />
            ),
        );
      } else {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <EmptyState
              size="medium"
              testId="no-metadata"
              title={intl.formatMessage({ id: 'NO_METADATA' })}
            >
              <FormattedMessage id="NO_METADATA_MESSAGE" />
            </EmptyState>
          </div>
        );
      }
    } else return null;
  }, [element]);

  return (
    <Modal open={!!isOpen} width="70rem" onClose={close} testId="edit-metadata">
      <Modal.Header onClose={close}>
        <FormattedMessage id="EDIT_ELEMENT_METADATA" values={{ element: element?.name }} />
      </Modal.Header>
      <Modal.Body>{modalBodyInformation}</Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="edit-metadata-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        {element?.type === 'document' && (
          <Button
            size="medium"
            variant="primary"
            onClick={close}
            disabled={errors?.length > 0}
            testId="edit-metadata-submit-button"
          >
            <FormattedMessage id="UPDATE_METADATA" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditMetadata;
