import { ReactNode, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { uniqBy } from 'lodash';
import { AsyncSelect, Select, Icon } from 'dodoc-design-system';

import { useDispatch, useSelector, usePublicProfiles } from '_common/hooks';
import { AuthService, GroupsService, Logger } from '_common/services';
import { getPublicGroup } from 'App/redux/publicSlice';
import { parseProfile } from 'Auth/redux/utils';
import { SuiteAvatar } from '_common/suite/components';
import UsernameLabel from '../Labels/UsernameLabel/UsernameLabel';
import UserAvatar from '../UserAvatar/UserAvatar';

import type {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';
import type { AsyncSelectProps } from 'dodoc-design-system/build/types/Components/Selects/AsyncSelect';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';

//TODO: these extra props shouldnt really be optional, check a way to do this. If this is done change this Required<SearchUserOption> bellow
export type SearchUserOption = {
  id?: string;
  email?: string;
  username?: string;
};

export type UserOption = SelectOption &
  SearchUserOption & {
    name: string;
    is_superuser?: boolean;
    type: 'user';
    imported?: boolean;
  };

export type SearchUserProps<
  Option extends SelectOption = SelectOption,
  IsMulti extends boolean = false,
> = Omit<AsyncSelectProps<Option, IsMulti>, 'options'> & {
  groups?: boolean;
  valuesToFilter?: string[] | null;
  filterOption?: SelectProps['filterOption'];
  noOptionsMessage?: string;
  escapeClearsValue?: boolean;
  both?: boolean;
  editor?: boolean;
  onInputChange?: () => void;
  inputValue?: string;
  options?: ({ id: UserId } | UserOption)[];
  loadOnRender?: string;
  onLoadOptions?: (options: Promise<UserOption[]>) => void;
};

export type GroupOption = SelectOption & {
  type: 'group';
};

const SearchUser = <Option extends SelectOption = SelectOption, IsMulti extends boolean = false>({
  valuesToFilter = null,
  filterOption,
  placeholder,
  options,
  value = null,
  onChange,
  clearable = true,
  noOptionsMessage,
  disabled,
  escapeClearsValue = false,
  defaultOptions = [],
  menuPosition = 'absolute',
  onInputChange,
  inputValue,
  size = 'medium',
  width,
  searchable,
  both,
  editor = false,
  hideSelectedOptions,
  error,
  creatable,
  loadOnRender,
  onLoadOptions,
  testId,
  onMenuClose,
  id,
  menuLabel,
  ...props
}: SearchUserProps<Option, IsMulti>) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const publicGroups = useSelector((state) => state.public.groups.profiles);
  const onboardingIsActive = useSelector((state) => state.onboarding.active.explorer);
  const onboardingHasStarted = useSelector((state) => state.onboarding.started.explorer);

  const { profiles } = usePublicProfiles(
    options?.map(
      (option) => (option as Required<UserOption>).value || (option as Required<UserOption>).id,
    ),
  );

  const parsedOptions = useMemo(() => {
    if (options && options?.length > 0) {
      return options
        ?.filter(
          (option) =>
            ('type' in option && option.type !== 'user') ||
            (option?.id && (profiles[option.id] || ('imported' in option && option.imported))),
        )
        .map((option) => {
          const searchUserOption = option as Required<UserOption | GroupOption>;
          let avatar = null;
          if (props.groups || searchUserOption.type === 'group') {
            avatar = (
              <span style={{ display: 'flex' }}>
                <Icon size={32} icon="Groups" />
              </span>
            );
          } else if (editor) {
            if (searchUserOption.imported) {
              avatar = (
                <SuiteAvatar userId="IMPORTED_USER" name={searchUserOption.id} online={false} />
              );
            } else {
              avatar = <SuiteAvatar userId={searchUserOption.id} online={false} />;
            }
          } else {
            avatar = <SuiteAvatar userId={searchUserOption.id} online={false} />;
          }

          if (searchUserOption.type === 'user') {
            return {
              ...searchUserOption,
              avatar,
              value: searchUserOption.value || searchUserOption.id,
              description:
                profiles[searchUserOption.id]?.email ||
                searchUserOption.email ||
                searchUserOption.description,
              label:
                profiles[searchUserOption.id]?.name ||
                searchUserOption.username ||
                searchUserOption.label ||
                searchUserOption.id,
              type: 'user',
            } as unknown as Option;
          } else {
            return {
              ...searchUserOption,
              avatar,
            } as unknown as Option;
          }
        });
    }
  }, [options, profiles]);

  const parsedValues = useMemo(() => {
    if (!value) {
      return value;
    }

    const parseValue = (searchUserOption: Required<UserOption> | Required<GroupOption>) => {
      let avatar = null;
      if (props.groups || searchUserOption.type === 'group') {
        avatar = (
          <span style={{ display: 'flex' }}>
            <Icon size={32} icon="Groups" />
          </span>
        );
      } else if (editor) {
        avatar = <SuiteAvatar userId={searchUserOption.value} online={false} />;
      } else {
        avatar = <UserAvatar userId={searchUserOption.value} />;
      }

      let label: ReactNode = null;

      if (searchUserOption.type === 'user') {
        const selectedOption = options?.find(
          (option) =>
            option.id === searchUserOption.value ||
            ('value' in option && option.value === searchUserOption.value),
        );
        if ((selectedOption as UserOption)?.imported) {
          label = searchUserOption.value;
        } else {
          label = <UsernameLabel userId={searchUserOption.value} />;
        }
      } else if (searchUserOption.type === 'group') {
        label = searchUserOption.label;
      } else {
        label = '';
      }

      return {
        ...searchUserOption,
        avatar,
        description:
          profiles[searchUserOption.value]?.email ||
          (searchUserOption as UserOption).email ||
          searchUserOption.description,
        label,
      } as unknown as Option;
    };

    if (value) {
      if (!Array.isArray(value)) {
        return parseValue(value as Required<UserOption>);
      } else if (value.length > 0) {
        return value.map(parseValue);
      } else {
        return value;
      }
    }
  }, [value, profiles]);

  useEffect(() => {
    if (loadOnRender) {
      loadOptions(loadOnRender);
    }
  }, []);

  const getUserOption = (user: UserPublicProfile): UserOption => {
    const parsedProfile = parseProfile(user);
    return {
      name: parsedProfile.name,
      username: user.username,
      is_superuser: user.is_superuser,
      value: user.id,
      label: <UsernameLabel userId={user.id} />,
      description: user.email,
      avatar: <UserAvatar userId={user.id} />,
      type: 'user',
    };
  };

  const getGroupOption = (group: Group): GroupOption => {
    return {
      avatar: (
        <span style={{ display: 'flex' }}>
          <Icon size={32} icon="Groups" />
        </span>
      ),
      value: group.id,
      label: group.name,
      description: intl.formatMessage({ id: 'NUMBER_OF_USERS' }, { number: group.users.length }),
      type: 'group',
    };
  };

  const getUserAutocomplete = (input: string) => {
    return new Promise<UserOption[]>((resolve, reject) => {
      if (onboardingIsActive || onboardingHasStarted) {
        const davidBeanProfile = parseProfile(GHOST_USERS.davidBean);
        const { email, first_name, last_name, username, name } = davidBeanProfile;
        const query = input.toLowerCase();

        if (
          email.toLowerCase().includes(query) ||
          name.toLowerCase().includes(query) ||
          first_name.toLowerCase().includes(query) ||
          last_name.toLowerCase().includes(query) ||
          username.toLowerCase().includes(query)
        ) {
          resolve([getUserOption(GHOST_USERS.davidBean)]);
        } else {
          resolve([]);
        }
      } else {
        new AuthService()
          .getUserAutocomplete(input)
          .then((response) => {
            const options = response.data.items.map((user) => {
              return getUserOption(user);
            });
            resolve(options);
          })
          .catch((error) => {
            Logger.captureMessage('Error in group autocomplete', {
              extra: error,
            });
            reject(error);
          });
      }
    });
  };

  const getGroupAutocomplete = (input: string) => {
    return new Promise<GroupOption[]>((resolve, reject) => {
      new GroupsService()
        .getGroupAutocomplete(input)
        .then((response) => {
          const options = response.data.items.map((group) => {
            if (!publicGroups[group.id]) {
              dispatch(getPublicGroup(group.id));
            }
            return getGroupOption(group);
          });
          resolve(options);
        })
        .catch((error) => {
          Logger.captureException('Error in user autocomplete', {
            extra: error,
          });
          reject(error);
        });
    });
  };

  const getBothAutocomplete = (input: string) => {
    return new Promise<UserOption[]>(async (resolve, reject) => {
      const values =
        onboardingIsActive || onboardingHasStarted
          ? await Promise.all([getUserAutocomplete(input), []])
          : await Promise.all([getUserAutocomplete(input), getGroupAutocomplete(input)]);
      if (value && Array.isArray(value)) {
        resolve(uniqBy([...value, ...values[0], ...values[1]], (v) => v.value));
      } else {
        //@ts-expect-error getBothAutocomplete should return Promise<(UserOption | GroupOption)[]>
        resolve([...values[0], ...values[1]]);
      }
    });
  };

  const loadOptions = (input?: string) => {
    if (!input) {
      return;
    }

    let loadPromise: Promise<UserOption[]>;

    if (both) {
      loadPromise = getBothAutocomplete(input);
    } else if (props.groups) {
      //@ts-expect-error loadPromise should be typed to allow both User and Group
      loadPromise = getGroupAutocomplete(input);
    } else {
      loadPromise = getUserAutocomplete(input);
    }

    onLoadOptions?.(loadPromise);

    return loadPromise;
  };

  const handleOnChange: typeof onChange = (value, actionMeta) => {
    if (onChange) {
      if (!value) {
        onChange(value, actionMeta);
      } else if (Array.isArray(value)) {
        onChange(
          //@ts-expect-error Select onChange prop issues
          value.map((itValue) => ({
            ...itValue,

            label: profiles[itValue.value]?.name ?? itValue.label,
          })),
          actionMeta,
        );
      } else {
        onChange({ ...value, label: profiles[value.value]?.name ?? value.label }, actionMeta);
      }
    }
  };

  const handleFilterOption: SelectProps<Option>['filterOption'] = (option, inputValue) => {
    if (filterOption) {
      return filterOption(option, inputValue);
    }
    const optionMatchesInputValue = (option.data as unknown as UserOption).name
      ? (option.data as unknown as UserOption).name
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
        (typeof option?.data?.description === 'string' &&
          option.data.description.toLowerCase().includes(inputValue.toLowerCase()))
      : (typeof option.label === 'string' &&
          option.label.toLowerCase().includes(inputValue.toLowerCase())) ||
        (typeof option?.data?.description === 'string' &&
          option.data.description.toLowerCase().includes(inputValue.toLowerCase()));
    if (valuesToFilter && valuesToFilter.length) {
      return !valuesToFilter.includes(option.value) && optionMatchesInputValue;
    }

    return optionMatchesInputValue;
  };

  if (options) {
    return (
      <Select
        id={id}
        size={size}
        searchable={searchable}
        clearable={clearable}
        inputValue={inputValue}
        onInputChange={onInputChange}
        placeholder={placeholder}
        creatable={creatable}
        options={parsedOptions}
        value={
          Array.isArray(parsedValues)
            ? parsedValues
            : parsedOptions?.find(
                (option) => option.value === (parsedValues as Option | undefined)?.value,
              ) ?? parsedValues
        }
        onChange={handleOnChange}
        filterOption={handleFilterOption}
        noOptionsMessage={noOptionsMessage}
        width={width}
        isMulti={props.isMulti}
        listOptionsGroupLabel={intl.formatMessage({
          id: 'UNSELECTED_USERS',
        })}
        multiOverflowLabel={intl.formatMessage(
          {
            id: 'USERS_SELECTED',
          },
          { total: Array.isArray(parsedValues) ? parsedValues.length : 0 },
        )}
        selectedOptionsGroupLabel={intl.formatMessage({
          id: 'SELECTED_USERS',
        })}
        closeMenuOnSelect={!props.isMulti}
        error={error}
        formatCreateLabel={() => intl.formatMessage({ id: 'NO_RESULTS_FOUND' })}
        testId={`${testId}-search-user-select`}
        disabled={disabled}
        onMenuClose={onMenuClose}
        fullWidth={props.fullWidth}
      />
    );
  }

  return (
    <AsyncSelect
      {...props}
      searchable
      creatable={creatable}
      //@ts-expect-error AsyncSelect loadOptions prop issues
      loadOptions={loadOptions}
      closeMenuOnSelect={!props.isMulti}
      blurInputOnSelect={!props.isMulti}
      hideSelectedOptions={hideSelectedOptions === undefined ? !props.isMulti : hideSelectedOptions}
      escapeClearsValue={escapeClearsValue}
      filterOption={handleFilterOption}
      defaultOptions={defaultOptions}
      onChange={handleOnChange}
      value={parsedValues}
      placeholder={placeholder || intl.formatMessage({ id: 'global.typeToSearch' })}
      noOptionsMessage={({ inputValue }: { inputValue: string }) => {
        //@ts-expect-error
        if (inputValue === '' && parsedValues && parsedValues.length !== 0) {
          return null;
        } else return intl.formatMessage({ id: 'global.noResults' });
      }}
      menuPosition={menuPosition}
      size={size}
      disabled={disabled}
      width={width}
      isMulti={props.isMulti}
      listOptionsGroupLabel={intl.formatMessage({
        id: 'UNSELECTED_USERS',
      })}
      multiOverflowLabel={intl.formatMessage(
        {
          id: 'USERS_SELECTED',
        },
        { total: Array.isArray(parsedValues) ? parsedValues?.length : 0 },
      )}
      selectedOptionsGroupLabel={intl.formatMessage({
        id: 'SELECTED_USERS',
      })}
      error={error}
      formatCreateLabel={() => intl.formatMessage({ id: 'NO_RESULTS_FOUND' })}
      isValidNewOption={(newValue, _, options) => newValue.length > 0 && options.length === 0}
      onMenuClose={onMenuClose}
      testId={`${testId}-search-user`}
      menuLabel={menuLabel}
    />
  );
};

export default SearchUser;
