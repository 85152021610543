import { SectionHeading } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

const RolesHeading = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleCreateRole = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'CreateNewObjectModal',
        data: {
          title: intl.formatMessage({
            id: 'settings.tenant.createRole',
          }),
          nameLabel: intl.formatMessage({ id: 'settings.tenant.roleName' }),
          namePlaceholder: intl.formatMessage({
            id: 'settings.tenant.insertRoleName',
          }),
          descriptionLabel: intl.formatMessage({ id: 'settings.tenant.roleDescription' }),
          descriptionPlaceholder: intl.formatMessage(
            { id: 'NEW_OBJECT_DESCRIPTION_PLACEHOLDER' },
            { value: 'role' },
          ),
          action: 'createRole',
        },
      }),
    );
  };

  return (
    <SectionHeading
      sticky
      title={intl.formatMessage({ id: 'global.roles' })}
      buttonRightProps={{
        size: 'medium',
        children: intl.formatMessage({ id: 'settings.tenant.newRole' }),
        onClick: handleCreateRole,
        variant: 'primary',
        testId: 'rolesHeading-new',
      }}
      testId="roles-heading"
    />
  );
};

export default RolesHeading;
