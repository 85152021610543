import { useDispatch, useSelector } from '_common/hooks';

import { useSuiteObject } from '_common/suite';
import { usePDFContext } from 'PDF/PDFContext';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setVersionHistory } from 'PDF/redux/PDFGeneralSlice';

import { VersionHistory } from '_common/suite/components';
import { VersionHistoryProps } from '_common/suite/components/Panel/VersionHistory/VersionHistory';

const PDFVersionHistory = () => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();
  const object = useSuiteObject();

  const loadedVersion = useSelector((state) => state.pdf.general.loadedVersion);

  const handleVersionHistoryChange: VersionHistoryProps['onVersionHistoryChange'] = (newValue) => {
    dispatch(setVersionHistory(newValue));
  };

  const handleLoadVersion: VersionHistoryProps['onLoadVersion'] = (version) => {
    pdfManager.loadVersion(version);
  };

  const handleDownloadOriginal: VersionHistoryProps['onDownloadOriginal'] = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DOWNLOAD_ORIGINAL_FILE',
          message: 'DOWNLOAD_ORIGINAL_DOPDF_FILE_CONFIRMATION',
          confirmButtonTextId: 'DOWNLOAD_ORIGINAL_FILE',
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'downloadOriginalFile',
          actionValue: {
            objectId: object.id,
            filename: object.name,
            objectType: object.type,
          },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const handleRestoreClick: VersionHistoryProps['onRestoreClick'] = (versionIndex) => {
    dispatch(
      openAndUpdateModal({
        modal: 'PDFConfirmationModal',
        data: {
          title: 'RESTORE_DOCUMENT_VERSION',
          message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_PDF_DOCUMENT_VERSION',
          messageValues: { versionNumber: versionIndex + 1 },
          confirmButtonTextId: 'RESTORE_VERSION',
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'restorePDFDocumentVersion',
          actionValue: { loadedVersion: versionIndex },
          headerType: 'information',
          cancelButtonShow: true,
          width: '60rem',
        },
      }),
    );
  };

  return (
    <VersionHistory
      onVersionHistoryChange={handleVersionHistoryChange}
      onLoadVersion={handleLoadVersion}
      onDownloadOriginal={handleDownloadOriginal}
      loadedVersion={loadedVersion}
      onRestoreClick={handleRestoreClick}
    />
  );
};

export default PDFVersionHistory;
