import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch, DatePicker } from 'dodoc-design-system';
import dayjs from 'dayjs';
import cx from 'classnames';
import { ReactDatePickerProps } from 'react-datepicker';

import { useDispatch, useSelector } from '_common/hooks';
import FormattedDate from '_common/components/FormattedDate/FormattedDate';

import { editOverdue, deleteOverdue, saveDueDate, deleteWarningDate } from 'App/redux/appSlice';

import styles from './DueDateSystem.module.scss';
import { useGetTenantSettingsQuery } from '_common/services/api/authority';

const MAX_REMINDERS = 5;

export type DueDateSystemProps = {
  objectId: ObjectId;
  showLabels?: boolean;
  canEdit?: boolean;
  dueDateValue?: ISODate;
  remindersValue?: ISODate[];
  size?: 'medium' | 'large';
  onChangeDueDate?: (dueDate: ISODate) => void;
  onDeleteDueDate?: () => void;
  onChangeReminder?: (reminder: ISODate) => void;
  onDeleteReminder?: (reminder: ISODate | null, deleteAll?: boolean) => void;
};

const DueDateSystem = ({
  objectId,
  showLabels,
  canEdit,
  dueDateValue,
  remindersValue,
  size = 'medium',
  onChangeDueDate,
  onDeleteDueDate,
  onChangeReminder,
  onDeleteReminder,
}: DueDateSystemProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data: tenantSettings } = useGetTenantSettingsQuery();

  const object = useSelector((state) => state.app.data[objectId]) as Objekt & {
    events: {
      warnings: ISODate[];
      due: ISODate;
    };
  };
  const currentPage = useSelector((state) => state.app.currentPage);

  //Due date
  const [dueDate, setDueDate] = useState(object.events.due);

  //Reminders = Warnings
  const [reminderActive, setReminderActive] = useState(object.events.warnings.length !== 0);
  const [reminderSortedArray, setReminderSortedArray] = useState<
    (typeof object)['events']['warnings']
  >([]);
  const minDay = dayjs().add(1, 'day').toDate();

  useEffect(() => {
    setReminderActive(object.events.warnings.length !== 0);
  }, [objectId, object]);

  useEffect(() => {
    if (dueDateValue || dueDateValue === '') {
      setDueDate(dueDateValue);
    } else {
      setDueDate(object.events.due);
    }
  }, [objectId, dueDateValue]);

  useEffect(() => {
    let reminders = [];
    if (remindersValue) {
      reminders = remindersValue;
    } else {
      reminders = [...object.events.warnings];
    }

    if ((!object.events?.due || !dueDateValue) && reminders?.length === 0) {
      setReminderActive(false);
    }

    setReminderSortedArray(reminders.sort((a, b) => (dayjs(a).isAfter(b) ? 1 : -1)));
  }, [object.events.warnings, remindersValue, dueDateValue]);

  useEffect(() => {
    if (!reminderActive) {
      if (onDeleteReminder) {
        onDeleteReminder(null, true);
      } else {
        reminderSortedArray.forEach((reminder) => {
          dispatch(deleteWarningDate({ objectId, warning: reminder }));
        });
      }
    }
  }, [reminderActive]);

  const handleEditOverdue: ReactDatePickerProps['onChange'] = (dueDate) => {
    const isoDueDate = dueDate ? dayjs(dueDate).toISOString() : null;

    setDueDate(isoDueDate ?? '');

    if (isoDueDate) {
      onChangeDueDate
        ? onChangeDueDate(isoDueDate)
        : dispatch(editOverdue({ objectId, overdue: isoDueDate }));
    } else {
      onDeleteDueDate
        ? onDeleteDueDate()
        : dispatch(deleteOverdue({ objectId, overdue: undefined }));
    }
  };

  const handleReminderChange = (reminder: Date | null, oldReminder?: ISODate) => {
    const isoReminderDate = reminder ? dayjs(reminder).toISOString() : null;

    if (isoReminderDate) {
      onChangeReminder
        ? onChangeReminder(isoReminderDate)
        : dispatch(saveDueDate({ objectId, warning: isoReminderDate }));
    } else if (oldReminder) {
      onDeleteReminder
        ? onDeleteReminder(oldReminder)
        : dispatch(deleteWarningDate({ objectId, warning: oldReminder }));
    }
  };

  const handleToggleSwitch = () => {
    setReminderActive(!reminderActive);
  };

  const renderUneditableContent = () => {
    return (
      <div className={styles.uneditableRoot}>
        {!currentPage.includes('/settings') && (
          <div className={styles.labelsWrapper}>
            <FormattedMessage id="settings.general.dueDate" />
            {reminderSortedArray.length > 0 && <FormattedMessage id="REMINDER" />}
          </div>
        )}
        <div className={styles.valuesWrapper}>
          {object.events.due ? (
            <FormattedDate date={object.events.due} />
          ) : (
            <div className={styles.unset}>
              <FormattedMessage id="NO_DUE_DATE_SET" />
            </div>
          )}
          {reminderSortedArray.map((reminder, index) => (
            <FormattedDate key={`reminder-${index}`} date={reminder} />
          ))}
        </div>
      </div>
    );
  };

  const renderEditableContent = () => {
    return (
      <div className={styles.editableRoot}>
        <div className={styles.labeledContent}>
          {showLabels && (
            <div className={styles.labelsWrapper}>
              <div className={styles.label}>
                <FormattedMessage id="settings.general.dueDate" />
              </div>
              <FormattedMessage id="REMINDER" />
            </div>
          )}
          <div className={cx(styles.valuesWrapper, { [styles.large]: size === 'large' })}>
            <div>
              <DatePicker
                size={size}
                placeholder="Select Option"
                selectedDate={
                  dueDate && dayjs(dueDate).isValid() ? dayjs(dueDate).toDate() : undefined
                }
                onChange={handleEditOverdue}
                testId="due-date-datepicker"
                minDate={!tenantSettings?.['overdue.allow_past_dates'] ? minDay : undefined}
              />
            </div>
            <Switch
              disabled={!dueDate}
              active={!!dueDate && reminderActive}
              onChange={handleToggleSwitch}
              size={'medium'}
              labelPlacement={'left'}
              testId="due-date-reminder-toggle"
            />
          </div>
        </div>
        {reminderActive && (
          <div className={styles.reminderList}>
            {reminderSortedArray.map((reminder, index) => (
              <DatePicker
                key={`reminder-${reminder}`}
                size={size}
                placeholder={intl.formatMessage({ id: 'SET_A_REMINDER' })}
                selectedDate={dayjs(reminder).toDate()}
                onChange={(newReminder) => {
                  handleReminderChange(newReminder, reminder);
                }}
                minDate={!tenantSettings?.['overdue.allow_past_dates'] ? minDay : undefined}
                filterDate={[
                  {
                    filterType: 'before',
                    filterDate: dayjs(dueDate).toDate(),
                  },
                  {
                    filterType: 'same',
                    filterDate: dayjs(reminderSortedArray[0]).toDate(),
                  },
                ]}
                testId={`${index}-reminder-datepicker`}
              />
            ))}
            {reminderSortedArray.length < MAX_REMINDERS && reminderActive && (
              <DatePicker
                size={size}
                placeholder={intl.formatMessage({ id: 'SET_A_REMINDER' })}
                onChange={(date: Date) => {
                  handleReminderChange(date);
                }}
                minDate={!tenantSettings?.['overdue.allow_past_dates'] ? minDay : undefined}
                filterDate={[
                  {
                    filterType: 'before',
                    filterDate: dayjs(dueDate).toDate(),
                  },
                ]}
                testId={`${reminderSortedArray.length}-reminder-datepicker`}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return canEdit ? renderEditableContent() : renderUneditableContent();
};

export default DueDateSystem;
