import { faker } from '@faker-js/faker';

export function createMockedTimeObject() {
  const access = faker.date.recent(5);
  const modification = faker.date.recent(5, access);
  const creation = faker.date.recent(30, modification);

  return {
    access: access.toISOString(),
    modification: modification.toISOString(),
    creation: creation.toISOString(),
  };
}

export function createMockedObjectId() {
  return faker.random.alphaNumeric(24);
}

export function createMockedUserId() {
  return `${faker.random.alphaNumeric(24)}`;
}

export function createMockedTenantTags() {
  return faker.helpers.uniqueArray(faker.word.adverb, 10);
}
