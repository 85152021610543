import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import { ProgressBar, Modal, Icon } from 'dodoc-design-system';
import styles from './TemplateActionModal.module.scss';

const TRANSLATIONS: { [key: string]: { header: string; body: string } } = {
  installing: {
    header: 'TEMPLATE_INSTALLING_HEADER',
    body: 'TEMPLATE_INSTALLING_BODY',
  },
  uninstalling: {
    header: 'TEMPLATE_UNINSTALLING_HEADER',
    body: 'TEMPLATE_UNINSTALLING_BODY',
  },
  updating: {
    header: 'TEMPLATE_UPDATING_HEADER',
    body: 'TEMPLATE_UPDATING_BODY',
  },
};

const MODAL = 'TemplateActionModal';

const TemplateActionModal = () => {
  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const total = useSelector((state) => state.modals[MODAL].total);
  const current = useSelector((state) => state.modals[MODAL].current);
  const name = useSelector((state) => state.modals[MODAL].name);
  const action = useSelector((state) => state.modals[MODAL].action);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} onClose={close} testId={`${action}-templates`}>
      <Modal.Header onClose={close}>
        <FormattedMessage id={TRANSLATIONS[action].header} values={{ name, total, current }} />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <Icon icon="Templates" size={96} />
          <div className={styles.message}>
            <FormattedMessage id={TRANSLATIONS[action].body} values={{ total }} />
          </div>
          <div className={styles.bar}>
            <ProgressBar testId={`${action}-templates-progressBar`} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TemplateActionModal;
