import { ReactNode } from 'react';

import styles from './Header.module.scss';

type HeaderProps = {
  children: ReactNode;
};

const Header = ({ children }: HeaderProps) => {
  return <div className={styles.header}>{children}</div>;
};

export default Header;
