import { Padding, Size, Style, TableHeader, TextAlignment } from '../Section';
import { EMPTY_HEIGHT } from '../utils';

import styles from './TabContent.module.scss';

const Row = ({ unsavedData, updateData }: TabContentProps) => {
  const handleUpdateData: SectionProps['updateData'] = (updateDataProps) => {
    updateData({ element: 'ROW', ...updateDataProps });
  };

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <Size height={unsavedData?.height ?? EMPTY_HEIGHT} updateData={handleUpdateData} />
        <Padding padding={unsavedData?.padding} updateData={handleUpdateData} />
        <TableHeader
          headerRow={unsavedData?.headerRow}
          fromStart={unsavedData?.fromStart}
          updateData={handleUpdateData}
        />
      </div>
      <div className={styles.column}>
        <TextAlignment
          textAlignment={unsavedData?.textAlignment}
          verticalAlignment={unsavedData?.verticalAlignment}
          updateData={handleUpdateData}
        />
        <Style
          background={unsavedData?.background}
          border={unsavedData?.border}
          updateData={handleUpdateData}
        />
      </div>
    </div>
  );
};

export default Row;
