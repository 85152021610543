import { JsonRange } from '../../../JsonRange';
import { BaseModifier } from '../BaseModifier';
import { NodeUtils } from 'Editor/services/DataManager';

export class MoveTextModifier extends BaseModifier {
  protected incrementOffset: number;

  constructor(
    Data: Editor.Data.API,
    granularity: Extract<Editor.Selection.ModifierGranularity, 'character' | 'word'>,
    direction: Editor.Selection.ModifierDirection,
    incrementOffset?: number,
  ) {
    super(Data, 'move', granularity, direction);

    this.incrementOffset = incrementOffset || 1;
  }

  private moveForward(range: Editor.Selection.JsonRange) {
    this.collapseRange(range);

    const baseModel = this.Data.nodes.getNodeModelById(range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    if (NodeUtils.isPathAtContentEnd(baseData, range.start.p)) {
      const nextModel = this.Data.nodes.getNextModelById(range.start.b);
      if (nextModel) {
        const path = nextModel.getPathToFirstContent();
        range.updateRangePositions({
          b: nextModel.id,
          p: path,
        });
      }
    } else {
      if (baseModel?.navigationData) {
        const iterator = this.getPositionIterator(baseModel.navigationData, range.start.p);

        if (iterator) {
          let nextPath: Editor.Selection.Path | undefined;
          let offset = this.incrementOffset;
          while (offset > 0) {
            nextPath = iterator.next();
            offset -= 1;
          }

          if (nextPath) {
            range.start.p = nextPath;
            range.end.p = nextPath;
          }
        }
      }
    }
  }

  private moveBackward(range: Editor.Selection.JsonRange) {
    this.collapseRange(range);

    const baseModel = this.Data.nodes.getNodeModelById(range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    if (NodeUtils.isPathAtContentStart(baseData, range.start.p)) {
      const previousModel = this.Data.nodes.getPreviousModelById(range.start.b);
      if (previousModel) {
        const path = previousModel.getPathToLastContent();
        range.updateRangePositions({
          b: previousModel.id,
          p: path,
        });
      }
    } else {
      if (baseModel?.navigationData) {
        const iterator = this.getPositionIterator(baseModel.navigationData, range.start.p);

        if (iterator) {
          let previousPath: Editor.Selection.Path | undefined;
          let offset = this.incrementOffset;
          while (offset > 0) {
            previousPath = iterator.previous();
            offset -= 1;
          }

          if (previousPath) {
            range.start.p = previousPath;
            range.end.p = previousPath;
          }
        }
      }
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    const jsonRange = JsonRange.buildFromDOMRange(range);
    this.visitJsonRange(jsonRange);
    range.updateFromJsonRange(jsonRange);
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    modifiersData.direction = this.direction;

    switch (this.direction) {
      case 'forward':
        this.moveForward(range);
        break;
      case 'backward':
        this.moveBackward(range);
        break;
      default:
        break;
    }

    const editorRange = range.serializeToDOMRange();
    const clientRects = editorRange.getClientRects();
    if (clientRects.length > 0) {
      modifiersData.px = clientRects[0].left;
    }

    this.Data.selection?.updateModifiers(modifiersData);
  }
}
