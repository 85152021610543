class SessionStorage {
  private static get(key: string) {
    return window.sessionStorage.getItem(key);
  }
  private static set(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }

  static getToken() {
    return SessionStorage.get('doDOC-Token');
  }

  static setToken(token: string) {
    SessionStorage.set('doDOC-Token', token);
  }
}

export default SessionStorage;
