import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useSelector } from '_common/hooks';
import { useGetInstalledTemplatesListQuery } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';

import { PageLayoutField, RadioButtonsField } from '_common/components';
import InputSelectField from '../InputSelectField/InputSelectField';

import styles from './PageMargins.module.scss';

import type { PageLayoutOption } from '_common/components/PageLayoutField/PageLayoutSelect/PageLayoutSelect';
import type { RadioButtonValue, MarginValues } from '../PageSetupModal';

type PageMarginsProps = {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  applyTo: {
    handleChange: React.Dispatch<React.SetStateAction<RadioButtonValue>>;
    applyToOptions: RadioButtonValue[];
    applyToValue: RadioButtonValue;
  };
  setMarginValues: React.Dispatch<React.SetStateAction<MarginValues>>;
  marginValues: MarginValues;
  isDisabled: boolean;
};

const PageMargins = ({
  setDisabled,
  applyTo,
  setMarginValues,
  marginValues,
  isDisabled,
}: PageMarginsProps) => {
  const intl = useIntl();
  const [marginSizeValue, setMarginSizeValue] = useState<PageLayoutOption>();
  const doc = useSelector(getDocumentObject);
  const sectionTemplate = useSelector(
    (state) => state.modals['PageSetupModal'].properties.template,
  );

  const { objectTemplate: template } = useGetInstalledTemplatesListQuery(
    { errorsExpected: [403, 404] },
    {
      selectFromResult: (result) => ({
        ...result,
        objectTemplate: result.data?.find((docTemplate) => docTemplate.id === doc?.template),
      }),
    },
  );

  const marginTopValues = useMemo(
    () => ({
      value: marginValues.t.value,
      unit: marginValues.t.unit,
    }),
    [marginValues.t],
  );
  const marginBottomValues = useMemo(
    () => ({
      value: marginValues.b.value,
      unit: marginValues.b.unit,
    }),
    [marginValues.b],
  );
  const marginLeftValues = useMemo(
    () => ({
      value: marginValues.l.value,
      unit: marginValues.l.unit,
    }),
    [marginValues.l],
  );
  const marginRightValues = useMemo(
    () => ({
      value: marginValues.r.value,
      unit: marginValues.r.unit,
    }),
    [marginValues.r],
  );

  const getExtraTemplates = () => {
    if (template) {
      return {
        [template.id]: {
          value: template.id,
          label: `${intl.formatMessage({ id: 'CUSTOM' })} - ${template.name}`,
          top: {
            value: sectionTemplate?.mar.t,
            unit: 'pt',
          },
          right: {
            value: sectionTemplate?.mar.r,
            unit: 'pt',
          },
          bottom: {
            value: sectionTemplate?.mar.b,
            unit: 'pt',
          },
          left: {
            value: sectionTemplate?.mar.l,
            unit: 'pt',
          },
        },
      };
    }

    return undefined;
  };

  return (
    <div>
      <PageLayoutField
        fullWidth
        testId={{
          inputField: 'page-margin',
          select: 'page-margin',
        }}
        type="margin"
        onChange={(option) => {
          setMarginSizeValue(option);
          setMarginValues({
            t: {
              value: `${option.top?.value}` || marginTopValues.value,
              //@ts-expect-error - unit does not yet have Common.Utils.MeasureUnit typing
              unit: option.top?.unit || marginTopValues.unit,
            },
            l: {
              value: `${option.right?.value}` || marginLeftValues.value,
              //@ts-expect-error - unit does not yet have Common.Utils.MeasureUnit typing
              unit: option.right?.unit || marginLeftValues.unit,
            },
            b: {
              value: `${option.bottom?.value}` || marginBottomValues.value,
              //@ts-expect-error - unit does not yet have Common.Utils.MeasureUnit typing
              unit: option.bottom?.unit || marginBottomValues.unit,
            },
            r: {
              value: `${option.left?.value}` || marginRightValues.value,
              //@ts-expect-error - unit does not yet have Common.Utils.MeasureUnit typing
              unit: option.left?.unit || marginRightValues.unit,
            },
          });
        }}
        value={{
          value: marginSizeValue?.value || 'nl',
          label: marginSizeValue?.label,
          top: { value: +marginTopValues.value, unit: marginTopValues.unit },
          right: { value: +marginRightValues.value, unit: marginRightValues.unit },
          bottom: { value: +marginBottomValues.value, unit: marginBottomValues.unit },
          left: { value: +marginLeftValues.value, unit: marginLeftValues.unit },
        }}
        newTemplates={getExtraTemplates()}
        margin="3rem 0 0 0"
        disabled={isDisabled}
      />
      <div className={styles.section}>
        <InputSelectField
          values={marginTopValues}
          setValues={(t) => setMarginValues({ ...marginValues, t })}
          label={intl.formatMessage({ id: 'TOP' })}
          setDisabled={setDisabled}
          isDisabled={isDisabled}
        />
        <InputSelectField
          values={marginBottomValues}
          setValues={(b) => setMarginValues({ ...marginValues, b })}
          label={intl.formatMessage({ id: 'BOTTOM' })}
          setDisabled={setDisabled}
          isDisabled={isDisabled}
        />
      </div>
      <div className={styles.section}>
        <InputSelectField
          values={marginLeftValues}
          setValues={(l) => setMarginValues({ ...marginValues, l })}
          label={intl.formatMessage({ id: 'LEFT' })}
          setDisabled={setDisabled}
          isDisabled={isDisabled}
        />
        <InputSelectField
          values={marginRightValues}
          setValues={(r) => setMarginValues({ ...marginValues, r })}
          label={intl.formatMessage({ id: 'RIGHT' })}
          setDisabled={setDisabled}
          isDisabled={isDisabled}
        />
      </div>
      <RadioButtonsField
        options={applyTo.applyToOptions}
        value={applyTo.applyToValue}
        setValue={applyTo.handleChange}
        label="APLLY_TO"
        testId="apply-to"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default PageMargins;
