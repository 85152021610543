import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Toggle, Tooltip, usePopper } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type PriorityProps = {
  priority: Card.Priority;
  onChange: (priority: Card.Priority) => void;
  disabled?: boolean;
  testId: string;
};

const PRIORITY: {
  [key in Card.Priority]: { icon: IconTypes['24']; labelId: string };
} = {
  High: {
    icon: 'PDFHigh',
    labelId: 'editor.sidebar.review.filter.priority.high',
  },
  Medium: {
    icon: 'PDFMedium',
    labelId: 'editor.sidebar.review.filter.priority.medium',
  },
  Low: {
    icon: 'PDFLow',
    labelId: 'editor.sidebar.review.filter.priority.low',
  },
};

const Priority = ({ priority, onChange, disabled, testId }: PriorityProps) => {
  const intl = useIntl();
  const { referenceProps, popperProps } = usePopper({
    placement: 'bottom-start',
    closeOnReferenceHidden: true,
  });

  const handleChangePriority = (newPriority: typeof priority) => {
    if (priority !== newPriority) {
      onChange(newPriority);
    }
  };

  return (
    <>
      <Tooltip
        placement="top"
        content={intl.formatMessage({ id: 'global.priority' })}
        testId={`${testId}-priority-tooltip`}
      >
        <Toggle
          variant="link"
          size="medium"
          icon={PRIORITY[priority]?.icon ?? 'PDFMedium'}
          isToggled={popperProps.isOpen}
          testId={`${testId}-priority-toggle`}
          {...referenceProps}
          onClickCapture={() => {
            referenceProps.onClickCapture();
          }}
        />
      </Tooltip>
      <Dropdown {...popperProps} testId={`${testId}-priority-dropdown`}>
        {(Object.keys(PRIORITY) as Card.Priority[]).map((priority) => (
          <Dropdown.Item
            key={priority}
            onClick={() => handleChangePriority(priority)}
            disabled={disabled}
            prefixIcon={PRIORITY[priority].icon}
            testId={`${testId}-priority-${priority}-item`}
          >
            <FormattedMessage id={PRIORITY[priority].labelId} />
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  );
};

export default Priority;
