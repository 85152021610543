import { CommonManipulator } from './Common/Common';
import { ApplyStlesManipulator } from './Common/Styles/ApplyStyles';
import { RemoveStylesManipulator } from './Common/Styles/RemoveStyles';
import { ContentManipulator } from './ContentManipulator';
import { InsertManipulator } from './Suggestions/Insert';
import { RemoveManipulator } from './Suggestions/Remove';
import { SplitManipulator } from './Suggestions/Split';

export class SuggestionManipulator extends ContentManipulator {
  manipulatorContext: Editor.Edition.ManipulatorsContext;

  constructor(ctx: Editor.Edition.Context) {
    super(ctx);

    this.manipulatorContext = {};

    this.manipulatorContext.common = new CommonManipulator(
      this.editionContext,
      this.manipulatorContext,
    );
    this.manipulatorContext.styles = {
      apply: new ApplyStlesManipulator(this.editionContext, this.manipulatorContext),
      remove: new RemoveStylesManipulator(this.editionContext, this.manipulatorContext),
    };
    this.manipulatorContext.remove = new RemoveManipulator(
      this.editionContext,
      this.manipulatorContext,
    );
    this.manipulatorContext.insert = new InsertManipulator(
      this.editionContext,
      this.manipulatorContext,
    );
    this.manipulatorContext.split = new SplitManipulator(
      this.editionContext,
      this.manipulatorContext,
    );
  }
}
