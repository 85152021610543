import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import GradientDefinition from '../GradientDefinition';

type GradientBackgroundProps = {
  background: Presentation.Data.Common.GradientFillType;
  size: Presentation.Data.Common.Size;
  shadeToTitle?: boolean;
};

const GradientBackground = ({ background, size, shadeToTitle }: GradientBackgroundProps) => {
  //Avoid generating a new id on every rerender
  const fillId = useMemo(() => uuid(), []);
  return (
    <>
      <defs>
        <GradientDefinition id={fillId} gradientFill={background} shadeToTitle={shadeToTitle} />
      </defs>
      <rect width={size.width} height={size.height} fill={`url(#${fillId})`} />
    </>
  );
};

export default GradientBackground;
