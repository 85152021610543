import { PathUtils } from 'Editor/services/_Common/Selection';
import { BaseOperation } from './BaseOperation';
import { NodeUtils } from 'Editor/services/DataManager';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

export class RemoveContentOperation extends BaseOperation<Editor.Data.Node.Model> {
  private startPath: Editor.Selection.Path;
  private endPath: Editor.Selection.Path;
  private options: Editor.Edition.RemoveContentOptions;

  constructor(
    model: Editor.Data.Node.Model,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
    options: Editor.Edition.RemoveContentOptions = {},
  ) {
    super(model);

    this.startPath = startPath;
    this.endPath = endPath;
    this.options = {
      pathFix: undefined,
      mergeText: options.mergeText || true,
      ...options,
    };

    this.build();
  }

  private adjustPathToContent(
    commonAncestor: Editor.Data.Node.Data,
    commonAncestorPath: Editor.Selection.Path,
    pathToElement: Editor.Selection.Path,
  ): Editor.Selection.Path {
    if (this.options.pathFix === 'TEXT_END' && !pathToElement.includes('content')) {
      const baseData = this.model.selectedData();

      if (baseData) {
        const previousElement = NodeUtils.getPreviousSibling(baseData, pathToElement);

        if (previousElement && NodeUtils.isTextData(previousElement.data)) {
          return [...previousElement.path, 'content', previousElement.data.content.length];
        }
      }
    } else if (this.options.pathFix === 'AFTER') {
      // TODO: rework this
      // BUG delete text with half citation group selected

      // set path outside element at common ancestor level
      let subStartPath = pathToElement.slice(commonAncestorPath.length, pathToElement.length);
      if (
        NodeUtils.isPathAtContentStart(commonAncestor, subStartPath) ||
        subStartPath.length === 0
      ) {
        return [...commonAncestorPath, 'childNodes', 0];
      } else if (subStartPath.includes('content')) {
        let path = [...commonAncestorPath, ...subStartPath.slice(0, 2)];
        let offset = Number(path[path.length - 1]);
        if (!isNaN(offset)) {
          path[path.length - 1] = offset + 1;
        }
        return path;
      } else {
        let path = [...commonAncestorPath, ...subStartPath];
        return path;
      }
    }

    return pathToElement;
  }

  private removeTextContent(
    textElement: Editor.Data.Node.TextData,
    originalStartPath: Editor.Selection.Path,
    originalEndPath: Editor.Selection.Path,
  ) {
    let pathToElement: Editor.Selection.Path;

    let startOffset;
    if (originalStartPath.includes('content')) {
      startOffset = +originalStartPath[originalStartPath.length - 1];
      pathToElement = originalStartPath.slice(0, originalStartPath.length - 2);
    } else {
      startOffset = 0;
      pathToElement = [...originalStartPath];
    }

    let endOffset;
    if (originalEndPath.includes('content')) {
      endOffset = +originalEndPath[originalEndPath.length - 1];
    } else {
      endOffset = textElement.content.length;
    }

    if (!isNaN(startOffset) && !isNaN(endOffset)) {
      if (startOffset === 0 && endOffset === textElement.content.length) {
        this.ops.push(RealtimeOpsBuilder.listDelete(textElement, pathToElement));
        this.resultPath = pathToElement;
      } else {
        let pathToRemove: Editor.Selection.Path = [...pathToElement, 'content', startOffset];
        this.ops.push(
          RealtimeOpsBuilder.stringDelete(
            textElement.content.slice(startOffset, endOffset),
            pathToRemove,
          ),
        );
        this.resultPath = pathToRemove;
      }
    }
  }

  private removeChildContent(
    baseData: Editor.Data.Node.Data,
    commonAncestor: Editor.Data.Node.Data,
    commonAncestorPath: Editor.Selection.Path,
    originalStartPath: Editor.Selection.Path,
    originalEndPath: Editor.Selection.Path,
  ) {
    const removeQueue: {
      data: Editor.Data.Node.Data;
      subStartPath: Editor.Selection.Path;
      subEndPath: Editor.Selection.Path;
      pathTo: Editor.Selection.Path;
    }[] = [
      {
        data: commonAncestor,
        subStartPath: originalStartPath.slice(commonAncestorPath.length, originalStartPath.length),
        subEndPath: originalEndPath.slice(commonAncestorPath.length, originalEndPath.length),
        pathTo: commonAncestorPath,
      },
    ];

    while (removeQueue.length) {
      const element = removeQueue.shift();

      if (element) {
        if (
          (element.subStartPath.includes('childNodes') ||
            element.subEndPath.includes('childNodes')) &&
          element.data.childNodes?.length
        ) {
          let startOffset = 0;
          if (element.subStartPath.length > 0) {
            startOffset = Number(element.subStartPath[1]);
          }

          let endOffset = 0;
          // check if endOffset should be included
          if (element.subEndPath.length > 2) {
            endOffset = Number(element.subEndPath[1]);
          } else if (element.subEndPath.length > 0) {
            endOffset = Number(element.subEndPath[1]) - 1;
          } else {
            endOffset = element.data.childNodes?.length || 0;
          }

          if (!isNaN(startOffset) && !isNaN(endOffset)) {
            let child: Editor.Data.Node.Data | undefined;
            for (let i = startOffset; i <= endOffset; i++) {
              child = element.data.childNodes?.[i];
              if (child) {
                if (i === startOffset && i === endOffset) {
                  removeQueue.unshift({
                    data: child,
                    subStartPath: element.subStartPath.slice(2, element.subStartPath.length),
                    subEndPath: element.subEndPath.slice(2, element.subEndPath.length),
                    pathTo: [...element.pathTo, 'childNodes', i],
                  });
                } else if (i === startOffset) {
                  // start node
                  let startPath: Editor.Selection.Path = element.subStartPath.slice(
                    2,
                    element.subStartPath.length,
                  );
                  let endPath: Editor.Selection.Path = [];

                  if (NodeUtils.isPathAtContentStart(child, startPath) || startPath.length === 0) {
                    startPath = [];
                    endPath = [];
                  } else {
                    if (NodeUtils.isTextData(child)) {
                      endPath = ['content', child.content.length];
                    } else {
                      endPath = ['childNodes', child.childNodes?.length || 0];
                    }
                  }

                  removeQueue.unshift({
                    data: child,
                    subStartPath: startPath,
                    subEndPath: endPath,
                    pathTo: [...element.pathTo, 'childNodes', i],
                  });
                } else if (i === endOffset) {
                  // end node
                  let startPath: Editor.Selection.Path = [];
                  let endPath: Editor.Selection.Path = element.subEndPath.slice(
                    2,
                    element.subEndPath.length,
                  );

                  if (NodeUtils.isPathAtContentEnd(child, endPath) || endPath.length === 0) {
                    startPath = [];
                    endPath = [];
                  } else {
                    if (NodeUtils.isTextData(child)) {
                      startPath = ['content', 0];
                    } else if (child.childNodes?.length) {
                      startPath = ['childNodes', 0];
                    }
                  }

                  removeQueue.unshift({
                    data: child,
                    subStartPath: startPath,
                    subEndPath: endPath,
                    pathTo: [...element.pathTo, 'childNodes', i],
                  });
                } else {
                  // midle nodes
                  removeQueue.unshift({
                    data: child,
                    subStartPath: [],
                    subEndPath: [],
                    pathTo: [...element.pathTo, 'childNodes', i],
                  });
                }
              }
            }
          }
        } else if (
          (element.subStartPath.includes('content') || element.subEndPath.includes('content')) &&
          NodeUtils.isTextData(element.data)
        ) {
          this.removeTextContent(
            element.data,
            [...element.pathTo, ...element.subStartPath],
            [...element.pathTo, ...element.subEndPath],
          );
        } else {
          this.ops.push(RealtimeOpsBuilder.listDelete(element.data, element.pathTo));
          this.resultPath = element.pathTo;
        }
      }
    }

    if (this.resultPath) {
      this.resultPath = this.adjustPathToContent(
        commonAncestor,
        commonAncestorPath,
        this.resultPath,
      );
    }

    if (this.options.mergeText) {
      // TODO join text nodes next to each other
      // let previousParentPath: Editor.Selection.Path = [];
      // let previousChildOffset;
      // let previousContentOffset;
      // if (this.startPath.includes('content')) {
      //   previousParentPath = this.startPath.slice(0, this.startPath.length - 4);
      //   previousChildOffset = Number(this.startPath[this.startPath.length - 3]);
      //   previousContentOffset = Number(this.startPath[this.startPath.length - 1]);
      // }  else {
      //   previousParentPath = this.startPath.slice(0, this.startPath.length - 2);
      //   previousChildOffset = Number(this.startPath[this.startPath.length - 1]) - 1;
      // }
      // let nextParentPath: Editor.Selection.Path = [];
      // let nextChildOffset;
      // let nextContentOffset;
      // if (this.endPath.includes('content')) {
      //   nextParentPath = this.endPath.slice(0, this.endPath.length - 4);
      //   nextChildOffset = Number(this.endPath[this.endPath.length - 3]);
      //   nextContentOffset = Number(this.endPath[this.endPath.length - 1]);
      // }  else {
      //   nextParentPath = this.endPath.slice(0, this.endPath.length - 2);
      //   nextChildOffset = Number(this.endPath[this.endPath.length - 1]);
      // }
      // if (this.startPath.includes('content')) {
      //   parentPath = this.startPath.slice(0, this.startPath.length - 4);
      //   childOffset = Number(this.startPath[this.startPath.length - 3]);
      //   contentOffset = Number(this.startPath[this.startPath.length - 1]);
      //   let parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      //   if (parentData && !isNaN(childOffset) && !isNaN(contentOffset)) {
      //     const child = parentData.childNodes?.[childOffset];
      //     const nextChild = parentData.childNodes?.[childOffset + 1];
      //     if (
      //       NodeUtils.isTextData(child) &&
      //       NodeUtils.isTextData(nextChild) &&
      //       contentOffset === child.content.length
      //     ) {
      //       this.ops.push(RealtimeOpsBuilder.stringInsert(nextChild.content, this.startPath));
      //       this.ops.push(
      //         RealtimeOpsBuilder.listDelete(nextChild, [
      //           ...parentPath,
      //           'childNodes',
      //           childOffset + 1,
      //         ]),
      //       );
      //       this.resultPath = this.startPath;
      //     }
      //   }
      // } else {
      //   parentPath = this.startPath.slice(0, this.startPath.length - 2);
      //   childOffset = Number(this.startPath[this.startPath.length - 1]);
      //   let parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      //   if (parentData && !isNaN(childOffset)) {
      //     const child = parentData.childNodes?.[childOffset];
      //     const previousChild = parentData.childNodes?.[childOffset - 1];
      //     if (NodeUtils.isTextData(child) && NodeUtils.isTextData(previousChild)) {
      //       let path: Editor.Selection.Path = [
      //         ...parentPath,
      //         'childNodes',
      //         childOffset - 1,
      //         'content',
      //         previousChild.content.length,
      //       ];
      //       this.ops.push(RealtimeOpsBuilder.stringInsert(child.content, path));
      //       this.ops.push(RealtimeOpsBuilder.listDelete(child, this.startPath));
      //       this.resultPath = path;
      //     }
      //   }
      // }
    }
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const baseData = this.model.selectedData();

    if (!baseData || PathUtils.isPathEqual(this.startPath, this.endPath)) {
      return this;
    }

    let commonAncestorPath = PathUtils.getCommonAncestorPath(this.startPath, this.endPath);
    let commonAncestorInfo = NodeUtils.getParentChildInfoByPath(baseData, commonAncestorPath);

    if (!commonAncestorInfo) {
      return this;
    }

    let commonAncestor = commonAncestorInfo.childData;

    if (!commonAncestor) {
      return this;
    }

    let remainingStartPath: Editor.Selection.Path = [];
    let remainingEndPath: Editor.Selection.Path = [];

    if (PathUtils.isPathEqual(commonAncestorPath, commonAncestorInfo.childPath)) {
      // expand start
      remainingStartPath = this.startPath.slice(commonAncestorPath.length);
      let startInfo = NodeUtils.getParentChildInfoByPath(commonAncestor, remainingStartPath);

      while (
        startInfo?.childData &&
        startInfo?.parentData &&
        startInfo.childData.id !== commonAncestor.id &&
        !NodeUtils.BLOCK_TYPES.includes(startInfo.childData.type) &&
        (startInfo.contentIndex === 0 ||
          (startInfo.childIndex === 0 && isNaN(startInfo.contentIndex)))
      ) {
        remainingStartPath = remainingStartPath.slice(0, remainingStartPath.length - 2);
        startInfo = NodeUtils.getParentChildInfoByPath(commonAncestor, remainingStartPath);
      }

      // expand end
      remainingEndPath = this.endPath.slice(commonAncestorPath.length);
      let endInfo = NodeUtils.getParentChildInfoByPath(commonAncestor, remainingEndPath);

      while (
        endInfo?.childData &&
        endInfo.parentData &&
        endInfo.childData.id !== commonAncestor.id &&
        !NodeUtils.BLOCK_TYPES.includes(endInfo.childData.type) &&
        ((NodeUtils.isTextData(endInfo.childData) &&
          endInfo.contentIndex === endInfo.childData.content.length) ||
          (endInfo.parentData.childNodes &&
            endInfo.childIndex === endInfo.parentData.childNodes.length &&
            isNaN(endInfo.contentIndex)))
      ) {
        remainingEndPath = remainingEndPath.slice(0, remainingEndPath.length - 2);
        let offset = Number(remainingEndPath[remainingEndPath.length - 1]);
        if (!isNaN(offset)) {
          remainingEndPath[remainingEndPath.length - 1] = offset + 1;
        }

        endInfo = NodeUtils.getParentChildInfoByPath(commonAncestor, remainingEndPath);
      }
    } else {
      // specific cases where rendered path does not have a complete representation in the database
      // like page-breaks, section-breaks, etc
      commonAncestorPath = commonAncestorInfo.childPath;
      remainingStartPath = [];
      remainingEndPath = [];
    }

    let startPath = [...commonAncestorPath, ...remainingStartPath];
    let endPath = [...commonAncestorPath, ...remainingEndPath];

    if (NodeUtils.isTextData(commonAncestor)) {
      this.removeTextContent(commonAncestor, startPath, endPath);
    } else if (commonAncestor) {
      this.removeChildContent(baseData, commonAncestor, commonAncestorPath, startPath, endPath);
    }

    return this;
  }
}
