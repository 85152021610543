import { memo, useEffect, useRef } from 'react';
import cx from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

import { useDispatch, useFocusElementByUrl, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { usePDFContext, usePDFManagerEvents } from 'PDF/PDFContext';

import { selectPDFZoom, setAnnotationToFocus } from 'PDF/redux/PDFGeneralSlice';

import { ReduxInterface } from 'PDF/services/ReduxInterface';
import usePDFData from 'PDF/hooks/usePDFData';
import { usePan } from '_common/suite/hooks';
import PDFPage from './PDFPage';

import styles from './Content.module.scss';
import { CONTENT_PAGE_GAP_SIZE } from 'PDF/consts';
import useSelectAnnotation from 'PDF/hooks/useSelectAnnotation';

const PDFDocument = () => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();

  const { selectAnnotation } = useSelectAnnotation();

  const inPanMode = useSelector((state) => state.pdf.general.cursorMode) === 'pan';
  const { ref: outerRef, isPanning } = usePan(inPanMode);
  const zoom = useSelector(selectPDFZoom);
  const ref = useRef<HTMLElement>(null);
  const versionHistory = useSelector((state) => state.pdf.general.versionHistory);
  const containerRef = useRef<HTMLDivElement>(null);
  const numPages = pdfManager.getNumPages();
  const annotations = useSelector((state) => state.pdf.annotations.byId);
  const annotationToFocus = useSelector((state) => state.pdf.general.annotationToFocus);
  const pdfData = usePDFData();
  const currentPage = useSelector((state) => state.pdf.general.currentPageNumber);
  const areClusteredAnnotationsProcessed = useSelector((state) => state.pdf.annotations.clustered);

  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);

  useFocusElementByUrl();

  usePDFManagerEvents('SCROLL_TO_PAGE', (current) => {
    setTimeout(() => {
      if (ref.current) {
        // @ts-expect-error
        ref.current.scrollToItem(current - 1, 'start');
        ReduxInterface.setCurrentPage(current);
      }
    }, 0);
  });

  useEffect(() => {
    pdfManager.navigation?.goTo(currentPage);
    if (ref.current) {
      // @ts-expect-error
      ref.current.scrollToItem(currentPage - 1, 'start');
    }
  }, [zoom]);

  useEffect(() => {
    if (
      annotationToFocus &&
      annotationToFocus.documentId &&
      pdfData &&
      pdfData.id === annotationToFocus.documentId &&
      areClusteredAnnotationsProcessed
    ) {
      const { objectId } = annotationToFocus;
      let objectNotFound = false;

      if (annotations) {
        const annotationsIds = Object.keys(annotations);

        if (annotationsIds.length > 0) {
          const annotation = Object.keys(annotations).filter(
            (annotationId) => annotationId === objectId,
          )[0];

          if (
            annotation &&
            annotations[annotation].state !== 'Completed' &&
            annotations[annotation].state !== 'Cancelled'
          ) {
            selectAnnotation(annotation);
          } else {
            objectNotFound = true;
          }
        }
      }

      dispatch(setAnnotationToFocus(undefined));

      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'ELEMENT_NOT_ACCESSIBLE',
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_DOESNT_EXIST',
        });
      }
    }
  }, [annotationToFocus, annotations, areClusteredAnnotationsProcessed]);

  return (
    <div
      id="pdfContainer"
      className={cx({
        [styles.content]: true,
        [styles.versionHistory]: versionHistory,
      })}
      ref={containerRef}
      tabIndex={0}
    >
      <AutoSizer>
        {({ height, width }) => (
          <List
            // @ts-expect-error
            ref={ref}
            key={zoom}
            outerRef={outerRef}
            className={cx((active.dopdf || started.dopdf) && styles.overlap, {
              [styles.panning]: isPanning,
              [styles.pan]: inPanMode,
            })}
            height={height}
            itemCount={numPages}
            itemSize={(index: number) =>
              pdfManager.getPageHeight(index + 1) * zoom + CONTENT_PAGE_GAP_SIZE
            }
            width={width}
            onScroll={({ scrollOffset }) => {
              let pageNum = 1;
              if (scrollOffset !== 0) {
                const offset = scrollOffset + height / 2;
                let totalHeight = 0;
                while (pageNum <= numPages) {
                  const pageHeight = pdfManager.getPageHeight(pageNum) * zoom;
                  totalHeight = totalHeight + CONTENT_PAGE_GAP_SIZE + pageHeight;
                  if (offset <= totalHeight) {
                    break;
                  }
                  pageNum += 1;
                }
              }
              pdfManager.navigation?.setCurrentPage(pageNum);
              // scrollDirection is either "forward" or "backward".
              // scrollOffset is a number.
              // scrollUpdateWasRequested is a boolean.
              // This value is true if the scroll was caused by scrollTo() or scrollToItem(),
              // And false if it was the result of a user interaction in the browser.
            }}
          >
            {PDFPage}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default memo(PDFDocument);
