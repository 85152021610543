import { useState, CSSProperties } from 'react';

import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import FieldContent from './FieldContent';
import FieldHeading from './FieldHeading';

type FieldProps = {
  field: Affiliation.Field;
} & Pick<CSSProperties, 'margin'>;

const Field = ({ field, margin }: FieldProps) => {
  const [search, setSearch] = useState('');

  return (
    <>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true, margin }}>
        <FieldHeading field={field} search={search} margin={margin} onFilterChange={setSearch} />
      </IntlErrorBoundary>

      <IntlErrorBoundary size="large" margin="10rem 0 10rem 0">
        <FieldContent field={field} search={search} />
      </IntlErrorBoundary>
    </>
  );
};

export default Field;
