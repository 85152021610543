import { ActionContext } from '../ActionContext';

export abstract class ContentManipulator implements Editor.Edition.IContentManipulator {
  protected editionContext: Editor.Edition.Context;

  abstract manipulatorContext: Editor.Edition.ManipulatorsContext;

  constructor(ctx: Editor.Edition.Context) {
    this.editionContext = ctx;
  }

  wrapContent(
    model: Editor.Data.Node.Model,
    wrapElement: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ) {
    if (!this.manipulatorContext.common) {
      return undefined;
    }
    return this.manipulatorContext.common.wrapContent(model, wrapElement, startPath, endPath);
  }

  // unwrapContent(
  //   model: Editor.Data.Node.Model,
  //   pathToElementToUnwrap: Editor.Selection.Path,
  // ): Editor.Selection.Path | undefined {
  //   return this.common.unwrapContent(model, pathToElementToUnwrap);
  // }

  splitInlineContent(
    model: Editor.Data.Node.Model,
    textData: Editor.Data.Node.Data,
    textDataPath: Editor.Selection.Path,
    pathToSplit: Editor.Selection.Path,
  ) {
    if (!this.manipulatorContext.common) {
      return undefined;
    }
    return this.manipulatorContext.common.splitInlineContent(
      model,
      textData,
      textDataPath,
      pathToSplit,
    );
  }

  insertContent(
    ctx: Editor.Edition.ActionContext,
    path: Editor.Selection.Path,
    dataToInsert: Editor.Data.Node.Data | string,
    options?: Editor.Edition.InsertContentOptions,
  ) {
    if (!this.manipulatorContext.insert) {
      return false;
    }
    return this.manipulatorContext.insert.insertContent(ctx, path, dataToInsert, options);
  }
  insertBlock(
    ctx: ActionContext,
    blockData: Editor.Data.Node.Data,
    position: 'BEFORE' | 'AFTER',
    options?: Editor.Edition.InsertBlockOptions,
  ) {
    if (!this.manipulatorContext.insert) {
      return false;
    }
    return this.manipulatorContext.insert.insertBlock(ctx, blockData, position, options);
  }

  removeContent(ctx: Editor.Edition.ActionContext, options?: Editor.Edition.RemoveContentOptions) {
    if (!this.manipulatorContext.remove) {
      return false;
    }
    return this.manipulatorContext.remove.removeContent(ctx, options);
  }

  removeBlock(ctx: Editor.Edition.ActionContext, opts?: Editor.Edition.RemoveContentOptions) {
    if (!this.manipulatorContext.remove) {
      return false;
    }
    return this.manipulatorContext.remove.removeBlock(ctx, opts);
  }

  splitBlockContent(
    ctx: Editor.Edition.ActionContext,
    pathToSplit: Editor.Selection.Path,
    insertOptions: Editor.Edition.SplitBlockOptions = {},
  ) {
    if (!this.manipulatorContext.split) {
      return undefined;
    }
    return this.manipulatorContext.split.splitBlockContent(ctx, pathToSplit, insertOptions);
  }

  // ----------------------------------------------------
  //                      Styles
  // ----------------------------------------------------
  removeStyle<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    range: Editor.Selection.JsonRange,
    style: T,
    value?: Editor.Edition.StylesMap[T],
  ) {
    if (!this.manipulatorContext.styles?.remove) {
      return range;
    }
    return this.manipulatorContext.styles.remove.removeStyle(baseModel, range, style, value);
  }

  applyStyle<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    range: Editor.Selection.JsonRange,
    style: T,
    value: Editor.Edition.StylesMap[T],
  ) {
    if (!this.manipulatorContext.styles?.apply) {
      return range;
    }
    return this.manipulatorContext.styles.apply.applyStyle(baseModel, range, style, value);
  }
}
