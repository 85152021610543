import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { Switch as BaseSwitch, Tooltip } from 'dodoc-design-system';

import type { SwitchProps as BaseSwitchProps } from 'dodoc-design-system/build/types/Components/Switch/Switch';

export type SwitchProps = Pick<BaseSwitchProps, 'onChange' | 'disabled' | 'active' | 'testId'> & {
  label?: string;
  margin?: CSSProperties['margin'];
  inAppActive?: boolean;
};

const Switch = ({
  onChange,
  disabled,
  active,
  label,
  margin,
  testId,
  inAppActive,
}: SwitchProps) => {
  const intl = useIntl();

  return (
    <Tooltip
      disabled={!disabled}
      placement="top"
      content={
        inAppActive === false
          ? intl.formatMessage({
              id: 'CANNOT_ACTIVATE_EMAIL_NOTIFICATIONS_WITHOUT_THE_ACTIVATION_OF_IN_APP_NOTIFICATIONS',
            })
          : intl.formatMessage({ id: 'SETTING_CANNOT_BE_CHANGED_DUE_TO_SECURITY_ISSUES' })
      }
      testId={`${testId}-tooltip`}
    >
      <div style={{ margin }}>
        <BaseSwitch
          size="large"
          labelPlacement="right"
          onChange={(e) => {
            onChange(e);
          }}
          disabled={disabled}
          active={active}
          testId={testId}
        >
          {label}
        </BaseSwitch>
      </div>
    </Tooltip>
  );
};

export default Switch;
