import { Icon } from "dodoc-design-system";
import { FormattedMessage } from "react-intl";


type ReferencePriorityCellProps = {
  object: Objekt;
  value: string;
};

const ReferencePriorityCell = ({ object, value }: ReferencePriorityCellProps) => {
  const referenceValue = value.split('.');
  return (
    <div style={{ display: 'flex' }}>
      {/**@ts-expect-error */}
      <Icon icon={`${referenceValue[0].charAt(0).toUpperCase()}${referenceValue[0].slice(1)}`} size={24} />
      <span style={{ marginLeft: '1rem' }}>
        <FormattedMessage
          id="NUMBER_OF_REFERENCES"
          values={{
            number: object.reference_priority?.[referenceValue[0]]?.[referenceValue[1]],
          }}
        />
      </span></div>
  )

};

export default ReferencePriorityCell;