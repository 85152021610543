import { Path } from 'd3';
import { SHAPE_CONST } from './consts';

type EllipseArcToReturn = {
  /** X of end point of arc */
  eX: number;
  /** Y of end point of arc */
  eY: number;
  /** X of center point of arc */
  cX: number;
  /** Y of center point of arc */
  cY: number;
  /** Callback to quick redraw the same arc for another d3Path context */
  redraw: (context: d3.Path & { _?: string }) => EllipseArcToReturn;
};

/**
 * Append arc to D3 Path serializer context based on ellipse parameters
 * @param context D3 Path serializer context to modify
 * @param wR Width radius of ellipse
 * @param hR Height radius of ellipse
 * @param stAng Angle of start point
 * @param swAng Angle of swing to perform
 * @param sX Start point X
 * @param sY Start point Y
 * @returns Object with the end point, center point and a callback to redraw the same arc
 */
export const ellipseArcTo = (
  // Added _ to context, because it's a private property (not sure if it's the best approach, but it works for now)
  context: d3.Path & { _?: string },
  wR: number,
  hR: number,
  stAng: number | keyof typeof SHAPE_CONST,
  swAng: number | keyof typeof SHAPE_CONST,
  sX: number,
  sY: number,
): EllipseArcToReturn => {
  // Convert static angles to its values
  stAng = typeof stAng === 'string' ? SHAPE_CONST[stAng] : stAng;
  swAng = typeof swAng === 'string' ? SHAPE_CONST[swAng] : swAng;

  /**
   * If the swing is 360º the end point will be the same as start point
   * When this happens, the ellipse will not be drawn
   *
   * Solution: Allow sending a full swing but subtract a tiny value and close the path
   */
  let closePath = false;
  if (swAng >= Math.PI * 2) {
    swAng = Math.PI * 2 - 0.01;
    closePath = true;
  }

  // Get the angle of the end point (from center point)
  const endAng = stAng + swAng;

  let centerX;
  let centerY;
  let endX;
  let endY;

  /**
   * Using atan2 will give more accurate angle calculations
   * However, when the ellipse is flat in either axis, the atan2 algorithm will have issues
   * Use simple trigonometry if ellipse is flat
   */
  if (wR !== 0 && hR !== 0 && !closePath) {
    //Displacement of start angle
    const wtSt = wR * Math.sin(stAng);
    const htSt = hR * Math.cos(stAng);
    const dxSt = wR * Math.cos(Math.atan2(wtSt, htSt));
    const dySt = hR * Math.sin(Math.atan2(wtSt, htSt));

    // Calculate the center point
    centerX = sX - dxSt;
    centerY = sY - dySt;

    //Displacement of end angle
    const wtEn = wR * Math.sin(endAng);
    const htEn = hR * Math.cos(endAng);
    const dxEn = wR * Math.cos(Math.atan2(wtEn, htEn));
    const dyEn = hR * Math.sin(Math.atan2(wtEn, htEn));

    // Calculate the end point
    endX = centerX + dxEn;
    endY = centerY + dyEn;
  } else {
    // Calculate the center point
    centerX = sX + wR * Math.cos(stAng + Math.PI);
    centerY = sY + hR * Math.sin(stAng + Math.PI);

    // Calculate the end point
    endX = centerX + wR * Math.cos(endAng);
    endY = centerY + hR * Math.sin(endAng);
  }

  // Append the arc to the context
  context._ += `A${wR} ${hR} 0 ${Math.abs(swAng) < Math.PI ? '0' : '1'} ${
    swAng < 0 ? '0' : '1'
  } ${endX} ${endY}`;

  // Close the path if the swing is 360
  if (closePath) {
    context._ += 'Z';
  }

  return {
    eX: endX,
    eY: endY,
    cX: centerX,
    cY: centerY,
    redraw: (context: d3.Path & { _?: string }) =>
      ellipseArcTo(context, wR, hR, stAng, swAng, sX, sY),
  };
};

/**
 * pin X Y Z (=) Math.max(Math.min(Z, Y), X)
 *
 * Source: Page 2897 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */
export const pin = (x: number, y: number, z: number) => {
  return Math.max(Math.min(z, y), x);
};

/**
 * mod X Y Z (=) sqrt(x^2 + b^2 + c^2)
 *
 * Source: Page 2897 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */
export const mod = (x: number, y: number, z: number) => {
  return Math.sqrt(x ** 2 + y ** 2 + z ** 2);
};

/**
 * Context: "Units are in 60'000 of a degree"
 * Source: Page 2988 of Ecma Office Open XML Part 1 - Fundamentals And Markup Language Reference.pdf
 */
export const constantToRad = (constant: number) => {
  return ((constant / 60000) * Math.PI) / 180;
};

//TODO:PRESENTATION:SHAPES Use markers instead of drawing the arrow
export const drawArrow = (
  d: Path,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  size: number,
) => {
  const angle = Math.atan2(endY - startY, endX - startX);

  d.moveTo(endX, endY);
  d.lineTo(
    endX - size * Math.cos(angle - Math.PI / 6),
    endY - size * Math.sin(angle - Math.PI / 6),
  );
  d.lineTo(
    endX - size * Math.cos(angle + Math.PI / 6),
    endY - size * Math.sin(angle + Math.PI / 6),
  );
  d.closePath();
};

/**
 * Modify color based on the modifier to darken or lighten the color
 * @param color The hexadecimal color to modify (with or without #)
 * @param modifier The modifier to apply to the color
 * @returns The modified hexadecimal color (with or without #)
 *
 * Modifier values source: https://learn.microsoft.com/en-us/openspecs/office_standards/ms-oi29500/bada2996-3fd4-4b83-9882-92e14d6f9c7d
 */
export const modifyColor = (color: string, modifier?: Presentation.Data.FillModifier) => {
  if (!modifier || modifier === 'norm') {
    return color;
  }

  let usePound = false;

  // Remove the # character if present
  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  // Parse the color string into its RGB components
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  /**
   * Based on the source values
   * If the modifier is in 'Less' category use 50/255, otherwise use 0.4
   * Intensity goes from 0 to 1
   */
  const intensity = modifier.endsWith('Less') ? 0.196078 /** ~50/255 */ : 0.4;

  // Calculate the blended color
  let blendedR, blendedG, blendedB;
  if (modifier === 'darken' || modifier === 'darkenLess') {
    blendedR = Math.round(r * (1 - intensity));
    blendedG = Math.round(g * (1 - intensity));
    blendedB = Math.round(b * (1 - intensity));
  } else {
    blendedR = Math.round(r + (255 - r) * intensity);
    blendedG = Math.round(g + (255 - g) * intensity);
    blendedB = Math.round(b + (255 - b) * intensity);
  }

  // Return the blended color as hexadecimal
  return `${usePound ? '#' : ''}${((blendedR << 16) + (blendedG << 8) + blendedB).toString(16)}`;
};
