import InstanceService from './InstanceService';

/**
 * Shared related requests
 */
export default class SharedService extends InstanceService {
  // -----------------------------------------------------------------
  // shared endpoints
  SHARED_LIST = this.LIST('share');

  /**
   * list shared
   */
  listShared(params: Request.AllListParams) {
    return this.get(this.SHARED_LIST, { params });
  }
}
