import { useSelector, useDispatch } from '_common/hooks';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Divider, Toggle, usePopper } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { getBulletList } from 'Editor/redux/listStylesSlice';
import { selectedBulletList } from 'Editor/redux/ToolbarSlice';
import PreviewLists from 'Editor/components/PreviewLists/PreviewLists';

import styles from './BulletList.module.scss';

const BulletList = () => {
  const dispatch = useDispatch();
  const selectedList = useSelector((state) => state.editor.toolbar.selectedList);
  const selected = useSelector((state) => selectedBulletList(state));
  const bulletLists = useSelector((state) => getBulletList(state));
  const isReadOnlyMode = useSelector((state) => selectReadOnlyMode(state));
  const isPageLayout = useSelector(selectIsPageLayout);

  const { isOpen, close, popperProps, referenceProps } = usePopper({
    disabled: isReadOnlyMode,
    placement: 'bottom-end',
  });

  const handleToggleList = (element: string) => {
    const manager = EditorManager.getInstance();
    if (element === selectedList) {
      manager.toggleList(false);
    } else {
      manager.toggleList(element);
    }
  };

  const handleListAction = (customize: boolean) =>
    dispatch(
      openAndUpdateModal({
        modal: 'NewListModal',
        data: {
          customize,
          type: 'bullet',
        },
      }),
    );

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={selected || isOpen}
        icon="BulletList"
        dropdown
        disabled={isReadOnlyMode || isPageLayout}
        testId="bullet-list-toggle"
        {...referenceProps}
      />

      <Dropdown
        {...popperProps}
        width={Object.keys(bulletLists).length > 6 ? '32.5rem' : '30rem'}
        testId="bullet-list-dropdown"
      >
        <div className={styles.styles}>
          {Object.keys(bulletLists).map((element) => (
            <div
              key={element}
              onClick={() => {
                handleToggleList(element);
                close();
              }}
              className={`${styles.item} ${element === selectedList && styles.selected}`}
              data-testid={`${element}-list`}
            >
              <PreviewLists list={bulletLists[element]} />
            </div>
          ))}
        </div>
        <Divider />
        {Object.keys(bulletLists).length > 0 && (
          <Dropdown.Item
            onClick={() => handleListAction(true)}
            testId="bullet-list-customize-list-styles"
          >
            <FormattedMessage id="CUSTOMIZE_LIST_STYLES" />
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => handleListAction(false)} testId="bullet-list-create-new-list">
          <FormattedMessage id="CREATE_NEW_LIST" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default BulletList;
