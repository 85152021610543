import { CSSProperties } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';

import { FilterIdentity } from '../../FilterController';
import { clearFilterIdentity } from '../../FilterSlice';

import styles from './EmptyFilteredState.module.scss';

type EmptyFilteredStateProps = {
  margin?: CSSProperties['margin'];
  //If no identity set, clear button won't be rendered
  identity?: FilterIdentity;
  size?: 'medium' | 'large';
  hideIcon?: boolean;
  message?: TranslationMessage;
};

const EmptyFilteredState = ({
  identity,
  size = 'large',
  margin = '8rem 0 0 0',
  hideIcon = false,
  message,
}: EmptyFilteredStateProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    if (identity) {
      dispatch(clearFilterIdentity({ identity }));
    }
  };

  return (
    <span className={styles.root} style={{ margin }}>
      <EmptyState
        size={size}
        btnVariant="neutral"
        title={intl.formatMessage({ id: 'NO_RESULTS_FOUND' })}
        icon={!hideIcon ? (size === 'large' ? 'NoSearchResults' : 'NoChangesProposed') : undefined}
        footer={identity ? intl.formatMessage({ id: 'CLEAR_FILTERS' }) : undefined}
        onClick={handleClearFilters}
        testId={`${identity ? `${identity}-` : ''}emptyFilteredState`}
      >
        {message ? (
          <FormattedMessage id={message.id} values={message.values} />
        ) : (
          <FormattedMessage id="NO_ELEMENTS_IN_SPACE_MATCHING_FILTERS_CLEAR_FILTERS" />
        )}
      </EmptyState>
    </span>
  );
};

export default EmptyFilteredState;
