import { PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { UpdateImageSourceOperation } from '../../Operations/ImageOperations/UpdateImageSourceOperation';
import { InsertImageCommand } from './InsertImageCommand';

export class ChangeImageCommand extends InsertImageCommand {
  image: File;

  constructor(context: Editor.Edition.Context, image: File) {
    super(context, image);

    this.image = image;
  }

  async handleExec(): Promise<void> {
    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    const jsonRange = this.actionContext.range;
    const baseModel = this.actionContext.baseModel;
    const baseData = this.actionContext.baseData;

    const result = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['img', 'figure']);
    const closest = result?.data;

    const closestSuggestion = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, [
      'tracked-insert',
      'tracked-delete',
    ]);

    let sameUserSuggestion: boolean = false;
    const suggestionsData = closestSuggestion?.data;
    sameUserSuggestion =
      closestSuggestion != null &&
      NodeUtils.isTrackedData(suggestionsData) &&
      this.isUserAuthor(suggestionsData);

    if (this.context.editionMode === 'SUGGESTIONS' && !sameUserSuggestion) {
      // track changes
      // call insert image exec
      await this.insertImage();
    } else {
      if (closest && closest.id) {
        const imageInfo = baseModel.getChildInfoById(closest.id);

        const imageData = await this.uploadImage(this.image);

        if (
          NodeUtils.isImageData(imageInfo.data) &&
          PathUtils.isValidSelectionPath(imageInfo.path)
        ) {
          this.applyOperations(
            baseModel,
            imageInfo.path,
            imageInfo.data,
            imageData.reference,
            imageData.dimensions.height,
            imageData.dimensions.width,
          );

          this.createPatch();
        }
      }
    }
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    imageData: Editor.Data.Node.ImageData,
    source: string,
    height: number, // in points
    width: number, // in points
  ) {
    const operation = new UpdateImageSourceOperation(
      baseModel,
      path,
      imageData,
      source,
      height,
      width,
    );
    return operation.apply();
  }

  async insertImage() {
    await super.handleExec();
  }
}
