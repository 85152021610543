export default class ErrorNodeIsLocked extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'ErrorNodeIsLocked';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorNodeIsLocked);
    }
  }
}
