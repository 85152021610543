import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import { usePasswordChecker } from '_common/hooks';

import styles from './PasswordCheck.module.scss';

type PasswordCheckProps = {
  rules: Account['password_validators'];
  password: string;
  setDisabled: (value: boolean) => void;
};

const RULES_TRANSLATIONS = {
  min_length: { label: 'AT_LEAST_CHARS' },
  max_length: { label: 'ANO_MORE_THAN_CHARS' },
  min_max_length: { label: 'BETWEEN_CHARS' },
  contains_upper_case: { label: 'A_UPPERCASE_LETTER' },
  contains_lower_case: { label: 'A_LOWERCASE_LETTER' },
  contains_digit: { label: 'A_NUMBER' },
  contains_symbol: { label: 'A_SPECIAL_CHAR' },
  regex: { label: 'CUSTOM_RESTRICTIONS' },
} as const;

const PasswordCheck = ({ rules, password, setDisabled }: PasswordCheckProps) => {
  const compliedRules = usePasswordChecker(rules, password);

  const parsedRules = useMemo(() => {
    const mergedRules = rules.reduce<
      { min_max_length?: { min: number; max: number } } & Account['password_validators'][number]
    >((merged, rule) => ({ ...merged, ...rule }), {});

    //Replace min and max with range rule
    if (mergedRules.min_length && mergedRules.max_length) {
      mergedRules.min_max_length = {
        min: mergedRules.min_length,
        max: mergedRules.max_length,
      };
      delete mergedRules['min_length'];
      delete mergedRules['max_length'];

      return mergedRules;
    }

    return mergedRules;
  }, [rules]);

  useEffect(
    () =>
      setDisabled(
        !Object.typedKeys(parsedRules)
          .filter((rule) => rule !== 'description')
          .every((ruleProperty) => compliedRules.includes(ruleProperty)),
      ),
    [parsedRules, compliedRules],
  );

  const getValues = (key: keyof typeof parsedRules) => {
    switch (key) {
      case 'min_max_length':
        return { min: parsedRules.min_max_length?.min, max: parsedRules.min_max_length?.max };
      case 'min_length':
        return { number: parsedRules.min_length };
      case 'max_length':
        return { number: parsedRules.max_length };
    }

    return {};
  };

  return (
    <div className={styles.popoverContainer} data-testid="password-policy-popover">
      <div className={styles.triangle} />
      <div className={styles.title}>
        <FormattedMessage id="PASSWORD_POLICY" />
      </div>
      {Object.typedKeys(parsedRules).map((rule) => {
        if (rule === 'description') {
          return null;
        }

        const isRuleComplied = compliedRules.includes(rule);
        return (
          <div className={styles.rule} key={rule}>
            <Icon size={24} icon={isRuleComplied ? 'Done' : 'NotDone'} />
            <div
              className={`${styles.ruleName} ${isRuleComplied && styles.verifiedName}`}
              data-testid={`${rule}-label`}
              data-complied={isRuleComplied}
            >
              <FormattedMessage id={RULES_TRANSLATIONS[rule].label} values={getValues(rule)} />
            </div>
          </div>
        );
      })}
      {parsedRules.regex && (
        <div className={styles.note} data-testid="password-policy-custom-description">
          <FormattedMessage id="NOTE_PASSWORD_RESTRICTIONS" />
          {parsedRules.description}
        </div>
      )}
    </div>
  );
};

export default PasswordCheck;
