import { useMemo } from 'react';

import { useDebounce, useSelector } from '_common/hooks';

import styles from './ShapeSelection.module.scss';
import interactionControllerStyles from '_common/components/OnboardingOverlay/InteractionController.module.scss';
import { getSvgBoundingBox } from '../InlineCards/CardHighlight/utils';

const ShapeSelection = () => {
  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const creating = useSelector((state) => state.presentation.general.creating);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  /**
   * When zoom changes UI will rerender
   * Debouncing zoom value and using it on the useMemo below will make sure we are getting the updated element rects
   */
  const debouncedZoom = useDebounce(zoom, 0);

  const selectionRect = useMemo<Omit<Rect, 'right' | 'bottom'> | null>(() => {
    if (!selectedShape) {
      return null;
    }

    return getSvgBoundingBox(document.getElementById(`slide-${selectedShape}`), debouncedZoom);
  }, [selectedShape, debouncedZoom]);

  if (!selectedShape || !selectionRect || creating?.anchor?.length) {
    return null;
  }

  return (
    <div
      id={interactionControllerStyles.skipControl}
      style={selectionRect}
      className={styles.root}
    />
  );
};

export default ShapeSelection;
