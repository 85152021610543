import TokenList from '_common/modals/UserManagement/UserTokensModal/TokenList/TokenList';

const List = () => {
  return (
    <TokenList
      emptyState={{
        title: 'NO_ACTIVE_SESSION',
        body: 'USER_DOES_NOT_HAVE_ACTIVE_SESSION',
      }}
      loadingState={{ message: 'LOADING_ACTIVE_SESSION' }}
      filters={{
        filter_fields: ['active'],
        filter_values: ['true'],
      }}
    />
  );
};

export default List;
