import { useState } from 'react';
import { Button, Input, InputField, Modal } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { closeModal } from 'App/ModalContext/utils';
import { useModalData } from 'App/ModalContext/ModalContext';
import { useDeleteMetadataMutation } from 'App/redux/MetadataApi';
import styles from './DeleteMetadataModal.module.scss';
import { notify } from '_common/components/ToastSystem';

const DeleteMetadataModal = () => {
  const intl = useIntl();
  const [deleteMetadata, { isLoading }] = useDeleteMetadataMutation();
  const [confirmation, setConfirmation] = useState('');
  const data = useModalData('DeleteMetadataModal');
  const close = () => {
    closeModal('DeleteMetadataModal');
  };

  const submit = async () => {
    if (data) {
      const result = await deleteMetadata(data.id);
      if (!('error' in result)) {
        notify({
          type: 'success',
          title: 'METADATA_DELETED',
          message: 'METADATA_SUCCESSFULLY_DELETED',
          messageValues: { name: data.name },
        });
      }
      close();
    }
  };

  if (data) {
    const disabled = confirmation !== data.name;
    return (
      <Modal open testId="delete-metadata" width="65rem" type="error" onClose={close}>
        <Modal.Header onClose={close}>
          <FormattedMessage id="DELETE_METADATA" />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.warning}>
            <FormattedMessage
              id="DELETE_METADATA_WARNING"
              values={{
                name: data.name,
                b: (chunks) => <span style={{ fontWeight: 'bold' }}>{chunks}</span>,
              }}
            />
          </div>
          <InputField
            size="large"
            label={intl.formatMessage({ id: 'TO_CONFIRM_ACTION_ENTER_METADATA_NAME' })}
            testId="confirm-metadata-name-input-field"
            feedback={
              confirmation !== '' && disabled
                ? intl.formatMessage({ id: 'METADATA_NAME_DOES_NOT_MATCH' })
                : undefined
            }
          >
            <Input
              size="large"
              testId="confirm-metadata"
              placeholder={intl.formatMessage({ id: 'ENTER_METADATA_NAME' })}
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              error={confirmation !== '' && disabled}
            />
          </InputField>
        </Modal.Body>
        <Modal.Footer>
          <Button size="medium" testId="delete-metadata-modal-cancel-button" onClick={close}>
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button
            size="medium"
            testId="delete-metadata-modal-submit-button"
            onClick={submit}
            variant="danger"
            disabled={disabled}
            loading={isLoading}
          >
            <FormattedMessage id="DELETE_METADATA" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
};

export default DeleteMetadataModal;
