import { FormattedMessage } from 'react-intl';
import AuthFooter from 'Auth/components/AuthFooter/AuthFooter';
import { LogoContainer } from '_common/components';
import { Icon } from 'dodoc-design-system';
import getConfig from 'dodoc.config';

import styles from './InvalidTenant.module.scss';

const InvalidTenant = () => (
  <div className={styles.root}>
    <LogoContainer expanded size="large" color="white" testId="invalid-tenant-logo" />
    <div className={styles.container}>
      <Icon icon="Deactivated" size={240} />
      <div className={styles.title}>
        <FormattedMessage id="DEACTIVATED_SCREEN_HEADER" />
      </div>
      <div className={styles.description}>
        <FormattedMessage
          values={{ tenant: getConfig().tenant || window.location.hostname }}
          id="DEACTIVATED_SCREEN_TEXT1"
        />
      </div>
    </div>
    <div className={styles.footer}>
      <AuthFooter white />
    </div>
  </div>
);

export default InvalidTenant;
