import { useIntl, FormattedDate } from 'react-intl';
import { useParams } from 'react-router';

import { useDispatch, usePublicGroup } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { Setting, SettingTags } from 'Settings/components';
import { LineEllipsis } from '_common/components';

const GeneralView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: groupId }: { id: ObjectId } = useParams();

  const { group } = usePublicGroup(groupId);

  const userPermissions = group.user_permissions;

  // ------------------------------------
  // -------------- Modals --------------
  const showModalChangeName = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({ id: 'settings.group.headerChangeName' }),
          label: intl.formatMessage({ id: 'settings.group.label' }),
          value: group.name,
          inputType: 'text',
          type: 'name',
          action: 'rename',
          data: group,
          actionValues: { objectId: group.id, objectType: 'group' },
        },
      }),
    );
  };

  const showModalChangeDescription = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({
            id: 'settings.group.headerChangeDescription',
          }),
          label: intl.formatMessage({ id: 'settings.group.label' }),
          value: group.description,
          inputType: 'textarea',
          type: 'description',
          action: 'editDescription',
          data: group,
          actionValues: { objectId: group.id, objectType: 'group' },
        },
      }),
    );
  };

  return (
    <>
      <div>
        {/* Name */}
        <Setting
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.general.name' })}
          onEdit={
            userPermissions.includes('admin') || userPermissions.includes('owner')
              ? showModalChangeName
              : undefined
          }
          onEditLabel="global.edit"
          testId="name"
        >
          {group?.name}
        </Setting>

        {/* Created */}
        <Setting
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.general.created' })}
          testId="created"
        >
          {group.time && (
            <FormattedDate value={group.time.creation} year="numeric" month="long" day="2-digit" />
          )}
        </Setting>

        {/* Description */}
        <Setting
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.general.description' })}
          onEdit={
            userPermissions.includes('admin') || userPermissions.includes('owner')
              ? showModalChangeDescription
              : undefined
          }
          onEditLabel="global.edit"
          testId="description"
        >
          <LineEllipsis>
            {group?.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
          </LineEllipsis>
        </Setting>

        {/* Tags */}
        <SettingTags groupId={groupId} testId="group" />
      </div>
    </>
  );
};

export default GeneralView;
