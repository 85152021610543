import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from '_common/hooks';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { useGetInstalledTemplatesListQuery } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';

import { PageLayoutField, RadioButtonsField } from '_common/components';
import InputSelectField from '../InputSelectField/InputSelectField';

import styles from './PageSize.module.scss';

import type { InputSelectFieldValue, SizeValues, RadioButtonValue } from '../PageSetupModal';
import type { PageLayoutOption } from '_common/components/PageLayoutField/PageLayoutSelect/PageLayoutSelect';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

type PageSizeProps = {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  applyTo: {
    handleChange: React.Dispatch<React.SetStateAction<RadioButtonValue>>;
    applyToOptions: RadioButtonValue[];
    applyToValue: RadioButtonValue;
  };
  sizeValues: SizeValues;
  setSizeValues: React.Dispatch<React.SetStateAction<SizeValues>>;
  pageOrientation: {
    handleChange: React.Dispatch<React.SetStateAction<RadioButtonValue>>;
    pageOrientationOptions: RadioButtonValue[];
    pageOrientationValue: RadioButtonValue;
  };
  sectionStart: string;
  setSectionStart: React.Dispatch<React.SetStateAction<'np' | 'c' | 'op' | 'ep'>>;
  isDisabled: boolean;
};

const PageSize = ({
  setDisabled,
  applyTo,
  sizeValues,
  setSizeValues,
  pageOrientation,
  sectionStart,
  setSectionStart,
  isDisabled,
}: PageSizeProps) => {
  const intl = useIntl();
  const doc = useSelector(getDocumentObject);
  const sectionTemplate = useSelector(
    (state) => state.modals['PageSetupModal'].properties.template,
  );
  const { objectTemplate: template } = useGetInstalledTemplatesListQuery(
    { errorsExpected: [403, 404] },
    {
      selectFromResult: (result) => ({
        ...result,
        objectTemplate: result.data?.find((docTemplate) => docTemplate.id === doc?.template),
      }),
    },
  );

  const [pageSize, setPageSize] = useState<PageLayoutOption>();
  const portraitValues = pageOrientation.pageOrientationOptions[0];
  const landscapeValues = pageOrientation.pageOrientationOptions[1];

  const pageWidthValues = useMemo(
    () => ({
      value: sizeValues.w.value,
      unit: sizeValues.w.unit,
    }),
    [sizeValues.w],
  );
  const pageHeighthValues = useMemo(
    () => ({
      value: sizeValues.h.value,
      unit: sizeValues.h.unit,
    }),
    [sizeValues.h],
  );

  const setPageHeightValues = (h: InputSelectFieldValue) => {
    const heightValue =
      h.unit === 'cm' ? h.value : EditorDOMUtils.convertUnitTo(h.value, h.unit, 'cm', 3);
    const widthValue =
      sizeValues.w.unit === 'cm'
        ? sizeValues.w.value
        : EditorDOMUtils.convertUnitTo(sizeValues.w.value, sizeValues.w.unit, 'cm', 3);
    if (heightValue < widthValue) {
      pageOrientation.handleChange(landscapeValues);
    } else {
      pageOrientation.handleChange(portraitValues);
    }

    if (sectionStart === 'c') {
      setSectionStart('np');
    }
    setSizeValues({
      ...sizeValues,
      h,
    });
  };

  const setPageWidthValues = (w: InputSelectFieldValue) => {
    const widthValue =
      w.unit === 'cm' ? w.value : EditorDOMUtils.convertUnitTo(w.value, w.unit, 'cm', 3);
    const heightValue =
      sizeValues.h.unit === 'cm'
        ? sizeValues.h.value
        : EditorDOMUtils.convertUnitTo(sizeValues.h.value, sizeValues.h.unit, 'cm', 3);
    if (widthValue > heightValue) {
      pageOrientation.handleChange(landscapeValues);
    } else {
      pageOrientation.handleChange(portraitValues);
    }
    if (sectionStart === 'c') {
      setSectionStart('np');
    }
    setSizeValues({
      ...sizeValues,
      w,
    });
  };

  const handleOrientationChange = (v: RadioButtonValue) => {
    setSizeValues({
      h: pageWidthValues,
      w: pageHeighthValues,
    });

    pageOrientation.handleChange(v);
  };

  const getExtraTemplates = () => {
    if (template) {
      return {
        [template.id]: {
          value: template.id,
          label: `${intl.formatMessage({ id: 'CUSTOM' })} - ${template.name}`,
          width: {
            value: sectionTemplate?.sz.w,
            unit: 'pt',
          },
          height: {
            value: sectionTemplate?.sz.h,
            unit: 'pt',
          },
        },
      };
    }

    return undefined;
  };

  return (
    <div>
      <PageLayoutField
        fullWidth
        testId={{
          inputField: 'page-size',
          select: 'page-size',
        }}
        type="size"
        onChange={(option) => {
          setPageSize(option);
          setSizeValues({
            w: {
              value: `${option.width?.value}` || sizeValues.w.value,
              //@ts-expect-error - option.width.unit does yet have Common.Utils.MeasureUnit typing
              unit: option.width?.unit || sizeValues.w.unit,
            },
            h: {
              value: `${option.height?.value}` || sizeValues.h.value,
              //@ts-expect-error - option.height.unit does yet have Common.Utils.MeasureUnit typing
              unit: option.height?.unit || sizeValues.h.unit,
            },
          });
        }}
        value={{
          value: pageSize?.value || 'a4',
          label: pageSize?.label,
          width: { value: +pageWidthValues.value, unit: pageWidthValues.unit },
          height: { value: +pageHeighthValues.value, unit: pageHeighthValues.unit },
        }}
        newTemplates={getExtraTemplates()}
        margin="3rem 0 0 0"
        disabled={isDisabled}
      />
      <div className={styles.inputsContainer}>
        <InputSelectField
          values={pageWidthValues}
          setValues={setPageWidthValues}
          label={intl.formatMessage({ id: 'PAGE_WIDTH' })}
          setDisabled={setDisabled}
          isDisabled={isDisabled}
        />
        <InputSelectField
          values={pageHeighthValues}
          setValues={setPageHeightValues}
          label={intl.formatMessage({ id: 'PAGE_HEIGHT' })}
          setDisabled={setDisabled}
          isDisabled={isDisabled}
        />
      </div>
      <RadioButtonsField
        options={pageOrientation.pageOrientationOptions}
        value={pageOrientation.pageOrientationValue}
        setValue={handleOrientationChange}
        label="PAGE_ORIENTATION"
        margin="0 0 3rem 0"
        testId="page-orientation"
        isDisabled={isDisabled}
      />
      <RadioButtonsField
        options={applyTo.applyToOptions}
        value={applyTo.applyToValue}
        setValue={applyTo.handleChange}
        label="APLLY_TO"
        testId="apply-to"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default PageSize;
