import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';
import styles from './Header.module.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOrderIcon(column) {
    const { order } = this.props;
    let show = false;
    let type;
    let icon;
    if (column.order) {
      type = column.order.type;
      icon = type === 'asc' ? 'ElementListUpGrey' : 'ElementListDownGrey';
      if (column.order.field === order.field) {
        show = true;
        icon = order.type === 'asc' ? 'ElementListUpGrey' : 'ElementListDownGrey';
        type = order.type === 'asc' ? 'desc' : 'asc';
      }
    }
    return (
      <span
        onClick={
          column.order
            ? () => {
                this.props.updateOrder({
                  order_field: column.order.field,
                  order_type: type,
                });
              }
            : () => {}
        }
        className={column.order ? styles.cursor : undefined}
      >
        {column.value !== 'moreOptions' && (
          <FormattedMessage id={`storage.browserHeader.${column.value}`} />
        )}
        {column.order && (
          <span className={`${styles.icon} ${show ? styles.show : undefined}`}>
            <Icon icon={icon} size={24} />
          </span>
        )}
      </span>
    );
  }

  render() {
    const { columns } = this.props;
    return (
      <div className={styles.root}>
        <div className={`${styles.header} ${styles.flex}`}>
          {this.handleOrderIcon({
            value: 'name',
            order: { type: 'asc', field: 'name' },
          })}
        </div>
        {columns.map((column) => (
          <div key={column.value} style={{ width: `${column.size}rem` }} className={styles.header}>
            {this.handleOrderIcon(column)}
          </div>
        ))}
      </div>
    );
  }
}

export default injectIntl(Header);
