import { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import { selectFilteredComments } from 'Editor/redux/CommentsSlice';
import {
  selectFilteredCommentsActive,
  selectIsPageLayout,
  selectReadOnlyMode,
  selectUser,
} from 'Editor/redux/EditorStatusSlice';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState } from '_common/components';
import { VirtualizedList } from '_common/suite/components/Card';
import { VirtualizedListProps } from '_common/suite/components/Card/VirtualizedList/VirtualizedList';
import CommentCard from 'Editor/components/CommentCard';

import styles from './CommentList.module.scss';
import interactionControllerStyles from '_common/components/OnboardingOverlay/InteractionController.module.scss';

const CommentList = () => {
  const intl = useIntl();

  const containerRef = useRef<HTMLDivElement>(null);

  const user = useSelector(selectUser);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const comments = useSelector(selectFilteredComments);
  const commentsActive = useSelector(selectFilteredCommentsActive);
  const hideAllComments = useSelector((state) => state.editor.comments.hideAll);
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorCommentPanel),
  );

  useEffect(() => {
    if (comments.insert.inserting && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [comments.insert.inserting]);

  //Selected index will be the first to be found
  const selectedCommentIndex = useMemo(
    () => comments.order.findIndex((itId) => commentsActive.includes(itId)),
    [commentsActive],
  );

  const renderEmptyState = () => (
    <div className={styles.emptyView}>
      <EmptyState
        size="medium"
        title={intl.formatMessage({ id: 'NO_COMMENTS_FOUND' })}
        testId="no-comments-found"
      >
        <FormattedMessage id="NO_COMMENTS_FOUND_MESSAGE" />
      </EmptyState>
    </div>
  );

  const itemRenderer: VirtualizedListProps['itemRenderer'] = ({ index }) => {
    const commentId = comments.order[index];

    return (
      <CommentCard
        sidebar
        user={user.id}
        selected={commentsActive.includes(commentId)}
        comment={comments.comments[commentId]}
        isReadOnlyMode={isReadOnlyMode || isPageLayout}
      />
    );
  };

  if (comments.order.length === 0 && !comments.insert.inserting) {
    if (hasActiveFilters) {
      return <EmptyFilteredState identity="editorCommentPanel" size="medium" />;
    }
    return renderEmptyState();
  } else if (hideAllComments) {
    return renderEmptyState();
  }

  return (
    <>
      <div
        id={interactionControllerStyles.skipControl}
        className={styles.root}
        ref={containerRef}
        data-testid="comments-list"
      >
        {comments.insert.inserting && (
          <div style={{ padding: '0rem 1rem' }}>
            <CommentCard isTemporary sidebar user={user.id} />
          </div>
        )}
        {
          <VirtualizedList
            itemCount={comments.order.length}
            selectedIndex={selectedCommentIndex}
            itemMinHeight={142}
            itemRenderer={itemRenderer}
          />
        }
      </div>
    </>
  );
};

export default CommentList;
