import { useIntl } from 'react-intl';
import { Tooltip } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { selectIsPageLayout, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';

import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';

type PageLayoutTooltipProps = Omit<TooltipProps, 'content'> & {
  content?: string;
  type: 'comment' | 'trackedAction' | 'task' | 'reference' | 'note';
};

const PageLayoutTooltip = ({ content, disabled, type, ...props }: PageLayoutTooltipProps) => {
  const intl = useIntl();
  const isPageLayout = useSelector(selectIsPageLayout);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);

  const getTooltipContent = () => {
    switch (type) {
      case 'comment':
        return 'CANNOT_MAKE_CHANGES_TO_COMMENTS_IN_PAGE_LAYOUT';
      case 'trackedAction':
        return 'CANNOT_MAKE_CHANGES_TO_TRACK_CHANGES_IN_PAGE_LAYOUT';
      case 'task':
        return 'CANNOT_MAKE_CHANGES_TO_TASKS_IN_PAGE_LAYOUT';
      case 'reference':
        return 'CANNOT_MAKE_CHANGES_TO_REFERENCES_IN_PAGE_LAYOUT';
      case 'note':
        return 'CANNOT_MAKE_CHANGES_TO_NOTES_IN_PAGE_LAYOUT';
      default:
        return '';
    }
  };

  return (
    <Tooltip
      {...props}
      disabled={disabled !== undefined ? disabled : isReadOnlyMode || (!isPageLayout && !content)}
      content={isPageLayout ? intl.formatMessage({ id: getTooltipContent() }) : content || ''}
    />
  );
};

export default PageLayoutTooltip;
