import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { EditorService } from '_common/services';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import SynonymGroup from './SynonymGroup/SynonymGroup';

import styles from './Synonyms.module.scss';

const MODAL = 'SynonymsModal';
const SynonymsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const query = useSelector((state) => state.modals[MODAL].query) as string;
  const isPageLayout = useSelector(selectIsPageLayout);

  const [list, setList] = useState<Synonym[]>([]);

  useEffect(() => {
    const findSynonyms = async () => {
      new EditorService({ errorsExpected: [404] })
        .findSynonyms(query)
        .then(({ data }) => {
          setList(data.response);
        })
        .catch((e) => {
          if (e?.response?.status === 404) {
            notify({
              type: 'warning',
              title: 'NO_SYNONYMS_FOUND',
              message: 'WE_COULD_NOT_FIND_ANY_SYNONYMS_OF_THE_SELECTION',
            });
          }

          global.logger.captureMessage('[SynonymsModal] Error in finding synonyms', {
            extra: e,
          });

          close();
        });
    };

    findSynonyms();
  }, [query]);

  const [selectedValue, setSelectedValue] = useState<{ id: string; value: string }>({
    id: '',
    value: '',
  });

  const handleChangeSelectedValue = (id: string, value: string) => {
    setSelectedValue({ id, value });
  };

  const saveChanges = () => {
    const manager = EditorManager.getInstance();
    manager.insertSynonym(selectedValue.value.trim());

    notify({
      type: 'success',
      title: 'SYNONYM_APPLIED',
      message: 'WORD_WAS_SUCCESSFULLY_REPLACED_BY_SYNONYM',
    });

    close();
  };

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  if (list?.length > 0) {
    return (
      <Modal width="75rem" open={!!isOpen} onClose={close} testId="synonyms">
        <Modal.Header onClose={close}>
          <FormattedMessage id="editor.synonyms.header" />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.container}>
            {list.map((group, index) => (
              <SynonymGroup
                key={`synonyms-group-${index}`}
                index={index}
                query={query}
                group={group}
                onChange={handleChangeSelectedValue}
                selectedId={selectedValue.id}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="medium" onClick={close} testId="synonyms-close-button">
            <FormattedMessage id="global.close" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            onClick={saveChanges}
            disabled={isPageLayout || selectedValue.id === ''}
            testId="synonyms-submit-button"
          >
            <FormattedMessage id="global.replace" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return null;
  }
};

export default SynonymsModal;
