/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Doc } from 'sharedb';
import { PDFDocument, Annotations, PageAnnotations, PDFPage, PDFStructure } from '../../models';
import { RealtimeObject, DoPDFDocument, UndoManager } from '_common/services/Realtime';
import { BaseController } from '../BaseController';

export const TYPE_NAME = {
  DOCUMENT: 'DOCUMENT',
  PDFDOCUMENT: 'PDFDOCUMENT',
  PDFSTRUCTURE: 'PDFSTRUCTURE',
  ANNOTATIONS: 'ANNOTATIONS',
  PAGE_ANNOTATIONS: 'PAGE_ANNOTATIONS',
  PAGE: 'PAGE',
} as const;

export interface Types {
  [TYPE_NAME.DOCUMENT]: DoPDFDocument;
  [TYPE_NAME.PDFDOCUMENT]: PDFDocument;
  [TYPE_NAME.PDFSTRUCTURE]: PDFStructure;
  [TYPE_NAME.ANNOTATIONS]: Annotations;
  [TYPE_NAME.PAGE_ANNOTATIONS]: PageAnnotations;
  [TYPE_NAME.PAGE]: PDFPage;
}
export type TypeName = keyof Types;

type ModelsState = {
  [index in TypeName]: {
    [x: string]: Types[index];
  };
};

export class ModelsController extends BaseController {
  protected models: ModelsState;
  undoManager?: Realtime.Core.UndoManager;
  constructor(Data: PDF.Data.State) {
    super(Data);
    this.models = {
      [TYPE_NAME.DOCUMENT]: {},
      [TYPE_NAME.PDFDOCUMENT]: {},
      [TYPE_NAME.PDFSTRUCTURE]: {},
      [TYPE_NAME.ANNOTATIONS]: {},
      [TYPE_NAME.PAGE_ANNOTATIONS]: {},
      [TYPE_NAME.PAGE]: {},
    };
  }

  start(): void {
    this.undoManager = new UndoManager({
      autoPatch: true,
    });
  }

  stop(): void {}

  destroy(): void {
    // dispose models
    const modelKeys = Object.keys(this.models);
    for (let i = 0; i < modelKeys.length; i++) {
      const modelType: TypeName = modelKeys[i] as TypeName;

      const idKeys = Object.keys(this.models[modelType]);
      for (let j = 0; j < idKeys.length; j++) {
        const id: string = idKeys[j];

        this.models[modelType][id]?.dispose();
        delete this.models[modelType][id];
      }
    }
  }

  get TYPE_NAME() {
    return TYPE_NAME;
  }

  private fetchModel<T extends TypeName, R extends Types[T]>(
    type: T,
    id: Realtime.Core.RealtimeObjectId,
    ...args: unknown[]
  ): R {
    let model;
    switch (type) {
      case TYPE_NAME.DOCUMENT:
        model = new DoPDFDocument(
          this.Data.transport,
          id as string,
          args[0] as Realtime.Core.Document.Data,
        ) as R;
        model.fetch();
        break;
      case TYPE_NAME.PDFDOCUMENT:
        model = new PDFDocument(this.Data.transport, id as string) as R;
        model.fetch();
        break;
      case TYPE_NAME.ANNOTATIONS:
        model = new Annotations(this.Data.transport, id, this.undoManager) as R;
        break;
      case TYPE_NAME.PDFSTRUCTURE:
        model = new PDFStructure(this.Data.transport, id) as R;
        break;
      default:
        throw new Error(`Unsupported model type : ${type}`);
    }
    if (model instanceof RealtimeObject) {
      model.subscribe();
    }
    return model as R;
  }

  get<T extends TypeName, R extends Types[T]>(
    type: T,
    id?: Realtime.Core.RealtimeObjectId,
    ...args: unknown[]
  ): R {
    let _id: string = '';
    if (id == null) {
      throw new Error('Invalid id value: ' + id);
    }
    if (typeof id === 'string') {
      _id = id;
    } else if (!(id instanceof String) && (id as Doc).id) {
      _id = (id as Doc).id;
    }
    if (!this.models[type][_id]) {
      // @ts-ignore
      this.models[type][_id] = this.fetchModel(type, id, ...args);
    }
    return this.models[type][_id] as R;
  }

  getPageAnnotations(id: Realtime.Core.RealtimeObjectId, pageNumber: number) {
    let annotations = new PageAnnotations(this.Data.transport, id, pageNumber);
    annotations.subscribe();
    return annotations;
  }

  async getPage(pageNumber: number) {
    if (!this.models.PAGE[pageNumber]) {
      let document = await this.get('PDFDOCUMENT', this.Data.context.documentId);
      let nativePage = await document.getPage(pageNumber);
      if (nativePage) {
        this.models.PAGE[pageNumber] = new PDFPage(`${pageNumber}`, nativePage);
      } else {
        throw new Error(`Page with id : ${pageNumber} not found.`);
      }
    }
    return this.models.PAGE[pageNumber];
  }

  setModelsVersion(version: ApiSchemas['VersionsSchema'] | null) {
    const processing = [];
    const annotations = Object.keys(this.models.ANNOTATIONS);
    for (let index = 0; index < annotations.length; index++) {
      processing.push(this.models.ANNOTATIONS[annotations[index]].setVersion(version));
    }
    return Promise.all(processing);
  }

  disposeModel<T extends TypeName>(type: T, id: string | undefined) {
    if (id != null && this.models[type][id]) {
      this.models[type][id].dispose();
      delete this.models[type][id];
    }
  }
}
