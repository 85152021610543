import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

type EmptyViewProps = { type: string; searchValue: string };

const EmptyView = ({ type, searchValue }: EmptyViewProps) => {
  const intl = useIntl();

  const getIntlTitle = () => {
    if (type === 'footnotes') {
      return searchValue === '' ? 'NO_FOOTNOTES_IN_DOCUMENT_TITLE' : 'NO_FOOTNOTES_FOUND_TITLE';
    }
    return searchValue === '' ? 'NO_ENDNOTES_IN_DOCUMENT_TITLE' : 'NO_ENDNOTES_FOUND_TITLE';
  };
  const getIntlDescription = () => {
    if (type === 'footnotes') {
      return searchValue === ''
        ? 'NO_FOOTNOTES_IN_DOCUMENT_DESCRIPTION'
        : 'NO_FOOTNOTES_FOUND_DESCRIPTION';
    }
    return searchValue === ''
      ? 'NO_ENDNOTES_IN_DOCUMENT_DESCRIPTION'
      : 'NO_ENDNOTES_FOUND_DESCRIPTION';
  };
  return (
    <EmptyState size="medium" title={intl.formatMessage({ id: getIntlTitle() })} testId="notes-panel-empty-view">
      <FormattedMessage id={getIntlDescription()} values={{ search_value: searchValue }} />
    </EmptyState>
  );
};

export default EmptyView;
