import { NodeUtils } from 'Editor/services/DataManager';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

export class DeleteCellsOperation extends BaseOperation<Editor.Data.Node.Model> {
  shiftCells: Editor.Edition.ShiftCellsType;
  tableId: string;
  selectedCells: Editor.Edition.CellInfo[];
  pageWidth?: number;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
    shiftCells: Editor.Edition.ShiftCellsType,
    pageWidth: number | undefined,
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.shiftCells = shiftCells;
    this.pageWidth = pageWidth;
    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.tableId && this.selectedCells.length > 0 && this.pageWidth) {
      if (this.shiftCells !== 'SHIFT_LEFT' && this.shiftCells !== 'SHIFT_UP') {
        this.shiftCells = 'SHIFT_LEFT';
      }

      const headCells: Editor.Edition.CellInfo[] = [];
      const cellIndexes: number[] = [];
      const rowIndexes: number[] = [];
      const cellsToDelete: string[] = [];

      const baseData = this.model.selectedData();

      if (!baseData) {
        return this;
      }

      const tableInfo: {
        data: Editor.Data.Node.Data;
        path: Realtime.Core.RealtimePath;
      } = this.model.getChildInfoById(this.tableId);

      const table: Editor.Data.Node.Data = tableInfo.data;
      const tablePath = tableInfo.path;
      const tbody = table.childNodes?.[0];

      // get indexes and head cells
      for (let i = 0; i < this.selectedCells.length; i++) {
        let selectedCell = this.selectedCells[i];

        const selectedCellData: {
          data: Editor.Data.Node.Data;
          path: Realtime.Core.RealtimePath;
        } = this.model.getChildInfoById(selectedCell.id);

        if (
          tbody &&
          selectedCell &&
          selectedCell.sectionRowIndex != null &&
          (selectedCell.colSpan > 1 || selectedCell.rowSpan > 1) &&
          !headCells.includes(selectedCell)
        ) {
          headCells.push(selectedCell);

          for (let r = 0; r < selectedCell.rowSpan; r++) {
            const rowIndex = selectedCell.sectionRowIndex + r;

            for (let c = 0; c < selectedCell.colSpan; c++) {
              const cellIndex = selectedCell.cellIndex + c;

              const cell = tbody.childNodes?.[rowIndex].childNodes?.[cellIndex];

              if (!cellIndexes.includes(cellIndex) && cell != null) {
                cellIndexes.push(cellIndex);
              }
              if (!rowIndexes.includes(rowIndex) && cell != null) {
                rowIndexes.push(rowIndex);
              }
              if (cell != null && cell.id && !cellsToDelete.includes(cell.id)) {
                cellsToDelete.push(cell.id);
              }
            }
          }
        } else {
          if (!cellIndexes.includes(selectedCell.cellIndex)) {
            cellIndexes.push(selectedCell.cellIndex);
          }

          if (selectedCellData.data.id && !cellsToDelete.includes(selectedCellData.data.id)) {
            cellsToDelete.push(selectedCellData.data.id);
          }

          const rowIndex = selectedCell.sectionRowIndex;

          if (rowIndex != null && !rowIndexes.includes(rowIndex)) {
            rowIndexes.push(rowIndex);
          }
        }
      }

      cellIndexes.sort((a, b) => {
        return a - b;
      });

      rowIndexes.sort((a, b) => {
        return a - b;
      });

      if (NodeUtils.isTableData(table) && tbody) {
        // build ops to remove cells

        const rows = tbody.childNodes;

        let rowOffsets: any = {};

        const columnCellsDeleted: { [index: number]: number } = {};

        if (rows) {
          for (let i = 0; i < cellIndexes.length; i++) {
            const cellIndex = cellIndexes[i];

            for (let j = 0; j < rows.length; j++) {
              const cell = rows[j]?.childNodes?.[cellIndex];

              if (!columnCellsDeleted[i]) {
                columnCellsDeleted[i] = 0;
              }

              if (cell && cell.id) {
                if (cellsToDelete.includes(cell.id) && rowIndexes.includes(j)) {
                  columnCellsDeleted[i] += 1;

                  const rowIndex = j;
                  if (rowOffsets[rowIndex] == null) {
                    rowOffsets[rowIndex] = 0;
                  }

                  // remove cell
                  const cellPath = this.model.findPath(this.model.KEYS.ID, cell.id);
                  cellPath.pop(); // remove path id

                  // adjust cell path based on delete offset
                  // i works has cell offset
                  cellPath[cellPath.length - 1] =
                    +cellPath[cellPath.length - 1] - rowOffsets[rowIndex];

                  this.ops.push(RealtimeOpsBuilder.listDelete(cell, cellPath));
                  rowOffsets[rowIndex] += 1;
                }
              } else {
                columnCellsDeleted[i] += 1;
              }
            }
          }

          // check empty rows
          let deletedRows = 0;
          for (let j = 0; j < rows.length; j++) {
            if (rows[j] && rows[j].childNodes?.length === rowOffsets[j]) {
              // remove row
              const rowId = rows[j].id;
              if (rowId) {
                const rowData = this.model.getChildDataById(rowId);
                rowData.childNodes = []; // to avoid wrong undo operation

                const rowPath = this.model.findPath(this.model.KEYS.ID, rowId);
                rowPath.pop(); // remove path id

                // adjust row path based on delete offset
                rowPath[rowPath.length - 1] = +rowPath[rowPath.length - 1] - deletedRows;

                this.ops.push(RealtimeOpsBuilder.listDelete(rowData, rowPath));

                deletedRows += 1;
              }
            }
          }

          // SHIFT UP option
          if (
            rows.length !== rowIndexes.length &&
            this.shiftCells === 'SHIFT_UP' &&
            cellsToDelete.length === rowIndexes.length * cellIndexes.length
          ) {
            for (
              let j = rowIndexes[rowIndexes.length - 1] + 1, offset = 0;
              j < rows.length;
              j++, offset++
            ) {
              for (let i = 0; i < cellIndexes.length; i++) {
                const cellIndex = cellIndexes[i];

                const row = rows[j];
                const cell = row?.childNodes?.[cellIndex];

                if (cell && cell.id) {
                  const cellData = this.model.getChildDataById(cell.id);

                  // remove cell
                  const cellPath = this.model.findPath(this.model.KEYS.ID, cell.id);
                  cellPath.pop(); // remove path id

                  // adjust cell path based on delete offset
                  // i works has cell offset
                  cellPath[cellPath.length - 1] = +cellPath[cellPath.length - 1] - i;

                  this.ops.push(RealtimeOpsBuilder.listDelete(cellData, cellPath));

                  this.ops.push(
                    RealtimeOpsBuilder.listInsert(cellData, [
                      ...tablePath,
                      this.model.KEYS.CHILDNODES,
                      0, // tbody
                      this.model.KEYS.CHILDNODES,
                      rowIndexes[0] + offset, // row
                      this.model.KEYS.CHILDNODES,
                      cellIndex, // cell
                    ]),
                  );
                }
              }
            }
          }

          // check deleted columns and update table width
          const deletedIndexes: number[] = [];
          const columnIndexes = Object.keys(columnCellsDeleted);
          for (let i = 0; i < columnIndexes.length; i++) {
            const index = +columnIndexes[i];

            if (columnCellsDeleted[index] === rows.length) {
              deletedIndexes.push(index);
            }
          }

          if (deletedIndexes.length) {
            const columnsWeights = TableUtils.findColumnsWeights(
              table,
              deletedIndexes,
              this.pageWidth,
            );
            const tableWidth = table.properties?.w;

            let op;
            const indexes = Object.keys(columnsWeights);
            if (
              tableWidth &&
              (tableWidth.t === 'abs' || tableWidth.t === 'pct') &&
              this.model.id === this.tableId &&
              indexes.length > 0
            ) {
              const newTableWidth = {
                t: tableWidth.t,
                v: tableWidth.v,
              };

              for (let i = 0; i < indexes.length; i++) {
                newTableWidth.v = +(
                  newTableWidth.v -
                  newTableWidth.v * columnsWeights[+indexes[i]]
                ).toFixed(3);
              }

              op = this.getObjectOperationforPathValue(tableWidth, newTableWidth, [
                ...tablePath,
                this.model.KEYS.PROPERTIES,
                'w',
              ]);
            } else if (!tableWidth || tableWidth.t !== 'auto') {
              op = this.getObjectOperationforPathValue(tableWidth, { t: 'auto', v: 0 }, [
                ...tablePath,
                this.model.KEYS.PROPERTIES,
                'w',
              ]);
            }

            if (op) {
              this.ops.push(op);
            }
          }
        }
      }
    }

    return this;
  }
}
