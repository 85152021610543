import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import styles from './ExtensionErrorsModal.module.scss';
import {
  useGetTemplatesListQuery,
  useInstallAllTemplatesMutation,
} from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';

const MODAL = 'ExtensionErrorsModal';

const ExtensionErrorsModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const list = useSelector((state) => state.modals[MODAL].list) as Template['id'][];
  const updating = useSelector((state) => state.modals[MODAL].updating);

  const { data: templates } = useGetTemplatesListQuery();
  const [installAllTemplates] = useInstallAllTemplatesMutation();

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const tryAgain = () => {
    close();
    installAllTemplates({ idList: list, updating });
  };

  if (!isOpen || !templates) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={close} type="error" width="60rem" testId="extension-errors">
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={updating ? 'EXTENSION_UPDATING_ERROR_TITLE' : 'EXTENSION_INSTALLING_ERROR_TITLE'}
          values={{ template: templates.extensions[list[0]].name }}
        />
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id={updating ? 'EXTENSION_UPDATING_ERROR_MESSAGE' : 'EXTENSION_INSTALLING_ERROR_MESSAGE'}
          values={{ template: templates.extensions[list[0]].name }}
        >
          {(text) => <div>{text}</div>}
        </FormattedMessage>
        {list.length > 0 && (
          <ul className={styles.list}>
            {list.map((id) => (
              <li key={id} className={styles.item}>
                {templates.extensions[id].name}
              </li>
            ))}
          </ul>
        )}
        <FormattedMessage id="EXTENSION_TRY_AGAIN_CLOSE" />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="extension-errors-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button size="medium" variant="primary" onClick={tryAgain} testId="extension-errors-submit-button">
          <FormattedMessage id="TRY_AGAIN" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtensionErrorsModal;
