import { Card } from '_common/suite/components';
import NoteDescription from '_common/suite/components/Card/NoteCard/Description/Description';
import NoteTitle from '_common/suite/components/Card/NoteCard/Title/Title';
import { Button } from 'dodoc-design-system';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';
import { ButtonProps } from 'dodoc-design-system/build/types/Components/Button/Button';
import { setNoteOverlayData } from 'Editor/redux/NotesSlice';
import { useDispatch, useIsNodeClamped } from '_common/hooks';
import { FormattedMessage } from 'react-intl';
import EditorManager from 'Editor/services/EditorManager';
import { Ref, forwardRef, useLayoutEffect, useRef, useState } from 'react';

type ViewNoteCardProps = {
  note: Editor.Note;
  sidebar?: boolean;
  selected?: boolean;
  testId: string;
  isReadOnlyMode?: boolean;
  doc: Objekt;
};

const ViewNoteCard = forwardRef(
  (
    { note, sidebar, selected, testId, isReadOnlyMode, doc }: ViewNoteCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const dispatch = useDispatch();
    const canEdit = doc.user_permissions.includes('owner') || doc.user_permissions.includes('edit');
    const manager = EditorManager.getInstance();
    const [readMore, setReadMore] = useState(true);
    const contentRef = useRef<HTMLSpanElement>(null);
    const isClamped = useIsNodeClamped({
      ref: contentRef,
      clamp: 3,
      dependencies: [note.content],
    });

    useLayoutEffect(() => {
      if (!isClamped) {
        setReadMore(false);
      }
    }, [isClamped]);

    const select = () => {
      if (selected) {
        return;
      }

      manager.viewNoteCard(note.id, note.type);
    };

    const remove: ButtonProps<'small'>['onClick'] = (e) => {
      e.stopPropagation();
      if (selected) {
        dispatch(setNoteOverlayData({ offsets: null, selected: null }));
      }
      manager.deleteNote(note.id);
    };
    const edit: ButtonProps<'small'>['onClick'] = (e) => {
      e.stopPropagation();
      manager.viewNoteCard(note.id, note.type, true);
    };

    return (
      <Card
        testId={testId}
        width={sidebar ? '100%' : '43rem'}
        selected={selected}
        sidebar={sidebar}
        onClick={sidebar ? select : () => {}}
        ref={ref}
      >
        <Card.Header>
          <NoteTitle type={note.type} order={note.order} />
        </Card.Header>
        <Card.Body>
          <NoteDescription
            content={note.content}
            contentRef={contentRef}
            isClamped={isClamped ?? false}
            readMore={readMore}
            setReadMore={setReadMore}
          />
        </Card.Body>
        <Card.Footer>
          <Card.Footer.Right>
            <PageLayoutTooltip type="note" testId={`note-card-${note.id}-remove-tooltip`}>
              <Button
                variant="link"
                disabled={isReadOnlyMode}
                size="small"
                onClick={remove}
                testId={`note-card-${note.id}-remove-button`}
              >
                <FormattedMessage id="global.remove" />
              </Button>
            </PageLayoutTooltip>
            <PageLayoutTooltip type="note" testId={`note-card-${note.id}-edit-tooltip`}>
              <Button
                variant="link"
                disabled={isReadOnlyMode || !canEdit}
                size="small"
                onClick={edit}
                testId={`note-card-${note.id}-edit-button`}
              >
                <FormattedMessage id="global.edit" />
              </Button>
            </PageLayoutTooltip>
          </Card.Footer.Right>
        </Card.Footer>
      </Card>
    );
  },
);

export default ViewNoteCard;
