/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { uniq, remove } from 'lodash';
import BaseController from '../BaseController';

export class UsersController extends BaseController {
  user?: Realtime.Core.User;

  start(documentId: string, document: Realtime.Core.Document.Data, user: Realtime.Core.User): void {
    this.user = user;

    this.registerTransportEvents({
      'DOCUMENT:USERS:STATE': this._handleEventDocumentUsersState.bind(this),
      'USER:JOINED': this._handleEventUsersJoined.bind(this),
      'USER:LEFT': this._handleEventUsersLeft.bind(this),
    });

    this.Data.transport.dispatchEvent('DOCUMENT:USERS:STATE', {
      document: documentId,
    });
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
  }

  _handleEventDocumentUsersState(event: { success: boolean; users: string[] }) {
    const users = uniq(event.users);
    remove(users, (n: string) => n === this.user?.id);
    this.Data.events?.emit('LOAD_USERS_ONLINE', users);
  }

  _handleEventUsersJoined(event: { success: boolean; user: string }) {
    if (event.user !== this.user?.id) {
      this.Data.events?.emit('USER_JOINED', event.user);
    }
  }

  _handleEventUsersLeft(event: { success: boolean; user: string }) {
    if (event.user !== this.user?.id) {
      this.Data.events?.emit('USER_LEFT', event.user);
    }
  }

  get loggedUserId() {
    return this.user?.id;
  }

  isLoggedUser(userId: string) {
    return this.user?.id === userId;
  }
}
