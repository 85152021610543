import { useMemo, useState } from 'react';
import { Button, Checkbox, Icon, Input, InputField, Modal, Select } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { closeModal } from 'App/ModalContext/utils';
import { DATA_TYPE_TRANSLATIONS } from 'Settings/pages/TenantSettingsPage/Metadata/const';
import SelectOptionForm from './SelectOptionForm';
import useMetadataNameValidation from '_common/hooks/useMetadataNameValidation';
import { useCreateMetadataMutation, useEditMetadataMutation } from 'App/redux/MetadataApi';
import { notify } from '_common/components/ToastSystem';
import { useModalData } from 'App/ModalContext/ModalContext';
import styles from './ManualMetadataModal.module.scss';

const TRANSLATIONS = {
  header: {
    create: 'CREATE_MANUAL_METADATA',
    edit: 'EDIT_MANUAL_METADATA',
    options: 'EDIT_SELECT_OPTIONS',
  },
  submit: {
    create: 'global.create',
    edit: 'global.save',
    options: 'global.save',
  },
};

const CHAR_LIMIT = {
  min: 1,
  max: 1500,
} as const;

const dataTypes = (
  Object.keys(DATA_TYPE_TRANSLATIONS) as Array<keyof typeof DATA_TYPE_TRANSLATIONS>
).map((key) => ({
  value: key,
  label: DATA_TYPE_TRANSLATIONS[key],
}));

const ManualMetadataModal = () => {
  const intl = useIntl();
  const data = useModalData('ManualMetadataModal');
  const [name, setName] = useState(data?.metadata?.name ?? '');
  const validation = useMetadataNameValidation(name);
  const [dataType, setDataType] = useState<(typeof dataTypes)[number] | null>(
    dataTypes.find(({ value }) => value === data?.metadata?.data_type) ?? null,
  );
  const [required, setRequired] = useState(data?.metadata?.required ?? true);
  //@ts-expect-error Not yet supported in the API side
  const [exportVeeva, setExportVeeva] = useState(data?.metadata?.confirm_pre_export ?? false);
  const [extra, setExtra] = useState({
    char_limit: data?.metadata?.data_type === 'string' ? `${data.metadata.char_limit}` : '',
    searchable: data?.metadata?.data_type === 'string' && (data.metadata.searchable ?? false),
  });
  const [options, setOptions] = useState<ApiSchemas['MetadataSelectDataType']['options']>(
    data?.metadata.data_type === 'select' || data?.metadata.data_type === 'multi-select'
      ? data.metadata.options
      : [
          {
            fields: [''],
            extra: [],
          },
        ],
  );
  const [createMetadata, { isLoading }] = useCreateMetadataMutation();
  const [editMetadata] = useEditMetadataMutation();

  const hasChanges = useMemo(() => {
    //@ts-expect-error Not yet supported in the API side
    const confirmPreExport = data?.metadata?.confirm_pre_export;

    return (
      name !== data?.metadata?.name ||
      required !== data?.metadata?.required ||
      (confirmPreExport !== undefined ? exportVeeva !== confirmPreExport : exportVeeva) ||
      (!data?.metadata.automatic &&
        dataType?.value === 'string' &&
        data?.metadata?.data_type === 'string' &&
        extra.char_limit !== `${data.metadata.char_limit}`) ||
      (!data?.metadata.automatic &&
        dataType?.value === 'string' &&
        data?.metadata?.data_type === 'string' &&
        extra.searchable !== data.metadata.searchable)
    );
  }, [name, dataType, required, exportVeeva, extra, options]);

  const chartLimitFeedback = useMemo(() => {
    //If extra char limit is below 1 or above 1500 display error feedback
    if (
      extra.char_limit &&
      (+extra.char_limit < CHAR_LIMIT.min || +extra.char_limit > CHAR_LIMIT.max)
    ) {
      return intl.formatMessage({ id: 'CHARACTER_LIMIT_SHOULD_BE_BETWEEN_X_AND_Y' }, CHAR_LIMIT);
    }
  }, [extra]);

  const mode = useMemo<'create' | 'edit' | 'options'>(() => {
    if (data?.metadata) {
      if (data.editOptions) {
        return 'options';
      }

      return 'edit';
    }

    return 'create';
  }, [data]);

  const addOption = () => {
    setOptions((values) => [
      ...values,
      {
        fields: [''],
        extra: [],
      },
    ]);
  };

  const editOption = (
    optionIndex: number,
    option: { fields: string[]; extra: { label: string; value: string }[] },
  ) => {
    setOptions([...options.slice(0, optionIndex), option, ...options.slice(optionIndex + 1)]);
  };

  const removeOption = (optionIndex: number) => {
    setOptions([...options.slice(0, optionIndex), ...options.slice(optionIndex + 1)]);

    if (data) {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}`,
          value: '',
        },
      ]);
    }
  };

  const addField = (optionIndex: number) => {
    const option = options[optionIndex];
    const newOptions = [...options];
    newOptions.splice(optionIndex, 1, { ...option, fields: [...option.fields, ''] });
    setOptions(newOptions);

    if (data && mode !== 'create') {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.fields.${option.fields.length}`,
          value: '',
        },
      ]);
    }
  };

  const changeField = (optionIndex: number, fieldIndex: number, newValue: string) => {
    const option = options[optionIndex];
    const newFields = [...option.fields];
    newFields.splice(fieldIndex, 1, newValue);
    editOption(optionIndex, { ...options[optionIndex], fields: newFields });

    if (data && mode !== 'create') {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.fields.${fieldIndex}`,
          value: newValue,
        },
      ]);
    }
  };

  const removeField = (optionIndex: number, fieldIndex: number) => {
    const option = options[optionIndex];
    const newFields = [...option.fields];
    newFields.splice(fieldIndex, 1);
    editOption(optionIndex, { ...options[optionIndex], fields: newFields });

    if (data && mode !== 'create') {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.fields.${fieldIndex}`,
        },
      ]);
    }
  };

  const addAdditionalInformation = (optionIndex: number) => {
    const option = options[optionIndex];
    const newOptions = [...options];
    newOptions.splice(optionIndex, 1, {
      ...option,
      extra: [...option.extra, { label: '', value: '' }],
    });
    setOptions(newOptions);

    if (data && mode !== 'create') {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.extra.${option.extra.length}.label`,
          value: '',
        },
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.extra.${option.extra.length}.value`,
          value: '',
        },
      ]);
    }
  };

  const editAdditionalInformation = (
    optionIndex: number,
    extraIndex: number,
    extraField: 'label' | 'value',
    extraValue: string,
  ) => {
    const option = options[optionIndex];
    const newExtra = [...option.extra];
    newExtra.splice(extraIndex, 1, { ...option.extra[extraIndex], [extraField]: extraValue });
    editOption(optionIndex, { ...options[optionIndex], extra: newExtra });

    if (data && mode !== 'create') {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.extra.${extraIndex}.${extraField}`,
          value: extraValue,
        },
      ]);
    }
  };

  const removeAdditionalInformation = (optionIndex: number, extraIndex: number) => {
    const option = options[optionIndex];
    const newExtra = [...option.extra];
    newExtra.splice(extraIndex, 1);
    editOption(optionIndex, { ...options[optionIndex], extra: newExtra });

    if (data && mode !== 'create') {
      editMetadata([
        {
          id: data?.metadata.id,
          path: `options.${optionIndex}.extra.${extraIndex}`,
        },
      ]);
    }
  };

  const close = () => {
    closeModal('ManualMetadataModal');
  };

  const submit = async () => {
    if (dataType) {
      let body!: ApiOperations['create_metadata']['requestBody']['content']['multipart/form-data'];

      switch (dataType.value) {
        case 'string':
          body = {
            name,
            required,
            data_type: dataType.value,
            automatic: false,
            char_limit: +extra.char_limit,
            searchable: extra.searchable,
            //@ts-expect-error Not yet supported in the API side
            confirm_pre_export: exportVeeva,
          };
          break;
        case 'select':
        case 'multi-select':
          body = {
            name,
            required,
            data_type: dataType.value,
            automatic: false,
            options,
            //@ts-expect-error Not yet supported in the API side
            confirm_pre_export: exportVeeva,
          };
          break;
        default:
          body = {
            name,
            required,
            data_type: dataType.value,
            automatic: false,
            //@ts-expect-error Not yet supported in the API side
            confirm_pre_export: exportVeeva,
          };
      }

      if (mode === 'create') {
        await createMetadata(body);
        notify({
          type: 'success',
          title: 'MANUAL_METADATA_CREATED',
          message: 'METADATA_CREATED_BODY',
          messageValues: { name },
        });
      } else {
        if (!data) {
          return;
        }

        const editOperations = [];
        //Name changed
        if (name !== data.metadata.name) {
          editOperations.push({
            id: data.metadata.id,
            path: 'name',
            value: name,
          });
        }
        //Required changed
        if (required !== data.metadata.required) {
          editOperations.push({
            id: data.metadata.id,
            path: 'required',
            value: `${required}`,
          });
        }
        //Export to Veeva changed
        //@ts-expect-error Not yet supported in the API side
        if (exportVeeva !== data.metadata.confirm_pre_export) {
          editOperations.push({
            id: data.metadata.id,
            path: 'confirm_pre_export',
            value: `${exportVeeva}`,
          });
        }
        //[STRING] Char limit changed
        if (
          data?.metadata?.data_type === 'string' &&
          extra.char_limit !== `${data.metadata.char_limit}`
        ) {
          editOperations.push({
            id: data.metadata.id,
            path: 'char_limit',
            value: extra.char_limit,
          });
        }
        //[STRING] Searchable changed
        if (
          data?.metadata?.data_type === 'string' &&
          extra.searchable !== data.metadata.searchable
        ) {
          editOperations.push({
            id: data.metadata.id,
            path: 'searchable',
            value: `${extra.searchable}`,
          });
        }

        await editMetadata(editOperations);
        notify({
          type: 'success',
          title: 'MANUAL_METADATA_EDITED',
          message: 'SOMETHING_WAS_SUCCESSFULLY_EDITED',
          messageValues: { label: name },
        });
      }
      close();
    }
  };

  const renderExtraFields = () => {
    switch (dataType?.value) {
      case 'string':
        return (
          <InputField
            testId="manual-metadata-character-limit-input-field"
            label={intl.formatMessage({ id: 'CHARACTER_LIMIT' })}
            size="large"
            feedback={chartLimitFeedback}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'INSERT_A_CHARACTER_LIMIT' })}
              testId="manual-metadata-character-limit"
              value={extra.char_limit}
              type="number"
              onChange={(e) => setExtra((extra) => ({ ...extra, char_limit: e.target.value }))}
            />
          </InputField>
        );
      case 'select':
      case 'multi-select':
        if (mode !== 'edit') {
          return (
            <InputField
              size="large"
              testId="select-options-input-field"
              label={intl.formatMessage({ id: 'SELECT_OPTIONS' })}
              primaryAction={intl.formatMessage({ id: 'ADD_OPTION' })}
              primaryActionOnClick={addOption}
            >
              {options.map((option, i) => (
                <SelectOptionForm
                  key={i}
                  option={option}
                  optionIndex={i}
                  canDeleteOption={options.length > 1}
                  removeOption={removeOption}
                  changeField={changeField}
                  addField={addField}
                  removeField={removeField}
                  addAdditionalInformation={addAdditionalInformation}
                  removeAdditionalInformation={removeAdditionalInformation}
                  editAdditionalInformation={editAdditionalInformation}
                />
              ))}
            </InputField>
          );
        }
        return null;
      case 'user':
      case 'date':
      default:
        return null;
    }
  };

  return (
    <Modal open testId="manual-metadata" width="127rem" onClose={close}>
      <Modal.Header onClose={close}>
        <FormattedMessage id={TRANSLATIONS.header[mode]} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          size="large"
          testId="manual-metadata-name-input-field"
          label={intl.formatMessage({ id: 'METADATA_NAME' })}
          feedback={
            validation && (mode === 'create' || name !== data?.metadata?.name)
              ? intl.formatMessage({ id: validation.id }, validation.values)
              : undefined
          }
          disabled={mode === 'options'}
        >
          <Input
            size="large"
            testId="metadata-name"
            placeholder={intl.formatMessage({ id: 'INSERT_METADATA_UNIQUE_NAME' })}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            error={!!(validation && (mode === 'create' || name !== data?.metadata?.name))}
            disabled={mode === 'options'}
          />
        </InputField>
        <InputField
          size="large"
          testId="metadata-automation-data-type-input-field"
          label={intl.formatMessage({ id: 'DATA_TYPE' })}
          feedback={intl.formatMessage({ id: 'FIELD_CANNOT_BE_CHANGED_AFTER_CREATED' })}
          feedbackType="information"
          disabled={mode !== 'create'}
        >
          <Select
            testId="metadata-automation-data-type-select"
            width="100%"
            menuPosition="fixed"
            options={dataTypes}
            value={dataType}
            onChange={setDataType}
            placeholder={intl.formatMessage({ id: 'SELECT_DATA_TYPE' })}
            size="large"
            disabled={mode !== 'create'}
          />
        </InputField>
        {renderExtraFields()}
        <Checkbox
          checked={required ? 'checked' : 'unchecked'}
          testId="metadata-required-checkbox"
          margin="1rem 0 0 0"
          onChange={() => setRequired(!required)}
          size="small"
          disabled={mode === 'options'}
        >
          <FormattedMessage id="METADATA_REQUIRED" />
        </Checkbox>
        <div className={styles.exportToVeevaCheckbox}>
          <Checkbox
            checked={exportVeeva ? 'checked' : 'unchecked'}
            testId="export-to-veeva-checkbox"
            margin="1rem 0 0 0"
            onChange={() => setExportVeeva(!exportVeeva)}
            size="small"
            disabled={mode === 'options'}
          >
            <FormattedMessage id="EXPORT_TO_VEEVA" />
          </Checkbox>
          <div className={styles.container}>
            <Icon size={16} icon="InformationBlue" />
            <FormattedMessage id="EXPORT_TO_VEEVA_INFO" />
          </div>
        </div>

        {dataType?.value === 'string' && (
          <div className={styles.searchableCheckbox}>
            <Checkbox
              checked={extra.searchable ? 'checked' : 'unchecked'}
              testId="metadata-searchable-checkbox"
              onChange={() => setExtra((extra) => ({ ...extra, searchable: !extra.searchable }))}
              size="small"
              disabled={mode === 'options'}
            >
              <FormattedMessage id="ALLOW_SEARCH_FROM_LIST_OF_OPTIONS" />
            </Checkbox>
            <FormattedMessage id="TYPE_OF_OPTIONS_PRESENTED_IS_USERS" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer alignment={mode === 'options' ? 'flex-start' : undefined}>
        {mode === 'options' && (
          <div className={styles.autoSaveRoot}>
            <Icon size={24} icon="Information" />
            <FormattedMessage id="CHANGES_IN_SELECT_OPTIONS_ARE_AUTO_SAVED" />
          </div>
        )}

        <Button size="medium" testId="manual-metadata-cancel-button" onClick={close}>
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          testId="manual-metadata-submit-button"
          variant="primary"
          disabled={
            name === '' ||
            !dataType ||
            (dataType.value === 'string' && !extra.char_limit) ||
            !!chartLimitFeedback ||
            (!!validation && (mode === 'create' || name !== data?.metadata?.name)) ||
            mode === 'options' ||
            !hasChanges
          }
          onClick={submit}
          loading={isLoading}
        >
          <FormattedMessage id={TRANSLATIONS.submit[mode]} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ManualMetadataModal;
