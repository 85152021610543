import { MouseEvent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Toggle, usePopper, Tooltip } from 'dodoc-design-system';
import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

export type Option = {
  order?: number;
  tooltip?: Omit<TooltipProps, 'children'>;
  options?: Option[];
  testId: string;
  icon?: IconTypes['32'];
  size?: 'large' | 'medium';
} & (
  | {
      inactive?: never | false;
      onClick: (e: MouseEvent<HTMLDivElement>) => void;
      label: TranslationMessage;
      disabled: boolean;
    }
  | {
      inactive?: true;
      onClick?: (e: MouseEvent<HTMLDivElement>) => void;
      label?: TranslationMessage;
      disabled?: boolean;
    }
);
type DefaultOption = Omit<Option, 'label' | 'testId'> & {
  label?: Option['label'];
};

export type OptionsProps = {
  editOption: DefaultOption;
  deleteOption: DefaultOption;
  moreOptions?: Option[];
  disabled?: boolean;
  testId: string;
};

const Options = ({ editOption, deleteOption, moreOptions, disabled, testId }: OptionsProps) => {
  const optionsPopper = usePopper({ placement: 'bottom-start', closeOnReferenceHidden: true });

  const options = useMemo<Option[]>(() => {
    let defaultEditOption: Option = {
      inactive: true,
      testId: `${testId}-options-toggle-edit-item`,
    };
    let defaultDeleteOption: Option = {
      inactive: true,
      testId: `${testId}-options-toggle-delete-item`,
    };

    if (!editOption.inactive && editOption.onClick && editOption.disabled != null) {
      defaultEditOption = {
        order: 1,
        label: { id: 'global.edit' },
        testId: `${testId}-options-toggle-edit-item`,
        disabled: editOption.disabled,
        onClick: editOption.onClick,
        ...editOption,
      };
    }

    if (!deleteOption.inactive && deleteOption.onClick && deleteOption.disabled != null) {
      defaultDeleteOption = {
        order: 2,
        label: { id: 'global.delete' },
        testId: `${testId}-options-toggle-delete-item`,
        disabled: deleteOption.disabled,
        onClick: deleteOption.onClick,
        ...deleteOption,
      };
    }

    let defaultOptions: Option[] = [defaultEditOption, defaultDeleteOption];

    return defaultOptions
      .concat(moreOptions ?? [])
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .filter((option) => !option.inactive);
  }, [moreOptions, editOption, deleteOption, testId]);

  const onOptionClick = (e: MouseEvent<HTMLDivElement>, option: Option) => {
    e.stopPropagation();
    option.onClick?.(e);
  };

  const generateOptionItem = (option: Option, id: number) => {
    const item = (
      //@ts-expect-error
      <Dropdown.Item
        key={id}
        onClick={(e) => {
          onOptionClick(e, option);
        }}
        disabled={option.disabled}
        testId={option.testId}
        size={option.size}
        prefixIcon={option.icon}
      >
        <FormattedMessage id={option.label?.id} values={option.label?.values} />
      </Dropdown.Item>
    );

    if (option.tooltip) {
      return (
        <Tooltip
          {...option.tooltip}
          disabled={option.tooltip ? option.tooltip.disabled : true}
          key={id}
        >
          {item}
        </Tooltip>
      );
    }

    return item;
  };

  const renderItem = (option: Option, id: number) => {
    return option.options?.length ? (
      <Dropdown.SubMenu
        key={id}
        itemContent={<FormattedMessage id={option.label?.id} values={option.label?.values} />}
        placement="left"
        disabled={option.disabled}
        testId={`${testId}-options-toggle-${option.testId}-submenu`}
      >
        {option.options.map((subOption, j) =>
          renderItem(
            {
              ...subOption,
              testId: `${testId}-options-toggle-${option.testId}-submenu-${subOption.testId}-item`,
            },
            j,
          ),
        )}
      </Dropdown.SubMenu>
    ) : (
      generateOptionItem(
        { ...option, testId: `${testId}-options-toggle-${option.testId}-item` },
        id,
      )
    );
  };

  return (
    <>
      <Toggle
        {...optionsPopper.referenceProps}
        variant="link"
        size="medium"
        icon={optionsPopper.isOpen ? 'PDFOptionsBlue' : 'PDFOptionsGrey'}
        isToggled={optionsPopper.isOpen}
        disabled={disabled}
        onClickCapture={() => {
          optionsPopper.referenceProps.onClickCapture();
        }}
        testId={`${testId}-options-toggle`}
      />
      <Dropdown {...optionsPopper.popperProps} width="30rem" testId={`${testId}-options-dropdown`}>
        {options.map((option, i) => {
          return renderItem(option, i);
        })}
      </Dropdown>
    </>
  );
};

export default Options;
