import { theme } from 'dodoc-design-system';
import { useState } from 'react';

const pallete = {
  neutral: {
    background: theme.colors['neutral-5'],
    border: theme.colors['neutral-50'],
    cross: theme.colors['neutral-50'],
  },
  hover: {
    background: theme.colors['red-20'],
    border: theme.colors['red-90'],
    cross: theme.colors['red-90'],
  },
};

const RemoveIcon = () => {
  const [state, setState] = useState<'neutral' | 'hover'>('neutral');
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setState('hover')}
      onMouseLeave={() => setState('neutral')}
    >
      <rect x="0.5" y="0.5" width="12" height="12" rx="6" fill={pallete[state].background} />
      <path d="M5 5L8 8" stroke={pallete[state].cross} strokeLinecap="round" />
      <path d="M5 8L8 5" stroke={pallete[state].cross} strokeLinecap="round" />
      <rect x="0.5" y="0.5" width="12" height="12" rx="6" stroke={pallete[state].border} />
    </svg>
  );
};

export default RemoveIcon;
