import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { Command } from '../Command';

export class ProcessCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.Context, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  async handleExec(): Promise<void> {
    const range = EditorSelectionUtils.getRange();
    if (range && !range.collapsed) {
      range.collapse(true);
    }

    // this interrup the event composition
    // this.applySelection();
  }
}
