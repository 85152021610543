import { Label } from 'dodoc-design-system';
import type { LabelProps } from 'dodoc-design-system/build/types/Components/Label/Label';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

type StatusProps = {
  id: ObjectId;
  fullWidth?: boolean;
  size?: LabelProps['size'];
  testId: string;
};

/**
 * Renders the status badge
 */
const Status = ({ id, fullWidth = false, size = 'small', testId }: StatusProps) => {
  const { status } = useGetElementStatusListQuery(undefined, {
    selectFromResult: ({ data }) => ({ status: data?.entities[id] }),
  });

  if (status) {
    return (
      <Label
        title={status.name}
        color={status.color}
        fullWidth={fullWidth}
        size={size}
        testId={testId}
      >
        {status.name}
      </Label>
    );
  }

  return null;
};

export default Status;
