import { useCallback } from 'react';

import { useSelector } from '_common/hooks';

const useDocumentParagraph = () => {
  const paragraphsAreLoaded = useSelector((state) => state.editor.status.paragraphsLoaded);

  const getDocumentParagraph = useCallback(
    (paragraphIndex: number) => {
      const paragraphs = document.getElementsByTagName('paragraph-element');
      if (paragraphs) {
        return paragraphs[paragraphIndex] as HTMLElement;
      }

      return null;
    },
    [paragraphsAreLoaded],
  );

  return getDocumentParagraph;
};

export default useDocumentParagraph;
