import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import ToolbarDropdown from 'Editor/components/ToolbarDropdown/ToolbarDropdown';
import { useIntl } from 'react-intl';

type VersionsProps = {
  versions?: Editor.Version[];
  loadedVersion: number | undefined | null;
  loadVersion: (index: number | null) => void;
  width?: string;
  testId: string;
  supportOriginalVersion?: boolean;
};

const Versions = ({
  versions,
  loadedVersion,
  width,
  testId,
  loadVersion,
  supportOriginalVersion,
}: VersionsProps) => {
  const intl = useIntl();
  const onChange = (value: SelectOption) => {
    if (`${loadedVersion}` !== value.value) {
      const index = value.value === 'current' ? null : +value.value;
      loadVersion(index);
    }
  };

  const options =
    versions?.map((_, index) => {
      const isOriginalVersion = supportOriginalVersion && index === 0;
      const versionNumber =
        (loadedVersion != null && loadedVersion - 1 === index ? loadedVersion : index + 1) -
        (supportOriginalVersion ? 1 : 0);
      return {
        label: isOriginalVersion
          ? `${intl.formatMessage({ id: 'ORIGINAL_FILE' })} (V.${versionNumber})`
          : `V.${versionNumber}`,
        value: `${index}`,
      };
    }) || [];
  options.push({
    label: `${intl.formatMessage({ id: 'CURRENT_VERSION' })} (V.${
      (versions?.length ?? 0) + (supportOriginalVersion ? 0 : 1)
    })`,
    value: 'current',
  });
  options.reverse();
  return (
    <ToolbarDropdown
      options={options}
      value={
        loadedVersion != null && loadedVersion >= 0
          ? options.find((option) => option.value === `${loadedVersion}`)
          : options[0]
      }
      width={width ?? '21'}
      onChange={onChange}
      testId={testId}
    />
  );
};

export default Versions;
