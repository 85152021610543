import { MouseEventHandler, ReactNode, useMemo, useRef } from 'react';
import { MenuItem } from 'react-contextmenu';
import { Tooltip, KeyboardShortcut } from 'dodoc-design-system';
import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';

import styles from './ContextMenuItem.module.scss';
import { useIsEllipsed } from '_common/hooks';

type ContextMenuItemProps = {
  disabled?: boolean;
  shortcut?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
  /**
   * Tooltip to show when the item is overflowed
   * For now this is a behaviour for only items that we want to have this tooltip when overflowed
   * Would be better to have this behaviour for all items automatically
   * But since there are questions like: What should happen if the item has a tooltip defined?
   * Should the overflow tooltip override the tooltip?
   * This was asked to design and they said to only apply the overflow behaviour to specific items
   * and not to be a general behaviour of all items
   */
  overflowTooltip?: Omit<TooltipProps, 'children' | 'disabled'>;
  onClick: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  dataTestId: string;
};

const ContextMenuItem = ({
  children,
  onClick,
  disabled,
  shortcut,
  tooltip = { disabled: true, content: '', testId: '' },
  overflowTooltip,
  dataTestId,
}: ContextMenuItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const isOverflowed = useIsEllipsed(ref, [children]);

  const tooltipProps = useMemo<Omit<TooltipProps, 'children'>>(() => {
    if (!overflowTooltip || !isOverflowed) {
      return tooltip;
    }

    return {
      ...overflowTooltip,
      disabled: !isOverflowed,
    };
  }, [isOverflowed, overflowTooltip, tooltip]);

  return (
    <Tooltip {...tooltipProps} testId={`${dataTestId}-tooltip`}>
      <div data-testid={dataTestId} className={styles.root}>
        {/* @ts-expect-error update react-contextmenu MenuItem component prop types out of date */}
        <MenuItem
          onClick={onClick}
          disabled={disabled}
          className={`${styles.item} ${disabled && styles.disabled}`}
        >
          <span ref={ref}>{children}</span>
          {shortcut && (
            <div className={styles.shortcut}>
              <KeyboardShortcut shortcut={shortcut} testId={`${shortcut}-shortcut`} />
            </div>
          )}
        </MenuItem>
      </div>
    </Tooltip>
  );
};

export default ContextMenuItem;
