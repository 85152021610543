/* eslint-disable class-methods-use-this */
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { DocumentCitationsData } from './Citations.types';

export class DocumentCitations extends RealtimeObject<DocumentCitationsData> {
  private index: string[];

  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'citations');
    this.index = [];
  }

  private calculateIndex() {
    this.index = this.get()?.order || [];
  }

  handleLoad(): void {
    this.calculateIndex();
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.calculateIndex();
    this.emit('UPDATED', this.get(), ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  get citations() {
    return this.get()?.citations || {};
  }

  get order() {
    return this.index;
  }

  get orderType() {
    return this.get()?.oType || 'S';
  }

  get locations() {
    return this.get()?.loc || {};
  }

  get referenceStyle() {
    return this.get()?.cs || null;
  }

  getChronoOrder() {
    const citations = this.get()?.citations || {};
    return Object.keys(citations).sort((a, b) => citations[a].ts - citations[b].ts);
  }

  getSequentialOrder() {
    return this.get()?.order || [];
  }

  setReferenceStyle(refStyleId: string) {
    return this.set(['cs'], refStyleId);
  }

  addCitationToDocument(citationId: string) {
    const data = this.selectedData();
    if (!data) {
      return Promise.reject();
    }

    if (data.citations[citationId]) {
      return Promise.resolve(this);
    }
    return this.apply([{ oi: { ts: Date.now() }, p: ['citations', citationId] }]);
  }
}
