import { Icon } from 'dodoc-design-system';
import { useEffect, useState } from 'react';

type AdministratorCellProps = {
  user: UserPublicProfileExtended;
};

const AdministratorCell = ({ user }: AdministratorCellProps) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(user?.is_admin || user?.is_superuser);
  }, [user]);

  return (
    <Icon
      icon={isAdmin ? 'Check' : 'CheckEmpty'}
      size={32}
      disabled={!user.is_active}
      testId={`is-admin-icon`}
    />
  );
};

export default AdministratorCell;
