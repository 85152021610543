import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Input, InputField } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';

const MODAL = 'HyperlinkModal';

const FIELDS = {
  url: 'url',
  textToDisplay: 'textToDisplay',
} as const;

const HyperlinkModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const edit = useSelector((state) => state.modals[MODAL].edit);
  const links = useSelector((state) => state.modals[MODAL].links);
  const showTextToDisplay = useSelector((state) => state.modals[MODAL].showTextToDisplay);
  const url = useSelector((state) => state.modals[MODAL].url);
  const textToDisplay = useSelector((state) => state.modals[MODAL].textToDisplay);

  const [fields, setFields] = useState<
    Record<
      (typeof FIELDS)[keyof typeof FIELDS],
      { value: string; error: boolean; changed: boolean; empty: boolean }
    >
  >({
    url: { value: '', error: false, changed: false, empty: true },
    textToDisplay: { value: '', error: false, changed: false, empty: true },
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const selectedText = EditorManager.getInstance().getSelectedText();
      setFields((prevState) => ({
        ...prevState,
        [FIELDS.url]: { value: url, error: false, changed: false, empty: url ? false : true },
        [FIELDS.textToDisplay]: {
          value: textToDisplay || selectedText,
          error: false,
          changed: false,
          empty: textToDisplay || selectedText ? false : true,
        },
      }));
    }
  }, [isOpen]);

  useEffect(() => {
    let errors = 0;
    let changed = 0;
    let empty = 0;

    Object.values(fields).forEach((field) => {
      if (field.error) {
        errors += 1;
      }
      if (field.changed) {
        changed += 1;
      }

      if (field.empty) {
        empty += 1;
      }
    });

    if (errors < 1 && changed > 0 && empty < 1) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [fields]);

  const stringIsValid = (value = '') => {
    if (!value) {
      return false;
    }

    if (value === ' ' || !/\S/.test(value)) {
      return false;
    }

    return true;
  };

  function urlIsValid(string: string) {
    try {
      let url = string;
      //If URL doesn't have scheme, validate as it has because in the end the hyperlink will be made with scheme.
      if (!/^(http:|https:)/i.test(url)) {
        url = 'http://' + url;
      }
      new URL(url);
    } catch (_) {
      return false;
    }

    return true;
  }

  const handleFieldChange = (
    field: keyof typeof fields,
    value: (typeof fields)[keyof typeof fields]['value'],
  ) => {
    let error = false;
    let changed = true;
    let empty = true;

    if (value === url) {
      changed = false;
    }

    if (stringIsValid(value)) {
      empty = false;

      if (field === 'url') {
        if (!urlIsValid(value)) {
          error = true;
        }
      }
    }

    setFields((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], value, error, changed, empty },
    }));
  };

  const handleUrlChange: InputProps['onChange'] = (e) => {
    handleFieldChange(FIELDS.url, e.target.value);
  };

  const handleTextChange: InputProps['onChange'] = (e) => {
    handleFieldChange(FIELDS.textToDisplay, e.target.value);
  };

  const close = () => {
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.restore();
    }
    dispatch(closeAndResetModal(MODAL));
  };

  const insertLinkIntoText = () => {
    const manager = EditorManager.getInstance();
    const text = fields[FIELDS.textToDisplay].value || fields[FIELDS.url].value;
    if (manager) {
      manager.insertHyperlink(links, fields[FIELDS.url].value, showTextToDisplay, text);
      if (edit) {
        notify({
          type: 'success',
          title: 'HYPERLINK_EDITED',
          message: 'THE_HYPERLINK_WAS_SUCCESSFULLY_EDITED',
        });
      }
    }
  };

  const saveChanges = () => {
    if (!fields[FIELDS.url].value) {
      notify({
        type: 'error',
        title: 'global.error',
        message: 'MISSING_URL',
      });
      return;
    }
    insertLinkIntoText();
    close();
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={close}
      width="60rem"
      testId={`${edit ? 'edit' : 'insert'}-hyperlink`}
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id={edit ? 'EDIT_HYPERLINK' : 'INSERT_HYPERLINK'} />
      </Modal.Header>
      <Modal.Body>
        {showTextToDisplay && (
          <div>
            <InputField
              label={intl.formatMessage({ id: 'editor.insertLink.textToDisplay' })}
              size="large"
              testId="hyperlink-text-to-display-field"
            >
              <Input
                value={fields[FIELDS.textToDisplay].value}
                type="text"
                onChange={handleTextChange}
                placeholder={intl.formatMessage({
                  id: 'editor.insertLink.placeholderTextToDisplay',
                })}
                onBlur={() => {
                  if (
                    !fields[FIELDS.textToDisplay].value ||
                    fields[FIELDS.textToDisplay].value.length === 0
                  ) {
                    handleFieldChange(FIELDS.textToDisplay, fields[FIELDS.url].value);
                  }
                }}
                size="large"
                testId={`${edit ? 'edit' : 'insert'}-hyperlink-text-to-display`}
              />
            </InputField>
          </div>
        )}
        <InputField
          label={intl.formatMessage({ id: 'editor.insertLink.url' })}
          size="large"
          feedback={
            fields.url.error
              ? intl.formatMessage({ id: 'INVALID_LINK_PLEASE_TRY_AGAIN' })
              : undefined
          }
          testId="hyperlink-url-field"
        >
          <Input
            value={
              fields[FIELDS.url].value === null
                ? intl.formatMessage({
                    id: 'editor.insertLink.multipleLinksSelected',
                  })
                : fields[FIELDS.url].value
            }
            type="text"
            onChange={handleUrlChange}
            placeholder={intl.formatMessage({
              id: 'PASTE_A_HYPERLINK_HERE',
            })}
            size="large"
            testId={`${edit ? 'edit' : 'insert'}-hyperlink-url`}
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="medium"
          onClick={close}
          testId={`${edit ? 'edit' : 'insert'}-hyperlink-cancel-button`}
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={saveChanges}
          disabled={!isFormValid}
          testId={`${edit ? 'edit' : 'insert'}-hyperlink-submit-button`}
        >
          <FormattedMessage id={edit ? 'storage.actionBar.actions.update' : 'global.insert'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HyperlinkModal;
