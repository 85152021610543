import './CommentElement.module.scss'; // eslint-disable-line no-unused-vars
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import UserColors from 'Editor/services/UserColors/UserColors';
import { hexToRgb } from 'assets/colors';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewElement } from '../..';

const ATTIBUTES = {
  AUTHOR: 'author',
  SELECTED: 'selected',
  DISABLED: 'disabled',
};

export class CommentElement extends BaseViewElement {
  private color?: string;
  protected setBackgroundTimeout?: NodeJS.Timeout;

  static get observedAttributes() {
    return [ATTIBUTES.AUTHOR, ATTIBUTES.SELECTED, ATTIBUTES.DISABLED];
  }

  get hidden() {
    return this.getAttribute('disabled') === 'true';
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(val) {
    if (val) {
      this.setAttribute('selected', 'true');
    } else {
      this.removeAttribute('selected');
    }
  }

  get elementReference() {
    return this.getAttribute('element_reference');
  }

  setHidden(value: boolean) {
    if (value) {
      this.setAttribute('disabled', 'true');
    } else {
      this.removeAttribute('disabled');
    }
  }

  connectedCallback() {
    super.connectedCallback();

    if (!this.preRendered) {
      this.preRender();
    }
  }

  preRender() {
    this.setBackground = this.setBackground.bind(this);
    this.clearBackground = this.clearBackground.bind(this);

    if (this.style) {
      this.style.fontFamily = 'inherit';
      this.style.fontSize = 'inherit';
      this.style.fontStyle = 'inherit';
      this.style.fontWeight = 'inherit';
      this.style.textDecoration = 'inherit';
      this.style.textDecoration = 'inherit';
      this.style.verticalAlign = 'inherit';
    }

    this.setUserColor();

    super.preRender();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.setBackgroundTimeout) {
      clearTimeout(this.setBackgroundTimeout);
    }
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    if (document.contains(this) && oldValue !== newValue) {
      if (name === ATTIBUTES.AUTHOR) {
        this.setUserColor();
      } else if (name === ATTIBUTES.SELECTED) {
        this.setBackground();
      } else if (name === ATTIBUTES.DISABLED) {
        this._handleHiddenChange();
      }
    }
  }

  _handleHiddenChange() {
    if (this.hidden) {
      this.clearBackground();
    } else {
      this.setUserColor();
    }
  }

  selectElement() {
    if (!this.hidden) {
      this.selected = true;
    }
  }

  deselectElement() {
    this.selected = false;
  }

  getAuthor() {
    return this.getAttribute('author') || this.getAttribute('user');
  }

  setUserColor() {
    const author = this.getAuthor();
    if (author) {
      this.color = UserColors.getUserColor(author)?.base;
    }
    this.setBackground();
  }

  reRenderBackground() {
    this.setBackground();
  }

  setBackground() {
    if (this.setBackgroundTimeout) {
      clearTimeout(this.setBackgroundTimeout);
    }

    this.setBackgroundTimeout = setTimeout(() => {
      if (!this.hidden) {
        const bounds = this.getClientRects();
        const lineHeight = parseInt(window.getComputedStyle(this).lineHeight, 10);
        if (bounds.length === 0) {
          return;
        }
        const boxShadowLength = (lineHeight - bounds[0].height) / 2;
        let color;
        const author = this.getAuthor();
        if (author) {
          const colors = UserColors.getUserColor(author);
          const selectedColor = colors?.tint30;
          const isSelected =
            this.hasAttribute('selected') && this.getAttribute('selected') === 'true';
          if (this.color) {
            const rgbObject = hexToRgb(this.color);
            if (rgbObject != null) {
              if (this.hasAttribute('selected') && this.getAttribute('selected') === 'true') {
                color = selectedColor;
              } else {
                color = `rgba( ${rgbObject[0]}, ${rgbObject[1]}, ${rgbObject[2]}, 0.14)`;
              }
            } else {
              color = this.color;
            }
            if (this.style) {
              const zoomValue = ReduxInterface.getZoomValue();
              const height = bounds[0].height / zoomValue;
              this.style.boxShadow = `inset 0px -${Math.min(height, lineHeight)}px 0px ${color} ${
                boxShadowLength >= 0
                  ? `, 0px ${Math.min(boxShadowLength, 5)}px 0px ${color}, 0px -${Math.min(
                      boxShadowLength,
                      5,
                    )}px 0px 0px ${color}`
                  : ''
              } ${isSelected ? ', 0px 8px 8px rgba(0, 0, 0, 0.25)' : ''}`;
            }
          }
        }
      }
    }, 50);
  }

  clearBackground() {
    if (this.style) {
      this.style.boxShadow = '';
    }
  }

  isEqual(node: HTMLElement) {
    return (
      this.tagName === node.tagName &&
      this.getAttribute('element_reference') === node.getAttribute('element_reference')
    );
  }
}

if (!window.customElements.get(ELEMENTS.CommentElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.CommentElement.IDENTIFIER, CommentElement);
}
