import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Tooltip, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToSettings } from 'router/history';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { toggleInfoPanel } from 'Shared/redux/SharedListPageSlice';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

import { ActionBar, ActionsToggle, FilterPopover } from '_common/components';
import SearchInput from 'Search/pages/SearchPage/SearchInput/SearchInput';

import styles from './Header.module.scss';

type HeaderProps = {
  hasError?: boolean;
};

const PAGE_IDENTITY = 'shared';

const Header = ({ hasError }: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedState = useSelector((state) => state.table.selected);
  const object = useSelector((state) => state.app.data[Object.keys(selectedState)[0]]);
  const data = useSelector((state) => state.app.data);
  const appLoading = useSelector((state) => state.app.loading.isOpen);
  const infoPanelOpen = useSelector((state) => state.shared.infoPanelOpen);
  const tableList = useSelector((state) => state.table.identity[PAGE_IDENTITY]?.list);
  const current = useSelector((state) => data[state.storage.current ?? '']);

  const { data: statuses } = useGetElementStatusListQuery();
  const memoStatuses = useMemo(
    () =>
      statuses?.ids
        .filter((id) => statuses.entities[id]?.allow_change)
        .map((id) => ({
          value: `${id}`,
          label: statuses.entities[id]?.name || '',
        })),
    [statuses],
  );

  const toggleInfoPanelOpen = () => {
    dispatch(toggleInfoPanel());
  };

  const handleOnClickSettings = () => {
    if (selectedState && Object.values(selectedState).length === 1) {
      navigateToSettings(object.type, Object.keys(selectedState)[0]);
    }
  };

  const handleDisabledShareButton = () => {
    const selected = Object.keys(selectedState);
    const conditionsToEnableShare = ['admin', 'owner', 'add_permission', 'remove_permission'];

    if (
      appLoading ||
      Object.keys(data).length === 0 ||
      selected.length !== 1 ||
      (object && object.status === 'processing') ||
      hasError ||
      !conditionsToEnableShare.some(
        (permission) => object && object.user_permissions.includes(permission),
      )
    ) {
      return true;
    }

    return false;
  };

  const handleShareClicked = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ShareModal',
        data: {
          view: 'users',
          objectId: Object.keys(selectedState)[0],
          objectType: object.type,
          editor: false,
        },
      }),
    );
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'shared',
            name: intl.formatMessage({ id: 'shared.sharedTitle' }),
          },
        ]}
        pageLoading={appLoading}
      />
      <div className={styles.actionsContainer}>
        <Toggle
          size="medium"
          variant="standard"
          icon="Settings"
          disabled={!(selectedState && Object.values(selectedState).length === 1) || appLoading}
          onClick={handleOnClickSettings}
          testId="shared-page-settings-toggle"
        >
          <FormattedMessage id="global.settings" />
        </Toggle>
        <Toggle
          size="medium"
          variant="standard"
          onClick={handleShareClicked}
          disabled={handleDisabledShareButton()}
          style={{ marginLeft: '0.5rem' }}
          icon="Share"
          testId="shared-page-share-toggle"
        >
          <FormattedMessage id="storage.actionBar.actions.share" />
        </Toggle>
        <ActionsToggle
          disabled={!(selectedState && Object.values(selectedState).length === 1) || appLoading}
          selected={selectedState && Object.keys(selectedState)}
          current={current}
          identity={PAGE_IDENTITY}
        />
      </div>
      <div className={styles.togglesContainer}>
        <Tooltip
          placement="bottom"
          content={intl.formatMessage({ id: 'global.information' })}
          testId="shared-page-information-tooltip"
        >
          <div style={{ marginRight: '0.5rem' }}>
            <Toggle
              size="medium"
              variant="standard"
              icon="Information"
              onClick={toggleInfoPanelOpen}
              isToggled={infoPanelOpen}
              disabled={tableList?.length === 0 || appLoading}
              testId="shared-page-information-toggle"
            />
          </div>
        </Tooltip>

        <FilterPopover
          identity="shared"
          popperSettings={{
            placement: 'bottom-end',
            skidding: 0,
            distance: 20,
          }}
          /*testId="storage-page-filters-toggle"*/
          status={{ options: memoStatuses, settings: { placeholder: 'SELECT_ELEMENT_STATUS' } }}
          objectType={{
            options: [
              {
                value: 'folder',
                label: 'storage.actionBar.filters.folder',
              },
              {
                value: 'file',
                label: 'storage.actionBar.filters.file',
              },
              {
                value: 'document',
                label: 'storage.actionBar.filters.document',
              },
              {
                value: 'dopdf',
                label: 'doPDF',
              },
            ],
            settings: { placeholder: 'SELECT_OBJECT_TYPE' },
          }}
        />
      </div>
      <SearchInput disabled={appLoading} style={{ marginLeft: '2rem' }} current={current} />
    </ActionBar>
  );
};

export default Header;
