import { Toggle } from 'dodoc-design-system';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';
import { useDispatch, useSelector } from '_common/hooks';

const FreehandToggle = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const isToggled = useSelector((state) => state.pdf.general.cursorMode) === 'Ink';
  return (
    <Toggle
      variant="ghost"
      size="medium"
      icon={isToggled ? 'FreehandBlue' : 'FreehandGrey'}
      isToggled={isToggled}
      onClick={() => {
        dispatch(setCursorMode(isToggled ? 'normal' : 'Ink'));
      }}
      disabled={disabled}
      testId="menu-freehand-toggle"
    />
  );
};

export default FreehandToggle;
