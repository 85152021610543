import { memo } from 'react';
import { useSuiteObject } from '_common/suite';
import {
  AvatarList,
  EditableName,
  ExportButton,
  Logo,
  ObjectStatus,
  ShareButton,
  Title,
  UserCenter,
} from '_common/suite/components';
import { useUsersSync } from './SyncStore';
import PresentationNotificationsCenter from './PresentationNotificationsCenter';
import { InteractionController } from '_common/components';

const PresentationTitle = () => {
  const usersOnline = useUsersSync();
  const object = useSuiteObject();
  return (
    <InteractionController environment="presentation" style={{ gridArea: 'title' }}>
      <Title divider>
        <Logo object={object} />
        <EditableName object={object} />
        <ObjectStatus object={object} />
        <AvatarList users={usersOnline ?? []} />
        <ShareButton object={object} />
        <ExportButton object={object} />
        <PresentationNotificationsCenter />
        <UserCenter />
      </Title>
    </InteractionController>
  );
};

export default memo(PresentationTitle);
