import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { Mixin } from 'mixwith';

export default Mixin(
  (superclass) =>
    class CopyPasteHelper extends superclass {
      /**
       *
       * @param {*} baseNode
       * @param {*} nodeToMerge
       */
      canMergeContens(baseNode, nodeToMerge) {
        if (
          EditorDOMUtils.isClosestTextElementEditable(baseNode) &&
          EditorDOMUtils.isClosestTextElementEditable(nodeToMerge)
        ) {
          // both are lists
          if (
            (this.dataManager.numbering.isListElement(baseNode.id) ||
              baseNode.hasAttribute('cp_list_id')) &&
            (this.dataManager.numbering.isListElement(nodeToMerge.id) ||
              nodeToMerge.hasAttribute('cp_list_id'))
          ) {
            return true;
          }

          // both are not lists
          if (
            !this.dataManager.numbering.isListElement(baseNode.id) &&
            !baseNode.hasAttribute('cp_list_id') &&
            !this.dataManager.numbering.isListElement(nodeToMerge.id) &&
            !nodeToMerge.hasAttribute('cp_list_id')
          ) {
            return true;
          }
        }

        return false;
      }
    },
);
