import { MouseEventHandler, useState } from 'react';

import { usePublicProfile } from '_common/hooks';

import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import UserAvatar from '_common/components/UserAvatar/UserAvatar';

import styles from './MentionCard.module.scss';

const MentionCard = ({
  userId,
  onInsertMention,
}: {
  userId: UserId;
  onInsertMention: (userId: UserId) => void;
}) => {
  const [renderedString, setRenderedString] = useState<string>();
  const profile = usePublicProfile(userId);

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    onInsertMention(userId);
  };

  return (
    <div onClick={handleClick} className={styles.root}>
      <div className={styles.avatar}>
        <UserAvatar userId={userId} />
      </div>
      <div className={styles.container}>
        <div
          className={`${styles.name} ${styles.ellipsis}`}
          ref={(ref) => setRenderedString(ref?.textContent ?? undefined)}
          title={renderedString}
        >
          <UsernameLabel userId={userId} />
        </div>

        {profile.email && (
          <div className={`${styles.email} ${styles.ellipsis}`} title={profile.email}>
            {profile.email}
          </div>
        )}
      </div>
    </div>
  );
};

export default MentionCard;
