import { useEffect } from 'react';

type FaviconUrl = '/favicon.ico' | '/favicon-red.ico' | '/favicon-orange.ico';

const useFavicon = (faviconUrl: FaviconUrl) => {
  useEffect(() => {
    const faviconLink = document.getElementById('favicon') as HTMLLinkElement;
    let previousFaviconUrl = '';
    if (faviconLink) {
      previousFaviconUrl = faviconLink.href;
      faviconLink.href = faviconUrl;
    }
    return () => {
      if (faviconLink && previousFaviconUrl) {
        faviconLink.href = previousFaviconUrl;
      }
    };
  }, []);
};

export default useFavicon;
