import { CSSProperties } from 'react';

type HighlightMarkerProps = {
  isActive: boolean;
  rect: ShortRect;
  defaultBackground: CSSProperties['background'];
  activeBackground?: CSSProperties['background'];
  activeBorderColor?: CSSProperties['borderColor'];
  onClick?: () => void;
  style?: CSSProperties;
};

const HighlightMarker = ({
  isActive,
  defaultBackground,
  activeBackground,
  activeBorderColor,
  rect,
  style,
  onClick,
}: HighlightMarkerProps) => {
  return (
    <span
      style={{
        position: 'absolute',
        mixBlendMode: 'multiply',
        pointerEvents: onClick ? 'initial' : 'none',
        background: isActive ? activeBackground ?? defaultBackground : defaultBackground,
        boxShadow:
          isActive && activeBorderColor
            ? `inset 0 0 0 2px ${activeBorderColor}, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)`
            : undefined,
        ...rect,
        ...style,
      }}
      onClick={onClick}
    />
  );
};

export default HighlightMarker;
