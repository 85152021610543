import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from '_common/hooks/redux';

import { List } from 'dodoc-design-system';
import Instance from './Instance/Instance';
import styles from './Instances.module.scss';

const Instances = ({ word }: { word: string | null }) => {
  const totalResults = useSelector((state) => state.editor.findAndReplace.totalResults);
  const instances = useSelector((state) => state.editor.findAndReplace.instances);
  const currentResult = useSelector((state) => state.editor.findAndReplace.currentResult);
  const ref: MyAny = useCallback(
    (node: MyAny) => {
      if (node !== null) {
        node.scrollToItem(currentResult);
      }
    },
    [currentResult],
  );

  if (!word) {
    return null;
  }

  return (
    <div className={styles.instances}>
      {instances?.length > 0 ? (
        <>
          <div className={styles.counter} data-testid="find-and-replace-instances-found-label">
            <FormattedMessage id="INSTANCES_FOUND_FOR_WORD" values={{ totalResults, word }} />
          </div>
          <div className={styles.list} data-testid="find-and-replace-list">
            <List
              ref={ref}
              itemSize={24}
              data={instances.map((instance, i) => (
                <Instance
                  key={instance.id}
                  before={instance.context.before}
                  word={instance.word}
                  after={instance.context.after}
                  id={i}
                  current={i === currentResult}
                />
              ))}
            />
          </div>
        </>
      ) : (
        <div className={styles.empty} data-testid="find-and-replace-instances-found-label">
          <FormattedMessage id="INSTANCES_FOUND_FOR_WORD" values={{ totalResults, word }} />
        </div>
      )}
    </div>
  );
};

export default Instances;
