import { ChangeEventHandler, CSSProperties, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'dodoc-design-system';
import { v4 as uuid } from 'uuid';

import { useDispatch } from '_common/hooks';
import { navigateToSearch } from 'router/history';

import {
  setAdvancedFilters,
  setAppliedFilterSet,
  setInitialParent,
  setSearchMode,
} from 'Search/redux/SearchPageSlice';
import { AdvancedFilter } from '_common/components/AdvancedFilterRow/AdvancedFilterOptions';

type SearchInputProps = {
  style: CSSProperties;
  current?: Objekt;
  disabled?: boolean;
};

const SearchInput = ({ style, current, disabled }: SearchInputProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');

  const searchObject = () => {
    if (inputValue !== '') {
      const advancedFilter: AdvancedFilter = {
        id: uuid(),
        option: 'name',
        operator: 'contains',
        value: inputValue,
      };
      setInputValue('');
      dispatch(setSearchMode('normal'));
      dispatch(setAppliedFilterSet(undefined));
      dispatch(setAdvancedFilters({ [advancedFilter.id]: advancedFilter }));
    }

    if (current) {
      dispatch(setInitialParent(current));
    }
    navigateToSearch();
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInputValue(e.target.value);

  return (
    <div style={{ width: '27rem', ...style }}>
      <Input
        placeholder={intl.formatMessage({ id: 'global.search' })}
        value={inputValue}
        onChange={handleInputChange}
        onPrefixClick={searchObject}
        onEnterKey={searchObject}
        prefix="NavSearchBlue"
        disabled={disabled}
        size="medium"
        testId="explorer-search"
      />
    </div>
  );
};

export default SearchInput;
