//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { FieldElement } from 'Editor/services/VisualizerManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

const LABEL_SEPARATORS = ['-', ':', '.', '—', '–⁠'];

export default Mixin(
  (superclass: any) =>
    class FieldsEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      handleInsertCaptionOnSelection(options: any = {}) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext;

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            actionContext = new ActionContext();

            if (typeof options.label === 'string' && options.label !== '') {
              // get label definition
              const labelDefinition = this.dataManager.captions.caption(options.label);

              const range = EditorSelectionUtils.getRange();

              if (range) {
                const closest = EditorDOMUtils.closest(range.commonAncestorContainer, [
                  ELEMENTS.TableElement.TAG,
                  ELEMENTS.FigureElement.TAG,
                  ELEMENTS.EquationElement.TAG,
                ]);

                const container =
                  EditorDOMUtils.closest(closest, [ELEMENTS.TableCellElement.TAG]) || this.page;

                let blockElement;
                if (closest) {
                  blockElement = EditorDOMUtils.findFirstLevelChildNode(container, closest);
                } else {
                  blockElement = EditorDOMUtils.findFirstLevelChildNode(
                    this.page,
                    range.commonAncestorContainer,
                  );
                }

                options.ls = labelDefinition && labelDefinition.labelStyle;

                if (!labelDefinition) {
                  this.dataManager.captions.createCaption(options.label);
                  actionContext.jsonChanges = true;
                }

                this.insertCaptionOnSelection(
                  actionContext,
                  blockElement,
                  range.startContainer,
                  options,
                  container.tagName === ELEMENTS.TableCellElement.TAG,
                );

                // update label definition
                const data: any = {};
                if (options.ls) {
                  data.ls = options.ls;
                }
                if (options.numberingType) {
                  data.nf = options.numberingType;
                }

                if (options.chapterNumbering) {
                  if (options.chapterNumbering.chapterType) {
                    data.c = options.chapterNumbering.chapterType;
                  }

                  if (options.chapterNumbering.separator) {
                    data.s = options.chapterNumbering.separator;
                  }
                }

                if (options.position) {
                  data.p = options.position;
                }

                this.dataManager.captions.updateCaption(options.label, data);
                actionContext.jsonChanges = true;
              }
            }

            this.visualizerManager.selection.triggerSelectionChanged();

            // save changes to dom
            this.changeTracker.saveActionChanges(actionContext);
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      handleEditSelectedCaption(options: any = {}) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext;

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            actionContext = new ActionContext();
            const data: any = {};
            const caption = this.dataManager.captions.caption(options.label);
            let shouldUpdate = false;
            if (options.numberingType && options.numberingType !== caption.numbering) {
              data.nf = options.numberingType;
              shouldUpdate = true;
            }

            if (caption.chapter) {
              if (options.chapterNumbering) {
                data.c = options.chapterNumbering.chapterType;
                shouldUpdate = true;
                // eslint-disable-next-line no-empty
                if (options.chapterNumbering.separator !== caption.separator) {
                  if (options.force) {
                    data.s = options.chapterNumbering.separator;
                  } else {
                    const range = EditorSelectionUtils.getRange();

                    if (range) {
                      const fieldElement = EditorDOMUtils.closest(
                        range.commonAncestorContainer,
                        ELEMENTS.FieldElement.TAG,
                      );

                      if (fieldElement) {
                        const previous = fieldElement.previousSibling;
                        if (
                          previous &&
                          previous.textContent &&
                          previous.nodeType === Node.TEXT_NODE &&
                          previous.textContent !== options.chapterNumbering.separator
                        ) {
                          if (LABEL_SEPARATORS.includes(previous.textContent)) {
                            if (previous.parentNode) {
                              EditorDOMUtils.replaceNode(
                                previous.parentNode,
                                document.createTextNode(options.chapterNumbering.separator),
                                previous,
                                true,
                              );
                            }
                          } else {
                            if (previous.parentNode) {
                              EditorDOMUtils.insertNodeBefore(
                                previous.parentNode,
                                document.createTextNode(options.chapterNumbering.separator),
                                fieldElement,
                                true,
                              );
                            }
                          }
                          actionContext.addChangeUpdatedNode(fieldElement);
                          notify({
                            type: 'warning',
                            title: 'CAPTIONS_SEPARATOR_NOT_UPDATED',
                            message: 'CAPTIONS_SEPARATOR_NOT_UPDATED_MESSAGE',
                            messageValues: { captionLabel: caption.label },
                          });
                        }
                      }
                    }
                  }
                } else {
                  data.s = caption.separator;
                }
              } else {
                shouldUpdate = true;
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (options.chapterNumbering) {
                data.c = options.chapterNumbering.chapterType;
                data.s = options.chapterNumbering.separator;
                shouldUpdate = true;
              } else {
                // shouldUpdate = true;
              }
            }

            if (shouldUpdate) {
              this.dataManager.captions.updateCaption(options.label, data);
              actionContext.jsonChanges = true;
            }

            this.visualizerManager.selection.triggerSelectionChanged();

            // save changes to dom
            this.changeTracker.saveActionChanges(actionContext);
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      handleInsertCrossReferenceOnSelection(
        options: Editor.Data.CrossReferences.PresentationTextOptionsType,
      ) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext: ActionContext = new ActionContext();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            ReduxInterface.startEditorLoading();
            options.origin = EditorSelectionUtils.getSelectedLevel0NodesId()[0];
            this.dataManager.crossReferences
              .getAsyncCrossReferencePresentationText(options)
              .then((text: string) => {
                const fieldElement = DOMElementFactory.buildCrossReferenceElement(options);
                fieldElement.setContent(text);

                this.handleInsertInlineNode(fieldElement, actionContext);

                this.visualizerManager.selection.triggerSelectionChanged();

                // save changes to dom
                this.changeTracker.saveActionChanges(actionContext);
              });
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
            ReduxInterface.stopEditorLoading();
          }
        }
      }

      handleEditSelectedCrossReference(
        options: Editor.Data.CrossReferences.PresentationTextOptionsType,
      ) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext: ActionContext = new ActionContext();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            const range = EditorSelectionUtils.getRange();

            if (range) {
              const fieldElement = EditorDOMUtils.closest(
                range.commonAncestorContainer,
                ELEMENTS.FieldElement.TAG,
              ) as FieldElement;

              if (fieldElement) {
                ReduxInterface.startEditorLoading();
                options.origin = EditorSelectionUtils.getSelectedLevel0NodesId()[0];
                this.dataManager.crossReferences
                  .getAsyncCrossReferencePresentationText(options)
                  .then((text: string) => {
                    fieldElement.updateAttributes(options);

                    fieldElement.setContent(text);

                    actionContext.addChangeUpdatedNode(fieldElement);

                    // save changes to dom
                    this.changeTracker.saveActionChanges(actionContext);
                  });
              } else {
                this.visualizerManager.selection.triggerSelectionChanged();

                // save changes to dom
                this.changeTracker.saveActionChanges(actionContext);
              }
            }
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
            ReduxInterface.stopEditorLoading();
          }
        }
      }

      handleUpdateCrossReferenceOnSelection() {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext: ActionContext = new ActionContext();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            const range = EditorSelectionUtils.getRange();

            if (range) {
              const fieldElement = EditorDOMUtils.closest(
                range.commonAncestorContainer,
                ELEMENTS.FieldElement.TAG,
              ) as FieldElement;

              if (fieldElement) {
                ReduxInterface.startEditorLoading();
                this.dataManager.crossReferences
                  .getAsyncCrossReferencePresentationText({
                    target: fieldElement.fieldReference,
                    format: fieldElement.format,
                    origin: EditorSelectionUtils.getSelectedLevel0NodesId()[0],
                  })
                  .then((text: string) => {
                    fieldElement.setContent(text);

                    actionContext.addChangeUpdatedNode(fieldElement);

                    // save changes to dom
                    this.changeTracker.saveActionChanges(actionContext);
                  });
              } else {
                this.visualizerManager.selection.triggerSelectionChanged();

                // save changes to dom
                this.changeTracker.saveActionChanges(actionContext);
              }
            }
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
            ReduxInterface.stopEditorLoading();
          }
        }
      }
    },
);
