import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { PageElement } from '.';

export class PageViewBuilder {
  build(sectionId?: string): PageElement {
    const page = DOMElementFactory.buildElement(ELEMENTS.PageElement.TAG) as PageElement;
    page.setupPageStructure();

    if (sectionId != null) {
      page.section = sectionId;
    }

    return page;
  }
}
