import { useMemo, useState } from 'react';
import { Icon } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { selectFilteredNavigationElements } from 'Editor/redux/NavigationSlice';

import ElementLabel from 'Editor/components/ElementLabel/ElementLabel';
import { ELEMENTS } from 'Editor/services/consts';

import styles from './NavigationTab.module.scss';

type NavigationItemTreeProps = {
  elementId: Editor.ToCItem['id'];
  indent: number;
};

const getChildIndentation = (type: Editor.ToCItem['st'], indent: number) => {
  switch (type) {
    case ELEMENTS.ParagraphElement.BASE_STYLES.MAIN_TITLE:
    case ELEMENTS.ParagraphElement.BASE_STYLES.HEADING1:
      return 0;
    case ELEMENTS.ParagraphElement.BASE_STYLES.HEADING2:
      return 1;
    case ELEMENTS.ParagraphElement.BASE_STYLES.HEADING3:
      return 2;
    default:
      return indent + 1;
  }
};

const NavigationTreeItem = ({ elementId, indent }: NavigationItemTreeProps) => {
  const [isOpen, setOpen] = useState(true);
  const { data } = useSelector(selectFilteredNavigationElements);
  const element = data[elementId];
  const redacted = element.content === null;
  const currentIndent = getChildIndentation(element.st, indent);
  const [target, focus] = element.target?.split(':');
  const testId = useMemo(() => {
    let type = '';
    if (element) {
      if (!element.st) {
        type = element.type;
      } else if (element.type === 'f' && element.label) {
        type = element.label;
      } else {
        type = element.st;
      }
      return `navigation-item-${type}-${element.id}`;
    }
  }, [element]);
  return (
    <>
      <div className={styles.tree_item_container} data-testid={testId}>
        <div
          style={{ paddingLeft: `${currentIndent * 2}rem` }}
          className={`${styles.tree_item} ${styles[element.type]} ${redacted && styles.redacted}`}
          onClick={() => {
            EditorManager.getInstance().scrollIntoView(target, focus);
          }}
          title={element.content || ''}
        >
          <span className={styles.tree_itemTitle}>
            <span
              className={`${styles.twister} ${isOpen && styles.open} ${
                element.childNodes.length === 0 && styles.hidden
              }`}
              onClick={(e) => {
                setOpen(!isOpen);
                e.stopPropagation();
              }}
            >
              <Icon icon="AngleDownGrey" size={24} />
            </span>
            <ElementLabel element={element} />
          </span>
          {element.wc && <span className={styles.wordCount}>{element.wc.WORDS || 0}</span>}
        </div>
      </div>
      {isOpen &&
        element.childNodes.map((childId) => {
          return (
            <NavigationTreeItem key={childId} elementId={childId} indent={currentIndent + 1} />
          );
        })}
    </>
  );
};

export default NavigationTreeItem;
