import { useIntl } from 'react-intl';
import TagSystem from '_common/components/TagSystem/TagSystem';

import styles from './SettingTags.module.scss';

type SettingTagsProps = {
  groupId?: ObjectId;
  testId: string;
};

const SettingTags = ({ groupId, testId }: SettingTagsProps) => {
  const intl = useIntl();

  return (
    <div className={styles.setting}>
      <span className={styles.label}>{intl.formatMessage({ id: 'settings.general.tags' })}</span>

      {groupId && (
        <div className={styles.value}>
          <TagSystem
            objectId={groupId}
            placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
            testId={testId}
          />
        </div>
      )}
    </div>
  );
};

export default SettingTags;
