import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, Tooltip, usePopper, Toggle } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';
import { setSelectedNodesForApproval } from 'Editor/redux/BlockApprovalSlice';
import {
  selectReadOnlyMode,
  selectCanApprove,
  selectCanChangePermission,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { useSuiteObject } from '_common/suite';

type ReviewProps = {
  insertComment: () => void;
};

const Review = ({ insertComment }: ReviewProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const object = useSuiteObject();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const canApprove = useSelector(selectCanApprove);
  const canChangePermissions = useSelector(selectCanChangePermission);
  const isTextSelected = useSelector((state) => state.editor.status.selection.TEXT);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isEditorEditable = useSelector((state) => state.editor.status.editable);
  const isVersionHistory = useSelector((state) => state.editor.status.versionHistory);
  const isEnvision = useSelector(selectIsIEnvision);
  const initiatedWithTasks = useSelector((state) => state.editor.status.initiatedWithTasks);

  const canCreateTask = !isEnvision;
  const canAccessTasks = !isEnvision || initiatedWithTasks;

  const openSpellCheck = () => {
    dispatch(setSidebarView('SPELL_CHECK'));
  };
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  const createNewTask = () => {
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.openCreateTaskOverlay();
    }
  };

  const handleViewCommentsClicked = useCallback(() => {
    dispatch(setSidebarView('REVIEW'));
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
  }, []);

  const handleViewChangesClicked = useCallback(() => {
    dispatch(setSidebarView('REVIEW'));
    dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
  }, []);

  const handleViewPermissionsClicked = useCallback(() => {
    dispatch(setSidebarView('CONTENT_PERMISSIONS'));
  }, []);

  const handleApproveBlocksClicked = useCallback(() => {
    dispatch(setSidebarView('APPROVE_CONTENT'));
    dispatch(setSelectedNodesForApproval());
  }, []);

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={isOpen}
        margin="0 1rem 0 0"
        testId="review-button"
        {...referenceProps}
      >
        <FormattedMessage id="editor.menu.review.label" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-review">
        <Dropdown.Item onClick={handleViewCommentsClicked} testId="view-comments-dropdown-item">
          <FormattedMessage id="editor.menu.review.viewComments" />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleViewChangesClicked} testId="topbar-menu-review-view-changes">
          <FormattedMessage id="editor.menu.review.viewChanges" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          onClick={insertComment}
          disabled={
            !isTextSelected ||
            object.status === 'approved' ||
            isVersionHistory ||
            (isReadOnlyMode && !isPageLayout && !isEditorEditable)
          }
          testId="add-comment-dropdown-item"
        >
          <FormattedMessage id="editor.menu.review.addComment" />
        </Dropdown.Item>
        <Divider />
        <Tooltip
          content={intl.formatMessage({
            id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
          })}
          disabled={canApprove}
          testId="topbar-menu-review-approve-content-tooltip"
        >
          <span>
            <Dropdown.Item
              disabled={!canApprove}
              onClick={handleApproveBlocksClicked}
              testId="topbar-menu-review-approve-content"
            >
              <FormattedMessage id="editor.menu.review.approveContent" />
            </Dropdown.Item>
          </span>
        </Tooltip>
        <Tooltip
          disabled={canChangePermissions}
          content={intl.formatMessage({
            id: 'YOU_DO_NOT_HAVE_PERMISSIONS_TO_PERFORM_THIS_ACTION',
          })}
          testId="topbar-menu-review-content-permissions-tooltip"
        >
          <span>
            <Dropdown.Item
              disabled={!canChangePermissions}
              onClick={handleViewPermissionsClicked}
              testId="topbar-menu-review-content-permissions"
            >
              <FormattedMessage id="CONTENT_PERMISSIONS" />
            </Dropdown.Item>
          </span>
        </Tooltip>
        <Divider />
        <Tooltip
          content={intl.formatMessage({
            id: 'NO_PERMISSION_TO_PERFORM_ACTION',
          })}
          placement="right"
          disabled={canAccessTasks}
          testId="topbar-menu-review-view-tasks-tooltip"
        >
          <Dropdown.Item
            onClick={() => dispatch(setSidebarView('TASKS'))}
            testId="topbar-menu-review-view-tasks"
            disabled={!canAccessTasks}
          >
            <FormattedMessage id="VIEW_TASKS" />
          </Dropdown.Item>
        </Tooltip>
        <Tooltip
          content={intl.formatMessage({
            id: 'NO_PERMISSION_TO_PERFORM_ACTION',
          })}
          placement="right"
          disabled={canCreateTask}
          testId="topbar-menu-review--insert-task-tooltip"
        >
          <Dropdown.Item
            onClick={createNewTask}
            testId="topbar-menu-review-insert-tasks"
            disabled={!canCreateTask}
          >
            <FormattedMessage id="INSERT_TASKS" />
          </Dropdown.Item>
        </Tooltip>
        <Divider />
        <Dropdown.Item
          onClick={openSpellCheck}
          disabled={isReadOnlyMode}
          testId="topbar-menu-review-spell-check"
        >
          <FormattedMessage id="SPELL_CHECK" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Review;
