import { Divider as BaseDivider } from 'dodoc-design-system';
import styles from './Divider.module.scss';

const Divider = () => {
  return (
    <div className={styles.root}>
      <BaseDivider vertical margin="0 2rem 0 2rem" />
    </div>
  );
};

export default Divider;
