import { ReactNode } from 'react';
import cx from 'classnames';

import { IntlErrorBoundary } from '_common/components';

import styles from './Title.module.scss';

type TitleProps = {
  children: ReactNode;
  divider?: boolean;
};

const Title = ({ children, divider }: TitleProps) => {
  return (
    <IntlErrorBoundary size="small" margin="0 0 0 1rem">
      <div data-testid="suite-title" className={cx(styles.title, { [styles.divider]: divider })}>
        {children}
      </div>
    </IntlErrorBoundary>
  );
};

export default Title;
