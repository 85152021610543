import { FormattedMessage } from 'react-intl';
import { ProgressBar } from 'dodoc-design-system';

import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import ElementStatusTable from './ElementStatusTable';
import ElementHeading from './ElementHeading';

import styles from './ElementStatus.module.scss';

const ElementStatus = () => {
  const { isLoading } = useGetElementStatusListQuery();

  return (
    <div className={styles.elementStatusContainer}>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <ElementHeading />
      </IntlErrorBoundary>
      {isLoading ? (
        <div className={styles.loading}>
          <ProgressBar testId="status-loading-progressBar" />
          <div className={styles.message}>
            <FormattedMessage id="LOADING_STATUS" />
            ...
          </div>
        </div>
      ) : (
        <IntlErrorBoundary size="large" margin="10rem 0 0 0">
          <ElementStatusTable />
        </IntlErrorBoundary>
      )}
    </div>
  );
};

export default ElementStatus;
