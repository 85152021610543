import { useEffect, useMemo, useState } from 'react';

import EditorManager from 'Editor/services/EditorManager';
import { useEffectOnUpdate, useSelector } from '_common/hooks';

import LoadingState from './States/Loading/Loading';
import EndOfDocumentState from './States/EndOfDocument/EndOfDocument';
import UnsupportedLanguageState from './States/UnsupportedLanguage';
import EmptyDocumentState from './States/EmptyDocument';

import OccurrenceNavigator from './OccurrenceNavigator/OccurrenceNavigator';
import OccurrenceActions from './OccurrenceActions/OccurrenceActions';
import ParagraphLanguage from './ParagraphLanguage';
import SpellCheckActions from './SpellCheckActions';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';

type OccurrenceParams =
  | {
      again: true;
      next?: never;
      fromStart?: never;
    }
  | { again?: false; next?: boolean; fromStart?: boolean };

const ContentPanel = () => {
  const object = useSelector(getDocumentObject);
  const languageTrigger = useSelector((state) => state.editor.spellcheck.languageTrigger);
  const changedAllTrigger = useSelector((state) => state.editor.spellcheck.changedAllTrigger);

  const [occurrence, setOccurrence] = useState<Editor.SpellCheck.Occurrence>();
  const [endOfDocument, setEndOfDocument] = useState(false);
  const [loading, setLoading] = useState(true);

  const languages = useMemo(
    () => EditorManager.getInstance().getSpellCheckLanguages() as Services.Language[],
    [],
  );
  const [paragraphLanguage, setParagraphLanguage] = useState<Services.Language | undefined>();
  const documentLanguage = useMemo<Services.Language | undefined>(
    () => languages.find(({ value }) => value === object.lang?.code),
    [object, languages],
  );

  useEffect(() => {
    return () => {
      EditorManager.getInstance().endSpellcheckCycle();
    };
  }, []);

  // Document language has been changed
  useEffect(() => {
    findOccurrence({ next: true });
  }, [documentLanguage]);

  // Paragraph language has been changed
  useEffectOnUpdate(() => {
    findOccurrence({ again: true });
  }, [languageTrigger]);

  // ChangedAll has been successfully triggered
  useEffectOnUpdate(() => {
    handleFindNext();
  }, [changedAllTrigger]);

  // Set local paragraph language
  useEffect(() => {
    if (occurrence?.lang) {
      setParagraphLanguage(languages.find(({ value }) => value === occurrence.lang));
    } else {
      setParagraphLanguage(undefined);
    }
  }, [occurrence]);

  const findOccurrence = async ({
    next = false,
    fromStart = false,
    again = false,
  }: OccurrenceParams = {}) => {
    const manager = EditorManager.getInstance();

    setLoading(true);

    let promise;

    if (again && occurrence) {
      promise = manager.recheckError(occurrence.rangeData, occurrence.word);
    } else {
      promise = fromStart ? manager.runSpellCheckFromStart() : manager.findError(next);
    }

    try {
      const newOccurrence = await promise;

      setOccurrence(newOccurrence || undefined);

      if (next && !newOccurrence) {
        setEndOfDocument(true);
      }
    } catch (e) {
      setOccurrence(undefined);
    }

    setLoading(false);
  };

  const handleFindPrevious = () => {
    findOccurrence();
  };

  const handleFindNext = (fromStart = false) => {
    findOccurrence({ next: true, fromStart });
    setEndOfDocument(false);
  };

  const setLanguage = (language: Services.Language['value']) => {
    if (paragraphLanguage?.supported) {
      if (occurrence?.blockId && language) {
        EditorManager.getInstance().setLanguageOnNodes(language, [occurrence.blockId]);
      }
    } else {
      EditorManager.getInstance().setLanguageOnDocument(language);
    }
  };

  const renderOccurenceUI = () => {
    if (EditorManager.getInstance().isPageEmpty()) {
      return <EmptyDocumentState />;
    }

    // Either paragraph or object language is supported
    if (paragraphLanguage?.supported || documentLanguage?.supported) {
      return (
        <>
          <OccurrenceNavigator
            occurrence={occurrence}
            handleFindPrevious={handleFindPrevious}
            handleFindNext={handleFindNext}
          />

          <OccurrenceActions occurrence={occurrence} handleFindNext={handleFindNext} />
        </>
      );
    }

    return <UnsupportedLanguageState />;
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <>
      {endOfDocument && !EditorManager.getInstance().isPageEmpty() ? (
        <EndOfDocumentState />
      ) : (
        <>
          {renderOccurenceUI()}
          <ParagraphLanguage
            currentLanguage={paragraphLanguage || documentLanguage}
            setLanguage={setLanguage}
            languages={languages}
          />
        </>
      )}

      <SpellCheckActions handleFindNext={handleFindNext} />
    </>
  );
};

export default ContentPanel;
