import { NodeUtils } from 'Editor/services/DataManager';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';
import { JsonRange } from 'Editor/services/_Common/Selection';

type Timeouts = {
  [index: string]: NodeJS.Timeout;
};

const BLOCK_STYLES: Editor.Edition.Styles[] = [
  'bold',
  'italic',
  'fontFamily',
  'fontSize',
  'color',
  'underline',
  'strikethrough',
  'superscript',
  'subscript',
  'vanish',
  'paragraphColor',
  'highlightColor',
  'spaceBefore',
  'spaceAfter',
  'alignment',
  'lineHeight',
  'leftIndentation',
  'rightIndentation',
  'specialIndent',
  'specialIndentValue',
  'listId',
  'listLevel',
  'asb',
  'asa',
  'wc',
  'kn',
  'kl',
  'cts',
  'pbb',
  'otl',
];

const INLINE_STYLES: Editor.Edition.Styles[] = [
  'bold',
  'italic',
  'fontFamily',
  'fontSize',
  'color',
  'highlightColor',
  'underline',
  'strikethrough',
  'superscript',
  'subscript',
  'vanish',
];

const INLINE_BOOLEAN_STYLES: Editor.Edition.InlineStyles[] = ['bold', 'italic'];
const INLINE_SINGLE_STATE_STYLES: Editor.Edition.InlineStyles[] = [
  'strikethrough',
  'superscript',
  'subscript',
  'underline',
  'vanish',
];
const INLINE_EXCLUSIVE_STYLES: {
  [index in Editor.Edition.InlineStyles]?: Editor.Edition.InlineStyles;
} = {
  subscript: 'superscript',
  superscript: 'subscript',
};

const ALLOWED_STYLES_BY_BLOCK: Editor.Edition.BlockStylesList = {
  p: [
    'bold',
    'italic',
    'fontFamily',
    'fontSize',
    'color',
    'underline',
    'strikethrough',
    'superscript',
    'subscript',
    'vanish',
    'paragraphColor',
    'highlightColor',
    'spaceBefore',
    'spaceAfter',
    'alignment',
    'lineHeight',
    'leftIndentation',
    'rightIndentation',
    'specialIndent',
    'specialIndentValue',
    'listId',
    'listLevel',
    'asb',
    'asa',
    'wc',
    'kn',
    'kl',
    'cts',
    'pbb',
    'otl',
  ],
  tbl: ['vanish'],
  figure: ['vanish'],
  toc: ['vanish'],
  tol: ['vanish'],
  tof: ['vanish'],
  tot: ['vanish'],
  k: ['vanish'],
  a: ['vanish'],
} as const;

const ALLOWED_STYLES_INLINE: Editor.Edition.InlineStylesList = {
  p: [
    'bold',
    'italic',
    'fontFamily',
    'fontSize',
    'color',
    'highlightColor',
    'underline',
    'strikethrough',
    'superscript',
    'subscript',
    'vanish',
  ],
} as const;

const INDENTATION_LIMITS: Editor.Edition.IndentationLimits = {
  INDENTATION_STEP: 36,
  LEFT: {
    MANIPULATION_MIN: -1440,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: -72,
    RENDER_MAX: 216,
  },
  RIGHT: {
    MANIPULATION_MIN: -1440,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: -72,
    RENDER_MAX: 216,
  },
  SPECIAL_INDENT: {
    MANIPULATION_MIN: 0,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: 0,
    RENDER_MAX: 216,
  },
} as const;

export class StylesManager {
  private context: Editor.Edition.Context;

  private stylesToApply: Editor.Edition.InlineStylesMap = {};
  private stylesToRemove: Editor.Edition.InlineStylesMap = {};

  private timeouts: Timeouts = {};

  private debug = false;

  constructor(editionContext: Editor.Edition.Context) {
    this.context = editionContext;

    this.onSelectionUpdate = this.onSelectionUpdate.bind(this);
  }

  initialize() {
    this.context.DataManager?.on('SELECTION_UPDATED', this.onSelectionUpdate);
  }

  destroy() {
    this.context.DataManager?.off('SELECTION_UPDATED', this.onSelectionUpdate);
  }

  static get INDENTATION_LIMITS() {
    return INDENTATION_LIMITS;
  }

  readonly BlockStylesParser: Editor.Edition.BlockStylesParser = {
    fontFamily: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline) {
          if (blockData.properties.ff != null) {
            return blockData.properties.ff;
          }
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.fontfamily != null) {
            return docStyle.extendedP.fontfamily;
          }
        }
      }

      return undefined;
    },
    fontSize: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline) {
          if (blockData.properties.fs != null) {
            return blockData.properties.fs;
          }
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.fontsize != null) {
            return docStyle.extendedP.fontsize;
          }
        }
      }

      return undefined;
    },
    color: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      // TODO convert colors from data????
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline) {
          if (blockData.properties.c != null) {
            return blockData.properties.c;
          }
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.color != null) {
            return docStyle.extendedP.color;
          }
        }
      }

      return undefined;
    },
    paragraphColor: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        // if (blockData.properties.bg != null) {
        //   return blockData.properties.bg;
        // } else
        if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.bg != null) {
            return docStyle.extendedP.bg;
          }
        }
      }

      return undefined;
    },
    highlightColor: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline && blockData.properties.bg != null) {
          return blockData.properties.bg;
        }
      }

      return undefined;
    },
    bold: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline) {
          if (blockData.properties.b != null) {
            return blockData.properties.b;
          }
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.bold != null) {
            return docStyle.extendedP.bold;
          }
        }
      }

      return undefined;
    },
    italic: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline) {
          if (blockData.properties.i != null) {
            return blockData.properties.i;
          }
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.italic != null) {
            return docStyle.extendedP.italic;
          }
        }
      }

      return undefined;
    },
    underline: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline) {
          if (blockData.properties.u != null) {
            return blockData.properties.u;
          }
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.underline != null) {
            return docStyle.extendedP.underline;
          }
        }
      }

      return undefined;
    },
    strikethrough: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline && blockData.properties.strikethrough != null) {
          return blockData.properties.strikethrough;
        }
      }

      return undefined;
    },
    superscript: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline && blockData.properties.sps != null) {
          return blockData.properties.sps;
        }
      }
      return undefined;
    },
    subscript: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (onlyInline && blockData.properties.sbs != null) {
          return blockData.properties.sbs;
        }
      }
      return undefined;
    },
    alignment: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.a != null) {
          return blockData.properties.a;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.a != null) {
            return docStyle.extendedP.a;
          }
        }
      }

      return undefined;
    },
    lineHeight: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.lh != null) {
          return blockData.properties.lh;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.lh != null) {
            return docStyle.extendedP.lh;
          }
        }
      }

      return undefined;
    },
    spaceBefore: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.sb != null) {
          return blockData.properties.sb;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.sb != null) {
            return docStyle.extendedP.sb;
          }
        }
      }

      return undefined;
    },
    spaceAfter: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.sa != null) {
          return blockData.properties.sa;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.sa != null) {
            return docStyle.extendedP.sa;
          }
        }
      }

      return undefined;
    },
    leftIndentation: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.ind?.l != null) {
          return blockData.properties.ind.l;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.ind?.l != null) {
            return docStyle.extendedP.ind.l;
          }
        }
      }

      return undefined;
    },
    rightIndentation: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.ind?.r != null) {
          return blockData.properties.ind.r;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.ind?.r != null) {
            return docStyle.extendedP.ind.r;
          }
        }
      }

      return undefined;
    },
    specialIndent: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.sp_ind?.t != null) {
          return blockData.properties.sp_ind?.t;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.sp_ind?.t != null) {
            return docStyle.extendedP.sp_ind.t;
          }
        }
      }

      return undefined;
    },
    specialIndentValue: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.sp_ind?.v != null) {
          return blockData.properties.sp_ind?.v;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.sp_ind?.v != null) {
            return docStyle.extendedP.sp_ind.v;
          }
        }
      }

      return undefined;
    },
    vanish: (blockData: Editor.Data.Node.Data, onlyInline: boolean = false) => {
      if (onlyInline) {
        if (blockData.properties?.v != null) {
          return blockData.properties.v;
        }
      } else if (blockData.properties?.s != null) {
        const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
          blockData.properties.s,
        );
        if (docStyle?.extendedP?.v != null) {
          return docStyle.extendedP.v;
        }
      }

      return undefined;
    },
    listId: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData) && blockData.id) {
        if (this.context.DataManager?.numbering.isListElement(blockData.id)) {
          return this.context.DataManager?.numbering.getListIdFromBlock(blockData.id);
        } else if (blockData.properties?.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.lst?.lId != null) {
            return docStyle?.extendedP.lst.lId; // TODO check this
          }
        }
      }

      return undefined;
    },
    listLevel: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData) && blockData.id) {
        if (this.context.DataManager?.numbering.isListElement(blockData.id)) {
          let level = this.context.DataManager?.numbering.getListLevelFromBlock(blockData.id);
          if (level) {
            return level;
          }
        } else if (blockData.properties?.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.lst?.lLv != null) {
            return docStyle?.extendedP.lst.lLv; // TODO check this
          }
        }
      }

      return undefined;
    },
    asb: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.asb != null) {
          return blockData.properties.asb;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.asb != null) {
            return docStyle.extendedP.asb;
          }
        }
      }

      return undefined;
    },
    asa: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.asa != null) {
          return blockData.properties.asa;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.asa != null) {
            return docStyle.extendedP.asa;
          }
        }
      }

      return undefined;
    },
    wc: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.wc != null) {
          return blockData.properties.wc;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.wc != null) {
            return docStyle.extendedP.wc;
          }
        }
      }

      return undefined;
    },
    kn: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.kn != null) {
          return blockData.properties.kn;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.kn != null) {
            return docStyle.extendedP.kn;
          }
        }
      }

      return undefined;
    },
    kl: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.kl != null) {
          return blockData.properties.kl;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.kl != null) {
            return docStyle.extendedP.kl;
          }
        }
      }

      return undefined;
    },
    cts: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.cts != null) {
          return blockData.properties.cts;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.cts != null) {
            return docStyle.extendedP.cts;
          }
        }
      }

      return undefined;
    },
    pbb: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.pbb != null) {
          return blockData.properties.pbb;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.pbb != null) {
            return docStyle.extendedP.pbb;
          }
        }
      }

      return undefined;
    },
    otl: (blockData: Editor.Data.Node.Data) => {
      if (NodeUtils.isParagraphData(blockData)) {
        if (blockData.properties.otl != null) {
          return blockData.properties.otl;
        } else if (blockData.properties.s != null) {
          const docStyle = this.context.DataManager?.styles.getDocumentStyleFromId(
            blockData.properties.s,
          );
          if (docStyle?.extendedP?.otl != null) {
            return docStyle.extendedP.otl;
          }
        }
      }

      return undefined;
    },
  };

  readonly InlineStylesParser: Editor.Edition.InlineStylesParser = {
    bold: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.bold != null ? formatData.properties.bold : undefined,
    color: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.color != null ? formatData.properties.color : undefined,
    italic: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.italic != null ? formatData.properties.italic : undefined,
    underline: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.underline != null ? formatData.properties.underline : undefined,
    strikethrough: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.strikethrough != null ? formatData.properties.strikethrough : undefined,
    superscript: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.superscript != null ? formatData.properties.superscript : undefined,
    subscript: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.subscript != null ? formatData.properties.subscript : undefined,
    vanish: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.v != null ? formatData.properties.v : undefined,
    highlightColor: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.backgroundcolor != null
        ? formatData.properties.backgroundcolor
        : undefined,
    fontFamily: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.fontfamily != null ? formatData.properties.fontfamily : undefined,
    fontSize: (formatData: Editor.Data.Node.FormatData) =>
      formatData.properties.fontsize != null ? formatData.properties.fontsize : undefined,
  };

  get STYLES(): Editor.Edition.Styles[] {
    return [...BLOCK_STYLES, 'highlightColor'];
  }

  get STYLABLE_BLOCKS() {
    return Object.keys(ALLOWED_STYLES_BY_BLOCK) as Editor.Data.Node.BlockDataTypes[];
  }

  get INLINE_EXCLUSIVE_STYLES() {
    return INLINE_EXCLUSIVE_STYLES;
  }

  updateStyleToRedux(styles: Editor.Edition.StylesMap) {
    if (this.timeouts.updateStyleValues) {
      clearTimeout(this.timeouts.updateStyleValues);
    }

    this.timeouts.updateStyleValues = setTimeout(() => {
      //@ts-expect-error
      ReduxInterface.updateToolbarValues(styles);
    }, 50);
  }

  isBlockStyle(style: Editor.Edition.Styles): style is Editor.Edition.BlockStyles {
    return BLOCK_STYLES.includes(style);
  }

  isAllowedBlockStyle(
    type: Editor.Data.Node.DataTypes,
    style: Editor.Edition.Styles,
  ): style is Editor.Edition.BlockStyles {
    return (
      NodeUtils.isBlockDataType(type) &&
      this.isBlockStyle(style) &&
      !!ALLOWED_STYLES_BY_BLOCK[type]?.includes(style)
    );
  }

  isInlineStyle(style: Editor.Edition.Styles): style is Editor.Edition.InlineStyles {
    return INLINE_STYLES.includes(style);
  }

  isAllowedInlineStyle(
    type: Editor.Data.Node.DataTypes,
    style: Editor.Edition.Styles,
  ): style is Editor.Edition.InlineStyles {
    return (
      NodeUtils.isBlockDataType(type) &&
      this.isInlineStyle(style) &&
      !!ALLOWED_STYLES_INLINE[type]?.includes(style)
    );
  }

  isInlineBooleanStyle(style: Editor.Edition.Styles): style is Editor.Edition.InlineStyles {
    return this.isInlineStyle(style) && INLINE_BOOLEAN_STYLES.includes(style);
  }

  isInlineExlusiveStyle(style: Editor.Edition.Styles): style is Editor.Edition.InlineStyles {
    return this.isInlineStyle(style) && !!INLINE_EXCLUSIVE_STYLES[style];
  }

  isInlineSingleStateStyle(style: Editor.Edition.Styles): style is Editor.Edition.InlineStyles {
    return this.isInlineStyle(style) && INLINE_SINGLE_STATE_STYLES.includes(style);
  }

  private getBlockStyles(
    baseData: Editor.Data.Node.Data,
    pathToElement: Editor.Selection.Path,
    stylesToCheck: Editor.Edition.Styles[],
  ): Editor.Edition.StylesMap {
    let pathToCheck = [...pathToElement];

    let blockStyles: Editor.Edition.StylesMap = {};

    let closesStylable = NodeUtils.closestOfTypeByPath(baseData, pathToCheck, this.STYLABLE_BLOCKS);

    while (closesStylable && NodeUtils.isBlockDataType(closesStylable.data.type)) {
      for (let i = 0; i < stylesToCheck.length; i++) {
        const styleProp = stylesToCheck[i];

        if (
          this.isAllowedBlockStyle(closesStylable.data.type, styleProp) &&
          blockStyles[styleProp] == null
        ) {
          const styleValue = this.BlockStylesParser[styleProp](closesStylable.data);

          if (styleValue != null) {
            //@ts-expect-error
            blockStyles[styleProp] = styleValue;
          }
        }
      }

      if (pathToCheck.length > 0) {
        pathToCheck = pathToCheck.slice(0, pathToCheck.length - 2);
        closesStylable = NodeUtils.closestOfTypeByPath(baseData, pathToCheck, this.STYLABLE_BLOCKS);
      } else {
        closesStylable = null;
      }
    }

    return blockStyles;
  }

  private onSelectionUpdate(
    ranges: Editor.Selection.RangeData[],
    source: Realtime.Core.RealtimeSourceType,
  ) {
    if (this.debug) {
      logger.trace('StylesManager onSelectionUpdate');
    }
    if (source === 'LOCAL_RENDER' || source === 'LOCAL_RENDER_OLD' || source === true) {
      // reset pending styles
      this.stylesToApply = {};
      this.stylesToRemove = {};
    }
  }

  private getInlineStyles(
    baseData: Editor.Data.Node.Data,
    pathToElement: Editor.Selection.Path,
    stylesToCheck: Editor.Edition.Styles[],
  ): Editor.Edition.StylesMap {
    let pathToCheck = [...pathToElement];

    let inlineStyles: Editor.Edition.StylesMap = {};

    let elementsToSearch: Editor.Data.Node.DataTypes[] = ['format'];
    let closesStylable = NodeUtils.closestOfTypeByPath(baseData, pathToCheck, elementsToSearch);

    while (
      closesStylable &&
      NodeUtils.isFormatData(closesStylable.data) &&
      pathToCheck.length > 0
    ) {
      for (let i = 0; i < stylesToCheck.length; i++) {
        const styleProp = stylesToCheck[i];

        if (
          this.isAllowedInlineStyle(closesStylable.data.type, styleProp) &&
          inlineStyles[styleProp] == null
        ) {
          const styleValue = this.InlineStylesParser[styleProp](closesStylable.data);

          if (styleValue != null) {
            //@ts-expect-error
            inlineStyles[styleProp] = styleValue;
          }
        }
      }

      pathToCheck = pathToCheck.slice(0, pathToCheck.length - 2);
      closesStylable = NodeUtils.closestOfTypeByPath(baseData, pathToCheck, elementsToSearch);
    }

    return inlineStyles;
  }

  getStylesAppliedToContent(
    baseData: Editor.Data.Node.Data,
    pathToElement: Editor.Selection.Path,
    stylesToCheck: Editor.Edition.Styles[],
  ): Editor.Edition.StylesMap {
    // const elementToCheck = NodeUtils.getChildDataByPath(baseData, pathToElement);

    const inlineStyles = this.getInlineStyles(baseData, pathToElement, stylesToCheck);
    const blockStyles = this.getBlockStyles(baseData, pathToElement, stylesToCheck);

    const mergedStyles = {
      ...blockStyles,
      ...inlineStyles,
    };

    if (this.debug) {
      logger.info('StylesManager getStylesAppliedToContent', mergedStyles);
    }

    return mergedStyles;
  }

  getStylesAppliedToData(data: Editor.Data.Node.Data, stylesType: 'BLOCK' | 'INLINE') {
    let stylesToCheck: Editor.Edition.Styles[] = [];

    if (stylesType === 'BLOCK') {
      stylesToCheck = [...BLOCK_STYLES];
    } else {
      stylesToCheck = [...INLINE_STYLES];
    }

    let stylesMap: Editor.Edition.StylesMap = {};

    if (NodeUtils.isBlockDataType(data.type) && this.STYLABLE_BLOCKS.includes(data.type)) {
      for (let i = 0; i < stylesToCheck.length; i++) {
        const styleProp = stylesToCheck[i];

        if (stylesMap[styleProp] == null) {
          if (stylesType === 'BLOCK' && this.isAllowedBlockStyle(data.type, styleProp)) {
            const styleValue = this.BlockStylesParser[styleProp](data);

            if (styleValue != null) {
              //@ts-expect-error
              stylesMap[styleProp] = styleValue;
            }
          } else if (stylesType === 'INLINE' && this.isAllowedInlineStyle(data.type, styleProp)) {
            const styleValue = this.BlockStylesParser[styleProp](data, true);

            if (styleValue != null) {
              //@ts-expect-error
              stylesMap[styleProp] = styleValue;
            }
          }
        }
      }
    } else if (NodeUtils.isFormatData(data)) {
      for (let i = 0; i < stylesToCheck.length; i++) {
        const styleProp = stylesToCheck[i];

        if (this.isInlineStyle(styleProp) && stylesMap[styleProp] == null) {
          const styleValue = this.InlineStylesParser[styleProp](data);

          if (styleValue != null) {
            //@ts-expect-error
            stylesMap[styleProp] = styleValue;
          }
        }
      }
    }

    return stylesMap;
  }

  havePendingStyles() {
    return this.havePendingStylesToApply() || this.havePendingStylesToRemove();
  }

  havePendingStylesToApply() {
    return Object.keys(this.stylesToApply).length > 0;
  }

  havePendingStylesToRemove() {
    return Object.keys(this.stylesToRemove).length > 0;
  }

  private addStyleToApply<T extends Editor.Edition.Styles>(
    style: T,
    value: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    if (!this.isInlineStyle(style)) {
      return false;
    }

    let exclusiveStyle: Editor.Edition.InlineStyles | undefined = INLINE_EXCLUSIVE_STYLES[style];

    if (INLINE_BOOLEAN_STYLES.includes(style) && this.stylesToApply[style] != null) {
      if (this.stylesToApply[style] != null) {
        // already exists
        if (this.stylesToApply[style] !== value) {
          // oposite value, just remove
          delete this.stylesToApply[style];
        }
      }
    } else if (exclusiveStyle != null && this.stylesToApply[exclusiveStyle] != null) {
      delete this.stylesToApply[exclusiveStyle];
      //@ts-ignore remove this in the future when typescript-eslint is updated
      this.stylesToApply[style] = value;
    } else {
      //@ts-ignore remove this in the future when typescript-eslint is updated
      this.stylesToApply[style] = value;
    }

    this.updateStyleToRedux({
      [style]: value,
    });

    return true;
  }

  addStylesToApply(styles: Editor.Edition.StylesMap) {
    const styleKeys = Object.typedKeys(styles);

    for (let s = 0; s < styleKeys.length; s++) {
      const style = styleKeys[s];
      const value = styles[style];

      if (value != null) {
        this.addStyleToApply(style, value);
      }
    }
  }

  async applyPendingStyles(
    range?: Editor.Selection.JsonRange,
    options?: Editor.Edition.StylesCommandOptions,
  ) {
    if (!this.context.commandFactory) {
      return;
    }

    if (this.havePendingStylesToRemove()) {
      const command = this.context.commandFactory.getCommand(
        'REMOVE_STYLES_COMMAND',
        this.stylesToRemove,
        range,
        options,
      );
      if (command) {
        await command.exec();
      }

      this.stylesToRemove = {};
    }

    if (this.havePendingStylesToApply()) {
      // exec apply styles command
      const command = this.context.commandFactory.getCommand(
        'APPLY_STYLES_COMMAND',
        this.stylesToApply,
        range,
        options,
      );
      if (command) {
        await command.exec();
      }

      this.stylesToApply = {};
    }
  }

  async toggleStyleValue<T extends Editor.Edition.Styles>(
    style: T,
    value: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    if (this.debug) {
      logger.info('StylesManager toggleStyleValue', style, value);
    }

    if (!this.context.DataManager) {
      return;
    }

    if (!this.context.untrackedActionWarning()) {
      return;
    }

    // restore selection to the browser
    this.context.DataManager.selection.restore();

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    if (value != null) {
      this.addStyleToApply(style, value);
    }

    if (jsonRange.collapsed) {
      // collapsed selection

      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
      const baseData = baseModel?.selectedData();

      if (baseData) {
        const closestTableData = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, [
          'tbl',
        ]);

        if (closestTableData && closestTableData.data.id) {
          const tableElement = document.getElementById(closestTableData.data.id);
          if (EditorDOMElements.isTableElement(tableElement)) {
            const selectedCells = tableElement.getSelectedCells();

            if (selectedCells.length > 1) {
              // apply styles if multiple cells are selected
              await this.applyPendingStyles(undefined, { cellSelection: true });
            }
          }
        }
      }
      // TODO aply styles directly to non-editable block elements?
    } else {
      // non-collapsed selection
      await this.applyPendingStyles();
    }
  }

  async removeStyleValue<T extends Editor.Edition.Styles>(
    style: T,
    value?: Exclude<Editor.Edition.StylesMap[T], undefined>,
  ) {
    if (this.debug) {
      logger.info('StylesManager removeStyleValue', style, value);
    }

    if (!this.context.DataManager) {
      return;
    }

    if (!this.isInlineStyle(style)) {
      return;
    }

    if (!this.context.untrackedActionWarning()) {
      return;
    }

    // restore selection to the browser
    this.context.DataManager.selection.restore();

    const rangeData = this.context.DataManager.selection.current;
    const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

    //@ts-ignore remove this in the future when typescript-eslint is updated
    this.stylesToRemove[style] = value;

    if (jsonRange.collapsed) {
      // collapsed selection

      const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
      const baseData = baseModel?.selectedData();

      if (baseData) {
        const closestTableData = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, [
          'tbl',
        ]);

        if (closestTableData && closestTableData.data.id) {
          const tableElement = document.getElementById(closestTableData.data.id);
          if (EditorDOMElements.isTableElement(tableElement)) {
            const selectedCells = tableElement.getSelectedCells();

            if (selectedCells.length > 1) {
              // apply styles if multiple cells are selected
              await this.applyPendingStyles(undefined, { cellSelection: true });
            }
          }
        }
      }
      // TODO aply styles directly to non-editable block elements?
    } else {
      // non-collapsed selection
      await this.applyPendingStyles();
    }
  }
}
