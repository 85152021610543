import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import LogoContainer from '_common/components/LogoContainer/LogoContainer';
import styles from './MobileNotSupported.module.scss';

const onResize = () => {
  const root = document.querySelector(':root') as HTMLElement;
  if (root) {
    root.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
};

const MobileNotSupported = () => {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });
  return (
    <div className={styles.root}>
      <LogoContainer expanded size="large" color="white" testId="mobile-not-supported-logo" />
      <div className={styles.title}>
        <FormattedMessage id="MOBILE_NOT_SUPPORTED_TITLE" />
      </div>
      <div className={styles.text}>
        <p>
          <FormattedMessage id="MOBILE_NOT_SUPPORTED_DESC1" />
        </p>
      </div>
    </div>
  );
};

export default MobileNotSupported;
