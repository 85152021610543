import { useEffect, memo, useRef, useState, useMemo } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { useFilteredComments, useFilteredTasks, useFocusCard } from 'Presentation/hooks';

import { setSelectedCard } from 'Presentation/PresentationSlice';

import CardToggle from '../../CardToggle/CardToggle';

import styles from './Comments.module.scss';

const Comments = ({ slideId }: { slideId: string }) => {
  const dispatch = useDispatch();
  const { focusCard } = useFocusCard();
  const slideComments = useFilteredComments(slideId);
  const slideTasks = useFilteredTasks(slideId);

  const creating = useSelector((state) => state.presentation.general.creating);
  const listRef = useRef<HTMLDivElement>(null);

  const tasksAndComments = useMemo(() => {
    const comments: (Presentation.Data.Comment & { type: 'comment' })[] = slideComments
      .filter((comment) => comment.anchor.length === 1) //Only slide anchor
      .map((comment) => {
        return { ...comment, type: 'comment' };
      });

    const tasks: (Presentation.Data.Task & { type: 'task'; time: string })[] = slideTasks
      .filter((task) => task.anchor.length === 1) //Only slide anchor
      .map((task) => {
        return { ...task, time: task.creationDate, type: 'task' };
      });

    return [...comments, ...tasks].sort((a, b) =>
      a.time !== '' && b.time !== '' ? new Date(b.time).getTime() - new Date(a.time).getTime() : 1,
    );
  }, [slideComments, slideTasks]);

  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);

  const [right, setRight] = useState<number | undefined>();

  useEffect(() => {
    setTimeout(() => {
      if (listRef?.current) {
        const current = listRef.current;
        const scrollDelta = current.offsetWidth - current.scrollWidth;
        const toggleWidth = 32;
        const padding = 8;
        setRight(-((current.scrollWidth || toggleWidth) + scrollDelta + padding));
      }
    }, 0);
  }, [tasksAndComments.length]);

  const handleToggleClick = ({
    objectId,
    type,
  }: {
    objectId: string;
    type: 'task' | 'comment';
  }) => {
    switch (type) {
      case 'task': {
        if (selectedCard === objectId) {
          dispatch(setSelectedCard(null));
        } else {
          focusCard({ objectId, objectType: type });
        }
        break;
      }
      case 'comment': {
        if (selectedCard === objectId) {
          dispatch(setSelectedCard(null));
        } else {
          focusCard({ objectId, objectType: type });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className={styles.root} style={{ right: `${right}px` }}>
      <div className={styles.list} ref={listRef}>
        {creating?.slide && creating?.type && (
          <CardToggle type={creating.type} mode="create" isOpen placement="right-start" />
        )}
        {tasksAndComments.map((element, i) => {
          return (
            <div key={i}>
              <CardToggle
                type={element.type}
                object={element}
                isOpen={selectedCard === element.id}
                placement="right-start"
                onClick={() => {
                  handleToggleClick({ objectId: element.id, type: element.type });
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Comments);
