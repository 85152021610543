import { useIntl } from 'react-intl';
import { Divider } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import TagSystem from '_common/components/TagSystem/TagSystem';

import styles from './Tags.module.scss';

const Tags = () => {
  const intl = useIntl();

  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];

  return (
    <div className={styles.root}>
      <Divider margin="0 1rem" />
      <div className={styles.tagsWrapper}>
        <TagSystem
          objectId={elementId}
          menuPlacement="top"
          placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
          testId="information-panel"
        />
      </div>
    </div>
  );
};

export default Tags;
