import { useSlideData } from '../SlideData';

type SolidBackgroundProps = {
  background: Presentation.Data.Common.Color;
  size: Presentation.Data.Common.Size;
  position: Presentation.Data.Common.Position;
};

const SolidBackground = ({ background, size, position }: SolidBackgroundProps) => {
  const { color } = useSlideData();
  return (
    <rect
      x={position.left}
      y={position.top}
      width={size.width}
      height={size.height}
      fill={color(background)}
    />
  );
};

export default SolidBackground;
