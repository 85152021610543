import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { DocumentTitle, ErrorView, Sidebar } from '_common/components';

import TenantSettingsPage from './pages/TenantSettingsPage/TenantSettingsPage';
import UserSettingsPage from './pages/UserSettingsPage/UserSettingsPage';
import ObjectSettingsPage from './pages/ObjectSettingsPage/ObjectSettingsPage';
import GroupSettingsPage from './pages/GroupSettingsPage/GroupSettingsPage';
import RoleSettingsPage from './pages/RoleSettingsPage/RoleSettingsPage';

import styles from './SettingsContainer.module.scss';
import { navigateToMyFiles } from 'router/history';

const SettingsContainer = () => {
  const intl = useIntl();

  const match = useRouteMatch();

  return (
    <div className={styles.root}>
      <Sidebar />
      <Switch>
        <Route exact path={match.path}>
          <DocumentTitle
            title={`doDOC - ${intl.formatMessage({
              id: 'global.settings',
            })}`}
          >
            <TenantSettingsPage />
          </DocumentTitle>
        </Route>
        <Route exact path={`${match.path}/user`}>
          <DocumentTitle
            title={`doDOC - ${intl.formatMessage({
              id: 'global.userPreferences',
            })}`}
          >
            <UserSettingsPage />
          </DocumentTitle>
        </Route>
        <Route
          exact
          path={`${match.path}/:type(space|folder|document|file|dopdf|presentation)/:id`}
        >
          <ObjectSettingsPage />
        </Route>
        <Route exact path={`${match.path}/group/:id`}>
          <GroupSettingsPage />
        </Route>
        <Route exact path={`${match.path}/role/:id`}>
          <RoleSettingsPage />
        </Route>
        <Route path="*">
          <ErrorView
            error={{
              status: 404,
            }}
            onClick={navigateToMyFiles}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default SettingsContainer;
