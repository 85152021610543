import { FormattedMessage } from 'react-intl';

import { PanelContent, PanelHeading } from '_common/suite/components';
import NotesPanel from './NotesPanel/NotesPanel';

const ReferencesTab = () => (
  <>
    <PanelHeading>
      <FormattedMessage id="NOTES_PANEL_TITLE" />
    </PanelHeading>
    <PanelContent testId="notes-panel">
      <NotesPanel />
    </PanelContent>
  </>
);

export default ReferencesTab;
