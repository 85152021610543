/* eslint-disable consistent-return */
const MEASURE_RATIO = Object.freeze({
  cm: {
    cm: 1,
    pt: 28.346456693,
    in: 0.393701,
    rem: 2.3622050220101,
  },
  in: {
    in: 1,
    pt: 72,
    cm: 2.54,
    rem: 6.0000007559056,
  },
  px: {
    px: 1,
    pt: 0.75,
    cm: 0.0264583333,
    rem: 0.063,
  },
  pt: {
    pt: 1,
    cm: 0.0352777778,
    rem: 0.0833,
  },
  twipp: {
    twipp: 1,
    cm: 0.001764,
    pt: 0.05,
  },
  rem: {},
  il: {
    twipps: 360,
    cm: 0.63504,
  },
});

export default class Units {
  static TYPE = {
    CM: 'cm',
    INCH: 'in',
    POINT: 'pt',
    PIXEL: 'px',
    TWIPP: 'twipp',
    REM: 'rem',
    INDENT_LEVEL: 'il',
  };

  static convert(from, to, value) {
    const _value = parseFloat(value, 10);
    if (Number.isNaN(_value)) {
      throw new Error(`Invalid value provided : ${value}`);
    }
    if (!from.match(/^(cm|in|pt|px|twipp|rem|il)$/)) {
      throw new Error(`Invalid origin unit provided : ${from}`);
    }
    if (!to.match(/^(cm|in|pt|px|twipp|rem|il)$/)) {
      throw new Error(`Invalid destination unit provided : ${to}`);
    }
    if (MEASURE_RATIO[from][to] == null) {
      throw new Error(`Undefined conversion from ${from} to ${to}`);
    }
    return _value * MEASURE_RATIO[from][to];
  }
}
