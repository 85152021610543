import { CSSProperties, MouseEventHandler } from 'react';

import { Icon } from 'dodoc-design-system';

import { NodeIcon } from '_common/components/';
import styles from './Item.module.scss';

import type { NodeIconProps } from '_common/components/Tables/NodeIcon/NodeIcon';
import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';

export type ItemProps<T extends ExportIntegrationModal.ExportType> = {
  style?: CSSProperties;
  object: NodeIconProps['object'] & { id: string };
  onClick: (object: ItemProps<T>['object']) => void;
  onDoubleClick: (object: ItemProps<T>['object']) => void;
  data: ExportIntegrationModal.IntegrationUtils[T]['data'];
  exportType: T;
};

const Item = <T extends ExportIntegrationModal.ExportType>({
  style = {},
  object,
  onClick,
  onDoubleClick,
  data,
  exportType,
}: ItemProps<T>) => {
  const handleClick = () => {
    if (onClick) {
      onClick(object);
    }
  };

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (onDoubleClick) {
      onDoubleClick(object);
      e.stopPropagation();
    }
  };

  const getDisabledItems = () => {
    if (exportType === 'box') {
      return false;
    }
    return false;
    // object no longer has property type, was triggering blue screen
    // return !object.type.match(/^(document|folder|workspace)$/);
  };

  if (!object) {
    return null;
  }

  const isDisabled = getDisabledItems();
  return (
    <div
      style={style}
      className={`${styles.item} ${data.selected === object.id && styles.selected} ${
        isDisabled && styles.disabled
      }`}
      onClick={!isDisabled ? handleClick : undefined}
      onDoubleClick={(e) => handleDoubleClick(e)}
    >
      <NodeIcon object={object} disabled={isDisabled} />
      <div title={object.name} className={styles.name}>
        {object.name}
      </div>
      {object.type === 'folder' && (
        <div className={styles.colRight} onClick={handleDoubleClick}>
          <Icon icon="AngleRightGrey" size={24} />
        </div>
      )}
    </div>
  );
};

export default Item;
