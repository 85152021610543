//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import { notify } from '_common/components/ToastSystem';
import { BlockViewModel } from 'Editor/services/VisualizerManager';
import { EditorRange, EditorSelectionUtils, JsonRange } from 'Editor/services/_Common/Selection';
import { Descendant } from 'slate';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass: any) =>
    class CommentsEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      async handleAddComment(comment: Descendant[]) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();
          try {
            // restore and scroll to selection
            this.navigationManager.scrollIntoSelection();

            const tempCommentReference = ReduxInterface.getEditorState().comments.insert.reference;

            const result: any = await this.dataManager?.comments?.addComment(
              comment,
              tempCommentReference,
            );

            ReduxInterface.cancelTemporaryComment();

            setTimeout(() => {
              const nodes = document.querySelectorAll(`*[element_reference="${result.id}"]`);
              if (nodes.length > 0) {
                const range = document.createRange();

                range.setStart(nodes.item(0), 0);

                const lastComment = nodes.item(nodes.length - 1);
                range.setEnd(lastComment, lastComment.childNodes.length);

                const selection = window.getSelection();
                selection?.removeAllRanges();
                selection?.addRange(range);

                this.visualizerManager?.selection.triggerSelectionChanged();
              }
            }, 0);
          } catch (error) {
            ReduxInterface.cancelTemporaryComment();
            Logger.captureException(error);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      async handleAddTemporaryComment() {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();
          try {
            // restore and scroll to selection
            this.navigationManager.scrollIntoSelection();

            let allowCreateComment = true;

            const nodes = EditorSelectionUtils.getSelectedNodes();
            if (nodes.length === 1 && nodes[0] === EditorDOMUtils.getContentContainer(nodes[0])) {
              // this.handleInsertCommentCanceled();
              this.handleRemoveTemporaryComment();
              notify({
                type: 'error',
                title: 'global.error',
                message: 'editor.errors.comments.currentSelectionInvalid',
              });
              return;
            }

            let level0Node = EditorDOMUtils.findFirstLevelChildNode(
              EditorDOMUtils.getContentContainer(nodes[0]),
              nodes[0],
            );
            if (!EditorDOMElements.isSupportedBlockElement(level0Node)) {
              return;
            }

            if (!EditorDOMUtils.isBlockNodeDeletable(level0Node) && level0Node.selectableContent) {
              level0Node = level0Node.selectableContent;
            }
            if (!EditorDOMElements.isSupportedBlockElement(level0Node)) {
              return;
            }

            allowCreateComment = this.dataManager?.permissions.canUserPerform(
              level0Node.id,
              'comment',
            );

            if (this.selectionManager.isSelectionCollapsed()) {
              this.selectionManager.modifySelection('move', 'backward', 'word');
              this.selectionManager.modifySelection('expand', 'forward', 'word');

              const range = EditorSelectionUtils.getRange();

              let text = range?.toString().trim();
              if (text === '') {
                allowCreateComment = false;
              }

              if (
                range &&
                !EditorDOMUtils.parentContainsNode(
                  EditorDOMUtils.getContentContainer(range.commonAncestorContainer),
                  range.commonAncestorContainer,
                )
              ) {
                allowCreateComment = false;
              }
            } else {
              EditorSelectionUtils.fixNonCollapsedTextSelection();
            }

            if (allowCreateComment) {
              const tempCommentReference = EditorDOMUtils.generateUUID();
              const startRange = EditorSelectionUtils.getRange();

              const ranges = EditorRange.splitRangeByBlocks(
                startRange,
                EditorDOMElements.BLOCK_TEXT_ELEMENTS,
                { allowNonDeletable: true },
              );

              let jsonRanges: Editor.Selection.RangeData[] = [];
              let lastRange: JsonRange | undefined;

              for (let i = 0; i < ranges.length; i++) {
                const block = ranges[i].block as Editor.Visualizer.BaseView;
                const blockRange = ranges[i].range;

                let pageContainer = EditorDOMUtils.getContentContainer(block);
                let blockModel;

                if (block.vm == null && block.parentElement !== pageContainer) {
                  const level0 = EditorDOMUtils.findFirstLevelChildNode(
                    pageContainer,
                    block,
                  ) as Editor.Visualizer.BaseView;
                  blockModel = level0.vm as BlockViewModel;
                } else {
                  blockModel = block.vm as BlockViewModel;
                }

                // check if last range is a splitted view
                if (lastRange && lastRange?.end.b === block.id) {
                  if (blockModel?.hasSplitViews()) {
                    let endJsonPosition = JsonRange.getPositionFromNodeOffset(
                      blockRange.endContainer,
                      blockRange.endOffset,
                    );

                    if (endJsonPosition) {
                      lastRange.end = endJsonPosition;
                    }
                  }
                } else {
                  lastRange = JsonRange.buildFromDOMRange(blockRange);

                  jsonRanges.push(lastRange.serializeToRangeData());
                }
              }

              EditorSelectionUtils.collapseToStart();
              this.visualizerManager?.selection.triggerSelectionChanged();

              await this.dataManager?.comments?.addTemporaryComment(
                tempCommentReference,
                jsonRanges,
              );

              // const tempComment = document.querySelector(
              //   `[element_reference="${tempCommentReference}"`,
              // );
              // this.selectionManager.setCaret(tempComment, 'INSIDE_END');

              // this.visualizerManager.selection.triggerSelectionChanged();

              ReduxInterface.openTemporaryCommentCard({
                reference: tempCommentReference,
                level0: level0Node.id,
              });
            } else {
              throw new Error('User has no permissions to comment selection');
            }
          } catch (error) {
            Logger.captureException(error);
            this.handleRemoveTemporaryComment();
            notify({
              type: 'error',
              title: 'global.error',
              message: 'editor.errors.comments.currentSelectionInvalid',
            });
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      handleRemoveTemporaryComment() {
        this.visualizerManager?.selection.stopSelectionTracker();
        try {
          const tempCommentReference = ReduxInterface.getEditorState().comments.insert.reference;
          if (tempCommentReference) {
            this.dataManager?.comments?.cancelTemporaryComment(tempCommentReference).then(() => {
              const insertingTemp = ReduxInterface.getEditorState().comments.insert.inserting;
              if (insertingTemp) {
                ReduxInterface.cancelTemporaryComment();
              }

              setTimeout(() => {
                this.dataManager?.selection?.restore();
              }, 0);
            });
          }
        } finally {
          this.visualizerManager.selection.debounceStartSelectionTracker();
        }
      }
    },
);
