import { Toggle } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { CSSProperties, forwardRef, MouseEventHandler, Ref } from 'react';

/**
 * Editor topbar menu component
 */
type ToolbarToggleButtonProps = {
  disabled?: boolean;
  toggleButtonClicked: MouseEventHandler<HTMLSpanElement>;
  isActive?: boolean;
  margin?: CSSProperties['margin'];
  icon?: IconTypes[24];
  sidebar?: boolean;
  ref: Ref<HTMLSpanElement> | undefined;
  color?: string;
  testId: string;
};

const ToolbarToggleButton = forwardRef<HTMLSpanElement, ToolbarToggleButtonProps>(
  ({ disabled, toggleButtonClicked, isActive, margin, icon, sidebar, color, testId }, ref) => {
    const onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      if (!disabled) {
        toggleButtonClicked(e);
      }
    };
    return (
      <div style={{ margin: sidebar ? '0 0 1rem 0' : '2px' }}>
        <Toggle
          size="medium"
          variant="ghost"
          ref={ref}
          isToggled={isActive}
          onClick={onClick}
          disabled={disabled}
          margin={margin}
          icon={icon}
          iconColor={color}
          testId={`${testId}-toggle`}
        />
      </div>
    );
  },
);

export default ToolbarToggleButton;
