export default class ErrorBuildingDOMElement extends Error {
  constructor(data) {
    super();
    this.name = 'ErrorBuildingDOMElement';
    this.message = `Error building DOM element! Tag: ${data}`;
    this.extraData = data;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorBuildingDOMElement);
    }
  }
}
