import { Structure, Template } from '../../models';
import BaseController from '../BaseController';
import DocumentStyle from './DocumentStyle';
import DocumentStylesManager, { DocumentStylesPropType } from './DocumentStylesManager';
import ListStyle from './ListStyle';
import ListStylesManager from './ListStylesManager';
import * as Util from '../../Util';

export class StylesController extends BaseController {
  private template?: Template;
  private structure?: Structure;
  documentStyles: DocumentStylesManager;
  listStyles: ListStylesManager;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.documentStyles = new DocumentStylesManager();
    this.listStyles = new ListStylesManager();

    this.handleEventAfterListStyleUpdate = this.handleEventAfterListStyleUpdate.bind(this);
    this.handleLoadDocumentStyles = this.handleLoadDocumentStyles.bind(this);
  }

  private handleEventDocumentStylesDeletedFinished(payload: any) {
    this.Data.events?.emit('DOCUMENT_STYLES_DELETE_RESPONSE', payload);
  }

  private handleEventDocumentStylesResetFinished(payload: any) {
    this.Data.events?.emit('DOCUMENT_STYLES_RESET_RESPONSE', payload);
  }

  start(documentId: string) {
    this.registerTransportEvents({
      'DOCUMENTSTYLES:DELETE:FINISHED': this.handleEventDocumentStylesDeletedFinished.bind(this),
      'DOCUMENTSTYLES:RESET:FINISHED': this.handleEventDocumentStylesResetFinished.bind(this),
    });
    this.template = this.Data.models?.get(this.Data?.models.TYPE_NAME.TEMPLATE, documentId);
    this.structure = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.STRUCTURE,
      `DS${documentId}`,
    );
    this.documentStyles.bindToTemplate(this.template).bindToStructure(this.structure);
    this.listStyles.bindToTemplate(this.template).bindToStructure(this.structure);
    this.documentStyles
      .on('LOADED', this.handleLoadDocumentStyles)
      .on('UPDATED', this.handleLoadDocumentStyles);
    // .on('STYLES_ADD', this.handleLoadDocumentStyles)
    // .on('STYLES_REMOVE', this.handleLoadDocumentStyles);
    this.listStyles
      .on('LOAD_LIST_STYLES', () => {
        this.Data.events?.emit('LOAD_LIST_STYLES', this.listStyles.parsedFrontStyles());
      })
      .on('LIST_STYLE_UPDATE', () => {
        this.Data.events?.emit('LOAD_LIST_STYLES', this.listStyles.parsedFrontStyles());
      })
      .on('LIST_STYLE_ADD', () => {
        this.Data.events?.emit('LOAD_LIST_STYLES', this.listStyles.parsedFrontStyles());
      })
      .on('LIST_STYLE_REMOVE', () => {
        this.Data.events?.emit('LOAD_LIST_STYLES', this.listStyles.parsedFrontStyles());
      })
      .on('AFTER_LIST_STYLE_UPDATE', this.handleEventAfterListStyleUpdate);

    this.documentStyles.start();
    this.listStyles.start();
  }

  generateStyleId() {
    return DocumentStyle.generateStyleId();
  }

  private handleLoadDocumentStyles() {
    this.Data.events?.emit('LOAD_DOCUMENT_STYLES', this.documentStyles.stylesData());
  }

  //TODO: handle update styles8

  private handleEventAfterListStyleUpdate(listStyleId: string, listStyle: ListStyle) {
    if (listStyle.isMultiLevelList()) {
      const listWithStyles = this.Data.numbering?.listsWithStyle(listStyleId) || [];
      for (let index = 0; index < listWithStyles.length; index++) {
        const listId = listWithStyles[index];
        this.updateDocumentStylesForList(listId, listStyleId);
      }
    }
  }

  addNewDocumentStyle(styleId: string, spec: any) {
    return this.documentStyles.addNewStyle(styleId, spec);
  }

  updateDocumentStyle(styleId: string, spec: any) {
    const stylesData = this.structure?.get(['st']) as unknown as DocumentStylesPropType;
    let _spec;

    if (!stylesData) {
      // ADD STYLES OBJECT
      return this.addNewDocumentStyle(styleId, spec);
    } else {
      if (stylesData[styleId]) {
        _spec = DocumentStyle.parseBack(stylesData[styleId], spec);
      } else {
        const templateStyle = this.documentStyles.style(styleId);
        if (templateStyle) {
          _spec = DocumentStyle.parseBack(templateStyle, spec);
        }
      }
      // CREATE
      return this.structure?.set(['st', styleId], _spec, { source: 'LOCAL_RENDER' });
    }
  }

  updateDocumentStylesForList(listId: string, styleId: string) {
    const documentStyles = this.documentStyles.getStylesForList(listId);
    const listStyle = this.listStyles.style(styleId);
    const multiLevelTypes = listStyle?.getMultiLevelTypes() || [];
    const mlStylesDiff = Util.diffInOut(documentStyles, multiLevelTypes);
    if (mlStylesDiff.out.length > 0) {
      for (let index = 0; index < mlStylesDiff.out.length; index++) {
        this.structure?.delete(['st', mlStylesDiff.out[index], 'p', 'lst']);
      }
    }

    for (let index = 0; index < mlStylesDiff.in.length; index++) {
      this.updateDocumentStyle(mlStylesDiff.in[index], {
        p: {
          lst: {
            lId: listId,
            lLv: listStyle?.getLevelForType(mlStylesDiff.in[index]),
          },
        },
      });
    }
  }

  renameDocumentStyle(styleId: string, name: string) {
    const templateStyle = this.documentStyles.style(styleId);
    if (templateStyle) {
      const _spec = {
        id: templateStyle.id,
        e: templateStyle.e,
        n: templateStyle.n,
        b: templateStyle.b,
        p: {
          ...templateStyle.p,
        },
      };
      // eslint-disable-next-line max-len
      return this.structure?.set(['st', styleId], DocumentStyle.validateName(_spec, name), {
        source: 'LOCAL_RENDER',
      });
    }
    return Promise.resolve(this);
  }

  removeListFromDocumentStyle(styleId: string) {
    const stylesData = this.structure?.get(['st']) as unknown as DocumentStylesPropType;
    if (!stylesData || !stylesData[styleId]) {
      // ADD STYLES OBJECT
      return;
    }
    // UPDATE
    return this.structure?.delete(['st', styleId, 'p', 'lst']);
  }

  deleteDocumentStyle(styleId: string) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENTSTYLES:DELETE',
        {
          styleId,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  resetTemplateStyle(styleId: string) {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENTSTYLES:RESET',
        {
          styleId,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getDocumentStyleFromId(styleId: string) {
    return this.documentStyles.style(styleId);
  }

  isStyleFromTemplate(styleId: string) {
    return this.documentStyles.isStyleFromTemplate(styleId);
  }

  hasStyleDocumentDefinition(styleId: string) {
    return this.documentStyles.hasStyleDocumentDefinition(styleId);
  }

  getStyleKeepLines(styleId: string) {
    return this.documentStyles.getStyleKeepLines(styleId);
  }

  getStyleKeepWithNext(styleId: string) {
    return this.documentStyles.getStyleKeepWithNext(styleId);
  }

  getStylePageBreakBefore(styleId: string) {
    return this.documentStyles.getStylePageBreakBefore(styleId);
  }

  getBaseStyleId(styleId: string) {
    return this.documentStyles.getBaseStyleId(styleId);
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
    this.listStyles.destroy();
    this.documentStyles.destroy();
  }
}
