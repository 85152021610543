import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helper } from 'dodoc-design-system';
import { SupportButton } from '_common/components';
import { useSelector } from '_common/hooks';
import AuthHeader from 'Auth/components/AuthHeader/AuthHeader';
import styles from './SecondaryAuthPageContainer.module.scss';

export const TEST_IDS = {
  feedback: 'feedback_container',
} as const;

type SecondaryAuthPageContainerProps = {
  title: TranslationId;
  description: TranslationId;
  children: ReactNode;
};

const SecondaryAuthPageContainer = ({
  title,
  description,
  children,
}: SecondaryAuthPageContainerProps) => {
  // REDUX
  const feedback = useSelector((state) => state.auth.feedback);
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <AuthHeader />
        <div className={styles.content}>
          <div className={styles.title}>
            <FormattedMessage id={title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={description} />
          </div>
          {feedback && (
            <div className={styles.feedback} data-testid={TEST_IDS.feedback}>
              <Helper type={feedback.type} testId="template-gallery-feedback-helper">
                <FormattedMessage id={feedback.message} values={feedback.messageValues} />
              </Helper>
            </div>
          )}
          {children}
        </div>
      </div>
      <SupportButton />
    </div>
  );
};

export default SecondaryAuthPageContainer;
