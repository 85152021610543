import { CSSTransition } from 'react-transition-group';

import { useSelector } from '_common/hooks';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import Options from './Options/Options';
import Header from './Header/Header';

import styles from './Menu.module.scss';

const Menu = () => {
  const isOpen = useSelector((state) => state.sidebar.menu.isOpen);

  const animationsClassNames = {
    enter: styles.enter,
    enterActive: styles.enterActive,
    enterDone: styles.enterDone,
    exit: styles.exit,
    exitActive: styles.exitActive,
    exitDone: styles.exitDone,
  };

  return (
    <CSSTransition in={isOpen} timeout={150} classNames={animationsClassNames}>
      <div className={`${styles.root}`}>
        <IntlErrorBoundary size="medium" variant="white" margin="auto">
          <>
            <Header />
            <Options />
          </>
        </IntlErrorBoundary>
      </div>
    </CSSTransition>
  );
};

export default Menu;
