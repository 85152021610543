import { BaseTypedEmitter } from '_common/services/Realtime';

export class ShortcutsManager extends BaseTypedEmitter<Presentation.Shortcuts.Events> {
  private platform: Platform | undefined = undefined;

  private documentListeners: { [x in string]: (e: any) => void } = {
    keydown: this.handleDocumentKeyDown.bind(this),
  };

  private containerListeners: { [x in string]: (e: any) => void } = {
    keydown: this.handleKeyDown.bind(this),
    wheel: this.handleMouseWheel.bind(this),
  };

  private commands: Presentation.Shortcuts.Commands = {
    ctrlKey: {},
    altKey: {},
    shiftKey: {
      //+ (*) firefox
      171: {
        action: 'ZOOM_IN',
        document: true,
      },
      //+
      187: {
        action: 'ZOOM_IN',
        document: true,
      },
      //+ numpad
      107: {
        action: 'ZOOM_IN',
        document: true,
      },
      //- (_) firefox
      173: {
        action: 'ZOOM_OUT',
        document: true,
      },
      //-
      189: {
        action: 'ZOOM_OUT',
        document: true,
      },
      //- numpad
      109: {
        action: 'ZOOM_OUT',
        document: true,
      },
    },
  };

  constructor(platform: Platform) {
    super();
    this.platform = platform;
  }

  public start() {
    const slidesContainer = document.getElementById('slidesContainer');

    if (slidesContainer) {
      Object.keys(this.containerListeners).forEach((event) => {
        slidesContainer.addEventListener(event, this.containerListeners[event]);
      });
    }

    Object.keys(this.documentListeners).forEach((event) => {
      document.addEventListener(event, this.documentListeners[event]);
    });
  }

  public destroy() {
    const slidesContainer = document.getElementById('slidesContainer');

    if (slidesContainer) {
      if (Object.keys(this.containerListeners).length) {
        Object.keys(this.containerListeners).forEach((event) => {
          slidesContainer.removeEventListener(event, this.containerListeners[event]);
        });
      }
    }

    Object.keys(this.documentListeners).forEach((event) => {
      document.removeEventListener(event, this.documentListeners[event]);
    });

    this.removeAllEventEmitterListeners();
  }

  private emitEvent(
    e: Event,
    {
      action,
      args,
      preventDefault = true,
    }: Pick<Presentation.Shortcuts.CommandArgs, 'action' | 'args' | 'preventDefault'>,
  ) {
    if (preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (action) {
      this.emit(action, { e, ...args });
    }
  }

  private handleDocumentKeyDown(e: KeyboardEvent) {
    if (this.platform != null) {
      if ((this.platform.os.mac && e.metaKey) || (!this.platform.os.mac && e.ctrlKey)) {
        //Ctrl + Shift
        if (e.shiftKey) {
          if (this.commands.shiftKey[e.keyCode] && this.commands.shiftKey[e.keyCode].document) {
            this.emitEvent(e, this.commands.shiftKey[e.keyCode]);
          }
        }
        //Ctrl + Alt
        else if (e.altKey) {
          if (this.commands.altKey[e.keyCode] && this.commands.altKey[e.keyCode].document) {
            this.emitEvent(e, this.commands.altKey[e.keyCode]);
          }
        }
        //Ctrl
        else if (this.commands.ctrlKey[e.keyCode] && this.commands.ctrlKey[e.keyCode].document) {
          this.emitEvent(e, this.commands.ctrlKey[e.keyCode]);
        }
      }
    }
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (this.platform != null) {
      if ((this.platform.os.mac && e.metaKey) || (!this.platform.os.mac && e.ctrlKey)) {
        //Ctrl + Shift
        if (e.shiftKey) {
          if (this.commands.shiftKey[e.keyCode]) {
            this.emitEvent(e, this.commands.shiftKey[e.keyCode]);
          }
        }
        //Ctrl + Alt
        else if (e.altKey) {
          if (this.commands.altKey[e.keyCode]) {
            this.emitEvent(e, this.commands.altKey[e.keyCode]);
          }
        }
        //Ctrl
        else if (this.commands.ctrlKey[e.keyCode]) {
          this.emitEvent(e, this.commands.ctrlKey[e.keyCode]);
        }
      }
    }
  }

  private handleMouseWheel(e: WheelEvent) {
    if (e.shiftKey) {
      if (e.deltaY > 0) {
        this.emitEvent(e, { action: 'ZOOM_OUT' });
      } else {
        this.emitEvent(e, { action: 'ZOOM_IN' });
      }
    }
  }
}
