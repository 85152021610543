import { CSSProperties } from 'react';
import { merge } from 'lodash';

import { usePresentationManager } from 'Presentation/PresentationManager';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import { useTextBody } from '../TextBodyContext';

import { cloneObject } from 'Presentation/utils';
import { TEXT_ALIGN_MAP, TEXT_DECORATION_MAP } from 'Presentation/consts';
import { nonNullableObject } from 'utils';
import useOutline from '../../useOutline';
import { isBullet } from '../Bullet/bulletUtils';
import { useGroupData } from '../../Group/GroupData';

const FONT_WEIGHT = {
  normal: 400,
  bold: 700,
} as const;

const useTextProperties = () => {
  /*
    * Paragraph sources: 
    * - ECMA part 1 p3184 - 21.1.2.2 Paragraph Formatting
    
    * Supported paragraph properties:
    * - br
    * - defPPr
    * - endParaRPr 
    *  - fld: (with missing implementations)
    *    - type:
    *      - datetimeX
    * - lnSpc
    * - algn
    * - indent
    * - lvl
    * - marL
    * - marR
    * - spcAft
    * - spcBef
    * - cap

    * TODO:PRESENTATION:TEXT Unsupported paragraph properties:
    *  - defTabSz
    *  - eaLnBrk
    *  - fontAlgn
    *  - hangingPunct
    *  - latinLnBrk
    *  - rtl
    *  - bmk
    *  - dirty
    *  - err
    *  - kern
    *  - kumimoji
    *  - lang
    *  - noProof
    *  - normalizeH
    *  - smtClean
    *  - smtId
    *  - tab
    *  - tabLst
-------------------------------------------------------------------------------------------------------------
    * Text sources: 
    * - ECMA part 1 p264  - 17.3.2 Run

    * Supported text properties:
    * - b
    * - caps
    * - color (with limitations):
    *    - only supports solid fill, some fill types might need text SVG refactor
    * - highlight
    * - i
    * - outline (with limitations):
    *    - its incomplete, but it needs text SVG refactor
    * - spacing
    * - sz
    * -  baseline (with problems):
    *   - attribute isnt being correctly processed
    * - latin (with missing implementations):
    *      - charset
    *      - panose
    *      - pitchFamily
    *  - strike
    *  - u
    
    * Supported text content:
    * - text (with the text properties limitations/issues)
    * - br (with missing missing implementations):
    *   - clear
    *   - type
      
    * Note: some of the unsupported properties dont need to be implemented, their purpose is different
    * TODO:PRESENTATION:TEXT Unsupported text properties:
    *  - cs
    *  - pitchFamily
    *  - ea
    *  - sym
    *  - uFill
    *  - uFillTx
    *  - uLn
    *  - uLnTx
    *  - bCs
    *  - bdo
    *  - bdr
    *  - dir
    *  - dstrike
    *  - eastAsianLayout
    *  - effect
    *  - em
    *  - emboss
    *  - fitText
    *  - iCs
    *  - imprint
    *  - kern
    *  - lang
    *  - noProof
    *  - oMath
    *  - position
    *  - rFonts
    *  - rStyle
    *  - rtl
    *  - shadow
    *  - shd
    *  - smallCaps
    *  - snapToGrid
    *  - specVanish
    *  - szCs
    *  - vanish
    *  - vertAlign
    *  - w
    *  - webHidden

    * TODO:PRESENTATION:TEXT Unsupported text content:
    *  - contentPart
    *  - control
    *  - cr
    *  - dayLong
    *  - dayShort
    *  - delText
    *  - dirty
    *  - drawing
    *  - hps
    *  - hpsBaseText
    *  - hpsRaise
    *  - lastRenderedPageBreak
    *  - lid
    *  - monthLong
    *  - monthShort
    *  - movie
    *  - noBreakHyphen
    *  - object
    *  - objectEmbed
    *  - objectLink
    *  - pgNum
    *  - ptab
    *  - rt
    *  - ruby
    *  - rubyAlign
    *  - rubyBase
    *  - rubyPr
    *  - softHyphen
    *  - sym
    *  - tab
    *  - yearLong
    *  - yearShort

    * TODO:PRESENTATION:TEXT Known issues:
    *  - line heights arent 100% correct in some situations
    */

  /*
   * Source: ECMA part 1 p299 - 17.3.2.28 rPr (Run Properties)
   * Text style hierarchy:
   *  - Document defaults
   *  - Table styles
   *  - Numbering styles
   *  - Paragraph styles
   *  - Character styles
   *  - Direct formatting
   */

  /**
   * TODO:PRESENTATION
   * getParagraphProperties is being used in many places, this should be optimized
   */

  const manager = usePresentationManager();
  const { shape, text, textStyle, fontScale, lnSpcReduction } = useTextBody();
  const { getFontFamily, color, getDefaultFontFamily, addUnsupportedElement } = useSlideData();
  const defaultFontFamily = getDefaultFontFamily({ shape });
  const { parseOutline } = useOutline();
  const { totalScale } = useGroupData();
  const groupReverseScale = 1 / (totalScale?.scaleX ?? 1);

  //#region Generic Helpers

  const parseFill = (fill?: Presentation.Data.Common.FillType) => {
    if (!fill) {
      return undefined;
    }

    //TODO:PRESENTATION:TEXT proccess other fill types
    switch (fill.type) {
      case 'solid':
        return color(fill.color);
      case 'none':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:NONE
        addUnsupportedElement('Text - No Fill');
        break;
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:GRADIENT
        addUnsupportedElement('Text - Gradient Fill');

        const mainStop = fill.stops.reduce<
          Presentation.Data.Common.GradientFillType['stops'][number] | undefined
        >((mainStop, currentStop) => {
          if (!mainStop || currentStop.pos > mainStop.pos) {
            return currentStop;
          }

          return mainStop;
        }, undefined);

        if (mainStop) {
          return color(mainStop.color);
        }
        break;
      case 'picture':
        fill.tile
          ? //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:TEXTURE
            addUnsupportedElement('Text - Texture Fill')
          : //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:PICTURE
            addUnsupportedElement('Text - Picture Fill');
        break;
      case 'pattern':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:PATTERN
        addUnsupportedElement('Text - Pattern Fill');
        break;
      default: {
        return undefined;
      }
    }
  };

  const getFontColor = ({
    properties,
  }: {
    properties: Presentation.Data.InlineProperties | undefined;
  }) => {
    return parseFill(properties?.fill);
  };

  const getFontSize = ({
    properties,
  }: {
    properties: Presentation.Data.InlineProperties | undefined;
  }) => {
    if (!properties) {
      return undefined;
    }

    //TODO:PRESENTATION:TEXT Math.ceil seems to work better in some situations, investigate this.
    return properties.size ? properties.size * fontScale * groupReverseScale : undefined;
  };

  const getLineHeight = ({
    fontFamily,
    fontSize,
    lnSpcAbs,
    lnSpcPct,
    lnSpcReduction,
  }: {
    fontFamily: string | undefined;
    fontSize: number | undefined;
    lnSpcAbs?: number;
    lnSpcPct?: number;
    lnSpcReduction: number;
  }) => {
    if (!fontFamily || !fontSize) {
      return undefined;
    }

    const metrics = manager.getFontFamilyHelper()?.getTextMetrics(fontFamily, `${fontSize}px`);

    if (metrics?.fontBoundingBoxAscent == null || metrics?.fontBoundingBoxDescent == null) {
      return undefined;
    }

    if (lnSpcAbs != null && lnSpcPct != null) {
      /**
       * Not sure what to do in this situation, dont know if this is even supposed to happen at all
       */
      // return `${lnSpcAbs * (lnSpcPct / 100 - lnSpcReduction)}px`; //Not sure if this is correct
    } else if (lnSpcAbs != null) {
      return `${lnSpcAbs}px`; //Not sure if this is correct
    } else if (lnSpcPct != null) {
      return `${
        (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent) *
        (lnSpcPct / 100 - lnSpcReduction)
      }px`; //Not sure if this is correct
    }
    return undefined;
  };

  const getTextShadow = ({
    ln,
  }: {
    ln: Presentation.Data.Common.Outline | undefined;
  }): string | undefined => {
    if (!ln) {
      return undefined;
    }

    /*
     * PRESENTATION:SVG TODO:PRESENTATION:TEXT proccess all outline properties
     */
    const outline = parseOutline(ln);
    const width = outline.strokeWidth ?? 1;

    let unsupportedDash = '';
    let unsupportedCap = '';
    let unsupportedJoin = '';

    switch (ln.dash) {
      case 'sysDot':
      case 'dot':
        unsupportedDash = 'Round dot';
        break;
      case 'sysDash':
        unsupportedDash = 'Square dot';
        break;
      case 'dash':
        unsupportedDash = 'Dash';
        break;
      case 'dashDot':
      case 'sysDashDot':
        unsupportedDash = 'Dash dot';
        break;
      case 'sysDashDotDot':
        unsupportedDash = 'Dash dot dot';
        break;
      case 'lgDash':
        unsupportedDash = 'Long dash';
        break;
      case 'lgDashDot':
        unsupportedDash = 'Long dash dot';
        break;
      case 'lgDashDotDot':
        unsupportedDash = 'Long dash dot dot';
        break;
    }

    switch (ln.cap) {
      case 'flat':
        unsupportedCap = 'Flat';
        break;
      case 'rnd':
        unsupportedCap = 'Round';
        break;
      case 'sq':
        unsupportedCap = 'Square';
        break;
    }

    switch (ln.join?.type) {
      case 'bevel':
        unsupportedJoin = 'Bevel';
        break;
      case 'miter':
        unsupportedJoin = 'Miter';
        break;
      case 'round':
        unsupportedJoin = 'Round';
        break;
    }

    if (unsupportedDash) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT:OUTLINE
      addUnsupportedElement(`Text - Outline dash type - ${unsupportedDash}`);
    }

    if (unsupportedCap) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT:OUTLINE
      addUnsupportedElement(`Text - Outline cap type - ${unsupportedCap}`);
    }

    if (unsupportedJoin) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT:OUTLINE
      addUnsupportedElement(`Text - Outline join type - ${unsupportedJoin}`);
    }

    //left, bottom, right, top
    return `-${width}px 0 ${outline.stroke}, 0 ${width}px ${outline.stroke}, ${width}px 0 ${outline.stroke}, 0 -${width}px ${outline.stroke}`;
  };

  const parseInlineProperties = (
    properties: Presentation.Data.InlineProperties | undefined,
  ): CSSProperties | undefined => {
    if (!properties) {
      return undefined;
    }

    //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
    properties?.effects?.forEach((effect) => {
      addUnsupportedElement(`Text Effect - ${effect.type}`);
    });

    const fontColor = getFontColor({ properties });
    const fontSize = getFontSize({ properties });
    const fontWeight = properties.b ? FONT_WEIGHT.bold : FONT_WEIGHT.normal;

    //#region Text decoration
    let textDecorationStyles: CSSProperties = {};

    const underlineType = properties.u;
    const strikethroughType = properties.strike;
    let underlineStyles = underlineType ? TEXT_DECORATION_MAP[underlineType] : {};
    let strikethroughStyles = strikethroughType ? TEXT_DECORATION_MAP[strikethroughType] : {};

    const underlineFill = properties.underline?.fill;
    const underlineColor =
      underlineFill?.sameAsText != null
        ? underlineFill.sameAsText
          ? fontColor
          : undefined
        : parseFill(underlineFill);

    /**
     * PRESENTATION:LIMITATION
     * If both underline and strikethrough are defined, we will have some CSS limitations
     * We can have multiple types of line in textDecoration, for example, text-decoration: underline line-through
     * But all other properties, like the color, thickness, style, etc, will be applied to all lines
     * CSS doesnt allow to define styles for each line.
     * We decided the underline styles to take precedence over the strikethrough styles
     * This decision has been made because in powerpoint we cant costumize the strikethrough, but we can the underlines
     */
    textDecorationStyles = {
      ...strikethroughStyles,
      ...underlineStyles,
      textDecoration:
        underlineStyles.textDecoration || strikethroughStyles.textDecoration
          ? `${underlineStyles.textDecoration ?? ''} ${strikethroughStyles.textDecoration ?? ''}`
          : undefined,
      textDecorationColor: underlineType != null ? underlineColor : undefined,
      textDecorationSkipInk: underlineType ? 'none' : undefined,
    };

    let unsupportedUnderline = '';

    switch (underlineType) {
      case 'dashLong':
        unsupportedUnderline = 'Dash long line';
        break;
      case 'dashLongHeavy':
        unsupportedUnderline = 'Dash long heavy line';
        break;
      case 'dotDash':
        unsupportedUnderline = 'Dot dash line';
        break;
      case 'dotDashHeavy':
        unsupportedUnderline = 'Dot dash heavy line';
        break;
      case 'dotDotDash':
        unsupportedUnderline = 'Dot dot dash line';
        break;
      case 'dotDotDashHeavy':
        unsupportedUnderline = 'Dot dash dash heavy line';
        break;
      case 'wavyDbl':
        unsupportedUnderline = 'Wavy double line';
        break;
    }

    if (unsupportedUnderline) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT
      addUnsupportedElement(`Text - Underline - ${unsupportedUnderline}`);
    }
    //#endregion

    switch (properties.cap) {
      case 'small':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT
        addUnsupportedElement(`Text - Small caps`);
        break;
    }

    if (properties.normalizeH) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT
      addUnsupportedElement(`Text - Equalize character height`);
    }

    if (properties.rtl) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT
      addUnsupportedElement(`Text - Right-to-left`);
    }

    return nonNullableObject({
      fontFamily: getFontFamily({ font: properties.latin?.font }) ?? defaultFontFamily,
      fontSize,
      color: fontColor,
      fontWeight: properties.b == null ? undefined : fontWeight,
      fontStyle: properties.i ? 'italic' : undefined,
      background: properties.highlight ? color(properties.highlight) : undefined,
      textTransform:
        properties.cap === 'all' ? 'uppercase' : properties.cap === 'none' ? 'none' : undefined,
      letterSpacing: properties.spc != null ? `${properties.spc / 100}px` : undefined,
      textShadow: getTextShadow({ ln: properties.ln }),
      ...textDecorationStyles,
    });
  };
  //#endregion

  //#region Paragraph Helpers
  const getParagraphProperties = ({
    paragraph,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
  }) => {
    let properties: Presentation.Data.ParagraphProperties | undefined = undefined;

    //Inherit properties from list style
    if (text.listStyle) {
      if (text.listStyle.defPPr) {
        properties = cloneObject(text.listStyle.defPPr);
      }
      if (paragraph.properties.lvl) {
        const lvl = `${paragraph.properties.lvl + 1}`;
        if (lvl in text.listStyle) {
          properties = cloneObject(text.listStyle[lvl]);
        }
      } else if (text.listStyle['1']) {
        properties = cloneObject(text.listStyle['1']);
      }
    }

    //Merge own properties into inherited properties
    if (Object.keys(paragraph.properties).length) {
      properties = merge(cloneObject(properties) ?? {}, paragraph.properties);

      if (paragraph.properties?.bullet) {
        properties.bullet = {
          ...properties?.bullet,
          ...paragraph.properties.bullet,
        };
      }

      /**
       * Note sure if correct
       * Dont allow the properties to have both lnSpcAbs and lnSpcPct defined at the same time
       * The paragraph properties should take precedence over the list styles properties
       */
      if (paragraph.properties.lnSpcPct != null && properties.lnSpcAbs != null) {
        properties.lnSpcAbs = undefined;
      } else if (paragraph.properties.lnSpcAbs != null && properties.lnSpcPct != null) {
        properties.lnSpcPct = undefined;
      }
    }

    return properties;
  };

  const getParagraphFontFamily = ({
    paragraph,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
  }) => {
    const properties = getParagraphProperties({ paragraph });

    return getFontFamily({ font: properties?.inlineProperties?.latin?.font }) ?? defaultFontFamily;
  };

  const getParagraphSpacing = ({ paragraph }: { paragraph: Presentation.Data.ParagraphShape }) => {
    const pProperties = getParagraphProperties({ paragraph });

    const style: Pick<CSSProperties, 'paddingTop' | 'paddingBottom' | 'lineHeight'> = {
      paddingTop: undefined,
      paddingBottom: undefined,
      lineHeight: undefined,
    };

    if (!pProperties) {
      return style;
    }

    let fontHeight: number | undefined = undefined;

    paragraph.childNodes?.forEach((run) => {
      const textFontFamily = getTextFontFamily({ paragraph, run });
      const textFontSize = getTextFontSize({ paragraph, run });

      if (textFontFamily != null && textFontSize != null) {
        const textMetrics = manager
          .getFontFamilyHelper()
          ?.getTextMetrics(textFontFamily, `${textFontSize}`);

        if (
          textMetrics?.fontBoundingBoxAscent != null &&
          textMetrics?.fontBoundingBoxDescent != null
        ) {
          const textFontHeight =
            textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent;
          if (fontHeight == null || textFontHeight > fontHeight) {
            fontHeight = textFontHeight;
          }

          style.lineHeight = getLineHeight({
            fontFamily: textFontFamily,
            fontSize: textFontSize,
            lnSpcPct: pProperties.lnSpcPct,
            lnSpcAbs: pProperties.lnSpcAbs,
            lnSpcReduction,
          });
        }
      }
    });

    style.paddingTop =
      pProperties.spcBefAbs != null
        ? `${pProperties.spcBefAbs}px`
        : pProperties.spcBefPct != null && fontHeight != null
        ? `${fontHeight * (pProperties.spcBefPct / 100)}px`
        : undefined;
    style.paddingBottom =
      pProperties.spcAftAbs != null
        ? `${pProperties.spcAftAbs}px`
        : pProperties.spcAftPct != null && fontHeight != null
        ? `${fontHeight * (pProperties.spcAftPct / 100)}px`
        : undefined;

    return style;
  };

  //#endregion

  //#region Text Helpers
  const getTextFontFamily = ({
    paragraph,
    run,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
    run: Presentation.Data.RunShape | undefined;
  }) => {
    return (
      getFontFamily({
        font: run ? run.properties?.latin?.font : paragraph.endParaProperties?.latin?.font,
      }) ??
      getParagraphFontFamily({ paragraph }) ??
      defaultFontFamily
    );
  };

  const getTextFontSize = ({
    paragraph,
    run,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
    run: Presentation.Data.RunShape | undefined;
  }) => {
    const paragraphProperties = getParagraphProperties({ paragraph });

    /*
     * 1. If 'text' is defined and 'size' exists, text font size is used.
     * 2. If 'text' is defined but 'size' doesnt exist, paragraph font size is used.
     * 3. If 'text' is not defined (zeroWidthSpace) and endParaProperties 'size' exists,
     *    endParaProperties is used.
     * 4. If 'text' is not defined (zeroWidthSpace) and endParaProperties 'size' doesnt exist,
     *    paragraph font size is used
     */
    const fontSize = getFontSize({
      properties: run
        ? run.properties?.size != null
          ? run.properties
          : paragraphProperties?.inlineProperties
        : paragraph.endParaProperties?.size != null
        ? paragraph.endParaProperties
        : paragraphProperties?.inlineProperties,
    });

    return run?.properties?.baseline ? (fontSize ?? 24) / 2 : fontSize;
  };

  const getTextVerticalAlign = ({
    paragraph,
    run,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
    run: Presentation.Data.RunShape | undefined;
  }) => {
    const paragraphProperties = getParagraphProperties({ paragraph });

    const paragraphFontSize = getFontSize({
      properties:
        paragraphProperties?.inlineProperties != null
          ? paragraphProperties.inlineProperties
          : paragraph.endParaProperties?.size != null
          ? paragraph.endParaProperties
          : undefined,
    });

    const baseline = run?.properties?.baseline;

    if (baseline != null && paragraphFontSize != null) {
      return paragraphFontSize * (baseline / 100);
    }

    return undefined;
  };

  const getTextLineHeight = ({
    paragraph,
    run,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
    run?: Presentation.Data.RunShape;
  }) => {
    const paragraphProperties = getParagraphProperties({ paragraph });

    /*
     * Attribute: lnSpc
     * Source: ECMA part 1 p3200 - 21.1.2.2.5 lnSpc (Line Spacing)
     * If this element is omitted then the spacing between two lines of text
     * should be determined by the point size of the largest piece of text within a line
     */

    return getLineHeight({
      fontFamily: getTextFontFamily({ paragraph, run }),
      fontSize: getTextFontSize({ paragraph, run }),
      lnSpcPct:
        /**
         * Not sure if correct:
         * Accordingly to the documentation above the lnSpc should a default value
         * If lnSpcAbs nor lnSpcPct are not defined, then by default lnSpcPct will be 100
         * */
        paragraphProperties?.lnSpcPct ?? (paragraphProperties?.lnSpcAbs == null ? 100 : undefined),
      lnSpcAbs: paragraphProperties?.lnSpcAbs,
      lnSpcReduction,
    });
  };

  //#endregion

  //#region Styles
  const getParagraphStyle = ({
    paragraph,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
  }): CSSProperties => {
    const properties = getParagraphProperties({ paragraph });
    const inlineProperties = parseInlineProperties(properties?.inlineProperties);

    let style: CSSProperties = {
      ...inlineProperties,
      /*
       *  1. Having the font-size defined in the HTML element will cause some line-heights to be wrong
       *  2. However this might be some problem with the line-heights or other properties processing
       *  3. For now we will keep the paragraph font-size undefined in the HTML element renderization
       *  4. Each paragraph child will define its own font-size anyway
       *  5. Note: Only the html element has font size of undefined. The font size of the paragraph is still
       *     used to calculate the line-height of the paragraph
       *  6. Example ppt: OS TMA Presentation - 23.04.17 FInal: slide 25 (24 in PPT app)
       */
      fontSize: '0px',
      color: shape.style?.fontRef?.color
        ? color(shape.style.fontRef.color)
        : inlineProperties?.color,
      whiteSpace: 'pre-wrap',
    };

    if (properties) {
      const paragraphHasBullet = isBullet(properties.bullet?.symbol);
      const isHanging =
        properties.indent != null &&
        properties.indent <= 0 &&
        properties.marL != null &&
        properties.marL !== 0;

      const paddingLeft =
        properties.marL != null
          ? properties.indent != null
            ? isHanging
              ? Math.max(Math.abs(properties.indent), properties.marL) //hanging
              : properties.marL //first line
            : properties.marL
          : undefined;

      const { paddingTop, paddingBottom } = getParagraphSpacing({ paragraph });
      const indentValue =
        properties.indent != null && properties.indent > 0 ? properties.indent : 0;

      style = {
        ...style,
        /*
         * If 'algn' is omitted, then a value of left is implied
         */
        textAlign: properties.algn ? TEXT_ALIGN_MAP[properties.algn] : 'left',
        lineHeight: '0px', //Not sure if correct
        marginRight: properties.marR,
        paddingLeft,
        paddingTop,
        paddingBottom,
        //Not sure if correct
        textIndent: isHanging ? properties.indent : paragraphHasBullet ? undefined : indentValue,
        ...textStyle,
      };

      let unsupportedAlgn = '';
      switch (properties.algn) {
        case 'justLow':
          unsupportedAlgn = 'Justify-low';
          break;
        case 'dist':
          unsupportedAlgn = 'Distributed';
          break;
        case 'thaiDist':
          unsupportedAlgn = 'Distributed';
          break;
      }

      if (unsupportedAlgn) {
        //TODO:PRESENTATION:UNSUPPORTED:TEXT
        addUnsupportedElement(`Text - Alignment - ${unsupportedAlgn}`);
      }

      if (properties.rtl) {
        //TODO:PRESENTATION:UNSUPPORTED:TEXT
        addUnsupportedElement(`Text - Right-to-left`);
      }
    }

    return style;
  };

  const getTextStyle = ({
    paragraph,
    run,
  }: {
    paragraph: Presentation.Data.ParagraphShape;
    run?: Presentation.Data.RunShape;
  }): CSSProperties => {
    //Add default link styles if it is a link
    const baseLinkStyles =
      run?.properties?.hlinkClick || run?.properties?.hlinkMouseOver
        ? {
            cursor: 'pointer',
            textDecoration: 'underline',
            color: color({ reference: 'hlink' }) ?? 'blue',
          }
        : {};

    const inlineStyles = run
      ? { ...parseInlineProperties(run.properties) }
      : {
          ...parseInlineProperties(paragraph.endParaProperties),
        };

    /*
     * 1. Paragraph inline properties will be inherit, implicilty, via HTML Node tree, 
          since the text is child of the paragraph
     * 2. If we decide to also inherit the properties via data
     *    and have the paragraph inline properties explicitly defined in the text HTML
     *    we HAVE to make a parseParagraphInlineProperties function and inherit the properties from there
     *    because the paragraph overrides some properties of the default parseInlineProperties, ex: color
     */

    return {
      ...baseLinkStyles,
      ...inlineStyles,
      fontFamily: getTextFontFamily({ paragraph, run }),
      fontSize: getTextFontSize({ paragraph, run }),
      lineHeight: run?.properties?.baseline ? undefined : getTextLineHeight({ paragraph, run }),
      verticalAlign: getTextVerticalAlign({ paragraph, run }),
    };
  };

  //#endregion

  return {
    getFontColor,
    getTextFontFamily,
    getTextFontSize,
    getParagraphStyle,
    getTextStyle,
  };
};

export default useTextProperties;
