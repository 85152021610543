import { useEffect, useState } from 'react';
import { ProgressBar, Switch } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { dayjsWithTimezone } from 'utils';
import TimepickerField, { TimepickerFieldProps } from './TimepickerField/TimepickerField';
import WeekSchedule, { WeekScheduleProps } from './WeekSchedule/WeekSchedule';

import styles from './SettingContent.module.scss';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { useEditUserSettingsMutation, useGetUserSettingsQuery } from '_common/services/api/UserApi';

const SettingContent = () => {
  const intl = useIntl();
  const { data, isLoading } = useGetUserSettingsQuery();
  const [editUserSettings] = useEditUserSettingsMutation();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (data) {
      setIsDisabled(!data.working_hours.active);
    }
  }, [data]);

  const { data: authUser } = useGetCurrentUserQuery();
  const userProfile = authUser?.profile;

  if (isLoading) {
    return <ProgressBar testId="user-notification-settings-loading-progressBar" />;
  }

  if (!data) {
    return null;
  }

  if (!userProfile) {
    return null;
  }

  const handleEditWorkingHours = (
    payload: Partial<ApiSchemas['UserSettingsSchema']['working_hours']>,
  ) => {
    const params: { [setting in string]: boolean | string | undefined } = {};

    if (payload.active !== undefined) {
      params['working_hours.active'] = payload.active;
    }

    if (payload.days !== undefined) {
      (Object.keys(payload.days) as Array<keyof typeof payload.days>).forEach((settingId) => {
        params[`working_hours.days.${settingId}`] = payload.days?.[settingId];
      });
    }

    if (payload.time !== undefined) {
      (Object.keys(payload.time) as Array<keyof typeof payload.time>).forEach((settingId) => {
        let date = dayjsWithTimezone(
          payload.time?.[settingId],
          userProfile.timezone || 'UTC',
          false,
          'HH:mm',
        );

        if (!date.isValid()) {
          date = dayjsWithTimezone(
            `${payload.time?.[settingId]}`,
            userProfile.timezone || 'UTC',
            false,
            'HH:mm',
          );
        }

        params[`working_hours.time.${settingId}`] = `${payload.time?.[settingId]}${date.format(
          'Z',
        )}`;
      });
    }

    editUserSettings(params);
  };

  const handleSwitchChange = () => {
    handleEditWorkingHours({
      ...data.working_hours,
      active: !data.working_hours.active,
    });
  };

  const getDateTime = (
    timeOption: Parameters<NonNullable<TimepickerFieldProps['onChange']>>[1],
  ) => {
    if (timeOption) {
      return `${timeOption.value}`;
    }

    return null;
  };

  const handleStartTimeChange: TimepickerFieldProps['onChange'] = (isValid, newValue) => {
    if (isValid && newValue?.value) {
      handleEditWorkingHours({
        time: { ...data.working_hours.time, start: getDateTime(newValue) || '' },
      });
    }
  };

  const handleEndTimeChange: TimepickerFieldProps['onChange'] = (isValid, newValue) => {
    if (isValid) {
      handleEditWorkingHours({
        time: { ...data.working_hours.time, end: getDateTime(newValue) || '' },
      });
    }
  };

  const handleWorkingDayChange: WeekScheduleProps['onChange'] = (workingDays) => {
    handleEditWorkingHours({ days: workingDays });
  };

  return (
    <div>
      <Switch
        onChange={handleSwitchChange}
        margin="0 0 1rem 0"
        active={data.working_hours.active}
        testId="working_hours_isActive"
      />
      <div className={styles.schedule}>
        <TimepickerField
          label={intl.formatMessage({ id: 'START_TIME' })}
          disabled={isDisabled}
          onChange={handleStartTimeChange}
          value={isDisabled ? undefined : data.working_hours.time.start}
          testId={{ inputField: 'start_time_inputField', timepicker: 'start_time_timepicker' }}
        />
        <TimepickerField
          label={intl.formatMessage({ id: 'END_TIME' })}
          disabled={isDisabled}
          margin="0 0 0 2rem"
          onChange={handleEndTimeChange}
          value={isDisabled ? undefined : data.working_hours.time.end}
          testId={{ inputField: 'end_time_inputField', timepicker: 'end_time_timepicker' }}
        />
        <WeekSchedule
          disabled={!data.working_hours.active}
          onChange={handleWorkingDayChange}
          margin="0rem 0 0 3rem"
          workingDays={data.working_hours.days}
        />
      </div>
    </div>
  );
};

export default SettingContent;
