import { useEffect, useMemo } from 'react';
import useOutline from '../../useOutline';
import useChartColor from './useChartColor';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useUnsupportedProperties from './useUnsupportedProperties';
import { useChartData } from '../ChartData';

const useChartPlotArea = (
  plotArea?: Presentation.Data.ChartShape['chartSpace']['chart']['plotArea'],
) => {
  const { addUnsupportedElement } = useSlideData();
  const { chartColor } = useChartColor();
  const { parseOutline } = useOutline();
  const { handleUnsupportedShapeEffects } = useUnsupportedProperties();
  const { chartShape, legendRect } = useChartData();

  /**
   * This was generated by copilot AI
   * The goal is to use a linear regression function to find the ratio between the value and results
   * of a sample and predict the result of a certain value.
   * This is used to calculate the percentage offsets of the chart accordingly to the legend height.
   *
   */
  const predictValueResult = (
    sample: { values: number[]; results: number[] },
    valueToPredict: number,
  ) => {
    // Data points
    const { values, results } = sample;

    // Calculate sums
    const sumX = values.reduce((acc, x) => acc + x, 0);
    const sumY = results.reduce((acc, y) => acc + y, 0);
    const sumXY = values.reduce((acc, x, i) => acc + x * results[i], 0);
    const sumX2 = values.reduce((acc, x) => acc + x * x, 0);
    const n = values.length;

    // Calculate slope (m) and y-intercept (b)
    const m = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const b = (sumY - m * sumX) / n;

    return m * valueToPredict + b;
  };

  const chartLegend = useMemo(() => {
    return chartShape?.chartSpace?.chart?.legend;
  }, [chartShape]);

  const offsets = useMemo<
    Pick<echarts.GridComponentOption, 'top' | 'right' | 'bottom' | 'left'>
  >(() => {
    const legendHeight = legendRect?.height ?? 0;
    const legendWidth = legendRect?.width ?? 0;

    if (plotArea?.layout?.type === 'manual') {
      return { top: 'center', bottom: 'center' };
    }

    /**
     * These are default values for the chart plot area offsets
     * that were defined by eye accordingly to PowerPoint renderization and eCharts library
     */

    const offsets: Pick<echarts.GridComponentOption, 'top' | 'right' | 'bottom' | 'left'> = {
      bottom: '5%',
      top: '10%',
      left: '1%',
    };

    if (chartLegend?.layout?.type === 'manual') {
      /**
       * All of the following samples were chosen by eye
       * accordingly to the PowerPoint renderization and eCharts library
       */

      if (legendWidth > legendHeight) {
        const predictedBottom = predictValueResult(
          { values: [31, 25, 17], results: [13, 12, 10] },
          legendHeight,
        );
        offsets.bottom = `${predictedBottom}%`;
      } else if (legendWidth < legendHeight) {
        const predictedRight = predictValueResult(
          { values: [88, 67, 58], results: [14, 12, 11] },
          legendHeight,
        );
        offsets.right = `${predictedRight}%`;
      }
    }

    return offsets;
  }, [legendRect]);

  useEffect(() => {
    //TODO:PRESENTATION:UNSUPPORTED:CHART:PLOTAREA:EFFECTS
    handleUnsupportedShapeEffects(plotArea?.properties, 'Chart - Plot Area');

    //TODO:PRESENTATION:UNSUPPORTED:CHART:SERIE:EFFECTS
    plotArea?.chartTypes?.forEach((charType) => {
      charType.ser.forEach((serie) => {
        handleUnsupportedShapeEffects(serie.properties, 'Chart - Serie');
      });
    });
  }, [plotArea]);

  const backgroundColor = useMemo(() => {
    switch (plotArea?.properties.fill?.type) {
      case 'picture':
        if (plotArea.properties.fill.tile) {
          //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:FILL:TEXTURE
          addUnsupportedElement('Chart - Plot Area - Texture Fill');
        } else {
          //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:FILL:PICTURE
          addUnsupportedElement('Chart - Plot Area - Picture Fill');
        }
        return 'transparent';
      case 'pattern':
        //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:FILL:PATTERN
        addUnsupportedElement('Chart - Plot Area - Pattern Fill');
        return 'transparent';
      default:
        return chartColor(plotArea?.properties.fill);
    }
  }, [plotArea?.properties.fill]);

  const borderColor = useMemo(() => {
    switch (plotArea?.properties.ln?.fill?.type) {
      case 'solid':
        //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:LINE:SOLID
        addUnsupportedElement('Chart - Plot Area - Solid Line');
        break;
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:PLOTAREA:LINE:GRADIENT
        addUnsupportedElement('Chart - Plot Area - Gradient Line');
        break;
    }
  }, [plotArea?.properties.ln?.fill]);

  if (plotArea) {
    const values = parseOutline(plotArea.properties.ln);

    return {
      show:
        plotArea.properties.fill?.type === 'none' || !plotArea.properties.fill?.type ? false : true,
      backgroundColor,
      borderColor,
      borderWidth: values.strokeWidth,
      borderType: values.strokeDasharray?.split(',').map((value) => +value),
      cap: values.strokeLinecap,
      join: values.strokeLinejoin,
      miterLimit: values.strokeMiterlimit,
      containLabel: true,
      ...offsets,
    };
  }
  return null;
};

export default useChartPlotArea;
