import { NodeUtils } from 'Editor/services/DataManager';

export class TableUtils {
  static DEFAULT_ROW_HEIGHT = 36 as const;

  /**
   * calculates the real column weight in the table from 0 to 1;
   */
  static findColumnsWeights(
    tableData: Editor.Data.Node.TableData,
    columnIndex: number[] = [],
    pageWidth: number,
  ) {
    const columnsWeights: { [index: number]: number } = {};

    const tableWidth = tableData.properties?.w;
    const columnWidths: Editor.Data.Node.TableWidth[] = TableUtils.getColumnWidths(tableData);

    let tableWidthAbs = 0;
    if (tableWidth?.t === 'abs') {
      tableWidthAbs = tableWidth.v;
    } else if (tableWidth?.t === 'pct') {
      tableWidthAbs = tableWidth.v * pageWidth;
    }

    if (tableWidthAbs > 0) {
      const colSum = columnWidths.reduce((sum, col) => {
        if (col.t === 'pct') {
          sum = sum + tableWidthAbs * col.v;
        } else if (col.t === 'abs') {
          sum = sum + col.v;
        } else {
          sum = sum + tableWidthAbs / columnWidths.length;
        }
        return sum;
      }, 0);

      for (let i = 0; i < columnIndex.length; i++) {
        const ci = columnIndex[i];

        let columnIndexAbs = 0;
        if (columnWidths[ci]?.t === 'abs') {
          columnIndexAbs = columnWidths[ci].v;
        } else if (columnWidths[ci]?.t === 'pct') {
          columnIndexAbs = columnWidths[ci].v * pageWidth;
        } else {
          columnIndexAbs = tableWidthAbs / columnWidths.length;
        }

        columnsWeights[ci] = (columnIndexAbs * 1) / colSum;
      }
    }

    return columnsWeights;
  }

  static getColumnWidths(tableData: Editor.Data.Node.TableData) {
    const columnWidths: Editor.Data.Node.TableWidth[] = [];

    const rowsData = tableData.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            let cellWidth: Editor.Data.Node.TableWidth;
            if (cell.properties?.w) {
              cellWidth = {
                t: cell.properties.w.t,
                v: cell.properties.w.v,
              };
            } else {
              cellWidth = {
                t: 'auto',
                v: 0,
              };
            }

            if (columnWidths[c] != null) {
              if (
                (columnWidths[c].t !== 'abs' && cellWidth.t === 'abs') ||
                (columnWidths[c].t === cellWidth.t && columnWidths[c].v < cellWidth.v)
              ) {
                columnWidths[c].t = cellWidth.t;
                columnWidths[c].v = cellWidth.v;
              }
            } else {
              columnWidths.push(cellWidth);
            }
          }
        }
      }
    }

    return columnWidths;
  }

  static getCellInfo(
    cells:
      | { data: Editor.Data.Node.TableCellData; path: Editor.Selection.Path }[]
      | Editor.Data.Node.DataPathInfo[],
  ): Editor.Edition.CellInfo[] {
    const cellsInfo: Editor.Edition.CellInfo[] = [];

    for (let i = 0; i < cells.length; i++) {
      const cell = cells[i];
      if (cell.data && cell.data.id && cell.data.properties) {
        const cellIndexs = NodeUtils.getCurrentAndParentIndexByPath(cell.path);

        if (cellIndexs?.currentIndex != null && cellIndexs?.parentIndex != null) {
          cellsInfo.push({
            cellIndex: cellIndexs.currentIndex,
            colSpan: cell.data.properties.cs ? cell.data.properties.cs : 1,
            id: cell.data.id,
            isMergedCell: !!cell.data.properties['head-id'],
            sectionRowIndex: cellIndexs.parentIndex,
            rowSpan: cell.data.properties.rs ? cell.data.properties.rs : 1,
          });
        }
      }
    }

    return cellsInfo;
  }

  static getRowsIndex(selectedCells: Editor.Edition.CellInfo[]): number[] {
    let rowsIndex: number[] = [];
    for (let i = 0; i < selectedCells.length; i++) {
      const index = selectedCells[i].sectionRowIndex;
      if (index != null && !rowsIndex.includes(index)) {
        rowsIndex.push(index);
      }
    }

    rowsIndex.sort((a, b) => {
      return a - b;
    });

    return rowsIndex;
  }

  static getCellsIndex(selectedCells: Editor.Edition.CellInfo[]): number[] {
    let cellsIndex: number[] = [];
    for (let i = 0; i < selectedCells.length; i++) {
      const index = selectedCells[i].cellIndex;
      if (index != null && !cellsIndex.includes(index)) {
        cellsIndex.push(index);
      }
    }

    cellsIndex.sort((a, b) => {
      return a - b;
    });

    return cellsIndex;
  }
}
