import NodeIcon from '../Tables/NodeIcon/NodeIcon';

import styles from './BaseRow/BaseRow.module.scss';

type ObjectNameProps = {
  object: Objekt;
};

const ObjectName = ({ object }: ObjectNameProps) => {
  const localObject = object?.object || object;

  return (
    <>
      <div className={styles.nodeIconWrapper}>
        <NodeIcon object={localObject} />
      </div>
      <div className={styles.name}>{localObject.name}</div>
    </>
  );
};

export default ObjectName;
