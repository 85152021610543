import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, ProgressBar, SectionHeading } from 'dodoc-design-system';

import { useDispatch, useObject } from '_common/hooks';
import useMetadataList from '_common/hooks/useMetadataList';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { Setting } from 'Settings/components';

import UsernameLabel from '../Labels/UsernameLabel/UsernameLabel';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';

import styles from './Metadata.module.scss';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

type MetadataItemProps = {
  label: string;
  children: ReactNode;
};

type MetadataProps = {
  id: ObjectId;
  setting?: boolean;
};

const MetadataItem = ({ label, children }: MetadataItemProps) => (
  <div className={styles.metadataItemContainer} key={label}>
    <div className={styles.labelContainer}>{label}</div>
    <div className={`${styles.valueContainer} ${children === 'not set' && styles.noValue}`}>
      {children}
    </div>
  </div>
);

const Metadata = ({ id, setting }: MetadataProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { automatic, manual, isLoading } = useMetadataList();
  const { data } = useObject({ object_type: 'document', object_id: id });
  const { data: statuses } = useGetElementStatusListQuery();

  const handleEdit = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'EditMetadata',
        data: { id },
      }),
    );
  };

  const handleValue = (field: ApiSchemas['MetadataObjectSchema']) => {
    const value = data?.metadata?.[field.id];
    if (value) {
      if (field.data_type === 'user' && !Array.isArray(value)) {
        return <UsernameLabel userId={value} />;
      }
      if (field.automatic && field.trigger === 'obj_status' && !Array.isArray(value)) {
        const status = statuses?.entities[value];
        return status?.name;
      }
      if (Array.isArray(value) && value.length > 1) {
        return value.join(', ');
      }
      return value;
    }
    return <FormattedMessage id="NOT_SET" />;
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <ProgressBar testId="metadata-progressBar" />
        <div className={styles.message}>
          <FormattedMessage id="LOADING_METADATA" />
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${setting && styles.setting}`}>
      {setting ? (
        <IntlErrorBoundary fallbackType="sectionHeading">
          <SectionHeading
            title={intl.formatMessage({ id: 'MANUAL_METADATA' })}
            size="large"
            buttonRightProps={{
              size: 'medium',
              children: intl.formatMessage({ id: 'EDIT_METADATA' }),
              onClick: handleEdit,
              testId: 'edit-metadata',
            }}
            testId={`manual-metadata-heading`}
          />
        </IntlErrorBoundary>
      ) : (
        <>
          <div className={styles.manualContainer}>
            <div className={styles.text}>
              <FormattedMessage id="MANUAL_METADATA" />
            </div>
            <Button variant="link" onClick={handleEdit} size="small" testId="edit-metadata-button">
              <FormattedMessage id="global.edit" />
            </Button>
          </div>
          <Divider margin="0.5rem 0 2rem 0" />
        </>
      )}
      <div className={styles.metadataGroup}>
        {manual.map((field, i) =>
          setting ? (
            <Setting
              key={field.id}
              label={field.name}
              labelStyles={{ flex: 1, maxWidth: '36rem' }}
              valueStyles={{ paddingRight: '3rem', whiteSpace: 'nowrap', flex: 1 }}
              testId={`setting-${i}`}
            >
              {handleValue(field)}
            </Setting>
          ) : (
            <MetadataItem key={field.id} label={field.name}>
              {handleValue(field)}
            </MetadataItem>
          ),
        )}
      </div>
      {setting ? (
        <IntlErrorBoundary fallbackType="sectionHeading">
          <SectionHeading
            title={intl.formatMessage({ id: 'AUTOMATIC_METADATA' })}
            size="large"
            testId={`automatic-metadata-heading`}
            margin="4rem 0 0 0"
          />
        </IntlErrorBoundary>
      ) : (
        <>
          <div className={styles.manualContainer}>
            <div className={styles.text}>
              <FormattedMessage id="AUTOMATIC_METADATA" />
            </div>
          </div>
          <Divider margin="0.5rem 0 2rem 0" />
        </>
      )}
      <div className={styles.metadataGroup}>
        {automatic.map((field, i) =>
          setting ? (
            <Setting
              key={field.id}
              label={field.name}
              labelStyles={{ flex: 1, maxWidth: '36rem' }}
              valueStyles={{ paddingRight: '3rem', whiteSpace: 'nowrap', flex: 1 }}
              testId={`setting-${i}`}
            >
              {handleValue(field)}
            </Setting>
          ) : (
            <MetadataItem key={field.id} label={field.name}>
              {handleValue(field)}
            </MetadataItem>
          ),
        )}
      </div>
    </div>
  );
};

export default Metadata;
