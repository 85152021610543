import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { BULLET_SYMBOLS, MORE_BULLET_SYMBOLS } from 'Editor/modals/NewListModal/consts';

import { Select, Checkbox, Icon, Toggle } from 'dodoc-design-system';

import styles from 'Editor/modals/NewListModal/NewListModal.module.scss';
import { Props } from './FormattingPane';

const IrregularBox = ({
  type,
  listItems,
  setListItems,
  selectedLine,
  getLabels,
  numberingTypes,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [defaultBulletList, setDefaultBulletList] = useState<string[]>([]);
  useEffect(() => {
    const localStorage = window.localStorage.getItem('bullet-types');
    if (localStorage === null) {
      window.localStorage.setItem('bullet-types', JSON.stringify(BULLET_SYMBOLS));
    } else {
      setDefaultBulletList(JSON.parse(localStorage));
    }
  }, []);

  const handleClick = (id: string) => {
    if (!defaultBulletList.includes(id)) {
      const newDefaultList: string[] = [id, ...defaultBulletList];
      newDefaultList.pop();
      setDefaultBulletList(newDefaultList);
      window.localStorage.setItem('bullet-types', JSON.stringify(newDefaultList));
    }
    setListItems(id, 'type');
  };

  const handleOnChange = (values: { value: string; label: string }) => {
    setListItems(values, 'numbering_type');
    setListItems(1, 'start_from');
  };

  if (!listItems || !listItems[selectedLine]) {
    return null;
  }

  if (type === 'bullet') {
    return (
      <>
        <div className={styles.gridContainer}>
          {defaultBulletList?.map((char, index) => {
            const charValue = Number(char);
            return (
              <Toggle
                variant="link"
                isToggled={char === listItems[selectedLine].type}
                size="medium"
                onClick={() => handleClick(char)}
                testId={`${char}-bullet-format-toggle`}
              >
                <div className={styles.gridItem}>{String.fromCharCode(charValue)}</div>
              </Toggle>
            );
          })}
        </div>
        <div
          className={`${styles.moreBullets} ${isOpen && styles.isOpen}`}
          onClick={() => setIsOpen(!isOpen)}
          data-testId="more-bullets-toggle"
        >
          <div className={`${styles.angleIcon} ${!isOpen && styles.isAngleClose}`}>
            <Icon icon="AngleDownBlue" size={24} />
          </div>
          <FormattedMessage id="MORE_BULLETS" />
        </div>
        {isOpen && (
          <div className={styles.moreBulletsContainer}>
            {MORE_BULLET_SYMBOLS.map((char) => {
              const charValue = Number(char);
              return (
                <Toggle
                  variant="link"
                  isToggled={char === listItems[selectedLine].type}
                  size="medium"
                  onClick={() => handleClick(char)}
                  testId={`${char}-bullet-format-toggle`}
                >
                  <div className={styles.gridItem}>{String.fromCharCode(charValue)}</div>
                </Toggle>
              );
            })}
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div className={styles.text}>
        <FormattedMessage id="NUMBERING_TYPE" />
      </div>
      <Select
        creatable
        isSearchable={false}
        isClearable={false}
        options={numberingTypes}
        value={getLabels(
          'numberingTypes',
          listItems[selectedLine].numbering_type?.value || listItems[selectedLine].numbering_type,
        )}
        onChange={handleOnChange}
        customStyles={{
          container: (provided: any) => ({ ...provided, marginBottom: '4rem' }),
          control: (provided: any) => ({ ...provided, height: '4rem' }),
        }}
        width="auto"
        size="medium"
        testId="numbering-type"
      />
      <div style={{ marginTop: '1rem', marginBottom: '4rem' }}>
        <Checkbox
          checked={listItems[selectedLine].incl_prev_lvls ? 'checked' : 'unchecked'}
          onChange={() => setListItems(!listItems[selectedLine].incl_prev_lvls, 'incl_prev_lvls')}
          disabled={listItems[selectedLine].level === 0}
          testId="include-previous-numbering-checkbox"
        >
          <FormattedMessage id="INCLUDE_PREVIOUS_NUMBERING" />
        </Checkbox>
      </div>
    </>
  );
};

export default IrregularBox;
