import { useMemo } from 'react';

const useSize = (shape: Presentation.Data.Shape) => {
  return useMemo(() => {
    if (shape.properties.xfrm?.ext) {
      return {
        width: shape.properties.xfrm.ext.cx,
        height: shape.properties.xfrm.ext.cy,
      };
    }
    return { height: 0, width: 0 };
  }, [shape]);
};

export default useSize;
