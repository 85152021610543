import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewElement } from '../..';
import './SymbolElement.module.scss';

export class SymbolElement extends BaseViewElement {
  connectedCallback() {
    super.connectedCallback();
    if (this.hasAttribute('fontfamily')) {
      this.style.fontSize = `${this.getAttribute('fontfamily')} !important`;
    }
    if (this.hasAttribute('content')) {
      while (this.firstChild) {
        this.removeChild(this.firstChild);
      }
      const fixedFromCodePoint = (codePt: any) => {
        if (codePt > 0xffff) {
          codePt -= 0x10000;
          // eslint-disable-next-line no-bitwise
          return String.fromCodePoint(0xd800 + (codePt >> 10), 0xdc00 + (codePt & 0x3ff));
        }
        return String.fromCodePoint(codePt);
      };

      this.appendChild(
        document.createTextNode(fixedFromCodePoint(`0x${this.getAttribute('content')}`)),
      );
    }
  }
}

// register element
if (!window.customElements.get(ELEMENTS.SymbolElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.SymbolElement.IDENTIFIER, SymbolElement);
}
