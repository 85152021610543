import { useState } from 'react';

import { useGetTemplatesListQuery } from '../TemplatesApi';

import { FilterDisplay, IntlErrorBoundary } from '_common/components';

import { ErrorState } from 'Settings/components';
import TemplatesHeading from './TemplatesHeading';
import TemplateTable from './TemplateTable/TemplateTable';

import styles from './TemplateSection.module.scss';

export type TemplateSectionProps = {
  category: string;
  testId: string;
};

const TemplateSection = ({ category, testId }: TemplateSectionProps) => {
  const { isLoading, isError } = useGetTemplatesListQuery();

  const [search, setSearch] = useState('');

  const renderContent = () => {
    if (isError) {
      return (
        <div data-testid="errorState">
          <ErrorState text="TEMPLATE_LIST_ERROR" testId="template-error-helper" />
        </div>
      );
    }

    return (
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <FilterDisplay identity={`templates-${category}`} />
        <TemplateTable category={category} search={search} />
      </IntlErrorBoundary>
    );
  };

  return (
    <div className={styles.root}>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <TemplatesHeading
          category={category}
          search={search}
          onSearchChange={setSearch}
          testId={`${testId}-heading`}
        />
      </IntlErrorBoundary>
      {!isLoading && renderContent()}
    </div>
  );
};

export default TemplateSection;
