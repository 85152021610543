import { NodeUtils } from 'Editor/services/DataManager';
import { BaseStylesManipulator } from './BaseStyles';
import {
  InsertElementOperation,
  RemoveContentOperation,
} from 'Editor/services/Edition_v2/Operations';
import { PathUtils } from 'Editor/services/_Common/Selection';

type FilteredData = { nodes: Editor.Data.Node.Data[]; filtered: boolean };

type Paths = {
  start: Editor.Selection.Path;
  end: Editor.Selection.Path;
};

export class RemoveStylesManipulator
  extends BaseStylesManipulator
  implements Editor.Edition.IRemoveStyleManipulator
{
  private unwrapElement(
    baseModel: Editor.Data.Node.Model,
    elementData: Editor.Data.Node.Data,
    pathToElement: Editor.Selection.Path,
  ): Paths | undefined {
    const baseData = baseModel.selectedData();

    if (!baseData) {
      return;
    }

    let startPath = [...pathToElement];
    let endPath = [...pathToElement];

    let offset = Number(endPath[endPath.length - 1]);
    if (!isNaN(offset)) {
      endPath[endPath.length - 1] = offset + 1;
    }

    const clonedNodes = NodeUtils.cloneData(
      baseData,
      [...startPath, 'childNodes', 0],
      [...startPath, 'childNodes', elementData.childNodes?.length || 0],
    );

    const removeOp = new RemoveContentOperation(baseModel, startPath, endPath, {
      mergeText: false,
    });
    removeOp.apply();

    let workingPath = removeOp.getAdjustedPath() || startPath;

    if (clonedNodes.length) {
      for (let i = 0; i < clonedNodes.length; i++) {
        const insertOp = new InsertElementOperation(baseModel, workingPath, clonedNodes[i], {
          mergeText: false,
          pathFix: 'AFTER',
        }).apply();
        const adjustedPath = insertOp.getAdjustedPath();
        if (adjustedPath) {
          workingPath = adjustedPath;
        }
      }
    }

    return { start: removeOp.getAdjustedPath() || startPath, end: workingPath };
  }

  private splitAncestor(
    baseModel: Editor.Data.Node.Model,
    pathToAncestor: Editor.Selection.Path,
    pathToSplit: Editor.Selection.Path,
  ): Editor.Selection.Path | undefined {
    const baseData = baseModel.selectedData();

    if (!baseData) {
      return;
    }

    if (!PathUtils.isChildPath(pathToAncestor, pathToSplit)) {
      return;
    }

    const ancestorData = NodeUtils.getChildDataByPath(baseData, pathToAncestor);

    if (!ancestorData) {
      return;
    }

    const subPath = pathToSplit.slice(pathToAncestor.length);

    // check if path is at ancestor start or end
    if (NodeUtils.isPathAtContentStart(ancestorData, subPath)) {
      // return path before
      return pathToAncestor;
    } else if (NodeUtils.isPathAtContentEnd(ancestorData, subPath)) {
      // return path after
      let pathAfter = [...pathToAncestor];

      let ancestorOffset = Number(pathAfter[pathAfter.length - 1]);
      if (!isNaN(ancestorOffset)) {
        pathAfter[pathAfter.length - 1] = ancestorOffset + 1;
      }

      return pathAfter;
    } else {
      // split content
      let startPath = [...pathToSplit];
      let endPath = [...pathToAncestor];

      let ancestorOffset = Number(endPath[endPath.length - 1]);
      if (!isNaN(ancestorOffset)) {
        endPath[endPath.length - 1] = ancestorOffset + 1;
      }

      const clonedData = NodeUtils.cloneData(baseData, startPath, endPath);

      if (clonedData.length) {
        const removeOp = new RemoveContentOperation(baseModel, startPath, endPath, {
          mergeText: false,
        });
        removeOp.apply();

        let workingPath = endPath;
        for (let i = 0; i < clonedData.length; i++) {
          const insertOp = new InsertElementOperation(baseModel, workingPath, clonedData[i], {
            mergeText: false,
            pathFix: 'AFTER',
          }).apply();
          const adjustedPath = insertOp.getAdjustedPath();
          if (adjustedPath) {
            workingPath = adjustedPath;
          }
        }
        return endPath;
      }
    }

    return pathToSplit;
  }

  private removeStyleFromNodes<T extends Editor.Edition.InlineStyles>(
    nodes: Editor.Data.Node.Data[],
    style: T,
    value?: Editor.Edition.StylesMap[T],
  ): FilteredData {
    const filtered: FilteredData = {
      nodes: [],
      filtered: false,
    };

    for (let i = 0; i < nodes.length; i++) {
      const child = nodes[i];

      if (NodeUtils.isElementData(child)) {
        if (NodeUtils.isFormatData(child)) {
          // format elements

          let checkFormatChildNodes: boolean = false;

          let removeStyle: boolean = false;
          if (value != null) {
            removeStyle = this.FORMAT_DATA_MAPPER[style].hasStyleValue(child, value);
          } else {
            removeStyle = this.FORMAT_DATA_MAPPER[style].hasStyle(child);
          }

          if (removeStyle) {
            // format with style to remove
            this.FORMAT_DATA_MAPPER[style].removeStyle(child);

            if (this.hasFormatAnyStyle(child)) {
              // format has other styles
              checkFormatChildNodes = true;
            } else {
              // no more styles, unwrap child nodes
              checkFormatChildNodes = false;
              if (child.childNodes?.length) {
                const data = this.removeStyleFromNodes(child.childNodes, style, value);
                if (data.nodes.length) {
                  filtered.nodes.push(...data.nodes);
                }
              }
            }
            filtered.filtered = true;
          } else {
            checkFormatChildNodes = true;
          }

          if (checkFormatChildNodes) {
            // formats with other styles
            if (child.childNodes?.length) {
              const data = this.removeStyleFromNodes(child.childNodes, style, value);
              if (data.nodes.length) {
                // normalize parentIds
                for (let c = 0; c < data.nodes.length; c++) {
                  data.nodes[c].parent_id = child._id;
                }
                child.childNodes = data.nodes;
                filtered.filtered = filtered.filtered || data.filtered;
              } else {
                child.childNodes = [];
              }
            }
            if (child.childNodes?.length) {
              // do not add if empty
              filtered.nodes.push(child);
            }
          }
        } else {
          // other elements
          if (child.childNodes?.length) {
            const data = this.removeStyleFromNodes(child.childNodes, style, value);
            if (data.nodes.length) {
              // normalize parentIds
              for (let c = 0; c < data.nodes.length; c++) {
                data.nodes[c].parent_id = child._id;
              }
              child.childNodes = data.nodes;
              filtered.filtered = filtered.filtered || data.filtered;
            } else {
              child.childNodes = [];
            }
          }

          filtered.nodes.push(child);
        }
      } else if (NodeUtils.isTextData(child)) {
        filtered.nodes.push(child);
      }
    }

    // merge contiguous text nodes
    let length = filtered.nodes.length;
    for (let i = length - 1; i > 0; i--) {
      const current = filtered.nodes[i];
      const previous = filtered.nodes[i - 1];
      if (NodeUtils.isTextData(previous) && NodeUtils.isTextData(current)) {
        previous.content += current.content;
        filtered.nodes.splice(i, 1);
      }
    }

    return filtered;
  }

  private removeStyleFromElement<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
    style: T,
    value: Editor.Edition.StylesMap[T],
  ): Paths | undefined {
    let baseData = baseModel.selectedData();

    if (!baseData || !this.manipulatorContext.common) {
      return;
    }

    let workingStartPath: Editor.Selection.Path = [...startPath];
    let workingEndPath: Editor.Selection.Path = [...endPath];

    let commonAncestorPath = PathUtils.getCommonAncestorPath(startPath, endPath);

    // check if there is a common ancestor with style
    let closestFormat: Editor.Data.Node.DataPathInfo | null = this.getClosestWithStyle(
      baseData,
      commonAncestorPath,
      style,
      value,
    );

    if (closestFormat) {
      // check if paths are at start and end
      const formatSubStart = startPath.slice(closestFormat.path.length);
      const formatSubEnd = endPath.slice(closestFormat.path.length);

      if (
        NodeUtils.isPathAtContentStart(closestFormat.data, formatSubStart) &&
        NodeUtils.isPathAtContentEnd(closestFormat.data, formatSubEnd)
      ) {
        const propKeys = Object.keys(closestFormat.data.properties || {});
        if (propKeys.length > 1) {
          // remove prop
          this.removeStyleOperation(baseModel, closestFormat.path, style);
          return { start: startPath, end: endPath };
        } else {
          // unwrap format
          return this.unwrapElement(baseModel, closestFormat.data, closestFormat.path);
        }
      } else {
        // split ancestor end
        this.splitAncestor(baseModel, closestFormat.path, workingEndPath);

        // split ancestor start
        let resultStartPath = this.splitAncestor(baseModel, closestFormat.path, workingStartPath);
        if (resultStartPath) {
          workingStartPath = resultStartPath;
        }

        // set end after split start
        let offset = Number(workingStartPath[workingStartPath.length - 1]);
        workingEndPath = [...workingStartPath];
        if (!isNaN(offset)) {
          workingEndPath[workingEndPath.length - 1] = offset + 1;
        }
      }
    }

    baseData = baseModel.selectedData();

    if (!baseData) {
      return;
    }

    const nodesToFiler = NodeUtils.cloneData(baseData, workingStartPath, workingEndPath);

    // filer nodes and remove style
    let filtered = this.removeStyleFromNodes(nodesToFiler, style, value);

    // only remove and insert content if nodes were filtered or needs to split ancestor
    if ((filtered.filtered && filtered.nodes.length) || closestFormat != null) {
      // remove content
      const removeOp = new RemoveContentOperation(baseModel, workingStartPath, workingEndPath, {
        mergeText: false,
      });
      removeOp.apply();
      let resultPath = removeOp.getAdjustedPath();
      if (resultPath) {
        workingStartPath = resultPath;
      }

      // insert nodes
      let insertPath = [...workingStartPath];
      for (let i = 0; i < filtered.nodes.length; i++) {
        const nodeData = filtered.nodes[i];

        let resultPath = this.insertElement(baseModel, nodeData, insertPath);

        if (resultPath) {
          insertPath = resultPath;
        }
      }

      workingEndPath = insertPath;
    }

    return {
      start: workingStartPath,
      end: workingEndPath,
    };
  }

  removeStyle<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    range: Editor.Selection.JsonRange,
    style: T,
    value?: Editor.Edition.StylesMap[T],
  ): Editor.Selection.JsonRange {
    let baseData = baseModel.selectedData();

    if (!baseData || !this.manipulatorContext.common || !this.editionContext.DataManager) {
      return range;
    }

    let updatedPaths: Paths | undefined;

    let startPath: Editor.Selection.Path | undefined;

    let endPathPosition = NodeUtils.getNavigationPositionFromPath(
      baseModel.navigationData,
      range.end.p,
    );

    const stylableData = this.getSylableDataInfo(range);

    for (let i = stylableData.length - 1; i >= 0; i--) {
      const stylableInfo = stylableData[i];

      let pathAfterChild = [...stylableInfo.childPath];
      let offset = Number(stylableInfo.childPath[stylableInfo.childPath.length - 1]);
      if (!isNaN(offset)) {
        pathAfterChild[pathAfterChild.length - 1] = offset + 1;
      }

      let initialPaths: Partial<Paths> = {};

      if (
        PathUtils.isChildPath(stylableInfo.childPath, range.start.p) &&
        PathUtils.isChildPath(stylableInfo.childPath, range.end.p)
      ) {
        // start and end within same element
        if (NodeUtils.isNonStylableInlindeData(stylableInfo.childData)) {
          initialPaths.start = stylableInfo.childPath;
          initialPaths.end = pathAfterChild;
        } else {
          initialPaths.start = range.start.p;
          initialPaths.end = range.end.p;
        }
      } else if (PathUtils.isChildPath(stylableInfo.childPath, range.start.p)) {
        // start within element
        if (NodeUtils.isNonStylableInlindeData(stylableInfo.childData)) {
          initialPaths.start = stylableInfo.childPath;
          initialPaths.end = pathAfterChild;
        } else {
          initialPaths.start = range.start.p;
          initialPaths.end = pathAfterChild;
        }
      } else if (PathUtils.isChildPath(stylableInfo.childPath, range.end.p)) {
        // end within element
        if (NodeUtils.isNonStylableInlindeData(stylableInfo.childData)) {
          initialPaths.start = stylableInfo.childPath;
          initialPaths.end = pathAfterChild;
        } else {
          initialPaths.start = stylableInfo.childPath;
          initialPaths.end = range.end.p;
        }
      } else {
        initialPaths.start = stylableInfo.childPath;
        initialPaths.end = pathAfterChild;
      }

      if (initialPaths.start && initialPaths.end) {
        updatedPaths = this.removeStyleFromElement(
          baseModel,
          initialPaths.start,
          initialPaths.end,
          style,
          value,
        );

        if (updatedPaths) {
          if (i === 0) {
            startPath = updatedPaths.start;
          }
        }
      }
    }

    const jRange = range.cloneRange();

    if (startPath) {
      jRange.setStart({ b: baseModel.id, p: startPath });
    }

    // adjust range
    let updatedEndPath = NodeUtils.getPathFromNavigationPosition(
      baseModel.navigationData,
      endPathPosition,
    );

    if (updatedEndPath) {
      jRange.setEnd({ b: baseModel.id, p: updatedEndPath });
    }

    return jRange;
  }
}
