import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'dodoc-design-system';
import { SelectProps } from 'dodoc-design-system/build/types/Components/Selects/Select';

type LanguageSelectProps = Pick<SelectProps<Services.Language>, 'value' | 'onChange' | 'size' | 'testId'> & {
  options: Services.Language[];
};

const LanguageSelect = ({ value, options, onChange, size, testId }: LanguageSelectProps) => {
  const [languageOptions, setLanguageOptions] = useState<Services.Language[]>(options);

  useEffect(() => {
    setLanguageOptions(
      options?.map((option) => {
        if (option.supported) {
          return { ...option, suffixIcon: 'SpellCheck' };
        } else {
          return option;
        }
      }),
    );
  }, [options]);

  return (
    <Select
      creatable
      isClearable={false}
      menuPlacement="bottom"
      menuPosition="fixed"
      options={languageOptions}
      value={value}
      onChange={onChange}
      size={size}
      width="100%"
      testId={testId}
    />
  );
};

LanguageSelect.propTypes = {
  large: PropTypes.bool,
};

export default LanguageSelect;
