import { useSuiteObject } from '_common/suite';
import { NotificationsCenter } from '_common/components';
import { useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { usePDFContext } from './PDFContext';

const PDFNotificationsCenter = () => {
  const object = useSuiteObject();
  const pdfManager = usePDFContext();
  const pdf = useSelector((state) => state.pdf);
  const pdfId = pdf?.general.pdfId ?? '';

  const handleNavigateToObject = ({
    objectType,
    objectId,
  }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
    objectId: ObjectId;
    documentId: ObjectId;
  }) => {
    if (pdf && pdfId) {
      let objectNotFound = false;
      if (pdf.annotations.ids.includes(objectId)) {
        if (
          (objectType === 'comment' || objectType === 'annotation') &&
          (pdf.annotations.byId[objectId].state === 'Completed' ||
            pdf.annotations.byId[objectId].state === 'Cancelled')
        ) {
          objectNotFound = true;
        } else {
          pdfManager?.selectAnnotation(objectId);
        }
      } else {
        objectNotFound = true;
      }

      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
    }
  };

  return (
    <NotificationsCenter
      object={object}
      margin="0 0 0 2rem"
      navigateToObject={handleNavigateToObject}
    />
  );
};

export default PDFNotificationsCenter;
