import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';

export default class UserColors {
  static CURRENT_USER_COLOR = { base: '#2e92e6', tint30: '#6DB3EE', name: 'blue' } as const;

  static COLORS = [
    { base: '#d92124', tint30: '#E46466', name: 'red' },
    { base: '#f95990', tint30: '#FB8BB1', name: 'pink' },
    { base: '#a600a6', tint30: '#C14DC1', name: 'purple' },
    { base: '#4200fc', tint30: '#7B4DFD', name: 'darkBlue' },
    { base: '#007a52', tint30: '#4DA286', name: 'darkGreen' },
    { base: '#00ad00', tint30: '#4DC64D', name: 'green' },
    { base: '#d97620', tint30: '#E49F63', name: 'brown' },
    { base: '#833200', tint30: '#A8704D', name: 'darkBrown' },
  ] as const;

  static INDEX = 0;

  static USERS: Record<
    UserId,
    (typeof UserColors.COLORS)[number] | typeof UserColors.CURRENT_USER_COLOR
  > = {};

  static getUserColor = (id: UserId) => {
    if (!id) {
      return null;
    }
    const userId = `${id}`;
    const currentUserId = ReduxInterface.getCurrentUser();
    if (userId === currentUserId) {
      return UserColors.CURRENT_USER_COLOR;
    }
    if (UserColors.USERS[userId]) {
      return UserColors.USERS[userId];
    }
    const color = UserColors.COLORS[UserColors.INDEX % UserColors.COLORS.length];
    UserColors.INDEX += 1;
    UserColors.USERS[userId] = color;
    return color;
  };
}
