import { Ref, forwardRef, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { uniq } from 'lodash';

import EditorManager from 'Editor/services/EditorManager';
import { stringToRichText } from 'utils';
import { useDispatch, useSelector } from '_common/hooks';
import { setTaskOverlayData } from 'Editor/redux/TasksSlice';
import {
  getDocumentObject,
  selectIsPageLayout,
  selectReadOnlyMode,
  selectUser,
} from 'Editor/redux/EditorStatusSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectCollaborators } from 'App/redux/appSlice';

import {
  AssigneePresentation,
  TaskStatus,
  UserPresentation,
  TaskOptions,
  ViewRichTextEditor,
  WatchToggle,
  ReplyToggle,
  ReplyList,
  MinifiedCard,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';
import { PageLayoutTooltip } from 'Editor/components';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export type ViewTaskCardProps = {
  task: Editor.Task;
  sidebar?: boolean;
  testId: string;
};

const ViewTaskCard = forwardRef(
  ({ task, sidebar, testId }: ViewTaskCardProps, ref: Ref<HTMLDivElement>) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const doc = useSelector(getDocumentObject);
    const user = useSelector(selectUser);
    const isPageLayout = useSelector(selectIsPageLayout);
    const isReadOnlyMode = useSelector(selectReadOnlyMode) || isPageLayout;
    const isListMode = useSelector((state) => state.editor.tasks.isListMode);
    const selected = useSelector((state) => state.editor.status.selection.TASK);
    const collaborators = useSelector((state) => selectCollaborators(state, doc.id));

    const [showReplies, setShowReplies] = useState(false);

    //#region Data parsing
    const parseReply = (originalReply: Editor.TaskReply): Card.Reply => {
      const reply: Card.Reply = {
        id: originalReply.id,
        authorId: originalReply.u,
        creationDate: originalReply.t.c,
        votes: originalReply.v,
        //@ts-expect-error Task Reply type is not accordingly to RichTextEditor
        content: { dir: 'ltr', content: originalReply?.c },
      };

      return reply;
    };

    const parsedTask = useMemo<Card.Task>(() => {
      const watchers = uniq([task.u, task.asg, ...(task?.w ?? [])].filter((w) => w));

      const parsedTask: Card.Task = {
        id: task.id,
        authorId: task.u,
        assignee: task.asg,
        //@ts-expect-error creationDate type shouldn't be Date
        creationDate: task.t.c,
        dueDate: task.t.d,
        status: task.s,
        watchers: watchers,
        content: { content: stringToRichText(task.d), dir: 'ltr' },
        state: task.t?.dlt ? 'Cancelled' : undefined,
        replies: task.r?.map((reply) => parseReply(reply)),
      };
      return parsedTask;
    }, [task]);
    //#endregion

    const headerBackgroundColor = useMemo(() => {
      if (parsedTask.state === 'Cancelled') {
        return 'red';
      } else if (parsedTask.status === 'd') {
        return 'green';
      }
    }, [parsedTask]);

    //#region Task Permissions
    const isCreator = task.u === user.id;
    const isAssignee = task.asg === user.id;
    const isOwner =
      doc.user_permissions.includes('owner') || doc.user_permissions.includes('admin');

    const canComment = doc.user_permissions.includes('comment');

    // Edit a task: Task Creator or user with Owner permission
    const canEdit = !isReadOnlyMode && (isCreator || isOwner);
    // Delete a task: Task Creator or user with Owner permission
    const canDelete = !isReadOnlyMode && (isCreator || isOwner);
    // Change status of a task: Task Creator, Task assignee or user with Owner permission
    const canChangeStatus = !isReadOnlyMode && (isCreator || isAssignee || isOwner);
    // Reply to a task: Task Creator, Task assignee Comment permissions, or user with Owner permission
    const canReply = (isCreator || isAssignee || isOwner || canComment) && !isReadOnlyMode;

    const isReplyAuthor = (reply: Card.Reply) => reply.authorId === user.id;
    //#endregion

    //#region Task methods
    const handleEditTask = () => {
      const node = document.querySelector(`*[task="${task.id}"]`);
      const offsets = EditorDOMUtils.getOffsets(node);
      dispatch(setTaskOverlayData({ selected: task.id, offsets, operation: 'edit' }));
      if (sidebar) {
        dispatch(setSidebarView(null));
      }
    };

    const handleDeleteTask = () => {
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            title: 'DELETE_TASK',
            message: 'DELETING_THIS_TASK_WILL_PERMANENTLY_REMOVE_IT_CONFIRM',
            messageValues: { taskNumber: task.i },
            cancelButtonTextId: 'global.cancel',
            confirmButtonType: 'danger',
            confirmButtonTextId: 'global.delete',
            headerType: 'error',
            actionCode: 'deleteTask',
            actionValue: {
              taskId: task.id,
            },
            cancelButtonShow: true,
          },
        }),
      );
    };

    const handleOpenWatchModal = () => {
      dispatch(openAndUpdateModal({ modal: 'TaskWatchModal', data: { taskId: task.id } }));
    };

    const handleChangeTaskStatus = (newStatus: (typeof parsedTask)['status']) => {
      EditorManager.getInstance().changeStatus(task.id, newStatus);
    };

    const handleWatchTask = () => {
      if (task.u !== user.id && task.asg !== user.id) {
        if (task.w.includes(user.id)) {
          EditorManager.getInstance().removeWatchFromTask(task.id, user.id);
        } else {
          EditorManager.getInstance().watchTask(task.id, user.id);
        }
      }
    };

    const handleCreateReply = (reply: string) => {
      EditorManager.getInstance().replyTask(task.id, stringToRichText(reply));
    };

    const handleDeleteReply = (replyId: string) => {
      EditorManager.getInstance().deleteTaskReply(task.id, replyId);
    };

    const handleEditReply = ({ replyId, newContent }: { replyId: string; newContent: string }) => {
      EditorManager.getInstance().editTaskReply(task.id, replyId, stringToRichText(newContent));
    };

    const handleVoteReply: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId && task.s !== 'd') {
        EditorManager.getInstance().voteTaskReply(task.id, replyId, !currentUserLiked);
      }
    };

    const canEditReply = (replyId: string) => {
      const reply = parsedTask.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return isReplyAuthor(reply) && !isReadOnlyMode;
      }
      return false;
    };

    const canDeleteReply = (replyId: string) => {
      const reply = parsedTask.replies?.find((reply) => reply.id === replyId);
      if (reply) {
        return (isOwner || (isReplyAuthor(reply) && canComment)) && !isReadOnlyMode;
      }

      return false;
    };

    const canVoteReply = () => {
      return !isReadOnlyMode;
    };
    //#region

    //#region Card methods
    const handleTasksSelected = () => {
      if (!selected || !selected.tasks.includes(task.id)) {
        dispatch(setTaskOverlayData({ selected: task.id }));
        EditorManager.getInstance().focusTask(task.id);
      }
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
    };
    //#endregion

    if (sidebar && isListMode && (!selected || (selected && !selected?.tasks.includes(task.id)))) {
      return (
        <MinifiedCard
          status={parsedTask.status}
          state={parsedTask.state}
          content={parsedTask.content?.content}
          order={task.i}
          onClick={handleTasksSelected}
          testId={testId}
        />
      );
    }

    return (
      <Card
        id={`Task#${task.id}`}
        selected={selected && selected.tasks.includes(task.id)}
        onClick={sidebar ? handleTasksSelected : () => {}}
        sidebar={sidebar}
        width={'43rem'}
        testId={testId}
        ref={ref}
      >
        <Card.Header background={headerBackgroundColor}>
          <Card.Header.Left>
            <AssigneePresentation userId={task.asg} />
          </Card.Header.Left>
          <Card.Header.Right>
            <TaskStatus taskState={parsedTask.state} taskStatus={parsedTask.status} />
          </Card.Header.Right>
        </Card.Header>
        <Card.DueDateBanner
          dueDate={parsedTask.dueDate}
          divider={headerBackgroundColor && !parsedTask.dueDate}
        />
        <Card.Body>
          <Card.Body.Header>
            <Card.Header.Left>
              <UserPresentation
                userId={parsedTask.authorId ?? ''}
                title={intl.formatMessage({ id: 'REPORTER' })}
              />
            </Card.Header.Left>
            <Card.Header.Right>
              <PageLayoutTooltip
                type="task"
                content={intl.formatMessage({ id: 'global.moreActions' })}
                testId={`task-card-${task.id}-more-options-button-tooltip`}
              >
                <TaskOptions
                  editTask={{ onClick: handleEditTask, disabled: !canEdit }}
                  deleteTask={{
                    onClick: handleDeleteTask,
                    disabled: !canDelete,
                  }}
                  changeStatus={{
                    onClick: handleChangeTaskStatus,
                    disabled: !canChangeStatus,
                  }}
                  watchOptions={{ onClick: handleOpenWatchModal, disabled: false }}
                  testId={testId}
                />
              </PageLayoutTooltip>
            </Card.Header.Right>
          </Card.Body.Header>
          <ViewRichTextEditor
            initialValue={parsedTask.content?.content && JSON.stringify(parsedTask.content.content)}
            dependencies={[parsedTask.content]}
            testId={`${testId}-rich-text-editor`}
          />
        </Card.Body>
        <Card.Footer>
          <Card.Footer.Left>
            <PageLayoutTooltip type="task" testId={`task-card-${parsedTask.id}-watcher-tooltip`}>
              <WatchToggle
                authorId={parsedTask.authorId}
                assignee={parsedTask.assignee}
                watchers={parsedTask.watchers}
                disabled={false}
                onWatch={handleWatchTask}
                openWatchModal={handleOpenWatchModal}
                testId={testId}
              />
            </PageLayoutTooltip>
            <PageLayoutTooltip
              type="task"
              disabled={!!parsedTask.replies?.length || undefined}
              testId={`task-card-${parsedTask.id}-reply-tooltip`}
            >
              <ReplyToggle
                repliesCount={parsedTask.replies?.length ?? 0}
                isToggled={showReplies}
                onToggleReplies={handleToggleReplies}
                canComment={canReply}
                id={`task-${parsedTask.id}-replyToggle`}
                testId={testId}
              />
            </PageLayoutTooltip>
          </Card.Footer.Left>
        </Card.Footer>
        {showReplies && (
          <ReplyList
            replies={parsedTask.replies ?? []}
            testId={`${testId}-replyList`}
            commentId={task.id}
            collaborators={collaborators}
            canComment={canReply}
            newReplyInteraction={{ environment: 'editor' }}
            optionsToggleTooltip={{
              content: intl.formatMessage({ id: 'CANNOT_MAKE_CHANGES_TO_TASKS_IN_PAGE_LAYOUT' }),
              disabled: !isPageLayout,
              testId: `task-card-${parsedTask.id}-reply-options-tooltip`,
            }}
            likeToggleTooltip={{
              content: intl.formatMessage({ id: 'CANNOT_MAKE_CHANGES_TO_TASKS_IN_PAGE_LAYOUT' }),
              disabled: !isPageLayout,
              testId: `task-card-${parsedTask.id}-reply-like-tooltip`,
            }}
            createReply={handleCreateReply}
            editReply={handleEditReply}
            deleteReply={handleDeleteReply}
            voteReply={handleVoteReply}
            canEditReply={canEditReply}
            canDeleteReply={canDeleteReply}
            canVoteReply={canVoteReply}
          />
        )}
      </Card>
    );
  },
);

export default ViewTaskCard;
