import { Connection } from 'sharedb/lib/client';
import { BaseTypedEmitter } from '../../BaseTypedEmitter';
import { TransportError, UnknownError } from '../Errors';

export default class ShareDB extends BaseTypedEmitter {
  static EVENT = Object.freeze({
    CONNECTED: 'SDB_CONNECT',
    DISCONNECTED: 'SDB_DISCONNECT',
    DESTROYED: 'SDB_DESTROYED',
    ERROR: 'SDB_ERROR',
  });

  constructor(config) {
    super();
    this._config = config;
    this._origOnclose = null;
    this._isDistroying = false;
  }

  onclose(event) {
    if (this._origOnclose) {
      this._origOnclose(event);
    }
    if (this._isDestroying) {
      this.emit(ShareDB.EVENT.DESTROYED);
    } else {
      this.emit(ShareDB.EVENT.DISCONNECTED, new TransportError('disconnected'));
    }
  }

  connect(tenant, documentId, token) {
    this._documentId = documentId;
    this._token = token;

    if (this._socket) {
      this._socket.close();
      this._sharedb = undefined;
    }

    this._socket = new WebSocket(
      `${this._config.protos.sharedb}${this._config.rt}${this._config.locations.sharedb}?token=${token}&domain=${tenant}&document=${documentId}`,
    );

    this._socket.onopen = () => {
      this._sharedb = new Connection(this._socket);
      this._origOnclose = this._socket.onclose;
      this._socket.onclose = this.onclose.bind(this);
      this.emit(ShareDB.EVENT.CONNECTED);
    };

    this._socket.onerror = (_, event) => {
      this.emit(ShareDB.EVENT.ERROR, ShareDB.EVENT.ERROR, new UnknownError(event, event?.status));
    };
  }

  get connected() {
    return !!(this._socket?.readyState === 1);
  }

  disconnect() {
    if (this.connected) {
      this._socket.close();
    }
  }

  destroy() {
    this._isDestroying = true;
    if (this.connected) {
      this._socket.close();
    }
    this._socket = undefined;
    this._sharedb = undefined;
    super.destroy();
  }

  onConnect(callback) {
    this.on(ShareDB.EVENT.CONNECTED, callback);
  }

  onDisconnect(callback) {
    this.on(ShareDB.EVENT.DISCONNECTED, callback);
  }

  onDestroy(callback) {
    this.on(ShareDB.EVENT.DESTROYED, callback);
  }

  onError(callback) {
    this.on(ShareDB.EVENT.ERROR, callback);
  }

  get(...args) {
    return this._sharedb.get(...args);
  }
  
  getPresence(...args) {
    return this._sharedb.getPresence(...args);
  }

  createFetchQuery(...args) {
    return this._sharedb.createFetchQuery(...args);
  }

  createSubscribeQuery(...args) {
    return this._sharedb.createSubscribeQuery(...args);
  }

  fetchSnapshot(...args) {
    return this._sharedb.fetchSnapshot(...args);
  }

  getLastVersionOf(collection, docId, v, callback) {
    return this._sharedb.fetchSnapshot(collection, docId, v, callback);
  }

  fetchSnapshotByTimestamp(...args) {
    return this._sharedb.fetchSnapshotByTimestamp(...args);
  }
}
