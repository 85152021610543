import { CSSProperties, ReactNode } from 'react';

import styles from './DetailsItem.module.scss';

type DetailsItemProps = {
  label: string;
  children?: ReactNode;
  labelContainerStyle?: CSSProperties;
  valueContainerStyle?: CSSProperties;
  isTitle?: boolean;
  extra?: ReactNode;
};

const DetailsItem = ({
  children,
  label,
  labelContainerStyle,
  valueContainerStyle,
  isTitle,
  extra,
}: DetailsItemProps) => (
  <div className={styles.details}>
    <div style={labelContainerStyle} className={styles.labelContainer}>
      <span className={`${styles.label} ${isTitle && styles.isTitle}`}>{label}</span>
    </div>
    <div style={valueContainerStyle} className={styles.valueContainer}>
      {children}
    </div>
    {extra}
  </div>
);

export default DetailsItem;
