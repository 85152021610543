import React, { MouseEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Icon, Toggle, Tooltip } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import classNames from 'classnames';

import { FilterObject } from 'Search/redux/SearchPageSlice';

import styles from './RemovableItem.module.scss';

type RemovableItemProps = {
  prefixIcon: IconTypes['32'];
  savedFilter: FilterObject;
  selected: boolean;
  beingRemoved: boolean;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onDelete: MouseEventHandler<HTMLButtonElement>;
  onStartDelete: (savedFilterId: FilterObject['id']) => void;
  onSelect: (savedFilterId: FilterObject['id']) => void;
  testId: string;
};

const RemovableItem = ({
  prefixIcon,
  savedFilter,
  selected,
  beingRemoved,
  onCancel,
  onDelete,
  onStartDelete,
  onSelect,
  testId,
}: RemovableItemProps) => {
  const intl = useIntl();

  const handleStartDelete: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();

    onStartDelete(savedFilter.id);
  };

  const handleSelect = () => {
    if (!beingRemoved) {
      onSelect(savedFilter.id);
    }
  };

  const renderRemoving = () => {
    return (
      <div className={styles.removingRoot} onClick={handleSelect}>
        <div className={styles.row}>
          <div className={styles.value}>
            <FormattedMessage id="DELETE_FILTER" values={{ filterName: savedFilter.name }} />
          </div>
          <div className={styles.actions}>
            <Button size="small" onClick={onCancel} testId={`${testId}-delete-cancel`}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              size="small"
              variant="primary"
              onClick={onDelete}
              testId={`${testId}-delete-confirm`}
            >
              <FormattedMessage id="global.delete" />
            </Button>
          </div>
        </div>
        <Divider margin="0" />
      </div>
    );
  };

  const renderRow = () => {
    return (
      <div
        className={`${styles.root} ${classNames(selected && styles.selected)}`}
        onClick={handleSelect}
        data-testid={testId}
      >
        <div className={styles.row}>
          <div className={styles.prefix}>
            <Icon size={32} icon={prefixIcon} />
          </div>
          <div className={styles.content}>
            <div className={styles.value} title={savedFilter.name}>
              {savedFilter.name}
            </div>
          </div>
          <div className={styles.suffix}>
            <Tooltip
              content={intl.formatMessage({ id: 'DELETE_SAVED_FILTER' })}
              testId={`${testId}-delete-tooltip`}
            >
              <Toggle
                size="medium"
                variant="link"
                icon="CloseGrey"
                onClick={handleStartDelete}
                testId={`${testId}-delete`}
              />
            </Tooltip>
          </div>
        </div>
        <Divider margin="0" />
      </div>
    );
  };

  if (beingRemoved) {
    return renderRemoving();
  } else {
    return renderRow();
  }
};

export default RemovableItem;
