const useStacked100 = () => {
  const stacked100 = (data: number[][]) => {
    const totals = data[0].map((_, i: number) => {
      return data.reduce((sum: number, series: number[]) => {
        return sum + series[i];
      }, 0);
    });

    var percentageData = data.map((series: number[]) => {
      return series.map((value: number, i: number) => {
        return (value / totals[i]) * 100;
      });
    });

    return percentageData;
  };
  return { stacked100 };
};

export default useStacked100;
