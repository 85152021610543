import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from '_common/hooks';

import { setCurrentAppPage } from 'App/redux/appSlice';

import { TabMenu, MenuItem, IntlErrorBoundary } from '_common/components';
import { ActionBar } from '_common/components';

import UserProfile from './UserProfile/UserProfile';
import UserSecurity from './UserSecurity/UserSecurity';
import Authentication from './Authentication/Authentication';
import UserNotifications from './UserNotifications/UserNotifications';

import styles from './UserSettingsPage.module.scss';

const UserSettingsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentAppPage('/settings/user'));
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IntlErrorBoundary size="small" margin="0 0 0 7rem">
          <ActionBar>
            <ActionBar.Breadcrumb
              path={[
                {
                  id: 'users',
                  name: intl.formatMessage({ id: 'global.userPreferences' }),
                },
              ]}
            />
          </ActionBar>
        </IntlErrorBoundary>
      </div>
      <div className={styles.page}>
        <IntlErrorBoundary size="large" margin="13.5rem 0 0 0">
          <TabMenu menuId="userSettingsPage">
            <MenuItem
              text={intl.formatMessage({ id: 'settings.tabs.general' })}
              id="user-preferences-general"
            >
              <UserProfile />
            </MenuItem>
            <MenuItem
              text={intl.formatMessage({ id: 'NOTIFICATIONS' })}
              id="user-preferences-notifications"
            >
              <UserNotifications />
            </MenuItem>
            <MenuItem
              text={intl.formatMessage({ id: 'settings.tabs.security' })}
              id="user-preferences-security"
            >
              <UserSecurity />
            </MenuItem>
            <MenuItem
              text={intl.formatMessage({ id: 'settings.tabs.authentication' })}
              id="user-preferences-authentication"
            >
              <Authentication />
            </MenuItem>
          </TabMenu>
        </IntlErrorBoundary>
      </div>
    </div>
  );
};

export default UserSettingsPage;
