import { useState, useEffect, useMemo, ChangeEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Tooltip, Checkbox, Button, InputField, Input } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { updateMiniExplorer } from '_common/components/MiniExplorer/miniExplorerSlice';
import { copyObject } from 'App/redux/appSlice';

import { MiniExplorer } from '_common/components';

import styles from './SaveModal.module.scss';

const INVALID_INPUT_STRING = {
  INVALID: 'RENAME_INPUT_ERROR',
  BIG: 'validation.name.longName',
} as const;

const MODAL = 'SaveAsModal';

const SUITE_OBJECT_TYPE: Record<doDOC.SuiteObject['type'], boolean> = {
  document: true,
  dopdf: true,
  presentation: true,
};

const SaveModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // redux
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const explorer = useSelector((state) => state.modals[MODAL].explorer);
  const identity = useSelector((state) => state.modals[MODAL].identity);
  const current = useSelector((state) => state.modals[MODAL].current);
  const objectIds = useSelector((state) => state.modals[MODAL].objectIds);
  const data = useSelector((state) => state.app.data);
  const miniExplorer = useSelector((state) => state.miniExplorer);

  // local
  const [inputValue, setInputValue] = useState('');
  const [newInputValueError, setNewInputValueError] = useState<
    keyof typeof INVALID_INPUT_STRING | null
  >(null);
  const [checkBoxValue, setCheckBoxValue] = useState(true);

  useEffect(() => {
    if (isOpen) {
      if (objectIds.length <= 1) {
        setInputValue(getDocumentName());
      }
    }
  }, [isOpen, objectIds]);

  const id = useMemo(() => {
    return objectIds[0];
  }, [objectIds]);

  const isSuiteObject = (
    objectType: doDOC.BaseObject['type'] | ObjectType,
  ): objectType is doDOC.SuiteObject['type'] => objectType in SUITE_OBJECT_TYPE;

  const getDocumentName = () => {
    // If it is a folder return name as a whole (doesn't have extensions)
    if (data[id].type === 'folder') {
      return `${data[id].name} copy`;
    }

    const array = data[id].name.split('.');
    const nameWithoutExtension = array.slice(0, array.length - 1).join('.') || array[0] || '';

    // If its a suite object remove the extension
    if (isSuiteObject(data[id].type)) {
      return `${nameWithoutExtension} copy`;
    }

    //Otherwise keep the extension
    return `${nameWithoutExtension} copy.${array[array.length - 1]}`;
  };

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
    dispatch(updateMiniExplorer({ view: undefined, current: null, operatedObjIds: [] }));
    setNewInputValueError(null);
    setInputValue('');
    setCheckBoxValue(true);
  };

  const startCreatingFolder = () => {
    dispatch(updateMiniExplorer({ isCreatingFolder: true }));
  };

  const updateField: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target) {
      const value = e.target.value;
      setInputValue(value);
      if (new RegExp(/[<>/:?*"]/).test(value)) {
        setNewInputValueError('INVALID');
      } else if (value.length > 150) {
        setNewInputValueError('BIG');
      } else {
        setNewInputValueError(null);
      }
    }
  };

  const handleSave = () => {
    const params = {
      sources: objectIds,
      keep_reviews: checkBoxValue,
      name: '',
      type: '',
      space: '',
      destination: '',
    };
    if (objectIds.length === 1) {
      params.name = inputValue;
      params.type = data[id].type;
    }
    let destination;
    if (miniExplorer.selected) {
      destination = data[miniExplorer.selected];
    } else if (miniExplorer.current) {
      destination = data[miniExplorer.current.id];
    }

    if (destination) {
      if (destination.type === 'space') {
        params.space = destination.id;
      } else {
        params.destination = destination.id;
      }

      dispatch(
        copyObject({
          params,
          identity,
          current,
          destinationName:
            destination.type === 'space' && destination.personal
              ? intl.formatMessage({ id: 'spaces.personalSpace' })
              : destination.name,
        }),
      );
    }
  };

  const isSaveButtonDisabled = () => {
    if ((objectIds.length <= 1 && inputValue === '') || newInputValueError !== null) {
      return true;
    }
    if (
      miniExplorer.view === 'share' ||
      (miniExplorer.view === 'space' && !miniExplorer.selected)
    ) {
      return true;
    }

    let destination;
    if (miniExplorer.selected) {
      destination = data[miniExplorer.selected];
    } else if (miniExplorer.current) {
      destination = data[miniExplorer.current.id];
    }
    if (!destination) {
      return true;
    }
    if (
      !destination.user_permissions.includes('edit') &&
      !destination.user_permissions.includes('owner') &&
      !destination.user_permissions.includes('admin')
    ) {
      return true;
    }

    return false;
  };

  const getSubmitButtonText = () => {
    if (explorer) {
      if (miniExplorer.selected) return 'global.copy';
      return 'storage.modals.move.copyHere';
    }

    if (miniExplorer.selected) return 'global.save';
    return 'SAVE_HERE';
  };

  if (!data[id]) {
    return null;
  }

  return (
    <Modal width="70rem" open={!!isOpen} onClose={close} testId="copy">
      <Modal.Header onClose={close}>
        <FormattedMessage id={explorer ? 'COPY_TO' : 'SAVE_A_COPY'} />
        ...
      </Modal.Header>
      <Modal.Body>
        <div className={styles.saveAs}>
          <InputField
            disabled={objectIds.length > 1}
            label={`${intl.formatMessage({ id: 'SAVE_AS' })}:`}
            size="large"
            feedback={
              newInputValueError
                ? intl.formatMessage(
                    { id: INVALID_INPUT_STRING[newInputValueError] },
                    {
                      length: 150,
                    },
                  )
                : undefined
            }
            testId="save-as-field"
          >
            <Tooltip
              content={intl.formatMessage({ id: 'MULTIPLE_ELEMENTS_COPY_TOOLTIP' })}
              placement="bottom"
              disabled={objectIds.length <= 1}
              testId="multiple-elements-copy-tooltip"
            >
              <span>
                <Input
                  disabled={objectIds.length > 1}
                  name="docName"
                  value={objectIds.length > 1 ? '' : inputValue}
                  placeholder={intl.formatMessage({ id: 'NAME_YOUR_COPY_PLACEHOLDER' })}
                  onChange={updateField}
                  size="large"
                  error={!!newInputValueError}
                  testId="copy-modal-save-as"
                />
              </span>
            </Tooltip>
          </InputField>
        </div>
        <MiniExplorer />
        <div className={styles.checkBoxContainer}>
          <Checkbox
            size="small"
            checked={checkBoxValue ? 'checked' : 'unchecked'}
            onChange={() => setCheckBoxValue(!checkBoxValue)}
            testId="copy-modal-include-comments-checkbox"
          >
            <FormattedMessage id="COPY_COMMENTS_AND_CHANGES" />
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Tooltip
          disabled={miniExplorer.canCreateFolder}
          content={
            miniExplorer.canCreateFolder
              ? ''
              : intl.formatMessage({ id: 'NO_PERMISSION_TO_CREATE_FOLDER_IN_DIRECTORY' })
          }
          testId="copy-modal-create-folder-tooltip"
        >
          <div style={{ flex: 1 }}>
            <Button
              size="medium"
              onClick={startCreatingFolder}
              disabled={!miniExplorer.canCreateFolder}
              testId="copy-modal-create-folder-button"
            >
              <FormattedMessage id="storage.modals.createFolder.createButton" />
            </Button>
          </div>
        </Tooltip>
        <Button size="medium" onClick={close} testId="copy-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSave}
          disabled={isSaveButtonDisabled()}
          testId="copy-modal-submit-button"
        >
          <FormattedMessage id={getSubmitButtonText()} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveModal;
