import { Timepicker as BaseTimepicker } from 'dodoc-design-system';
import type { TimepickerProps as BaseTimepickerProps } from 'dodoc-design-system/build/types/Components/Timepicker/Timepicker';

import { useGetCurrentUserQuery } from '_common/services/api/authority';

export type TimepickerProps = Omit<BaseTimepickerProps, 'timeFormat'>;

const Timepicker = (props: TimepickerProps) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!currentUser) {
    return null;
  }

  return <BaseTimepicker {...props} timeFormat={currentUser.profile.time_format} />;
};

export default Timepicker;
