import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from 'dodoc-design-system';
import styles from './Footer.module.scss';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

const TableFooter = ({ activeFilters, objects, filters, publicProfiles, isAdvancedSearch }) => {
  const intl = useIntl();
  const statuses = useGetElementStatusListQuery();
  const renderAdvancedFilter = () => {
    if (activeFilters && activeFilters.length) {
      return (
        <div className={styles.filter}>
          <Icon icon="FiltersGrey" size={32} />
          <div style={{ marginRight: '1rem' }}>
            <FormattedMessage
              id="FOOTER_FILTERS_APPLIED"
              values={{ total: activeFilters.length }}
            />{' '}
            <FormattedMessage id="FOOTER_SHOWING_RESULTS" values={{ total: objects.list.length }} />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderFilter = () => {
    const { fields, values } = filters;

    if (fields && fields.length && values && values.length) {
      return (
        <div className={styles.filter}>
          <Icon icon="FiltersBlue" size={32} />
          {fields.map((field, index) => {
            let fieldString;
            let valueString = intl.formatMessage({ id: 'global.notDefined' });

            switch (field) {
              case 'type':
                fieldString = intl.formatMessage({
                  id: 'storage.actionBar.filters.objectType',
                });
                valueString = intl.formatMessage({
                  id: `object.type.${values[index]}`,
                });

                break;
              case 'creator':
                fieldString = intl.formatMessage({
                  id: 'storage.actionBar.filters.createdBy',
                });
                valueString = publicProfiles[values[index]]
                  ? publicProfiles[values[index]].name
                  : values[index];

                break;
              case 'status': {
                fieldString = intl.formatMessage({
                  id: 'storage.actionBar.filters.status',
                });
                const status = statuses.entities[values[index]];
                valueString = status?.name;

                break;
              }
              case 'approved_by':
                fieldString = intl.formatMessage({
                  id: 'storage.actionBar.filters.approvedBy',
                });
                valueString = publicProfiles[values[index]]
                  ? publicProfiles[values[index]].name
                  : values[index];

                break;
              default:
                fieldString = intl.formatMessage({
                  id: 'storage.actionBar.filters.filterNotFound',
                });
            }
            return (
              <div key={field} style={{ marginRight: '1rem' }}>
                {`${fieldString}: `}
                <span style={{ fontWeight: 700, marginLeft: '0.5rem' }}>{`"${valueString}"`}</span>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const renderItemsSelected = () => {
    const length =
      objects.selected &&
      objects.dict[objects.selected] &&
      objects.dict[objects.selected].type === 'group'
        ? 1
        : objects.selected.length;

    if (length > 0) {
      return (
        <div className={styles.selectedItems}>
          <FormattedMessage id="storage.footer.selectedItems" values={{ selected: length }} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.root}>
      {isAdvancedSearch ? renderAdvancedFilter() : renderFilter()}
      {renderItemsSelected()}
    </div>
  );
};

export default TableFooter;
