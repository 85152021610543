import { notify } from '_common/components/ToastSystem';
import { Logger } from '_common/services';
import api from '_common/services/api/api';

type AffiliationsState = {
  fields: Affiliation;
  loading: boolean;
};

const orderData = (data: Affiliation) => {
  const order = [
    'department',
    'function',
    'organization',
    'company',
    'site',
    'address1',
    'address2',
    'state',
    'zip_code',
    'country',
  ];

  const payload: any | Affiliation = {};
  for (const item of order) {
    for (const [key, value] of Object.entries(data)) {
      if (key === item) {
        payload[item] = value;
      }
    }
  }

  return payload;
};

const affiliationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAffiliationsList: builder.query<AffiliationsState['fields'], void>({
      query: () => ({
        url: '/object/affiliations/list',
      }),
      transformResponse: (responseData: AffiliationsState['fields']) => {
        return orderData(responseData);
      },
      providesTags: () => {
        return ['Affiliation'];
      },
    }),
    addAffiliationField: builder.mutation<void, { field: keyof Affiliation; value: string }>({
      query: ({ field, value }) => {
        return {
          url: '/object/affiliations/field/add',
          method: 'POST',
          body: { field, value },
        };
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'Affiliation', field: arg.field, value: arg.value },
      ],
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            type: 'success',
            title: 'AFFILIATION_OPTION_ADDED',
            message: 'NEW_AFILLIATION_OPTION_WAS_ADDED_TO_THE_AFILLIATION',
            messageValues: { affiliation: payload.field },
          });
        } catch (e) {
          Logger.captureException(e);
        }
      },
    }),
    editAffiliationField: builder.mutation<
      void,
      { field: Affiliation; old: string; newValue: string }
    >({
      query: ({ field, old, newValue }) => {
        return {
          url: '/object/affiliations/field/edit',
          method: 'POST',
          body: { field, old, new: newValue },
        };
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'Affiliation', field: arg.field, old: arg.old, new: arg.newValue },
      ],
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            type: 'success',
            title: 'FIELD_OPTION_EDITED',
            message: 'SOMETHING_WAS_SUCCESSFULLY_EDITED',
            messageValues: { label: payload.newValue },
          });
        } catch (e) {
          Logger.captureException(e);
        }
      },
    }),
    deleteAffiliationField: builder.mutation<void, { field: Affiliation; value: string }>({
      query: ({ field, value }) => {
        return {
          url: '/object/affiliations/field/delete',
          method: 'POST',
          body: { field, value },
        };
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'Affiliation', field: arg.field, value: arg.field },
      ],
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            type: 'success',
            title: 'FIELD_OPTION_DELETED',
            message: 'FIELD_OPTION_DELETED_MESSAGE',
            messageValues: {
              option: payload.value,
            },
          });
        } catch (e) {
          Logger.captureException(e);
        }
      },
    }),
  }),
});

// Export queries and mutations
export const {
  useGetAffiliationsListQuery,
  useAddAffiliationFieldMutation,
  useEditAffiliationFieldMutation,
  useDeleteAffiliationFieldMutation,
} = affiliationsApi;

export default affiliationsApi;
