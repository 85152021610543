import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Tooltip, Modal, Button } from 'dodoc-design-system';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { updateMiniExplorer } from '_common/components/MiniExplorer/miniExplorerSlice';
import { MiniExplorer } from '_common/components';
import { moveObject } from 'App/redux/appSlice';

const MODAL = 'MoveModal';

const MoveModal = () => {
  const intl = useIntl();

  // redux
  const dispatch = useDispatch();
  const data = useSelector((state) => state.app.data);
  const miniExplorer = useSelector((state) => state.miniExplorer);

  const sources = useSelector((state) => state.modals[MODAL].sources);
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const current = useSelector((state) => state.modals[MODAL].current);
  const identity = useSelector((state) => state.modals[MODAL].identity);

  if (!isOpen) {
    return null;
  }

  const startCreatingFolder = () => {
    dispatch(updateMiniExplorer({ isCreatingFolder: true }));
  };

  const isMoveButtonDisabled = () => {
    if (
      miniExplorer.view === 'share' ||
      (miniExplorer.view === 'space' && !miniExplorer.selected)
    ) {
      return true;
    }
    if (miniExplorer.current?.id === current.id && !miniExplorer.selected) {
      return true;
    }
    if (miniExplorer.current?.id !== current.id && miniExplorer.selected === current.id) {
      return true;
    }
    let destination;
    if (miniExplorer.selected) {
      destination = data[miniExplorer.selected];
    } else if (miniExplorer.current) {
      destination = data[miniExplorer.current.id];
    }

    if (!destination) {
      return false;
    }
    if (
      !destination.user_permissions.includes('edit') &&
      !destination.user_permissions.includes('owner') &&
      !destination.user_permissions.includes('admin')
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    let destination;
    if (miniExplorer.selected) {
      destination = data[miniExplorer.selected];
    } else if (miniExplorer.current) {
      destination = data[miniExplorer.current.id];
    }

    if (destination) {
      dispatch(moveObject({ sourcesId: sources, destination, identity }));
    }

    close();
  };

  const close = () => {
    dispatch(updateMiniExplorer({ operatedObjIds: [] }));
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="70rem" open={isOpen} onClose={close} testId="move">
      <Modal.Header onClose={close}>
        <FormattedMessage id="storage.actionBar.actions.moveto" />
      </Modal.Header>
      <Modal.Body>
        <MiniExplorer />
      </Modal.Body>
      <Modal.Footer>
        <Tooltip
          disabled={miniExplorer.canCreateFolder}
          content={
            miniExplorer.canCreateFolder
              ? ''
              : intl.formatMessage({ id: 'NO_PERMISSION_TO_CREATE_FOLDER_IN_DIRECTORY' })
          }
          testId="move-create-button-tooltip"
        >
          <Button
            size="medium"
            margin="0 auto 0 0"
            onClick={startCreatingFolder}
            disabled={!miniExplorer.canCreateFolder}
            testId="move-create-button"
          >
            <FormattedMessage id="storage.modals.createFolder.createButton" />
          </Button>
        </Tooltip>
        <Button size="medium" onClick={close} testId="move-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSubmit}
          disabled={isMoveButtonDisabled()}
          testId="move-submit-button"
        >
          <FormattedMessage
            id={miniExplorer.selected ? 'global.move' : 'storage.modals.move.moveHere'}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveModal;
