import type { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { parseProfile } from 'Auth/redux/utils';
import authority from './authority';

type GhostUserKey = 'davidBean';

export const GHOST_USERS: { [x in GhostUserKey]: UserPublicProfile } = {
  davidBean: {
    id: 'user-david-bean',
    email: 'dbean@dodoc.com',
    first_name: 'David',
    last_name: 'Bean',
    username: 'dbean',
    is_superuser: false,
    has_avatar: true,
  },
};

export const publicProfilesApi = authority.injectEndpoints({
  endpoints: (builder) => ({
    getPublicProfile: builder.query<ParsedProfile<UserPublicProfile> | null, UserId>({
      query: (userId) => ({
        url: '/user/profiles',
        config: {
          params: {
            users: [userId],
          },
        },
      }),
      transformResponse: (responseData: UserPublicProfile[], _, arg) => {
        (Object.keys(GHOST_USERS) as Array<keyof typeof GHOST_USERS>).forEach((userId) => {
          if (arg === GHOST_USERS[userId].id) {
            responseData.push(GHOST_USERS[userId]);
          }
        });
        return responseData[0] ? parseProfile(responseData[0]) : null;
      },
      providesTags: (_, __, arg) => [
        {
          type: 'PublicProfile',
          id: arg,
        },
      ],
    }),
    getPublicProfiles: builder.query<ParsedProfile<UserPublicProfile>[], UserId[]>({
      query: (userIds) => ({
        url: '/user/profiles',
        method: 'POST',
        body: { users: userIds },
      }),
      transformResponse: (responseData: UserPublicProfile[], _, arg) => {
        (Object.keys(GHOST_USERS) as Array<keyof typeof GHOST_USERS>).forEach((userId) => {
          if (arg.includes(GHOST_USERS[userId].id)) {
            responseData.push(GHOST_USERS[userId]);
          }
        });

        return responseData.map((profile) => parseProfile(profile));
      },
      providesTags: (_, __, arg) => {
        // Not sure if this TagDescription<T> is the most correct one
        const tags: TagDescription<'PublicProfile'>[] = ['PublicProfile'];

        arg.forEach((id) => {
          tags.push({ type: 'PublicProfile', id });
        });
        return tags;
      },
    }),
  }),
});

// Export queries
export const { useGetPublicProfileQuery, useGetPublicProfilesQuery } = publicProfilesApi;
