import { RealtimeFragment } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export type SlideCommentsType = Presentation.Model.Comments.Data['sld'][string];

export class SlideComments extends RealtimeFragment<SlideCommentsType> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId, slideId: string) {
    super(transport, id, 'presComments', ['sld', slideId]);
  }

  get commentIds() {
    return Object.keys(this.selectedData() || {});
  }

  get comments() {
    return this.selectedData() || {};
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as SlideCommentsType;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
