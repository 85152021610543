import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ApproveViewElement } from '.';
import { BaseDecorator } from '..';

export class ApproveModeDecorator extends BaseDecorator {
  decorator(data: Editor.Data.Node.Data, node: HTMLElement) {
    if (data.id) {
      const approvedUsers = data.approvedBy || [];
      if (this.data.permissions.canUserPerform(data.id, 'approve')) {
        const decoration = DOMElementFactory.buildElement(
          ELEMENTS.ApproveViewElement.TAG,
        ) as ApproveViewElement;
        decoration.setAttribute('enclosed_element', data.id);
        decoration.setContent(node);
        decoration.setApprovalUsers(approvedUsers);
        if (this.data.approvals.isSelectedForApproval(data.id)) {
          decoration.setAttribute('selected', 'true');
        }
        return decoration;
      }
      if (this.data.nodes.isNodeReadonly(data.id)) {
        if (approvedUsers.length > 0) {
          return this.approveDecorator(data, node);
        }
        return this.readonlyDecorator(data, node);
      }
    }

    return node;
  }
}
