import { path } from 'd3';
import useOutline from '../useOutline';
import { useSlideData } from '../../SlideData';

const useChartSVG = () => {
  const { parseOutline } = useOutline();
  const { color, addUnsupportedElement } = useSlideData();

  const background = ({
    id,
    fill,
    color,
  }: {
    id: string;
    fill: Presentation.Data.Common.FillType | undefined;
    color: (c: Presentation.Data.Common.Color) => string;
  }) => {
    switch (fill?.type) {
      case 'gradient': {
        const stops = [...fill.stops].sort((a, b) => a.pos - b.pos);
        const deg = fill.angle ?? 0;

        return `<defs>
            <linearGradient id="${id}" gradientTransform="rotate(${deg})">
            ${stops.map((stop) => {
              return `<stop offset="${stop.pos}%" stop-color="${color(stop.color)}" />`;
            })}
            </linearGradient>
          </defs>`;
      }
      case 'pattern': {
        break;
      }
      default: {
        return '';
      }
    }
  };

  const markerSymbol = (payload: {
    symbol: Presentation.Data.Marker['symbol'];
    properties: Presentation.Data.Marker['properties'] | undefined;
    width: number;
    height: number;
  }) => {
    const { symbol, properties, width, height } = payload;

    if (symbol === 'auto') {
      addUnsupportedElement('Chart - Marker Symbol - Auto');
    }

    if (symbol === 'none' || symbol === 'auto') {
      return '';
    }

    const outline = parseOutline(properties?.ln);

    const x = {
      left: 0,
      center: width / 2,
      right: width,
    };

    const y = {
      top: 0,
      center: height / 2,
      bottom: height,
    };

    switch (symbol) {
      case 'triangle': {
        const bgId = 'bg-fill';
        const bgFill = properties
          ? properties?.fill?.type === 'solid'
            ? color(properties.fill.color)
            : `url(#${bgId})`
          : '';

        const d = path();
        d.moveTo(x.left + x.center, y.top);
        d.lineTo(x.right, y.bottom);
        d.lineTo(x.left, y.bottom);
        d.closePath();

        return `
        <svg
          width="${width}px"
          height="${height}px"
          xmlns="http://www.w3.org/2000/svg"
          display="block"
          overflow="visible"
      >
        ${background({ id: bgId, fill: properties?.fill, color })}
         <path 
          d="${d.toString()}"
          fill="${bgFill}"
          stroke="${outline.stroke}" 
          stroke-width="${outline.strokeWidth}" 
          stroke-dasharray="${outline.strokeDasharray}"
          stroke-linecap="${outline.strokeLinecap}"
          stroke-linejoin="${outline.strokeLinejoin}"
         />
      </svg>`;
      }
      case 'diamond': {
        const d = path();
        d.moveTo(x.center, y.top);
        d.lineTo(x.right, y.center);
        d.lineTo(x.center, y.bottom);
        d.lineTo(x.left, y.center);
        d.closePath();

        const bgId = 'bg-fill';
        const bgFill = properties
          ? properties?.fill?.type === 'solid'
            ? color(properties.fill.color)
            : `url(#${bgId})`
          : '';

        return `
        <svg
          width="${width}px"
          height="${height}px"
          xmlns="http://www.w3.org/2000/svg"
          display="block"
          overflow="visible"
      >
        ${background({ id: bgId, fill: properties?.fill, color })}
         <path 
          d="${d.toString()}"
          fill="${bgFill}"
          stroke="${outline.stroke}" 
          stroke-width="${outline.strokeWidth}" 
          stroke-dasharray="${outline.strokeDasharray}"
          stroke-linecap="${outline.strokeLinecap}"
          stroke-linejoin="${outline.strokeLinejoin}"
         />
      </svg>`;
      }

      case 'circle': {
        const bgId = 'bg-fill';
        const bgFill = properties
          ? properties?.fill?.type === 'solid'
            ? color(properties.fill.color)
            : `url(#${bgId})`
          : '';

        return `    
        <svg 
          viewBox="0 0 1 10"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          width="${width}px"
          height="${height}px"
          display="block"
          >
        <g>
          ${background({ id: bgId, fill: properties?.fill, color })}
          <circle 
            cx="50%" 
            cy="50%" 
            r="50%" 
            fill="${bgFill}"
            stroke="${outline.stroke}" 
            stroke-width="${outline.strokeWidth}" 
            stroke-dasharray="${outline.strokeDasharray}"
            stroke-linecap="${outline.strokeLinecap}"
            stroke-linejoin="${outline.strokeLinejoin}"/>
        </g>
      </svg>`;
      }
      case 'x': {
        const bgId = 'bg-fill';
        const bgFill = properties
          ? properties?.fill?.type === 'solid'
            ? color(properties.fill.color)
            : `url(#${bgId})`
          : '';

        const xFillId = 'stroke-fill';
        const xFill =
          properties?.ln?.fill?.type === 'solid'
            ? color(properties.ln.fill.color)
            : `url(#${xFillId})`;

        return `<svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        width="${width}px"
        height="${height}px"
        display="block"
      >
        <g>
          ${background({ id: bgId, fill: properties?.fill, color })}
          <rect x="0" y="0" width="100%" height="100%" fill="${bgFill}"/>
        </g>
        <g>
          ${background({ id: xFillId, fill: properties?.ln?.fill, color })}
          <line
            stroke="${xFill}"
            stroke-width="${outline.strokeWidth}" 
            stroke-dasharray="${outline.strokeDasharray}"
            stroke-linecap="${outline.strokeLinecap}"
            stroke-linejoin="${outline.strokeLinejoin}"
            x1="0" 
            y1="0" 
            x2="100%" 
            y2="100%"
          />
        <line
          stroke="${xFill}"
          stroke-width="${outline.strokeWidth}" 
          stroke-dasharray="${outline.strokeDasharray}"
          stroke-linecap="${outline.strokeLinecap}"
          stroke-linejoin="${outline.strokeLinejoin}"
          x1="0" 
          y1="100%" 
          x2="100%" 
          y2="0"
        />
        </g>
      </svg>`;
      }

      case 'square':
      default: {
        if (symbol !== 'square') {
          addUnsupportedElement(`Chart - Marker Symbol - ${symbol}`);
        }
        //TODO:CHARTS Render remaning symbols
        const bgId = 'bg-fill';
        const bgFill = properties
          ? properties?.fill?.type === 'solid'
            ? color(properties.fill.color)
            : `url(#${bgId})`
          : '';

        return `
        <svg
          width="${width}px"
          height="${height}px"
          xmlns="http://www.w3.org/2000/svg"
          display="block"
      >
        <g>
        ${background({ id: bgId, fill: properties?.fill, color })}
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="${bgFill}"
            stroke="${outline.stroke}" 
            stroke-width="${outline.strokeWidth ?? 0}" 
            stroke-dasharray="${outline.strokeDasharray}"
            stroke-linecap="${outline.strokeLinecap}"
            stroke-linejoin="${outline.strokeLinejoin}"
          />
        </g>
      </svg>`;
      }
    }
  };

  return { markerSymbol };
};

export default useChartSVG;
