import { Logger } from '_common/services';
import {
  ApprovalsController,
  CaptionsController,
  CitationsController,
  CommentsController,
  CrossReferencesController,
  DocumentController,
  EventsController,
  FindAndReplaceController,
  HistoryController,
  ModelsController,
  NodeController,
  NotesController,
  NumberingController,
  PermissionsController,
  ProofingController,
  SectionsController,
  SelectionController,
  SpellcheckController,
  StructureController,
  StylesController,
  SuggestionController,
  TableOfContentsController,
  TasksController,
  TemplatesController,
  UsersController,
  VersionsController,
  FieldsController,
  HeadersAndFootersController,
} from './controllers';
import { DataManagerAPI } from 'Editor/services/DataManager';

import * as Util from './Util';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function DataManager(transport: Realtime.Transport.Transport): DataManagerAPI {
  const Data: Editor.Data.State = {
    status: 'UNITIALIZED',
    transport,
    context: {
      version: null,
    },
  };

  let documentId: string;
  let user: Realtime.Core.User;

  const events: EventsController = new EventsController();
  const users: UsersController = new UsersController(Data);
  const models: ModelsController = new ModelsController(Data);
  const docController: DocumentController = new DocumentController(Data);
  const structureController: StructureController = new StructureController(Data);
  const citations: CitationsController = new CitationsController(Data);
  const spellcheck: SpellcheckController = new SpellcheckController(Data);
  const findAndReplace: FindAndReplaceController = new FindAndReplaceController(Data);
  const templates: TemplatesController = new TemplatesController(Data);
  const styles: StylesController = new StylesController(Data);
  const captions: CaptionsController = new CaptionsController(Data);
  const nodeController: NodeController = new NodeController(Data);
  const numbering: NumberingController = new NumberingController(Data);
  const sections: SectionsController = new SectionsController(Data);
  const approvals: ApprovalsController = new ApprovalsController(Data);
  const permissions: PermissionsController = new PermissionsController(Data);
  const comments: CommentsController = new CommentsController(Data);
  const tasks: TasksController = new TasksController(Data);
  const suggestions: SuggestionController = new SuggestionController(Data);
  const notes: NotesController = new NotesController(Data);
  const versions: VersionsController = new VersionsController(Data);
  const tableOfContents: TableOfContentsController = new TableOfContentsController(Data);
  const crossReferences: CrossReferencesController = new CrossReferencesController(Data);
  const history: HistoryController = new HistoryController(Data);
  const proofing: ProofingController = new ProofingController(Data);
  const selection: Editor.Data.SelectionController = new SelectionController(Data);
  const fields: FieldsController = new FieldsController(Data);
  const headersAndFooters: HeadersAndFootersController = new HeadersAndFootersController(Data);

  const _transportEvents: any = {
    'EDITOR:INITIAL:LOADING': _handleEventEditorInitialLoading,
    'EDITOR:READONLY:STATE': _handleEventEditorReadonlyState,
    'FORCE:REMOTE:RELOAD': _handleEventForceRemoteReload,
    'UI:UPDATE': _handleEventUIUpdate,
    'EDITOR:INITIAL:LOADING:ERROR': _handleEventEditorInitialLoadingError,
  };

  function _handleEventEditorInitialLoadingError() {
    events.emit('INITIAL_LOADING_ERROR');
  }

  function _handleEventUIUpdate() {
    events.emit('UI_UPDATE');
  }

  function _handleEventEditorReadonlyState(payload: boolean) {
    events.emit('UPDATE_READONLY_STATE', payload);
  }

  function _handleEventForceRemoteReload(event: { users: string[] }) {
    try {
      if (event.users.includes(`${users.loggedUserId}`)) {
        events.emit('FORCE_REMOTE_RELOAD');
      }
    } catch (error) {
      Logger.captureException(error);
    }
  }

  function _handleEventEditorInitialLoading(event: { document: Realtime.Core.Document.Data }) {
    if (Data.status === 'INITIALIZED') {
      Logger.captureException(new Error('DataManager double initial loading event!!'));
      return;
    }

    if (!Data.context) {
      Data.context = {
        version: null,
      };
    }
    Data.context.document = {
      id: documentId,
    };

    //handle document status
    const statusId = event.document.status;

    events.emit('UPDATE_OBJECT_STATUS', documentId, statusId, event.document.statusInfo, false);

    if (statusId !== 'processing' && statusId !== 'broken') {
      users.start(documentId, event.document, user);
      models.start();
      docController.start(documentId, event.document);
      templates.start(documentId);
      structureController.start(documentId);
      citations.start(documentId);
      spellcheck.start();
      numbering.start(documentId);
      captions.start(documentId);
      nodeController.start();
      sections.start(documentId);
      approvals.start();
      permissions.start(documentId);
      comments.start(documentId);
      tasks.start(documentId);
      suggestions.start(documentId);
      notes.start(documentId);
      versions.start(documentId);
      tableOfContents.start(documentId);
      crossReferences.start(documentId);
      history.start(documentId);
      styles.start(documentId);
      proofing.start(documentId);
      selection.start(documentId);
      fields.start(documentId);
      headersAndFooters.start(documentId);

      Data.status = 'INITIALIZED';
      Logger.debug('DATA MANAGER READY');
      events.emit('READY');
    }
  }

  function start(docId: string, u: Realtime.Core.User) {
    if (Data.status === 'INITIALIZED' || Data.status === 'INITIALIZING') {
      Logger.captureException(new Error('DataManager double initialization attempt!!'));
      return;
    }

    documentId = docId;
    user = u;

    Logger.debug('DATA MANAGER START');
    Data.status = 'INITIALIZING';

    const eventKeys = Object.keys(_transportEvents);
    for (let i = 0; i < eventKeys.length; i++) {
      const event: Realtime.Transport.ServerEventName = eventKeys[
        i
      ] as Realtime.Transport.ServerEventName;
      Data.transport.handleEvent(event, _transportEvents[event]);
    }

    events.start();

    // send request to RT
    Data.transport.dispatchEvent(
      'EDITOR:INITIAL:LOADING',
      {
        document: documentId,
      },
      (response: any) => {
        if (!response.success) {
          events.emit('INITIAL_LOADING_ERROR', response.error);
        }
      },
    );
  }

  function destroy() {
    Data.status = 'DESTROYING';
    const eventKeys = Object.keys(_transportEvents) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event = eventKeys[i];
      Data.transport.removeEvent(event, _transportEvents[event]);
    }

    events.emit('DESTROYING');

    // destroy controllers;
    approvals.destroy();
    captions.destroy();
    citations.destroy();
    comments.destroy();
    crossReferences.destroy();
    docController.destroy();
    events.destroy();
    findAndReplace.destroy();
    history.destroy();
    models.destroy();
    nodeController.destroy();
    notes.destroy();
    numbering.destroy();
    permissions.destroy();
    sections.destroy();
    spellcheck.destroy();
    structureController.destroy();
    styles.destroy();
    suggestions.destroy();
    tableOfContents.destroy();
    tasks.destroy();
    templates.destroy();
    users.destroy();
    versions.destroy();
    proofing.destroy();
    selection.destroy();
    fields.destroy();
    headersAndFooters.destroy();
    Data.status = 'DESTROYED';
  }

  function getStatus() {
    return Data.status;
  }

  const API: DataManagerAPI = {
    isReady: () => Data.status === 'INITIALIZED',
    start,
    destroy,
    getStatus,

    on: events.on.bind(events),
    off: events.off.bind(events),

    approvals,
    captions,
    citations,
    comments,
    crossReferences,
    document: docController,
    events,
    findAndReplace,
    history,
    models,
    nodes: nodeController,
    notes,
    numbering,
    permissions,
    sections,
    spellcheck,
    structure: structureController,
    styles,
    suggestions,
    tableOfContents,
    tasks,
    templates,
    users,
    versions,
    proofing,
    selection,
    fields,
    headersAndFooters,
  };

  Util.extend(Data, {
    ...API,
    emit: events.emit.bind(events),
  });

  return API;
}
