import { ChangeEventHandler, useEffect, useRef, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Input, Toggle, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { updateMiniExplorer } from './miniExplorerSlice';
import { createObject } from 'Storage/pages/StoragePage/StoragePageSlice';

import styles from './Header.module.scss';

const INVALID_INPUT_STRING: { [key: string]: string } = {
  INVALID: 'RENAME_INPUT_ERROR',
  BIG: 'validation.name.longName',
};

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.app.data);
  const miniExplorer = useSelector((state) => state.miniExplorer);

  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');

  const newFolderInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (miniExplorer.isCreatingFolder && !miniExplorer.canCreateFolder) {
      dispatch(updateMiniExplorer({ isCreatingFolder: false }));
    } else if (miniExplorer.isCreatingFolder && newFolderInputRef.current) {
      newFolderInputRef.current.focus();
    }
  }, [miniExplorer.canCreateFolder, miniExplorer.isCreatingFolder]);

  // Handlers
  const handleBackIconClick = () => {
    if (miniExplorer.current?.type === 'space') {
      dispatch(updateMiniExplorer({ view: 'space', current: null }));
    } else if (miniExplorer.current?.id) {
      const node = data[miniExplorer.current?.id];
      const parent: Pick<Objekt, 'id' | 'type'> = {
        id: node.space,
        type: 'space',
      };
      if (node.parent) {
        parent.id = node.parent;
        parent.type = 'folder';
      }
      dispatch(updateMiniExplorer({ view: undefined, current: parent }));
    }
  };

  const onFolderInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
    if (new RegExp(/[<>/:?*"]/).test(e.target.value)) {
      setInputError('INVALID');
    } else if (e.target.value.length > 150) {
      setInputError('BIG');
    } else {
      setInputError('');
    }
  };

  const cancelCreateFolder = () => {
    setInputValue('');
    setInputError('');
    dispatch(updateMiniExplorer({ isCreatingFolder: false }));
  };

  const handleNewFolderSubmit = useCallback(() => {
    if (!inputError && inputValue) {
      const params: {
        name: string;
        space: string | undefined;
        parent: string | undefined;
        description: string;
      } = {
        name: '',
        space: '',
        parent: '',
        description: '',
      };
      params.name = inputValue;
      if (miniExplorer.current?.type === 'space') {
        params.space = miniExplorer.current?.id;
      } else {
        params.parent = miniExplorer.current?.id;
      }
      dispatch(
        createObject({
          identity: miniExplorer.identity,
          parameters: params,
          type: 'folder',
          skipSelection: true,
        }),
      ).then((resp) => {
        const data = resp.payload as Objekt;
        dispatch(
          updateMiniExplorer({
            selected: data.id,
            list: [data.id, ...miniExplorer.list],
          }),
        );
      });
    }
    dispatch(updateMiniExplorer({ isCreatingFolder: false }));
    setInputValue('');
    setInputError('');
  }, [inputError, inputValue, miniExplorer, setInputError, setInputValue]);

  const handleOpenNewTabClick = () => {
    if (miniExplorer.view === 'space') {
      window.open(`/storage/spaces`, '_blank');
    } else if (miniExplorer.view === 'share') {
      window.open(`/shared`, '_blank');
    } else {
      window.open(`/storage/${miniExplorer.current?.type}/${miniExplorer.current?.id}`, '_blank');
    }
  };

  let label = '';
  switch (miniExplorer.view) {
    case 'share':
      label = 'Shared';
      break;
    case 'space':
      label = 'Spaces';
      break;
    default: {
      if (miniExplorer.current) {
        const node = data[miniExplorer.current.id];
        if (node) {
          if (node.type === 'space' && node.personal) {
            label = 'My Files';
          } else {
            label = data[miniExplorer.current.id] ? data[miniExplorer.current.id].name : '';
          }
        }
      }
    }
  }
  const backIconIsDisabled = miniExplorer.view === 'space' || miniExplorer.isCreatingFolder;
  return (
    <div className={styles.header}>
      <div
        style={{ marginRight: '2rem', cursor: backIconIsDisabled ? 'not-allowed' : 'pointer' }}
        onClick={backIconIsDisabled ? () => {} : handleBackIconClick}
        data-testid="go-back-icon"
      >
        <Icon disabled={backIconIsDisabled} icon="GoBack" size={16} />
      </div>
      {miniExplorer.isCreatingFolder ? (
        <>
          <Tooltip
            disabled={!inputError}
            content={
              inputError
                ? intl.formatMessage({ id: INVALID_INPUT_STRING[inputError] }, { length: 150 })
                : ''
            }
            placement="bottom"
            testId="new-folder-validation-tooltip"
          >
            <Input
              value={inputValue}
              onChange={onFolderInputChange}
              ref={newFolderInputRef}
              error={!!inputError}
              width="100%"
              size="medium"
              placeholder=""
              testId="new-folder"
            />
          </Tooltip>
          <Button
            size="medium"
            margin="0 0 0 1rem"
            onClick={cancelCreateFolder}
            testId="cancel-button"
          >
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            margin="0 0 0 1rem"
            onClick={handleNewFolderSubmit}
            disabled={!!inputError || inputValue === ''}
            testId="create-new-folder-button"
          >
            <FormattedMessage id="global.create" />
          </Button>
        </>
      ) : (
        <>
          <div className={styles.headerItem}>
            <div>{label}</div>
            <Tooltip
              placement="bottom"
              content={intl.formatMessage({ id: 'OPEN_IN_NEW_TAB' })}
              testId="open-in-new-tab-tooltip"
            >
              <Toggle
                size="medium"
                variant="ghost"
                icon="Open"
                onClick={handleOpenNewTabClick}
                testId="open-in-new-tab-toggle"
              />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
