import { BaseController } from '../BaseController';

type FindReplaceOptions = {
  matchCase: boolean;
  wholeWords: boolean;
  suggesting?: boolean;
};

export class FindAndReplaceController extends BaseController {
  start(): void {}

  stop(): void {}

  destroy(): void {}

  findInPresentation(
    text: string,
    options: FindReplaceOptions,
  ): Promise<Presentation.Data.FindAndReplace.WordOccurence[]> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'FIND:ALL:IN:PRESENTATION',
        {
          document: this.Data.context?.documentId,
          term: text,
          options,
        },
        (response) => {
          if (response.success) {
            resolve(response.payload as Presentation.Data.FindAndReplace.WordOccurence[]);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
