import { navigateToObject } from 'router/history';
import { useSelector } from '_common/hooks';
import { BaseRow, RowCell, NodeIcon } from '_common/components';
import { FormattedMessage } from 'react-intl';
import styles from './PersonalSpaceListRow.module.scss';
import { usePublicProfile } from '_common/hooks';
import { BaseRowProps } from '_common/components/Table/BaseRow/BaseRow';

type PersonalSpaceListRowProps = Pick<
  BaseRowProps,
  'style' | 'objectId' | 'selected' | 'clickHandles'
>;

const PersonalSpaceListRow = ({
  style,
  objectId,
  selected,
  clickHandles,
}: PersonalSpaceListRowProps) => {
  // redux
  const object = useSelector((state) => state.app.data[objectId]);
  const profile = usePublicProfile(object?.owner);

  const handleRowDoubleClick = (_: React.MouseEvent<HTMLDivElement>, objectId: ObjectId) => {
    navigateToObject('space', objectId);
  };
  clickHandles.handleRowDoubleClick = handleRowDoubleClick;
  return (
    <BaseRow style={style} objectId={objectId} selected={selected} clickHandles={clickHandles}>
      <RowCell testId={`personal-spaces-${objectId}-name`}>
        <div className={`${styles.item} ${styles.flex}`}>
          <NodeIcon object={object} />
          <div className={styles.name}>
            <FormattedMessage id="USER_PERSONAL_SPACE_NAME" values={{ user: profile?.name }} />
          </div>
        </div>
      </RowCell>
    </BaseRow>
  );
};

export default PersonalSpaceListRow;
