import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Logger } from '_common/services';

/*Locales*/
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/ja';
dayjs.extend(customParseFormat)
dayjs.extend(utc); // Modifies dayjs object to allow timezone changes
dayjs.extend(timezone); // Extends dayjs to have tz (timezone modifier) function
dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 24, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy', d: 'year' },
  ],
  rounding: Math.floor,
}); // Extends dayjs to have tz (timezone modifier) function
dayjs.extend(localeData); // Extends dayjs to have locale data (translations)
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
export default dayjs;

export const dayjsWithTimezone = (
  datetime: dayjs.ConfigType,
  timezone: Timezone,
  keepLocalTime: boolean = false,
  format?: string,
) => {
  let djs;

  const handleDatetime = () => {
    if(format) {
      return dayjs(datetime, format);
    } else {
      return dayjs(datetime);
    }
  }

  try {
    djs = handleDatetime().tz(timezone, keepLocalTime);
  } catch (error) {
    djs = handleDatetime().tz('Europe/Lisbon', keepLocalTime);
    Logger.captureException(error);
  }
  return djs;
};
