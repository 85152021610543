import { faker } from '@faker-js/faker';

export function createMockedSystemSettings(overwrites?: {
  notifications?: Partial<ApiSchemas['SystemSettingsSchema']['notifications']>;
}): ApiSchemas['SystemSettingsSchema'] {
  return {
    notifications: createMockedNotificationsSettings(overwrites?.notifications),
  };
}

export function createMockedNotificationsSettings(
  overwrites?: Partial<ApiSchemas['SystemSettingsSchema']['notifications']>,
): ApiSchemas['SystemSettingsSchema']['notifications'] {
  return {
    comments: faker.datatype.boolean(),
    container_updates: faker.datatype.boolean(),
    mentions: true, // should always be true
    node_status: faker.datatype.boolean(),
    obj_deadline: faker.datatype.boolean(),
    obj_permissions: faker.datatype.boolean(),
    obj_status: faker.datatype.boolean(),
    pdf_annotations: faker.datatype.boolean(),
    pdf_tasks: faker.datatype.boolean(),
    profile: true, // should always be true
    suggestions: faker.datatype.boolean(),
    tasks: faker.datatype.boolean(),
    presentation_comments: faker.datatype.boolean(),
    presentation_tasks: faker.datatype.boolean(),
    ...overwrites,
  };
}
