import React from 'react';
import { useIntl } from 'react-intl';
import { ProgressBar } from 'dodoc-design-system';

import styles from './Loading.module.scss';

const Loading = () => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <ProgressBar
        label={intl.formatMessage({ id: 'LOADING_SPELL_CHECKER' })}
        testId="spellchecker-loading-progressBar"
      />
    </div>
  );
};

export default Loading;
