import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Icon, Input, ProgressBar } from 'dodoc-design-system';

import { useDebounce, useDispatch } from '_common/hooks';
import { updateSearchValue } from 'Editor/redux/CurrentIntegrationSlice';

import EmptyView from 'Editor/modals/ExportIntegrationModal/Content/EmptyView/EmptyView';
import ItemsList from 'Editor/modals/ExportIntegrationModal/Content/ItemsList/ItemsList';
import styles from 'Editor/modals/ExportIntegrationModal/Content/Content.module.scss';

import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';

type ContentProps<ExportType extends ExportIntegrationModal.ExportType> = {
  exportType: ExportType;
  info: ExportIntegrationModal.IntegrationUtils[ExportType];
};

const Content = <ExportType extends ExportIntegrationModal.ExportType>({
  info,
  ...props
}: ContentProps<ExportType>) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const { data } = info;
  const { path } = data;

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (data.current.type) {
        info.search({
          id: data.path[0].id,
          size: { size: 20, next: data.nextSearch, offset: 0 },
          query: {
            query: searchValue,
            object: data.current,
            container: data.current.id,
          },
        });
      } else if (data.current.subOption) {
        if (data.current.subOption === 'recent') {
          dispatch(updateSearchValue(searchValue));
        } else {
          dispatch(updateSearchValue(searchValue));
        }
      } else {
        info.search({
          id: data.path[0].id,
          size: { size: 20, next: data.nextSearch, offset: 0 },
          query: {
            query: searchValue,
            object: data.current,
          },
        });
      }
    } else if (data.current.type) {
      if (data.current.type === 'folder') {
        const isEmpty = true;
        info.listFolderContent({ object: data.current, params: { size: 20 }, isEmpty });
      } else {
        if (props.exportType === 'netdocuments') {
          (info as ExportIntegrationModal.IntegrationUtils['netdocuments']).listWorkspaceContent(
            data.current,
          );
        }
      }
    } else if (data.current.subOption || (data.current.id && props.exportType === 'box')) {
      if (data.current.subOption === 'recent' || data.current.id === 'recent') {
        info.listRecentsContent(data.current);
        dispatch(updateSearchValue(''));
      } else if (data.current.subOption === 'favourites' || data.current.id === 'favourites') {
        info.listFavouritesContent(data.current);
        dispatch(updateSearchValue(''));
      } else {
        info.getContent(data.current, { size: 20 });
        dispatch(updateSearchValue(''));
      }
    } else {
      info.getList();
    }
  }, [debouncedSearchTerm]);

  const handleArrowIconClick = () => {
    const { path } = data;
    const prevObject = path[path.length - 2];

    const getNetDocumentsPrevInfo = () => {
      if (prevObject.type) {
        if (prevObject.type === 'folder') {
          const isPrevPath = true;
          info.listFolderContent({
            object: prevObject,
            params: { size: 20 },
            isPrevPath,
          });
        } else {
          const isPrevPath = true;
          if (props.exportType === 'netdocuments') {
            (info as ExportIntegrationModal.IntegrationUtils['netdocuments']).listWorkspaceContent(
              prevObject,
              isPrevPath,
            );
          }
        }
      } else if (prevObject.subOption) {
        if (prevObject.subOption === 'recent') {
          info.listRecentsContent(prevObject);
        } else {
          info.listFavouritesContent(prevObject);
        }
      } else {
        info.getContent(prevObject, { size: 20 });
      }
    };

    const getBoxPrevInfo = () => {
      if (prevObject.type && prevObject.type === 'folder') {
        const isPrevPath = true;
        info.listFolderContent({
          object: prevObject,
          params: { size: 20 },
          isPrevPath,
        });
      } else if (prevObject.id === 'recent') {
        info.listRecentsContent(prevObject);
      } else if (prevObject.id === 'favourites') {
        info.listFavouritesContent(prevObject);
      } else if (prevObject.id === 'allFiles') {
        info.getContent(prevObject, { size: 20 });
      }
    };

    switch (props.exportType) {
      case 'netdocuments':
        getNetDocumentsPrevInfo();
        break;
      case 'box':
        getBoxPrevInfo();
        break;
      default:
        break;
    }
  };

  const getPrevPathName = (path: (typeof data)['path']) => {
    if (path.length === 0) {
      return '';
    }
    if (path[path.length - 1].subOption) {
      if (path[path.length - 1].subOption === 'recent') {
        return intl.formatMessage({ id: 'RECENT' });
      }
      return intl.formatMessage({ id: 'FAVOURITES' });
    }
    return path[path.length - 1].name;
  };

  const handleSearchValue: InputProps['onChange'] = (e) => {
    const newValue = (e.target && e.target.value) || '';
    setSearchValue(newValue);
  };

  if (data.loading) {
    return (
      <div className={styles.content}>
        <div className={styles.loading}>
          <ProgressBar
            label={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
            testId="loading-elements-progressBar"
          />
        </div>
      </div>
    );
  }

  const name = getPrevPathName(path);

  return (
    <div className={styles.content}>
      <div className={styles.headerContainer}>
        <div style={{ display: 'flex' }}>
          <div className={`${path.length > 1 && styles.backArrow}`} onClick={handleArrowIconClick}>
            {path.length > 1 && <Icon disabled icon="AngleLeftGrey" size={24} />}
          </div>
          <div className={styles.label}>{name}</div>
        </div>
        <Input
          value={searchValue}
          placeholder={intl.formatMessage({ id: 'NET_DOCUMENTS_SEARCH_PLACEHOLDER' })}
          onChange={handleSearchValue}
          prefix="NavSearchGrey"
          size="medium"
          testId="netDocuments-search"
        />
      </div>
      <div className={styles.listContainer}>
        {data.content.list.length === 0 ? (
          <EmptyView data={data} name={name} />
        ) : (
          <ItemsList info={info} searchValue={searchValue} exportType={props.exportType} />
        )}
      </div>
    </div>
  );
};

export default Content;
