import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { Checkbox } from 'dodoc-design-system';

import { useGetObjectQuery } from 'App/redux/objectApi';

import Header from './Header/Header';
import Table from './Table/Table';

const Permissions = () => {
  const { id: objectId, type: objectType }: { id: ObjectId; type: ObjectType } = useParams();

  const { data } = useGetObjectQuery({ objectId, objectType }, { skip: !objectId || !objectType });

  const [recursive, setRecursive] = useState(false);

  const canEdit =
    data?.user_permissions.includes('admin') ||
    data?.user_permissions.includes('owner') ||
    data?.user_permissions.includes('add_permission') ||
    data?.user_permissions.includes('remove_permission');

  const handleRecursiveChange = () => {
    setRecursive(!recursive);
  };

  return (
    <>
      <Header objectId={objectId} recursive={recursive} />
      {data && canEdit && (data.type === 'space' || data.type === 'folder') && (
        <>
          <Checkbox checked={recursive ? 'checked' : 'unchecked'} onChange={handleRecursiveChange} testId="apply-to-descendants-checkbox">
            <FormattedMessage
              id="storage.modals.share.applyToDescendants"
              values={{ name: data.name }}
            />
          </Checkbox>
        </>
      )}

      <Table
        objectId={objectId}
        objectType={objectType}
        recursive={recursive}
        testId="permissions"
      />
    </>
  );
};

export default Permissions;
