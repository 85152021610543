import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import MathML from '../MathML';

import styles from './Preview.module.scss';

type PreviewProps = {
  value: string;
  isValid?: boolean;
  equation: MyAny /** TODO: Change type to specific when provided by editor */;
};

const Preview = forwardRef<HTMLDivElement, PreviewProps>(({ value, isValid, equation }, ref) => (
  <div className={styles.preview}>
    <div className={styles.label}>
      <FormattedMessage id="PREVIEW" />:
    </div>
    <div className={styles.area}>
      {!value && (
        <div className={styles.empty}>
          <FormattedMessage id="EQUATION_PREVIEW" />
        </div>
      )}
      {(!equation || value !== equation.tex) && <div ref={ref} />}
      {equation && value === equation.tex && isValid && (
        <div>
          <MathML element={equation} testId={'preview-equation'} />
        </div>
      )}
      {value && !isValid && (
        <div className={styles.invalid}>
          <Icon icon="Warning" size={32} />
          <FormattedMessage id="INVALID_EQUATION" />
        </div>
      )}
    </div>
  </div>
));

export default Preview;
