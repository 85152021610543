import { forwardRef, Ref } from 'react';
import { useIntl } from 'react-intl';
import { DatePicker, InputField } from 'dodoc-design-system';

type DueDateFieldProps = {
  id?: string;
  dueDate?: Date;
  onChange: (newValue: Date) => void;
  testId: string;
};

const DueDateField = forwardRef(
  ({ id, dueDate, onChange, testId }: DueDateFieldProps, ref: Ref<HTMLDivElement>) => {
    const intl = useIntl();

    const currentDate = new Date();

    return (
      <InputField
        size="medium"
        label={intl.formatMessage({ id: 'settings.general.dueDate' })}
        feedback={false}
        margin="2rem 0 0 0"
        testId={`${testId}-due-date`}
      >
        <div ref={ref}>
          <DatePicker
            id={id}
            filterDate={[
              {
                filterType: 'after',
                filterDate: new Date(currentDate.setDate(currentDate.getDate() - 1)),
              },
            ]}
            placeholder="dd/mm/yy"
            selectedDate={dueDate ?? null}
            onChange={onChange}
            testId={`${testId}-due-date`}
          />
        </div>
      </InputField>
    );
  },
);

export default DueDateField;
