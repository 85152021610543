import NodeParser from './NodeParser/NodeParser';

export default class DocumentParser {
  constructor(editorContext) {
    this.nodeParser = new NodeParser(editorContext);
    this.subscriptions = [];
  }

  destroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
  }

  parse(representation) {
    const result = this.nodeParser.parseNode(representation);
    return result;
  }
}
