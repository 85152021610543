import cx from 'classnames';
import { Icon, usePopper } from 'dodoc-design-system';
import { selectAnnotation } from 'PDF/redux/PDFAnnotationsSlice';
import AnnotationCard from 'PDF/RightSidePanel/AnnotationsPanel/AnnotationCard/AnnotationCard';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import styles from './Annotation.module.scss';
import { useAnnotationContext } from './AnnotationContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

type AnnotationToggleProps = {
  isOpen: boolean;
  style?: CSSProperties;
};

const AnnotationToggle = ({ isOpen, style }: AnnotationToggleProps) => {
  const { referenceProps, popperProps, forceUpdate } = usePopper({
    placement: 'bottom-start',
  });
  const dispatch = useDispatch();
  const { annotation, onMoveDown } = useAnnotationContext();
  const showCard = !useSelector((state) => state.pdf.general.rightPanel);
  const versionHistory = useSelector((state) => state.pdf.general.versionHistory);
  const rightPanel = useSelector((state) => state.pdf.general.rightPanel);
  const editing = useSelector((state) => state.pdf.annotations.editing);
  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);
  const interactions = useSelector((state) => state.onboarding.interactions);
  const mainAction =
    (active.dopdf || started.dopdf) &&
    (editing || interactions.pdf_comment_reply || interactions.pdf_annotation_save);

  useEffect(() => {
    if (isOpen && !rightPanel && forceUpdate) {
      window.setTimeout(() => {
        forceUpdate();
      }, 0);
    }
  }, [isOpen, rightPanel, forceUpdate]);

  useEffect(() => {
    if (isOpen && forceUpdate) {
      forceUpdate();
    }
  }, [isOpen, style, forceUpdate]);

  const { canEditAnnotation } = usePDFPermissions();

  const canMove = useMemo(
    () =>
      isOpen &&
      canEditAnnotation(annotation) &&
      !versionHistory &&
      annotation &&
      (annotation.subtype === 'Note' || (annotation.subtype === 'Task' && !annotation.quadPoints)),
    [annotation, isOpen, canEditAnnotation, versionHistory],
  );

  return (
    <>
      <div
        className={cx(styles.toggle, { [styles.selected]: isOpen, [styles.canMove]: canMove })}
        style={style}
        ref={referenceProps.ref}
      >
        <div
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(selectAnnotation(isOpen ? null : annotation.id));
          }}
          onMouseDown={(e) => {
            if (canMove) {
              onMoveDown(e);
            }
          }}
        >
          <Icon size={24} icon={annotation.subtype === 'Task' ? 'TaskGrey' : 'PDFCommentGrey'} />
        </div>
      </div>
      {isOpen && showCard && (
        <div
          className={`${styles.card} ${mainAction && styles.cardOverlap}`}
          ref={popperProps.ref}
          style={popperProps.style}
          onClick={(e) => e.stopPropagation()}
        >
          <AnnotationCard
            annotation={annotation}
            sidebar={false}
            testId={`annotation-card-${annotation.id}`}
          />
        </div>
      )}
    </>
  );
};

export default AnnotationToggle;
