import EditorFooter from './Footer';
import { Footer } from '_common/suite/components';

const FooterBoundary = () => (
  <Footer fallbackSize="xsmall">
    <EditorFooter />
  </Footer>
);

export default FooterBoundary;
