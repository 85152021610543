import { FormattedMessage } from 'react-intl';

import { useSelector } from '_common/hooks';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import FormattedTime from '_common/components/FormattedTime/FormattedTime';
import FormattedDate from '_common/components/FormattedDate/FormattedDate';

import styles from './Details.module.scss';

const Details = () => {
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  const getTypeString = () => {
    if (!object) return null;
    const type = object.type;
    switch (type) {
      case 'folder':
        return <FormattedMessage id="object.type.folder" />;
      case 'document':
        return <FormattedMessage id="object.type.doDOCDoc" />;
      case 'file':
        return <FormattedMessage id="object.type.file" />;
      case 'dopdf':
        return 'doPDF';
      case 'presentation':
        return 'doPPT';
      default:
        return 'Object';
    }
  };

  const getOwner = () => {
    if (!object) return null;

    return <UsernameLabel userId={object.owner} />;
  };

  const getCreationDate = () => {
    if (!object || !object.time || !object.time.creation) return null;
    return <FormattedDate date={object.time.creation} />;
  };

  const getModifiedDate = () => {
    if (!object || !object.time || !object.time.modification) return null;
    return <FormattedTime time={object.time.modification} />;
  };

  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <FormattedMessage id="editor.sidebar.review.filter.byType" />
      </div>
      <div className={styles.value} data-testid="information-panel-type-value">
        {getTypeString()}
      </div>
      <div className={styles.label}>
        <FormattedMessage id="global.owner" />
      </div>
      <div className={styles.value} data-testid="information-panel-owner-value">
        {getOwner()}
      </div>
      <div className={styles.label}>
        <FormattedMessage id="global.creationDate" />
      </div>
      <div className={styles.value} data-testid="information-panel-creation-date-value">
        {getCreationDate()}
      </div>
      <div className={styles.label}>
        <FormattedMessage id="storage.browserSidebar.modified" />
      </div>
      <div className={styles.value} data-testid="information-panel-modified-value">
        {getModifiedDate()}
      </div>
    </div>
  );
};

export default Details;
