import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider, Select } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';

import styles from './SelectableBlock.module.scss';

type Option = { value: string; label: string };
type Identity =
  | 'caption_label'
  | 'caption_numbering'
  | 'chapter_begins_with'
  | 'separator'
  | 'caption_position';

export type SelectableBlockProps = {
  value: Option;
  onChange: (newValue: Option) => void;
  label: string;
  options: Option[];
  isLabelSelect?: boolean;
  editMode?: boolean;
  disabled?: boolean;
  creatable?: boolean;
  handleSelectSetters?: (newValue: Option, identity: Identity) => void;
};

const SelectableBlock = ({
  value,
  onChange,
  label,
  options,
  isLabelSelect = false,
  editMode = false,
  disabled = false,
  creatable = false,
  handleSelectSetters = () => {},
}: SelectableBlockProps) => {
  const intl = useIntl();

  const [selectOptions, setSelectOptions] = useState<typeof options>([]);

  const handleOnDeleteOption = (option: (typeof options)[number], options: Option[]) => {
    const newOptions = options.filter((element) => element.value !== option.value);
    // Prevents empty values
    if (value.value === option.value) {
      handleSelectSetters(options[0], 'caption_label');
    }

    setSelectOptions(parseOptions(newOptions));
    EditorManager.getInstance().deleteCaptionLabel(option.value);
  };

  const parseOptions = (options: Option[]) => {
    return options.map((option) => {
      if (
        isLabelSelect &&
        !editMode &&
        !(option.label as string).match(/^(Table|Equation|Figure)$/)
      ) {
        return {
          ...option,
          suffixIcon: 'CloseGrey',
          suffixIconTooltip: {
            content: intl.formatMessage({
              id: 'REMOVE_LABEL',
            }),
            placement: 'bottom',
          },
          suffixIconClick: (e: MouseEvent<HTMLInputElement>) => {
            handleOnDeleteOption(option, options);
            e.stopPropagation();
          },
        } as Option;
      }
      return option;
    });
  };

  const handleOnCreateOption = useCallback(
    (newOption: string) => {
      const option = { value: newOption.toLowerCase(), label: newOption };
      const options = [...selectOptions, option];
      setSelectOptions(parseOptions(options));
      handleSelectSetters({ value: newOption.toLowerCase(), label: newOption }, 'caption_label');
      EditorManager.getInstance().createCaptionLabel(newOption);
    },
    [selectOptions],
  );

  useEffect(() => {
    setSelectOptions(parseOptions(options));
  }, [options]);

  return (
    <div className={`${styles.rootBlock} ${editMode && styles.editionMode}`}>
      <div className={styles.title}>{label}</div>
      <Divider margin="0 0 2rem 0" />
      <Select
        size="large"
        menuPosition="fixed"
        value={value}
        onChange={onChange}
        options={selectOptions}
        creatable={creatable && !editMode}
        onCreateOption={handleOnCreateOption}
        disabled={disabled}
        width="60rem"
        testId={label.replace(' ', '-').toLowerCase()}
      />
    </div>
  );
};

export default SelectableBlock;
