import { useEffect, useRef } from 'react';

/** Source: Mix of
 * https://github.com/streamich/react-use/blob/master/src/useUpdateEffect.ts
 * https://github.com/streamich/react-use/blob/master/src/useFirstMountState.ts
 */
const useEffectOnUpdate: typeof useEffect = (effect, deps) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      // Seen as first render, ignore effect
      isFirst.current = false;
      return;
    }

    //Run effect otherwise
    return effect();
  }, deps);
};

export default useEffectOnUpdate;
