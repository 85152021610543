import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';

import { closeAndResetModal, openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './Inconsistencies.module.scss';

type InconsistenciesProps = {
  metadata: Record<string, string>;
  objectId: ObjectId;
};

const Inconsistencies = ({ metadata, objectId }: InconsistenciesProps) => {
  const dispatch = useDispatch();

  const goToMetadata = () => {
    dispatch(closeAndResetModal('MetadataInconsistenciesModal'));
    dispatch(
      openAndUpdateModal({
        modal: 'EditMetadata',
        data: {
          id: objectId,
          export: true,
        },
      }),
    );
  };
  if (!metadata) {
    return null;
  }
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <FormattedMessage id="Non-compliant metadata" />
        <Button variant="primary" size="small" onClick={goToMetadata} testId="edit-metadata-button">
          <FormattedMessage id="Edit metadata" />
        </Button>
      </div>
      <div className={styles.table}>
        {Object.keys(metadata).map((key) => {
          const value = metadata[key];
          return (
            <div key={key} className={styles.row}>
              <span className={styles.metadata}>{key}</span>
              <span className={styles.value}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Inconsistencies;
