import { forwardRef, Ref } from 'react';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import ViewAnnotationCardContent, { ViewAnnotationCardProps } from './ViewAnnotationCard';

const ViewAnnotationCard = forwardRef(
  (props: ViewAnnotationCardProps, ref: Ref<HTMLDivElement>) => {
    return (
      <IntlErrorBoundary
        fallbackType="card"
        mockProps={{ sidebar: !!props.sidebar, width: props.sidebar ? '100%' : undefined }}
      >
        <ViewAnnotationCardContent {...props} ref={ref} />
      </IntlErrorBoundary>
    );
  },
);

export default ViewAnnotationCard;
