import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { useIntl } from 'react-intl';
import { useGetPublicProfileQuery } from '_common/services/api/publicProfilesApi';
import removedUserAvatar from 'assets/images/REMOVED_USER.svg';
import importedUserSvg from 'assets/images/IMPORTED_USER.svg';
import neutralUserSvg from 'assets/images/NEUTRAL_USER.svg';
import { useSelector } from './redux';

const usePublicProfile = (id: UserId, fallbackName: string = '', skip?: boolean) => {
  const isVeevaUser = +id === -100;
  const isImportedUser = id === 'IMPORTED_USER';
  const intl = useIntl();
  const authenticated = useSelector((state) => state.auth.authenticated);
  const accounts = useSelector(selectAccounts);
  const { data, isSuccess } = useGetPublicProfileQuery(id, {
    skip: !authenticated || isVeevaUser || !id || isImportedUser || skip,
  });
  const profile: Profile = {
    id,
    name: '',
    email: '',
    initials: '',
    username: '',
    has_avatar: data?.has_avatar,
  };

  if (id === '') {
    profile.name = intl.formatMessage({ id: 'UNKNOWN_AUTHOR' });
    profile.username = intl.formatMessage({ id: 'UNKNOWN_AUTHOR' });
    profile.type = 'unknown';
  } else if (isImportedUser) {
    profile.avatar = importedUserSvg;
  } else if (isVeevaUser) {
    profile.name = intl.formatMessage({ id: 'Veeva import process' });
    profile.username = intl.formatMessage({ id: 'Veeva import process' });
    profile.avatar = neutralUserSvg;
    profile.type = 'veeva';
  } else if (!data && isSuccess) {
    profile.name = intl.formatMessage({ id: 'REMOVED_USER' });
    profile.username = intl.formatMessage({ id: 'REMOVED_USER' });
    profile.avatar = removedUserAvatar;
    profile.type = 'removed';
  } else if (data) {
    profile.name = data.name;
    profile.email = data.email;
    profile.initials = data.initials;
    profile.username = data.username;
  } else if (accounts[id]) {
    profile.name = accounts[id].name;
    profile.email = accounts[id].email;
    profile.initials = accounts[id].initials;
    profile.username = accounts[id].username;
  } else {
    profile.name = fallbackName;
  }

  return profile;
};

export default usePublicProfile;
