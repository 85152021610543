import { useState, useRef, useEffect } from 'react';
import { InputField, PasswordInput, Button } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { signIn } from 'Auth/redux/authSlice';
import SavedAccount from '../SavedAccount/SavedAccount';
import styles from './SelectedAccountLogin.module.scss';

type SelectedAccountLoginProps = {
  account: ParsedProfile<Account>;
  chooseAnother: () => void;
  setRemovingAccounts: () => void;
};

const SelectedAccountLogin = ({
  account,
  chooseAnother,
  setRemovingAccounts,
}: SelectedAccountLoginProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const loading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    passwordRef.current?.focus();
  }, []);

  const handleSignin = () => {
    dispatch(signIn({ params: { username: account.username, password } }));
  };

  return (
    <div>
      <div data-testid={'selected-saved-account'}>
        <SavedAccount account={account} removing={false} />
      </div>
      <div className={styles.password}>
        {/** This form wrap avoids browser verbose displaying password value */}
        <form>
          <InputField
            size="large"
            label={intl.formatMessage({ id: 'PASSWORD' })}
            link={`${intl.formatMessage({ id: 'FORGOT_PASSWORD' })}`}
            linkHref="/auth/recover"
            testId="password-field"
          >
            <PasswordInput
              ref={passwordRef}
              size="large"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD' })}
              onEnterKey={handleSignin}
              testId="selected-saved-account-password"
            />
          </InputField>
        </form>
      </div>
      <Button
        variant="primary"
        fullWidth
        onClick={handleSignin}
        size="large"
        loading={loading}
        testId="selected-saved-account-signin-button"
      >
        <FormattedMessage id="SIGN_IN" />
      </Button>
      <Button
        fullWidth
        onClick={chooseAnother}
        size="large"
        margin="1rem 0 0 0"
        testId="sign-in-with-another-account-button"
      >
        <FormattedMessage id="SIGN_IN_WITH_ANOTHER_ACCOUNT" />
      </Button>
      <div className={styles['remove-account']}>
        <Button
          variant="link"
          size="small"
          onClick={setRemovingAccounts}
          testId="selected-saved-account-remove-account-button"
        >
          <FormattedMessage id="REMOVE_ACCOUNT" />
        </Button>
      </div>
    </div>
  );
};

export default SelectedAccountLogin;
