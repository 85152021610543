import { Footer } from '_common/suite/components';
import PresentationFooter from './Footer';
import { InteractionController } from '_common/components';

const FooterBoundary = () => {
  return (
    <InteractionController environment="presentation" style={{ gridArea: 'footer' }}>
      <Footer id="presentation-footer">
        <PresentationFooter />
      </Footer>
    </InteractionController>
  );
};

export default FooterBoundary;
