import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Select } from 'dodoc-design-system';

import {
  getDocumentObject,
  selectReadOnlyMode,
  documentAddKeyword,
  documentRemoveKeyword,
} from 'Editor/redux/EditorStatusSlice';

import Tags from '_common/components/TagSystem/Tags/Tags';

import styles from './KeywordSystem.module.scss';

const KeywordSystem = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { keywords, ...docObject } = useSelector(getDocumentObject);
  const disabled = useSelector(selectReadOnlyMode);

  const handleRemoveKeyword = (value: string) => {
    if (value) {
      dispatch(
        documentRemoveKeyword({ documentId: docObject.id, keywordIndex: keywords.indexOf(value) }),
      );
    }
  };

  const checkPermissions = () =>
    docObject.user_permissions.includes('owner') ||
    docObject.user_permissions.includes('edit') ||
    docObject.user_permissions.includes('admin');

  return (
    <div className={styles.root}>
      <div className={styles.inputContainer}>
        <Select
          width="100%"
          creatable={checkPermissions()}
          menuPosition="fixed"
          disabled={disabled || !checkPermissions()}
          menuPlacement={
            docObject.events && (docObject.events.due || docObject.events.warnings.length > 0)
              ? 'top'
              : 'bottom'
          }
          value={null} //Force select UI cleanup by sending null
          onChange={(values) => {
            if (values?.value) {
              dispatch(
                documentAddKeyword({ documentId: docObject.id, keywordValue: values.value }),
              );
            }
          }}
          placeholder={intl.formatMessage({
            id: 'SELECT_KEYWORDS',
          })}
          formatCreateLabel={(label: string) =>
            `${intl.formatMessage({
              id: 'KEYWORD_SYSTEM_CREATE_KEYWORD',
            })} "${label}"`
          }
          noOptionsMessage={({ inputValue }: { inputValue: string }) =>
            inputValue === '' ? null : intl.formatMessage({ id: 'global.noResults' })
          }
          testId="keywords"
        />
      </div>

      {keywords && keywords.length >= 0 && (
        <Tags
          tags={keywords}
          removeTag={checkPermissions() && handleRemoveKeyword}
          canEdit
          testId="keyword"
        />
      )}
    </div>
  );
};

export default KeywordSystem;
