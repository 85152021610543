import { Helper } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

const NoRolesMessage = ({ type }: { type: string }) => (
  <Helper testId="share-modal-no-roles-helper">
    <div>
      <div>
        <FormattedMessage id="storage.modals.share.noRoles1" />
      </div>
      <div>
        <FormattedMessage id="storage.modals.share.noRoles2" values={{ type }} />
      </div>
    </div>
  </Helper>
);

export default NoRolesMessage;
