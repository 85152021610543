import { memo } from 'react';
import { PageViewport } from 'pdfjs-dist';
import AnnotationOverlay from './Annotation';
import { useSelector } from '_common/hooks';
import TemporaryTask from './Annotation/TemporaryTask';
import AnnotationContextProvider from './Annotation/AnnotationContext';
import AnnotationErrorBoundary from './Annotation/AnnotationErrorBoundary';

type AnnotationsLayerProps = {
  pageNum: number;
  viewport: PageViewport;
  annotations: PDF.Annotation[];
};

const AnnotationsLayer = ({ pageNum, viewport, annotations }: AnnotationsLayerProps) => {
  const selectedAnnotation = useSelector((state) => state.pdf.annotations.selected);

  return (
    <>
      {annotations.map((annotation) => (
        <AnnotationContextProvider
          key={annotation.id}
          annotation={annotation}
          viewport={viewport}
          pageNum={pageNum}
          isSelected={selectedAnnotation === annotation.id}
        >
          <AnnotationErrorBoundary>
            <AnnotationOverlay />
          </AnnotationErrorBoundary>
        </AnnotationContextProvider>
      ))}
      <TemporaryTask pageNum={pageNum} />
    </>
  );
};

export default memo(AnnotationsLayer);
