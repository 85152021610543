import { ELEMENTS, INDENT_TYPE } from 'Editor/services/consts';
import DocumentStyle from 'Editor/services/DataManager/controllers/Styles/DocumentStyle';
import ParseMapper from 'Editor/services/Parsers/ParseMapper';
import { CSSRuleFormatter } from '../../utils';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { BaseViewModel } from '../BaseViewModel';

export class ParagraphStyleViewModel extends BaseViewModel {
  typeName = 'ParagraphStyleViewModel';

  protected model?: DocumentStyle;
  view?: HTMLStyleElement;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);

    this.model = this.Data.styles.documentStyles.style(id);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    if (this.model) {
      this.model.on('LOADED', this.handleModelLoad);
      this.model.on('UPDATED', this.handleModelUpdate);
    }
    this.buildView();
  }

  private append(newView: HTMLStyleElement) {
    if (this.view && this.view.parentNode) {
      this.view.parentNode.replaceChild(newView, this.view);
    }
    this.view = newView;
  }

  getSelectorForStyle(appendix = '') {
    // WARN: this selector should be mantained like this to respect css styles hierarchy
    const styleIdRule = `[element_type="${ELEMENTS.ParagraphElement.ELEMENT_TYPE}"][st="${this.model?.id}"]${appendix},
    [element_type="${ELEMENTS.ParagraphElement.ELEMENT_TYPE}"][data-style-id="${this.model?.id}"]${appendix},
    [element_type="${ELEMENTS.ApprovedElement.ELEMENT_TYPE}"][data-style-id="${this.model?.id}"]${appendix}`;
    return styleIdRule;
  }

  getPreviewSelectorForStyle(appendix = '') {
    return `.dodoc-preview[data-style-id="${this.model?.id}"]${appendix}`;
  }

  private getCssRule() {
    const elementStyles = this.model?.extendedP || {};
    let indLeft =
      elementStyles.ind != null && elementStyles.ind.l != null ? +elementStyles.ind.l : null;

    if (indLeft != null) {
      if (indLeft > 0) {
        indLeft = Math.min(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MAX, indLeft);
      } else {
        indLeft = Math.max(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MIN, indLeft);
      }
    }

    let indRight =
      elementStyles.ind != null && elementStyles.ind.r != null ? +elementStyles.ind.r : null;

    if (indRight != null) {
      if (indRight > 0) {
        indRight = Math.min(StylesUtils.INDENTATION_LIMITS.RIGHT.RENDER_MAX, indRight);
      } else {
        indRight = Math.max(StylesUtils.INDENTATION_LIMITS.RIGHT.RENDER_MIN, indRight);
      }
    }

    let textInd;
    let paddingLeft;
    let hanging;

    if (elementStyles.sp_ind) {
      if (elementStyles.sp_ind.v != null) {
        let specialIndentValue = +elementStyles.sp_ind.v;

        if (specialIndentValue > 0) {
          specialIndentValue = Math.min(
            StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.RENDER_MAX,
            specialIndentValue,
          );
        } else {
          specialIndentValue = Math.max(
            StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.RENDER_MIN,
            specialIndentValue,
          );
        }

        switch (elementStyles.sp_ind.t) {
          case INDENT_TYPE.HANGING:
            textInd = -specialIndentValue;
            paddingLeft = specialIndentValue;
            hanging = specialIndentValue;
            break;
          case INDENT_TYPE.FIRST_LINE:
            textInd = specialIndentValue;
            paddingLeft = 0;
            break;
          default:
            break;
        }
      }
    }

    let backgroundColor;
    if (elementStyles.bg != null) {
      if (typeof elementStyles.bg === 'boolean' && elementStyles.bg === false) {
        backgroundColor = 'rgba(0,0,0,0)';
      } else if (
        typeof elementStyles.color === 'string' &&
        !elementStyles.bg.includes('rgb') &&
        !elementStyles.bg.includes('#')
      ) {
        backgroundColor = `#${elementStyles.bg}`;
      } else {
        backgroundColor = elementStyles.bg;
      }
    }

    let color;
    if (elementStyles.color != null) {
      if (typeof elementStyles.color === 'boolean' && elementStyles.color === false) {
        color = 'var(--suggestionColor, rgba(0,0,0,0))';
      } else if (
        typeof elementStyles.color === 'string' &&
        !elementStyles.color.includes('rgb') &&
        !elementStyles.color.includes('#')
      ) {
        color = `var(--suggestionColor, #${elementStyles.color})`;
      } else {
        color = `var(--suggestionColor, ${elementStyles.color})`;
      }
    }

    let marginTop;
    // @ts-expect-error
    if (elementStyles.asb === true || elementStyles.asb === 'true') {
      marginTop = '14pt';
    } else if (elementStyles.sb != null) {
      marginTop = `${elementStyles.sb}pt`;
    }

    let marginBottom;
    // @ts-expect-error
    if (elementStyles.asa === true || elementStyles.asa === 'true') {
      marginBottom = '14pt';
    } else if (elementStyles.sa != null) {
      marginBottom = `${elementStyles.sa}pt`;
    }

    let cssRule = '';

    if (this.model?.hasList && this.Visualizer.numberingCSSApplier) {
      cssRule += this.Visualizer.numberingCSSApplier.getCssRule(
        {
          listId: this.model.p?.lst?.lId as string,
          level: this.model.p?.lst?.lLv as number,
          number: 1,
          allLevels: [],
        },
        this.getPreviewSelectorForStyle(),
      );
    }

    const lineHeight = elementStyles.lh;
    const fontFamily = elementStyles.fontfamily;
    const fontSize = elementStyles.fontsize;

    const cssStyleRule = new CSSRuleFormatter();
    cssStyleRule
      .setSelector(this.getSelectorForStyle())
      .setSelector(this.getPreviewSelectorForStyle())
      .setAttribute('font-family', fontFamily)
      .setAttribute('font-size', fontSize, 'pt')
      .setAttribute('color', color)
      .setAttribute('background-color', backgroundColor);
    if (elementStyles.bold != null) {
      cssStyleRule.setAttribute('font-weight', elementStyles.bold ? 'bold' : 'normal');
    }
    if (elementStyles.italic != null) {
      cssStyleRule.setAttribute('font-style', elementStyles.italic ? 'italic' : 'normal');
    }
    if (elementStyles.v === true) {
      cssStyleRule
        .setAttribute('text-decoration-style', 'dashed')
        .setAttribute('text-decoration-color', '#1d94f3')
        .setAttribute('text-decoration-line', 'underline');
    } else if (elementStyles.underline != null) {
      cssStyleRule.setAttribute('text-decoration', elementStyles.underline ? 'underline' : 'none');
    }
    if (elementStyles.a != null) {
      cssStyleRule.setAttribute('text-align', ParseMapper.alignmentMapper.render(elementStyles.a));
    }

    cssStyleRule
      .setAttribute('margin-top', marginTop)
      .setAttribute('margin-bottom', marginBottom)
      .setAttribute('text-indent', textInd, 'pt');

    if (lineHeight != null && fontFamily && fontSize) {
      const metrics = this.Visualizer.fontFamilyHelper?.getTextMetrics(fontFamily, `${fontSize}pt`);
      let calculateLineHeight = lineHeight;
      if (metrics?.fontBoundingBoxAscent != null && metrics?.fontBoundingBoxDescent != null) {
        calculateLineHeight =
          (metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent) * lineHeight;
        cssStyleRule.setAttribute('--lineHeight', calculateLineHeight, 'px');
      } else {
        cssStyleRule.setAttribute('--lineHeight', calculateLineHeight);
      }
    } else {
      cssStyleRule.setAttribute('--lineHeight', elementStyles.lh);
    }

    cssStyleRule
      .setAttribute('--leftPadding', paddingLeft, 'pt')
      .setAttribute('--leftMargin', indLeft, 'pt')
      .setAttribute('--rightMargin', indRight, 'pt');

    if (textInd != null || hanging != null) {
      cssStyleRule.setAttribute('--afterContent', 'none');
    }
    if (hanging != null) {
      cssStyleRule
        .setAttribute('--hanging', hanging, 'pt')
        .setAttribute('--beforeDisplay', 'inline-block')
        .setAttribute('--beforeWidth', hanging, 'pt')
        .setAttribute('--beforeTextIndent', '0pt');
    }
    return CSSRuleFormatter.concatRules(cssRule, cssStyleRule.getRule());
  }

  private buildView() {
    let newView: HTMLStyleElement;
    newView = document.createElement('style');
    newView.id = `${this.id}`;
    newView.dataset.name = this.model?.n || `${this.id}`;
    if (this.model) {
      newView.dataset.style_id = this.id;
      newView.appendChild(document.createTextNode(this.getCssRule()));
    }
    this.append(newView);
    this.Visualizer.hooks.afterParagraphStyleUpdate?.trigger(this.id);
  }

  private handleModelUpdate() {
    if (this.model?.p?.fontfamily) {
      this.Visualizer.fontFamilyHelper?.validateFontFamily(this.model?.p?.fontfamily);
    }
    this.buildView();
  }

  private handleModelLoad() {
    if (this.model?.p?.fontfamily) {
      this.Visualizer.fontFamilyHelper?.validateFontFamily(this.model?.p?.fontfamily);
    }
    this.buildView();
  }

  render() {
    this.buildView();
  }

  dispose() {
    if (this.model) {
      this.model.off('UPDATED', this.handleModelUpdate);
      this.model.off('LOADED', this.handleModelLoad);
    }
    if (this.view && this.view.parentNode) {
      this.view.parentNode.removeChild(this.view);
    }
    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
