import { CSSProperties, ReactNode } from 'react';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import styles from './MenuItem.module.scss';

export type MenuItemRenderProps = {
  text: string;
  onClick?: () => void;
  hidden?: boolean;
  id: string;
};

type MenuItemProps = {
  noOverflow?: boolean;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

const MenuItem = ({
  noOverflow,
  className,
  style,
  children,
  hidden,
  id,
}: MenuItemRenderProps & MenuItemProps) =>
  hidden ? null : (
    <div className={`${styles.root} ${noOverflow && styles.noOverflow} ${className}`} style={style}>
      <IntlErrorBoundary key={id} size="large" margin="5rem 0">
        {children}
      </IntlErrorBoundary>
    </div>
  );

export default MenuItem;
