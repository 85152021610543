import { useSuiteObject } from "_common/suite";
import { NotificationsCenter } from '_common/components';
import { useSelector } from "_common/hooks";
import { notify } from "_common/components/ToastSystem";
import EditorManager from "Editor/services/EditorManager";
import { getDocumentObject } from "Editor/redux/EditorStatusSlice";

const EditorNotificationsCenter = () => {
  const object = useSuiteObject();
  const isEditor = useSelector((state) => state.editor.status.visible);
  const comments = useSelector((state) => state.editor.comments.order);
  const trackedActions = useSelector((state) => state.editor.trackedActions.order);
  const tasks = useSelector((state) => state.editor.tasks.order);
  const document = useSelector(getDocumentObject);

  const handleNavigateToObject = ({
    objectType,
    objectId,
    documentId,
  }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
    objectId: ObjectId;
    documentId: ObjectId;
  }) => {
    if (isEditor && documentId === document?.id) {
      let objectNotFound = false;
      if (objectType === 'comment') {
        if (comments.includes(objectId)) {
          EditorManager.getInstance().focusComment(objectId);
        } else {
          objectNotFound = true;
        }
      } else if (objectType === 'suggestion') {
        if (trackedActions.includes(objectId)) {
          EditorManager.getInstance().focusTrackedAction(objectId);
        } else {
          objectNotFound = true;
        }
      } else if (objectType === 'task') {
        if (tasks.includes(objectId)) {
          EditorManager.getInstance().focusTask(objectId);
        } else {
          objectNotFound = true;
        }
      } else if (objectType === 'node') {
        EditorManager.getInstance()
          .scrollIntoView(objectId)
          .catch((_) => {
            notify({
              type: 'error',
              title: 'ELEMENT_NOT_ACCESSIBLE',
              message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_DOESNT_EXIST',
            });
          });
      }
      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
    }
  }

  return (
    <NotificationsCenter object={object} margin="0 0 0 2rem" navigateToObject={handleNavigateToObject} />
  )
}

export default EditorNotificationsCenter;