import EditionBox from 'Editor/modals/NewListModal/FormattingPane/EditionBox';
import RegularBox from 'Editor/modals/NewListModal/FormattingPane/RegularBox';
import IrregularBox from 'Editor/modals/NewListModal/FormattingPane/IrregularBox';

import styles from 'Editor/modals/NewListModal/NewListModal.module.scss';
import { ItemName } from '../NewListModal';
import { EmptyState } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

export type Props = {
  type: string;
  applyStylesToAll?: () => void;
  listItems: Editor.ListItem[];
  setListItems: (
    values:
      | {
          value: string;
          label: string;
        }
      | number
      | boolean
      | string,
    key: ItemName,
  ) => void;
  selectedLine: number;
  getLabels: (
    l: string,
    v: any,
  ) =>
    | {
        value: string;
        label: string;
      }
    | {
        value: string;
        label: string;
      }[]
    | null;
  specialIndentations: {
    value: string;
    label: string;
  }[];
  numberingTypes: {
    value: string;
    label: string;
  }[];
  customize: boolean;
};

const FormattingPane = ({ type, applyStylesToAll, ...props }: Props) => {
  const intl = useIntl();

  const formattingPaneSize = () => {
    if (type === 'outline_numbered') {
      return '47rem';
    } else {
      return '53rem';
    }
  };

  const renderEmptyState = () => {
    return (
      <EmptyState size="medium" title={intl.formatMessage({ id: 'NO_LIST_LEVEL_SELECTED' })} testId="no-list-level-selected">
        <FormattedMessage id="SELECT_A_LIST_LEVEL_TO_SEE_ITS_INFORMATION_HERE" />
      </EmptyState>
    );
  };

  if (!Number.isInteger(props.selectedLine)) {
    return (
      <div
        className={`${styles.emptyState} ${(props.customize || type === 'outline_numbered') && styles.customizeEmptyState}`}
        style={{ width: formattingPaneSize() }}
      >
        {renderEmptyState()}
      </div>
    );
  } else {
    return (
      <div
        className={`${styles.formattingPane} ${props.customize && styles.customizeFormattingPane}`}
        style={{ width: formattingPaneSize() }}
      >
        <EditionBox header={type !== 'bullet' ? 'NUMBER_FORMATTING' : 'BULLET_FORMATTING'}>
          {type === 'outline_numbered' ? (
            <RegularBox type={type} leftText="numbering_type" rightText="start_from" {...props} />
          ) : (
            <IrregularBox type={type} {...props} />
          )}
        </EditionBox>
        {type !== 'bullet' && (
          <EditionBox header="CHARACTER_FORMATTING" applyStylesToAll={applyStylesToAll}>
            <RegularBox type={type} leftText="char_before" rightText="char_after" {...props} />
          </EditionBox>
        )}
        <EditionBox header="INDENTATION">
          <RegularBox
            type={type}
            leftText="indentation_left"
            rightText="indentation_right"
            {...props}
          />
        </EditionBox>
      </div>
    );
  }
};

export default FormattingPane;
