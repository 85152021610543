import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Modal, Button, Link, Checkbox } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { closeModal } from '_common/modals/ModalsSlice';

import { parseTimezoneLabel } from '_common/modals/EditTimezoneModal/TimezoneSelect';
import { useEditProfileMutation } from 'Settings/pages/UserSettingsPage/UserSettingsApi';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

function DifferentTimezoneWarningModal() {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.DifferentTimezoneWarningModal);
  const [editProfile] = useEditProfileMutation();
  const { data: user } = useGetCurrentUserQuery();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [stopShowing, setStopShowing] = useState(false);

  const handleCloseModal = () => {
    if (stopShowing && user) {
      // Avoid modal to show up permanently
      localStorage.setItem(
        `doDOC-DifferentTimezoneWarningModalDismiss-${user.profile.id}`,
        'dismiss',
      );
    }
    dispatch(closeModal('DifferentTimezoneWarningModal'));
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  const handleUpdate = () => {
    editProfile({ timezone: dayjs.tz.guess() as Timezone });

    handleCloseModal();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} width="75rem" onClose={handleCloseModal} testId="update-timezone">
      <Modal.Header onClose={handleCloseModal}>
        <FormattedMessage id="UPDATE_TIMEZONE" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        {user && (
          <FormattedMessage
            id="DIFFERENT_TIMEZONE_WARNING"
            values={{
              profileTimezone: parseTimezoneLabel(user.profile.timezone),
              detectedTimezone: parseTimezoneLabel(dayjs.tz.guess() as Timezone),
            }}
          />
        )}
        <Checkbox
          size="small"
          checked={stopShowing ? 'checked' : 'unchecked'}
          onChange={() => setStopShowing(!stopShowing)}
          margin="4rem 0 0 0"
          testId="update-timezone-checkbox"
        >
          <FormattedMessage id="DO_NOT_SHOW_THIS_MESSAGE_AGAIN" />
        </Checkbox>
      </Modal.Body>
      <Modal.Footer alignment="space-between">
        <Link href="/settings/user/" testId="update-timezone-link" />
        <div>
          <Button size="medium" onClick={handleCancel} testId="update-timezone-cancel-button">
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            onClick={handleUpdate}
            testId="update-timezone-submit-button"
          >
            <FormattedMessage id="UPDATE" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default DifferentTimezoneWarningModal;
