import { path } from 'd3';
import { drawArrow } from './utils';

type BlockArrowProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type: 'line' | 'straightConnector1' | 'bentConnector3' | 'curvedConnector3';
  headEnd?: boolean;
  tailEnd?: boolean;
};

const getAdjstByType = (type: BlockArrowProps['type']) => {
  switch (type) {
    case 'bentConnector3':
    case 'curvedConnector3':
      return {
        adj1: 50000,
      };
    case 'line':
    case 'straightConnector1':
      return {
        adj1: 0,
        adj2: 0,
        adj3: 0,
      };
  }
};

const generateLinePath = ({
  size,
  type,
  adjst,
  tailEnd,
  headEnd,
}: BlockArrowProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;
  /** Height / 4 */
  const hd4 = h / 4;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;

  switch (type) {
    case 'line': {
      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, b);
      d.closePath();

      return {
        paths: [{ custom: { type: 'line', x1: l, y1: t, x2: r, y2: b } }],
      };
    }
    case 'straightConnector1': {
      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, b);

      drawArrow(d, l, t, r, b, 8);

      return {
        paths: [{ d: d.toString() }],
      };
    }
    case 'bentConnector3': {
      const x1 = (w * adj1) / 100000;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x1, t);
      d.lineTo(x1, b);
      d.lineTo(r, b);

      //TODO:PRESENTATION Use markers instead of drawing the arrow
      if (tailEnd) {
        drawArrow(d, x1, t, l, t, 8);
        if (headEnd) {
          drawArrow(d, x1, b, r, b, 8);
        }
      }

      return {
        paths: [{ d: d.toString() }],
      };
    }
    case 'curvedConnector3': {
      const x2 = (w * adj1) / 100000;
      const x1 = (l + x2) / 2;
      const x3 = (r + x2) / 2;
      const y3 = (h * 3) / 4;

      const d = path();
      d.moveTo(l, t);
      d.bezierCurveTo(x1, t, x2, hd4, x2, vc);
      d.bezierCurveTo(x2, y3, x3, b, r, b);

      const x1Value = hc;
      const y1Value = vc;
      const x2Value = r;
      const y2Value = b;
      const x3Value = l;
      const y3Value = t;
      const x4Value = l;
      const y4Value = t;

      //TODO:PRESENTATION Use markers instead of drawing the arrow
      if (tailEnd) {
        drawArrow(d, x1Value, y1Value, x2Value, y2Value, 8);
        if (headEnd) {
          drawArrow(d, x3Value, y3Value, x4Value, y4Value, 8);
        }
      }

      return { paths: [{ d: d.toString() }] };
    }
  }
};

export default generateLinePath;
