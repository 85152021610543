import { ReactNode } from 'react';

import styles from './Footer.module.scss';

type FooterProps = {
  children?: ReactNode;
};

const Footer = ({ children }: FooterProps) => {
  return <div className={styles.root}>{children}</div>;
};

export default Footer;
