import { Button, Tooltip } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { getThirdPartyIcon } from 'Auth/consts';
import styles from './ThirdPartyLogin.module.scss';

type ThirdPartyLoginProps = {
  data: AuthoritySchemas['ThirdPartyObject'][];
};

const ThirdPartyLogin = ({ data }: ThirdPartyLoginProps) => {
  const intl = useIntl();
  const filteredData = data.filter((thirdParty) => thirdParty.url);
  if (filteredData.length === 0) {
    return null;
  }
  const vertical = filteredData.length <= 2;

  const handleOnClick = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className={!vertical ? styles.horizontal : undefined}>
      {filteredData.map((thirdParty) => (
        <div
          key={thirdParty.name}
          style={vertical ? { marginBottom: '1rem' } : { marginRight: '1rem ' }}
        >
          <Tooltip
            content={intl.formatMessage(
              { id: 'SIGN_IN_WITH' },
              { authenticator: thirdParty.label },
            )}
            placement="bottom"
            disabled={vertical}
            testId="using-thirdParty-tooltip"
          >
            <Button
              fullWidth={vertical}
              size="large"
              icon={getThirdPartyIcon(thirdParty.label) ?? undefined}
              onClick={() => handleOnClick(thirdParty.url)}
              tabIndex={2}
              testId="using-thirdParty-button"
            >
              {vertical && (
                <FormattedMessage
                  id="auth.login.signInUsing"
                  values={{ label3p: thirdParty.label }}
                />
              )}
            </Button>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default ThirdPartyLogin;
