import { Logo, Tooltip } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { navigateToMyFiles } from 'router/history';
import { useIEnvision } from '_common/hooks';
import styles from './Title.module.scss';

const LOGO_COLORS = {
  dopdf: 'red',
  presentation: 'orange',
  document: 'blue',
} as const;

type SuiteLogoProps = {
  object: doDOC.Document | doDOC.PDF | doDOC.Presentation;
};

const SuiteLogo = ({ object }: SuiteLogoProps) => {
  const intl = useIntl();
  const isIEnvision = useIEnvision();

  const handleLogoClick = () => {
    if (!isIEnvision) {
      navigateToMyFiles();
    }
  };

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'GO_BACK_EXPLORER' })}
      placement="bottom"
      disabled={isIEnvision}
      testId="go-to-tooltip"
    >
      <span
        onClick={handleLogoClick}
        className={cx({ [styles.clickable]: !isIEnvision })}
        data-testid="logo"
      >
        <Logo color={LOGO_COLORS[object.type]} testId="title-logo" />
      </span>
    </Tooltip>
  );
};

export default SuiteLogo;
