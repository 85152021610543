import { UserPresentation as UserPresentationBase } from '_common/components';
import { UserPresentationProps as UserPresentationBaseProps } from '_common/components/UserPresentation/UserPresentation';

export type UserPresentationProps = UserPresentationBaseProps;

const UserPresentation = (props: UserPresentationProps) => {
  return <UserPresentationBase {...props} />;
};

export default UserPresentation;
