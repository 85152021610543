import { path } from 'd3';
import { SHAPE_CONST } from './consts';
import { constantToRad, ellipseArcTo, mod, pin } from './utils';

type BlockArrowProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'rightArrow'
    | 'leftArrow'
    | 'upArrow'
    | 'downArrow'
    | 'leftRightArrow'
    | 'upDownArrow'
    | 'quadArrow'
    | 'leftRightUpArrow'
    | 'bentArrow'
    | 'uturnArrow'
    | 'leftUpArrow'
    | 'bentUpArrow'
    | 'curvedRightArrow'
    | 'curvedLeftArrow'
    | 'curvedUpArrow'
    | 'curvedDownArrow'
    | 'stripedRightArrow'
    | 'notchedRightArrow'
    | 'homePlate'
    | 'chevron'
    | 'rightArrowCallout'
    | 'downArrowCallout'
    | 'leftArrowCallout'
    | 'upArrowCallout'
    | 'leftRightArrowCallout'
    | 'quadArrowCallout'
    | 'circularArrow';
};

const getAdjstByType = (type: BlockArrowProps['type']) => {
  switch (type) {
    case 'rightArrow':
    case 'leftArrow':
    case 'upArrow':
    case 'downArrow':
    case 'leftRightArrow':
    case 'upDownArrow':
    case 'stripedRightArrow':
    case 'notchedRightArrow':
    case 'homePlate':
    case 'chevron':
      return {
        adj1: 50000,
        adj2: 50000,
        adj3: 0,
        adj4: 0,
        adj5: 0,
      };
    case 'quadArrow':
      return {
        adj1: 22500,
        adj2: 22500,
        adj3: 22500,
        adj4: 0,
        adj5: 0,
      };
    case 'leftRightUpArrow':
    case 'leftUpArrow':
    case 'bentUpArrow':
    case 'bentArrow':
    case 'uturnArrow':
      return {
        adj1: 25000,
        adj2: 25000,
        adj3: 25000,
        adj4: 43750,
        adj5: 75000,
      };
    case 'leftRightArrowCallout':
      return {
        adj1: 25000,
        adj2: 25000,
        adj3: 25000,
        adj4: 48123,
        adj5: 0,
      };
    case 'curvedRightArrow':
    case 'curvedLeftArrow':
    case 'curvedUpArrow':
    case 'curvedDownArrow':
      return {
        adj1: 25000,
        adj2: 50000,
        adj3: 25000,
        adj4: 0,
        adj5: 0,
      };

    case 'rightArrowCallout':
    case 'downArrowCallout':
    case 'leftArrowCallout':
    case 'upArrowCallout':
      return {
        adj1: 25000,
        adj2: 25000,
        adj3: 25000,
        adj4: 64977,
        adj5: 0,
      };
    case 'quadArrowCallout':
      return {
        adj1: 18515,
        adj2: 18515,
        adj3: 18515,
        adj4: 48123,
        adj5: 0,
      };
    case 'circularArrow':
      return {
        adj1: 12500,
        adj2: 1142319,
        adj3: 20457681,
        adj4: 10800000,
        adj5: 12500,
      };
  }
};

const generateBlockArrowPath = ({
  size,
  type,
  adjst,
}: BlockArrowProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side */
  const ss = Math.min(w, h);
  /** Shortest Side divided by 8*/
  const ssd8 = ss / 8;
  /** Shortest Side divided by 16*/
  const ssd16 = ss / 16;
  /** Shortest Side divided by 32*/
  const ssd32 = ss / 32;

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;
  const adj3 = adjst?.adj3 ? +adjst.adj3 : defaultAdjLst.adj3;
  const adj4 = adjst?.adj4 ? +adjst.adj4 : defaultAdjLst.adj4;
  const adj5 = adjst?.adj5 ? +adjst.adj5 : defaultAdjLst.adj5;

  switch (type) {
    case 'rightArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dx1 = (ss * a2) / 100000;
      const x1 = r - dx1;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const dx2 = (y1 * dx1) / hd2;
      const x2 = x1 + dx2;

      const d = path();
      d.moveTo(l, y1);
      d.lineTo(x1, y1);
      d.lineTo(x1, t);
      d.lineTo(r, vc);
      d.lineTo(x1, b);
      d.lineTo(x1, y2);
      d.lineTo(l, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t: y1, r: x2, b: y2 } };
    }
    case 'leftArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dx2 = (ss * a2) / 100000;
      const x2 = l + dx2;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const dx1 = (y1 * dx2) / hd2;
      const x1 = x2 - dx1;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x2, t);
      d.lineTo(x2, y1);
      d.lineTo(r, y1);
      d.lineTo(r, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r, b: y2 } };
    }
    case 'upArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dy2 = (ss * a2) / 100000;
      const y2 = t + dy2;
      const dx1 = (w * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const dy1 = (x1 * dy2) / wd2;
      const y1 = y2 - dy1;

      const d = path();
      d.moveTo(l, y2);
      d.lineTo(hc, t);
      d.lineTo(r, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, b);
      d.lineTo(x1, b);
      d.lineTo(x1, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x2, b } };
    }
    case 'downArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dy1 = (ss * a2) / 100000;
      const y1 = b - dy1;
      const dx1 = (w * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const dy2 = (x1 * dy1) / wd2;
      const y2 = y1 + dy2;

      const d = path();
      d.moveTo(l, y1);
      d.lineTo(x1, y1);
      d.lineTo(x1, t);
      d.lineTo(x2, t);
      d.lineTo(x2, y1);
      d.lineTo(r, y1);
      d.lineTo(hc, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t, r: x2, b: y2 } };
    }
    case 'leftRightArrow': {
      const maxAdj2 = (50000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const x2 = (ss * a2) / 100000;
      const x3 = r - x2;
      const dy = (h * a1) / 200000;
      const y1 = vc - dy;
      const y2 = vc + dy;
      const dx1 = (y1 * x2) / hd2;
      const x1 = x2 - dx1;
      const x4 = x3 + dx1;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x2, t);
      d.lineTo(x2, y1);
      d.lineTo(x3, y1);
      d.lineTo(x3, t);
      d.lineTo(r, vc);
      d.lineTo(x3, b);
      d.lineTo(x3, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x4, b: y2 } };
    }
    case 'upDownArrow': {
      const maxAdj2 = (50000 * h) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const y2 = (ss * a2) / 100000;
      const y3 = b - y2;
      const dx1 = (w * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const dy1 = (x1 * y2) / wd2;
      const y1 = y2 - dy1;
      const y4 = y3 + dy1;

      const d = path();
      d.moveTo(l, y2);
      d.lineTo(hc, t);
      d.lineTo(r, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, y3);
      d.lineTo(r, y3);
      d.lineTo(hc, b);
      d.lineTo(l, y3);
      d.lineTo(x1, y3);
      d.lineTo(x1, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x2, b: y4 } };
    }
    case 'quadArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const q1 = 100000 - maxAdj1;
      const maxAdj3 = q1 / 2;
      const a3 = pin(0, adj3, maxAdj3);
      const x1 = (ss * a3) / 100000;
      const dx2 = (ss * a2) / 100000;
      const x2 = hc - dx2;
      const x5 = hc + dx2;
      const dx3 = (ss * a1) / 200000;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x6 = r - x1;
      const y2 = vc - dx2;
      const y5 = vc + dx2;
      const y3 = vc - dx3;
      const y4 = vc + dx3;
      const y6 = b - x1;
      const il = (dx3 * x1) / dx2;
      const ir = r - il;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x1, y2);
      d.lineTo(x1, y3);
      d.lineTo(x3, y3);
      d.lineTo(x3, x1);
      d.lineTo(x2, x1);
      d.lineTo(hc, t);
      d.lineTo(x5, x1);
      d.lineTo(x4, x1);
      d.lineTo(x4, y3);
      d.lineTo(x6, y3);
      d.lineTo(x6, y2);
      d.lineTo(r, vc);
      d.lineTo(x6, y5);
      d.lineTo(x6, y4);
      d.lineTo(x4, y4);
      d.lineTo(x4, y6);
      d.lineTo(x5, y6);
      d.lineTo(hc, b);
      d.lineTo(x2, y6);
      d.lineTo(x3, y6);
      d.lineTo(x3, y4);
      d.lineTo(x1, y4);
      d.lineTo(x1, y5);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: y3, r: ir, b: y4 } };
    }
    case 'leftRightUpArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const q1 = 100000 - maxAdj1;
      const maxAdj3 = q1;
      const a3 = pin(0, adj3, maxAdj3);
      const x1 = (ss * a3) / 100000;
      const dx2 = (ss * a2) / 100000;
      const x2 = hc - dx2;
      const x5 = hc + dx2;
      const dx3 = (ss * a1) / 200000;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x6 = r - x1;
      const dy2 = (ss * a2) / 50000;
      const y2 = b - dy2;
      const y4 = b - dx2;
      const y3 = y4 - dx3;
      const y5 = y4 + dx3;
      const il = (dx3 * x1) / dx2;
      const ir = r - il;

      const d = path();
      d.moveTo(l, y4);
      d.lineTo(x1, y2);
      d.lineTo(x1, y3);
      d.lineTo(x3, y3);
      d.lineTo(x3, x1);
      d.lineTo(x2, x1);
      d.lineTo(hc, t);
      d.lineTo(x5, x1);
      d.lineTo(x4, x1);
      d.lineTo(x4, y3);
      d.lineTo(x6, y3);
      d.lineTo(x6, y2);
      d.lineTo(r, y4);
      d.lineTo(x6, b);
      d.lineTo(x6, y5);
      d.lineTo(x1, y5);
      d.lineTo(x1, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: y3, r: ir, b: y5 } };
    }
    case 'bentArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const a3 = pin(0, adj3, 50000);
      const th = (ss * a1) / 100000;
      const aw2 = (ss * a2) / 100000;
      const th2 = th / 2;
      const dh2 = aw2 - th2;
      const ah = (ss * a3) / 100000;
      const bw = r - ah;
      const bh = b - dh2;
      const bs = Math.min(bw, bh);
      const maxAdj4 = (100000 * bs) / ss;
      const a4 = pin(0, adj4, maxAdj4);
      const bd = (ss * a4) / 100000;
      const bd3 = bd - th;
      const bd2 = Math.max(bd3, 0);
      const x3 = th + bd2;
      const x4 = r - ah;
      const y3 = dh2 + th;
      const y4 = y3 + dh2;
      const y5 = dh2 + bd;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(l, y5);
      ellipseArcTo(d, bd, bd, 'cd2', 'cd4', l, y5);
      d.lineTo(x4, dh2);
      d.lineTo(x4, t);
      d.lineTo(r, aw2);
      d.lineTo(x4, y4);
      d.lineTo(x4, y3);
      d.lineTo(x3, y3);
      ellipseArcTo(d, bd2, bd2, '3cd4', constantToRad(-5400000), x3, y3);
      d.lineTo(th, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b } };
    }
    case 'uturnArrow': {
      const a2 = pin(0, adj2, 25000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const q2 = (a1 * ss) / h;
      const q3 = 100000 - q2;
      const maxAdj3 = (q3 * h) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q1 = a3 + a1;
      const minAdj5 = (q1 * ss) / h;
      const a5 = pin(minAdj5, adj5, 100000);
      const th = (ss * a1) / 100000;
      const aw2 = (ss * a2) / 100000;
      const th2 = th / 2;
      const dh2 = aw2 - th2;
      const y5 = (h * a5) / 100000;
      const ah = (ss * a3) / 100000;
      const y4 = y5 - ah;
      const x9 = r - dh2;
      const bw = x9 / 2;
      const bs = Math.min(bw, y4);
      const maxAdj4 = (bs * 100000) / ss;
      const a4 = pin(0, adj4, maxAdj4);
      const bd = (ss * a4) / 100000;
      const bd3 = bd - th;
      const bd2 = Math.max(bd3, 0);
      const x3 = th + bd2;
      const x8 = r - aw2;
      const x6 = x8 - aw2;
      const x7 = x6 + dh2;
      const x4 = x9 - bd;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(l, bd);
      ellipseArcTo(d, bd, bd, 'cd2', 'cd4', l, bd);
      d.lineTo(x4, t);
      ellipseArcTo(d, bd, bd, '3cd4', 'cd4', x4, t);
      d.lineTo(x9, y4);
      d.lineTo(r, y4);
      d.lineTo(x8, y5);
      d.lineTo(x6, y4);
      d.lineTo(x7, y4);
      d.lineTo(x7, x3);
      ellipseArcTo(d, bd2, bd2, 0, constantToRad(-5400000), x7, x3);
      d.lineTo(x3, th);
      ellipseArcTo(d, bd2, bd2, '3cd4', constantToRad(-5400000), x3, th);
      d.lineTo(th, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b } };
    }
    case 'leftUpArrow': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = 100000 - maxAdj1;
      const a3 = pin(0, adj3, maxAdj3);
      const x1 = (ss * a3) / 100000;
      const dx2 = (ss * a2) / 50000;
      const x2 = r - dx2;
      const y2 = b - dx2;
      const dx4 = (ss * a2) / 100000;
      const x4 = r - dx4;
      const y4 = b - dx4;
      const dx3 = (ss * a1) / 200000;
      const x3 = x4 - dx3;
      const x5 = x4 + dx3;
      const y3 = y4 - dx3;
      const y5 = y4 + dx3;
      const il = (dx3 * x1) / dx4;

      const d = path();
      d.moveTo(l, y4);
      d.lineTo(x1, y2);
      d.lineTo(x1, y3);
      d.lineTo(x3, y3);
      d.lineTo(x3, x1);
      d.lineTo(x2, x1);
      d.lineTo(x4, t);
      d.lineTo(r, x1);
      d.lineTo(x5, x1);
      d.lineTo(x5, y5);
      d.lineTo(x1, y5);
      d.lineTo(x1, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: y3, r: x4, b: y5 } };
    }
    case 'bentUpArrow': {
      const a1 = pin(0, adj1, 50000);
      const a2 = pin(0, adj2, 50000);
      const a3 = pin(0, adj3, 50000);
      const y1 = (ss * a3) / 100000;
      const dx1 = (ss * a2) / 50000;
      const x1 = r - dx1;
      const dx3 = (ss * a2) / 100000;
      const x3 = r - dx3;
      const dx2 = (ss * a1) / 200000;
      const x2 = x3 - dx2;
      const x4 = x3 + dx2;
      const dy2 = (ss * a1) / 100000;
      const y2 = b - dy2;

      const d = path();
      d.moveTo(l, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, y1);
      d.lineTo(x1, y1);
      d.lineTo(x3, t);
      d.lineTo(r, y1);
      d.lineTo(x4, y1);
      d.lineTo(x4, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t: y2, r: x4, b } };
    }
    case 'curvedRightArrow': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const a1 = pin(0, adj1, a2);
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const hR = hd2 - q1;
      const q7 = hR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const idx = (q11 * w) / q7;
      const maxAdj3 = (100000 * idx) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const ah = (ss * a3) / 100000;
      const y3 = hR + th;
      const q2 = w * w;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dy = (q5 * hR) / w;
      const y5 = hR + dy;
      const y7 = y3 + dy;
      const q6 = aw - th;
      const dh = q6 / 2;
      const y4 = y5 - dh;
      const y8 = y7 + dh;
      const aw2 = aw / 2;
      const y6 = b - aw2;
      const x1 = r - ah;
      const swAng = Math.atan2(dy, ah);
      const stAng = SHAPE_CONST.cd2 - swAng;
      const mswAng = -swAng;
      const q12 = th / 2;
      const dang2 = Math.atan2(q12, idx);
      const swAng2 = dang2 - SHAPE_CONST.cd4;
      const swAng3 = SHAPE_CONST.cd4 + dang2;
      const stAng3 = SHAPE_CONST.cd2 - dang2;

      const dHead = path();
      dHead.moveTo(l, hR);
      ellipseArcTo(dHead, w, hR, 'cd2', mswAng, l, hR);
      dHead.lineTo(x1, y4);
      dHead.lineTo(r, y6);
      dHead.lineTo(x1, y8);
      dHead.lineTo(x1, y7);
      ellipseArcTo(dHead, w, hR, stAng, swAng, x1, y7);
      dHead.closePath();

      const dTail = path();
      dTail.moveTo(r, th);
      const tailBottomArc = ellipseArcTo(dTail, w, hR, '3cd4', swAng2, r, th);
      ellipseArcTo(dTail, w, hR, stAng3, swAng3, tailBottomArc.eX, tailBottomArc.eY);
      dTail.closePath();

      const dOutline = path();
      dOutline.moveTo(l, hR);
      ellipseArcTo(dOutline, w, hR, 'cd2', mswAng, l, hR);
      dOutline.lineTo(x1, y4);
      dOutline.lineTo(r, y6);
      dOutline.lineTo(x1, y8);
      dOutline.lineTo(x1, y7);
      ellipseArcTo(dOutline, w, hR, stAng, swAng, x1, y7);
      dOutline.lineTo(l, hR);
      ellipseArcTo(dOutline, w, hR, 'cd2', 'cd4', l, hR);
      dOutline.lineTo(r, th);
      tailBottomArc.redraw(dOutline);

      return {
        paths: [
          { d: dHead.toString(), stroke: 'false' },
          { d: dTail.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'curvedLeftArrow': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const a1 = pin(0, adj1, a2);
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const hR = hd2 - q1;
      const q7 = hR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const idx = (q11 * w) / q7;
      const maxAdj3 = (100000 * idx) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const ah = (ss * a3) / 100000;
      const y3 = hR + th;
      const q2 = w * w;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dy = (q5 * hR) / w;
      const y5 = hR + dy;
      const y7 = y3 + dy;
      const q6 = aw - th;
      const dh = q6 / 2;
      const y4 = y5 - dh;
      const y8 = y7 + dh;
      const aw2 = aw / 2;
      const y6 = b - aw2;
      const x1 = l + ah;
      const swAng = Math.atan2(dy, ah);
      const q12 = th / 2;
      const dang2 = Math.atan2(q12, idx);
      const swAng2 = dang2 - swAng;
      const swAng3 = swAng + dang2;
      const stAng3 = -dang2;

      const dHead = path();
      dHead.moveTo(l, y6);
      dHead.lineTo(x1, y4);
      dHead.lineTo(x1, y5);
      const headTopArc = ellipseArcTo(dHead, w, hR, swAng, swAng2, x1, y5);
      ellipseArcTo(dHead, w, hR, stAng3, swAng3, headTopArc.eX, headTopArc.eY);
      dHead.lineTo(x1, y8);
      dHead.closePath();

      const dTail = path();
      dTail.moveTo(r, y3);
      ellipseArcTo(dTail, w, hR, 0, constantToRad(-5400000), r, y3);
      dTail.lineTo(l, t);
      ellipseArcTo(dTail, w, hR, '3cd4', 'cd4', l, t);
      dTail.closePath();

      const dOutline = path();
      dOutline.moveTo(r, y3);
      ellipseArcTo(dOutline, w, hR, 0, constantToRad(-5400000), r, y3);
      dOutline.lineTo(l, t);
      ellipseArcTo(dOutline, w, hR, '3cd4', 'cd4', l, t);
      dOutline.lineTo(r, y3);
      ellipseArcTo(dOutline, w, hR, 0, swAng, r, y3);
      dOutline.lineTo(x1, y8);
      dOutline.lineTo(l, y6);
      dOutline.lineTo(x1, y4);
      dOutline.lineTo(x1, y5);
      headTopArc.redraw(dOutline);

      return {
        paths: [
          { d: dHead.toString(), stroke: 'false' },
          { d: dTail.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'curvedUpArrow': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const a1 = pin(0, adj1, 100000);
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const wR = wd2 - q1;
      const q7 = wR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const idy = (q11 * h) / q7;
      const ah = (ss * adj3) / 100000;
      const x3 = wR + th;
      const q2 = h * h;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dx = (q5 * wR) / h;
      const x5 = wR + dx;
      const x7 = x3 + dx;
      const q6 = aw - th;
      const dh = q6 / 2;
      const x4 = x5 - dh;
      const x8 = x7 + dh;
      const aw2 = aw / 2;
      const x6 = r - aw2;
      const y1 = t + ah;
      const swAng = Math.atan2(dx, ah);
      const iy = t + idy;
      const ix = (wR + x3) / 2;
      const q12 = th / 2;
      const dang2 = Math.atan2(q12, idy);
      const swAng2 = dang2 - swAng;
      const stAng3 = SHAPE_CONST.cd4 - swAng;
      const swAng3 = swAng + dang2;
      const stAng2 = SHAPE_CONST.cd4 - dang2;

      const dHead = path();
      dHead.moveTo(x6, t);
      dHead.lineTo(x8, y1);
      dHead.lineTo(x7, y1);
      const headRightArc = ellipseArcTo(dHead, wR, h, stAng3, swAng3, x7, y1);
      ellipseArcTo(dHead, wR, h, stAng2, swAng2, headRightArc.eX, headRightArc.eY);
      dHead.lineTo(x4, y1);
      dHead.closePath();

      const dTail = path();
      dTail.moveTo(wR, b);
      ellipseArcTo(dTail, wR, h, 'cd4', 'cd4', wR, b);
      dTail.lineTo(th, t);
      ellipseArcTo(dTail, wR, h, 'cd2', constantToRad(-5400000), th, t);
      dTail.closePath();

      const dOutline = path();
      dOutline.moveTo(ix, iy);
      ellipseArcTo(dOutline, wR, h, stAng2, swAng2, ix, iy);
      dOutline.lineTo(x4, y1);
      dOutline.lineTo(x6, t);
      dOutline.lineTo(x8, y1);
      dOutline.lineTo(x7, y1);
      ellipseArcTo(dOutline, wR, h, stAng3, swAng, x7, y1);
      dOutline.lineTo(wR, b);
      ellipseArcTo(dOutline, wR, h, 'cd4', 'cd4', wR, b);
      dOutline.lineTo(th, t);
      ellipseArcTo(dOutline, wR, h, 'cd2', constantToRad(-5400000), th, t);

      return {
        paths: [
          { d: dHead.toString(), stroke: 'false' },
          { d: dTail.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'curvedDownArrow': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const a1 = pin(0, adj1, 100000);
      const th = (ss * a1) / 100000;
      const aw = (ss * a2) / 100000;
      const q1 = (th + aw) / 4;
      const wR = wd2 - q1;
      const q7 = wR * 2;
      const q8 = q7 * q7;
      const q9 = th * th;
      const q10 = q8 - q9;
      const q11 = Math.sqrt(q10);
      const idy = (q11 * h) / q7;
      const ah = (ss * adj3) / 100000;
      const x3 = wR + th;
      const q2 = h * h;
      const q3 = ah * ah;
      const q4 = q2 - q3;
      const q5 = Math.sqrt(q4);
      const dx = (q5 * wR) / h;
      const x5 = wR + dx;
      const x7 = x3 + dx;
      const q6 = aw - th;
      const dh = q6 / 2;
      const x4 = x5 - dh;
      const x8 = x7 + dh;
      const aw2 = aw / 2;
      const x6 = r - aw2;
      const y1 = b - ah;
      const swAng = Math.atan2(dx, ah);
      const mswAng = -swAng;
      const iy = b - idy;
      const ix = (wR + x3) / 2;
      const q12 = th / 2;
      const dang2 = Math.atan2(q12, idy);
      const stAng = SHAPE_CONST['3cd4'] + swAng;
      const stAng2 = SHAPE_CONST['3cd4'] - dang2;
      const swAng2 = dang2 - SHAPE_CONST.cd4;
      const swAng3 = SHAPE_CONST.cd4 + dang2;

      const dHead = path();
      dHead.moveTo(x6, b);
      dHead.lineTo(x4, y1);
      dHead.lineTo(x5, y1);
      ellipseArcTo(dHead, wR, h, stAng, mswAng, x5, y1);
      dHead.lineTo(x3, t);
      ellipseArcTo(dHead, wR, h, '3cd4', swAng, x3, t);
      dHead.lineTo(x8, y1);
      dHead.closePath();

      const dTail = path();
      dTail.moveTo(ix, iy);
      ellipseArcTo(dTail, wR, h, stAng2, swAng2, ix, iy);
      dTail.lineTo(l, b);
      ellipseArcTo(dTail, wR, h, 'cd2', swAng3, l, b);
      dTail.closePath();

      const dOutline = path();
      dOutline.moveTo(ix, iy);
      ellipseArcTo(dOutline, wR, h, stAng2, swAng2, ix, iy);
      dOutline.lineTo(l, b);
      ellipseArcTo(dOutline, wR, h, 'cd2', 'cd4', l, b);
      dOutline.lineTo(x3, t);
      ellipseArcTo(dOutline, wR, h, '3cd4', swAng, x3, t);
      dOutline.lineTo(x8, y1);
      dOutline.lineTo(x6, b);
      dOutline.lineTo(x4, y1);
      dOutline.lineTo(x5, y1);
      ellipseArcTo(dOutline, wR, h, stAng, mswAng, x5, y1);

      return {
        paths: [
          { d: dHead.toString(), stroke: 'false' },
          { d: dTail.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'stripedRightArrow': {
      const maxAdj2 = (84375 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const x4 = (ss * 5) / 32;
      const dx5 = (ss * a2) / 100000;
      const x5 = r - dx5;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const dx6 = (dy1 * dx5) / hd2;
      const x6 = r - dx6;

      const d = path();
      d.moveTo(l, y1);
      d.lineTo(ssd32, y1);
      d.lineTo(ssd32, y2);
      d.lineTo(l, y2);
      d.closePath();
      d.moveTo(ssd16, y1);
      d.lineTo(ssd8, y1);
      d.lineTo(ssd8, y2);
      d.lineTo(ssd16, y2);
      d.closePath();
      d.moveTo(x4, y1);
      d.lineTo(x5, y1);
      d.lineTo(x5, 0);
      d.lineTo(r, vc);
      d.lineTo(x5, h);
      d.lineTo(x5, y2);
      d.lineTo(x4, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x4, t: y1, r: x6, b: y2 } };
    }
    case 'notchedRightArrow': {
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(0, adj2, maxAdj2);
      const dx2 = (ss * a2) / 100000;
      const x2 = r - dx2;
      const dy1 = (h * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const x1 = (dy1 * dx2) / hd2;
      const x3 = r - x1;

      const d = path();
      d.moveTo(l, y1);
      d.lineTo(x2, y1);
      d.lineTo(x2, t);
      d.lineTo(r, vc);
      d.lineTo(x2, b);
      d.lineTo(x2, y2);
      d.lineTo(l, y2);
      d.lineTo(x1, vc);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x3, b: y2 } };
    }
    case 'homePlate': {
      const maxAdj = (100000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const dx1 = (ss * a) / 100000;
      const x1 = r - dx1;
      const ir = (x1 + r) / 2;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x1, t);
      d.lineTo(r, vc);
      d.lineTo(x1, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r: ir, b } };
    }
    case 'chevron': {
      const maxAdj = (100000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const dx = x2 - x1;
      const il = dx > 0 ? x1 : l;
      const ir = dx > 0 ? x2 : r;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x2, t);
      d.lineTo(r, vc);
      d.lineTo(x2, b);
      d.lineTo(l, b);
      d.lineTo(x1, vc);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t, r: ir, b } };
    }
    case 'rightArrowCallout': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * w) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / w;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dy1 = (ss * a2) / 100000;
      const dy2 = (ss * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const dx3 = (ss * a3) / 100000;
      const x3 = r - dx3;
      const x2 = (w * a4) / 100000;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x2, t);
      d.lineTo(x2, y2);
      d.lineTo(x3, y2);
      d.lineTo(x3, y1);
      d.lineTo(r, vc);
      d.lineTo(x3, y4);
      d.lineTo(x3, y3);
      d.lineTo(x2, y3);
      d.lineTo(x2, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r: x2, b } };
    }
    case 'downArrowCallout': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * h) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / h;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dx1 = (ss * a2) / 100000;
      const dx2 = (ss * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const dy3 = (ss * a3) / 100000;
      const y3 = b - dy3;
      const y2 = (h * a4) / 100000;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r, y2);
      d.lineTo(x3, y2);
      d.lineTo(x3, y3);
      d.lineTo(x4, y3);
      d.lineTo(hc, b);
      d.lineTo(x1, y3);
      d.lineTo(x2, y3);
      d.lineTo(x2, y2);
      d.lineTo(l, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b: y2 } };
    }
    case 'leftArrowCallout': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * w) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / w;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dy1 = (ss * a2) / 100000;
      const dy2 = (ss * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const x1 = (ss * a3) / 100000;
      const dx2 = (w * a4) / 100000;
      const x2 = r - dx2;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x1, y1);
      d.lineTo(x1, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, t);
      d.lineTo(r, t);
      d.lineTo(r, b);
      d.lineTo(x2, b);
      d.lineTo(x2, y3);
      d.lineTo(x1, y3);
      d.lineTo(x1, y4);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x2, t, r, b } };
    }
    case 'upArrowCallout': {
      const maxAdj2 = (50000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (100000 * h) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / h;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dx1 = (ss * a2) / 100000;
      const dx2 = (ss * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = (ss * a3) / 100000;
      const dy2 = (h * a4) / 100000;
      const y2 = b - dy2;

      const d = path();
      d.moveTo(l, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, y1);
      d.lineTo(x1, y1);
      d.lineTo(hc, t);
      d.lineTo(x4, y1);
      d.lineTo(x3, y1);
      d.lineTo(x3, y2);
      d.lineTo(r, y2);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t: y2, r, b } };
    }
    case 'leftRightArrowCallout': {
      const maxAdj2 = (50000 * h) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = (50000 * w) / ss;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = (a3 * ss) / wd2;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(0, adj4, maxAdj4);
      const dy1 = (ss * a2) / 100000;
      const dy2 = (ss * a1) / 200000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const x1 = (ss * a3) / 100000;
      const x4 = r - x1;
      const dx2 = (w * a4) / 200000;
      const x2 = hc - dx2;
      const x3 = hc + dx2;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x1, y1);
      d.lineTo(x1, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, t);
      d.lineTo(x3, t);
      d.lineTo(x3, y2);
      d.lineTo(x4, y2);
      d.lineTo(x4, y1);
      d.lineTo(r, vc);
      d.lineTo(x4, y4);
      d.lineTo(x4, y3);
      d.lineTo(x3, y3);
      d.lineTo(x3, b);
      d.lineTo(x2, b);
      d.lineTo(x2, y3);
      d.lineTo(x1, y3);
      d.lineTo(x1, y4);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x2, t, r: x3, b } };
    }
    case 'quadArrowCallout': {
      const a2 = pin(0, adj2, 50000);
      const maxAdj1 = a2 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const maxAdj3 = 50000 - a2;
      const a3 = pin(0, adj3, maxAdj3);
      const q2 = a3 * 2;
      const maxAdj4 = 100000 - q2;
      const a4 = pin(a1, adj4, maxAdj4);
      const dx2 = (ss * a2) / 100000;
      const dx3 = (ss * a1) / 200000;
      const ah = (ss * a3) / 100000;
      const dx1 = (w * a4) / 200000;
      const dy1 = (h * a4) / 200000;
      const x8 = r - ah;
      const x2 = hc - dx1;
      const x7 = hc + dx1;
      const x3 = hc - dx2;
      const x6 = hc + dx2;
      const x4 = hc - dx3;
      const x5 = hc + dx3;
      const y8 = b - ah;
      const y2 = vc - dy1;
      const y7 = vc + dy1;
      const y3 = vc - dx2;
      const y6 = vc + dx2;
      const y4 = vc - dx3;
      const y5 = vc + dx3;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(ah, y3);
      d.lineTo(ah, y4);
      d.lineTo(x2, y4);
      d.lineTo(x2, y2);
      d.lineTo(x4, y2);
      d.lineTo(x4, ah);
      d.lineTo(x3, ah);
      d.lineTo(hc, t);
      d.lineTo(x6, ah);
      d.lineTo(x5, ah);
      d.lineTo(x5, y2);
      d.lineTo(x7, y2);
      d.lineTo(x7, y4);
      d.lineTo(x8, y4);
      d.lineTo(x8, y3);
      d.lineTo(r, vc);
      d.lineTo(x8, y6);
      d.lineTo(x8, y5);
      d.lineTo(x7, y5);
      d.lineTo(x7, y7);
      d.lineTo(x5, y7);
      d.lineTo(x5, y8);
      d.lineTo(x6, y8);
      d.lineTo(hc, b);
      d.lineTo(x3, y8);
      d.lineTo(x4, y8);
      d.lineTo(x4, y7);
      d.lineTo(x2, y7);
      d.lineTo(x2, y5);
      d.lineTo(ah, y5);
      d.lineTo(ah, y6);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x2, t: y2, r: x7, b: y7 } };
    }
    case 'circularArrow': {
      //The min value of adj5 was changed to 1, as 0 causes NaN values
      const a5 = pin(1, adj5, 25000);
      const maxAdj1 = a5 * 2;
      const a1 = pin(0, adj1, maxAdj1);
      const enAng = constantToRad(pin(1, adj3, 21599999));
      const stAng = constantToRad(pin(0, adj4, 21599999));
      const th = (ss * a1) / 100000;
      const thh = (ss * a5) / 100000;
      const th2 = th / 2;
      const rw1 = wd2 + th2 - thh;
      const rh1 = hd2 + th2 - thh;
      const rw2 = rw1 - th;
      const rh2 = rh1 - th;
      const rw3 = rw2 + th2;
      const rh3 = rh2 + th2;
      const wtH = rw3 * Math.sin(enAng);
      const htH = rh3 * Math.cos(enAng);
      const dxH = rw3 * Math.cos(Math.atan2(wtH, htH));
      const dyH = rh3 * Math.sin(Math.atan2(wtH, htH));
      const xH = hc + dxH;
      const yH = vc + dyH;
      const rI = Math.min(rw2, rh2);
      const u1 = dxH * dxH;
      const u2 = dyH * dyH;
      const u3 = rI * rI;
      const u4 = u1 - u3;
      const u5 = u2 - u3;
      const u6 = (u4 * u5) / u1;
      const u7 = u6 / u2;
      const u8 = 1 - u7;
      const u9 = Math.sqrt(u8);
      const u10 = u4 / dxH;
      const u11 = u10 / dyH;
      const u12 = (1 + u9) / u11;
      const u13 = Math.atan2(u12, 1);
      const u14 = u13 + constantToRad(21600000);
      const u15 = u13 > 0 ? u13 : u14;
      const u16 = u15 - enAng;
      const u17 = u16 + constantToRad(21600000);
      const u18 = u16 > 0 ? u16 : u17;
      const u19 = u18 - SHAPE_CONST.cd2;
      const u20 = u18 - constantToRad(21600000);
      const u21 = u19 > 0 ? u20 : u18;
      const maxAng = Math.abs(u21);
      const aAng = pin(0, constantToRad(adj2), maxAng);
      const ptAng = enAng + aAng;
      const wtA = rw3 * Math.sin(ptAng);
      const htA = rh3 * Math.cos(ptAng);
      const dxA = rw3 * Math.cos(Math.atan2(wtA, htA));
      const dyA = rh3 * Math.sin(Math.atan2(wtA, htA));
      const xA = hc + dxA;
      const yA = vc + dyA;
      const wtE = rw1 * Math.sin(stAng);
      const htE = rh1 * Math.cos(stAng);
      const dxE = rw1 * Math.cos(Math.atan2(wtE, htE));
      const dyE = rh1 * Math.sin(Math.atan2(wtE, htE));
      const xE = hc + dxE;
      const yE = vc + dyE;
      const dxG = thh * Math.cos(ptAng);
      const dyG = thh * Math.sin(ptAng);
      const xG = xH + dxG;
      const yG = yH + dyG;
      const dxB = thh * Math.cos(ptAng);
      const dyB = thh * Math.sin(ptAng);
      const xB = xH - dxB;
      const yB = yH - dyB;
      const sx1 = xB - hc;
      const sy1 = yB - vc;
      const sx2 = xG - hc;
      const sy2 = yG - vc;
      const rO = Math.min(rw1, rh1);
      const x1O = (sx1 * rO) / rw1;
      const y1O = (sy1 * rO) / rh1;
      const x2O = (sx2 * rO) / rw1;
      const y2O = (sy2 * rO) / rh1;
      const dxO = x2O - x1O;
      const dyO = y2O - y1O;
      const dO = mod(dxO, dyO, 0);
      const q1 = x1O * y2O;
      const q2 = x2O * y1O;
      const DO = q1 - q2;
      const q3 = rO * rO;
      const q4 = dO * dO;
      const q5 = q3 * q4;
      const q6 = DO * DO;
      const q7 = q5 - q6;
      const q8 = Math.max(q7, 0);
      const sdelO = Math.sqrt(q8);
      const ndyO = dyO * -1;
      const sdyO = ndyO > 0 ? -1 : 1;
      const q9 = sdyO * dxO;
      const q10 = q9 * sdelO;
      const q11 = DO * dyO;
      const dxF1 = (q11 + q10) / q4;
      const q12 = q11 - q10;
      const dxF2 = (q12 * 1) / q4;
      const adyO = Math.abs(dyO);
      const q13 = adyO * sdelO;
      const q14 = (DO * dxO) / -1;
      const dyF1 = (q14 + q13) / q4;
      const q15 = q14 - q13;
      const dyF2 = (q15 * 1) / q4;
      const q16 = x2O - dxF1;
      const q17 = x2O - dxF2;
      const q18 = y2O - dyF1;
      const q19 = y2O - dyF2;
      const q20 = mod(q16, q18, 0);
      const q21 = mod(q17, q19, 0);
      const q22 = q21 - q20;
      const dxF = q22 > 0 ? dxF1 : dxF2;
      const dyF = q22 > 0 ? dyF1 : dyF2;
      const sdxF = (dxF * rw1) / rO;
      const sdyF = (dyF * rh1) / rO;
      const xF = hc + sdxF;
      const yF = vc + sdyF;
      const x1I = (sx1 * rI) / rw2;
      const y1I = (sy1 * rI) / rh2;
      const x2I = (sx2 * rI) / rw2;
      const y2I = (sy2 * rI) / rh2;
      const dxI = x2I - x1I;
      const dyI = y2I - y1I;
      const dI = mod(dxI, dyI, 0);
      const v1 = x1I * y2I;
      const v2 = x2I * y1I;
      const DI = v1 - v2;
      const v3 = rI * rI;
      const v4 = dI * dI;
      const v5 = v3 * v4;
      const v6 = DI * DI;
      const v7 = v5 - v6;
      const v8 = Math.max(v7, 0);
      const sdelI = Math.sqrt(v8);
      const v9 = sdyO * dxI;
      const v10 = v9 * sdelI;
      const v11 = DI * dyI;
      const dxC1 = (v11 + v10) / v4;
      const v12 = v11 - v10;
      const dxC2 = (v12 * 1) / v4;
      const adyI = Math.abs(dyI);
      const v13 = adyI * sdelI;
      const v14 = (DI * dxI) / -1;
      const dyC1 = (v14 + v13) / v4;
      const v15 = v14 - v13;
      const dyC2 = (v15 * 1) / v4;
      const v16 = x1I - dxC1;
      const v17 = x1I - dxC2;
      const v18 = y1I - dyC1;
      const v19 = y1I - dyC2;
      const v20 = mod(v16, v18, 0);
      const v21 = mod(v17, v19, 0);
      const v22 = v21 - v20;
      const dxC = v22 > 0 ? dxC1 : dxC2;
      const dyC = v22 > 0 ? dyC1 : dyC2;
      const sdxC = (dxC * rw2) / rI;
      const sdyC = (dyC * rh2) / rI;
      const xC = hc + sdxC;
      const yC = vc + sdyC;
      const ist0 = Math.atan2(sdyC, sdxC);
      const ist1 = ist0 + constantToRad(21600000);
      const istAng = ist0 > 0 ? ist0 : ist1;
      const isw1 = stAng - istAng;
      const isw2 = isw1 - constantToRad(21600000);
      const iswAng = isw1 > 0 ? isw2 : isw1;
      const p1 = xF - xC;
      const p2 = yF - yC;
      const p3 = mod(p1, p2, 0);
      const p4 = (p3 * 1) / 2;
      const p5 = p4 - thh;
      const xGp = p5 > 0 ? xF : xG;
      const yGp = p5 > 0 ? yF : yG;
      const xBp = p5 > 0 ? xC : xB;
      const yBp = p5 > 0 ? yC : yB;
      const en0 = Math.atan2(sdyF, sdxF);
      const en1 = en0 + constantToRad(21600000);
      const en2 = en0 > 0 ? en0 : en1;
      const sw0 = en2 - stAng;
      const sw1 = sw0 + constantToRad(21600000);
      const swAng = sw0 > 0 ? sw0 : sw1;
      const idx = rw1 * Math.cos(constantToRad(2700000));
      const idy = rh1 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const d = path();
      d.moveTo(xE, yE);
      ellipseArcTo(d, rw1, rh1, stAng, swAng, xE, yE);
      d.lineTo(xGp, yGp);
      d.lineTo(xA, yA);
      d.lineTo(xBp, yBp);
      d.lineTo(xC, yC);
      ellipseArcTo(d, rw2, rh2, istAng, iswAng, xC, yC);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
  }
};

export default generateBlockArrowPath;
