import { useState, useEffect, memo } from 'react';
import { Avatar } from 'dodoc-design-system';
import { getAvatarColor } from 'assets/colors';
import type { AvatarProps } from 'dodoc-design-system/build/types/Components/Avatar/Avatar';
import { usePublicProfile, useSelector } from '_common/hooks';
import { useGetAvatarQuery } from 'Auth/redux/avatarApi';

type UserAvatarProps = {
  userId: UserId;
  text?: string;
  showTooltip?: boolean;
  online?: boolean;
} & Pick<AvatarProps, 'size' | 'color' | 'editor' | 'margin'>;

const UserAvatar = ({
  userId,
  text = '',
  showTooltip = false,
  online = false,
  size = 'medium',
  color,
  editor,
  margin,
}: UserAvatarProps) => {
  const profile = usePublicProfile(userId);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const [avatarColor, setAvatarColor] = useState<AvatarProps['color']>(undefined);
  const { data: avatar } = useGetAvatarQuery(userId, {
    skip: !authenticated || !userId || userId === 'IMPORTED_USER' || !profile.has_avatar,
  });

  useEffect(() => {
    let code = getColorCode(+userId);

    let initials = profile.initials;
    if (!initials) {
      initials = '  ';
    }

    if (!code || (code === 0 && initials.length > 0)) {
      code = getColorCode(initials.toLowerCase().charCodeAt(0));
    }

    const userColor = getAvatarColor(code);

    setAvatarColor(userColor);
  }, [userId, text]);

  const getColorCode = (id: number) => {
    let code;
    code = Math.sin(id) * 10000;
    code = Math.floor((code - Math.floor(code)) * 10000);
    return code;
  };

  if (!profile.name && !avatar && !profile.avatar) {
    color = 'blue';
    editor = 'blue';
  }

  return (
    <Avatar
      size={size}
      //@ts-expect-error Avatar color prop doesn't accept null
      color={avatar || profile.avatar ? null : color || avatarColor} //If there is an image, color will be null to override default 'blue' value
      imageSrc={avatar || profile.avatar}
      tooltip={showTooltip ? profile.name : undefined}
      text={profile.initials}
      badge={online}
      editor={editor}
      margin={margin}
      testId={`avatar-user-${userId}`}
    />
  );
};

export default memo(UserAvatar);
