import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectIsPageLayout, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { selectFilteredComments } from 'Editor/redux/CommentsSlice';

import styles from './CommentButtons.module.scss';

const CommentButtons = () => {
  const dispatch = useDispatch();
  const object = useSuiteObject();

  const selection = useSelector((state) => state.editor.status.selection);
  const comments = useSelector(selectFilteredComments);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isOwner =
    object.user_permissions.includes('owner') || object.user_permissions.includes('admin');

  const openConfirmationModal = (action: 'accept' | 'reject', mode: 'selection' | 'object') => {
    const title = action === 'accept' ? 'RESOLVE_ALL_COMMENTS' : 'DELETE_ALL_COMMENTS';
    const actionCode = action === 'accept' ? 'resolveAllComments' : 'deleteAllComments';
    const actionValue = {
      selectedComments: selection.COMMENT,
      ontoAll: isOwner,
      forceAll: mode === 'object',
    };
    const confirmButtonTextId = action === 'accept' ? 'RESOLVE_COMMENTS' : 'DELETE_COMMENTS';

    let message: TranslationId;
    if (actionValue.ontoAll) {
      //Own comments only
      if (actionValue.forceAll) {
        //All
        message =
          action === 'accept'
            ? 'RESOLVE_ALL_COMMENTS_IN_THE_DOCUMENT'
            : 'YOU_ARE_ABOUT_TO_DELETE_ALL_COMMENTS_IN_DOCUMENT_CONFIRM';
      } else {
        //Selected
        message =
          action === 'accept'
            ? 'RESOLVE_ALL_COMMENTS_IN_THE_SELECTION'
            : 'YOU_ARE_ABOUT_TO_DELETE_ALL_COMMENTS_IN_SELECTION_CONFIRM';
      }
    } else {
      //All comments
      if (actionValue.forceAll) {
        //All
        message =
          action === 'accept'
            ? 'RESOLVE_ALL_YOUR_COMMENTS_IN_THE_DOCUMENT'
            : 'YOU_ARE_ABOUT_TO_DELETE_YOUR_COMMENTS_IN_DOCUMENT_CONFIRM';
      } else {
        //Selected
        message =
          action === 'accept'
            ? 'RESOLVE_ALL_YOUR_COMMENTS_IN_THE_SELECTION'
            : 'YOU_ARE_ABOUT_TO_DELETE_YOUR_COMMENTS_IN_SELECTION_CONFIRM';
      }
    }

    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title,
          message,
          confirmButtonTextId,
          confirmButtonTextValues: { total: 2 },
          actionCode,
          actionValue,
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          headerType: 'error',
          cancelButtonShow: true,
          width: '56rem',
        },
      }),
    );
  };

  return (
    <div className={styles.commentButtons}>
      <Button
        fullWidth
        size="small"
        disabled={comments?.order.length === 0 || isReadOnlyMode || isPageLayout}
        onClick={() =>
          openConfirmationModal('accept', selection.COMMENT.length > 0 ? 'selection' : 'object')
        }
        testId={'resolve-all-button'}
      >
        <FormattedMessage id="RESOLVE_ALL" />
      </Button>
      <Button
        fullWidth
        margin="0 0 0 2rem"
        size="small"
        disabled={comments?.order.length === 0 || isReadOnlyMode || isPageLayout}
        onClick={() =>
          openConfirmationModal('reject', selection.COMMENT.length > 0 ? 'selection' : 'object')
        }
        testId={'delete-all-button'}
      >
        <FormattedMessage id="DELETE_ALL" />
      </Button>
    </div>
  );
};

export default CommentButtons;
