import { useIntl } from 'react-intl';
import NotificationSettingsRow from 'Settings/components/NotificationSettings/NotificationSettingsRow/NotificationSettingsRow';
import SettingContent from './SettingContent/SettingContent';

const NotificationSettings = () => {
  const intl = useIntl();

  return (
    <NotificationSettingsRow
      id={'notification.workingHours'}
      title={intl.formatMessage({ id: 'WORKING_HOURS' })}
      description={intl.formatMessage({
        id: 'SET_TIMEFRAME_OF_WHEN_EMAIL_NOTIFICATIONS_ARE_SENT',
      })}
    >
      <SettingContent />
    </NotificationSettingsRow>
  );
};

export default NotificationSettings;
