import PopperTooltip from '../List/PopperTooltip/PopperTooltip';
import styles from '../List/List.module.scss';

type NameCellProps = {
  user: UserPublicProfileExtended;
  firstName?: boolean;
};

const NameCell = ({ user, firstName = false }: NameCellProps) => {
  return (
    <div className={`${!user.is_active && styles.deactivated}`}>
      <PopperTooltip
        content={firstName ? user.first_name : user.last_name}
        testId={firstName ? 'first-name-tooltip' : 'last-name-tooltip'}
      >
        <>{firstName ? user.first_name : user.last_name}</>
      </PopperTooltip>
    </div>
  );
};

export default NameCell;
