import { Mixin } from 'mixwith';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      parseApprovedNode(representation) {
        return {
          id: representation.attributes.enclosed_element.nodeValue,
          ignore: true,
        };
      }
    },
);
