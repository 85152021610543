import { InteractionController, IntlErrorBoundary } from '_common/components';

import TrackedActionCardContent, { TrackedActionCardProps } from './TrackedActionCardContent';

const TrackedActionCard = (props: TrackedActionCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.panel, width: props.panel ? '100%' : '43rem' }}
    >
      <InteractionController environment="editor">
        <TrackedActionCardContent {...props} />
      </InteractionController>
    </IntlErrorBoundary>
  );
};

export default TrackedActionCard;
