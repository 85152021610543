import { Footer } from '_common/suite/components';
import PDFFooter from './Footer';

const FooterBoundary = () => (
  <Footer>
    <PDFFooter />
  </Footer>
);

export default FooterBoundary;
