import { BlockViewModel } from '../../ViewModels/BlockViewModel/BlockViewModel';

export class ApprovalsManager {
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;

  constructor(dataManager: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;
    this.handleApprovalSummaryChanged = this.handleApprovalSummaryChanged.bind(this);
  }

  start() {
    this.Data.events.on('LOAD_APPROVAL_SUMMARY', this.handleApprovalSummaryChanged);
  }

  private handleApprovalSummaryChanged(summary: Approval.ApprovalSummary) {
    //
    let children = this.Visualizer.dVM?.children;
    let childrenList = children?.list() || [];
    let child;
    for (let index = 0; index < childrenList.length; index++) {
      child = childrenList[index];
      (child as BlockViewModel).setBlockSelected(
        this.Data.approvals.isSelectedForApproval(child.id),
      );
    }
  }

  destroy() {
    this.Data.events.off('LOAD_APPROVAL_SUMMARY', this.handleApprovalSummaryChanged);
  }
}
