import { ReactNode, createContext, useContext, useState } from 'react';

type IObjectPreviewContext = {
  type: ObjectPreviewType;
  object: doDOC.File;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

type ObjectPreviewProviderProps = Pick<IObjectPreviewContext, 'type' | 'object'> & {
  children: ReactNode;
};

const ObjectPreviewContext = createContext<IObjectPreviewContext | undefined>(undefined);

const ObjectPreviewProvider = ({ type, object, children }: ObjectPreviewProviderProps) => {
  //Loading will only affect presentation preview
  const [loading, setLoading] = useState(type === 'presentation');

  return (
    <ObjectPreviewContext.Provider value={{ type, object, loading, setLoading }}>
      {children}
    </ObjectPreviewContext.Provider>
  );
};

export const useObjectPreview = () => {
  const context = useContext(ObjectPreviewContext);
  if (context === undefined) {
    throw new Error('useObjectPreview must be used within a ObjectPreviewProvider');
  }
  return context;
};

export default ObjectPreviewProvider;
