import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ApprovedViewElement } from '../BasicModeDecorator';

export class BaseDecorator {
  protected data: Editor.Data.API;
  private visualizer: Editor.Visualizer.State;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.data = Data;
    this.visualizer = Visualizer;
  }

  isNodeApproved(data: Editor.Data.Node.Data) {
    return data.approvedBy?.length > 0;
  }

  isSelectedForPermissions(nodeId: string): boolean {
    return this.data.permissions.isSelectedForPermissions(nodeId);
  }

  usersWithPermissions(nodeId: string) {
    return this.data.permissions.usersWithBlockPermissions(nodeId);
  }

  approveDecorator(data: Editor.Data.Node.Data, node: HTMLElement) {
    const approvedNode = DOMElementFactory.buildElement(
      ELEMENTS.ApprovedElement.IDENTIFIER,
    ) as ApprovedViewElement;

    if (data.id) {
      const approvedUsers: string[] = data.approvedBy || [];

      approvedNode.setAttribute('enclosed_element', data.id);

      if (node.dataset.styleId) {
        approvedNode.dataset.styleId = node.dataset.styleId;
      }

      if (node.dataset.spaceBefore) {
        approvedNode.dataset.spaceBefore = node.dataset.spaceBefore;
      }

      if (node.dataset.spaceAfter) {
        approvedNode.dataset.spaceAfter = node.dataset.spaceAfter;
      }

      if (node.dataset.asa) {
        approvedNode.dataset.asa = node.dataset.asa;
      }

      if (node.dataset.asb) {
        approvedNode.dataset.asb = node.dataset.asb;
      }

      approvedNode.setupStructure(node, this.visualizer.layoutType, approvedUsers);
    }
    return approvedNode;
  }

  readonlyDecorator(data: Editor.Data.Node.Data, node: HTMLElement) {
    if (!this.visualizer.isReadOnly?.()) {
      node.setAttribute('lock', 'true');
    }
    return node;
  }

  decorator(data: Editor.Data.Node.Data, node: HTMLElement) {
    return node;
  }
}
