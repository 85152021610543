import { Toggle } from 'dodoc-design-system';

import styles from './Suggestion.module.scss';

type SuggestionProps = {
  suggestion: string;
  active: boolean;
  index: number;
  setActive: (index: number) => void;
  handleChangeOccurrence: (index: number) => void;
};

const Suggestion = ({
  suggestion,
  active,
  index,
  setActive,
  handleChangeOccurrence,
}: SuggestionProps) => {
  const onClick = () => {
    setActive(index);
  };

  const onDoubleClick = () => {
    handleChangeOccurrence(index);
  };

  return (
    <div className={styles.root}>
      <Toggle
        size="medium"
        variant="pill"
        isToggled={active}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        testId={`spell-check-suggestion-${index}-toggle`}
      >
        {suggestion}
      </Toggle>
    </div>
  );
};

export default Suggestion;
