import { FormattedMessage } from 'react-intl';
import { Toggle, Divider, Dropdown, usePopper } from 'dodoc-design-system';

import { useFind } from './FindContext';

import styles from './FindInstances.module.scss';

export type FindInstancesProps = {
  totalInstances: number | undefined;
  currentInstanceIndex: number;
  onChangeInstance: (newInstanceIndex: number) => void;
};

const FindInstances = ({
  currentInstanceIndex,
  totalInstances,
  onChangeInstance,
}: FindInstancesProps) => {
  const { matchCase, wholeWord, toggleMatchCase, toggleWholeWord } = useFind();
  const moreActions = usePopper({ placement: 'bottom-start' });

  const next = () => {
    if (totalInstances != null) {
      let newIndex = (currentInstanceIndex + 1) % totalInstances;
      onChangeInstance(newIndex);
    }
  };

  const previous = () => {
    if (totalInstances != null) {
      let newIndex = (currentInstanceIndex + totalInstances - 1) % totalInstances;
      onChangeInstance(newIndex);
    }
  };

  if (totalInstances == null) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {totalInstances > 0 ? (
          <FormattedMessage
            id="NUMBER_OF_INSTANCES"
            values={{ current: currentInstanceIndex + 1, total: totalInstances }}
          />
        ) : (
          <FormattedMessage id="NO_MATCHES_FOUND" />
        )}
      </div>
      <div className={styles.toggles}>
        <Toggle
          size="medium"
          variant="ghost"
          icon="AngleDownGrey"
          onClick={next}
          disabled={totalInstances === 0}
          testId="next-instance"
        />
        <Toggle
          size="medium"
          variant="ghost"
          icon="AngleUpGrey"
          onClick={previous}
          disabled={totalInstances === 0}
          testId="previous-instance"
        />
      </div>
      <Divider vertical />
      <Toggle
        size="medium"
        variant="ghost"
        icon="Options"
        margin="0 1rem 0 0.75rem"
        isToggled={moreActions.isOpen}
        disabled={totalInstances === 0}
        testId="more-options"
        {...moreActions.referenceProps}
      />
      <Dropdown {...moreActions.popperProps} testId="more-options-dropdown">
        <Dropdown.Item
          onClick={toggleMatchCase}
          prefixIcon={matchCase ? 'ResolveComment' : undefined}
          testId="match-case-dropdown-item"
        >
          <FormattedMessage id="MATCH_CASE" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={toggleWholeWord}
          prefixIcon={wholeWord ? 'ResolveComment' : undefined}
          testId="find-whole-word-dropdown-item"
        >
          <FormattedMessage id="FIND_WHOLE_WORDS" />
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default FindInstances;
