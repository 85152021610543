import { useIntl } from 'react-intl';
import { Select } from 'dodoc-design-system';
import {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';

export type RoleOption = SelectOption;

export type RoleSelectProps<
  Option extends RoleOption = RoleOption,
  IsMulti extends boolean = false,
> = Omit<
  SelectProps<Option, IsMulti>,
  | 'isMulti'
  | 'menuPosition'
  | 'selectedOptionsGroupLabel'
  | 'listOptionsGroupLabel'
  | 'multiOverflowLabel'
>;

const RoleSelect = <Option extends RoleOption = RoleOption>(
  props: RoleSelectProps<Option, true>,
) => {
  const intl = useIntl();
  return (
    <Select
      {...props}
      isMulti
      menuPosition="fixed"
      selectedOptionsGroupLabel={intl.formatMessage({
        id: 'SELECTED_ROLES',
      })}
      listOptionsGroupLabel={intl.formatMessage({
        id: 'UNSELECTED_ROLES',
      })}
      multiOverflowLabel={intl.formatMessage(
        {
          id: 'ROLES_SELECTED',
        },
        { total: Array.isArray(props.value) ? props.value.length : 0 },
      )}
    />
  );
};

export default RoleSelect;
