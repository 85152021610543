import { CSSProperties, ReactNode } from 'react';
import styles from './SettingGroupHeader.module.scss';

type SettingGroupHeaderProps = {
  style?: CSSProperties;
  actions?: ReactNode;
  children: ReactNode;
};

const SettingGroupHeader = ({ style, actions, children }: SettingGroupHeaderProps) => (
  <div className={styles.header} style={style}>
    {children}
    {actions}
  </div>
);

export default SettingGroupHeader;
