import { useMemo } from 'react';
import { useSelector } from '_common/hooks';
import { dayjs } from 'utils';

type TextChildProps =
  | { text: Presentation.Data.TextShape; zeroWidthSpace?: never; breakLine?: never }
  | { text?: never; zeroWidthSpace: true; breakLine?: never }
  | { text?: never; zeroWidthSpace?: never; breakLine: true };

/**
 * These are the date formats for the following languages:
 *  - English (United Kingdom) - default language
 *  - English (United States)
 */
const DATE_FORMATS = {
  'en-GB': {
    datetime1: 'DD/MM/YYYY',
    datetime2: 'dddd, DD MMMM YYYY',
    datetime3: 'D MMMM, YYYY',
    datetime4: 'D MMMM YYYY',
    datetime5: 'D-MMM-YY',
    datetime6: 'MMMM YY',
    datetime7: 'MMMM-YY',
    datetime8: 'DD/MM/YYYY HH:mm',
    datetime9: 'DD/MM/YYYY HH:mm:ss',
    datetime10: 'HH:mm',
    datetime11: 'HH:mm:ss',
    datetime12: 'h:mm A',
    datetime13: 'h:mm:ss A',
    datetimeyyyy: 'YYYY',
  },
  'en-US': {
    datetime1: 'M/D/YYYY',
    datetime2: 'dddd, MMMM D, YYYY',
    datetime3: 'D MMMM YYYY',
    datetime4: 'D MMMM, YYYY',
    datetime5: 'D-MMM-YY',
    datetime6: 'MMMM YY',
    datetime7: 'MMMM-YY',
    datetime8: 'M/D/YYYY h:mm A',
    datetime9: 'M/D/YYYY h:mm:ss A',
    datetime10: 'HH:mm',
    datetime11: 'HH:mm:ss',
    datetime12: 'h:mm A',
    datetime13: 'h:mm:ss A',
    datetimeyyyy: 'YYYY',
  },
};

const TextChild = ({ text, zeroWidthSpace, breakLine }: TextChildProps) => {
  const currentPageNumber = useSelector((state) => state.presentation.general.currentSlide);

  const content = useMemo(() => {
    if (zeroWidthSpace) {
      return '\u200B';
    }

    if (breakLine) {
      return (
        <>
          <br />
          {'\u200B'}
        </>
      );
    }

    if (text?.type === 'tab') {
      /**
       * Firefox isn't rendering "\t" so this is the solution i found as a workaround with the same visual outcome
       * It has to be 2 &emsp because 1 &emsp = 1/2 tab
       */
      return <>&emsp;&emsp;</>;
    }

    if (text?.type === 'tx_field') {
      switch (text.tx_field_type) {
        case 'slidenum': {
          if (!/^[0-9]*$/.test(text.content)) {
            return currentPageNumber - 1;
          }
          return text.content;
        }
        case 'datetime1':
        case 'datetime2':
        case 'datetime3':
        case 'datetime4':
        case 'datetime5':
        case 'datetime6':
        case 'datetime7':
        case 'datetime8':
        case 'datetime9':
        case 'datetime10':
        case 'datetime11':
        case 'datetime12':
        case 'datetime13':
        case 'datetimeyyyy': {
          const textLanguage = text.properties?.lang;
          const dateLocale =
            textLanguage === 'en-GB' || textLanguage === 'en-US' ? textLanguage : 'en-GB';
          const format = DATE_FORMATS[dateLocale][text.tx_field_type];
          if (format) {
            return dayjs().format(format);
          }
          return text.content;
        }
      }
    }
    return text.content;
  }, [text, currentPageNumber]);

  return content;
};

export default TextChild;
