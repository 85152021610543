import { useState } from 'react';
import { Button, Input, InputField } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { getCabinetList } from 'Editor/redux/CurrentIntegrationSlice';
import {
  selectUserCurrentTenant,
  useGetCurrentUserQuery,
  useGetLinksQuery,
} from '_common/services/api/authority';

import styles from './NewIntegrationLogin.module.scss';

const NewIntegrationLogin = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserQuery();
  const currentTenant = useSelector(selectUserCurrentTenant);

  const { data: links } = useGetLinksQuery(
    { userId: currentUser?.profile.id ?? '', tenant: currentTenant?.name ?? '' },
    { skip: !currentUser?.other.tenants || !currentTenant },
  );

  const [pristine, setPristine] = useState(true);
  const [value, setValue] = useState('');

  const goToLogin = () => {
    setPristine(false);
    if (links) {
      const integrationURL = links.providers?.find((link) => link.name === 'netdocuments');
      if (integrationURL) {
        window.open(integrationURL.url, '_blank');
      }
    }
  };

  const onSubmit = () => {
    dispatch(getCabinetList(value));
  };

  if (pristine) {
    return (
      <div className={styles.root}>
        <div className={styles.message}>
          <FormattedMessage
            id="VALID_NETDOCUMENTS_LOGIN_REQUIRED"
            values={{ integrationName: 'netDocuments' }}
          />
        </div>
        <Button size="medium" variant="primary" margin="0 0 0 auto" onClick={goToLogin} testId="open-netDocuments-button">
          <FormattedMessage id="OPEN_NETDOCUMENTS" values={{ integrationName: 'netDocuments' }} />
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <InputField
        label={intl.formatMessage(
          { id: 'PASTE_NETDOCUMENTS_CODE' },
          { integrationName: 'netDocuments' },
        )}
        testId="netDocuments-code-field"
      >
        <Input
          placeholder={intl.formatMessage({ id: 'INSERT_CODE_HERE' })}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size="medium"
          testId="netDocuments-code"
        />
      </InputField>
      <Button size="medium" variant="primary" margin="2rem 0 0 auto" onClick={onSubmit} testId="submit-button">
        <FormattedMessage id="SUBMIT" />
      </Button>
    </div>
  );
};

export default NewIntegrationLogin;
