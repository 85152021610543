import api from '_common/services/api/api';

const DEFAULT_DAYS = {
  sunday: false,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
} as const;

const SystemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserSettings: builder.query<ApiSchemas['UserSettingsSchema'], void>({
      query: () => ({ url: '/user/settings' }),
      providesTags: [{ type: 'Settings', id: 'user' }],
      transformResponse: (responseData: ApiSchemas['UserSettingsSchema']) => {
        const parsedWorkingHours = { ...responseData.working_hours };
        if (parsedWorkingHours.time.start === null) {
          parsedWorkingHours.time.start = '08:00';
        }
        if (parsedWorkingHours.time.end === null) {
          parsedWorkingHours.time.end = '17:00';
        }
        (
          Object.keys(parsedWorkingHours.days) as Array<keyof typeof parsedWorkingHours.days>
        ).forEach((day) => {
          if (parsedWorkingHours.days[day] === null) {
            parsedWorkingHours.days[day] = DEFAULT_DAYS[day];
          }
        });
        return { ...responseData, working_hours: parsedWorkingHours };
      },
    }),
    editUserSettings: builder.mutation<
      void,
      Partial<ApiOperations['edit_user_settings']['requestBody']['content']['multipart/form-data']>
    >({
      query: (payload) => ({
        url: '/user/settings',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Settings', id: 'user' }],
    }),
  }),
});

export const { useGetUserSettingsQuery, useEditUserSettingsMutation } = SystemApi;

export default SystemApi;
