import { usePublicProfile } from '_common/hooks';
import style from './UsernameLabel.module.scss';

type UsernameLabelProps = {
  userId?: UserId;
  name?: ParsedProfile<UserPublicProfile>['name'];
  displayEmail?: boolean;
};

const UsernameLabel = ({ userId = '', name = '', displayEmail }: UsernameLabelProps) => {
  const profile = usePublicProfile(userId, name);

  if (profile.type === 'removed') {
    return <span className={style.removed}>{profile.name}</span>;
  }

  return displayEmail ? (
    <div className={style.root}>
      {profile.name || name}
      <span className={style.email}>{profile.email}</span>
    </div>
  ) : (
    <>{profile.name || name}</>
  );
};

export default UsernameLabel;
