import { ChangeEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Input, InputField, TextArea } from 'dodoc-design-system';

import styles from './EditableField.module.scss';

export type EditableFieldProps<I extends string = string> = {
  id: I;
  type: 'input' | 'textarea';
  isEditing: boolean;
  value?: string;
  placeholder: string;
  maxChars: number;
  errorLabels: {
    specialChars?: string;
    maxChars?: string;
  };
  onSave: (newValue?: string) => void;
  onEditing: (payload: { id: I; isEditing: boolean }) => void;
  testId: string;
};

const EditableField = <I extends string = string>({
  id,
  type,
  isEditing,
  value,
  placeholder,
  maxChars,
  errorLabels,
  onSave,
  onEditing,
  testId,
}: EditableFieldProps<I>) => {
  const [localValue, setLocalValue] = useState(value);
  const [editing, setEditing] = useState(isEditing);
  const [feedback, setFeedback] = useState<string>();

  useEffect(() => {
    setEditing(isEditing);
  }, [isEditing]);

  useEffect(() => {
    onEditing({ isEditing: editing, id });
  }, [editing, id]);

  const handleStartEdit = () => {
    setEditing(true);
  };

  const handleStopEdit = () => {
    setEditing(false);
  };

  const handleSave = () => {
    onSave(localValue);
    setEditing(false);
  };

  const handleValueChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);

    if (errorLabels.maxChars && newValue.length > maxChars) {
      setFeedback(errorLabels.maxChars);
    } else if (errorLabels.specialChars && /[<>/\\:?*"]/.test(newValue)) {
      setFeedback(errorLabels.specialChars);
    } else {
      setFeedback(undefined);
    }
  };

  const renderField = () => {
    switch (type) {
      case 'input':
        return (
          <Input
            value={localValue}
            onChange={handleValueChange}
            placeholder={placeholder}
            testId={`${testId}-field`}
          />
        );
      case 'textarea':
        return (
          <TextArea
            value={localValue}
            onChange={handleValueChange}
            placeholder={placeholder}
            testId={`${testId}-field`}
          />
        );
    }
  };

  if (editing) {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <InputField feedback={feedback} testId={`${testId}-field`}>
            {renderField()}
          </InputField>
          <div className={styles.buttons}>
            <Button
              size="small"
              variant="neutral"
              onClick={handleStopEdit}
              testId={`${testId}-cancel`}
            >
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              size="small"
              variant="primary"
              onClick={handleSave}
              disabled={localValue === value || !!feedback}
              testId={`${testId}-save`}
            >
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.label}>{value}</div>
      </div>
      <Button
        size="medium"
        variant="link"
        icon="EditDisabled"
        onClick={handleStartEdit}
        testId={`${testId}-edit`}
      />
    </div>
  );
};

export default EditableField;
