import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import ReferenceCardContent, { ReferenceCardProps } from './ReferenceCardContent';

const ReferenceCard = (props: ReferenceCardProps) => {
  return (
    <IntlErrorBoundary fallbackType="card" mockProps={{ sidebar: true, width: '100%' }}>
      <ReferenceCardContent {...props} />
    </IntlErrorBoundary>
  );
};

export default ReferenceCard;
