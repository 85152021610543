import { useSelector } from '_common/hooks';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import { useSuiteObject } from '_common/suite';

const usePresentationCollaborators = () => {
  const object = useSuiteObject();

  const onboardingIsActive = useSelector((state) => state.onboarding.active.presentation);
  const onboardingHasStarted = useSelector((state) => state.onboarding.started.presentation);
  if (object) {
    const collaborators: string[] =
      onboardingIsActive || onboardingHasStarted
        ? [GHOST_USERS.davidBean['id']]
        : [...object.owners, ...(object.shared_with ?? [])];

    return collaborators;
  }

  return [];
};

export default usePresentationCollaborators;
