import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useTextProperties from '../hooks/useTextProperties';
import LinkRun from './LinkRun';
import TextChild from './TextChild';

type RunElementProps = {
  paragraph: Presentation.Data.ParagraphShape;
} & (
  | { run: Presentation.Data.RunShape; zeroWidthSpace?: never }
  | { run?: never; zeroWidthSpace: true }
);

const RunElement = ({ paragraph, run, zeroWidthSpace }: RunElementProps) => {
  const { addUnsupportedElement } = useSlideData();
  const { getTextStyle } = useTextProperties();
  const style = getTextStyle({ paragraph, run });

  //TODO:PRESENTATION:UNSUPPORTED
  if (run?.type === 'm') {
    addUnsupportedElement('Equation');
    return null;
  }

  if (run?.type === 'ln_br') {
    return (
      <span style={style} data-type="run">
        <TextChild breakLine />
      </span>
    );
  }

  if (run?.type === 'tx_field') {
    return (
      <span style={style} data-type="run">
        <TextChild text={run} />
      </span>
    );
  }

  if (zeroWidthSpace) {
    return (
      <span style={style} data-type="run">
        <TextChild zeroWidthSpace />
      </span>
    );
  }
  if (run.properties?.hlinkClick || run.properties?.hlinkMouseOver) {
    return (
      // This element will have data-type="run" to allow selection to work properly
      <LinkRun
        paragraph={paragraph}
        run={run}
        clickLink={run.properties?.hlinkClick}
        hoverLink={run.properties?.hlinkMouseOver}
      />
    );
  }
  return (
    <span style={style} data-type="run">
      {run?.childNodes?.map((text, index) => (
        <TextChild key={index} text={text} />
      ))}
    </span>
  );
};

export default RunElement;
