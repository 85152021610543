import { TableUtils } from '../../Utils/TableUtils';
import { BaseOperation } from '../BaseOperation';

export class UpdateTableSizeOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  height: number;
  width: Editor.Data.Node.TableWidth;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    height: number,
    width: Editor.Data.Node.TableWidth,
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.height = height;
    this.width = width;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const tableInfo = this.model.getChildInfoById(this.tableId);
    const table: Editor.Data.Node.Data = tableInfo.data;
    const tableBody = table.childNodes?.[0];
    const rowsData = tableBody?.childNodes;

    const op = this.getObjectOperationforPathValue(table.properties?.w, this.width, [
      ...tableInfo.path,
      this.model.KEYS.PROPERTIES,
      'w',
    ]);
    if (op) {
      this.ops.push(op);
    }

    const opAR = this.getObjectOperationforPathValue(table.properties?.ar, false, [
      ...tableInfo.path,
      this.model.KEYS.PROPERTIES,
      'ar',
    ]);
    if (opAR) {
      this.ops.push(opAR);
    }

    // update height
    let actualHeight: number = 0;

    if (rowsData) {
      const rowsDataLength = rowsData.length;

      for (let i = 0; i < rowsDataLength; i++) {
        actualHeight =
          actualHeight + +(rowsData[i].properties?.rh || TableUtils.DEFAULT_ROW_HEIGHT);
      }

      const heightDiffRatio = this.height / actualHeight;

      for (let i = 0; i < rowsDataLength; i++) {
        let rh = rowsData[i].properties?.rh;

        let newHeight: number;
        if (rh != null && +rh !== 0) {
          newHeight = +(+rh * heightDiffRatio).toFixed(2);
        } else {
          newHeight = +(TableUtils.DEFAULT_ROW_HEIGHT * heightDiffRatio).toFixed(2);
        }

        const op = this.getObjectOperationforPathValue(rh, newHeight, [
          ...tableInfo.path,
          this.model.KEYS.CHILDNODES,
          '0',
          this.model.KEYS.CHILDNODES,
          `${i}`,
          this.model.KEYS.PROPERTIES,
          'rh',
        ]);

        if (op) {
          this.ops.push(op);
        }
      }
    }

    return this;
  }
}
