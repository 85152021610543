import { useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import { Button, Modal, Tooltip, Checkbox, Icon } from 'dodoc-design-system';
import {
  createUser,
  editUser,
} from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';

import { FIELD_LIMIT } from 'Settings/pages/TenantSettingsPage/Users/constants';

import Field from './Field/Field';

import style from './UserManagementFormModal.module.scss';

import type { FieldProps } from './Field/Field';
import type { UserCreationParams } from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';

const MODAL = 'UserManagementFormModal';
const FORM_FIELDS: Record<string, FieldProps['id']> = {
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  EMAIL: 'email',
};

export const TEST_IDS = {
  firstName: 'first-name',
  lastName: 'last-name',
  email: 'email',
  admin: 'admin-checkbox',
  cta: 'cta-button',
  cancel: 'cancel-button',
};

const UserManagementFormModal = () => {
  const dispatch = useDispatch();
  // redux
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const title = useSelector((state) => state.modals[MODAL].title);
  const submit = useSelector((state) => state.modals[MODAL].submit);
  const user = useSelector((state) => state.modals[MODAL].user);

  //local
  const [fields, setFields] = useState<
    Record<FieldProps['id'], { value: string; error: boolean; changed: boolean }>
  >({
    firstName: { value: '', error: true, changed: false },
    lastName: { value: '', error: true, changed: false },
    email: { value: '', error: true, changed: false },
  });
  const [isFormValid, setIsFormValid] = useState(false);

  // Checks if the form is valid to be submitted every time a field is changed
  useEffect(() => {
    let errors = 0;
    let changed = 0;

    Object.values(fields).forEach((field) => {
      if (field.error) {
        errors += 1;
      }
      if (field.changed) {
        changed += 1;
      }
    });

    if (errors < 1 && changed > 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [fields]);

  useEffect(() => {
    if (user?.email) {
      const editFields = { ...fields, fields };
      editFields.firstName.error = false;
      editFields.firstName.value = user.first_name;
      editFields.lastName.error = false;
      editFields.lastName.value = user.last_name;
      editFields.email.error = false;
      editFields.email.value = user.email;

      setFields(editFields);
    }
  }, [user]);

  const close = () => {
    setFields({
      firstName: { value: '', error: true, changed: false },
      lastName: { value: '', error: true, changed: false },
      email: { value: '', error: true, changed: false },
    });
    dispatch(closeAndResetModal(MODAL));
  };

  // Handles the value change of a field
  const handleChange: FieldProps['onChange'] = (id, value, error, changed) => {
    setFields((state) => ({
      ...state,
      [id]: { value, error, changed },
    }));
  };

  // Handles the form submition
  const handleSubmit = () => {
    const params: UserCreationParams = {
      email: fields.email.value,
      first_name: fields.firstName.value,
      last_name: fields.lastName.value,
    };

    if (user?.id) {
      dispatch(editUser({ userId: `${user.id}`, params }));
    } else {
      dispatch(createUser({ params }));
    }

    close();
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={close}
      width="60rem"
      testId={user?.email ? 'userManagementForm-edit' : 'userManagementForm-add'}
    >
      <Modal.Header onClose={close}>
        <FormattedMessage id={title} />
      </Modal.Header>
      <Modal.Body>
        <div className={`${style.body}`}>
          <div className={style.content}>
            <div className={`${style.align} ${style.between}`}>
              <div className={style.field}>
                <Field
                  id={FORM_FIELDS.FIRSTNAME}
                  label="FIRST_NAME"
                  placeholder="INSERT_FIRST_NAME"
                  feedback={{
                    id: 'EXCEEDED_MAX_CHARACTERS',
                    values: { max: FIELD_LIMIT },
                  }}
                  value={fields['firstName'].value}
                  editValue={user?.first_name}
                  limit={FIELD_LIMIT}
                  onChange={handleChange}
                  testId={TEST_IDS.firstName}
                />
              </div>
              <div className={style.field}>
                <Field
                  id={FORM_FIELDS.LASTNAME}
                  label="LAST_NAME"
                  placeholder="INSERT_LAST_NAME"
                  feedback={{
                    id: 'EXCEEDED_MAX_CHARACTERS',
                    values: { max: FIELD_LIMIT },
                  }}
                  value={fields['lastName'].value}
                  editValue={user?.last_name}
                  limit={FIELD_LIMIT}
                  onChange={handleChange}
                  testId={TEST_IDS.lastName}
                />
              </div>
            </div>
            <div className={style.field}>
              <Field
                id={FORM_FIELDS.EMAIL}
                type="email"
                label="EMAIL_ADDRESS"
                placeholder="EMAIL_ADDRESS_ASSOCIATED_USER"
                feedback={{
                  id: 'INVALID_EMAIL_ADDRESS',
                }}
                value={fields['email'].value}
                editValue={user?.email}
                onChange={handleChange}
                testId={TEST_IDS.email}
              />
            </div>
            {user && (
              <div className={`${style.align} ${style.start} ${style.admin}`}>
                <Tooltip
                  content={<FormattedMessage id="ADMIN_PERMISSION_GRANTED_ON_DJANGO" />}
                  placement="top"
                  testId="admin-permission-information-tooltip"
                >
                  <div className={`${style.align} ${style.start}`}>
                    <Checkbox
                      disabled
                      checked={user?.is_admin || user?.is_superuser ? 'checked' : 'unchecked'}
                      size="small"
                      testId={TEST_IDS.admin}
                    />

                    <div className={style.label}>
                      <FormattedMessage id="ADMINISTRATOR_PERMISSION" />
                    </div>
                    <Icon icon="InformationBlue" size={16} />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer alignment="flex-end">
        <Button size="medium" onClick={close} testId={TEST_IDS.cancel}>
          <FormattedMessage id={'global.cancel'} />
        </Button>
        <Button
          size="medium"
          variant="primary"
          testId={TEST_IDS.cta}
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          <FormattedMessage id={submit} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserManagementFormModal;
