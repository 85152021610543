import { FormattedMessage } from 'react-intl';

import { PanelContent, PanelHeading } from '_common/suite/components';
import PermissionsPanel from './PermissionsPanel/PermissionsPanel';

const ContentTab = () => {
  return (
    <>
      <PanelHeading>
        <FormattedMessage id="CONTENT_PERMISSIONS" />
      </PanelHeading>
      <PanelContent testId="content-permissions-panel">
        <PermissionsPanel />
      </PanelContent>
    </>
  );
};

export default ContentTab;
