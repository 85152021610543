import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'utils/dayjs';
import { EmptyCardListState, Switch } from 'dodoc-design-system';

import { useDispatch, usePublicProfiles, useSelector } from '_common/hooks';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import {
  selectPDFAnnotations,
  selectFilteredPDFAnnotations,
  selectAnnotationsAuthors,
  selectAllPDFAnnotations,
  toggleAnnotationsListMode,
} from 'PDF/redux/PDFAnnotationsSlice';
import { VirtualizedList } from '_common/suite/components/Card';
import { VirtualizedListProps } from '_common/suite/components/Card/VirtualizedList/VirtualizedList';

import Header from '../Header/Header';
import Search from './Search/Search';
import AnnotationCard from './AnnotationCard/AnnotationCard';

import panelStyles from '../RightSidePanel.module.scss';
import styles from './AnnotationsPanel.module.scss';
import usePDFData from 'PDF/hooks/usePDFData';
import { EmptyFilteredState, FilterDisplay } from '_common/components';
import { useMemo } from 'react';
import { CommentFilters } from '_common/suite/components';
import { annotationBelongsToGroupType } from 'PDF/redux/PDFAnnotationsSlice';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import type { UserOption } from '_common/components/SearchUser/SearchUser';
import { useTotalMatchedFilters } from '_common/components/Filters';

const Annotations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = usePDFData();
  const annotationList = useSelector(selectPDFAnnotations);
  const allAnnotationList = useSelector(selectAllPDFAnnotations);
  const annotationsFilteredList = useSelector(selectFilteredPDFAnnotations);
  const selectedAnnotation = useSelector((state) => state.pdf.annotations.selected);
  const searchBy = useSelector((state) => state.pdf.annotations.searchBy);
  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.reviewerCommentPanel),
  );
  const totalAnnotationsFilters = useTotalMatchedFilters({ identity: 'reviewerCommentPanel' });
  const isListMode = useSelector((state) => state.pdf.annotations.annotationsListMode);

  const { data: userProfile } = useGetCurrentUserQuery();
  const users = useSelector(selectAnnotationsAuthors);
  const { profiles } = usePublicProfiles(users.map((user) => user.id));

  const selectedAnnotationIndex = useMemo(
    () =>
      selectedAnnotation
        ? annotationsFilteredList.list.findIndex((itId) => itId === selectedAnnotation)
        : -1,
    [selectedAnnotation],
  );

  const userOptions = useMemo(() => {
    const options: UserOption[] = [];

    users.forEach((user) => {
      const option: UserOption = {
        type: 'user',
        name: '',
        label: '',
        id: '',
        value: '',
      };
      if (user.imported) {
        option.label = user.id;
        option.id = 'IMPORTED_USER';
        option.imported = true;
      } else {
        option.label = profiles[user.id]?.name;
        option.email = profiles[user.id]?.email;
        option.id = user.id;
      }
      option.value = user.id;
      options.push(option);
    });

    return options;
  }, [users, profiles]);

  const annotationsOrder = useMemo(() => {
    const order: { [x in PDF.Annotation.Task['id']]: number } = {};
    allAnnotationList
      .sort((a, b) => (dayjs(a.creationDate).isAfter(b.creationDate) ? 1 : -1))
      .forEach((annotation, i) => {
        order[annotation.id] = i + 1;
      });
    return order;
  }, [allAnnotationList]);

  const toggleListMode = () => {
    dispatch(toggleAnnotationsListMode());
  };

  if (!object || !userProfile) {
    return null;
  }

  const renderEmptyState = () => {
    if (searchBy) {
      return (
        <div className={styles.emptyStateSearchBy} data-testid="sidebar-comments-empty-search">
          <FormattedMessage id="NO_MATCHES_FOUND" />
        </div>
      );
    }

    return <EmptyFilteredState identity="reviewerCommentPanel" size="medium" />;
  };

  const itemRenderer: VirtualizedListProps['itemRenderer'] = ({ index, measure }) => {
    const annotationId = annotationsFilteredList.list[index];

    return (
      <AnnotationCard
        annotation={annotationsFilteredList.annotations[annotationId]}
        sidebar
        order={annotationsOrder[annotationId]}
        measure={measure}
        testId={`sidebar-comment-${annotationId}`}
      />
    );
  };

  return (
    <>
      <Header>
        <FormattedMessage id="COMMENTS" />
        <CommentFilters
          categories={{
            commentStatus: {
              options: [
                {
                  value: 'Completed',
                  label: 'editor.sidebar.review.filter.status.resolved',
                  labelNumber: allAnnotationList.filter(
                    (annotation) => annotation.state === 'Completed',
                  ).length,
                },
                {
                  value: 'Cancelled',
                  label: 'editor.sidebar.review.filter.status.deleted',
                  labelNumber: allAnnotationList.filter(
                    (annotation) => annotation.state === 'Cancelled',
                  ).length,
                },
              ],
            },
            commentType: {
              options: [
                {
                  value: 'comment',
                  label: 'COMMENTS',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('comment', annotation),
                  ).length,
                },
                {
                  value: 'freehand',
                  label: 'FREEHAND',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('freehand', annotation),
                  ).length,
                },
                {
                  value: 'highlights_notes',
                  label: 'HIGHLIGHTS_AND_NOTES',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('highlights_notes', annotation),
                  ).length,
                },
                {
                  value: 'shapes',
                  label: 'SHAPES',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('shapes', annotation),
                  ).length,
                },
                {
                  value: 'textbox',
                  label: 'TEXT_BOX',
                  labelNumber: allAnnotationList.filter((annotation) =>
                    annotationBelongsToGroupType('textbox', annotation),
                  ).length,
                },
              ],
            },
            cardPriority: {
              options: [
                { value: 'Low', label: 'editor.sidebar.review.filter.priority.low' },
                { value: 'Medium', label: 'editor.sidebar.review.filter.priority.medium' },
                { value: 'High', label: 'editor.sidebar.review.filter.priority.high' },
              ],
            },
            author: { options: userOptions, settings: { editorAvatar: true } },
          }}
        />
      </Header>
      <div className={panelStyles.content}>
        {annotationList.length === 0 && !hasActiveFilters ? (
          <div className={styles.emptyState} data-testid="no-comments-yet">
            <EmptyCardListState size="medium" testId="no-comments-empty-card-list-state">
              <FormattedMessage id="NO_COMMENTS_YET" />
            </EmptyCardListState>
          </div>
        ) : (
          <>
            <Search />
            <div
              className={styles.annotationsControl}
              style={{ paddingBottom: hasActiveFilters ? '2rem' : '1rem' }}
            >
              <div className={styles.annotationsCounter}>
                {totalAnnotationsFilters > 0 || searchBy ? (
                  <FormattedMessage
                    id="Y_OF_X_COMMENTS"
                    values={{
                      value: annotationsFilteredList.list?.length,
                      total: allAnnotationList.length,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="X_COMMENTS_IN_THE_DOCUMENT"
                    values={{ total: annotationList.length }}
                  />
                )}
              </div>
              <Switch
                size="medium"
                labelPlacement="left"
                onChange={toggleListMode}
                active={isListMode}
                testId={`tasks-panel-list-mode-switch`}
              >
                {intl.formatMessage({ id: 'LIST_MODE' })}
              </Switch>
            </div>
            <FilterDisplay
              identity="reviewerCommentPanel"
              direction="column"
              margin="0 0 1rem 1rem"
            />
            <div className={styles.annotationsList} data-testid="sidebar-comments-list">
              {annotationsFilteredList.list.length === 0 ? (
                renderEmptyState()
              ) : isListMode ? (
                <VirtualizedList
                  itemCount={annotationsFilteredList.list.length}
                  selectedIndex={selectedAnnotationIndex}
                  itemMinHeight={40}
                  itemRenderer={itemRenderer}
                />
              ) : (
                <VirtualizedList
                  itemCount={annotationsFilteredList.list.length}
                  selectedIndex={selectedAnnotationIndex}
                  itemMinHeight={138}
                  itemRenderer={itemRenderer}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Annotations;
