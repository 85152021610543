import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { uniq } from 'lodash';
import { Link, Toggle, Tooltip } from 'dodoc-design-system';

import { usePublicProfiles, useSelector } from '_common/hooks';

import styles from './WatchToggle.module.scss';

type WatchToggleProps = {
  authorId: Card.Task['authorId'];
  assignee: Card.Task['assignee'] | undefined;
  watchers: Card.Task.Watchers;
  disabled: boolean;
  onWatch: () => void;
  openWatchModal: () => void;
  testId: string;
};

const WatchToggle = ({
  authorId,
  assignee,
  watchers,
  disabled,
  testId,
  onWatch,
  openWatchModal,
}: WatchToggleProps) => {
  const intl = useIntl();

  const taskWatchers = useMemo(() => {
    const taskWatchers = [...watchers];
    if (authorId) {
      taskWatchers.push(authorId);
    }

    if (assignee) {
      taskWatchers.push(assignee);
    }

    return uniq(taskWatchers);
  }, [watchers, authorId, assignee]);

  const currentUserId = useSelector((state) => state.auth.userId);
  const { profiles } = usePublicProfiles(taskWatchers.length === 1 ? taskWatchers : undefined);

  const tooltipContent = useMemo(() => {
    if (taskWatchers.length === 1) {
      if (taskWatchers.includes(currentUserId)) {
        return intl.formatMessage({ id: 'YOU_ARE_WATCHING_THE_TASK' });
      }

      return intl.formatMessage(
        { id: 'TASK_ONE_WATCHER' },
        { data: profiles[taskWatchers[0]]?.name },
      );
    } else if (taskWatchers.includes(currentUserId)) {
      return intl.formatMessage(
        { id: 'TASK_YOU_AND_OTHER_WATCHERS' },
        { data: taskWatchers.length - 1 },
      );
    } else {
      return intl.formatMessage({ id: 'TASK_MANY_WATCHERS' }, { data: taskWatchers.length });
    }
  }, [taskWatchers, profiles, currentUserId]);

  const handleOnWatch = useCallback(() => {
    if (currentUserId === authorId || currentUserId === assignee) {
      return;
    }

    onWatch();
  }, [currentUserId, authorId, assignee, onWatch]);

  return (
    <div className={styles.root}>
      <Toggle
        size="medium"
        variant="link"
        icon={taskWatchers?.includes(currentUserId) ? 'WatcherBlue' : 'WatcherGrey'}
        isToggled={taskWatchers?.includes(currentUserId)}
        onClick={handleOnWatch}
        disabled={disabled}
        testId={`${testId}-watch-toggle`}
      />
      {taskWatchers.length > 0 && (
        <Tooltip placement="top" content={tooltipContent} testId={`${testId}-watchers-tooltip`}>
          <span className={styles.counter}>
            <Link
              variant="neutral"
              size="medium"
              onClick={openWatchModal}
              testId={`${testId}-watchers-link`}
            >
              {taskWatchers.length}
            </Link>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default WatchToggle;
