import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch, Tooltip, Icon } from 'dodoc-design-system';

import getConfig from 'dodoc.config';
import { useSelector } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import EditorManager from 'Editor/services/EditorManager';

import { SuiteAvatar } from '_common/suite/components';

import styles from './UsersList.module.scss';

const UsersList = () => {
  const intl = useIntl();
  const object = useSuiteObject();

  const { byUser, owners, users } = useSelector((state) => state.editor.permissions.summary);
  const selected = useSelector((state) => state.editor.permissions.selected);
  const loggedUserId = useSelector((state) => state.auth.userId);

  const ICONS = getConfig().editor.blockPermissions;
  const [isUserListOpen, setIsUserListOpen] = useState(false);

  const manager = EditorManager.getInstance();

  const handleToggleUserListPopover = () => {
    setIsUserListOpen(!isUserListOpen);
  };

  const addUser = (userId: UserId) => {
    const permissionsList = Object.keys(ICONS);
    const permissions = Object.keys(users[userId]).filter((permission) =>
      permissionsList.includes(permission),
    );
    manager.addBlockPermission(selected, userId, permissions);
    handleToggleUserListPopover();
  };

  const removeUser = (userId: UserId) => {
    manager.removeUserFromBlock(selected, userId);
  };

  const addPermission = (userId: UserId, permission?: keyof Permission.Block) => {
    if (hasAddPermissions(userId, permission)) {
      manager.addBlockPermission(selected, userId, permission);
    } else {
      // TODO: notify no permissions
    }
  };

  const removePermission = (userId: UserId, permission?: keyof Permission.Block) => {
    if (hasRemovePermissions(userId, permission)) {
      manager.removeBlockPermission(selected, userId, permission);
    } else {
      // TODO: notify no permissions
    }
  };

  const hasAddPermissions = (userId: UserId, permission?: keyof Permission.Block) => {
    if (
      userId &&
      (userId === object.owner || userId === loggedUserId || userId === owners[userId])
    ) {
      return false;
    }

    const addPermission = object.user_permissions.some(
      (perm) => perm === 'admin' || perm === 'owner' || perm === 'add_permission',
    );

    if (permission) {
      let loggedUserGranularPermission;

      if (byUser[loggedUserId]) {
        loggedUserGranularPermission = byUser[loggedUserId][permission];
      } else {
        loggedUserGranularPermission = object.user_permissions.some(
          (perm) => perm === 'owner' || perm === permission,
        );
      }

      return addPermission && !!loggedUserGranularPermission;
    }

    return addPermission;
  };

  const hasRemovePermissions = (userId: UserId, permission?: keyof Permission.Block) => {
    if (
      userId &&
      (userId === object.owner || userId === loggedUserId || userId === owners[userId])
    ) {
      return false;
    }

    const removePermission = object.user_permissions.some(
      (perm) => perm === 'admin' || perm === 'owner' || perm === 'remove_permission',
    );

    if (permission) {
      let loggedUserGranularPermission;

      if (byUser[loggedUserId]) {
        loggedUserGranularPermission = byUser[loggedUserId][permission];
      } else {
        loggedUserGranularPermission = object.user_permissions.some(
          (perm) => perm === 'owner' || perm === permission,
        );
      }

      return removePermission && !!loggedUserGranularPermission;
    }

    return removePermission;
  };

  const hasGranularPermission = (userId: UserId) => {
    if (!userId) {
      return false;
    }

    return byUser[userId] && byUser[userId].length === selected.length;
  };

  const renderOverlay = (
    isOwner: boolean,
    warning: boolean,
    noPermission: boolean,
    userId: UserId,
    permission: keyof Permission.Block,
    hasGranular: boolean,
  ) => {
    if (isOwner || !hasGranular) {
      return null;
    }

    if (warning || noPermission) {
      if (hasAddPermissions(userId, permission)) {
        return (
          <span
            className={styles.actionIcon}
            onClick={() => addPermission(userId, permission)}
            data-testid={`add-${permission}-permission-${userId}`}
          >
            <Icon icon="EditorAdd" size={24} />
          </span>
        );
      }
    } else if (hasRemovePermissions(userId, permission)) {
      return (
        <span
          className={styles.actionIcon}
          onClick={() => removePermission(userId, permission)}
          data-testid={`remove-${permission}-permission-${userId}`}
        >
          <Icon icon="EditorRemove" size={24} />
        </span>
      );
    }

    return null;
  };

  const renderIcon = (permission: Exclude<keyof Permission.Block, 'length'>, userId: UserId) => {
    const isOwner = userId === object.owner || !!owners[userId];
    const hasGranular = hasGranularPermission(userId);

    const disabled =
      isOwner ||
      !hasGranular ||
      !(hasAddPermissions(userId, permission) || hasRemovePermissions(userId, permission));
    // !(
    //   (!byUser[user][permission] && hasAddPermissions(user, permission)) ||
    //   (byUser[user][permission] && hasRemovePermissions(user, permission))
    // );

    const warning =
      isOwner || !hasGranular
        ? false
        : byUser[userId][permission] !== selected.length && byUser[userId][permission] !== 0;

    let noPermission;
    if (isOwner) {
      noPermission = false;
    } else if (hasGranular) {
      noPermission = byUser[userId][permission] === 0;
    } else {
      noPermission = !users[userId][permission];
    }

    return (
      <div
        className={`${styles.icon} ${disabled ? styles.disabled : ''}`}
        data-testid={`${permission}-permission-${userId}`}
      >
        <Tooltip
          content={intl.formatMessage({
            id: `editor.sidebar.permissions.${permission}`,
          })}
          testId={`content-permissions-${userId}-${permission}-permission-tooltip`}
        >
          <span>
            <span
              className={`
          ${styles.icon}
          ${noPermission ? styles.gray : ''}
          ${warning ? styles.warning : ''}
        `}
            >
              <Icon
                icon={noPermission ? `${ICONS[permission]}Grey` : `${ICONS[permission]}Blue`}
                size={24}
              />
            </span>

            {renderOverlay(isOwner, warning, noPermission, userId, permission, hasGranular)}

            {warning && (
              <span
                className={styles.warningSign}
                data-testid={`warning-${permission}-permission-${userId}`}
              >
                <Icon icon="Warning" size={24} />
              </span>
            )}
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.userRow}>
        <div style={{ display: 'flex' }} className={styles.gray}>
          <SuiteAvatar userId={object.owner} margin="0 3rem 0 0" showTooltip />
          {renderIcon('access', object.owner)}
          {renderIcon('edit', object.owner)}
          {renderIcon('comment', object.owner)}
          {renderIcon('approve', object.owner)}
        </div>
      </div>
      {Object.keys(owners).map((userId) => (
        <div key={userId} className={styles.userRow}>
          <div style={{ display: 'flex' }} className={styles.gray}>
            <SuiteAvatar userId={userId} margin="0 3rem 0 0" showTooltip />
            {renderIcon('access', userId)}
            {renderIcon('edit', userId)}
            {renderIcon('comment', userId)}
            {renderIcon('approve', userId)}
          </div>
        </div>
      ))}
      {Object.keys(users).map((userId) => {
        const hasPermission = hasAddPermissions(userId) || hasRemovePermissions(userId);
        const hasGranular = hasGranularPermission(userId);

        return (
          <div key={userId} className={styles.userRow}>
            <div style={{ display: 'flex' }} className={!hasGranular ? styles.gray : ''}>
              <SuiteAvatar userId={userId} margin="0 3rem 0 0" showTooltip />
              {renderIcon('access', userId)}
              {renderIcon('edit', userId)}
              {renderIcon('comment', userId)}
              {renderIcon('approve', userId)}
            </div>
            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              {hasPermission && (
                <Tooltip
                  content={
                    hasGranular
                      ? intl.formatMessage({ id: 'DISABLE_EDITING' })
                      : intl.formatMessage({ id: 'ENABLE_EDITING' })
                  }
                  testId={`${hasGranular ? 'disable-editing' : 'enable-editing'}-tooltip`}
                >
                  <div>
                    <Switch
                      disabled={
                        hasGranular &&
                        object.user_permissions.includes('add_permission') &&
                        !object.user_permissions.includes('remove_permission')
                      }
                      active={hasGranular}
                      onChange={hasGranular ? () => removeUser(userId) : () => addUser(userId)}
                      testId={`${hasGranular ? 'disable-editing' : 'enable-editing'}-switch`}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles.disclaimer}>
        <FormattedMessage id="global.modals.delete.note" />
        <FormattedMessage id="NOTE_CUSTOMIZE_PERMISSIONS" />
      </div>
    </div>
  );
};

export default UsersList;
