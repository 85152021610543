import { Modal, Button } from 'dodoc-design-system';
import EditorManager from 'Editor/services/EditorManager';
import { useSelector, useDispatch } from '_common/hooks/redux';
import { useParams } from 'react-router';
import { closeModal } from '_common/modals/ModalsSlice';

const EditorFailedConnection = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open.EditorErrorModal);
  const title = useSelector((state) => state.modals.EditorErrorModal.title);
  const message = useSelector((state) => state.modals.EditorErrorModal.message);

  const { id }: { id: ObjectId } = useParams();
  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector((state) => state.localStorage.accounts);
  const token = accounts[userId]?.token;

  const close = () => {
    dispatch(closeModal('EditorErrorModal'));
  };
  const reconnect = () => {
    // window.location.reload(false);
    close();
    EditorManager.getInstance().reloadEditorManager(id, {
      id: userId,
      token: token,
    });
  };

  return (
    <Modal width="75rem" open={!!isOpen} testId="failed-connection">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button size="medium" variant="primary" onClick={reconnect} testId={`${title === 'Disconnected' ? 'reconnect' : 'try-again'}-button`}>
          {title === 'Disconnected' ? 'Reconnect' : 'Try again'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditorFailedConnection;
