import { dayjs } from 'utils';

const useFormatCode = () => {
  //There are edge cases of date formats that dont have a direct correspondence with dayjs
  const DATE_FORMATS: Record<string, string> = {
    'dddd, mmmm dd, yyyy': 'DD MMMM YYYY', //not sure if correct
    mmmmm: 'MMM', //This has a limitation: 'December' should be formatted to 'D' but in dayjs it will be 'Dec'.
  };

  const serialDateNumberToUnixEpoch = (number: number) => {
    return (number - (25567 + 2)) * 86400 * 1000;
  };

  const getDateFormat = ({ formatCode, value }: { formatCode: string; value?: string }) => {
    let isDate = false;
    let formattedDate: string | undefined = undefined;

    const [positiveFormat] = formatCode.split(';');

    const dateFormatMatch = positiveFormat.replace(/\[([$-](?=[^\]]*))[^\]]*\]|\\+/g, '');

    //Save the time AM/PM pattern
    const dateAMPM = dateFormatMatch.match(/AM\/PM/)?.[0];

    //Save the original hour and minute pattern
    const dateFormatTime = dateFormatMatch.match(/([hHmsS]+:[hHmsS]+)\b/)?.[0];

    //Save the original seconds pattern
    const dateFormatTimeSeconds = dateFormatMatch.match(/(:[sS]+)/)?.[0];

    let dateFormat =
      DATE_FORMATS[dateFormatMatch] ??
      /*
       * Transform the dateFormat to uppercase so that it corresponds to a dayjs format.
       * But the time pattern has to be the same case as the original
       */
      dateFormatMatch.toUpperCase().replace(/([hHmMsS]+:[hHmMsS]+)\b/, dateFormatTime ?? '');

    /*
     * If the time isnt in AM/PM format, transform the time pattern to uppercase, except the seconds.
     * In dayjs, uppercase time its in 00-23 format, lowercase its 1-12
     * The seconds are 's' (0-59), or 'ss' (00-59), or 'SSS' (000-999).
     * Otherwise replace the "AM/PM" with "A", so that dayjs presents the "AM" or "PM"
     */
    if (!dateAMPM) {
      dateFormat = dateFormat.replace(/([hHm]+:[hHm]+)\b/, (match) => {
        return match.toUpperCase();
      });
    } else {
      dateFormat = dateFormat.replace(dateAMPM, 'A');
    }

    //The seconds in dayjs are 's' (0-59), or 'ss' (00-59), or 'SSS' (000-999).
    if (dateFormatTimeSeconds?.replace(':', '').length === 3) {
      dateFormat = dateFormat.replace(/:[sS]+/, (match) => {
        return match.toUpperCase();
      });
    } else {
      dateFormat = dateFormat.replace(/:[sS]+/, dateFormatTimeSeconds ?? '');
    }

    //The minutes in dayjs are 'm' ('0-59) or 'mm' (00-59), so they are always lowercase
    dateFormat = dateFormat.replace(/:[M]+/, (match) => {
      return match.toLocaleLowerCase();
    });

    //ex: [h]:mm, replace the '[' and ']' with nothing. By doing this we are not supporting elapsed time
    dateFormat = dateFormat.replace(/\[|\]/g, (match) => {
      return '';
    });

    const dateNumber = value
      ? serialDateNumberToUnixEpoch(parseFloat(value))
      : new Date('1').getTime();
    formattedDate = dayjs(dateNumber).utc().format(dateFormat);
    const isValidDate = dayjs(formattedDate, dateFormat).isValid();
    isDate =
      !!DATE_FORMATS[dateFormatMatch] ||
      (isValidDate && formattedDate !== '0' && !formattedDate.includes('#'));

    if (!isDate) {
      return undefined;
    }

    return {
      format: dateFormat,
      formattedValue: { content: value ? formattedDate : undefined },
    };
  };

  const formatValue = (
    value: string,
    formatCode: string | undefined,
  ): { content: string; color: string | undefined } => {
    /*
     * This function its specifically made to only proccess some of the format codes that are pre-defined by ppt
     * This is a very limited way to proccess the format code
     * This function isnt able to proccess all kind of format codes
     * If a custom format code is sent as argument the output will most likely be wrong
     * Limitations:
     *  - Has very little support for custom formats. Some custom formats might work, but most wont.
     *  - Date, Time:
     *      - Only guaranteed support for UK and USA languages. Others can work, but might fail as well
     *      - Date type 'M', will display, for example, "Dec" instead of "D"
     *
     */

    if (!formatCode || formatCode === 'General') {
      return {
        content: value,
        color: undefined,
      };
    }

    const [positiveFormat, negativeFormat] = formatCode.split(';');

    const formattedDate = getDateFormat({ formatCode, value })?.formattedValue.content;
    let numberValue = parseFloat(value);

    // If the number is negative and there is a negative format defined, follow the negative format
    // Otherwise follow the positive format
    const positivityFormat = numberValue < 0 && negativeFormat ? negativeFormat : positiveFormat;

    // Determine the number of decimal places
    const decimalPlaces =
      (positivityFormat.split('.')[1] || '').match(/\d+/g)?.join('').length ?? 0;

    // Determine whether to use thousands separator
    const useThousandsSeparator = positivityFormat.includes(',');

    // Determine the color for negative numbers
    const negativeColor = negativeFormat ? negativeFormat.match(/\[(\w+)\]/)?.[1] : undefined;

    //Percentage values
    const isPercentage = !!positivityFormat.match(/%/);
    const percentageIndicator = isPercentage ? '%' : '';
    if (isPercentage) {
      numberValue *= 100;
    }

    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    };
    if (useThousandsSeparator) {
      options.useGrouping = true;
    } else {
      options.useGrouping = false;
    }

    // Format the number
    const isAbsoluteValue: boolean = negativeFormat != null && !negativeFormat.match(/\\-/);
    let formattedNumber = new Intl.NumberFormat('en-US', options).format(numberValue);
    formattedNumber = `${formattedNumber}`.replace('-', '');
    let negativeIndicator = '';

    if (numberValue < 0 && !isAbsoluteValue) {
      negativeIndicator = '-';
    }

    // Determine the color
    const color = numberValue < 0 ? negativeColor : undefined;

    //#region Concat text, symbols, currency, indicators
    /*
     * This logic is very limited, it doesnt allow to have these multiple values at the same time
     * nor with dynamic positions in the text. Probably there are even more problems with this.
     * But, this seems to be enough to proccess the configurations of the format code that
     * weren't set manually by the user, which is what we only want to support (at least for now).
     */

    formattedNumber += percentageIndicator;

    // Check for currency (a pattern like this example [$₿-x-xbt1])
    const currencyMatch = positiveFormat.match(/\$\s*([^\-[\]]+)(?=-|\])/);
    const currencyValue = currencyMatch?.[1];
    const currencyIndex = currencyMatch?.index ?? 0;

    //Check for text (anything inside "")
    const textMatch = positiveFormat.match(/"([^"]*)"/);
    const textValue = textMatch?.[1];
    const textIndex = textMatch?.index ?? 0;

    //Check for symbols anything right after \
    const symbolMatch = positiveFormat.match(/\\ ?([^ ,]+)/);
    const symbolValue = symbolMatch?.[1];
    const symbolIndex = symbolMatch?.index ?? 0;

    if (currencyValue) {
      if (currencyIndex > 1) {
        formattedNumber = `${negativeIndicator}${formattedNumber}${currencyValue}`;
      } else {
        formattedNumber = `${negativeIndicator}${currencyValue}${formattedNumber}`;
      }
    } else if (symbolValue) {
      if (symbolIndex > 1) {
        formattedNumber = `${negativeIndicator}${formattedNumber}${symbolValue}`;
      } else {
        formattedNumber = `${negativeIndicator}${symbolValue}${formattedNumber}`;
      }
    } else if (textValue) {
      if (textIndex > 1) {
        formattedNumber = `${negativeIndicator}${formattedNumber}${textValue}`;
      } else {
        formattedNumber = `${negativeIndicator}${textValue}${formattedNumber}`;
      }
    } else {
      formattedNumber = `${negativeIndicator}${formattedNumber}`;
    }
    //#endregion

    return {
      content: formattedDate ?? formattedNumber,
      color,
    };
  };

  return { formatValue, getDateFormat };
};

export default useFormatCode;
