import { selectFilteredNavigationElements } from 'Editor/redux/NavigationSlice';
import { useSelector } from '_common/hooks';
import NavigationTreeItem from './NavigationTreeItem';
import styles from './NavigationTab.module.scss';
import { FormattedMessage } from 'react-intl';

const NavigationTree = () => {
  const elements = useSelector(selectFilteredNavigationElements);
  return (
    <>
      <div className={styles.tree_header}>
        <FormattedMessage id="SECTIONS" />
        <FormattedMessage id="WORDS" />
      </div>
      <div className={styles.tree_container}>
        {elements.list.map((elementId) => {
          return <NavigationTreeItem key={elementId} elementId={elementId} indent={-1} />;
        })}
      </div>
    </>
  );
};

export default NavigationTree;
