import { useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, EmptyState, Select, Button } from 'dodoc-design-system';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useSelector, useDispatch } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import EditorManager from 'Editor/services/EditorManager';

import { selectDocumentCitations, setSelectedCitation } from 'Editor/redux/CitationsSlice';

import { ReferenceCard } from 'Editor/components';
import { getSanitizedEntityName } from '_common/modals/CitationsModal/utils';

import styles from './DocumentCitationsList.module.scss';
import { useCanUserPerform } from 'Editor/hooks';

const SLICE_CHUNK = 20;

type DocumentCitationsListProps = {
  focusCitation: (value: { level0: string; citation: string }) => void;
  edit: (id: string) => void;
  remove: (id: string) => void;
  insert: (id: string) => void;
};

const DocumentCitationsList = ({
  focusCitation,
  edit,
  remove,
  insert,
}: DocumentCitationsListProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const canEdit = useCanUserPerform({ action: 'edit' });
  const citations = useSelector((state) => state.editor.citations.citations);
  const order = useSelector((state) => state.editor.citations.order);
  const selected = useSelector((state) => state.editor.citations.selected);
  const currentStyle = useSelector((state) => state.editor.citations.currentStyleId);
  const styleList = useSelector((state) => state.editor.citations.styleList);
  const docCitations = useSelector(selectDocumentCitations).dict;

  const selectedReferenceStyle = styleList.find((reference) => {
    return reference.value === currentStyle;
  });
  const [filter, setFilter] = useState('');
  const [lastFocus, setLastFocus] = useState({ citation: '', index: 0 });

  const containerRef = useRef(null);
  const [sliceIndex, setSliceIndex] = useState(0);
  const scrollFlag = useRef(false);

  useEffect(() => {
    if (scrollFlag.current) {
      const id = `Citation#${selected}`;
      const element = document.querySelector(`*[id="${id}"]`);
      if (element) {
        element.scrollIntoView({ block: 'center' });
      }
      scrollFlag.current = false;
    }
  }, [sliceIndex]);

  useEffect(() => {
    if (selected) {
      const index = order.indexOf(selected);
      const indexOfTemporaryComment = index === -1;

      if ((index >= sliceIndex && index < sliceIndex + SLICE_CHUNK) || indexOfTemporaryComment) {
        const id = `Citation#${selected}`;

        const element = document.querySelector(`*[id="${id}"]`);
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
      } else {
        scrollFlag.current = true;
        setSliceIndex(Math.max(index - SLICE_CHUNK / 2, 0));
      }
    }
  }, [selected]);

  const onScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop < target.clientHeight + 150) {
      setSliceIndex(Math.min(sliceIndex + SLICE_CHUNK / 2, order.length - SLICE_CHUNK));
    } else if (target.scrollTop < 150) {
      setSliceIndex(Math.max(sliceIndex - SLICE_CHUNK / 2, 0));
    }
  };

  const handleCitationFocus = (citationId: string) => {
    if (lastFocus.citation !== citationId) {
      const location = citations[citationId].locations[0];
      focusCitation(location);
      setLastFocus({ citation: citationId, index: 0 });
      dispatch(setSelectedCitation(citationId));
    } else {
      const locations = citations[citationId].locations;
      const index = lastFocus.index + 1 >= locations.length ? 0 : lastFocus.index + 1;
      const location = citations[citationId].locations[index];
      focusCitation(location);
      setLastFocus({ citation: citationId, index });
      dispatch(setSelectedCitation(citationId));
    }
  };

  const filteredOrder = order
    .slice(Math.max(sliceIndex - SLICE_CHUNK, 0), Math.min(sliceIndex + SLICE_CHUNK, order.length))
    .filter((id: string) => {
      if (!id || !docCitations[id]) {
        return false;
      }
      const { title, author: authors, year } = docCitations[id];
      return (
        filter === '' ||
        (title && title.toLowerCase().includes(filter.toLowerCase())) ||
        (authors &&
          !!authors.find((author) =>
            getSanitizedEntityName(author)?.toLowerCase().includes(filter.toLowerCase()),
          )) ||
        (year && year.toString().includes(filter.toString()))
      );
    });

  const getReferencesNumber = () => {
    if (filter) {
      return (
        <FormattedMessage
          id="NUMBER_OF_FILTERED_REFERENCES"
          values={{ tasks: filteredOrder.length, total: order.length }}
        />
      );
    }

    if (order.length === 0) {
      return <FormattedMessage id="REFERENCES_EMPTY_TITLE" />;
    }

    return <FormattedMessage id="REFERENCES_IN_THE_DOCUMENT" values={{ length: order.length }} />;
  };

  const handleReferenceStyleChange = (newRefStyle: SelectOption) => {
    EditorManager.getInstance()
      .setReferenceStyle(newRefStyle.value)
      ?.then(() => {
        notify({
          type: 'success',
          title: 'REFERENCE_STYLE_CHANGED',
          message: 'REFERENCE_STYLE_CHANGED_MESSAGE',
          messageValues: {
            referenceStyle: newRefStyle?.label as string,
          },
        });
      });
  };

  const renderFiltersEmptyView = () => {
    return (
      <div className={styles.emptyView}>
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'editor.modals.citations.noReferencesFound' })}
          testId="no-references-found-in-document"
        >
          <FormattedMessage id="NO_REFERENCES_IN_DOCUMENT_FILTERS" />
        </EmptyState>
      </div>
    );
  };

  const renderEmptyView = () => {
    return (
      <div className={styles.emptyView}>
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'REFERENCES_EMPTY_TITLE' })}
          testId="no-references"
        >
          <FormattedMessage id="REFERENCES_EMPTY_TITLE_MESSAGE" />
        </EmptyState>
      </div>
    );
  };

  const renderContent = () => {
    if (filteredOrder.length > 0) {
      return filteredOrder.map((id: string) => {
        const citation = citations[id];
        return (
          <div key={citation.id} className={styles.card}>
            <ReferenceCard
              citationId={id}
              isDocumentList
              remove={remove}
              edit={edit}
              insert={insert}
              handleCitationFocus={handleCitationFocus}
            />
          </div>
        );
      });
    } else if (filteredOrder.length === 0 && filter.length > 0) {
      return renderFiltersEmptyView();
    } else {
      return renderEmptyView();
    }
  };
  return (
    <>
      <Input
        margin="2rem 0 0"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        prefix="NavSearchBlue"
        placeholder={intl.formatMessage({ id: 'global.search' })}
        size="medium"
        testId="document-citations-search"
      />
      <Button
        onClick={() => {
          EditorManager.getInstance().insertReferenceSectionElement();
        }}
        size="small"
        margin="2rem 0 0 0"
        testId="references-panel-insert-reference-section-button"
      >
        <FormattedMessage id="INSERT_REFERENCE_SECTION_IN_DOCUMENT" />
      </Button>
      <div className={styles.referenceStyleLabel}>
        <FormattedMessage id="REFERENCE_STYLE" />
      </div>
      <Select
        clearable={false}
        size="medium"
        fullWidth
        value={selectedReferenceStyle}
        options={Object.values(styleList)}
        onChange={handleReferenceStyleChange}
        testId="references-panel-reference-style"
        disabled={!canEdit}
      />
      <div className={styles.infoTasks}>
        <div className={styles.infoText}>{getReferencesNumber()}</div>
      </div>
      <div ref={containerRef} className={styles.root} onScroll={onScroll}>
        {renderContent()}
      </div>
    </>
  );
};

export default DocumentCitationsList;
