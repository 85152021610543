import { createContext, ReactNode, useContext, useState } from 'react';

type ReplyState = null | 'open' | 'footer';

type VirtualizedListContextType = {
  setReplyState: (cardId: string, state: ReplyState) => void;
  openReplies: Record<string, ReplyState>;
  setEditingCards: (cardId: string | null, state: boolean) => void;
  editingCards: Record<string, boolean>;
  setPreviouslySelected: (cardId: string, state: boolean) => void;
  previouslySelected: Record<string, boolean>;
  virtualized: boolean;
};

const VirtualizedListContext = createContext<VirtualizedListContextType>({
  setReplyState: () => {},
  openReplies: {},
  setEditingCards: () => {},
  editingCards: {},
  setPreviouslySelected: () => {},
  previouslySelected: {},
  virtualized: false,
});

const VirtualizedListContextProvider = ({ children }: { children: ReactNode }) => {
  const [openReplies, setOpenReplies] = useState<VirtualizedListContextType['openReplies']>({});
  const [editingCards, setEditingCards] = useState<VirtualizedListContextType['editingCards']>({});
  const [previouslySelected, setPreviouslySelected] = useState<
    VirtualizedListContextType['previouslySelected']
  >({});

  const handleReplyStateChange: VirtualizedListContextType['setReplyState'] = (cardId, open) => {
    setOpenReplies((prev) => ({ ...prev, [cardId]: open }));
  };

  const handleEditModeChange: VirtualizedListContextType['setEditingCards'] = (cardId, open) => {
    if (!cardId) {
      setEditingCards({});
    } else {
      setEditingCards((prev) => ({ ...prev, [cardId]: open }));
    }
  };

  const handlePreviouslySelectedChange: VirtualizedListContextType['setPreviouslySelected'] = (
    cardId,
    selected,
  ) => {
    setPreviouslySelected((prev) => {
      if (!selected) {
        const newState = { ...prev };
        delete newState[cardId];
        return newState;
      }
      return { ...prev, [cardId]: true };
    });
  };

  return (
    <VirtualizedListContext.Provider
      value={{
        openReplies,
        setReplyState: handleReplyStateChange,
        editingCards,
        setEditingCards: handleEditModeChange,
        previouslySelected,
        setPreviouslySelected: handlePreviouslySelectedChange,
        virtualized: true,
      }}
    >
      {children}
    </VirtualizedListContext.Provider>
  );
};

export const useVirtualizedList = () => {
  return useContext(VirtualizedListContext);
};

export default VirtualizedListContextProvider;
