import { NodeDataBuilder } from 'Editor/services/DataManager';
import { InsertInlineCommand } from '../GenericCommands';

export class InsertEquationCommand extends InsertInlineCommand {
  value: any;

  constructor(context: Editor.Edition.Context, value: any) {
    super(context);

    this.value = value;
  }

  async handleExec() {
    // insert equation
    const equation = NodeDataBuilder.buildData({
      type: 'equation',
      properties: {
        equation: this.value,
      },
    });

    if (equation && this.context.commandFactory) {
      await super.handleExec(equation);
    }
  }
}
