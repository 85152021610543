import { PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from './NodeUtils';

type DataInfo = { data: Editor.Data.Node.Data; path: Editor.Selection.Path };

export class NodeDataIterator implements DoDOCCommon.IIterator<DataInfo | null> {
  private baseData: Editor.Data.Node.Data;

  private startPath: Editor.Selection.Path;
  private endPath: Editor.Selection.Path;
  private isCollapsed: boolean;

  private current: DataInfo | null;

  private nextData: DataInfo | null;
  private previousData: DataInfo | null;

  constructor(
    baseData: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
  ) {
    this.baseData = baseData;

    this.isCollapsed = PathUtils.isPathEqual(startPath, endPath);

    if (startPath.includes('content')) {
      this.startPath = startPath.slice(0, startPath.length - 2);
    } else {
      this.startPath = startPath;
    }
    if (endPath.includes('content')) {
      this.endPath = endPath.slice(0, endPath.length - 2);
    } else {
      this.endPath = endPath;
    }

    this.current = null;
    this.nextData = this.getNext();
    this.previousData = this.getPrevious();
  }

  private getNext(): DataInfo | null {
    if (this.current === null) {
      let commonAncestorPath = PathUtils.getCommonAncestorPath(this.startPath, this.endPath);
      let commonAncestorData = NodeUtils.getChildDataByPath(this.baseData, commonAncestorPath);

      let startParentChildInfo = NodeUtils.getParentChildInfoByPath(this.baseData, this.startPath);

      if (!commonAncestorData || !startParentChildInfo) {
        return null;
      }

      if (
        PathUtils.isPathEqual(startParentChildInfo.parentPath, commonAncestorPath) &&
        !this.isCollapsed
      ) {
        return {
          data: commonAncestorData,
          path: commonAncestorPath,
        };
      } else {
        let firstChildPath = this.startPath.slice(0, commonAncestorPath.length + 2);

        if (firstChildPath.length < 2) {
          return null;
        }

        const firstChild = NodeUtils.getChildDataByPath(this.baseData, firstChildPath);
        if (firstChild) {
          return {
            data: firstChild,
            path: firstChildPath,
          };
        }
      }
    } else {
      // check if path is at end
      if (PathUtils.isPathEqual(this.current.path, this.endPath)) {
        return null;
      }

      // check if start path is contained in current path
      if (
        PathUtils.isChildPath(this.current.path, this.startPath) &&
        !NodeUtils.isTextData(this.current.data)
      ) {
        let childPath = this.startPath.slice(
          this.current.path.length,
          this.current.path.length + 2,
        );

        if (childPath.length > 0) {
          let childData = NodeUtils.getChildDataByPath(this.current.data, childPath);
          if (childData && childData.id !== this.current.data.id) {
            return {
              data: childData,
              path: [...this.current.path, ...childPath],
            };
          }
        }
      }

      // check if current node has child nodes
      if (
        !NodeUtils.isTextData(this.current.data) &&
        this.current.data.childNodes &&
        this.current.data.childNodes.length > 0
      ) {
        return {
          data: this.current.data.childNodes[0],
          path: [...this.current.path, 'childNodes', 0],
        };
      }

      let parentChildInfo = NodeUtils.getParentChildInfoByPath(this.baseData, this.current.path);
      // check if current node has next sibling
      if (
        parentChildInfo &&
        parentChildInfo.parentData.childNodes &&
        parentChildInfo.childIndex < parentChildInfo.parentData.childNodes.length - 1
      ) {
        let path = [...this.current.path];
        path[path.length - 1] = parentChildInfo.childIndex + 1;
        return {
          data: parentChildInfo.parentData.childNodes[parentChildInfo.childIndex + 1],
          path,
        };
      }

      // check if parent node has next sibling
      const nextAncestor = NodeUtils.getNextAncestor(this.baseData, this.current.path);
      if (nextAncestor && PathUtils.comparePath(this.endPath, nextAncestor.path) >= 0) {
        return nextAncestor;
      }
    }

    return null;
  }

  private getPrevious(): DataInfo | null {
    let workingData: DataInfo | null = null;

    // TODO: to be implemented

    return workingData;
  }

  reset() {
    this.current = null;
    this.nextData = this.getNext();
    this.previousData = this.getPrevious();
  }

  hasNext(): boolean {
    return this.nextData != null;
  }

  next(): DataInfo | null {
    if (this.nextData != null) {
      this.previousData = this.current;
      this.current = this.nextData;
      this.nextData = this.getNext();

      return this.current;
    }

    return null;
  }
  hasPrevious(): boolean {
    return this.previousData != null;
  }
  previous(): DataInfo | null {
    if (this.previousData != null) {
      this.nextData = this.current;
      this.current = this.previousData;
      this.previousData = this.getPrevious();

      return this.current;
    }

    return null;
  }
}
