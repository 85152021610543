import { useIntl } from 'react-intl';
import { Tooltip } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import { ToolbarToggleButton } from 'Editor/components';

const UnderlineToggleButton = () => {
  const intl = useIntl();

  const isUnderlineActive = useSelector((state) => state.editor.toolbar.underline);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const onClick = () => {
    EditorManager.getInstance().toggleSelectionStyle(
      StylesUtils.STYLES.UNDERLINE,
      !isUnderlineActive,
    );
  };

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'editor.menu.format.underline' })}
      testId="underline-tooltip"
    >
      <ToolbarToggleButton
        isActive={isUnderlineActive}
        toggleButtonClicked={onClick}
        icon="Underline"
        disabled={isReadOnlyMode || isPageLayout}
        testId="underline"
      />
    </Tooltip>
  );
};

export default UnderlineToggleButton;
