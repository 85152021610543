import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import { UserOption } from '_common/components/SearchUser/SearchUser';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, usePublicProfile, useSelector } from '_common/hooks';
import { Logger } from '_common/services';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import {
  AssigneeField,
  DescriptionField,
  DueDateField,
  UserPresentation,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { selectCollaborators } from 'App/redux/appSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { Button } from 'dodoc-design-system';
import { getDocumentObject, selectDocumentUsers } from 'Editor/redux/EditorStatusSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import { selectTask, setTaskOverlayData } from 'Editor/redux/TasksSlice';
import EditorManager from 'Editor/services/EditorManager';
import { uniq } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { stringToRichText } from 'utils';

type EditableTaskCardProps = {
  sidebar?: boolean;
  mode: 'create' | 'edit';
};

const EditableTaskCard = ({ sidebar, mode }: EditableTaskCardProps) => {
  const dispatch = useDispatch();

  const descriptionRef = useRef<RichTextEditorHandler>(null);
  const assignedRef = useRef<HTMLDivElement>(null);
  const dueDateRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);

  const document = useSelector(getDocumentObject);
  const selectedTask = useSelector(selectTask);
  const currentUserId = useSelector((state) => state.auth.userId);
  const collaborators = useSelector((state) => selectCollaborators(state, document.id));
  const usersList = useSelector(selectDocumentUsers);
  const onboardingActive = useSelector(
    (state) => state.onboarding.active.editor || state.onboarding.started.editor,
  );
  const initialTaskValues = useSelector((state) => state.onboarding.initialTaskValues);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);

  const task = useMemo(() => {
    if (mode === 'edit') {
      return selectedTask;
    }
    return undefined;
  }, [selectedTask, mode]);

  const initialAssigneeProfile = usePublicProfile(
    task?.asg ?? '',
    undefined,
    onboardingActive && !!initialTaskValues?.assignee,
  );

  const assigneeInitialValue: UserOption = {
    value: task?.asg ?? '',
    label: task?.asg ? initialAssigneeProfile.name : '',
    name: '',
    is_superuser: false,
    type: 'user',
  };
  const [assignee, setAssignee] = useState<UserOption>(
    initialTaskValues?.assignee
      ? {
          value: initialTaskValues.assignee,
          label: `${GHOST_USERS.davidBean.first_name} ${GHOST_USERS.davidBean.last_name}`,
          name: '',
          is_superuser: false,
          type: 'user',
        }
      : assigneeInitialValue,
  );
  const [taskDescription, setTaskDescription] = useState(
    onboardingActive ? initialTaskValues?.description ?? '' : task?.d,
  );
  const [dueDate, setDueDate] = useState<string | null>(
    onboardingActive ? initialTaskValues?.dueDate ?? null : (task?.t.d as string) ?? null,
  );

  const testId = useMemo(() => {
    return 'create-task';
  }, []);

  const users = useMemo<UserOption[]>(() => {
    if (usersList) {
      if (usersList.find((userId) => userId === GHOST_USERS.davidBean.id)) {
        return uniq([...usersList]).map((id) => ({
          id: id,
          type: 'user',
        })) as UserOption[];
      } else {
        return uniq([...document.owners, ...usersList]).map((id: string) => ({
          id: id,
          type: 'user',
        })) as UserOption[];
      }
    }
    return [];
  }, [document.owners, usersList]);

  const handleDueDateChange = (values: Date | null) => {
    if (values) {
      setDueDate(values.toISOString());
    } else {
      setDueDate(values);
    }
  };

  const handleCancelClicked = () => {
    dispatch(setTaskOverlayData({ operation: 'view' }));
  };

  const handleApply = async () => {
    try {
      if (mode === 'edit' && task) {
        await EditorManager.getInstance()
          .editTask(task.id, {
            description: stringToRichText(taskDescription),
            dueDate,
            assignee: assignee?.value ?? null,
          })
          .then(() => {
            notify({
              type: 'success',
              title: 'TASK_EDITED',
              message: 'THE_TASK_WAS_SUCCESSFULLY_EDITED',
            });
          });
        dispatch(setTaskOverlayData({ operation: 'view', selected: task.id }));
      } else {
        const taskId = await EditorManager.getInstance().createTask({
          description: stringToRichText(taskDescription),
          dueDate,
          assignee: assignee?.value ?? null,
          user: currentUserId,
        });
        dispatch(completeAction('editor_tasks_createTask'));
        dispatch(setTaskOverlayData({ operation: 'view', selected: taskId }));
      }
    } catch (error) {
      Logger.captureException(error);
    }
  };

  useEffect(() => {
    if (sidebar) {
      dispatch(setSidebarView(null));
    }
  }, [sidebar]);

  useEffect(() => {
    if (!onboardingActive) {
      return;
    }

    if (assignee.value) {
      dispatch(setPulseData({ taskHasAssignee: true }));
      dispatch(completeAction('editor_tasks_assignee'));
    }
    if (dueDate) {
      dispatch(setPulseData({ taskHasDueDate: true }));
      dispatch(completeAction('editor_tasks_dueDate'));
    }
    if (!taskDescription) {
      dispatch(setPulseData({ taskHasDescription: false }));
      const current = descriptionRef?.current?.getCurrent();
      if (current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: current.offsetTop,
              left: current.offsetLeft,
              height: current.offsetHeight,
              width: current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.editor_tasks_assignee) {
      dispatch(setPulseData({ taskHasDescription: true }));
      if (assignedRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: assignedRef.current.offsetTop,
              left: assignedRef.current.offsetLeft,
              height: assignedRef.current.offsetHeight,
              width: assignedRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else if (!actionsCompleted.editor_tasks_dueDate) {
      dispatch(setPulseData({ taskHasDescription: true }));
      if (dueDateRef?.current) {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: {
              top: dueDateRef.current.offsetTop,
              left: dueDateRef.current.offsetLeft,
              height: dueDateRef.current.offsetHeight,
              width: dueDateRef.current.offsetWidth,
            },
          }),
        );
      } else {
        dispatch(
          setPulseData({
            taskButtonRect: undefined,
            taskInputRect: undefined,
          }),
        );
      }
    } else {
      dispatch(setPulseData({ taskHasDescription: true }));
      if (ctaRef?.current)
        dispatch(
          setPulseData({
            taskButtonRect: {
              top: ctaRef.current.offsetTop,
              left: ctaRef.current.offsetLeft,
              height: ctaRef.current.offsetHeight,
              width: ctaRef.current.offsetWidth,
            },
            taskInputRect: undefined,
          }),
        );
    }

    return () => {
      dispatch(
        setPulseData({
          taskInputRect: undefined,
        }),
      );
    };
  }, [assignee, dueDate, taskDescription, actionsCompleted, onboardingActive]);

  if (sidebar) {
    return null;
  }

  return (
    <Card
      id={mode === 'edit' && task ? `Task#${task.id}` : 'temporaryTaskCard'}
      testId={mode === 'edit' ? `task-${task?.id}-card` : 'temporaryTaskCard'}
    >
      <Card.Header>
        <Card.Header.Left>
          <UserPresentation userId={task?.u || currentUserId} />
        </Card.Header.Left>
      </Card.Header>
      <Card.Body>
        <DescriptionField
          id={task?.id ? undefined : 'temporaryTaskDescription'}
          ref={descriptionRef}
          initialValue={taskDescription}
          collaborators={collaborators}
          onChange={setTaskDescription}
          testId={testId}
        />
        <AssigneeField
          id={task?.id ? undefined : 'temporaryTaskAssignedTo'}
          collaborators={users ?? []}
          assignee={assignee}
          onChange={setAssignee}
          ref={assignedRef}
          testId={testId}
        />
        <DueDateField
          id={task?.id ? undefined : 'temporaryTaskDueDate'}
          dueDate={dueDate ? new Date(dueDate) : undefined}
          onChange={handleDueDateChange}
          ref={dueDateRef}
          testId={testId}
        />
      </Card.Body>
      <Card.Footer>
        <Card.Footer.Right>
          <Button
            size="small"
            variant="link"
            onClick={handleCancelClicked}
            testId={`${testId}-cancel-button`}
          >
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button
            size="small"
            variant="primary"
            margin="0 0 0 1rem"
            disabled={!taskDescription}
            onClick={handleApply}
            ref={ctaRef}
            testId={`${testId}-submit-button`}
            id={task?.id ? undefined : 'temporaryTaskCTA'}
          >
            {mode === 'create' ? (
              <FormattedMessage id="global.create" />
            ) : (
              <FormattedMessage id="SAVE_CHANGES" />
            )}
          </Button>
        </Card.Footer.Right>
      </Card.Footer>
    </Card>
  );
};

export default EditableTaskCard;
