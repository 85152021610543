import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import styles from './Description.module.scss';

type VersionDescriptionProps = {
  description?: Card.Version['description'];
  customDescription?: TranslationMessage;
};

const VersionDescription = ({ description, customDescription }: VersionDescriptionProps) => {
  return (
    <div
      className={cx({
        [styles.description]: true,
        [styles.empty]: !description,
      })}
      title={description}
    >
      {customDescription ? (
        <FormattedMessage id={customDescription.id} values={customDescription.values} />
      ) : (
        description || <FormattedMessage id="NO_DESCRIPTION" />
      )}
    </div>
  );
};

export default VersionDescription;
