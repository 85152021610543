import { Icon } from 'dodoc-design-system';
import { MouseEvent } from 'react';
import styles from './AnnotationsCluster.module.scss';

type AnnotationsClusterProps = {
  cluster: {
    id: string;
    pageNum: number;
    annotations: PDF.Annotation[];
    coords: PDF.Annotation.Point;
  };
  handleOnClick: (annotations: PDF.Annotation[]) => void;
};

const AnnotationsCluster = ({ cluster, handleOnClick }: AnnotationsClusterProps) => {
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    handleOnClick(cluster.annotations);
    e.stopPropagation();
  };
  return (
    <div
      id={`annotations-cluster-${cluster.pageNum}-${cluster.id}`}
      onClick={onClick}
      className={styles.container}
      style={{
        bottom: cluster.coords.y - 44,
        left: cluster.coords.x + 4,
      }}
    >
      <div>
        <Icon size={24} icon="PDFCommentBlue" />
      </div>
      <div className={styles.number}>{cluster.annotations.length}</div>
      <div>
        <Icon size={24} icon="PDFCommentBlue" />
      </div>
    </div>
  );
};

export default AnnotationsCluster;
