import { Button, SectionHeader } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

type SpellCheckActionsProps = {
  handleFindNext: (fromStart?: boolean) => void;
};

const SpellCheckActions = ({ handleFindNext }: SpellCheckActionsProps) => {
  const handleFromStart = () => {
    handleFindNext(true);
  };

  const handleFromCursor = () => {
    handleFindNext();
  };

  return (
    <>
      <SectionHeader margin="4rem 0 2rem 0">
        <FormattedMessage id="RUN_SPELL_CHECKER" />
      </SectionHeader>
      <Button
        size="medium"
        onClick={handleFromStart}
        fullWidth
        margin="0 0 1rem 0"
        testId="spell-check-from-beginning-button"
      >
        <FormattedMessage id="FROM_THE_BEGINNING_OF_THE_DOCUMENT" />
      </Button>
      <Button
        size="medium"
        onClick={handleFromCursor}
        fullWidth
        testId="spell-check-from-cursor-button"
      >
        <FormattedMessage id="FROM_CURSOR_POSITION" />
      </Button>
    </>
  );
};

export default SpellCheckActions;
