import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { EditorRange } from '../../EditorRange';

export class TextLineIterator
  implements DoDOCCommon.IIterator<Editor.Selection.Iterators.HTMLPosition>
{
  private modifiersData: Editor.Data.Selection.Modifiers;
  private originPosition: Editor.Selection.Iterators.HTMLPosition;
  private workingPosition: Editor.Selection.Iterators.HTMLPosition;

  private editorRoot: Element | null;
  private editorRootBoundingRect: DOMRect | null;

  private incrementMultiplier: number = 1;

  constructor(
    originNode: Node | null,
    originOffset: number | null,
    modifiersData: Editor.Data.Selection.Modifiers,
  ) {
    this.originPosition = {
      node: originNode,
      offset: originOffset,
    };
    this.workingPosition = {
      node: originNode,
      offset: originOffset,
    };

    this.modifiersData = modifiersData;

    this.editorRoot = document.getElementById('EditorRoot');
    this.editorRootBoundingRect = this.editorRoot?.getBoundingClientRect() || null;
  }

  getPreviousLinePosition(
    range: EditorRange,
    blockNode: Node,
    selectableElement: Element,
  ): Editor.Selection.Iterators.HTMLPosition {
    let htmlPosition: Editor.Selection.Iterators.HTMLPosition = { node: null, offset: null };
    const position: Editor.Selection.Iterators.XYPosition = {};

    const selectedComputedStyle = getComputedStyle(selectableElement);
    const rangeRects: DOMRectList = range.getClientRects();
    const lineHeight = parseInt(selectedComputedStyle.lineHeight, 10);
    const boundingRect = selectableElement.getBoundingClientRect();

    let rectToCheck = rangeRects[rangeRects.length - 1];
    let rectX = rectToCheck?.left;
    let rectY = (rectToCheck?.top + rectToCheck?.bottom) / 2;

    if (
      selectableElement.textContent &&
      rangeRects.length > 0 &&
      rectY - this.incrementMultiplier * lineHeight >=
        boundingRect.top - parseInt(selectedComputedStyle.paddingTop, 10)
    ) {
      // previous line from text element

      // position x
      if (
        this.modifiersData.px &&
        this.modifiersData.px > boundingRect.left &&
        this.modifiersData.px < boundingRect.right
      ) {
        position.x = this.modifiersData.px;
      } else if (rectToCheck && rectX > boundingRect.left && rectX < boundingRect.right) {
        position.x = rectX;
      } else {
        position.x = boundingRect.left + 2;
      }

      // position y
      if (
        this.modifiersData.py != null &&
        this.modifiersData.py === rectY - this.incrementMultiplier * lineHeight
      ) {
        this.incrementMultiplier += 1;
      }

      position.y = rectY - this.incrementMultiplier * lineHeight;
    } else {
      let previousSibling = EditorDOMUtils.getPreviousSelectableElement(
        blockNode,
        selectableElement,
        this.workingPosition.node,
        'row',
      );

      if (previousSibling instanceof Element) {
        let siblingBoundingRect = previousSibling.getClientRects()[0];

        // let documentContainer = EditorDOMUtils.getContentContainer(previousSibling);

        // while (
        //   previousSibling &&
        //   EditorDOMUtils.parentContainsNode(documentContainer, previousSibling) &&
        //   !EditorDOMUtils.parentContainsNode(selectableElement, previousSibling) &&
        //   siblingBoundingRect != null &&
        //   boundingRect.top < siblingBoundingRect.bottom
        // ) {
        //   // means that sibling is a column
        //   previousSibling = EditorDOMUtils.getPreviousSelectableElement(
        //     previousSibling.parentNode,
        //     previousSibling.parentNode,
        //     previousSibling,
        //     'row',
        //   );

        //   if (previousSibling instanceof Element) {
        //     siblingBoundingRect = previousSibling.getClientRects()[0];
        //   } else {
        //     break;
        //   }
        // }

        if (previousSibling && EditorDOMElements.isNodeContainerElement(previousSibling)) {
          previousSibling = previousSibling.firstChild;
        }

        if (previousSibling instanceof Element) {
          siblingBoundingRect = previousSibling.getClientRects()[0];

          const fontSize = parseInt(getComputedStyle(previousSibling).fontSize, 10) || 14;
          const siblingLineHeight =
            parseInt(getComputedStyle(previousSibling).lineHeight, 10) || fontSize * 1.33;

          // position x
          if (
            this.modifiersData.px &&
            this.modifiersData.px > boundingRect.left &&
            this.modifiersData.px < boundingRect.right
          ) {
            position.x = this.modifiersData.px;
          } else if (rectToCheck && rectX > boundingRect.left && rectX < boundingRect.right) {
            position.x = rectToCheck.right;
          } else {
            position.x = boundingRect.left + 2;
          }

          const paddingBottom = parseInt(getComputedStyle(previousSibling).paddingBottom, 10) || 0;
          // position y
          position.y = siblingBoundingRect.bottom - paddingBottom - siblingLineHeight / 2;
        }
        // }
      } else {
        // previous sibling not found
        if (EditorDOMUtils.closest(selectableElement, [ELEMENTS.TableCellElement.TAG])) {
          position.x = boundingRect.left;

          position.y =
            boundingRect.top + parseInt(selectedComputedStyle.paddingTop, 10) + lineHeight / 2;
        }
      }
    }

    if (
      position.x &&
      position.y &&
      this.editorRootBoundingRect &&
      (position.y <= this.editorRootBoundingRect?.top ||
        position.y >= this.editorRootBoundingRect.bottom ||
        position.x <= this.editorRootBoundingRect.left ||
        position.x >= this.editorRootBoundingRect.right)
    ) {
      const { x, y } = EditorDOMUtils.scrollIntoXY(position.x, position.y);
      // update positions with scroll offsets
      position.x = x;
      position.y = y;
    }

    if (position.x && position.y) {
      htmlPosition = EditorDOMUtils.getCaretOffsetFromPoint(position.x, position.y);

      if (htmlPosition && htmlPosition.node) {
        if (!this.modifiersData.px) {
          this.modifiersData.px = position.x;
        }
        this.modifiersData.py = position.y;

        // validate if caret data is not the same as current range
        // WARN: implemented to fix issue where line height is bigger than expected (Ex: text with bigger fontsize in the same line )
        const selectedNodeLength =
          this.workingPosition.node instanceof Text
            ? this.workingPosition.node.length
            : this.workingPosition.node?.childNodes.length;
        if (
          htmlPosition.node === this.workingPosition.node &&
          htmlPosition.offset === this.workingPosition.offset &&
          this.workingPosition.offset !== 0 &&
          this.workingPosition.offset !== selectedNodeLength &&
          position.x >= boundingRect.left &&
          position.x <= boundingRect.right &&
          position.y > boundingRect.top &&
          position.y < boundingRect.bottom
        ) {
          this.incrementMultiplier += 1;
          htmlPosition = this.getPreviousLinePosition(range, blockNode, selectableElement);
        }
      }
    }

    return htmlPosition;
  }

  getNextLinePosition(
    range: EditorRange,
    blockNode: Node,
    selectableElement: Element,
  ): Editor.Selection.Iterators.HTMLPosition {
    let htmlPosition: Editor.Selection.Iterators.HTMLPosition = { node: null, offset: null };
    const position: Editor.Selection.Iterators.XYPosition = {};

    const selectedComputedStyle = getComputedStyle(selectableElement);

    const rangeRects: DOMRectList = range.getClientRects();
    const lineHeight = parseInt(selectedComputedStyle.lineHeight, 10);
    const boundingRect = selectableElement.getBoundingClientRect();

    let rectToCheck = rangeRects[rangeRects.length - 1];
    let rectX = rectToCheck?.left;
    let rectY = (rectToCheck?.top + rectToCheck?.bottom) / 2;

    if (
      selectableElement.textContent &&
      rangeRects.length > 0 &&
      rectY + this.incrementMultiplier * lineHeight <=
        boundingRect.bottom - parseInt(selectedComputedStyle.paddingBottom, 10)
    ) {
      // next line from text element

      // position x
      if (
        this.modifiersData.px &&
        this.modifiersData.px > boundingRect.left &&
        this.modifiersData.px < boundingRect.right
      ) {
        position.x = this.modifiersData.px;
      } else if (rectToCheck && rectX > boundingRect.left && rectX < boundingRect.right) {
        position.x = rectX;
      } else {
        position.x = boundingRect.left + 2;
      }

      // position y
      if (
        this.modifiersData.py != null &&
        this.modifiersData.py === rectY + this.incrementMultiplier * lineHeight
      ) {
        this.incrementMultiplier += 1;
      }

      position.y = rectY + this.incrementMultiplier * lineHeight;
    } else {
      // first line from next sibling
      let nextSibling = EditorDOMUtils.getNextSelectableElement(
        blockNode,
        selectableElement,
        this.workingPosition.node,
        'row',
      );

      if (nextSibling instanceof Element) {
        let siblingBoundingRect = nextSibling.getClientRects()[0];

        // let documentContainer = EditorDOMUtils.getContentContainer(nextSibling);

        // while (
        //   nextSibling &&
        //   EditorDOMUtils.parentContainsNode(documentContainer, nextSibling) &&
        //   !EditorDOMUtils.parentContainsNode(selectableElement, nextSibling) &&
        //   siblingBoundingRect != null &&
        //   boundingRect.bottom > siblingBoundingRect.top
        // ) {
        //   // means that sibling is a column
        //   nextSibling = EditorDOMUtils.getNextSelectableElement(
        //     nextSibling.parentNode,
        //     nextSibling.parentNode,
        //     nextSibling,
        //     'row',
        //   );

        //   if (nextSibling instanceof Element) {
        //     siblingBoundingRect = nextSibling.getClientRects()[0];
        //   } else {
        //     break;
        //   }
        // }

        if (nextSibling && EditorDOMElements.isNodeContainerElement(nextSibling)) {
          nextSibling = nextSibling.firstChild;
        }

        if (nextSibling instanceof Element) {
          siblingBoundingRect = nextSibling.getClientRects()[0];

          const fontSize = parseInt(getComputedStyle(nextSibling).fontSize, 10) || 14;
          const siblingLineHeight =
            parseInt(getComputedStyle(nextSibling).lineHeight, 10) || fontSize * 1.33;

          // position x
          if (
            this.modifiersData.px &&
            this.modifiersData.px > boundingRect.left &&
            this.modifiersData.px < boundingRect.right
          ) {
            position.x = this.modifiersData.px;
          } else if (rectToCheck && rectX > boundingRect.left && rectX < boundingRect.right) {
            position.x = rectToCheck.right;
          } else {
            position.x = boundingRect.left + 2;
          }

          const paddingTop = parseInt(getComputedStyle(nextSibling).paddingTop, 10) || 0;

          // position y
          position.y = siblingBoundingRect.top + paddingTop + siblingLineHeight / 2;
        }
        // }
      } else {
        // next sibling not found
        if (EditorDOMUtils.closest(selectableElement, [ELEMENTS.TableCellElement.TAG])) {
          position.x = boundingRect.right;

          const paddingBottom = parseInt(selectedComputedStyle.paddingBottom, 10) || 0;
          position.y = boundingRect.bottom - paddingBottom - lineHeight / 2;
        }
      }
    }

    if (
      position.x &&
      position.y &&
      this.editorRootBoundingRect &&
      (position.y <= this.editorRootBoundingRect?.top ||
        position.y >= this.editorRootBoundingRect.bottom ||
        position.x <= this.editorRootBoundingRect.left ||
        position.x >= this.editorRootBoundingRect.right)
    ) {
      const { x, y } = EditorDOMUtils.scrollIntoXY(position.x, position.y);
      // update positions with scroll offsets
      position.x = x;
      position.y = y;
    }

    if (position.x && position.y) {
      htmlPosition = EditorDOMUtils.getCaretOffsetFromPoint(position.x, position.y);

      if (htmlPosition && htmlPosition.node) {
        if (!this.modifiersData.px) {
          this.modifiersData.px = position.x;
        }
        this.modifiersData.py = position.y;

        // validate if caret data is not the same as current range
        // WARN: implemented to fix issue where line height is bigger than expected (Ex: text with bigger fontsize in the same line )
        const selectedNodeLength =
          this.workingPosition.node instanceof Text
            ? this.workingPosition.node.length
            : this.workingPosition.node?.childNodes.length;
        if (
          htmlPosition.node === this.workingPosition.node &&
          htmlPosition.offset === this.workingPosition.offset &&
          this.workingPosition.offset !== 0 &&
          this.workingPosition.offset !== selectedNodeLength &&
          position.x >= boundingRect.left &&
          position.x <= boundingRect.right &&
          position.y > boundingRect.top &&
          position.y < boundingRect.bottom
        ) {
          this.incrementMultiplier += 1;
          htmlPosition = this.getNextLinePosition(range, blockNode, selectableElement);
        }
      }
    }

    return htmlPosition;
  }

  hasNext(): boolean {
    throw new Error('Not implemented!');
  }

  next(): Editor.Selection.Iterators.HTMLPosition {
    let htmlPosition: Editor.Selection.Iterators.HTMLPosition = {
      node: null,
      offset: null,
    };

    this.incrementMultiplier = 1;

    let blockNode: Node | null = null;
    let selectableElement: Node | null = null;
    let range = new EditorRange();

    if (this.workingPosition.node != null && this.workingPosition.offset != null) {
      blockNode = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(this.workingPosition.node),
        this.workingPosition.node,
      );

      selectableElement = EditorDOMUtils.getSelectableElementFromBlock(
        blockNode,
        this.workingPosition.node,
      );

      range.setStartAndEnd(
        this.workingPosition.node,
        this.workingPosition.offset,
        this.workingPosition.node,
        this.workingPosition.offset,
      );

      if (blockNode && selectableElement instanceof Element) {
        htmlPosition = this.getNextLinePosition(range, blockNode, selectableElement);
      }
    }

    return htmlPosition;
  }

  hasPrevious(): boolean {
    throw new Error('Not implemented!');
  }

  previous(): Editor.Selection.Iterators.HTMLPosition {
    let htmlPosition: Editor.Selection.Iterators.HTMLPosition = {
      node: null,
      offset: null,
    };

    this.incrementMultiplier = 1;

    let blockNode: Node | null = null;
    let selectableElement: Node | null = null;
    let range = new EditorRange();

    if (this.workingPosition.node != null && this.workingPosition.offset != null) {
      blockNode = EditorDOMUtils.findFirstLevelChildNode(
        EditorDOMUtils.getContentContainer(this.workingPosition.node),
        this.workingPosition.node,
      );

      selectableElement = EditorDOMUtils.getSelectableElementFromBlock(
        blockNode,
        this.workingPosition.node,
      );

      range.setStartAndEnd(
        this.workingPosition.node,
        this.workingPosition.offset,
        this.workingPosition.node,
        this.workingPosition.offset,
      );

      if (blockNode && selectableElement instanceof Element) {
        htmlPosition = this.getPreviousLinePosition(range, blockNode, selectableElement);
      }
    }

    return htmlPosition;
  }
}
