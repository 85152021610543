import { useEffect, useRef, useState } from 'react';
import EditorManager from 'Editor/services/EditorManager';
import { InstanceType } from 'Editor/redux/FindAndReplaceSlice';
import styles from './Instance.module.scss';

const Instance = ({
  before,
  word,
  after,
  current,
  id,
}: {
  before?: InstanceType['context']['before'];
  word: InstanceType['word'];
  after?: InstanceType['context']['after'];
  current: boolean;
  id: number;
}) => {
  const [beforeWord, setBeforeWord] = useState('');
  const [highlightWord, setHighlightWord] = useState(false);
  const [hasWhiteSpace, setHasWhiteSpace] = useState(false);
  const [maxWidth, setMaxWidth] = useState('');
  const instanceRef = useRef<HTMLDivElement>(null);
  const wordRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (before) {
      const beginningSpaces = before.match(/^\s*/);
      const endSpaces = before.match(/\s*$/);
      let newBefore = before.trim();

      if (beginningSpaces) {
        newBefore = newBefore + beginningSpaces[0];
      }
      if (endSpaces && endSpaces[0]) {
        setHasWhiteSpace(true);
      } else {
        setHasWhiteSpace(false);
      }

      setBeforeWord(newBefore);
    }

    if (word) {
      const trimedWord = word.trim();
      if (trimedWord === ' ' || trimedWord === '') {
        setHighlightWord(true);
      }
    }

    if (instanceRef.current && wordRef.current) {
      let maxWidth = instanceRef.current.clientWidth - wordRef.current.clientWidth;
      if (after && before) {
        maxWidth /= 2;
      }
      setMaxWidth(`${maxWidth}px`);
    }
  }, [word, after, before]);

  const handleNavigateTo = async () => {
    EditorManager.getInstance().navigateToMatch(id);
  };

  return (
    <div
      className={`${styles.instance} ${current && styles.current}`}
      onClick={handleNavigateTo}
      ref={instanceRef}
      data-testid={`find-and-replace-list-${id}`}
    >
      {before && before !== null && (
        <div
          className={`${styles.before} ${before[before.length - 1] === ' ' && styles.pre}`}
          style={{ maxWidth }}
        >
          {hasWhiteSpace && ' '}&#8234;{beforeWord}
          {/*Left-To-Right Embedding for symbols and numbers*/}
        </div>
      )}
      <div className={`${styles.word} ${highlightWord && styles.highlight}`} ref={wordRef}>
        {word}
      </div>
      {after && after !== null && (
        <div className={`${styles.after} ${after[0] === ' ' && styles.pre}`}>{after}</div>
      )}
    </div>
  );
};

export default Instance;
