import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import enStrings from 'locales/en/translation.json';
// import ptStrings from 'locales/pt/translation.json';
import esStrings from 'locales/es/translation.json';
// import noStrings from 'locales/no/translation.json';
import frStrings from 'locales/fr/translation.json';
import zhStrings from 'locales/zh-CN/translation.json';
import jaStrings from 'locales/ja/translation.json';
import deStrings from 'locales/de/translation.json';
import { RootState } from '_common/redux';

type NestedMessages = { [key: string]: string | NestedMessages };

const flattenMessages = (nestedMessages: NestedMessages, prefix = '') =>
  Object.keys(nestedMessages).reduce<{ [key: string]: string }>((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});

export const getMessages = (locale: string) => {
  let messages;

  switch (locale) {
    case 'en':
      messages = enStrings;
      break;
    // case 'pt':
    //   messages = ptStrings;
    //   break;
    case 'es':
      messages = esStrings;
      break;
    // case 'no':
    //   messages = noStrings;
    //   break;
    case 'fr':
      messages = frStrings;
      break;
    case 'de':
      messages = deStrings;
      break;
    case 'zh-CN':
      messages = zhStrings;
      break;
    case 'ja':
      messages = jaStrings;
      break;
    default:
      messages = enStrings;
  }
  return flattenMessages(messages);
};

type Props = {
  children: ReactNode;
  locale: string;
};

const Intl = ({ locale, children }: Props) => (
  <IntlProvider
    locale={locale}
    messages={getMessages(locale)}
    defaultLocale="en"
    textComponent="span"
    onError={() => {}}
  >
    {children}
  </IntlProvider>
);

/**
 * Maps the store into component's props
 */
const mapStateToProps = (state: RootState) => ({
  locale: state.intl.locale,
});

export const LANGUAGES: { [key in Language['code']]: Language } = {
  en: { code: 'en', name: 'English' },
  // pt: { code: 'pt', name: 'Português' },
  es: { code: 'es', name: 'Español' },
  // no: { code: 'no', name: 'Norsk' },
  fr: { code: 'fr', name: 'Français' },
  de: { code: 'de', name: 'Deutsch' },
  'zh-CN': { code: 'zh-CN', name: '中文' },
  ja: { code: 'ja', name: '日本語' },
};

export { Intl as IntlProvider };

export default connect(mapStateToProps)(Intl);
