import useChartColor from './useChartColor';
import useOutline from '../../useOutline';

const useChartOutline = () => {
  const { chartColor } = useChartColor();
  const { parseOutline } = useOutline();

  const chartOutline = (ln: Presentation.Data.Common.Outline | undefined) => {
    if (!ln) {
      return undefined;
    }

    const shapeOutline = parseOutline(ln);

    return {
      borderType:
        shapeOutline.strokeDasharray !== 'none'
          ? shapeOutline.strokeDasharray?.split(',').map((value) => +value)
          : undefined,
      borderWidth: shapeOutline.strokeWidth,
      borderColor: chartColor(ln.fill),
      borderCap: shapeOutline.strokeLinecap,
      borderJoin: shapeOutline.strokeLinejoin,
      borderMiterLimit: shapeOutline.strokeMiterlimit,
    };
  };
  return { chartOutline };
};

export default useChartOutline;
