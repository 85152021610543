import { mix } from 'mixwith';
import EditionMode from '../EditionMode';
import BackspaceHandler from './handlers/BackspaceHandler';
import RemoveContentHelper from './helpers/RemoveContentHelper';
import EnterHandler from './handlers/EnterHandler';
import AddContentHelper from './helpers/AddContentHelper';
import DefaultHandler from './handlers/DefaultHandler';
import DeleteHandler from './handlers/DeleteHandler';
import RemoveCitationsHelper from './helpers/RemoveCitationsHelper';
import TabHandler from './handlers/TabHandler';
import InsertBlockHelper from './helpers/InsertBlockHelper';
import InsertHandler from './handlers/InsertHandler';
import InsertInlineHandler from './handlers/InsertInlineHandler';
import CopyPasteHandler from './handlers/CopyPasteHandler';
import CompositionHandler from './handlers/CompositionHandler';
import FieldsHandler from './handlers/FieldsHandler';
import {
  InsertElementHelper,
  SplitSelectionHelper,
  JoinSelectionHelper,
  RemoveSelectionHelper,
  SuggestionsHelper,
  TableOperations,
  TableStylesOperations,
  StructureOperations,
  CommentsEditionHandler,
  HyperlinksEditionHandler,
  CitationsEditionHandler,
  FieldsEditionHandler,
  TablesEditionHandler,
  NotesEditionManager,
  EquationsEditionHandler,
  ImagesEditionHandler,
  CommonOperations,
  ImageOperations,
} from '../_Common';

class SuggestionMode extends mix(EditionMode).with(
  EquationsEditionHandler,
  NotesEditionManager,
  CommentsEditionHandler,
  HyperlinksEditionHandler,
  CitationsEditionHandler,
  FieldsEditionHandler,
  TablesEditionHandler,
  ImagesEditionHandler,
  CommonOperations,
  ImageOperations,
  StructureOperations,
  TableOperations,
  TableStylesOperations,
  InsertElementHelper,
  SplitSelectionHelper,
  JoinSelectionHelper,
  RemoveSelectionHelper,
  SuggestionsHelper,
  AddContentHelper,
  RemoveContentHelper,
  RemoveCitationsHelper,
  InsertBlockHelper,
  InsertHandler,
  InsertInlineHandler,
  BackspaceHandler,
  EnterHandler,
  DefaultHandler,
  DeleteHandler,
  TabHandler,
  CopyPasteHandler,
  CompositionHandler,
  FieldsHandler,
) {}

export default SuggestionMode;
