import { ReactNode, useState } from 'react';
import styles from './BaseRow.module.scss';

type RowCellProps = {
  size?: string;
  align?: 'left' | 'center' | 'right';
  ellipsis?: boolean;
  children: string | undefined | ReactNode;
  disabled?: boolean;
  testId?: string;
};

function RowCell({ size, align = 'left', ellipsis, children, disabled, testId }: RowCellProps) {
  const [renderedString, setRenderedString] = useState<string>();

  return (
    <div
      style={{ width: `${size ? `${size}rem` : ''}` }}
      className={`
      ${styles.item}
      ${styles.fixed}
      ${styles[align]}
      ${size ? undefined : styles.flex}
      ${disabled && styles.disabled}`}
      title={ellipsis ? renderedString : undefined}
      data-testid={testId}
    >
      {ellipsis && children ? (
        <span
          ref={(ref) => setRenderedString(ref?.textContent ?? undefined)}
          className={styles.ellipsis}
        >
          {children}
        </span>
      ) : (
        children
      )}
    </div>
  );
}

export default RowCell;
