import { useIntl } from 'react-intl';
import { Toggle, Tooltip } from 'dodoc-design-system';

import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { useDispatch, useSelector } from '_common/hooks';
import { RightPanel, setRightPanel } from 'PDF/redux/PDFGeneralSlice';
import { InteractionController } from '_common/components';
import { Sidebar } from '_common/suite/components';

const PDFSidebar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const rightPanel = useSelector((state) => state.pdf.general.rightPanel);
  const isIEnvision = useSelector(selectIsIEnvision);

  const { canAccessVersionHistory, canAccessTasks } = usePDFPermissions();

  const toggleRightPanel = (panel: RightPanel) => {
    dispatch(setRightPanel(rightPanel === panel ? undefined : panel));
  };

  return (
    <Sidebar>
      <InteractionController environment="dopdf" style={{ flexDirection: 'column' }}>
        <Tooltip
          placement="left"
          content={intl.formatMessage({ id: 'DOCUMENT_DETAILS' })}
          testId="sidebar-details-tooltip"
        >
          <Toggle
            variant="ghost"
            size="medium"
            icon={rightPanel === 'details' ? 'InformationBlue' : 'InformationGrey'}
            isToggled={rightPanel === 'details'}
            onClick={() => toggleRightPanel('details')}
            margin="0 0 1rem 0"
            testId="sidebar-details-toggle"
          />
        </Tooltip>
        <Tooltip
          placement="left"
          content={intl.formatMessage({ id: 'COMMENTS' })}
          testId="sidebar-comments-tooltip"
        >
          <Toggle
            variant="ghost"
            size="medium"
            icon={rightPanel === 'annotations' ? 'PDFCommentBlue' : 'PDFCommentGrey'}
            isToggled={rightPanel === 'annotations'}
            onClick={() => toggleRightPanel('annotations')}
            margin="0 0 1rem 0"
            testId="sidebar-annotations-toggle"
          />
        </Tooltip>
        <Tooltip
          placement="left"
          content={intl.formatMessage({
            id: canAccessTasks ? 'TASKS' : 'NO_PERMISSION_TO_PERFORM_ACTION',
          })}
          testId="sidebar-tasks-tooltip"
        >
          <Toggle
            variant="ghost"
            size="medium"
            icon={rightPanel === 'tasks' ? 'TaskBlue' : 'TaskGrey'}
            isToggled={rightPanel === 'tasks'}
            onClick={() => toggleRightPanel('tasks')}
            margin="0 0 1rem 0"
            disabled={!canAccessTasks}
            testId="sidebar-tasks-toggle"
          />
        </Tooltip>
        <Tooltip
          content={
            !isIEnvision && canAccessVersionHistory
              ? intl.formatMessage({ id: 'VERSION_HISTORY' })
              : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
          }
          placement="left"
          testId="sidebar-version-history-tooltip"
        >
          <Toggle
            variant="ghost"
            size="medium"
            icon={rightPanel === 'versionhistory' ? 'VersionHistoryBlue' : 'VersionHistoryGrey'}
            isToggled={rightPanel === 'versionhistory'}
            onClick={() => toggleRightPanel('versionhistory')}
            margin="0 0 1rem 0"
            disabled={isIEnvision || !canAccessVersionHistory}
            testId="sidebar-version-history-toggle"
          />
        </Tooltip>
      </InteractionController>
    </Sidebar>
  );
};

export default PDFSidebar;
