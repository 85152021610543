import { MouseEventHandler, useMemo } from 'react';

import { useSelector } from '_common/hooks';

import CardToggle from 'Presentation/CardToggle/CardToggle';

import styles from './CardHighlight.module.scss';
import { useFocusCard } from 'Presentation/hooks';

export type CardHighlightProps = {
  rects: ShortRect[];
} & (
  | {
      object: Presentation.Data.Comment | Presentation.Data.Task;
      type: 'task' | 'comment';
      creating?: false;
    }
  | {
      object?: never;
      type?: never;
      creating: true;
    }
);

const CardHighlight = ({ object, type, rects, creating }: CardHighlightProps) => {
  const { focusCard } = useFocusCard();

  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);
  const creatingParams = useSelector((state) => state.presentation.general.creating);
  const isOpen = !creating && object.id === selectedCard;

  const handleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    if (!creating) {
      focusCard({ objectId: object.id });
    }
  };

  const handleToggleClick = () => {
    if (!creating) {
      focusCard({ objectId: isOpen ? null : object.id });
    }
  };

  /**
   * Get position of the toggle with the intention to be displayed in the Top-Right of all rects
   * Apply the offset of 16px (2rem), which is defined in designs
   */
  const togglePosition = useMemo(
    () =>
      rects.reduce<Partial<Position>>((position, rect) => {
        const newTop = rect.top;
        const newLeft = rect.left + rect.width + 16; /* 2rem offset */

        //Lowest numerical value and the highest position in terms of appearance
        position.top = position.top ? Math.min(position.top, newTop) : newTop;
        //Highest numerical value and the rightmost position in terms of appearance
        position.left = position.left ? Math.max(position.left, newLeft) : newLeft;

        return position;
      }, {}),
    [rects],
  );

  //Render border of highlight separate due to differente opacities
  return (
    <>
      {/* border & card toggle */}
      {isOpen || creating ? (
        <>
          {rects.map((rect, index) => (
            <span
              key={`highlight-border-${index}`}
              className={styles.borderRoot}
              style={{
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height,
              }}
            />
          ))}
        </>
      ) : null}
      {/* highlights */}
      {rects.map((rect, index) => (
        <span
          key={`highlight-${index}`}
          className={styles.highlighRoot}
          style={{
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
          }}
          onClick={handleClick}
        />
      ))}
      <span style={togglePosition} className={styles.toggle}>
        {creating ? (
          creatingParams && (
            <CardToggle
              isOpen={true}
              type={creatingParams.type}
              mode="create"
              placement="right-start"
              onClick={handleToggleClick}
            />
          )
        ) : (
          <CardToggle
            isOpen={isOpen}
            object={object}
            type={type}
            placement="right-start"
            onClick={handleToggleClick}
          />
        )}
      </span>
    </>
  );
};

export default CardHighlight;
