const permissionTranslation = (code: Permission.Code) => {
  switch (code) {
    case 'owner':
      return 'global.coOwner';
    case 'access':
      return 'global.access';
    case 'approve':
      return 'global.approve';
    case 'delete':
      return 'global.delete';
    case 'edit':
      return 'global.edit';
    case 'comment':
      return 'global.comment';
    case 'add_permission':
      return 'global.add';
    case 'remove_permission':
      return 'global.remove';
    default:
      return code;
  }
};

export default permissionTranslation;
