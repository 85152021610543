import { useDispatch, useSelector } from '_common/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import { closeModal } from '_common/modals/ModalsSlice';
import { getTrackingState } from 'Editor/redux/TrackingSlice';
import EditorManager from 'Editor/services/EditorManager';
import styles from './SuggestionModeLockConfirmationModal.module.scss';

const SuggestionModeLockConfirmationModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.SuggestionModeLockConfirmationModal);
  const tracking = useSelector(getTrackingState);

  const close = () => {
    dispatch(closeModal('SuggestionModeLockConfirmationModal'));
  };

  const handleOnClick = () => {
    close();
    if (tracking.lock) {
      return EditorManager.getInstance().lockTrackingState(false);
    }
    return EditorManager.getInstance().lockTrackingState(true);
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="suggestion-mode-lock-confirmation">
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={
            tracking.lock
              ? 'SUGGESTIONS.UNLOCK_SUGGESTIONS_STATE_TITLE'
              : 'SUGGESTIONS.LOCK_SUGGESTIONS_STATE_TITTLE'
          }
        />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <FormattedMessage
            id={
              tracking.lock
                ? 'SUGGESTIONS.UNLOCK_SUGGESTIONS_STATE_MESSAGE'
                : 'SUGGESTIONS.LOCK_SUGGESTIONS_STATE_MESSAGE'
            }
            values={
              tracking.lock
                ? {}
                : {
                    activeState: tracking.state ? (
                      <b>{intl.formatMessage({ id: 'global.on' }).toUpperCase()}</b>
                    ) : (
                      <b>{intl.formatMessage({ id: 'global.off' }).toUpperCase()}</b>
                    ),
                    inactiveState: tracking.state ? (
                      <b>{intl.formatMessage({ id: 'global.off' }).toUpperCase()}</b>
                    ) : (
                      <b>{intl.formatMessage({ id: 'global.on' }).toUpperCase()}</b>
                    ),
                  }
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="medium"
          onClick={close}
          testId="suggestion-mode-lock-confirmation-cancel-button"
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleOnClick}
          testId="suggestion-mode-lock-confirmation-submit-button"
        >
          <FormattedMessage
            id={tracking.lock ? 'SUGGESTIONS.UNLOCK_SUGGESTIONS' : 'SUGGESTIONS.LOCK_SUGGESTIONS'}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuggestionModeLockConfirmationModal;
