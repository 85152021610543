import { Fragment, MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import { useSelector } from '_common/hooks';
import ResizeAnchor from './ResizeAnchor';
import styles from './TableOptions.module.scss';

// Sizes retrieved from https://dodoccorp.atlassian.net/wiki/spaces/PDoc/pages/679641128/Tables#Minimum%3A
const MIN_COLUMN_SIZE = 13.228346457; // in px
const MIN_ROW_SIZE = 1.5118110236; // in px
const MAX_TABLE_WIDTH = 2111.622 - 1; // in px (-1 because it works right, maybe because of a border?)

type ResizeAnchorsProps = {
  rows: number[];
  columns: number[];
  resize: (
    selection: Editor.Elements.Table.Selection,
    params: { [index: number | string]: { current: number; delta: number } },
  ) => void;
};

type ResizingInfo = {
  type: Editor.Elements.Table.Selection;
  index: number;
  original: number;
  delta: number;
} | null;

const ResizeAnchors = ({ rows, columns, resize }: ResizeAnchorsProps) => {
  const [resizing, setResizing] = useState<ResizingInfo>(null);
  const zoom = useSelector((state) => state.editor.status.zoom);

  useEffect(() => {
    if (resizing) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      return () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [resizing]);

  let rowOffset = 0;
  let columnOffset = 0;
  const width = columns.reduce((previous, current) => current / zoom + previous, 0);
  const height = rows.reduce((previous, current) => current / zoom + previous, 0);

  const onMouseDown = (
    e: ReactMouseEvent<HTMLDivElement>,
    type: Editor.Elements.Table.Selection,
    index: number,
  ) => {
    setResizing({ type, index, original: type === 'column' ? e.pageX : e.pageY, delta: 0 });
  };

  const onMouseMove = (e: MouseEvent) => {
    if (resizing) {
      const min =
        resizing.type === 'column'
          ? -columns[resizing.index] + MIN_COLUMN_SIZE
          : -rows[resizing.index] + MIN_ROW_SIZE;
      const max =
        resizing.type === 'column'
          ? columns[resizing.index + 1] - MIN_COLUMN_SIZE || Math.max(MAX_TABLE_WIDTH - width, 0)
          : 9999;

      // const min = -leftSize + MIN_SIZE;
      // const max = rightSize - MIN_SIZE;
      const delta =
        resizing.type === 'column' ? e.pageX - resizing.original : e.pageY - resizing.original;

      setResizing({
        ...resizing,
        delta: Math.max(Math.min(max, delta), min),
      });
    }
  };

  const onMouseUp = () => {
    if (resizing) {
      const values = resizing.type === 'column' ? columns : rows;
      const params = {
        [resizing.index]: {
          current: (values[resizing.index] + resizing.delta) / zoom,
          delta: resizing.delta / zoom,
        },
      };
      if (resizing.type === 'column' && values[resizing.index + 1]) {
        params[resizing.index + 1] = {
          current: (values[resizing.index + 1] - resizing.delta) / zoom,
          delta: resizing.delta / zoom,
        };
      }

      resize(resizing.type, params);
    }
    setResizing(null);
  };

  const getOffset = (sizes: number[], index: number) => {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      offset += sizes[i];
    }
    return offset;
  };

  return (
    <div className={styles.anchors}>
      {rows.map((row, index) => {
        rowOffset += row / zoom;
        return (
          <Fragment key={`row-${index}-${row}`}>
            <ResizeAnchor
              size={`${width}px`}
              offset={`${rowOffset}px`}
              type="row"
              onMouseDown={(e) => {
                onMouseDown(e, 'row', index);
              }}
            />
            <ResizeAnchor
              size="2rem"
              offset={`${rowOffset}px`}
              type="row"
              onMouseDown={(e) => {
                onMouseDown(e, 'row', index);
              }}
              extensor
            />
          </Fragment>
        );
      })}
      {columns.map((column, index) => {
        columnOffset += column / zoom;
        return (
          <Fragment key={`col-${index}-${column}`}>
            <ResizeAnchor
              size={`${height}px`}
              offset={`${columnOffset}px`}
              type="column"
              onMouseDown={(e) => {
                onMouseDown(e, 'column', index);
              }}
            />
            <ResizeAnchor
              size="2rem"
              offset={`${columnOffset}px`}
              type="column"
              onMouseDown={(e) => {
                onMouseDown(e, 'column', index);
              }}
              extensor
            />
          </Fragment>
        );
      })}
      {resizing && (
        <ResizeAnchor
          offset={`${
            getOffset(resizing.type === 'column' ? columns : rows, resizing.index + 1) / zoom
          }px`}
          size={`${resizing.type === 'column' ? height : width}px`}
          type={resizing.type}
          onMouseDown={() => {}}
          ghostDelta={resizing.delta / zoom}
        />
      )}
    </div>
  );
};

export default ResizeAnchors;
