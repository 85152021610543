import { useDispatch } from '_common/hooks';
import { setObjectPreview } from '../ObjectPreviewSlice';

import { useObjectPreview } from '../ObjectPreviewContext';
import DOCXPreview from './DOCXPreview';
import ImagePreview from './ImagePreview';
import PDFPreview from './PDFPreview';

import styles from './Preview.module.scss';

const Preview = () => {
  const dispatch = useDispatch();
  const { type, object } = useObjectPreview();

  const close = () => {
    dispatch(setObjectPreview({ id: null }));
  };

  const renderByType = () => {
    switch (type) {
      case 'image':
        return <ImagePreview object={object} />;
      case 'docx':
        return <DOCXPreview object={object} />;
      case 'pdf':
      case 'presentation':
        return <PDFPreview object={object} sourceType={type} />;
    }
  };

  return (
    <div className={styles.preview} onClick={close} data-testid="object-preview-background">
      {renderByType()}
    </div>
  );
};

export default Preview;
