import { useState, useRef, useEffect, ChangeEvent, MutableRefObject } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Input, InputField, TextArea } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { renameObject, editDescription } from 'App/redux/appSlice';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  useEditRoleDescriptionMutation,
  useRenameRoleMutation,
} from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { useEditProfileMutation } from 'Settings/pages/UserSettingsPage/UserSettingsApi';
import { paths } from '_types/authority';

const MODAL = 'GenericSettingModal';

type ValueProps = {
  value: string;
};

export type ActionProps = {
  rename: Omit<Parameters<typeof renameObject>[0], 'newName'> & ValueProps;
  editDescription: Omit<Parameters<typeof editDescription>[0], 'newDescription'> & ValueProps;
  renameRole: Pick<Role, 'id'> & { value: Role['name'] };
  editRoleDescription: Pick<Role, 'id'> & { value: Role['description'] };
  editProfile: {
    setting: keyof paths['/api/authority/user/profile/edit']['post']['requestBody']['content']['multipart/form-data'];
  } & ValueProps & { userId?: UserId };
};

export type Action = keyof ActionProps;
const GenericSettingModal = () => {
  const intl = useIntl();

  const [mutRenameRole] = useRenameRoleMutation();
  const [mutEditRoleDescription] = useEditRoleDescriptionMutation();
  const [editProfile] = useEditProfileMutation();

  const ACTIONS: { [key in Action]: (props: ActionProps[key]) => void } = {
    rename: ({ objectId, objectType, value }) => {
      dispatch(renameObject({ objectId, objectType, newName: value }));
    },
    editDescription: ({ objectId, objectType, value }) => {
      dispatch(editDescription({ objectId, objectType, newDescription: value }));
    },
    renameRole: ({ id, value }) => {
      mutRenameRole({ id, name: value });
    },
    editRoleDescription: ({ id, value }) => {
      mutEditRoleDescription({ id, description: value });
    },
    editProfile: ({ setting, value, userId }) => {
      editProfile({ [setting]: value, userId });
    },
  };

  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const inputType = useSelector((state) => state.modals[MODAL].inputType);
  const header = useSelector((state) => state.modals[MODAL].header);
  const label = useSelector((state) => state.modals[MODAL].label);
  const value = useSelector((state) => state.modals[MODAL].value);
  const inputPlaceholder = useSelector((state) => state.modals[MODAL].inputPlaceholder);
  const buttons = useSelector((state) => state.modals[MODAL].buttons);
  const data = useSelector((state) => state.modals[MODAL].data);
  const type = useSelector((state) => state.modals[MODAL].type);
  const action = useSelector((state) => state.modals[MODAL].action);
  const actionValues = useSelector((state) => state.modals[MODAL].actionValues);

  // local
  const [inputValue, setInputValue] = useState(value);
  const [validations, setValidations] = useState<{ input?: string }>({});
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const typedAction = action in ACTIONS ? (action as Action) : undefined;

  useEffect(() => {
    if (isOpen) {
      setInputValue(value);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isOpen, value, data, type]);

  const close = () => {
    setInputValue('');
    setValidations({});
    dispatch(closeAndResetModal(MODAL));
  };

  const onChange = <T extends HTMLInputElement | HTMLTextAreaElement>(e: ChangeEvent<T>) => {
    const newValidations: typeof validations = {};

    const inputName = e.target.value;
    setInputValue(inputName);

    if (new RegExp(/[<>/:?*"|]/).test(inputName)) {
      newValidations.input = intl.formatMessage(
        {
          id: 'UNSUPPORTED_CHARACTER_MESSAGE',
        },
        { type: data.type },
      );
    }
    if (type === 'name') {
      if (inputName.length === 0) {
        newValidations.input = intl.formatMessage({
          id: 'validation.name.insertName',
        });
      } else if (data && (data.type === 'document' || data.type === 'file')) {
        if (inputName.length > 150) {
          newValidations.input = intl.formatMessage(
            { id: 'validation.name.longName' },
            { length: 150 },
          );
        }
      } else if (data?.type === 'group') {
        if (inputName.length > 35) {
          newValidations.input = intl.formatMessage(
            { id: 'validation.name.longName' },
            { length: 35 },
          );
        }
      } else if (inputName.length > 150) {
        newValidations.input = intl.formatMessage(
          { id: 'validation.name.longName' },
          { length: 150 },
        );
      }
    } else if (inputName.length > 1000) {
      newValidations.input = intl.formatMessage({
        id: 'validation.description.longDescription',
      });
    } else {
      newValidations.input = '';
    }

    setValidations(newValidations);
  };

  const submit = (value: ValueProps['value']) => {
    if (typedAction && ACTIONS[typedAction]) {
      ACTIONS[typedAction]({ ...actionValues, value });
    }
  };

  const handleSubmit = () => {
    submit(inputValue);
    close();
  };

  const renderButtons = () => {
    //@ts-expect-error ModalSlice selector issue: Should be fixed once modal props have types
    return buttons?.map((button) => {
      return (
        <Button
          size="medium"
          variant={button.classType || 'neutral'}
          key={button.text}
          style={button.style}
          disabled={inputValue === '' || !!validations.input}
          onClick={button.submit ? handleSubmit : button.onClick}
          testId={`generic-setting-${button.text}-button`}
        >
          <FormattedMessage id={button.text} />
        </Button>
      );
    });
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="generic-setting">
      <Modal.Header onClose={close}>{header}</Modal.Header>
      <Modal.Body>
        {inputType === 'textarea' ? (
          <InputField
            size="large"
            label={label}
            feedback={validations.input}
            testId="generic-setting-modal-textarea-field"
          >
            <TextArea
              size="large"
              value={inputValue}
              onChange={onChange}
              placeholder={inputPlaceholder}
              ref={inputRef as MutableRefObject<HTMLTextAreaElement>}
              error={!!validations.input}
              testId="generic-setting-modal-textarea"
            />
          </InputField>
        ) : (
          <InputField
            size="large"
            label={label}
            feedback={validations.input}
            testId="generic-setting-modal-input-field"
          >
            <Input
              size="large"
              value={inputValue}
              onChange={onChange}
              placeholder={inputPlaceholder}
              onEnterKey={() => {
                if (validations.input === '') {
                  handleSubmit();
                }
              }}
              ref={inputRef as MutableRefObject<HTMLInputElement>}
              error={!!validations.input}
              testId="generic-setting-modal"
            />
          </InputField>
        )}
      </Modal.Body>
      <Modal.Footer>
        {buttons?.length > 0 ? (
          renderButtons()
        ) : (
          <>
            <Button size="medium" onClick={close} testId="generic-setting-modal-close-button">
              <FormattedMessage id="global.close" />
            </Button>
            <Button
              size="medium"
              disabled={!!validations.input}
              onClick={handleSubmit}
              testId="generic-setting-modal-save-button"
              variant="primary"
            >
              <FormattedMessage id="global.save" />
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default GenericSettingModal;
