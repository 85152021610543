import styles from './Spinner.module.scss';

type SpinnerProps = {
  color?: 'blue';
};

const Spinner = ({ color }: SpinnerProps) => (
  <div className={`${styles.spinner} ${color && styles[color]}`}>
    <div className={styles.bounce1} />
    <div className={styles.bounce2} />
    <div className={styles.bounce3} />
  </div>
);

export default Spinner;
