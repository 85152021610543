/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { BaseTypedEmitter } from '_common/services/Realtime';

class PresentationDataEmitter extends BaseTypedEmitter<Presentation.Data.DataEvents> {}

export class EventsController {
  ee: PresentationDataEmitter;

  constructor() {
    this.ee = new PresentationDataEmitter();
  }

  start(): void {}

  stop(): void {}

  destroy(): void {
    this.ee.destroy();
  }

  on<E extends Presentation.Data.DataEventsEventName, H extends Presentation.Data.DataEvents[E]>(
    event: E,
    handler: H,
  ) {
    return this.ee.on(event, handler);
  }

  off<E extends Presentation.Data.DataEventsEventName, H extends Presentation.Data.DataEvents[E]>(
    event: E,
    handler: H,
  ) {
    return this.ee.off(event, handler);
  }

  emit<E extends Presentation.Data.DataEventsEventName, H extends Presentation.Data.DataEvents[E]>(
    event: E,
    ...args: Parameters<H>
  ) {
    return this.ee.emit(event, ...args);
  }
}
