import { Dropdown } from 'dodoc-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Cell from '_common/components/Table2/Cells';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal, openModal, updateModal } from '_common/modals/ModalsSlice';

import styles from './UserSuffixCell.module.scss';

type UsersSuffixCellProps = {
  user: UserPublicProfileExtended;
};

const TEST_ID = {
  button: 'users-list-options-button',
  edit: 'users-list-options-edit',
  tokenHistory: 'users-list-options-tokenHistory',
  remove: 'users-list-options-remove',
  deactivate: 'users-list-options-deactivate',
  activate: 'users-list-options-activate',
};

const UsersSuffixCell = ({ user }: UsersSuffixCellProps) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsAdmin(user.is_admin || user.is_superuser);
  }, [user]);

  const handleRemoveUser = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          width: '52.5rem',
          headerType: 'error',
          title: 'DELETE_SOMETHING_QUOTED',
          titleValues: { label: user.email },
          message: `REMOVING_USER_FROM_TENANT_DELETES_ALL_ACTIVITY_CONFIRM`,
          messageValues: { email: user.email },
          confirmButtonTextId: 'groups.removeUser',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          cancelButtonShow: true,
          actionCode: 'removeUserFromTenant',
          actionValue: { user },
        },
      }),
    );
  };

  const handleDeactivateUser = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          width: '60rem',
          headerType: 'error',
          title: 'DEACTIVATE_USER',
          message: `DEACTIVATE_USER_FROM_TENANT`,
          messageValues: { firstName: user.first_name, lastName: user.last_name },
          confirmButtonTextId: 'DEACTIVATE_USER',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          cancelButtonShow: true,
          actionCode: 'editUserActiveState',
          actionValue: { user, active: false },
        },
      }),
    );
  };

  const handleActivateUser = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          width: '60rem',
          title: 'ACTIVATE_USER',
          message: `ACTIVATE_USER_FROM_TENANT`,
          confirmButtonTextId: 'ACTIVATE_USER',
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          cancelButtonShow: true,
          actionCode: 'editUserActiveState',
          actionValue: { user, active: true },
        },
      }),
    );
  };

  //TODO:TS user.id MUST be string
  const handleEditUser = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'UserManagementFormModal',
        data: {
          title: 'EDIT_USER_INFORMATION',
          submit: 'SAVE_CHANGES',
          user,
        },
      }),
    );
  };

  const openTokenHistory = () => {
    dispatch(updateModal({ modal: 'UserTokensModal', data: { user } }));
    dispatch(openModal('UserTokensModal'));
  };

  return (
    <Cell.Options
      items={
        <>
          <Dropdown.Item size="large" onClick={handleEditUser} testId={TEST_ID.edit}>
            <FormattedMessage id="EDIT_USER_INFORMATION" />
          </Dropdown.Item>
          <Dropdown.Item size="large" onClick={openTokenHistory} testId={TEST_ID.tokenHistory}>
            <FormattedMessage id="SEE_USER_TOKEN_HISTORY" />
          </Dropdown.Item>
          {!isAdmin && user.is_active && (
            <Dropdown.Item size="large" onClick={handleDeactivateUser} testId={TEST_ID.deactivate}>
              <FormattedMessage id="DEACTIVATE_USER" />
            </Dropdown.Item>
          )}
          {!isAdmin && !user.is_active && (
            <Dropdown.Item size="large" onClick={handleActivateUser} testId={TEST_ID.activate}>
              <FormattedMessage id="ACTIVATE_USER" />
            </Dropdown.Item>
          )}
          {!isAdmin && (
            <Dropdown.Item size="large" onClick={handleRemoveUser} testId={TEST_ID.remove}>
              <div className={`${styles.danger}`}>
                <FormattedMessage id="groups.removeUser" />
              </div>
            </Dropdown.Item>
          )}
        </>
      }
      testId={`users-${user.id}`}
    />
  );
};

export default UsersSuffixCell;
