/* eslint-disable class-methods-use-this */
import { StructureKeys, StructureData } from './Structure.types';
import { RealtimeObject, RealtimeOpsBuilder } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class Structure extends RealtimeObject<StructureData> {
  constructor(
    transport: Transport,
    id: Realtime.Core.RealtimeObjectId,
    undoManager?: Realtime.Core.UndoManager,
  ) {
    super(transport, id, 'documentStructure', undoManager);
  }

  get KEYS() {
    return StructureKeys;
  }

  get modelId() {
    return this.selectedData()?._id;
  }

  get version() {
    return this.model.version;
  }

  get childNodes(): string[] {
    return this.selectedData()?.childNodes || [];
  }

  get blockProperties() {
    return this.selectedData()?.blkProps;
  }

  get captions() {
    return this.selectedData()?.cpt || {};
  }

  get taskSequence() {
    return this.selectedData()?.tsk_sq || 0;
  }

  get hasAutomaticUpdates() {
    return this.selectedData()?.extra?.us;
  }

  get wordTemplate() {
    return this.selectedData()?.extra?.t;
  }

  get styles() {
    return this.selectedData()?.st || {};
  }

  protected getUndoableOps(ops: Realtime.Core.RealtimeOps): Realtime.Core.RealtimeOps {
    return ops.filter((op) => {
      if (
        op.p[0] === 'st' || // Appends new Document Styles
        (op.p[0] === 'cpt' && op.p.length >= 2) ||
        op.p[0] === 'childNodes' ||
        op.p[0] === 'blkProps' ||
        (op.p[0] === 'lists' && (op.p.length === 2 || op.p[2] === 'n' || op.p[2] === 'style')) ||
        op.p[0] === 'out'
      ) {
        return true;
      }
      return false;
    });
  }

  hasChildNode(nodeId: string) {
    return this.childNodes.includes(nodeId);
  }

  handleLoad(): void {
    // TODO:
    // model loaded
    // version loaded
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const selectedData = this.selectedData();
    for (let index = 0; index < ops.length; index++) {
      const op: Realtime.Core.RealtimeOp = ops[index];
      if (op.p[0] === this.KEYS.BLOCK_PROPERTIES && op.p.length === 2 && op.od?.sct) {
        this.emit('BLOCK_SECTION_UPDATE', selectedData?.blkProps[op.p[1]].sct, op.p[1]);
      } else if (op.p.length > 2 && op.p[2] === 'sct' && op.od !== undefined) {
        this.emit('BLOCK_SECTION_UPDATE', selectedData?.blkProps[op.p[1]].sct, op.p[1]);
      }
    }
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    logger.info('STRUCTURE operations ' + this.id, `source: ${source}`, ops);

    this.emit('UPDATED', this.data, ops, source);

    let childrenUpdated = false;

    const sectionsUpdated: string[] = [];

    const selectedData = this.selectedData();

    for (let index = 0; index < ops.length; index++) {
      const op = ops[index];

      // child nodes
      if (op.p[0] === this.KEYS.CHILDNODES) {
        if (op.li || op.ld) {
          childrenUpdated = true;
        }
      }

      // block properties
      if (op.p[0] === this.KEYS.BLOCK_PROPERTIES) {
        if (op.p.length === 2) {
          if (op.oi !== undefined) {
            const sct = selectedData?.blkProps[op.p[1]].sct;
            if (!sectionsUpdated.includes(sct)) {
              sectionsUpdated.push(sct);
            }
          }
        } else if (op.p.length > 2) {
          if (op.p[2] === 'lst') {
            this.emit('BLOCK_LIST_UPDATE', selectedData?.blkProps[op.p[1]].lst, op.p[1]);

            if (op.oi !== undefined && op.od !== undefined) {
              this.emit('LIST_UPDATE_ELEMENT', selectedData?.blkProps[op.p[1]].lst.lId, op.p[1]);
            }
          } else if (op.p[2] === 'sct') {
            if (op.oi !== undefined && op.od !== undefined) {
              const sct = selectedData?.blkProps[op.p[1]].sct;
              if (!sectionsUpdated.includes(sct)) {
                sectionsUpdated.push(sct);
              }
            }
          }
        }
      }

      //captions
      if (op.p[0] === this.KEYS.CAPTIONS) {
        if (op.p.length === 2) {
          if (op.od !== undefined) {
            this.emit('REMOVED_CAPTION', op.p[1] as string);
          }
          if (op.oi !== undefined) {
            this.emit('ADDED_CAPTION', op.p[1] as string, selectedData?.cpt[op.p[1]]);
          }
        } else if (op.p.length > 2) {
          this.emit('UPDATED_CAPTION', op.p[1] as string, selectedData?.cpt[op.p[1]]);
        } else {
          this.emit('LOAD_CAPTIONS', selectedData?.cpt);
        }
      }

      //template
      if (op.p[0] === this.KEYS.TEMPLATE) {
        this.emit('TEMPLATE_UPDATE', selectedData?.t);
      }
    }

    // trigger children updated
    if (childrenUpdated) {
      this.emit('CHILDREN_UPDATE');
    }

    // trigger sections update
    if (sectionsUpdated.length > 0) {
      for (let index = 0; index < sectionsUpdated.length; index++) {
        const sct = sectionsUpdated[index];
        this.emit('BLOCK_SECTION_UPDATE', sct, undefined, true);
      }
    }
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  handleOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {}

  getNodeReferences(nodeId: string) {
    const childNodes = this.selectedData()?.childNodes || [];
    const position = childNodes.indexOf(nodeId);
    if (position < 0) {
      throw new Error(`nodeId ${nodeId} not found in childNodes`);
    } else if (position === 0) {
      return {
        afterRef: childNodes[position + 1],
      };
    } else if (position === childNodes.length - 1) {
      return {
        beforeRef: childNodes[position - 1],
      };
    } else {
      return {
        beforeRef: childNodes[position - 1],
        afterRef: childNodes[position + 1],
      };
    }
  }

  updateTaskSequence(sequence: number, options?: Realtime.Core.RealtimeSourceOptions) {
    if (sequence >= 0 && sequence >= this.taskSequence) {
      const ops = [RealtimeOpsBuilder.objectReplace(this.taskSequence, sequence, ['tsk_sq'])];
      this.apply(ops, options);
    } else {
      throw new Error(`Sequence (${sequence}) is invalid`);
    }
  }

  getSectionsNodes() {
    const sectionsNodes: any = {};
    const selectedData = this.selectedData();
    const children = selectedData?.childNodes || [];
    const blkProps = selectedData?.blkProps;
    for (let index = 0; index < children.length; index++) {
      const childId = children[index];
      if (blkProps?.[childId] && blkProps[childId].sct) {
        if (!sectionsNodes[blkProps[childId].sct]) {
          sectionsNodes[blkProps[childId].sct] = [];
        }
        sectionsNodes[blkProps[childId].sct].push(childId);
      }
    }
    return sectionsNodes;
  }

  getSectionNodes(sectionId: string) {
    const sectionNodes = [];
    const selectedData = this.selectedData();
    const children = selectedData?.childNodes || [];
    const blkProps = selectedData?.blkProps;
    for (let index = 0; index < children.length; index++) {
      const childId = children[index];
      if (blkProps?.[childId] && blkProps[childId].sct) {
        if (blkProps[childId].sct === sectionId) {
          sectionNodes.push(childId);
        }
      }
    }
    return sectionNodes;
  }

  getBlockProperties(childId: string) {
    const selectedData = this.selectedData();
    if (childId && selectedData?.blkProps) {
      return selectedData.blkProps[childId] || {};
    }
    return {};
  }

  getIndexOfNode(childId: string) {
    return this.selectedData()?.childNodes.indexOf(childId);
  }

  isExcludedFromOutline(nodeId: string) {
    const nodeBlockProps = this.selectedData()?.blkProps[nodeId];
    if (
      nodeBlockProps.lst &&
      (nodeBlockProps.lst.lId === null || nodeBlockProps.lst.lId === undefined)
    ) {
      return true;
    }
    return false;
  }

  removeFromOutline(nodeId: string) {
    const nodeBlockProps = this.selectedData()?.blkProps[nodeId];
    if (
      nodeBlockProps?.lst &&
      (nodeBlockProps.lst.lId !== null || nodeBlockProps.lst.lId !== undefined)
    ) {
      return;
    }
    return this.apply([
      {
        oi: {
          lId: null,
          lLv: null,
        },
        p: [this.KEYS.BLOCK_PROPERTIES, nodeId, 'lst'],
      },
    ]);
  }

  loadVersion() {
    // TODO: refactor versioning
    // to discuss later
  }

  backToCurrentVersion() {
    // TODO: refactor versioning
    // to discuss later
  }

  restoreVersion() {
    // TODO: refactor versioning
    // to discuss later
  }

  dispose() {
    super.dispose();
  }
}
