import { notify } from '_common/components/ToastSystem';
import api from '_common/services/api/api';

export type PublicLinkInfo = {
  password: string;
  link?:
    | {
        expiration: {
          active: boolean;
          expiration: Date | null;
        };
      }
    | undefined;
  object?:
    | {
        name: string;
        type: string;
      }
    | undefined;
};

type APIError = {
  error: { data: any; status: number };
};

const publicLinkPageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    publicLinkInfo: builder.query<
      PublicLinkInfo,
      {
        hash: string;
        password?: string;
      }
    >({
      query: ({ hash, password }) => ({
        url: `/public/link/${hash}`,
        method: 'POST',
        body: { password },
        errorsExpected: [400, 404],
      }),
      transformResponse: (responseData: PublicLinkInfo) => {
        return responseData;
      },
      providesTags: (_, __, arg) => {
        return [{ type: 'PublicLink', hash: arg.hash }];
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const typedError = (error as APIError).error;
          if (typedError?.status === 400 && typedError?.data.password) {
            if (typedError.data.password.includes('wrong')) {
              notify({
                type: 'error',
                title: 'global.error',
                message: 'public.wrongPassword',
              });
            }
          }
        }
      },
    }),

    downloadPublicLinkFile: builder.mutation<
      Blob,
      { hash: string; password?: string; filename: string }
    >({
      query: ({ hash, password, filename }) => ({
        url: `/public/link/${hash}/download`,
        method: 'POST',
        errorsExpected: [400, 404],
        body: { password, filename },
        config: {
          responseType: 'blob',
        },
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'PublicLink', hash: arg.hash }],
      async onQueryStarted({ filename }, { queryFulfilled }) {
        try {
          const resp = await queryFulfilled;
          const url = window.URL.createObjectURL(resp.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          const typedError = (error as APIError).error;
          if (typedError?.status === 400 && typedError?.data.password.includes('wrong')) {
            notify({
              type: 'error',
              title: 'global.error',
              message: 'public.wrongPassword',
            });
          }
        }
      },
    }),
  }),
});

export const { usePublicLinkInfoQuery, useDownloadPublicLinkFileMutation } = publicLinkPageApi;

export default publicLinkPageApi;
