import { useSelector, useDispatch } from '_common/hooks';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Divider, usePopper, Toggle } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { getNumberedList } from 'Editor/redux/listStylesSlice';
import { selectedNumberedList } from 'Editor/redux/ToolbarSlice';
import PreviewLists from 'Editor/components/PreviewLists/PreviewLists';

import styles from './NumberedList.module.scss';

const NumberedList = () => {
  const dispatch = useDispatch();
  const selectedList = useSelector((state) => state.editor.toolbar.selectedList);
  const selected = useSelector((state) => selectedNumberedList(state));
  const numberedLists = useSelector((state) => getNumberedList(state));
  const isReadOnlyMode = useSelector((state) => selectReadOnlyMode(state));
  const isPageLayout = useSelector(selectIsPageLayout);
  const { isOpen, popperProps, referenceProps, close } = usePopper({
    disabled: isReadOnlyMode,
    placement: 'bottom-end',
  });

  const handleToggleList = (element: string) => {
    const manager = EditorManager.getInstance();
    if (element === selectedList) {
      manager.toggleList(false);
    } else {
      manager.toggleList(element);
    }
  };

  const handleListAction = (customize: boolean) => {
    dispatch(
      openAndUpdateModal({
        modal: 'NewListModal',
        data: {
          customize,
          type: 'numbered',
        },
      }),
    );
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={selected || isOpen}
        icon="NumberedList"
        dropdown
        disabled={isReadOnlyMode || isPageLayout}
        testId="numbered-list-toggle"
        {...referenceProps}
      />
      <Dropdown
        {...popperProps}
        width={Object.keys(numberedLists).length > 6 ? '32.5rem' : '30rem'}
        testId="numbered-list-dropdown"
      >
        <div className={styles.styles}>
          {Object.keys(numberedLists).map((element) => (
            <div
              key={element}
              onClick={() => {
                handleToggleList(element);
                close();
              }}
              className={`${styles.item} ${element === selectedList && styles.selected}`}
              data-testid={`${element}-list`}
            >
              <PreviewLists list={numberedLists[element]} />
            </div>
          ))}
        </div>
        <Divider />
        {Object.keys(numberedLists).length > 0 && (
          <Dropdown.Item
            onClick={() => handleListAction(true)}
            testId="numbered-list-customize-list-styles"
          >
            <FormattedMessage id="CUSTOMIZE_LIST_STYLES" />
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => handleListAction(false)}
          testId="numbered-list-create-new-list"
        >
          <FormattedMessage id="CREATE_NEW_LIST" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default NumberedList;
