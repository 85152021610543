import { useEffect, useState } from 'react';
import { Input, Icon } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { useDispatch, useSelector } from '_common/hooks';
import { updateModal } from '_common/modals/ModalsSlice';
import {
  SearchPageSliceState,
  setInitialParent,
  BaseCondition,
  setActiveTab,
  setBaseCondition,
  setAdvancedFilters,
  setTriggerPage,
} from 'Search/redux/SearchPageSlice';

import styles from './NavigateToDoDOC.module.scss';
import { AdvancedFilter } from '_common/components/AdvancedFilterRow/AdvancedFilterOptions';

type HeaderProps = {
  data: ObjectDict;
  search?: boolean;
  current: string;
  identity: SearchPageSliceState['activeTab'];
  parent?: Objekt | null;
};

const Header = ({ data, search = true, current, identity, parent }: HeaderProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const triggerPage = useSelector((state) => state.search.triggerPage);
  const [searchValue, setSearchValue] = useState('');
  const node = data[current];

  useEffect(() => {
    if (searchValue === '') {
      clearValue();
    }
  }, [searchValue]);

  const handleBackIconClick = () => {
    const newParent: {
      id: string;
      type: string;
    } = {
      id: '',
      type: '',
    };
    if (node.type === 'space') {
      dispatch(
        updateModal({
          modal: 'CitationsModal',
          data: { navigation: { identity: 'spaces' } },
        }),
      );
    } else if (node.personal) {
      if (node.parent) {
        newParent.id = node.parent;
        newParent.type = 'folder';
      } else if (node.space) {
        newParent.id = node.space;
        newParent.type = 'space';
      }
      dispatch(
        updateModal({
          modal: 'CitationsModal',
          data: { navigation: { current: newParent.id, identity: 'storage' } },
        }),
      );
    } else if (!node.personal) {
      if (node.share) {
        dispatch(
          updateModal({
            modal: 'CitationsModal',
            data: { navigation: { identity: 'shared' } },
          }),
        );
      } else {
        dispatch(
          updateModal({
            modal: 'CitationsModal',
            data: { navigation: { current: node.space, identity: 'storage' } },
          }),
        );
      }
    }
  };

  const getName = () => {
    if (identity === 'shared') {
      return intl.formatMessage({ id: 'shared.sharedTitle' });
    } else if (identity === 'spaces' && !current) {
      return intl.formatMessage({ id: 'storage.sidebar.spaces' });
    } else {
      if (current && node) {
        if (node?.type === 'space' && node?.personal) {
          return intl.formatMessage({
            id: 'spaces.personalSpace',
          });
        }
        return node.name;
      }
      return intl.formatMessage({
        id: 'spaces.personalSpace',
      });
    }
  };

  const handleOnChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const clearValue = () => {
    setSearchValue('');
    dispatch(
      updateModal({
        modal: 'CitationsModal',
        data: { navigation: { current: node?.id ?? parent?.id, identity: triggerPage } },
      }),
    );
  };

  const handleSearchObjects = () => {
    let baseCondition: BaseCondition | undefined = undefined;
    let initialFilter: AdvancedFilter | undefined = undefined;
    let newParent: Objekt | undefined = {
      id: '',
      name: '',
      type: 'folder',
    };
    if (!node && parent) {
      baseCondition = {
        name: 'parent',
        operator: 'eq',
        value: parent.id,
      };
      newParent = parent;
    }
    if (searchValue.length > 0) {
      initialFilter = {
        id: uuid(),
        option: 'name',
        operator: 'contains',
        value: searchValue,
      };
    }
    dispatch(
      updateModal({
        modal: 'CitationsModal',
        data: { navigation: { current: node?.id ?? parent?.id, identity: 'search' } },
      }),
    );
    if (baseCondition) {
      dispatch(setBaseCondition(baseCondition));
    }
    if (initialFilter) {
      dispatch(setAdvancedFilters({ initialFilter }));
    }

    dispatch(setInitialParent(newParent));
    dispatch(setTriggerPage(identity));
    dispatch(setActiveTab(identity));
  };

  return (
    <div className={styles.header}>
      <div className={styles.goBack}>
        <div onClick={!node ? () => {} : handleBackIconClick}>
          <Icon icon="ArrowLeft" size={16} disabled={!node} />
        </div>
        <div className={styles.headerText}>{getName()}</div>
      </div>
      {search && (
        <Input
          value={searchValue}
          placeholder={intl.formatMessage({ id: 'SEARCH_FILE' })}
          onChange={handleOnChangeValue}
          onEnterKey={handleSearchObjects}
          onPrefixClick={handleSearchObjects}
          prefix="NavSearchBlue"
          size="medium"
          width="30rem"
          testId="navigate-to-doDOC-search"
        />
      )}
    </div>
  );
};

export default Header;
