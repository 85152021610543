import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      parseTableOfContentsNode(representation) {
        const builder = new ElementNodeBuilder();
        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.TableOfContentsElement.ELEMENT_TYPE);
        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder, prop);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        this.parseChildren(representation, builder);
        return builder.getNode();
      }
    },
);
