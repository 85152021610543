import { useSuiteObject } from '_common/suite/SuiteContext/SuiteContext';
import { FormattedMessage } from 'react-intl';
import PanelHeading from '../PanelHeading/PanelHeading';
import styles from './Comments.module.scss';
import { ReactNode } from 'react';
import CommentFilters from '../../Filters/CommentFilters';
import { Settings } from '_common/components/Filters/FilterController';

type CommentsProps = {
  children: ReactNode;
  commentsCategories: Settings;
};

const Comments = ({ children, commentsCategories }: CommentsProps) => {
  const object = useSuiteObject();

  if (!object) {
    return null;
  }

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="COMMENTS" />
        <CommentFilters categories={commentsCategories} />
      </PanelHeading>
      <div className={styles.content}>{children}</div>
    </>
  );
};

export default Comments;
