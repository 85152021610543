import { useCallback } from 'react';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';

const useChartDefaultColors = () => {
  const { theme } = useSlideData();

  const defaultColors = useCallback((defaultNumber: number) => {
    const themeColors = Object.keys(theme.colorScheme)
      .sort()
      .reduce((acc: { [key: string]: { [key: string]: string } }, key) => {
        acc[key] = theme.colorScheme[key];
        return acc;
      }, {});
    const colors = Object.entries(themeColors);
    const firstColors = colors.slice(0, defaultNumber);
    const firstColorsObj = Object.fromEntries(firstColors);

    return Object.entries(firstColorsObj).map(([_, value]) => ({
      properties: {
        fill: { color: value, type: 'solid' },
      },
    }));
  }, []);

  return { defaultColors };
};

export default useChartDefaultColors;
