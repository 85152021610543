import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import styles from './States.module.scss';

const UnsupportedLanguageState = () => {
  const intl = useIntl();

  return (
    <div className={styles.stateWrapper}>
      <EmptyState size="medium" title={intl.formatMessage({ id: 'LANGUAGE_NOT_SUPPORTED' })} testId="language-not-supported">
        <FormattedMessage id="NO_DICTIONARY_FOR_THE_LANGUAGE_SELECTED" />
      </EmptyState>
    </div>
  );
};

export default UnsupportedLanguageState;
