/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Transport } from '../../Transport';
import { DoDocObject } from './AbstractDocument';

const DEFAULT_LANGUAGE = { code: 'en-us' };

export class DocumentModel extends DoDocObject {
  constructor(transport: Transport, id: string, data: Realtime.Core.Document.Data) {
    super(transport, id, data);

    this.handleTransportEventTrackingUpdate = this.handleTransportEventTrackingUpdate.bind(this);
    this.handleTransportEventTrackingStateUpdate =
      this.handleTransportEventTrackingStateUpdate.bind(this);

    this.transport.handleEvent(
      'LOCK:TRACKING:STATE:UPDATE',
      this.handleTransportEventTrackingStateUpdate,
    );
    this.transport.handleEvent('TRACKING:UPDATE', this.handleTransportEventTrackingUpdate);
  }

  dispose(): void {
    this.transport.removeEvent(
      'LOCK:TRACKING:STATE:UPDATE',
      this.handleTransportEventTrackingStateUpdate,
    );
    this.transport.removeEvent('TRACKING:UPDATE', this.handleTransportEventTrackingUpdate);
    super.dispose();
  }

  get language() {
    return this.data?.lang || DEFAULT_LANGUAGE;
  }

  get versions() {
    return this.data?.lifecycle?.versions || [];
  }

  protected getObjectType(): Realtime.Core.Document.DocumentType {
    return 'document';
  }

  private handleTransportEventTrackingStateUpdate(event: {
    payload: { document: Realtime.Core.Document.Data };
  }) {
    if (this.data) {
      this.data.tracking = event.payload.document.tracking;
      this.emit('UPDATED', this.data);
      this.emit('DOCUMENT_TRAKING_STATE_UPDATED');
    }
  }

  private handleTransportEventTrackingUpdate(data: Realtime.Core.Document.Data) {
    if (this.data) {
      this.data.tracking = data.tracking;
      this.emit('UPDATED', this.data);
      this.emit('DOCUMENT_TRAKING_STATE_UPDATED');
    }
  }

  setDocumentLanguage(language: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'DOCUMENT:LANGUAGE:SET',
        {
          language,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
