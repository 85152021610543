import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';
import cx from 'classnames';

import { useDispatch, useSelector } from '_common/hooks';
import { OnboardingEnvironments, startOnboarding } from 'App/redux/onboardingSlice';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import Questionnaire, { QuestionnaireProps } from './Questionnaire/Questionnaire';

import styles from './WelcomeScreen.module.scss';

type Location = OnboardingEnvironments;

type WelcomeScreenProps<AnswerId extends string = string> = {
  location: Location;
  onSkip: (skipping: boolean) => void;
  absoluteOverlay?: boolean;
  hiddenElements?: string[];
  labelsId: {
    title: string;
    description: string;
    cta: string;
  };
  showCurrentUser?: boolean;
  questionnaire?: Omit<
    QuestionnaireProps<AnswerId>,
    'onAnswerChange' | 'onBack' | 'onStart' | 'onSkip'
  >;
  padding?: CSSProperties['padding'];
};

const WelcomeScreen = <AnswerId extends string = string>({
  location,
  onSkip,
  absoluteOverlay = true,
  showCurrentUser = true,
  hiddenElements,
  labelsId,
  questionnaire,
  padding,
}: WelcomeScreenProps<AnswerId>) => {
  const dispatch = useDispatch();
  const { data: currentUser } = useGetCurrentUserQuery();

  const active = useSelector((state) => state.onboarding.active[location]);
  const started = useSelector((state) => state.onboarding.started[location]);

  const [questionnaireIsOpen, setQuestionnaireIsOpen] = useState(!!questionnaire?.isOpen);
  const [defaultAnswer, setDefaultAnswer] = useState<AnswerId | undefined>();

  const toggleHiddenElements = (on: boolean) => {
    if (hiddenElements) {
      hiddenElements.forEach((elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
          if (on) {
            element.style.display = 'inherit';
          } else {
            element.style.display = 'none';
          }
        }
      });
    }

    const welcomeScreenContainer = document.getElementById('WelcomeScreen');
    if (welcomeScreenContainer) {
      if (on) {
        welcomeScreenContainer.style.width = 'inherit';
        welcomeScreenContainer.style.display = 'none';
      } else {
        welcomeScreenContainer.style.width = '100%';
        welcomeScreenContainer.style.display = 'inherit';
      }
    }
  };

  const openQuestionnaire = () => {
    setQuestionnaireIsOpen(true);
  };

  const handleStart = () => {
    toggleHiddenElements(true);
    if (!started) {
      dispatch(startOnboarding(location));
    }
  };

  const handleSkip = () => {
    onSkip(true);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    };
    window.document.addEventListener('keydown', handleKeyDown);

    return () => {
      toggleHiddenElements(true);
      window.document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight' && !started) {
        if (questionnaire) {
          if (questionnaireIsOpen) {
            handleStart();
          } else {
            openQuestionnaire();
          }
        } else {
          handleStart();
        }
        e.stopPropagation();
      }
    };
    window.document.addEventListener('keydown', handleKeyDown);

    return () => {
      window.document.removeEventListener('keydown', handleKeyDown);
    };
  }, [started, questionnaire, questionnaireIsOpen]);

  useEffect(() => {
    toggleHiddenElements(!active || started);
  }, [hiddenElements, active, started]);

  if (active && !started) {
    if (currentUser) {
      return (
        <div className={styles.root}>
          <div
            className={cx(styles.overlay, { [styles.absolute]: absoluteOverlay })}
            style={{ padding }}
          >
            <div className={styles.content} data-testid="welcome-screen-content">
              {questionnaire && questionnaireIsOpen ? (
                <Questionnaire
                  {...questionnaire}
                  defaultAnswer={defaultAnswer || questionnaire.defaultAnswer}
                  isOpen={questionnaireIsOpen}
                  onAnswerChange={setDefaultAnswer}
                  onBack={setQuestionnaireIsOpen}
                  onStart={handleStart}
                  onSkip={handleSkip}
                />
              ) : (
                <>
                  <div className={styles.title}>
                    <FormattedMessage id={labelsId.title} />
                    {showCurrentUser && <>, {currentUser.profile.first_name}</>}! 👋🏼
                  </div>
                  <div className={styles.description}>
                    <FormattedMessage
                      id={labelsId.description}
                      values={{
                        br: (chunks: ReactNode) => (
                          <>
                            <br />
                            {chunks}
                          </>
                        ),
                      }}
                    />
                  </div>
                  <div className={styles.footer}>
                    <Button
                      size="large"
                      variant="primary"
                      fullWidth
                      onClick={questionnaire ? openQuestionnaire : handleStart}
                      testId={`${labelsId.cta.toLowerCase()}-button`}
                    >
                      <FormattedMessage id={labelsId.cta} />
                    </Button>
                    <div className={styles.skip}>
                      <FormattedMessage id="NOT_YOUR_FIRST_TIME_USING_DODOC" />
                      <Button
                        size="medium"
                        variant="link"
                        onClick={handleSkip}
                        testId="skip-onboarding-button"
                        margin="0 0 0 0.5rem"
                      >
                        <FormattedMessage id="SKIP_ONBOARDING" />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  return null;
};

export default WelcomeScreen;
