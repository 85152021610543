import { BaseTypedEmitter } from '_common/services/Realtime';

class PageStatus {
  private promise: Promise<boolean>;
  private loaded: (value: boolean) => void = () => {};
  private reject: (value: any) => void = () => {};
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.loaded = resolve;
      this.reject = reject;
    });
  }

  await() {
    return this.promise;
  }

  pageLoaded(value: boolean = true) {
    this.loaded(value);
  }
}

export class NavigationService extends BaseTypedEmitter<PDF.Navigation.Events> {
  private context: PDF.PDFManagerContext;
  private currentPage: number = 1;
  private pageStatus: {
    [index: number]: undefined | PageStatus;
  } = {};

  constructor(context: PDF.PDFManagerContext) {
    super();
    this.context = context;
    this.currentPage = 1;

    this.start = this.start.bind(this);
    this.loadedPage = this.loadedPage.bind(this);
    this.unloadedPage = this.unloadedPage.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.goTo = this.goTo.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  start() {
    if (!this.pageStatus[this.currentPage]) {
      this.pageStatus[this.currentPage] = new PageStatus();
    }
    this.emit('CHANGED_CURRENT_PAGE', this.currentPage);
  }

  stop() {
    // this.removeListener();
  }

  destroy() {
    // this.removeListener();
  }

  get current() {
    return this.currentPage;
  }

  get numPages() {
    return this.context.data?.documentController.getNumPages() || 0;
  }

  loadedPage(pageNumber: number) {
    if (!this.pageStatus[pageNumber]) {
      this.pageStatus[pageNumber] = new PageStatus();
    }
    this.pageStatus[pageNumber]?.pageLoaded();
  }

  unloadedPage(pageNumber: number) {
    if (this.pageStatus[pageNumber]) {
      delete this.pageStatus[pageNumber];
    }
  }

  async setCurrentPage(pageNumber: number) {
    if (this.currentPage !== pageNumber) {
      // TODO : extra validations
      this.emit('BEFORE_CHANGING_CURRENT_PAGE', this.currentPage);
      if (!this.pageStatus[this.currentPage]) {
        this.pageStatus[this.currentPage] = new PageStatus();
      }
      this.currentPage = pageNumber;
      this.emit('CHANGED_CURRENT_PAGE', this.currentPage);
      this.pageStatus[this.currentPage]?.await();
      this.emit('AFTER_CHANGING_CURRENT_PAGE', this.currentPage);
      return;
    }
  }

  async goTo(pageNumber: number) {
    if (this.currentPage !== pageNumber) {
      // TODO : extra validations
      this.emit('BEFORE_CHANGING_CURRENT_PAGE', this.currentPage);

      if (!this.pageStatus[this.currentPage]) {
        this.pageStatus[this.currentPage] = new PageStatus();
      }
      this.currentPage = pageNumber;
      this.emit('SCROLL_TO_PAGE', this.currentPage);
      this.emit('CHANGED_CURRENT_PAGE', this.currentPage);
      this.pageStatus[this.currentPage]?.await();
      this.emit('AFTER_CHANGING_CURRENT_PAGE', this.currentPage);
      return;
    }
  }

  previous() {
    return this.goTo(Math.max(this.currentPage - 1, 1));
  }

  next() {
    return this.goTo(Math.min(this.currentPage + 1, this.numPages));
  }
}
