import { Divider } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';
import LogoContainer from '../LogoContainer/LogoContainer';
import ChangeTenantDropdown from './ChangeTenantDropdown/ChangeTenantDropdown';
import Navigation from './Navigation/Navigation';
import Options from './Options/Options';

import styles from './Sidebar.module.scss';
import { InteractionController } from '_common/components';

const SidebarContent = () => {
  const sidebarExpanded = useSelector((state) => state.sidebar.expanded);

  return (
    <>
      <InteractionController environment='explorer' style={{ height: '100%', flexDirection: 'column' }}>
        <IntlErrorBoundary
          variant="white"
          size="xsmall"
          margin="4rem auto 1rem auto"
          collapsed={!sidebarExpanded}
        >
          <LogoContainer expanded={sidebarExpanded} color="white" testId="sidebar-logo" />
        </IntlErrorBoundary>
        <div className={styles.content} id="ExplorerSidebarContent">
          <IntlErrorBoundary
            variant="white"
            size="xsmall"
            margin="5rem auto 1rem auto"
            collapsed={!sidebarExpanded}
          >
            <ChangeTenantDropdown />
          </IntlErrorBoundary>
          <IntlErrorBoundary
            variant="white"
            size={sidebarExpanded ? 'medium' : 'xsmall'}
            margin="6.5rem auto 0 auto"
            collapsed={!sidebarExpanded}
          >
            <Navigation />
          </IntlErrorBoundary>
          <Divider variant="dark" margin="auto 0 1rem 0" />
          <IntlErrorBoundary
            variant="white"
            size={sidebarExpanded ? 'medium' : 'xsmall'}
            margin="4rem auto"
            collapsed={!sidebarExpanded}
          >
            <Options />
          </IntlErrorBoundary>
        </div>
      </InteractionController>
    </>
  );
};

export default SidebarContent;
