import { useIntl } from 'react-intl';

import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { useGetObjectQuery } from 'App/redux/objectApi';

import UsernameLabel from './UsernameLabel/UsernameLabel';

type ObjectLabelProps = {
  objectId?: ObjectId;
  parentSpace?: ObjectId;
};

const ObjectLabel = ({ objectId, parentSpace }: ObjectLabelProps) => {
  const intl = useIntl();

  const { data: currentUser } = useGetCurrentUserQuery();
  const {
    data: object,
    isLoading,
    isError,
  } = useGetObjectQuery(
    {
      objectId: objectId ?? '',
      objectType: objectId === parentSpace ? 'space' : 'folder',
    },
    { skip: !objectId },
  );

  if (isError) {
    return (
      <>
        {objectId === parentSpace
          ? intl.formatMessage({ id: 'SPACE_ID' }, { spaceId: objectId })
          : intl.formatMessage({ id: 'FOLDER_ID' }, { folderId: objectId })}
      </>
    );
  }

  if (objectId === parentSpace && object?.personal) {
    if (!currentUser || isLoading) {
      return null;
    }

    return (
      <>
        {object.owner === currentUser.profile.id
          ? intl.formatMessage({
              id: 'spaces.personalSpace',
            })
          : intl.formatMessage(
              { id: 'USER_PERSONAL_SPACE_NAME' },
              { user: <UsernameLabel userId={currentUser.profile.id} /> },
            )}
      </>
    );
  }

  return <>{object?.name}</>;
};

export default ObjectLabel;
