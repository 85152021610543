import { ClipboardManager } from "Editor/services/Clipboard";
import EditorManager from "Editor/services/EditorManager";
import { Dropdown, Icon, Toggle, usePopper } from "dodoc-design-system";
import { FormattedMessage } from "react-intl";

type PasteOptionsWidgetViewProps = {
  offsets?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
};

const PasteOptionsWidgetView = ({ offsets }: PasteOptionsWidgetViewProps) => {
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
  });

  if (!offsets) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: offsets.left != null ? offsets.left : '',
        top: offsets.top != null ? offsets.top : '',
        right: offsets.right != null ? offsets.right : '',
        bottom: offsets.bottom != null ? offsets.bottom : '',
      }}
    >
      <>
        <Toggle
          size="medium"
          variant="standard"
          icon="PasteOptions"
          isToggled={isOpen}
          {...referenceProps}
          testId="paste-options-toggle"
        >
          <div style={{ marginLeft: '0.5rem' }}>
            <Icon icon="ArrowDown" size={16} />
          </div>
        </Toggle>
        <Dropdown {...popperProps} testId="paste-options-dropdown">
          <Dropdown.Item
            onClick={() => {
              EditorManager.getInstance().handlePasteOptions(ClipboardManager.MATCH_DESTINATION);
            }}
            testId="paste-options-match-style-dropdown-item"
          >
            <FormattedMessage id="MATCH_STYLE" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              EditorManager.getInstance().handlePasteOptions(ClipboardManager.ORIGINAL_STYLES);
            }}
            testId="paste-options-keep-original-style-dropdown-item"
          >
            <FormattedMessage id="KEEP_ORIGINAL_STYLE" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              EditorManager.getInstance().handlePasteOptions(ClipboardManager.PLAIN_TEXT);
            }}
            testId="paste-options-keep-text-only-dropdown-item"
          >
            <FormattedMessage id="KEEP_TEXT_ONLY" />
          </Dropdown.Item>
        </Dropdown>
      </>
    </div>
  )
}

export default PasteOptionsWidgetView;