import cx from 'classnames';
import { Divider, Icon, Popover, SplitToggle, Tooltip, usePopper } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import { setCursorMode } from 'PDF/redux/PDFGeneralSlice';
import styles from './Toggles.module.scss';
import { selectAnnotation, setCreationSetting } from 'PDF/redux/PDFAnnotationsSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePDFContext } from 'PDF/PDFContext';
import { COLORS_HEX_MAP } from 'PDF/services';
import { InteractionController } from '_common/components';

export const COLORS = ['yellow', 'green', 'red', 'blue'] as const;
export const TYPES = ['Highlight', 'Underline', 'StrikeOut'] as const;

const icons = {
  Highlight: {
    true: 'HighlightBlue',
    false: 'HighlightGrey',
  },
  Underline: {
    true: 'PDFUnderlineBlue',
    false: 'PDFUnderlineGrey',
  },
  StrikeOut: {
    true: 'PDFStrikethroughBlue',
    false: 'PDFStrikethroughGrey',
  },
} as const;

const ROIToggle = ({ disabled }: { disabled: boolean }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { referenceProps, popperProps, isOpen, close } = usePopper();
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const roiColor = useSelector((state) => state.pdf.annotations.creation.roiColor);
  const roiType = useSelector((state) => state.pdf.annotations.creation.roiType);
  const manager = usePDFContext();

  const setROIColor = (color: (typeof COLORS)[number]) => {
    dispatch(
      setCreationSetting({
        roiColor: color,
      }),
    );
    dispatch(setCursorMode(roiType));
    close();
  };

  const setROIType = (type: (typeof TYPES)[number]) => {
    dispatch(
      setCreationSetting({
        roiType: type,
      }),
    );
    dispatch(setCursorMode(type));
    close();
  };

  const isToggled =
    cursorMode === 'Highlight' || cursorMode === 'Underline' || cursorMode === 'StrikeOut';

  const handleCursorMode = () => {
    if (!isToggled) {
      manager.restoreSelection();
      const id = manager.createTextMarkupAnnotation(roiType);
      if (id) {
        dispatch(selectAnnotation(id));
      } else {
        dispatch(setCursorMode(roiType));
      }
    } else {
      dispatch(setCursorMode('normal'));
    }
  };

  return (
    <span id="pdf-toolbar-highlight" style={{ position: 'relative', zIndex: 1 }}>
      <InteractionController
        environment="dopdf"
        rules={[{ interaction: 'pdf_toolbar_highlight', actions: ['pdf_annotations_highlight'] }]}
      >
        <Tooltip
          disabled={isOpen}
          content={
            disabled
              ? intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
              : intl.formatMessage({ id: 'HIGHLIGHTS_AND_NOTES' })
          }
          placement="bottom"
          testId="roi-split-tooltip"
        >
          <SplitToggle
            margin="0 0.75rem 0 0"
            isToggled={isToggled}
            onClick={handleCursorMode}
            icon={icons[roiType][isToggled ? 'true' : 'false']}
            iconColor={COLORS_HEX_MAP[roiColor]}
            disabled={disabled}
            testId="roi-split"
            {...referenceProps}
            isOpen={isOpen}
            onPopperClick={referenceProps.onClickCapture}
          />
        </Tooltip>
      </InteractionController>
      <Popover {...popperProps} testId="roi-popper">
        <div className={styles.popover}>
          {COLORS.map((color) => (
            <div
              key={color}
              className={cx(styles.item, { [styles.isSelected]: roiColor === color })}
              onClick={() => setROIColor(color)}
            >
              <span className={styles.selection}>
                <Icon size={16} icon="Check" />
              </span>
              <span className={cx(styles.color, styles[color])} />
              <span className={styles.text}>
                <FormattedMessage id={color.toUpperCase()} />
              </span>
            </div>
          ))}
          <Divider margin="1rem 0 1rem" />
          {TYPES.map((type) => (
            <div
              key={type}
              className={cx(styles.item, { [styles.isSelected]: roiType === type })}
              onClick={() => setROIType(type)}
            >
              <span className={styles.selection}>
                <Icon size={16} icon="Check" />
              </span>
              <Icon size={24} icon={icons[type].false} color={COLORS_HEX_MAP[roiColor]} />
              <span className={styles.text}>
                <FormattedMessage id={type.toUpperCase()} />
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </span>
  );
};

export default ROIToggle;
