import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FindSlice = {
  instances?: (Presentation.Data.FindAndReplace.WordOccurence & { id: number })[] | null;
  selectedInstance: number;
};

const SLICE_NAME = 'Find';
const initialState: FindSlice = {
  instances: null,
  selectedInstance: 0,
};

// #region Slice
const FindSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setInstances: (state, action: PayloadAction<FindSlice['instances']>) => {
      //@ts-expect-error not sure whats the fix
      state.instances = action.payload;
    },
    setSelectedInstance: (state, action: PayloadAction<FindSlice['selectedInstance']>) => {
      state.selectedInstance = action.payload;
    },
  },
});

export default FindSlice;
// #endregion

// #region Actions
export const { setInstances, setSelectedInstance } = FindSlice.actions;
// #endregion
