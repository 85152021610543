import { RenderElementProps } from 'slate-react';
import cx from 'classnames';

import { useSelector } from '_common/hooks';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';

import styles from './InlineMention.module.scss';
import { selectIsIEnvision } from 'App/redux/appSlice';

type InlineMentionProps = RenderElementProps & { testId?: string };

const InlineMention = ({ attributes, element, children, testId }: InlineMentionProps) => {
  const activeUserId = useSelector((state) => state.auth.userId);
  const isEnvision = useSelector(selectIsIEnvision);

  if (element.type !== 'mention') {
    return null;
  }

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={cx(styles.root, {
        [styles.activeUser]: element.userId === activeUserId,
        [styles.envision]: isEnvision,
      })}
      data-testid={testId ? `${testId}-inlineMention` : ''}
    >
      @<UsernameLabel userId={element.userId} />
      {children}
    </span>
  );
};

export default InlineMention;
