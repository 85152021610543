import { setAppTheme } from 'App/redux/appSlice';
import { useEffect } from 'react';
import { useDispatch } from './redux';

const useEnvisionTheme = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAppTheme('blue'));

    return () => {
      dispatch(setAppTheme('lightBlue'));
    };
  }, []);
};

export default useEnvisionTheme;
