import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';

import { setInstances } from 'Editor/redux/FindAndReplaceSlice';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import { useDispatch, useSelector } from '_common/hooks/redux';
import EditorManager from 'Editor/services/EditorManager';
import styles from './ReplaceAllModal.module.scss';

const ReplaceAllModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.ReplaceAllModal);
  const find = useSelector((state) => state.modals.ReplaceAllModal.find);
  const replace = useSelector((state) => state.modals.ReplaceAllModal.replace);
  const number = useSelector((state) => state.modals.ReplaceAllModal.number);
  const matchCase = useSelector((state) => state.modals.ReplaceAllModal.matchCase);
  const wholeWords = useSelector((state) => state.modals.ReplaceAllModal.wholeWords);

  const close = () => {
    dispatch(closeAndResetModal('ReplaceAllModal'));
  };

  const replaceAll = async () => {
    const res = await EditorManager.getInstance().replaceMatch(
      replace,
      matchCase,
      wholeWords,
      true,
    );

    const totalWords = find.split(' ').length;

    if (res) {
      dispatch(
        setInstances({
          instances: res.matches,
          currentResult: res.currentMatchIndex || -1,
          currentCanBeReplaced: res.editable,
        }),
      );
      notify({
        type: 'success',
        title: 'WORD_REPLACED',
        message: 'ALL_INSTANCES_OF_THE_WORD_WERE_SUCCESSFULLY_REPLACED',
        messageValues: { totalWords, find, replace },
      });
    }
    close();
  };

  return (
    <Modal open={!!isOpen} onClose={close} testId="replace-all">
      <Modal.Header>
        <FormattedMessage id="REPLACE_ALL_INSTANCES" />
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="REPLACE_ALL_INSTANCES_TEXT"
          values={{
            findWord: find,
            replaceWord: replace,
            number,
            p: (chunks: any) => <div className={styles.block}>{chunks}</div>,
            b: (chunks: any) => <span className={styles.bold}>{chunks}</span>,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="replace-all-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={replaceAll}
          testId="replace-all-modal-submit-button"
        >
          <FormattedMessage id="PROCEED" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplaceAllModal;
