import { useMemo, useState } from 'react';
import { Checkbox } from 'dodoc-design-system';

import ElementTwister from './ElementTwister/ElementTwister';
import ElementLabel from 'Editor/components/ElementLabel/ElementLabel';

import styles from './Element.module.scss';
import useConditionalData from '../../useConditionalData';

//TODO:TS This type probably should be related to state.editor.toc.data

export type ElementProps = {
  element: Editor.ToCItem;
  toggleElement: (id: Editor.ToCItem['id']) => void;
  selectedItems: Record<Editor.ToCItem['id'], 'indeterminate' | boolean>;
  indent: number;
};

export const getChildIndentation = (
  subType: Editor.ToCItem['st'],
  indent: ElementProps['indent'],
) => {
  switch (subType) {
    case 't':
    case 'h1':
      return 0;
    case 'h2':
      return 1;
    case 'h3':
      return 2;
    default:
      return indent + 1;
  }
};

const ElementComponent = ({ element, toggleElement, selectedItems, indent }: ElementProps) => {
  const [isOpen, setOpen] = useState(true);
  const { navigationData } = useConditionalData();
  const redacted = element.content === null;

  const onChange = () => {
    toggleElement(element.id);
  };

  const filterCitations = (elementId: Editor.ToCItem['id']) =>
    navigationData[elementId].type !== 'citation';

  const childNodes = element.childNodes.filter(filterCitations);

  const checked = useMemo(() => {
    if (selectedItems[element.id] === 'indeterminate') {
      return 'indeterminate';
    } else if (selectedItems[element.id]) {
      return 'checked';
    } else {
      return 'unchecked';
    }
  }, [selectedItems, element]);

  return (
    <>
      <div className={styles.element} style={{ paddingLeft: `${indent * 3.5}rem` }}>
        <ElementTwister isOpen={isOpen} setOpen={setOpen} hide={childNodes.length === 0} />
        <Checkbox checked={checked} onChange={onChange} disabled={redacted} testId={`${element.label}-checkbox`}>
          <ElementLabel element={element} />
        </Checkbox>
      </div>
      {isOpen &&
        childNodes.length > 0 &&
        childNodes.map((elementId) => {
          const element = navigationData[elementId];
          return (
            <ElementComponent
              key={element.id}
              selectedItems={selectedItems}
              element={element}
              toggleElement={toggleElement}
              indent={getChildIndentation(element.st, indent)}
            />
          );
        })}
    </>
  );
};
export default ElementComponent;
