import { Structure } from 'Editor/modals/EquationsModal/ToolbarElements';
import { useEquationsContext } from 'Editor/modals/EquationsModal/EquationsContext';

import styles from './GridItem.module.scss';
import { useEffect, useRef } from 'react';
import { Logger } from '_common/services';

type GridItemProps = {
  element: Structure['elements'][number];
  testId: string;
};

const GridItem = ({ element, testId }: GridItemProps) => {
  const { onElementInsertion } = useEquationsContext();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';

      if (element) {
        try {
          ref.current.appendChild(MathJax.mathml2svg(element.mathml));
          MathJax.startup.document.clear();
          MathJax.startup.document.updateDocument();
        } catch (error) {
          Logger.error("Error!!");
        }
      }
    }
  }, [element]);

  const onClick = () => {
    onElementInsertion(element);
  };
  return (
    <div
      className={styles.grid_item}
      onClick={onClick}
      style={{
        gridArea: `span ${element.repX || 1} / span ${element.repY || 1}`,
      }}
      ref={ref}
    />
  );
};

export default GridItem;
