import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Checkbox } from 'dodoc-design-system';
import { CheckboxProps } from 'dodoc-design-system/build/types/Components/Checkbox/Checkbox';

import styles from './Section.module.scss';

type TableHeaderProps = Pick<Editor.Styles.TableProperties, 'autoResize'> & SectionProps;

const TableHeader = ({ autoResize, updateData }: TableHeaderProps) => {
  const intl = useIntl();

  const [checkState, setCheckState] = useState<CheckboxProps['checked']>(
    autoResize ? 'checked' : 'unchecked',
  );

  useEffect(() => {
    setCheckState(autoResize ? 'checked' : 'unchecked');
  }, [autoResize]);

  const handleHeaderRowChange = () => {
    setCheckState((prevState) => {
      switch (prevState) {
        case 'unchecked':
          updateData({ property: 'autoResize', value: true });
          return 'checked';
        default:
          updateData({ property: 'autoResize', value: false });
          return 'unchecked';
      }
    });
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'MORE_OPTIONS' })}
      initialCollapsed={false}
      contentMargin="2rem 1rem 0"
      testId="tableProperties-more-options-accordion"
    >
      <div className={`${styles.root} ${styles.fitContent}`}>
        <Checkbox
          size="x-small"
          checked={checkState}
          onChange={handleHeaderRowChange}
          testId="auto-resize-to-fit-contents-checkbox"
        />
        <FormattedMessage id="AUTOMATICALLY_RESIZE_TO_FIT_CONTENTS" />
      </div>
    </Accordion>
  );
};

export default TableHeader;
