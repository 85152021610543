import { useIntl } from 'react-intl';
import { Tooltip } from 'dodoc-design-system';

import { usePublicProfiles, useSelector } from '_common/hooks';

import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';

type LikesTooltipProps = { votes?: Editor.Comment.Vote[] } & Omit<TooltipProps, 'content'>;

const LikesTooltip = ({ votes, ...props }: LikesTooltipProps) => {
  const intl = useIntl();
  const user = useSelector((state) => state.auth.userId);
  const { profiles } = usePublicProfiles(votes?.map((vote) => vote.user));

  const getTooltipContent = (users: typeof votes, size = 5) => {
    if (!users) {
      return '';
    }

    if (size < 1) {
      size = 5;
    }

    let content = '';
    const self = users.find((vote) => vote.user === user);

    let values = [...users];
    const totalLiked = values.length;

    if (totalLiked > 0 && size > 0) {
      if (size > totalLiked) {
        size = totalLiked;
      }

      if (totalLiked === 1) {
        if (self) {
          content = intl.formatMessage({ id: 'SELF_LIKED' });
        } else {
          content = intl.formatMessage(
            { id: 'USER_LIKED' },
            { user: profiles[values[totalLiked - 1].user]?.name },
          );
        }

        return content;
      }
      values.sort((a, b) =>
        a.time !== '' && b.time !== ''
          ? new Date(a.time).getTime() - new Date(b.time).getTime()
          : 1,
      );

      const remainingCount = totalLiked - size;

      if (self) {
        const index = values.indexOf(self);
        if (index > -1) {
          values.splice(index, 1);
          size -= 1;
        }
      }

      const lastUser = values[values.length - 1];
      const lastIndex = values.indexOf(lastUser);

      if (lastIndex > -1) {
        values.splice(lastIndex, 1);
      }

      if (size < 1) {
        values = [];
      } else {
        values = values.slice(-size);
      }

      const usersList = values
        .map(
          (user, index) =>
            (index > 0 || (index === 0 && self) ? ', ' : '') + profiles[user.user]?.name,
        )
        .join('');

      if (remainingCount > 0) {
        if (self) {
          content = intl.formatMessage(
            { id: 'SELF_USERS_AND_MORE_LIKED' },
            { users: usersList, remainingCount },
          );
        } else {
          content = intl.formatMessage(
            { id: 'USERS_AND_MORE_LIKED' },
            { users: usersList, remainingCount },
          );
        }
      } else if (self) {
        content = intl.formatMessage(
          { id: 'SELF_USERS_AND_USER_LIKED' },
          { users: values.length > 0 && usersList, lastUser: profiles[lastUser.user]?.name },
        );
      } else {
        content = intl.formatMessage(
          { id: 'USERS_AND_USER_LIKED' },
          { users: usersList, lastUser: profiles[lastUser.user]?.name },
        );
      }
    }

    return content;
  };

  return (
    <Tooltip
      {...props}
      disabled={props.disabled !== undefined ? props.disabled : !votes ? true : votes.length < 1}
      content={getTooltipContent(votes)}
    />
  );
};

export default LikesTooltip;
