import { ReactNode } from 'react';
import { Root } from 'react-dom/client';
import ReactUtils from '_common/utils/ReactUtils';

export abstract class WidgetViewModel<T extends Editor.Visualizer.WidgetTypes>
  implements Editor.Visualizer.IWidgetViewModel
{
  protected Visualizer: Editor.Visualizer.State;
  protected refView: Editor.Visualizer.WidgetDataMapper[T]['view'];
  protected props: Editor.Visualizer.WidgetDataMapper[T]['props'];
  protected container: HTMLElement;
  protected root: Root | null;

  constructor(
    Visualizer: Editor.Visualizer.State,
    refView: Editor.Visualizer.WidgetDataMapper[T]['view'],
    props: Editor.Visualizer.WidgetDataMapper[T]['props'],
  ) {
    this.Visualizer = Visualizer;
    this.refView = refView;
    this.props = props;
    this.container = document.createElement('div');
    this.container.dataset.viewId = this.refView.id;
    this.root = null;
  }

  abstract render(): void;

  dispose() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
    this.container.remove();
  }

  hide() {
    this.unmountReactComponent();
  }

  updateProps(props: Editor.Visualizer.WidgetDataMapper[T]['props']) {
    this.props = props;
  }

  getContainer(): HTMLElement {
    return this.container;
  }

  getRefView(): Editor.Visualizer.WidgetDataMapper[T]['view'] {
    return this.refView;
  }

  protected renderReactComponent(component: ReactNode) {
    if (!this.root) {
      this.root = ReactUtils.createReactRoot(this.container);
    }

    ReactUtils.renderReactComponent(component, this.root);
  }

  protected unmountReactComponent() {
    if (this.root) {
      this.root.unmount();
    }
    this.root = null;
  }
}
