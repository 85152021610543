import { useSelector } from '_common/hooks';
import useSlideId from './useSlideId';

const useSlideMissingFonts = () => {
  const currentSlideId = useSlideId();
  const missingFonts = useSelector((state) => state.fonts.missing);
  const slideFonts = useSelector((state) => state.presentation.general.fontsBySlide)[
    currentSlideId
  ];

  return missingFonts.filter((font) => slideFonts?.[font.label]);
};

export default useSlideMissingFonts;
