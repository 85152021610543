import { useTasks } from 'Presentation/SyncStore';
import { dayjs } from 'utils';

//Return the order of ALL tasks
const useTasksOrder = (slideId?: Presentation.Data.Slide['_id']) => {
  const data = useTasks()?.data;
  const tasks = data ? Object.values(data) : [];
  const order: { [x in Presentation.Data.Task['id']]: number } = {};

  const filterTask = (task: Presentation.Data.Task) => {
    if (slideId) {
      return task.anchor[0].id === slideId;
    }

    return true;
  };

  tasks
    .filter(filterTask)
    .sort((a, b) => (dayjs(a.creationDate).isAfter(b.creationDate) ? 1 : -1))
    .forEach((task, i) => {
      order[task.id] = i + 1;
    });

  return order;
};

export default useTasksOrder;
