import { ErrorElementNotEditable } from '../../Errors';
import { Command } from '../Command';
import { NodeUtils } from 'Editor/services/DataManager';

export class DeleteTableCommand extends Command {
  async handleExec(): Promise<void> {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid context');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    const range = this.actionContext.range;

    const closestTable = NodeUtils.closestOfTypeByPath(
      this.actionContext.baseData,
      range.start.p,
      'tbl',
    );

    if (closestTable && closestTable.data.id) {
      this.context.VisualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(
        closestTable.data.id,
      );

      // select table
      range.updateRangePositions(
        {
          b: range.start.b,
          p: [...closestTable.path, 'childNodes', 0],
        },
        {
          b: range.start.b,
          p: [...closestTable.path, 'childNodes', closestTable.data.childNodes?.length || 0],
        },
      );

      this.context.contentManipulator?.removeContent(this.actionContext);

      this.handleSuggestionsUpdate();

      this.applySelection();

      this.createPatch();
    }
  }
}
