export type Measure = 'rem' | 'px' | 'pt' | 'cm' | 'in';

type ParseOptions = {
  min?: number;
  max?: number;
  valid: Measure[];
  defaultUnit: Measure;
  toFixed: number;
};

const MEASURE_RATIO: { [key in Measure]: { [key in Measure]: number } } = {
  rem: {
    rem: 1,
    px: 8,
    pt: 12,
    cm: 1 / 2.3622,
    in: 1 / 6,
  },
  px: {
    rem: 1 / 8,
    px: 1,
    pt: 0.75,
    cm: 1 / 37.79528,
    in: 1 / 96,
  },
  cm: {
    rem: 2.3622,
    px: 37.79528,
    pt: 28.346456693,
    cm: 1,
    in: 1 / 2.54,
  },
  in: {
    rem: 6,
    px: 96,
    pt: 72,
    cm: 2.54,
    in: 1,
  },
  pt: {
    rem: 1 / 12,
    px: 1 / 0.75,
    pt: 1,
    cm: 1 / 28.346456693,
    in: 1 / 72,
  },
} as const;

export const MEASURE_MIX_MAX_VALUE: { [key in string]: { [key in string]: number } } = {
  px: {
    min: -96,
    max: 288,
  },
  cm: {
    min: -2.54,
    max: 7.62,
  },
  in: {
    min: -1,
    max: 3
  },
  pt: {
    min: -72,
    max: 216,
  },
} as const;

const DEFAULT_OPTIONS: ParseOptions = {
  valid: ['cm', 'in', 'pt', 'px'],
  defaultUnit: 'cm',
  toFixed: 2,
};

/**
 * convert ui measure values in px, in, cm or pt to a desired unit, default to pt
 *
 * @param {number} value original value (with or without unit)
 * @param {Measure} originUnit original unit (can be null if it is in the value param)
 * @param {string} [convertionUnit='pt'] unit to convert to
 * @param {number} [toFixed=0] number of decimal places
 * @returns converted value without the unit
 */
const convertUnit = (
  value: number,
  originUnit: Measure,
  convertionUnit: Measure = 'pt',
  toFixed = 0,
) => {
  let stringValue = value.toString();

  if (!originUnit) {
    const indexOfUnit = stringValue.search(/cm|in|pt|px/);
    if (indexOfUnit > 0) {
      originUnit = stringValue.substring(indexOfUnit) as Measure;
    }
    stringValue = stringValue.replace(/cm|in|pt|px/g, '');
  }

  const _value = parseFloat(stringValue);
  if (originUnit && !Number.isNaN(_value) && originUnit.match(/^(cm|in|pt|px)$/)) {
    return +(MEASURE_RATIO[originUnit][convertionUnit] * _value).toFixed(toFixed);
  }
};

/**
 * This function parses a string to identify the measurement value and unit
 * It then converts the value to a specified unit and returns it.
 * A custom options object can also be provided *
 *
 * @param {string} str
 * @param {string} convertTo
 * @param {Object} [customOptions] Several custom options for validation and conversion
 * @param {number} [customOptions.min]: Minimum value of the converted result
 * @param {number} [customOptions.max]: Maximum value of the converted result
 * @param {Array} [customOptions.valid]: Array of valid units
 * @param {string} [customOptions.defaultUnit]: If no unit is found when parsing or if it is invalid, it defaults to this unit
 * @param {number} [customOptions.toFixed]: Number of decimal places
 * @returns the parsed measurement value converted to the specified unit
 */
function parseMeasurement(str: string, convertTo: Measure, customOptions: Partial<ParseOptions> = {}) {
  const regex = /^\s*((-?[0-9]+)([\\.,][0-9]+)?)\s*(\S{2})?\s*$/gi;
  const match = regex.exec(str);

  let options: ParseOptions;
  options = {min: MEASURE_MIX_MAX_VALUE[convertTo]['min'], max:  MEASURE_MIX_MAX_VALUE[convertTo]['max'], ...DEFAULT_OPTIONS, ...customOptions};

  if (match) {
    let unit = (match[4] || '').toLowerCase() as Measure;
    const value = Number(match[1]) || 0;

    if (!options.valid.includes(unit)) {
      unit = options.defaultUnit as Measure;
    }

    let normalized = value;
    if (convertTo && value) {
      const converted = convertUnit(value, unit, convertTo, options.toFixed);
      if (converted) {
        normalized = converted;
      }
    }

    if (options.min && options.min !== null && normalized < options.min) {
      normalized = options.min;
    } else if (options.max && options.max !== null && normalized > options.max) {
      normalized = options.max;
    }

    return normalized;
  }
  return null;
}

export default parseMeasurement;
