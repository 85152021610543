import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { useGetRolesListQuery } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';
import { useAddPermissionMutation, useRemovePermissionMutation } from 'App/redux/objectApi';

import { usePermissions } from '_common/components/Permissions/PermissionsContext';

import styles from './PermissionPopover.module.scss';

type PermissionPopoverProps = {
  objectId: ObjectId;
  permission: Permission.CollaboratorPermission;
  collaboratorType: Permission.Collaborator['collaboratorType'];
  collaboratorId: Permission.Collaborator['collaboratorId'];
  recursive: boolean;
};

const PermissionPopover = ({
  objectId,
  permission,
  collaboratorType,
  collaboratorId,
  recursive,
}: PermissionPopoverProps) => {
  const data = useSelector((state) => state.app.data[objectId]);
  const { data: roles } = useGetRolesListQuery();
  const publicGroups = useSelector((state) => state.public.groups.profiles);

  const [addPermission] = useAddPermissionMutation();
  const [removePermission] = useRemovePermissionMutation();

  const { canEditPermission } = usePermissions();

  const formKey = collaboratorType === 'user' ? 'users' : 'groups';

  const userRoles = useMemo(() => {
    const rolesWithPermission: { id: string; name: string; active: boolean }[] = [];

    if (roles) {
      data.permissions.roles[formKey][collaboratorId]?.forEach((roleId: string) => {
        const role = roles.dict[roleId];

        rolesWithPermission.push({
          id: roleId,
          name: role.name,
          active: role.granted.includes(permission) || role.granted.includes('owner'),
        });
      });
    }

    return rolesWithPermission;
  }, [roles, permission]);

  const userGroups = useMemo(() => {
    const groupRolesHasPermission = (groupId: string) => {
      let hasPermission = false;

      if (roles) {
        //@ts-expect-error Objekt permissions doesnt have type
        data.permissions.roles?.groups?.[groupId]?.forEach((roleId) => {
          if (roles.dict[roleId]) {
            if (
              roles.dict[roleId].granted.includes(permission) ||
              roles.dict[roleId].granted.includes('owner')
            ) {
              hasPermission = true;
              return;
            }
          }
        });
      }

      return hasPermission;
    };

    const groupsWithPermission: { id: string; name: string; active: boolean }[] = [];

    if (publicGroups) {
      Object.keys(data.permissions.groups)?.forEach((groupId: string) => {
        const group = publicGroups[groupId];

        if (group?.users?.includes(collaboratorId)) {
          const isActive =
            data.permissions.groups[groupId].includes(permission) ||
            data.permissions.groups[groupId].includes('owner') ||
            groupRolesHasPermission(groupId);

          groupsWithPermission.push({
            id: groupId,
            name: group.name,
            active: isActive,
          });
        }
      });
    }

    return groupsWithPermission;
  }, [data, permission, publicGroups, roles]);

  const granularPermissionIsActive = useMemo<boolean>(() => {
    if (data?.permissions[formKey] && data?.permissions[formKey][collaboratorId]) {
      return data.permissions[formKey][collaboratorId].includes(permission);
    }

    return false;
  }, [data, formKey]);

  const handleChange = () => {
    if (granularPermissionIsActive) {
      removePermission({
        code: permission,
        user: collaboratorId,
        recursive,
        objectId,
        objectType: data.type,
      });
    } else {
      addPermission({
        code: permission,
        user: collaboratorId,
        recursive,
        objectId,
        objectType: data.type,
      });
    }
  };

  return (
    <div className={styles.root}>
      <Checkbox
        size="small"
        margin="0 0 0.5rem 0"
        checked={granularPermissionIsActive ? 'checked' : 'unchecked'}
        onChange={handleChange}
        disabled={!canEditPermission(collaboratorId, granularPermissionIsActive)}
        testId={`popper-${permission}-granular-checkbox`}
      >
        <FormattedMessage id="GRANULAR_PERMISSIONS" />
      </Checkbox>

      {userRoles.length > 0 && (
        <>
          <div className={styles.title}>
            <FormattedMessage id="ROLES_WITH_THIS_PERMISSION" />
          </div>
          <div className={styles.permissions}>
            {userRoles.map((role, i) => {
              return (
                <Checkbox
                  disabled
                  size="small"
                  checked={role.active ? 'checked' : 'unchecked'}
                  key={i}
                  testId={`popper-${permission}-role-${role.id}-checkbox`}
                >
                  {role.name}
                </Checkbox>
              );
            })}
          </div>
        </>
      )}

      {userGroups.length > 0 && (
        <>
          <div className={styles.title}>
            <FormattedMessage id="GROUPS_WITH_THIS_PERMISSION" />
          </div>
          <div className={styles.permissions}>
            {userGroups.map((group, i) => {
              return (
                <Checkbox
                  disabled
                  size="small"
                  checked={group.active ? 'checked' : 'unchecked'}
                  key={i}
                  testId={`popper-${permission}-group-${group.id}-checkbox`}
                >
                  {group.name}
                </Checkbox>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PermissionPopover;
