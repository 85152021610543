import { INDENT_TYPE } from 'Editor/services/consts';
import { DataManagerAPI, NodeNumberingData } from 'Editor/services/DataManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { Logger } from '_common/services';
import { CSSRuleFormatter } from './CSSRuleFormatter';

export class NumberingCSSApplier {
  protected Data: DataManagerAPI;

  constructor(Data: DataManagerAPI) {
    this.Data = Data;
  }

  getCssRule(nodeNumbering: NodeNumberingData, cssSelector: string) {
    try {
      if (!nodeNumbering || nodeNumbering.listId === null) {
        return '';
      }
      const level = nodeNumbering ? nodeNumbering.level : 0;
      const listStyle = this.Data.numbering.getStyleIdForList(nodeNumbering.listId);
      if (!listStyle) {
        return '';
      }
      const isBulletList = this.Data.styles.listStyles.style(listStyle)?.isBulletList();

      const levelDef = this.Data.numbering.applier.levelDefinition(
        nodeNumbering.listId,
        `${level}`,
      );
      if (!levelDef) {
        return '';
      }
      let indLeft = levelDef.ind != null && levelDef.ind.l != null ? +levelDef.ind.l : null;

      if (indLeft != null) {
        if (indLeft > 0) {
          indLeft = Math.min(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MAX, indLeft);
        } else {
          indLeft = Math.max(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MIN, indLeft);
        }
      }

      let indRight = levelDef.ind != null && levelDef.ind.r != null ? +levelDef.ind.r : null;

      if (indRight != null) {
        if (indRight > 0) {
          indRight = Math.min(StylesUtils.INDENTATION_LIMITS.RIGHT.RENDER_MAX, indRight);
        } else {
          indRight = Math.max(StylesUtils.INDENTATION_LIMITS.RIGHT.RENDER_MIN, indRight);
        }
      }

      let textInd;
      let paddingLeft;
      let hanging;

      if (levelDef.sp_ind?.v != null) {
        let specialIndentValue = +levelDef.sp_ind.v;
        if (specialIndentValue > 0) {
          specialIndentValue = Math.min(
            StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.RENDER_MAX,
            specialIndentValue,
          );
        } else {
          specialIndentValue = Math.max(
            StylesUtils.INDENTATION_LIMITS.SPECIAL_INDENT.RENDER_MIN,
            specialIndentValue,
          );
        }

        //@ts-expect-error
        let sp_ind_type = levelDef.sp_ind?.t.value?.t || levelDef.sp_ind.t;
        switch (sp_ind_type) {
          case INDENT_TYPE.HANGING:
            textInd = -specialIndentValue;
            paddingLeft = specialIndentValue;
            hanging = specialIndentValue;
            break;
          case INDENT_TYPE.FIRST_LINE:
            textInd = specialIndentValue;
            paddingLeft = 0;
            break;
          default:
            break;
        }
      }

      let cssRule = new CSSRuleFormatter();
      cssRule
        .setSelector(cssSelector)
        .setAttribute('display', 'block')
        .setAttribute('text-indent', textInd, 'pt')
        .setAttribute('--leftPadding', paddingLeft, 'pt')
        .setAttribute('--rightMargin', indRight, 'pt')
        .setAttribute('--leftMargin', indLeft, 'pt')
        .setAttribute('--beforeMargin', 0, 'px');
      if (hanging != null) {
        cssRule
          .setAttribute('--hanging', hanging, 'pt')
          .setAttribute('--beforeDisplay', `inline-block`)
          .setAttribute('--beforeWidth', hanging, 'pt')
          .setAttribute('--beforeTextIndent', 0, 'px')
          .setAttribute('--afterContent', 'none');
      }

      if (levelDef.fnw === 'not' || levelDef.fnw === 'spc') {
        cssRule.setAttribute('--beforeWidth', 0, 'px');
      }

      let content;
      if (levelDef.fnw === 'spc') {
        content = `'${this.Data.numbering.applier.applyStyle(nodeNumbering)} '`;
      } else {
        content = `'${this.Data.numbering.applier.applyStyle(nodeNumbering)}'`;
      }

      let cssBeforeRule = new CSSRuleFormatter();
      cssBeforeRule.setSelector(`${cssSelector}:before`).setAttribute('content', content);

      if (levelDef.nf === 'b' && levelDef.ff !== 'Symbol') {
        cssBeforeRule.setAttribute('font-family', levelDef.ff);
      } else if (levelDef.ff) {
        cssBeforeRule.setAttribute('font-family', levelDef.ff);
      }

      if (isBulletList) {
        cssBeforeRule.setAttribute('font-weight', 'normal');
        cssBeforeRule.setAttribute('font-style', 'normal');
      }

      return CSSRuleFormatter.concatRules(cssRule.getRule(), cssBeforeRule.getRule());
    } catch (error) {
      Logger.captureException(error);
    }
    return '';
  }
}
