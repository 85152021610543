import { BaseRangeFixer } from '../BaseRangeFixer';
import { HTMLSelectionNormalizer, JSONSelectionNormalizer } from '.';

export class TextSelectionNormalizer extends BaseRangeFixer {
  protected options: Partial<Editor.Selection.FixerOptions>;
  protected DataManager?: Editor.Data.API;

  constructor(args: Partial<Editor.Selection.FixerOptions>, DataManager?: Editor.Data.API) {
    super();
    this.DataManager = DataManager;
    this.options = args;
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    if (!this.DataManager) {
      throw new Error('No DataManager provided!');
    }

    let baseModel: Editor.Data.Node.Model | undefined;
    let baseData: Editor.Data.Node.Data | null | undefined;

    if (!range.collapsed) {
      // non collapsed selection

      // start container
      baseModel = this.DataManager.nodes.getNodeModelById(range.start.b);
      baseData = baseModel?.selectedData();

      if (baseData) {
        const fixedPath = new JSONSelectionNormalizer({
          ...this.options,
          forceNonEditableDirection: 'BACKWARD',
        }).normalize(baseData, range.start.p);
        if (fixedPath) {
          range.start.p = fixedPath;
        }
      }

      // end container
      baseModel = this.DataManager.nodes.getNodeModelById(range.end.b);
      baseData = baseModel?.selectedData();

      if (baseData) {
        const fixedPath = new JSONSelectionNormalizer({
          ...this.options,
          forceNonEditableDirection: 'FORWARD',
        }).normalize(baseData, range.end.p);
        if (fixedPath) {
          range.end.p = fixedPath;
        }
      }
    } else {
      //collapsed selection
      baseModel = this.DataManager.nodes.getNodeModelById(range.start.b);
      baseData = baseModel?.selectedData();

      if (baseData) {
        const fixedPath = new JSONSelectionNormalizer(this.options).normalize(
          baseData,
          range.start.p,
        );
        if (fixedPath) {
          range.start.p = fixedPath;
          range.end.p = fixedPath;
        }
      }
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    if (!range.collapsed) {
      // non collapsed selection

      // start container
      this.options.containerPosition = 'start';
      new HTMLSelectionNormalizer(this.options).normalize(range);

      // end container
      this.options.containerPosition = 'end';
      new HTMLSelectionNormalizer(this.options).normalize(range);
    } else {
      //collapsed selection
      this.options.containerPosition = undefined;
      new HTMLSelectionNormalizer(this.options).normalize(range);
    }
  }
}
