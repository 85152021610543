import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Logo } from 'dodoc-design-system';
import AuthFooter from 'Auth/components/AuthFooter/AuthFooter';
import styles from './AuthHero.module.scss';
import { useLoginSetupQuery } from '_common/services/api/authority';

/**
 * AuthHero comment.
 *
 * @author    José Nunes <jnunes@dodoc.com>
 * @author    José Viegas <jviegas@dodoc.com>
 * @copyright 2017 doDOC
 */

const AuthHero = ({ children }: { children?: ReactNode }) => {
  const { data: setupData } = useLoginSetupQuery();
  const customMessage = setupData?.custom_message;

  return (
    <div className={styles.root}>
      <Logo size="small" expanded color="white" testId="auth-hero-logo" />

      {children || (
        <div className={styles.text}>
          <FormattedMessage id="HERO_TITLE" />
        </div>
      )}
      {!children && customMessage && (
        <div className={styles.customMessage} data-testid="auth-hero-custom-message">
          {customMessage}
        </div>
      )}
      <AuthFooter hero />
    </div>
  );
};

export default AuthHero;
