import { Fragment, memo, useMemo, useEffect } from 'react';
import { PageViewport } from 'pdfjs-dist';

import { useSelector } from '_common/hooks';

import styles from '../PDFPage/PDFPage.module.scss';

// Util function
const utilTransform = (transf1: number[], transf2: number[]) => {
  return [
    transf1[0] * transf2[0] + transf1[2] * transf2[1],
    transf1[1] * transf2[0] + transf1[3] * transf2[1],
    transf1[0] * transf2[2] + transf1[2] * transf2[3],
    transf1[1] * transf2[2] + transf1[3] * transf2[3],
    transf1[0] * transf2[4] + transf1[2] * transf2[5] + transf1[4],
    transf1[1] * transf2[4] + transf1[3] * transf2[5] + transf1[5],
  ];
};

type TextLayerProps = {
  textContent: PDF.Page.PDFPageContent | null;
  viewport: PageViewport;
  findHighlights: PDF.Find.FindHitType[] | null;
  pageNum: number;
};

const TextLayer = ({ textContent, viewport, findHighlights, pageNum }: TextLayerProps) => {
  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);
  const selectedInstance = useSelector((state) => state.pdf.find?.selected);

  const clientRects = useMemo(() => {
    const overlay = document.querySelector(
      `div[data-overlays-layer][data-page-num="${pageNum}"]`,
    ) as HTMLDivElement;
    if (findHighlights && overlay) {
      const value = overlay.getBoundingClientRect();
      return findHighlights.map((element) => {
        const range = document.createRange();
        const start = document.getElementById(element.start.id);
        const end = document.getElementById(element.end.id);
        if (start && end) {
          range.setStart(start?.childNodes[0], element.start.offset);
          range.setEnd(end?.childNodes[0], element.end.offset);
        }
        const rect = range.getBoundingClientRect();
        return {
          ...rect,
          id: element.id,
          top: rect.y - value.y,
          width: rect.width,
          height: rect.height,
          x: rect.x,
          y: rect.y,
          left: rect.left - value.left,
        };
      });
    }
    return [];
  }, [findHighlights, viewport.scale]);

  useEffect(() => {
    if (findHighlights && findHighlights.length && selectedInstance) {
      const instanceValues = findHighlights.find(
        (element: PDF.Find.FindHitType) => element.id === selectedInstance,
      );
      if (instanceValues && instanceValues.numPage === currentPageNumber) {
        let selection = window.getSelection();
        const range = document.createRange();
        selection?.removeAllRanges();
        selection?.addRange(range);

        const start = document.getElementById(instanceValues?.start.id);
        const end = document.getElementById(instanceValues?.end.id);
        if (start && end) {
          range.setStart(start.childNodes[0], instanceValues.start.offset);
          range.setEnd(end.childNodes[0], instanceValues.end.offset);
          const rect = start.getBoundingClientRect();
          const visible =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 160 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) - 160;
          if (!visible) {
            //setTimeout to focus the element after SCROLL_TO_PAGE scrollToItem is made on Content.tsx
            setTimeout(() => {
              start.scrollIntoView({ block: 'center' });
            }, 0);
          }
        }
      }
    }
  }, [findHighlights, selectedInstance, currentPageNumber]);

  const text = useMemo(() => {
    let elements = null;

    // TODO: check form empty content and if is all whitespace

    if (textContent?.items?.length) {
      let canvas = document.createElement('canvas');
      canvas.height = 30; // default
      canvas.width = 30; // default

      const canvasContext = canvas.getContext('2d', { alpha: false });

      elements = textContent.items.map((item: any, index: number) => {
        const result = utilTransform(viewport.transform, item.transform);

        const style = textContent.styles[item.fontName];

        let angle = Math.atan2(result[1], result[0]);

        if (style.vertical) {
          angle += Math.PI / 2;
        }

        const fontSize = Math.sqrt(result[2] * result[2] + result[3] * result[3]);
        let fontAdjust = fontSize;

        if (style.descent) {
          fontAdjust = (1 + style.descent) * fontSize;
        } else if (style.ascent) {
          fontAdjust = style.ascent * fontSize;
        }

        let fontFamily = style.fontFamily;
        try {
          if (
            !item.fontName.includes('g_d') &&
            document.fonts.check(`${fontSize}px ${item.fontName}`)
          ) {
            fontFamily = item.fontName;
          }
        } catch (e) {
          logger.error(e);
        }

        let left;
        let top;

        if (angle === 0) {
          left = result[4];
          top = result[5] - fontAdjust;
        } else {
          left = result[4] + fontAdjust * Math.sin(angle);
          top = result[5] - fontAdjust * Math.cos(angle);
        }

        let scaleText = false;

        if (item.str.length > 1) {
          scaleText = true;
        } else if (item.transform[0] !== item.transform[3]) {
          const absScaleX = Math.abs(item.transform[0]),
            absScaleY = Math.abs(item.transform[3]);

          if (
            absScaleX !== absScaleY &&
            Math.max(absScaleX, absScaleY) / Math.min(absScaleX, absScaleY) > 1.5
          ) {
            scaleText = true;
          }
        }

        let textWidth = 0;
        let transform = '';

        if (scaleText && canvasContext) {
          if (style.vertical) {
            textWidth = item.height * viewport.scale;
          } else {
            textWidth = item.width * viewport.scale;
          }

          if (textWidth !== 0) {
            canvasContext.font = `${fontSize}px ${fontFamily}`;

            const measure = canvasContext.measureText(item.str);

            if (measure.width > 0) {
              let textScale = textWidth / measure.width;
              transform = `scaleX(${textScale})`;
            }

            let textAngle = 0;
            if (angle !== 0) {
              textAngle = angle * (180 / Math.PI);
            }

            if (textAngle !== 0) {
              transform = `rotate(${textAngle}deg) ${transform}`;
            }
          }
        }

        return (
          <Fragment key={item.id}>
            <span
              id={item.id}
              dir={item.dir}
              className={styles.span}
              role="presentation"
              style={{
                top: `${top}px`,
                left: `${left}px`,
                fontSize: `${fontSize}px`,
                fontFamily,
                transform,
              }}
              data-elementtype="text"
            >
              {item.str}
            </span>
            {item.hasEOL && (
              <br
                className={styles.br}
                id={'br_' + item.id}
                key={'br_' + item.id}
                role="presentation"
              />
            )}
          </Fragment>
        );
      });

      // release resources
      canvas.width = 0;
      canvas.height = 0;
    }

    return elements;
  }, [textContent, viewport]);

  return (
    <>
      {clientRects.map((rect) => {
        return (
          <span
            id={rect.id}
            key={rect.id}
            className={styles.br}
            style={{
              top: rect.top,
              left: rect.left,
              backgroundColor: '#e9f1fe',
              mixBlendMode: 'multiply',
              width: rect.width,
              height: rect.height,
            }}
          />
        );
      })}
      {text}
    </>
  );
};

export default memo(TextLayer);
