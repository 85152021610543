import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

import styles from './WarningModal.module.scss';

const WarningModal = () => {
  const modalData = useModalData('WarningModal');
  if (!modalData) {
    return null;
  }

  const { title, description, groupTitle, groupItems } = modalData;

  const close = () => {
    closeModal('WarningModal');
  };

  return (
    <Modal open onClose={close} width="62rem" type="warning" testId="warning-modal">
      <Modal.Header onClose={close}>
        <FormattedMessage id={title} />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <FormattedMessage id={description} />
        </div>
        {!groupTitle || !groupItems ? null : (
          <div className={styles.group}>
            <span className={styles.title}>
              <FormattedMessage id={groupTitle} />:
            </span>
            {groupItems.map((groupItem) => (
              <div key={groupItem}>{groupItem}</div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="warning-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
