import { Checkbox } from 'dodoc-design-system';
import classNames from 'classnames';

import styles from './BaseRow.module.scss';
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';

export type BaseRowProps = {
  objectId: ObjectId;
  selected?: boolean;
  overdue?: boolean;
  processing?: boolean;
  selectable?: boolean;
  deactivated?: boolean;
  style?: CSSProperties;
  clickHandles: {
    handleCheckBoxClick: (e: React.ChangeEvent<HTMLInputElement>, objectId: ObjectId) => void;
    handleRowClick: (e: React.MouseEvent<HTMLDivElement>, objectId: ObjectId) => void;
    handleRowDoubleClick: (e: React.MouseEvent<HTMLDivElement>, objectId: ObjectId) => void;
  };
  children: ReactNode;
  disabled?: boolean;
  dataTestId?: {
    root?: string;
    row?: string;
    checkbox?: string;
  };
};

function BaseRow({
  objectId,
  selected = false,
  overdue,
  processing,
  selectable = true,
  deactivated,
  style,
  clickHandles,
  children,
  disabled,
  dataTestId,
}: BaseRowProps) {
  const handleCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    clickHandles.handleCheckBoxClick(e, objectId);
  };

  const handleRowClick: MouseEventHandler<HTMLDivElement> = (e) => {
    clickHandles.handleRowClick(e, objectId);
  };

  const handleRowDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    clickHandles.handleRowDoubleClick(e, objectId);
  };

  return (
    <div
      style={style}
      className={`${styles.row}
      ${classNames(
        selected && styles.selected,
        overdue && styles.overdue,
        processing && styles.processing,
        selectable && styles.selectable,
        deactivated && styles.deactivated,
      )}
      `}
      data-testid={dataTestId?.root}
    >
      {selectable && (
        <div className={`${styles.item} ${styles.checkBox}`}>
          <div className={styles.iconWrapper}>
            <Checkbox
              onChange={handleCheckBoxClick}
              size="small"
              checked={selected ? 'checked' : 'unchecked'}
              testId={dataTestId?.checkbox ?? 'checkbox'}
            />
          </div>
        </div>
      )}
      <div
        style={{ display: 'flex', width: '100%', height: '100%' }}
        onClick={disabled ? () => {} : handleRowClick}
        onDoubleClick={handleRowDoubleClick}
        data-testid={dataTestId?.row}
      >
        {children}
      </div>
    </div>
  );
}

export default BaseRow;
