import { Logger } from '_common/services';
import { Command } from '../Command';

export class ArrowLeftCommand extends Command {
  private event: KeyboardEvent;

  constructor(context: Editor.Edition.Context, event: KeyboardEvent) {
    super(context, {
      scrollIntoSelection: false,
      removePasteOptions: false,
      updateModifiers: false,
    });
    this.event = event;
  }

  async handleExec(): Promise<void> {
    if (this.debug) {
      Logger.trace('ArrowLeftCommand exec', this);
    }

    this.buildActionContext();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.selection?.modifiers ||
      !this.actionContext
    ) {
      throw new Error('Invalid context');
    }

    // TODO fix page selection if needed

    let shiftKey = this.event.shiftKey;
    let ctrlKey = this.event.ctrlKey;

    if (!shiftKey && !ctrlKey) {
      this.context.selection.modifiers.modify(
        this.actionContext.range,
        'move',
        'character',
        'backward',
      );
    } else if (shiftKey && ctrlKey) {
      this.context.selection.modifiers.modify(
        this.actionContext.range,
        'expand',
        'word',
        'backward',
      );
    } else if (shiftKey) {
      this.context.selection.modifiers.modify(
        this.actionContext.range,
        'expand',
        'character',
        'backward',
      );
    } else if (ctrlKey) {
      this.context.selection.modifiers.modify(this.actionContext.range, 'move', 'word', 'backward');
    }

    this.applySelection();
  }
}
