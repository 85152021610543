import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyCardListState, Switch } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  selectPDFTasks,
  selectFilteredPDFTasks,
  toggleTasksListMode,
} from 'PDF/redux/PDFAnnotationsSlice';
import usePDFData from 'PDF/hooks/usePDFData';

import Header from '../Header/Header';
import { EmptyFilteredState, FilterDisplay } from '_common/components';
import { TaskFilters } from '_common/suite/components';
import Search from './Search/Search';
import EditableTaskCard from './TaskCard/EditableTaskCard/EditableTaskCard';
import AnnotationCard from '../AnnotationsPanel/AnnotationCard/AnnotationCard';

import panelStyles from '../RightSidePanel.module.scss';
import styles from './TasksPanel.module.scss';
import { dayjs } from 'utils';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';
import { useTotalMatchedFilters } from '_common/components/Filters';

export type TasksPanelProps = {
  /** ID to be used in unit or automated tests.
   * Will result in 5 different attributes:
   * * ${testId}-emptyState
   * * ${testId}-taskcard-creating
   * * ${testId}-taskcard-${index}-root
   * * ${testId}-taskcard-${index}-editable
   * * ${testId}-taskcard-${index}-view
   */
  testId: string;
};

const Tasks = ({ testId }: TasksPanelProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const object = usePDFData();
  const creating = useSelector((state) => state.pdf.annotations.creating);
  const allTasksList = useSelector(selectPDFTasks);
  const taskList = useSelector(selectPDFTasks).filter((task) => task.state !== 'Cancelled');
  const tasksFilteredList = useSelector(selectFilteredPDFTasks);
  const tasksSearchBy = useSelector((state) => state.pdf.annotations.tasksSearchBy);
  const totalTasksFilters = useTotalMatchedFilters({ identity: 'reviewerTaskPanel' });
  const tasksListMode = useSelector((state) => state.pdf.annotations.tasksListMode);
  const collaborators = usePDFCollaborators();

  const options = useMemo(() => {
    return collaborators.map((id) => ({
      id: id,
      value: id,
      type: 'user',
      label: '',
    }));
  }, [collaborators]);

  const tasksOrder = useMemo(() => {
    const order: { [x in PDF.Annotation.Task['id']]: number } = {};
    allTasksList
      .sort((a, b) => (dayjs(a.creationDate).isAfter(b.creationDate) ? 1 : -1))
      .forEach((task, i) => {
        order[task.id] = i + 1;
      });

    return order;
  }, [allTasksList]);

  const handleListModeChange = () => {
    dispatch(toggleTasksListMode());
  };

  const renderEmptyState = () => {
    if (tasksSearchBy) {
      return (
        <div className={styles.emptyStateSearchBy} data-testid="sidebar-tasks-empty-search">
          <FormattedMessage id="NO_MATCHES_FOUND" />
        </div>
      );
    }
    if (!creating) {
      return <EmptyFilteredState identity="reviewerTaskPanel" size="medium" />;
    }
  };

  if (!object) {
    return null;
  }

  return (
    <>
      <Header>
        <FormattedMessage id="TASKS" />
        <TaskFilters
          tasksList={allTasksList}
          options={{
            users: options,
          }}
        />
      </Header>
      <div className={panelStyles.content}>
        {taskList.length === 0 && totalTasksFilters === 0 && !creating ? (
          <div className={styles.emptyState} data-testid={`${testId}-emptyState`}>
            <EmptyCardListState size="medium" testId="no-tasks-empty-card-list-state">
              <FormattedMessage id="NO_TASKS_YET" />
            </EmptyCardListState>
          </div>
        ) : (
          <>
            <div className={styles.tasksControl}>
              <div className={styles.tasksCounter}>
                {totalTasksFilters > 0 || tasksSearchBy ? (
                  <FormattedMessage
                    id="Y_OF_X_TASKS"
                    values={{ value: tasksFilteredList.length, total: taskList.length }}
                  />
                ) : (
                  <FormattedMessage
                    id="X_TASKS_IN_THE_DOCUMENT"
                    values={{ total: taskList.length }}
                  />
                )}
              </div>
              <div>
                <Switch
                  size="medium"
                  labelPlacement="left"
                  onChange={handleListModeChange}
                  active={tasksListMode}
                  testId={`${testId}-list-mode-switch`}
                >
                  {intl.formatMessage({ id: 'LIST_MODE' })}
                </Switch>
              </div>
            </div>
            <Search />
            <FilterDisplay identity="reviewerTaskPanel" direction="column" margin="0 0 2rem 1rem" />
            <div className={`${styles.taskList} ${tasksListMode && styles.listMode}`}>
              {creating && (
                <EditableTaskCard
                  mode="create"
                  creating={creating as PDF.TaskCreationData}
                  sidebar
                  testId={`${testId}-taskcard-creating`}
                />
              )}
              {tasksFilteredList.length > 0
                ? tasksFilteredList.map((task, index) => (
                    <AnnotationCard
                      key={`taskpanel-task-${task.id}`}
                      annotation={task}
                      sidebar
                      index={index}
                      order={tasksOrder[task.id]}
                      testId={`${testId}-taskcard-${index}`}
                    />
                  ))
                : renderEmptyState()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Tasks;
