import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import styles from './BrowserSupportWarning.module.scss';

const MODAL = 'BrowserSupportWarningModal';

const BrowserSupportWarning = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="browser-support-warning">
      <Modal.Header onClose={close}>
        <FormattedMessage id="BROWSER_SUPPORT_WARNING_HEADER" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.text}>
          <FormattedMessage id="BROWSER_SUPPORT_WARNING_TEXT_1" />
        </div>
        <div style={{ marginTop: '2rem' }} className={styles.text}>
          <FormattedMessage id="BROWSER_SUPPORT_WARNING_TEXT_2" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" variant="primary" onClick={close} testId="browser-support-warning-modal-continue-anyway">
          <FormattedMessage id="CONTINUE_ANYWAY" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BrowserSupportWarning;
