import { useSelector } from '_common/hooks';
import { InteractionController } from '_common/components';

import VersionHistoryToolbar from './VersionHistoryToolbar/VersionHistoryToolbar';
import ToolbarButtons from './ToolbarButtons/ToolbarButtons';
import ImageToolbar from './ImageToolbar/ImageToolbar';
import ToolbarToggles from './ToolbarToggles/ToolbarToggles';

import styles from './Toolbar.module.scss';

const Toolbar = () => {
  const inVersionHistory = useSelector((state) => state.editor.status.versionHistory);
  const isTextSelected = useSelector((state) => state.editor.status.selection.TEXT);
  const isImageSelected = useSelector((state) => state.editor.status.selection.IMAGE);

  const renderToolbars = () => {
    if (inVersionHistory) {
      return <VersionHistoryToolbar key="VERSION_TOOLBAR" />;
    } else if (isTextSelected) {
      return <ToolbarButtons key="TEXT_TOOLBAR" />;
    } else if (isImageSelected) {
      return <ImageToolbar key="IMAGE_TOOLBAR" />;
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <InteractionController
        environment="editor"
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        {renderToolbars()}
        <ToolbarToggles />
      </InteractionController>
    </div>
  );
};

export default Toolbar;
