import { Logger } from '_common/services';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import { NodeModel } from 'Editor/services/DataManager/models';
import { JsonRange, PathUtils } from 'Editor/services/_Common/Selection';
import { BaseViewModel } from '../BaseViewModel';
import { DocumentViewModel } from '../DocumentViewModel';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

type AllowedPendingEvents = 'LOADED' | 'RENDERED' | 'BUILDED';

export class BlockViewModel extends BaseViewModel {
  typeName = 'BlockViewModel';

  protected model: NodeModel;
  private errorState: boolean;

  private views: Editor.Visualizer.SplitView[];
  private pendingEvents: any = {};

  private debug: boolean = false;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);
    this.errorState = false;

    this.model = this.Data.models.get(this.Data.models.TYPE_NAME.NODE, id);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.handleModelUpdateRender = this.handleModelUpdateRender.bind(this);
    this.model.on('LOADED', this.handleModelLoad);
    this.model.on('UPDATED', this.handleModelUpdate);
    this.model.on('UPDATE_RENDER', this.handleModelUpdateRender);

    this.views = [];

    // const splitView = this.buildView();
    // this.views.push(splitView);
  }

  get isDisposable() {
    return !this.getRootView() || !this.getRootView()?.parentNode;
  }

  get loaded() {
    return this.model.loaded;
  }

  get isRendered(): boolean {
    const currentView = this.getRootView();
    return currentView != null && currentView.parentNode != null; // TODO: complete isRendered verification ( check if node is in the page )
  }

  get splitViews() {
    return this.views;
  }

  set splitViews(value: Editor.Visualizer.SplitView[]) {
    this.views = value;
  }

  getSplitPoint(index: number): Editor.Selection.Path | undefined {
    if (this.views[index]) {
      return this.views[index].splitPoint;
    }
  }

  hasSplitViews() {
    return this.views.length > 1;
  }

  indexOfSplitView(view: Editor.Visualizer.BaseView): number {
    for (let i = 0; i < this.views.length; i++) {
      if (this.views[i].view === view) {
        return i;
      }
    }

    return -1;
  }

  // TODO: review this
  transformPathWithSplitPoints(
    splitView: Editor.Visualizer.BaseView,
    pathToTransform: Editor.Selection.Path,
    action: 'ADD' | 'SUBTRACT' = 'SUBTRACT',
  ): Editor.Selection.Path | null {
    let path: Editor.Selection.Path | null = pathToTransform;

    const index = this.indexOfSplitView(splitView);

    for (let i = 0; i < index; i++) {
      path = PathUtils.transformPath(path, this.views[index].splitPoint, action);
    }

    return path;
  }

  getModel() {
    return this.model;
  }

  hasBreakElement() {
    return this.model.get(['refs', 'br']) || [];
  }

  awaitForEvent(eventName: AllowedPendingEvents): Promise<void> {
    return new Promise((resolve) => {
      if (!this.pendingEvents[eventName]) {
        this.pendingEvents[eventName] = [];
      }
      this.pendingEvents[eventName].push(resolve);
    });
  }

  triggerPendingEvent(eventName: AllowedPendingEvents) {
    if (this.pendingEvents[eventName]) {
      while (this.pendingEvents[eventName].length) {
        this.pendingEvents[eventName].pop()();
      }
    }
  }

  getScrollDiffToBottom() {
    if (this.parent && this.parent instanceof DocumentViewModel) {
      return this.parent.getScrollDiffToBottom();
    }
    return null;
  }

  private handleModelLoad() {
    this.triggerPendingEvent('LOADED');
    this.render(true);
  }

  private handleModelUpdate() {}

  private handleModelUpdateRender(renderOperations: Realtime.Core.RealtimeOps) {
    if (this.debug) {
      Logger.info('BlockViewModel handleModelUpdateRender', renderOperations);
    }
    if (renderOperations.length > 20 || this.errorState === true) {
      // TODO: check this value --^^
      // full render
      this.render(true);
    } else {
      // const view = this.views[v].view;
      // const oldHeight = view.clientHeight;

      const initialHeight: number[] = [];

      const scrollDiffToBottom = this.getScrollDiffToBottom();

      // get initial heights
      for (let v = 0; v < this.views.length; v++) {
        initialHeight.push(this.views[v].view.clientHeight || 0);
      }

      const collidesWithSelection = this.Visualizer.selectionManager?.collides(this.id);
      let shouldRestoreSelection = false;
      let shouldUpdateTabulations = false;

      // let hasWidgetsOpen = this.Visualizer.widgets?.isAnyWidgetOpenForView(this.id);

      // if (hasWidgetsOpen) {
      //   this.Visualizer.widgets?.removeAllWidgetsForView(this.id);
      // }

      try {
        // stop selection tracker
        if (collidesWithSelection) {
          this.Visualizer.selection?.stopSelectionTracker();
        }

        for (let i = 0; i < renderOperations.length; i++) {
          const op = renderOperations[i];

          let isHeaderRow = false;

          // check if path for first view is in a header row
          const { node } = JsonRange.getNodeOffsetFromViewPath(this.views[0].view, op.p);
          const firstViewClosestRow = EditorDOMUtils.closest(node || null, [
            ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG,
          ]);

          // check if operation is for header row
          if (
            firstViewClosestRow instanceof HTMLTableRowElement &&
            firstViewClosestRow.dataset.hr === 'true'
          ) {
            isHeaderRow = true;
          }

          for (let v = 0; v < this.views.length; v++) {
            let operationPath: Editor.Selection.Path | null = op.p as Editor.Selection.Path;
            const view = this.views[v].view;

            // check if operation is for tables with header rows
            if (EditorDOMElements.isTableElement(view) && operationPath && !isHeaderRow) {
              // transform operation by the number of header rows
              const nHeaderRows = view.getNumberHeaderRows();
              if (nHeaderRows > 0 && v !== 0) {
                operationPath = PathUtils.transformPath(
                  operationPath,
                  ['childNodes', 0, 'childNodes', nHeaderRows],
                  'ADD',
                );
              }

              // // handle tables operation transformation
              // const nHeaderRows = view.getNumberHeaderRows();
              // if (nHeaderRows > 0) {

              //   if (
              //     closestRow instanceof HTMLTableRowElement &&
              //     closestRow?.dataset.hr === 'true'
              //   ) {

              //     applyTransformPath = false;
              //   } else if (v !== 0) {
              //     // transform operation by the number of header rows
              //     operationPath = JsonRange.transformPath(
              //       operationPath,
              //       ['childNodes', 0, 'childNodes', nHeaderRows],
              //       false,
              //     );
              //   }
              // }
            }

            if (!isHeaderRow) {
              operationPath = PathUtils.transformPath(operationPath, this.views[v].splitPoint);
            }

            let operationApplied = false;

            if (op.p.length > 0) {
              if (op.p.includes('lock')) {
                // update lock
                operationApplied = this.handleUpdateProperties(op, view, operationPath);
              } else if (
                op.p.includes('type') ||
                op.p.includes('st') ||
                op.p.includes('approvedBy')
              ) {
                // update note type (fullrender)
                this.render(true);
                return;
              } else if (op.p.includes('tasks')) {
                // update node properties
                operationApplied = this.handleUpdateProperties(op, view, operationPath);
              } else if (
                op.p.includes('properties') ||
                op.p.includes('id') ||
                op.p.includes('parent_id')
              ) {
                // update node properties
                shouldUpdateTabulations = true;
                operationApplied = this.handleUpdateProperties(op, view, operationPath);
              } else if (op.p.includes('content')) {
                // update content
                shouldRestoreSelection = true;
                shouldUpdateTabulations = true;
                operationApplied = this.handleUpdateContent(op, view, operationPath);
              } else if (op.p.includes('childNodes')) {
                // update child nodes
                shouldRestoreSelection = true;
                shouldUpdateTabulations = true;
                operationApplied = this.handleUpdateChildNodes(op, view, operationPath);
              }
            }

            // break cicle if operation was applied and its not for header rows
            if (!isHeaderRow && operationApplied) {
              break;
            }
          }
        }
        if (this.isRendered && shouldUpdateTabulations) {
          this.Visualizer.tabulator?.tabulate(this);
        }
      } catch (error) {
        // handle error
        this.render(true);
        Logger.captureException(error, {
          extra: {
            error,
          },
        });
      } finally {
        if (collidesWithSelection) {
          // restore selection and schedule selection changed
          if (shouldRestoreSelection) {
            this.Data.selection?.restore();
          }

          // rebuild widgets
          this.Visualizer?.widgets?.rebuildWidgets();

          // schedule resume selection tracker
          this.Visualizer.selection?.debounceStartSelectionTracker();
        }
      }

      // check final hights
      // adjust scroll if updated

      // for (let v = 0; v < this.views.length; v++) {
      //   const finalHeight = this.views[v].view.clientHeight;
      //   if (initialHeight[v] !== finalHeight) {
      //     this.parent?.childChangedHeight(
      //       this,
      //       this.views[v].view,
      //       finalHeight - initialHeight[v],
      //     );
      //     // TEMP: for now update block if something changed or if has splitted views
      //     break;
      //   }
      // }

      // update block if height changed
      for (let v = 0; v < this.views.length; v++) {
        const splitView = this.views[v];

        const finalHeight = splitView.view.clientHeight || 0;
        if (initialHeight[v] !== finalHeight && scrollDiffToBottom != null) {
          this.parent?.childChangedHeight(
            this,
            splitView.view,
            finalHeight - initialHeight[v],
            scrollDiffToBottom,
          );
          this.Visualizer.widgets?.rebuildWidgetForView(this.id);
          break; //! BREAK
        }
      }
    }
  }

  private handleUpdateProperties(
    op: Realtime.Core.RealtimeOp,
    view: Editor.Visualizer.BaseView,
    operationPath: Realtime.Core.RealtimePath | null,
  ): boolean {
    let updated = false;
    if (this.isRendered) {
      // update properties
      let element: any;

      if (operationPath?.length) {
        const { node } = JsonRange.getNodeOffsetFromViewPath(view, operationPath);
        element = node;
      }

      if (element && element.nodeType === Node.ELEMENT_NODE && operationPath != null) {
        let mapper = this.Visualizer.viewFactory?.getAttributeMapper(
          element.getAttribute('element_type'),
        );

        if (mapper) {
          // get propertie key
          const pathElementsToCheck = ['properties', 'id', 'parent_id', 'tasks', 'lock'];
          let propKey;
          let pathToUpdate;
          let updateAllProps = false;

          let jsonData: any;

          for (let i = 0; i < pathElementsToCheck.length; i++) {
            const elem = pathElementsToCheck[i];

            const pathIndex = operationPath.indexOf(elem);

            if (pathIndex >= 0) {
              if (elem === 'properties') {
                if (pathIndex < operationPath.length - 1) {
                  // get key from path
                  propKey = operationPath[pathIndex + 1];
                  pathToUpdate = operationPath.slice(pathIndex, operationPath.length);
                  break;
                }
                if (operationPath.length === 1) {
                  updateAllProps = true;
                  break;
                }
              } else if (elem === 'lock') {
                if (!this.model.isReadonly()) {
                  propKey = operationPath[pathIndex];
                  pathToUpdate = operationPath.slice(pathIndex, operationPath.length);
                  break;
                }
              } else {
                // id, parent_id, task
                propKey = operationPath[pathIndex];
                pathToUpdate = operationPath.slice(pathIndex, operationPath.length);
                break;
              }
            }
          }

          if (updateAllProps) {
            let deleteKeys = op.od ? Object.keys(op.od) : [];
            let insertKeys = op.oi ? Object.keys(op.oi) : [];

            let uniqueDeleteKeys = [];
            // filter keys to remove
            for (let d = 0; d < deleteKeys.length; d++) {
              if (!insertKeys.includes(deleteKeys[d])) {
                uniqueDeleteKeys.push(deleteKeys[d]);
              }
            }

            if (uniqueDeleteKeys.length) {
              for (let i = 0; i < uniqueDeleteKeys.length; i++) {
                const key = uniqueDeleteKeys[i];
                if (mapper[key]) {
                  mapper[key].remove(element);
                }
              }
            }

            if (insertKeys.length && op.oi) {
              jsonData = { properties: { ...op.oi } };
              for (let i = 0; i < insertKeys.length; i++) {
                const key = insertKeys[i];
                if (mapper[key]) {
                  mapper[key].render(jsonData, element);
                }
              }
            }
          } else if (pathToUpdate != null && propKey != null && mapper[propKey] != null) {
            const builder = new ElementNodeBuilder();

            mapper[propKey].parse(element, builder);

            jsonData = builder.getNode();

            // apply operation to data
            let objectIterator: any = jsonData;

            for (let j = 0; j < pathToUpdate.length - 1; j++) {
              if (objectIterator[pathToUpdate[j]] == null) {
                if (!isNaN(+pathToUpdate[j + 1])) {
                  objectIterator[pathToUpdate[j]] = [];
                } else if (typeof pathToUpdate[j + 1] === 'string') {
                  objectIterator[pathToUpdate[j]] = {};
                }
              }
              objectIterator = objectIterator[pathToUpdate[j]];
            }

            const lastKey = pathToUpdate[pathToUpdate.length - 1];

            if (op.li != null && op.ld != null) {
              objectIterator[lastKey] = op.li;
            } else if (op.oi != null && op.od != null) {
              objectIterator[lastKey] = op.oi;
            } else {
              if (op.ld != null && Array.isArray(objectIterator)) {
                objectIterator.splice(+lastKey, 1);

                if (!objectIterator.length) {
                  objectIterator = undefined;
                  mapper[propKey].remove(element);
                }
              }

              if (op.li != null) {
                if (Array.isArray(objectIterator)) {
                  if (+lastKey >= objectIterator.length) {
                    objectIterator.push(op.li);
                  } else {
                    objectIterator.splice(+lastKey, 0, op.li);
                  }
                } else {
                  objectIterator = [op.li];
                }
              }

              if (op.od != null) {
                delete objectIterator[lastKey];
                mapper[propKey].remove(element);
              }

              if (op.oi != null) {
                objectIterator[lastKey] = op.oi;
              }
            }
            if (op.oi != null || op.li != null || op.ld != null || op.od != null) {
              mapper[propKey].render(jsonData, element);
            }

            updated = true;
          }
        }
      }
    }
    return updated;
  }

  private handleUpdateContent(
    op: Realtime.Core.RealtimeOp,
    view: Editor.Visualizer.BaseView,
    operationPath: Editor.Selection.Path | null,
  ): boolean {
    let updated = false;

    if (this.isRendered) {
      let element;

      if (operationPath?.length) {
        const { node } = JsonRange.getNodeOffsetFromViewPath(view, operationPath);
        element = node;
      }

      if (element && operationPath) {
        const contentIndex = operationPath.indexOf('content');
        const contentOffset = Number(operationPath[contentIndex + 1]);
        const childIndexToUpdate = Number(operationPath[contentIndex - 1]);

        // update child text content
        const textElement =
          element.nodeType === Node.TEXT_NODE ? element : element.childNodes[childIndexToUpdate];

        if (textElement instanceof Text) {
          if (op.oi) {
            textElement.textContent = op.oi;
          }

          if (op.sd) {
            textElement.deleteData(contentOffset, op.sd.length);
          }

          if (op.si) {
            textElement.insertData(contentOffset, op.si);
          }

          updated = true;
        }

        const closestElement = EditorDOMUtils.closest(element, [ELEMENTS.ParagraphElement.TAG]);

        // paragraphs check content for BRs
        if (EditorDOMElements.isParagraphElement(closestElement)) {
          closestElement.checkEmptyContent();
        }
      }
    }

    return updated;
  }

  private handleUpdateChildNodes(
    op: Realtime.Core.RealtimeOp,
    view: Editor.Visualizer.BaseView,
    operationPath: Editor.Selection.Path | null,
  ): boolean {
    let updated = false;

    if (this.isRendered) {
      let element: HTMLElement | null = null;
      let lastKey: any;

      if (operationPath?.length) {
        lastKey = operationPath[operationPath.length - 1];

        // find parent node to insert/remove child
        if (lastKey === 'childNodes') {
          const { node } = JsonRange.getNodeOffsetFromViewPath(
            view,
            operationPath.slice(0, operationPath.length - 1),
          );
          if (node instanceof HTMLElement) {
            element = node;
          }
        } else {
          const { node } = JsonRange.getNodeOffsetFromViewPath(
            view,
            operationPath.slice(0, operationPath.length - 2),
          );
          if (node instanceof HTMLElement) {
            element = node;
          }
        }
      }

      if (element != null && operationPath != null) {
        // apply child operation
        if (lastKey === 'childNodes') {
          if (op.od || op.oi) {
            // remove childreen
            while (element.firstChild) {
              element.removeChild(element.firstChild);
            }

            updated = true;
          }

          if (op.oi?.length) {
            for (let i = 0; i < op.oi.length; i++) {
              const renderedNode = this.Visualizer.viewFactory?.get(op.oi[i], this.model) as Node;
              element.appendChild(renderedNode);
            }
            updated = true;
          }

          if (EditorDOMElements.isSupportedBlockElement(element)) {
            if (EditorDOMElements.isTableElement(element)) {
              const baseElement = element.id === view.id;

              let pageWidth;
              if (baseElement && view.id) {
                pageWidth = this.Data?.sections.getPageWidthForBlockId(view.id);
              }
              element.preRender(baseElement, pageWidth);
            } else {
              element.preRender();
            }
          }
        } else if (+lastKey >= 0 && +lastKey <= element.childNodes.length) {
          const index = +lastKey;
          if (op.ld != null) {
            const childNode = EditorDOMUtils.getChildNodeFromElement(element, index);
            if (childNode) {
              element.removeChild(childNode);
              updated = true;
            }
          }
          if (op.li != null) {
            const renderedNode = this.Visualizer.viewFactory?.get(op.li, this.model) as Node;
            const childNode = EditorDOMUtils.getChildNodeFromElement(element, index);
            if (childNode) {
              element.insertBefore(renderedNode, childNode);
            } else {
              element.appendChild(renderedNode);
            }
            updated = true;
          }
        }

        const closestElement = EditorDOMUtils.closest(element, [
          ELEMENTS.ParagraphElement.TAG,
          ELEMENTS.TableElement.TAG,
        ]);

        // paragraphs check content for BRs
        if (EditorDOMElements.isParagraphElement(closestElement)) {
          closestElement.checkEmptyContent();
        }
      }
    }

    return updated;
  }

  private buildView(error: boolean = false): Editor.Visualizer.SplitView {
    const modelData = this.model.get();
    let view;
    let decoratorView;

    if (error) {
      modelData.type = 'invalid';
      view = this.Visualizer.viewFactory?.get(modelData, this.model) as Editor.Visualizer.BaseView;
      decoratorView = undefined;
    } else if (!this.model.loaded) {
      view = this.Visualizer.viewFactory?.get(
        {
          id: this.id,
          type: 'loader',
        },
        this.model,
      ) as Editor.Visualizer.BaseView;
      decoratorView = undefined;
    } else {
      view = this.Visualizer.viewFactory?.get(modelData, this.model) as Editor.Visualizer.BaseView;
      decoratorView = this.Visualizer.viewFactory?.decorate(
        this.Visualizer.renderMode,
        modelData,
        view,
      ) as Editor.Visualizer.BaseView;
    }

    if (view) {
      view.vm = this;
    }

    this.triggerPendingEvent('BUILDED');
    return {
      splitPoint: [],
      view,
      decoratorView,
    };
  }

  setBlockSelected(value: boolean) {
    for (let i = 0; i < this.views.length; i++) {
      if (value) {
        this.views[i].decoratorView?.setAttribute('selected', 'true');
      } else {
        this.views[i].decoratorView?.removeAttribute('selected');
      }
    }
  }

  updateSectionValue(sectionValue: string) {
    for (let i = 0; i < this.views.length; i++) {
      this.views[i].view?.setAttribute('section', sectionValue);
      this.views[i].decoratorView?.setAttribute('section', sectionValue);
    }
  }

  getRootView(index: number = 0): Editor.Visualizer.BaseView | undefined {
    return this.views[index]?.decoratorView || this.views[index]?.view;
  }

  private cleanSplitViews() {
    for (let i = 0; i < this.views.length; i++) {
      const splitView = this.views[i];
      const view = splitView.decoratorView || splitView.view;

      if (view.parentNode) {
        view.parentNode.removeChild(view);
      }
    }

    this.views = [];
  }

  render(propUpdate: boolean = false) {
    if (this.debug) {
      Logger.trace('BlockViewModel render', propUpdate);
    }

    // TODO:
    // update 'collidesWithSelection' to take into consideration only some operations
    // for example:
    // an operation for properties, updating 'lock' to true or false, should not be executed as collides with selection
    // and therefor rebuild widgets and other operations should not be called

    const collidesWithSelection = this.Visualizer.selectionManager?.collides(this.id);

    let hasWidgetsOpen = this.Visualizer.widgets?.isAnyWidgetOpenForView(this.id);

    // if (hasWidgetsOpen) {
    //   this.Visualizer.widgets?.removeAllWidgetsForView(this.id);
    // }

    try {
      // stop selection tracker
      if (collidesWithSelection) {
        this.Visualizer.selection?.stopSelectionTracker();
      }

      const currentView = this.getRootView();
      const parentNode = currentView?.parentNode;

      const newSplitView = this.buildView();
      const newView = newSplitView.decoratorView || newSplitView.view;

      let height = currentView?.clientHeight || 0;
      const scrollDiffToBottom = this.getScrollDiffToBottom();
      if (parentNode) {
        parentNode.replaceChild(newView, currentView);
      }

      // clean split views
      this.cleanSplitViews();

      this.views.push(newSplitView);

      if (propUpdate && newView.parentNode && this.model.loaded) {
        this.Visualizer.tabulator?.tabulate(this);
        let newHeight = newView.clientHeight || 0;
        if (newHeight !== height && scrollDiffToBottom != null) {
          this.parent?.childChangedHeight(this, newView, newHeight - height, scrollDiffToBottom);
          this.Visualizer.widgets?.rebuildWidgetForView(this.id);
        }
      }

      this.errorState = false;
    } catch (error) {
      Logger.captureException(error, {
        extra: {
          error,
        },
      });
      this.renderErrorView();
    } finally {
      // schedule resume selection tracker
      if (collidesWithSelection) {
        // restore selection and schedule selection changed
        this.Data.selection?.restore();

        const view = this.getRootView();
        if (hasWidgetsOpen && view) {
          // rebuild widgets
          this.Visualizer?.widgets?.rebuildWidgets();
        }

        this.Visualizer.selection?.debounceStartSelectionTracker();
      }
    }

    return this.getRootView();
  }

  renderErrorView() {
    const currentView = this.getRootView();
    const parentNode = currentView?.parentNode;

    const newSplitView = this.buildView();
    const newView = newSplitView.decoratorView || newSplitView.view;

    if (parentNode) {
      let height = currentView.clientHeight;
      const scrollDiffToBottom = this.getScrollDiffToBottom();

      parentNode.replaceChild(newView, currentView);

      let newHeight = newView.clientHeight || 0;
      if (newHeight !== height && scrollDiffToBottom != null) {
        this.parent?.childChangedHeight(this, newView, newHeight - height, scrollDiffToBottom);
        this.Visualizer.widgets?.rebuildWidgetForView(this.id);
      }
    }

    // clean split views
    this.cleanSplitViews();

    this.views.push(newSplitView);

    this.errorState = true;
  }

  addSplitView(view: Editor.Visualizer.BaseView, splitPoint: Editor.Selection.Path) {
    const decoratorView = this.Visualizer.viewFactory?.decorate(
      this.Visualizer.renderMode,
      this.model.get(),
      view,
    ) as Editor.Visualizer.BaseView;

    this.views.push({
      splitPoint,
      view,
      decoratorView,
    });

    return decoratorView;
  }

  setTaskSelected(select?: boolean) {
    for (let i = 0; i < this.views.length; i++) {
      const view = this.views[i].view;

      if (EditorDOMElements.isSupportedBlockElement(view)) {
        if (select) {
          view.selectTask();
        } else {
          view.deselectTask();
        }
      }
    }
  }

  removeViews() {
    for (let v = 0; v < this.views.length; v++) {
      if (this.views[v].decoratorView) {
        this.views[v].decoratorView?.remove();
      } else if (this.views[v].view) {
        this.views[v].view?.remove();
      }
    }
  }

  dispose(shouldRemove: boolean = true, shouldUpdateScroll: boolean = true) {
    this.model.removeListener('LOADED', this.handleModelLoad);
    this.model.removeListener('UPDATED', this.handleModelUpdate);
    this.model.removeListener('UPDATE_RENDER', this.handleModelUpdateRender);

    const currentView = this.getRootView();
    let height = currentView?.clientHeight || 0;
    const scrollDiffToBottom = this.getScrollDiffToBottom();
    // this.removeAllChildren();
    // currentView?.remove();
    this.removeViews();
    this.Visualizer.viewModelFactory?.remove(this.id);
    if (this.parent) {
      if (shouldUpdateScroll && scrollDiffToBottom != null) {
        this.parent?.childChangedHeight(this, null, -height, scrollDiffToBottom);
        this.Visualizer.widgets?.rebuildWidgetForView(this.id);
      }
      if (shouldRemove) {
        this.parent.removeChild(this);
      }
    } else {
      logger.warn('disposing parent not found!');
    }
  }
}
