//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

export default Mixin(
  (superclass: any) =>
    class CommonOperations extends superclass {
      destroy() {
        super.destroy();
      }

      getObjectOperationforPathValue(
        oldValue: unknown | undefined,
        newValue: unknown | null,
        path: (string | number)[],
      ) {
        if (newValue != null) {
          if (oldValue !== undefined) {
            if (oldValue !== newValue) {
              // this condition should not have an else
              return RealtimeOpsBuilder.objectReplace(oldValue, newValue, path);
            }
          } else {
            return RealtimeOpsBuilder.objectInsert(newValue, path);
          }
        } else if (oldValue !== undefined) {
          return RealtimeOpsBuilder.objectDelete(oldValue, path);
        }
      }
    },
);
