import { ReactNode, createContext, memo, useContext, useState } from 'react';
import usePosition from '../usePosition';

type ChartContextValue = {
  position: Presentation.Data.Common.Position;
  chartShape: Presentation.Data.ChartShape;
  legendRect: ShortRect | null;
  setLegendRect: (rect: ShortRect) => void;
};

const ChartContext = createContext<ChartContextValue | undefined>(undefined);

type ChartDataProps = {
  children: ReactNode;
  shape: Presentation.Data.ChartShape;
};

const ChartDataProvider = ({ children, shape }: ChartDataProps) => {
  const position = usePosition(shape);
  const [legendRect, setLegendRect] = useState<ShortRect | null>(null);
  return (
    <ChartContext.Provider value={{ position, chartShape: shape, legendRect, setLegendRect }}>
      {children}
    </ChartContext.Provider>
  );
};

export const useChartData = () => {
  const context = useContext(ChartContext);
  if (context === undefined) {
    throw new Error('useChartData must be used within a ChartDataProvider');
  }
  return context;
};

export default memo(ChartDataProvider);
