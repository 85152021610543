import { useCallback, useMemo } from 'react';
import { Popover, Toggle, usePopper } from 'dodoc-design-system';
import { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';
import cx from 'classnames';

import { useDispatch } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { hexToRgb } from 'assets/colors';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { ColorsMenu } from 'Editor/components';
import { ColorsMenuProps } from 'Editor/components/ColorsMenu/ColorsMenu';

import styles from './ColorPickerToggle.module.scss';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

type ColorPickerToggleProps = {
  color: string;
  onColorChange: (newColor: string) => void;
  popperSettings?: PopperProps;
  disabled?: boolean;
  testId?: string;
};

const ColorPickerToggle = ({
  color,
  onColorChange,
  popperSettings,
  disabled,
  testId,
}: ColorPickerToggleProps) => {
  const dispatch = useDispatch();
  const togglePopper = usePopper({
    placement: 'top-end',
    strategy: 'fixed',
    closeOnReferenceHidden: true,
    ...popperSettings,
  });

  const rbgColor = useMemo(() => {
    if (color) {
      const rbgColor = hexToRgb(color);
      return rbgColor && `rgb(${rbgColor[0]},${rbgColor[1]},${rbgColor[2]})`;
    }
  }, [color]);

  //TODO: Check how this state is populated and clean type accordingly
  const documentColors = useMemo<
    { r?: string | number; g?: string | number; b?: string | number; rgb?: string }[]
  >(() => EditorManager.getInstance().getDocumentColors() ?? [], [color]);

  const updateColorCallback = useCallback(
    (newColor: string) => onColorChange(EditorDOMUtils.rgbToHex(newColor) ?? 'transparent'),
    [],
  );

  const handleClearColor = () => onColorChange('transparent');

  const handleColorChange = (newColor: string) =>
    onColorChange(EditorDOMUtils.rgbToHex(newColor) ?? 'transparent');

  const handleOpenColorPickerModalBorderColor: ColorsMenuProps['setOpenColorPickerModal'] = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ColorPickerModal',
        data: { action: 'updateColor', updateColorCallback },
      }),
    );
  };

  return (
    <>
      <Toggle
        size="medium"
        {...togglePopper.referenceProps}
        margin="0 0 3rem 0"
        testId={`${testId}-toggle`}
        disabled={disabled}
      >
        <div
          className={cx(styles.box, { [styles.transparent]: color === 'transparent' })}
          style={{
            background: color,
          }}
        />
      </Toggle>
      <Popover {...togglePopper.popperProps} testId={`${testId}-popper`}>
        <ColorsMenu
          selected={rbgColor}
          documentColors={documentColors}
          toggle={togglePopper.close}
          changeBackgroundColor={handleColorChange}
          clearBackgroundColor={handleClearColor}
          setOpenColorPickerModal={handleOpenColorPickerModalBorderColor}
        />
      </Popover>
    </>
  );
};

export default ColorPickerToggle;
