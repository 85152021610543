import { Tag } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { useGetTagsListQuery } from './TagsApi';

import styles from './Tags.module.scss';

type TagListProps = {
  search: string;
  testId: string;
};

const TagList = ({ search, testId }: TagListProps) => {
  const dispatch = useDispatch();
  const { data: tags } = useGetTagsListQuery();

  const handleDeleteTagModal = (tag: Tag) => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_SOMETHING_QUOTED',
          titleValues: { label: tag },
          message: 'DELETING_THIS_TAG_FROM_THE_TENANT_CONFIRM',
          confirmButtonTextId: 'DELETE_TAG',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'deleteTag',
          actionValue: tag,
          headerType: 'error',
          cancelButtonShow: true,
          width: '75rem',
        },
      }),
    );
  };

  return (
    <div className={styles.listTags}>
      {tags?.list
        .filter((tag) => {
          if (search === '') {
            return tag;
          }
          return tag.toLowerCase().includes(search.toLowerCase());
        })
        .map((tag) => (
          <Tag
            key={tag}
            title={tag}
            onClick={() => handleDeleteTagModal(tag)}
            margin="0 1rem 2rem 0"
            testId={`${testId}-tag-${tag}`}
          >
            {tag}
          </Tag>
        ))}
    </div>
  );
};

export default TagList;
