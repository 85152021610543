type OmitKeys<Target, Supplied> = Omit<Target, keyof Supplied>;

export interface IWith<Target, Supplied> {
  with<T extends OmitKeys<Target, Supplied>, K extends keyof T>(
    key: K,
    value: T[K],
  ): keyof Omit<OmitKeys<Target, Supplied>, K> extends never
    ? IBuild<Target>
    : IWith<Target, Supplied & Pick<T, K>>;
}

export interface IBuild<Target> {
  build(): Target;
}

export class Builder<Target, Supplied> implements IBuild<Target>, IWith<Target, Supplied> {
  constructor(private target: Partial<Target>) {}

  with<T extends OmitKeys<Target, Supplied>, K extends keyof T>(key: K, value: T[K]) {
    const target: Partial<Target> = { ...this.target, [key]: value };

    return new Builder<Target, Supplied & Pick<T, K>>(target);
  }

  build() {
    return this.target as Target;
  }
}

export class ObjectBuilder {
  public static new<Target>(): IWith<Target, {}> {
    return new Builder<Target, {}>({});
  }
}
