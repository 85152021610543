import { ReduxInterface } from 'Editor/services';
import { TEXT_ALIGN_PROPERTIES } from '../consts';
import EventsManager from '../EventsManager';
import { BaseTypedEmitter } from '_common/services/Realtime';

class ShortcutsManagerEmitter extends BaseTypedEmitter<Editor.ShortcutsManager.ShortcutsManagerEvents> {}
export default class ShortcutManager extends ShortcutsManagerEmitter {
  private platform: Platform;
  private modifierIsPressed: Boolean;
  private _isRunning: Boolean;
  private commands: Editor.ShortcutsManager.CommandMapper;

  constructor(platform: Platform) {
    super();
    this.platform = platform;
    this.modifierIsPressed = false;
    this._isRunning = false;
    this.commands = {
      baseCommands: {
        Enter: { action: 'INSERT_PAGE_BREAK' }, // enter

        KeyA: { action: 'SELECT_ALL' }, // ctrl+a cmd+a
        KeyB: {
          action: 'TOGGLE_SELECTION_STYLE',
          args: () => {
            return { style: 'bold', value: !ReduxInterface.getToolbarStylesStatus().bold };
          },
        }, // b
        KeyE: { action: 'ALIGN_CURRENT_SELECTION', args: { style: TEXT_ALIGN_PROPERTIES.center } }, // e
        KeyF: { action: 'OPEN_FIND_AND_REPLACE', document: true }, // f
        KeyG: { action: 'FIND_NEXT', document: true }, // g
        KeyI: {
          action: 'TOGGLE_SELECTION_STYLE',
          args: () => ({ style: 'italic', value: !ReduxInterface.getToolbarStylesStatus().italic }),
        }, // i
        KeyJ: { action: 'ALIGN_CURRENT_SELECTION', args: { style: TEXT_ALIGN_PROPERTIES.justify } }, // j
        KeyS: { action: 'OPEN_SAVE_VERSION', document: true }, // s
        KeyU: {
          action: 'TOGGLE_SELECTION_STYLE',
          args: () => ({
            style: 'underline',
            value: !ReduxInterface.getToolbarStylesStatus().underline,
          }),
        }, // u
        // KeyR: { action: 'OPEN_CITATIONS_MODAL', args: {value:null} }, // r
        KeyY: { action: 'REDO' }, // y
        KeyZ: { action: 'UNDO' }, // z
        BracketLeft: { action: 'TOGGLE_SELECTION_STYLE', args: { style: 'superscript' } }, // +
        Slash: { action: 'TOGGLE_SELECTION_STYLE', args: { style: 'subscript' } }, // -
        Minus: { action: 'TOGGLE_SELECTION_STYLE', args: { style: 'subscript' } },
      },
      shiftKey: {
        KeyG: { action: 'FIND_PREVIOUS', document: true }, // g
        KeyZ: { action: 'REDO' }, // z
        BracketLeft: { action: 'ZOOM_IN' }, //+
        NumpadAdd: { action: 'ZOOM_IN' }, // numpad +

        Slash: { action: 'ZOOM_OUT' }, //-
        Minus: { action: 'ZOOM_OUT' }, // -
        NumpadSubtract: { action: 'ZOOM_OUT' }, // numpad -
      },
      altKey: {
        Digit1: { action: 'CHANGE_PARAGRAPH_STYLE', args: { style: 'h1' } }, // 1
        Digit2: { action: 'CHANGE_PARAGRAPH_STYLE', args: { style: 'h2' } }, // 2
        Digit3: { action: 'CHANGE_PARAGRAPH_STYLE', args: { style: 'h3' } }, // 3
        Digit4: { action: 'CHANGE_PARAGRAPH_STYLE', args: { style: 'h4' } }, // 4
        Digit5: { action: 'CHANGE_PARAGRAPH_STYLE', args: { style: 'h5' } }, // 5
        Digit6: { action: 'CHANGE_PARAGRAPH_STYLE', args: { style: 'h6' } }, // 6

        KeyR: { action: 'START_REVERT_VERSION' }, // r
      },
    };

    this.destroy = this.destroy.bind(this);
    this.start = this.start.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
  }

  start() {
    EventsManager.getInstance().on('KEY_DOWN', (e: KeyboardEvent) => {
      this.handleKeyDown(e);
    });
    EventsManager.getInstance().on('DOCUMENT_KEY_DOWN', (e: KeyboardEvent) => {
      this.handleDocumentKeyDown(e);
    });
    EventsManager.getInstance().on('mousewheel DOMMouseScroll', (e: WheelEvent) => {
      this.handleMouseWheel(e);
    });
    this._isRunning = true;
  }

  pause() {
    this._isRunning = false;
  }

  resume() {
    this._isRunning = true;
  }

  destroy() {
    EventsManager.getInstance().remove('KEY_DOWN', this.handleKeyDown as (event: Event) => void);
    EventsManager.getInstance().remove(
      'DOCUMENT_KEY_DOWN',
      this.handleDocumentKeyDown as (event: Event) => void,
    );
    EventsManager.getInstance().remove(
      'mousewheel DOMMouseScroll',
      this.handleMouseWheel as (event: Event) => void,
    );
    this._isRunning = false;
    super.destroy();
  }

  get isPaused() {
    return !this._isRunning;
  }

  private handleKeyDown(e: KeyboardEvent) {
    if ((this.platform.os.mac && e.metaKey) || (!this.platform.os.mac && e.ctrlKey)) {
      if (e.shiftKey) {
        if (this.commands.shiftKey[e.code] && !this.commands.shiftKey[e.code].document) {
          this.publish(e, this.commands.shiftKey[e.code]);
        }
      } else if (e.altKey) {
        if (this.commands.altKey[e.code] && !this.commands.altKey[e.code].document) {
          this.publish(e, this.commands.altKey[e.code]);
        }
      } else if (
        this.commands.baseCommands[e.code] &&
        !this.commands.baseCommands[e.code].document
      ) {
        this.publish(e, this.commands.baseCommands[e.code]);
      }
    }
  }

  private handleDocumentKeyDown(e: KeyboardEvent) {
    if ((this.platform.os.mac && e.metaKey) || (!this.platform.os.mac && e.ctrlKey)) {
      if (e.shiftKey) {
        if (this.commands.shiftKey[e.code] && this.commands.shiftKey[e.code].document) {
          this.publish(e, this.commands.shiftKey[e.code]);
        }
      } else if (e.altKey) {
        if (this.commands.altKey[e.code] && this.commands.altKey[e.code].document) {
          this.publish(e, this.commands.altKey[e.code]);
        }
      } else if (
        this.commands.baseCommands[e.code] &&
        this.commands.baseCommands[e.code].document
      ) {
        this.publish(e, this.commands.baseCommands[e.code]);
      }
    }
  }

  private handleMouseWheel(e: WheelEvent) {
    if (e.shiftKey) {
      if (e.deltaY > 0) {
        this.publish(e, {
          action: 'ZOOM_OUT',
        });
      } else {
        this.publish(e, {
          action: 'ZOOM_IN',
        });
      }
    }
  }

  private publish(
    e: KeyboardEvent | WheelEvent,
    { action, args, preventDefault = true }: Editor.ShortcutsManager.Command,
  ) {
    if (preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (this._isRunning && action) {
      if (typeof args === 'function') {
        this.emit(action, args());
      } else {
        this.emit(action, args);
      }
    }
  }
}
