import { useIntl } from 'react-intl';
import { Select } from 'dodoc-design-system';
import { ItemName } from '../NewListModal';

type ListProps = {
  type: string;
  listItems: Editor.ListItem[];
  setListItems: (
    values:
      | {
          value: string;
          label: string;
        }
      | number
      | boolean
      | string
      | null,
    key: ItemName,
  ) => void;
  selectedLine: number;
  setSelectedLine: React.Dispatch<React.SetStateAction<number>>;
  getLabels: (
    l: string,
    v: any,
  ) =>
    | {
        value: string;
        label: string;
      }
    | {
        value: string;
        label: string;
      }[]
    | null;
  paragraphStyles: {
    value: any;
    label: any;
  }[];
};

type ParagraphStylesCellProps = ListProps & { item: Editor.ListItem };

const ParagraphStylesCell = ({
  item,
  selectedLine,
  setSelectedLine,
  listItems,
  setListItems,
  getLabels,
  paragraphStyles,
}: ParagraphStylesCellProps) => {
  const intl = useIntl();

  const handleOnMenuOpen = () => {
    if (selectedLine !== item.level) {
      setSelectedLine(item.level);
    }
  };

  const handleOnChange = (values: { value: string; label: string }) => {
    if (values.value === 'not_defined') {
      setListItems(null, 'paragraph_style');
    } else {
      setListItems(values, 'paragraph_style');
    }
  };

  const getAvailableOptions = () => {
    const usedParagraphStyles = listItems
      .map((element) => element.paragraph_style && element.paragraph_style.value)
      .filter((element) => element);

    const newParagraphStyles = paragraphStyles.filter(
      (element) => !usedParagraphStyles.includes(element.value),
    );

    newParagraphStyles.unshift({
      value: 'not_defined',
      label: intl.formatMessage({
        id: 'global.notDefined',
      }),
    });
    return newParagraphStyles;
  };

  return (
    <>
      <Select
        creatable
        isSearchable={false}
        isClearable={false}
        onMenuOpen={handleOnMenuOpen}
        options={getAvailableOptions()}
        value={
          item.paragraph_style ? getLabels('paragraphStyles', item.paragraph_style.value) : null
        }
        onChange={handleOnChange}
        placeholder={intl.formatMessage({
          id: 'global.notDefined',
        })}
        customStyles={{
          container: (provided: any) => ({ ...provided, width: '20.625rem' }),
          control: (provided: any) => ({ ...provided, height: '4rem' }),
          option: (provided: any) => ({ ...provided, textTransform: 'capitalize' }),
          singleValue: (provided: any) => ({ ...provided, textTransform: 'capitalize' }),
        }}
        size="medium"
        width="auto"
        testId={`${item.level + 1}-paragraph-styles`}
      />
    </>
  );
};

export default ParagraphStylesCell;
