import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import Answer from './Answer/Answer';

import styles from './Questionnaire.module.scss';

export type QuestionnaireProps<AnswerId extends string = string> = {
  isOpen: boolean;
  question: TranslationMessage;
  description?: TranslationMessage;
  answers: Record<AnswerId, TranslationMessage>;
  defaultAnswer: AnswerId | undefined;
  setAnswer: (id: AnswerId) => void;
  onAnswerChange: (id: AnswerId) => void;
  onBack: (isOpen: boolean) => void;
  onStart: () => void;
  onSkip: () => void;
};

const Questionnaire = <AnswerId extends string = string>({
  isOpen,
  question,
  answers,
  defaultAnswer,
  description = { id: 'DONT_WORRY_YOU_CAN_CHANGE_IT_LATER' },
  setAnswer,
  onAnswerChange,
  onBack,
  onStart,
  onSkip,
}: QuestionnaireProps<AnswerId>) => {
  const [selectedAnswer, setSelectedAnswer] = useState<AnswerId>();

  useEffect(() => {
    if (defaultAnswer) {
      setSelectedAnswer(defaultAnswer);
    }
  }, [defaultAnswer]);

  const handleAnswerChange = (answerId: AnswerId) => {
    onAnswerChange(answerId);
    setSelectedAnswer(answerId);
  };

  const handleBack = () => {
    onBack(false);
  };

  const handleStart = () => {
    if (selectedAnswer) {
      setAnswer(selectedAnswer);
    }
    onStart();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.question}>
        <FormattedMessage id={question.id} values={question.values} />
      </div>
      <div className={styles.description}>
        <FormattedMessage id={description.id} values={description.values} />
      </div>
      <div className={styles.answers}>
        {(Object.keys(answers) as Array<keyof typeof answers>).map((answerId) => {
          const label = answers[answerId];
          return (
            <Answer
              id={answerId}
              key={answerId}
              label={label}
              testId={answerId}
              checked={selectedAnswer === answerId}
              selectAnswer={handleAnswerChange}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        <div>
          <Button size="medium" variant="link" onClick={onSkip} testId="questionnaire-skip-button">
            <FormattedMessage id="SKIP_ONBOARDING" />
          </Button>
        </div>
        <div>
          <Button
            size="medium"
            variant="neutral"
            onClick={handleBack}
            margin="0 1rem 0 0"
            testId="questionnaire-back-button"
          >
            <FormattedMessage id="BACK" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            onClick={handleStart}
            disabled={!selectedAnswer}
            testId="questionnaire-start-button"
          >
            <FormattedMessage id="START_ONBOARDING" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
