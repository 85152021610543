import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useChartOutline from './useChartOutline';
import { useChartData } from '../ChartData';
import useFormatCode from './useFormatCode';
import useUnsupportedProperties from './useUnsupportedProperties';
const useDataLabel = () => {
  const { chartShape } = useChartData();
  const { color, getFontFamily, addUnsupportedElement } = useSlideData();
  const { chartOutline } = useChartOutline();
  const { formatValue } = useFormatCode();
  const { handleUnsupportedText } = useUnsupportedProperties();

  const getDataLabelPosition = ({
    position,
    defaultPosition,
    chart,
  }: {
    position: Presentation.Data.DLblShared['dLblPos'];
    defaultPosition: Exclude<echarts.LineSeriesOption['label'], undefined>['position'];
    chart: Presentation.Data.ChartTypesProperties | Presentation.Data.PlotAreaRegion;
  }) => {
    let labelPosition: Exclude<echarts.LineSeriesOption['label'], undefined>['position'] =
      undefined;

    switch (position) {
      case 'ctr': {
        labelPosition = 'inside';
        break;
      }
      case 'r': {
        labelPosition = 'right';
        break;
      }
      case 'l': {
        labelPosition = 'left';
        break;
      }
      case 't': {
        labelPosition = 'top';
        break;
      }
      case 'b': {
        labelPosition = 'bottom';
        break;
      }
      case 'inEnd': {
        if ('type' in chart) {
          switch (chart.type) {
            case 'pie':
              addUnsupportedElement(`Chart - Pie - Data Labels - Label Position - Inside End`);
              break;
          }
        }
        labelPosition = 'inside'; // TODO:CHARTS Check if insideRight works in other charts (doesn't work in pie)
        break;
      }
      case 'outEnd': {
        if ('type' in chart) {
          switch (chart.type) {
            case 'bar':
              if (chart.barDir === 'col') {
                labelPosition = 'top';
              } else {
                labelPosition = 'right';
              }
              break;
            default:
              labelPosition = 'right';
              break;
          }
        } else {
          labelPosition = 'right';
        }
        break;
      }
      case 'inBase': {
        labelPosition = 'insideLeft';
        break;
      }

      case 'bestFit':
      default: {
        labelPosition = defaultPosition;

        if (position) {
          addUnsupportedElement(`Chart - Data Labels - Label Position - ${position}`);
        }
      }
    }

    return labelPosition;
  };

  const dataLabelStyle = ({
    dLbls,
    idx,
    defaultPosition,
    chart,
  }: {
    dLbls: Presentation.Data.DLbls | undefined;
    idx: number;
    defaultPosition: Exclude<echarts.LineSeriesOption['label'], undefined>['position'];
    chart: Presentation.Data.ChartTypesProperties | Presentation.Data.PlotAreaRegion;
  }): echarts.LineSeriesOption['label'] => {
    //TODO:CHARTS proccess styles as rich

    /*
     * First the general properties of the label will be proccessed. These are inherited properties from dbLbsl
     * Then the specific properties of the label will be proccessed. There are properties from dLbls.dLbl[number]
     */

    if (!dLbls) {
      return undefined;
    }

    const TEXT_ALIGN = {
      l: 'left',
      ctr: 'center',
      r: 'right',
      just: undefined,
      justLow: undefined,
      dist: undefined,
      thaiDist: undefined,
    } as const;

    const dLblsText = dLbls.text;
    let bodyPrRot = dLblsText?.bodyPr?.rot;
    let vert = dLblsText?.bodyPr?.vert;
    let unsupportedVert = '';

    handleUnsupportedText(dLblsText);

    const labelParentProperties = dLblsText?.childNodes?.[0].properties;
    const labelParentOutline = chartOutline(labelParentProperties?.inlineProperties?.ln);

    const labelParentStyle: echarts.LineSeriesOption['label'] = {
      //TODO:CHARTS Font family
      fontFamily:
        getFontFamily({ font: labelParentProperties?.inlineProperties?.latin?.font }) ??
        getFontFamily({ font: chartShape.chartStyle?.dataLabel?.fontRef }),
      fontSize: labelParentProperties?.inlineProperties?.size,
      fontWeight: labelParentProperties?.inlineProperties?.b ? 'bold' : undefined,
      fontStyle: labelParentProperties?.inlineProperties?.i ? 'italic' : undefined,
      //CHARTS:LIMITATION color only supports string (solid color)
      color:
        labelParentProperties?.inlineProperties?.fill?.type === 'solid'
          ? color(labelParentProperties?.inlineProperties?.fill.color)
          : undefined,
      backgroundColor: labelParentProperties?.inlineProperties?.highlight
        ? color(labelParentProperties?.inlineProperties?.highlight)
        : undefined,
      //CHARTS:LIMITATION textBorderColor only supports string (solid color)
      textBorderColor:
        labelParentProperties?.inlineProperties?.ln?.fill?.type === 'solid'
          ? color(labelParentProperties?.inlineProperties?.ln?.fill?.color)
          : undefined,
      textBorderWidth: labelParentOutline?.borderWidth,
      textBorderType: labelParentOutline?.borderType,

      align: labelParentProperties?.algn ? TEXT_ALIGN[labelParentProperties.algn] : undefined,
    };

    let labelStyle: echarts.LineSeriesOption['label'] = { ...labelParentStyle };

    const labelPosition = getDataLabelPosition({
      position: dLbls?.dLblPos,
      defaultPosition,
      chart,
    });

    //Proccess specific individual label properties
    if (dLbls?.dLbl?.length) {
      const label = dLbls.dLbl.find((lbl) => lbl.idx === idx);
      if (label) {
        if (label.layout?.type === 'manual') {
          addUnsupportedElement('Chart - Data Labels - Label Position - Manual');
        }

        const labelText = label.text;
        if (labelText?.bodyPr?.rot != null) {
          bodyPrRot = labelText?.bodyPr?.rot;
        }
        if (labelText?.bodyPr?.vert != null) {
          vert = labelText?.bodyPr?.vert;
        }
        handleUnsupportedText(labelText);

        const labelProperties =
          labelText?.childNodes?.[0]?.childNodes?.[0]?.properties ??
          labelText?.childNodes?.[0].properties.inlineProperties;
        const labelOutline = chartOutline(labelProperties?.ln);

        labelStyle = {
          fontFamily:
            getFontFamily({ font: labelProperties?.latin?.font }) ?? labelParentStyle.fontFamily,
          fontSize: labelProperties?.size ?? labelParentStyle?.fontSize,
          fontWeight: labelProperties?.b ? 'bold' : labelParentStyle?.fontWeight,
          fontStyle: labelProperties?.i ? 'italic' : labelParentStyle?.fontStyle,
          //CHARTS:LIMITATION color only suppports string (solid color)
          color:
            labelProperties?.fill?.type === 'solid'
              ? color(labelProperties?.fill.color) ?? labelParentStyle?.color
              : labelParentStyle?.color,
          backgroundColor: labelProperties?.highlight
            ? color(labelProperties.highlight) ?? labelParentStyle?.backgroundColor
            : labelParentStyle?.backgroundColor,
          //CHARTS:LIMITATION textBorderColor only supports string (solid color)
          textBorderColor:
            labelProperties?.ln?.fill?.type === 'solid'
              ? color(labelProperties?.ln?.fill.color) ?? labelParentStyle.textBorderColor
              : labelParentStyle.textBorderColor,
          textBorderWidth: labelOutline?.borderWidth ?? labelParentStyle.textBorderWidth,
          textBorderType: labelOutline?.borderType ?? labelParentStyle.textBorderType,
        };

        if (label.layout?.type === 'manual') {
          //TODO:CHARTS
        }
      }
    }

    switch (bodyPrRot) {
      case 90:
        unsupportedVert = '90°';
        break;
      case -90:
        unsupportedVert = '270°';
        break;
    }

    switch (vert) {
      case 'vert':
        unsupportedVert = '90°';
        break;
      case 'vert270':
        unsupportedVert = '270°';
        break;
      case 'wordArtVert':
        unsupportedVert = 'Stacked';
        break;
      case 'wordArtVertRtl':
        unsupportedVert = 'Stacked Right-to-left';
        break;
    }

    if (unsupportedVert) {
      addUnsupportedElement(`Chart - Data Labels - Text Direction - ${unsupportedVert}`);
    }

    return {
      position: labelPosition,
      ...labelStyle,
    };
  };

  const dataLabelContent = ({
    dLbls,
    idx,
    val,
    cat,
    catFormatCode,
  }: {
    dLbls: Presentation.Data.DLbls | undefined;
    idx: number;
    val: string | undefined;
    cat: string | undefined;
    catFormatCode?: string;
  }): string => {
    if (!dLbls) {
      return '';
    }

    let formatCode: string = dLbls?.numFmt?.formatCode ?? 'General'; //default is "General"
    let separator = ','; //default is ","
    let parsedVal = val ?? ''; //y
    let parsedCat = cat ?? ''; //x
    let value = '';
    let showVal = dLbls.showVal;
    let showCatName = dLbls.showCatName;
    let showSerName = dLbls.showSerName;

    let unsupportedSeprator = '';
    switch (dLbls.separator) {
      case '\n':
        unsupportedSeprator = 'New line';
        break;
      case ' ':
        separator = '';
        break;
      case '; ':
        separator = ';';
        break;
      case '. ':
        separator = '.';
        break;
    }
    if (unsupportedSeprator) {
      addUnsupportedElement(
        `Chart - Data Labels - Label Contains - Separator - ${unsupportedSeprator}`,
      );
    }
    if (showSerName) {
      addUnsupportedElement('Chart - Data Labels - Label Contains - Series Name');
    }
    if (dLbls.showLeaderLines) {
      addUnsupportedElement('Chart - Data Labels - Label Contains - Show Leader Lines');
    }
    if (dLbls.showLegendKey) {
      addUnsupportedElement('Chart - Data Labels - Label Contains - Show Legend Key');
    }
    if (dLbls.showPercent) {
      addUnsupportedElement('Chart - Data Labels - Label Contains - Show Percent');
    }
    if (dLbls.showBubbleSize) {
      addUnsupportedElement('Chart - Data Labels - Label Contains - Show Bubble Size');
    }

    if (dLbls?.dLbl?.length) {
      const label = dLbls.dLbl.find((lbl) => lbl.idx === idx);
      if (label) {
        if (label.showSerName) {
          addUnsupportedElement('Chart - Data Labels - Label Contains - Series Name');
        }

        if (label.showLegendKey) {
          addUnsupportedElement('Chart - Data Labels - Label Contains - Show Legend Key');
        }
        if (label.showPercent) {
          addUnsupportedElement('Chart - Data Labels - Label Contains - Show Percent');
        }
        if (label.showBubbleSize) {
          addUnsupportedElement('Chart - Data Labels - Label Contains - Show Bubble Size');
        }

        //TODO:CHARTS proccess all types of child.type

        if (label.rich?.childNodes?.[0].childNodes?.length) {
          label.rich?.childNodes?.[0].childNodes?.forEach((run, i) => {
            const prevRun = label.rich?.childNodes?.[0].childNodes?.[i - 1];

            if (run.type === 'tx_field') {
              if (run.tx_field_type === 'XVALUE' || run.tx_field_type === 'CATEGORYNAME') {
                value += parsedCat;
              } else if (run.tx_field_type === 'YVALUE' || run.tx_field_type === 'VALUE') {
                value += parsedVal;
              } else if (run.tx_field_type === 'CELLRANGE') {
                addUnsupportedElement('Chart - Data Labels - Label Contains - Value From Cells');
              } else if (run.tx_field_type === 'SERIESNAME') {
                addUnsupportedElement('Chart - Data Labels - Label Contains - Series Name');
              }
            }
            run.childNodes?.forEach((child) => {
              if (child.type === 'text') {
                if (prevRun) {
                  switch (prevRun.type) {
                    case 'tx_field': {
                      switch (prevRun.tx_field_type) {
                        case 'CELLRANGE':
                        case 'SERIESNAME':
                          /**
                           * If the previous run element is a tx_field_type, then the current child content is a separator, for example ",".
                           * Since we didnt render the content from these tx_field_type, there is no need for a seprator
                           * Otherwise the label could look something like this ",,4.3", instead it will look like this "4.3"
                           */

                          return;
                      }
                    }
                  }
                }
                value += child?.content;
              }
            });
          });

          return value;
        } else {
          showVal = label.showVal;
          showCatName = label.showCatName;
        }
      }
    }
    switch (formatCode) {
      case 'General': {
        if (!isNaN(+parsedVal)) {
          parsedVal = `${Math.round(+parsedVal * 10) / 10}`;
        }

        break;
      }

      default: {
        parsedVal = formatValue(parsedVal, formatCode).content;
        break;
      }
    }

    switch (catFormatCode) {
      case 'General': {
        if (!isNaN(+parsedCat)) {
          parsedCat = `${Math.round(+parsedCat * 10) / 10}`;
        }

        break;
      }

      default: {
        parsedCat = formatValue(parsedCat, catFormatCode).content;
        break;
      }
    }

    //TODO:PRESENTATION:CHARTS:Leaving this commented because its not working
    // if (showVal && showCatName && showSerName) {
    //   value = `${dLbls.serieValue}${separator}\n${parsedCat}${separator}\n ${parsedVal}`;
    // }
    // else if (showSerName && showCatName) {
    //   value = `${dLbls.serieValue}${separator}\n${parsedCat}`;
    // }
    // else if (showSerName && showVal) {
    //   value = `${dLbls.serieValue}${separator}${parsedVal}`;
    // }
    //TODO:PRESENTATION:CHARTS:Proccess these strings in a dynamic way
    if (showVal && showCatName) {
      value = `${parsedCat}${separator} ${parsedVal}`;
    } else if (showVal) {
      value = `${parsedVal}`;
    } else if (showCatName) {
      value = `${parsedCat}`;
    }

    return value;
  };

  const dataLabel = ({
    dLbls,
    idx,
    val,
    cat,
    defaultPosition,
    chart,
    catFormatCode,
  }: Parameters<typeof dataLabelStyle>[0] &
    Parameters<typeof dataLabelContent>[0] & {
      chart: Presentation.Data.ChartTypesProperties | Presentation.Data.PlotAreaRegion;
      catFormatCode?: string;
    }): echarts.LineSeriesOption['label'] => {
    let show = dLbls?.showVal || dLbls?.showCatName || dLbls?.showLegendKey;
    const style = dataLabelStyle({ dLbls, idx, defaultPosition, chart });
    const content = dataLabelContent({ dLbls: dLbls, idx, val, cat, catFormatCode });

    if (dLbls?.dLbl?.length) {
      const label = dLbls?.dLbl.find((lbl) => lbl.idx === idx);
      if (label) {
        show = label.showVal || label.showCatName || label.showLegendKey;
      }
    }

    return {
      show,
      ...style,
      formatter: (_) => {
        return content;
      },
    };
  };

  return { dataLabel };
};

export default useDataLabel;
