import { Cmapps, Structure, StructureData } from '../../models';

type StyleData = { [index: string]: string };
type StyleDataChildren = { [index: string]: string[] };

export default class MultilevelStructure {
  private structure?: Structure;
  private cmapps?: Cmapps;
  index: string[] = [];
  childrenOfStyle: StyleDataChildren = {};
  styleData: StyleData = {};
  blockProps: StructureData['blkProps'] = {};
  nodesForLists: string[] = [];

  constructor(structure?: Structure, cmapps?: any) {
    this.structure = structure;
    this.cmapps = cmapps;
  }

  start() {
    this.build();
  }

  private build() {
    if (!this.structure || !this.cmapps) {
      return;
    }
    const tempIndex = new Set<string>();
    const tempStyleData: StyleData = {};
    const childrenOfStyle: StyleDataChildren = {};
    const tempBlockProps: StructureData['blkProps'] = {};
    const nodesForLists = [];
    const children = this.structure.childNodes;
    let nodeId;
    let cmapping;
    for (let index = 0; index < children.length; index++) {
      nodeId = children[index];
      cmapping = this.cmapps.hasNode(nodeId);
      if (cmapping) {
        for (let index = 0; index < cmapping.o.length; index++) {
          const element = cmapping.o[index];
          const blockProps = this.structure.getBlockProperties(element);
          if (!(blockProps.lst && blockProps.lst.lId == null)) {
            nodesForLists.push(element);
          }
          tempIndex.add(element);
          tempBlockProps[element] = blockProps;
          tempStyleData[element] = cmapping.sd[element];
        }
        const _childrenOfStyle = cmapping.cos;
        const styles = Object.keys(_childrenOfStyle);
        for (let index = 0; index < styles.length; index++) {
          const styleId = styles[index];
          if (!childrenOfStyle[styleId]) {
            childrenOfStyle[styleId] = [];
          }
          childrenOfStyle[styleId].push(..._childrenOfStyle[styleId]);
        }
      } else {
        const blockProps = this.structure.getBlockProperties(nodeId);
        if (!(blockProps.lst && blockProps.lst.lId == null)) {
          nodesForLists.push(nodeId);
        }
        tempBlockProps[nodeId] = blockProps;
        tempIndex.add(nodeId);
      }
    }
    this.index = Array.from(tempIndex);
    this.childrenOfStyle = childrenOfStyle;
    this.styleData = tempStyleData;
    this.blockProps = tempBlockProps;
    this.nodesForLists = nodesForLists;
  }

  childrenWithStyles(styles: string[]) {
    const result = [];
    for (let index = 0; index < styles.length; index++) {
      const style = styles[index];
      result.push(...(this.childrenOfStyle[style] || []));
    }
    return result;
  }
}
