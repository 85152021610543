import { ReduxInterface } from 'Editor/services';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement, CommentElement, TemporaryCommentElement } from '..';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';

export class CommentsViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: this.GENERIC_ATTRIBUTE_MAPPER.element_type,
    // element_reference
    element_reference: this.GENERIC_ATTRIBUTE_MAPPER.element_reference,
    // author
    author: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('author')) {
          builder.addProperty('author', node.getAttribute('author'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.author) {
          node.setAttribute('author', json.properties?.author);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('author');
      },
    },
    // user (legacy support)
    user: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('user') && !node.hasAttribute('author')) {
          builder.addProperty('author', node.getAttribute('user'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.user && !json.properties?.author) {
          node.setAttribute('author', json.properties?.user);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('user');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data) {
    let node: CommentElement;

    if (json.type === ELEMENTS.TemporaryComment.ELEMENT_TYPE) {
      node = DOMElementFactory.buildElement(
        ELEMENTS.TemporaryComment.TAG,
      ) as TemporaryCommentElement;
    } else {
      node = DOMElementFactory.buildElement(ELEMENTS.CommentElement.TAG) as CommentElement;
    }

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (!json.properties?.user && !json.properties?.author) {
      node.setAttribute('author', 'unknown');
    }

    if (json.type === ELEMENTS.TemporaryComment.ELEMENT_TYPE) {
      node.setHidden(this.Data.users.loggedUserId !== json.properties?.author);
    } else {
      const attribute = node.getAttribute('element_reference');
      if (attribute) {
        node.setHidden(!ReduxInterface.getFilteredComments()?.includes(attribute));
      }
    }

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    node.preRender();

    return node;
  }
}
