import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useToastSystem } from './ToastSystemContext';
import Toast from './Toast';

import styles from './ToastSystem.module.scss';
import { createRef } from 'react';

const ToastSystem = () => {
  const { toastList } = useToastSystem();

  return (
    <div className={styles.root} onMouseDown={(e) => e.stopPropagation()}>
      <TransitionGroup component={null}>
        {Object.values(toastList)
          .slice(0, 50) //Define max quantity of toasts to appear at once
          .map((toastProps, index) => {
            const nodeRef = createRef<HTMLDivElement>();
            return (
              <CSSTransition
                nodeRef={nodeRef}
                key={`toast-${toastProps.id}`}
                timeout={200}
                classNames="toast"
              >
                <Toast {...toastProps} ref={nodeRef} testId={`notification-${index + 1}`} />
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </div>
  );
};

export default ToastSystem;
