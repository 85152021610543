import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from '_common/hooks';

import UserPresentation, {
  UserPresentationProps,
} from '_common/suite/components/Card/UserPresentation/UserPresentation';

import styles from './AssigneePresentation.module.scss';

type AssigneePresentationProps = Omit<
  UserPresentationProps,
  'title' | 'fixedName' | 'creationDate'
>;

const AssigneePresentation = ({ userId, ...props }: AssigneePresentationProps) => {
  const intl = useIntl();

  const currentUserId = useSelector((state) => state.auth.userId);

  const isAssignee = useMemo(() => {
    return userId === currentUserId;
  }, [userId, currentUserId]);

  if (userId) {
    return (
      <UserPresentation
        {...props}
        userId={userId}
        title={intl.formatMessage({ id: 'ASSIGNED_TO' })}
        fixedName={isAssignee ? intl.formatMessage({ id: 'YOU' }) : undefined}
      />
    );
  }

  return (
    <div className={styles.noAssignee}>
      <div className={styles.avatarPlaceholder} />
      <div className={styles.label}>
        <FormattedMessage id="NO_USER_ASSIGNED" />
      </div>
    </div>
  );
};

export default AssigneePresentation;
