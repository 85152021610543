import { BaseTypedEmitter } from '_common/services/Realtime';

class SlideStatus {
  private promise: Promise<boolean>;
  private loaded: (value: boolean) => void = () => {};
  private reject: (value: any) => void = () => {};
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.loaded = resolve;
      this.reject = reject;
    });
  }

  await() {
    return this.promise;
  }

  slideLoaded(value: boolean = true) {
    this.loaded(value);
  }
}

export class NavigationService extends BaseTypedEmitter<Presentation.Navigation.Events> {
  private context: Presentation.Context;
  private currentSlide: number = 1;
  private slideStatus: {
    [index: number]: undefined | SlideStatus;
  } = {};

  constructor(context: Presentation.Context) {
    super();
    this.context = context;
    this.currentSlide = 1;

    this.start = this.start.bind(this);
    this.loadedSlide = this.loadedSlide.bind(this);
    this.unloadedSlide = this.unloadedSlide.bind(this);
    this.setCurrentSlide = this.setCurrentSlide.bind(this);
    this.goTo = this.goTo.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  start() {
    if (!this.slideStatus[this.currentSlide]) {
      this.slideStatus[this.currentSlide] = new SlideStatus();
    }
    this.emit('CHANGED_CURRENT_SLIDE', this.currentSlide);
  }

  stop() {
    // this.removeListener();
  }

  destroy() {
    // this.removeListener();
  }

  get current() {
    return this.currentSlide;
  }

  get numSlides() {
    return this.context.data?.structure.numSlides || 0;
  }

  loadedSlide(slideNumber: number) {
    if (!this.slideStatus[slideNumber]) {
      this.slideStatus[slideNumber] = new SlideStatus();
    }
    this.slideStatus[slideNumber]?.slideLoaded();
  }

  unloadedSlide(slideNumber: number) {
    if (this.slideStatus[slideNumber]) {
      delete this.slideStatus[slideNumber];
    }
  }

  async setCurrentSlide(slideNumber: number) {
    if (this.currentSlide !== slideNumber) {
      // TODO : extra validations
      this.emit('BEFORE_CHANGING_CURRENT_SLIDE', this.currentSlide);
      if (!this.slideStatus[this.currentSlide]) {
        this.slideStatus[this.currentSlide] = new SlideStatus();
      }
      this.currentSlide = slideNumber;
      this.emit('CHANGED_CURRENT_SLIDE', this.currentSlide);
      this.slideStatus[this.currentSlide]?.await();
      this.emit('AFTER_CHANGING_CURRENT_SLIDE', this.currentSlide);
      return;
    }
  }

  async goTo(slideNumber: number) {
    if (this.currentSlide !== slideNumber) {
      // TODO : extra validations
      this.emit('BEFORE_CHANGING_CURRENT_SLIDE', this.currentSlide);

      if (!this.slideStatus[this.currentSlide]) {
        this.slideStatus[this.currentSlide] = new SlideStatus();
      }
      this.currentSlide = slideNumber;
      this.emit('SCROLL_TO_SLIDE', this.currentSlide);
      this.emit('CHANGED_CURRENT_SLIDE', this.currentSlide);
      this.slideStatus[this.currentSlide]?.await();
      this.emit('AFTER_CHANGING_CURRENT_SLIDE', this.currentSlide);
      return;
    }
  }

  async goToSlideId(slideId: string) {
    const slideIndex = this.context.data?.structure.getSlideIndex(slideId);
    if (slideIndex < 0) {
      return;
    }
    const slideNumber = slideIndex + 1;
    if (this.currentSlide !== slideNumber) {
      // TODO : extra validations
      this.emit('BEFORE_CHANGING_CURRENT_SLIDE', this.currentSlide);

      if (!this.slideStatus[this.currentSlide]) {
        this.slideStatus[this.currentSlide] = new SlideStatus();
      }
      this.currentSlide = slideNumber;
      this.emit('SCROLL_TO_SLIDE', this.currentSlide);
      this.emit('CHANGED_CURRENT_SLIDE', this.currentSlide);
      this.slideStatus[this.currentSlide]?.await();
      this.emit('AFTER_CHANGING_CURRENT_SLIDE', this.currentSlide);
      return;
    }
  }

  previous() {
    return this.goTo(Math.max(this.currentSlide - 1, 1));
  }

  next() {
    return this.goTo(Math.min(this.currentSlide + 1, this.numSlides));
  }

  goToFirstSlide() {
    return this.goTo(1);
  }

  goToLastSlide() {
    return this.goTo(this.numSlides);
  }
}
