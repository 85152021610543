import { useEffect, useState } from 'react';

import { ExtensionsService } from '_common/services';

import { useGetInstalledTemplatesListQuery } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';

import styles from './TemplateDetails.module.scss';
import { Button } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import { TemplateGallery } from '_common/components';
import useConditionalData from '../useConditionalData';

type TemplateDetailsProps = {
  objectId: ObjectId;
};

const TemplateDetails = ({ objectId }: TemplateDetailsProps) => {
  const [choosingTemplate, setChoosingTemplate] = useState(false);

  const { template } = useConditionalData();

  const { activeTemplate } = useGetInstalledTemplatesListQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      activeTemplate: result.data?.find((objTemplate) => objTemplate.id === template),
    }),
  });

  const [thumbnail, setThumbnail] = useState('');

  useEffect(() => {
    setThumbnail('');
    if (template) {
      new ExtensionsService().previewTemplate(template).then((response) => {
        const url = URL.createObjectURL(response.data); // create <img> with src set to the blob
        setThumbnail(url);
      });
    }
  }, [template]);

  const name = activeTemplate?.name || 'No Template';
  return (
    <div className={styles.root}>
      <div className={styles.img_container}>
        {template ? (
          <img className={styles.img} alt={name} src={thumbnail} />
        ) : (
          <div className={`${styles.img} ${styles.noTemplate}`} />
        )}
      </div>
      <div>
        <div>{name}</div>
        <Button
          variant="neutral"
          size="small"
          margin="1rem 0 0 0"
          onClick={() => setChoosingTemplate(true)}
          testId="change=template-button"
        >
          <FormattedMessage id="CHANGE_TEMPLATE" />
        </Button>
        {choosingTemplate && (
          <TemplateGallery objectId={objectId} close={() => setChoosingTemplate(false)} />
        )}
      </div>
    </div>
  );
};

export default TemplateDetails;
