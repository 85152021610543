import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

type CollaboratorsCellProps = {
  object: Objekt;
  personalLabel?: boolean;
};

const CollaboratorsCell = ({ object, personalLabel }: CollaboratorsCellProps) => {
  const totalCollaborators = useMemo(() => {
    return object.shared_with
      ? object.shared_with.length
      : 0;
  }, [object]);

  if (personalLabel && object.personal) {
    return <FormattedMessage id="spaces.personal" />;
  }

  if (totalCollaborators > 0) {
    return <FormattedMessage values={{ members: totalCollaborators }} id="spaces.collaborators" />;
  }

  return <>-</>;
};

export default CollaboratorsCell;
