import { ChangeEventHandler, MouseEventHandler } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Icon, Button, Input, TextArea, InputField, ProgressBar } from 'dodoc-design-system';

import formatBytes from 'utils/formatBytes';

import { Modals } from '_common/modals/ModalsSliceTypes';
import { useSelector } from '_common/hooks';

import styles from '../CheckInModal.module.scss';

type UploadErrorProps = {
  reset: MouseEventHandler<HTMLSpanElement>;
  name: string;
  editing?: boolean;
  toggleEditingName: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  onInputChanged: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  comment?: string;
  modal: Modals;
};

const UploadErrors = ({
  reset,
  editing,
  onInputChanged,
  name,
  toggleEditingName,
  comment,
  modal,
}: UploadErrorProps) => {
  const intl = useIntl();

  // redux
  const loading = useSelector((state) => state.modals[modal].loading);
  const file = useSelector((state) => state.modals[modal].file);
  const uploadPercentage = useSelector((state) => state.modals[modal].uploadPercentage);
  const errors = useSelector((state) => state.modals[modal].errors);

  const { size } = file;
  return (
    <div>
      <div className={styles.warning}>
        <span className={styles.clearButton} onClick={reset}>
          <Icon icon="CloseGrey" size={24} />
        </span>
        <Icon icon="Document" size={96} />
        {editing ? (
          <div>
            <Input
              name="name"
              onChange={onInputChanged}
              value={name}
              size="medium"
              placeholder=""
              testId="name"
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '0.5rem',
              }}
            >
              <Button size="medium" variant="primary" onClick={toggleEditingName} testId="save-button">
                <FormattedMessage id="global.save" />
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <div style={{ display: 'flex' }}>
                <span className={styles.value}>{name}</span>
                <span className={styles.append}>{`(${formatBytes(size)})`}</span>
              </div>
              {!loading && (
                <div className={styles.link} onClick={toggleEditingName}>
                  <FormattedMessage id="storage.modals.checkIn.click" />
                </div>
              )}
              {loading && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1.5rem',
                  }}
                >
                  <ProgressBar
                    progress={uploadPercentage}
                    testId="check-in-modal-uploading-with-error-progressBar"
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles.error}>
          <span>
            <FormattedMessage id="storage.modals.checkIn.errorDesc" />
          </span>
          {errors.name && (
            <div style={{ marginTop: '0.5rem' }}>
              <FormattedMessage id="storage.modals.checkIn.errorName" />
            </div>
          )}
          {errors.mime && (
            <div style={{ marginTop: '0.5rem' }}>
              <FormattedMessage id="storage.modals.checkIn.errorMime" />
            </div>
          )}
          {errors.extension === true && (
            <div style={{ marginTop: '0.5rem' }}>
              <FormattedMessage id="storage.modals.checkIn.errorExt" />
            </div>
          )}
        </div>
      </div>
      <div className={styles.comment}>
        <InputField
          label={intl.formatMessage({
            id: 'storage.modals.checkIn.leaveComment',
          })}
          size="large"
          testId="comment-field"
        >
          <TextArea
            size="large"
            name="comment"
            placeholder={intl.formatMessage({
              id: 'storage.modals.checkIn.leaveCommentPlaceholder',
            })}
            value={comment}
            onChange={onInputChanged}
            testId="leave-a-comment-textarea"
          />
        </InputField>
      </div>
    </div>
  );
};

export default UploadErrors;
