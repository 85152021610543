import { Popover, Toggle, Tooltip, usePopper } from 'dodoc-design-system';
import { useState, useMemo, CSSProperties } from 'react';
import { detectOverflow } from '@popperjs/core';

import { useIEnvision, useSelector } from '_common/hooks';
import type { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';
import NotificationsPopover from '../Popovers/NotificationsPopover/NotificationsPopover';
import { useIntl } from 'react-intl';
import NotificationsCenterContext from './NotificationsCenterContext';

type NotificationsCenterProps = {
  margin?: CSSProperties['margin'];
  object: doDOC.SuiteObject;
  navigateToObject: ({ objectType, objectId, documentId }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
    objectId: ObjectId;
    documentId: ObjectId;
  }) => void;
};

const NotificationsCenter = ({ margin, object, navigateToObject }: NotificationsCenterProps) => {
  const intl = useIntl();
  const unreadNotifications = useSelector((state) => state.notifications.unreadCount);
  const [popperHeight, setPopperHeight] = useState(640);
  const isIEnvision = useIEnvision();
  //@ts-expect-error
  const modifiers: PopperProps['modifiers'] = useMemo(() => {
    return [
      {
        name: 'resizePopper',
        enabled: true,
        phase: 'beforeWrite',
        requiresIfExists: ['offset'],

        fn({ state }) {
          const padding = 40; //px
          const maxHeight = 640; //px

          const overflow = detectOverflow(state, {
            padding: { top: padding, bottom: padding },
          });

          let newHeight = 0;

          if (overflow.bottom > 0) {
            newHeight = state.rects.popper.height - overflow.bottom;

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
            }

            state.styles.popper.height = `${newHeight}px`;
          } else {
            newHeight = state.rects.popper.height + Math.abs(overflow.bottom);

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
            }

            state.styles.popper.height = `${newHeight}px`;
          }
          setPopperHeight(newHeight);
        },
        effect(meta) {
          meta.instance.forceUpdate(); //force update to update popper position when open popover
        },
      },
    ];
  }, [setPopperHeight]);

  const notificationsPopover = usePopper({
    placement: 'bottom-end',
    distance: 8,
    strategy: 'fixed',
    modifiers,
    disabled: isIEnvision,
  });

  notificationsPopover.popperProps.style = {
    ...notificationsPopover.popperProps.style,
    marginBottom: '5rem',
  };

  const handleToggleIcon = () => {
    if (notificationsPopover.isOpen) {
      return 'PDFNotificationsBlue';
    } else {
      return 'PDFNotificationsGrey';
    }
  };

  return (
    <NotificationsCenterContext.Provider value={{ navigateToObject: navigateToObject }}>
      <Tooltip
        content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
        placement="left"
        disabled={!isIEnvision}
        testId="notifications-no-permission-tooltip"
      >
        <Toggle
          variant="link"
          icon={handleToggleIcon()}
          size="medium"
          iconBadge={
            unreadNotifications > 0
              ? {
                  icon: 'Badge',
                  size: 16,
                  number: unreadNotifications,
                  position: 'top-right',
                }
              : undefined
          }
          margin={margin ?? '0 1rem 0 0'}
          isToggled={notificationsPopover.isOpen}
          disabled={isIEnvision}
          {...notificationsPopover.referenceProps}
          testId={`notifications-toggle`}
        />
      </Tooltip>
      <Popover {...notificationsPopover.popperProps} testId="notifications-center-popper">
        <NotificationsPopover
          closePopover={notificationsPopover.close}
          popperHeight={popperHeight}
          object={object}
        />
      </Popover>
    </NotificationsCenterContext.Provider>
  );
};

export default NotificationsCenter;
