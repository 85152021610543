import { path } from 'd3';
import { constantToRad, ellipseArcTo, pin } from './utils';

type StarAndBannerProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'star4'
    | 'star5'
    | 'star6'
    | 'star7'
    | 'star8'
    | 'star10'
    | 'star12'
    | 'star16'
    | 'star24'
    | 'star32'
    | 'irregularSeal1'
    | 'irregularSeal2'
    | 'ribbon2'
    | 'ribbon'
    | 'doubleWave'
    | 'wave'
    | 'horizontalScroll'
    | 'verticalScroll'
    | 'ellipseRibbon'
    | 'ellipseRibbon2';
};

const getAdjstByType = (type: StarAndBannerProps['type']) => {
  switch (type) {
    case 'star4':
    case 'star5':
      return {
        adj1: 19098,
        adj2: 0,
        adj3: 0,
      };
    case 'star6':
      return {
        adj1: 28868,
        adj2: 0,
        adj3: 0,
      };
    case 'star7':
      return {
        adj1: 34601,
        adj2: 0,
        adj3: 0,
      };
    case 'star8':
    case 'star12':
    case 'star16':
    case 'star24':
    case 'star32':
      return {
        adj1: 37500,
        adj2: 0,
        adj3: 0,
      };
    case 'star10':
      return {
        adj1: 42533,
        adj2: 0,
        adj3: 0,
      };
    case 'ribbon2':
    case 'ribbon':
      return {
        adj1: 16667,
        adj2: 50000,
        adj3: 0,
      };
    case 'ellipseRibbon':
    case 'ellipseRibbon2':
      return {
        adj1: 25000,
        adj2: 50000,
        adj3: 12500,
      };
    case 'horizontalScroll':
    case 'verticalScroll':
    case 'wave':
      return {
        adj1: 12500,
        adj2: 0,
        adj3: 0,
      };
    case 'doubleWave':
      return {
        adj1: 6250,
        adj2: 0,
        adj3: 0,
      };
    case 'irregularSeal1':
    case 'irregularSeal2':
      return {
        adj1: 0,
        adj2: 0,
        adj3: 0,
      };
  }
};

const generateStarAndBannerPath = ({
  size,
  type,
  adjst,
}: StarAndBannerProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;
  /** Width / 4 */
  const wd4 = w / 4;
  /** Height / 4 */
  const hd4 = h / 4;
  /** Width / 8 */
  const wd8 = w / 8;
  /** Width / 32 */
  const wd32 = w / 32;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side */
  const ss = Math.min(w, h);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst?.adj1 : getAdjstByType(type).adj1;
  const adj2 = adjst?.adj2 ? +adjst?.adj2 : getAdjstByType(type).adj2;
  const adj3 = adjst?.adj3 ? +adjst?.adj3 : getAdjstByType(type).adj3;

  switch (type) {
    case 'irregularSeal1': {
      //Based on definitions, this shape will be drawn with a ratio of 21600x21600
      const getWidthRelativeCoord = (constant: number) => (w * constant) / 21600;
      const getHeightRelativeCoord = (constant: number) => (h * constant) / 21600;

      const x5 = getWidthRelativeCoord(4627);
      const x21 = getWidthRelativeCoord(16702);
      const y3 = getHeightRelativeCoord(6320);
      const y9 = getHeightRelativeCoord(13937);

      const d = path();
      d.moveTo(getWidthRelativeCoord(10800), getHeightRelativeCoord(5800));
      d.lineTo(getWidthRelativeCoord(14522), getHeightRelativeCoord(0));
      d.lineTo(getWidthRelativeCoord(14155), getHeightRelativeCoord(5325));
      d.lineTo(getWidthRelativeCoord(18380), getHeightRelativeCoord(4457));
      d.lineTo(getWidthRelativeCoord(16702), getHeightRelativeCoord(7315));
      d.lineTo(getWidthRelativeCoord(21097), getHeightRelativeCoord(8137));
      d.lineTo(getWidthRelativeCoord(17607), getHeightRelativeCoord(10475));
      d.lineTo(getWidthRelativeCoord(21600), getHeightRelativeCoord(13290));
      d.lineTo(getWidthRelativeCoord(16837), getHeightRelativeCoord(12942));
      d.lineTo(getWidthRelativeCoord(18145), getHeightRelativeCoord(18095));
      d.lineTo(getWidthRelativeCoord(14020), getHeightRelativeCoord(14457));
      d.lineTo(getWidthRelativeCoord(13247), getHeightRelativeCoord(19737));
      d.lineTo(getWidthRelativeCoord(10532), getHeightRelativeCoord(14935));
      d.lineTo(getWidthRelativeCoord(8485), getHeightRelativeCoord(21600));
      d.lineTo(getWidthRelativeCoord(7715), getHeightRelativeCoord(15627));
      d.lineTo(getWidthRelativeCoord(4762), getHeightRelativeCoord(17617));
      d.lineTo(getWidthRelativeCoord(5667), getHeightRelativeCoord(13937));
      d.lineTo(getWidthRelativeCoord(135), getHeightRelativeCoord(14587));
      d.lineTo(getWidthRelativeCoord(3722), getHeightRelativeCoord(11775));
      d.lineTo(getWidthRelativeCoord(0), getHeightRelativeCoord(8615));
      d.lineTo(getWidthRelativeCoord(4627), getHeightRelativeCoord(7617));
      d.lineTo(getWidthRelativeCoord(370), getHeightRelativeCoord(2295));
      d.lineTo(getWidthRelativeCoord(7312), getHeightRelativeCoord(6320));
      d.lineTo(getWidthRelativeCoord(8352), getHeightRelativeCoord(2295));
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x5, t: y3, r: x21, b: y9 } };
    }
    case 'irregularSeal2': {
      //Based on definitions, this shape will be drawn with a ratio of 21600x21600
      const getWidthRelativeCoord = (constant: number) => (w * constant) / 21600;
      const getHeightRelativeCoord = (constant: number) => (h * constant) / 21600;

      const x5 = getWidthRelativeCoord(5372);
      const x19 = getWidthRelativeCoord(14640);
      const y3 = getHeightRelativeCoord(6382);
      const y17 = getHeightRelativeCoord(15935);

      const d = path();
      d.moveTo(getWidthRelativeCoord(11462), getHeightRelativeCoord(4342));
      d.lineTo(getWidthRelativeCoord(14790), getHeightRelativeCoord(0));
      d.lineTo(getWidthRelativeCoord(14525), getHeightRelativeCoord(5777));
      d.lineTo(getWidthRelativeCoord(18007), getHeightRelativeCoord(3172));
      d.lineTo(getWidthRelativeCoord(16380), getHeightRelativeCoord(6532));
      d.lineTo(getWidthRelativeCoord(21600), getHeightRelativeCoord(6645));
      d.lineTo(getWidthRelativeCoord(16985), getHeightRelativeCoord(9402));
      d.lineTo(getWidthRelativeCoord(18270), getHeightRelativeCoord(11290));
      d.lineTo(getWidthRelativeCoord(16380), getHeightRelativeCoord(12310));
      d.lineTo(getWidthRelativeCoord(18877), getHeightRelativeCoord(15632));
      d.lineTo(getWidthRelativeCoord(14640), getHeightRelativeCoord(14350));
      d.lineTo(getWidthRelativeCoord(14942), getHeightRelativeCoord(17370));
      d.lineTo(getWidthRelativeCoord(12180), getHeightRelativeCoord(15935));
      d.lineTo(getWidthRelativeCoord(11612), getHeightRelativeCoord(18842));
      d.lineTo(getWidthRelativeCoord(9872), getHeightRelativeCoord(17370));
      d.lineTo(getWidthRelativeCoord(8700), getHeightRelativeCoord(19712));
      d.lineTo(getWidthRelativeCoord(7527), getHeightRelativeCoord(18125));
      d.lineTo(getWidthRelativeCoord(4917), getHeightRelativeCoord(21600));
      d.lineTo(getWidthRelativeCoord(4805), getHeightRelativeCoord(18240));
      d.lineTo(getWidthRelativeCoord(1285), getHeightRelativeCoord(17825));
      d.lineTo(getWidthRelativeCoord(3330), getHeightRelativeCoord(15370));
      d.lineTo(getWidthRelativeCoord(0), getHeightRelativeCoord(12877));
      d.lineTo(getWidthRelativeCoord(3935), getHeightRelativeCoord(11592));
      d.lineTo(getWidthRelativeCoord(1172), getHeightRelativeCoord(8270));
      d.lineTo(getWidthRelativeCoord(5372), getHeightRelativeCoord(7817));
      d.lineTo(getWidthRelativeCoord(4502), getHeightRelativeCoord(3625));
      d.lineTo(getWidthRelativeCoord(8550), getHeightRelativeCoord(6382));
      d.lineTo(getWidthRelativeCoord(9722), getHeightRelativeCoord(1887));
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x5, t: y3, r: x19, b: y17 } };
    }
    case 'star4': {
      const a = pin(0, adj1, 50000);
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx = iwd2 * Math.cos(constantToRad(2700000));
      const sdy = ihd2 * Math.sin(constantToRad(2700000));
      const sx1 = hc - sdx;
      const sx2 = hc + sdx;
      const sy1 = vc - sdy;
      const sy2 = vc + sdy;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(sx1, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx2, sy1);
      d.lineTo(r, vc);
      d.lineTo(sx2, sy2);
      d.lineTo(hc, b);
      d.lineTo(sx1, sy2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx1, t: sy1, r: sx2, b: sy2 } };
    }
    case 'star5': {
      const hf = 105146;
      const vf = 110557;

      const a = pin(0, adj1, 50000);
      const swd2 = (wd2 * hf) / 100000;
      const shd2 = (hd2 * vf) / 100000;
      const svc = (vc * vf) / 100000;
      const dx1 = swd2 * Math.cos(constantToRad(1080000));
      const dx2 = swd2 * Math.cos(constantToRad(18360000));
      const dy1 = shd2 * Math.sin(constantToRad(1080000));
      const dy2 = shd2 * Math.sin(constantToRad(18360000));
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = svc - dy1;
      const y2 = svc - dy2;
      const iwd2 = (swd2 * a) / 50000;
      const ihd2 = (shd2 * a) / 50000;
      const sdx1 = iwd2 * Math.cos(constantToRad(20520000));
      const sdx2 = iwd2 * Math.cos(constantToRad(3240000));
      const sdy1 = ihd2 * Math.sin(constantToRad(3240000));
      const sdy2 = ihd2 * Math.sin(constantToRad(20520000));
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc + sdx2;
      const sx4 = hc + sdx1;
      const sy1 = svc - sdy1;
      const sy2 = svc - sdy2;
      const sy3 = svc + ihd2;

      const d = path();
      d.moveTo(x1, y1);
      d.lineTo(sx2, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx3, sy1);
      d.lineTo(x4, y1);
      d.lineTo(sx4, sy2);
      d.lineTo(x3, y2);
      d.lineTo(hc, sy3);
      d.lineTo(x2, y2);
      d.lineTo(sx1, sy2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx1, t: sy1, r: sx4, b: sy3 } };
    }
    case 'star6': {
      const hf = 115470;

      const a = pin(0, adj1, 50000);
      const swd2 = (wd2 * hf) / 100000;
      const dx1 = swd2 * Math.cos(constantToRad(1800000));
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const y2 = vc + hd4;
      const iwd2 = (swd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx2 = iwd2 / 2;
      const sx1 = hc - iwd2;
      const sx2 = hc - sdx2;
      const sx3 = hc + sdx2;
      const sx4 = hc + iwd2;
      const sdy1 = ihd2 * Math.sin(constantToRad(3600000));
      const sy1 = vc - sdy1;
      const sy2 = vc + sdy1;

      const d = path();
      d.moveTo(x1, hd4);
      d.lineTo(sx2, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx3, sy1);
      d.lineTo(x2, hd4);
      d.lineTo(sx4, vc);
      d.lineTo(x2, y2);
      d.lineTo(sx3, sy2);
      d.lineTo(hc, b);
      d.lineTo(sx2, sy2);
      d.lineTo(x1, y2);
      d.lineTo(sx1, vc);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx1, t: sy1, r: sx4, b: sy2 } };
    }
    case 'star7': {
      const hf = 102572;
      const vf = 105210;

      const a = pin(0, adj1, 50000);
      const swd2 = (wd2 * hf) / 100000;
      const shd2 = (hd2 * vf) / 100000;
      const svc = (vc * vf) / 100000;
      const dx1 = (swd2 * 97493) / 100000;
      const dx2 = (swd2 * 78183) / 100000;
      const dx3 = (swd2 * 43388) / 100000;
      const dy1 = (shd2 * 62349) / 100000;
      const dy2 = (shd2 * 22252) / 100000;
      const dy3 = (shd2 * 90097) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x5 = hc + dx2;
      const x6 = hc + dx1;
      const y1 = svc - dy1;
      const y2 = svc + dy2;
      const y3 = svc + dy3;
      const iwd2 = (swd2 * a) / 50000;
      const ihd2 = (shd2 * a) / 50000;
      const sdx1 = (iwd2 * 97493) / 100000;
      const sdx2 = (iwd2 * 78183) / 100000;
      const sdx3 = (iwd2 * 43388) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc + sdx3;
      const sx5 = hc + sdx2;
      const sx6 = hc + sdx1;
      const sdy1 = (ihd2 * 90097) / 100000;
      const sdy2 = (ihd2 * 22252) / 100000;
      const sdy3 = (ihd2 * 62349) / 100000;
      const sy1 = svc - sdy1;
      const sy2 = svc - sdy2;
      const sy3 = svc + sdy3;
      const sy4 = svc + ihd2;

      const d = path();
      d.moveTo(x1, y2);
      d.lineTo(sx1, sy2);
      d.lineTo(x2, y1);
      d.lineTo(sx3, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx4, sy1);
      d.lineTo(x5, y1);
      d.lineTo(sx6, sy2);
      d.lineTo(x6, y2);
      d.lineTo(sx5, sy3);
      d.lineTo(x4, y3);
      d.lineTo(hc, sy4);
      d.lineTo(x3, y3);
      d.lineTo(sx2, sy3);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx2, t: sy1, r: sx5, b: sy3 } };
    }
    case 'star8': {
      const a = pin(0, adj1, 50000);
      const dx1 = wd2 * Math.cos(constantToRad(2700000));
      const x1 = hc - dx1;
      const x2 = hc + dx1;
      const dy1 = hd2 * Math.sin(constantToRad(2700000));
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx1 = (iwd2 * 92388) / 100000;
      const sdx2 = (iwd2 * 38268) / 100000;
      const sdy1 = (ihd2 * 92388) / 100000;
      const sdy2 = (ihd2 * 38268) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc + sdx2;
      const sx4 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc + sdy2;
      const sy4 = vc + sdy1;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(sx1, sy2);
      d.lineTo(x1, y1);
      d.lineTo(sx2, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx3, sy1);
      d.lineTo(x2, y1);
      d.lineTo(sx4, sy2);
      d.lineTo(r, vc);
      d.lineTo(sx4, sy3);
      d.lineTo(x2, y2);
      d.lineTo(sx3, sy4);
      d.lineTo(hc, b);
      d.lineTo(sx2, sy4);
      d.lineTo(x1, y2);
      d.lineTo(sx1, sy3);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx1, t: sy1, r: sx4, b: sy4 } };
    }
    case 'star10': {
      const hf = 105146;

      const a = pin(0, adj1, 50000);
      const swd2 = (wd2 * hf) / 100000;
      const dx1 = (swd2 * 95106) / 100000;
      const dx2 = (swd2 * 58779) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const dy1 = (hd2 * 80902) / 100000;
      const dy2 = (hd2 * 30902) / 100000;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;
      const iwd2 = (swd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx1 = (iwd2 * 80902) / 100000;
      const sdx2 = (iwd2 * 30902) / 100000;
      const sdy1 = (ihd2 * 95106) / 100000;
      const sdy2 = (ihd2 * 58779) / 100000;
      const sx1 = hc - iwd2;
      const sx2 = hc - sdx1;
      const sx3 = hc - sdx2;
      const sx4 = hc + sdx2;
      const sx5 = hc + sdx1;
      const sx6 = hc + iwd2;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc + sdy2;
      const sy4 = vc + sdy1;

      const d = path();
      d.moveTo(x1, y2);
      d.lineTo(sx2, sy2);
      d.lineTo(x2, y1);
      d.lineTo(sx3, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx4, sy1);
      d.lineTo(x3, y1);
      d.lineTo(sx5, sy2);
      d.lineTo(x4, y2);
      d.lineTo(sx6, vc);
      d.lineTo(x4, y3);
      d.lineTo(sx5, sy3);
      d.lineTo(x3, y4);
      d.lineTo(sx4, sy4);
      d.lineTo(hc, b);
      d.lineTo(sx3, sy4);
      d.lineTo(x2, y4);
      d.lineTo(sx2, sy3);
      d.lineTo(x1, y3);
      d.lineTo(sx1, vc);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx2, t: sy2, r: sx5, b: sy3 } };
    }
    case 'star12': {
      const a = pin(0, adj1, 50000);
      const dx1 = wd2 * Math.cos(constantToRad(1800000));
      const dy1 = hd2 * Math.sin(constantToRad(3600000));
      const x1 = hc - dx1;
      const x3 = (w * 3) / 4;
      const x4 = hc + dx1;
      const y1 = vc - dy1;
      const y3 = (h * 3) / 4;
      const y4 = vc + dy1;
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx1 = iwd2 * Math.cos(constantToRad(900000));
      const sdx2 = iwd2 * Math.cos(constantToRad(2700000));
      const sdx3 = iwd2 * Math.cos(constantToRad(4500000));
      const sdy1 = ihd2 * Math.sin(constantToRad(4500000));
      const sdy2 = ihd2 * Math.sin(constantToRad(2700000));
      const sdy3 = ihd2 * Math.sin(constantToRad(900000));
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc + sdx3;
      const sx5 = hc + sdx2;
      const sx6 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc + sdy3;
      const sy5 = vc + sdy2;
      const sy6 = vc + sdy1;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(sx1, sy3);
      d.lineTo(x1, hd4);
      d.lineTo(sx2, sy2);
      d.lineTo(wd4, y1);
      d.lineTo(sx3, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx4, sy1);
      d.lineTo(x3, y1);
      d.lineTo(sx5, sy2);
      d.lineTo(x4, hd4);
      d.lineTo(sx6, sy3);
      d.lineTo(r, vc);
      d.lineTo(sx6, sy4);
      d.lineTo(x4, y3);
      d.lineTo(sx5, sy5);
      d.lineTo(x3, y4);
      d.lineTo(sx4, sy6);
      d.lineTo(hc, b);
      d.lineTo(sx3, sy6);
      d.lineTo(wd4, y4);
      d.lineTo(sx2, sy5);
      d.lineTo(x1, y3);
      d.lineTo(sx1, sy4);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: sx2, t: sy2, r: sx5, b: sy5 } };
    }
    case 'star16': {
      const a = pin(0, adj1, 50000);
      const dx1 = (wd2 * 92388) / 100000;
      const dx2 = (wd2 * 70711) / 100000;
      const dx3 = (wd2 * 38268) / 100000;
      const dy1 = (hd2 * 92388) / 100000;
      const dy2 = (hd2 * 70711) / 100000;
      const dy3 = (hd2 * 38268) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x5 = hc + dx2;
      const x6 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc - dy3;
      const y4 = vc + dy3;
      const y5 = vc + dy2;
      const y6 = vc + dy1;
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx1 = (iwd2 * 98079) / 100000;
      const sdx2 = (iwd2 * 83147) / 100000;
      const sdx3 = (iwd2 * 55557) / 100000;
      const sdx4 = (iwd2 * 19509) / 100000;
      const sdy1 = (ihd2 * 98079) / 100000;
      const sdy2 = (ihd2 * 83147) / 100000;
      const sdy3 = (ihd2 * 55557) / 100000;
      const sdy4 = (ihd2 * 19509) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc - sdx4;
      const sx5 = hc + sdx4;
      const sx6 = hc + sdx3;
      const sx7 = hc + sdx2;
      const sx8 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc - sdy4;
      const sy5 = vc + sdy4;
      const sy6 = vc + sdy3;
      const sy7 = vc + sdy2;
      const sy8 = vc + sdy1;
      const idx = iwd2 * Math.cos(constantToRad(2700000));
      const idy = ihd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const it = vc - idy;
      const ir = hc + idx;
      const ib = vc + idy;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(sx1, sy4);
      d.lineTo(x1, y3);
      d.lineTo(sx2, sy3);
      d.lineTo(x2, y2);
      d.lineTo(sx3, sy2);
      d.lineTo(x3, y1);
      d.lineTo(sx4, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx5, sy1);
      d.lineTo(x4, y1);
      d.lineTo(sx6, sy2);
      d.lineTo(x5, y2);
      d.lineTo(sx7, sy3);
      d.lineTo(x6, y3);
      d.lineTo(sx8, sy4);
      d.lineTo(r, vc);
      d.lineTo(sx8, sy5);
      d.lineTo(x6, y4);
      d.lineTo(sx7, sy6);
      d.lineTo(x5, y5);
      d.lineTo(sx6, sy7);
      d.lineTo(x4, y6);
      d.lineTo(sx5, sy8);
      d.lineTo(hc, b);
      d.lineTo(sx4, sy8);
      d.lineTo(x3, y6);
      d.lineTo(sx3, sy7);
      d.lineTo(x2, y5);
      d.lineTo(sx2, sy6);
      d.lineTo(x1, y4);
      d.lineTo(sx1, sy5);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'star24': {
      const a = pin(0, adj1, 50000);
      const dx1 = wd2 * Math.cos(constantToRad(900000));
      const dx2 = wd2 * Math.cos(constantToRad(1800000));
      const dx3 = wd2 * Math.cos(constantToRad(2700000));
      const dx4 = wd4;
      const dx5 = wd2 * Math.cos(constantToRad(4500000));
      const dy1 = hd2 * Math.sin(constantToRad(4500000));
      const dy2 = hd2 * Math.sin(constantToRad(3600000));
      const dy3 = hd2 * Math.sin(constantToRad(2700000));
      const dy4 = hd4;
      const dy5 = hd2 * Math.sin(constantToRad(900000));
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc - dx4;
      const x5 = hc - dx5;
      const x6 = hc + dx5;
      const x7 = hc + dx4;
      const x8 = hc + dx3;
      const x9 = hc + dx2;
      const x10 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc - dy3;
      const y4 = vc - dy4;
      const y5 = vc - dy5;
      const y6 = vc + dy5;
      const y7 = vc + dy4;
      const y8 = vc + dy3;
      const y9 = vc + dy2;
      const y10 = vc + dy1;
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx1 = (iwd2 * 99144) / 100000;
      const sdx2 = (iwd2 * 92388) / 100000;
      const sdx3 = (iwd2 * 79335) / 100000;
      const sdx4 = (iwd2 * 60876) / 100000;
      const sdx5 = (iwd2 * 38268) / 100000;
      const sdx6 = (iwd2 * 13053) / 100000;
      const sdy1 = (ihd2 * 99144) / 100000;
      const sdy2 = (ihd2 * 92388) / 100000;
      const sdy3 = (ihd2 * 79335) / 100000;
      const sdy4 = (ihd2 * 60876) / 100000;
      const sdy5 = (ihd2 * 38268) / 100000;
      const sdy6 = (ihd2 * 13053) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc - sdx4;
      const sx5 = hc - sdx5;
      const sx6 = hc - sdx6;
      const sx7 = hc + sdx6;
      const sx8 = hc + sdx5;
      const sx9 = hc + sdx4;
      const sx10 = hc + sdx3;
      const sx11 = hc + sdx2;
      const sx12 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc - sdy4;
      const sy5 = vc - sdy5;
      const sy6 = vc - sdy6;
      const sy7 = vc + sdy6;
      const sy8 = vc + sdy5;
      const sy9 = vc + sdy4;
      const sy10 = vc + sdy3;
      const sy11 = vc + sdy2;
      const sy12 = vc + sdy1;
      const idx = iwd2 * Math.cos(constantToRad(2700000));
      const idy = ihd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const it = vc - idy;
      const ir = hc + idx;
      const ib = vc + idy;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(sx1, sy6);
      d.lineTo(x1, y5);
      d.lineTo(sx2, sy5);
      d.lineTo(x2, y4);
      d.lineTo(sx3, sy4);
      d.lineTo(x3, y3);
      d.lineTo(sx4, sy3);
      d.lineTo(x4, y2);
      d.lineTo(sx5, sy2);
      d.lineTo(x5, y1);
      d.lineTo(sx6, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx7, sy1);
      d.lineTo(x6, y1);
      d.lineTo(sx8, sy2);
      d.lineTo(x7, y2);
      d.lineTo(sx9, sy3);
      d.lineTo(x8, y3);
      d.lineTo(sx10, sy4);
      d.lineTo(x9, y4);
      d.lineTo(sx11, sy5);
      d.lineTo(x10, y5);
      d.lineTo(sx12, sy6);
      d.lineTo(r, vc);
      d.lineTo(sx12, sy7);
      d.lineTo(x10, y6);
      d.lineTo(sx11, sy8);
      d.lineTo(x9, y7);
      d.lineTo(sx10, sy9);
      d.lineTo(x8, y8);
      d.lineTo(sx9, sy10);
      d.lineTo(x7, y9);
      d.lineTo(sx8, sy11);
      d.lineTo(x6, y10);
      d.lineTo(sx7, sy12);
      d.lineTo(hc, b);
      d.lineTo(sx6, sy12);
      d.lineTo(x5, y10);
      d.lineTo(sx5, sy11);
      d.lineTo(x4, y9);
      d.lineTo(sx4, sy10);
      d.lineTo(x3, y8);
      d.lineTo(sx3, sy9);
      d.lineTo(x2, y7);
      d.lineTo(sx2, sy8);
      d.lineTo(x1, y6);
      d.lineTo(sx1, sy7);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'star32': {
      const a = pin(0, adj1, 50000);
      const dx1 = (wd2 * 98079) / 100000;
      const dx2 = (wd2 * 92388) / 100000;
      const dx3 = (wd2 * 83147) / 100000;
      const dx4 = wd2 * Math.cos(constantToRad(2700000));
      const dx5 = (wd2 * 55557) / 100000;
      const dx6 = (wd2 * 38268) / 100000;
      const dx7 = (wd2 * 19509) / 100000;
      const dy1 = (hd2 * 98079) / 100000;
      const dy2 = (hd2 * 92388) / 100000;
      const dy3 = (hd2 * 83147) / 100000;
      const dy4 = hd2 * Math.sin(constantToRad(2700000));
      const dy5 = (hd2 * 55557) / 100000;
      const dy6 = (hd2 * 38268) / 100000;
      const dy7 = (hd2 * 19509) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc - dx4;
      const x5 = hc - dx5;
      const x6 = hc - dx6;
      const x7 = hc - dx7;
      const x8 = hc + dx7;
      const x9 = hc + dx6;
      const x10 = hc + dx5;
      const x11 = hc + dx4;
      const x12 = hc + dx3;
      const x13 = hc + dx2;
      const x14 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc - dy3;
      const y4 = vc - dy4;
      const y5 = vc - dy5;
      const y6 = vc - dy6;
      const y7 = vc - dy7;
      const y8 = vc + dy7;
      const y9 = vc + dy6;
      const y10 = vc + dy5;
      const y11 = vc + dy4;
      const y12 = vc + dy3;
      const y13 = vc + dy2;
      const y14 = vc + dy1;
      const iwd2 = (wd2 * a) / 50000;
      const ihd2 = (hd2 * a) / 50000;
      const sdx1 = (iwd2 * 99518) / 100000;
      const sdx2 = (iwd2 * 95694) / 100000;
      const sdx3 = (iwd2 * 88192) / 100000;
      const sdx4 = (iwd2 * 77301) / 100000;
      const sdx5 = (iwd2 * 63439) / 100000;
      const sdx6 = (iwd2 * 47140) / 100000;
      const sdx7 = (iwd2 * 29028) / 100000;
      const sdx8 = (iwd2 * 9802) / 100000;
      const sdy1 = (ihd2 * 99518) / 100000;
      const sdy2 = (ihd2 * 95694) / 100000;
      const sdy3 = (ihd2 * 88192) / 100000;
      const sdy4 = (ihd2 * 77301) / 100000;
      const sdy5 = (ihd2 * 63439) / 100000;
      const sdy6 = (ihd2 * 47140) / 100000;
      const sdy7 = (ihd2 * 29028) / 100000;
      const sdy8 = (ihd2 * 9802) / 100000;
      const sx1 = hc - sdx1;
      const sx2 = hc - sdx2;
      const sx3 = hc - sdx3;
      const sx4 = hc - sdx4;
      const sx5 = hc - sdx5;
      const sx6 = hc - sdx6;
      const sx7 = hc - sdx7;
      const sx8 = hc - sdx8;
      const sx9 = hc + sdx8;
      const sx10 = hc + sdx7;
      const sx11 = hc + sdx6;
      const sx12 = hc + sdx5;
      const sx13 = hc + sdx4;
      const sx14 = hc + sdx3;
      const sx15 = hc + sdx2;
      const sx16 = hc + sdx1;
      const sy1 = vc - sdy1;
      const sy2 = vc - sdy2;
      const sy3 = vc - sdy3;
      const sy4 = vc - sdy4;
      const sy5 = vc - sdy5;
      const sy6 = vc - sdy6;
      const sy7 = vc - sdy7;
      const sy8 = vc - sdy8;
      const sy9 = vc + sdy8;
      const sy10 = vc + sdy7;
      const sy11 = vc + sdy6;
      const sy12 = vc + sdy5;
      const sy13 = vc + sdy4;
      const sy14 = vc + sdy3;
      const sy15 = vc + sdy2;
      const sy16 = vc + sdy1;
      const idx = iwd2 * Math.cos(constantToRad(2700000));
      const idy = ihd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const it = vc - idy;
      const ir = hc + idx;
      const ib = vc + idy;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(sx1, sy8);
      d.lineTo(x1, y7);
      d.lineTo(sx2, sy7);
      d.lineTo(x2, y6);
      d.lineTo(sx3, sy6);
      d.lineTo(x3, y5);
      d.lineTo(sx4, sy5);
      d.lineTo(x4, y4);
      d.lineTo(sx5, sy4);
      d.lineTo(x5, y3);
      d.lineTo(sx6, sy3);
      d.lineTo(x6, y2);
      d.lineTo(sx7, sy2);
      d.lineTo(x7, y1);
      d.lineTo(sx8, sy1);
      d.lineTo(hc, t);
      d.lineTo(sx9, sy1);
      d.lineTo(x8, y1);
      d.lineTo(sx10, sy2);
      d.lineTo(x9, y2);
      d.lineTo(sx11, sy3);
      d.lineTo(x10, y3);
      d.lineTo(sx12, sy4);
      d.lineTo(x11, y4);
      d.lineTo(sx13, sy5);
      d.lineTo(x12, y5);
      d.lineTo(sx14, sy6);
      d.lineTo(x13, y6);
      d.lineTo(sx15, sy7);
      d.lineTo(x14, y7);
      d.lineTo(sx16, sy8);
      d.lineTo(r, vc);
      d.lineTo(sx16, sy9);
      d.lineTo(x14, y8);
      d.lineTo(sx15, sy10);
      d.lineTo(x13, y9);
      d.lineTo(sx14, sy11);
      d.lineTo(x12, y10);
      d.lineTo(sx13, sy12);
      d.lineTo(x11, y11);
      d.lineTo(sx12, sy13);
      d.lineTo(x10, y12);
      d.lineTo(sx11, sy14);
      d.lineTo(x9, y13);
      d.lineTo(sx10, sy15);
      d.lineTo(x8, y14);
      d.lineTo(sx9, sy16);
      d.lineTo(hc, b);
      d.lineTo(sx8, sy16);
      d.lineTo(x7, y14);
      d.lineTo(sx7, sy15);
      d.lineTo(x6, y13);
      d.lineTo(sx6, sy14);
      d.lineTo(x5, y12);
      d.lineTo(sx5, sy13);
      d.lineTo(x4, y11);
      d.lineTo(sx4, sy12);
      d.lineTo(x3, y10);
      d.lineTo(sx3, sy11);
      d.lineTo(x2, y9);
      d.lineTo(sx2, sy10);
      d.lineTo(x1, y8);
      d.lineTo(sx1, sy9);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'ribbon2': {
      const a1 = pin(0, adj1, 33333);
      const a2 = pin(25000, adj2, 75000);
      const x10 = r - wd8;
      const dx2 = (w * a2) / 200000;
      const x2 = hc - dx2;
      const x9 = hc + dx2;
      const x3 = x2 + wd32;
      const x8 = x9 - wd32;
      const x5 = x2 + wd8;
      const x6 = x9 - wd8;
      const x4 = x5 - wd32;
      const x7 = x6 + wd32;
      const dy1 = (h * a1) / 200000;
      const y1 = b - dy1;
      const dy2 = (h * a1) / 100000;
      const y2 = b - dy2;
      const y4 = t + dy2;
      const y3 = (y4 + b) / 2;
      const hR = (h * a1) / 400000;
      const y6 = b - hR;
      const y7 = y1 - hR;

      const dOuter = path();
      dOuter.moveTo(l, b);
      dOuter.lineTo(x4, b);
      ellipseArcTo(dOuter, wd32, hR, 'cd4', constantToRad(-10800000), x4, b);
      dOuter.lineTo(x3, y1);
      ellipseArcTo(dOuter, wd32, hR, 'cd4', 'cd2', x3, y1);
      dOuter.lineTo(x8, y2);
      ellipseArcTo(dOuter, wd32, hR, '3cd4', 'cd2', x8, y2);
      dOuter.lineTo(x7, y1);
      ellipseArcTo(dOuter, wd32, hR, '3cd4', constantToRad(-10800000), x7, y1);
      dOuter.lineTo(r, b);
      dOuter.lineTo(x10, y3);
      dOuter.lineTo(r, y4);
      dOuter.lineTo(x9, y4);
      dOuter.lineTo(x9, hR);
      ellipseArcTo(dOuter, wd32, hR, 0, constantToRad(-5400000), x9, hR);
      dOuter.lineTo(x3, t);
      ellipseArcTo(dOuter, wd32, hR, '3cd4', constantToRad(-5400000), x3, t);
      dOuter.lineTo(x2, y4);
      dOuter.lineTo(l, y4);
      dOuter.lineTo(wd8, y3);
      dOuter.closePath();

      const dInner = path();
      dInner.moveTo(x5, y6);
      ellipseArcTo(dInner, wd32, hR, 0, constantToRad(-5400000), x5, y6);
      dInner.lineTo(x3, y1);
      ellipseArcTo(dInner, wd32, hR, 'cd4', 'cd2', x3, y1);
      dInner.lineTo(x5, y2);
      dInner.closePath();
      dInner.moveTo(x6, y6);
      ellipseArcTo(dInner, wd32, hR, 'cd2', 'cd4', x6, y6);
      dInner.lineTo(x8, y1);
      ellipseArcTo(dInner, wd32, hR, 'cd4', constantToRad(-10800000), x8, y1);
      dInner.lineTo(x6, y2);
      dInner.closePath();

      const dOutline = path();
      dOutline.moveTo(l, b);
      dOutline.lineTo(wd8, y3);
      dOutline.lineTo(l, y4);
      dOutline.lineTo(x2, y4);
      dOutline.lineTo(x2, hR);
      ellipseArcTo(dOutline, wd32, hR, 'cd2', 'cd4', x2, hR);
      dOutline.lineTo(x8, t);
      ellipseArcTo(dOutline, wd32, hR, '3cd4', 'cd4', x8, t);
      dOutline.lineTo(x9, y4);
      dOutline.lineTo(x9, y4);
      dOutline.lineTo(r, y4);
      dOutline.lineTo(x10, y3);
      dOutline.lineTo(r, b);
      dOutline.lineTo(x7, b);
      ellipseArcTo(dOutline, wd32, hR, 'cd4', 'cd2', x7, b);
      dOutline.lineTo(x8, y1);
      ellipseArcTo(dOutline, wd32, hR, 'cd4', constantToRad(-10800000), x8, y1);
      dOutline.lineTo(x3, y2);
      ellipseArcTo(dOutline, wd32, hR, '3cd4', constantToRad(-10800000), x3, y2);
      dOutline.lineTo(x4, y1);
      ellipseArcTo(dOutline, wd32, hR, '3cd4', 'cd2', x4, y1);
      dOutline.closePath();
      dOutline.moveTo(x5, y2);
      dOutline.lineTo(x5, y6);
      dOutline.moveTo(x6, y6);
      dOutline.lineTo(x6, y2);
      dOutline.moveTo(x2, y7);
      dOutline.lineTo(x2, y4);
      dOutline.moveTo(x9, y4);
      dOutline.lineTo(x9, y7);

      return {
        paths: [
          { d: dOuter.toString(), stroke: 'false' },
          {
            d: dInner.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l: x2, t, r: x9, b: y2 },
      };
    }
    case 'ribbon': {
      const a1 = pin(0, adj1, 33333);
      const a2 = pin(25000, adj2, 75000);
      const x10 = r - wd8;
      const dx2 = (w * a2) / 200000;
      const x2 = hc - dx2;
      const x9 = hc + dx2;
      const x3 = x2 + wd32;
      const x8 = x9 - wd32;
      const x5 = x2 + wd8;
      const x6 = x9 - wd8;
      const x4 = x5 - wd32;
      const x7 = x6 + wd32;
      const y1 = (h * a1) / 200000;
      const y2 = (h * a1) / 100000;
      const y4 = b - y2;
      const y3 = y4 / 2;
      const hR = (h * a1) / 400000;
      const y5 = b - hR;
      const y6 = y2 - hR;

      const dOuter = path();
      dOuter.moveTo(l, t);
      dOuter.lineTo(x4, t);
      ellipseArcTo(dOuter, wd32, hR, '3cd4', 'cd2', x4, t);
      dOuter.lineTo(x3, y1);
      ellipseArcTo(dOuter, wd32, hR, '3cd4', constantToRad(-10800000), x3, y1);
      dOuter.lineTo(x8, y2);
      ellipseArcTo(dOuter, wd32, hR, 'cd4', constantToRad(-10800000), x8, y2);
      dOuter.lineTo(x7, y1);
      ellipseArcTo(dOuter, wd32, hR, 'cd4', 'cd2', x7, y1);
      dOuter.lineTo(r, t);
      dOuter.lineTo(x10, y3);
      dOuter.lineTo(r, y4);
      dOuter.lineTo(x9, y4);
      dOuter.lineTo(x9, y5);
      ellipseArcTo(dOuter, wd32, hR, 0, 'cd4', x9, y5);
      dOuter.lineTo(x3, b);
      ellipseArcTo(dOuter, wd32, hR, 'cd4', 'cd4', x3, b);
      dOuter.lineTo(x2, y4);
      dOuter.lineTo(l, y4);
      dOuter.lineTo(wd8, y3);
      dOuter.closePath();

      const dInner = path();
      dInner.moveTo(x5, hR);
      ellipseArcTo(dInner, wd32, hR, 0, 'cd4', x5, hR);
      dInner.lineTo(x3, y1);
      ellipseArcTo(dInner, wd32, hR, '3cd4', constantToRad(-10800000), x3, y1);
      dInner.lineTo(x5, y2);
      dInner.closePath();
      dInner.moveTo(x6, hR);
      ellipseArcTo(dInner, wd32, hR, 'cd2', constantToRad(-5400000), x6, hR);
      dInner.lineTo(x8, y1);
      ellipseArcTo(dInner, wd32, hR, '3cd4', 'cd2', x8, y1);
      dInner.lineTo(x6, y2);
      dInner.closePath();

      const dOutline = path();
      dOutline.moveTo(l, t);
      dOutline.lineTo(x4, t);
      ellipseArcTo(dOutline, wd32, hR, '3cd4', 'cd2', x4, t);
      dOutline.lineTo(x3, y1);
      ellipseArcTo(dOutline, wd32, hR, '3cd4', constantToRad(-10800000), x3, y1);
      dOutline.lineTo(x8, y2);
      ellipseArcTo(dOutline, wd32, hR, 'cd4', constantToRad(-10800000), x8, y2);
      dOutline.lineTo(x7, y1);
      ellipseArcTo(dOutline, wd32, hR, 'cd4', 'cd2', x7, y1);
      dOutline.lineTo(r, t);
      dOutline.lineTo(x10, y3);
      dOutline.lineTo(r, y4);
      dOutline.lineTo(x9, y4);
      dOutline.lineTo(x9, y5);
      ellipseArcTo(dOutline, wd32, hR, 0, 'cd4', x9, y5);
      dOutline.lineTo(x3, b);
      ellipseArcTo(dOutline, wd32, hR, 'cd4', 'cd4', x3, b);
      dOutline.lineTo(x2, y4);
      dOutline.lineTo(l, y4);
      dOutline.lineTo(wd8, y3);
      dOutline.closePath();
      dOutline.moveTo(x5, hR);
      dOutline.lineTo(x5, y2);
      dOutline.moveTo(x6, y2);
      dOutline.lineTo(x6, hR);
      dOutline.moveTo(x2, y4);
      dOutline.lineTo(x2, y6);
      dOutline.moveTo(x9, y6);
      dOutline.lineTo(x9, y4);
      dOutline.closePath();

      return {
        paths: [
          { d: dOuter.toString(), stroke: 'false' },
          {
            d: dInner.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l: x2, t: y2, r: x9, b },
      };
    }
    case 'ellipseRibbon2': {
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(25000, adj2, 75000);
      const q10 = 100000 - a1;
      const q11 = q10 / 2;
      const q12 = a1 - q11;
      const minAdj3 = Math.max(0, q12);
      const a3 = pin(minAdj3, adj3, a1);
      const dx2 = (w * a2) / 200000;
      const x2 = hc - dx2;
      const x3 = x2 + wd8;
      const x4 = r - x3;
      const x5 = r - x2;
      const x6 = r - wd8;
      const dy1 = (h * a3) / 100000;
      const f1 = (4 * dy1) / w;
      let q1 = (x3 * x3) / w;
      const q2 = x3 - q1;
      const u1 = f1 * q2;
      const y1 = b - u1;
      const cx1 = x3 / 2;
      const cu1 = f1 * cx1;
      const cy1 = b - cu1;
      const cx2 = r - cx1;
      q1 = (h * a1) / 100000;
      const dy3 = q1 - dy1;
      const q3 = (x2 * x2) / w;
      const q4 = x2 - q3;
      const q5 = f1 * q4;
      const u3 = q5 + dy3;
      const y3 = b - u3;
      const q6 = dy1 + dy3 - u3;
      const q7 = q6 + dy1;
      const cu3 = q7 + dy3;
      const cy3 = b - cu3;
      const rh = b - q1;
      const q8 = (dy1 * 14) / 16;
      const u2 = (q8 + rh) / 2;
      const y2 = b - u2;
      const u5 = q5 + rh;
      const y5 = b - u5;
      const u6 = u3 + rh;
      const y6 = b - u6;
      const cx4 = x2 / 2;
      const q9 = f1 * cx4;
      const cu4 = q9 + rh;
      const cy4 = b - cu4;
      const cx5 = r - cx4;
      const cu6 = cu3 + rh;
      const cy6 = b - cu6;
      const u7 = u1 + dy3;
      const y7 = b - u7;
      const cu7 = q1 + q1 - u7;
      const cy7 = b - cu7;

      const dOuter = path();
      dOuter.moveTo(l, b);
      dOuter.quadraticCurveTo(cx1, cy1, x3, y1);
      dOuter.lineTo(x2, y3);
      dOuter.quadraticCurveTo(hc, cy3, x5, y3);
      dOuter.lineTo(x4, y1);
      dOuter.quadraticCurveTo(cx2, cy1, r, b);
      dOuter.lineTo(x6, y2);
      dOuter.lineTo(r, q1);
      dOuter.quadraticCurveTo(cx5, cy4, x5, y5);
      dOuter.lineTo(x5, y6);
      dOuter.quadraticCurveTo(hc, cy6, x2, y6);
      dOuter.lineTo(x2, y5);
      dOuter.quadraticCurveTo(cx4, cy4, l, q1);
      dOuter.lineTo(wd8, y2);
      dOuter.closePath();

      const dInner = path();
      dInner.moveTo(x3, y7);
      dInner.lineTo(x3, y1);
      dInner.lineTo(x2, y3);
      dInner.quadraticCurveTo(hc, cy3, x5, y3);
      dInner.lineTo(x4, y1);
      dInner.lineTo(x4, y7);
      dInner.quadraticCurveTo(hc, cy7, x3, y7);
      dInner.closePath();

      const dOutline = path();
      dOutline.moveTo(l, b);
      dOutline.lineTo(wd8, y2);
      dOutline.lineTo(l, q1);
      dOutline.quadraticCurveTo(cx4, cy4, x2, y5);
      dOutline.lineTo(x2, y6);
      dOutline.quadraticCurveTo(hc, cy6, x5, y6);
      dOutline.lineTo(x5, y5);
      dOutline.quadraticCurveTo(cx5, cy4, r, q1);
      dOutline.lineTo(x6, y2);
      dOutline.lineTo(r, b);
      dOutline.quadraticCurveTo(cx2, cy1, x4, y1);
      dOutline.lineTo(x5, y3);
      dOutline.quadraticCurveTo(hc, cy3, x2, y3);
      dOutline.lineTo(x3, y1);
      dOutline.quadraticCurveTo(cx1, cy1, l, b);
      dOutline.closePath();
      dOutline.moveTo(x2, y3);
      dOutline.lineTo(x2, y5);
      dOutline.moveTo(x5, y5);
      dOutline.lineTo(x5, y3);
      dOutline.moveTo(x3, y7);
      dOutline.lineTo(x3, y1);
      dOutline.moveTo(x4, y1);
      dOutline.lineTo(x4, y7);

      return {
        paths: [
          { d: dOuter.toString(), stroke: 'false' },
          {
            d: dInner.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l: x2, t: y6, r: x5, b: rh },
      };
    }
    case 'ellipseRibbon': {
      const a1 = pin(0, adj1, 100000);
      const a2 = pin(25000, adj2, 75000);
      const q10 = 100000 - a1;
      const q11 = q10 / 2;
      const q12 = a1 - q11;
      const minAdj3 = Math.max(0, q12);
      const a3 = pin(minAdj3, adj3, a1);
      const dx2 = (w * a2) / 200000;
      const x2 = hc - dx2;
      const x3 = x2 + wd8;
      const x4 = r - x3;
      const x5 = r - x2;
      const x6 = r - wd8;
      const dy1 = (h * a3) / 100000;
      const f1 = (4 * dy1) / w;
      let q1 = (x3 * x3) / w;
      const q2 = x3 - q1;
      const y1 = f1 * q2;
      const cx1 = x3 / 2;
      const cy1 = f1 * cx1;
      const cx2 = r - cx1;
      q1 = (h * a1) / 100000;
      const dy3 = q1 - dy1;
      const q3 = (x2 * x2) / w;
      const q4 = x2 - q3;
      const q5 = f1 * q4;
      const y3 = q5 + dy3;
      const q6 = dy1 + dy3 - y3;
      const q7 = q6 + dy1;
      const cy3 = q7 + dy3;
      const rh = b - q1;
      const q8 = (dy1 * 14) / 16;
      const y2 = (q8 + rh) / 2;
      const y5 = q5 + rh;
      const y6 = y3 + rh;
      const cx4 = x2 / 2;
      const q9 = f1 * cx4;
      const cy4 = q9 + rh;
      const cx5 = r - cx4;
      const cy6 = cy3 + rh;
      const y7 = y1 + dy3;
      const cy7 = q1 + q1 - y7;

      const dOuter = path();
      dOuter.moveTo(l, t);
      dOuter.quadraticCurveTo(cx1, cy1, x3, y1);
      dOuter.lineTo(x2, y3);
      dOuter.quadraticCurveTo(hc, cy3, x5, y3);
      dOuter.lineTo(x4, y1);
      dOuter.quadraticCurveTo(cx2, cy1, r, t);
      dOuter.lineTo(x6, y2);
      dOuter.lineTo(r, rh);
      dOuter.quadraticCurveTo(cx5, cy4, x5, y5);
      dOuter.lineTo(x5, y6);
      dOuter.quadraticCurveTo(hc, cy6, x2, y6);
      dOuter.lineTo(x2, y5);
      dOuter.quadraticCurveTo(cx4, cy4, l, rh);
      dOuter.lineTo(wd8, y2);
      dOuter.closePath();

      const dInner = path();
      dInner.moveTo(x3, y7);
      dInner.lineTo(x3, y1);
      dInner.lineTo(x2, y3);
      dInner.quadraticCurveTo(hc, cy3, x5, y3);
      dInner.lineTo(x4, y1);
      dInner.lineTo(x4, y7);
      dInner.quadraticCurveTo(hc, cy7, x3, y7);
      dInner.closePath();

      const dOutline = path();
      dOutline.moveTo(l, t);
      dOutline.quadraticCurveTo(cx1, cy1, x3, y1);
      dOutline.lineTo(x2, y3);
      dOutline.quadraticCurveTo(hc, cy3, x5, y3);
      dOutline.lineTo(x4, y1);
      dOutline.quadraticCurveTo(cx2, cy1, r, t);
      dOutline.lineTo(x6, y2);
      dOutline.lineTo(r, rh);
      dOutline.quadraticCurveTo(cx5, cy4, x5, y5);
      dOutline.lineTo(x5, y6);
      dOutline.quadraticCurveTo(hc, cy6, x2, y6);
      dOutline.lineTo(x2, y5);
      dOutline.quadraticCurveTo(cx4, cy4, l, rh);
      dOutline.lineTo(wd8, y2);
      dOutline.closePath();
      dOutline.moveTo(x2, y5);
      dOutline.lineTo(x2, y3);
      dOutline.moveTo(x5, y3);
      dOutline.lineTo(x5, y5);
      dOutline.moveTo(x3, y1);
      dOutline.lineTo(x3, y7);
      dOutline.moveTo(x4, y7);
      dOutline.lineTo(x4, y1);

      return {
        paths: [
          { d: dOuter.toString(), stroke: 'false' },
          {
            d: dInner.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l: x2, t: q1, r: x5, b: y6 },
      };
    }
    case 'verticalScroll': {
      const a = pin(0, adj1, 25000);
      const ch = (ss * a) / 100000;
      const ch2 = ch / 2;
      const ch4 = ch / 4;
      const x3 = ch + ch2;
      const x4 = ch + ch;
      const x6 = r - ch;
      const x7 = r - ch2;
      const y3 = b - ch;
      const y4 = b - ch2;

      const dOuter = path();
      dOuter.moveTo(ch2, b);
      ellipseArcTo(dOuter, ch2, ch2, 'cd4', constantToRad(-5400000), ch2, b);
      dOuter.lineTo(ch2, y4);
      ellipseArcTo(dOuter, ch4, ch4, 'cd4', constantToRad(-10800000), ch2, y4);
      dOuter.lineTo(ch, y3);
      dOuter.lineTo(ch, ch2);
      ellipseArcTo(dOuter, ch2, ch2, 'cd2', 'cd4', ch, ch2);
      dOuter.lineTo(x7, t);
      ellipseArcTo(dOuter, ch2, ch2, '3cd4', 'cd2', x7, t);
      dOuter.lineTo(x6, ch);
      dOuter.lineTo(x6, y4);
      ellipseArcTo(dOuter, ch2, ch2, 0, 'cd4', x6, y4);
      dOuter.closePath();
      dOuter.moveTo(x4, ch2);
      const topPipeRightArc = ellipseArcTo(dOuter, ch2, ch2, 0, 'cd4', x4, ch2);
      const topPipeLeftArc = ellipseArcTo(
        dOuter,
        ch4,
        ch4,
        'cd4',
        'cd2',
        topPipeRightArc.eX,
        topPipeRightArc.eY,
      );
      dOuter.closePath();

      const dInner = path();
      dInner.moveTo(x4, ch2);
      topPipeRightArc.redraw(dInner);
      topPipeLeftArc.redraw(dInner);
      dInner.closePath();
      dInner.moveTo(ch, y4);
      const bottomPipeArc = ellipseArcTo(dInner, ch2, ch2, 0, '3cd4', ch, y4);
      ellipseArcTo(dInner, ch4, ch4, '3cd4', 'cd2', bottomPipeArc.eX, bottomPipeArc.eY);
      dInner.closePath();

      const dOutline = path();
      dOutline.moveTo(ch, y3);
      dOutline.lineTo(ch, ch2);
      ellipseArcTo(dOutline, ch2, ch2, 'cd2', 'cd4', ch, ch2);
      dOutline.lineTo(x7, t);
      ellipseArcTo(dOutline, ch2, ch2, '3cd4', 'cd2', x7, t);
      dOutline.lineTo(x6, ch);
      dOutline.lineTo(x6, y4);
      ellipseArcTo(dOutline, ch2, ch2, 0, 'cd4', x6, y4);
      dOutline.lineTo(ch2, b);
      ellipseArcTo(dOutline, ch2, ch2, 'cd4', 'cd2', ch2, b);
      dOutline.closePath();
      dOutline.moveTo(x3, t);
      const topPipeRightOutlineArc = ellipseArcTo(dOutline, ch2, ch2, '3cd4', 'cd2', x3, t);
      ellipseArcTo(
        dOutline,
        ch4,
        ch4,
        'cd4',
        'cd2',
        topPipeRightOutlineArc.eX,
        topPipeRightOutlineArc.eY,
      );
      dOutline.lineTo(x4, ch2);
      dOutline.moveTo(x6, ch);
      dOutline.lineTo(x3, ch);
      dOutline.moveTo(ch2, y3);
      ellipseArcTo(dOutline, ch4, ch4, '3cd4', 'cd2', ch2, y3);
      dOutline.lineTo(ch, y4);
      dOutline.moveTo(ch2, b);
      ellipseArcTo(dOutline, ch2, ch2, 'cd4', constantToRad(-5400000), ch2, b);
      dOutline.lineTo(ch, y3);

      return {
        paths: [
          { d: dOuter.toString(), stroke: 'false' },
          {
            d: dInner.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l: ch, t: ch, r: x6, b: y4 },
      };
    }
    case 'horizontalScroll': {
      const a = pin(0, adj1, 25000);
      const ch = (ss * a) / 100000;
      const ch2 = ch / 2;
      const ch4 = ch / 4;
      const y3 = ch + ch2;
      const y4 = ch + ch;
      const y6 = b - ch;
      const y7 = b - ch2;
      const y5 = y6 - ch2;
      const x3 = r - ch;
      const x4 = r - ch2;

      const dOuter = path();
      dOuter.moveTo(r, ch2);
      ellipseArcTo(dOuter, ch2, ch2, 0, 'cd4', r, ch2);
      dOuter.lineTo(x4, ch2);
      ellipseArcTo(dOuter, ch4, ch4, 0, 'cd2', x4, ch2);
      dOuter.lineTo(x3, ch);
      dOuter.lineTo(ch2, ch);
      ellipseArcTo(dOuter, ch2, ch2, '3cd4', constantToRad(-5400000), ch2, ch);
      dOuter.lineTo(l, y7);
      ellipseArcTo(dOuter, ch2, ch2, 'cd2', constantToRad(-10800000), l, y7);
      dOuter.lineTo(ch, y6);
      dOuter.lineTo(x4, y6);
      ellipseArcTo(dOuter, ch2, ch2, 'cd4', constantToRad(-5400000), x4, y6);
      dOuter.closePath();
      dOuter.moveTo(ch2, y4);
      const leftPipeLeftArc = ellipseArcTo(
        dOuter,
        ch2,
        ch2,
        'cd4',
        constantToRad(-5400000),
        ch2,
        y4,
      );
      const leftPipeRightArc = ellipseArcTo(
        dOuter,
        ch4,
        ch4,
        0,
        constantToRad(-10800000),
        leftPipeLeftArc.eX,
        leftPipeLeftArc.eY,
      );
      dOuter.closePath();

      const dInner = path();
      dInner.moveTo(ch2, y4);
      leftPipeLeftArc.redraw(dInner);
      leftPipeRightArc.redraw(dInner);
      dInner.closePath();
      dInner.moveTo(x4, ch);
      const rightPipeArc = ellipseArcTo(dInner, ch2, ch2, 'cd4', constantToRad(-16200000), x4, ch);
      ellipseArcTo(
        dInner,
        ch4,
        ch4,
        'cd2',
        constantToRad(-10800000),
        rightPipeArc.eX,
        rightPipeArc.eY,
      );
      dInner.closePath();

      const dOutline = path();
      dOutline.moveTo(l, y3);
      ellipseArcTo(dOutline, ch2, ch2, 'cd2', 'cd4', l, y3);
      dOutline.lineTo(x3, ch);
      dOutline.lineTo(x3, ch2);
      ellipseArcTo(dOutline, ch2, ch2, 'cd2', 'cd2', x3, ch2);
      dOutline.lineTo(r, y5);
      ellipseArcTo(dOutline, ch2, ch2, 0, 'cd4', r, y5);
      dOutline.lineTo(ch, y6);
      dOutline.lineTo(ch, y7);
      ellipseArcTo(dOutline, ch2, ch2, 0, 'cd2', ch, y7);
      dOutline.closePath();
      dOutline.moveTo(x3, ch);
      dOutline.lineTo(x4, ch);
      ellipseArcTo(dOutline, ch2, ch2, 'cd4', constantToRad(-5400000), x4, ch);
      dOutline.moveTo(x4, ch);
      dOutline.lineTo(x4, ch2);
      ellipseArcTo(dOutline, ch4, ch4, 0, 'cd2', x4, ch2);
      dOutline.moveTo(ch2, y4);
      dOutline.lineTo(ch2, y3);
      const topPipeRightOutlineArc = ellipseArcTo(dOutline, ch4, ch4, 'cd2', 'cd2', ch2, y3);
      ellipseArcTo(
        dOutline,
        ch2,
        ch2,
        0,
        'cd2',
        topPipeRightOutlineArc.eX,
        topPipeRightOutlineArc.eY,
      );
      dOutline.moveTo(ch, y3);
      dOutline.lineTo(ch, y6);

      return {
        paths: [
          { d: dOuter.toString(), stroke: 'false' },
          {
            d: dInner.toString(),
            stroke: 'false',
            fillModifier: 'darkenLess',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l: ch, t: ch, r: x4, b: y6 },
      };
    }
    case 'wave': {
      const a1 = pin(0, adj1, 20000);
      const a2 = pin(-10000, adj2, 10000);
      const y1 = (h * a1) / 100000;
      const dy2 = (y1 * 10) / 3;
      const y2 = y1 - dy2;
      const y3 = y1 + dy2 - 0;
      const y4 = b - y1;
      const y5 = y4 - dy2;
      const y6 = y4 + dy2 - 0;
      const of2 = (w * a2) / 50000;
      const dx2 = of2 > 0 ? 0 : of2;
      const x2 = l - dx2;
      const dx5 = of2 > 0 ? of2 : 0;
      const x5 = r - dx5;
      const dx3 = (dx2 + x5) / 3;
      const x3 = x2 + dx3 - 0;
      const x4 = (x3 + x5) / 2;
      const x6 = l + dx5 - 0;
      const x10 = r + dx2 - 0;
      const x7 = x6 + dx3 - 0;
      const x8 = (x7 + x10) / 2;
      const il = Math.max(x2, x6);
      const ir = Math.min(x5, x10);
      const it = (h * a1) / 50000;
      const ib = b - it;

      const d = path();
      d.moveTo(x2, y1);
      d.bezierCurveTo(x3, y2, x4, y3, x5, y1);
      d.lineTo(x10, y4);
      d.bezierCurveTo(x8, y6, x7, y5, x6, y4);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'doubleWave': {
      const a1 = pin(0, adj1, 12500);
      const a2 = pin(-10000, adj2, 10000);
      const y1 = (h * a1) / 100000;
      const dy2 = (y1 * 10) / 3;
      const y2 = y1 + 0 - dy2;
      const y3 = y1 + dy2 - 0;
      const y4 = b + 0 - y1;
      const y5 = y4 + 0 - dy2;
      const y6 = y4 + dy2 - 0;
      const of2 = (w * a2) / 50000;
      const dx2 = of2 > 0 ? 0 : of2;
      const x2 = l + 0 - dx2;
      const dx8 = of2 > 0 ? of2 : 0;
      const x8 = r + 0 - dx8;
      const dx3 = (dx2 + x8) / 6;
      const x3 = x2 + dx3 - 0;
      const dx4 = (dx2 + x8) / 3;
      const x4 = x2 + dx4 - 0;
      const x5 = (x2 + x8) / 2;
      const x6 = x5 + dx3 - 0;
      const x7 = (x6 + x8) / 2;
      const x9 = l + dx8 - 0;
      const x15 = r + dx2 - 0;
      const x10 = x9 + dx3 - 0;
      const x11 = x9 + dx4 - 0;
      const x12 = (x9 + x15) / 2;
      const x13 = x12 + dx3 - 0;
      const x14 = (x13 + x15) / 2;
      const il = Math.max(x2, x9);
      const ir = Math.min(x8, x15);
      const it = (h * a1) / 50000;
      const ib = b - it;

      const d = path();
      d.moveTo(x2, y1);
      d.bezierCurveTo(x3, y2, x4, y3, x5, y1);
      d.bezierCurveTo(x6, y2, x7, y3, x8, y1);
      d.lineTo(x15, y4);
      d.bezierCurveTo(x14, y6, x13, y5, x12, y4);
      d.bezierCurveTo(x11, y6, x10, y5, x9, y4);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }

    default:
      return { paths: [{ d: '' }] };
  }
};

export default generateStarAndBannerPath;
