import { useSelector } from '_common/hooks';
import { GHOST_USERS } from '_common/services/api/publicProfilesApi';
import usePDFData from './usePDFData';

const usePDFCollaborators = () => {
  const pdf = usePDFData();

  const onboardingIsActive = useSelector((state) => state.onboarding.active.dopdf);
  const onboardingHasStarted = useSelector((state) => state.onboarding.started.dopdf);
  if (pdf) {
    const collaborators: string[] =
      onboardingIsActive || onboardingHasStarted
        ? [GHOST_USERS.davidBean['id']]
        : [...pdf.owners, ...(pdf.shared_with ?? [])];

    return collaborators;
  }

  return [];
};

export default usePDFCollaborators;
