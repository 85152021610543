import Details from '../Details/Details';
import ApprovalNote from '../ApprovalNote/ApprovalNote';
import Description from '../Description/Description';
import DueDate from '../DueDate/DueDate';
import Tags from '../Tags/Tags';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

type ObjectPanelProps = {
  object: Objekt;
};

const ObjectPanel = ({ object }: ObjectPanelProps) => {
  const { data: statusList } = useGetElementStatusListQuery();

  const canEdit =
    statusList?.entities[object?.status]?.allow_edit === true &&
    //@ts-expect-error Objekt should be replaced by BaseObjectSchema
    object.user_permissions.some((permission) => ['admin', 'owner', 'edit'].includes(permission));

  return (
    <>
      <Details />
      <ApprovalNote />
      <Description canEdit={canEdit} />
      <DueDate canEdit={canEdit} />
      <Tags />
    </>
  );
};

export default ObjectPanel;
