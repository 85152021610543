import { ProgressBar } from 'dodoc-design-system';
import styles from './Loading.module.scss';

type props = {
  label: string;
  testId: string;
};

const Loading = ({ label, testId }: props) => {
  return (
    <div className={styles.loading_container}>
      <ProgressBar
        size="medium"
        color="primaryLight"
        label={label}
        testId={`${testId}-progressBar`}
      />
    </div>
  );
};

export default Loading;
