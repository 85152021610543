import { openModal } from 'App/ModalContext/utils';

export const cloneObject = <T extends object | undefined>(
  obj: T,
): T extends undefined ? undefined : T => {
  if (obj === undefined) {
    return undefined as T extends undefined ? undefined : T;
  }

  return JSON.parse(JSON.stringify(obj));
};

export const indexedModEffects = (effects?: Presentation.Data.Effect[]) =>
  effects?.reduce<Presentation.Data.IndexedEffect>((parsedEffects, effect) => {
    // @ts-expect-error TS has trouble assigning the effect into a specific sub-type
    parsedEffects[effect.type] = effect;

    return parsedEffects;
  }, {}) ?? {};

export const openDisconnectedModal = () => {
  openModal({
    modal: 'ConfirmationModal',
    data: {
      title: 'DISCONNECTED',
      message: 'DISCONNECTED_ERROR_MESSAGE',
      confirmButtonType: 'primary',
      confirmButtonTextId: 'RECONNECT',
      onConfirm: () => {
        window.location.reload();
      },
      modalWidth: '60rem',
      persistent: true,
    },
  });
};
export const openConnectionErrorModal = () => {
  openModal({
    modal: 'ConfirmationModal',
    data: {
      title: 'CONNECTION_ERROR',
      message: 'CONNECTION_ERROR_MESSAGE',
      confirmButtonType: 'primary',
      confirmButtonTextId: 'RECONNECT',
      cancelButtonShow: false,
      modalWidth: '60rem',
      persistent: true,
      onConfirm: () => {
        window.location.reload();
      },
    },
  });
};
