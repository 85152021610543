import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

export type NodeDataBuilderOptions = {
  data?: Editor.Data.Node.Data;
  rows?: number;
  cells?: number;
  tableWidth?: number;
  rowHeight?: number;
  columnWidth?: number;
  headerRow?: boolean;
};

export class NodeDataBuilder<T extends Editor.Data.Node.DataTypes> {
  private data: Editor.Data.Node.DataTypeMapper[T];

  constructor(type?: T) {
    //@ts-expect-error
    this.data = {
      type: type || 'invalid',
    };

    if (this.data.type !== 'text') {
      if (!this.data.id) {
        this.data.id = EditorDOMUtils.generateRandomNodeId();
      }

      if (!this.data.properties) {
        this.data.properties = {};
      }

      if (!this.data.childNodes) {
        this.data.childNodes = [];
      }
    }
  }

  setData(data: Editor.Data.Node.DataTypeMapper[T]) {
    this.data = {
      ...data,
    };

    if (this.data.type !== 'text') {
      if (!this.data.id) {
        this.data.id = EditorDOMUtils.generateRandomNodeId();
      }

      if (!this.data.properties) {
        this.data.properties = {};
      }

      if (!this.data.childNodes) {
        this.data.childNodes = [];
      }
    }

    return this;
  }

  set id(id: string) {
    this.data.id = id;
  }

  getId() {
    return this.data.id;
  }

  set parentId(parentId: string) {
    this.data.parent_id = parentId;
  }

  setParentId(parentId: string) {
    this.data.parent_id = parentId;
    return this;
  }

  set elementType(type: T) {
    this.data.type = type;
  }

  setElementType(type: T) {
    this.data.type = type;
    return this;
  }

  set properties(properties: Editor.Data.Node.DataTypeMapper[T]['properties']) {
    if (this.data.type !== 'text') {
      this.data.properties = JSON.parse(JSON.stringify(properties));
    }
  }

  setProperties(properties: Editor.Data.Node.DataTypeMapper[T]['properties']) {
    if (this.data.type !== 'text') {
      this.data.properties = JSON.parse(JSON.stringify(properties));
    }
    return this;
  }

  set content(content: string) {
    if (this.data.type === 'text') {
      this.data.content = content;
    }
  }

  setContent(content: string) {
    if (this.data.type === 'text') {
      this.data.content = content;
    }
    return this;
  }

  getProperties(): Editor.Data.Node.DataTypeMapper[T]['properties'] {
    return this.data.properties;
  }

  set childNodes(childNodes: Editor.Data.Node.DataTypeMapper[T]['childNodes']) {
    if (this.data.type !== 'text') {
      this.data.childNodes = JSON.parse(JSON.stringify(childNodes));
    }
  }

  setChildNodes(childNodes: Editor.Data.Node.DataTypeMapper[T]['childNodes']) {
    if (this.data.type !== 'text') {
      this.data.childNodes = JSON.parse(JSON.stringify(childNodes));
    }
    return this;
  }

  getChild(index: number) {
    return this.data.childNodes?.[index];
  }

  addKeyValue<K extends keyof Editor.Data.Node.DataTypeMapper[T]>(
    key: K,
    value: Editor.Data.Node.DataTypeMapper[T][K],
  ) {
    if (value === 'true') {
      //@ts-expect-error
      this.data[key] = true;
    } else if (value === 'false') {
      //@ts-expect-error
      this.data[key] = false;
    } else {
      this.data[key] = value;
    }

    return this;
  }

  addProperty<K extends keyof Editor.Data.Node.DataTypeMapper[T]['properties']>(
    key: K,
    value: Editor.Data.Node.DataTypeMapper[T]['properties'][K],
  ) {
    if (this.data.type !== 'text') {
      if (!this.data.properties) {
        this.data.properties = {};
      }

      if (value === 'true') {
        //@ts-expect-error
        this.data.properties[key] = true;
      } else if (value === 'false') {
        //@ts-expect-error
        this.data.properties[key] = false;
      } else {
        //@ts-expect-error
        this.data.properties[key] = value;
      }
    }

    return this;
  }

  addClipboardProperty<K extends keyof Editor.Data.Node.DataTypeMapper[T]['properties']>(
    key: K,
    value: Editor.Data.Node.DataTypeMapper[T]['properties'][K],
  ) {
    if (this.data.type !== 'text') {
      if (!this.data.clipboardProps) {
        this.data.clipboardProps = {};
      }

      if (value === 'true') {
        //@ts-expect-error
        this.data.clipboardProps[key] = true;
      } else if (value === 'false') {
        //@ts-expect-error
        this.data.clipboardProps[key] = false;
      } else {
        //@ts-expect-error
        this.data.clipboardProps[key] = value;
      }
    }

    return this;
  }

  addProperties(properties: Editor.Data.Node.DataTypeMapper[T]['properties']) {
    if (this.data.type !== 'text') {
      if (!this.data.properties) {
        this.data.properties = {};
      }

      this.data.properties = {
        ...this.data.properties,
        ...properties,
      };
    }
  }

  addChildData(...childNodes: Editor.Data.Node.Data[]) {
    if (this.data.type !== 'text') {
      if (!this.data.childNodes) {
        this.data.childNodes = [];
      }

      if (childNodes?.length) {
        this.data.childNodes.push(...childNodes);
      }
    }

    return this;
  }

  static normalizeData(data: Editor.Data.Node.Data, generateId: boolean = false) {
    if (!data.id && generateId) {
      data.id = EditorDOMUtils.generateRandomNodeId();
    }

    if (data.childNodes?.length) {
      const queue = [
        {
          id: data.id,
          childNodes: data.childNodes,
        },
      ];

      let item;
      while ((item = queue.shift())) {
        for (let i = 0; i < item.childNodes.length; i++) {
          const child = item.childNodes[i];
          if (child.type !== 'text') {
            child.parent_id = item.id;
            child.id = EditorDOMUtils.generateRandomNodeId();

            if (child.childNodes?.length) {
              queue.push({
                id: child.id,
                childNodes: child.childNodes,
              });
            }
          }
        }
      }
    }
  }

  build(): Editor.Data.Node.DataTypeMapper[T] | undefined {
    if (this.data.type && this.data.type !== 'text' && this.data.id) {
      NodeDataBuilder.normalizeData(this.data);
      return this.data;
    } else if (this.data.type === 'text' && this.data.content) {
      return this.data;
    }

    return undefined;
  }

  private static buildTableRow(options: NodeDataBuilderOptions) {
    const { cells = 3, rowHeight = 48, columnWidth = 36 } = options;

    const rowBuilder = new NodeDataBuilder('tblr');

    rowBuilder.addProperty('rh', EditorDOMUtils.convertUnitTo(rowHeight, 'px', 'pt', 3));
    rowBuilder.addProperty('rcs', true);

    for (let i = 0; i < cells; i++) {
      const cellBuilder = new NodeDataBuilder('tblc');

      cellBuilder.addKeyValue('parent_id', rowBuilder.getId());

      cellBuilder.addProperty('w', {
        t: 'abs',
        v: columnWidth,
      });

      const childData = NodeDataBuilder.buildParagraph({
        parent_id: cellBuilder.getId(),
      });
      if (childData) {
        cellBuilder.addChildData(childData);
      }

      const cell = cellBuilder.build();
      if (cell) {
        rowBuilder.addChildData(cell);
      }
    }

    return rowBuilder;
  }

  static buildTable(options: NodeDataBuilderOptions) {
    const { rows = 3, cells = 3, tableWidth = 516, rowHeight = 48, headerRow = false } = options;

    const tableBuilder = new NodeDataBuilder('tbl');

    if (options.data?.parent_id) {
      tableBuilder.addKeyValue('parent_id', options.data?.parent_id);
    }

    const columnWidth = tableWidth / cells;

    tableBuilder.addProperty('ar', true);

    if (options.data?.properties?.w == null) {
      tableBuilder.addProperty('w', {
        t: 'auto',
        v: 0,
      });
    }

    // cell borders
    if (options.data?.properties?.cb == null) {
      tableBuilder.addProperty('cb', {
        t: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        b: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        l: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
        r: {
          w: 0.75,
          s: 's',
          c: '000000',
        },
      });
    }

    if (!options.data?.childNodes) {
      const bodyBuilder = new NodeDataBuilder('tblb');

      bodyBuilder.addKeyValue('parent_id', tableBuilder.getId());

      for (let i = 0; i < rows; i++) {
        const rowDataBuilder = NodeDataBuilder.buildTableRow({
          data: {
            type: 'tblr',
          },
          cells,
          rowHeight,
          columnWidth,
        });

        rowDataBuilder.addKeyValue('parent_id', bodyBuilder.getId());

        if (headerRow && i === 0) {
          rowDataBuilder.addProperty('hr', true);
        }

        const row = rowDataBuilder.build();
        if (row) {
          bodyBuilder.addChildData(row);
        }
      }

      const body = bodyBuilder.build();
      if (body) {
        tableBuilder.addChildData(body);
      }
    } else {
      tableBuilder.childNodes = options.data.childNodes;
    }

    return tableBuilder.build();
  }

  static buildParagraph(data?: Partial<Editor.Data.Node.Data>) {
    const paragraphBuilder = new NodeDataBuilder('p');

    if (data?.parent_id) {
      paragraphBuilder.addKeyValue('parent_id', data.parent_id);
    }

    if (data?.properties) {
      paragraphBuilder.properties = data.properties as Editor.Data.Node.ParagraphProperties;
    }

    if (data?.properties?.s == null) {
      paragraphBuilder.addProperty('s', 'p');
    }

    if (data?.childNodes?.length) {
      paragraphBuilder.childNodes = data.childNodes;
    }

    return paragraphBuilder.build();
  }

  static buildCaptionElements(
    label: string,
    chapterNumbering: { chapterType?: string; separator?: string } = {},
    text?: string,
  ): Editor.Data.Node.Data[] {
    const captionElements: Editor.Data.Node.Data[] = [];

    const labelData = new NodeDataBuilder('text').setContent(`${label} `).build();
    if (labelData) {
      captionElements.push(labelData);
    }

    if (chapterNumbering) {
      if (chapterNumbering.chapterType) {
        const chapterData = new NodeDataBuilder('f')
          .addProperty('t', 'sr')
          .addProperty(
            'f',
            chapterNumbering.chapterType as Editor.Data.CrossReferences.PresentationFormat,
          )
          .addProperty('cpt', label)
          .build();

        if (chapterData) {
          captionElements.push(chapterData);
        }
      }

      if (chapterNumbering.separator) {
        const separatorData = new NodeDataBuilder('text')
          .setContent(chapterNumbering.separator)
          .build();
        if (separatorData) {
          captionElements.push(separatorData);
        }
      }
    }

    const fieldData = new NodeDataBuilder('f')
      .addProperty('t', 'cpt')
      .addProperty('cpt', label)
      .build();
    if (fieldData) {
      captionElements.push(fieldData);
    }

    if (text) {
      const textData = new NodeDataBuilder('text').setContent(text).build();
      if (textData) {
        captionElements.push(textData);
      }
    }

    return captionElements;
  }

  static buildData<T extends keyof Editor.Data.Node.DataTypeMapper>(
    data: Editor.Data.Node.DataTypeMapper[T],
  ) {
    const builder = new NodeDataBuilder(data.type);
    builder.setData(data);
    return builder.build();
  }
}
