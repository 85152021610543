import { useIntl } from 'react-intl';
import { Toggle, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { usePermissions } from '_common/components/Permissions/PermissionsContext';

type RemoveColumnProps = {
  objectId: ObjectId;
  collaborator: Permission.Collaborator;
  recursive: boolean;
};
const RemoveColumn = ({ objectId, collaborator, recursive }: RemoveColumnProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const data = useSelector((state) => state.app.data[objectId]);

  const { canRemovePermissions } = usePermissions();

  const handleOpenDeleteRowModal = () => {
    const name = collaborator.name;
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          isOpen: true,
          title: 'REMOVE_SOMETHING_QUOTED',
          titleValues: {
            label: name,
          },
          message: 'REMOVING_COLLABORATOR_WILL_REVOKE_THEIR_ACCESS_CONFIRM',
          messageValues: {
            name,
          },
          confirmButtonTextId: 'global.delete',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'removeRow',
          actionValue: {
            data,
            id: collaborator.collaboratorId,
            formKey: collaborator.collaboratorType,
            recursive,
          },
          headerType: 'error',
          cancelButtonShow: true,
        },
      }),
    );
  };

  if (!canRemovePermissions || collaborator.isOwner) {
    return null;
  }

  return (
    <Tooltip
      content={
        collaborator.collaboratorType === 'user'
          ? intl.formatMessage({ id: 'groups.removeUser' })
          : intl.formatMessage({ id: 'REMOVE_GROUP' })
      }
      placement="bottom"
      testId={`collaborator-${collaborator.collaboratorId}-remove-tooltip`}
    >
      <Toggle
        variant="link"
        size="small"
        icon="CloseGrey"
        onClick={() => handleOpenDeleteRowModal()}
        testId={`collaborator-${collaborator.collaboratorId}-remove`}
      />
    </Tooltip>
  );
};

export default RemoveColumn;
