import { useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Switch } from 'dodoc-design-system';
import { uniq } from 'lodash';

import { useDispatch, useSelector } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import { selectDocumentUsers } from 'Editor/redux/EditorStatusSlice';
import { selectFilteredTasks, setTaskOverlayData, toggleListMode } from 'Editor/redux/TasksSlice';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';

import { PanelContent, PanelHeading } from '_common/suite/components';
import { FilterDisplay, FilterDrawer, InteractionController } from '_common/components';
import { UserOption } from '_common/components/SearchUser/SearchUser';

import TasksTabContent from './TasksTabContent';

import styles from './TasksTab.module.scss';
import { completeAction } from 'App/redux/onboardingSlice';

const TasksTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const object = useSuiteObject();

  const order = useSelector((state) => state.editor.tasks.order);
  const deletedTasks = useSelector((state) => state.editor.tasks.deleted);
  const isListMode = useSelector((state) => state.editor.tasks.isListMode);
  const tasks = useSelector(selectFilteredTasks);
  const usersList = useSelector(selectDocumentUsers);

  useEffect(() => {
    dispatch(completeAction('editor_tasks_openSidePanel'));
  }, []);

  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorTaskPanel),
  );

  const handleSwitchMode = () => {
    if (!isListMode) {
      dispatch(setTaskOverlayData({ selected: null }));
    }
    dispatch(toggleListMode());
  };

  const getTasksNumber = () => {
    if (hasActiveFilters) {
      return (
        <FormattedMessage
          id="CURRENT_OUT_OF_TOTAL_TASKS"
          values={{ current: tasks.length, total: order.length + deletedTasks.length }}
        />
      );
    }

    if (tasks.length === 0) {
      return <FormattedMessage id="TASKS_EMPTY_TITLE" />;
    }

    return <FormattedMessage id="TASKS_IN_THE_DOCUMENT" values={{ length: order.length }} />;
  };

  const users = useMemo(() => {
    return uniq([...object.owners, ...usersList]).map((id) => ({
      id: id,
      type: 'user',
    })) as UserOption[];
  }, [object.owners, usersList]);

  return (
    <InteractionController environment="editor" style={{ flexDirection: 'column' }}>
      <PanelHeading id="editorTaskPanelHeading">
        <FormattedMessage id="TASKS" />
      </PanelHeading>
      <PanelContent testId="tasks-panel">
        <FilterDrawer
          identity="editorTaskPanel"
          taskStatus={{
            options: [
              { value: 'td', label: 'TODO' },
              { value: 'pr', label: 'IN_PROGRESS' },
              { value: 'd', label: 'DONE' },
              { value: 'dlt', label: 'editor.sidebar.review.filter.status.deleted' },
            ],
          }}
          assignedUser={{ options: users }}
        />
        <div className={styles.infoTasks}>
          <div className={styles.infoText}>{getTasksNumber()}</div>
          <div className={styles.listMode}>
            <Switch
              active={isListMode}
              onChange={handleSwitchMode}
              labelPlacement="left"
              size="medium"
              testId="list-mode-switch"
            >
              {intl.formatMessage({ id: 'LIST_MODE' })}
            </Switch>
          </div>
        </div>
        <FilterDisplay identity="editorTaskPanel" direction="column" margin="0 0 2rem 0" />
        <TasksTabContent />
      </PanelContent>
    </InteractionController>
  );
};

export default TasksTab;
