import { createSlice, createAsyncThunk, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { navigateToSignIn, navigateToDeviceRegistration } from 'router/history';
import { notify } from '_common/components/ToastSystem';
import { AuthService, Logger } from '_common/services';

import {
  resetAppState,
  setAppLoading,
  setRedirectValidSession,
  setAppInformation,
} from 'App/redux/appSlice';
import { authenticationSuccessful, updateAccount } from 'Auth/redux/localStorageSlice';
import { loadPersonalSpace } from 'Storage/pages/StoragePage/StoragePageSlice';
import authority from '_common/services/api/authority';
import { SessionStorage } from '_common/utils';

const SLICE_NAME = 'auth';

type SliceState = {
  loading: boolean;
  authenticated: boolean;
  firstLogin: boolean;
  feedback: Feedback | null;
  sendingNewCode: boolean;
  failedLogins: number;
  setupData: {
    '3p': AuthoritySchemas['ThirdPartyObject'][];
    custom_message?: string;
    login_max_attempts: number | null;
    allow_login_with_password: boolean | null;
  };
  userId: Uuid;
  isUpdatePasswordForced: boolean;
  authority_version: string;
  dodoc_version: string;
};

// #region State
export const INITIAL_STATE: SliceState = {
  loading: false,
  authenticated: false,
  firstLogin: false,
  feedback: null,
  sendingNewCode: false,
  failedLogins: 0,
  setupData: {
    '3p': [],
    custom_message: undefined,
    login_max_attempts: null,
    allow_login_with_password: null,
  },
  userId: '',
  isUpdatePasswordForced: false,
  authority_version: '',
  dodoc_version: '',
};
// #endregion

// #region AsyncThunks
export const signIn = createAsyncThunk(
  `${SLICE_NAME}/signIn`,
  async (
    {
      params,
    }: {
      params: {
        username: string;
        password: string;
        device?: string | undefined;
      };
    },
    { dispatch, getState },
  ) => {
    const { accounts } = (getState() as RootState).localStorage;
    const accountId = Object.keys(accounts).find(
      (accountId) => accounts[accountId].username === params.username,
    );
    let storedDevice = '';
    if (accountId) {
      storedDevice = accounts[accountId].device || '';
    }

    try {
      const { data } = await new AuthService({ errorsExpected: [400, 403, 427, 428] }).signIn({
        ...params,
        device: storedDevice,
      });

      SessionStorage.setToken(data.token ?? '');
      Logger.setUser({
        username: data.username,
        id: data.id,
        ip_address: undefined,
      });

      if ((getState() as RootState).app.platform.browser.ie) {
        Logger.captureMessage(`User ${data.username} just signed in using IE`, {
          level: 'info',
          extra: { user: data },
        });
      }

      dispatch(authenticationSuccessful(data));
      dispatch(loginSuccessful(data));
      dispatch(
        setAppInformation({
          actions: data.actions,
          extra: data.extra,
          third_party: data.third_party,
        }),
      );

      Logger.configureScope((scope) =>
        scope.setUser({ username: params.username, ip_address: '' }),
      );
      return data;
    } catch (error) {
      if (AuthService.isAxiosError(error)) {
        switch (error?.response?.status) {
          case 400: {
            const error = 'auth.errors.error';
            dispatch(gotFeedback({ message: error, type: 'error', id: '400' }));
            break;
          }
          case 403: {
            dispatch(incrementFailedLogins());
            const { failedLogins } = (getState() as RootState).auth;
            const { setupData } = (getState() as RootState).auth;
            let error;
            let id = '403';

            if (failedLogins === 1 || !setupData.login_max_attempts) {
              error = 'INVALID_LOGIN_1';
              id = '403_1';
            } else if (failedLogins <= setupData.login_max_attempts) {
              error = 'INVALID_LOGIN_2';
              id = '403_2';
            } else {
              error = 'INVALID_LOGIN_3';
              id = '403_3';
            }

            dispatch(gotFeedback({ message: error ?? '', type: 'error', id }));

            throw error;
          }
          case 427: {
            const firstLogin = error?.response.data.first_login;
            SessionStorage.setToken(error.response.data.token ?? '');
            if (firstLogin) {
              dispatch(
                authenticationSuccessful({ ...error.response.data, username: params.username }),
              );
              dispatch(isFirstLogin(error.response.data));
            } else {
              notify({
                id: 'forcePasswordReset',
                type: 'error',
                title: 'notifications.passwordOutdated.title',
                message: 'notifications.passwordOutdated.message',
              });
              dispatch(changePasswordUpdateForced(true));
              dispatch(setAppInformation({ ...error.response.data, username: params.username }));
              dispatch(
                authenticationSuccessful({ ...error.response.data, username: params.username }),
              );
              dispatch(loginSuccessful({ ...error.response.data, username: params.username }));
              dispatch(setAppInformation({ ...error.response.data, username: params.username }));
              Logger.configureScope((scope) =>
                scope.setUser({ username: params.username, ip_address: '' }),
              );
            }
            break;
          }
          case 428: {
            dispatch(
              authenticationSuccessful({ ...error.response.data, username: params.username }),
            );
            dispatch(loginSuccessful({ ...error.response.data, username: params.username }));
            dispatch(setAppInformation({ ...error.response.data, username: params.username }));
            Logger.configureScope((scope) =>
              scope.setUser({ username: params.username, ip_address: '' }),
            );
            navigateToDeviceRegistration();
            break;
          }
          default: {
            const error = 'auth.errors.error';
            dispatch(gotFeedback({ message: error, type: 'error' }));
          }
        }
      }
    }

    return {};
  },
);

export const checkActiveAccount = createAsyncThunk(
  `${SLICE_NAME}/checkActiveAccount`,
  async ({ account }: { account: Account }, { dispatch }) => {
    if (account && account.token) {
      try {
        const { data } = await new AuthService().getLogin(account.token);
        SessionStorage.setToken(account.token);

        data.token = account.token;

        dispatch(setRedirectValidSession(true));
        dispatch(updateAccount(data));
        dispatch(
          setAppInformation({
            actions: data.actions,
            extra: data.extra,
            third_party: data.third_party,
          }),
        );
        return data;
      } catch (error) {
        if (AuthService.isAxiosError(error)) {
          if (error?.response?.status === 427) {
            if (error.response.data.first_login) {
              dispatch(authenticationSuccessful(error.response.data));
              dispatch(isFirstLogin(error.response.data));
            } else {
              dispatch(changePasswordUpdateForced(true));
              dispatch(authenticationSuccessful(error.response.data));
              dispatch(setAppInformation(error.response.data));
              dispatch(loginSuccessful(error.response.data));
            }
            return error.response.data;
          } else {
            dispatch(signedOut());
          }
        }
      }
    } else {
      dispatch(signedOut());
      dispatch(switchedAccount(account));
      dispatch(updateAccount(account));
      dispatch(setAppLoading({ isOpen: false }));
    }
  },
);

export const switchAccount = createAsyncThunk(
  `${SLICE_NAME}/switchAccount`,
  async ({ user }: { user: Account }, { dispatch }) => {
    dispatch(setRedirectValidSession(false));
    dispatch(switchingAccount());
    dispatch(setAppLoading({ isOpen: true }));

    try {
      const { data } = await new AuthService({
        useAuthorizationToken: false,
        errorsExpected: [401, 427],
      }).getLogin(user.token);

      const persist = JSON.parse(
        JSON.stringify(window.localStorage.getItem(`doDOC-myFiles-${user.id}`)),
      );

      SessionStorage.setToken(user.token ?? '');

      dispatch(switchedAccount(data));
      dispatch(updateAccount(data));

      dispatch(
        authority.util.invalidateTags([
          {
            type: 'User',
            id: 'Current',
          },
        ]),
      );

      navigateToSignIn();
      dispatch(
        loadPersonalSpace({
          offset: 0,
          size: 200,
          filter_fields: persist && persist.filter_fields ? persist.filter_fields : [],
          filter_values: persist && persist.filter_values ? persist.filter_values : [],
          order_field: persist && persist.order_field && persist.order_field,
          order_type: persist && persist.order_type && persist.order_type,
        }),
      );

      dispatch(setAppLoading({ isOpen: false }));
    } catch (error) {
      if (AuthService.isAxiosError(error)) {
        if (error?.response?.status === 401 || error?.response?.status === 427) {
          dispatch(setRedirectValidSession(false));
          dispatch(signedOut());
          dispatch(switchedAccount(user));
          dispatch(updateAccount(user));

          dispatch(setAppLoading({ isOpen: false }));
        } else {
          dispatch(signedOut());
          dispatch(switchedAccount(user));
          dispatch(updateAccount(user));

          dispatch(setAppLoading({ isOpen: false }));
        }
      }
    }
  },
);
// #endregion

// #region Slice
const authSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    // This can't be a fulfilled because it needs to happen before getPublicProfiles dispatch
    // Update: getPublicProfiles isn't happening anymore, change this to the fulfilled callback?
    loginSuccessful: (state, action: PayloadAction<{ id: string }>) => {
      state.userId = action.payload.id;
      state.authenticated = true;
      state.feedback = null;
      state.failedLogins = 0;
    },
    resetLoginErrors: (state) => {
      state.feedback = null;
      state.failedLogins = 0;
    },
    signedOut: (state) => {
      state.authenticated = false;
      state.userId = '';
      state.isUpdatePasswordForced = false;
      state.firstLogin = false;
    },
    setFinishedFirstLogin: (state) => {
      state.firstLogin = false;
      state.isUpdatePasswordForced = false;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
    },
    switchedAccount: (state, action: PayloadAction<{ id: string }>) => {
      state.userId = action.payload.id;
    },
    // maybe this will be a rejected action
    incrementFailedLogins: (state) => {
      state.failedLogins += 1;
    },
    changePasswordUpdateForced: (state, action: PayloadAction<boolean>) => {
      state.isUpdatePasswordForced = action.payload;
    },
    isFirstLogin: (state, action: PayloadAction<{ id: string }>) => {
      state.userId = action.payload.id;
      state.firstLogin = true;
    },
    gotFeedback: (state, action: PayloadAction<Feedback | null>) => {
      state.feedback = action.payload;
    },
    // used by other reducers
    switchingAccount: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkActiveAccount.fulfilled, (state, action) => {
      state.userId = action.payload?.id ? action.payload.id : '';
      state.authenticated = true;
    });

    builder.addCase(resetAppState, () => {
      return INITIAL_STATE;
    });
    builder.addMatcher(isAnyOf(signIn.fulfilled, signIn.rejected), (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      isAnyOf(authority.endpoints.getCurrentUser.matchFulfilled),
      (state, action) => {
        state.userId = action.payload.profile.id;
      },
    );
    builder.addMatcher(isAnyOf(authority.endpoints.loginSetup.matchFulfilled), (state, action) => {
      state.setupData = { ...state.setupData, ...action.payload };
      // @ts-expect-error need api types update
      state.authority_version = action.payload.authority_version;
      // @ts-expect-error need api types update
      state.dodoc_version = action.payload.dodoc_version;
    });
    builder.addMatcher(
      isAnyOf(
        authority.endpoints.signOut.matchFulfilled,
        authority.endpoints.signOut.matchRejected,
      ),
      (state) => {
        state.authenticated = false;
        state.userId = '';
        state.isUpdatePasswordForced = false;
      },
    );
    builder.addMatcher(
      isAnyOf(authority.endpoints.getTokenInfo.matchFulfilled),
      (state, action) => {
        state.userId = action.payload.id;
        state.authenticated = true;
      },
    );
    builder.addMatcher(isAnyOf(authority.endpoints.getTokenInfo.matchRejected), (state) => {
      state.authenticated = false;
    });
    builder.addMatcher(authority.endpoints.recoverPassword.matchFulfilled, (state) => {
      state.feedback = {
        message: 'FORGOT_PASSWORD_SUCCESS',
        type: 'success',
      };
    });
    builder.addMatcher(authority.endpoints.recoverPassword.matchRejected, (state) => {
      state.feedback = {
        message: 'auth.errors.error',
        type: 'error',
      };
    });
    builder.addMatcher(authority.endpoints.unlockAccount.matchFulfilled, (state) => {
      state.feedback = { type: 'success', message: 'auth.login.unlockSuccess' };
    });
    builder.addMatcher(authority.endpoints.unlockAccount.matchRejected, (state) => {
      state.feedback = null;
    });
    builder.addMatcher(authority.endpoints.updatePassword.matchFulfilled, (state) => {
      state.isUpdatePasswordForced = false;
    });
    builder.addMatcher(authority.endpoints.updatePassword.matchRejected, (state, action) => {
      if (action.payload?.status === 400) {
        const errors = action.payload?.data.errors[0];
        const key = errors.errors[0];
        let feedback: Omit<Feedback, 'type'> = { message: '' };

        const VALUES_FOR_FEEDBACK = {
          username: 'settings.user.username',
          first_name: 'settings.name.firstName',
          last_name: 'settings.name.lastName',
          email_address: 'editor.templatePreview.properties.authors.email',
        };

        const errorField = errors[key];

        switch (key) {
          case 'password_too_similar':
            feedback = {
              message: key.toUpperCase(),
              messageValues: {
                name:
                  errorField in VALUES_FOR_FEEDBACK
                    ? VALUES_FOR_FEEDBACK[errorField as keyof typeof VALUES_FOR_FEEDBACK]
                    : '',
              },
            };
            break;
          case 'invalid':
            feedback = {
              message: 'PASSWORD_ALREADY_UPDATED',
            };
            break;
          case 'expired':
            feedback = {
              message: 'RESET_PASSWORD_EXPIRED',
            };
            break;
          case 'password_in_history':
            feedback = {
              message: 'PASSWORD_RECENTLY_USED',
            };
            break;
          case 'password_too_common':
            feedback = {
              message: 'PASSWORD_TOO_COMMON',
            };
            break;
          default:
            feedback = {
              message: 'ERROR_SCREEN_HEADER',
            };
            break;
        }

        state.feedback = { ...feedback, type: 'error' };
      }
    });
  },
});

// Actions
export const {
  loginSuccessful,
  resetLoginErrors,
  signedOut,
  setFinishedFirstLogin,
  setAuthenticated,
  switchedAccount,
  incrementFailedLogins,
  changePasswordUpdateForced,
  isFirstLogin,
  gotFeedback,
  switchingAccount,
} = authSlice.actions;

// Persistence
const persistConfig = {
  key: 'auth',
  storage: storageSession,
  whitelist: [
    'userId',
    'username',
    'authenticated',
    'isUpdatePasswordForced',
    'authority_version',
    'dodoc_version',
  ],
};

const authReducer = persistReducer(persistConfig, authSlice.reducer);

export default authReducer;
// #endregion
