import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import Inconsistencies from './Inconsistencies';
import styles from './MetadataInconsistenciesModal.module.scss';

const MetadataInconsistenciesModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open.MetadataInconsistenciesModal);
  const metadata = useSelector((state) => state.modals.MetadataInconsistenciesModal.errors);
  const objectId = useSelector((state) => state.modals.MetadataInconsistenciesModal.id);

  const close = () => {
    dispatch(closeAndResetModal('MetadataInconsistenciesModal'));
  };
  return (
    <Modal width="65rem" open={!!isOpen} onClose={close} testId="metadata-inconsistencies">
      <Modal.Header onClose={close}>
        <FormattedMessage id="METADATA_INCONSISTENCIES" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <FormattedMessage id="FOUND_METADATA_INCONSISTENCIES_VEEVA_EXPORT" />
        </div>
        <Inconsistencies metadata={metadata} objectId={objectId} />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="metadata-inconsistencies-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MetadataInconsistenciesModal;
