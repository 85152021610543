import { faker } from '@faker-js/faker';
import { createMockedTimeObject, createMockedObjectId, createMockedUserId } from './common';

export function createMockedRole(overwrites?: Partial<doDOC.Tenant.Role>): doDOC.Tenant.Role {
  const ownerId = createMockedUserId();
  return {
    id: createMockedObjectId(),
    creator: ownerId,
    description: faker.lorem.lines(2),
    name: faker.name.jobDescriptor(),
    owner: ownerId,
    tags: [],
    time: createMockedTimeObject(),
    type: 'role',
    // granted: ['access'],
    granted: faker.helpers.arrayElements([
      'delete',
      'comment',
      'owner',
      'edit',
      'approve',
      'add_permission',
      'remove_permission',
      'import',
      'export',
    ] as const),
    ...overwrites,
  };
}

export function createMockedRolesList(): doDOC.Tenant.Role[] {
  return [
    createMockedRole({
      name: 'Viewer',
      description: 'Access permission only',
      granted: ['access'],
    }),
    createMockedRole({
      name: 'Owner',
      description: 'Owner permission',
      granted: ['owner'],
    }),
    createMockedRole(),
    createMockedRole({
      name: 'Reviewer',
      description: undefined,
      granted: ['edit', 'comment'],
    }),
  ];
}
