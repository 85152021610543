/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { StatusValue, TaskData, TaskEditData, Status, TaskDataLongProps, Task } from '../../models';
import { Logger } from '_common/services';
import BaseController from '../BaseController';
import { TaskList } from './TaskList';
import { IndexerDeltaType, ModelIndexer } from '../Models/ModelIndexer';
import { v4 as uuid } from 'uuid';
import { Descendant } from 'slate';
import { reject } from 'lodash';
import { RealtimeResponse } from '_common/services/Realtime/Transport';
import { ModelsController } from '../Models/ModelsController';

export class DeletedTaskList extends ModelIndexer<'TASK'> {
  start(documentId: string) {
    super.start({
      p_id: documentId,
      't.dlt': { $exists: true },
    });
  }
}
export class TasksController extends BaseController {
  private tasksList?: TaskList;
  private deletedTasksList?: DeletedTaskList;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.handleTaskListError = this.handleTaskListError.bind(this);
    this.handleTaskListChanged = this.handleTaskListChanged.bind(this);
    this.handleTaskChanged = this.handleTaskChanged.bind(this);
    this.handleDeletedTaskListChanged = this.handleDeletedTaskListChanged.bind(this);
    this.handleDeletedTaskListInserted = this.handleDeletedTaskListInserted.bind(this);
    this.handleDeletedTaskListRemoved = this.handleDeletedTaskListRemoved.bind(this);
  }

  start(documentId: string): void {
    this.tasksList = this.Data.models?.getIndexer('TASK') as TaskList;
    this.tasksList.on('ERROR', this.handleTaskListError);
    this.tasksList.on('CHANGED_DELTA', this.handleTaskListChanged);
    this.deletedTasksList = new DeletedTaskList(
      this.Data.transport,
      this.Data.models as ModelsController,
      'TASK',
    );
    this.deletedTasksList.on('INSERTED', this.handleDeletedTaskListInserted);
    this.deletedTasksList.on('REMOVED', this.handleDeletedTaskListRemoved);
    this.deletedTasksList.on('CHANGED', this.handleDeletedTaskListChanged);
    this.tasksList.start(documentId);
    this.deletedTasksList.start(documentId);
  }

  private handleTaskListError(error: Error) {
    Logger.error(error);
  }

  private handleTaskListChanged(data: IndexerDeltaType<string[]>) {
    for (let index = 0; index < data.in.length; index++) {
      const element = this.tasksList?.task(data.in[index]);
      element?.on('LOADED', this.handleTaskChanged);
      element?.on('UPDATED', this.handleTaskChanged);
    }
    this.loadTasksData();
  }

  private handleDeletedTaskListInserted(inserted: Task[]) {
    for (let index = 0; index < inserted.length; index++) {
      const element = inserted[index];
      element?.on('LOADED', this.handleTaskChanged);
      element?.on('UPDATED', this.handleTaskChanged);
    }
  }

  private handleDeletedTaskListRemoved(removed: Task[]) {
    for (let index = 0; index < removed.length; index++) {
      const element = removed[index];
      element?.off('LOADED', this.handleTaskChanged);
      element?.off('UPDATED', this.handleTaskChanged);
    }
  }

  private handleDeletedTaskListChanged(data: Task[]) {
    this.loadDeletedTasksData();
  }

  private loadDeletedTasksData() {
    const tasksData: { [index: string]: TaskData } = {};
    const tasksList: string[] = [];
    const dataObjs = this.deletedTasksList?.results || [];
    for (let index = 0; index < dataObjs.length; index++) {
      const task = dataObjs[index];
      tasksList.push(task.id);
      tasksData[task.id] = {
        id: task.id,
        ...(task.get([]) || {}),
      };
    }
    this.Data.events?.emit('LOAD_DELETED_TASKS_DATA', tasksList, tasksData);
  }

  private loadTasksData() {
    const tasksData: { [index: string]: TaskData } = {};
    const tasksList = this.tasksList?.list || [];
    for (let index = 0; index < tasksList.length; index++) {
      const taskId: string = tasksList[index];
      tasksData[taskId] = {
        id: taskId,
        ...(this.tasksList?.task(taskId).get([]) || {}),
      };
    }
    this.Data.events?.emit('LOAD_TASKS_DATA', tasksList, tasksData);
  }

  private handleTaskChanged(data: TaskData | null) {
    this.Data.events?.emit('UPDATE_TASK', data);
  }

  stop(): void {}

  destroy(): void {
    this.tasksList?.destroy();
    this.deletedTasksList?.destroy();
  }

  getTaskModelById(taskId: string): Task | undefined {
    return this.Data.models?.get('TASK', taskId);
  }

  async createTask(data: TaskDataLongProps, locations: string[]): Promise<string> {
    return new Promise((resolve) => {
      const taskId = uuid();
      this.Data.transport.dispatchEvent(
        'TASK:CREATE',
        {
          task: {
            id: taskId,
            asg: data.assignee,
            u: this.Data.users?.loggedUserId as string,
            p_id: this.Data.structure?.structureData.parent_id as string,
            t: {
              c: new Date().toISOString(),
              d: data.dueDate ? new Date(data.dueDate).toISOString() : null,
            },
            s: Status.TO_DO,
            d: data.description,
            w: [],
          },
          locations,
        },
        (response: RealtimeResponse<void>) => {
          if (response.success) {
            resolve(taskId);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  async editTask(id: string, data: Partial<TaskDataLongProps>) {
    const editData: TaskEditData = {};
    if (data.dueDate) {
      editData.t = {
        d: new Date(data.dueDate).toISOString(),
      };
    } else {
      editData.t = {
        d: null,
      };
    }
    if (data.description) {
      editData.d = data.description;
    }
    if (data.assignee || data.assignee === null) {
      editData.asg = data.assignee;
    }
    return this.tasksList?.data[id].edit(editData);
  }

  async changeStatus(taskId: string, status: StatusValue) {
    return this.tasksList?.data[taskId].changeStatus(status);
  }

  async changeAssignee(taskId: string, user: string) {
    return this.tasksList?.data[taskId].changeAssignee(user);
  }

  async changeDescription(taskId: string, description: string) {
    return this.tasksList?.data[taskId].changeDescription(description);
  }

  async deleteTask(taskId: string) {
    return new Promise((resolve) => {
      this.Data.transport.dispatchEvent(
        'TASK:DELETE',
        {
          taskId,
        },
        (response: RealtimeResponse<void>) => {
          if (response.success) {
            resolve(taskId);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  async removeTaskNode(nodeId: string) {
    const nodeTasks = this.Data.nodes?.getNodeTasks(nodeId);
    if (nodeTasks.length) {
      for (let index = 0; index < nodeTasks.length; index++) {
        const nodesWithTask = this.tasksList?.getNodesWithTask(nodeTasks[index]);
        if (nodesWithTask?.length === 1 && nodesWithTask[0] === nodeId) {
          await this.tasksList?.data[nodeTasks[index]].deleteTask();
        }
      }
    }
  }

  async replyTask(id: string, replyContent: Descendant[]) {
    return this.tasksList?.data[id].reply(replyContent, this.Data.users?.loggedUserId as string);
  }

  async editTaskReply(taskId: string, taskReplyId: string, content: Descendant[]) {
    return this.tasksList?.data[taskId].editReply(taskReplyId, content);
  }

  async voteTaskReply(taskId: string, taskReplyId: string, vote: boolean) {
    return this.tasksList?.data[taskId].voteReply(
      taskReplyId,
      vote,
      this.Data.users?.loggedUserId as string,
    );
  }

  async deleteTaskReply(taskId: string, taskReplyId: string) {
    return this.tasksList?.data[taskId].deleteReply(taskReplyId);
  }

  async watchTask(taskId: string, userId: string = this.Data.users?.loggedUserId as string) {
    return this.tasksList?.data[taskId].watchTask(userId);
  }

  async removeWatchFromTask(taskId: string, user: string) {
    return this.tasksList?.data[taskId].removeWatchFromTask(user);
  }

  async reappendTask(taskId: string) {
    return this.tasksList?.data[taskId].reappend();
  }

  getNodesWithTask(taskId: string) {
    return this.tasksList?.getNodesWithTask(taskId);
  }

  get list() {
    return this.tasksList;
  }

  get deleteList() {
    return this.deletedTasksList;
  }

  async applyOpsToTask(
    taskId: string,
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    const model = this.getTaskModelById(taskId);
    if (model?.loaded) {
      return model.apply(ops, options);
    }
  }

  async revertOpsToTask(
    taskId: string,
    ops: Realtime.Core.RealtimeOps,
    options?: Realtime.Core.RealtimeSourceOptions,
  ) {
    const model = this.getTaskModelById(taskId);
    if (model?.loaded) {
      return model.revert(ops, options);
    }
  }
}
