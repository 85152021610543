import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ELEMENTS } from 'Editor/services/consts';
import { ReduxInterface } from 'Editor/services';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { notify } from '_common/components/ToastSystem';
import { EditorRange, EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils, EditorDOMElements } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class RemoveContentHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       *
       * @param {ActionContext} actionContext
       */
      removeNonEditableElementsFromSelection(actionContext) {
        const nodes = EditorSelectionUtils.getRange().getNodes(
          [Node.ELEMENT_NODE],
          (node) =>
            node.parentNode === this.page &&
            EditorDOMUtils.isBlockNodeDeletable(node) &&
            !EditorDOMUtils.isClosestBlockNodeEditable(node),
        );

        for (let i = 0; i < nodes.length; i += 1) {
          this.removeBlockElementFromDOM(actionContext, nodes[i]);
        }
      }

      /**
       * remove content from selection
       * @param {ActionContext} actionContext
       */
      removeSelectionContent(actionContext) {
        // check if action is can be applied to selection
        if (this.selectionManager.isCurrentSelectionDeletable()) {
          let range = EditorSelectionUtils.getRange();

          // validate confirmation to delete captions
          if (!actionContext.confirmDeleteCaption) {
            const captions = EditorSelectionUtils.getElementsFromRange(
              range,
              [ELEMENTS.FieldElement.TAG],
              (node, isAncestor) => {
                if (isAncestor) {
                  return (
                    node.isTypeCaption() &&
                    EditorSelectionUtils.isSelectionAtStart(node, 'start', range) &&
                    EditorSelectionUtils.isSelectionAtEnd(node, 'end', range)
                  );
                }
                return node.isTypeCaption();
              },
            );

            if (captions.length > 0) {
              ReduxInterface.openDeleteCaptionConfirmationModal();
              return false;
            }
          }

          // remove non-editable elements if they exist in selection
          if (!this.selectionManager.isCurrentSelectionEditable()) {
            this.removeNonEditableElementsFromSelection(actionContext);
          }

          // double-check if the currect selection is editable
          // temp fix
          if (this.selectionManager.isCurrentSelectionEditable()) {
            range = EditorSelectionUtils.getRange();
            const rangeNodes = Array.from(range.getNodes());

            for (let i = 0; i < rangeNodes.length; i += 1) {
              try {
                if (rangeNodes[i] && this.page.contains(rangeNodes[i])) {
                  // if selection contains images, remove figure node (legacy)
                  if (
                    rangeNodes[i].tagName === 'IMG' ||
                    rangeNodes[i].tagName === 'IMAGE-ELEMENT'
                  ) {
                    const figure = EditorDOMUtils.closest(rangeNodes[i], [
                      ELEMENTS.FigureElement.TAG,
                    ]);
                    if (figure) {
                      if (
                        figure.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG ||
                        figure.parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG
                      ) {
                        actionContext.addReferenceToRefresh(
                          figure.parentNode.getAttribute('element_reference'),
                        );
                        this.removeBlockElementFromDOM(actionContext, figure.parentNode);
                      } else {
                        this.removeBlockElementFromDOM(actionContext, figure);
                      }
                    }
                  }

                  // if selection contains a table body, remove table node
                  if (rangeNodes[i].tagName === ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG) {
                    const table = EditorDOMUtils.closest(rangeNodes[i], [
                      ELEMENTS.TableElement.TAG,
                    ]);

                    if (
                      table.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG ||
                      table.parentNode.tagName === ELEMENTS.TrackDeleteElement.TAG
                    ) {
                      actionContext.addReferenceToRefresh(
                        table.parentNode.getAttribute('element_reference'),
                      );
                      this.removeBlockElementFromDOM(actionContext, table.parentNode);
                    } else {
                      this.removeBlockElementFromDOM(actionContext, table);
                    }
                  }

                  // if selection contains a non-editable text element
                  const nonEditable = EditorDOMUtils.closest(
                    rangeNodes[i],
                    EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
                  );
                  if (nonEditable) {
                    if (nonEditable.tagName === 'CITATION-ELEMENT') {
                      const citationGroup = EditorDOMUtils.closest(
                        nonEditable,
                        'CITATIONS-GROUP-ELEMENT',
                      );
                      if (
                        citationGroup &&
                        citationGroup.getCitationsCount &&
                        citationGroup.getCitationsCount() <= 1
                      ) {
                        // if parent citatio group has only one child
                        actionContext.addChangeUpdatedNode(citationGroup);
                        citationGroup.remove();
                      } else {
                        actionContext.addChangeUpdatedNode(nonEditable);
                        nonEditable.remove();
                      }
                    } else {
                      if (
                        !EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(
                          nonEditable.tagName,
                        )
                      ) {
                        actionContext.addChangeUpdatedNode(nonEditable);
                      }
                      nonEditable.remove();
                    }
                  }

                  // if there are suggestion elements in range mark them to be checked
                  if (
                    rangeNodes[i].tagName === ELEMENTS.TrackDeleteElement.TAG ||
                    rangeNodes[i].tagName === ELEMENTS.TrackInsertElement.TAG
                  ) {
                    actionContext.addReferenceToRefresh(
                      rangeNodes[i].getAttribute('element_reference'),
                    );

                    // if there is a inserted or deleted paragraph marker it removes it
                    if (EditorDOMElements.isNodeSuggestionParagraphMarker(rangeNodes[i])) {
                      rangeNodes[i].remove();
                    }
                  }
                }
              } catch (error) {
                Logger.captureException(error);
              }
            }

            // get updated / altered range to delete contentss
            range = EditorSelectionUtils.getRange();

            // check start container for inline elements
            const elementsToCheck = [
              ...EditorDOMElements.INLINE_EDITABLE_ELEMENTS,
              ELEMENTS.TrackInsertElement.TAG,
              ELEMENTS.TrackDeleteElement.TAG,
            ];

            let workingNode = range.startContainer;
            let closestStartEditable;
            while (
              workingNode.parentNode &&
              workingNode.parentNode !== this.page &&
              !EditorDOMElements.isNodeContainerElement(workingNode.parentNode) &&
              (closestStartEditable = EditorDOMUtils.closest(workingNode, elementsToCheck)) &&
              EditorSelectionUtils.isSelectionAtStart(range.startContainer, 'start', range)
            ) {
              if (
                closestStartEditable.parentNode !== this.page &&
                !EditorDOMElements.isNodeContainerElement(closestStartEditable.parentNode) &&
                range.commonAncestorContainer.contains(closestStartEditable)
              ) {
                range.setStartBefore(closestStartEditable);
              }
              workingNode = closestStartEditable.parentNode;
            }

            // check end container for inline elements
            workingNode = range.endContainer;
            let closestEndEditable;
            while (
              workingNode.parentNode &&
              workingNode.parentNode !== this.page &&
              !EditorDOMElements.isNodeContainerElement(workingNode.parentNode) &&
              (closestEndEditable = EditorDOMUtils.closest(workingNode, elementsToCheck)) &&
              EditorSelectionUtils.isSelectionAtEnd(range.endContainer, 'end', range)
            ) {
              if (
                closestEndEditable.parentNode !== this.page &&
                !EditorDOMElements.isNodeContainerElement(closestEndEditable.parentNode) &&
                range.commonAncestorContainer.contains(closestEndEditable)
              ) {
                range.setEndAfter(closestEndEditable);
              }
              workingNode = closestEndEditable.parentNode;
            }

            let parentContainer = range.commonAncestorContainer;

            const blockNode = EditorDOMUtils.findFirstLevelChildNode(this.page, parentContainer);

            const closest = EditorDOMUtils.closest(parentContainer, [
              ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG,
              ELEMENTS.FigureElement.TAG,
            ]);
            if (closest) {
              if (closest.tagName === ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG) {
                // split ranges and remove when inside table
                const rangeList = EditorRange.splitRangeByTableCells(range);
                rangeList.forEach((r) => {
                  this.removeRangeContents(actionContext, r);
                  // range.deleteContents()
                });
              } else if (closest.tagName === ELEMENTS.FigureElement.TAG) {
                this.removeBlockElementFromDOM(actionContext, closest);
              }
            } else {
              this.removeRangeContents(actionContext, range);
              // range.deleteContents();
            }

            EditorSelectionUtils.fixSelection(range, true);

            parentContainer = range.commonAncestorContainer;

            // add a empty P if all the content is deleted from the PAGE or from inside a TD
            if (
              parentContainer === this.page ||
              EditorDOMElements.isNodeContainerElement(parentContainer)
            ) {
              if (parentContainer.childNodes.length === 0) {
                if (parentContainer === this.page) {
                  const data = NodeDataBuilder.buildParagraph({
                    parent_id: parentContainer.id,
                  });
                  this.inserBlockNodeOperation(actionContext, data, null, 'AFTER');
                } else if (EditorDOMElements.isNodeContainerElement(parentContainer)) {
                  const p = DOMElementFactory.createNewParagraphElement();
                  EditorDOMUtils.appendNode(parentContainer, p);
                  this.selectionManager.setCaret(p, 'INDEX', 0);
                  actionContext.addChangeAddedNode(p);
                }
              } else {
                EditorSelectionUtils.fixSelection();
              }
            }

            const closestTable = EditorDOMUtils.closest(range.startContainer, [
              ELEMENTS.TableElement.TAG,
            ]);

            if (closestTable) {
              if (closestTable.previousSibling?.tagName === ELEMENTS.TableElement.TAG) {
                const data = NodeDataBuilder.buildParagraph({
                  parent_id: closestTable.parentNode.id,
                });
                this.inserBlockNodeOperation(actionContext, data, closestTable, 'BEFORE');
              } else if (closestTable.nextSibling?.tagName === ELEMENTS.TableElement.TAG) {
                const data = NodeDataBuilder.buildParagraph({
                  parent_id: closestTable.parentNode.id,
                });
                this.inserBlockNodeOperation(actionContext, data, closestTable, 'AFTER');
              }
            }

            // check for editable elements, format-elements, track-insert, track-delete
            const inlineElementsToCheck = [
              ELEMENTS.TrackDeleteElement.TAG,
              ELEMENTS.TrackInsertElement.TAG,
              ...EditorDOMElements.INLINE_EDITABLE_ELEMENTS,
            ];
            let closestEditableElement;

            let styleAppliers = [];

            while (
              parentContainer &&
              blockNode &&
              EditorDOMUtils.parentContainsNode(blockNode, parentContainer) &&
              (closestEditableElement = EditorDOMUtils.closest(
                parentContainer,
                inlineElementsToCheck,
              ))
            ) {
              if (
                closestEditableElement.tagName === ELEMENTS.TrackDeleteElement.TAG ||
                closestEditableElement.tagName === ELEMENTS.TrackInsertElement.TAG
              ) {
                actionContext.addReferenceToRefresh(
                  closestEditableElement.getAttribute('element_reference'),
                );
              }

              if (EditorDOMUtils.isEmptyElement(closestEditableElement)) {
                if (closestEditableElement.tagName === ELEMENTS.FormatElement.TAG) {
                  styleAppliers = [
                    ...styleAppliers,
                    ...this.stylesHandler.getStyleAppliersFromElement(closestEditableElement),
                  ];
                }

                actionContext.addChangeUpdatedNode(closestEditableElement);
                this.selectionManager.setCaret(closestEditableElement, 'PRE');
                closestEditableElement.parentNode.removeChild(closestEditableElement);
              }

              parentContainer = closestEditableElement.parentNode;
            }

            if (styleAppliers.length > 0) {
              this.stylesHandler.addArrayAppliersToPendingStyles({
                appliers: styleAppliers,
                skipNextCheckStyles: true,
              });
            }

            if (styleAppliers.length > 0) {
              this.stylesHandler.addArrayAppliersToPendingStyles({
                appliers: styleAppliers,
                skipNextCheckStyles: true,
              });
            }

            // check empty paragraphs
            if (
              blockNode &&
              blockNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              blockNode.checkEmptyContent
            ) {
              blockNode.checkEmptyContent();
            }

            return true;
          }
        } else {
          if (this.selectionManager.isCurrentSelectionApproved()) {
            notify({
              type: 'error',
              title: 'CANNOT_DELETE_CONTENT',
              message: 'UNAPPROVE_BEFORE_DELETE',
            });
          }
        }

        return false;
      }
    },
);
