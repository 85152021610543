export default class ErrorNodeCannotBeEdited extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'ErrorNodeCannotBeEdited';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorNodeCannotBeEdited);
    }
  }
}
