import { FormattedMessage } from 'react-intl';
import { ProgressBar } from 'dodoc-design-system';

import { useGetAffiliationsListQuery } from 'Settings/pages/TenantSettingsPage/Affiliations/AffiliationsApi';

import Field from './Field/Field';

import styles from './Affiliations.module.scss';

const AffiliationsContainer = () => {
  const { data: fields, isLoading } = useGetAffiliationsListQuery();

  if (isLoading) {
    return (
      <div className={styles.loading} data-testid="loading-information">
        <ProgressBar testId="loading-information-progressBar" />
        <div style={{ marginTop: '3rem' }}>
          <FormattedMessage id="LOADING_INFORMATION" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.content}>
      <div>
        {fields &&
          (Object.keys(fields) as Affiliation.Field[]).map((field, i) => (
            <div key={field}>
              <Field field={field} margin={i > 0 ? '8rem 0 0 0' : undefined} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AffiliationsContainer;
