import { Divider, Icon } from 'dodoc-design-system';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch } from '_common/hooks';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import { clearFilterIdentity } from '../../FilterSlice';
import Filters, { FiltersProps } from '../../Filters';

import styles from './FilterDrawer.module.scss';

const FilterDrawer = ({ identity, ...props }: FiltersProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearFilterIdentity({ identity, noFeedback: true }));
    };
  }, []);

  const toggleOpenState = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Divider />
      <div
        className={styles.root}
        onClick={toggleOpenState}
        data-testid={`${identity}-filterDrawer-toggle`}
      >
        <Icon icon={open ? 'AngleDownGrey' : 'AngleRightGrey'} size={24} />
        <div className={styles.title}>
          {open ? (
            <FormattedMessage id="editor.sidebar.review.filter.hide" />
          ) : (
            <FormattedMessage id="editor.sidebar.review.filter.show" />
          )}
        </div>
      </div>
      {open && (
        <div className={styles.filterList}>
          <IntlErrorBoundary margin="auto">
            <Filters identity={identity} testId={`${identity}-filterDrawer`} {...props} />
          </IntlErrorBoundary>
        </div>
      )}
      <Divider />
    </>
  );
};

export default FilterDrawer;
