import { RemoveContentOperation } from 'Editor/services/Edition_v2/Operations';
import { BaseStylesManipulator } from './BaseStyles';
import { NodeUtils } from 'Editor/services/DataManager';
import { PathUtils } from 'Editor/services/_Common/Selection';

type Paths = {
  start: Editor.Selection.Path;
  end: Editor.Selection.Path;
};

export class ApplyStlesManipulator
  extends BaseStylesManipulator
  implements Editor.Edition.IApplyStyleManipulator
{
  private applyStylesToElement<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
    style: T,
    value: Editor.Edition.StylesMap[T],
  ): Paths {
    let baseData = baseModel.selectedData();

    if (!baseData) {
      return { start: startPath, end: endPath };
    }

    // get closest non-splitable parent
    const closestParent = NodeUtils.closestOfTypeByPath(
      baseData,
      PathUtils.getCommonAncestorPath(startPath, endPath),
      [...NodeUtils.BLOCK_SPLITABLE_TYPES, ...NodeUtils.INLINE_WRAP_TYPES],
    );

    if (!closestParent) {
      return { start: startPath, end: endPath };
    }

    const subStartPath: Editor.Selection.Path = startPath.slice(closestParent.path.length);
    const subEndPath: Editor.Selection.Path = endPath.slice(closestParent.path.length);

    // check closest format within parent
    const closestFormat = NodeUtils.closestOfTypeByPath(
      closestParent.data,
      PathUtils.getCommonAncestorPath(subStartPath, subEndPath),
      'format',
    );

    if (closestFormat) {
      // check if can update existing format
      const formatSubStart = subStartPath.slice(closestFormat.path.length);
      const formatSubEnd = subEndPath.slice(closestFormat.path.length);

      if (
        NodeUtils.isPathAtContentStart(closestFormat.data, formatSubStart) &&
        NodeUtils.isPathAtContentEnd(closestFormat.data, formatSubEnd)
      ) {
        // update existing format
        this.updateStyleOperation(
          baseModel,
          [...closestParent.path, ...closestFormat.path],
          style,
          value,
        );

        return { start: startPath, end: endPath };
      }
    }

    // insert new format
    const clonedNodes = NodeUtils.cloneData(closestParent.data, subStartPath, subEndPath);

    if (clonedNodes.length) {
      const formatData = this.buildFormatData(clonedNodes, style, value);

      if (formatData) {
        let removeOp = new RemoveContentOperation(baseModel, startPath, endPath, {
          mergeText: false,
        });
        removeOp.apply();

        let insertPath: Editor.Selection.Path | undefined = removeOp.getAdjustedPath();

        baseData = baseModel.selectedData();

        if (baseData) {
          if (insertPath) {
            let resultPath = this.insertElement(baseModel, formatData, insertPath);

            if (resultPath) {
              return { start: insertPath, end: resultPath };
            }
          }
        }
      }
    }

    return { start: startPath, end: endPath };
  }

  applyStyle<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    range: Editor.Selection.JsonRange,
    style: T,
    value: Editor.Edition.StylesMap[T],
  ): Editor.Selection.JsonRange {
    if (!this.editionContext.DataManager) {
      return range;
    }

    let baseData = baseModel.selectedData();

    if (!baseData) {
      return range;
    }

    let updatedPaths: Paths | undefined;

    const stylableData = this.getSylableDataInfo(range);

    let startPath: Editor.Selection.Path | undefined;

    let endPathPosition = NodeUtils.getNavigationPositionFromPath(
      baseModel.navigationData,
      range.end.p,
    );

    for (let i = stylableData.length - 1; i >= 0; i--) {
      const stylableInfo = stylableData[i];

      const closestWithStyle = this.getClosestWithStyle(baseData, stylableInfo.childPath, style);
      const closestWithStyleValue = this.getClosestWithStyle(
        baseData,
        stylableInfo.childPath,
        style,
        value,
      );

      if (!closestWithStyleValue || closestWithStyle?.data._id !== closestWithStyleValue.data._id) {
        let pathAfterChild = [...stylableInfo.childPath];
        let offset = Number(stylableInfo.childPath[stylableInfo.childPath.length - 1]);
        if (!isNaN(offset)) {
          pathAfterChild[pathAfterChild.length - 1] = offset + 1;
        }

        let initialPaths: Partial<Paths> = {};

        if (
          PathUtils.isChildPath(stylableInfo.childPath, range.start.p) &&
          PathUtils.isChildPath(stylableInfo.childPath, range.end.p)
        ) {
          // start and end within same element
          if (NodeUtils.isNonStylableInlindeData(stylableInfo.childData)) {
            initialPaths.start = stylableInfo.childPath;
            initialPaths.end = pathAfterChild;
          } else {
            initialPaths.start = range.start.p;
            initialPaths.end = range.end.p;
          }
        } else if (PathUtils.isChildPath(stylableInfo.childPath, range.start.p)) {
          // start within element

          if (NodeUtils.isNonStylableInlindeData(stylableInfo.childData)) {
            initialPaths.start = stylableInfo.childPath;
            initialPaths.end = pathAfterChild;
          } else {
            initialPaths.start = range.start.p;
            initialPaths.end = pathAfterChild;
          }
        } else if (PathUtils.isChildPath(stylableInfo.childPath, range.end.p)) {
          // end within element
          if (NodeUtils.isNonStylableInlindeData(stylableInfo.childData)) {
            initialPaths.start = stylableInfo.childPath;
            initialPaths.end = pathAfterChild;
          } else {
            initialPaths.start = stylableInfo.childPath;
            initialPaths.end = range.end.p;
          }
        } else {
          initialPaths.start = stylableInfo.childPath;
          initialPaths.end = pathAfterChild;
        }

        if (initialPaths.start && initialPaths.end) {
          updatedPaths = this.applyStylesToElement(
            baseModel,
            initialPaths.start,
            initialPaths.end,
            style,
            value,
          );
        }
      }

      if (updatedPaths) {
        if (i === 0) {
          startPath = updatedPaths.start;
        }
      }
    }

    const jRange = range.cloneRange();

    if (startPath) {
      jRange.setStart({ b: baseModel.id, p: startPath });
    }

    // adjust range
    let updatedEndPath = NodeUtils.getPathFromNavigationPosition(
      baseModel.navigationData,
      endPathPosition,
    );

    if (updatedEndPath) {
      jRange.setEnd({ b: baseModel.id, p: updatedEndPath });
    }

    return jRange;
  }
}
