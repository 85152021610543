import { Mixin } from 'mixwith';
import { ElementNodeBuilder } from 'Editor/services/Model';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      parseEquation(representation) {
        const builder = new ElementNodeBuilder();
        builder.addElementType('equation');
        if (representation.attributes.parent_id) {
          builder.addParentId(representation.attributes.parent_id.nodeValue);
        }
        if (representation.attributes.id) {
          builder.addId(representation.attributes.id.nodeValue);
        }
        if (representation.equation) {
          builder.addProperty('equation', representation.equation);
        }
        return builder.getNode();
      }
    },
);
