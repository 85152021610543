import { IntlErrorBoundary } from '_common/components';

import Toolbar from './Toolbar';

import styles from './Toolbar.module.scss';

const ToolbarBoundary = () => {
  return (
    <div className={styles.root}>
      <IntlErrorBoundary size="small" margin="0 0 0 1rem">
        <Toolbar />
      </IntlErrorBoundary>
    </div>
  );
};

export default ToolbarBoundary;
