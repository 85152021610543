import { ReactNode } from 'react';

import { OnboardingEnvironments } from 'App/redux/onboardingSlice';
import AppOverlay from '../AppOverlay/AppOverlay';
import PDFOverlay from '../PDFOverlay/PDFOverlay';

import styles from './OnboardingOverlay.module.scss';

type OnboardingOverlayProps = {
  environment: OnboardingEnvironments;
  children: ReactNode;
};

const OnboardingOverlay = ({ environment, children }: OnboardingOverlayProps) => {
  switch (environment) {
    case 'dopdf':
      return (
        <PDFOverlay>
          <div className={styles.root}>{children}</div>
        </PDFOverlay>
      );
    default:
      return (
        <AppOverlay>
          <div className={styles.root}>{children}</div>
        </AppOverlay>
      );
  }
};

export default OnboardingOverlay;
