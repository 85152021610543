import { useComments } from 'Presentation/SyncStore';

const useCommentsAuthors = () => {
  const comments = useComments();
  const users: string | any[] = [];
  if (comments?.list) {
    const addUser = (comment: Presentation.Data.Comment) => {
      for (let i = 0; i < users.length; i++) {
        const u = users[i];
        if (u.id === comment.authorId || u.id === comment.authorName) {
          return;
        }
      }
      const user: { id: string; imported?: boolean } = { id: '' };
      if (comment?.authorName) {
        //Imported user
        user.id = comment?.authorName;
        user.imported = true;
      } else if (comment?.authorId) {
        user.id = comment.authorId;
      } else {
        return;
      }
      users.push(user);
    };

    for (let i = 0; i < comments?.list?.length; i++) {
      let comment = comments?.data[comments?.list[i]];
      addUser(comment);
    }
    return users;
  }
};

export default useCommentsAuthors;
