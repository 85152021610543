import { usePresentationManager } from '../PresentationManager';
import { useSyncExternalStore } from 'react';
import { RealtimeSyncStore, SnapshotTypes } from './RealtimeSyncStore';
import { useParams } from 'react-router';

let store: RealtimeSyncStore;

export function useObjectSync<T extends Presentation.Model.Name>(type: T, id?: string) {
  const manager = usePresentationManager();
  if (!store) {
    const data = manager.getData() as Presentation.API;
    store = new RealtimeSyncStore(data);
  }
  const objectStore = store.getStore(type, id);
  return useSyncExternalStore(objectStore.subscribe, objectStore.getSnapshot) as
    | SnapshotTypes[T]
    | undefined;
}

export function useStructureSync() {
  const { id } = useParams<{ id: string }>();
  return useObjectSync('STRUCTURE', `PS${id}`);
}

export function useSlideSync(id: string) {
  return useObjectSync('SLIDE', id);
}

export function useSlideLayout(id?: string) {
  return useObjectSync('SLIDE_LAYOUT', id);
}

export function useSlideMaster(id?: string) {
  return useObjectSync('SLIDE_MASTER', id);
}

export function useTheme(themeId?: string) {
  return useObjectSync('THEME', themeId);
}

export function useComments() {
  const { id } = useParams<{ id: string }>();
  return useObjectSync('COMMENTS', `PC${id}`) as
    | Common.ListDataComplex<Presentation.Data.Comment>
    | undefined;
}

export function useTasks() {
  const { id } = useParams<{ id: string }>();
  return useObjectSync('TASKS', `PT${id}`) as
    | Common.ListDataComplex<Presentation.Data.Task>
    | undefined;
}

export function useSlideComments(slideId: string) {
  const { id } = useParams<{ id: string }>();
  const manager = usePresentationManager();
  if (!store) {
    const data = manager.getData() as Presentation.API;
    store = new RealtimeSyncStore(data);
  }
  const objectStore = store.getFragmentStore('SLIDE_COMMENTS', `PC${id}`, slideId);
  return useSyncExternalStore(objectStore.subscribe, objectStore.getSnapshot) as
    | {
        list: string[];
        data: Record<string, Presentation.Data.Comment>;
      }
    | undefined;
}

export function useSlideTasks(slideId: string) {
  const { id } = useParams<{ id: string }>();
  const manager = usePresentationManager();
  if (!store) {
    const data = manager.getData() as Presentation.API;
    store = new RealtimeSyncStore(data);
  }
  const objectStore = store.getFragmentStore('SLIDE_TASKS', `PT${id}`, slideId);
  return useSyncExternalStore(objectStore.subscribe, objectStore.getSnapshot) as
    | {
        list: string[];
        data: Record<string, Presentation.Data.Task>;
      }
    | undefined;
}

export function useUsersSync() {
  const manager = usePresentationManager();
  if (!store) {
    const data = manager.getData() as Presentation.API;
    store = new RealtimeSyncStore(data);
  }
  const objectStore = store.getStore('USERS', 'USERS');
  return useSyncExternalStore(objectStore.subscribe, objectStore.getSnapshot) as string[];
}

export function useNotesSlide(id?: string) {
  return useObjectSync('NOTES_SLIDE', id);
}

export function useNotesMaster(id?: string) {
  return useObjectSync('NOTES_MASTER', id);
}
