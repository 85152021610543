import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';

import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { closeModal } from '_common/modals/ModalsSlice';
import { useEditProfileMutation } from 'Settings/pages/UserSettingsPage/UserSettingsApi';

import TimezoneSelect from './TimezoneSelect';

import styles from './EditTimezoneModal.module.scss';

const MODAL = 'EditTimezoneModal';

const EditTimezoneModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const { data: currentUser } = useGetCurrentUserQuery();
  const [editProfile] = useEditProfileMutation();

  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>();

  useEffect(() => {
    if (isOpen && currentUser) {
      setSelectedTimezone(currentUser.profile.timezone);
    }
  }, [isOpen, currentUser]);

  const handleTimezoneSubmit = () => {
    editProfile({ timezone: selectedTimezone }).then(() => {
      notify({
        type: 'success',
        title: 'TIMEZONE_CHANGED',
        message: 'THE_TIMEZONE_WAS_SUCCESSFULLY_CHANGED_TO',
        messageValues: { newTimezone: selectedTimezone ?? '' },
      });
    });

    close();
  };

  const close = () => {
    dispatch(closeModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} width="75rem" onClose={close} testId="change-timezone">
      <Modal.Header onClose={close}>
        <FormattedMessage id="settings.timezone.header" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <div className={styles.timezoneLabel}>
          <FormattedMessage id="settings.user.timezone" />
        </div>
        <div className={styles.timezone}>
          <TimezoneSelect
            showOptionTooltip
            size="large"
            width="100%"
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            testId="timezone"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="edit-timezone-modal-close-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleTimezoneSubmit}
          testId="edit-timezone-modal-submit-button"
        >
          <FormattedMessage id="UPDATE_TIMEZONE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTimezoneModal;
