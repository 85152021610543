import ReactDOM from 'react-dom';
import { Checkbox, Icon, Popover, usePopper } from 'dodoc-design-system';

import { usePermissions } from '_common/components/Permissions/PermissionsContext';
import PermissionPopover from './PermissionPopover/PermissionPopover';

import type { CheckboxProps } from 'dodoc-design-system/build/types/Components/Checkbox/Checkbox';

type PermissionColumnProps = {
  objectId: ObjectId;
  permission: Permission.Collaborator['permissions'][keyof Permission.Collaborator['permissions']];
  collaborator: Permission.Collaborator;
  recursive: boolean;
  onChange: (
    code: Permission.CollaboratorPermission,
    collaboratorId: Permission.Collaborator['collaboratorId'],
    collaboratorType: Permission.Collaborator['collaboratorType'],
  ) => void;
};

const PermissionColumn = ({
  permission,
  objectId,
  collaborator,
  recursive,
  onChange,
}: PermissionColumnProps) => {
  const permissionPopover = usePopper({
    placement: 'bottom-start',
    closeOnReferenceHidden: true,
  });

  const { canEditPermissions, canEditPermission } = usePermissions();

  const { collaboratorId, collaboratorType } = collaborator;

  const handleChange: CheckboxProps['onChange'] = () => {
    if (!permission.locked) {
      onChange(permission.code, collaboratorId, collaboratorType);
    } else {
      if (permissionPopover.isOpen) {
        permissionPopover.close();
      } else {
        permissionPopover.open();
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {canEditPermissions ? (
        <Checkbox
          checked={
            permission.locked ? 'indeterminate' : permission.active ? 'checked' : 'unchecked'
          }
          disabled={
            permission.disabled ||
            (!permission.locked &&
              !canEditPermission(collaborator.collaboratorId, permission.active))
          }
          onChange={handleChange}
          testId={`collaborator-${collaboratorId}-${permission.code}-checkbox`}
          {...permissionPopover.referenceProps}
        />
      ) : (
        <Icon
          icon={permission.active || permission.locked ? 'Check' : 'CheckEmpty'}
          size={32}
          testId={`collaborator-${collaboratorId}-${permission.code}-${
            permission.active || permission.locked ? 'checked' : 'unchecked'
          }`}
        />
      )}

      {ReactDOM.createPortal(
        <Popover
          {...permissionPopover.popperProps}
          testId={`collaborator-${collaboratorId}-${permission.code}-popper`}
        >
          <PermissionPopover
            objectId={objectId}
            permission={permission.code}
            collaboratorType={collaboratorType}
            collaboratorId={collaboratorId}
            recursive={recursive}
          />
        </Popover>,
        document.getElementById('ModalPortal') ?? document.body,
      )}
    </div>
  );
};

export default PermissionColumn;
