import { FormattedMessage } from 'react-intl';

import styles from './WarningLabel.module.scss';

type WarningLabelProps = {
  label: TranslationId;
  margin?: React.CSSProperties['margin'];
  onClick: React.MouseEventHandler;
};

const WarningLabel = ({ label, margin, onClick }: WarningLabelProps) => {
  return (
    <div className={styles.root} style={{ margin }} onClick={onClick}>
      <FormattedMessage id={label} />
    </div>
  );
};

export default WarningLabel;
