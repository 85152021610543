import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProgressBar } from 'dodoc-design-system';

import { useSelector } from '_common/hooks/redux';

import AppOverlay from '_common/components/AppOverlay/AppOverlay';
import styles from './PageLoader.module.scss';

const PageLoader = () => {
  const appLoading = useSelector((state) => state.app.loading);

  if (appLoading.isOpen) {
    return (
      <AppOverlay>
        <div className={styles.backgroundContainer}>
          <div className={styles.mainContainer}>
            <ProgressBar size="medium" color="dark" testId="page-loader-progressBar" />
            <div className={styles.textContainer}>
              {appLoading.message && (
                <FormattedMessage
                  id={appLoading.message}
                  values={{
                    ...appLoading.messageValues,
                    p: (chunks: ReactNode) => <div>{chunks}</div>,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </AppOverlay>
    );
  } else {
    return null;
  }
};

export default PageLoader;
