import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import CommentCardContent, { CommentCardProps } from './CommentCard';

const CommentCard = (props: CommentCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.sidebar, width: props.sidebar ? '100%' : '43rem' }}
    >
      <CommentCardContent {...props} />
    </IntlErrorBoundary>
  );
};

export default CommentCard;
