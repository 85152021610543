import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Tab } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import styles from './Tabs.module.scss';

export type TabType = 'general' | 'metadata';

type TabsProps = {
  selectedTab: TabType;
  onChange: (newTab: TabType) => void;
};

const Tabs = ({ selectedTab, onChange }: TabsProps) => {
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];
  const object = useSelector((state) => state.app.data[elementId]);

  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const hasMetadata = object?.type === 'document' && Object.keys(metadata).length > 0;

  useEffect(() => {
    if (!hasMetadata) {
      onChange('general');
    }
  }, [elementId]);

  const handleClickTab = (id: TabType) => {
    onChange(id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.tabsWrapper}>
        <Tab
          fullWidth
          selected={selectedTab === 'general'}
          onClick={() => handleClickTab('general')}
          testId="general-tab"
        >
          <FormattedMessage id="settings.tabs.general" />
        </Tab>
        {hasMetadata && (
          <Tab
            fullWidth
            selected={selectedTab === 'metadata'}
            onClick={() => handleClickTab('metadata')}
            testId="metadata-tab"
          >
            <FormattedMessage id="METADATA" />
          </Tab>
        )}
      </div>
      <Divider margin="0" />
    </div>
  );
};

export default Tabs;
