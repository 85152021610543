import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, RadioButton } from 'dodoc-design-system';

import { LANGUAGES } from 'Intl/Intl';
import { useDispatch, useSelector } from '_common/hooks';

import { closeModal } from '_common/modals/ModalsSlice';
import { useChangeLanguageMutation } from 'Settings/pages/UserSettingsPage/UserSettingsApi';

const MODAL = 'LanguageSettingModal';

const LanguageSettingModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const locale = useSelector((state) => state.intl.locale);
  const typedLocale = useMemo<Language | undefined>(() => {
    const parsedLocale = locale in LANGUAGES ? locale : locale.split('-')[0];
    return LANGUAGES[parsedLocale as Language['code']] ?? undefined;
  }, [locale]);

  const [changeLanguage] = useChangeLanguageMutation();

  const [selectedLanguage, setSelectedLanguage] = useState<Language>();

  useEffect(() => {
    if (isOpen) {
      setSelectedLanguage(typedLocale);
    }
  }, [isOpen, typedLocale]);

  const handleChangeLanguage = () => {
    if (selectedLanguage && typedLocale?.code !== selectedLanguage.code) {
      changeLanguage({ language: selectedLanguage.code });
    }
    close();
  };

  const close = () => {
    dispatch(closeModal(MODAL));
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="change-language">
      <Modal.Header onClose={close}>
        <FormattedMessage id="settings.languages.header" />
      </Modal.Header>
      <Modal.Body>
        {Object.values(LANGUAGES).map(({ code, name }) => {
          return (
            <RadioButton
              key={code}
              checked={selectedLanguage?.code === code}
              onChange={() => setSelectedLanguage(LANGUAGES[code])}
              size="small"
              testId={`${code}-language-radio-button`}
            >
              {name}
            </RadioButton>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="language-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleChangeLanguage}
          testId="language-modal-submit-button"
        >
          <FormattedMessage id="UPDATE_LANGUAGE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LanguageSettingModal;
