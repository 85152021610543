import { JsonRange } from 'Editor/services/_Common/Selection';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { InsertInlineCommand } from '../GenericCommands';

export class InsertHyperlinkCommand extends InsertInlineCommand {
  links: string[];
  url: string;
  showTextToDisplay: boolean;
  textToDisplay: string;

  constructor(
    context: Editor.Edition.Context,
    links: string[],
    url: string,
    showTextToDisplay: boolean = false,
    textToDisplay: string = '',
  ) {
    super(context);
    this.links = links;
    this.url = this.validateUrl(url);
    this.showTextToDisplay = showTextToDisplay;
    this.textToDisplay = textToDisplay;
  }

  async handleExec(): Promise<void> {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid Context');
    }

    const range = this.actionContext.range;

    if (
      JsonRange.isSelectionEditable(this.context.DataManager, range) &&
      NodeUtils.closestOfTypeByPath(this.actionContext.baseData, range.start.p, 'p')
    ) {
      let linkBuilder = new NodeDataBuilder('link');
      linkBuilder.addProperty('href', this.url);

      if (this.showTextToDisplay) {
        const textBuilder = new NodeDataBuilder('text');
        textBuilder.setContent(this.textToDisplay);

        let text = textBuilder.build();
        if (text) {
          linkBuilder.addChildData(text);
        }
      }

      if (this.links.length) {
        let childInfo = this.actionContext.baseModel.getChildInfoById(this.links[0]);

        if (childInfo) {
          let startPath: Editor.Selection.Path = [...childInfo.path];
          let endPath: Editor.Selection.Path = [...childInfo.path];
          let offset = Number(endPath[endPath.length - 1]);
          if (!isNaN(offset)) {
            endPath[endPath.length - 1] = offset + 1;
          }

          range.updateRangePositions(
            {
              b: range.start.b,
              p: startPath,
            },
            {
              b: range.start.b,
              p: endPath,
            },
          );
        }
      }

      const linkData = linkBuilder.build();
      if (linkData) {
        await super.handleExec(linkData);
      }
    }
  }

  validateUrl(url: string): string {
    let link: string;

    if (url[0] === '/') {
      link = url;
    } else if (url.indexOf('http') < 0) {
      link = `https://${url}`;
    } else {
      link = url;
    }

    return link;
  }
}
