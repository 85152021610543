import { BaseOperation } from '../BaseOperation';

export class UpdateColumnWidthsOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  columnWidths: Editor.Edition.ColumnWidths;
  pageWidth: number;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    columnWidths: Editor.Edition.ColumnWidths,
    pageWidth: number,
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.columnWidths = columnWidths;
    this.pageWidth = pageWidth;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const tableInfo = this.model.getChildInfoById(this.tableId);
    const table: Editor.Data.Node.Data = tableInfo.data;
    const tableBody = table.childNodes?.[0];

    const rowsPath = [...tableInfo.path, 'childNodes', 0, 'childNodes'];
    const rowsData = tableBody?.childNodes;

    let maxColumns: number = 0;

    const indexes = Object.keys(this.columnWidths);

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          if (row.childNodes.length > maxColumns) {
            maxColumns = row.childNodes.length;
          }

          for (let c = 0; c < indexes.length; c++) {
            const cellIndex = +indexes[c];
            const cell = row.childNodes[cellIndex];

            if (cell) {
              const propPath = [
                ...rowsPath,
                r,
                'childNodes',
                cellIndex,
                this.model.KEYS.PROPERTIES,
                'w',
              ];

              let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;

              switch (this.columnWidths[cellIndex].current.t) {
                case 'abs':
                case 'pct': {
                  newWidth = {
                    t: this.columnWidths[cellIndex].current.t,
                    v: this.columnWidths[cellIndex].current.v,
                  };
                  break;
                }
                case 'nil':
                case 'auto': {
                  newWidth = {
                    t: 'auto',
                    v: 0,
                  };
                  break;
                }
              }

              const op = this.getObjectOperationforPathValue(
                cell.properties?.w,
                newWidth,
                propPath,
              );
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }

    const tableWidth = table.properties?.w;
    // update table width
    if (
      this.columnWidths[maxColumns - 1] &&
      indexes.length === 1 &&
      tableWidth &&
      (tableWidth.t === 'abs' || tableWidth.t === 'pct')
    ) {
      let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;
      if (tableWidth?.t === 'abs') {
        newWidth = {
          t: 'abs',
          v: +(tableWidth.v + this.columnWidths[maxColumns - 1].delta).toFixed(3),
        };
      } else if (tableWidth?.t === 'pct') {
        if (this.model.id === table.id) {
          const oldAbsWidth = this.pageWidth * tableWidth.v;
          const newAbsWidth = oldAbsWidth + this.columnWidths[maxColumns - 1].delta;

          newWidth = {
            t: 'pct',
            v: +((newAbsWidth * tableWidth.v) / oldAbsWidth).toFixed(3),
          };
        } else {
          newWidth = {
            t: 'auto',
            v: 0,
          };
        }
      }

      const op = this.getObjectOperationforPathValue(tableWidth, newWidth, [
        ...tableInfo.path,
        this.model.KEYS.PROPERTIES,
        'w',
      ]);
      if (op) {
        this.ops.push(op);
      }
    }

    const op = this.getObjectOperationforPathValue(table.properties?.ar, false, [
      ...tableInfo.path,
      this.model.KEYS.PROPERTIES,
      'ar',
    ]);
    if (op) {
      this.ops.push(op);
    }

    return this;
  }
}
