/* eslint-disable class-methods-use-this */
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class TableOfContents extends RealtimeObject<{
  _id: string;
  cached: any;
  stylesMap: any;
  toc: {
    list: string[];
    data: TableOfContents.TOCElementDataById;
  };
}> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'tocs');
  }

  handleLoad(): void {}

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit('UPDATED', this.get(), ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  get list() {
    return this.get(['toc', 'list']) || [];
  }

  get tocElements() {
    return this.get(['toc', 'data']) || {};
  }

  async getDocumentMainTitle() {
    if (!this.loaded) {
      return undefined;
    }
    const list = this.list;
    const elements = this.tocElements;
    for (let index = 0; index < list.length; index++) {
      const nodeId = list[index];
      const data = elements[nodeId];
      if (data && data.level === -1) {
        return data;
      }
    }
  }
}
