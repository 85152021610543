/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { v4 } from 'uuid';
import { PDFPageProxy, RenderTask } from 'pdfjs-dist';
import { TextItem } from 'pdfjs-dist/types/src/display/api';
import { DataObject } from '_common/services/Realtime';

export class PDFPage extends DataObject<
  {
    _id: string;
  },
  PDF.Page.Events
> {
  protected pdfPage: PDFPageProxy;
  protected textContent?: PDF.Page.PDFPageContent;
  pageTextInfo: PDF.Page.TextInfo | null = null;
  findHighlights: PDF.Find.FindHitType[] | null = null;

  constructor(id: string, page: PDFPageProxy) {
    super(id);
    this.data = {
      _id: id,
    };
    this.pdfPage = page;
  }

  async fetch(): Promise<any> {
    // this.emit('UPDATED', this.data);
    return this;
  }

  getNumPage(): number {
    return this.pdfPage.pageNumber;
  }

  render(...params: Parameters<PDFPageProxy['render']>): RenderTask {
    return this.pdfPage.render(...params);
  }

  getViewport(...params: Parameters<PDFPageProxy['getViewport']>) {
    return this.pdfPage.getViewport(...params);
  }

  getOperatorList(...params: Parameters<PDFPageProxy['getOperatorList']>) {
    return this.pdfPage.getOperatorList(...params);
  }

  getAnnotations(...params: Parameters<PDFPageProxy['getAnnotations']>) {
    return this.pdfPage.getAnnotations(...params);
  }

  async getTextContent() {
    if (!this.textContent) {
      const content = await this.pdfPage.getTextContent();
      let items = (content.items as TextItem[]).map((item) => {
        return {
          ...item,
          id: v4(),
        };
      });
      this.textContent = {
        ...content,
        items,
      };
    }
    return this.textContent as PDF.Page.PDFPageContent;
  }

  accept(visitor: PDF.Page.IPageVisitor) {
    return visitor.visit(this);
  }

  storeTextInformations(
    pageContents: PDF.Page.TextInfo['pageContents'],
    pageDiffs: PDF.Page.TextInfo['pageDiffs'],
    hasDiacritics: PDF.Page.TextInfo['hasDiacritics'],
  ) {
    this.pageTextInfo = {
      pageContents,
      pageDiffs,
      hasDiacritics,
    };
  }

  setFindHits(hits: PDF.Find.FindHitType[] | null) {
    this.findHighlights = hits;
    this.emit('UPDATE_HIGHLIGHTS', hits);
  }

  dispose(): void {
    super.dispose();
  }
}
