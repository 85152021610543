import authority from '_common/services/api/authority';
import { paths } from '_types/authority';
import authApi from '_common/services/api/authority';
import { publicProfilesApi } from '_common/services/api/publicProfilesApi';

const avatarApi = authority.injectEndpoints({
  endpoints: (builder) => ({
    getAvatar: builder.query<string, UserId>({
      query: (userId) => ({
        url: `/user/profile/get/avatar/${userId}`,
        errorsExpected: [404],
        config: {
          responseType: 'blob',
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'Avatar', id: arg }],
      transformResponse: (avatar: Blob) => {
        return URL.createObjectURL(avatar);
      },
      transformErrorResponse: () => {
        return null;
      },
      onQueryStarted: async (userId, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            publicProfilesApi.util.updateQueryData('getPublicProfile', userId, (draft) => {
              if (draft) {
                draft.has_avatar = false;
              }
            }),
          );
        }
      },
    }),
    uploadAvatar: builder.mutation<
      paths['/api/authority/user/profile/edit/avatar']['post']['responses']['200'],
      {
        avatar: Blob;
        userId: UserId;
        onUploadProgress: (values: Pick<ProgressEvent, 'loaded' | 'total'>) => void;
      }
    >({
      query: ({ avatar, userId, onUploadProgress }) => {
        const formData = new FormData();
        formData.append('avatar', avatar, 'avatar.png');

        const config = {
          onUploadProgress: (progress: { loaded: number; total: number }) => {
            onUploadProgress(progress);
          },
        };

        return {
          url: `/user/profile/edit/avatar`,
          method: 'POST',
          config,
          body: formData,
        };
      },
      invalidatesTags: () => ['Avatar'],
      onQueryStarted: async ({ userId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            authApi.util.invalidateTags([
              {
                type: 'User',
                id: 'Current',
              },
              {
                type: 'PublicProfile',
                id: userId,
              },
            ]),
          );
        } catch (e) {}
      },
    }),
  }),
});

export const { useGetAvatarQuery, useUploadAvatarMutation } = avatarApi;
