import { ChangeEventHandler, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Select, TextArea } from 'dodoc-design-system';

import { useDebounce, useEffectOnUpdate } from '_common/hooks';
import { paths } from '_types/api';

import styles from './AffiliationField.module.scss';

type AffiliationFieldProps = {
  field: Affiliation.Field;
  initialValue: Author.Affiliation.Value;
  updateAffiliation: (
    params: paths['/api/object/document/{document_id}/author/update']['post']['requestBody']['content']['multipart/form-data'],
  ) => void;
  options?: { label: string; value: string }[];
};

const AffiliationField = ({
  field,
  initialValue,
  updateAffiliation,
  options,
}: AffiliationFieldProps) => {
  const intl = useIntl();

  const [newValue, setNewValue] = useState<AffiliationFieldProps['initialValue']>(initialValue);
  const debouncedNewValue = useDebounce(newValue ?? '', 500);

  useEffectOnUpdate(() => {
    updateAffiliation({ path: field, value: debouncedNewValue });
  }, [debouncedNewValue]);

  const handleOnInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setNewValue(e.target.value);
  };

  const renderFields = () => {
    switch (field) {
      case 'address1':
      case 'address2':
        return (
          <Input
            value={newValue}
            onChange={handleOnInputChange}
            placeholder={intl.formatMessage({ id: 'INSERT_ADDRESS' })}
            data-testid={field === 'address1' ? 'input_address1line' : 'input_address2line'}
            size="large"
            testId={`author-affiliation-${field}`}
          />
        );
      case 'extra':
        return (
          <TextArea
            value={newValue}
            onChange={handleOnInputChange}
            placeholder={intl.formatMessage({ id: 'WRITE_ADDITIONAL_INFORMATION' })}
            size="large"
            testId={`author-affiliation-${field}-textarea`}
          />
        );
      default: {
        return (
          <div title={newValue}>
            <Select
              creatable
              searchable
              placeholder={intl.formatMessage({ id: `SEARCH_FOR_${field.toUpperCase()}` })}
              value={newValue ? { value: newValue, label: newValue } : null}
              options={options ?? []}
              onChange={(newValue) => {
                setNewValue(newValue?.value || '');
              }}
              noOptionsMessage={() => {
                return intl.formatMessage({ id: 'NO_RESULTS_FOUND' });
              }}
              width="40rem"
              testId={`author-affiliation-${field}`}
            />
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className={styles.field}>
        <FormattedMessage id={field.toUpperCase()} />
      </div>
      {renderFields()}
    </>
  );
};

export default AffiliationField;
