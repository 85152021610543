import { useGetRoleQuery } from 'Settings/pages/TenantSettingsPage/Roles/RolesApi';

type RoleLabelProps = {
  roleId?: ObjectId;
};

const RoleLabel = ({ roleId = '' }: RoleLabelProps) => {
  const { data: role } = useGetRoleQuery(roleId, { skip: !roleId });

  return <>{role?.name}</>;
};

export default RoleLabel;
