import { useMemo } from 'react';
import { Select, Icon } from 'dodoc-design-system';
import { CITATION_PRIORITY } from 'Editor/services/consts';
import { useIntl } from 'react-intl';

import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import type {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';

type OptionIconProps = {
  icon: IconTypes['24'];
};

const OptionIcon = ({ icon }: OptionIconProps) => <Icon size={24} icon={icon} />;

type PrioritySelectProps = Pick<SelectProps, 'disabled' | 'size' | 'width' | 'testId'> & {
  value: string;
  onChange: (e: SelectOption) => void;
};

const PrioritySelect = ({
  value,
  onChange,
  disabled,
  size,
  width = '100%',
  testId,
}: PrioritySelectProps) => {
  const intl = useIntl();

  const translationForValue = (value: string) => {
    switch (value) {
      case CITATION_PRIORITY.HIGH:
        return intl.formatMessage({
          id: 'editor.sidebar.review.filter.priority.high',
        });
      case CITATION_PRIORITY.MEDIUM:
        return intl.formatMessage({
          id: 'editor.sidebar.review.filter.priority.medium',
        });
      case CITATION_PRIORITY.LOW:
        return intl.formatMessage({
          id: 'editor.sidebar.review.filter.priority.low',
        });
      default:
        return '';
    }
  };

  const options = useMemo(() => {
    return [
      {
        label: translationForValue(CITATION_PRIORITY.HIGH),
        value: CITATION_PRIORITY.HIGH,
        avatar: <OptionIcon icon="High" />,
      },
      {
        label: translationForValue(CITATION_PRIORITY.MEDIUM),
        value: CITATION_PRIORITY.MEDIUM,
        avatar: <OptionIcon icon="Medium" />,
      },
      {
        label: translationForValue(CITATION_PRIORITY.LOW),
        value: CITATION_PRIORITY.LOW,
        avatar: <OptionIcon icon="Low" />,
      },
    ];
  }, []);

  return (
    <Select
      options={options}
      value={options.filter((option) => option.value === value)}
      onChange={onChange}
      width={width}
      disabled={disabled}
      size={size}
      clearable={false}
      testId={testId}
    />
  );
};

export default PrioritySelect;
