import { PresentationRange } from 'Presentation/services/SelectionManager/PresentationRange';
import { DOMUtils } from '_common/utils';

export type ObjectHighlightRect<T> = {
  object: T;
  rects: ShortRect[];
};

/**
 * Will calculate the rects to highlight zones of the slide based on provided anchor
 * @param params.anchor - The anchor which will provide the range for the highlight rectangles
 * @param params.negativeOffset - The negative offset position, if applicable
 * @returns The highlight rectangles
 */
export const getHighlightRects = ({
  anchor,
  negativeOffset,
}: {
  anchor: Presentation.Common.PresentationAnchor;
  negativeOffset?: Position;
}): ShortRect[] => {
  const location = anchor[2];
  //If anchor doesn't have location: rects are unobtainable, return empty object
  if (!location) {
    return [];
  }

  const range = PresentationRange.fromJsonLocation(location);
  //If can't obtain range from location: rects are unobtainable, return empty object
  if (!range) {
    return [];
  }

  const rects = DOMUtils.filterAndAdjustDOMRectList(range.getClientRects());
  return rects.map((rect) => {
    return {
      ...rect,
      top: rect.top - (negativeOffset?.top ?? 0),
      width: rect.width,
      height: rect.height,
      left: rect.left - (negativeOffset?.left ?? 0),
    };
  });
};

/**
 * Firefox getBoundingClientRect will return different values from Chrome.
 *  As an alternative we can use getBBox for SVG elements which will work well for both browsers.
 * @param shapeNode The shape node to get the bounding box from.
 * @param scale Apply a scale to the bounding box.
 * @returns If shapeNode is not an SVGGraphicsElement, return null. Otherwise, return the bounding box of the shape in the SVG coordinate system.
 */
export const getSvgBoundingBox = (
  shapeNode: HTMLElement | null,
  scale: number = 1,
): ShortRect | null => {
  if (!shapeNode || !(shapeNode instanceof SVGGraphicsElement)) {
    return null;
  }

  const { x, y, width, height } = shapeNode.getBBox();
  return { left: x * scale, top: y * scale, width: width * scale, height: height * scale };
};
