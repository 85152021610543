export class BlockWindow {
  private _start: number = 0;
  private _end: number = 0;

  constructor(start: number, end: number) {
    if (start > end) throw new RangeError('start must be lesser than or equal to end');

    this._start = start;
    this._end = end;
  }

  get start(): number {
    return this._start;
  }

  set start(value: number) {
    this._start = value;
  }

  get end(): number {
    return this._end;
  }

  set end(value: number) {
    this._end = value;
  }

  toString() {
    return `{ start: ${this.start} , end: ${this.end} }`;
  }

  clone() {
    return new BlockWindow(this.start, this.end);
  }

  size() {
    return this.end - this.start;
  }

  includes(value: number) {
    return this.start <= value && value <= this.end;
  }

  isBefore(blockWindow: BlockWindow) {
    return this.end < blockWindow.start;
  }

  isAfter(blockWindow: BlockWindow) {
    return this.start > blockWindow.end;
  }

  intersects(blockWindow: BlockWindow) {
    if (this.start > blockWindow.end || blockWindow.start > this.end) {
      return false;
    }
    return true;
  }

  contains(blockWindow: BlockWindow) {
    return this.start <= blockWindow.start && blockWindow.end <= this.end;
  }

  equals(blockWindow: BlockWindow) {
    return this.start === blockWindow.start && this.end === blockWindow.end;
  }

  intersection(blockWindow: BlockWindow): BlockWindow[] {
    if (!this.intersects(blockWindow)) {
      return [];
    }
    return [
      new BlockWindow(Math.max(this.start, blockWindow.start), Math.min(this.end, blockWindow.end)),
    ];
  }

  union(blockWindow: BlockWindow): BlockWindow[] {
    if (!this.intersects(blockWindow)) {
      return [this.clone(), blockWindow.clone()];
    }
    return [
      new BlockWindow(Math.min(this.start, blockWindow.start), Math.max(this.end, blockWindow.end)),
    ];
  }

  substract(blockWindow: BlockWindow): BlockWindow[] {
    if (!this.intersects(blockWindow)) {
      return [this.clone()];
    }

    const intersection = this.intersection(blockWindow);
    if (this.equals(intersection[0])) {
      return [];
    }

    if (this.start === intersection[0].start) {
      return [new BlockWindow(intersection[0].end + 1, this.end)];
    }

    if (this.end === intersection[0].end) {
      return [new BlockWindow(this.start, intersection[0].start - 1)];
    }

    return [
      new BlockWindow(this.start, intersection[0].start - 1),
      new BlockWindow(intersection[0].end + 1, this.end),
    ];
  }
}
