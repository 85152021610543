import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';
import { useParams } from 'react-router';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

const PublicLinkHeading = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id }: { id: ObjectId } = useParams();

  return (
    <SectionHeading
      title={intl.formatMessage({ id: 'settings.publicLink.publicLink' })}
      buttonRightProps={{
        size: 'medium',
        onClick: () => {
          dispatch(
            openAndUpdateModal({
              modal: 'PublicLinkModal',
              data: {
                objectId: id,
                mode: 'create',
              },
            }),
          );
        },
        variant: 'primary',
        children: intl.formatMessage({ id: 'settings.publicLink.newPublicLink' }),
        testId: 'public-link-header-new-button',
      }}
      testId="public-link-heading"
    />
  );
};

export default PublicLinkHeading;
