import { useEffect } from 'react';
import { Select } from 'dodoc-design-system';

import {
  HandlerProps,
  IMAGE_TYPES,
  MIMETYPE_BEHAVIOUR,
  SelectOption,
} from '../AdvancedFilterOptions';
import { useAdvancedFilterContext } from '../AdvancedFilterContext';

import styles from './Handlers.module.scss';

type MimeTypeHandlerProps = {
  testId?: string;
};

const MimeTypeHandler = ({ sequence, testId }: HandlerProps & MimeTypeHandlerProps) => {
  const { advancedFilter, setValue, setAuxValue, setQuery } = useAdvancedFilterContext();

  const { option, metadataOption, value, auxValue } = advancedFilter;
  const mergedOption = metadataOption ? `${option}.${metadataOption}` : option;

  useEffect(() => {
    if (!sequence.type || !value || (value === 'image' && !auxValue)) {
      setQuery(undefined);
      return;
    }

    const overrideField = sequence.options.find((option) => option.value === value)?.overrideField;
    if (value !== 'image') {
      setQuery(
        MIMETYPE_BEHAVIOUR({
          field: overrideField || mergedOption,
          value: value,
        }),
      );
    } else if (auxValue) {
      setQuery(
        MIMETYPE_BEHAVIOUR({
          field: overrideField || mergedOption,
          value: auxValue,
        }),
      );
    }
  }, [value, auxValue]);

  const handleChangeValue = (newSelectValue: SelectOption) => {
    setValue(newSelectValue.value);
    setAuxValue(newSelectValue.overrideField);
  };

  const handleChangeAuxValue = (newSelectValue: SelectOption) => {
    setAuxValue(newSelectValue.value);
  };

  const renderMimeTypeSequence = () => {
    // Only image has sequence
    if (value !== 'image') {
      return null;
    }

    return (
      <Select
        clearable={false}
        width="20rem"
        size="medium"
        options={IMAGE_TYPES}
        onChange={handleChangeAuxValue}
        value={IMAGE_TYPES.find((option) => option.value === auxValue) || null} //Force select UI cleanup by sending null
        testId={`${testId}-sequence-options`}
      />
    );
  };

  return (
    <div className={styles.handlerRoot} data-testid={testId}>
      <Select
        clearable={false}
        width="20rem"
        size="medium"
        options={sequence.options}
        onChange={handleChangeValue}
        value={sequence.options.find((option) => option.value === value) || null} //Force select UI cleanup by sending null
        testId={`${testId}-options`}
      />
      {renderMimeTypeSequence()}
    </div>
  );
};

export default MimeTypeHandler;
