import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RadioButton, Toggle, Tooltip } from 'dodoc-design-system';
import cx from 'classnames';

import { useEffectOnUpdate } from '_common/hooks';

import TabStopField, { TempTabStop } from '../TabStopField/TabStopField';

import styles from './CustomTabStop.module.scss';

export type TempCustomTabStop = {
  id: string;
  measure?: Editor.Tabulations.Measure;
  value?: Editor.Tabulations.CustomTabStop['value'];
  hasError?: boolean;
  type: Exclude<Editor.Tabulations.CustomTabStop['type'], 'h'>;
  leader: Editor.Tabulations.CustomTabStop['leader'];
};

type CustomTabStopProps = TempCustomTabStop & {
  onChange: (data: TempCustomTabStop) => void;
  onRemove: (id: string) => void;
  onCollapse: (data: TempCustomTabStop) => void;
  testId: string;
};

const CustomTabStop = ({
  id,
  value,
  measure,
  type,
  leader,
  onChange,
  onRemove,
  onCollapse,
  testId,
}: CustomTabStopProps) => {
  const intl = useIntl();

  const ALIGNMENT_LABEL: { [x in TempCustomTabStop['type']]: string } = {
    l: intl.formatMessage({ id: 'LEFT' }),
    c: intl.formatMessage({ id: 'CENTER' }),
    b: intl.formatMessage({ id: 'BAR_TAB' }),
    r: intl.formatMessage({ id: 'RIGHT' }),
    d: intl.formatMessage({ id: 'DECIMAL_LABEL' }),
    cl: '',
  };

  const LEADER_LABEL: { [x in Exclude<TempCustomTabStop['leader'], undefined>]: string } = {
    n: intl.formatMessage({ id: 'NONE' }),
    d: '.........',
    h: '-------',
    u: '________',
  };

  const [collapsed, setCollapsed] = useState(true);
  const [tempValue, setTempValue] = useState<TempCustomTabStop['value']>(value);
  const [tempMeasure, setTempMeasure] = useState<TempCustomTabStop['measure']>(measure);
  const [tempAlignment, setTempAlignment] = useState<TempCustomTabStop['type']>(type);
  const [tempLeader, setTempLeader] = useState<TempCustomTabStop['leader']>(leader);
  const [hasError, setHasError] = useState<boolean | undefined>(false);

  useEffect(() => {
    onChange({
      id,
      value: tempValue,
      measure: tempMeasure,
      type: tempAlignment,
      leader: tempLeader,
      hasError: hasError,
    });
  }, [tempValue, tempMeasure, tempAlignment, tempLeader, hasError]);

  useEffectOnUpdate(() => {
    if (collapsed) {
      onCollapse({
        id,
        value: tempValue,
        measure: tempMeasure,
        type: tempAlignment,
        leader: tempLeader,
      });
    }
  }, [collapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const removeCustomTabStop = () => {
    onRemove(id);
  };

  const handleAlignmentChange = (alignment: TempCustomTabStop['type']) => {
    setTempAlignment(alignment);
  };

  const handleLeaderChange = (leader: TempCustomTabStop['leader']) => {
    setTempLeader(leader);
  };

  const handleTabStopChange = (data: TempTabStop) => {
    setTempValue(data.value !== undefined && data.value !== '' ? +data.value : undefined);
    setTempMeasure(data.measure);
    setHasError(data.hasError);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.section}>
          <Toggle
            size="medium"
            variant="link"
            isToggled={!collapsed}
            icon={collapsed ? 'AngleRightBlue' : 'AngleDownBlue'}
            margin="0 1rem 0 0"
            onClick={toggleCollapsed}
            testId={`${testId}-collapse`}
          />

          <div
            className={cx({ [styles.empty]: tempValue == null })}
            data-testid={`${testId}-measure`}
          >
            {tempValue != null && tempMeasure ? (
              <>
                <span data-testid={`${testId}-value`}>{`${tempValue}`}</span>{' '}
                <span data-testid={`${testId}-measure`}>{`${tempMeasure}`}</span>
              </>
            ) : (
              intl.formatMessage({ id: 'NO_VALUE_SET' })
            )}
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.properties}>
            <div className={styles.property}>{ALIGNMENT_LABEL[tempAlignment]}</div>
            <div className={styles.property}> {LEADER_LABEL[tempLeader ?? 'n']}</div>
          </div>
          <Tooltip
            placement="top"
            content={intl.formatMessage({ id: 'DELETE_CUSTOM_TAB_STOP' })}
            testId={`${testId}-delete-tooltip`}
          >
            <Toggle
              size="medium"
              variant="link"
              icon={'CloseGrey'}
              margin="0 0 0 2rem"
              onClick={removeCustomTabStop}
              testId={`${testId}-delete`}
            />
          </Tooltip>
        </div>
      </div>
      {!collapsed && (
        <div className={styles.body}>
          <TabStopField
            initialValue={value}
            initialMeasure={measure}
            fieldLabel={intl.formatMessage({ id: 'TAB_STOP_VALUE' })}
            selectWidth="14rem"
            onChange={handleTabStopChange}
            testId={`${testId}`}
          />
          <div className={styles.properties}>
            <div className={styles.section}>
              <FormattedMessage id="editor.menu.format.alignment" />
              <div className={styles.radioButtons}>
                <div className={styles.section}>
                  <RadioButton
                    size="small"
                    checked={tempAlignment === 'l'}
                    onChange={() => {
                      handleAlignmentChange('l');
                    }}
                    testId={`${testId}-alignment-left-radio`}
                  >
                    {ALIGNMENT_LABEL.l}
                  </RadioButton>
                  <RadioButton
                    size="small"
                    checked={tempAlignment === 'c'}
                    onChange={() => {
                      handleAlignmentChange('c');
                    }}
                    testId={`${testId}-alignment-center-radio`}
                  >
                    {ALIGNMENT_LABEL.c}
                  </RadioButton>
                  <RadioButton
                    size="small"
                    checked={tempAlignment === 'd'}
                    onChange={() => {
                      handleAlignmentChange('d');
                    }}
                    testId={`${testId}-alignment-decimal-radio`}
                  >
                    {ALIGNMENT_LABEL.d}
                  </RadioButton>
                </div>
                <div className={styles.section}>
                  <RadioButton
                    size="small"
                    checked={tempAlignment === 'b'}
                    onChange={() => {
                      handleAlignmentChange('b');
                    }}
                    testId={`${testId}-alignment-barTab-radio`}
                  >
                    {ALIGNMENT_LABEL.b}
                  </RadioButton>
                  <RadioButton
                    size="small"
                    checked={tempAlignment === 'r'}
                    onChange={() => {
                      handleAlignmentChange('r');
                    }}
                    testId={`${testId}-alignment-right-radio`}
                  >
                    {ALIGNMENT_LABEL.r}
                  </RadioButton>
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <FormattedMessage id="LEADER" />
              <div className={styles.radioButtons}>
                <div className={styles.section}>
                  <RadioButton
                    size="small"
                    checked={tempLeader === 'n' || !tempLeader}
                    onChange={() => {
                      handleLeaderChange('n');
                    }}
                    testId={`${testId}-leader-none-radio`}
                  >
                    {LEADER_LABEL.n}
                  </RadioButton>
                  <RadioButton
                    size="small"
                    checked={tempLeader === 'd'}
                    onChange={() => {
                      handleLeaderChange('d');
                    }}
                    testId={`${testId}-leader-dot-radio`}
                  >
                    {LEADER_LABEL.d}
                  </RadioButton>
                </div>
                <div className={styles.section}>
                  <RadioButton
                    size="small"
                    checked={tempLeader === 'h'}
                    onChange={() => {
                      handleLeaderChange('h');
                    }}
                    testId={`${testId}-leader-dash-radio`}
                  >
                    {LEADER_LABEL.h}
                  </RadioButton>
                  <RadioButton
                    size="small"
                    checked={tempLeader === 'u'}
                    onChange={() => {
                      handleLeaderChange('u');
                    }}
                    testId={`${testId}-leader-underline-radio`}
                  >
                    {LEADER_LABEL.u}
                  </RadioButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTabStop;
