import dayjs from 'dayjs';
import {
  DateRange,
  FilterName,
  Filters,
  FilterType,
  OptionByType,
  TYPES,
} from './FilterController';

export const modifyFilterValue = (
  filter: FilterName | 'startISO' | 'endISO',
  value: ValueOf<FilterType>,
) => {
  //Category filters
  if (filter !== 'startISO' && filter !== 'endISO') {
    if (TYPES.multiUser.includes(filter)) {
      return (value as NonNullable<OptionByType['user']>).join(',');
    }
  }

  //Specific filters
  switch (filter) {
    case 'endISO':
      return dayjs(value as Exclude<DateRange, null>['endISO'])
        .add(1, 'day')
        .toISOString();
    case 'auditActions':
      return (value as NonNullable<Filters['auditActions']>).join(',');
  }

  return value as string; //Cast string due to typescript limitations
};

export const modifyDateRangeFilterField = (filter: FilterName, date: 'startISO' | 'endISO') => {
  switch (filter) {
    case 'registrationDate':
      return date === 'startISO' ? 'date_joined.start' : 'date_joined.end';
    default:
      return date === 'startISO' ? 'time.gt' : 'time.lt';
  }
};
