import { Logger } from '_common/services';
import { Command } from '../Command';
import { PathUtils, SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { ELEMENTS } from 'Editor/services/consts';
import { ErrorElementNotEditable } from '../../Errors';

export class DeleteCommand extends Command {
  private event?: KeyboardEvent | null;
  private deleteOptions: Editor.Edition.DeleteOptions;

  constructor(
    context: Editor.Edition.Context,
    event?: KeyboardEvent | null,
    deleteOptions: Editor.Edition.DeleteOptions = {},
  ) {
    super(context);
    this.event = event;
    this.deleteOptions = deleteOptions;
  }

  protected handleDeleteAtEnd(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (this.debug) {
      logger.info('handleDeleteAtEnd', ctx, elementData, elementPath);
    }
    if (!this.context.DataManager || !this.context.contentManipulator || !ctx.baseData) {
      return false;
    }

    let structureModel = this.context.DataManager.structure?.structureModel;

    if (!structureModel) {
      return false;
    }

    let nextData: Editor.Data.Node.Data | null = null;
    let nextPath: Editor.Selection.Path = [];
    let baseId: string | null = null;

    if (ctx.baseData.id && elementData.id === ctx.baseData.id) {
      // get preivous base block element

      const nextModel = this.context.DataManager.nodes.getNextModelById(ctx.baseData.id);
      if (nextModel) {
        nextData = nextModel?.selectedData();
        baseId = nextData?.id || null;
      }
    } else if (elementPath.length > 0) {
      //
      const result = NodeUtils.getNextSibling(ctx.baseData, elementPath);
      if (result) {
        nextData = result.data;
        nextPath = result.path;

        baseId = ctx.baseData.id || null;
      }
    }

    if (baseId && nextData) {
      if (
        this.context.editionMode === 'NORMAL' &&
        NodeUtils.isBlockTextData(elementData) &&
        NodeUtils.isEmptyData(elementData) &&
        (!NodeUtils.isBlockEditableData(nextData) ||
          NodeUtils.isMultiBlockContainerData(nextData) ||
          NodeUtils.isFigureData(nextData) ||
          NodeUtils.isTableData(nextData) ||
          NodeUtils.isTrackedData(nextData))
      ) {
        return this.context.contentManipulator.removeBlock(ctx);
      } else if (NodeUtils.isBlockTextData(nextData)) {
        // check text data

        // check mergeables
        if (NodeUtils.isBlockTextData(elementData) && ctx.baseData.id) {
          ctx.range.updateRangePositions(
            {
              b: ctx.baseData.id,
              p: [...elementPath, 'childNodes', elementData.childNodes?.length || 0],
            },
            {
              b: baseId,
              p: [...nextPath, 'childNodes', 0],
            },
          );
        } else {
          // move selection to next element
          ctx.range.updateRangePositions({
            b: baseId,
            p: [...nextPath, 'childNodes', 0],
          });
          ctx.avoidNextNonCollapsedAction = true;
        }
        return true;
      } else if (NodeUtils.isBlockNonTextData(nextData) || NodeUtils.isTrackInsertData(nextData)) {
        // check non deletable
        ctx.range.updateRangePositions(
          {
            b: baseId,
            p: [...nextPath, 'childNodes', 0],
          },
          {
            b: baseId,
            p: [...nextPath, 'childNodes', nextData.childNodes?.length || 1],
          },
        );

        ctx.avoidNextNonCollapsedAction = true;

        return true;
      }
    }

    return false;
  }

  protected handleTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    if (
      !ctx.baseModel ||
      !ctx.baseData ||
      !this.context.selection?.modifiers ||
      !this.context.DataManager
    ) {
      return false;
    }

    let subPath = ctx.range.start.p.slice(elementPath.length);

    if (NodeUtils.isPathAtContentEnd(elementData, subPath)) {
      // SELECTION AT END
      return this.handleDeleteAtEnd(ctx, elementData, elementPath);
    } else {
      // SELECTION START OR MID

      let closestTrackedDelete = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
        'tracked-delete',
      ]);

      let closestNonEditable = NodeUtils.closestNextAncestorOfType(
        ctx.baseData,
        ctx.range.start.p,
        [...NodeUtils.INLINE_NON_EDITABLE_TYPES, ELEMENTS.FieldElement.ELEMENT_TYPE],
      );

      // check inline elements
      if (this.context.editionMode === 'SUGGESTIONS' && closestTrackedDelete) {
        let subPath = ctx.range.start.p.slice(closestTrackedDelete.path.length);
        if (
          !PathUtils.isPathEqual(closestTrackedDelete.path, ctx.range.start.p) &&
          !NodeUtils.isPathAtContentEnd(closestTrackedDelete.data, subPath)
        ) {
          const offset = Number(closestTrackedDelete.path[closestTrackedDelete.path.length - 1]);
          if (isNaN(offset)) {
            return false;
          }

          let path = [...closestTrackedDelete.path];
          path[path.length - 1] = offset + 1;

          ctx.range.updateRangePositions({
            b: ctx.baseModel.id,
            p: path,
          });
          return true;
        }
      }

      if (
        closestNonEditable &&
        (!NodeUtils.isFieldData(closestNonEditable.data) ||
          NodeUtils.isFieldCaptionData(closestNonEditable.data))
      ) {
        let nonEditableSubPath = ctx.range.start.p.slice(closestNonEditable.path.length);
        let isAtEnd = false;

        if (
          PathUtils.isChildPath(closestNonEditable.path, ctx.range.start.p) &&
          NodeUtils.isPathAtContentEnd(closestNonEditable.data, nonEditableSubPath)
        ) {
          isAtEnd = true;
        }

        if (!isAtEnd) {
          const offset = Number(closestNonEditable.path[closestNonEditable.path.length - 1]);
          if (isNaN(offset)) {
            return false;
          }

          let startPath = [...closestNonEditable.path];
          let endPath = [...closestNonEditable.path];
          endPath[endPath.length - 1] = offset + 1;

          ctx.range.updateRangePositions(
            {
              b: ctx.baseModel.id,
              p: startPath,
            },
            {
              b: ctx.baseModel.id,
              p: endPath,
            },
          );

          ctx.avoidNextNonCollapsedAction = true;

          // trigger confirmation modal
          if (NodeUtils.isFieldCaptionData(closestNonEditable.data)) {
            ReduxInterface.openDeleteCaptionConfirmationModal();
          }
          return true;
        }
      }

      // normalize text selection
      SelectionFixer.normalizeTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
          forceWrapAsText: true,
          isDelete: true,
        },
        this.context.DataManager,
      );

      this.context.selection.modifiers.modify(ctx.range, 'expand', 'character', 'forward');

      return true;
    }
  }

  protected handleNonTextElement(
    ctx: Editor.Edition.ActionContext,
    elementData: Editor.Data.Node.Data,
    elementPath: Editor.Selection.Path,
  ): boolean {
    let subPath = ctx.range.start.p.slice(elementPath.length);

    if (NodeUtils.isPathAtContentEnd(elementData, subPath)) {
      return this.handleDeleteAtEnd(ctx, elementData, elementPath);
    } else {
      let startPath: Editor.Selection.Path = ['childNodes', 0];
      let endPath: Editor.Selection.Path = ['childNodes', elementData.childNodes?.length || 1];
      ctx.range.updateRangePositions(
        { b: ctx.range.start.b, p: startPath },
        { b: ctx.range.start.b, p: endPath },
      );
      ctx.avoidNextNonCollapsedAction = true;

      return true;
    }
  }

  protected handleCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager || !this.context.contentManipulator) {
      return false;
    }

    const baseModel = this.context.DataManager.nodes.getNodeModelById(ctx.range.start.b);

    const baseData = baseModel?.selectedData();
    if (!baseModel || !baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    ctx.setModelAndData(baseModel, baseData);

    const closestTable = NodeUtils.closestOfTypeByPath(
      baseData,
      ctx.range.getCommonAncestorPath(),
      'tbl',
    );

    if (closestTable) {
      const tableElement = EditorDOMUtils.getNode(closestTable.data.id);
      if (EditorDOMElements.isTableElement(tableElement)) {
        const selectedCells = tableElement.getSelectedCellsIds();
        if (selectedCells.length > 1) {
          return this.context.contentManipulator.removeContent(ctx, {
            useSelectedCells: true,
            confirmDeleteCaption: this.deleteOptions.confirmDeleteCaption,
          });
        }
      }
    }

    const result = NodeUtils.closestOfTypeByPath(
      baseData,
      ctx.range.start.p,
      NodeUtils.BLOCK_EDITABLE_TYPES,
    );

    if (result) {
      if (NodeUtils.isBlockTextData(result.data)) {
        return this.handleTextElement(ctx, result.data, result.path);
      } else {
        return this.handleNonTextElement(ctx, result.data, result.path);
      }
    }

    return false;
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    if (!this.context.DataManager || !this.context.contentManipulator) {
      return false;
    }

    // WARN: Fix selection here moved inside remove content

    return this.context.contentManipulator.removeContent(ctx, {
      selectionDirection: 'forward',
      confirmDeleteCaption: this.deleteOptions.confirmDeleteCaption,
    });
  }

  async handleExec(): Promise<void> {
    if (this.debug) {
      Logger.trace('DeleteCommand exec', this);
    }

    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    //TODO:
    // fix block selection?

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      if (!this.handleCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    // handle non collapsed selection
    if (!this.actionContext.range.collapsed && !this.actionContext.avoidNextNonCollapsedAction) {
      if (!this.handleNonCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
