import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, InputField, Select, Toggle, Tooltip } from 'dodoc-design-system';

import styles from './Section.module.scss';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';
import { ColorPickerToggle, MeasureInput } from '_common/components';
import { useEffectOnUpdate } from '_common/hooks';

type StyleProps = Pick<Editor.Styles.TableProperties, 'background' | 'border'> & SectionProps;

const DEFAULT_PROPERTIES: Pick<Editor.Styles.TableProperties, 'background'> & {
  borderColor: string;
  borderWidth: number;
  borderStyle: Editor.Data.Node.BorderStyle;
  borderChosen: Editor.Styles.BorderChosen;
} = {
  background: 'transparent',
  borderColor: '#000000',
  borderWidth: 0.75,
  borderStyle: 's',
  borderChosen: 'none',
};

type ToggleOption = {
  value: Editor.Styles.BorderChosen;
  icon: IconTypes['24'];
  label: string;
};

const BORDER_TOGGLES: ToggleOption[] = [
  {
    value: 'all',
    icon: 'AllBorders',
    label: 'ALL_BORDERS',
  },
  {
    value: 'top',
    icon: 'TopBorder',
    label: 'TOP_BORDER',
  },
  {
    value: 'right',
    icon: 'RightBorder',
    label: 'RIGHT_BORDER',
  },
  {
    value: 'bottom',
    icon: 'BottomBorder',
    label: 'BOTTOM_BORDER',
  },
  {
    value: 'left',
    icon: 'LeftBorder',
    label: 'LEFT_BORDER',
  },
  {
    value: 'inside',
    icon: 'InsideBorder',
    label: 'INSIDE_BORDERS',
  },
  {
    value: 'outside',
    icon: 'OutsideBorders',
    label: 'OUTSIDE_BORDERS',
  },
  {
    value: 'none',
    icon: 'NoBorder',
    label: 'NO_BORDERS',
  },
];

type BorderStyleOption = {
  value: Editor.Data.Node.BorderStyle;
  label: string;
};

//Forcefully restricting value type while maintaining SelectOption type
const BORDER_STYLES: (SelectOption & BorderStyleOption)[] = [
  { value: 's', label: '\u2014 (solid)' },
  { value: 'dot', label: '\u00B7\u00B7\u00B7 (dotted)' },
  { value: 'das', label: '--- (dashed)' },
  { value: 'd', label: '\u2550 \u2550 \u2550 (double)' },
  { value: 'nil', label: 'NONE' },
];

const Style = ({ background, border, updateData }: StyleProps) => {
  const intl = useIntl();

  const borderStyleOptions = useMemo(
    () =>
      BORDER_STYLES.map((borderStyle) => ({
        ...borderStyle,
        label: intl.formatMessage({ id: borderStyle.label }),
      })),
    [],
  );

  const [localBackground, setLocalBackground] = useState(
    background ?? DEFAULT_PROPERTIES.background ?? 'transparent',
  );
  useEffectOnUpdate(() => {
    updateData({
      property: 'background',
      value: localBackground,
    });
  }, [localBackground]);

  const [localBorderColor, setLocalBorderColor] = useState(
    border?.color?.value ?? DEFAULT_PROPERTIES.borderColor ?? 'transparent',
  );
  useEffectOnUpdate(() => {
    updateData({
      property: 'border',
      value: {
        ...border,
        color: { value: localBorderColor },
      },
    });
  }, [localBorderColor]);

  const [localBorderWidth, setLocalBorderWidth] = useState(
    `${border?.width?.value ?? DEFAULT_PROPERTIES.borderWidth}`,
  );
  const [localBorderStyle, setLocalBorderStyle] = useState(
    borderStyleOptions.find(
      (borderStyleOption) =>
        borderStyleOption.value === (border?.style?.value ?? DEFAULT_PROPERTIES.borderStyle),
    ),
  );
  const [localBorderChosen, setLocalBorderChosen] = useState(border?.chosen?.value);

  //#region Property change handlers
  const handleBorderStyleChange = (newStyle: (typeof borderStyleOptions)[number]) => {
    setLocalBorderStyle(newStyle);
    updateData({ property: 'border', value: { ...border, style: { value: newStyle.value } } });
  };

  const handleBorderWidthChange = (newValue: string) => {
    setLocalBorderWidth(newValue);
    updateData({ property: 'border', value: { ...border, width: { value: +newValue } } });
  };

  const handleChosenBorderChange = (newChosenBorder: Editor.Styles.BorderChosen) => {
    setLocalBorderChosen(newChosenBorder);

    //Relates to requirement.4 of "Allow borderless in table/cell limits"
    if (
      newChosenBorder !== 'none' &&
      localBorderWidth === '0' &&
      localBorderStyle?.value === 'nil' &&
      localBorderColor === ''
    ) {
      setLocalBorderWidth(`${DEFAULT_PROPERTIES.borderWidth}`);
      setLocalBorderStyle(borderStyleOptions.find((option) => option.value === 's'));
      setLocalBorderColor(DEFAULT_PROPERTIES.borderColor);

      updateData({
        property: 'border',
        value: {
          ...border,
          width: { value: DEFAULT_PROPERTIES.borderWidth },
          style: { value: 's' },
          chosen: { value: newChosenBorder },
        },
      });
    } else {
      updateData({
        property: 'border',
        value: { ...border, chosen: { value: newChosenBorder } },
      });
    }
  };
  //#endregion

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'STYLE' })}
      initialCollapsed={false}
      contentMargin="1rem"
      testId="tableProperties-style-accordion"
    >
      <div className={styles.root}>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'BACKGROUND_COLOR' })}
            testId="tableProperties-background-color-field"
          >
            <ColorPickerToggle
              color={localBackground}
              onColorChange={setLocalBackground}
              testId="tableProperties-background-color"
            />
          </InputField>
        </div>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'BORDER' })}
            testId="tableProperties-border-field"
          >
            <Select
              size="medium"
              value={
                localBorderChosen === 'none'
                  ? borderStyleOptions.find((option) => option.value === 'nil')
                  : localBorderStyle
              }
              options={borderStyleOptions}
              onChange={handleBorderStyleChange}
              clearable={false}
              menuPosition="fixed"
              fullWidth
              testId="border-styles"
              disabled={localBorderChosen === 'none'}
            />
            <div className={`${styles.row} ${styles.withMargin}`}>
              <MeasureInput
                size="medium"
                value={localBorderChosen === 'none' ? '0' : localBorderWidth}
                clearable={false}
                valueSuffix="pt"
                placeholder=""
                onChange={handleBorderWidthChange}
                allowDecimal
                avoidEmpty
                testId="tableProperties-border"
                disabled={localBorderChosen === 'none'}
              />
              <ColorPickerToggle
                color={localBorderColor}
                onColorChange={setLocalBorderColor}
                testId="tableProperties-border-color"
              />
            </div>
            <div className={styles.shelf}>
              {BORDER_TOGGLES.map((toggle) => (
                <Tooltip
                  key={`borderType-${toggle.value}`}
                  content={intl.formatMessage({ id: toggle.label })}
                  testId={`tableProperties-border-${toggle.value}-tooltip`}
                >
                  <Toggle
                    size="medium"
                    variant="link"
                    isToggled={localBorderChosen === toggle.value}
                    icon={toggle.icon}
                    onClick={() => handleChosenBorderChange(toggle.value)}
                    testId={`tableProperties-border-${toggle.value}-toggle`}
                  />
                </Tooltip>
              ))}
            </div>
          </InputField>
        </div>
      </div>
    </Accordion>
  );
};

export default Style;
