import { useState } from 'react';

import { useIntl } from 'react-intl';

import { InputField, Input } from 'dodoc-design-system';

import { FIELD_LIMIT } from 'Settings/pages/TenantSettingsPage/Users/constants';

import type { InputFieldProps } from 'dodoc-design-system/build/types/Components/InputField/InputField';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

export type FieldProps = {
  id: 'firstName' | 'lastName' | 'email';
  type?: 'text' | 'email';
  editValue?: InputProps['value'];
  limit?: typeof FIELD_LIMIT;
  feedback: TranslationMessage;
  onChange: (id: string, value: string, error: boolean, changed: boolean) => void;
  testId: string;
} & Pick<InputFieldProps, 'label'> &
  Pick<InputProps, 'placeholder' | 'value' | 'testId'>;

const Field = ({
  id,
  value,
  type = 'text',
  label,
  placeholder,
  feedback,
  editValue,
  limit,
  onChange,
  testId,
}: FieldProps) => {
  const intl = useIntl();
  const [showFeedback, setShowFeedback] = useState(false);

  const emailIsValid = (email: string) => {
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );
  };

  const stringIsValid = (value: string) => {
    if (!value) {
      return false;
    }

    if (value === ' ' || /\s{2,}/.test(value)) {
      return false;
    }

    return true;
  };

  const stringIsBetweenLimits = ({ value = '', min = 1, max = FIELD_LIMIT }) => {
    if (!stringIsValid(value)) {
      return false;
    }

    if (value.length >= min && value.length <= max) {
      return true;
    }

    return false;
  };

  const handleChange: InputProps['onChange'] = (e) => {
    if (onChange) {
      let error = true;
      let changed = true;
      let { value } = e.target;

      if (value) {
        value = value === ' ' ? value.trim() : value.replace(/\s+/g, ' ');
      }

      if (editValue) {
        if (editValue === value) {
          changed = false;
        }
      }

      if (!value || value === '') {
        setShowFeedback(false);
      } else {
        switch (type) {
          case 'text': {
            if (stringIsBetweenLimits({ value, max: limit })) {
              error = false;
            }
            break;
          }
          case 'email': {
            if (emailIsValid(value)) {
              error = false;
            }
            break;
          }
          default:
            break;
        }

        setShowFeedback(error);
      }

      onChange(id, value, error, changed);
    }
  };

  return (
    <InputField
      testId={testId}
      feedback={
        showFeedback && intl.formatMessage({ id: feedback.id }, feedback.values)?.toString()
      }
      label={intl.formatMessage({
        id: label,
      })}
      size="large"
    >
      <Input
        placeholder={intl.formatMessage({
          id: placeholder,
        })}
        size="large"
        value={value}
        error={showFeedback}
        onChange={handleChange}
        testId={testId}
      />
    </InputField>
  );
};

export default Field;
