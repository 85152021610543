import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CommentsSliceState = {
  searchBy?: string;
  isListMode: boolean;
};

const initialState: CommentsSliceState = { isListMode: false };

const CommentsSlice = createSlice({
  name: 'presentation_comments',
  initialState,
  reducers: {
    setCommentsSearchBy: (state, action: PayloadAction<CommentsSliceState['searchBy']>) => {
      state.searchBy = action.payload;
    },
    toggleIsListMode: (state) => {
      state.isListMode = !state.isListMode;
    },
  },
});

export default CommentsSlice;

export const { setCommentsSearchBy, toggleIsListMode } = CommentsSlice.actions;
