import { ElementNodeBuilder } from 'Editor/services/Model';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, ParagraphElement } from '..';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
export class ParagraphViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<ParagraphElement, Editor.Data.Node.ParagraphData>
{
  // -------------------------------------------------------------------------
  //                          Paragraph Attribute Mapper
  // -------------------------------------------------------------------------
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<
    ParagraphElement,
    Editor.Data.Node.ParagraphData
  > = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.ParagraphElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    element_reference: this.GENERIC_ATTRIBUTE_MAPPER.element_reference,
    // lock status
    lock: this.GENERIC_ATTRIBUTE_MAPPER.lock,
    // sub type
    st: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.styleId == null && node.hasAttribute('st')) {
          const subType = node.getAttribute('st') as Editor.Elements.ParagraphStylesType;
          if (subType && Object.values(ELEMENTS.ParagraphElement.BASE_STYLES).includes(subType)) {
            builder.addKeyValue('st', subType);
          }
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.s == null && json.st != null) {
          const elementType = json.st || ELEMENTS.ParagraphElement.ELEMENT_TYPE;
          node.setAttribute('st', elementType);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('st');
      },
    },
    // alignment
    a: this.GENERIC_ATTRIBUTE_MAPPER.a,
    // status
    status: this.GENERIC_ATTRIBUTE_MAPPER.status,
    // tasks
    tasks: this.GENERIC_ATTRIBUTE_MAPPER.tasks,
    // style id
    s: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.styleId) {
          builder.addProperty('s', node.dataset.styleId);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.s) {
          node.dataset.styleId = json.properties.s;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.styleId;
      },
    },
    // space before
    sb: this.GENERIC_ATTRIBUTE_MAPPER.sb,
    // space after
    sa: this.GENERIC_ATTRIBUTE_MAPPER.sa,
    // line heigth
    lh: this.GENERIC_ATTRIBUTE_MAPPER.lh,
    // indentation
    ind: this.GENERIC_ATTRIBUTE_MAPPER.ind,
    // special indentation
    sp_ind: this.GENERIC_ATTRIBUTE_MAPPER.sp_ind,
    // background color from dataset
    bg: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.backgroundColor != null) {
          const bg: any = node.dataset.backgroundColor;
          if (bg != null) {
            if (
              bg === 'rgba(0, 0, 0, 0)' ||
              bg === 'transparent' ||
              bg === false ||
              bg === 'false'
            ) {
              builder.addProperty('bg', false);
            } else if (bg.includes('rgb')) {
              const hex = EditorDOMUtils.rgbToHex(bg);
              builder.addProperty('bg', hex?.substring(1, bg.length).toUpperCase?.());
            } else if (bg.includes('#')) {
              builder.addProperty('bg', bg.substring(1, bg.length).toUpperCase?.());
            } else {
              builder.addProperty('bg', bg.toUpperCase?.());
            }
          }
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.bg != null) {
          const bg = json.properties.bg;
          if (bg != null) {
            node.dataset.backgroundColor = bg;
          }
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.backgroundColor;
      },
    },
    // highligh color
    hc: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.HIGHLIGHTCOLOR] != null) {
          const hc: any = node.dataset[StylesUtils.STYLES.HIGHLIGHTCOLOR];
          if (hc != null) {
            if (
              hc === 'rgba(0, 0, 0, 0)' ||
              hc === 'transparent' ||
              hc === false ||
              hc === 'false'
            ) {
              builder.addProperty('hc', false);
            } else if (hc.includes('rgb')) {
              const hex = EditorDOMUtils.rgbToHex(hc);
              builder.addProperty('hc', hex?.substring(1, hc.length).toUpperCase?.());
            } else if (hc.includes('#')) {
              builder.addProperty('hc', hc.substring(1, hc.length).toUpperCase?.());
            } else {
              builder.addProperty('hc', hc.toUpperCase?.());
            }
          }
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.hc != null) {
          const hc = json.properties.hc;
          if (hc != null) {
            node.dataset[StylesUtils.STYLES.HIGHLIGHTCOLOR] = hc;
          }
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.HIGHLIGHTCOLOR];
      },
    },
    // font family
    ff: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.FONTFAMILY] != null) {
          builder.addProperty('ff', node.dataset[StylesUtils.STYLES.FONTFAMILY]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.ff != null) {
          node.dataset[StylesUtils.STYLES.FONTFAMILY] = json.properties.ff;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.FONTFAMILY];
      },
    },
    // font size
    fs: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.FONTSIZE] != null) {
          builder.addProperty('fs', node.dataset[StylesUtils.STYLES.FONTSIZE]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.fs != null) {
          node.dataset[StylesUtils.STYLES.FONTSIZE] = `${json.properties.fs}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.FONTSIZE];
      },
    },
    // color
    c: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.COLOR] != null) {
          const color: any = node.dataset[StylesUtils.STYLES.COLOR];
          if (color != null) {
            if (
              color === 'rgba(0, 0, 0, 0)' ||
              color === 'transparent' ||
              color === false ||
              color === 'false'
            ) {
              builder.addProperty('c', false);
            } else if (color.includes('rgb')) {
              const hex = EditorDOMUtils.rgbToHex(color);
              builder.addProperty('c', hex?.substring(1, color.length).toUpperCase?.());
            } else if (color.includes('#')) {
              builder.addProperty('c', color.substring(1, color.length).toUpperCase?.());
            } else {
              builder.addProperty('c', color.toUpperCase?.());
            }
          }
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.c != null) {
          node.dataset[StylesUtils.STYLES.COLOR] = json.properties.c;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.COLOR];
      },
    },
    // bold
    b: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.BOLD] != null) {
          builder.addProperty('b', node.dataset[StylesUtils.STYLES.BOLD]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.b != null) {
          node.dataset[StylesUtils.STYLES.BOLD] = `${json.properties.b}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.BOLD];
      },
    },
    // italic
    i: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.ITALIC] != null) {
          builder.addProperty('i', node.dataset[StylesUtils.STYLES.ITALIC]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.i != null) {
          node.dataset[StylesUtils.STYLES.ITALIC] = `${json.properties.i}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.ITALIC];
      },
    },
    // underline
    u: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.UNDERLINE] != null) {
          builder.addProperty('u', node.dataset[StylesUtils.STYLES.UNDERLINE]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.u != null) {
          node.dataset[StylesUtils.STYLES.UNDERLINE] = `${json.properties.u}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.UNDERLINE];
      },
    },
    // strikethrough
    strikethrough: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.STRIKETHROUGH] != null) {
          builder.addProperty('strikethrough', node.dataset[StylesUtils.STYLES.STRIKETHROUGH]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.str != null) {
          node.dataset[StylesUtils.STYLES.STRIKETHROUGH] = json.properties.str;
        } else if (json.properties?.strikethrough != null) {
          node.dataset[StylesUtils.STYLES.STRIKETHROUGH] = `${json.properties.strikethrough}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.STRIKETHROUGH];
      },
    },
    // superscript
    sps: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.SUPERSCRIPT] != null) {
          builder.addProperty('sps', node.dataset[StylesUtils.STYLES.SUPERSCRIPT]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.sps != null) {
          node.dataset[StylesUtils.STYLES.SUPERSCRIPT] = `${json.properties.sps}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.SUPERSCRIPT];
      },
    },
    // subscript
    sbs: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.SUBSCRIPT] != null) {
          builder.addProperty('sbs', node.dataset[StylesUtils.STYLES.SUBSCRIPT]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.sbs != null) {
          node.dataset[StylesUtils.STYLES.SUBSCRIPT] = `${json.properties.sbs}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.SUBSCRIPT];
      },
    },
    // vanish / hidden
    v: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset[StylesUtils.STYLES.VANISH] != null) {
          builder.addProperty('v', node.dataset[StylesUtils.STYLES.VANISH]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.v != null) {
          node.dataset[StylesUtils.STYLES.VANISH] = `${json.properties.v}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset[StylesUtils.STYLES.VANISH];
      },
    },

    // widow/orphan control
    wc: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.wc != null) {
          builder.addProperty('wc', node.dataset.wc);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.wc != null) {
          node.dataset.wc = `${json.properties.wc}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.wc;
      },
    },
    // keep next
    kn: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.kn != null) {
          builder.addProperty('kn', node.dataset.kn);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.kn != null) {
          node.dataset.kn = `${json.properties.kn}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.kn;
      },
    },
    // keep lines
    kl: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.kl != null) {
          builder.addProperty('kl', node.dataset.kl);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.kl != null) {
          node.dataset.kl = `${json.properties.kl}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.kl;
      },
    },
    // contextual spacing
    cts: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.cts != null) {
          builder.addProperty('cts', node.dataset.cts);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.cts != null) {
          node.dataset.cts = `${json.properties.cts}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.cts;
      },
    },
    // pagebreak before
    pbb: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.pbb != null) {
          builder.addProperty('pbb', node.dataset.pbb);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.pbb != null) {
          node.dataset.pbb = `${json.properties.pbb}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.pbb;
      },
    },
    // auto space before
    asb: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.asb != null) {
          builder.addProperty('asb', node.dataset.asb);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.asb != null) {
          node.dataset.asb = `${json.properties.asb}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.asb;
      },
    },
    // auto space after
    asa: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.asa != null) {
          builder.addProperty('asa', node.dataset.asa);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.asa != null) {
          node.dataset.asa = `${json.properties.asa}`;
        }
      },
      remove: (node) => {
        delete node.dataset.asa;
      },
    },
    //ouline level
    otl: {
      parse(node, builder, attributeName?) {
        if (node.dataset.otl != null) {
          builder.addProperty('otl', node.dataset.otl);
        }
      },
      render(json, node, baseNodeData?, attributeName?, options?) {
        if (json.properties?.otl != null) {
          node.dataset.otl = `${json.properties.otl}`;
        }
      },
      remove(node, attributeName?, options?) {
        delete node.dataset.otl;
      },
    },
    //section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
    //tabs
    tabs: {
      parse(node, builder) {
        if (node.dataset.tabs != null) {
          builder.addProperty('tabs', JSON.parse(node.dataset.tabs));
        }
      },
      render(json, node) {
        if (json.properties?.tabs != null) {
          node.dataset.tabs = JSON.stringify(json.properties.tabs);
        }
      },
      remove(node) {
        delete node.dataset.tabs;
      },
    },

    /*
    clipboard only
    */
    tempStyleId: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.tempStyleId) {
          builder.addClipboardProperty('tempStyleId', node.dataset.tempStyleId);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {},
      remove: (node: HTMLElement) => {
        delete node.dataset.tempStyleId;
      },
    },

    cp_list_id: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('cp_list_id')) {
          builder.addClipboardProperty('cp_list_id', node.getAttribute('cp_list_id'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {},
      remove: (node: HTMLElement) => {
        node.removeAttribute('cp_list_id');
      },
    },

    cp_list_level: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('cp_list_level')) {
          builder.addClipboardProperty('cp_list_level', node.getAttribute('cp_list_level'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {},
      remove: (node: HTMLElement) => {
        node.removeAttribute('cp_list_level');
      },
    },

    cp_list_style: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('cp_list_style')) {
          builder.addClipboardProperty('cp_list_style', node.getAttribute('cp_list_style'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {},
      remove: (node: HTMLElement) => {
        node.removeAttribute('cp_list_style');
      },
    },

    tempCrossReferenceId: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.tempCrossReferenceId) {
          builder.addClipboardProperty('tempCrossReferenceId', node.dataset.tempCrossReferenceId);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {},
      remove: (node: HTMLElement) => {
        delete node.dataset.tempCrossReferenceId;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.ParagraphData | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.ParagraphData, model?: Editor.Data.Node.Model) {
    const node = new ParagraphElement(this.Visualizer, this.Data);

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data);
    });

    node.preRender();

    return node;
  }
}
