import { useComments } from 'Presentation/SyncStore';

const useCommentsOrder = (slideId?: Presentation.Data.Slide['_id']) => {
  const data = useComments()?.data;
  const comments = data ? Object.values(data) : [];
  const order: { [x in Presentation.Data.Comment['id']]: number } = {};

  const filterComment = (comment: Presentation.Data.Comment) => {
    if (slideId) {
      return comment.anchor[0].id === slideId;
    }
    return true;
  };

  comments.filter(filterComment).forEach((comment, i) => {
    order[comment.id] = i + 1;
  });

  return order;
};
export default useCommentsOrder;
