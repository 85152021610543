import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ReduxInterface } from 'Editor/services';
import { InsertInlineCommand } from '../GenericCommands';

export class InsertNoteCommand extends InsertInlineCommand {
  type: Notes.NoteType;
  text: string;

  constructor(context: Editor.Edition.Context, type: Notes.NoteType, text: string) {
    super(context);
    this.type = type;
    this.text = text;
  }

  async handleExec(): Promise<void> {
    try {
      if (!this.context.DataManager) {
        throw new Error('Invalid context');
      }

      const id = await this.context.DataManager.notes.addNote(null, this.type, this.text);

      if (id) {
        const note = NodeDataBuilder.buildData({
          type: 'note',
          properties: {
            note_type: this.type,
            element_reference: id,
          },
        });

        if (note) {
          await this.insertInline(note);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      ReduxInterface.setNoteOverlayData({
        offsets: null,
        selected: null,
      });
    }
  }

  async insertInline(note: Editor.Data.Node.Data) {
    await super.handleExec(note);
  }
}
