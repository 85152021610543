import EditionModeFactory from './EditionModes/EditionModeFactory';
import KeydownEventValidator from './KeydownEventValidator';
import { HistoryManager } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import EventsManager from 'Editor/services/EventsManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { ClipboardManager } from '../Clipboard';
import { Logger } from '_common/services';

export const MODES = EditionModeFactory.availableModes();

export default class OldEditionManager {
  constructor() {
    this._mode = EditionModeFactory.MODE_DISABLED;
    this._editionMode = {};

    this.handleUpdateImageProperties = this.handleUpdateImageProperties.bind(this);

    this.eventsManagerListeners = {
      KEY_UP: this.handleKeyUp.bind(this),
      KEY_DOWN: this.handleKeyDown.bind(this),
      KEY_PRESS: this.handleKeyPress.bind(this),
      MOUSE_DOWN: this.handleMouseDown.bind(this),
      MOUSE_UP: this.handleMouseUp.bind(this),
      MOUSE_OVER: this.handleMouseOver.bind(this),
      PASTE: this.handlePaste.bind(this),
      CUT: this.handleCut.bind(this),
      COPY: this.handleCopy.bind(this),
      DROP: this.handleDrop.bind(this),
      INPUT: this.handleInput.bind(this),
      COMPOSITION_END: this.handleCompositionEnd.bind(this),
    };
  }

  start(editorContext) {
    this.page = editorContext.documentContainer;
    this.selectionManager = editorContext.selectionManager;
    this.navigationManager = editorContext.navigationManager;
    this.changeTracker = editorContext.changeTracker;
    this.stylesHandler = editorContext.stylesHandler;
    this.dataManager = editorContext.DataManager;
    this.documentParser = editorContext.documentParser;
    this.visualizerManager = editorContext.visualizerManager;

    this.clipboard = new ClipboardManager(
      this.page,
      this.stylesHandler,
      this.dataManager,
      this.visualizerManager,
    );

    const eventsManager = EventsManager.getInstance();
    Object.keys(this.eventsManagerListeners).forEach((event) => {
      eventsManager.on(event, this.eventsManagerListeners[event]);
    });

    Logger.info('EditionManager version 1 started!');
  }

  destroy() {
    const eventsManager = EventsManager.getInstance();
    Object.keys(this.eventsManagerListeners).forEach((event) => {
      eventsManager.removeListener(event, this.eventsManagerListeners[event]);
    });

    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  get editionMode() {
    if (!this._editionMode[this._mode]) {
      this._editionMode[this._mode] = EditionModeFactory.getEditionMode(
        this._mode,
        this.page,
        this.selectionManager,
        this.navigationManager,
        this.dataManager,
        this.changeTracker,
        this.clipboard,
        this.stylesHandler,
        this.documentParser,
        this.visualizerManager,
      );
    }
    return this._editionMode[this._mode];
  }

  set editionMode(value) {
    this._mode = value;
  }

  enableSuggestionMode() {
    this._mode = EditionModeFactory.MODE_SUGGESTING;
  }

  enableNormalMode() {
    this._mode = EditionModeFactory.MODE_NORMAL;
  }

  disableEdition() {
    this._mode = EditionModeFactory.MODE_DISABLED;
  }

  // #################################################
  //                  event handlers
  // #################################################
  handleKeyDown(event) {
    if (!KeydownEventValidator.isKeyDefaultAllowed(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.editionMode.handleKeyDown(event);
    }
  }

  handleKeyUp(event) {
    this.editionMode.handleKeyUp(event);
  }

  handleKeyPress(event) {
    this.editionMode.handleKeyPress(event);
  }

  handleMouseUp({ event, mousedownTarget }) {
    this.editionMode.handleMouseUp({ event, mousedownTarget });
  }

  handleMouseOver({ event, mousedownTarget }) {
    this.editionMode.handleMouseOver({ event, mousedownTarget });
  }

  handleMouseDown(event) {
    this.editionMode.handleMouseDown(event);
  }

  handleCut(event) {
    this.editionMode.handleCutEvent(event);
  }

  handleCopy(event) {
    this.editionMode.handleCopyEvent(event);
  }

  handleDrop(event) {
    this.editionMode.handleDropEvent(event);
  }

  handlePaste(event) {
    this.editionMode.handlePasteEvent(event);
  }

  handleInput(event) {
    this.editionMode.handleInput(event);
  }

  handleCompositionEnd(event) {
    this.editionMode.handleCompositionEnd(event);
  }

  // #################################################
  //         content manager event handlers
  // #################################################

  insertInlineElement(inlineElement) {
    this.editionMode.handleInsertInlineNode?.(inlineElement);
  }

  handleRemoveSelection(deleteOptions) {
    this.editionMode.handleRemoveSelection?.(null, deleteOptions);
  }

  handleSaveChanges(actionContext) {
    this.changeTracker.saveActionChanges?.(actionContext);
  }

  // #################################################
  //              insert element handlers
  // #################################################
  insertTable(rows, columns) {
    this.editionMode.handleInsertTable?.(rows, columns);
  }

  insertPageBreak() {
    const pageBreak = DOMElementFactory.buildElement(ELEMENTS.PageBreakElement.IDENTIFIER);
    this.editionMode.handleInsertInlineNode?.(pageBreak, null, {
      forceBlockSplit: true,
      forceTextAsWrap: true,
    });
  }

  insertSectionBreak(type) {
    const sectionBreak = DOMElementFactory.buildElement(ELEMENTS.SectionBreakElement.IDENTIFIER);
    sectionBreak.setSectionType(type);
    this.editionMode.handleInsertInlineNode?.(sectionBreak, null, {
      forceBlockSplit: true,
      forceTextAsWrap: true,
    });
  }

  insertColumnBreak() {
    const columnBreak = DOMElementFactory.buildElement(ELEMENTS.ColumnBreakElement.IDENTIFIER);
    this.editionMode.handleInsertInlineNode?.(columnBreak, null, {
      forceBlockSplit: true,
      forceTextAsWrap: true,
    });
  }

  insertTableOfContents(options) {
    const tableOfContents = DOMElementFactory.buildElement(
      ELEMENTS.TableOfContentsElement.IDENTIFIER,
    );
    if (options.representingLevels) {
      tableOfContents.dataset.rl = JSON.stringify(options.representingLevels);
    }
    tableOfContents.setAttribute('spn', options.showPageNumber);
    tableOfContents.setAttribute('anr', options.alignNumberRight);
    tableOfContents.setAttribute('l', options.useLink);
    tableOfContents.dataset.tabs = JSON.stringify([
      {
        t: 'r',
        l: options.leader,
      },
    ]);
    this.editionMode.handleInsertBlockNode?.(
      tableOfContents,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK, {
        caretPosition: { nextSibling: true, position: 'INSIDE_START' },
      }),
    );
  }

  insertTableOfLabels(options) {
    const tableOfLabels = DOMElementFactory.buildElement(ELEMENTS.TableOfLabelsElement.IDENTIFIER);
    tableOfLabels.setAttribute('cpt', options.label);
    tableOfLabels.setAttribute('spn', options.showPageNumber);
    tableOfLabels.setAttribute('anr', options.alignNumberRight);
    tableOfLabels.setAttribute('l', options.useLink);
    tableOfLabels.dataset.tabs = JSON.stringify([
      {
        t: 'r',
        l: options.leader,
      },
    ]);
    this.editionMode.handleInsertBlockNode?.(
      tableOfLabels,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK, {
        caretPosition: { nextSibling: true, position: 'INSIDE_START' },
      }),
    );
  }

  insertListOfFigures(options) {
    const listOfFigures = DOMElementFactory.buildElement(ELEMENTS.ListOfFiguresElement.IDENTIFIER);
    this.editionMode.handleInsertBlockNode?.(
      listOfFigures,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK, {
        caretPosition: { nextSibling: true, position: 'INSIDE_START' },
      }),
    );
  }

  insertListOfTables() {
    const listOfTables = DOMElementFactory.buildElement(ELEMENTS.ListOfTablesElement.IDENTIFIER);
    this.editionMode.handleInsertBlockNode?.(
      listOfTables,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK, {
        caretPosition: { nextSibling: true, position: 'INSIDE_START' },
      }),
    );
  }

  insertKeywordsElement() {
    const keywordsElement = DOMElementFactory.buildElement(ELEMENTS.KeywordsElement.IDENTIFIER);
    this.editionMode.handleInsertBlockNode?.(
      keywordsElement,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK, {
        caretPosition: { nextSibling: true, position: 'INSIDE_START' },
      }),
    );
  }

  insertAuthorsElement() {
    const authorsElement = DOMElementFactory.buildElement(ELEMENTS.AuthorsElement.IDENTIFIER);
    this.editionMode.handleInsertBlockNode?.(
      authorsElement,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK, {
        caretPosition: { nextSibling: true, position: 'INSIDE_START' },
      }),
    );
  }

  insertReferenceSectionElement() {
    const referencesSectionElement = DOMElementFactory.buildElement(
      ELEMENTS.ReferencesSectionElement.IDENTIFIER,
    );
    this.editionMode.handleInsertBlockNode?.(
      referencesSectionElement,
      new ActionContext(ActionContext.ACTION.INSERT_BLOCK),
    );
  }

  // #################################################
  //              fields Handling
  //            captions / cross ref
  // #################################################

  insertCaptionElement(options = {}) {
    this.editionMode.handleInsertCaptionOnSelection?.(options);
  }

  editCaptionElement(options = {}) {
    this.editionMode.handleEditSelectedCaption?.(options);
  }

  /**
   *
   * @param {Editor.Data.CrossReferences.PresentationTextOptionsType} options
   */
  insertCrossReferenceElement(options = {}) {
    this.editionMode.handleInsertCrossReferenceOnSelection?.(options);
  }

  /**
   *
   * @param {Editor.Data.CrossReferences.PresentationTextOptionsType} options
   */
  editCrossReferenceElement(options = {}) {
    this.editionMode.handleEditSelectedCrossReference?.(options);
  }

  updateCrossReferenceElement() {
    this.editionMode.handleUpdateCrossReferenceOnSelection?.();
  }

  // #################################################
  //              Tables Operations Handling
  // #################################################
  handleDeleteCells(shiftCells = 'SHIFT_LEFT') {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.DELETE_CELLS,
      shiftCells,
    });
  }

  handleDeleteRows() {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.DELETE_ROWS,
    });
  }

  handleDeleteRowAtIndex(index) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.DELETE_ROWS_INDEX,
      index,
    });
  }

  handleDeleteColumns() {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.DELETE_COLUMNS,
    });
  }

  handleDeleteColumnAtIndex(index) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.DELETE_COLUMNS_INDEX,
      index,
    });
  }

  handleDeleteTable() {
    return this.editionMode.handleDeleteTable?.();
  }

  handleInsertRow(before = false) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.INSERT_ROW,
      before,
    });
  }

  handleInsertRowAtIndex(index) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.INSERT_ROW_INDEX,
      index,
    });
  }

  handleInsertColumn(before = false) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.INSERT_COLUMN,
      before,
    });
  }

  handleInsertColumnAtIndex(index) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.INSERT_COLUMN_INDEX,
      index,
    });
  }

  handleMergeCells() {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.MERGE_CELLS,
    });
  }

  handleSplitCells(splitColumns = 2, splitRows = 1, mergeCells = false) {
    return this.editionMode.handleTableOperation?.({
      operation: this.editionMode.TABLE_OPERATIONS.SPLIT_CELLS,
      splitColumns,
      splitRows,
      mergeCells,
    });
  }

  handleSortRows(sortType) {
    return Logger.warn('Not implemented in Edition V1 !!');
  }

  handleDistribute(type) {
    return Logger.warn('Not implemented in Edition V1 !!');
  }

  /**
   *
   * @param {*} table
   * @param {*} columnWidths in points (pt)
   */
  handleUpdateColumnWidths(table, columnWidths) {
    return this.editionMode.handleUpdateColumnWidths?.(table, columnWidths);
  }

  /**
   *
   * @param table
   * @param rowHeights in points (pt)
   */
  handleUpdateRowHeigths(table, rowHeights) {
    return this.editionMode.handleUpdateRowHeigths?.(table, rowHeights);
  }

  handleUpdateTableSize(table, height, width) {
    return this.editionMode.handleUpdateTableSize(table, height, width);
  }

  handleUpdateTableProperties(propertiesData) {
    return this.editionMode.handleUpdateTableProperties(propertiesData);
  }

  // #################################################
  //              Images Operations Handlers
  // #################################################
  handleInsertImage(image) {
    return this.editionMode.handleInsertImage(image);
  }

  handleChangeImage(image) {
    return this.editionMode.handleChangeImage(image);
  }

  handleUpdateImageSize(image, height, width) {
    return this.editionMode.handleUpdateImageSize(image, height, width);
  }

  handleUpdateImageProperties(properties) {
    return this.editionMode.handleUpdateImageProperties(properties);
  }

  // #################################################
  //                Citations handlers
  // #################################################
  handleInsertCitation(citationId) {
    this.editionMode.handleInsertCitation?.(citationId);
  }

  // #################################################
  //                Notes handlers
  // #################################################
  startCreatingNote(type) {
    this.editionMode.startCreatingNote?.(type);
  }

  async handleInsertNote(type, text) {
    const id = await this.dataManager.notes.addNote(null, type, text);
    if (id) {
      this.editionMode.handleInsertNote?.(type, id);
    }
  }

  // #################################################
  //              Hyperlink handlers
  // #################################################

  handleInsertHyperlink(links, url, showTextToDisplay, textToDisplay) {
    this.editionMode.handleInsertHyperlink?.(links, url, showTextToDisplay, textToDisplay);
  }

  handleDeleteHyperlink() {
    this.editionMode.handleDeleteHyperlink?.();
  }

  // #################################################
  //              Comments handlers
  // #################################################
  handleAddComment(comment) {
    this.editionMode.handleAddComment?.(comment);
  }

  handleAddTemporaryComment() {
    this.editionMode.handleAddTemporaryComment?.();
  }

  handleRemoveTemporaryComment() {
    this.editionMode.handleRemoveTemporaryComment?.();
  }

  // #################################################
  //              Equation handlers
  // #################################################
  handleInsertEquation(value) {
    this.editionMode.handleInsertEquation(value);
  }

  handleEditEquation() {
    this.editionMode.handleEditEquation();
  }

  handleUpdateEquation(blockId, elementId, value) {
    this.editionMode.handleUpdateEquation(elementId, value);
  }

  // #################################################
  //            copy/paste functions
  // #################################################
  removePasteOptions() {
    this.clipboard.removePasteOptions();
  }

  updatePasteOptions() {
    this.clipboard.updatePasteOptions();
  }

  async handlePasteOptions(pasteOptionsStyle) {
    this.clipboard.removePasteOptions();

    await HistoryManager.getInstance()
      .undo({
        showNotifications: false,
      })
      .then(() => {
        this.editionMode.handlePasteEvent(null, pasteOptionsStyle);
      });
  }

  // #################################################
  //              other functions
  // #################################################
}
