import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Label } from '_common/components/Forms';
import ActiveDetails from './ActiveDetails/ActiveDetails';
import { Divider } from 'dodoc-design-system';
import styles from './Block.module.scss';

import type { ActiveDetailsProps } from './ActiveDetails/ActiveDetails';

type BlockProps = {
  labelContent: string; //TODO:TS Set type to TranslationId when translation Ids are all inline
  activeDetailsProps: ActiveDetailsProps;
  children?: ReactNode;
};

const Block = ({ labelContent, activeDetailsProps, children }: BlockProps) => {
  const intl = useIntl();
  return (
    <div className={styles.inlineToggleContainer}>
      <Label
        labelText={intl.formatMessage({
          id: labelContent,
        })}
        style={{ marginBottom: '0' }}
      />
      <Divider margin="1rem 0 2rem 0" />
      <div className={styles.row}>
        <ActiveDetails {...activeDetailsProps} />
        {children}
      </div>
    </div>
  );
};

export default Block;
