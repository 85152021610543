import styles from '../Menus.module.scss';
import Options from '../Options/Options';

import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';
import type { Menu } from 'Editor/modals/ExportIntegrationModal/Menus/MenuTypes';

type StaticMenusProps<T extends ExportIntegrationModal.ExportType> = Menu.BaseProps<T>;

const StaticMenus = <T extends ExportIntegrationModal.ExportType>({
  info,
  selectedOption,
  setSelectedOption,
  utilsMenusComponent,
  isOptionOpen,
  setOptionState,
}: StaticMenusProps<T>) => {
  const handleChangeOption = (elem: ExportIntegrationModal.Element) => {
    if (selectedOption.id !== elem.id) {
      setSelectedOption(elem);
      switch (elem.id) {
        case 'allFiles':
          info.getContent(elem, { size: 20 });
          break;
        case 'favourites':
          info.listFavouritesContent(elem);
          break;
        case 'recent':
          info.listRecentsContent(elem);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={styles.menus}>
      <div className={styles.instancesContainer}>
        {utilsMenusComponent.options.map((elem) => (
          <Options
            info={info}
            elem={elem}
            key={elem.name}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            onClick={() => handleChangeOption(elem)}
            utilsMenusComponent={utilsMenusComponent}
            isOptionOpen={isOptionOpen}
            setOptionState={setOptionState}
          />
        ))}
      </div>
    </div>
  );
};

export default StaticMenus;
