/* eslint-disable class-methods-use-this */
import { isEqual } from 'lodash';
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { Descendant } from 'slate';

const DEFAULT = {
  content: null,
  author: null,
  time: null,
  status: null,
  location: null,
  priority: null,
  comments: [],
  votes: [],
  id: null,
};
type user = { [index: string]: string[] };

export class Comment extends RealtimeObject<Comments.CommentData> {
  private _nodesPermissions: user | undefined;
  private tempData: any = { ...DEFAULT };

  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'comments');
    this.tempData = { ...this.tempData, id };
  }

  set nodesPermissions(value: any) {
    if (!isEqual(this._nodesPermissions, value)) {
      this._nodesPermissions = value;

      if (!this.loaded) {
        this.emit('UPDATED', this.data);
      }
    }
  }

  get current() {
    const data = this.data;
    return {
      ...data,
      nodesPermissions: this._nodesPermissions,
    };
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = this.get();
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  updateCommentPermissions() {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:NODES:PERMISSIONS',
        {
          commentId: this.id,
        },
        (response) => {
          if (response.success) {
            this.nodesPermissions = response.payload;
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description edits a comment content
   * @author Filipe Assunção
   * @param {String} newContent the new content of the comment
   * @returns {Promise<any>}  Promise with void or error
   */
  editComment(newContent: Descendant[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:EDIT',
        {
          commentId: this.id,
          newContent,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description edits a comment content
   * @author Filipe Assunção
   * @param {CommentPriority} priority the new priority of the comment
   * @returns {Promise<any>}  Promise with void or error
   */
  changeCommentPriority(priority: Comments.CommentPriority): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:PRIORITY:EDIT',
        {
          commentId: this.id,
          priority,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description votes a comment
   * @author Filipe Assunção
   * @param {boolean} vote the vote value
   * @returns {Promise<any>} Promise with void or error
   */
  voteComment(vote: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:VOTE',
        {
          commentId: this.id,
          vote,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description persists a comment reply
   * @author Filipe Assunção
   * @param {String} reply the reply content
   * @returns {Promise<any>} Promise with void or error
   */
  replyComment(reply: Descendant[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:REPLY:ADD',
        {
          commentId: this.id,
          reply,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description votes a comment reply
   * @author Filipe Assunção
   * @param {String} replyId the id of the reply to vote
   * @param {boolean} vote the vote value
   * @returns {Promise<any>} Promise with void or error
   */
  voteReply(replyId: string, vote: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:REPLY:VOTE',
        {
          commentId: this.id,
          replyId,
          vote,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description edits a comment reply
   * @author Filipe Assunção
   * @param {String} replyId the id of the reply to edit
   * @param {Descendant[]} newContent the reply new content
   * @returns {Promise<any>} Promise with void or error
   */
  editReply(replyId: string, newContent: Descendant[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:REPLY:EDIT',
        {
          commentId: this.id,
          replyId,
          newContent,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description resolves a comment
   * @author Filipe Assunção
   * @returns {Promise<any>} Promise with void or error
   */
  resolveComment(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:RESOLVE',
        {
          commentId: this.id,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description deletes a comment
   * @author Filipe Assunção
   * @returns {Promise<any>} Promise with void or error
   */
  deleteComment(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:DELETE',
        {
          commentId: this.id,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  /**
   * @description deletes a comment reply
   * @author Filipe Assunção
   * @param {String} replyId the id of the reply to delete
   * @returns {Promise<any>} Promise with void or error
   */
  deleteReply(replyId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transport.dispatchEvent(
        'COMMENT:REPLY:DELETE',
        {
          commentId: this.id,
          replyId,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
