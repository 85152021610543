import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  InputField,
  PasswordInput,
  usePopper,
  Modal,
  Link,
  Button,
  Popover,
  Helper,
} from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { AuthService, Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import { closeModal } from '_common/modals/ModalsSlice';
import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { useGetCurrentUserQuery, useSignOutMutation } from '_common/services/api/authority';

import { PasswordCheck } from '_common/components';

import styles from './ChangePasswordModal.module.scss';

const ChangePasswordModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { data: currentUser } = useGetCurrentUserQuery();
  const [signOut] = useSignOutMutation();

  const accounts = useSelector(selectAccounts);
  const userId = useSelector((state) => state.auth.userId);
  const isOpenModal = useSelector((state) => state.modals.open.ChangePasswordModal);

  const [loadingBtn, setLoadingBtn] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [feedback, setFeedback] = useState({ error: '', value: '' });
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [rules, setRules] = useState<{ [key: string]: unknown }[]>([]);
  const { popperProps, referenceProps } = usePopper({
    disabled: rules.length === 0,
    placement: 'left-start',
    trigger: 'focus',
    skidding: 5,
    distance: 30,
  });
  const VALUES_FOR_FEEDBACK: { [key: string]: string } = {
    username: intl.formatMessage({ id: 'settings.user.username' }),
    first_name: intl.formatMessage({ id: 'settings.name.firstName' }),
    last_name: intl.formatMessage({ id: 'settings.name.lastName' }),
    email_address: intl.formatMessage({ id: 'editor.templatePreview.properties.authors.email' }),
  };

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      setLoadingBtn(false);
      setOldPassword('');
      setNewPassword('');
      setResetMessage('');
      setFeedback({ error: '', value: '' });
      setDisabled(true);
    };
  }, [isOpenModal]);

  useEffect(() => {
    if (isOpenModal) {
      setRules((accounts[userId] && accounts[userId].password_validators) || []);
    }
  }, [isOpenModal, accounts, userId]);

  const handleOnOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
    setFeedback({ error: '', value: '' });
  };

  const handleOnNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setFeedback({ error: '', value: '' });
  };

  const handleChangePassword = async () => {
    setLoadingBtn(true);

    try {
      await new AuthService({ errorsExpected: [400] }).passwordChange({
        password: oldPassword,
        new: newPassword,
      });

      notify({
        type: 'success',
        title: 'PASSWORD_UPDATED',
        message: 'YOUR_PASSWORD_WAS_SUCCESSFULLY_UPDATED',
      });

      setLoadingBtn(false);
      close();
    } catch (error) {
      if (AuthService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          setLoadingBtn(false);
          const errors = error?.response?.data.errors[0];
          const key = errors.errors[0];
          setFeedback({ error: key, value: errors[key] });
        } else {
          setLoadingBtn(false);
          Logger.captureException(error);
          const message = 'auth.errors.validPassword';
          setResetMessage(message);
        }
      }
    }
  };

  const handleSignOut = () => {
    signOut(currentUser?.profile.id ?? '');
  };

  const getOldPasswordError = () => {
    if (feedback.error) {
      let msg = '';
      switch (feedback.error) {
        case 'invalid':
          msg = intl.formatMessage({ id: 'settings.password.invalid' });
          break;
        default:
          break;
      }
      return msg;
    }
    return '';
  };

  const getNewPasswordError = () => {
    if (feedback.error) {
      let msg = '';
      switch (feedback.error) {
        case 'invalid':
          msg = '';
          break;
        case 'password_too_similar':
          msg = intl.formatMessage(
            { id: feedback.error.toUpperCase() },
            { name: VALUES_FOR_FEEDBACK[feedback.value] },
          );
          break;
        case 'same_password':
          msg = intl.formatMessage({ id: 'settings.password.same_password' });
          break;
        case 'password_in_history':
          msg = intl.formatMessage({ id: 'PASSWORD_RECENTLY_USED' });
          break;
        case 'password_too_common':
          msg = intl.formatMessage({ id: 'PASSWORD_TOO_COMMON' });
          break;
        default:
          msg = intl.formatMessage({ id: 'ERROR_SCREEN_HEADER' });
          break;
      }
      return msg;
    }
    return '';
  };

  const close = () => dispatch(closeModal('ChangePasswordModal'));

  return (
    <Modal width="75rem" open={!!isOpenModal} onClose={close} testId="change-password">
      <Modal.Header onClose={close}>
        <FormattedMessage id="settings.password.changeYourPassword" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        {resetMessage.length > 0 && (
          <Helper margin="0 0 2rem 0" type="error" testId="change-password-modal-reset-helper">
            <FormattedMessage id={resetMessage} />
          </Helper>
        )}
        <InputField
          label={intl.formatMessage({ id: 'settings.password.oldPassword' })}
          size="large"
          feedback={getOldPasswordError()}
          testId="old-password-field"
        >
          <PasswordInput
            error={feedback.error === 'invalid'}
            ref={inputRef}
            value={oldPassword}
            onChange={handleOnOldPasswordChange}
            autoCompleteOff
            placeholder={intl.formatMessage({ id: 'settings.password.oldPassword' })}
            size="large"
            testId="change-password-old-password"
          />
        </InputField>
        <InputField
          label={intl.formatMessage({ id: 'settings.password.newPassword' })}
          size="large"
          feedback={getNewPasswordError()}
          testId="new-password-field"
        >
          <>
            <div className={styles.password}>
              <PasswordInput
                error={feedback?.error.length > 0 && feedback.error !== 'invalid'}
                value={newPassword}
                onChange={handleOnNewPasswordChange}
                autoCompleteOff
                placeholder={intl.formatMessage({ id: 'settings.password.newPassword' })}
                size="large"
                {...referenceProps}
                testId="change-password-new-password"
              />
            </div>
            <Popover {...popperProps} testId="password-checker-popper">
              <PasswordCheck rules={rules} password={newPassword} setDisabled={setDisabled} />
            </Popover>
          </>
        </InputField>
        <p className={styles.forgotPassword}>
          <Link
            href="/auth/recover"
            onClick={handleSignOut}
            size="small"
            testId="forgot-password-link"
          >
            <FormattedMessage id="settings.password.forgotPassword" />
          </Link>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="change-password-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          loading={loadingBtn}
          onClick={handleChangePassword}
          disabled={oldPassword === '' || newPassword === '' || (rules.length > 0 && disabled)}
          testId="change-password-submit-button"
        >
          <FormattedMessage id="settings.password.changePassword" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
