import { MouseEventHandler, useEffect, useRef, useState, memo } from 'react';

import { useDebounce, useDispatch, useEffectOnUpdate, useSelector } from '_common/hooks';
import { stringToRichText, richTextToString } from 'utils';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useAnnotationBox, useAnnotationContext } from './AnnotationContext';
import {
  selectAnnotation,
  setEditingAnnotation,
  setTextboxSelectedStyles,
} from 'PDF/redux/PDFAnnotationsSlice';

import { RichTextEditor } from '_common/components';
import {
  RichFormat,
  RichTextEditorHandler,
} from '_common/components/RichTextEditor/RichTextEditor';

import useRTEEventHandler from './useRTEEventHandler';
import BaseAnnotation from './BaseAnnotation';
import TransformWrapper from './TransformWrapper';

const ALLOW_RESIZE_NODES = ['e', 'w'] as const;

const FreeTextAnnotation = () => {
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();
  const { annotation, isSelected, viewport } = useAnnotationContext();
  const { canEditAnnotation } = usePDFPermissions();
  const box = useAnnotationBox();

  const editorRef = useRef<RichTextEditorHandler>(null);
  useRTEEventHandler(editorRef);

  const isRightPanelOpen = useSelector((state) => !!state.pdf.general.rightPanel);
  const isEditing = useSelector((state) => state.pdf.annotations.editing === annotation.id);
  const versionHistory = useSelector((state) => state.pdf.general.versionHistory);
  const hasSelection = useSelector((state) => state.pdf.annotations.selected);

  const [currValue, setCurrValue] = useState(richTextToString(annotation.content?.content));
  const debouncedValue = useDebounce(currValue, 250);
  useEffectOnUpdate(() => {
    pdfManager.editAnnotationContent(
      annotation.pageNumber,
      annotation.id,
      stringToRichText(debouncedValue),
    );
  }, [debouncedValue]);

  useEffect(() => {
    if (!isRightPanelOpen) {
      setCurrValue(richTextToString(annotation.content?.content));
    }
  }, [isRightPanelOpen]);

  useEffect(() => {
    //Don't reset edit annotation if has a new selection
    if (!isSelected && !hasSelection) {
      dispatch(setEditingAnnotation({ id: null, isTask: annotation.subtype === 'Task' }));
    }
  }, [isSelected]);

  const handleSelectionChange = (selectionStyles: RichFormat) => {
    dispatch(setTextboxSelectedStyles(selectionStyles));
  };

  const handleBaseClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    dispatch(selectAnnotation(annotation.id));
  };

  const handleBaseDblClick = () => {
    if (canEditAnnotation(annotation)) {
      dispatch(setEditingAnnotation({ id: annotation.id, isTask: annotation.subtype === 'Task' }));
    }
  };

  const border = `${
    annotation.border?.width ? annotation.border.width * viewport.scale : 1 * viewport.scale
  }px solid ${annotation.color?.stroke}`;

  return (
    <BaseAnnotation onClick={handleBaseClick} onDoubleClick={handleBaseDblClick}>
      <TransformWrapper
        allowResize={
          canEditAnnotation(annotation) && !versionHistory && !isEditing ? ALLOW_RESIZE_NODES : []
        }
        allowMove={canEditAnnotation(annotation) && !versionHistory && !isEditing}
      >
        <span
          style={{
            display: 'flex',
            flex: '1',
            backgroundColor: annotation.color?.fill ?? 'transparent',
            border: annotation.color?.stroke ? border : 'none',
            minHeight: annotation.subtype === 'FreeText' ? box.height : 0,
            overflow: 'hidden',
          }}
        >
          {canEditAnnotation(annotation) && !versionHistory && isEditing && !isRightPanelOpen ? (
            <RichTextEditor
              key={viewport.scale}
              ref={editorRef}
              initialValue={currValue}
              variant="transparent"
              onChange={setCurrValue}
              onSelectionChanged={handleSelectionChange}
              stretched
              placeholder=""
              scale={viewport.scale}
              testId={`free-text-${annotation.id}-rich-text-editor`}
            />
          ) : (
            <RichTextEditor
              key={viewport.scale}
              variant="transparent"
              initialValue={richTextToString(annotation.content?.content)}
              readOnly
              expanded
              stretched
              scale={viewport.scale}
              testId={`free-text-${annotation.id}-rich-text-editor`}
            />
          )}
        </span>
      </TransformWrapper>
    </BaseAnnotation>
  );
};

export default memo(FreeTextAnnotation);
