import { PresentationSelectionUtils } from './PresentationSelectionUtils';

type Timeouts = {
  selectionChanged: NodeJS.Timeout | null;
};

const SELECTION_CHANGE_TIMEOUT = 100;

export class SelectionManager {
  private timeouts: Timeouts;

  private savedRange: Presentation.Selection.PresentationRange | null;

  constructor() {
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);

    this.savedRange = null;

    this.timeouts = {
      selectionChanged: null,
    };
  }

  start() {
    this.addListener();
  }

  stop() {
    this.removeListener();
  }

  destroy() {
    this.removeListener();
  }

  private addListener() {
    document.addEventListener('selectionchange', this.onSelectionChange);
  }

  private removeListener() {
    document.removeEventListener('selectionchange', this.onSelectionChange);
  }

  private onSelectionChange(event: Event) {
    if (this.timeouts.selectionChanged) {
      clearTimeout(this.timeouts.selectionChanged);
    }

    this.timeouts.selectionChanged = setTimeout(this.selectionChanged, SELECTION_CHANGE_TIMEOUT);
  }

  private selectionChanged() {
    logger.info('selectionChanged');

    this.saveRange();
  }

  saveRange() {
    const range = PresentationSelectionUtils.getRange();
    if (range && this.isSelectionInPresentation(range)) {
      this.savedRange = range;
      logger.info('savedRange', this.savedRange);
    }
  }

  restoreRange() {
    if (!this.isSelectionInPresentation()) {
      const range = this.savedRange;
      if (range) {
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  }

  clearRange() {
    const selection = PresentationSelectionUtils.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  }

  isSelectionInPresentation(
    range:
      | Presentation.Selection.PresentationRange
      | undefined = PresentationSelectionUtils.getRange(),
  ) {
    if (range) {
      let node = range.commonAncestorContainer;
      while (!this.isNodeAnElement(node) && node.parentElement) {
        node = node.parentElement;
      }
      if (this.isNodeAnElement(node)) {
        const popper = node.closest('[data-popper-type="popper"]');
        if (popper) {
          return false;
        }
        const presentationContainer = node.closest('div#slidesContainer');
        if (presentationContainer) {
          return true;
        }
      }
    }

    return false;
  }

  isSelectionCollapsed(
    range:
      | Presentation.Selection.PresentationRange
      | undefined = PresentationSelectionUtils.getRange(),
  ) {
    return range && range.collapsed;
  }

  isNodeAnElement(node: Node): node is Element {
    return node.nodeType === Node.ELEMENT_NODE;
  }
}
