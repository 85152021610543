import { Tooltip } from 'dodoc-design-system';

import MathML from 'Editor/modals/EquationsModal/MathML';
import { Symbol } from 'Editor/modals/EquationsModal/ToolbarElements';
import { useEquationsContext } from 'Editor/modals/EquationsModal/EquationsContext';

import styles from './CharGridItem.module.scss';

type CharGridItemProps = {
  element: Symbol['elements'][number];
  testId: string;
};

const CharGridItem = ({ element, testId }: CharGridItemProps) => {
  const { onElementInsertion } = useEquationsContext();

  const onClick = () => {
    onElementInsertion(element);
  };

  return (
    <div className={styles.char_grid_item} onClick={onClick}>
      {element.mathml && element.mathml.includes('<math') ? (
        <MathML element={element} testId={testId} />
      ) : (
        <Tooltip content={element.label} testId={`${testId}-tooltip`}>
          <div>{element.code}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default CharGridItem;
