import { Descendant } from 'slate';

export const Status = {
  IN_PROGRESS: 'pr',
  TO_DO: 'td',
  DONE: 'd',
} as const;
export type StatusValue = typeof Status[keyof typeof Status];

export type Vote = {
  user: string;
  value: 0 | 1;
  time: string;
};

export type Reply = {
  id: string;
  c: Descendant[];
  u: string;
  t: {
    c: string;
  };
  v: Vote[];
};

export type TaskData = {
  _id?: string;
  i: number;
  asg: string | null;
  u: string;
  p_id: string;
  t: {
    c: string;
    d: string | null;
    dlt?: string;
  };
  s: StatusValue;
  d: Descendant[];
  w: string[];
  r?: Reply[];
};
export type TasksData = { [task: string]: TaskData };

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
export type TaskEditData = DeepPartial<TaskData>;

export const TaskKeys = {
  INDEX: 'i',
  ASSIGNEE: 'asg',
  USER: 'u',
  PARAGRAPH_ID: 'p_id',
  TIME: 't',
  VOTES: 'v',
  STATUS: 's',
  DESCRIPTION: 'd',
  WATCHERS: 'w',
  REPLY: 'r',
} as const;

export type TaskDataLongProps = {
  dueDate: string | null;
  description: Descendant[];
  assignee: string | null;
  i?: number;
  user: string;
};
