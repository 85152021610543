import { InputField, RadioButton } from 'dodoc-design-system';
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import styles from './RadioButtonsField.module.scss';

type RadioButtonsFieldProps = {
  options: {
    label: string;
    id: string;
  }[];
  value: {
    label: string;
    id: string;
  };
  setValue: (v: { label: string; id: string }) => void;
  label: string;
  margin?: CSSProperties['margin'];
  testId: string;
  isDisabled: boolean;
};

const RadioButtonsField = ({
  options,
  value,
  setValue,
  label,
  margin,
  testId,
  isDisabled,
}: RadioButtonsFieldProps) => {
  const intl = useIntl();
  return (
    <>
      <InputField
        label={intl.formatMessage({ id: label })}
        size="large"
        feedback={false}
        margin={margin}
        testId={`${testId}-field`}
        disabled={isDisabled}
      >
        {options.map(({ id, label }: { id: string; label: string }) => (
          <div className={styles.options} key={id}>
            <RadioButton
              key={id}
              onChange={() => {
                setValue({ id: id, label: label });
              }}
              checked={value.id === id}
              size="small"
              testId={`radio-button-${label.toLowerCase()}`}
              disabled={isDisabled}
            >
              {label}
            </RadioButton>
          </div>
        ))}
      </InputField>
    </>
  );
};

export default RadioButtonsField;
