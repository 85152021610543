import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, InputField, RadioButton } from 'dodoc-design-system';

import { parseMeasurement } from 'utils';

import { MeasureInput } from '_common/components';
import { convertToDataUnit, convertToDisplayUnit } from '../utils';

import styles from './Section.module.scss';

type TableAlignmentProps = Pick<Editor.Styles.TableProperties, 'alignment' | 'leftIndentation'> &
  SectionProps;

const DEFAULT_PROPERTIES: Pick<Editor.Styles.TableProperties, 'alignment' | 'leftIndentation'> = {
  alignment: 'l',
  leftIndentation: 0,
};

const TableAlignment = ({ alignment, leftIndentation, updateData }: TableAlignmentProps) => {
  const intl = useIntl();

  const alignmentOptions = useMemo<
    { label: string; value: Editor.Styles.TableProperties['alignment'] }[]
  >(
    () => [
      { label: intl.formatMessage({ id: 'LEFT' }), value: 'l' },
      { label: intl.formatMessage({ id: 'CENTER' }), value: 'c' },
      { label: intl.formatMessage({ id: 'RIGHT' }), value: 'r' },
    ],
    [],
  );

  const [localAlignment, setLocalAlignment] = useState(
    alignmentOptions.find((option) => option.value === (alignment ?? DEFAULT_PROPERTIES.alignment)),
  );
  const [localLeftIndent, setLocalLeftIndent] = useState(
    convertToDisplayUnit(leftIndentation ?? DEFAULT_PROPERTIES.leftIndentation),
  );

  const handleTableAlignmentChange = (newAlignment: Editor.Styles.TableProperties['alignment']) => {
    if (newAlignment === localAlignment?.value) {
      return;
    }

    setLocalAlignment(alignmentOptions.find((option) => option.value === newAlignment));
    updateData({ property: 'alignment', value: newAlignment });
  };

  const handleLeftIndentChange = (newValue: string) => {
    const normalizedIndent =
      parseMeasurement(newValue, 'cm', {
        defaultUnit: 'cm',
      }) ?? 0;

    setLocalLeftIndent(normalizedIndent);
    updateData({ property: 'leftIndentation', value: convertToDataUnit(normalizedIndent) });
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'TABLE_ALIGNMENT' })}
      initialCollapsed={false}
      contentMargin="1rem"
      testId="tableProperties-table-alignment-accordion"
    >
      <div className={styles.root}>
        <div className={`${styles.column} ${styles.radioGroup}`}>
          {alignmentOptions.map((option) => (
            <RadioButton
              key={`tableAlignment-${option.value}`}
              size="x-small"
              checked={option.value === localAlignment?.value}
              onChange={() => handleTableAlignmentChange(option.value)}
              testId={`table-alignment-${option.label.toLowerCase()}-radio-button`}
            >
              {option.label}
            </RadioButton>
          ))}
        </div>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'LEFT_INDENT' })}
            testId="tableProperties-left-ident-field"
          >
            <div className={styles.row}>
              <MeasureInput
                size="medium"
                value={`${localLeftIndent}`}
                onChange={handleLeftIndentChange}
                clearable={false}
                placeholder=""
                valueSuffix="cm"
                avoidEmpty
                testId="tableProperties-left-ident"
              />
            </div>
          </InputField>
        </div>
      </div>
    </Accordion>
  );
};

export default TableAlignment;
