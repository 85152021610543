// https://redux.js.org/usage/writing-tests#components
import { PropsWithChildren, ReactElement, Suspense } from 'react';
import { render as rtlRender, RenderOptions } from '@testing-library/react';
import { PreloadedState } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import Intl from 'Intl/Intl';
import { Route, Router, Switch } from 'react-router-dom';
import { getStore } from '_common/redux/store';
import history from 'router/history';
import App from 'App/App';
import { loginSuccessful } from 'Auth/redux/authSlice';
import mockedData from './mockedData';
import SessionStorage from '../SessionStorage';
import { setAppInformation } from 'App/redux/appSlice';
import { authenticationSuccessful } from 'Auth/redux/localStorageSlice';

export type RenderExtraOptions = {
  options?: Omit<RenderOptions, 'queries'>;
  preloadedState?: PreloadedState<RootState>;
  /**
   * In case URL params are necessary, use the param variable here
   * @example "/settings/role/:id"
   */
  route?: string;
  /**
   * In case URL params are necessary, use the actual params value here
   * @example "/settings/role/123123123"
   */
  entry?: string;
  loggedIn?: boolean;
  isIEnvision?: boolean;
};

export let store = getStore(undefined, { immutableCheck: true, serializableCheck: true });

function render(
  ui: ReactElement,
  {
    options,
    preloadedState,
    route = '/',
    entry,
    loggedIn = true,
    isIEnvision = false,
  }: RenderExtraOptions = {
    route: '/',
    loggedIn: true,
    isIEnvision: false,
  },
) {
  // Will always use the same store that the app uses
  store = getStore(preloadedState, { immutableCheck: true, serializableCheck: true });
  if (entry) {
    history.push(entry);
  }
  if (loggedIn) {
    store.dispatch(loginSuccessful({ id: mockedData.currentUser.profile.id }));
    store.dispatch(
      authenticationSuccessful({
        ...mockedData.currentUser.profile,
        ...mockedData.currentUserToken,
      }),
    );
    SessionStorage.setToken(mockedData.currentUserToken.token ?? '');
  }
  if (isIEnvision) {
    store.dispatch(
      setAppInformation({
        third_party: { name: 'ienvision', image: {}, style: {}, label: '', url: '' },
        actions: {},
        extra: {},
      }),
    );
  }
  // If react-router was updated, this would be the way to mock useNavigate
  // const navigationMock = jest.fn();
  // jest.spyOn(require('react-router'), 'useNavigate').mockImplementation(() => navigationMock);

  function Wrapper({ children }: PropsWithChildren<{}>) {
    return (
      <Suspense fallback={<div>loading...</div>}>
        <Router history={history}>
          <Provider store={store}>
            <Intl>
              <App>
                <Switch>
                  <Route path={route}>{children}</Route>
                </Switch>
              </App>
            </Intl>
          </Provider>
        </Router>
      </Suspense>
    );
  }
  return {
    ...rtlRender(ui, { wrapper: Wrapper, ...options }),
    store,
    history /* navigationMock */,
  };
}

export default render;
