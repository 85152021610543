import { ChangeEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from '_common/hooks';
import { Tooltip, Divider } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import {
  EditorIconButton,
  ContentStructure,
  Alignment,
  BulletList,
  NumberedList,
  OutlineList,
  FontFamily,
  FontSize,
  FontColor,
  FontBackgroundColor,
  ParagraphBackgroundColor,
} from 'Editor/components';
import InsertImageButton from 'Editor/pages/EditorPage/TopBarComponent/Toolbar/ToolbarButtons/InsertImageButton';

import BoldToggleButton from './BoldToggleButton';
import ItalicToggleButton from './ItalicToggleButton';
import UnderlineToggleButton from './UnderlineToggleButton';

import styles from './ToolbarButtons.module.scss';
import { InteractionController } from '_common/components';

const ToolbarButtons = () => {
  const intl = useIntl();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const canUndo = useSelector((state) => state.editor.status.undo);
  const canRedo = useSelector((state) => state.editor.status.redo);

  const handleImageUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target;

    if (files) {
      EditorManager.getInstance().handleInsertImage(files[0]);
    }

    e.target.value = '';
  };

  const indent = () => EditorManager.getInstance().increaseIndent();
  const outdent = () => EditorManager.getInstance().decreaseIndent();

  return (
    <div className={styles.root}>
      <Tooltip
        content={
          canUndo
            ? intl.formatMessage({ id: 'editor.errors.undo.title' })
            : intl.formatMessage({ id: 'NO_ACTIONS_TO_UNDO' })
        }
        testId="undo-tooltip"
        placement="left"
      >
        <EditorIconButton
          icon="Undo"
          onClick={() => {
            EditorManager.getInstance().undoListener();
          }}
          disabled={isReadOnlyMode || isPageLayout || !canUndo}
          testId="undo-button"
        />
      </Tooltip>
      <Tooltip
        content={
          canRedo
            ? intl.formatMessage({ id: 'editor.errors.redo.title' })
            : intl.formatMessage({ id: 'NO_ACTIONS_TO_REDO' })
        }
        testId="redo-tooltip"
        placement="left"
      >
        <EditorIconButton
          icon="Redo"
          onClick={() => {
            EditorManager.getInstance().redoListener();
          }}
          disabled={isReadOnlyMode || isPageLayout || !canRedo}
          testId="redo-button"
        />
      </Tooltip>
      <Divider vertical margin="0 0.5rem" />
      <InteractionController
        environment="editor"
        rules={[
          {
            interaction: 'editor_toolbar_heading',
            actions: ['editor_writing_heading_select'],
            preconditions: ['editor_writing_selectHeading'],
          },
        ]}
      >
        <ContentStructure />
      </InteractionController>

      <Divider vertical margin="0 0.5rem" />
      <InteractionController
        environment="editor"
        rules={[
          {
            interaction: 'editor_toolbar_font',
            actions: ['editor_writing_font_select'],
            preconditions: ['editor_writing_selectFont'],
          },
        ]}
      >
        <FontFamily isReadOnlyMode={isReadOnlyMode || isPageLayout} />
      </InteractionController>
      <Divider vertical margin="0 0.5rem" />
      <FontSize isReadOnlyMode={isReadOnlyMode || isPageLayout} />
      <Divider vertical margin="0 0.5rem" />
      <BoldToggleButton />
      <ItalicToggleButton />
      <UnderlineToggleButton />
      <FontColor isReadOnlyMode={isReadOnlyMode || isPageLayout} />
      <FontBackgroundColor isReadOnlyMode={isReadOnlyMode || isPageLayout} />
      <ParagraphBackgroundColor isReadOnlyMode={isReadOnlyMode || isPageLayout} />
      <Divider vertical margin="0 0.5rem" />
      <Alignment />
      <Divider vertical margin="0 0.5rem" />
      <Tooltip
        content={intl.formatMessage({ id: 'editor.toolbar.contentStructure.UL' })}
        testId="bullet-list-tooltip"
      >
        <span>
          <BulletList />
        </span>
      </Tooltip>
      <Tooltip
        content={intl.formatMessage({ id: 'editor.toolbar.contentStructure.OL' })}
        testId="numbered-list-tooltip"
      >
        <span>
          <NumberedList />
        </span>
      </Tooltip>
      <Tooltip content={intl.formatMessage({ id: 'OUTLINE_LIST' })} testId="outline-list-tooltip">
        <span>
          <OutlineList />
        </span>
      </Tooltip>
      <Divider vertical margin="0 0.5rem" />
      <Tooltip content={intl.formatMessage({ id: 'INDENT' })} testId="indent-tooltip">
        <EditorIconButton
          id="editor.toolbar.indent"
          icon="ListIncrease"
          onClick={indent}
          disabled={isReadOnlyMode || isPageLayout}
          testId="indent-toggle"
        />
      </Tooltip>
      <Tooltip content={intl.formatMessage({ id: 'OUTDENT' })} testId="outdent-tooltip">
        <EditorIconButton
          id="editor.toolbar.outdent"
          icon="ListDecrease"
          onClick={outdent}
          disabled={isReadOnlyMode || isPageLayout}
          testId="outdent-toggle"
        />
      </Tooltip>
      <Divider vertical margin="0 0.5rem" />
      <Tooltip content={intl.formatMessage({ id: 'INSERT_IMAGE' })} testId="insert-image-tooltip">
        <InsertImageButton disabled={isReadOnlyMode || isPageLayout} onChange={handleImageUpload} />
      </Tooltip>
    </div>
  );
};

export default ToolbarButtons;
