import { HeadersAndFootersCompoundModel } from 'Editor/services/DataManager/controllers/HeadersAndFooters/HeadersAndFootersController';
import { BaseViewModel } from '../BaseViewModel';
import { BlockViewModel } from '../BlockViewModel';
import { PageViewModel } from '../PageViewModel';

export class HeaderViewModel extends BaseViewModel {
  typeName = 'HeaderViewModel';

  protected model?: HeadersAndFootersCompoundModel;
  view?: HTMLElement;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);

    this.model = this.Data.headersAndFooters?.getSectionHeader(id);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);
    this.handleModelLoad = this.handleModelLoad.bind(this);
    this.model?.on('UPDATE', this.handleModelUpdate);
    this.render();
  }

  private handleModelUpdate(data: any | null) {
    this.render();
  }

  private handleModelLoad() {
    this.render();
  }

  async bindView(view: Editor.Visualizer.BaseView | null) {
    if (view) {
      this.removeAllChildren();
      this.view = view;
      //@ts-expect-error
      this.view.vm = this;
      await this.render();
    }
  }

  private async appendChild(viewModel: BlockViewModel) {
    if (this.view) {
      if (!viewModel.loaded) {
        await viewModel.awaitForEvent('LOADED');
      }
      viewModel.render();
      this.children.push(viewModel);
      const view = viewModel.getRootView();
      if (view) {
        this.view.appendChild(view);
        this.Visualizer.tabulator?.tabulate(viewModel);
        viewModel.triggerPendingEvent('RENDERED');
      }
    }
    viewModel.parent = this;
  }

  async render() {
    if (this.view) {
      this.removeAllChildren();
      while (this.children.length) {
        this.children.shift()?.dispose();
      }
      let header;
      if ((this.parent as PageViewModel)?.isFirstPage) {
        header = this.model?.getPageHeader('FIRST_PAGE');
      } else if ((this.parent as PageViewModel)?.side() === 'VERSO') {
        header = this.model?.getPageHeader('VERSO');
      } else if ((this.parent as PageViewModel)?.side() === 'RECTO') {
        header = this.model?.getPageHeader('RECTO');
      }
      const childNodes: string[] = header?.childNodes || [];
      if (childNodes.length) {
        let vm: BlockViewModel | undefined;
        for (let index = 0; index < childNodes.length; index++) {
          vm = this.Visualizer.viewModelFactory?.get(childNodes[index], true) as BlockViewModel;
          if (vm) {
            await this.appendChild(vm);
          }
        }
      } else {
        this.view.style.visibility = 'hidden';
      }
    }
    return this.view;
  }

  dispose() {
    if (this.model) {
      // unbind model
      this.model.off('UPDATE', this.handleModelUpdate);
    }
    if (this.view && this.view.parentNode) {
      this.view.parentNode.removeChild(this.view);
    }
    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
