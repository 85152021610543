import { useState, useEffect, useReducer, useMemo, useCallback, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditorService } from '_common/services';
import {
  Icon,
  Button,
  Spinner,
  Input,
  EmptyState,
  Helper,
  Switch,
  Tooltip,
} from 'dodoc-design-system';

import { useDebounce, useSelector } from '_common/hooks';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal, closeModal } from '_common/modals/ModalsSlice';
import useConditionalData from '_common/modals/ExportDocumentModal/useConditionalData';
import { useApplyTemplateMutation } from '_common/services/api/realtime';
import EditorManager from 'Editor/services/EditorManager';
import AppOverlay from '../AppOverlay/AppOverlay';
import TabMenu from '../TabMenu/TabMenu';
import MenuItem from '../TabMenu/MenuItem/MenuItem';
import Template from './Template';
import styles from './TemplateGallery.module.scss';

const SEARCH_INPUT_STYLES = { marginLeft: 'auto', width: '28rem' };

type InitialStateProps = {
  loading: boolean;
  data: DocumentTemplate[] | null;
  error: string | null;
};
type State = {
  data: DocumentTemplate[] | null;
  loading: boolean;
  error: string | null;
};

type Action =
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_DATA'; payload: DocumentTemplate[] };

const initialState: InitialStateProps = {
  loading: true,
  error: null,
  data: null,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        error: null,
        loading: false,
        data: action.payload,
      };
    case 'SET_ERROR':
      return {
        loading: false,
        error: action.payload,
        data: null,
      };
    default:
      return state;
  }
};

type TemplateGalleryProps = {
  objectId?: ObjectId;
  close: () => void;
};

const TemplateGallery = ({ objectId, close }: TemplateGalleryProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate | undefined>(undefined);
  const [{ loading, data, error }, dispatchReducer] = useReducer(reducer, initialState);
  const [filterValue, setFilterValue] = useState('');
  const [removeInlineStyles, setRemoveInlineStyles] = useState(false);
  const debouncedFilterValue = useDebounce(filterValue, 500);

  const isEditor = useSelector((state) => state.editor.status.visible);
  const { template: activeTemplate } = useConditionalData();
  const [applyTemplate] = useApplyTemplateMutation();

  const filteredData = useMemo(() => {
    if (!data) {
      return null;
    }
    if (debouncedFilterValue === '') {
      return data;
    }

    return data.filter((template: { name: string }) =>
      template.name.toLowerCase().includes(debouncedFilterValue.toLowerCase()),
    );
  }, [debouncedFilterValue, data]);

  const setData = (data: DocumentTemplate[]) =>
    dispatchReducer({ type: 'SET_DATA', payload: data });
  const setError = (error: any) => dispatchReducer({ type: 'SET_ERROR', payload: error });
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setFilterValue(e.target.value),
    [setFilterValue],
  );

  useEffect(() => {
    new EditorService()
      .listInstallledTemplates()
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const apply = useCallback(() => {
    const confirmationModalFlow = (objectId?: ObjectId) => {
      dispatch(closeModal('ExportDocumentModal'));
      dispatch(
        openAndUpdateModal({
          modal: 'ConfirmationModal',
          data: {
            message: 'CHANGE_DOCUMENT_TEMPLATE_MESSAGE',
            title: 'CHANGE_DOCUMENT_TEMPLATE',
            confirmButtonTextId: 'CHANGE_DOCUMENT_TEMPLATE',
            cancelButtonTextId: 'global.cancel',
            confirmButtonType: 'primary',
            actionCode: 'changeDocumentTemplate',
            actionValue: {
              removeInlineStyles,
              templateId: selectedTemplate?.id,
              objectId: objectId,
            },
            width: '60rem',
            headerType: 'information',
            cancelButtonShow: true,
          },
        }),
      );
    };

    if (isEditor) {
      EditorManager.getInstance()
        .getApprovedNodes()
        .then((response: string | string[]) => {
          if (response.length > 0) {
            confirmationModalFlow();
          } else {
            if (selectedTemplate?.id) {
              EditorManager.getInstance().updateTemplateStyles(
                selectedTemplate.id,
                removeInlineStyles,
              );
            }
          }
        });
    } else if (selectedTemplate && objectId) {
      applyTemplate({
        template: selectedTemplate.id,
        forceReopen: false,
        removeInlineStyles,
        objectId,
      });
    }

    close();
  }, [selectedTemplate, close, removeInlineStyles]);

  return (
    <AppOverlay>
      <div className={styles.root}>
        <div className={styles.header}>
          <FormattedMessage id="templates.gallery.title" />
          <div
            style={{ cursor: 'pointer' }}
            onClick={close}
            data-testid="template-galley-close-icon"
          >
            <Icon icon="CloseGrey" size={24} />
          </div>
        </div>
        <TabMenu
          menuId="TemplateGallery"
          extra={
            <div style={SEARCH_INPUT_STYLES}>
              <Input
                prefix="NavSearchGrey"
                placeholder={intl.formatMessage({ id: 'global.search' })}
                value={filterValue}
                onChange={onInputChange}
                size="medium"
                testId="template-gallery-search"
              />
            </div>
          }
        >
          <MenuItem
            id="TemplateGallery"
            text={intl.formatMessage({ id: 'TENANT_TEMPLATES' })}
            className={styles.item}
          >
            {error && (
              <Helper type="warning" testId="template-gallery-error-helper">
                <FormattedMessage id="DOCUMENT_TEMPLATE_GALLERY_LIST_ERROR" />
              </Helper>
            )}
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner size="medium" testId="template-gallery-loading" />
              </div>
            )}
            {!loading && filteredData && filteredData.length > 0 && (
              <div className={styles.templates}>
                {filteredData.map((template: DocumentTemplate) => (
                  <Template
                    key={template.id}
                    template={template}
                    selectTemplate={setSelectedTemplate}
                    active={
                      selectedTemplate
                        ? selectedTemplate.id === template.id
                        : activeTemplate === template.id
                    }
                  />
                ))}
              </div>
            )}
            {!loading && filteredData && filteredData.length === 0 && (
              <div style={{ margin: 'auto' }}>
                <EmptyState
                  size="large"
                  icon="NoSearchResults"
                  title={
                    data?.length === 0
                      ? intl.formatMessage({ id: 'NO_DOCUMENT_TEMPLATES_AVAILABLE' })
                      : intl.formatMessage({ id: 'SEARCH_EMPTY_TITLE' })
                  }
                  testId="template-gallery-empty-list"
                >
                  {data?.length === 0 ? '' : intl.formatMessage({ id: 'SEARCH_EMPTY_DESCRIPTION' })}
                </EmptyState>
              </div>
            )}
          </MenuItem>
        </TabMenu>

        <div className={styles.footer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: '5rem',
            }}
          >
            <Switch
              onChange={() => setRemoveInlineStyles(!removeInlineStyles)}
              active={removeInlineStyles}
              disabled={!selectedTemplate}
              size="large"
              margin="0 2rem 0 0"
              testId="template-gallery-delete-existing-document-inline-styles-switch"
            >
              {intl.formatMessage({ id: 'DELETE_EXISTING_DOCUMENT_INLINE_STYLES' })}
            </Switch>
            <Tooltip
              content={intl.formatMessage({
                id: 'DELETE_EXISTING_DOCUMENT_INLINE_STYLES_TOOLTIP',
              })}
              testId="delete-existing-inline-styles-tooltip"
            >
              <Icon icon="InformationBlue" size={16} />
            </Tooltip>
          </div>
          <Button
            size="medium"
            margin="0 1rem 0 0"
            onClick={close}
            testId="template-gallery-close-button"
          >
            <FormattedMessage id="global.close" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            disabled={!selectedTemplate}
            onClick={apply}
            testId="template-gallery-apply-template-button"
          >
            <FormattedMessage id="APPLY_TEMPLATE" />
          </Button>
        </div>
      </div>
    </AppOverlay>
  );
};

export default TemplateGallery;
