import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import styles from './RefStyleEmptyState.module.scss';

const RefStyleEmptyState = () => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <EmptyState
        size="large"
        icon="EmptyFolder"
        title={intl.formatMessage({ id: 'NO_REFERENCES_STYLES' })}
        testId="no-references-styles"
      >
        <FormattedMessage id="TENANT_DOESNT_HAVE_REF_STYLES_UPLOADED" />
      </EmptyState>
    </div>
  );
};

export default RefStyleEmptyState;
