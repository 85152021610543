import { ChangeEventHandler, DragEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { uploadNewFileVersion, confirmNewFileVersion } from 'App/redux/appSlice';
import { closeAndResetModal, updateModal } from '_common/modals/ModalsSlice';

import { ProgressBar, Upload, Icon, Checkbox, Button, Modal } from 'dodoc-design-system';
import UploadClean from './UploadClean/UploadClean';
import UploadErrors from './UploadErrors/UploadErrors';
import styles from './CheckInModal.module.scss';
import ObjectApi from '_common/services/api/ObjectApi';

const MODAL = 'CheckInModal';

const CheckInModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const object = useSelector((state) => state.app.data[state.modals[MODAL].objectId]);
  const loading = useSelector((state) => state.modals[MODAL].loading);
  const file = useSelector((state) => state.modals[MODAL].file);
  const errors = useSelector((state) => state.modals[MODAL].errors);
  const id = useSelector((state) => state.modals[MODAL].id);
  const uploadPercentage = useSelector((state) => state.modals[MODAL].uploadPercentage);
  const operation = useSelector((state) => state.modals[MODAL].operation);

  const initialInputs = { name: '', keepCheckedOut: false, comment: '', editing: false };
  const [inputs, setInputs] = useState(initialInputs);

  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      name: file?.name,
    }));
  }, [file]);

  const onInputChanged: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    e.persist();
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const reset = () => {
    dispatch(
      updateModal({
        modal: MODAL,
        data: {
          loading: false,
          file: {},
          errors: {},
          id: '',
        },
      }),
    );
  };

  const uploadNewVersion = (_: DragEvent<HTMLDivElement>, acceptedFiles: File[]) => {
    dispatch(
      uploadNewFileVersion({
        params: {
          objectId: object.id,
          file: acceptedFiles[0],
        },
      }),
    ).then(() => {
      dispatch(ObjectApi.util.invalidateTags([{ type: object.type, id: object.id }]));
    });
  };

  const checkInConfirmation = () => {
    const { name, keepCheckedOut, comment } = inputs;

    dispatch(
      confirmNewFileVersion({
        objectId: object.id,
        params: {
          version: id,
          name,
          close: !keepCheckedOut,
          comment,
        },
        operation,
      }),
    );
    close();
  };

  const close = () => {
    setInputs(initialInputs);
    dispatch(closeAndResetModal(MODAL));
  };

  const toggleEditingName = () => {
    setInputs((prevState) => ({
      ...prevState,
      editing: !inputs.editing,
    }));
  };

  const toggleKeepCheckedOut = () => {
    setInputs((prevState) => ({
      ...prevState,
      keepCheckedOut: !prevState.keepCheckedOut,
    }));
  };

  const renderUploadContent = () => {
    const { name, editing, comment } = inputs;
    if (!id) {
      if (loading) {
        return (
          <div className={styles.loading}>
            <div className={styles.loadingIcon}>
              <Icon icon="File" size={96} />
            </div>
            <div className={styles.loadingIndicator}>
              <ProgressBar
                progress={uploadPercentage}
                testId="check-in-modal-uploading-progressBar"
              />
            </div>
            <div className={styles.loadingText}>
              {`${intl.formatMessage({ id: 'UPLOADING_FILE' })}...`}
            </div>
          </div>
        );
      }
      return (
        <Upload
          onDrop={uploadNewVersion}
          text={intl.formatMessage({ id: 'DRAG_DROP_YOUR_FILE' })}
          testId="upload-file-input"
        />
      );
    }
    return !errors.name && !errors.extension && !errors.mime && !loading ? (
      <UploadClean
        reset={reset}
        name={name}
        editing={editing}
        toggleEditingName={toggleEditingName}
        onInputChanged={onInputChanged}
        comment={comment}
        modal={MODAL}
      />
    ) : (
      errors && !loading && (
        <UploadErrors
          reset={reset}
          editing={editing}
          onInputChanged={onInputChanged}
          name={name}
          toggleEditingName={toggleEditingName}
          comment={comment}
          modal={MODAL}
        />
      )
    );
  };

  const renderHeader = () => {
    if (loading) {
      return intl.formatMessage({ id: 'UPLOADING_FILE' });
    }
    if (operation === 'checkIn') {
      return `${intl.formatMessage({ id: 'storage.modals.checkIn.titleCheckIn' })} ${object.name} `;
    }
    return `${intl.formatMessage({
      id: 'storage.modals.checkIn.titleUpdate',
    })} ${object.name}`;
  };

  if (!object) {
    return null;
  }

  return (
    <Modal open={!!isOpen} width="60rem" onClose={close} testId="check-in">
      <Modal.Header onClose={close}>
        <span style={{ flex: '1' }}>{renderHeader()}</span>
      </Modal.Header>
      <Modal.Body>
        {renderUploadContent()}
        {operation === 'checkIn' && !loading && (
          <div style={{ paddingTop: '1.5rem', display: 'flex' }}>
            <Checkbox
              onChange={toggleKeepCheckedOut}
              checked={inputs.keepCheckedOut ? 'checked' : 'unchecked'}
              testId="keep-the-file-checked-out-checkbox"
            >
              <FormattedMessage id="storage.modals.checkIn.checkbox" />
            </Checkbox>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
          <>
            <Button size="medium" onClick={close} testId="check-in-cancel-button">
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              size="medium"
              variant="primary"
              onClick={checkInConfirmation}
              disabled={!id}
              testId="check-in-submit-button"
            >
              <FormattedMessage
                id={
                  operation === 'checkIn'
                    ? 'storage.modals.checkIn.buttonCheckIn'
                    : 'storage.modals.checkIn.buttonUpdate'
                }
              />
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CheckInModal;
