import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';
import styles from './NotFoundElement.module.scss';

const NotFoundElement = () => (
  <div className={styles.container}>
    <div className={styles.logoContainer}>
      <Icon icon="NoResults" size={240} />
    </div>
    <div className={styles.text} data-testid="public-link-notfound-description">
      <FormattedMessage id="public.noLink.noLink1" />
      <br />
      <FormattedMessage id="public.noLink.noLink2" />
    </div>
  </div>
);

export default NotFoundElement;
