import { BaseOperation } from '../BaseOperation';
import { NodeUtils } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { ErrorCannotMergeCells } from 'Editor/services/_CustomErrors';

export class MergeCellsOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  selectedCells: Editor.Edition.CellInfo[];

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const tableInfo = this.model.getChildInfoById(this.tableId);
    const table: Editor.Data.Node.Data = tableInfo.data;
    const tableBody = table.childNodes?.[0];
    const rows = tableBody?.childNodes;

    const headCells: Editor.Edition.CellInfo[] = [];

    const cellIndexes: number[] = [];
    const rowIndexes: number[] = [];

    const cellsToMerge: string[] = [];

    for (let i = 0; i < this.selectedCells.length; i++) {
      let selectedCell = this.selectedCells[i];

      const selectedCellPath = this.model.findPath('id', selectedCell.id);
      const selectedCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(selectedCellPath);

      if (selectedCellIndexs.parentIndex != null && tableBody) {
        if (
          (selectedCell.colSpan > 1 || selectedCell.rowSpan > 1) &&
          !headCells.includes(selectedCell)
        ) {
          headCells.push(selectedCell);

          for (let r = 0; r < selectedCell.rowSpan; r++) {
            const rowIndex = selectedCellIndexs.parentIndex + r;

            for (let c = 0; c < selectedCell.colSpan; c++) {
              const cellIndex = selectedCell.cellIndex + c;

              const cell = tableBody.childNodes?.[rowIndex].childNodes?.[cellIndex];

              if (cell && cell.id) {
                if (!cellIndexes.includes(cellIndex) && cell != null) {
                  cellIndexes.push(cellIndex);
                }
                if (!rowIndexes.includes(rowIndex) && cell != null) {
                  rowIndexes.push(rowIndex);
                }
                if (!cellsToMerge.includes(cell.id) && cell != null) {
                  cellsToMerge.push(cell.id);
                }
              }
            }
          }
        } else {
          if (!cellIndexes.includes(selectedCell.cellIndex)) {
            cellIndexes.push(selectedCell.cellIndex);
          }

          if (!rowIndexes.includes(selectedCellIndexs.parentIndex)) {
            rowIndexes.push(selectedCellIndexs.parentIndex);
          }

          if (!cellsToMerge.includes(selectedCell.id)) {
            cellsToMerge.push(selectedCell.id);
          }
        }
      }
    }

    cellIndexes.sort((a, b) => {
      return a - b;
    });

    rowIndexes.sort((a, b) => {
      return a - b;
    });

    // validate number of cells to merge
    if (
      (rowIndexes.length <= 1 && cellIndexes.length <= 1) ||
      cellsToMerge.length !== cellIndexes.length * rowIndexes.length
    ) {
      throw new ErrorCannotMergeCells();
    }

    // validate if indexes are contigous
    if (
      rowIndexes[rowIndexes.length - 1] - rowIndexes[0] > rowIndexes.length - 1 ||
      cellIndexes[cellIndexes.length - 1] - cellIndexes[0] > cellIndexes.length - 1
    ) {
      throw new ErrorCannotMergeCells();
    }

    // validate headcells rowspan and colspan
    for (let i = 0; i < headCells.length; i++) {
      const headCell = headCells[i];
      const headCellPath = this.model.findPath('id', headCell.id);
      const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);

      if (
        headCellIndexs.parentIndex != null &&
        headCell.rowSpan > rowIndexes.length - rowIndexes.indexOf(headCellIndexs.parentIndex)
      ) {
        throw new ErrorCannotMergeCells();
      }

      if (headCell.colSpan > cellIndexes.length - cellIndexes.indexOf(headCell.cellIndex)) {
        throw new ErrorCannotMergeCells();
      }
    }

    const childNodesToUpdate: Editor.Data.Node.Data[] = [];

    if (rows) {
      const headCell = rows[rowIndexes[0]].childNodes?.[cellIndexes[0]];
      let headCellPath: Realtime.Core.AbstractPath = [];

      if (headCell && headCell.id) {
        for (let x = 0; x < rowIndexes.length; x++) {
          for (let y = 0; y < cellIndexes.length; y++) {
            const cell = rows[rowIndexes[x]].childNodes?.[cellIndexes[y]];

            if (cell) {
              const cellPath = this.model.findPath(this.model.KEYS.ID, cell.id);
              cellPath.pop(); // remove path id

              const cellData = this.model.get(cellPath);

              if (x === 0 && y === 0) {
                // head cell
                headCellPath = cellPath;

                // add child nodes to update
                Array.prototype.push.apply(childNodesToUpdate, cellData.childNodes);

                // update rowspan
                if (rowIndexes.length > 1 && rowIndexes.length !== cell.properties?.rs) {
                  const op = this.model.buildOp(
                    [...cellPath, this.model.KEYS.PROPERTIES, 'rs'],
                    rowIndexes.length,
                  );
                  if (op != null) {
                    this.ops.push(op);
                  }
                }

                // update colspan
                if (cellIndexes.length > 1 && cellIndexes.length !== cell.properties?.cs) {
                  const op = this.model.buildOp(
                    [...cellPath, this.model.KEYS.PROPERTIES, 'cs'],
                    cellIndexes.length,
                  );
                  if (op != null) {
                    this.ops.push(op);
                  }
                }
              } else {
                // cells to hide

                // add child nodes to update
                const childsLength = cell.childNodes?.length;

                if (cell.childNodes && childsLength) {
                  for (let j = 0; j < childsLength; j++) {
                    const node = cell.childNodes[j];

                    if (node.type === ELEMENTS.ParagraphElement.ELEMENT_TYPE) {
                      if (NodeUtils.getContentFromData(node)) {
                        childNodesToUpdate.push(cellData.childNodes[j]);
                      }
                    } else {
                      childNodesToUpdate.push(cellData.childNodes[j]);
                    }
                  }
                }

                // delete child nodes
                const childNodesOp = this.model.buildOp(
                  [...cellPath, this.model.KEYS.CHILDNODES],
                  [],
                );
                if (childNodesOp != null) {
                  this.ops.push(childNodesOp);
                }

                // remove rowspan if exist
                if (cellData.properties.rs != null) {
                  const op = this.model.buildOp(
                    [...cellPath, this.model.KEYS.PROPERTIES, 'rs'],
                    null,
                  );
                  if (op != null) {
                    this.ops.push(op);
                  }
                }

                // remove colspan if exist
                if (cellData.properties.cs != null) {
                  const op = this.model.buildOp(
                    [...cellPath, this.model.KEYS.PROPERTIES, 'cs'],
                    null,
                  );
                  if (op != null) {
                    this.ops.push(op);
                  }
                }

                // update head id
                const headIdOp = this.model.buildOp(
                  [...cellPath, this.model.KEYS.PROPERTIES, 'head-id'],
                  headCell.id,
                );
                if (headIdOp) {
                  this.ops.push(headIdOp);
                }

                // update display
                const displayOp = this.model.buildOp(
                  [...cellPath, this.model.KEYS.PROPERTIES, 'd'],
                  false,
                );
                if (displayOp) {
                  this.ops.push(displayOp);
                }
              }
            }
          }
        }

        // update child nodes parent id
        for (let i = 0; i < childNodesToUpdate.length; i++) {
          childNodesToUpdate[i].parent_id = headCell.id;
        }

        // update head cell child nodes
        const childNodesOp = this.model.buildOp(
          [...headCellPath, this.model.KEYS.CHILDNODES],
          childNodesToUpdate,
        );
        if (childNodesOp) {
          this.ops.push(childNodesOp);
        }
      }
    }

    return this;
  }
}
