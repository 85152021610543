import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { useMemo } from 'react';
import { useSelector } from '_common/hooks';
import { useLoginSetupQuery } from '_common/services/api/authority';

const useValidAccounts = () => {
  const accounts = useSelector(selectAccounts);
  const { data } = useLoginSetupQuery();
  return useMemo(() => {
    if (data && accounts) {
      return Object.keys(accounts).reduce<Record<UserId, ParsedProfile<Account>>>(
        (previous, id) => {
          if (data?.allow_login_with_password || accounts[id].third_party) {
            previous[id] = accounts[id];
          }
          return previous;
        },
        {},
      );
    }
    return {};
  }, [data, accounts]);
};

export default useValidAccounts;
