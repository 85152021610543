import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from '_common/hooks';
import { Toggle, Tooltip } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import {
  navigateToGroups,
  navigateToMyFiles,
  navigateToRecycle,
  navigateToShared,
  navigateToSpaces,
} from 'router/history';

import { viewPersonalSpaces } from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';

const Navigation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const appLoading = useSelector((state) => state.app.loading.isOpen);
  const expanded = useSelector((state) => state.sidebar.expanded);
  const currentPage = useSelector((state) => state.app.currentPage);
  const personalSpaces = useSelector((state) => state.spaces.personals);

  const handleGoToMyFiles = () => {
    navigateToMyFiles();
  };

  const handleGoToShared = () => {
    navigateToShared();
  };

  const handleGoToSpaces = () => {
    if (personalSpaces) {
      dispatch(viewPersonalSpaces(false));
    }
    navigateToSpaces();
  };

  const handleGoToGroups = () => {
    navigateToGroups();
  };

  const handleGoToRecycle = () => {
    navigateToRecycle();
  };

  return (
    <>
      <Tooltip
        placement="right"
        disabled={expanded}
        content={expanded ? '' : intl.formatMessage({ id: 'storage.sidebar.myFiles' })}
        testId="sidebar-navigation-my-files-tooltip"
      >
        <Toggle
          variant="monochrome"
          size="large"
          icon="MyFiles"
          loading={appLoading}
          expanded={expanded}
          onClick={handleGoToMyFiles}
          isToggled={currentPage === '/storage'}
          margin="0 0 1rem 0"
          testId="sidebar-navigation-my-files-toggle"
        >
          <FormattedMessage id="storage.sidebar.myFiles" />
        </Toggle>
      </Tooltip>
      <Tooltip
        placement="right"
        disabled={expanded}
        content={expanded ? '' : intl.formatMessage({ id: 'storage.sidebar.shared' })}
        testId="sidebar-navigation-shared-tooltip"
      >
        <Toggle
          variant="monochrome"
          size="large"
          icon="SharedFiles"
          loading={appLoading}
          expanded={expanded}
          onClick={handleGoToShared}
          isToggled={currentPage === '/shared'}
          margin="0 0 1rem 0"
          testId="sidebar-navigation-shared-toggle"
        >
          <FormattedMessage id="storage.sidebar.shared" />
        </Toggle>
      </Tooltip>
      <Tooltip
        placement="right"
        disabled={expanded}
        content={expanded ? '' : intl.formatMessage({ id: 'storage.sidebar.spaces' })}
        testId="sidebar-navigation-spaces-tooltip"
      >
        <Toggle
          variant="monochrome"
          size="large"
          icon="MyFiles"
          loading={appLoading}
          expanded={expanded}
          onClick={handleGoToSpaces}
          isToggled={currentPage === '/storage/spaces'}
          margin="0 0 1rem 0"
          testId="sidebar-navigation-spaces-toggle"
        >
          <FormattedMessage id="storage.sidebar.spaces" />
        </Toggle>
      </Tooltip>
      <Tooltip
        placement="right"
        disabled={expanded}
        content={expanded ? '' : intl.formatMessage({ id: 'storage.sidebar.groups' })}
        testId="sidebar-navigation-groups-tooltip"
      >
        <Toggle
          variant="monochrome"
          size="large"
          icon="Groups"
          loading={appLoading}
          expanded={expanded}
          onClick={handleGoToGroups}
          isToggled={currentPage === '/groups'}
          margin="0 0 1rem 0"
          testId="sidebar-navigation-groups-toggle"
        >
          <FormattedMessage id="storage.sidebar.groups" />
        </Toggle>
      </Tooltip>
      <Tooltip
        placement="right"
        disabled={expanded}
        content={expanded ? '' : intl.formatMessage({ id: 'storage.sidebar.recycleBin' })}
        testId="sidebar-navigation-recycle-tooltip"
      >
        <Toggle
          variant="monochrome"
          size="large"
          loading={appLoading}
          expanded={expanded}
          icon="RecycleBin"
          onClick={handleGoToRecycle}
          isToggled={currentPage === '/storage/recycle'}
          margin="0 0 1rem 0"
          testId="sidebar-navigation-recycle-toggle"
        >
          <FormattedMessage id="storage.sidebar.recycleBin" />
        </Toggle>
      </Tooltip>
    </>
  );
};
export default Navigation;
