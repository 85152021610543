import { ReactNode } from 'react';
import { ErrorBoundaryFallbackProps } from 'dodoc-design-system/build/types/Components/ErrorBoundary/Fallback/ErrorBoundaryFallback';
import cx from 'classnames';

import { useSuiteApp } from '_common/suite/SuiteContext/SuiteContext';
import { IntlErrorBoundary } from '_common/components';

import styles from './Footer.module.scss';

type FooterProps = {
  id?: string;
  children: ReactNode;
  fallbackSize?: ErrorBoundaryFallbackProps['size'];
};

const Footer = ({ id, children, fallbackSize = 'small' }: FooterProps) => {
  const suiteApp = useSuiteApp();

  return (
    <div id={id} className={cx(styles.footer, { [styles.editor]: suiteApp === 'document' })}>
      <IntlErrorBoundary size={fallbackSize} margin="auto">
        <div className={styles.content}>{children}</div>
      </IntlErrorBoundary>
    </div>
  );
};

const Left = ({ children }: { children: ReactNode }) => {
  return <div className={styles.left}>{children}</div>;
};
const Right = ({ children }: { children: ReactNode }) => {
  return <div className={styles.right}>{children}</div>;
};

export default Object.assign(Footer, {
  Left,
  Right,
});
