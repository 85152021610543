import { Route, Switch, useRouteMatch } from 'react-router-dom';
import WopiPage from './pages/WopiPage/WopiPage';

const ExtensionsContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/wopi/:id([a-f0-9]{24})/:applicationId/:actionId`}>
        <WopiPage />
      </Route>
    </Switch>
  );
};

export default ExtensionsContainer;
