/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { InstanceService } from '_common/services';
import { Transport } from '../../Transport';
import { DataObject } from '../DataObject';

export abstract class DoDocObject extends DataObject<
  Realtime.Core.Document.Data,
  Realtime.Core.Document.Events
> {
  protected transport: Transport;

  constructor(transport: Transport, id: string, data: Realtime.Core.Document.Data) {
    super(id);
    this.data = {
      ...data,
      _id: id,
    };
    this.transport = transport;

    this.handleTransportEventDocumentUpdate = this.handleTransportEventDocumentUpdate.bind(this);
    this.handleEventUpdateObjectStatus = this.handleEventUpdateObjectStatus.bind(this);

    this.transport.handleEvent('DOCUMENT:UPDATE', this.handleTransportEventDocumentUpdate);
    this.transport.handleEvent('UPDATE:OBJECT:STATUS', this.handleEventUpdateObjectStatus);
  }

  protected abstract getObjectType(): Realtime.Core.Document.DocumentType;

  async fetch(): Promise<DoDocObject> {
    const { data } = (await new InstanceService().getObjectData({
      objectId: this.id,
      objectType: this.getObjectType(),
    })) as { data: Realtime.Core.Document.Data };
    this.data = {
      ...this.data,
      ...data,
    };
    this.emit('UPDATED', this.data);
    return this;
  }

  dispose(): void {
    this.transport.removeEvent('DOCUMENT:UPDATE', this.handleTransportEventDocumentUpdate);
    this.transport.removeEvent('UPDATE:OBJECT:STATUS', this.handleEventUpdateObjectStatus);
    super.dispose();
  }

  get user_permissions() {
    return this.data?.user_permissions || [];
  }

  abstract get versions(): Realtime.Core.Document.Version[] | undefined;

  private handleTransportEventDocumentUpdate(event: Realtime.Core.Document.Data) {
    if (this.data) {
      this.data = { ...this.data, ...event };
      this.emit('UPDATED', this.data);
    }
  }

  private handleEventUpdateObjectStatus(event: {
    status: Realtime.Core.Document.Data['status'];
    statusInfo: Realtime.Core.Document.Data['statusInfo'];
  }) {
    this.emit('UPDATE_OBJECT_STATUS', this._id, event.status, event.statusInfo);
  }
}
