import { NodeUtils } from 'Editor/services/DataManager';
import { BaseOperation } from '../BaseOperation';

export class InsertBlockOperation extends BaseOperation<Editor.Data.Structure.Model> {
  private dataManager: Editor.Data.API;
  private elementData: Editor.Data.Node.Data;
  private referenceElementId: string;
  private position: 'BEFORE' | 'AFTER';
  private options: Editor.Edition.InsertBlockOptions;

  constructor(
    dataManager: Editor.Data.API,
    model: Editor.Data.Structure.Model,
    elementData: Editor.Data.Node.Data,
    referenceElementId: string,
    position: 'BEFORE' | 'AFTER' = 'AFTER',
    options: Editor.Edition.InsertBlockOptions = {},
  ) {
    super(model);

    this.dataManager = dataManager;
    this.elementData = elementData;
    this.referenceElementId = referenceElementId;
    this.position = position;
    this.options = {
      pathAfterInsert: 'TEXT_START',
      ...options,
    };

    this.build();
  }

  protected adjustPostOpPath() {
    if (this.options.pathAfterInsert === 'TEXT_START') {
      if (NodeUtils.isTableData(this.elementData)) {
        this.resultPath = ['childNodes', 0, 'childNodes', 0, 'childNodes', 0, 'childNodes', 0];
      } else {
        this.resultPath = [];
      }

      let child = NodeUtils.getChildDataByPath(this.elementData, this.resultPath);

      while (child && !NodeUtils.isNonEditableInlineData(child)) {
        if (NodeUtils.isTextData(child)) {
          this.resultPath.push('content', 0);
          child = undefined;
          break;
        } else {
          this.resultPath.push('childNodes', 0);

          if (child.childNodes?.length) {
            child = child.childNodes[0];
          } else {
            child = undefined;
            break;
          }
        }
      }
    } else if (this.options.pathAfterInsert === 'START') {
      if (NodeUtils.isTableData(this.elementData)) {
        this.resultPath = [
          'childNodes',
          0,
          'childNodes',
          0,
          'childNodes',
          0,
          'childNodes',
          0,
          'childNodes',
          0,
        ];
      } else {
        this.resultPath = ['childNodes', 0];
      }
    } else if (this.options.pathAfterInsert === 'END') {
      let length = this.elementData.childNodes?.length || 0;
      this.resultPath = ['childNodes', length];
    }

    if (this.elementData.id && this.resultPath) {
      this.posOpPosition = {
        b: this.elementData.id,
        p: this.resultPath,
      };
    }
  }

  protected build(): Editor.Edition.IOperationBuilder {
    let elementId = this.elementData.id;

    const structureData = this.model.selectedData();

    if (!structureData || !elementId) {
      return this;
    }

    const structureNodes = structureData.childNodes;

    this.elementData.parent_id = this.dataManager.document.doc?.id || structureData.parent_id;

    // create node model
    const nodeModel = this.dataManager.models.get(
      this.dataManager?.models.TYPE_NAME.NODE,
      elementId,
    );

    nodeModel.create(this.elementData);

    let refIndex;

    if (this.position === 'AFTER') {
      refIndex = structureNodes.indexOf(this.referenceElementId);
      refIndex += 1;
    } else {
      refIndex = structureNodes.indexOf(this.referenceElementId);
    }

    let sct = this.dataManager.structure.getSectionForPosition(
      elementId,
      this.referenceElementId,
      refIndex,
    );

    if (sct) {
      this.ops.push({
        li: elementId,
        p: ['childNodes', refIndex],
      });
      this.ops.push({
        oi: {
          sct,
          ...this.model.getBlockProperties(elementId),
        },
        p: ['blkProps', elementId],
      });

      // TODO: is this needed?
      // if (extra.lst && extra.lst.lId) {
      //   let listPosition = data.lists[extra.lst.lId]?.n?.indexOf(referenceId);

      //   if (listPosition < 0 || listPosition == null) {
      //     listPosition = data.lists[extra.lst.lId]?.n?.length || 0;
      //   }

      //   op.push({
      //     p: ['lists', extra.lst.lId, 'n', listPosition],
      //     li: nodeId,
      //   });
      // }
    }

    this.adjustPostOpPath();

    return this;
  }
}
