import { createContext, useContext, useEffect, useState } from 'react';

type DocumentTitleContextType = {
  push: (title: string) => void;
  pop: () => void;
};

const DocumentTitleContext = createContext<DocumentTitleContextType>({
  push: () => null,
  pop: () => null,
});

const DocumentTitleProvider = ({
  baseTitle,
  children,
}: {
  baseTitle: string;
  children: JSX.Element;
}) => {
  const [titleStack, setTitleStack] = useState<string[]>([]);

  useEffect(() => {
    if (titleStack.length) {
      document.title = titleStack[titleStack.length - 1];
    } else {
      document.title = baseTitle;
    }
  }, [titleStack]);

  const handlePush: DocumentTitleContextType['push'] = (title) => {
    setTitleStack((prev) => {
      const newStack = [...prev];
      newStack.push(title);
      return newStack;
    });
  };

  const handlePop: DocumentTitleContextType['pop'] = () => {
    setTitleStack((prev) => {
      const newStack = [...prev];
      newStack.pop();
      return newStack;
    });
  };

  return (
    <DocumentTitleContext.Provider value={{ push: handlePush, pop: handlePop }}>
      {children}
    </DocumentTitleContext.Provider>
  );
};

export const useDocumentTitle = () => {
  return useContext(DocumentTitleContext);
};

export default DocumentTitleProvider;
