import { JsonRange } from 'Editor/services/_Common/Selection';
import { Logger } from '_common/services';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { InsertInlineCommand } from '../GenericCommands';
import { ErrorElementNotEditable } from '../../Errors';

export class TabCommand extends InsertInlineCommand {
  private event: KeyboardEvent;
  private avoidCollpasedAction: boolean = false;

  constructor(context: Editor.Edition.Context, event: KeyboardEvent) {
    super(context);
    this.event = event;
  }

  private async insertTab(): Promise<boolean> {
    const tabData = new NodeDataBuilder('tab').build();

    if (tabData) {
      this.setExecOptions({ createPatch: false });
      await super.handleExec(tabData);

      return true;
    }
    return false;
  }

  private async getNextCell(
    ctx: Editor.Edition.ActionContext,
  ): Promise<Editor.Data.Node.DataPathInfo | null> {
    if (!ctx.baseModel || !ctx.baseData) {
      return null;
    }

    let currentCell = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, ['tblc']);

    while (currentCell) {
      const nextCell = NodeUtils.getNextSibling(ctx.baseData, currentCell.path);
      if (nextCell && NodeUtils.isTableCellData(nextCell.data)) {
        if (nextCell.data.properties.d !== false) {
          return nextCell;
        }
      } else {
        const parentChildInfo = NodeUtils.getParentChildInfoByPath(ctx.baseData, currentCell.path);
        if (parentChildInfo) {
          const nextRow = NodeUtils.getNextSibling(ctx.baseData, parentChildInfo.parentPath);
          if (nextRow && NodeUtils.isTableRowData(nextRow.data) && nextRow.data.childNodes) {
            const firstCell = nextRow.data.childNodes[0];
            if (NodeUtils.isTableCellData(firstCell) && firstCell.properties.d !== false) {
              return {
                data: firstCell,
                path: [...nextRow.path, 'childNodes', 0],
              };
            }
          } else {
            let command = this.context.commandFactory?.getCommand('TABLE_OPERATION', {
              operation: 'INSERT_ROW',
              before: false,
            });
            if (command) {
              await command.exec();
            }
            ctx.refreshBaseData();
            return this.getNextCell(ctx);
          }
        }
      }

      currentCell = nextCell;
    }

    return null;
  }

  private async getPreviousCell(
    ctx: Editor.Edition.ActionContext,
  ): Promise<Editor.Data.Node.DataPathInfo | null> {
    if (!ctx.baseModel || !ctx.baseData) {
      return null;
    }

    let currentCell = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, ['tblc']);
    if (!currentCell) {
      return null;
    }

    while (currentCell) {
      const previousCell = NodeUtils.getPreviousSibling(ctx.baseData, currentCell.path);
      if (previousCell && NodeUtils.isTableCellData(previousCell.data)) {
        if (previousCell.data.properties.d !== false) {
          return previousCell;
        }
      } else {
        const parentChildInfo = NodeUtils.getParentChildInfoByPath(ctx.baseData, currentCell.path);
        if (parentChildInfo) {
          const previousRow = NodeUtils.getPreviousSibling(
            ctx.baseData,
            parentChildInfo.parentPath,
          );
          if (
            previousRow &&
            NodeUtils.isTableRowData(previousRow.data) &&
            previousRow.data.childNodes
          ) {
            const index = (previousRow.data.childNodes.length || 0) - 1;
            const lastCell = previousRow.data.childNodes[index];
            if (NodeUtils.isTableCellData(lastCell) && lastCell.properties.d !== false) {
              return {
                data: lastCell,
                path: [...previousRow.path, 'childNodes', index],
              };
            }
          }
        }
      }

      currentCell = previousCell;
    }

    return null;
  }

  private async handleTabOnTableElement(ctx: Editor.Edition.ActionContext): Promise<boolean> {
    if (!ctx.baseModel || !ctx.baseData) {
      return false;
    }

    let cellToSelect: Editor.Data.Node.DataPathInfo | null = null;

    if (this.event.shiftKey) {
      cellToSelect = await this.getPreviousCell(ctx);
    } else {
      cellToSelect = await this.getNextCell(ctx);
    }

    if (cellToSelect) {
      this.avoidCollpasedAction = true;

      if (cellToSelect.data.childNodes && !NodeUtils.isEmptyData(cellToSelect.data)) {
        const lastIndex = cellToSelect.data.childNodes.length - 1;
        const lastChild = cellToSelect.data.childNodes[lastIndex];
        const lastChildLength = lastChild.childNodes?.length || 0;

        ctx.range.updateRangePositions(
          {
            b: ctx.baseModel.id,
            p: [...cellToSelect.path, 'childNodes', 0, 'childNodes', 0],
          },
          {
            b: ctx.baseModel.id,
            p: [...cellToSelect.path, 'childNodes', lastIndex, 'childNodes', lastChildLength],
          },
        );
      } else {
        ctx.range.updateRangePositions({
          b: ctx.baseModel.id,
          p: [...cellToSelect.path, 'childNodes', 0, 'childNodes', 0],
        });
      }
    }

    return true;
  }

  private async handleTabOnTextElement(
    ctx: Editor.Edition.ActionContext,
    baseData: Editor.Data.Node.Data,
    textData: Editor.Data.Node.Data,
    textPath: Editor.Selection.Path,
    blockSelection?: boolean,
  ) {
    if (!this.context.DataManager || !textData?.id || !baseData?.id) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(baseData.id)) {
      throw new ErrorElementNotEditable();
    }

    if (blockSelection) {
      if (this.context.DataManager.numbering.isListElement(textData.id)) {
        // is list element
        if (this.event.shiftKey) {
          // decrease list indent
          //! TEMP
          await this.context.stylesHandler?.increaseIndent(undefined, true);
        } else {
          // increase list indent
          //! TEMP
          await this.context.stylesHandler?.increaseIndent(undefined, false);
        }
      } else {
        // is not list element
        if (this.event?.shiftKey) {
          // outdent
          this.context.DataManager.nodes.changeLeftIndentation(baseData.id, textPath, false);
        } else {
          // indent
          this.context.DataManager.nodes.changeLeftIndentation(baseData.id, textPath, true);
        }
      }
    } else {
      let textSubPath = ctx.range.start.p.slice(textPath.length);

      if (
        this.context.DataManager.numbering.isListElement(textData.id) &&
        NodeUtils.isPathAtContentStart(textData, textSubPath)
      ) {
        // is list element
        if (this.event.shiftKey) {
          // decrease list indent
          //! TEMP
          await this.context.stylesHandler?.increaseIndent(undefined, true);
        } else {
          // increase list indent
          //! TEMP
          await this.context.stylesHandler?.increaseIndent(undefined, false);
        }
      } else {
        await this.insertTab();
        this.context.VisualizerManager?.tabulateView(textData.id);

        return true;
      }
    }

    return false;
  }

  protected async handleTabCollapsedSelection(ctx: Editor.Edition.ActionContext): Promise<boolean> {
    if (!this.context.DataManager || !ctx.baseModel || !ctx.baseData) {
      return false;
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(ctx.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    const closestTable = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, ['tbl']);

    const closestText = NodeUtils.closestOfTypeByPath(
      ctx.baseData,
      ctx.range.start.p,
      NodeUtils.BLOCK_TEXT_TYPES,
    );

    if (closestTable && NodeUtils.isTableData(closestTable.data)) {
      return await this.handleTabOnTableElement(ctx);
    } else if (closestText && NodeUtils.isBlockTextData(closestText.data)) {
      return await this.handleTabOnTextElement(
        ctx,
        ctx.baseData,
        closestText.data,
        closestText.path,
      );
    }

    return false;
  }

  protected async handleTabNonCollapsedSelection(
    ctx: Editor.Edition.ActionContext,
  ): Promise<boolean> {
    if (!this.context.DataManager || !ctx.baseModel || !ctx.baseData) {
      return false;
    }

    const closestTable = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, ['tbl']);

    if (
      closestTable &&
      NodeUtils.isTableData(closestTable.data) &&
      ctx.range.start.b === ctx.range.end.b
    ) {
      await this.handleTabOnTableElement(ctx);
    } else {
      const blockElements = JsonRange.filterElementDataFromRange(
        this.context.DataManager,
        ctx.range,
        NodeUtils.BLOCK_TYPES,
        { onlyContainerLevel: true, useSelectedCells: false },
      );

      if (blockElements.length > 1) {
        for (let i = 0; i < blockElements.length; i++) {
          if (NodeUtils.isBlockTextData(blockElements[i].childData)) {
            await this.handleTabOnTextElement(
              ctx,
              blockElements[i].baseData,
              blockElements[i].childData,
              blockElements[i].childPath,
              true,
            );
          }
        }
      } else {
        // normalize text selection
        if (this.context.contentManipulator) {
          return this.context.contentManipulator.removeContent(ctx);
        }
      }
    }

    return true;
  }

  async handleExec(): Promise<void> {
    if (this.debug) {
      Logger.trace('TabCommand');
    }

    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    //TODO:
    // fix block selection?

    if (!this.actionContext.range.collapsed) {
      // handle non collapsed selection
      if (!(await this.handleTabNonCollapsedSelection(this.actionContext))) {
        return;
      }
    }

    if (this.actionContext.range.collapsed && !this.avoidCollpasedAction) {
      // handle collapsed selection
      if (!(await this.handleTabCollapsedSelection(this.actionContext))) {
        return;
      }
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
