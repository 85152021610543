import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { navigateToPrevious } from 'router/history';
import { useSelector } from '_common/hooks';
import { selectActiveMenu } from '_common/components/Sidebar/SidebarSlice';

import styles from './Header.module.scss';

const Header = () => {
  const activeMenu = useSelector(selectActiveMenu);

  if (!activeMenu) {
    return null;
  }

  const goToPreviousLocation = () => {
    navigateToPrevious();
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <FormattedMessage id={activeMenu.title.id} values={activeMenu.title.values} />
      </div>
      <Button
        variant="link"
        size="small"
        icon="CloseWhite"
        onClick={goToPreviousLocation}
        margin="0 0 0 1rem"
        testId="sidebar-menu-close-button"
      />
    </div>
  );
};

export default Header;
