import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { BaseManipulator } from '../Base';
import { InsertElementOperation } from 'Editor/services/Edition_v2/Operations';

type FormatDataMapper = {
  [style in Editor.Edition.InlineStyles]: {
    hasStyle: (formatData: Editor.Data.Node.FormatData) => boolean;
    hasStyleValue: (
      formatData: Editor.Data.Node.FormatData,
      value: Editor.Edition.StylesMap[style],
    ) => boolean;
    removeStyle: (formatData: Editor.Data.Node.FormatData) => void;
    addStyleValue: (
      formatData: Editor.Data.Node.FormatData,
      value: Editor.Edition.StylesMap[style],
    ) => void;
    getStylePropertyPath: () => Realtime.Core.RealtimePath;
  };
};

export abstract class BaseStylesManipulator extends BaseManipulator {
  FORMAT_DATA_MAPPER: FormatDataMapper = {
    fontFamily: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.fontfamily != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.fontfamily === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.fontfamily;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.fontfamily = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'fontfamily'];
      },
    },
    fontSize: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.fontsize != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.fontsize === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.fontsize;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.fontsize = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'fontsize'];
      },
    },
    color: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.color != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.color === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.color;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.color = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'color'];
      },
    },
    highlightColor: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.backgroundcolor != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.backgroundcolor === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.backgroundcolor;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.backgroundcolor = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'backgroundcolor'];
      },
    },
    bold: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.bold != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.bold === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.bold;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.bold = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'bold'];
      },
    },
    italic: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.italic != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.italic === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.italic;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.italic = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'italic'];
      },
    },
    underline: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.underline != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.underline === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.underline;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.underline = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'underline'];
      },
    },
    strikethrough: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.strikethrough != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.strikethrough === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.strikethrough;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.strikethrough = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'strikethrough'];
      },
    },
    superscript: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.superscript != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.superscript === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.superscript;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.superscript = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'superscript'];
      },
    },
    subscript: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.subscript != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.subscript === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.subscript;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.subscript = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'subscript'];
      },
    },
    vanish: {
      hasStyle: function (formatData: Editor.Data.Node.FormatData): boolean {
        return formatData.properties.v != null;
      },
      hasStyleValue: function (formatData: Editor.Data.Node.FormatData, value): boolean {
        if (value != null) {
          return formatData.properties.v === value;
        }
        return false;
      },
      removeStyle: function (formatData: Editor.Data.Node.FormatData): void {
        delete formatData.properties.v;
      },
      addStyleValue: function (formatData: Editor.Data.Node.FormatData, value): void {
        if (value != null) {
          formatData.properties.v = value;
        }
      },
      getStylePropertyPath: function () {
        return ['properties', 'v'];
      },
    },
  };

  protected buildFormatData<T extends Editor.Edition.InlineStyles>(
    childNodes: Editor.Data.Node.Data[],
    style: T,
    value: Editor.Edition.StylesMap[T],
  ) {
    const builder = new NodeDataBuilder('format');

    builder.addChildData(...childNodes);

    const data = builder.build();

    if (data) {
      this.FORMAT_DATA_MAPPER[style].addStyleValue(data, value);
    }

    return data;
  }

  protected hasFormatAnyStyle(formatData: Editor.Data.Node.FormatData) {
    const propKeys = Object.keys(formatData.properties);
    return propKeys.length > 0;
  }

  protected updateStyleOperation<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    elementPathToUpdate: Editor.Selection.Path,
    style: T,
    value: Editor.Edition.StylesMap[T],
  ) {
    const propPath = this.FORMAT_DATA_MAPPER[style].getStylePropertyPath();

    baseModel.set([...elementPathToUpdate, ...propPath], value, {
      source: 'LOCAL_RENDER',
    });
  }

  protected removeStyleOperation<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    elementPathToUpdate: Editor.Selection.Path,
    style: T,
  ) {
    const propPath = this.FORMAT_DATA_MAPPER[style].getStylePropertyPath();

    baseModel.delete([...elementPathToUpdate, ...propPath], {
      source: 'LOCAL_RENDER',
    });
  }

  protected canMergeFormat(format1: Editor.Data.Node.Data, format2: Editor.Data.Node.Data) {
    return (
      NodeUtils.isFormatData(format1) &&
      NodeUtils.isFormatData(format2) &&
      format1.properties.bold === format2.properties.bold &&
      format1.properties.color === format2.properties.color &&
      format1.properties.fontfamily === format2.properties.fontfamily &&
      format1.properties.fontsize === format2.properties.fontsize &&
      format1.properties.backgroundcolor === format2.properties.backgroundcolor &&
      format1.properties.italic === format2.properties.italic &&
      format1.properties.strikethrough === format2.properties.strikethrough &&
      format1.properties.superscript === format2.properties.superscript &&
      format1.properties.subscript === format2.properties.subscript &&
      format1.properties.underline === format2.properties.underline &&
      format1.properties.v === format2.properties.v
    );
  }

  protected getClosestWithStyle<T extends Editor.Edition.InlineStyles>(
    baseData: Editor.Data.Node.Data,
    childPath: Editor.Selection.Path,
    style: T,
    value?: Editor.Edition.StylesMap[T],
  ) {
    if (!this.editionContext.stylesManager) {
      return null;
    }

    let closestFormat = NodeUtils.closestOfTypeByPath(baseData, childPath, ['format']);

    while (closestFormat && NodeUtils.isFormatData(closestFormat.data)) {
      const appliedProp = this.editionContext.stylesManager.InlineStylesParser[style](
        closestFormat.data,
      );

      if (value != null) {
        // check is has style and value
        if (appliedProp === value) {
          return closestFormat;
        }
      } else if (appliedProp != null) {
        // check if has only style
        return closestFormat;
      }

      let parentPath = closestFormat.path.slice(0, closestFormat.path.length - 2);
      if (parentPath.length > 0) {
        closestFormat = NodeUtils.closestOfTypeByPath(baseData, parentPath, ['format']);
      } else {
        closestFormat = null;
      }
    }

    return null;
  }

  protected getSylableDataInfo(range: Editor.Selection.JsonRange) {
    if (!this.editionContext.DataManager) {
      return [];
    }

    return range.getNodes(
      this.editionContext.DataManager,
      ['text', ...NodeUtils.INLINE_NON_STYLABLE_TYPES],
      (
        baseData: Editor.Data.Node.Data,
        childData: Editor.Data.Node.Data,
        childPath: Editor.Selection.Path,
      ) => {
        // validation function
        if (NodeUtils.isTextData(childData)) {
          return !NodeUtils.closestOfTypeByPath(
            baseData,
            childPath,
            NodeUtils.INLINE_NON_STYLABLE_TYPES,
          );
        }

        return true;
      },
    );
  }

  protected insertElement(
    baseModel: Editor.Data.Node.Model,
    insertElement: Editor.Data.Node.Data,
    insertPath: Editor.Selection.Path,
  ): Editor.Selection.Path | undefined {
    // do not insert empty elements
    if (
      NodeUtils.isFormatData(insertElement) &&
      insertElement.childNodes &&
      insertElement.childNodes.length === 0
    ) {
      return;
    }

    const baseData = baseModel.selectedData();

    if (!baseData) {
      return;
    }

    let workingPath: Editor.Selection.Path | undefined = [...insertPath];

    let parentChildInfo = NodeUtils.getParentChildInfoByPath(baseData, workingPath);

    if (!parentChildInfo) {
      return;
    }

    const previousSibling = NodeUtils.getPreviousSibling(baseData, workingPath);
    const nextSibling = NodeUtils.getNextSibling(baseData, workingPath);

    let isAtStart = false;
    let isAtEnd = false;
    if (NodeUtils.isTextData(parentChildInfo.childData) && parentChildInfo.contentIndex != null) {
      isAtStart = parentChildInfo.contentIndex === 0;
      isAtEnd = parentChildInfo.contentIndex === parentChildInfo.childData.content.length;
    } else {
      isAtStart = parentChildInfo.childIndex === 0;
      isAtEnd = parentChildInfo.childIndex === (parentChildInfo.parentData.childNodes?.length || 0);
    }

    if (isAtStart && previousSibling && this.canMergeFormat(previousSibling.data, insertElement)) {
      // has previous sibling format and can be merge
      let length = previousSibling.data.childNodes?.length || 0;
      workingPath = [...previousSibling.path, 'childNodes', length];

      const childNodes = insertElement.childNodes || [];
      for (let c = 0; c < childNodes.length; c++) {
        if (workingPath) {
          let insertOp: Editor.Edition.IOperationBuilder = new InsertElementOperation(
            baseModel,
            workingPath,
            childNodes[c],
            {
              pathFix: 'AFTER',
            },
          );
          insertOp.apply();
          workingPath = insertOp.getAdjustedPath();
        }
      }
    } else if (isAtEnd && nextSibling && this.canMergeFormat(nextSibling.data, insertElement)) {
      // has next sibling format and can be merge
      workingPath = [...nextSibling.path, 'childNodes', 0];

      const childNodes = insertElement.childNodes || [];
      for (let c = 0; c < childNodes.length; c++) {
        if (workingPath) {
          let insertOp: Editor.Edition.IOperationBuilder = new InsertElementOperation(
            baseModel,
            workingPath,
            childNodes[c],
            {
              pathFix: 'AFTER',
              mergeText: false,
            },
          );
          insertOp.apply();
          workingPath = insertOp.getAdjustedPath();
        }
      }
    } else {
      let insertOp = new InsertElementOperation(baseModel, workingPath, insertElement, {
        pathFix: 'AFTER',
        mergeText: false,
      });
      insertOp.apply();
      workingPath = insertOp.getAdjustedPath();
    }

    return workingPath;
  }
}
