import { RealtimeFragment } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export type SlideTasksType = Presentation.Model.Tasks.Data['sld'][string];

export class SlideTasks extends RealtimeFragment<SlideTasksType> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId, slideId: string) {
    super(transport, id, 'presTasks', ['sld', slideId]);
  }

  get taskIds() {
    return Object.keys(this.selectedData() || {});
  }

  get tasks() {
    return this.selectedData() || {};
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as SlideTasksType;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
