import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { useParams } from 'react-router';

import UserCard from '_common/components/UserCard/UserCard';
import TagSystem from '_common/components/TagSystem/TagSystem';

import { Setting } from 'Settings/components';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './SharedSpaceSettings.module.scss';
import { LineEllipsis } from '_common/components';

type OpenGenericSettingModalProps = {
  header: string;
  label: string;
  value: string;
  inputType: string;
  type: string | undefined;
  action: string;
  actionValues: { objectId: string; objectType: string };
};

const SharedSpaceSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId }: { id: ObjectId } = useParams();

  const object = useSelector((state) => state.app.data[objectId]);
  const userPermissions = object.user_permissions;

  const openChangeManagerModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ChangeManagerModal',
        data: { name: object.name, data: object },
      }),
    );
  };

  const handleOpenGenericSettingModal = ({
    header,
    label,
    value,
    inputType,
    type,
    action,
    actionValues,
  }: OpenGenericSettingModalProps) => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: { header, label, value, inputType, type, action, actionValues },
      }),
    );
  };

  const handleUpdateName = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({
        id: 'spaces.editName',
      }),
      label: intl.formatMessage({
        id: 'spaces.newValue',
      }),
      value: object.name,
      type: 'name',
      inputType: 'text',
      action: 'rename',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  const handleUpdateDescription = () => {
    handleOpenGenericSettingModal({
      header: intl.formatMessage({
        id: 'spaces.editDescription',
      }),
      label: intl.formatMessage({
        id: 'spaces.newValue',
      }),
      value: object.description,
      type: 'description',
      inputType: 'textarea',
      action: 'editDescription',
      actionValues: { objectId: object.id, objectType: object.type },
    });
  };

  return (
    <>
      <div className={styles.root}>
        {/* Title */}
        <Setting
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            handleUpdateName
          }
          label={intl.formatMessage({
            id: 'global.name',
          })}
          onEditLabel="global.edit"
          testId="name"
        >
          {object.name}
        </Setting>
        {/* Creation */}
        <Setting
          label={intl.formatMessage({
            id: 'global.creator',
          })}
          testId="creator"
        >
          {<UserCard userId={object.creator} />}
        </Setting>
        {/* Owner */}
        <Setting
          manager
          label={intl.formatMessage({
            id: 'global.owner',
          })}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            openChangeManagerModal
          }
          onEditLabel="global.edit"
          testId="owner"
        >
          {<UserCard userId={object.owner} />}
        </Setting>
        {/* Description */}
        <Setting
          label={intl.formatMessage({
            id: 'settings.general.description',
          })}
          onEdit={
            (userPermissions.includes('admin') || userPermissions.includes('owner')) &&
            handleUpdateDescription
          }
          onEditLabel="global.edit"
          testId="description"
        >
          <LineEllipsis>
            {object.description || intl.formatMessage({ id: 'NO_DESCRIPTION' })}
          </LineEllipsis>
        </Setting>
        {/* Tags */}
        <div className={styles.setting} data-testid="tags-row-container">
          <span className={styles.label}>
            <FormattedMessage id="settings.general.tags" />
          </span>
          <div className={styles.value}>
            <TagSystem
              objectId={objectId}
              placeholder={intl.formatMessage({ id: 'SELECT_TAG' })}
              testId="space-settings"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default SharedSpaceSettings;
