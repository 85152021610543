import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { usePublicGroup } from '_common/hooks';
import { navigateToGroups } from 'router/history';

import { ActionBar } from '_common/components';
import { PathElement } from '_common/components/ActionBar/ActionBarBreadcrumb/ActionBarBreadcrumb';

const Header = () => {
  const intl = useIntl();
  const { id: groupId }: { id: ObjectId } = useParams();
  const { group: data } = usePublicGroup(groupId);

  const handleGoTo = (element: PathElement) => {
    if (element.id === 'groups') {
      navigateToGroups();
    }
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'groups',
            name: intl.formatMessage({ id: 'storage.sidebar.groups' }),
          },
          {
            id: data.id,
            name: data.name,
          },
        ]}
        onBreadcrumbElementClick={handleGoTo}
      />
    </ActionBar>
  );
};

export default Header;
