import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectReferenceStyleById } from 'Settings/pages/TenantSettingsPage/ReferencesStyles/ReferenceStylesApi';

import styles from './NameCell.module.scss';

type NameCellProps = {
  refStyleId: Template['id'];
};

const NameCell = ({ refStyleId }: NameCellProps) => {
  const dispatch = useDispatch();

  const refStyle = useSelector((state) => selectReferenceStyleById(state, refStyleId));

  const handleLinkClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ExtensionUpdateInformationModal',
        data: {
          id: refStyleId,
          type: 'referenceStyle',
        },
      }),
    );
  };

  return (
    <div className={styles.name}>
      <div className={styles.label}>{refStyle?.name}</div>
      {refStyle?.status === 'outdated' && (
        <Button variant="link" size="small" onClick={handleLinkClick} testId="whatsNew">
          <FormattedMessage id="WHATS_NEW" />
        </Button>
      )}
    </div>
  );
};

export default NameCell;
