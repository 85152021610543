import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';

const ElementHeading = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const openElementStatusModal = () => {
    dispatch(openModal('ElementStatusFormModal'));
  };

  return (
    <SectionHeading
      sticky
      title={intl.formatMessage({ id: 'ELEMENT_STATUS' })}
      buttonRightProps={{
        size: 'medium',
        variant: 'primary',
        onClick: openElementStatusModal,
        children: intl.formatMessage({ id: 'NEW_ELEMENT_STATUS' }),
        testId: 'new-element-status-button',
      }}
      testId="element-status-heading"
    />
  );
};

export default ElementHeading;
