import { MouseEvent } from 'react';
import { usePopper, Popover } from 'dodoc-design-system';
import styles from './PopperTooltip.module.scss';

import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';

type PopperTooltipProps = TooltipProps;

const PopperTooltip = ({ children, content, testId, placement = 'bottom' }: PopperTooltipProps) => {
  const { referenceProps, popperProps, close, open } = usePopper({
    placement,
    closeOnOutsideClick: true,
  });

  const showToolTip = (e: MouseEvent<HTMLDivElement>) => {
    return e.currentTarget.scrollWidth > e.currentTarget.clientWidth;
  };

  return (
    <>
      <div
        className={styles.ellipsis}
        onMouseEnter={(e) => {
          showToolTip(e) && open();
        }}
        onMouseLeave={close}
        {...referenceProps}
      >
        {children}
      </div>
      <Popover {...popperProps} testId="popper-tooltip">
        <div className={styles.tooltip} data-testid={testId}>
          {content}
        </div>
      </Popover>
    </>
  );
};

export default PopperTooltip;
