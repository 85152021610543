import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce, useSelector } from '_common/hooks';

import { useFilteredComments, useFilteredTasks, useSlideId } from 'Presentation/hooks';
import { setCreating } from 'Presentation/PresentationSlice';
import { ObjectHighlightRect, getHighlightRects, getSvgBoundingBox } from './CardHighlight/utils';

import CardHighlight from './CardHighlight/CardHighlight';

const InlineCards = () => {
  const currentSlideId = useSlideId();
  const activeComments = useFilteredComments(currentSlideId);
  const activeTasks = useFilteredTasks(currentSlideId);

  const creating = useSelector((state) => state.presentation.general.creating);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  const [shapesRendered, setShapesRendered] = useState<boolean>(false);
  /**
   * When zoom changes UI will rerender
   * Debouncing zoom value and using it on the useMemo below will make sure we are getting the updated element rects
   */
  const debouncedZoom = useDebounce(zoom, 0);

  //Stop creating new card if slide is changed
  useEffect(() => {
    setCreating(null);
  }, [currentSlideId]);

  const getShapeHighlights = useCallback(
    (objects: (Presentation.Data.Comment | Presentation.Data.Task)[]) => {
      /** Wait for shapes to be rendered */
      if (!shapesRendered) {
        setTimeout(() => setShapesRendered(true), 0);
        return [];
      }

      //Only get object from current slide and anchor without range
      return objects
        .filter((object) => object.anchor[0].id === currentSlideId && object.anchor.length === 2)
        .reduce<ObjectHighlightRect<Presentation.Data.Comment | Presentation.Data.Task>[]>(
          (highlights, object) => {
            //Safeguard, ignore object if anchor doesn't have a shape id
            const shapeId = object.anchor[1]?.id;
            if (!shapeId) {
              return highlights;
            }

            const shapeRect = getSvgBoundingBox(
              document.getElementById(`slide-${shapeId}`),
              debouncedZoom,
            );
            //Ignore highlight if shape is not rendered or not an SVG element
            if (!shapeRect) {
              return highlights;
            }

            highlights.push({
              object,
              rects: [shapeRect],
            });
            return highlights;
          },
          [],
        );
    },
    //Debounced Zoom not used in callback but required to recalculate rects after its changed
    [shapesRendered, debouncedZoom],
  );

  const getRangeHighlights = useCallback(
    (objects: (Presentation.Data.Comment | Presentation.Data.Task)[]) => {
      /** Wait for shapes to be rendered */
      if (!shapesRendered) {
        setTimeout(() => setShapesRendered(true), 0);
        return [];
      }

      const slideRect = document.getElementById(currentSlideId);
      const slideRectBounds = slideRect?.getBoundingClientRect();
      const slideRectOffset = slideRectBounds && {
        top: slideRectBounds.top,
        left: slideRectBounds.left,
      };

      //Only get tasks from current slide and anchor without range
      return objects
        .filter((task) => task.anchor[0].id === currentSlideId && task.anchor.length === 3)
        .reduce<ObjectHighlightRect<Presentation.Data.Comment | Presentation.Data.Task>[]>(
          (highlights, object) => {
            //Safeguard, ignore object if anchor doesn't have a range id
            const textBodyId = object.anchor[2]?.start.b;
            if (!textBodyId) {
              return highlights;
            }

            //Ignore highlight if textBody is not rendered
            const textBodyNode = document.getElementById(textBodyId);
            if (!textBodyNode) {
              return highlights;
            }

            highlights.push({
              object,
              rects: getHighlightRects({
                anchor: object.anchor,
                negativeOffset: slideRectOffset,
              }),
            });
            return highlights;
          },
          [],
        );
    },
    //Debounced Zoom not used in callback but required to recalculate rects after its changed
    [shapesRendered, debouncedZoom],
  );

  const taskHighlights = useMemo(() => {
    const shapeHighlights = getShapeHighlights(activeTasks);
    const rangeHighlights = getRangeHighlights(activeTasks);

    //Range highlights added on top of shape highlights for clickability priority
    return [...shapeHighlights, ...rangeHighlights];
  }, [shapesRendered, activeTasks, currentSlideId]);

  const commentHighlights = useMemo(() => {
    const shapeHighlights = getShapeHighlights(activeComments);
    const rangeHighlights = getRangeHighlights(activeComments);

    //Range highlights added on top of shape highlights for clickability priority
    return [...shapeHighlights, ...rangeHighlights];
  }, [shapesRendered, activeComments, currentSlideId]);

  const creatingHighlightRects = useMemo<ShortRect[]>(() => {
    const slideRect = document.getElementById(currentSlideId);
    const slideRectBounds = slideRect?.getBoundingClientRect();
    const slideRectOffset = slideRectBounds && {
      top: slideRectBounds.top,
      left: slideRectBounds.left,
    };

    switch (creating?.anchor?.length) {
      //Get highlight for a range
      case 3:
        //Safeguard to display highlight only if textbody exists
        //No need to use slideRect.querySelector because thumbnail TextBody ids have 'thumbnail-' prefix
        if (!document.getElementById(creating.anchor[2].start.b)) {
          return [];
        }

        //Get rects for the range specified in the anchor
        return getHighlightRects({
          anchor: creating.anchor,
          negativeOffset: slideRectOffset,
        });
      //Get highlight for a shape
      case 2:
        //Anchor should have shape id in second element
        const shapeId = creating.anchor[1]?.id;
        if (!shapeId) {
          return [];
        }

        const shapeRect = getSvgBoundingBox(
          document.getElementById(`slide-${shapeId}`),
          debouncedZoom,
        );
        //Ignore highlight if shape is not rendered or not an SVG element
        if (!shapeRect) {
          return [];
        }

        return [shapeRect];
    }

    return [];
  }, [currentSlideId, creating, debouncedZoom]);

  return (
    <>
      {taskHighlights.map(({ object, rects }) => (
        <CardHighlight
          key={`task-highlight-${object.id}`}
          object={object}
          rects={rects}
          type="task"
        />
      ))}
      {commentHighlights.map(({ object, rects }) => (
        <CardHighlight
          key={`comment-highlight-${object.id}`}
          object={object}
          rects={rects}
          type="comment"
        />
      ))}
      {/* Only display card creation highlight if creating a card and with an anchor */}
      {creating?.anchor?.length ? <CardHighlight rects={creatingHighlightRects} creating /> : null}
    </>
  );
};

export default InlineCards;
