import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import styles from './Priority.module.scss';

import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type Priority = PDF.Annotation['priority'];

type PriorityProps = {
  type: 'comment' | 'annotation';
  oldPriorityId: Priority | null;
  newPriorityId: Priority;
};

const PRIORITY: {
  [key in Priority]: { iconDDC: IconTypes['24']; iconPDF: IconTypes['24']; labelId: string };
} = {
  High: {
    iconDDC: 'High',
    iconPDF: 'PDFHigh',
    labelId: 'editor.sidebar.review.filter.priority.high',
  },
  Medium: {
    iconDDC: 'Medium',
    iconPDF: 'PDFMedium',
    labelId: 'editor.sidebar.review.filter.priority.medium',
  },
  Low: {
    iconDDC: 'Low',
    iconPDF: 'PDFLow',
    labelId: 'editor.sidebar.review.filter.priority.low',
  },
};

const Priority = ({ type, oldPriorityId, newPriorityId }: PriorityProps) => {
  const getPriority = (priorityId: Priority) => {
    const priority = PRIORITY[priorityId];
    const icon: IconTypes['24'] | null =
      type === 'annotation' ? priority.iconPDF : priority.iconDDC;

    return (
      <div
        className={styles.priority}
        data-testid={`notification-priority-${
          priorityId === oldPriorityId ? 'old' : 'new'
        }-${priorityId.toLowerCase()}`}
      >
        <Icon icon={icon} size={24} margin="0 0.5rem 0 0" />
        <FormattedMessage id={priority.labelId} />
      </div>
    );
  };

  if (oldPriorityId == null || newPriorityId == null || type == null) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {oldPriorityId && (
          <>
            {getPriority(oldPriorityId)}
            <div className={styles.arrow}>→</div>
          </>
        )}
        {getPriority(newPriorityId)}
      </div>
    </div>
  );
};

export default Priority;
