import { useEffect } from 'react';
import { useSelector } from './redux';

const useScrollToAnnotationElement = (annotation: PDF.Annotation, isSelected: boolean) => {
  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);

  useEffect(() => {
    if (isSelected) {
      const element = document.getElementById(annotation.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const visible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 160 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) - 160;
        if (!visible && annotation.pageNumber === currentPageNumber) {
          element.scrollIntoView({ block: 'center' });
        }
      }
    }
  }, [annotation, isSelected]);
};

export default useScrollToAnnotationElement;
