export class TextElement extends Text {
  // constructor(textContent?: string) {
  //   super(textContent);
  // }

  get parentId() {
    if (this.parentElement != null) {
      return this.parentElement.id;
    }
    return '';
  }
}
