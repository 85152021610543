import { BaseDecorator } from '..';

export class BasicModeDecorator extends BaseDecorator {
  decorator(data: Editor.Data.Node.Data, node: HTMLElement) {
    if (this.isNodeApproved(data)) {
      return this.approveDecorator(data, node);
    }
    if (data.id && this.data.nodes.isNodeReadonly(data.id)) {
      return this.readonlyDecorator(data, node);
    }
    return node;
  }
}
