import { UserAvatar } from '_common/components';
import PopperTooltip from '../List/PopperTooltip/PopperTooltip';
import styles from '../List/List.module.scss';

type EmailAddressCellProps = {
  user: UserPublicProfileExtended;
};

const EmailAddressCell = ({ user }: EmailAddressCellProps) => {
  return (
    <div className={`${styles.align} ${styles.email} ${!user.is_active && styles.deactivated}`}>
      <div className={styles.avatar} data-testid={`email-column-avatar-user-${user.id}`}>
        <UserAvatar userId={user.id} margin="0 2rem 0 0" />
      </div>
      <PopperTooltip content={user.email} testId="email-tooltip">
        <>{user.email}</>
      </PopperTooltip>
    </div>
  );
};

export default EmailAddressCell;
