import { Ref, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { InputField } from 'dodoc-design-system';

import { SearchUser } from '_common/components';

import type { UserOption } from '_common/components/SearchUser/SearchUser';

type AssigneeFieldProps = {
  id?: string;
  collaborators: UserOption[];
  assignee?: UserOption;
  onChange: (newValue: UserOption) => void;
  testId: string;
};

const AssigneeField = forwardRef(
  (
    { id, collaborators, assignee, onChange, testId }: AssigneeFieldProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const intl = useIntl();

    return (
      <InputField
        size="medium"
        label={intl.formatMessage({ id: 'ASSIGNED_TO' })}
        feedback={false}
        margin="2rem 0 0 0"
        testId={`${testId}-assgined-to`}
      >
        <div ref={ref}>
          <SearchUser
            id={id}
            width="100%"
            placeholder={intl.formatMessage({
              id: 'TASK_SELECT_USER',
            })}
            options={collaborators}
            value={assignee && assignee.value ? assignee : null}
            onChange={onChange}
            searchable
            // @ts-expect-error Fix type in the DS
            noOptionsMessage={() => intl.formatMessage({ id: 'NO_RESULTS_FOUND' })}
            testId={`${testId}-assigned-to`}
          />
        </div>
      </InputField>
    );
  },
);

export default AssigneeField;
