import { useIntl } from 'react-intl';
import { Toggle, Tooltip } from 'dodoc-design-system';

import styles from './OccurrenceNavigator.module.scss';

type Occurrence = Editor.SpellCheck.Occurrence;

type OccurrenceNavigatorProps = {
  occurrence?: Occurrence;
  handleFindPrevious: () => void;
  handleFindNext: () => void;
};

const OccurrenceNavigator = ({
  occurrence,
  handleFindPrevious,
  handleFindNext,
}: OccurrenceNavigatorProps) => {
  const intl = useIntl();

  const handleNext = () => {
    handleFindNext();
  };

  const handlePrevious = () => {
    handleFindPrevious();
  };

  return (
    <div className={styles.root}>
      <div>
        <Tooltip
          content={intl.formatMessage({ id: 'PREVIOUS' })}
          testId="spell-check-previous-tooltip"
        >
          <Toggle
            variant="link"
            size="small"
            onClick={handlePrevious}
            style={{ marginLeft: 'auto' }}
            icon="ArrowLeft"
            testId="spell-check-previous-button"
          />
        </Tooltip>
      </div>
      <div className={styles.text}>{occurrence?.word}</div>
      <div>
        <Tooltip content={intl.formatMessage({ id: 'NEXT' })} testId="spell-check-next-tooltip">
          <Toggle
            variant="link"
            size="small"
            onClick={handleNext}
            icon="ArrowRight"
            testId="spell-check-next-button"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default OccurrenceNavigator;
