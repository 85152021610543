import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class Notes extends RealtimeObject<Notes.NotesModelData> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'notes');
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit('UPDATED', this.get(), ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  note(noteId: Notes.NoteDataId): Notes.NoteData | null {
    //! previous implementation, for reference
    //  return (
    //   this.get(['nts', noteId]) || {
    //     id: noteId,
    //   }
    // );
    return this.get(['nts', noteId]) || null;
  }

  placement(): Notes.NotePlacement {
    return this.get(['p']) || 'd';
  }

  async setPlacement(placement: Notes.NotePlacement) {
    return this.set(['p'], placement);
  }

  async addNote(
    id: Notes.NoteDataId | null,
    type: Notes.NoteType,
    content: string,
    author: string,
  ) {
    const noteId = id ? id : EditorDOMUtils.generateRandomNodeId();

    await this.set(['nts', noteId], {
      type,
      content,
      id: noteId,
      author,
    });
    return noteId;
  }

  async editNote(id: Notes.NoteDataId, content?: string) {
    const note = this.note(id);
    const edit: any = {};
    if (content !== undefined) edit.content = content;

    await this.set(['nts', id], {
      ...note,
      ...edit,
    });
    return id;
  }
}
