import { useComments } from 'Presentation/SyncStore';

const useAllComments = (slideId?: Presentation.Data.Slide['_id']) => {
  const data = useComments()?.data;
  const comments = data ? Object.values(data) : [];

  const filterComment = (comment: Presentation.Data.Comment) => {
    if (slideId) {
      return comment.anchor[0].id === slideId;
    }

    return true;
  };

  const filteredComments: Presentation.Data.Comment[] = comments.filter(filterComment);

  return filteredComments;
};

export default useAllComments;
