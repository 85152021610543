import { faker } from '@faker-js/faker';
import { createMockedObjectId } from './common';

export function createMockedAnnotation(overwrites?: Partial<PDF.Annotation>): PDF.Annotation {
  const subtype: PDF.Annotation['subtype'] =
    overwrites?.subtype ?? faker.helpers.arrayElement<PDF.Annotation['subtype']>(['Task', 'Note']);

  const priority: PDF.Annotation['priority'] =
    overwrites?.priority ??
    faker.helpers.arrayElement<PDF.Annotation['priority']>(['High', 'Medium', 'Low']);

  const annotationBase: PDF.Annotation.Base = {
    id: createMockedObjectId(),
    annotationFlags: overwrites?.annotationFlags ?? +faker.random.numeric(),
    pageNumber: overwrites?.pageNumber ?? +faker.random.numeric(1),
    color: overwrites?.color ?? {},
    border: overwrites?.border ?? {
      style: 'S',
      width: 0,
    },
    content: overwrites?.content ?? {
      content: [],
      dir: 'ltr',
    },
    rect: overwrites?.rect ?? {
      left: 287.89943609022555,
      bottom: 409.45723684210526,
      right: 311.9595864661654,
      top: 433.5173872180451,
      width: 24.060150375939827,
      height: 24.060150375939827,
    },
    authorId: overwrites?.authorId ?? '1',
    //@ts-expect-error type shouldnt be "Date", the actual data its string.
    creationDate: overwrites?.creationDate ?? faker.date.recent().toISOString(),
    //@ts-expect-error type shouldnt be "Date", the actual data its string.
    modificationDate: overwrites?.modificationDate ?? faker.date.recent().toISOString(),
    subtype: 'Note',
    priority,
  };

  //TODO:Proccess other subtypes if needed
  switch (subtype) {
    case 'Task': {
      let task: PDF.Annotation.Task = {
        ...annotationBase,
        subtype,
        watchers: [],
        status: 'td',
        dueDate: faker.date.soon().toISOString(),
        assignee: '',
      };

      if (overwrites?.subtype === 'Task') {
        task = { ...task, ...overwrites };
      }

      return task;
    }
    case 'Note': {
      let note: PDF.Annotation.Base = { ...annotationBase, subtype };

      if (overwrites?.subtype === 'Note') {
        note = { ...note, ...overwrites };
      }

      return note;
    }
    default: {
      return annotationBase;
    }
  }
}
