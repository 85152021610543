import { useState, useEffect, FocusEvent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Button, Modal, Input, InputField, Select, SectionHeader } from 'dodoc-design-system';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { INDENT_TYPE } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';
import { parseMeasurement } from 'utils';
import { MEASURE_MIX_MAX_VALUE } from 'utils/parseMeasurement';
import styles from './IndentationOptionsModal.module.scss';

type Indentation = 'leftIndentation' | 'rightIndentation' | 'specialIndentValue';

type UnitMeasure = 'cm' | 'in' | 'pt' | 'px';

const MEASURES: { value: UnitMeasure; label: string }[] = [
  { value: 'cm', label: 'centimeters (cm)' },
  { value: 'in', label: 'inches (in)' },
  { value: 'pt', label: 'points (pt)' },
  { value: 'px', label: 'pixels (px)' },
];

const MODAL = 'IndentationOptionsModal';

const IndentationOptions = () => {
  const intl = useIntl();

  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const indentation = useSelector((state) => state.modals[MODAL].indentation);

  // local
  const specialIndent = useMemo(() => {
    return [
      { value: INDENT_TYPE.NONE, label: intl.formatMessage({ id: 'NONE' }) },
      { value: INDENT_TYPE.FIRST_LINE, label: intl.formatMessage({ id: 'FIRST_LINE' }) },
      { value: INDENT_TYPE.HANGING, label: intl.formatMessage({ id: 'HANGING' }) },
    ];
  }, []);

  const [specialIndentSelected, setSpecialIndentSelected] = useState<
    (typeof specialIndent)[number] | null
  >(null);
  const [leftIndentation, setLeftIndentation] = useState('');
  const [leftIndentationUnit, setLeftIndentationUnit] = useState<{
    value: UnitMeasure;
    label: string;
  }>({
    label: 'centimeters (cm)',
    value: 'cm',
  });
  const [rightIndentation, setRightIndentation] = useState('');
  const [rightIndentationUnit, setRightIndentationUnit] = useState<{
    value: UnitMeasure;
    label: string;
  }>({
    label: 'centimeters (cm)',
    value: 'cm',
  });
  const [specialIndentValue, setSpecialIndentValue] = useState('');
  const [specialIndentUnit, setSpecialIndentUnit] = useState<{ value: UnitMeasure; label: string }>(
    {
      label: 'centimeters (cm)',
      value: 'cm',
    },
  );
  const [validations, setValidations] = useState<{
    leftIndentation?: string;
    rightIndentation?: string;
    specialIndentValue?: string;
  }>({});

  useEffect(() => {
    if (isOpen) {
      const specialIndentValue =
        typeof indentation.specialIndent === 'string'
          ? indentation.specialIndent
          : indentation.specialIndent.value;
      setSpecialIndentSelected({
        value:
          specialIndent.find((x) => x.value === specialIndentValue.toLowerCase())?.value ||
          INDENT_TYPE.NONE,
        label:
          specialIndent.find((x) => x.value === specialIndentValue.toLowerCase())?.label ||
          intl.formatMessage({ id: 'NONE' }),
      });
      setLeftIndentation(indentation.leftIndentation);
      setLeftIndentationUnit({ label: 'centimeters (cm)', value: 'cm' });
      setRightIndentation(indentation.rightIndentation);
      setRightIndentationUnit({ label: 'centimeters (cm)', value: 'cm' });
      setSpecialIndentValue(indentation.specialIndentValue);
      setSpecialIndentUnit({ label: 'centimeters (cm)', value: 'cm' });
    }
  }, [isOpen, indentation]);

  const handleSetters = (text: Indentation) => {
    switch (text) {
      case 'leftIndentation':
        setLeftIndentation(indentation.leftIndentation);
        setLeftIndentationUnit({ label: 'centimeters (cm)', value: 'cm' });
        break;
      case 'rightIndentation':
        setRightIndentation(indentation.rightIndentation);
        setRightIndentationUnit({ label: 'centimeters (cm)', value: 'cm' });
        break;
      case 'specialIndentValue':
        setSpecialIndentValue(indentation.specialIndentValue);
        setSpecialIndentUnit({ label: 'centimeters (cm)', value: 'cm' });
        break;
      default:
        break;
    }
  };

  const handleOnBlurIndentationInput = (e: FocusEvent<HTMLInputElement>, text: Indentation) => {
    let measurement;

    switch (text) {
      case 'leftIndentation':
        measurement = parseMeasurement(e.target.value, 'pt', {
          defaultUnit: leftIndentationUnit.value,
        });
        break;
      case 'rightIndentation':
        measurement = parseMeasurement(e.target.value, 'pt', {
          defaultUnit: rightIndentationUnit.value,
        });
        break;
      case 'specialIndentValue':
        measurement = parseMeasurement(e.target.value, 'pt', {
          defaultUnit: specialIndentUnit.value,
        });

        break;
      default:
        break;
    }

    if (measurement == null) {
      handleSetters(text);
    }
  };

  const close = () => {
    clearValidations();
    dispatch(closeAndResetModal(MODAL));
  };

  const handleApply = () => {
    const state: Editor.Elements.IndentationProperties = {
      leftIndentation: parseMeasurement(leftIndentation, 'pt', {
        defaultUnit: leftIndentationUnit.value,
      }),
      rightIndentation: parseMeasurement(rightIndentation, 'pt', {
        defaultUnit: rightIndentationUnit.value,
      }),
      specialIndentValue: null,
    };

    if (specialIndentSelected?.value != null) {
      state.specialIndent = specialIndentSelected.value;
    }

    if (specialIndentSelected?.value !== INDENT_TYPE.NONE) {
      state.specialIndentValue = parseMeasurement(specialIndentValue, 'pt', {
        defaultUnit: specialIndentUnit.value,
      });
    }

    EditorManager.getInstance().indentCurrentSelection(state);
    close();
  };

  const clearValidations = () => {
    setValidations({ leftIndentation: '', rightIndentation: '', specialIndentValue: '' });
  };

  const handleIndentationMinMaxValue = (text: Indentation) => {
    const unitValue = () => {
      if (text === 'rightIndentation') {
        return rightIndentationUnit;
      } else if (text === 'specialIndentValue') {
        return specialIndentUnit;
      } else {
        return leftIndentationUnit;
      }
    };

    const max = `${MEASURE_MIX_MAX_VALUE[unitValue().value]['max']}`;
    const min = `${MEASURE_MIX_MAX_VALUE[unitValue().value]['min']}`;
    return {
      max,
      min,
    };
  };

  const handleIndentationValue = (e: React.ChangeEvent<HTMLInputElement>, text: Indentation) => {
    const value = e.target.value;
    if (
      Number(value) > Number(handleIndentationMinMaxValue(text).max) ||
      Number(value) < Number(handleIndentationMinMaxValue(text).min)
    ) {
      validations[text] = intl.formatMessage(
        { id: 'CHOOSE_A_VALUE_BETWEEN' },
        {
          min: handleIndentationMinMaxValue(text).min,
          max: handleIndentationMinMaxValue(text).max,
        },
      );
    } else {
      validations[text] = '';
    }

    if (Object.keys(validations).length > 0) {
      setValidations(validations);
    }
  };

  const handleIndentationUnit = (
    value: { value: UnitMeasure; label: string },
    text: Indentation,
  ) => {
    let measure;

    switch (text) {
      case 'leftIndentation':
        measure = parseMeasurement(leftIndentation, value.value, {
          defaultUnit: leftIndentationUnit.value,
        });
        setLeftIndentation(`${measure}` || '');
        setLeftIndentationUnit(value);
        break;
      case 'rightIndentation':
        measure = parseMeasurement(rightIndentation, value.value, {
          defaultUnit: rightIndentationUnit.value,
        });
        setRightIndentation(`${measure}` || '');
        setRightIndentationUnit(value);
        break;
      case 'specialIndentValue':
        measure = parseMeasurement(specialIndentValue, value.value, {
          defaultUnit: specialIndentUnit.value,
        });
        setSpecialIndentValue(`${measure}` || '');
        setSpecialIndentUnit(value);
        break;
      default:
        break;
    }
    setValidations((prevState) => {
      return { ...prevState, [text]: '' };
    });
  };

  return (
    <Modal width="69rem" open={!!isOpen} onClose={close} testId="indentation-modal">
      <Modal.Header onClose={close}>
        <FormattedMessage id="INDENTATION_OPTIONS" />
      </Modal.Header>
      <Modal.Body>
        <SectionHeader margin="0 0 1rem 0">
          <FormattedMessage id="INDENTATION" />
        </SectionHeader>

        <div className={styles.fields}>
          <InputField
            width="27rem"
            label={intl.formatMessage({ id: 'LEFT' })}
            size="large"
            feedback={validations.leftIndentation ?? undefined}
            testId="indentation-modal-left-indentation"
          >
            <div className={styles.inputs}>
              <Input
                type="number"
                allowDecimal
                value={`${leftIndentation}`}
                onBlur={(e) => handleOnBlurIndentationInput(e, 'leftIndentation')}
                onChange={(e) => {
                  setLeftIndentation(e.target.value);
                  handleIndentationValue(e, 'leftIndentation');
                }}
                size="large"
                placeholder=""
                margin="0 1rem 0 0"
                error={!!validations.leftIndentation}
                testId="indentation-modal-left-indentation"
              />
              <Select
                width="11rem"
                options={MEASURES}
                value={{
                  value: leftIndentationUnit.value,
                  label: leftIndentationUnit.value as string,
                }}
                onChange={(value) => handleIndentationUnit(value, 'leftIndentation')}
                menuPosition="fixed"
                menuWidth="22rem"
                clearable={false}
                testId="indentation-modal-left-indentation-unit"
              />
            </div>
          </InputField>
          <InputField
            width="27rem"
            margin="0 0 0 7rem"
            label={intl.formatMessage({ id: 'RIGHT' })}
            size="large"
            feedback={validations.rightIndentation ?? undefined}
            testId="indentation-modal-right-indentation"
          >
            <div className={styles.inputs}>
              <Input
                type="number"
                allowDecimal
                value={`${rightIndentation}`}
                onBlur={(e) => handleOnBlurIndentationInput(e, 'rightIndentation')}
                onChange={(e) => {
                  setRightIndentation(e.target.value);
                  handleIndentationValue(e, 'rightIndentation');
                }}
                size="large"
                placeholder=""
                margin="0 1rem 0 0"
                error={!!validations.rightIndentation}
                testId="indentation-modal-right-indentation"
              />
              <Select
                width="11rem"
                options={MEASURES}
                value={{
                  value: rightIndentationUnit.value,
                  label: rightIndentationUnit.value as string,
                }}
                onChange={(value) => handleIndentationUnit(value, 'rightIndentation')}
                menuPosition="fixed"
                menuWidth="22rem"
                clearable={false}
                testId="indentation-modal-right-indentation-unit"
              />
            </div>
          </InputField>
        </div>
        <SectionHeader margin="2rem 0 3rem 0">
          <FormattedMessage id="SPECIAL_INDENT" />
        </SectionHeader>
        <div className={styles.fields}>
          <Select
            options={specialIndent}
            value={specialIndent.find((option) => option.value === specialIndentSelected?.value)}
            onChange={setSpecialIndentSelected}
            width="27rem"
            menuPosition="fixed"
            size="large"
            clearable={false}
            testId="indentation-modal-special-indentation-type"
          />
          <InputField
            size="large"
            feedback={validations.specialIndentValue ?? undefined}
            margin="0 0 0 7rem"
            width="27rem"
            testId="indentation-modal-special-indentation"
          >
            <div className={styles.inputs}>
              <Input
                value={`${specialIndentValue}`}
                onChange={(e) => {
                  setSpecialIndentValue(e.target.value);
                  handleIndentationValue(e, 'specialIndentValue');
                }}
                disabled={specialIndentSelected?.value?.toLowerCase() === INDENT_TYPE.NONE}
                onBlur={(e) => handleOnBlurIndentationInput(e, 'specialIndentValue')}
                size="large"
                placeholder=""
                margin="0 1rem 0 0"
                type="number"
                allowDecimal
                error={!!validations.specialIndentValue}
                testId="indentation-modal-special-indentation"
              />
              <Select
                width="11rem"
                options={MEASURES}
                value={{ value: specialIndentUnit.value, label: specialIndentUnit.value as string }}
                onChange={(value) => handleIndentationUnit(value, 'specialIndentValue')}
                menuPosition="fixed"
                menuWidth="22rem"
                clearable={false}
                disabled={specialIndentSelected?.value?.toLowerCase() === INDENT_TYPE.NONE}
                testId="indentation-modal-special-indentation-unit"
              />
            </div>
          </InputField>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="indentation-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleApply}
          disabled={
            !!validations.leftIndentation ||
            !!validations.rightIndentation ||
            !!validations.specialIndentValue
          }
          testId="indentation-modal-submit-button"
        >
          <FormattedMessage id="DONE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IndentationOptions;
