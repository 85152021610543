import { SectionHeading } from 'dodoc-design-system';
import { useIntl } from 'react-intl';

const SessionHeading = () => {
  const intl = useIntl();

  return (
    <SectionHeading
      sticky
      margin="4rem 0 0 0"
      title={intl.formatMessage({ id: 'settings.security.session' })}
      testId="sessions-heading"
    />
  );
};

export default SessionHeading;
