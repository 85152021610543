import { PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { Command } from '../Command';
import { UpdateImagePropertiesOperation } from '../../Operations/ImageOperations';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';
import { ELEMENTS } from 'Editor/services/consts';

export class UpdateImagePropertiesCommand extends Command {
  properties: Editor.Styles.ImageProperties;

  constructor(context: Editor.Edition.Context, properties: Editor.Styles.ImageProperties) {
    super(context);

    this.properties = properties;
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    const closest = NodeUtils.closestOfTypeByPath(
      this.actionContext.baseData,
      this.actionContext.range.start.p,
      ['img', 'image-element'],
    );

    if (closest) {
      const image = EditorDOMUtils.getNode(closest.data.id);

      if (EditorDOMElements.isImageElement(image)) {
        const closestBlock = EditorDOMUtils.closest(image, [
          ELEMENTS.ParagraphElement.TAG,
          ELEMENTS.FigureElement.TAG,
        ]);
        const offsets = EditorDOMUtils.getOffsets(image, closestBlock);

        if (offsets) {
          if (NodeUtils.isImageData(closest.data) && PathUtils.isValidSelectionPath(closest.path)) {
            this.applyOperations(
              this.actionContext.baseModel,
              closest.path,
              offsets,
              closest.data,
              this.properties,
            );
          }
        }
      }
    }

    this.createPatch();
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    offsets: Rect,
    imageData: Editor.Data.Node.ImageData,
    properties: Editor.Styles.ImageProperties,
  ) {
    const operation = new UpdateImagePropertiesOperation(
      baseModel,
      path,
      offsets,
      imageData,
      properties,
    );
    return operation.apply();
  }
}
