import { Checkbox } from 'dodoc-design-system';
import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ShareModal.module.scss';

type ApplyToDescendantsProps = {
  name: string;
  applyToDescendants: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
};

const ApplyToDescendants = ({ applyToDescendants, name, onChange }: ApplyToDescendantsProps) => {
  return (
    <div className={`${styles.descendants} ${applyToDescendants ? styles.active : undefined}`}>
      <Checkbox
        checked={applyToDescendants ? 'checked' : 'unchecked'}
        onChange={() => {
          onChange(!applyToDescendants);
        }}
        testId="apply-to-descendants-checkbox"
      >
        <FormattedMessage id="storage.modals.share.applyToDescendants" values={{ name }} />
      </Checkbox>
    </div>
  );
};

export default ApplyToDescendants;
