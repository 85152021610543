import { Command } from '../Command';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { EditorService } from '_common/services';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { ErrorElementNotEditable } from '../../Errors';

export class EditEquationCommand extends Command {
  async handleExec() {
    this.buildActionContext();

    if (!this.context.DataManager || !this.actionContext) {
      throw new Error('Context is not defined');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    const result = NodeUtils.closestOfTypeByPath(
      this.actionContext.baseData,
      this.actionContext.range.start.p,
      ['equation'],
    );
    const closest = result?.data;

    if (closest?.properties?.equation) {
      const { data } = await new EditorService().convertEquation(closest?.properties?.equation);

      ReduxInterface.openAndUpdateModal({
        modal: 'EquationsModal',
        data: {
          isEdit: true,
          equation: {
            elementId: closest.id,
            tex: data,
            mathml: closest?.properties?.equation,
            blockId: this.actionContext.baseModel.id,
          },
        },
      });
    }
  }
}
