import { useMemo } from 'react';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

const NAME_MAX_CHARACTERS = 100;

const useMetadataNameValidation = (name: string) => {
  const { data } = useGetMetadataListQuery();
  return useMemo(() => {
    if (name.length > NAME_MAX_CHARACTERS) {
      return { id: 'validation.name.longName', values: { length: NAME_MAX_CHARACTERS } };
    }
    if (data) {
      const ids = Object.keys(data);
      if (ids.some((id) => data[id].name.toLowerCase() === name.toLowerCase())) {
        return { id: 'NAME_ALREADY_EXISTS' };
      }
    }
    return null;
  }, [data, name]);
};

export default useMetadataNameValidation;
