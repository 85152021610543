import { useState, useRef, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Input, InputField } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  useChangeEmailMutation,
  useCheckEmailStatusMutation,
  useResendNewEmailMutation,
} from 'Settings/pages/UserSettingsPage/UserSettingsApi';
import authApi from '_common/services/api/authority';

import styles from './ChangeEmailModal.module.scss';

const MODAL = 'ChangeEmailModal';

const ChangeEmailModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const userId = useSelector((state) => state.auth.userId);

  const [changeStep, setChangeStep] = useState(1);
  const [success, setSuccess] = useState(false);

  const [changeEmail, { data: emailChangeResponse, isSuccess: changeEmailSuccess }] =
    useChangeEmailMutation();
  const [checkEmailStatus, { data: emailStatus, isSuccess: checkEmailSuccess }] =
    useCheckEmailStatusMutation();
  const [resendNewEmail] = useResendNewEmailMutation();

  const [exitView, setExitView] = useState(false);
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [timeOutID, setTimeOutID] = useState(0);
  const [intervalID, setIntervalID] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    } else {
      //Clean up local states on close modal
      setChangeStep(1);
      setSuccess(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (changeEmailSuccess) {
      if (emailChangeResponse === '1' || !emailChangeResponse) {
        setChangeStep(2);
      } else if (emailChangeResponse === '2') {
        setChangeStep(3);
      }
    }
  }, [emailChangeResponse, changeEmailSuccess]);

  useEffect(() => {
    if (checkEmailSuccess) {
      if (emailStatus === '2') {
        setChangeStep(3);
      } else if (emailStatus === '3') {
        dispatch(
          authApi.util.invalidateTags([
            {
              type: 'User',
              id: 'Current',
            },
          ]),
        );
        setSuccess(true);
      }
    }
  }, [emailStatus, checkEmailSuccess]);

  const checkValidationEmail = useCallback(() => {
    checkEmailStatus();
  }, [email]);

  useEffect(() => {
    if (!disabled) {
      clearInterval(intervalID);
    }

    if (changeStep === 1 || changeStep === 3 || success) {
      clearInterval(intervalID);
      clearTimeout(timeOutID);
    }
    if ((changeStep === 2 || changeStep === 3) && !success) {
      handleDisableValidationButton();
      setIntervalID(window.setInterval(checkValidationEmail, 5000));
      setTimeOutID(window.setTimeout(handleShowValidationButton, 60000));
    }
  }, [disabled, changeStep, success, checkValidationEmail]);

  const handleChangeEmail = () => {
    changeEmail({ email, userId });
  };

  const resendEmailForNewEmail = () => {
    resendNewEmail();
  };

  const handleDisableValidationButton = () => {
    setDisabled(true);
  };

  const handleShowValidationButton = () => {
    setDisabled(false);
  };

  const handleCloseModal = () => {
    clearTimeout(timeOutID);
    clearInterval(intervalID);

    setExitView(false);
    setEmail('');
    setDisabled(true);
    setTimeOutID(0);
    setIntervalID(0);

    dispatch(closeAndResetModal(MODAL));
  };

  const toggle = () => {
    if (changeStep !== 1) {
      setExitView(true);
    } else {
      handleCloseModal();
    }
  };

  const handleBackButton = () => {
    setExitView(false);
  };

  const handleLinkChangeToAnotherEmail = () => {
    setChangeStep(1);
  };

  return (
    <Modal
      width="75rem"
      open={!!isOpen}
      onClose={toggle}
      type={exitView ? 'error' : 'information'}
      testId="change-email"
    >
      {changeStep === 1 && !exitView && !success && (
        <>
          <Modal.Header onClose={handleCloseModal}>
            <FormattedMessage id="CHANGE_EMAIL" />
          </Modal.Header>
          <Modal.Body>
            <div className={styles.instructions}>
              <FormattedMessage id="settings.email.firstStep" />
              <br />
              <br />
              <FormattedMessage id="settings.email.firstInstruction" />
            </div>

            <InputField
              label={intl.formatMessage({
                id: 'settings.email.newEmailAddress',
              })}
              size="large"
              testId="change-email-modal-new-email-field"
            >
              <Input
                size="large"
                placeholder={intl.formatMessage({
                  id: 'settings.email.newEmailAddress',
                })}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                ref={inputRef}
                onEnterKey={handleChangeEmail}
                testId="change-email-modal-new-email"
              />
            </InputField>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="medium"
              variant="primary"
              disabled={email === ''}
              onClick={handleChangeEmail}
              testId="change-email-modal-send-link-button"
            >
              <FormattedMessage id="settings.email.sendLink" />
            </Button>
          </Modal.Footer>
        </>
      )}
      {changeStep === 2 && !exitView && !success && (
        <>
          <Modal.Header>
            <FormattedMessage id="CHANGE_EMAIL" />
          </Modal.Header>
          <Modal.Body>
            <div className={styles.instructions}>
              <FormattedMessage id="settings.email.firstStep" />
              <br />
              <br />
              <FormattedMessage id="settings.email.firstInstruction" />
            </div>
            <div className={styles.blueBox}>
              <FormattedMessage id="settings.email.sendFeedback" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.forgotPassword} onClick={handleLinkChangeToAnotherEmail}>
              <FormattedMessage id="settings.email.anotherEmailLink" />
            </div>
            <Button
              size="medium"
              variant="primary"
              disabled={disabled}
              onClick={checkValidationEmail}
              testId="change-email-modal-check-validation-button"
            >
              <FormattedMessage id="settings.email.checkValidation" />
            </Button>
          </Modal.Footer>
        </>
      )}
      {changeStep === 3 && !exitView && !success && (
        <>
          <Modal.Header>
            <FormattedMessage id="CHANGE_EMAIL" />
          </Modal.Header>
          <Modal.Body>
            <div className={styles.instructions}>
              <FormattedMessage id="settings.email.secondStep" />
              <br />
              <br />
              <FormattedMessage id="settings.email.secondInstruction" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="medium"
              variant="link"
              margin="0 auto 0 0"
              onClick={resendEmailForNewEmail}
              testId="change-email-modal-resend-email-button"
            >
              <FormattedMessage id="settings.email.resendEmail" />
            </Button>
            <Button
              size="medium"
              variant="primary"
              disabled={disabled}
              onClick={checkValidationEmail}
              testId="change-email-modal-check-validation-button"
            >
              <FormattedMessage id="settings.email.checkValidation" />
            </Button>
          </Modal.Footer>
        </>
      )}

      {exitView && (
        <>
          <Modal.Header>
            <FormattedMessage id="CHANGE_EMAIL" />
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="CLOSING_THIS_MENU_WILL_REQUIRE_YOU_TO_RESTART_PROCESS_CONFIRM" />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="medium"
              onClick={handleBackButton}
              testId="change-email-modal-back-button"
            >
              <FormattedMessage id="settings.email.backButton" />
            </Button>
            <Button
              size="medium"
              variant="danger"
              onClick={handleCloseModal}
              testId="change-email-modal-close-button"
            >
              <FormattedMessage id="settings.email.closeButton" />
            </Button>
          </Modal.Footer>
        </>
      )}

      {success && (
        <>
          <Modal.Header onClose={handleCloseModal}>
            <FormattedMessage id="CHANGE_EMAIL" />
          </Modal.Header>
          <Modal.Body>
            <div className={styles.successInstructions}>
              <FormattedMessage id="settings.email.successInstruction" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="medium"
              onClick={handleCloseModal}
              testId="change-email-modal-close-button"
            >
              <FormattedMessage id="settings.email.close" />
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ChangeEmailModal;
