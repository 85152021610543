/* eslint-disable class-methods-use-this */
import { Mixin } from 'mixwith';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMNormalizer from 'Editor/services/DOMUtilities/DOMNormalizer/DOMNormalizer';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class InsertBlockHelper extends superclass {
      /**
       *
       * @param {ActionContext} actionContext
       * @param {*} content
       * @param {*} reference
       */
      appendContentAfterNode(actionContext, content, reference) {
        const newInsertElement = this.getTrackInsElement(
          this.getProperSuggestionRef(actionContext),
        );
        const newContent = this.prepareContentToAppend(content);
        newInsertElement.appendChild(newContent);

        if (reference) {
          if (reference.parentNode === EditorDOMUtils.getContentContainer()) {
            // copy task attribute
            if (
              reference.nextSibling &&
              reference.hasAttribute('task') &&
              reference.getAttribute('task') === reference.nextSibling.getAttribute('task')
            ) {
              newInsertElement.setAttribute('task', reference.getAttribute('task'));
            }
          }

          EditorDOMUtils.insertNodeAfter(reference.parentNode, newInsertElement, reference);
          EditorSelectionUtils.setCaret(newInsertElement, 'POST');
        }

        actionContext.addChangeUpdatedNode(newInsertElement);
        return newInsertElement;
      }

      _insertBlockNode(actionContext, node, reference, direction = 'AFTER') {
        let caretPosition;
        let nodeToSetCaret;
        if (node && reference) {
          let nodeToAppend;

          const closestRef = EditorDOMUtils.closest(reference, [
            ELEMENTS.TrackInsertElement.TAG,
            ELEMENTS.TrackDeleteElement.TAG,
          ]);
          const insideRef =
            closestRef &&
            EditorDOMUtils.parentContainsNode(closestRef, reference) &&
            this.isUserAuthor(closestRef);

          if (insideRef) {
            actionContext.addReferenceToRefresh(closestRef.getAttribute('element_reference'));
          }

          // prepare node to append
          if (insideRef) {
            nodeToAppend = node;
          } else if (node.tagName === ELEMENTS.ParagraphElement.TAG) {
            // remove BR if exist
            const elements = node.querySelectorAll('BR');
            Array.from(elements).forEach((br) => {
              br.parentNode.removeChild(br);
            });

            if (node.childNodes.length > 0) {
              // if paragraph insert suggestion inline
              const insertSuggestion = this.getTrackInsElement(
                this.getProperSuggestionRef(actionContext),
              );
              actionContext.addReferenceToRefresh(
                insertSuggestion.getAttribute('element_reference'),
              );

              while (node.firstChild) {
                insertSuggestion.appendChild(node.firstChild);
              }

              node.appendChild(insertSuggestion);
            }
            nodeToAppend = node;
          } else {
            // else insert tracked block node
            nodeToAppend = this.getTrackInsElement(this.getProperSuggestionRef(actionContext));
            nodeToAppend.appendChild(node);
          }

          // copy specific attributes
          if (reference.parentNode === EditorDOMUtils.getContentContainer()) {
            if (
              direction === 'AFTER' &&
              reference.nextSibling &&
              reference.hasAttribute('task') &&
              reference.getAttribute('task') === reference.nextSibling.getAttribute('task')
            ) {
              nodeToAppend.setAttribute('task', reference.getAttribute('task'));
            } else if (
              direction === 'BEFORE' &&
              reference.previousSibling &&
              reference.hasAttribute('task') &&
              reference.getAttribute('task') === reference.previousSibling.getAttribute('task')
            ) {
              nodeToAppend.setAttribute('task', reference.getAttribute('task'));
            }
          }

          // normalize node to append to get id
          DOMNormalizer.normalizeTree(nodeToAppend, reference.parentNode.id);

          // insert node and respective references if possible
          if (direction === 'AFTER') {
            // validations for table elements
            if (
              nodeToAppend.tagName === ELEMENTS.TableElement.TAG ||
              (nodeToAppend.tagName === ELEMENTS.TrackInsertElement.TAG &&
                nodeToAppend.firstChild.tagName === ELEMENTS.TableElement.TAG) ||
              (nodeToAppend.tagName === ELEMENTS.TrackDeleteElement.TAG &&
                nodeToAppend.firstChild.tagName === ELEMENTS.TableElement.TAG)
            ) {
              if (
                reference.tagName === ELEMENTS.TableElement.TAG ||
                (reference.tagName === ELEMENTS.TrackInsertElement.TAG &&
                  reference.firstChild.tagName === ELEMENTS.TableElement.TAG) ||
                (reference.tagName === ELEMENTS.TrackDeleteElement.TAG &&
                  reference.firstChild.tagName === ELEMENTS.TableElement.TAG)
              ) {
                const p = DOMElementFactory.createNewParagraphElement();
                this.insertBlockNodeAfterNode(actionContext, p, reference);
                reference = p;
              } else if (
                reference.nextSibling != null &&
                (reference.nextSibling.tagName === ELEMENTS.TableElement.TAG ||
                  (reference.nextSibling.tagName === ELEMENTS.TrackInsertElement.TAG &&
                    reference.nextSibling.firstChild.tagName === ELEMENTS.TableElement.TAG) ||
                  (reference.nextSibling.tagName === ELEMENTS.TrackDeleteElement.TAG &&
                    reference.nextSibling.firstChild.tagName === ELEMENTS.TableElement.TAG))
              ) {
                const p = DOMElementFactory.createNewParagraphElement();
                this.insertBlockNodeAfterNode(actionContext, p, reference);
              }
            }

            // check if is possible to append insertion reference
            if (
              EditorDOMUtils.isClosestTextElementEditable(reference) &&
              EditorDOMUtils.isClosestTextElementEditable(nodeToAppend)
            ) {
              // check if already has an inserted reference
              if (
                this.isAddParagraphMarker(reference.lastChild) ||
                this.isDeleteParagraphMarker(reference.lastChild)
              ) {
                if (nodeToAppend.tagName === ELEMENTS.ParagraphElement.TAG) {
                  nodeToAppend.appendChild(reference.lastChild);
                } else {
                  // TODO: not the best solution, temporary
                  reference.lastChild.remove();
                }
              }

              if (!insideRef) {
                this.insertAddParagraphMarker(actionContext, reference, nodeToAppend.id);
              }
            } else {
              // TODO: find a way show that this block was appended as a suggestion
            }

            // insert node after
            const closestContainer = EditorDOMUtils.closest(
              reference,
              EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
            );
            if (closestContainer) {
              EditorDOMUtils.insertNodeAfter(reference.parentNode, nodeToAppend, reference);
              actionContext.addChangeAddedNode(nodeToAppend);
            } else {
              const data = NodeDataBuilder.buildData({
                ...this.documentParser.parse(nodeToAppend),
                parent_id: reference.parentNode.id,
              });
              this.inserBlockNodeOperation(actionContext, data, reference, 'AFTER');
              nodeToAppend = EditorDOMUtils.getNode(data.id);
            }

            // check if before is a list
            // let elementId = reference.id;

            // if (DOMUtils.WRAPPER_LEVEL0_ELEMENTS.includes(reference.tagName)) {
            //   elementId = reference?.selectableContent?.id;
            // }

            // if (this.persistenceManager.isListElement(elementId)) {
            //   const listId = this.persistenceManager.getListIdFromBlock(elementId);
            //   const listLevel = this.persistenceManager.getListLevelFromBlock(elementId);
            //   this.persistenceManager.addBlocksToList(
            // actionContext,
            //     [nodeToAppend.id],
            //     listId,
            //     listLevel,
            //     elementId,
            //   );
            // }

            if (actionContext.caretPosition) {
              if (actionContext.caretPosition.nextSibling && nodeToAppend.nextSibling) {
                nodeToSetCaret = nodeToAppend.nextSibling;
              } else if (
                actionContext.caretPosition.previousSibling &&
                nodeToAppend.previousSibling
              ) {
                nodeToSetCaret = nodeToAppend.previousSibling;
              }
              caretPosition = actionContext.caretPosition.position;
            } else if (nodeToAppend.tagName === ELEMENTS.TableElement.TAG) {
              caretPosition = 'END';
            } else {
              caretPosition = 'INSIDE_START';
              nodeToSetCaret = nodeToAppend;
            }

            EditorSelectionUtils.setCaret(nodeToSetCaret, caretPosition);
            return nodeToAppend;
          }
          if (direction === 'BEFORE') {
            // validations for table elements
            if (
              nodeToAppend.tagName === ELEMENTS.TableElement.TAG ||
              (nodeToAppend.tagName === ELEMENTS.TrackInsertElement.TAG &&
                nodeToAppend.firstChild.tagName === ELEMENTS.TableElement.TAG) ||
              (nodeToAppend.tagName === ELEMENTS.TrackDeleteElement.TAG &&
                nodeToAppend.firstChild.tagName === ELEMENTS.TableElement.TAG)
            ) {
              if (
                reference.tagName === ELEMENTS.TableElement.TAG ||
                (reference.tagName === ELEMENTS.TrackInsertElement.TAG &&
                  reference.firstChild.tagName === ELEMENTS.TableElement.TAG) ||
                (reference.tagName === ELEMENTS.TrackDeleteElement.TAG &&
                  reference.firstChild.tagName === ELEMENTS.TableElement.TAG)
              ) {
                const p = DOMElementFactory.createNewParagraphElement();
                this.insertBlockNodeBeforeNode(actionContext, p, reference);
                reference = p;
              } else if (
                reference.previousSibling != null &&
                (reference.previousSibling.tagName === ELEMENTS.TableElement.TAG ||
                  (reference.previousSibling.tagName === ELEMENTS.TrackInsertElement.TAG &&
                    reference.previousSibling.firstChild.tagName === ELEMENTS.TableElement.TAG) ||
                  (reference.previousSibling.tagName === ELEMENTS.TrackDeleteElement.TAG &&
                    reference.previousSibling.firstChild.tagName === ELEMENTS.TableElement.TAG))
              ) {
                const p = DOMElementFactory.createNewParagraphElement();
                this.insertBlockNodeBeforeNode(actionContext, p, reference);
              }
            }

            // check if is possible to append insertion reference
            if (reference.previousSibling) {
              if (
                EditorDOMUtils.isClosestTextElementEditable(reference.previousSibling) &&
                EditorDOMUtils.isClosestTextElementEditable(nodeToAppend)
              ) {
                // check if already has an inserted reference
                if (
                  this.isAddParagraphMarker(reference.previousSibling.lastChild) ||
                  this.isDeleteParagraphMarker(reference.previousSibling.lastChild)
                ) {
                  if (nodeToAppend.tagName === ELEMENTS.ParagraphElement.TAG) {
                    nodeToAppend.appendChild(reference.previousSibling.lastChild);
                  } else {
                    // TODO: not the best solution, temporary
                    reference.previousSibling.lastChild.remove();
                  }
                }

                if (!insideRef) {
                  this.insertAddParagraphMarker(
                    actionContext,
                    reference.previousSibling,
                    nodeToAppend.id,
                  );
                }
              } else {
                // TODO: find a way show that this block was appended as a suggestion
              }
            }

            // insert node before
            const closestContainer = EditorDOMUtils.closest(
              reference,
              EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
            );
            if (closestContainer) {
              if (reference.parentNode !== closestContainer) {
                reference = EditorDOMUtils.findFirstLevelChildNode(closestContainer, reference);
              }
              EditorDOMUtils.insertNodeBefore(closestContainer, nodeToAppend, reference);
              actionContext.addChangeAddedNode(nodeToAppend);
            } else {
              const pageNode = EditorDOMUtils.getContentContainer(reference);
              if (reference.parentNode !== pageNode) {
                reference = EditorDOMUtils.findFirstLevelChildNode(pageNode, reference);
              }
              const data = NodeDataBuilder.buildData({
                ...this.documentParser.parse(nodeToAppend),
                parent_id: reference.parentNode.id,
              });
              this.inserBlockNodeOperation(actionContext, data, reference, 'BEFORE');
              nodeToAppend = EditorDOMUtils.getNode(data.id);
            }

            // check if before is a list
            // let elementId = reference.id;

            // if (DOMUtils.WRAPPER_LEVEL0_ELEMENTS.includes(reference.tagName)) {
            //   elementId = reference?.selectableContent?.id;
            // }

            // if (this.persistenceManager.isListElement(elementId)) {
            //   const listId = this.persistenceManager.getListIdFromBlock(elementId);
            //   const listLevel = this.persistenceManager.getListLevelFromBlock(elementId);
            //   this.persistenceManager.addBlocksToList(
            // actionContext,
            //     [nodeToAppend.id],
            //     listId,
            //     listLevel,
            //     elementId,
            //   );
            // }

            if (actionContext.caretPosition) {
              if (actionContext.caretPosition.nextSibling && nodeToAppend.nextSibling) {
                nodeToSetCaret = nodeToAppend.nextSibling;
              } else if (
                actionContext.caretPosition.previousSibling &&
                nodeToAppend.previousSibling
              ) {
                nodeToSetCaret = nodeToAppend.previousSibling;
              }
              caretPosition = actionContext.caretPosition.position;
            } else if (nodeToAppend.tagName === ELEMENTS.TableElement.TAG) {
              caretPosition = 'END';
            } else {
              caretPosition = 'INSIDE_END';
              nodeToSetCaret = nodeToAppend;
            }

            EditorSelectionUtils.setCaret(nodeToSetCaret, caretPosition);
            return nodeToAppend;
          }
        }
        return null;
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} node
       * @param {*} reference
       */
      insertBlockNodeAfterNode(actionContext, node, reference) {
        return this._insertBlockNode(actionContext, node, reference, 'AFTER');
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} node
       * @param {*} reference
       */
      insertBlockNodeBeforeNode(actionContext, node, reference) {
        return this._insertBlockNode(actionContext, node, reference, 'BEFORE');
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} mergeId
       */
      getMergePoint(actionContext, mergeId, target) {
        const mergePoint = this.getTrackInsElement(actionContext.id);
        mergePoint.setAttribute('merge', mergeId);
        if (target) {
          mergePoint.setAttribute('target', target);
        }
        mergePoint.setAttribute('contenteditable', false);
        return mergePoint;
      }

      splitList(bound) {
        const range = EditorSelectionUtils.getRange();
        const savedMarkers = range.saveRange();
        const marker = EditorDOMUtils.getNode(range.markerId, this.page);
        let result = null;
        let temp = null;
        let grandparent = null;
        for (let parent = marker.parentNode; bound !== parent; parent = grandparent) {
          temp = parent.cloneNode(false);
          EditorDOMUtils.cleanAttributes(temp, ['id', 'parent_id']);
          while (marker.nextSibling) {
            EditorDOMUtils.cleanAttributes(marker.nextSibling, ['id', 'parent_id']);
            temp.appendChild(marker.nextSibling);
          }
          grandparent = parent.parentNode;
          if (result) {
            if (temp.firstChild) {
              temp.insertBefore(result, temp.firstChild);
            } else {
              temp.appendChild(result);
            }
          }
          result = temp;
          grandparent.insertBefore(marker, parent.nextSibling);
        }
        range.restoreRange(savedMarkers);
        EditorSelectionUtils.applyRangeToSelection(range);
        return result;
      }
    },
);
