import { ChangeEventHandler, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Input, InputField } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks/redux';

import { savePersonalFilter } from 'Search/redux/SearchPageSlice';
import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

const MODAL = 'SaveFiltersModal';

const SaveFiltersModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { savingFilters } = useModalData('SaveFiltersModal') ?? {};

  const [filterSetName, setFilterSetName] = useState('');
  const [filterSetNameFeedback, setFilterSetNameFeedback] = useState<string>();

  const handleClose = () => {
    setFilterSetName('');
    setFilterSetNameFeedback(undefined);

    closeModal(MODAL);
  };

  const handleFilterSetNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newName = e.target.value;

    if (newName.length > 150) {
      setFilterSetNameFeedback(
        intl.formatMessage({ id: 'validation.name.longName' }, { length: 150 }),
      );
    } else {
      setFilterSetNameFeedback(undefined);
    }

    setFilterSetName(e.target.value);
  };

  const handleSaveFilterSet = () => {
    if (!savingFilters) {
      return;
    }

    if (Array.isArray(savingFilters)) {
      const parsedFilters: SearchCondition[] = [];

      savingFilters.forEach((filter) => {
        const name =
          filter.option === 'mime_type' && filter.auxValue && filter.value !== 'image'
            ? filter.auxValue
            : filter.option;
        parsedFilters.push({
          name,
          operator: filter.operator ?? 'eq', //Placeholder in order for API to accept
          value: filter.value ?? '',
          metadataField: filter.metadataOption,
          endDate: filter.auxValue,
        });
      });

      dispatch(savePersonalFilter({ name: filterSetName, filters: parsedFilters }));
    } else {
      dispatch(savePersonalFilter({ name: filterSetName, filters: savingFilters }));
    }

    handleClose();
  };

  return (
    <Modal open width="75rem" onClose={handleClose} testId="advanced-search-save-filters-modal">
      <Modal.Header onClose={handleClose}>
        <FormattedMessage id="SAVE_COMBINATION_FILTERS" />
      </Modal.Header>

      <Modal.Body>
        <InputField
          label={`${intl.formatMessage({ id: 'SAVE_AS' })}:`}
          size="large"
          feedback={filterSetNameFeedback}
          testId="advanced-search-save-filters-modal-input"
        >
          <Input
            value={filterSetName}
            onChange={handleFilterSetNameChange}
            size="large"
            placeholder={intl.formatMessage({ id: 'NAME_COMBINATION_FILTERS' })}
            error={!!filterSetNameFeedback}
            testId="advanced-search-save-filters-modal"
          />
        </InputField>
      </Modal.Body>

      <Modal.Footer>
        <Button
          size="medium"
          onClick={handleClose}
          testId="advanced-search-save-filters-modal-cancel"
        >
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSaveFilterSet}
          disabled={!!filterSetNameFeedback || !filterSetName}
          testId="advanced-search-save-filters-modal-confirm"
        >
          <FormattedMessage id="SAVE_FILTERS" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveFiltersModal;
