import { ReactNode } from 'react';
import { Button } from 'dodoc-design-system';

import styles from './Title.module.scss';

type BackToButtonProps = {
  children: ReactNode;
  onClick: () => void;
  testId: string;
};

const BackToButton = ({ children, testId, onClick }: BackToButtonProps) => {
  return (
    <div className={styles.container}>
      <Button size="medium" variant="primary" onClick={onClick} testId={`back-to-${testId}-button`}>
        {children}
      </Button>
    </div>
  );
};

export default BackToButton;
