import { dayjs } from 'utils';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

const useDayjsWithLocale = () => {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (currentUser?.profile.language) {
    dayjs.locale(currentUser.profile.language);
  }

  return dayjs;
};

export default useDayjsWithLocale;
