import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from '_common/hooks';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './Language.module.scss';

const Language = () => {
  const dispatch = useDispatch();
  const document = useSelector(getDocumentObject);

  const defaultLanguage = useSelector((state) => state.editor.language.defaultLanguage);
  const languages = useSelector((state) => state.editor.language.languages);
  const [language, setLanguage] = useState<(typeof languages)[number]>();
  const nodeLanguages = useSelector((state) => state.editor.status.selection.LANGUAGES);
  const languageCode = document?.lang ? document.lang.code : defaultLanguage;

  useEffect(() => {
    const nodeIdsLanguages = nodeLanguages && Object.keys(nodeLanguages);
    if (nodeIdsLanguages && nodeIdsLanguages.length) {
      const allLanguages = Object.entries(nodeLanguages).map(([_, value]) => value);
      if (nodeIdsLanguages.length > 1) {
        if (languages && allLanguages.every((language) => language === allLanguages[0])) {
          setLanguage(languages.find(({ value }) => value === allLanguages[0]));
        } else {
          setLanguage(languages.find(({ value }) => value === languageCode));
        }
      } else {
        setLanguage(languages.find(({ value }) => value === allLanguages[0]));
      }
    } else {
      setLanguage(languages.find(({ value }) => value === languageCode));
    }
  }, [nodeLanguages, defaultLanguage, languages]);

  const handleOpenModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'SetLanguageModal',
        data: { onSelection: nodeLanguages && Object.keys(nodeLanguages).length ? true : false },
      }),
    );
  };

  return (
    <div
      className={styles.root}
      data-testid={`${language?.value}-language-value`}
      onClick={handleOpenModal}
    >
      {language?.label}
    </div>
  );
};

export default Language;
