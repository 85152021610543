import { faker } from '@faker-js/faker';
import { paths } from '_types/authority';
import { createMockedUserId, createMockedObjectId } from './common';
import UIPackageJson from '_common/../../package.json';

export const createMockedToken = (overwrites?: Partial<doDOC.Token>): doDOC.Token => {
  return {
    expires: faker.date.soon(2).toISOString(),
    id: createMockedUserId(),
    username: faker.internet.userName(),
    extra: {},
    third_party: false,
    ...overwrites,
  };
};

export const createMockedAuthSetup = (
  overwrites?: Partial<
    paths['/api/authority/setup']['get']['responses']['200']['content']['application/json']
  >,
): paths['/api/authority/setup']['get']['responses']['200']['content']['application/json'] => {
  return {
    allow_login_with_password: true,
    login_max_attempts: 3,
    authority_version: 'develop',
    dodoc_version: process.env.REACT_APP_UI_VERSION || UIPackageJson.version,
    custom_message: faker.random.words(5),
    ...overwrites,
  };
};

export const createMockedThirdParty = (): ThirdParty => {
  return {
    label: 'Google', //Type of ThirdPartyIcon in order to display third party image
    url: faker.internet.url(),
    name: faker.random.word(),
    style: {},
    image: {
      type: 'url',
    },
  };
};

const createTenantUser = (
  overwrites?: Partial<UserPublicProfileExtended>,
): UserPublicProfileExtended => {
  const firstName = overwrites?.first_name || faker.name.firstName();
  const lastName = overwrites?.last_name || faker.name.lastName();
  const email = overwrites?.email || faker.internet.email(firstName, lastName);
  return {
    id: createMockedUserId(),
    username: faker.internet.userName(firstName, lastName),
    first_name: firstName,
    last_name: lastName,
    email,
    date_joined: faker.date.recent().toISOString(),
    is_admin: faker.datatype.boolean(),
    is_superuser: faker.datatype.boolean(),
    is_active: faker.datatype.boolean(),
    has_avatar: false,
    ...overwrites,
  };
};

const createUserToken = (
  overwrites?: Omit<Partial<TokenObject>, 'expires'> & { expires?: Date },
): TokenObject => {
  const expires = overwrites?.expires || faker.date.recent();

  return {
    pk: createMockedObjectId(),
    browser: faker.internet.userAgent(),
    is_expired: expires < new Date(),
    ...overwrites,
    expires: expires.toISOString(),
  };
};

export const createMockedTenantUsers = ({
  quantity,
  allInactive = false,
  noneAdmin = false,
}: {
  quantity: number;
  allInactive?: boolean;
  noneAdmin?: boolean;
}): UserPublicProfileExtended[] => {
  const users: UserPublicProfileExtended[] = [];

  for (let i = 0; i < quantity; i++) {
    const id = `${i}`;
    //always generate an admin user (if noneAdmin = false)
    if (i === 0) {
      users.push(
        createTenantUser({
          id,
          is_superuser: !noneAdmin,
          is_admin: !noneAdmin,
          is_active: !allInactive,
        }),
      );
      //always generate a regular user (if quantity > 1)
    } else if (i === quantity - 1) {
      users.push(
        createTenantUser({ id, is_superuser: false, is_admin: false, is_active: !allInactive }),
      );
    } else {
      users.push(
        createTenantUser({
          id,
          is_active: !allInactive,
          ...{ ...(noneAdmin ? { is_admin: false, is_superuser: false } : {}) },
        }),
      );
    }
  }
  return users;
};

export const createMockedUserTokenHistory = (quantity: number): TokenObject[] => {
  const users: TokenObject[] = [];

  for (let i = 0; i < quantity; i++) {
    const pk = `${i}`;
    //always generate a token that hasnt beeen expired
    if (i === 0) {
      users.push(createUserToken({ pk, expires: faker.date.soon() }));
      //create a token with an unknown browser
    } else if (i === 1) {
      users.push(createUserToken({ pk, browser: undefined }));
    } else {
      users.push(createUserToken({ pk }));
    }
  }
  return users;
};

export function createMockedConnection(): ConnectionLink {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  const uuid = faker.internet.email(firstName, lastName);
  return {
    pk: createMockedObjectId(),
    uuid: uuid,
    provider: 1,
    creation: faker.date.recent().toISOString(),
  };
}

export function createMockedConnectionList(): ConnectionLink[] {
  return [createMockedConnection()];
}

export function createMockedSession(overwrites?: Partial<Session>): Session {
  return {
    token: `${faker.random.alphaNumeric(24)}`,
    expires: faker.date.soon(2).toISOString(),
    third_party: false,
    last_user_agent: faker.internet.domainName(),
    last_ip_address: faker.internet.ip(),
    ...overwrites,
  };
}

export function createMockedSessionList(): Session[] {
  return [
    createMockedSession(),
    createMockedSession({
      last_user_agent: undefined,
    }),
  ];
}

export function createMockedDevice(overwrites?: Partial<Device>): Device {
  return {
    token: `${faker.random.alphaNumeric(24)}`,
    name: faker.lorem.text(),
    verified: true,
    creation: faker.date.recent().toISOString(),
    last_user_agent: faker.internet.domainName(),
    last_ip_address: faker.internet.ip(),
    ...overwrites,
  };
}

export function createMockedDeviceList(): Device[] {
  return [
    createMockedDevice(),
    createMockedDevice({ name: 'AUTO_VERIFIED', last_user_agent: undefined }),
  ];
}
