import { ReactNode, CSSProperties } from 'react';
import * as Sentry from '@sentry/react';
import { Icon } from 'dodoc-design-system';

import styles from './RowErrorBoundary.module.scss';

type RowErrorBoundaryProps = {
  children: ReactNode;
  rowProps: MyAny;
  style?: CSSProperties;
};

const RowErrorBoundary = ({ children, style, rowProps }: RowErrorBoundaryProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <div style={style} className={styles.root}>
          <div className={styles.icon}>
            <Icon icon="Warning" size={32} />
          </div>
          Failed to retrieve information
        </div>
      )}
      beforeCapture={(scope) => {
        scope.setTag('logger', 'RowErrorBoundary');
        scope.setExtra('rowProps', rowProps);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default RowErrorBoundary;
