// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import pdfjs from 'PDF/services/PDFJS';
import { Logger } from '_common/services';
import { Transport } from '_common/services/Realtime/Transport';
import {
  AnnotationsController,
  DocumentController,
  EventsController,
  FindController,
  ModelsController,
  UsersController,
  VersionsController,
  StructureController,
  HistoryController,
} from './controllers';
import * as Util from './Util';

export function DataManager(transport: Transport): PDF.Data.API {
  const Data: PDF.Data.State = {
    transport: transport,
    context: {
      version: null,
      pdfjs,
    },
  };

  let ready = false;
  let documentId: string;

  const events: EventsController = new EventsController();
  const users: UsersController = new UsersController(Data);
  const models: ModelsController = new ModelsController(Data);
  const structureController: StructureController = new StructureController(Data);
  const docController: DocumentController = new DocumentController(Data);
  const annotations: AnnotationsController = new AnnotationsController(Data);
  const find: FindController = new FindController(Data);
  const versions: VersionsController = new VersionsController(Data);
  const history: HistoryController = new HistoryController(Data);

  const _transportEvents: any = {
    // 'FORCE:REMOTE:RELOAD': _handleEventForceRemoteReload,
  };

  // function _handleEventForceRemoteReload(event: { users: string[] }) {
  //   try {
  //     if (event.users.includes(`${users.loggedUserId}`)) {
  //       events.emit('FORCE_REMOTE_RELOAD');
  //     }
  //   } catch (error) {
  //     Logger.captureException(error);
  //   }
  // }

  async function start(docId: string, u: any) {
    documentId = docId;
    Data.context.documentId = docId;
    Logger.debug('DATA MANAGER START');

    const eventKeys = Object.keys(_transportEvents);
    for (let i = 0; i < eventKeys.length; i++) {
      const event: Realtime.Transport.ServerEventName = eventKeys[
        i
      ] as Realtime.Transport.ServerEventName;
      Data.transport.handleEvent(event, _transportEvents[event]);
    }

    await models.start();
    await docController.start(documentId);
    await structureController.start(documentId);
    await users.start(documentId, docController.doc?.get() as Realtime.Core.Document.Data, u);
    await annotations.start(documentId);
    await find.start(documentId);
    await versions.start(documentId);
    await history.start(documentId);

    ready = true;
  }

  function stop() {
    ready = false;
    Logger.debug('DATA MANAGER NOT READY');

    // destroy controllers
    events.destroy();
    annotations.destroy();
    docController.destroy();
    structureController.destroy();
    users.destroy();
    models.destroy();
    find.destroy();
    versions.destroy();
    history.destroy();
  }

  function destroy() {
    stop();
  }

  const API: PDF.Data.API = {
    isReady: () => ready,
    start,
    stop,
    destroy,
    on: events.on.bind(events),
    off: events.off.bind(events),
    events,
    documentController: docController,
    structureController: structureController,
    models,
    annotations,
    versions,
    history,
    /* develblock:start */
    users: users,
    find,
    /* develblock:end */
  };

  Util.extend(Data, {
    ...API,
    emit: events.emit.bind(events),
    documentController: docController,
    models,
    annotations,
    users,
    find,
    versions,
  });

  return API;
}
