import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SpellcheckSliceState = {
  /** TODO: Both triggers will force some spellcheck logic
   * Said logic can be moved to a spellcheck manager and affected states to spellcheck slice
   */
  languageTrigger: boolean;
  changedAllTrigger: boolean;
  wordHasChanged: boolean;
};

const SLICE_NAME = 'SPELLCHECK';
const INITIAL_STATE: SpellcheckSliceState = {
  languageTrigger: false,
  changedAllTrigger: false,
  wordHasChanged: false,
};

// #region Slice
const SpellcheckSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    toggleLanguageTrigger: (state) => {
      state.languageTrigger = !state.languageTrigger;
    },
    toggleChangedAllTrigger: (state) => {
      state.changedAllTrigger = !state.changedAllTrigger;
    },
    setWordHasChanged: (state, action: PayloadAction<SpellcheckSliceState['wordHasChanged']>) => {
      state.wordHasChanged = action.payload;
    },
  },
});

export default SpellcheckSlice.reducer;
// #endregion

// #region Actions
export const { toggleLanguageTrigger, toggleChangedAllTrigger, setWordHasChanged } =
  SpellcheckSlice.actions;
// #endregion
