import { forwardRef, Ref, useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from '_common/hooks';

import ViewTaskCard from './ViewTaskCard';
import EditableTaskCard from './EditableTaskCard';

export type TaskCardProps = {
  sidebar?: boolean;
  testId: string;
} & (
  | { mode: 'create'; task?: never }
  | { mode: 'edit'; task: Presentation.Data.Task }
  | { mode?: never; task: Presentation.Data.Task }
);

const TaskCard = forwardRef(
  ({ mode, task, ...commonProps }: TaskCardProps, ref: Ref<HTMLDivElement>) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const selectedTask = useSelector((state) => state.presentation.general.selectedCard);

    const [isEditing, setIsEditing] = useState(false);

    const isSelected = useMemo(() => {
      return selectedTask === task?.id;
    }, [selectedTask, task]);

    useEffect(() => {
      if (commonProps.sidebar && isSelected && cardRef.current) {
        cardRef.current.scrollIntoView({ block: 'center' });
      }
    }, [commonProps]);

    if (mode === 'create') {
      return <EditableTaskCard mode="create" {...commonProps} ref={cardRef} />;
    } else if ((mode === 'edit' || isEditing) && task) {
      return (
        <EditableTaskCard
          mode="edit"
          task={task}
          setEditMode={setIsEditing}
          {...commonProps}
          ref={cardRef}
        />
      );
    } else if (task) {
      return <ViewTaskCard task={task} setEditMode={setIsEditing} {...commonProps} ref={cardRef} />;
    }

    return null;
  },
);

export default TaskCard;
