import { ModalData } from 'App/ModalContext/ModalContextTypes';
import { openModal } from 'App/ModalContext/utils';
import EditorManager from 'Editor/services/EditorManager';
import { useSuiteObject } from '_common/suite';

const useAuditLogModal = () => {
  const object = useSuiteObject();

  const navigateToObject: ModalData['DocumentAuditLogModal']['navigateToObject'] = ({
    objectType,
    objectId,
  }) => {
    switch (objectType) {
      case 'comment':
        EditorManager.getInstance().focusComment(objectId);
        break;
      case 'suggestion':
        EditorManager.getInstance().focusTrackedAction(objectId);
        break;
      case 'task':
        EditorManager.getInstance().focusTask(objectId);
        break;
      case 'node':
        EditorManager.getInstance().scrollIntoView(objectId);
        break;
    }
  };

  const openAuditLogModal = () => {
    openModal({
      modal: 'DocumentAuditLogModal',
      data: { objectId: object.id, objectType: object.type, navigateToObject },
    });
  };

  return { openAuditLogModal };
};

export default useAuditLogModal;
