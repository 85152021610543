import { FormattedMessage } from 'react-intl';
import { Modal, ProgressBar, Icon } from 'dodoc-design-system';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import styles from './UploadProgressModal.module.scss';
import { useDispatch, useSelector } from '_common/hooks';

const MODAL = 'UploadProgressModal';

const UploadProgressModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const title = useSelector((state) => state.modals[MODAL].title);
  const name = useSelector((state) => state.modals[MODAL].name);
  const percentage = useSelector((state) => state.modals[MODAL].percentage);
  const size = useSelector((state) => state.modals[MODAL].size);

  const getSizeText = () => {
    let sizeValue = size;
    let count = 0;
    let textUnity = '';

    while (sizeValue > 1000) {
      sizeValue /= 1024;
      count += 1;
    }

    sizeValue = sizeValue.toFixed(2);

    switch (count) {
      case 0:
        textUnity = 'b';
        break;
      case 1:
        textUnity = 'kb';
        break;
      case 2:
        textUnity = 'mb';
        break;
      case 3:
        textUnity = 'gb';
        break;
      default:
        break;
    }

    return `(${sizeValue}${textUnity})`;
  };

  const toggle = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} onClose={toggle} width="75rem" testId="upload-progress">
      <Modal.Header onClose={toggle}>
        {title || (
          <FormattedMessage id="storage.modals.uploadProgress.uploadingName" values={{ name }} />
        )}
      </Modal.Header>
      <Modal.Body>
        <div className={title ? styles.root1 : styles.root}>
          <Icon icon="Document" size={96} />

          <div className={styles.nameContainer}>
            <b>{name}</b>
            <span className={styles.greyText}>{title ? undefined : getSizeText()}</span>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1.5rem',
            }}
          >
            <ProgressBar progress={percentage} testId="upload-progress-progressBar" />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer> </Modal.Footer>
      {/* Footer requires a children, maybe it shouldn't */}
    </Modal>
  );
};

export default UploadProgressModal;
