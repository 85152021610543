/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Transport } from '../../Transport';
import { BaseTypedEmitter } from '../../BaseTypedEmitter';
import { Presence } from 'sharedb/lib/client';

interface BasePresenceEvents<T> {
  LOADED: (data: any) => void;
  UPDATED: (source: Realtime.Core.RealtimeSourceType, id: string, data: T | null) => void;
}

export abstract class PresenceChannel<T extends any> extends BaseTypedEmitter<
  BasePresenceEvents<T>
> {
  data: Record<string, T> = {};
  protected presence: Presence;
  protected loadedVersion: Realtime.Core.RealtimeVersion | null = null;
  transport: Transport;
  modelType?: string;
  protected pendingEvents: any = {};

  static TYPE_NAME() {
    throw new Error('Not Implemented!');
  }

  static TYPE_COLLECTION() {
    throw new Error('Not Implemented!');
  }

  constructor(transport: Transport, id: string) {
    super();
    this.transport = transport;
    this.presence = this.transport.getPresence(id);
    this.handleChannelUpdate = this.handleChannelUpdate.bind(this);
    this.presence.on('receive', this.handleChannelUpdate);
  }

  fetch() {
    //
  }

  subscribe(): Promise<PresenceChannel<T>> {
    return new Promise((resolve, reject) => {
      this.presence.subscribe((error) => {
        if (error) {
          reject(error);
        } else {
          this.setInitialData();
          this.emit('LOADED', this.data);
          resolve(this);
        }
      });
    });
  }

  submit(id: string, data: T, options?: Realtime.Core.RealtimeSourceOptions) {
    if (!this.presence.localPresences[id]) {
      this.presence.create(id);
    }
    this.presence.localPresences[id].submit(data);
    this.data[id] = data;
    this.emit('UPDATED', options?.source || true, id, data);
  }

  private setInitialData() {
    let data: Record<string, T> = {};
    let remoteKeys = Object.keys(this.presence.remotePresences);
    for (let index = 0; index < remoteKeys.length; index++) {
      const remoteKey = remoteKeys[index];
      data[remoteKey] = this.presence.remotePresences[remoteKey].value;
    }
    let localKeys = Object.keys(this.presence.localPresences);
    for (let index = 0; index < localKeys.length; index++) {
      const localKey = localKeys[index];
      data[localKey] = this.presence.localPresences[localKey].value as T;
    }
    this.data = data;
  }

  getLocalData(): Record<string, T> {
    let data: Record<string, T> = {};
    let localKeys = Object.keys(this.presence.localPresences);
    for (let index = 0; index < localKeys.length; index++) {
      const localKey = localKeys[index];
      data[localKey] = this.presence.localPresences[localKey].value as T;
    }
    return data;
  }

  private handleChannelUpdate(presenceId: string, update: T) {
    if (update === null) {
      delete this.data[presenceId];
      // The remote client is no longer present in the document
    } else {
      this.data[presenceId] = update;
      // Handle the new value by updating UI, etc.
    }
    this.emit('UPDATED', false, presenceId, update);
  }

  dispose(): void {
    /* if (this.model) {
      this.model.destroy();
    }
 */
    super.destroy();
  }
}
