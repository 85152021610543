import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

type SliceState = {
  personals: boolean;
  infoPanelOpen: boolean;
};

const SLICE_NAME = 'spaces';

// #region State

export const INITIAL_STATE: SliceState = {
  personals: false,
  infoPanelOpen: false,
};

// #endregion

// #region Slice

const spacesPageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    viewPersonalSpaces: (state, action: PayloadAction<boolean>) => {
      state.personals = action.payload;
    },
    toggleInfoPanel: (state) => {
      state.infoPanelOpen = !state.infoPanelOpen;
    },
    setInfoPanelOpenValue: (state, action: PayloadAction<SliceState['infoPanelOpen']>) => {
      state.infoPanelOpen = !!action.payload;
    },
  },
});

// Actions

export const { viewPersonalSpaces, toggleInfoPanel, setInfoPanelOpenValue } =
  spacesPageSlice.actions;

// Persistence
const persistConfig = {
  key: 'spaces',
  storage: localStorage,
  whitelist: ['infoPanelOpen'],
};

const reducer = persistReducer(persistConfig, spacesPageSlice.reducer);

export default reducer;

// #endregion
