import { InteractionController, UserPresentation } from '_common/components';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import { useDispatch, useSelector } from '_common/hooks';
import { useSuitePermissions } from '_common/suite';
import { notify } from '_common/components/ToastSystem';
import {
  LikeToggle,
  MinifiedCard,
  Options,
  Priority,
  ReplyList,
  ReplyToggle,
  ResolveButton,
  ViewRichTextEditor,
} from '_common/suite/components/Card';
import { Card } from '_common/suite/components';
import { useRef, useState, MouseEvent, useMemo, forwardRef, Ref, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { Label, Divider } from 'dodoc-design-system';
import { CardHeaderProps } from 'dodoc-design-system/build/types/Components/Card/CardHeader/CardHeader';
import { LikeToggleProps } from '_common/suite/components/Card/LikeToggle/LikeToggle';
import { containsMention, stringToRichText } from 'utils';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { useCommentsOrder, useFocusCard, usePresentationCollaborators } from 'Presentation/hooks';
import { setSelectedCard } from 'Presentation/PresentationSlice';
import { openModal } from 'App/ModalContext/utils';

export type ViewCommentCardProps = {
  id?: string;
  comment: Presentation.Data.Comment;
  sidebar?: boolean;
  testId: string;
  setEditMode: (isEdit: boolean) => void;
};

const STATUS = {
  resolved: { labelId: 'editor.sidebar.review.filter.status.resolved', color: 'green' },
  deleted: { labelId: 'editor.sidebar.review.filter.status.deleted', color: 'red' },
} as const;

const ViewCommentCard = forwardRef(
  (
    { id, comment, sidebar, testId, setEditMode }: ViewCommentCardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const manager = usePresentationManager();
    const {
      canComment,
      canDeleteComment,
      canEditComment,
      canEditCommentReply,
      canDeleteCommentReply,
      canResolveComment,
      canChangeCommentPriority,
    } = useSuitePermissions();
    const { focusCard } = useFocusCard();
    const commentsOrder = useCommentsOrder();

    const selected = useSelector((state) => state.presentation.general.selectedCard);
    const isListMode = useSelector((state) => state.presentation.comments.isListMode);
    const editorRef = useRef<RichTextEditorHandler>(null);

    const collaborators = usePresentationCollaborators();
    const [showReplies, setShowReplies] = useState(false);
    const canCopyComment = true;

    useEffect(() => {
      dispatch(setPulseData({ commentRepliesToggled: showReplies }));
    }, [showReplies]);

    const priority = useMemo(() => {
      switch (comment.priority) {
        case 'HIGH': {
          return 'High';
        }
        case 'MEDIUM': {
          return 'Medium';
        }
        case 'LOW': {
          return 'Low';
        }
        default: {
          return 'Medium';
        }
      }
    }, [comment]);

    const cardState = useMemo<keyof typeof STATUS | undefined>(() => {
      switch (comment.status) {
        case 'REJECTED':
          return 'deleted';
        case 'ACCEPTED':
          return 'resolved';
        default:
          return undefined;
      }
    }, [comment]);

    const cardHeaderColor = useMemo<CardHeaderProps['background']>(() => {
      switch (comment.status) {
        case 'REJECTED':
          return 'red';
        case 'ACCEPTED':
          return 'green';
        default:
          return undefined;
      }
    }, [comment]);

    const isSelected = useMemo(() => {
      return comment.id === selected;
    }, [selected, comment]);

    const order = useMemo(() => {
      return commentsOrder[comment.id];
    }, [comment, commentsOrder]);

    const handleSelectComment = () => {
      focusCard({ objectId: comment.id });
    };

    const handleToggleReplies = () => {
      setShowReplies(!showReplies);
      if (!showReplies === true) {
        dispatch(completeAction('presentation_comments_openReplies'));
      }
    };

    const handleEditComment = () => {
      if (selected !== comment.id) {
        dispatch(setSelectedCard(null));
      }
      setEditMode(true);
    };

    const handleDeleteComment = () => {
      openModal({
        modal: 'ConfirmationModal',
        data: {
          headerType: 'error',
          title: 'DELETE_COMMENT',
          message: 'DELETING_THIS_COMMENT_WILL_PERMANENTLY_REMOVE_FROM_PRESENTATION_CONFIRM',
          confirmButtonTextId: 'DELETE_COMMENT',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          modalWidth: '60rem',
          onConfirm: () => {
            manager.deleteComment(comment);
            notify({
              type: 'success',
              title: intl.formatMessage({ id: 'COMMENT_DELETED' }),
              message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_DELETED' }),
            });
          },
        },
      });
    };

    const handleResolveComment = async () => {
      try {
        await manager.resolveComment(comment.anchor[0].id, comment.id);
        notify({
          type: 'success',
          title: 'notifications.commentResolve.messageSuccess',
          message: 'THE_COMMENT_WAS_SUCCESSFULLY_RESOLVED',
        });
      } catch (error) {
        throw error;
      }
    };

    const handleCopyText = async (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      //@ts-expect-error
      const editor = editorRef?.current.getCurrent();

      const listener = (e: ClipboardEvent) => {
        if (e.clipboardData && editor?.textContent && editor.outerHTML) {
          e.clipboardData.setData('text/plain', editor?.textContent);
          e.clipboardData.setData('text/html', editor?.outerHTML);
          e.clipboardData.setData('dodoc/richText', JSON.stringify(comment.content.content));
          e.preventDefault();
        }
      };

      if (editor) {
        try {
          window.getSelection()?.removeAllRanges();
          document.addEventListener('copy', listener);
          document.execCommand('copy');
          document.removeEventListener('copy', listener);
          notify({
            type: 'success',
            title: intl.formatMessage({ id: 'TEXT_COPIED' }),
            message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_COPIED' }),
          });
          editor.removeEventListener('copy', listener);
        } catch (e) {
          editor.removeEventListener('copy', listener);
          throw e;
        }
      }
    };

    const handleVoteComment: LikeToggleProps['onVote'] = ({ replyId, currentUserLiked }) => {
      if (replyId) {
        manager.voteReply(comment.anchor[0].id, comment.id, replyId, currentUserLiked ? 0 : 1);
      } else {
        manager.voteComment(comment.anchor[0].id, comment.id, currentUserLiked ? 0 : 1);
      }
    };

    const handleReplyToComment = (reply: string) => {
      manager.replyToComment(comment.anchor[0].id, comment.id, stringToRichText(reply));
      if (containsMention(stringToRichText(reply))) {
        dispatch(completeAction('presentation_comments_mentionInCommentReply'));
      }
    };

    const handleEditReply = ({ replyId, newContent }: { replyId: string; newContent: string }) => {
      manager.editReplyComment(
        comment.anchor[0].id,
        comment.id,
        replyId,
        stringToRichText(newContent),
      );
    };

    const handleDeleteReply = (replyId: string) => {
      manager.deleteReplyComment(comment.anchor[0].id, comment.id, replyId);
    };

    const canEditReply = (replyId: string) => {
      const reply = comment.replies.find((reply) => reply.id === replyId);
      if (reply) {
        return canEditCommentReply(reply);
      }
      return false;
    };

    const canDeleteReply = (replyId: string) => {
      const reply = comment.replies.find((reply) => reply.id === replyId);
      if (reply) {
        return canDeleteCommentReply(reply);
      }
      return false;
    };

    const canVoteReply = () => {
      return canComment;
    };

    const handleChangePriority = (priority: Card.Priority) => {
      // TODO:PRESENTATION:PRIORITY PDF and Presentation priority has different capitalization
      if (comment.priority !== priority.toUpperCase()) {
        manager.changePriority(
          comment.anchor[0].id,
          comment.id,
          priority.toUpperCase() as Presentation.Model.Comments.CommentPriority,
        );
      }
    };

    if (sidebar && isListMode && !isSelected) {
      return (
        <MinifiedCard
          content={comment.content.content ?? comment.content}
          onClick={handleSelectComment}
          testId={testId}
          authorId={comment.authorId}
          authorName={comment.authorName}
          order={order}
          state={comment.status}
          status={undefined}
        />
      );
    }

    return (
      <Card
        id={id ?? `comment-card-${comment.id}`}
        testId={testId}
        sidebar={sidebar}
        selected={isSelected}
        width={sidebar ? '100%' : '43rem'}
        onClick={handleSelectComment}
        ref={ref}
      >
        <Card.Header background={cardHeaderColor}>
          <Card.Header.Left>
            <UserPresentation
              userId={comment.authorId}
              fixedName={comment.authorName}
              creationDate={comment.time}
            />
          </Card.Header.Left>
          <Card.Header.Right>
            <Priority
              priority={priority}
              onChange={handleChangePriority}
              disabled={!canChangeCommentPriority(comment)}
              testId={testId}
            />
            {cardState ? (
              <Label
                size="small"
                color={STATUS[cardState].color}
                margin={'0 0 0 0.5rem'}
                testId={`${testId}-status-label`}
              >
                <FormattedMessage id={STATUS[cardState].labelId} />
              </Label>
            ) : (
              <Options
                editOption={{
                  onClick: handleEditComment,
                  disabled: !canEditComment(comment),
                }}
                deleteOption={{
                  onClick: handleDeleteComment,
                  disabled: !canDeleteComment(comment),
                }}
                moreOptions={[
                  {
                    label: { id: 'Copy' },
                    onClick: handleCopyText,
                    disabled: !canCopyComment || comment?.content?.content?.length === 0,
                    order: 0,
                    testId: 'copy',
                  },
                ]}
                testId={testId}
                disabled={false}
              />
            )}
          </Card.Header.Right>
        </Card.Header>
        {cardHeaderColor && <Divider margin="0" />}
        <Card.Body>
          <ViewRichTextEditor
            initialValue={
              comment?.content.content
                ? JSON.stringify(comment?.content.content)
                : JSON.stringify(comment?.content)
            }
            testId={`comment-card-${comment?.id}-rich-text-editor`}
            dependencies={[comment?.content.content]}
            ref={editorRef}
          />
        </Card.Body>
        <Card.Footer>
          <Card.Footer.Left>
            <LikeToggle
              votes={comment?.votes ?? []}
              target="comment"
              onVote={handleVoteComment}
              testId={testId}
              disabled={!canComment}
            />
            <InteractionController
              environment="presentation"
              rules={[
                {
                  interaction: 'presentation_comment_reply',
                  actions: ['presentation_comments_openReplies'],
                },
              ]}
            >
              <ReplyToggle
                repliesCount={comment?.replies?.length ?? 0}
                isToggled={showReplies}
                onToggleReplies={handleToggleReplies}
                canComment={canComment}
                id={`comment-card-${comment.id}-reply-toggle`}
                testId={testId}
              />
            </InteractionController>
          </Card.Footer.Left>
          <Card.Footer.Right>
            <ResolveButton
              onClick={handleResolveComment}
              disabled={!canResolveComment(comment)}
              testId={testId}
            />
          </Card.Footer.Right>
        </Card.Footer>
        {showReplies && (
          <ReplyList
            replies={comment?.replies ?? []}
            commentId={comment?.id}
            canComment={canComment}
            createReply={handleReplyToComment}
            editReply={handleEditReply}
            voteReply={handleVoteComment}
            deleteReply={handleDeleteReply}
            testId={`${testId}-replyList`}
            collaborators={collaborators}
            newReplyInteraction={{
              environment: 'presentation',
              rules: [{ interaction: 'presentation_comment_reply' }],
              style: { flexDirection: 'column' },
            }}
            canEditReply={canEditReply}
            canDeleteReply={canDeleteReply}
            canVoteReply={canVoteReply}
          />
        )}
      </Card>
    );
  },
);

export default ViewCommentCard;
