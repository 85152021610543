import { InteractionController, IntlErrorBoundary } from '_common/components';

import SidebarToggles from './SidebarToggles';

import styles from './SidebarComponent.module.scss';

const SidebarComponent = () => {
  return (
    <div className={styles.root}>
      <InteractionController environment="editor">
        <div className={styles.toggles}>
          <IntlErrorBoundary size="xsmall" tooltipPlacement="left" margin="auto" collapsed>
            <SidebarToggles />
          </IntlErrorBoundary>
        </div>
      </InteractionController>
    </div>
  );
};

export default SidebarComponent;
