import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

type SliceState = {
  infoPanelOpen: boolean;
};

const SLICE_NAME = 'shared';

export const INITIAL_STATE: SliceState = {
  infoPanelOpen: false,
};

const sharedListPageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    toggleInfoPanel: (state) => {
      state.infoPanelOpen = !state.infoPanelOpen;
    },
    setInfoPanelOpenValue: (state, action: PayloadAction<SliceState['infoPanelOpen']>) => {
      state.infoPanelOpen = !!action.payload;
    },
  },
});

export const { toggleInfoPanel, setInfoPanelOpenValue } = sharedListPageSlice.actions;

// Persistence
const persistConfig = {
  key: 'shared',
  storage,
  whitelist: ['infoPanelOpen'],
};

const sharedListPageReducer = persistReducer(persistConfig, sharedListPageSlice.reducer);

export default sharedListPageReducer;
