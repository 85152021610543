import { useEffect } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { usePresentationData } from 'Presentation/PresentationData';
import { useSlideId } from 'Presentation/hooks';
import { setInstances, setSelectedInstance } from 'Presentation/FindSlice';

import { Find } from '_common/suite/components';

const Search = () => {
  const dispatch = useDispatch();
  const manager = usePresentationManager();
  const { structure } = usePresentationData();

  const currentSlideId = useSlideId();
  const instances = useSelector((state) => state.presentation.find.instances);
  const selectedInstance = useSelector((state) => state.presentation.find.selectedInstance);

  //Navigate to the corresponding slide of the selected instance
  useEffect(() => {
    if (instances?.length) {
      const instanceSlideId = instances[selectedInstance].location[0].id;
      if (instanceSlideId !== currentSlideId) {
        const newSlideIndex = structure.sldIdLst.findIndex((id) => id === instanceSlideId);

        if (newSlideIndex > -1) {
          manager.navigation?.goTo(newSlideIndex + 1);
        }
      }
    }
  }, [selectedInstance, instances]);

  const find = async ({
    matchCase,
    wholeWord,
    query,
  }: {
    matchCase: boolean;
    wholeWord: boolean;
    query: string;
  }) => {
    const res = await manager.find(query, {
      matchCase,
      wholeWords: wholeWord,
    });

    console.log(res);
    dispatch(
      setInstances(
        res?.map((instance, i) => {
          return { ...instance, id: i };
        }),
      ),
    );

    const instanceIndex = res?.findIndex((instance) => instance.location[0].id === currentSlideId);
    if (instanceIndex != null && instanceIndex > -1) {
      onChangeInstance(instanceIndex);
    }
  };

  const resetFind = () => {
    dispatch(setInstances(null));
    dispatch(setSelectedInstance(0));
  };

  const onChangeInstance = (newIndex: number) => {
    dispatch(setSelectedInstance(newIndex));
  };

  return (
    <Find
      find={find}
      resetFind={resetFind}
      instancesBoundary={document.getElementById('slidesContainer')}
      totalInstances={instances?.length}
      currentInstanceIndex={selectedInstance}
      onChangeInstance={onChangeInstance}
    />
  );
};

export default Search;
