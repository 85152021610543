import { useObject, useSelector } from '_common/hooks';
import { useListNavigationQuery, useListTemplateQuery } from '_common/services/api/realtime';

const useConditionalData = () => {
  const exportDocumentId = useSelector((state) => state.modals.ExportDocumentModal.objectId);

  //Navigation
  const { data: explorerNavigation } = useListNavigationQuery(exportDocumentId);
  const { list: navigationList, data: navigationData } = explorerNavigation ?? {
    list: [],
    data: {},
  };

  //Object
  const { data: object } = useObject({ object_id: exportDocumentId, object_type: 'document' });

  //Template
  const { data: explorerTemplate } = useListTemplateQuery(exportDocumentId);
  const template = explorerTemplate?.template;

  return { navigationList, navigationData, template, object };
};

export default useConditionalData;
