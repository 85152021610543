import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Tooltip } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { Action } from './ActionsSelector';

type ActionItem = {
  id: Action;
  text: string;
  textValues?: Record<string, string>;
  icon?: IconTypes['32'];
  tooltipMessageId?: TranslationId;
} & (
  | {
      subMenu?: false;
      onClick: () => void;
      disabled?: boolean;

      subActions?: undefined;
    }
  | {
      subMenu: true;
      subActions: (ActionItem | undefined)[];

      onClick?: undefined;
      disabled?: undefined;
    }
);

type ActionItemProps = Omit<ActionItem, 'id'> & { children?: ReactNode; id: string };

const ActionItem = ({
  id,
  text,
  textValues,
  icon,
  tooltipMessageId,
  subMenu,
  onClick,
  disabled,
  subActions,
  children,
}: ActionItemProps) => {
  const intl = useIntl();

  if (subMenu) {
    return (
      <Dropdown.SubMenu
        size="large"
        prefixIcon={icon}
        itemContent={<FormattedMessage id={text} values={textValues} />}
        placement="left"
        testId={`${id}-action-dropdown-submenu`}
      >
        <Tooltip
          placement="left"
          disabled={disabled !== undefined ? !disabled || !tooltipMessageId : !tooltipMessageId}
          content={
            tooltipMessageId
              ? intl.formatMessage({
                  id: tooltipMessageId,
                })
              : ''
          }
          testId={`${id}-action-dropdown-subMenu-item-tooltip`}
        >
          <div>
            {subActions?.map((action) => action && <ActionItem key={action.id} {...action} />)}
          </div>
        </Tooltip>
      </Dropdown.SubMenu>
    );
  }

  return (
    <Tooltip
      placement="left"
      disabled={disabled !== undefined ? !disabled || !tooltipMessageId : !tooltipMessageId}
      content={
        tooltipMessageId
          ? intl.formatMessage({
              id: tooltipMessageId,
            })
          : ''
      }
      testId={`${id}-action-dropdown-item-tooltip`}
    >
      <div>
        <Dropdown.Item
          size="large"
          prefixIcon={icon}
          onClick={onClick}
          disabled={disabled}
          testId={`${id}-action-dropdown-item`}
        >
          {children}
          <FormattedMessage id={text} values={textValues} />
        </Dropdown.Item>
      </div>
    </Tooltip>
  );
};

export default ActionItem;
