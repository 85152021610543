import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addData } from 'App/redux/appSlice';
import { InstanceService } from '_common/services';

type initialRequestState = {
  offset: number;
  size: number;
};

type SliceState = {
  canCreateFolder: boolean;
  isCreatingFolder: boolean;
  current: Pick<Objekt, 'id' | 'type'> | null;
  list: Uuid[];
  view?: ListableObjectType;
  selected?: ObjectId;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  request: initialRequestState;
  operatedObjIds?: ObjectId[];
  identity: Table.Identity;
};

const SLICE_NAME = 'MINIEXPLORER';

const INITIAL_REQUEST_STATE: initialRequestState = {
  offset: 0,
  size: 200,
};

const INITIAL_STATE: SliceState = {
  canCreateFolder: false,
  isCreatingFolder: false,
  current: null,
  list: [],
  view: undefined,
  selected: undefined,
  hasNextPage: false,
  isNextPageLoading: true,
  request: INITIAL_REQUEST_STATE,
  identity: 'storage',
};

const parseResponse = (data: {
  nodes: Objekt;
  source: { id: Uuid };
  permissions: { folder: boolean };
}) => {
  const list: Uuid[] = [];
  const nodes = data.nodes.reduce((nodes: Objekt, node: { id: Uuid }) => {
    nodes[node.id] = node;
    list.push(node.id);
    return nodes;
  }, {});
  if (data.source) {
    nodes[data.source.id] = data.source;
  }
  return { list, nodes, canCreateFolder: data.permissions.folder };
};

// #region AsyncThunks
export const listObject = createAsyncThunk(
  `${SLICE_NAME}/listObject`,
  async ({ objectId }: { objectId: Uuid }, { dispatch, getState }) => {
    const currentRequestState = (getState() as RootState).miniExplorer.request;

    const object = (getState() as RootState).app.data[objectId];
    const { data } = await new InstanceService({ errorsExpected: [403] }).listObject(
      objectId,
      object.type,
      currentRequestState,
    );
    // @ts-expect-error Generic endpoint
    const { list, nodes, canCreateFolder } = parseResponse(data);
    dispatch(addData(nodes));

    return {
      list,
      canCreateFolder,
      selected: undefined,
    };
  },
);

export const listRoute = createAsyncThunk(
  `${SLICE_NAME}/listRoute`,
  async ({ route }: { route: ListableObjectType }, { dispatch, getState }) => {
    const currentRequestState = (getState() as RootState).miniExplorer.request;

    const { data } = await new InstanceService({ errorsExpected: [403] }).list(
      route,
      currentRequestState,
    );
    // @ts-expect-error Generic endpoint
    const { list, nodes, canCreateFolder } = parseResponse(data);

    dispatch(addData(nodes));
    return {
      list,
      canCreateFolder,
      selected: undefined,
    };
  },
);
// #endregion

// #region Slice
const miniExplorerSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateMiniExplorer: (state, action: PayloadAction<Partial<SliceState>>) => {
      return { ...state, ...action.payload };
    },
    resetList: (state) => {
      state.list = [];
      state.selected = undefined;
      state.hasNextPage = false;
      state.isNextPageLoading = true;
      state.request = INITIAL_REQUEST_STATE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listObject.fulfilled, (state, action) => {
      const { list, canCreateFolder, selected } = action.payload;

      state.list = state.list.concat(list.filter((objectId) => !state.list.includes(objectId)));
      state.canCreateFolder = canCreateFolder;
      state.selected = selected;

      state.hasNextPage = list.length === state.request.size;
      state.request.offset = state.list.length;
    });
    builder.addCase(listObject.rejected, (state) => {
      state.view = 'space';
      state.current = null;
      state.list = [];

      state.hasNextPage = false;
      state.isNextPageLoading = true;

      state.request = INITIAL_REQUEST_STATE;
    });
    builder.addCase(listRoute.fulfilled, (state, action) => {
      const { list, canCreateFolder, selected } = action.payload;

      state.list = state.list.concat(list.filter((objectId) => !state.list.includes(objectId)));
      state.canCreateFolder = canCreateFolder;
      state.selected = selected;

      state.hasNextPage = list.length === state.request.size;
      state.request.offset = state.list.length;
    });
    builder.addCase(listRoute.rejected, (state) => {
      state.view = 'space';
      state.current = null;
      state.list = [];

      state.hasNextPage = false;
      state.isNextPageLoading = true;

      state.request = INITIAL_REQUEST_STATE;
    });
  },
});

export default miniExplorerSlice.reducer;
// #endregion

// #region Actions
export const { updateMiniExplorer, resetList } = miniExplorerSlice.actions;
// #endregion
