import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';

import NotificationSettings from 'Settings/pages/UserSettingsPage/UserNotifications/NotificationSettings/NotificationSettings';

import WorkingHours from 'Settings/pages/UserSettingsPage/UserNotifications/WorkingHours/WorkingHours';

import styles from './UserNotifications.module.scss';

const UserNotifications = () => {
  const intl = useIntl();
  return (
    <div className={styles.content}>
      <div>
        <SectionHeading
          sticky
          title={intl.formatMessage({ id: 'WORKING_HOURS' })}
          margin="0 0 2rem 0"
          testId="working-hours-heading"
        />
        <WorkingHours />
      </div>
      <div>
        <SectionHeading
          sticky
          margin="3rem 0 0 0"
          title={intl.formatMessage({ id: 'NOTIFICATION_SETTINGS' })}
          testId="notification-settings-heading"
        />
        <NotificationSettings />
      </div>
    </div>
  );
};

export default UserNotifications;
