import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, InputField, Select, Switch } from 'dodoc-design-system';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useEffectOnUpdate } from '_common/hooks';
import { MeasureInput } from '_common/components';
import { MeasureInputProps } from '_common/components/MeasureInput/MeasureInput';

import { convertToDataUnit, convertToDisplayUnit, DisplayUnit } from '../utils';
import { useTableProperties } from '../TablePropertiesContext';

import styles from './Section.module.scss';

type SizeProps = Pick<Editor.Styles.TableProperties, 'width' | 'height'> & SectionProps;

type UnitOption = SelectOption & { value: DisplayUnit };
const UNIT_OPTIONS: UnitOption[] = [
  { value: 'cm', label: 'cm' },
  { value: 'in', label: 'in' },
  { value: 'pct', label: '%' },
];

const MAX_ABSOLUTE_WIDTH = 1583.8;

const Size = ({ width, height, updateData }: SizeProps) => {
  const intl = useIntl();
  const { pageSize, setHasValidWidth, preferedUnit, setPreferedUnit } = useTableProperties();

  const getUnitOption = () => {
    const defaultOption = UNIT_OPTIONS[0];

    if (!width) {
      return defaultOption;
    }

    if (width.value.t === 'abs') {
      //abs (pt) unit is displayed as cm (value will be parsed from pt to cm)
      return UNIT_OPTIONS.find((option) => option.value === preferedUnit) ?? defaultOption;
    } else {
      return UNIT_OPTIONS.find((option) => option.value === width.value.t) ?? defaultOption;
    }
  };

  const [currentUnit, setCurrentUnit] = useState<UnitOption | null>(getUnitOption());
  const [localWidth, setLocalWidth] = useState(
    convertToDisplayUnit(width?.value.v, width?.value.t, preferedUnit),
  );
  const [localHeight, setLocalHeight] = useState(
    convertToDisplayUnit(height?.value.v, 'abs', preferedUnit),
  );

  //#region Auto width&height management
  const [autoWidth, setAutoWidth] = useState(width?.value.t === 'auto');
  useEffect(() => {
    if (!currentUnit) {
      return;
    }

    if (
      !width ||
      (width?.value.t === 'auto' && autoWidth) ||
      (width.value.t !== 'auto' && !autoWidth)
    ) {
      return;
    }

    updateData({
      property: 'width',
      value: {
        value: autoWidth
          ? {
              v: 0,
              t: 'auto',
            }
          : {
              v: convertToDataUnit(localWidth, currentUnit.value),
              t:
                currentUnit.value === 'cm' || currentUnit.value === 'in'
                  ? 'abs'
                  : currentUnit.value,
            },
      },
    });
  }, [width, autoWidth]);

  const [autoHeight, setAutoHeight] = useState(height?.value.t === 'auto');
  useEffect(() => {
    if (
      !height ||
      (height?.value.t === 'auto' && autoHeight) ||
      (height.value.t !== 'auto' && !autoHeight)
    ) {
      return;
    }

    updateData({
      property: 'height',
      value: {
        value: autoHeight
          ? {
              v: 0,
              t: 'auto',
            }
          : {
              v: convertToDataUnit(localHeight, 'cm'),
              t: 'abs',
            },
      },
    });
  }, [height, autoHeight]);
  //#endregion

  useEffectOnUpdate(() => {
    if (width?.value.t === 'abs' && currentUnit?.value !== preferedUnit) {
      setLocalWidth(convertToDisplayUnit(width?.value.v, 'abs', preferedUnit));
      setCurrentUnit(UNIT_OPTIONS.find((option) => option.value === preferedUnit) ?? null);
    }
    setLocalHeight(convertToDisplayUnit(height?.value.v, 'abs', preferedUnit));
  }, [preferedUnit]);

  const [widthFeedback, setWidthFeedback] = useState<string | null>(null);
  useEffect(() => {
    setHasValidWidth(!widthFeedback);
  }, [widthFeedback]);

  useEffect(() => {
    //Validate to define width's input field feedback
    if (pageSize) {
      switch (currentUnit?.value) {
        case 'cm':
        case 'in':
          {
            const convertedMaxAbsoluteWidth = convertToDisplayUnit(
              MAX_ABSOLUTE_WIDTH,
              'abs',
              currentUnit.value,
            );
            setWidthFeedback(
              localWidth < 0 || localWidth > convertedMaxAbsoluteWidth
                ? intl.formatMessage(
                    { id: 'ONLY_VALID_BETWEEN_MIN_AND_MAX' },
                    { min: 0, max: convertedMaxAbsoluteWidth },
                  )
                : null,
            );
          }
          break;
        case 'pct':
          setWidthFeedback(
            localWidth < 1 || localWidth > 100
              ? intl.formatMessage({ id: 'ONLY_VALID_BETWEEN_MIN_AND_MAX' }, { min: 1, max: 100 })
              : null,
          );
          break;
      }
    }
  }, [localWidth, currentUnit]);

  const handleWidthChange: MeasureInputProps['onChange'] = (newValue) => {
    setLocalWidth(+newValue);

    if (currentUnit) {
      updateData({
        property: 'width',
        value: {
          value: {
            v: convertToDataUnit(+newValue, currentUnit.value),
            t: currentUnit.value === 'cm' || currentUnit.value === 'in' ? 'abs' : currentUnit.value,
          },
        },
      });
    }
  };

  const handleUnitChange = (newUnit: UnitOption) => {
    if (newUnit.value === currentUnit?.value) {
      return;
    }

    setCurrentUnit((prevUnit) => {
      let newWidth;
      let skipUpdate = false;

      switch (prevUnit?.value) {
        case 'auto':
          newWidth =
            newUnit.value !== 'auto' && newUnit.value !== 'pct'
              ? convertToDisplayUnit(pageSize, 'abs', newUnit.value) ?? 0
              : 100;
          break;
        case 'cm':
        case 'in':
          switch (newUnit.value) {
            case 'cm':
            case 'in':
              newWidth = convertToDisplayUnit(width?.value.v, 'abs', newUnit.value);
              skipUpdate = true;
              break;
            case 'pct':
              newWidth =
                newUnit.value === 'pct' && pageSize
                  ? +(
                      (+localWidth * 100) /
                      convertToDisplayUnit(pageSize, 'abs', prevUnit.value)
                    ).toFixed(2)
                  : 0 /*Unexpected unit or undefined pageSize*/;
              break;
          }
          break;
        case 'pct':
          if (newUnit.value === 'cm' || newUnit.value === 'in') {
            newWidth =
              pageSize && (newUnit.value === 'cm' || newUnit.value === 'in')
                ? +(
                    (+localWidth * convertToDisplayUnit(pageSize, 'abs', newUnit.value)) /
                    100
                  ).toFixed(2)
                : 0 /*Unexpected unit or undefined pageSize*/;
          }
          break;
      }

      if (newWidth) {
        setLocalWidth(newWidth);

        /**
         * Changing between 'cm' and 'in' shouldn't update width property
         * Because no change to the value itself has been made and skipping avoids rounding unnecessarily
         */
        if (!skipUpdate) {
          updateData({
            property: 'width',
            value: {
              value: {
                v: convertToDataUnit(newWidth, newUnit.value),
                t: newUnit.value === 'cm' || newUnit.value === 'in' ? 'abs' : newUnit.value,
              },
            },
          });
        }
      }

      if (newUnit.value === 'cm' || newUnit.value === 'in') {
        setPreferedUnit(newUnit.value);
      }
      return newUnit;
    });
  };

  const handleHeightChange: MeasureInputProps['onChange'] = (newValue) => {
    setLocalHeight(+newValue);

    if (currentUnit && currentUnit.value !== 'pct') {
      updateData({
        property: 'height',
        value: {
          value: {
            v: convertToDataUnit(+newValue, preferedUnit),
            t: autoHeight ? 'auto' : 'abs',
          },
        },
      });
    }
  };

  const handleAutoWidthToggle = () => setAutoWidth((prev) => !prev);
  const handleAutoHeightToggle = () => setAutoHeight((prev) => !prev);

  const renderAutoWidthSwitch = () => (
    <Switch
      active={autoWidth}
      onChange={handleAutoWidthToggle}
      testId="tableProperties-auto-width-switch"
    >
      {intl.formatMessage({ id: 'AUTO_WIDTH' })}
    </Switch>
  );

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'SIZE' })}
      initialCollapsed={false}
      contentMargin="1rem"
      testId="tableProperties-size-accordion"
    >
      <div className={styles.root}>
        {width && (
          <div className={styles.column}>
            <InputField
              label={intl.formatMessage({ id: 'WIDTH' })}
              feedback={widthFeedback ?? undefined}
              testId="tableProperties-width-field"
              disabled={autoWidth}
            >
              <div className={`${styles.row} ${styles.flexChildren}`}>
                <MeasureInput
                  size="medium"
                  value={`${localWidth}`}
                  disabled={autoWidth}
                  clearable={false}
                  placeholder=""
                  onChange={handleWidthChange}
                  avoidEmpty
                  testId="tableProperties-width"
                />
                <Select
                  size="medium"
                  value={currentUnit}
                  options={UNIT_OPTIONS}
                  disabled={autoWidth}
                  clearable={false}
                  fullWidth
                  menuWidth="auto"
                  onChange={handleUnitChange}
                  testId="width-unit"
                />
              </div>
            </InputField>
            {height && renderAutoWidthSwitch()}
          </div>
        )}
        <div className={styles.column}>
          {height ? (
            <InputField
              label={intl.formatMessage({ id: 'HEIGHT' })}
              testId="tableProperties-height-field"
              disabled={autoHeight}
            >
              <div className={`${styles.row} ${styles.flexChildren}`}>
                <MeasureInput
                  size="medium"
                  value={`${localHeight}`}
                  disabled={autoHeight}
                  onChange={handleHeightChange}
                  clearable={false}
                  placeholder=""
                  valueSuffix={preferedUnit}
                  avoidEmpty
                  testId="tableProperties-height"
                />
              </div>
            </InputField>
          ) : (
            <div className={`${styles.column} ${styles.autoSwitch}`}>{renderAutoWidthSwitch()}</div>
          )}
        </div>
        {!width && (
          <div className={`${styles.column} ${styles.autoSwitch}`}>
            <Switch
              active={autoHeight}
              onChange={handleAutoHeightToggle}
              testId="tableProperties-auto-height-switch"
            >
              {intl.formatMessage({ id: 'AUTO_HEIGHT' })}
            </Switch>
          </div>
        )}
      </div>
    </Accordion>
  );
};

export default Size;
