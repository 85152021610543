import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import { useDispatch, useSelector, useFocusElementByUrl } from '_common/hooks';
import { InstanceService } from '_common/services';
import { SuiteProvider } from '_common/suite';
import { PageVisibility } from 'Editor/services';
import EditorManager from 'Editor/services/EditorManager';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  setDocumentId,
  setLoadingValue,
  getDocumentObject,
  getDocumentInfo,
  setNodeToFocus,
  updateZoomValue,
  setInitiatedWithTasks,
} from 'Editor/redux/EditorStatusSlice';
import { selectIsIEnvision } from 'App/redux/appSlice';
import { setCurrentDocument } from 'App/redux/onboardingSlice';

import { DocumentTitle, FileUploadLoading } from '_common/components';
import { notify } from '_common/components/ToastSystem';
import EditorModalConductor from './EditorModalConductor';
import OnboardingEditorStandalone from 'Editor/components/OnboardingEditor/Standalone/Standalone';
import EditorContentLoader from 'Editor/components/EditorContentLoader/EditorContentLoader';
import OnboardingEditorIntegration from 'Editor/components/OnboardingEditor/OnboardingEditorIntegration';

import Title from './pages/EditorPage/TopBarComponent/Title/Title';
import Menu from './pages/EditorPage/TopBarComponent/Menu';
import Toolbar from './pages/EditorPage/TopBarComponent/Toolbar';
import Content from './pages/EditorPage/ContentComponent/EditorInputComponent/EditorInputComponent';
import Sidebar from './pages/EditorPage/SidebarComponent/SidebarComponent';
import Footer from './pages/EditorPage/Footer';

import styles from './Editor.module.scss';
import Sidepanel from './pages/EditorPage/Sidepanel/Sidepanel';
import { clearFontValidationData } from 'App/redux/FontsSlice';

const Editor = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector((state) => state.localStorage.accounts);
  const token = accounts[userId]?.token;
  const errorModal = useSelector((state) => state.modals.open.EditorErrorModal);
  const docObject = useSelector(getDocumentObject);
  const isUploadingImage = useSelector((state) => state.editor.imageUpload.uploading);
  const nodeToFocus = useSelector((state) => state.editor.status.nodeToFocus);
  const commentsLoaded = useSelector((state) => state.editor.comments.loaded);
  const comments = useSelector((state) => state.editor.comments.order);
  const trackedActions = useSelector((state) => state.editor.trackedActions.order);
  const trackedActionsLoaded = useSelector((state) => state.editor.trackedActions.loaded);
  const tasks = useSelector((state) => state.editor.tasks.order);
  const tasksLoaded = useSelector((state) => state.editor.tasks.loaded);
  const isEnvision = useSelector(selectIsIEnvision);
  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);
  const zoomValue = useSelector((state) => state.editor.status.zoom);
  const sidepanelOpen = useSelector((state) => !!state.editor.sidebar.view);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (tasksLoaded) {
      dispatch(setInitiatedWithTasks(!!tasks.length));
    }
  }, [tasksLoaded]);

  useEffect(() => {
    // Zoom affects all widgets due to absolute positioning, they must be recalculated
    EditorManager.getInstance().updateWidgets();
  }, [zoomValue]);

  useFocusElementByUrl();

  const handleObjectProcessing = ({
    objectId,
    objectType,
  }: {
    objectId: ObjectId;
    objectType: ObjectType;
  }) => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService().getObjectStatus(objectType, objectId).then(({ data }) => {
        if (data.status !== 'processing') {
          if (data.id !== id) {
            dispatch(setCurrentDocument({ target: 'editor', id, zoom: zoomValue }));
            window.open(`/editor/${data.id}`, '_self');
          }
        } else {
          handleObjectProcessing({ objectId: data.id, objectType: objectType });
        }
      });
    }, 2500);
  };

  const handleDragStart = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (errorModal) {
      terminateEditorServices();
    }
  }, [errorModal]);

  useEffect(() => {
    dispatch(setDocumentId(id));
    dispatch(setLoadingValue(true));

    document.addEventListener('dragstart', handleDragStart);
    if (errorModal) {
      dispatch(closeAndResetModal('EditorErrorModal'));
    }

    return () => {
      dispatch(clearFontValidationData());
      dispatch(setDocumentId(''));
      terminateEditorServices();
    };
  }, []);

  useEffect(() => {
    if (id && userId && token) {
      const request = dispatch(getDocumentInfo({ objectId: id, open: true }));
      request.then(() => {
        let service = EditorManager.getInstance();
        service.initializeServices(id, {
          id: userId,
          token: token,
        });

        PageVisibility.getInstance().on('TAB_FOCUS', handlePageFocus);
        PageVisibility.getInstance().on('WINDOW_FOCUS', handlePageFocus);
        PageVisibility.getInstance().start();
      });

      return () => {
        request?.abort();
        terminateEditorServices();
      };
    }
  }, [id, token]);

  useEffect(() => {
    if (nodeToFocus && docObject && nodeToFocus.documentId === docObject.id) {
      const { objectType, objectId } = nodeToFocus;
      let objectNotFound = false;
      switch (objectType) {
        case 'comment': {
          if (commentsLoaded) {
            if (comments.includes(objectId)) {
              EditorManager.getInstance().focusComment(objectId);
            } else {
              objectNotFound = true;
            }

            dispatch(setNodeToFocus(undefined));
          }

          break;
        }
        case 'suggestion': {
          if (trackedActionsLoaded) {
            if (trackedActions?.includes(objectId)) {
              EditorManager.getInstance().focusTrackedAction(objectId);
            } else {
              objectNotFound = true;
            }

            dispatch(setNodeToFocus(undefined));
          }

          break;
        }
        case 'task': {
          if (tasksLoaded) {
            if (tasks.includes(objectId)) {
              EditorManager.getInstance().focusTask(objectId);
            } else {
              objectNotFound = true;
            }

            dispatch(setNodeToFocus(undefined));
          }

          break;
        }
        case 'node': {
          if (commentsLoaded && trackedActionsLoaded && tasksLoaded) {
            setTimeout(async () => {
              EditorManager.getInstance()
                .scrollIntoView(objectId)
                .catch((_) => {
                  notify({
                    type: 'error',
                    title: 'ELEMENT_NOT_ACCESSIBLE',
                    message: 'LINK_CANNOT_BE_OPENED_BECAUSE_ELEMENT_DOESNT_EXIST',
                  });
                });

              dispatch(setNodeToFocus(undefined));
            }, 0);
          }
          break;
        }
        default: {
          break;
        }
      }

      if (objectNotFound) {
        notify({
          type: 'error',
          title: 'CARD_TYPE_X_NOT_ACCESSIBLE',
          titleValues: { cardType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
          message: 'LINK_CANNOT_BE_OPENED_BECAUSE_CARD_TYPE_X_DOESNT_EXIST',
          messageValues: { cardType: objectType },
        });
      }
    }
  }, [
    nodeToFocus,
    tasks,
    tasksLoaded,
    commentsLoaded,
    comments,
    trackedActions,
    trackedActionsLoaded,
    docObject,
  ]);

  useEffect(() => {
    restoreZoomValue();
  }, [userId, docObject]);

  useEffect(() => {
    if (id && (active.editor || started.editor)) {
      new InstanceService().getDocumentOnboarding().then(({ data }) => {
        if (data.status === 'active') {
          if (data.id !== id) {
            dispatch(setCurrentDocument({ target: 'editor', id, zoom: zoomValue }));
            window.open(`/editor/${data.id}`, '_self');
          }
        }
        if (data.status === 'processing') {
          handleObjectProcessing({ objectId: data.id, objectType: 'document' });
        }
      });
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [active, started, id, isEnvision]);

  const restoreZoomValue = () => {
    if (userId && docObject) {
      const zoom = window.localStorage.getItem(`${userId}-${docObject.id}-zoom`);
      if (zoom) {
        dispatch(updateZoomValue(+zoom));
      }
    }
  };

  const terminateEditorServices = () => {
    document.removeEventListener('dragstart', handleDragStart);
    if (EditorManager.getInstance()) {
      EditorManager.getInstance().destroy();
    }
    PageVisibility.getInstance().destroy();
  };

  const handlePageFocus = () => {
    EditorManager.getInstance().restartOnTabFocus(id, {
      id: userId,
      token: token,
    });
  };

  if (!docObject) {
    return null;
  }

  return (
    <div className={styles.container} data-testid="editor-page">
      <DocumentTitle title={docObject.name ?? 'doDOC'}>
        <SuiteProvider app="document">
          <Title />
          <Menu />
          <Toolbar />
          <Content />
          {sidepanelOpen && <Sidepanel />}
          <Sidebar />
          <Footer />
          {isEnvision ? <OnboardingEditorIntegration /> : <OnboardingEditorStandalone />}
          {isUploadingImage && <FileUploadLoading />}
          <EditorContentLoader />
          <EditorModalConductor />
        </SuiteProvider>
      </DocumentTitle>
    </div>
  );
};

export default Editor;
