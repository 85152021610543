import { FILE_TYPE_FROM_MIME } from '_common/consts';

/**
 * Only returns value for objects with mime type of application/zip
 * Check extension of zip file and return valid mime type accordingly
 * @param objectData Object to check mime type of
 * @returns Valid mime type instead of application/zip
 */
const getValidZipMime = (objectData?: Objekt) => {
  if (objectData?.mime?.type !== 'application/zip') {
    return null;
  }

  const isDocument = objectData.name.endsWith('.docx');
  const isPresentation = objectData.name.endsWith('.pptx');

  //Get correct mime type for document or presentation
  for (const key in FILE_TYPE_FROM_MIME) {
    const typedKey = key as keyof typeof FILE_TYPE_FROM_MIME;
    if (
      (isDocument && FILE_TYPE_FROM_MIME[typedKey] === 'document') ||
      (isPresentation && FILE_TYPE_FROM_MIME[typedKey] === 'presentation')
    ) {
      return typedKey;
    }
  }
};

export default getValidZipMime;
