import { useIntl, FormattedMessage } from 'react-intl';

import { EmptyState } from 'dodoc-design-system';

import styles from './Empty.module.scss';

export type EmptyProps = {
  title: TranslationMessage;
  body: TranslationMessage;
};

const Empty = ({ title, body }: EmptyProps) => {
  const intl = useIntl();
  return (
    <EmptyState
      size="large"
      title={intl.formatMessage(
        {
          id: title.id,
        },
        title.values,
      )}
      testId="empty-state"
    >
      <div className={styles.body}>
        <FormattedMessage id={body.id} values={body.values} />
      </div>
    </EmptyState>
  );
};

export default Empty;
