import { useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { ActionBar } from '_common/components';

import { viewPersonalSpaces } from '../SpacesListPage/redux/spacesListPageSlice';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.app.loading.isOpen);

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'spaces',
            name: intl.formatMessage({ id: 'storage.sidebar.spaces' }),
          },
          {
            id: 'personal_spaces',
            name: intl.formatMessage({ id: 'PERSONAL_SPACES_HEADER' }),
          },
        ]}
        onBreadcrumbElementClick={() => {
          dispatch(viewPersonalSpaces(false));
        }}
        pageLoading={loading}
      />
    </ActionBar>
  );
};

export default Header;
