import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { BaseRangeFixer } from '../BaseRangeFixer';

export class CollapsedSelectionFixer extends BaseRangeFixer {
  protected options: Editor.Selection.FixerOptions = {
    suggestionMode: false,
    forceTextAsWrap: false,
    containerPosition: undefined,
    forceWrapAsText: false,
    forceNonEditableDirection: null,
    isDelete: false,
    isBackspace: false,
  };
  updated: boolean = false;

  constructor(args: Partial<Editor.Selection.FixerOptions>) {
    super();
    this.options = {
      ...this.options,
      ...args,
    };
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    throw new Error('Method not implemented.');
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    let anchorNode = range.startContainer;
    let anchorOffset = range.startOffset;
    let focusNode = range.endContainer;
    let focusOffset = range.endOffset;

    let ancestorContainer =
      EditorDOMUtils.closestMultiBlockContainerElement(anchorNode) ||
      EditorDOMUtils.getContentContainer(anchorNode);

    if (anchorNode === ancestorContainer && anchorNode.childNodes.length > 0) {
      let childOffset = anchorOffset;
      let caretPosition: DoDOCSelection.CaretPosition = 'START';
      let caretOffset;
      if (childOffset >= anchorNode.childNodes.length) {
        childOffset = anchorOffset - 1;
        caretPosition = 'INDEX';
        caretOffset = anchorNode.childNodes[childOffset].childNodes.length;
      }

      childOffset = anchorOffset - 1;
      caretPosition = 'END';

      if (childOffset < 0) {
        childOffset = 0;
        caretPosition = 'START';
      }

      anchorNode = anchorNode.childNodes[childOffset];
      range.setRangeStart(anchorNode, caretPosition, caretOffset);

      this.updated = true;
    }

    ancestorContainer =
      EditorDOMUtils.closestMultiBlockContainerElement(focusNode) ||
      EditorDOMUtils.getContentContainer(focusNode);

    if (focusNode === ancestorContainer && focusNode.childNodes.length > 0) {
      let childOffset = focusOffset;
      let caretPosition: DoDOCSelection.CaretPosition = 'START';
      let caretOffset;

      if (childOffset >= focusNode.childNodes.length) {
        childOffset = focusOffset - 1;
        caretPosition = 'INDEX';
        caretOffset = focusNode.childNodes[childOffset].childNodes.length;
      }

      childOffset = focusOffset - 1;
      caretPosition = 'END';

      if (childOffset < 0) {
        childOffset = 0;
        caretPosition = 'START';
      }

      focusNode = focusNode.childNodes[childOffset];
      range.setRangeEnd(focusNode, caretPosition, caretOffset);

      this.updated = true;
    }
  }
}
