import { Button, Checkbox, Popover, Toggle, usePopper } from 'dodoc-design-system';
import { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './TableColumnsToggle.module.scss';
import { useEditUserSettingsMutation, useGetUserSettingsQuery } from '_common/services/api/UserApi';
import { COLUMNS_TRANSLATION_IDS } from '_common/consts';

const TABLE_COLUMNS = [
  'status',
  'time.modification',
  'shared_with',
  'owner',
  'time.creation',
] as const;

function useTableColumns(table: keyof ApiSchemas['UserSettingsSchema']['tables']) {
  const intl = useIntl();
  const { data } = useGetUserSettingsQuery();

  return useMemo(() => {
    if (data) {
      return TABLE_COLUMNS.map((column) => ({
        id: column,
        label: COLUMNS_TRANSLATION_IDS[column]
          ? intl.formatMessage({ id: COLUMNS_TRANSLATION_IDS[column] })
          : '',
        checked: data.tables[table][column],
      }));
    }
    return [];
  }, [data]);
}

export type Column = {
  id: string;
  label: string;
  checked: boolean;
};
type TableColumnsToggleProps = { table: keyof ApiSchemas['UserSettingsSchema']['tables'] };

const TableColumnsToggle: FC<TableColumnsToggleProps> = ({ table }) => {
  const [editUserSettings] = useEditUserSettingsMutation();
  const columns = useTableColumns(table);
  const { popperProps, referenceProps, isOpen, close } = usePopper({ placement: 'bottom-end' });

  const [options, setOptions] = useState([...columns]);

  const onChange = (columnId: string) => {
    const index = options.findIndex((column) => column.id === columnId);
    const newColumnsState = [...options];
    newColumnsState.splice(index, 1, { ...options[index], checked: !options[index].checked });
    setOptions(newColumnsState);
  };

  const onClick = () => {
    const newSettings = options.reduce<
      ApiOperations['edit_user_settings']['requestBody']['content']['multipart/form-data']
    >((previous, current) => {
      previous[`tables.${table}.${current.id}`] = current.checked;
      return previous;
    }, {});
    editUserSettings(newSettings);
    close();
  };

  const isButtonDisabled = useMemo(() => {
    return options.every((option, index) => option.checked === columns[index].checked);
  }, [options, columns]);

  return (
    <>
      <Toggle
        variant="ghost"
        size="medium"
        testId="display-columns-toggle"
        dropdown
        margin="0 0 0 2rem"
        isToggled={isOpen}
        {...referenceProps}
      >
        <FormattedMessage id="DISPLAY_COLUMNS" />
      </Toggle>
      <Popover testId="display-columns-popover" {...popperProps}>
        <div className={styles.popover}>
          <div className={styles.title}>
            <FormattedMessage id="DISPLAY_COLUMNS" />
          </div>
          <div className={styles.checkboxes}>
            {options.map(({ id, label, checked }, index) => (
              <Checkbox
                key={id}
                checked={checked ? 'checked' : 'unchecked'}
                testId={`${id}-checkbox`}
                margin={index === 0 ? '' : '2rem 0 0 0'}
                size="x-small"
                onChange={() => onChange(id)}
              >
                {label}
              </Checkbox>
            ))}
          </div>
          <div className={styles.button}>
            <Button
              size="small"
              testId="display-columns-apply-button"
              variant="primary"
              margin="3rem 0 0 0"
              onClick={onClick}
              disabled={isButtonDisabled}
            >
              <FormattedMessage id="global.apply" />
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default TableColumnsToggle;
