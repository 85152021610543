import { NodeUtils } from 'Editor/services/DataManager';
import { PathUtils } from 'Editor/services/_Common/Selection';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

export class SortRowsOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  cellsIndex?: number[];
  selectedCells: Editor.Edition.CellInfo[];
  sortType: Editor.Edition.SortType;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
    sortType: Editor.Edition.SortType,
  ) {
    super(baseModel);
    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.sortType = sortType;
    this.cellsIndex = TableUtils.getCellsIndex(selectedCells);

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.cellsIndex) {
      const tableInfo: {
        data: Editor.Data.Node.Data;
        path: Realtime.Core.RealtimePath;
      } = this.model.getChildInfoById(this.tableId);

      const baseData = this.model.selectedData();

      if (!baseData) {
        return this;
      }

      if (PathUtils.isValidSelectionPath(tableInfo.path)) {
        if (tableInfo.data && tableInfo.data.childNodes?.length) {
          const tbody = tableInfo.data.childNodes[0];
          const cloneRows = JSON.parse(JSON.stringify(tbody.childNodes));
          const rows: Editor.Data.Node.Data[] = cloneRows;

          const sortedRows = this.getSortedRows(rows);

          // replace old rows with the sorted rows
          let op = RealtimeOpsBuilder.objectReplace(tbody.childNodes, sortedRows, [
            ...tableInfo.path,
            'childNodes',
            '0',
            'childNodes',
          ]);

          if (op) {
            this.ops.push(op);
            this.resultPath = [
              ...tableInfo.path,
              'childNodes',
              '0',
              'childNodes',
              '0',
              'childNodes',
              this.cellsIndex[0],
              'childNodes',
              '0',
            ];
          }
        }
      }
    }

    return this;
  }

  private getSortedRows(rows: Editor.Data.Node.Data[]): Editor.Data.Node.Data[] {
    const headerRows: Editor.Data.Node.Data[] = [];
    const imageRows: Editor.Data.Node.Data[] = [];
    const emptyRows: Editor.Data.Node.Data[] = [];
    const tableRows: Editor.Data.Node.Data[] = [];
    const equationsRows: Editor.Data.Node.Data[] = [];
    const smartObjectsRows: Editor.Data.Node.Data[] = [];
    const textAndNumbersRows: Editor.Data.Node.Data[] = [];
    const selectedCellsArray: any = {};

    if (rows && this.cellsIndex) {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const cell = row.childNodes?.[this.cellsIndex[0]];

        if (row.id && cell) {
          // check if is a headerRow
          if (row.properties && row.properties.hr) {
            headerRows.push(row);
          } else {
            // check the type of the cell childNodes content
            const type = this.getContentType(cell);

            switch (type) {
              case 'tbl':
              case 'tblb':
                tableRows.push(row);
                break;
              case 'img':
                imageRows.push(row);
                break;
              case 'text':
                const content = NodeUtils.getContentFromData(cell);

                if (content.trim() === '') {
                  emptyRows.push(row);
                } else {
                  selectedCellsArray[row.id] = content;
                }
                break;
              case 'equation':
                equationsRows.push(row);
                break;
              case 'ph':
                smartObjectsRows.push(row);
                break;

              default:
                emptyRows.push(row);
            }
          }
        }
      }
    }

    // sort the text rows --------------
    const arrayKeyValue = Object.entries(selectedCellsArray);
    arrayKeyValue.sort((a, b) => this.compare(a[1], b[1]));

    for (let i = 0; i < arrayKeyValue.length; i++) {
      const id = arrayKeyValue[i][0];
      const row = this.model.getChildInfoById(id);
      textAndNumbersRows.push(row.data);
    }
    // --------------

    const sortedRows = [
      ...textAndNumbersRows,
      ...tableRows,
      ...smartObjectsRows,
      ...imageRows,
      ...equationsRows,
      ...emptyRows,
    ];

    if (this.sortType === 'DESC') {
      sortedRows.reverse();
    }

    return [...headerRows, ...sortedRows];
  }

  private getContentType(cell: Editor.Data.Node.Data): string {
    if (cell.childNodes) {
      for (let i = 0; i < cell.childNodes.length; i++) {
        if (cell.childNodes[i].childNodes) {
          const child = cell.childNodes[i].childNodes?.[0];
          if (child && child.type) {
            return child.type;
          }
        }
      }
    }
    return 'empty';
  }

  private compare(a: any, b: any): number {
    // Check if is a not alphabetic character
    const regex = /^[^0-9a-zA-Z].*/;

    if (regex.test(a)) {
      return 1;
    }

    if (regex.test(b)) {
      return -1;
    }

    // check if is a number
    if (!isNaN(Number(a)) && !isNaN(Number(b))) {
      return Number(a) - Number(b);
    }

    // Check if one of them is a number
    if (!isNaN(Number(a))) {
      return -1;
    }

    if (!isNaN(Number(b))) {
      return 1;
    }

    // Compare the two string
    return a.localeCompare(b);
  }
}
