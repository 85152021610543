import { useSlideData } from 'Presentation/Slides/Slide/SlideData';

const CAP = {
  flat: 'butt',
  rnd: 'round',
  sq: 'square',
} as const;

const useDataLabelLine = () => {
  const { color } = useSlideData();

  const dataLabelLineStyle = (
    dLbls: Presentation.Data.DLbls | undefined,
  ): echarts.LineSeriesOption['labelLine'] => {
    if (!dLbls?.leaderLines || !dLbls.showLeaderLines) {
      return undefined;
    }

    const lineProperties = dLbls.leaderLines.properties?.ln;
    if (!lineProperties) {
      return undefined;
    }

    return {
      lineStyle: {
        cap: CAP[lineProperties.cap ?? 'flat'],
        color: lineProperties.fill?.type === 'solid' ? color(lineProperties.fill.color) : undefined,
        join: lineProperties.join?.type,
      },
    };
  };

  const dataLabelLine = (
    dLbls: Presentation.Data.DLbls | undefined,
  ): echarts.LineSeriesOption['labelLine'] => {
    if (!dLbls?.leaderLines || !dLbls?.showLeaderLines) {
      return { show: false };
    }

    return {
      show: true,
      ...dataLabelLineStyle(dLbls),
    };
  };

  return { dataLabelLine };
};

export default useDataLabelLine;
