import { useInterval } from '_common/hooks';
import { InstanceService } from '_common/services';
import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react';

type JobsContextValue = {
  addJob: (jobId: string, jobCallback: (result: any) => void) => void;
  removeJob: (jobId: string) => void;
};

const JobsContext = createContext<JobsContextValue | undefined>(undefined);

const JobsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [jobs, setJobs] = useState<Record<string, (result: any) => void>>({});

  const jobIds = Object.keys(jobs);

  const addJob = useCallback(
    (jobId: string, jobCallback: (result: any) => void) => {
      setJobs((jobs) => ({ ...jobs, [jobId]: jobCallback }));
    },
    [setJobs],
  );

  const removeJob = useCallback(
    (jobId: string) => {
      const newJobs = { ...jobs };
      delete newJobs[jobId];
      setJobs(newJobs);
    },
    [setJobs],
  );

  const controller = new AbortController();

  useInterval(
    async () => {
      const { data } = await new InstanceService({ controller }).checkJobStatus({
        jobs: jobIds,
      });
      for (let i = 0; i < jobIds.length; i++) {
        const jobId = jobIds[i];
        // @ts-expect-error job api spec is missing
        if (data[jobId]?.status === 'finished') {
          // @ts-expect-error job api spec is missing
          jobs[jobId]?.(data[jobId]?.result);
          removeJob(jobId);
        }
      }
    },
    jobIds.length > 0 ? 3000 : null,
  );

  return <JobsContext.Provider value={{ addJob, removeJob }}>{children}</JobsContext.Provider>;
};

export const useJobs = () => {
  const context = useContext(JobsContext);
  if (context === undefined) {
    throw new Error('useJobs can only be used in a Jobs Context');
  }
  return context;
};

export default JobsProvider;
