import { useLayoutEffect, useRef, useState } from 'react';

const useEllipsisCheck = <R extends HTMLElement>() => {
  const ref = useRef<R>(null);
  const [hasEllipsis, setHasEllipsis] = useState(false);

  useLayoutEffect(() => {
    setHasEllipsis(!!(ref.current && ref.current.scrollWidth > ref.current.clientWidth));
  }, []);

  return { ref, hasEllipsis };
};

export default useEllipsisCheck;
