import { useIntl } from 'react-intl';
import { Tooltip, Button } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { markAllNotificationsAsRead } from '../NotificationsSlice';
import { notify } from '_common/components/ToastSystem';

type MarkAllButtonProps = {
  enabled: boolean;
};

const MarkAllButton = ({ enabled }: MarkAllButtonProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'ALL_AVAILABLE_NOTIFICATIONS_ARE_READ' })}
      placement="bottom"
      disabled={enabled}
      testId="notifications-button-mark-all-as-read-tooltip"
    >
      <Button
        size="small"
        variant="link"
        onClick={() =>
          dispatch(markAllNotificationsAsRead()).then(() => {
            notify({
              type: 'success',
              title: 'ALL_NOTIFICATIONS_READ',
              message: 'YOU_HAVE_MARKED_ALL_UNREAD_NOTIFICATIONS_AS_READ',
            });
          })
        }
        disabled={!enabled}
        testId="notifications-button-mark-all-as-read"
        margin="0.5rem 0 0 0"
      >
        {intl.formatMessage({ id: 'MARK_ALL_AS_READ' })}
      </Button>
    </Tooltip>
  );
};

export default MarkAllButton;
