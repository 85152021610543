import { useState, useLayoutEffect, DependencyList, RefObject } from 'react';

const useIsNodeClamped = ({
  ref,
  clamp,
  dependencies,
}: {
  ref: RefObject<HTMLSpanElement | null> | undefined;
  clamp: number;
  dependencies: DependencyList;
}) => {
  const [isClamped, setIsClamped] = useState(false);
  useLayoutEffect(() => {
    if (ref && ref.current) {
      const node = ref.current;
      //When the element is clamped, scrollHeight gives the height of the element without the clamp effect
      const height = node.scrollHeight > 0 ? node.scrollHeight : node.offsetHeight;

      const totalLines = height / (parseInt(node.style.lineHeight) * 8); // * 8 to convert from rem to px

      setIsClamped(totalLines >= clamp + 1);
    }
    return () => {
      setIsClamped(false);
    };
  }, dependencies);
  return isClamped;
};

export default useIsNodeClamped;
