/* eslint-disable class-methods-use-this */
import { Mixin } from 'mixwith';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class extends superclass {
      prepareContentToAppend(content) {
        let newContent = content;
        if (!newContent) {
          newContent = DOMElementFactory.createNewParagraphElement();
          newContent.appendChild(DOMElementFactory.buildElement('BR'));
          return newContent;
        }
        if (
          (newContent.nodeType === Node.DOCUMENT_FRAGMENT_NODE && !newContent.children.length) ||
          (newContent.firstChild && EditorDOMUtils.isInlineNode(newContent.firstChild)) ||
          EditorDOMUtils.isInlineNode(newContent)
        ) {
          const newParagraph = DOMElementFactory.createNewParagraphElement();
          newParagraph.appendChild(newContent);
          return newParagraph;
        }
        return newContent;
      }
    },
);
