import axios, { AxiosError } from 'axios';
import { get, post } from './axios/axios';
import { LocalStorage, SessionStorage } from '_common/utils';

export type BaseServiceConstructor = {
  baseURL: string;
  useAuthorizationToken?: boolean;
  errorsExpected?: number[];
  controller?: AbortController;
};

export default class BaseService {
  baseURL: string;
  useAuthorizationToken: boolean;
  static token: string;
  private errorsExpected: number[];
  private controller: AbortController;

  // -----------------------------------------------------------------

  constructor({
    baseURL,
    useAuthorizationToken = true,
    errorsExpected = [],
    controller,
  }: BaseServiceConstructor) {
    this.baseURL = baseURL;
    this.useAuthorizationToken = useAuthorizationToken;
    this.errorsExpected = errorsExpected;
    if (controller) {
      this.controller = controller;
    } else {
      this.controller = new AbortController();
    }
  }

  prepareConfig(config?: Request.Config): Request.Config {
    // config headers
    const headers: Request.Config['headers'] = {
      'X-DoDOC-Tenant': LocalStorage.getTenant(),
      Accept: 'application/json, text/plain, */*',
    };

    if (this.useAuthorizationToken) {
      const token = SessionStorage.getToken();
      if (token) {
        headers.Authorization = `Token ${token}`;
      }
    }

    // In case a redux thunk is manually cancelled, if the signal is present it will also cancel the request
    // https://redux-toolkit.js.org/api/createAsyncThunk#listening-for-abort-events
    if (config?.signal) {
      config.signal.addEventListener('abort', () => {
        this.controller.abort();
      });
    } else if (config) {
      config.signal = this.controller.signal;
    }

    return {
      baseURL: this.baseURL,
      // ...config and headers has to be in this order to avoid config.headers overriding
      ...config,
      headers: {
        ...headers,
        ...config?.headers,
      },
    };
  }

  get<T>(url: string, config?: Request.Config) {
    // return axios.get(url, this.prepareConfig(config));
    return get<T>({
      url,
      config: this.prepareConfig(config),
      errorsExpected: this.errorsExpected,
    });
  }

  post<T>(url: string, data?: any, config?: Request.Config) {
    // return axios.post(url, data, this.prepareConfig(config));
    return post<T>({
      url,
      data,
      config: this.prepareConfig(config),
      errorsExpected: this.errorsExpected,
    });
  }

  cancelRequest() {
    this.controller.abort();
  }

  static isAxiosError(error: any): error is AxiosError<any> {
    return axios.isAxiosError(error);
  }
}
