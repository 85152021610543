import { FC } from 'react';
import CommonShape from './CommonShape';
import GroupShape from './Group/GroupShape';
import TableShape from './Table/TableShape';
import ChartShape from './Chart/ChartShape';
import ShapeFallback from './ShapeFallback';
import { useSlideData } from '../SlideData';
import { useDispatch, useSelector } from '_common/hooks';
import { setSelectedCard, setSelectedShape } from 'Presentation/PresentationSlice';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { useGroupData } from './Group/GroupData';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const Shape: FC<ShapeProps> = ({ shape }) => {
  const dispatch = useDispatch();
  const manager = usePresentationManager();
  const { isThumbnailSlide, addUnsupportedElement } = useSlideData();
  const { parentGroup } = useGroupData();

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);
  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);

  const handleSelectShape: React.MouseEventHandler<SVGGElement> = (e) => {
    //Only allow selection of slide shapes (skip thumbnail and if under a group)
    if (isThumbnailSlide || shape.origin !== 'slide' || parentGroup) {
      return;
    }

    e.stopPropagation();
    if (selectedShape !== shape.id) {
      manager.clearSelection();
      dispatch(setSelectedShape(shape.id));
    }

    if (selectedCard) {
      dispatch(setSelectedCard(null));
    }
  };

  const renderShapeByType = (shape: Presentation.Data.Shape) => {
    switch (shape.type) {
      case 'slidezoom':
        addUnsupportedElement('Slide Zoom');
        //TODO:PRESENTATION:UNSUPPORTED
        return <ShapeFallback fallback={shape.fallback} />;
      case 'model3d':
        addUnsupportedElement('3D models');
        //TODO:PRESENTATION:UNSUPPORTED
        return <ShapeFallback fallback={shape.fallback} />;
      case 'diagram':
        //TODO:PRESENTATION:UNSUPPORTED
        addUnsupportedElement('SmartArt');
        return null;
      case 'webextension':
        addUnsupportedElement('Add-ins');
        //TODO:PRESENTATION:UNSUPPORTED
        return <ShapeFallback fallback={shape.fallback} />;
      case 'oleObj':
        //TODO:PRESENTATION:UNSUPPORTED
        addUnsupportedElement('Object');
        return <ShapeFallback fallback={shape.oleObj.fallback.oleObj.picture} />;
      case 'table':
        return <TableShape key={shape.id} shape={shape} />;
      case 'chartex':
      case 'chart':
        return <ChartShape key={shape.id} shape={shape} />;
      /**
       * Videos are picture shapes with media[type='video'] in nvProperties
       * Sounds are picture shapes with media[type='audio'] in nvProperties
       */
      case 'picture':
        switch (shape.nvProperties?.media?.type) {
          case 'video':
            addUnsupportedElement('Video');
            break;
          case 'audio':
            addUnsupportedElement('Audio');
            break;
        }
        return <CommonShape key={shape.id} shape={shape} />;
      case 'shape':
      default:
        return <CommonShape key={shape.id} shape={shape} />;
    }
  };

  //Group shape will have their own root <g> element
  if (shape.type === 'group_shape') {
    return <GroupShape key={shape.id} groupShape={shape} />;
  }

  return (
    <g
      id={
        isThumbnailSlide ? `thumbnail-${shape.origin}-${shape.id}` : `${shape.origin}-${shape.id}`
      }
      //Required for PresentationRange to get the original shape id
      data-id={shape.id}
      data-type="shape"
      onMouseDown={handleSelectShape}
    >
      {renderShapeByType(shape)}
    </g>
  );
};

export default Shape;
