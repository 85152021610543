import { DataManagerError, DataManagerErrorPayload } from './DataManagerError';

type AlreadyExistsErrorPayload = DataManagerErrorPayload & {};

export class AlreadyExistsError extends DataManagerError {
  data: AlreadyExistsErrorPayload;

  constructor(msg: string, data: AlreadyExistsErrorPayload = {}) {
    super(msg);
    this.data = {
      ...data,
      type: 'ALREADY_EXISTS',
    };
  }
}
