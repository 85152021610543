import cx from 'classnames';
import { Icon } from 'dodoc-design-system';
import { UserCard } from '_common/components';
import { getThirdPartyIcon } from 'Auth/consts';
import styles from './SavedAccount.module.scss';
import { useLoginSetupQuery } from '_common/services/api/authority';

export const testIds = {
  name: 'name',
  email: 'email',
  tpImage: 'tpImage',
};

type SavedAccountProps = {
  account: ParsedProfile<Account>;
  removing: boolean;
  removeAccount?: (id: UserId) => void;
  onClick?: (account: ParsedProfile<Account>, useThirdParty: boolean) => void;
};

const SavedAccount = ({ account, removing, removeAccount, onClick }: SavedAccountProps) => {
  // REDUX
  const { data: setupData } = useLoginSetupQuery();
  const connectedThirdParty = setupData?.['3p'];

  const handleRemoveAccount = () => {
    removeAccount?.(account.id);
  };

  const useThirdParty =
    account.third_party &&
    account.third_party.url &&
    connectedThirdParty?.some((tp) => account.third_party && tp.name === account.third_party.name);
  const handleOnClick = () => {
    onClick?.(account, !!useThirdParty);
  };

  const thirdPartyIcon = getThirdPartyIcon(account.third_party ? account.third_party.label : '');

  return (
    <div
      className={cx(styles.root, {
        [styles.clickable]: onClick,
      })}
      onClick={handleOnClick}
      data-testid={`${account.username}_profile_login`}
    >
      <div className={styles.info}>
        <UserCard
          size="large"
          userId={account.id}
          online={account.online}
          titleStyle={{ fontWeight: 500, fontSize: '1.5rem' }}
        />
      </div>

      {useThirdParty && thirdPartyIcon && (
        <div className={styles.tp} data-testid={testIds.tpImage}>
          <Icon icon={thirdPartyIcon} size={32} />
        </div>
      )}
      {removing && (
        <div
          className={styles.removing}
          onClick={handleRemoveAccount}
          data-testid={`${account.username}-remove-account-button`}
        >
          <Icon icon="CloseGrey" size={24} />
        </div>
      )}
    </div>
  );
};

export default SavedAccount;
