import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Checkbox } from 'dodoc-design-system';

import { useDispatch, useObject, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { EditorService, InstanceService } from '_common/services';

import { closeAndResetModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { getDocumentInfo } from 'Editor/redux/EditorStatusSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import PublishVeevaLoading from './PublishVeevaLoading';
import ValidMetadataWarning from './ValidMetadataWarning';
import MetadataFields from '_common/components/MetadataFields/MetadataFields';

const PublishVeevaModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.PublishVeevaModal);
  const id = useSelector((state) => state.modals.PublishVeevaModal.id);
  const element = useSelector((state) => state.app.data[id]);
  const { data: document } = useObject({ object_id: element.id, object_type: element.type });

  const { data: metadata, isLoading } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });
  const timeoutRef = useRef<number>();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [jobId, setJobId] = useState(null);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState('');
  const [includeTasks, setIncludeTasks] = useState(true);

  const manualValues = Object.keys(metadata).filter(
    (value) => metadata[value].confirm_pre_export && !metadata[value].automatic,
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [isOpen]);

  useEffect(() => {
    if (jobId) {
      timeoutRef.current = window.setInterval(async () => {
        const { data } = await new InstanceService().checkJobStatus({ jobs: [jobId] });
        //@ts-expect-error API not fully typed yet
        switch (data[jobId].status) {
          case 'queued':
          case 'started':
            return;
          case 'finished':
            //@ts-expect-error API not fully typed yet
            if (+data[jobId].result[0] === 492) {
              notify({
                type: 'error',
                title: 'ERROR_EXPORTING_TIMEOUT_VEEVA',
                message: 'ERROR_EXPORTING_TIMEOUT_VEEVA_MESSAGE',
              });
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] === 493) {
              notify({
                type: 'error',
                title: 'DOCUMENT_ALREADY_ARCHIVED_VEEVA',
                message: 'THIS_DOCUMENT_HAS_ALREADY_BEEN_ARCHIVED_VEEVA',
              });
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] === 491) {
              notify({
                type: 'error',
                title: 'MISSING_FIRST_AND_LAST_NAME',
                message: 'MISSING_FIRST_AND_LAST_NAME_MESSAGE',
              });
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] === 200) {
              if (
                //@ts-expect-error API not fully typed yet
                data[jobId].result[1].errors &&
                //@ts-expect-error API not fully typed yet
                Object.keys(data[jobId].result[1].errors).length > 0
              ) {
                dispatch(
                  openAndUpdateModal({
                    modal: 'MetadataInconsistenciesModal',
                    //@ts-expect-error API not fully typed yet
                    data: { errors: data[jobId].result[1].errors, id },
                  }),
                );
                notify({
                  type: 'warning',
                  title: 'METADATA_VALUE_NOT_COMPLIABLE',
                  message: 'ISSUES_EXPORTED_METADATA_VEEVA_MESSAGE',
                });
              } else {
                if (element.type === 'document') {
                  dispatch(getDocumentInfo({ objectId: element.id }));
                }
                notify({
                  type: 'success',
                  title: 'DOCUMENT_EXPORTED_TO_VEEVA',
                  message: 'DOCUMENT_EXPORTED_TO_VEEVA_MESSAGE',
                });
              }
              //@ts-expect-error API not fully typed yet
            } else if (+data[jobId].result[0] >= 400) {
              //Unhandled error code
              notify({
                type: 'error',
                title: 'ERROR_EXPORTING_VEEVA',
                message: 'ERROR_EXPORTING_VEEVA_MESSAGE',
              });
            }
            close();
            return;
          case 'failed':
            setJobId(null);
            return;
          default:
            setJobId(null);
        }
      }, 2500);
    }
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [jobId]);

  useEffect(() => {
    if (document && !isLoading) {
      const ids = Object.keys(metadata);
      const valid = ids.every((id) => {
        if (metadata[id].required && !metadata[id].automatic) {
          //@ts-expect-error API not fully typed yet
          const value = document?.metadata ? document?.metadata[id] : {};
          // Empty array or no value (null || '')
          if (!value || (Array.isArray(value) && value.length === 0)) {
            return false;
          }
        }
        return true;
      });
      setValid(valid);
    }
  }, [document, metadata]);

  const submit = async () => {
    setLoading(true);
    try {
      const { data } = await new EditorService({ errorsExpected: [400] }).exportDocument(
        element?.type,
        element?.id,
        {
          connector: 'veeva',
          format: 'docx',
        },
      );
      //@ts-expect-error API not fully typed yet
      setJobId(data.id);
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.status?.includes('not_allowed')) {
            setError(true);
          } else {
            close();
          }
        } else {
          close();
        }
      }
    }
  };

  const reset = () => {
    setLoading(false);
    setError(false);
  };

  const close = () => {
    dispatch(closeAndResetModal('PublishVeevaModal'));
    setJobId(null);
  };

  const handleNoMetadataFields = () => {
    if (element?.type !== 'file') {
      return (
        <div>
          <FormattedMessage
            id="DO_YOU_WANT_TO_INCLUDE_COMMENTS_NOTES_TASKS"
            values={{ name: element?.name }}
          />
          <Checkbox
            checked={includeTasks ? 'checked' : 'unchecked'}
            onChange={() => setIncludeTasks(!includeTasks)}
            margin="3rem 0 1rem 0"
            testId="export-element-to-veeva-include-tasks"
          >
            <FormattedMessage id="YES_INCLUDE_TASKS_AS_COMMENTS" />
          </Checkbox>
        </div>
      );
    } else {
      return (
        <div>
          <FormattedMessage id="EXPORT_FILE_TO_VEEVA_MESSAGE" values={{ name: element?.name }} />
        </div>
      );
    }
  };

  return (
    <Modal
      width="60rem"
      open={!!isOpen}
      onClose={close}
      type={error ? 'error' : 'information'}
      testId="export-to-veeva"
    >
      <Modal.Header onClose={close}>
        <FormattedMessage
          id={element?.type === 'document' ? 'EXPORT_DOCUMENT_TO_VEEVA' : 'EXPORT_ELEMENT_TO_VEEVA'}
        />
      </Modal.Header>

      <Modal.Body>
        {error ? <FormattedMessage id="EXPORT_DOCUMENT_TO_VEEVA_ERROR" /> : null}
        {!error && loading ? <PublishVeevaLoading /> : null}
        {!error && !loading ? (
          <>
            {manualValues.length === 0
              ? handleNoMetadataFields()
              : manualValues.map(
                  (field: string) =>
                    document?.type && (
                      <MetadataFields
                        key={field}
                        validations={errors}
                        element={
                          document as doDOC.Document | doDOC.File | doDOC.PDF | doDOC.Presentation
                        }
                        setValidations={setErrors}
                        metadata={metadata[field]}
                      />
                    ),
                )}
            {!valid && <ValidMetadataWarning />}
          </>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <Button size="medium" onClick={close} testId="export-to-veeva-modal-cancel-button">
          <FormattedMessage id={error ? 'global.close' : 'global.cancel'} />
        </Button>
        {!error && !loading && (
          <Button
            size="medium"
            variant="primary"
            onClick={submit}
            disabled={!valid}
            testId="export-to-veeva-modal-submit-button"
          >
            <FormattedMessage id="global.export" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PublishVeevaModal;
