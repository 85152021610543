import { Mixin } from 'mixwith';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      ignoreElementNode(representation) {
        return {
          id: representation.attributes.id,
          ignore: true,
        };
      }
    },
);
