import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import HeaderContent, { HeaderProps } from './HeaderContent';

const Header = (props: HeaderProps) => {
  return (
    <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ margin: '3rem 0 2rem 0' }}>
      <HeaderContent {...props} />
    </IntlErrorBoundary>
  );
};

export default Header;
