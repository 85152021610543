import { createSelector } from '@reduxjs/toolkit';

import { ExtensionsService } from '_common/services';

import { closeAndResetModal, openAndUpdateModal, updateModal } from '_common/modals/ModalsSlice';
import { paths } from '_types/api';
import api from '_common/services/api/api';
import { notify } from '_common/components/ToastSystem';

export type TemplateApiState = {
  order: Template['id'][];
  extensions: Record<Uuid, Template>;
};

const templatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTemplatesList: builder.query<Pick<TemplateApiState, 'order' | 'extensions'>, void>({
      query: () => ({
        url: `/object/extension/template/list`,
      }),
      transformResponse: (
        responseData: paths['/api/object/extension/template/list']['get']['responses']['200']['content']['application/json'],
      ) => {
        const payload = responseData.reduce<Pick<TemplateApiState, 'order' | 'extensions'>>(
          (payload, extension) => {
            payload.order.push(extension.id);
            payload.extensions[extension.id] = extension;
            return payload;
          },
          { order: [], extensions: {} },
        );

        return payload;
      },
      providesTags: () => {
        return ['Template'];
      },
    }),
    getInstalledTemplatesList: builder.query<
      DocumentTemplate[],
      { errorsExpected?: (403 | 404)[] } | undefined | void
    >({
      query: (payload) => ({
        url: `/object/document/template/list`,
        errorsExpected: payload?.errorsExpected,
      }),
      providesTags: () => {
        return ['InstalledTemplate'];
      },
    }),
    installTemplate: builder.mutation<
      Template,
      Pick<Template, 'id' | 'name'> & { updating?: boolean }
    >({
      query: ({ id }) => ({
        url: `/object/extension/template/${id}/install`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: ['InstalledTemplate'],
      //Instead of invalidating and fetching all templates, only the affected template is updated with endpoint response
      onQueryStarted: async (parameters, { dispatch, queryFulfilled }) => {
        //Same as RTK Thunk 'pending' state
        dispatch(
          openAndUpdateModal({
            modal: 'TemplateActionModal',
            data: {
              action: parameters.updating ? 'updating' : 'installing',
              name: parameters.name,
              total: 1,
            },
          }),
        );

        try {
          const resp = await queryFulfilled;

          //Same as RTK Thunk 'fulfilled' state
          dispatch(
            templatesApi.util.updateQueryData('getTemplatesList', undefined, (draft) => {
              draft.extensions[parameters.id] = resp.data;
            }),
          );

          dispatch(closeAndResetModal('TemplateActionModal'));
          notify({
            type: 'success',
            title: parameters.updating ? 'TEMPLATE_UPDATED' : 'EXTENSION_INSTALLED_TITLE',
            message: parameters.updating
              ? 'TEMPLATE_WAS_SUCCESSFULLY_UPDATED'
              : 'EXTENSION_INSTALLED_MESSAGE',
            messageValues: { name: parameters.name },
          });
        } catch (error) {
          // Same as RTK Thunk 'rejected' state
          dispatch(closeAndResetModal('TemplateActionModal'));
          dispatch(
            openAndUpdateModal({
              modal: 'ExtensionErrorsModal',
              data: { list: [parameters.id], updating: parameters.updating },
            }),
          );
        }
      },
    }),
    uninstallTemplate: builder.mutation<Template, Pick<Template, 'id' | 'name'>>({
      query: ({ id }) => ({
        url: `/object/extension/template/${id}/uninstall`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: ['InstalledTemplate'],
      //Instead of invalidating and fetching all templates, only the affected template is updated with endpoint response
      onQueryStarted: async (parameters, { dispatch, queryFulfilled }) => {
        //Same as RTK Thunk 'pending' state
        dispatch(closeAndResetModal('ConfirmationModal'));
        dispatch(
          openAndUpdateModal({
            modal: 'TemplateActionModal',
            data: {
              action: 'uninstalling',
              name: parameters.name,
              total: 1,
            },
          }),
        );

        try {
          const resp = await queryFulfilled;

          //Same as RTK Thunk 'fulfilled' state
          dispatch(
            templatesApi.util.updateQueryData('getTemplatesList', undefined, (draft) => {
              draft.extensions[parameters.id] = resp.data;
            }),
          );

          dispatch(closeAndResetModal('TemplateActionModal'));
          notify({
            type: 'success',
            title: 'EXTENSION_UNINSTALLED_TITLE',
            message: 'EXTENSION_UNINSTALLED_MESSAGE',
            messageValues: { name: parameters.name },
          });
        } catch (error) {
          // Same as RTK Thunk 'rejected' state
          dispatch(closeAndResetModal('TemplateActionModal'));
        }
      },
    }),
    installAllTemplates: builder.mutation<
      Template[],
      { updating?: boolean; idList: Template['id'][] }
    >({
      queryFn: async (parameters, { dispatch, getState }) => {
        let returnData: ApiSchemas['TemplateSchema'][] = [];

        dispatch(
          openAndUpdateModal({
            modal: 'TemplateActionModal',
            data: {
              action: parameters.updating ? 'updating' : 'installing',
              total: parameters.idList.length,
              current: 1,
            },
          }),
        );
        await Promise.all<void>(
          parameters.idList.map((id) => {
            return new Promise((resolve) => {
              new ExtensionsService()
                .install('template', id)
                .then(({ data }) => {
                  const current = (getState() as RootState).modals.TemplateActionModal.current;
                  dispatch(
                    updateModal({
                      modal: 'TemplateActionModal',
                      data: {
                        current: current + 1,
                      },
                    }),
                  );
                  //Instead of invalidating and fetching all templates, only the affected template is updated with endpoint response
                  dispatch(
                    templatesApi.util.updateQueryData('getTemplatesList', undefined, (draft) => {
                      draft.extensions[id] = data;
                    }),
                  );
                  resolve();
                })
                .catch(() => {
                  const list = (getState() as RootState).modals.ExtensionErrorsModal.list;
                  dispatch(
                    updateModal({
                      modal: 'ExtensionErrorsModal',
                      data: {
                        list: [...list, id],
                      },
                    }),
                  );
                  resolve();
                });
            });
          }),
        ).then(() => {
          dispatch(closeAndResetModal('TemplateActionModal'));
          const list = (getState() as RootState).modals.ExtensionErrorsModal.list;
          if (list.length > 0) {
            dispatch(
              openAndUpdateModal({
                modal: 'ExtensionErrorsModal',
                data: {
                  updating: parameters.updating,
                  list,
                },
              }),
            );
          }
        });
        return { data: returnData };
      },
      invalidatesTags: ['InstalledTemplate'],
    }),
  }),
});

const selectTemplateList = templatesApi.endpoints.getTemplatesList.select();
export const selectTemplateById = createSelector(
  [selectTemplateList, (_: any, id: ObjectId) => id],
  ({ data: list }, id) => {
    return list?.extensions[id];
  },
);

// Export queries and mutations
export const {
  useGetTemplatesListQuery,
  useGetInstalledTemplatesListQuery,
  useInstallTemplateMutation,
  useUninstallTemplateMutation,
  useInstallAllTemplatesMutation,
} = templatesApi;

export default templatesApi;
