import { ToggleProps } from './Toggle';
import EditableCardToggle from './EditableCardToggle';
import ViewCardToggle from './ViewCardToggle';
import { useEffect, useRef } from 'react';

export type CardToggleProps = (
  | {
      mode: 'edit';
      object: Presentation.Data.Comment | Presentation.Data.Task;
    }
  | { mode: 'create'; object?: never }
  | { mode?: never; object: Presentation.Data.Comment | Presentation.Data.Task }
) &
  Omit<ToggleProps, 'children'>;

const CardToggle = ({ mode, object, ...toggleProps }: CardToggleProps) => {
  const toggleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (toggleProps.isOpen && toggleRef.current) {
      toggleRef.current.scrollIntoView({ block: 'center' });
    }
  }, [toggleProps]);

  if (mode === 'create') {
    return <EditableCardToggle {...toggleProps} mode="create" ref={toggleRef} />;
  } else if (mode === 'edit' && object) {
    return <EditableCardToggle {...toggleProps} mode="edit" object={object} ref={toggleRef} />;
  } else if (object) {
    return <ViewCardToggle {...toggleProps} object={object} ref={toggleRef} />;
  }

  return null;
};

export default CardToggle;
