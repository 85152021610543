import { RealtimeFragment } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export type PageAnnotationsCollection = Record<PDF.Annotation['id'], PDF.Annotation>;

export class PageAnnotations extends RealtimeFragment<PageAnnotationsCollection> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId, pageNumber: number) {
    super(transport, id, 'annotations', ['a', `${pageNumber}`]);
  }

  get annotationsIds() {
    return Object.keys(this.selectedData() || {});
  }

  get annotations() {
    let ids = this.annotationsIds;
    let data = this.selectedData() || {};
    let annots = [];
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      annots.push(data[id]);
    }
    return annots;
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as PageAnnotationsCollection;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
}
