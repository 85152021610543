import React, { ReactNode } from 'react';

type AnnotationErrorBoundaryProps = {
  children: ReactNode;
};
type AnnotationErrorBoundaryState = { hasError: boolean };

class AnnotationErrorBoundary extends React.Component<
  AnnotationErrorBoundaryProps,
  AnnotationErrorBoundaryState
> {
  state: AnnotationErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: any) {
    console.error(error);
    logger.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
    }

    return this.props.children;
  }
}

export default AnnotationErrorBoundary;
