import {
  active,
  approved,
  broken,
  checked_out,
  migrating,
  processing,
} from './defaultObjectStatus';
import { createMockedTenantTags } from './common';
import {
  createAffiliation,
  createdMockedDocument,
  createMockedDocxFile,
  // createMockedDocxFile,
  createMockedDoPDF,
  createMockedFile,
  createMockedFolder,
  createMockedGroup,
  createMockedImageFile,
  createMockedPdfFile,
  createMockedPersonalSpace,
  createMockedPptxFile,
  createMockedPresentation,
  createMockedRecycleList,
  createMockedReferenceStylesList,
  createMockedSavedFilter,
  createMockedSpace,
  createMockedTemplatesList,
  createObjectStatus,
} from './object';
import { createMockedRole, createMockedRolesList } from './role';
import { createMockedSystemSettings } from './settings';
import { createNotification } from './notification';
import {
  createMockedCurrentUser,
  createMockedUserPublicProfile,
  createMockedUserSettings,
} from './user';
import {
  createMockedConnectionList,
  createMockedDeviceList,
  createMockedSessionList,
  createMockedTenantUsers,
  createMockedThirdParty,
  createMockedToken,
  createMockedUserTokenHistory,
} from './authority';
import { createMockedPublicLink } from './publicLink';
import { DateModule, faker } from '@faker-js/faker';
import { createMockedAnnotation } from './pdf';
import { createMockedActionsList } from './auditlog';
import { createMockedMetadataList } from './metadata';

export type Connection = {
  pk: string;
  uuid: string;
  provider: number;
  creation: DateModule;
};

type Result = {
  status: number;
  data?: any;
};

type Requests = {
  authority: {
    getLogin?: Result;
    login?: Result;
    firstLogin?: Result;
    profile?: Result;
    forcePasswordChange?: Result;
    setup?: Result;
    validateUserCSV?: Result;
    createUserCSV?: Result;
  };
  onboarding: {
    getDoPDF?: Result;
  };
  object: {
    getLink?: Result;
    deleteAffiliation?: Result;
    listReferenceStyles?: Result;
    installReferenceStyle?: Result;
    uninstallReferenceStyle?: Result;
    listGroups?: Result;
    restore?: Result;
    installTemplate?: Result;
    uninstallTemplate?: Result;
    deleteMetadata?: Result;
    getRole?: Result;
    downloadFile?: Result;
  };
  tag: {
    delete?: Result;
    import?: Result;
  };
};

/**
 * Class to manage mocked data to be used in unit testing
 * All information related to the current user is always generated
 * Every other kind of object should be generated in beforeAll/beforeEach calls in the specific test files
 * The generation methods will return the generated data and use it in the related endpoints
 * @example
 * let tags
 * beforeAll(() => {
 *   tags = mockedData.generateTags();
 * })
 *
 *
 */
class MockedData {
  currentUser!: UserProfile;
  currentUserPersonalSpace!: doDOC.Space;
  spaces!: doDOC.Space[];
  groups!: doDOC.Group[];
  recycle!: doDOC.Recycle[];
  baseObjects!: Record<string, doDOC.BaseObject>;
  objectsActions!: Record<string, (Omit<ActionLog, 'extra'> & { extra?: any })[]>;
  roles!: doDOC.Tenant.Role[];
  tags!: string[];
  templates!: doDOC.Tenant.Template[];
  referenceStyles!: doDOC.Tenant.Template[];
  status!: doDOC.Object.Status[];
  affiliations!: doDOC.Editor.Affiliation;
  currentUserToken!: doDOC.Token;
  thirdParty!: ThirdParty[];
  settings!: {
    system: ApiSchemas['SystemSettingsSchema'];
    user: ApiSchemas['UserSettingsSchema'];
  };
  tenantUsers!: UserPublicProfileExtended[];
  userTokenHistory!: TokenObject[];
  requests!: Requests;
  userPublicProfiles!: UserPublicProfile[];
  publicLinks: Record<ObjectId, PublicLink[]> = {};
  publicLinksWild: Record<ObjectId, PublicLink> = {};
  connections!: ConnectionLink[];
  devices!: Device[];
  sessions!: Session[];
  notifications!: NotificationRecord[];
  pdf!: {
    initialAnnotations: PDF.Annotation[];
  };
  metadata!: doDOC.MetadataObject[];
  savedFilters!: ApiSchemas['SearchFilterSchema'][];

  constructor() {
    this.setupInitialState();
  }

  private setupInitialState() {
    this.setupCurrentUser();

    this.spaces = [this.currentUserPersonalSpace];
    this.baseObjects = {};
    this.objectsActions = {};

    // @ts-expect-error status has position:number but some have position = null
    this.status = [approved, active, processing, broken, migrating, checked_out];
    this.affiliations = this.generateAffiliations();
    this.roles = [];
    this.settings = {
      system: createMockedSystemSettings(),
      user: createMockedUserSettings(),
    };
    this.tags = [];
    this.thirdParty = [];
    this.tenantUsers = [];
    this.userTokenHistory = [];
    this.requests = {
      authority: {},
      onboarding: {},
      object: {},
      tag: {},
    };
    this.groups = [];
    this.templates = this.generateTemplates({ quantity: 3 });
    this.referenceStyles = this.generateReferenceStyles({ quantity: 3 });
    this.generateUserPublicProfiles();
    this.notifications = [];
    this.pdf = { initialAnnotations: [] };
    this.metadata = [];
    this.savedFilters = [];
  }

  reset() {
    this.setupInitialState();
  }

  setupCurrentUser(overwrites?: Parameters<typeof createMockedCurrentUser>[0]) {
    // current user related information, should always exist
    this.currentUser = createMockedCurrentUser(overwrites);
    this.currentUserToken = createMockedToken({
      id: this.currentUser.profile.id,
      username: this.currentUser.profile.username,
    });
    this.currentUserPersonalSpace = createMockedPersonalSpace({
      owner: this.currentUser.profile.id,
    });
    this.userPublicProfiles = [
      {
        email: this.currentUser.profile.email,
        first_name: this.currentUser.profile.first_name,
        last_name: this.currentUser.profile.last_name,
        username: this.currentUser.profile.username,
        id: this.currentUser.profile.id,
        is_superuser: this.currentUser.is_superuser,
        has_avatar: this.currentUser.has_avatar,
      },
    ];
  }

  /**
   * Returns a random object that matches with the params provided
   */
  getObject(type: doDOC.BaseObject['type'], ownerUserId: string = this.currentUser.profile.id) {
    const baseObjects = Object.values(this.baseObjects).filter(
      (node) => node.type === type && node.owner === ownerUserId,
    );
    const randomIndex = Math.floor(Math.random() * baseObjects.length);
    return baseObjects[randomIndex];
  }

  getPersonalSpaceObjects() {
    return Object.values(this.baseObjects).filter(
      (node) => node.space === this.currentUserPersonalSpace.id && !node.parent,
    );
  }

  getFromId(objectId: string) {
    const baseObject = this.baseObjects[objectId];
    if (baseObject) {
      return baseObject;
    }
    const space = this.spaces.find((node) => node.id === objectId);
    if (space) {
      return space;
    }
    return this.groups.find((node) => node.id === objectId);
  }

  generateTags() {
    this.tags = createMockedTenantTags();
    return this.tags;
  }

  generateRoles() {
    this.roles = createMockedRolesList();
    return this.roles;
  }

  generatePdfObject(partialPdfFile?: Partial<doDOC.File>) {
    const pdfFile = createMockedPdfFile(partialPdfFile);
    this.baseObjects[pdfFile.id] = pdfFile;
    return pdfFile;
  }

  generateFolder(partialFolder?: Partial<doDOC.Folder>) {
    const folder = createMockedFolder(partialFolder);
    this.baseObjects[folder.id] = folder;
    return folder;
  }

  generateSpace(partialSpace?: Partial<doDOC.Space>) {
    const space = createMockedSpace(partialSpace);
    this.spaces.push(space);
    return space;
  }

  generateGroup(partialGroup?: Partial<doDOC.Group>) {
    const group = createMockedGroup(partialGroup);
    this.groups.push(group);
    return group;
  }

  generateGroupList({
    quantity,
    partialGroup,
  }: {
    quantity?: number;
    partialGroup?: Partial<doDOC.Group>;
  }) {
    const newGroups = [];
    for (let i = 0; i < (quantity ?? 1); i++) {
      newGroups.push(createMockedGroup(partialGroup));
    }

    this.groups = [...this.groups, ...newGroups];

    return newGroups;
  }

  generateSpacesList({
    quantity,
    partialSpace,
  }: {
    quantity?: number;
    partialSpace?: Partial<doDOC.Space>;
  }) {
    const newSpaces = [];
    for (let i = 0; i < (quantity ?? 1); i++) {
      newSpaces.push(createMockedSpace(partialSpace));
    }

    this.spaces = [...this.spaces, ...newSpaces];

    return newSpaces;
  }

  generateDoPDF(partialPdf?: Partial<doDOC.PDF>) {
    const pdf = createMockedDoPDF(partialPdf);
    this.baseObjects[pdf.id] = pdf;
    return pdf;
  }

  generateDoPDFObjects(props?: { quantity?: number; overwrites?: Partial<doDOC.PDF> }) {
    const newPDF: Record<string, doDOC.PDF> = {};

    for (let i = 0; i < (props?.quantity ?? 1); i++) {
      const pdf = createMockedDoPDF(props?.overwrites);
      newPDF[pdf.id] = pdf;
    }

    this.baseObjects = { ...this.baseObjects, ...newPDF };

    return Object.values(newPDF);
  }

  generateDocument(partialDocument?: Partial<doDOC.Document>) {
    const document = createdMockedDocument(partialDocument);
    this.baseObjects[document.id] = document;
    return document;
  }

  generateDocuments(props?: { quantity?: number; overwrites?: Partial<doDOC.Document> }) {
    const newDocuments: Record<string, doDOC.Document> = {};

    for (let i = 0; i < (props?.quantity ?? 1); i++) {
      const document = createdMockedDocument(props?.overwrites);
      newDocuments[document.id] = document;
    }

    this.baseObjects = { ...this.baseObjects, ...newDocuments };

    return Object.values(newDocuments);
  }

  generatePresentation(partialPresentation?: Partial<doDOC.Presentation>) {
    const presentation = createMockedPresentation(partialPresentation);
    this.baseObjects[presentation.id] = presentation;
    return presentation;
  }

  generateThirdParty(quantity: number = 1) {
    const newThirdPartyList = [];
    for (let i = 0; i < quantity; i++) {
      newThirdPartyList.push(createMockedThirdParty());
    }

    this.thirdParty = newThirdPartyList;
    return newThirdPartyList;
  }

  generateTenantUsers(props?: { quantity?: number; allInactive?: boolean; noneAdmin?: boolean }) {
    const users = createMockedTenantUsers({
      quantity: props?.quantity ?? 10,
      allInactive: props?.allInactive,
      noneAdmin: props?.noneAdmin,
    });
    this.tenantUsers = users;
    return users;
  }

  generateUserTokenHistory(props?: { quantity?: number }) {
    const tokenHistory = createMockedUserTokenHistory(props?.quantity ?? 10);
    this.userTokenHistory = tokenHistory;
    return tokenHistory;
  }

  editCurrentUser(overwrites?: Partial<Omit<UserProfile, 'profile'>>) {
    this.currentUser = { ...this.currentUser, ...overwrites };
  }

  editCurrentUserOther(overwrites?: Partial<UserProfile['other']>) {
    this.currentUser.other = { ...this.currentUser.other, ...overwrites };
  }

  editCurrentUserProfile(overwrites?: Partial<Omit<UserProfile['profile'], 'id'>>) {
    this.currentUser = {
      ...this.currentUser,
      profile: { ...this.currentUser.profile, ...overwrites },
    };
  }

  editCurrentToken(overwrites?: Partial<doDOC.Token>) {
    this.currentUserToken = { ...this.currentUserToken, ...overwrites };
  }

  editFolder(id: string, overwrites?: Partial<doDOC.Folder>) {
    this.baseObjects[id] = { ...this.baseObjects[id], ...overwrites };
  }

  editStatus(overwrites?: Partial<doDOC.Object.Status[]>) {
    this.status = [...this.status, { ...this.status[0], ...overwrites }];
  }

  setRequestResult<R extends keyof Requests>(path: R, endpoint: keyof Requests[R], result: Result) {
    this.requests = {
      ...this.requests,
      [path]: { ...this.requests[path], [endpoint]: result },
    };
  }

  generateFile(payload: Parameters<typeof createMockedFile>[0]) {
    const file = createMockedFile(payload);
    this.baseObjects[file.id] = file;
    return file;
  }

  generateDocxFile(payload: Parameters<typeof createMockedDocxFile>[0]) {
    const file = createMockedDocxFile(payload);
    this.baseObjects[file.id] = file;
    return file;
  }
  generatePdfFile(payload: Parameters<typeof createMockedPdfFile>[0]) {
    const file = createMockedPdfFile(payload);
    this.baseObjects[file.id] = file;
    return file;
  }
  generatePptxFile(payload: Parameters<typeof createMockedPptxFile>[0]) {
    const file = createMockedPptxFile(payload);
    this.baseObjects[file.id] = file;
    return file;
  }
  generateImageFile(payload: Parameters<typeof createMockedImageFile>[0]) {
    const file = createMockedImageFile(payload);
    this.baseObjects[file.id] = file;
    return file;
  }

  setAuthenticatedStatus(authenticated: boolean) {
    if (authenticated) {
      this.currentUserToken = {
        ...this.currentUserToken,
        token: `${faker.random.alphaNumeric(24)}`,
        device: `${faker.random.alphaNumeric(24)}`,
      };
    } else {
      const { token, device, ...rest } = this.currentUserToken;
      this.currentUserToken = { ...rest };
    }
  }

  generateUserPublicProfiles(
    numberOfUsers: number = 5,
    partialProfiles: Partial<UserPublicProfile>[] = [],
  ) {
    const profiles = [];
    for (let i = 0; i < numberOfUsers; i++) {
      const publicProfile = createMockedUserPublicProfile(partialProfiles[i]);
      this.userPublicProfiles.push(publicProfile);
      profiles.push(publicProfile);
    }
    return profiles;
  }

  createPublicLink(object: doDOC.File, overwrites?: Partial<PublicLink>) {
    const newPublicLink = createMockedPublicLink({ ...overwrites, object: object.id });
    object.links.push(newPublicLink.id);
    if (!this.publicLinks[object.id]) {
      this.publicLinks[object.id] = [];
    }
    this.publicLinks[object.id].push(newPublicLink);
    this.publicLinksWild[newPublicLink.id] = newPublicLink;
    return newPublicLink;
  }

  generateTemplates({ quantity }: { quantity: number }) {
    this.templates = createMockedTemplatesList({ quantity });
    return this.templates;
  }

  generateReferenceStyles({
    quantity,
    referenceStyles,
  }: Parameters<typeof createMockedReferenceStylesList>[0]) {
    this.referenceStyles = createMockedReferenceStylesList({ quantity, referenceStyles });
    return this.referenceStyles;
  }

  editTemplate(overwrites?: Partial<doDOC.Tenant.Template>) {
    this.templates[0] = { ...this.templates[0], ...overwrites };
  }

  /**
   * Add a new status to mockedData.status. By default the status has a position
   */
  addObjectStatus(props?: Partial<doDOC.Object.Status>) {
    const newStatus = createObjectStatus({
      ...props,
      position: this.status.filter((status) => status.position != null).length,
    });
    this.status.push(newStatus);
    return newStatus;
  }

  generateAffiliations(payload?: { quantity?: number }) {
    const affiliations = createAffiliation({ quantity: payload?.quantity ?? 5 });
    this.affiliations = affiliations;
    return affiliations;
  }

  createRole(props?: Partial<doDOC.Tenant.Role>) {
    const newRole = createMockedRole(props);
    this.roles.push(newRole);
    return newRole;
  }

  uploadTags(tags: string[]) {
    if (tags.length) {
      mockedData.tags = mockedData.tags.concat(tags);
    }

    return mockedData.tags;
  }
  generateConnectionsList() {
    this.connections = createMockedConnectionList();
    return this.connections;
  }

  generateSessionsList() {
    this.sessions = createMockedSessionList();
    return this.sessions;
  }

  generateDevicesList() {
    this.devices = createMockedDeviceList();
    return this.devices;
  }

  generateRecycleList(payload?: { quantity?: number }) {
    const recycle = createMockedRecycleList({ quantity: payload?.quantity ?? 5 });
    this.recycle = recycle;

    return recycle;
  }

  generateNotifications({
    quantity,
    overwrites,
    actionOverwrites,
  }: {
    quantity?: number;
    overwrites?: Partial<NotificationRecord>;
    actionOverwrites?: Partial<NotificationRecord['action']>;
  }) {
    const newNotifications: NotificationRecord[] = [];
    for (let i = 0; i < (quantity ?? 1); i++) {
      newNotifications.push(createNotification({ overwrites, actionOverwrites }));
    }

    this.notifications = [...this.notifications, ...newNotifications];

    return newNotifications;
  }
  //#region Audit Log
  generateObjectActionsList({ objectId, actions }: Parameters<typeof createMockedActionsList>[0]) {
    this.objectsActions[objectId] = createMockedActionsList({ objectId, actions });
    return this.objectsActions[objectId];
  }
  //#endregion

  //#region PDF
  pdfGenerateInitialAnnotation(overwrites: Parameters<typeof createMockedAnnotation>[0]) {
    const annotation = createMockedAnnotation(overwrites);
    this.pdf.initialAnnotations.push(annotation);
    return annotation;
  }
  //#endregion

  //#region Metadata
  generateMetadataList({
    defaultQuantity = 0,
    metadata,
  }: Parameters<typeof createMockedMetadataList>[0]) {
    this.metadata = createMockedMetadataList({ defaultQuantity, metadata });
    return this.metadata;
  }
  //#endregion

  generateSavedFilter(overwrites: Parameters<typeof createMockedSavedFilter>[0]) {
    const savedFilter = createMockedSavedFilter(overwrites);
    this.savedFilters.push(savedFilter);
    return savedFilter;
  }
}

const mockedData = new MockedData();

export default mockedData;
