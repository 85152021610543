export class ViewModelChildren<T extends { id: any }> {
  protected childrenList: T[] = [];
  protected childrenMap: {
    [index: string]: T;
  } = {};

  get length() {
    return this.childrenList.length;
  }

  getAtIndex(index: number) {
    return this.childrenList[index];
  }

  getById(id: string) {
    return this.childrenMap[id];
  }

  indexOf(vm: T) {
    return this.childrenList.indexOf(vm);
  }

  includes(vm: T) {
    return this.childrenList.includes(vm);
  }

  splice(start: number, delelteCount: number, replace: T[] = []) {
    for (let index = 0; index < replace.length; index++) {
      if (this.childrenMap[replace[index].id]) {
        logger.trace('children already in struct');
      } else {
        this.childrenMap[replace[index].id] = replace[index];
      }
    }
    const deleted = this.childrenList.splice(start, delelteCount, ...replace);
    for (let index = 0; index < deleted.length; index++) {
      delete this.childrenMap[deleted[index].id];
    }
    return deleted;
  }

  shift() {
    const deleted = this.childrenList.shift();
    if (deleted) {
      delete this.childrenMap[deleted.id];
    }
    return deleted;
  }

  push(vm: T) {
    if (vm) {
      if (this.childrenMap[vm.id]) {
        logger.trace('children already in struct');
      }
      this.childrenList.push(vm);
      this.childrenMap[vm.id] = vm;
    }
  }

  remove(vm: T) {
    let index = this.childrenList.indexOf(vm);
    if (index >= 0) {
      return this.splice(index, 1);
    }
    return [];
  }

  removeById(id: string) {
    let vm = this.getById(id);
    return this.remove(vm);
  }

  removeAll() {
    let deleted = [...this.childrenList];
    this.childrenList = [];
    this.childrenMap = {};
    return deleted;
  }

  list() {
    return this.childrenList;
  }
}
