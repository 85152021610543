import { useSuiteObject } from '_common/suite';
import { NotificationsCenter } from '_common/components';

import { useSelector } from '_common/hooks';

import { useFocusCard } from './hooks';

const PresentationNotificationsCenter = () => {
  const object = useSuiteObject();
  const presentation = useSelector((state) => state.presentation.general);
  const presentationId = presentation?.presentationId ?? '';

  const { focusCard } = useFocusCard();

  const handleNavigateToObject = ({
    objectType,
    objectId,
  }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
    objectId: ObjectId;
    documentId: ObjectId;
  }) => {
    if (presentation && presentationId && (objectType === 'comment' || objectType === 'task')) {
      focusCard({ objectId, objectType });
    }
  };

  return (
    <NotificationsCenter
      object={object}
      margin="0 0 0 2rem"
      navigateToObject={handleNavigateToObject}
    />
  );
};

export default PresentationNotificationsCenter;
