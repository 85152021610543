import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import EditorManager from 'Editor/services/EditorManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';
import { BaseViewElement } from '../..';
import styles from './EquationElement.module.scss';

const hideOnClickOutside = (
  element: EquationElement,
  callback: (elem: EquationElement) => void,
) => {
  const outsideClickListener = (event: Event) => {
    if (!element.contains(event.target as Node) && isVisible(element)) {
      // or use: event.target.closest(selector) === null
      callback(element);
      removeClickListener();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener);
  };

  document.addEventListener('click', outsideClickListener);
};

const isVisible = (elem: HTMLElement) =>
  !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

export class EquationElement extends BaseViewElement {
  private _equation?: string;
  private container?: HTMLElement;
  private contWrapper?: HTMLElement;
  private wrapper?: HTMLElement;
  private iconWrapper?: Element;
  private info?: HTMLElement;

  static onDoubleClickListener() {
    EditorManager.getInstance().editEquation();
  }

  constructor() {
    super();
    this.onClickListener = this.onClickListener.bind(this);
  }

  get displayType() {
    return DISPLAY_TYPES.INLINE;
  }

  // Hook when element is mounted
  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this.onClickListener);
    this.addEventListener('dblclick', EquationElement.onDoubleClickListener);

    this.selected = false;
    // this.renderEquation();
  }

  preRender() {
    this.renderEquation();
    super.preRender();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this.onClickListener);
    this.removeEventListener('dblclick', EquationElement.onDoubleClickListener);
  }

  onClickListener() {
    EditorManager.getInstance().selectNodeContents(this);
    if (this.selected) {
      // this.selected = false;
    } else {
      this.selected = true;
      hideOnClickOutside(this, (element: EquationElement) => {
        element.selected = false;
      });
    }
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(val) {
    if (val) {
      this.setAttribute('selected', 'true');
    } else {
      this.removeAttribute('selected');
    }
  }

  get equation() {
    return this.getAttribute('equation');
  }

  set equation(value: string | null) {
    if (value) {
      this.setAttribute('equation', value);
    }
  }

  updateEquation(value: string) {
    this.equation = value;
    this.renderEquation();
  }

  _setIcon() {
    if (this.iconWrapper) {
      ReactDOM.unmountComponentAtNode(this.iconWrapper);
      ReactDOM.render(<Icon icon="Warning" size={32} />, this.iconWrapper);
    }
  }

  _setTranslations() {
    const locale = ReduxInterface.getLocale();
    if (this.info) {
      ReactDOM.unmountComponentAtNode(this.info);
      ReactDOM.render(
        <IntlProvider locale={locale}>
          <FormattedMessage id="EQUATION_ERROR" />
        </IntlProvider>,
        this.info,
      );
    }
  }

  _setError() {
    if (this.container) {
      this.container.innerHTML = '';
      this.container.classList.remove(styles.container);
      this.container.classList.add(styles.errorContainer);
      this.contWrapper = document.createElement('div');
      this.contWrapper.classList.add(styles.wrapper);
      this.info = DOMElementFactory.buildElement('span');
      this.info.setAttribute('class', styles.info);

      this.wrapper = DOMElementFactory.buildElement('span');
      this.wrapper.setAttribute('class', styles.contentWrapper);

      this.wrapper.appendChild(this.info);

      this.iconWrapper = DOMElementFactory.buildElement('div');
      this.iconWrapper.setAttribute('class', styles.iconWrapper);

      this.contWrapper.appendChild(this.iconWrapper);
      this.contWrapper.appendChild(this.wrapper);
      this.container.appendChild(this.contWrapper);
      this._setTranslations();
      this._setIcon();
    }
  }

  renderEquation(recursive = false) {
    let isValid;
    while (this.firstChild) {
      this.firstChild.remove();
    }
    this.container = document.createElement('div');
    this.container.classList.add(styles.container);
    this.appendChild(document.createTextNode('\u200B'));
    this.appendChild(this.container);
    this.appendChild(document.createTextNode('\u200B'));

    try {
      this.container.appendChild(MathJax.mathml2svg(this.equation));
      MathJax.startup.document.clear();
      MathJax.startup.document.updateDocument();

      isValid = !this.container.querySelector('.noError');
    } catch (error) {
      if (!recursive) {
        // WARN: temp fix, some equations do not render properly the first time
        this.renderEquation(true);
      } else {
        isValid = false;
      }
      Logger.captureException(error);
    } finally {
      if (!isValid) {
        this._setError();
      }
    }
  }
}

if (!window.customElements.get(ELEMENTS.EquationElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.EquationElement.IDENTIFIER, EquationElement);
}
