import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, EmptyState, Modal, SectionHeader } from 'dodoc-design-system';
import { v4 as uuid } from 'uuid';

import { parseMeasurement } from 'utils';
import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import CustomTabStop, { TempCustomTabStop } from './CustomTabStop/CustomTabStop';
import TabStopField, { TempTabStop } from './TabStopField/TabStopField';

import styles from './TabulationsModal.module.scss';

const MODAL = 'TabulationsModal';

const DEFAULT_VALUE: number = 1.27;
const DEFAULT_MEASURE: Editor.Tabulations.Measure = 'cm';

const TabulationsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialDefaultTabStop = useMemo(() => {
    const initialValue = EditorManager.getInstance().getDefaultTabStop();
    const value =
      initialValue != null
        ? parseMeasurement(`${initialValue}`, DEFAULT_MEASURE, {
            defaultUnit: 'pt',
            max: undefined,
          })
        : DEFAULT_VALUE;
    return {
      value: value ?? DEFAULT_VALUE,
      measure: DEFAULT_MEASURE,
    };
  }, []);

  const isOpen = useSelector((state) => state.modals.open[MODAL]);

  const initialCustomTabStop = useSelector((state) => state.editor.status.selection.TABULATIONS);
  const blockIds = useSelector((state) => state.editor.status.selection.BLOCK_IDS);

  const [defaultTabStop, setDefaultTabStop] = useState<TempTabStop>(initialDefaultTabStop);
  const [customTabStops, setCustomTabStops] = useState<TempCustomTabStop[]>([]);
  const [formIsValid, setFormIsValid] = useState(false);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const orderCustomTabStops = (customTabStops: TempCustomTabStop[]) => {
    //Order by value (cm) ASC
    return [...customTabStops].sort((a, b) => {
      const parsedA =
        parseMeasurement(`${a.value}`, 'cm', {
          defaultUnit: a.measure,
          max: undefined,
        }) ?? 0;
      const parsedB =
        parseMeasurement(`${b.value}`, 'cm', {
          defaultUnit: b.measure,
          max: undefined,
        }) ?? 0;

      return parsedA - parsedB;
    });
  };

  const tabulationsAreChangedAndValid = useMemo(() => {
    const initialCustomTabStopsAreTheSame = initialCustomTabStop.every((tabStop) => {
      return customTabStops.some((tmpTabStop) => {
        const valueA = parseMeasurement(`${tmpTabStop.value}`, 'cm', {
          defaultUnit: tmpTabStop.measure,
          max: undefined,
        });

        const valueB = parseMeasurement(`${tabStop.value}`, 'cm', {
          defaultUnit: 'pt',
          max: undefined,
        });

        return (
          valueA === valueB &&
          tmpTabStop.type === tabStop.type &&
          tmpTabStop.leader === tabStop.leader
        );
      });
    });

    if (!initialCustomTabStopsAreTheSame || initialCustomTabStop.length !== customTabStops.length) {
      return customTabStops.every((tabStop) => tabStop.value != null && !tabStop.hasError);
    }

    if (
      (`${initialDefaultTabStop.value}` !== `${defaultTabStop.value}` ||
        initialDefaultTabStop.measure !== defaultTabStop.measure) &&
      !defaultTabStop.hasError
    ) {
      return true;
    }
    return false;
  }, [initialDefaultTabStop, initialCustomTabStop, defaultTabStop, customTabStops]);

  const handleAddCustomTabStop = () => {
    const newCustomTabStops = [...customTabStops];
    const emptyTabStop: (typeof customTabStops)[number] = {
      type: 'l',
      leader: 'n',
      id: uuid(),
      measure: 'cm',
    };
    newCustomTabStops.unshift(emptyTabStop);
    setCustomTabStops(newCustomTabStops);
  };

  const handleRemoveCustomTabStop = (id: string) => {
    const elementToRemove = customTabStops.find((tabStop) => tabStop.id === id);
    if (elementToRemove) {
      const newCustomTabStops = [...customTabStops];
      const index = newCustomTabStops.indexOf(elementToRemove);
      if (index > -1) {
        newCustomTabStops.splice(index, 1);
        setCustomTabStops(newCustomTabStops);
      }
    }
  };

  const handleRemoveAllCustomTabs = () => {
    setCustomTabStops([]);
  };

  const handleDefaultTabStopChange = (data: TempTabStop) => {
    if (data.value && data.measure) {
      setDefaultTabStop(data);
    }
  };

  const handleCustomTabStopChange = (data: TempCustomTabStop) => {
    const elementToChange = customTabStops.find((tabStop) => tabStop.id === data.id);

    if (elementToChange) {
      const newCustomTabStops = [...customTabStops];
      const index = newCustomTabStops.indexOf(elementToChange);
      if (index > -1) {
        newCustomTabStops[index] = data;
        setCustomTabStops(newCustomTabStops);
      }
    }
  };

  const handleCustomTabStopCollapse = (data: TempTabStop) => {
    if (data.value && data.measure) {
      setCustomTabStops(orderCustomTabStops(customTabStops));
    }
  };

  const handleSave = () => {
    if (formIsValid) {
      if (defaultTabStop.value != null && defaultTabStop.measure) {
        const parsedCustomTabStops: Editor.Data.TabStop[] = customTabStops
          .filter((customTabStop) => customTabStop.value != null)
          .map<Editor.Data.TabStop>((customTabStop) => {
            return {
              l: customTabStop.leader,
              t: customTabStop.type,
              v:
                parseMeasurement(`${customTabStop.value}`, 'pt', {
                  defaultUnit: customTabStop.measure ?? 'cm',
                  max: undefined,
                }) ?? 0,
            };
          });

        EditorManager.getInstance().setDefaultTabStop(
          parseMeasurement(`${defaultTabStop.value}`, 'pt', {
            defaultUnit: defaultTabStop.measure ?? 'cm',
            max: undefined,
          }) ?? 0,
        );

        blockIds?.forEach((blockId) => {
          EditorManager.getInstance().updateCustomTabsInNode(blockId, parsedCustomTabStops);
        });
        close();
      }
    }
  };

  useEffect(() => {
    setDefaultTabStop(initialDefaultTabStop);
  }, [initialDefaultTabStop]);

  useEffect(() => {
    const newCustomTabStops: typeof customTabStops = [];
    initialCustomTabStop.forEach((tabStop) => {
      const value =
        parseMeasurement(`${tabStop.value}`, 'cm', {
          defaultUnit: 'pt',
          max: undefined,
        }) ?? tabStop.value;
      newCustomTabStops.push({ ...tabStop, id: uuid(), measure: 'cm', value });
    });
    setCustomTabStops(orderCustomTabStops(newCustomTabStops));
  }, [initialCustomTabStop]);

  useEffect(() => {
    setFormIsValid(tabulationsAreChangedAndValid);
  }, [tabulationsAreChangedAndValid]);

  return (
    <Modal width="65rem" open={!!isOpen} onClose={close} testId="tabulations">
      <Modal.Header onClose={close}>
        <FormattedMessage id="TABULATIONS" />
      </Modal.Header>
      <Modal.Body>
        <SectionHeader margin="0 0 2rem 0">
          <FormattedMessage id="DEFAULT_TAB_STOP" />
        </SectionHeader>
        <TabStopField
          initialValue={defaultTabStop.value}
          initialMeasure={defaultTabStop.measure}
          selectWidth="15rem"
          onChange={handleDefaultTabStopChange}
          testId="default"
        />
        <SectionHeader
          margin="2rem 0 3rem 0"
          suffixButtonProps={
            customTabStops.length > 0
              ? {
                  size: 'small',
                  variant: 'neutral',
                  onClick: handleRemoveAllCustomTabs,
                  testId: 'custom-tabs-delete-all',
                  children: <FormattedMessage id="DELETE_ALL" />,
                }
              : undefined
          }
          secondary={{
            label: intl.formatMessage({ id: 'global.add' }),
            onClick: handleAddCustomTabStop,
            testId: 'custom-tabs-add',
          }}
        >
          <FormattedMessage id="CUSTOM_TAB_STOP" />
        </SectionHeader>
        <div className={styles.customTabs}>
          {customTabStops.length > 0 ? (
            customTabStops.map((tabStop, i) => {
              return (
                <CustomTabStop
                  key={tabStop.id}
                  id={tabStop.id}
                  value={tabStop.value}
                  measure={tabStop.measure}
                  type={tabStop.type}
                  leader={tabStop.leader}
                  onRemove={handleRemoveCustomTabStop}
                  onChange={handleCustomTabStopChange}
                  onCollapse={handleCustomTabStopCollapse}
                  testId={`custom-${i}`}
                />
              );
            })
          ) : (
            <div className={styles.empty}>
              <EmptyState
                size="medium"
                title={intl.formatMessage({ id: 'NO_CUSTOM_TAB_STOPS_IN_THE_DOCUMENT' })}
                footer={<FormattedMessage id="ADD_CUSTOM_TAB_STOP" />}
                onClick={handleAddCustomTabStop}
                testId="empty-list"
              >
                <FormattedMessage id="ADD_CUSTOM_TAB_STOPS_AND_THEY_WILL_APPEAR_HERE" />
              </EmptyState>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleSave}
          disabled={!formIsValid}
          testId="save-button"
        >
          <FormattedMessage id="SAVE_CHANGES" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TabulationsModal;
