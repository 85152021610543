import { Fragment } from 'react';
import { Divider, Icon, Tooltip } from 'dodoc-design-system';

import styles from './Column.module.scss';

export type RowObject = { label: string; quantity: number };

type ColumnProps = {
  title: string;
  information?: string;
  rows: RowObject[];
};

const Column = ({ title, information, rows }: ColumnProps) => {
  return (
    <div className={styles.root}>
      <div className={`${styles.row} ${styles.header}`}>
        {title}
        {information && (
          <Tooltip content={information} testId="count-information-tooltip">
            <Icon size={16} icon="InformationBlue" />
          </Tooltip>
        )}
      </div>
      {rows.map((row, index) => {
        return (
          <Fragment key={`wordCount-row-${index}`}>
            <div className={styles.row}>
              {row.label}
              <span className={styles.quantity}>{row.quantity}</span>
            </div>
            <Divider margin="0" />
          </Fragment>
        );
      })}
    </div>
  );
};

export default Column;
