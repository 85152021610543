export type SnapshotTypes = Presentation.Model.DataTypes & {
  USERS: string[];
  SLIDE_COMMENTS: {
    list: string[];
    data: Presentation.Model.Comments.Data['sld'][string];
  };
  COMMENTS: {
    list: string[];
    data: Record<string, Presentation.Data.Comment>;
  };
  TASKS: {
    list: string[];
    data: Record<string, Presentation.Data.Task>;
  };
};

interface IRealtimeObjectStore {
  subscribe(listener: () => void): () => void;
  getSnapshot<TypeName extends Presentation.Model.Name | 'USERS'>():
    | undefined
    | SnapshotTypes[TypeName];
}

interface IRealtimeObjectFragmentStore {
  subscribe(listener: () => void): () => void;
  getSnapshot<TypeName extends Presentation.Model.Name | 'USERS'>():
    | undefined
    | SnapshotTypes[TypeName];
}

class RealtimeObjectStore<
  TypeName extends Presentation.Model.Name | 'USERS',
  T extends SnapshotTypes[TypeName] = SnapshotTypes[TypeName],
> implements IRealtimeObjectStore
{
  protected data: Presentation.API;
  protected type: TypeName;
  protected id: string;
  protected object: Realtime.Core.RealtimeObject | Realtime.Core.DataObject;
  protected snapshot: T | undefined;
  protected listeners: (() => void)[] = [];

  constructor(data: Presentation.API, type: TypeName, id: string) {
    this.data = data;
    this.type = type;
    this.id = id;
    this.handler = this.handler.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.getSnapshot = this.getSnapshot.bind(this);
    if (type === 'USERS') {
      this.object = this.data?.users.users;
    } else {
      this.object = this.data?.models.get(type, id);
    }
    this.getData();
    if (this.object) {
      this.object.on('LOADED', this.handler);
      this.object.on('UPDATED', this.handler);
      this.object.on('UPDATE', this.handler);
    }
  }

  protected getData() {
    this.snapshot = this.object.get([]);
  }

  protected emitSync() {
    const listeners = this.listeners || [];
    for (let index = 0; index < listeners.length; index++) {
      listeners[index]();
    }
  }

  protected handler(data: any) {
    this.getData();
    this.emitSync();
  }

  subscribe(listener: () => void): () => void {
    this.listeners = [...this.listeners, listener];
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  getSnapshot<T extends Presentation.Model.Name | 'USERS'>(): SnapshotTypes[T] {
    return this.snapshot as SnapshotTypes[T];
  }
}

class RealtimeObjectFragmentStore<TypeName extends Presentation.Model.Name | 'USERS'>
  implements IRealtimeObjectFragmentStore
{
  protected data: Presentation.API;
  protected type: TypeName;
  protected id: string;
  protected fragment: string;
  protected object: Realtime.Core.RealtimeObject | Realtime.Core.DataObject;
  protected snapshot: SnapshotTypes[TypeName] | undefined;
  protected listeners: (() => void)[] = [];

  constructor(data: Presentation.API, type: TypeName, id: string, fragment: string) {
    this.data = data;
    this.type = type;
    this.id = id;
    this.fragment = fragment;
    this.loadHandler = this.loadHandler.bind(this);
    this.updatedHandler = this.updatedHandler.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.getSnapshot = this.getSnapshot.bind(this);
    if (type === 'USERS') {
      this.object = this.data?.users.users;
    } else {
      this.object = this.data?.models.get(type, id, this.fragment);
    }
    this.getData();
    if (this.object) {
      this.object.on('LOADED', this.loadHandler);
      this.object.on('UPDATED', this.updatedHandler);
    }
  }

  protected getData() {
    this.snapshot = this.object.get([]);
  }

  getSnapshot<TypeName extends keyof Presentation.Model.Types | 'USERS'>():
    | SnapshotTypes[TypeName]
    | undefined {
    return this.snapshot as SnapshotTypes[TypeName];
  }

  protected emitSync() {
    const listeners = this.listeners || [];
    for (let index = 0; index < listeners.length; index++) {
      listeners[index]();
    }
  }

  protected loadHandler(data: any) {
    this.getData();
    this.emitSync();
  }

  protected updatedHandler(data: any, ops: Realtime.Core.RealtimeOps) {
    this.getData();
    this.emitSync();
  }

  subscribe(listener: () => void): () => void {
    this.listeners = [...this.listeners, listener];
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }
}

class CommentsStore extends RealtimeObjectStore<'COMMENTS'> {
  protected getData() {
    this.snapshot = this.object.getCommentsData();
  }
}
class TasksStore extends RealtimeObjectStore<'TASKS'> {
  protected getData() {
    this.snapshot = this.object.getTasksData();
  }
}

class SlideCommentsStore extends RealtimeObjectFragmentStore<'SLIDE_COMMENTS'> {
  protected getData() {
    this.snapshot = {
      list: this.object.commentIds,
      data: this.object.comments,
    };
  }
}
class SlideTasksStore extends RealtimeObjectFragmentStore<'SLIDE_TASKS'> {
  protected getData() {
    this.snapshot = {
      list: this.object.taskIds,
      data: this.object.tasks,
    };
  }
}

class NullRealtimeObjectStore implements IRealtimeObjectStore {
  protected data: Presentation.API;
  protected listeners: (() => void)[] = [];

  constructor(data: Presentation.API) {
    this.data = data;
    this.subscribe = this.subscribe.bind(this);
    this.getSnapshot = this.getSnapshot.bind(this);
  }

  subscribe(listener: () => void): () => void {
    this.listeners = [...this.listeners, listener];
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  getSnapshot<T extends 'USERS' | keyof Presentation.Model.Types>(): SnapshotTypes[T] | undefined {
    return undefined;
  }
}

export class RealtimeSyncStore {
  protected stores: Record<string, IRealtimeObjectStore | IRealtimeObjectFragmentStore> = {};
  protected data: Presentation.API;
  protected nullStore: NullRealtimeObjectStore;

  constructor(data: Presentation.API) {
    this.data = data;
    this.nullStore = new NullRealtimeObjectStore(this.data);
  }

  getStore(type: Presentation.Model.Name | 'USERS', id?: string) {
    if (id === undefined) {
      return this.nullStore;
    }
    if (!this.stores[id]) {
      if (type === 'COMMENTS') {
        this.stores[id] = new CommentsStore(this.data, type, id);
      } else if (type === 'TASKS') {
        this.stores[id] = new TasksStore(this.data, type, id);
      } else {
        this.stores[id] = new RealtimeObjectStore(this.data, type, id);
      }
    }
    return this.stores[id];
  }

  getFragmentStore<N extends Presentation.Model.Name | 'USERS'>(
    type: N,
    id?: string,
    ...args: any
  ) {
    if (id === undefined || args[0] === undefined) {
      return this.nullStore;
    }
    const fragmentKey = `${id}::${args[0]}`;
    if (!this.stores[fragmentKey]) {
      if (type === 'SLIDE_COMMENTS') {
        this.stores[fragmentKey] = new SlideCommentsStore(this.data, type, id, args[0]);
      } else if (type === 'SLIDE_TASKS') {
        this.stores[fragmentKey] = new SlideTasksStore(this.data, type, id, args[0]);
      } else {
        this.stores[fragmentKey] = new RealtimeObjectFragmentStore(this.data, type, id, args[0]);
      }
    }
    return this.stores[fragmentKey];
  }
}
