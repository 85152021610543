import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Popover, Toggle, Tooltip, usePopper } from 'dodoc-design-system';
import cx from 'classnames';

import { useDispatch, useSelector } from '_common/hooks';
import { selectCurrentAnnotation, setCreationSetting } from 'PDF/redux/PDFAnnotationsSlice';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { usePDFContext } from 'PDF/PDFContext';
import { COLORS_HEX_MAP } from 'PDF/services';

import styles from './Toggles.module.scss';

export const COLORS = ['none', 'black', 'yellow', 'green', 'red', 'blue'] as const;

type ColorDropdownProps = {
  property: 'stroke' | 'fill';
  disabled: boolean;
  testId: string;
};

const ColorDropdown = ({ property, disabled, testId }: ColorDropdownProps) => {
  const manager = usePDFContext();
  const intl = useIntl();
  const { referenceProps, popperProps, isOpen, close } = usePopper({ disabled });
  const shapeColor = useSelector((state) => state.pdf.annotations.creation[property]);
  const selectedAnnotation = useSelector(selectCurrentAnnotation);
  const cursorMode = useSelector((state) => state.pdf.general.cursorMode);
  const dispatch = useDispatch();
  const { canCreateAnnotation, canEditAnnotation } = usePDFPermissions();

  const setColor = (color: (typeof COLORS)[number]) => {
    if (selectedAnnotation) {
      const newColor = color === 'none' ? undefined : COLORS_HEX_MAP[color];
      manager.editAnnotationColor(selectedAnnotation.pageNumber, selectedAnnotation.id, {
        ...selectedAnnotation.color,
        [property]: newColor,
      });
    } else {
      dispatch(
        setCreationSetting({
          [property]: color,
        }),
      );
      close();
    }
  };

  const icon = property === 'stroke' ? 'Stroke' : 'Fill';

  const iconColor = useMemo(() => {
    if (selectedAnnotation) {
      if (property === 'fill' && selectedAnnotation.subtype === 'Ink') {
        return 'none';
      }
      if (
        selectedAnnotation.subtype === 'Ink' ||
        selectedAnnotation.subtype === 'Line' ||
        selectedAnnotation.subtype === 'Square' ||
        selectedAnnotation.subtype === 'Circle' ||
        selectedAnnotation.subtype === 'FreeText'
      ) {
        return selectedAnnotation.color?.[property] ?? 'none';
      }
    }
    return COLORS_HEX_MAP[shapeColor] ?? 'none';
  }, [selectedAnnotation, shapeColor]);

  const tooltipContent = useMemo(() => {
    if (!disabled) {
      if (property === 'fill') {
        return intl.formatMessage({ id: 'FILL_COLOR' });
      }
      if (property === 'stroke') {
        return intl.formatMessage({ id: 'STROKE_COLOR' });
      }
    }

    const isROIType = (['Highlight', 'Underline', 'StrikeOut'] as PDF.Annotation.Type[]).some(
      (type) => type === selectedAnnotation?.subtype || type === cursorMode,
    );

    if (!canCreateAnnotation || !!(selectedAnnotation && !canEditAnnotation(selectedAnnotation))) {
      return intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' });
    }
    if (
      ((cursorMode === 'Ink' || selectedAnnotation?.subtype === 'Ink') && property === 'fill') ||
      isROIType
    ) {
      return intl.formatMessage({ id: 'CANNOT_APPLY_PROPERTY_IN_THE_SELECTED_SHAPE' });
    }

    return '';
  }, [disabled, canCreateAnnotation, cursorMode, property, selectedAnnotation]);

  return (
    <>
      <Tooltip
        placement="bottom"
        disabled={popperProps.isOpen}
        content={tooltipContent}
        testId={`${testId}-tooltip`}
      >
        <Toggle
          size="medium"
          variant="ghost"
          dropdown
          margin="0 0.75rem 0 0"
          isToggled={isOpen}
          icon={popperProps.isOpen ? `${icon}Blue` : icon}
          iconColor={iconColor}
          disabled={disabled}
          {...referenceProps}
          testId={`${testId}-toggle`}
        />
      </Tooltip>
      <Popover {...popperProps} testId="color-popper">
        <div className={styles.popover}>
          {COLORS.map((color) => (
            <div
              key={color}
              className={cx(styles.item, {
                [styles.isSelected]:
                  iconColor === color || (color !== 'none' && iconColor === COLORS_HEX_MAP[color]),
              })}
              onClick={() => setColor(color)}
            >
              <span className={styles.selection}>
                <Icon size={16} icon="Check" />
              </span>
              <span className={cx(styles.color, styles[color])} />
              <span className={styles.text}>
                <FormattedMessage id={color.toUpperCase()} />
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default ColorDropdown;
