import { CSSProperties, ReactNode } from 'react';
import cx from 'classnames';

import { useSuiteApp } from '_common/suite/SuiteContext/SuiteContext';

import styles from './PanelContent.module.scss';

type PanelContentProps = {
  padding?: CSSProperties['padding'];
  testId: string;
  children: ReactNode;
};

const PanelContent = ({ padding, testId, children }: PanelContentProps) => {
  const suiteApp = useSuiteApp();

  return (
    <div
      style={{ padding }}
      className={cx(styles.panelContent, { [styles.editor]: suiteApp === 'document' })}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

export default PanelContent;
