import { useEffect } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToMyFiles } from 'router/history';

import { setCurrentAppPage } from 'App/redux/appSlice';
import { selectUserIsAdmin } from '_common/services/api/authority';
import {
  selectActiveMenuOption,
  openSidebarMenu,
  closeSidebarMenu,
} from '_common/components/Sidebar/SidebarSlice';

import { ErrorView, InteractionController, IntlErrorBoundary } from '_common/components';

import Templates from './Templates/Templates';
import Affiliations from './Affiliations/Affiliations';
import Tags from './Tags/Tags';
import Roles from './Roles/Roles';
import ElementStatus from './ElementStatus/ElementStatus';
import Users from './Users/Users';
import ReferencesStyles from './ReferencesStyles/ReferencesStyles';
import General from './General/General';

import styles from './TenantSettingsPage.module.scss';
import Metadata from './Metadata/Metadata';

const TenantSettingsPage = () => {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.app.error);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const activeMenuOption = useSelector(selectActiveMenuOption);

  useEffect(() => {
    dispatch(setCurrentAppPage('/settings'));
    dispatch(openSidebarMenu({ menu: 'tenantSettings' }));

    return () => {
      dispatch(closeSidebarMenu());
    };
  }, []);

  const checkErrorStatus = () => {
    return error.status === 400 || error.status === 403 || error.status === 404;
  };

  if (!userIsAdmin) {
    return <ErrorView error={{ status: 403 }} onClick={navigateToMyFiles} />;
  }

  // check for forbidden view
  if (checkErrorStatus()) {
    return <ErrorView error={error} onClick={navigateToMyFiles} />;
  }

  const renderView = (option: typeof activeMenuOption) => {
    switch (option) {
      case 'general':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="General" size="large" margin="21rem 0 0 0">
              <General />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'users':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column', height: '100%' }}
          >
            <IntlErrorBoundary key="Users" size="large" margin="21rem 0 0 0">
              <Users />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'roles':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="Roles" size="large" margin="21rem 0 0 0">
              <Roles />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'tags':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="Tags" size="large" margin="21rem 0 0 0">
              <Tags />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'elementStatus':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="ElementStatus" size="large" margin="21rem 0 0 0">
              <ElementStatus />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'templates':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="Templates" size="large" margin="21rem 0 0 0">
              <Templates />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'referencesStyles':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="ReferencesStyles" size="large" margin="21rem 0 0 0">
              <ReferencesStyles />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'affiliations':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="Affiliations" size="large" margin="21rem 0 0 0">
              <Affiliations />
            </IntlErrorBoundary>
          </InteractionController>
        );
      case 'metadata':
        return (
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <IntlErrorBoundary key="Metadata" size="large" margin="21rem 0 0 0">
              <Metadata />
            </IntlErrorBoundary>
          </InteractionController>
        );
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.page}>{renderView(activeMenuOption)}</div>
    </div>
  );
};

export default TenantSettingsPage;
