import { useIntl } from 'react-intl';

import { useGetReferenceStylesListQuery } from './ReferenceStylesApi';

import { ErrorState, Loading } from 'Settings/components';
import RefStyleSection from './RefStyleSection/RefStyleSection';

import styles from './ReferencesStyles.module.scss';

const ReferencesStyles = () => {
  const intl = useIntl();

  const { isLoading, categories, isError } = useGetReferenceStylesListQuery(undefined, {
    selectFromResult: (result) => {
      const { order, extensions } = result.data || {
        order: [],
        extensions: {},
      };

      return {
        ...result,
        categories: Object.keys(
          order.reduce((indexedCat, index) => {
            indexedCat[extensions[index].category] = true;
            return indexedCat;
          }, {} as Record<Template['category'], boolean>),
        ),
      };
    },
  });

  if (isLoading) {
    return (
      <div data-testid="loading">
        <Loading
          label={intl.formatMessage({ id: 'LOADING_REFERENCES_STYLES' })}
          testId="loading-references-styles"
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div data-testid="errorState" style={{ marginTop: '5rem' }}>
        <ErrorState text="REFERENCES_STYLES_LIST_ERROR" testId="references-style-error-helper" />
      </div>
    );
  }

  return (
    <div className={styles.referencesStyles}>
      {categories.map((category) => (
        <RefStyleSection key={category} category={category} testId={category} />
      ))}
    </div>
  );
};

export default ReferencesStyles;
