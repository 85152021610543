import { path } from 'd3';
import { constantToRad, ellipseArcTo, mod, pin } from './utils';
import { SHAPE_CONST } from './consts';

type EquationProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type: 'mathPlus' | 'mathMinus' | 'mathMultiply' | 'mathDivide' | 'mathEqual' | 'mathNotEqual';
};

const getAdjstByType = (type: EquationProps['type']) => {
  switch (type) {
    case 'mathPlus':
    case 'mathMinus':
    case 'mathMultiply':
      return {
        adj1: 23520,
        adj2: 0,
        adj3: 0,
      };
    case 'mathDivide':
      return {
        adj1: 23520,
        adj2: 5880,
        adj3: 11760,
      };
    case 'mathEqual':
      return {
        adj1: 23520,
        adj2: 11760,
        adj3: 0,
      };
    case 'mathNotEqual':
      return {
        adj1: 23520,
        adj2: 6600000,
        adj3: 11760,
      };
  }
};

const generateEquationPath = ({
  size,
  type,
  adjst,
}: EquationProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;

  /** Shortest Side */
  const ss = Math.min(w, h);

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;
  const adj3 = adjst?.adj3 ? +adjst.adj3 : defaultAdjLst.adj3;

  switch (type) {
    case 'mathPlus': {
      const a1 = pin(0, adj1, 73490);
      const dx1 = (w * 73490) / 200000;
      const dy1 = (h * 73490) / 200000;
      const dx2 = (ss * a1) / 200000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = vc - dy1;
      const y2 = vc - dx2;
      const y3 = vc + dx2;
      const y4 = vc + dy1;

      const d = path();
      d.moveTo(x1, y2);
      d.lineTo(x2, y2);
      d.lineTo(x2, y1);
      d.lineTo(x3, y1);
      d.lineTo(x3, y2);
      d.lineTo(x4, y2);
      d.lineTo(x4, y3);
      d.lineTo(x3, y3);
      d.lineTo(x3, y4);
      d.lineTo(x2, y4);
      d.lineTo(x2, y3);
      d.lineTo(x1, y3);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y2, r: x4, b: y3 } };
    }
    case 'mathMinus': {
      const a1 = pin(0, adj1, 100000);
      const dy1 = (h * a1) / 200000;
      const dx1 = (w * 73490) / 200000;
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const x1 = hc - dx1;
      const x2 = hc + dx1;

      const d = path();
      d.moveTo(x1, y1);
      d.lineTo(x2, y1);
      d.lineTo(x2, y2);
      d.lineTo(x1, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x2, b: y2 } };
    }
    case 'mathMultiply': {
      const a1 = pin(0, adj1, 51965);
      const th = (ss * a1) / 100000;
      const a = Math.atan(h / w);
      const sa = Math.sin(a);
      const ca = Math.cos(a);
      const ta = Math.tan(a);
      const dl = mod(w, h, 0);
      const rw = (dl * 51965) / 100000;
      const lM = dl - rw;
      const xM = (ca * lM) / 2;
      const yM = (sa * lM) / 2;
      const dxAM = (sa * th) / 2;
      const dyAM = (ca * th) / 2;
      const xA = xM - dxAM;
      const yA = yM + dyAM;
      const xB = xM + dxAM;
      const yB = yM - dyAM;
      const xBC = hc - xB;
      const yBC = (xBC * ta) / 1;
      const yC = yBC + yB;
      const xD = r - xB;
      const xE = r - xA;
      const yFE = vc - yA;
      const xFE = (yFE * 1) / ta;
      const xF = xE - xFE;
      const xL = xA + xFE;
      const yG = b - yA;
      const yH = b - yB;
      const yI = b - yC;

      const d = path();
      d.moveTo(xA, yA);
      d.lineTo(xB, yB);
      d.lineTo(hc, yC);
      d.lineTo(xD, yB);
      d.lineTo(xE, yA);
      d.lineTo(xF, vc);
      d.lineTo(xE, yG);
      d.lineTo(xD, yH);
      d.lineTo(hc, yI);
      d.lineTo(xB, yH);
      d.lineTo(xA, yG);
      d.lineTo(xL, vc);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: xA, t: yB, r: xE, b: yH } };
    }
    case 'mathDivide': {
      const a1 = pin(1000, adj1, 36745);
      const ma1 = -a1;
      const ma3h = (73490 + ma1) / 4;
      const ma3w = (36745 * w) / h;
      const maxAdj3 = Math.min(ma3h, ma3w);
      const a3 = pin(1000, adj3, maxAdj3);
      const m4a3 = -4 * a3;
      const maxAdj2 = 73490 + m4a3 - a1;
      const a2 = pin(0, adj2, maxAdj2);
      const dy1 = (h * a1) / 200000;
      const yg = (h * a2) / 100000;
      const rad = (h * a3) / 100000;
      const dx1 = (w * 73490) / 200000;
      const y3 = vc - dy1;
      const y4 = vc + dy1;
      const a = yg + rad;
      const y2 = y3 - a;
      const y1 = y2 - rad;
      const y5 = b - y1;
      const x1 = hc - dx1;
      const x3 = hc + dx1;

      const dMain = path();
      dMain.moveTo(hc, y1);
      ellipseArcTo(dMain, rad, rad, '3cd4', 360, hc, y1);
      dMain.moveTo(hc, y5);
      ellipseArcTo(dMain, rad, rad, 'cd4', 360, hc, y5);
      dMain.moveTo(x1, y3);
      dMain.lineTo(x3, y3);
      dMain.lineTo(x3, y4);
      dMain.lineTo(x1, y4);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: x1, t: y3, r: x3, b: y4 },
      };
    }
    case 'mathEqual': {
      const a1 = pin(0, adj1, 36745);
      const doubleA1 = a1 * 2;
      const mAdj2 = 100000 - doubleA1;
      const a2 = pin(0, adj2, mAdj2);
      const dy1 = (h * a1) / 100000;
      const dy2 = (h * a2) / 200000;
      const dx1 = (w * 73490) / 200000;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y1 = y2 - dy1;
      const y4 = y3 + dy1;
      const x1 = hc - dx1;
      const x2 = hc + dx1;

      const d = path();
      d.moveTo(x1, y1);
      d.lineTo(x2, y1);
      d.lineTo(x2, y2);
      d.lineTo(x1, y2);
      d.closePath();
      d.moveTo(x1, y3);
      d.lineTo(x2, y3);
      d.lineTo(x2, y4);
      d.lineTo(x1, y4);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x2, b: y4 } };
    }
    case 'mathNotEqual': {
      const a1 = pin(0, adj1, 50000);
      const crAng = constantToRad(pin(4200000, adj2, 6600000));
      const doubleA1 = a1 * 2;
      const maxAdj3 = 100000 - doubleA1;
      const a3 = pin(0, adj3, maxAdj3);
      const dy1 = (h * a1) / 100000;
      const dy2 = (h * a3) / 200000;
      const dx1 = (w * 73490) / 200000;
      const x1 = hc - dx1;
      const x8 = hc + dx1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y1 = y2 - dy1;
      const y4 = y3 + dy1;
      const cadj2 = crAng - SHAPE_CONST.cd4;
      const xadj2 = hd2 * Math.tan(cadj2);
      const len = mod(xadj2, hd2, 0);
      const bhw = (len * dy1) / hd2;
      const bhw2 = (bhw * 1) / 2;
      const x7 = hc + xadj2 - bhw2;
      const dx67 = (xadj2 * y1) / hd2;
      const x6 = x7 - dx67;
      const dx57 = (xadj2 * y2) / hd2;
      const x5 = x7 - dx57;
      const dx47 = (xadj2 * y3) / hd2;
      const x4 = x7 - dx47;
      const dx37 = (xadj2 * y4) / hd2;
      const x3 = x7 - dx37;
      const rx7 = x7 + bhw;
      const rx6 = x6 + bhw;
      const rx5 = x5 + bhw;
      const rx4 = x4 + bhw;
      const rx3 = x3 + bhw;
      const dx7 = (dy1 * hd2) / len;
      const rxt = x7 + dx7;
      const lxt = rx7 - dx7;
      const rx = cadj2 > 0 ? rxt : rx7;
      const lx = cadj2 > 0 ? x7 : lxt;
      const dy3 = (dy1 * xadj2) / len;
      const dy4 = 0 - dy3;
      const ry = cadj2 > 0 ? dy3 : t;
      const ly = cadj2 > 0 ? t : dy4;
      const dlx = w - rx;
      const drx = w - lx;
      const dly = h - ry;
      const dry = h - ly;

      const d = path();
      d.moveTo(x1, y1);
      d.lineTo(x6, y1);
      d.lineTo(lx, ly);
      d.lineTo(rx, ry);
      d.lineTo(rx6, y1);
      d.lineTo(x8, y1);
      d.lineTo(x8, y2);
      d.lineTo(rx5, y2);
      d.lineTo(rx4, y3);
      d.lineTo(x8, y3);
      d.lineTo(x8, y4);
      d.lineTo(rx3, y4);
      d.lineTo(drx, dry);
      d.lineTo(dlx, dly);
      d.lineTo(x3, y4);
      d.lineTo(x1, y4);
      d.lineTo(x1, y3);
      d.lineTo(x4, y3);
      d.lineTo(x5, y2);
      d.lineTo(x1, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x8, b: y4 } };
    }
  }
};

export default generateEquationPath;
