import { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Button, Divider, InputField, Spinner } from 'dodoc-design-system';

import { updateData } from 'App/redux/appSlice';
import {
  addAuthor,
  getDocumentInfo,
  getDocumentObject,
  selectReadOnlyMode,
} from 'Editor/redux/EditorStatusSlice';
import { useGetAffiliationsListQuery } from 'Settings/pages/TenantSettingsPage/Affiliations/AffiliationsApi';

import Author from './Author/Author';
import KeywordSystem from './KeywordSystem/KeywordSystem';
import DetailsItem from '../DetailsItem/DetailsItem';

import styles from './Authoring.module.scss';

const Authoring = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = useSelector(getDocumentObject);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);

  const [loading, setLoading] = useState(true);
  const template = object.template;
  useGetAffiliationsListQuery();
  useEffect(() => {
    dispatch(getDocumentInfo({ objectId: object.id, open: false })).then(() => {
      setLoading(false);
      dispatch(updateData({ objectId: object.id, data: { template } }));
    });
  }, []);

  const handleAddAuthor = () => {
    dispatch(addAuthor({ documentId: object.id }));
  };

  const renderAuthorsList = () => {
    const authors: Author[] = object.authors;

    if (authors && authors.length > 0) {
      return authors.map((author, index) => (
        <Author
          key={`authoring-author-${author.id}`}
          isLastAuthor={index !== authors.length - 1}
          main={index === 0}
          authorIndex={index}
          author={author}
        />
      ));
    }

    return (
      <div className={styles.emptyAuthors}>
        <span className={styles.title}>
          <FormattedMessage id="MODAL_EDIT_AUTHORS_EMPTY_TITLE" />
        </span>
        <span className={styles.subtitle}>
          <FormattedMessage id="MODAL_EDIT_AUTHORS_EMPTY_SUBTITLE" />
        </span>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {loading ? (
        <div className={styles.loading}>
          <Spinner size="medium" testId="authoring-loading-information" />
          <span className={styles.loadingText}>
            <FormattedMessage id="LOADING_INFORMATION" />
          </span>
        </div>
      ) : (
        <>
          <div className={styles.detailsContainer}>
            <DetailsItem
              isTitle
              label={intl.formatMessage({ id: 'DOCUMENT_AUTHORS' })}
              labelContainerStyle={{ alignItems: 'center', padding: '0' }}
              extra={
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleAddAuthor}
                  disabled={isReadOnlyMode}
                  testId="add-author-button"
                >
                  <FormattedMessage id="AUTHORING_ADD_AUTHOR" />
                </Button>
              }
            />
          </div>
          <Divider margin="1rem 0 0 0" />
          {renderAuthorsList()}
          <div className={styles.detailsContainer}>
            <DetailsItem
              isTitle
              label={intl.formatMessage({ id: 'ADDITIONAL_DETAILS' })}
              labelContainerStyle={{ alignItems: 'center', padding: '0' }}
            />
          </div>
          <Divider margin="1rem 0 0 0" />

          <div className={styles.keywords}>
            <InputField
              size="large"
              label={intl.formatMessage({ id: 'EDITOR_DOCUMENT_KEYWORDS' })}
              testId="keywords-field"
            >
              <KeywordSystem />
            </InputField>
          </div>
        </>
      )}
    </div>
  );
};

export default Authoring;
