/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { DoDocObject } from './AbstractDocument';

export class PresentationDocument extends DoDocObject {
  protected getObjectType(): Realtime.Core.Document.DocumentType {
    return 'presentation';
  }

  get versions() {
    return this.data?.lifecycle?.versions || [];
  }
}
