import { useMemo } from 'react';
import { navigateTo } from 'router/history';

import { useSelector } from '_common/hooks';
import { usePresentationManager } from 'Presentation/PresentationManager';
import useTextProperties from '../hooks/useTextProperties';

import TextChild from './TextChild';

type LinkRunProps = {
  paragraph: Presentation.Data.ParagraphShape;
  run: Presentation.Data.Run;
  clickLink?: Presentation.Data.Hyperlink;
  hoverLink?: Presentation.Data.Hyperlink;
};

const ALLOWED_ACTIONS = ['hlinkshowjump', 'hlinksldjump'] as const;
type AllowedAction = (typeof ALLOWED_ACTIONS)[number];
const ALLOWED_JUMPS = [
  'nextslide',
  'previousslide',
  'firstslide',
  'lastslide',
  'lastslideviewed',
] as const;
type AllowedJump = (typeof ALLOWED_JUMPS)[number];

type LinkAction =
  | {
      action: AllowedJump;
      targetSlide?: never;
      href?: never;
      to?: never;
      subject?: never;
    }
  | {
      action: 'hlinksldjump';
      targetSlide: string;
      href?: never;
      to?: never;
      subject?: never;
    }
  | {
      action: 'mailTo';
      to: string;
      subject: string;
      targetSlide?: never;
      href?: never;
    }
  | {
      href: string;
      action?: never;
      targetSlide?: never;
      to?: never;
      subject?: never;
    };

/** ### Visual (Render but do nothing)
 *
 * [Other File]
 * Action: ppaction://hlinkpres?slideindex=1&slidetitle=
 * Href: file:///C:\\Users\\pereirar\\Desktop\\Shapes\\Basic.pptx
 *
 * [Custom show]
 * Action: ppaction://customshow?id=0&return=true
 *
 * [Mail to]
 * Href: mailto:someone@somewhere.com?subject=PPT%20email%20to
 *
 * [End show]
 * Action: ppaction://hlinkshowjump?jump=endshow
 *
 * [Open PPT OR Other file]
 * Action: ppaction://hlinkpres?slideindex=1&slidetitle=PowerPoint Presentation
 * href: 'file:///PATH\\FILENAME.pptx#-1,1,PowerPoint Presentation'
 *
 * [Run program]
 * Action: ppaction://program
 * href: 'file:///PATH\\FILENAME.exe'
 *
 * [Play sound]
 * Action: ppaction://noaction
 * {
 *   "type": "wavAudio",
 *   "os": "65ddf3a40235498bc9b63483",
 *   "name": "arrow.wav"
 * }
 *
 * ### Functional (Render and have functionality)
 *
 * [Next Slide]
 * Action: ppaction://hlinkshowjump?jump=nextslide
 *
 * [Previous Slide]
 * Action: ppaction://hlinkshowjump?jump=previousslide
 *
 * [First Slide]
 * Action: ppaction://hlinkshowjump?jump=firstslide
 *
 * [Last Slide]
 * Action: ppaction://hlinkshowjump?jump=lastslide
 *
 * [Last Viewed Slide]
 * Action: ppaction://hlinkshowjump?jump=lastslideviewed
 *
 * [Slide]
 * Action: ppaction://hlinksldjump
 * Href: slide3.xml
 *
 * [URL]
 * Href: http://google.com/
 */
const LinkRun = ({ paragraph, run, clickLink, hoverLink }: LinkRunProps) => {
  const manager = usePresentationManager();
  const { getTextStyle } = useTextProperties();

  const style = getTextStyle({ paragraph, run });

  const lastSlideViewed = useSelector((state) => state.presentation.general.lastSlideViewed);

  const { clickAction, hoverAction } = useMemo(() => {
    const parseLinkAction = (link?: Presentation.Data.Hyperlink): LinkAction | null => {
      //If no link is provided, do nothing
      if (!link) {
        return null;
      }

      //If no action is provided, it is assumed to be a regular link
      if (!link.action) {
        //If no href is provided, do nothing
        if (!link.href) {
          return null;
        }

        const target = link.href.target;

        /**
         * Parse the mailTo href as an URL
         * Example: mailto:someone@somewhere.com?subject=PPT%20email%20to
         *  - protocol: 'mailto:'
         *  - action: 'someone@somewhere.com'
         *  - properties: { subject: 'PPT email to' }
         */
        if (target.startsWith('mailto:')) {
          const parsedMailTo = new URL(target);
          return {
            to: parsedMailTo.pathname,
            subject: parsedMailTo.searchParams.get('subject') ?? '',
            action: 'mailTo',
          };
        }

        return { href: target };
      }

      /**
       * Parse the link action as an URL
       * Example: ppaction://hlinkshowjump?jump=nextslide
       *  - protocol: 'ppaction:'
       *  - action: '//hlinkshowjump'
       *  - properties: { jump: 'nextslide' }
       */
      const parsedUrl = new URL(link.action);

      //Only allow ppaction link actions
      if (parsedUrl.protocol !== 'ppaction:') {
        return null;
      }

      const action = parsedUrl.pathname.replaceAll('/', '');
      //If action is not allowed, do nothing
      if (!ALLOWED_ACTIONS.includes(action as AllowedAction)) {
        return null;
      }

      //Parse the properties of the link
      const parameters: Record<string, string> = {};
      parsedUrl.searchParams.forEach((value, key) => {
        parameters[key] = value;
      });

      const typedAction = action as AllowedAction;

      switch (typedAction) {
        case 'hlinkshowjump':
          //If manager's navigation isn't initialized OR If jump action is not allowed, do nothing
          if (!manager.navigation || !ALLOWED_JUMPS.includes(parameters.jump as AllowedJump)) {
            return null;
          }
          return { action: parameters.jump as AllowedJump };
        case 'hlinksldjump':
          //Extract slide id from href
          const slideId = link.href?.target;

          //If no slide id is provided, do nothing
          if (!slideId) {
            return null;
          }

          return { action: 'hlinksldjump', targetSlide: slideId };
      }
    };

    return {
      clickAction: parseLinkAction(clickLink),
      hoverAction: parseLinkAction(hoverLink),
    };
  }, [clickLink, hoverLink]);

  const handleLinkAction = (linkAction: LinkAction | null) => {
    if (!linkAction) {
      return;
    }
    if (linkAction.action) {
      switch (linkAction.action) {
        case 'nextslide':
          manager.navigation?.next();
          break;
        case 'previousslide':
          manager.navigation?.previous();
          break;
        case 'firstslide':
          manager.navigation?.goToFirstSlide();
          break;
        case 'lastslide':
          manager.navigation?.goToLastSlide();
          break;
        case 'lastslideviewed':
          //lastSlideViewed can be null when in the initial slide
          if (lastSlideViewed) {
            manager.navigation?.goTo(lastSlideViewed);
          }
          break;
        case 'hlinksldjump': {
          manager.navigation?.goToSlideId(linkAction.targetSlide);
          break;
        }
        //Inactive actions
        case 'mailTo':
          break;
      }
    } else if (linkAction.href) {
      //If no action and href is provided, navigate to the href
      navigateTo(linkAction.href, true);
    }
  };

  const handleClick = () => handleLinkAction(clickAction);
  const handleHover = () => handleLinkAction(hoverAction);

  return (
    <span style={style} data-type="run" onClick={handleClick} onMouseOver={handleHover}>
      {run?.childNodes?.map((text, index) => {
        return <TextChild key={index} text={text} />;
      })}
    </span>
  );
};

export default LinkRun;
