/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import BaseController from '../BaseController';

class SpellcheckCycle {
  ignoredWords: any = {};

  ignoreAllOccurences(term: string, lang: string) {
    if (!this.ignoredWords[lang]) {
      this.ignoredWords[lang] = [];
    }
    this.ignoredWords[lang].push(term);
  }
}

export class SpellcheckController extends BaseController {
  static DEFAULT_LANGUAGE = 'en-us';

  languages: Services.Language[];
  sCycle: SpellcheckCycle | null = null;

  constructor(Data: Editor.Data.State) {
    super(Data);
    this.languages = [];
  }

  start(): void {
    this.fetchLanguages().then((languages) => {
      this.Data.events?.emit('LOAD_LANGUAGES', {
        languages,
        defaultLanguage: SpellcheckController.DEFAULT_LANGUAGE,
      });
    });
  }

  stop(): void {}

  destroy(): void {}

  getDefaultLanguage() {
    return SpellcheckController.DEFAULT_LANGUAGE;
  }

  private fetchLanguages(): Promise<Services.Language[]> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'GET:SPELLCHECK:LANGUAGES',
        {
          document: this.Data.context?.document?.id,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            this.languages = response.payload as Services.Language[];
            resolve(this.languages);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  endSpellcheckCycle() {
    this.sCycle = null;
  }

  nextSpellcheckHit(options: any, position: any, fromStart: boolean = false): Promise<any> {
    if (!this.sCycle || fromStart) {
      this.sCycle = new SpellcheckCycle();
    }
    if (!options) {
      options = {};
    }
    options.ignore = this.sCycle.ignoredWords;
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENT:NEXT:SPELLCHECK',
        {
          document: this.Data.context?.document?.id,
          options,
          position,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  previousSpellcheckHit(options: any, position: any): Promise<any> {
    if (!this.sCycle) {
      this.sCycle = new SpellcheckCycle();
    }
    if (!options) {
      options = {};
    }
    options.ignore = this.sCycle.ignoredWords;
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENT:PREVIOUS:SPELLCHECK',
        {
          document: this.Data.context?.document?.id,
          options,
          position,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  ignoreAllOccurences(term: string, lang: string = 'en-us'): Promise<any> {
    if (!this.sCycle) {
      this.sCycle = new SpellcheckCycle();
    }
    this.sCycle.ignoreAllOccurences(term, lang);
    return Promise.resolve();
    // return new Promise((resolve, reject) => {
    //   this.Data.transport.dispatchEvent(
    //     'DOCUMENT:IGNORE:ALL:OCCURENCES',
    //     {
    //       document: this.Data.context.document.id,
    //       term: term,
    //       lang,
    //     },
    //     (response: RealtimeEventResponse) => {
    //       if (response.success) {
    //         resolve(response.payload);
    //       } else {
    //         reject(response.error);
    //       }
    //     },
    //   );
    // });
  }

  changeAllOccurences(term: string, toTerm: string, suggesting: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENT:ALL:OCCURENCES:SPELLCHECK',
        {
          document: this.Data.context?.document?.id,
          wordToReplace: term,
          suggestionToReplace: toTerm,
          suggesting,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  changeOccurence(occurence: any, newTerm: string, suggesting: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'CHANGE:OCCURENCE:SPELLCHECK',
        {
          document: this.Data.context?.document?.id,
          occurence: {
            word: occurence.word,
            location: occurence.location,
          },
          newTerm,
          suggesting,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  addWordToDictionary(term: string, lang: string = 'en-us'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'ADD:WORD:SPELLCHECK',
        {
          document: this.Data.context?.document?.id,
          word: term,
          lang,
        },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}
