import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Divider } from 'dodoc-design-system';

import { useSuiteObject, useSuitePermissions } from '_common/suite';
import VersionCard, { VersionCardProps } from './PanelVersionCard';
import PanelHeading from '../PanelHeading/PanelHeading';

import styles from './VersionHistory.module.scss';

export type VersionHistoryProps = {
  /**
   * Determines if version history supports original version card or not
   */
  supportOriginalVersion?: boolean;
  onVersionHistoryChange: (value: boolean) => void;
  onLoadVersion: (index: number | null) => void;
  onDownloadOriginal?: () => void;
} & Pick<VersionCardProps, 'loadedVersion' | 'onRestoreClick' | 'onEditDescription'>;

const VersionHistory = ({
  loadedVersion,
  supportOriginalVersion,
  onVersionHistoryChange,
  onLoadVersion,
  onDownloadOriginal,
  onRestoreClick,
  onEditDescription,
}: VersionHistoryProps) => {
  const { canAccessVersionHistory } = useSuitePermissions();
  const object = useSuiteObject();
  const isOwner =
    object?.user_permissions.includes('owner') || object?.user_permissions.includes('admin');

  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  const versions = object.lifecycle.versions;

  const sortedVersions = useMemo(() => {
    if (versions) {
      return [...versions].reverse();
    }
  }, [versions]);

  useEffect(() => {
    if (canAccessVersionHistory) {
      onVersionHistoryChange(true);
    }

    return () => {
      //Load current version and exit version history mode
      onLoadVersion(null);
      onVersionHistoryChange(false);
    };
  }, [canAccessVersionHistory]);

  useEffect(() => {
    if (ref.current) {
      setIsOverflowed(ref.current.clientHeight < ref.current.scrollHeight);
    }
  }, [versions]);

  const handleDownloadOriginalFile = () => {
    if (object) {
      onDownloadOriginal?.();
    }
  };

  if (!canAccessVersionHistory) {
    return null;
  }

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="VERSIONS_PANEL_TITLE" />
      </PanelHeading>
      {isOwner && onDownloadOriginal && (
        <div className={styles.downloadOriginal}>
          <Button
            size="small"
            fullWidth
            onClick={handleDownloadOriginalFile}
            testId="version-history-panel-download-original-file-button"
          >
            <FormattedMessage id="DOWNLOAD_ORIGINAL_FILE" />
          </Button>
        </div>
      )}
      <div className={styles.root} ref={ref}>
        <div className={styles.history} style={{ height: isOverflowed ? 'auto' : '100%' }}>
          <div>
            <Divider vertical margin="0 3rem 0 2rem" />
          </div>
          <div className={styles.list}>
            <VersionCard
              currentVersion
              loadedVersion={loadedVersion}
              onLoadVersion={onLoadVersion}
              onRestoreClick={onRestoreClick}
              onEditDescription={onEditDescription}
              supportOriginalVersion={supportOriginalVersion}
            />
            {sortedVersions?.map((version, i) => {
              return (
                <VersionCard
                  key={i}
                  versionIndex={i}
                  version={version}
                  loadedVersion={loadedVersion}
                  /**
                   * If the version history supports original version card
                   * and its the first version (last version because its reversed),
                   * then its the original version
                   */
                  originalVersion={supportOriginalVersion && i === sortedVersions.length - 1}
                  supportOriginalVersion={supportOriginalVersion}
                  onLoadVersion={onLoadVersion}
                  onRestoreClick={onRestoreClick}
                  onEditDescription={onEditDescription}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VersionHistory;
