import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, usePopper, Toggle } from 'dodoc-design-system';

import { useSelector, useDispatch } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { getOutlineList } from 'Editor/redux/listStylesSlice';
import { selectedMultilevelList } from 'Editor/redux/ToolbarSlice';
import PreviewLists from 'Editor/components/PreviewLists/PreviewLists';

import styles from './OutlineList.module.scss';

const OutlineList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selected = useSelector((state) => selectedMultilevelList(state));
  const outlineStyles = useSelector((state) => getOutlineList(state));
  const selectedList = useSelector((state) => state.editor.toolbar.selectedList);
  const isReadOnlyMode = useSelector((state) => selectReadOnlyMode(state));
  const isPageLayout = useSelector(selectIsPageLayout);
  const { isOpen, close, referenceProps, popperProps } = usePopper({
    disabled: isReadOnlyMode,
    placement: 'bottom-end',
  });
  const handleSelect = (element: string) => {
    const manager = EditorManager.getInstance();
    if (element === selectedList) {
      manager.toggleList(false);
    } else {
      manager.toggleList(element);
    }
  };

  const handleListAction = (customize: boolean) =>
    dispatch(
      openAndUpdateModal({
        modal: 'NewListModal',
        data: {
          customize,
          type: 'outline_numbered',
        },
      }),
    );

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={selected || isOpen}
        icon="OutlineNumberedList"
        dropdown
        disabled={isReadOnlyMode || isPageLayout}
        testId="outline-list-toggle"
        {...referenceProps}
      />

      <Dropdown
        {...popperProps}
        width={Object.keys(outlineStyles).length > 5 ? '32.5rem' : '30rem'}
        testId="outline-list-dropdown"
      >
        <div className={styles.styles}>
          <div
            className={`${styles.item} ${styles.none} ${!selected && styles.selected}`}
            onClick={() => {
              handleSelect('');
              close();
            }}
            data-testid="none"
          >
            {intl.formatMessage({ id: 'NONE' }).toUpperCase()}
          </div>
          {Object.keys(outlineStyles).map((element) => (
            <div
              key={element}
              onClick={() => {
                handleSelect(element);
                close();
              }}
              className={`${styles.item} ${element === selectedList && styles.selected}`}
              data-testid={`${element}-list`}
            >
              <PreviewLists list={outlineStyles[element]} />
            </div>
          ))}
        </div>
        <Divider />
        {Object.keys(outlineStyles).length > 0 && (
          <Dropdown.Item
            onClick={() => handleListAction(true)}
            testId="outline-list-customize-list-styles"
          >
            <FormattedMessage id="CUSTOMIZE_LIST_STYLES" />
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => handleListAction(false)}
          testId="outline-list-create-new-list"
        >
          <FormattedMessage id="CREATE_NEW_LIST" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default OutlineList;
