import DocumentStyle from 'Editor/services/DataManager/controllers/Styles/DocumentStyle';
import DocumentStylesManager, {
  MergedStylesData,
} from 'Editor/services/DataManager/controllers/Styles/DocumentStylesManager';
import { ParagraphStyleViewModel } from '../../ViewModels/ParagraphStyleViewModel/ParagraphStyleViewModel';
import { Hook, ReadyState, ViewModelChildren } from '../../utils';

export class ParagraphStylesManager {
  private readyState?: ReadyState | null;
  private Data: Editor.Data.API;
  private Visualizer: Editor.Visualizer.State;
  private styles?: DocumentStylesManager;
  private children: ViewModelChildren<ParagraphStyleViewModel>;
  private view: HTMLElement;
  private reference: HTMLElement | null;

  DEBUG = false;

  constructor(dataManager: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    this.Data = dataManager;
    this.Visualizer = Visualizer;
    this.children = new ViewModelChildren<ParagraphStyleViewModel>();
    this.handleStylesLoaded = this.handleStylesLoaded.bind(this);
    this.handleStyleInserted = this.handleStyleInserted.bind(this);
    this.handleStyleRemoved = this.handleStyleRemoved.bind(this);
    this.view = document.head;
    this.reference = document.getElementById('documentStyles');

    this.createHooks();
  }

  start(readyState: ReadyState) {
    this.readyState = readyState;
    this.styles = this.Data.styles.documentStyles;
    if (this.styles) {
      this.styles.on('LOADED', this.handleStylesLoaded);
      this.styles.on('STYLES_ADD', this.handleStyleInserted);
      this.styles.on('STYLES_REMOVE', this.handleStyleRemoved);
      if (this.styles.loaded) {
        this.handleStylesLoaded(this.styles.styles());
      }
    }
  }

  private createHooks() {
    if (!this.Visualizer.hooks.afterParagraphStyleUpdate) {
      this.Visualizer.hooks.afterParagraphStyleUpdate = new Hook<'afterParagraphStyleUpdate'>();
    }

    if (!this.Visualizer.hooks.afterAllParagraphStylesUpdate) {
      this.Visualizer.hooks.afterAllParagraphStylesUpdate =
        new Hook<'afterAllParagraphStylesUpdate'>();
    }
  }

  private handleStylesLoaded(styles: MergedStylesData) {
    if (this.DEBUG) {
      logger.trace('ParagraphStylesManager handleStylesLoaded', styles);
    }
    let styleIds = Object.keys(styles);
    let styleId;
    for (let index = 0; index < styleIds.length; index++) {
      styleId = styleIds[index];
      if (this.children.getById(styleId)) {
        this.children.getById(styleId).render();
      } else {
        this.handleStyleInserted(styles[styleId]);
      }
    }
    this.readyState?.setReady(ParagraphStylesManager.name);
    this.readyState = null;

    if (this.Visualizer.hooks.afterAllParagraphStylesUpdate) {
      this.Visualizer.hooks.afterAllParagraphStylesUpdate.trigger();
    }
  }

  private handleStyleInserted(data: DocumentStyle) {
    if (this.DEBUG) {
      logger.trace('ParagraphStylesManager handleStyleInserted ' + data.id, data);
    }
    let model = this.Visualizer.viewModelFactory?.getParagraphStyle(data.id);
    this.appendChild(model);
  }

  private handleStyleRemoved(styleId: string) {
    if (this.DEBUG) {
      logger.trace('ParagraphStylesManager handleStyleRemoved ' + styleId);
    }
    let index;
    let removed = this.children.removeById(styleId);
    for (index = 0; index < removed.length; index++) {
      removed[index].dispose();
    }
  }

  private appendChild(child?: ParagraphStyleViewModel) {
    if (child) {
      this.children.push(child);
      if (child.view) {
        if (this.reference) {
          this.view.insertBefore(child.view, this.reference);
        } else {
          this.view.appendChild(child.view);
        }
      }
    }
  }

  destroy() {
    if (this.styles) {
      this.styles.off('LOADED', this.handleStylesLoaded);
      this.styles.off('STYLES_ADD', this.handleStyleInserted);
      this.styles.off('STYLES_REMOVE', this.handleStyleRemoved);
    }
    let children = this.children.removeAll();
    for (let index = 0; index < children.length; index++) {
      children[index].dispose();
    }
  }
}
