/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PDFStructure } from '../../models';
import { BaseController } from '../BaseController';

export class StructureController extends BaseController {
  private structure?: PDFStructure;

  async start(documentId: string) {
    this.structure = this.Data.models?.get('PDFSTRUCTURE', `PS${documentId}`);
    await this.structure?.awaitForEvent('LOADED');
    console.log('structure', this.structure?.get([]));
  }

  stop(): void {}

  destroy(): void {
    //
  }

  getPageHeight(pageNum: string) {
    return this.structure?.pageHeight(pageNum);
  }

  getPageWidth(pageNum: string) {
    return this.structure?.pageWidth(pageNum);
  }

  getPageDimensions(pageNum: string) {
    return this.structure?.pageDimensions(pageNum);
  }
}
