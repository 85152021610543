import { useCallback } from 'react';

import Element, { getChildIndentation } from './Element/Element';
import type { ElementProps } from './Element/Element';
import useConditionalData from '../useConditionalData';

import styles from './DocumentContent.module.scss';

type DocumentContentProps = {
  setSelectedItems: (id: ElementProps['element']['id']) => void;
} & Pick<ElementProps, 'selectedItems'>;

const DocumentContent = ({ selectedItems, setSelectedItems }: DocumentContentProps) => {
  const { navigationList, navigationData } = useConditionalData();

  const toggleElement = useCallback(
    (id: Parameters<typeof setSelectedItems>[0]) => {
      setSelectedItems(id);
    },
    [selectedItems],
  );

  return (
    <div className={styles.root}>
      {navigationList.map((elementId) => {
        const element = navigationData[elementId];
        return (
          <Element
            key={element.id}
            element={element}
            toggleElement={toggleElement}
            selectedItems={selectedItems}
            indent={getChildIndentation(element.st, -1)}
          />
        );
      })}
    </div>
  );
};

export default DocumentContent;
