import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import SectionHeadingContent, { SectionHeadingProps } from './SectionHeadingContent';

const SectionHeading = (props: SectionHeadingProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="sectionHeading"
      mockProps={{ margin: props.margin, sticky: true }}
    >
      <SectionHeadingContent {...props} />
    </IntlErrorBoundary>
  );
};

export default SectionHeading;
