import { notify } from '_common/components/ToastSystem';
import { closeAndResetModal, updateModal } from '_common/modals/ModalsSlice';

import api from '_common/services/api/api';
import { paths } from '_types/api';

type TagState = {
  list: ObjectList;
  dict: Record<Tag, Tag>;
};

type TagList = Tag[];

type APIError = {
  error: { data: any; status: number };
};

const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTagsList: builder.query<Pick<TagState, 'list' | 'dict'>, void>({
      query: () => ({
        url: `/tags`,
      }),
      transformResponse: (responseData: TagList) => {
        const tags = responseData.reduce(
          (previous: Pick<TagState, 'list' | 'dict'>, current) => {
            previous.list.push(current);
            previous.dict[current] = current;
            return previous;
          },
          {
            list: [],
            dict: {},
          },
        );

        return { ...tags };
      },
      providesTags: () => {
        return ['Tag'];
      },
    }),
    createTag: builder.mutation<void, Tag>({
      query: (tag) => {
        return {
          url: `/tag/add`,
          method: 'POST',
          body: { tag },
        };
      },
      invalidatesTags: () => ['Tag'],
      async onQueryStarted(tag, { queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            type: 'success',
            title: 'TAG_CREATED',
            message: 'THE_TAG_WAS_SUCCESSFULLY_ADDED_TO_TENANT_LIST_OF_TAGS',
            messageValues: { tagName: tag },
          });
        } catch (e) {}
      },
    }),
    deleteTag: builder.mutation<void, Tag>({
      query: (tag) => {
        return {
          url: `/tag/remove`,
          method: 'POST',
          body: { tag },
        };
      },
      invalidatesTags: () => ['Tag'],

      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          tagsApi.util.updateQueryData('getTagsList', undefined, (draft) => {
            //Optimistic save
            draft.list.filter((tagId) => tagId !== id);
          }),
        );
        try {
          await queryFulfilled;
          notify({
            type: 'success',
            title: 'TAG_DELETED',
            message: 'THE_TAG_WAS_SUCCESSFULLY_DELETED_FROM_TENANT_LIST_OF_TAGS',
            messageValues: { tagName: id },
          });
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),
    uploadTags: builder.mutation<
      paths['/api/tag/import']['post']['responses']['200']['content']['application/json'],
      {
        file: File;
        force?: boolean;
        onUploadProgress: (percentage: number) => void;
      }
    >({
      query: ({ file, force, onUploadProgress }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('force', `${force}`);

        /*
         * MSW doesnt support XMLHttpRequest events: https://github.com/mswjs/interceptors/issues/187
         * check the link above later to see if it already supports
         */
        const config =
          process.env.NODE_ENV === 'test'
            ? undefined
            : {
                onUploadProgress: (progress: { loaded: number; total: number }) => {
                  const percentage = (progress.loaded * 100) / progress.total;
                  onUploadProgress(percentage);
                },
              };

        return {
          url: `/tag/import`,
          method: 'POST',
          errorsExpected: [400],
          config,
          body: formData,
        };
      },
      invalidatesTags: () => ['Tag'],
      onQueryStarted: async ({ force }, { dispatch, queryFulfilled }) => {
        try {
          dispatch(updateModal({ modal: 'UploadTagsModal', data: { state: 'uploading' } }));
          const { data } = await queryFulfilled;
          if (force) {
            if (data.length > 0) {
              dispatch(closeAndResetModal('UploadTagsModal'));
              notify({
                type: 'success',
                title: 'TAGS_UPLOADED',
                message: 'TAGS_UPLOADED_SUCCESS_MESSAGE',
              });
            } else {
              dispatch(
                updateModal({
                  modal: 'UploadTagsModal',
                  data: {
                    state: 'alreadyExist',
                  },
                }),
              );
            }
          } else if (data.length === 0) {
            dispatch(
              updateModal({
                modal: 'UploadTagsModal',
                data: {
                  state: 'failed',
                },
              }),
            );
          } else {
            dispatch(closeAndResetModal('UploadTagsModal'));
            notify({
              type: 'success',
              title: 'TAGS_UPLOADED',
              message: 'TAGS_UPLOADED_SUCCESS_MESSAGE',
            });
          }
        } catch (e) {
          const error = (e as APIError).error;

          if (error?.status === 400) {
            if (error.data.tag?.[0] === 'duplicated') {
              dispatch(
                updateModal({
                  modal: 'UploadTagsModal',
                  data: {
                    state: 'duplicated',
                  },
                }),
              );
            }
            if (error.data.file?.[0] === 'invalid') {
              dispatch(
                updateModal({
                  modal: 'UploadTagsModal',
                  data: {
                    state: 'failed',
                  },
                }),
              );
            }
          } else {
            dispatch(
              updateModal({
                modal: 'UploadTagsModal',
                data: {
                  state: 'failed',
                },
              }),
            );
          }
        }
      },
    }),
  }),
});

// Export queries and mutations
export const {
  useGetTagsListQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useUploadTagsMutation,
} = tagsApi;

// Select data from where RTK Query stores the data from the list endpoint
export const selectTagsList = tagsApi.endpoints.getTagsList.select();

export default tagsApi;
