import { CSSProperties, useState } from 'react';
import { useIntl } from 'react-intl';
import { InputField } from 'dodoc-design-system';

import { Timepicker } from '_common/components';

import type { TimepickerProps } from '_common/components/Timepicker/Timepicker';
import type { InputFieldProps } from 'dodoc-design-system/build/types/Components/InputField/InputField';

export type TimepickerFieldProps = Required<Pick<InputFieldProps, 'label'>> &
  Pick<InputFieldProps, 'disabled'> &
  Pick<TimepickerProps, 'value' | 'onChange'> &
  Pick<CSSProperties, 'margin'> & {
    testId: {
      inputField: InputFieldProps['testId'];
      timepicker: TimepickerProps['testId'];
    };
  };

const TimepickerField = ({
  label,
  disabled,
  margin,
  value,
  testId,
  onChange,
}: TimepickerFieldProps) => {
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);

  const handleChange: typeof onChange = (isValid, newValue, actionMeta) => {
    setHasError(!isValid);

    if (onChange) {
      onChange(isValid, newValue, actionMeta);
    }
  };

  return (
    <InputField
      label={label}
      margin={margin}
      disabled={disabled}
      feedback={hasError ? intl.formatMessage({ id: 'ENTER_A_VALID_TIME_FORMAT' }) : undefined}
      testId={testId.inputField}
    >
      <Timepicker
        size="medium"
        disabled={disabled}
        value={value}
        onChange={handleChange}
        testId={testId.timepicker}
      />
    </InputField>
  );
};

export default TimepickerField;
