import { useSelector } from '_common/hooks';
import { dayjs } from 'utils';
import useSearchComments from './useSearchComments';

const useFilteredComments = (slideId?: Presentation.Data.Slide['_id']) => {
  const filters = useSelector((state) => state.filters.presentationCommentPanel);
  const comments = useSearchComments();
  const filterValues = {
    commentStatus: filters.commentStatus?.map((filterValue) => filterValue.value),
    cardPriority: filters.cardPriority?.map((filterValue) => filterValue.value),
    author: filters.author?.map((filterValue) => filterValue.value),
    presetCreationDate: filters.presetCreationDate,
  };

  const filterComment = (comment: Presentation.Data.Comment) => {
    if (!comment) {
      return false;
    }

    //Status
    if (
      (!filterValues?.commentStatus &&
        (comment.status === 'ACCEPTED' || comment.status === 'REJECTED')) ||
      (filterValues.commentStatus &&
        filterValues.commentStatus.length > 0 &&
        !filterValues.commentStatus.includes(comment.status))
    ) {
      return false;
    }

    //Priority
    if (
      filterValues.cardPriority &&
      filterValues.cardPriority.length > 0 &&
      comment &&
      !filterValues.cardPriority.includes(comment.priority)
    ) {
      return false;
    }

    //Users
    if (
      filterValues.author &&
      filterValues.author.length > 0 &&
      filterValues.author.every((user) => {
        return user !== comment.authorId && user !== comment.authorName;
      })
    ) {
      return false;
    }

    //Preset Creation Date
    if (filterValues.presetCreationDate) {
      const creationDate = dayjs(comment.time);

      if (
        filterValues.presetCreationDate.value.includes('6_month_ago') &&
        dayjs().diff(creationDate, 'month') > 5
      ) {
        return false;
      }

      if (
        filterValues.presetCreationDate.value.includes('3_month_ago') &&
        dayjs().diff(creationDate, 'month') > 2
      ) {
        return false;
      }

      if (
        filterValues.presetCreationDate.value.includes('last_month') &&
        dayjs().diff(creationDate, 'month') !== 0
      ) {
        return false;
      }

      if (
        filterValues.presetCreationDate.value.includes('last_week') &&
        dayjs().diff(creationDate, 'week') !== 0
      ) {
        return false;
      }

      if (filterValues.presetCreationDate?.value.includes('specific_date_range')) {
        if (filterValues.presetCreationDate.dateValue?.startISO) {
          if (
            !creationDate.isSameOrAfter(
              dayjs(filterValues.presetCreationDate.dateValue.startISO),
              'day',
            )
          ) {
            return false;
          }
        }
        if (filterValues.presetCreationDate && filterValues.presetCreationDate?.dateValue?.endISO) {
          if (
            !creationDate.isSameOrBefore(
              dayjs(filterValues.presetCreationDate?.dateValue?.endISO),
              'day',
            )
          )
            return false;
        }
      }
    }

    //Parameter slide id
    if (slideId) {
      return comment.anchor[0].id === slideId;
    }

    return true;
  };

  return comments.filter(filterComment);
};

export default useFilteredComments;
