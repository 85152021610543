import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import CurrentStyleContent, { CurrentStyleProps } from './CurrentStyleContent';
import { useSelector } from '_common/hooks';

const CurrentStyle = (props: CurrentStyleProps) => {
  const styleId = useSelector((state) => state.editor.status.selectedStyle.id);

  return (
    //Key allows boundary to update based on selected style
    <IntlErrorBoundary key={styleId} fallbackType="card" mockProps={{ sidebar: true }}>
      <CurrentStyleContent {...props} />
    </IntlErrorBoundary>
  );
};

export default CurrentStyle;
