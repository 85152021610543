import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import EditorManager from 'Editor/services/EditorManager';
import { Errors } from '_common/services/Realtime/Transport';
import { Transport } from '_common/services/Realtime/Transport';
import getConfig from 'dodoc.config';

export function transportInitializer(this: EditorManager) {
  if (this.editorContext.transport != null && this.editorContext.transport.isConnected()) {
    // transport is already connected
    this.editorContext.transport.checkConnectionStrength().then((result: number) => {
      Logger.debug(`TRANSPORT CONNECTION : ${result}`);
      this.initializeCoreServices();
    });
  } else {
    this.editorContext.transport = new Transport(getConfig());
    this.editorContext.transport
      .on('TS_CONNECTED', () => {
        this.editorContext.transport?.checkConnectionStrength().then((result: number) => {
          Logger.debug(`TRANSPORT CONNECTION : ${result}`);
          this.initializeCoreServices();
        });
      })
      .on('TS_DESTROYED', () => {
        // this.terminateServices();
      })
      .on('TS_DISCONNECTED', () => {
        ReduxInterface.setEditorError(
          'Disconnected',
          'Your browser was inactive for several minutes and we disconnected you from the document. To access it again press "Reconnect".',
          'Dassie',
        );
        ReduxInterface.setSidebarView(null);
        this.terminateServices();
      })
      .on('TS_ERROR', (event: any, error: any) => {
        if (error.constructor === Errors.ConnectionError) {
          ReduxInterface.setEditorError(
            `Connection Problem - Code: ${error.type}`,
            'Could not connect to the servers. If the problem persists please contact the administrator.',
            error.type,
            error.code,
          );
          ReduxInterface.setSidebarView(null);
          this.terminateServices();
        } else if (error.constructor === Errors.TimeoutError) {
          ReduxInterface.setEditorError(
            'Connection Timed Out',
            'Your connection timed out. If the problem persists please contact the administrator.',
            'Tayra',
          );
          ReduxInterface.setSidebarView(null);
          this.terminateServices();
        } else if (error.constructor === Errors.UnknownError) {
          let title: string = '';
          let message: string = '';
          let code: string = '';
          let status: number | undefined = undefined;
          if (error.reason === 4041) {
            title = 'Connection Problem - Code: Tyrant';
            message =
              'Could not connect to the servers. If the problem persists please contact the administrator.';
            code = 'Tyrant';
          } else if (error.reason === 4042) {
            title = 'Document Not Found';
            message = 'The specified document was not found.';
            status = 404;
          } else if (error.reason === 4031) {
            title = 'Insuficient Permissions';
            message =
              'You are not authorized to access this document. If this is not correct please contact the administrator.';
            status = 403;
          }
          ReduxInterface.setEditorError(title, message, code, status);
          ReduxInterface.setSidebarView(null);
          this.terminateServices();
        }
      });

    if (this.editorContext.documentId && this.editorContext.user?.token) {
      this.editorContext.transport.connect(
        this.editorContext.documentId,
        this.editorContext.user.token,
      );
    }
  }
}
