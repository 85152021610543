import { useState, useEffect, useRef, RefObject } from 'react';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import {
  selectReadOnlyMode,
  selectTrackedActionsActive,
  selectFilteredCommentsActive,
  selectUser,
  selectIsPageLayout,
} from 'Editor/redux/EditorStatusSlice';
import { selectFilteredComments } from 'Editor/redux/CommentsSlice';
import { selectActiveTasks } from 'Editor/redux/TasksSlice';
import { selectFilteredTrackedActions } from 'Editor/redux/TrackedActionsSlice';
import { setSidebarPanelTab, setSidebarView } from 'Editor/redux/SidebarSlice';

import { InteractionController } from '_common/components';
import { TrackedActionCard } from 'Editor/components';
import CommentCard from 'Editor/components/CommentCard';
import TaskCard from 'Editor/pages/EditorPage/SidebarComponent/TasksTab/TaskCard';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

type OverlayProps = {
  editorInputRef: RefObject<HTMLDivElement>;
};

const Overlay = ({ editorInputRef }: OverlayProps) => {
  const dispatch = useDispatch();

  const sidebarView = useSelector((state) => state.editor.sidebar.view);
  const selected = useSelector((state) => state.editor.tasks.overlay.selected);
  const operation = useSelector((state) => state.editor.tasks.overlay.operation);
  const tasks = useSelector((state) => state.editor.tasks.tasks);
  const offsets = useSelector((state) => state.editor.tasks.overlay.offsets);
  const blockWindowStart = useSelector((state) => state.editor.status.blockWindow.start);
  const user = useSelector(selectUser);
  const comments = useSelector(selectFilteredComments);
  const commentsActive = useSelector(selectFilteredCommentsActive);
  const trackedActions = useSelector(selectFilteredTrackedActions);
  const activeTrackedActions = useSelector(selectTrackedActionsActive);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const activeTasks = useSelector(selectActiveTasks);
  const zoom = useSelector((state) => state.editor.status.zoom);
  const isPageLayout = useSelector(selectIsPageLayout);

  const [pageHeight, setPageHeight] = useState<number>();
  const editorInputObserver = useRef<ResizeObserver>();
  const [cardOffsets, setCardOffsets] = useState<(ShortRect & { leftWithBounds?: number }) | null>(
    null,
  );
  const timeout = useRef<number>();

  const handleCardsOffsets = () => {
    if (activeTasks.tasks.length > 0) {
      const node = EditorDOMUtils.getBlockNode(activeTasks.blockId);

      if (node) {
        const offsets = EditorDOMUtils.getOffsets(node);
        if (!offsets) {
          return null;
        }

        return { ...offsets, left: offsets.leftWithBounds ?? offsets.left };
      }
    }
    if (activeTrackedActions.length > 0) {
      const page = EditorDOMUtils.getContentContainer();
      let suggestion: Element | null = null;
      if (page instanceof Element) {
        suggestion = page?.querySelector(`*[element_reference="${activeTrackedActions[0]}"]`);
      }
      if (suggestion) {
        const level0 = EditorDOMUtils.findFirstLevelChildNode(page, suggestion);
        if (level0) {
          const level0Offsets = EditorDOMUtils.getOffsets(level0);
          const suggestionOffsets = EditorDOMUtils.getOffsets(suggestion);
          if (!level0Offsets || !suggestionOffsets) {
            return null;
          }

          const offsets = {
            top: suggestionOffsets.top,
            left: level0Offsets.leftWithBounds ?? level0Offsets.left,
            width: level0Offsets.width,
            height: suggestionOffsets.height,
          };
          return offsets;
        }
      }
    }
    if (commentsActive.length > 0 || comments.insert.inserting) {
      const inserting = comments.insert.inserting;
      const reference = comments.insert.reference;
      const page = EditorDOMUtils.getContentContainer();
      let comment: Element | null = null;
      if (page instanceof Element) {
        comment = page.querySelector(
          inserting
            ? `temp-comment-element[element_reference="${reference}"]`
            : `comment-element[element_reference="${commentsActive[0]}"]`,
        );
      }

      if (comment) {
        const level0 = EditorDOMUtils.findFirstLevelChildNode(page, comment);
        if (level0) {
          const level0Offsets = EditorDOMUtils.getOffsets(level0);
          const commentOffsets = EditorDOMUtils.getOffsets(comment);
          if (!level0Offsets || !commentOffsets) {
            return null;
          }

          const offsets = {
            top: commentOffsets.top,
            left: level0Offsets.leftWithBounds ?? level0Offsets.left,
            width: level0Offsets.width,
            height: commentOffsets.height,
          };
          return offsets;
        }
      } else {
        const node = EditorDOMUtils.getNode(comments.insert.level0);
        const offsets = EditorDOMUtils.getOffsets(node || null);
        if (!offsets) {
          return null;
        }

        return { ...offsets, left: offsets.leftWithBounds ?? offsets.left };
      }
    }
    if (offsets) {
      return { ...offsets };
    }
    return null;
  };

  useEffect(() => {
    timeout.current = window.setTimeout(() => setCardOffsets(handleCardsOffsets()), 0);
  }, [
    zoom,
    offsets,
    pageHeight,
    comments.insert.inserting,
    commentsActive[0],
    activeTrackedActions[0],
    activeTasks.tasks[0],
    tasks,
    selected,
    blockWindowStart,
  ]);

  useEffect(() => {
    if (isPageLayout && !sidebarView) {
      if (commentsActive?.length > 0) {
        dispatch(setSidebarPanelTab({ view: 'review', tab: 'comments' }));
        dispatch(setSidebarView('REVIEW'));
      } else if (activeTrackedActions?.length > 0) {
        dispatch(setSidebarPanelTab({ view: 'review', tab: 'changes' }));
        dispatch(setSidebarView('REVIEW'));
      } else if (activeTasks?.tasks?.length > 0) {
        dispatch(setSidebarView('TASKS'));
      }
    }
  }, [isPageLayout, commentsActive, activeTrackedActions, activeTasks]);

  useEffect(() => {
    if (
      commentsActive.length === 0 &&
      activeTrackedActions.length === 0 &&
      activeTasks.tasks.length === 0 &&
      !comments.insert.inserting
    ) {
      if (editorInputObserver.current) {
        editorInputObserver.current.disconnect();
        editorInputObserver.current = undefined;
      }

      return () => {
        clearTimeout(timeout.current);
      };
    }
    const newObserver = new ResizeObserver((entries) =>
      setPageHeight(entries[0].target.clientHeight),
    );

    if (editorInputRef?.current) {
      newObserver.observe(editorInputRef.current);
    }
    editorInputObserver.current = newObserver;

    return () => {
      newObserver.disconnect();
    };
  }, [
    commentsActive.length,
    activeTrackedActions.length,
    comments.insert.inserting,
    activeTasks.tasks.length,
  ]);

  const overlayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    EditorManager.getInstance().filterComments(comments.order);
  }, [comments.order]);
  useEffect(() => {
    EditorManager.getInstance().filterSuggestions(trackedActions.order);
  }, [trackedActions.order]);

  if (
    !offsets &&
    commentsActive.length === 0 &&
    !activeTrackedActions &&
    activeTasks.tasks.length === 0 &&
    !comments.insert.inserting
  ) {
    return null;
  }

  const renderCard = () => {
    return (
      <div>
        {/* TRACKED ACTIONS LAYER */}
        {!isPageLayout &&
          activeTrackedActions.length > 0 &&
          !sidebarView &&
          activeTrackedActions
            .filter((trackedActionId) => trackedActions.actions[trackedActionId])
            .map((trackedActionId) => (
              <div key={trackedActionId} style={{ marginBottom: '1rem' }}>
                <TrackedActionCard
                  selected
                  trackedAction={trackedActions.actions[trackedActionId]}
                  testId={`editor-suggestion-card-${trackedActionId}`}
                />
              </div>
            ))}
        {/* COMMENTS LAYER */}
        {comments.insert.inserting && !sidebarView && !isPageLayout && (
          <div style={{ marginBottom: '1rem' }}>
            <CommentCard isTemporary user={user.id} />
          </div>
        )}
        {!isPageLayout &&
          commentsActive.length > 0 &&
          !sidebarView &&
          commentsActive.map((commentId) => (
            <div key={commentId} style={{ marginBottom: '1rem' }}>
              <CommentCard
                user={user.id}
                comment={comments.comments[commentId]}
                isReadOnlyMode={isReadOnlyMode || isPageLayout}
                selected
              />
            </div>
          ))}
        {/* TASKS LAYER */}
        {(operation === 'create' || operation === 'edit') && (
          <InteractionController
            environment="editor"
            rules={[
              {
                interaction: 'editor_tasks_tempCard',
                actions: ['editor_tasks_createTask'],
              },
            ]}
          >
            <TaskCard mode={operation} sidebar={!!sidebarView} />
          </InteractionController>
        )}
        {!isPageLayout &&
          operation === 'view' &&
          activeTasks.tasks.length > 0 &&
          !sidebarView &&
          activeTasks.tasks.map((taskId) => {
            return (
              <div key={taskId} style={{ marginBottom: '1rem' }}>
                <TaskCard task={tasks[taskId]} />
              </div>
            );
          })}
      </div>
    );
  };

  if (!cardOffsets) {
    return null;
  }
  return (
    <div
      ref={overlayRef}
      style={{
        left: cardOffsets.left * zoom + cardOffsets.width + 20 * zoom,
        top: cardOffsets.top * zoom,
        position: 'absolute',
      }}
    >
      {renderCard()}
    </div>
  );
};

export default Overlay;
