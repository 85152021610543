import { EffectCallback, useLayoutEffect, useRef } from 'react';

const usePrimitiveUpdate = <P extends Primitive>(
  effect: EffectCallback,
  singleDep: P,
  invalidInitial?: P,
) => {
  //Save dependency as initial value
  const lastValue = useRef<Primitive>(singleDep);

  useLayoutEffect(() => {
    if (lastValue.current !== invalidInitial && lastValue.current !== singleDep) {
      effect();
    }

    lastValue.current = singleDep;
  }, [singleDep]);
};

export default usePrimitiveUpdate;
