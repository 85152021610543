/* eslint-disable import/first */
// import './wdyr'; // Remove comment to enable whyDidYouRender
import 'core-js';
import 'regenerator-runtime/runtime';
import { Logger } from '_common/services';

// custom polyfills
import './polyfills/forEachPolyfill';
import './polyfills/nodeRemovePolyfill';
import './polyfills/elementInViewport';
import './polyfills/closestPolyfill';
import './polyfills/endsWithPolyfill';
// import './polyfills/charcodePolyfill';

import '@webcomponents/custom-elements/src/native-shim';
import '@webcomponents/custom-elements';
import '@webcomponents/webcomponentsjs';
import '@webcomponents/shadydom';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import history from 'router/history';
import AppRouter from 'router/index';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'App/App';
import Intl from 'Intl/Intl';
import { getDoDOCAppConfig } from 'dodoc.config';
import { store, persistor } from '_common/redux';
import { LoadingPage, ErrorBoundary } from '_common/components';
import setupSentry from 'sentry';

// Stylesheets
import 'dodoc-design-system/build/css/index.css';
import 'react-virtualized/styles.css';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import 'assets/styles/global.module.scss';

import 'mathjax-full/es5/tex-mml-svg';

global.logger = global.logger || Logger;
Object.typedKeys = Object.keys as any;

// MathJax configs
// MathJax.options.enableMenu = false;

getDoDOCAppConfig().then((config) => {
  setupSentry(config);
  if (config.logger?.level) {
    Logger.setLogLevel(config.logger?.level);
  }

  const container = document.getElementById('main');
  const root = createRoot(container!);

  root.render(
    <StrictMode>
      <Provider store={store}>
        <Intl>
          <PersistGate loading={<LoadingPage />} persistor={persistor}>
            <Router history={history}>
              <ErrorBoundary>
                <App>
                  <AppRouter />
                </App>
              </ErrorBoundary>
            </Router>
          </PersistGate>
        </Intl>
      </Provider>
    </StrictMode>,
  );
});
