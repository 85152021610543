import { BaseOperation } from '../BaseOperation';
import { ParseFigureForParagaphOperation } from './ParseFigureForParagaphOperation';
import { NodeUtils } from 'Editor/services/DataManager/models';

const MAX_IMAGE_WIDTH = 500;

export class UpdateImageSizeOperation extends BaseOperation<Editor.Data.Node.Model> {
  protected path: Editor.Selection.Path;
  private imageData: Editor.Data.Node.ImageData;
  private height: number;
  private width: number;

  constructor(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    imageData: Editor.Data.Node.ImageData,
    height: number, // in points
    width: number, // in points
  ) {
    super(baseModel);
    this.imageData = imageData;
    this.height = height;
    this.width = width;
    this.path = path;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.width >= MAX_IMAGE_WIDTH) {
      this.height = (this.height * MAX_IMAGE_WIDTH) / this.width;
      this.width = MAX_IMAGE_WIDTH;
    }

    const baseData = this.model.selectedData();

    if (!baseData) {
      return this;
    }

    const closestFigure = NodeUtils.closestOfTypeByPath(baseData, this.path, 'figure');

    // check if level0 is a Figure and change to paragraph
    if (closestFigure && NodeUtils.isFigureData(closestFigure.data)) {
      const parseFigureForParagaphOperation = new ParseFigureForParagaphOperation(
        this.model,
        closestFigure.path,
      );
      this.ops.push(...parseFigureForParagaphOperation.getOps());
    }

    if (this.imageData && this.path) {
      const op1 = this.getObjectOperationforPathValue(this.imageData.properties?.h, this.height, [
        ...this.path,
        'properties',
        'h',
      ]);
      if (op1) {
        this.ops.push(op1);
      }

      const op2 = this.getObjectOperationforPathValue(this.imageData.properties?.w, this.width, [
        ...this.path,
        'properties',
        'w',
      ]);
      if (op2) {
        this.ops.push(op2);
      }
    }

    return this;
  }
}
