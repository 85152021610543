import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { Mixin } from 'mixwith';

export default Mixin(
  (superclass) =>
    class JoinSelectionHelper extends superclass {
      /**
       * join citations-groups on selection if there are no space bwtween them
       * @param {ActionContext} actionContext
       * @param {Node} anchorClosest closest "joinable" element, generaly, page level 0 nodes
       */
      joinCitationGroupsContent(actionContext, anchorClosest) {
        // TODO: review this
        const selection = EditorSelectionUtils.getSelection();
        let anchorNode = selection.anchorNode;
        const anchorOffset = selection.anchorOffset;
        if (
          anchorNode === anchorClosest &&
          anchorNode.childNodes.length > 0 &&
          anchorOffset < anchorNode.childNodes.length
        ) {
          anchorNode = anchorNode.childNodes[anchorOffset];
        }
        if (anchorNode) {
          if (
            anchorNode.nodeType === Node.TEXT_NODE &&
            anchorNode.length === 0 &&
            anchorNode.previousSibling &&
            anchorNode.previousSibling.nodeType === Node.ELEMENT_NODE &&
            anchorNode.previousSibling.tagName === 'CITATIONS-GROUP-ELEMENT' &&
            anchorNode.nextSibling &&
            anchorNode.nextSibling.nodeType === Node.ELEMENT_NODE &&
            anchorNode.nextSibling.tagName === 'CITATIONS-GROUP-ELEMENT'
          ) {
            anchorNode.previousSibling.addCitationElement(
              anchorNode.nextSibling.getCitationElements(),
            );
            anchorNode.nextSibling.remove();
            // register node for update
            actionContext.addChangeUpdatedNode(anchorClosest);
          } else if (
            anchorNode.tagName === 'CITATIONS-GROUP-ELEMENT' &&
            anchorNode.nextSibling &&
            anchorNode.nextSibling.tagName === 'CITATIONS-GROUP-ELEMENT'
          ) {
            anchorNode.addCitationElement(anchorNode.nextSibling.getCitationElements());
            anchorNode.nextSibling.remove();
            // register node for update
            actionContext.addChangeUpdatedNode(anchorClosest);
          } else if (
            anchorNode.tagName === 'CITATIONS-GROUP-ELEMENT' &&
            anchorNode.previousSibling &&
            anchorNode.previousSibling.tagName === 'CITATIONS-GROUP-ELEMENT'
          ) {
            anchorNode.previousSibling.addCitationElement(anchorNode.getCitationElements());
            anchorNode.remove();
            // register node for update
            actionContext.addChangeUpdatedNode(anchorClosest);
          }
        }
      }
    },
);
