import { path } from 'd3';
import { constantToRad, ellipseArcTo, mod } from './utils';

type CalloutProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'wedgeRectCallout'
    | 'wedgeRoundRectCallout'
    | 'wedgeEllipseCallout'
    | 'cloudCallout'
    | 'borderCallout1'
    | 'borderCallout2'
    | 'borderCallout3'
    | 'accentCallout1'
    | 'accentCallout2'
    | 'accentCallout3'
    | 'callout1'
    | 'callout2'
    | 'callout3'
    | 'accentBorderCallout1'
    | 'accentBorderCallout2'
    | 'accentBorderCallout3';
};

const getAdjstByType = (type: CalloutProps['type']) => {
  switch (type) {
    case 'wedgeRectCallout':
    case 'wedgeEllipseCallout':
    case 'cloudCallout':
    case 'wedgeRoundRectCallout':
      return {
        adj1: -20833,
        adj2: 62500,
        adj3: 16667,
        adj4: 0,
        adj5: 0,
        adj6: 0,
        adj7: 0,
        adj8: 0,
      };
    case 'borderCallout1':
    case 'accentCallout1':
    case 'callout1':
    case 'accentBorderCallout1':
      return {
        adj1: 18750,
        adj2: -8333,
        adj3: 112500,
        adj4: -38333,
        adj5: 0,
        adj6: 0,
        adj7: 0,
        adj8: 0,
      };
    case 'borderCallout2':
    case 'accentCallout2':
    case 'callout2':
    case 'accentBorderCallout2':
      return {
        adj1: 18750,
        adj2: -8333,
        adj3: 18750,
        adj4: -16667,
        adj5: 112500,
        adj6: -46667,
        adj7: 0,
        adj8: 0,
      };
    case 'borderCallout3':
    case 'accentCallout3':
    case 'callout3':
    case 'accentBorderCallout3':
      return {
        adj1: 18750,
        adj2: -8333,
        adj3: 18750,
        adj4: -16667,
        adj5: 100000,
        adj6: -16667,
        adj7: 112963,
        adj8: -8333,
      };
  }
};

const generateCalloutPath = ({
  size,
  type,
  adjst,
}: CalloutProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side */
  const ss = Math.min(w, h);

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;
  const adj3 = adjst?.adj3 ? +adjst.adj3 : defaultAdjLst.adj3;
  const adj4 = adjst?.adj4 ? +adjst.adj4 : defaultAdjLst.adj4;
  const adj5 = adjst?.adj5 ? +adjst.adj5 : defaultAdjLst.adj5;
  const adj6 = adjst?.adj6 ? +adjst.adj6 : defaultAdjLst.adj6;
  const adj7 = adjst?.adj7 ? +adjst.adj7 : defaultAdjLst.adj7;
  const adj8 = adjst?.adj8 ? +adjst.adj8 : defaultAdjLst.adj8;

  switch (type) {
    case 'wedgeRectCallout': {
      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      let xPos = hc + dxPos;
      let yPos = vc + dyPos;

      // Check if both dxPos and dyPos are inside the main shape bounds
      const isCustomPointInside = xPos > l && xPos < r && yPos > t && yPos < b;

      // Push the custom point to the edge of the main shape if it is inside the bounds
      xPos = isCustomPointInside ? (dxPos > 0 ? r : l) : xPos;
      yPos = isCustomPointInside ? (dyPos > 0 ? b : t) : yPos;

      const dq = (dxPos * h) / w;
      const ady = Math.abs(dyPos);
      const adq = Math.abs(dq);
      const dz = ady - adq;
      const xg1 = dxPos > 0 ? 7 : 2;
      const xg2 = dxPos > 0 ? 10 : 5;
      const x1 = (w * xg1) / 12;
      const x2 = (w * xg2) / 12;
      const yg1 = dyPos > 0 ? 7 : 2;
      const yg2 = dyPos > 0 ? 10 : 5;
      const y1 = (h * yg1) / 12;
      const y2 = (h * yg2) / 12;
      const t1 = dxPos > 0 ? l : xPos;
      const xl = dz > 0 ? l : t1;
      const t2 = dyPos > 0 ? x1 : xPos;
      const xt = dz > 0 ? t2 : x1;
      const t3 = dxPos > 0 ? xPos : r;
      const xr = dz > 0 ? r : t3;
      const t4 = dyPos > 0 ? xPos : x1;
      const xb = dz > 0 ? t4 : x1;
      const t5 = dxPos > 0 ? y1 : yPos;
      const yl = dz > 0 ? y1 : t5;
      const t6 = dyPos > 0 ? t : yPos;
      const yt = dz > 0 ? t6 : t;
      const t7 = dxPos > 0 ? yPos : y1;
      const yr = dz > 0 ? y1 : t7;
      const t8 = dyPos > 0 ? yPos : b;
      const yb = dz > 0 ? t8 : b;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(x1, t);
      d.lineTo(xt, yt);
      d.lineTo(x2, t);
      d.lineTo(r, t);
      d.lineTo(r, y1);
      d.lineTo(xr, yr);
      d.lineTo(r, y2);
      d.lineTo(r, b);
      d.lineTo(x2, b);
      d.lineTo(xb, yb);
      d.lineTo(x1, b);
      d.lineTo(l, b);
      d.lineTo(l, y2);
      d.lineTo(xl, yl);
      d.lineTo(l, y1);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b } };
    }
    case 'wedgeRoundRectCallout': {
      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      let xPos = hc + dxPos;
      let yPos = vc + dyPos;

      // Check if both dxPos and dyPos are inside the main shape bounds
      const isCustomPointInside = xPos > l && xPos < r && yPos > t && yPos < b;

      // Push the custom point to the edge of the main shape if it is inside the bounds
      xPos = isCustomPointInside ? (dxPos > 0 ? r : l) : xPos;
      yPos = isCustomPointInside ? (dyPos > 0 ? b : t) : yPos;

      const dq = (dxPos * h) / w;
      const ady = Math.abs(dyPos);
      const adq = Math.abs(dq);
      const dz = ady - adq;
      const xg1 = dxPos > 0 ? 7 : 2;
      const xg2 = dxPos > 0 ? 10 : 5;
      const x1 = (w * xg1) / 12;
      const x2 = (w * xg2) / 12;
      const yg1 = dyPos > 0 ? 7 : 2;
      const yg2 = dyPos > 0 ? 10 : 5;
      const y1 = (h * yg1) / 12;
      const y2 = (h * yg2) / 12;
      const t1 = dxPos > 0 ? l : xPos;
      const xl = dz > 0 ? l : t1;
      const t2 = dyPos > 0 ? x1 : xPos;
      const xt = dz > 0 ? t2 : x1;
      const t3 = dxPos > 0 ? xPos : r;
      const xr = dz > 0 ? r : t3;
      const t4 = dyPos > 0 ? xPos : x1;
      const xb = dz > 0 ? t4 : x1;
      const t5 = dxPos > 0 ? y1 : yPos;
      const yl = dz > 0 ? y1 : t5;
      const t6 = dyPos > 0 ? t : yPos;
      const yt = dz > 0 ? t6 : t;
      const t7 = dxPos > 0 ? yPos : y1;
      const yr = dz > 0 ? y1 : t7;
      const t8 = dyPos > 0 ? yPos : b;
      const yb = dz > 0 ? t8 : b;
      const u1 = (ss * adj3) / 100000;
      const u2 = r - u1;
      const v2 = b - u1;
      const il = (u1 * 29289) / 100000;
      const ir = r - il;
      const ib = b - il;

      const d = path();
      d.moveTo(l, u1);
      ellipseArcTo(d, u1, u1, 'cd2', 'cd4', l, u1);
      d.lineTo(x1, t);
      d.lineTo(xt, yt);
      d.lineTo(x2, t);
      d.lineTo(u2, t);
      ellipseArcTo(d, u1, u1, '3cd4', 'cd4', u2, t);
      d.lineTo(r, y1);
      d.lineTo(xr, yr);
      d.lineTo(r, y2);
      d.lineTo(r, v2);
      ellipseArcTo(d, u1, u1, 0, 'cd4', r, v2);
      d.lineTo(x2, b);
      d.lineTo(xb, yb);
      d.lineTo(x1, b);
      d.lineTo(u1, b);
      ellipseArcTo(d, u1, u1, 'cd4', 'cd4', u1, b);
      d.lineTo(l, y2);
      d.lineTo(xl, yl);
      d.lineTo(l, y1);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: il, r: ir, b: ib } };
    }
    case 'wedgeEllipseCallout': {
      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      const xPos = hc + dxPos;
      const yPos = vc + dyPos;
      const sdx = dxPos * h;
      const sdy = dyPos * w;
      const pang = Math.atan2(sdy, sdx);
      const stAng = pang + constantToRad(660000);
      const enAng = pang - constantToRad(660000);
      const dx1 = wd2 * Math.cos(stAng);
      const dy1 = hd2 * Math.sin(stAng);
      const x1 = hc + dx1;
      const y1 = vc + dy1;
      const dx2 = wd2 * Math.cos(enAng);
      const dy2 = hd2 * Math.sin(enAng);
      const stAng1 = Math.atan2(dy1, dx1);
      const enAng1 = Math.atan2(dy2, dx2);
      const swAng1 = enAng1 - stAng1;
      const swAng2 = swAng1 + constantToRad(21600000);
      const swAng = swAng1 > 0 ? swAng1 : swAng2;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      // Check if the point is inside the ellipse
      const isInEllipse = (xPos - hc) ** 2 / wd2 ** 2 + (yPos - vc) ** 2 / hd2 ** 2 <= 1;

      const d = path();
      //Ignore the custom point if it is inside the ellipse
      if (isInEllipse) {
        d.moveTo(x1, y1);
      } else {
        d.moveTo(xPos, yPos);
        d.lineTo(x1, y1);
      }
      ellipseArcTo(d, wd2, hd2, stAng1, swAng, x1, y1);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'cloudCallout': {
      /**
       * Due to angle issues, the same will be drawn as 43200x43200 (based on definitions)
       * After the drawing, scale up to the correct size
       */
      const drawW = 43200;
      const drawH = 43200;

      const dxPos = (w * adj1) / 100000;
      const dyPos = (h * adj2) / 100000;
      const xPos = hc + dxPos;
      const yPos = vc + dyPos;
      const ht = hd2 * Math.cos(Math.atan2(dyPos, dxPos));
      const wt = wd2 * Math.sin(Math.atan2(dyPos, dxPos));
      const g2 = wd2 * Math.cos(Math.atan2(wt, ht));
      const g3 = hd2 * Math.sin(Math.atan2(wt, ht));
      const g4 = hc + g2;
      const g5 = vc + g3;
      const g6 = g4 - xPos;
      const g7 = g5 - yPos;
      const g8 = mod(g6, g7, 0);
      const g9 = (ss * 6600) / 21600;
      const g10 = g8 - g9;
      const g11 = g10 / 3;
      const g12 = (ss * 1800) / 21600;
      const g13 = g11 + g12;
      const g14 = (g13 * g6) / g8;
      const g15 = (g13 * g7) / g8;
      const g16 = g14 + xPos;
      const g17 = g15 + yPos;
      const g18 = (ss * 4800) / 21600;
      const g19 = g11 * 2;
      const g20 = g18 + g19;
      const g21 = (g20 * g6) / g8;
      const g22 = (g20 * g7) / g8;
      const g23 = g21 + xPos;
      const g24 = g22 + yPos;
      const g25 = (ss * 1200) / 21600;
      const g26 = (ss * 600) / 21600;
      const x23 = xPos + g26;
      const x24 = g16 + g25;
      const x25 = g23 + g12;
      const il = (w * 2977) / 21600;
      const it = (h * 3262) / 21600;
      const ir = (w * 17087) / 21600;
      const ib = (h * 17337) / 21600;

      /**
       * Renaming to allow each ellipseArcTo to be inline
       * c2r: constantToRad
       * ca: CloudArc
       */
      const c2r = constantToRad;

      const dCloud = path();
      dCloud.moveTo(3900, 14370);
      const ca1 = ellipseArcTo(dCloud, 6753, 9190, c2r(-11429249), c2r(7426832), 3900, 14370);
      const ca2 = ellipseArcTo(dCloud, 5333, 7267, c2r(-8646143), c2r(5396714), ca1.eX, ca1.eY);
      const ca3 = ellipseArcTo(dCloud, 4365, 5945, c2r(-8748475), c2r(5983381), ca2.eX, ca2.eY);
      const ca4 = ellipseArcTo(dCloud, 4857, 6595, c2r(-7859164), c2r(7034504), ca3.eX, ca3.eY);
      const ca5 = ellipseArcTo(dCloud, 5333, 7273, c2r(-4722533), c2r(6541615), ca4.eX, ca4.eY);
      const ca6 = ellipseArcTo(dCloud, 6775, 9220, c2r(-2776035), c2r(7816140), ca5.eX, ca5.eY);
      const ca7 = ellipseArcTo(dCloud, 5785, 7867, c2r(37501), c2r(6842000), ca6.eX, ca6.eY);
      const ca8 = ellipseArcTo(dCloud, 6752, 9215, c2r(1347096), c2r(6910353), ca7.eX, ca7.eY);
      const ca9 = ellipseArcTo(dCloud, 7720, 10543, c2r(3974558), c2r(4542661), ca8.eX, ca8.eY);
      const ca10 = ellipseArcTo(dCloud, 4360, 5918, c2r(-16496525), c2r(8804134), ca9.eX, ca9.eY);
      ellipseArcTo(dCloud, 4345, 5945, c2r(-14809710), c2r(9151131), ca10.eX, ca10.eY);

      const dCloudOutline = dCloud;

      const dOutline = path();
      dOutline.moveTo(4693, 26177);
      ellipseArcTo(dOutline, 4345, 5945, c2r(5204520), c2r(1585770), 4693, 26177);
      dOutline.moveTo(6928, 34899);
      ellipseArcTo(dOutline, 4360, 5918, c2r(4416628), c2r(686848), 6928, 34899);
      dOutline.moveTo(16478, 39090);
      ellipseArcTo(dOutline, 6752, 9215, c2r(8257449), c2r(844866), 16478, 39090);
      dOutline.moveTo(28827, 34751);
      ellipseArcTo(dOutline, 6752, 9215, c2r(387196), c2r(959901), 28827, 34751);
      dOutline.moveTo(34129, 22954);
      ellipseArcTo(dOutline, 5785, 7867, c2r(-4217541), c2r(4255042), 34129, 22954);
      dOutline.moveTo(41798, 15354);
      ellipseArcTo(dOutline, 5333, 7273, c2r(1819082), c2r(1665090), 41798, 15354);
      dOutline.moveTo(38324, 5426);
      ellipseArcTo(dOutline, 4857, 6595, c2r(-824660), c2r(891534), 38324, 5426);
      dOutline.moveTo(29078, 3952);
      ellipseArcTo(dOutline, 4857, 6595, c2r(-8950887), c2r(1091722), 29078, 3952);
      dOutline.moveTo(22141, 4720);
      ellipseArcTo(dOutline, 4365, 5945, c2r(-9809656), c2r(1061181), 22141, 4720);
      dOutline.moveTo(14000, 5192);
      ellipseArcTo(dOutline, 6753, 9190, c2r(-4002417), c2r(739161), 14000, 5192);
      dOutline.moveTo(4127, 15789);
      ellipseArcTo(dOutline, 6753, 9190, c2r(9459261), c2r(711490), 4127, 15789);

      const dInnerCircle = path();
      dInnerCircle.moveTo(x25, g24);
      ellipseArcTo(dInnerCircle, g12, g12, 0, 'cd', x25, g24);
      dInnerCircle.closePath();

      const dOuterCircle = path();
      dOuterCircle.moveTo(x23, yPos);
      ellipseArcTo(dOuterCircle, g26, g26, 0, 'cd', x23, yPos);
      dOuterCircle.closePath();

      const dMiddleCircle = path();
      dMiddleCircle.moveTo(x24, g17);
      ellipseArcTo(dMiddleCircle, g25, g25, 0, 'cd', x24, g17);
      dMiddleCircle.closePath();

      const dInnerCircleOutline = dInnerCircle;
      const dOuterCircleOutline = dOuterCircle;
      const dMiddleCircleOutline = dMiddleCircle;

      return {
        paths: [
          {
            d: dCloud.toString(),
            stroke: 'false',
            transform: `scale(${w / drawW}, ${h / drawH})`,
            vectorEffect: 'non-scaling-size',
            fillIdSuffix: 'transform',
          },
          { d: dInnerCircle.toString(), stroke: 'false' },
          { d: dMiddleCircle.toString(), stroke: 'false' },
          { d: dOuterCircle.toString(), stroke: 'false' },
          {
            d: dCloudOutline.toString(),
            fill: 'none',
            transform: `scale(${w / drawW}, ${h / drawH})`,
            vectorEffect: 'non-scaling-stroke',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
            transform: `scale(${w / drawW}, ${h / drawH})`,
            vectorEffect: 'non-scaling-stroke',
          },
          { d: dInnerCircleOutline.toString(), fill: 'none' },
          { d: dMiddleCircleOutline.toString(), fill: 'none' },
          { d: dOuterCircleOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: il, t: it, r: ir, b: ib },
        inverseTransform: { x: drawW / w, y: drawH / h },
      };
    }
    case 'borderCallout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);

      return {
        paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }],
        textBounds: { l, t, r, b },
      };
    }
    case 'borderCallout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);

      return {
        paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }],
        textBounds: { l, t, r, b },
      };
    }
    case 'borderCallout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);
      dLine.lineTo(x4, y4);

      return {
        paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }],
        textBounds: { l, t, r, b },
      };
    }
    case 'accentCallout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'accentCallout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'accentCallout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);
      dLine2.lineTo(x4, y4);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'callout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);

      return {
        paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }],
        textBounds: { l, t, r, b },
      };
    }
    case 'callout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);

      return {
        paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }],
        textBounds: { l, t, r, b },
      };
    }
    case 'callout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(x1, y1);
      dLine.lineTo(x2, y2);
      dLine.lineTo(x3, y3);
      dLine.lineTo(x4, y4);

      return {
        paths: [{ d: dMain.toString() }, { d: dLine.toString(), fill: 'none' }],
        textBounds: { l, t, r, b },
      };
    }
    case 'accentBorderCallout1': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'accentBorderCallout2': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
    case 'accentBorderCallout3': {
      const y1 = (h * adj1) / 100000;
      const x1 = (w * adj2) / 100000;
      const y2 = (h * adj3) / 100000;
      const x2 = (w * adj4) / 100000;
      const y3 = (h * adj5) / 100000;
      const x3 = (w * adj6) / 100000;
      const y4 = (h * adj7) / 100000;
      const x4 = (w * adj8) / 100000;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(r, t);
      dMain.lineTo(r, b);
      dMain.lineTo(l, b);
      dMain.closePath();

      const dLine1 = path();
      dLine1.moveTo(x1, t);
      dLine1.lineTo(x1, b);

      const dLine2 = path();
      dLine2.moveTo(x1, y1);
      dLine2.lineTo(x2, y2);
      dLine2.lineTo(x3, y3);
      dLine2.lineTo(x4, y4);

      return {
        paths: [
          { d: dMain.toString() },
          { d: dLine1.toString(), fill: 'none' },
          { d: dLine2.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b },
      };
    }
  }
};

export default generateCalloutPath;
