import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, InputField, Toggle, ToggleGroup } from 'dodoc-design-system';

import styles from './Section.module.scss';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type TextAlignmentProps = Pick<
  Editor.Styles.TableProperties,
  'textAlignment' | 'verticalAlignment'
> &
  SectionProps;

const DEFAULT_PROPERTIES: Pick<
  Editor.Styles.TableProperties,
  'textAlignment' | 'verticalAlignment'
> = {
  textAlignment: 'l',
  verticalAlignment: 'c',
};

type ToggleOption<
  T extends
    | Editor.Styles.TableProperties['textAlignment']
    | Editor.Styles.TableProperties['verticalAlignment'],
> = {
  value: T;
  unselectedIcon: IconTypes['24'];
  selectedIcon: IconTypes['24'];
};

const HORIZONTAL_TOGGLES: ToggleOption<Editor.Styles.TableProperties['textAlignment']>[] = [
  {
    value: 'l',
    unselectedIcon: 'LeftGrey',
    selectedIcon: 'LeftWhite',
  },
  {
    value: 'c',
    unselectedIcon: 'CenterGrey',
    selectedIcon: 'CenterWhite',
  },
  {
    value: 'r',
    unselectedIcon: 'RightGrey',
    selectedIcon: 'RightWhite',
  },
  {
    value: 'j',
    unselectedIcon: 'JustifyGrey',
    selectedIcon: 'JustifyWhite',
  },
];
const VERTICAL_TOGGLES: ToggleOption<Editor.Styles.TableProperties['verticalAlignment']>[] = [
  {
    value: 't',
    unselectedIcon: 'TopGrey',
    selectedIcon: 'TopWhite',
  },
  {
    value: 'c',
    unselectedIcon: 'MiddleGrey',
    selectedIcon: 'MiddleWhite',
  },
  {
    value: 'b',
    unselectedIcon: 'BottomGrey',
    selectedIcon: 'BottomWhite',
  },
];

const TextAlignment = ({ textAlignment, verticalAlignment, updateData }: TextAlignmentProps) => {
  const intl = useIntl();

  const [localHorizontalAlign, setLocalHorizontalAlign] = useState(
    textAlignment ?? DEFAULT_PROPERTIES.textAlignment,
  );
  const [localVerticalAlign, setLocalVerticalAlign] = useState(
    verticalAlignment ?? DEFAULT_PROPERTIES.verticalAlignment,
  );

  const handleHorizontalChange = (newAlign: typeof textAlignment) => {
    setLocalHorizontalAlign(newAlign);
    updateData({ property: 'textAlignment', value: newAlign });
  };
  const handleVerticalChange = (newAlign: typeof verticalAlignment) => {
    setLocalVerticalAlign(newAlign);
    updateData({ property: 'verticalAlignment', value: newAlign });
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'TEXT_ALIGNMENT' })}
      initialCollapsed={false}
      contentMargin="1rem"
      testId="tableProperties-text-alignment-accordion"
    >
      <div className={styles.root}>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'HORIZONTAL_ALIGNMENT' })}
            testId="tableProperties-horizontal-alignment-field"
          >
            <div className={styles.row}>
              <ToggleGroup>
                {HORIZONTAL_TOGGLES.map((toggle) => (
                  <Toggle
                    key={`textAlignment-${toggle.value}`}
                    size="medium"
                    variant="group"
                    isToggled={localHorizontalAlign === toggle.value}
                    icon={
                      localHorizontalAlign === toggle.value
                        ? toggle.selectedIcon
                        : toggle.unselectedIcon
                    }
                    onClick={() => handleHorizontalChange(toggle.value)}
                    testId={`tableProperties-horizontal-alignment-${toggle.value}-toggle`}
                  />
                ))}
              </ToggleGroup>
            </div>
          </InputField>
        </div>
        <div className={styles.column}>
          <InputField
            label={intl.formatMessage({ id: 'VERTICAL_ALIGNMENT' })}
            testId="tableProperties-vertical-alignment-field"
          >
            <div className={styles.row}>
              <ToggleGroup>
                {VERTICAL_TOGGLES.map((toggle) => (
                  <Toggle
                    key={`verticalAlignment-${toggle.value}`}
                    size="medium"
                    variant="group"
                    isToggled={localVerticalAlign === toggle.value}
                    icon={
                      localVerticalAlign === toggle.value
                        ? toggle.selectedIcon
                        : toggle.unselectedIcon
                    }
                    onClick={() => handleVerticalChange(toggle.value)}
                    testId={`tableProperties-vertical-alignment-${toggle.value}-toggle`}
                  />
                ))}
              </ToggleGroup>
            </div>
          </InputField>
        </div>
      </div>
    </Accordion>
  );
};

export default TextAlignment;
