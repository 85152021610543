import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { useSelector } from './redux';

const useCurrentUser = () => {
  const userId = useSelector((state) => state.auth.userId);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const result = useGetCurrentUserQuery(undefined, { skip: !userId || !authenticated });

  return result;
};

export default useCurrentUser;
