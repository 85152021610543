import { Ref, forwardRef } from 'react';
import { Card } from 'dodoc-design-system';
import { CardProps } from 'dodoc-design-system/build/types/Components/Card/Card';

import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import DueDateBanner from './DueDateBanner/DueDateBanner';

const BaseCard = forwardRef(({ children, ...props }: CardProps, ref: Ref<HTMLDivElement>) => {
  return (
    <Card {...props} width={props.sidebar ? '100%' : props.width} ref={ref}>
      {children}
    </Card>
  );
});

export default Object.assign(BaseCard, {
  Header,
  Body,
  Footer,
  DueDateBanner,
});
