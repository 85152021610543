import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Tooltip } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

import { useGetTenantSettingsQuery } from '_common/services/api/authority';
import { useLazyDownloadQuery } from '_common/services/api/FileApi';

import WopiActions from './WopiActions';

import styles from './Topbar.module.scss';
import { useObjectPreview } from '../ObjectPreviewContext';
import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setObjectPreview } from '../ObjectPreviewSlice';
import { useMemo } from 'react';

const ICONS: Record<string, IconTypes['24']> = {
  image: 'EditorImage',
  docx: 'FileWord',
  pdf: 'FilePDFGrey',
  presentation: 'FilePowerPoint',
};

const Topbar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { type, object, loading } = useObjectPreview();

  const [downloadBlob] = useLazyDownloadQuery();
  const { data: tenantSettings } = useGetTenantSettingsQuery();

  const canConvert = useMemo(
    () =>
      !!(object?.user_permissions.includes('owner') || object?.user_permissions.includes('admin')),
    [object],
  );

  const download = async () => {
    if (object) {
      const { data: blob } = await downloadBlob({ object_id: object.id });
      if (blob) {
        const name = object.name;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }
  };

  const open = () => {
    if (object) {
      dispatch(
        openAndUpdateModal({
          modal: 'ConvertToModal',
          data: {
            objectIds: [object.id],
            openAfterConverting: true,
          },
        }),
      );
    }
  };

  const close = () => {
    dispatch(setObjectPreview({ id: null }));
  };

  return (
    <div className={styles.topbar} data-testid="object-preview-topbar">
      <div className={styles.buttons} data-testid="object-preview-buttons">
        <Tooltip
          content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
          disabled={canConvert}
          testId="object-preview-open-tooltip"
        >
          <>
            {type === 'docx' && (
              <Button
                variant="link"
                size="medium"
                icon="Open"
                margin="0 0.5rem 0 0"
                onClick={open}
                disabled={!canConvert}
                testId="object-preview-docx-open"
              >
                <FormattedMessage id="OPEN_IN_EDITOR" />
              </Button>
            )}
            {(type === 'pdf' || type === 'presentation') && (
              <Button
                variant="link"
                size="medium"
                icon="Open"
                margin="0 0.5rem 0 0"
                onClick={open}
                testId={
                  type === 'pdf' ? 'object-preview-pdf-open' : 'object-preview-presentation-open'
                }
                disabled={
                  (type === 'pdf'
                    ? !tenantSettings?.['product.pdf_reviewer']
                    : !tenantSettings?.['product.presentation_reviewer']) ||
                  !canConvert ||
                  loading
                }
              >
                <FormattedMessage id="OPEN_IN_REVIEWER" />
              </Button>
            )}
          </>
        </Tooltip>
        <Button
          variant="link"
          size="medium"
          icon="Download"
          onClick={download}
          testId="object-preview-download-button"
          disabled={loading}
        >
          <FormattedMessage id="storage.actionBar.actions.download" />
        </Button>
        {object.wopi && <WopiActions object={object} />}
      </div>
      <div className={styles.title} data-testid="object-preview-title">
        {type ? <Icon margin="0 1rem 0 0" size={24} icon={ICONS[type]} /> : null}
        {object.name}
      </div>
      <div className={styles.close} data-testid="object-preview-close">
        <Button
          variant="link"
          size="medium"
          icon="CloseGrey"
          onClick={close}
          testId="object-preview-close-icon-button"
        />
      </div>
    </div>
  );
};

export default Topbar;
