import { store } from '_common/redux';
import { store as testStore } from '_common/utils/test/render';
import {
  addUserOnline,
  removeUserOnline,
  setUsersOnline,
  setCurrentPage,
  setLoadedVersion,
  setZoomValue,
} from 'PDF/redux/PDFGeneralSlice';
import { selectAnnotation, setAnnotations, setCreating } from 'PDF/redux/PDFAnnotationsSlice';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import appApi from 'App/redux/AppApi';

export class ReduxInterface {
  static getStore() {
    if (process.env.NODE_ENV === 'test') {
      return testStore;
    }

    return store;
  }

  static getPlatform() {
    return ReduxInterface.getStore().getState().app.platform;
  }

  static setCurrentPage(payload: Parameters<typeof setCurrentPage>[0]) {
    ReduxInterface.getStore().dispatch(setCurrentPage(payload));
  }

  static usersOnline(payload: Parameters<typeof setUsersOnline>[0]) {
    ReduxInterface.getStore().dispatch(setUsersOnline(payload));
  }

  static userJoined(payload: Parameters<typeof addUserOnline>[0]) {
    ReduxInterface.getStore().dispatch(addUserOnline(payload));
  }

  static userLeft(payload: Parameters<typeof removeUserOnline>[0]) {
    ReduxInterface.getStore().dispatch(removeUserOnline(payload));
  }

  static loadAnnotations(payload: Parameters<typeof setAnnotations>[0]) {
    ReduxInterface.getStore()?.dispatch(setAnnotations(payload));
  }

  static selectAnnotation(payload: Parameters<typeof selectAnnotation>[0]) {
    ReduxInterface.getStore().dispatch(selectAnnotation(payload));
  }

  static getAnnotationCreationValues() {
    return ReduxInterface.getStore().getState().pdf.annotations.creation;
  }

  static setCreating(payload: Parameters<typeof setCreating>[0]) {
    return ReduxInterface.getStore().dispatch(setCreating(payload));
  }

  static setLoadedVersion(payload: Parameters<typeof setLoadedVersion>[0]) {
    return ReduxInterface.getStore().dispatch(setLoadedVersion(payload));
  }

  static invalidateApiTags(payload: Parameters<typeof appApi.util.invalidateTags>[0]) {
    return ReduxInterface.getStore().dispatch(appApi.util.invalidateTags(payload));
  }

  static zoomIn() {
    return ReduxInterface.getStore().dispatch(
      setZoomValue(ReduxInterface.getStore().getState().pdf.general.zoom + 0.1),
    );
  }

  static zoomOut() {
    return ReduxInterface.getStore().dispatch(
      setZoomValue(ReduxInterface.getStore().getState().pdf.general.zoom - 0.1),
    );
  }

  static completeAction(payload: Parameters<typeof completeAction>[0]) {
    return ReduxInterface.getStore().dispatch(completeAction(payload));
  }

  static setPulseData(payload: Parameters<typeof setPulseData>[0]) {
    return ReduxInterface.getStore().dispatch(setPulseData(payload));
  }
}
