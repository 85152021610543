import { NodeUtils, TableOfContents } from '../../models';
import BaseController from '../BaseController';

export class TableOfContentsController extends BaseController {
  private toc?: TableOfContents;
  constructor(data: Editor.Data.State) {
    super(data);
    this.handleTableOfContentsUpdate = this.handleTableOfContentsUpdate.bind(this);
  }

  start(documentId: string): void {
    if (this.Data.models) {
      this.toc = this.Data.models?.get(this.Data?.models.TYPE_NAME.TOC, `TOC${documentId}`);
      this.toc.on('LOADED', this.handleTableOfContentsUpdate);
      this.toc.on('UPDATED', this.handleTableOfContentsUpdate);
    }
  }

  private getParamsFromOptions(
    options?: any,
  ): TableOfContents.TOCSectionProperties | TableOfContents.TOLSectionProperties | undefined {
    if (!options) {
      return;
    }
    if (!options.rl && options.cpt) {
      return {
        label: options.cpt,
        leader: options.tabs?.[0]?.l,
        showPageNumber: options.spn,
        alignNumberRight: options.anr,
        useLink: options.l,
      };
    }
    if (options.rl && !options.cpt) {
      return {
        representingLevels: options.rl,
        leader: options.tabs?.[0]?.l,
        showPageNumber: options.spn,
        alignNumberRight: options.anr,
        useLink: options.l,
      };
    }
  }

  private getTOCOptionsFromParams(options?: TableOfContents.TOCSectionProperties) {
    if (!options) {
      return;
    }
    return {
      rl: options.representingLevels,
      tabs: [
        {
          t: 'r',
          l: options.leader || 'n',
        },
      ],
      spn: options.showPageNumber,
      anr: options.alignNumberRight,
      l: options.useLink,
    };
  }

  private getTOLOptionsFromParams(options?: TableOfContents.TOLSectionProperties) {
    if (!options) {
      return;
    }
    return {
      cpt: options.label,
      tabs: [
        {
          t: 'r',
          l: options.leader || 'n',
        },
      ],
      spn: options.showPageNumber,
      anr: options.alignNumberRight,
      l: options.useLink,
    };
  }

  private handleTableOfContentsUpdate(data: any) {
    this.Data.events?.emit('LOAD_TABLE_OF_CONTENTS', data?.toc || { list: [], data: {} });
  }

  getDocumentMainTitle(force: boolean) {
    return this.toc?.getDocumentMainTitle();
  }

  async updateTOCSection(
    blockId: string,
    tocId: string,
    options?: TableOfContents.TOCSectionProperties,
  ): Promise<boolean> {
    const tocSection = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, blockId);
    let data: Editor.Data.Node.Data | undefined = tocSection?.get();
    if (!data) {
      throw new Error('Invalid data');
    }

    if (data.id !== tocId) {
      data = NodeUtils.getElementDataById(data, tocId)?.data;
    }

    if (NodeUtils.isTableOfContentsData(data)) {
      return new Promise((resolve, reject) => {
        this.Data.transport.dispatchEvent(
          'UPDATE:TOC:SECTION',
          {
            blockId,
            tocId,
            options: this.getTOCOptionsFromParams(options),
          },
          (response: Realtime.Transport.RealtimeResponse) => {
            if (response.success) {
              resolve(true);
            } else {
              reject(response.error);
            }
          },
        );
      });
    } else {
      throw new Error('Invalid ToC Type');
    }
  }

  async updateLabelSection(
    blockId: string,
    tolId: string,
    options?: TableOfContents.TOLSectionProperties,
  ): Promise<boolean> {
    const tocSection = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, blockId);
    let data: Editor.Data.Node.Data | undefined = tocSection?.get();
    if (!data) {
      throw new Error('Invalid data');
    }

    if (data.id !== tolId) {
      data = NodeUtils.getElementDataById(data, tolId)?.data;
    }

    if (NodeUtils.isTableOfLabelsData(data)) {
      return new Promise((resolve, reject) => {
        this.Data.transport.dispatchEvent(
          'UPDATE:LABEL:SECTION',
          {
            blockId,
            tolId,
            options: this.getTOLOptionsFromParams(options),
          },
          (response: Realtime.Transport.RealtimeResponse) => {
            if (response.success) {
              resolve(true);
            } else {
              reject(response.error);
            }
          },
        );
      });
    }

    throw new Error('Invalid ToL Type');
  }

  getDefaultTocDefinition() {
    return this.getParamsFromOptions(this.Data.templates?.getTocDefinition()?.properties);
  }

  getDefaultTolDefinition(label: string) {
    return this.getParamsFromOptions(this.Data.templates?.getTolDefinition(label)?.properties);
  }

  getPropertiesFromTOCSection(
    blockId: string,
    tocId: string,
  ): TableOfContents.TOCSectionProperties | null {
    const tocSection = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, blockId);
    let data: Editor.Data.Node.Data | undefined = tocSection?.get();
    if (!data) {
      return null;
    }

    if (data.id !== tocId) {
      data = NodeUtils.getElementDataById(data, tocId)?.data;
    }

    if (NodeUtils.isTableOfContentsData(data)) {
      return {
        representingLevels: data.properties.rl || [],
        showPageNumber: data.properties.spn || false,
        alignNumberRight: data.properties.anr || false,
        leader: data.properties.tabs?.[0]?.l || 'n',
        useLink: data.properties.l || false,
      };
    }

    return null;
  }

  getPropertiesFromTOLSection(
    blockId: string,
    tolId: string,
  ): TableOfContents.TOLSectionProperties | null {
    const tolSection = this.Data.models?.get(this.Data?.models.TYPE_NAME.NODE, blockId);
    let data: Editor.Data.Node.Data | undefined = tolSection?.get();

    if (!data) {
      return null;
    }

    if (data.id !== tolId) {
      data = NodeUtils.getElementDataById(data, tolId)?.data;
    }

    if (NodeUtils.isLegacyTableOfFiguresData(data)) {
      const templateDef =
        this.getParamsFromOptions(this.Data.templates?.getTolDefinition('Figure')?.properties) ||
        {};
      return {
        label: 'Figure',
        showPageNumber: data.properties.spn || false,
        alignNumberRight: data.properties.anr || false,
        leader: data.properties.tabs?.[0]?.l || 'n',
        useLink: data.properties.l || false,
        ...templateDef,
      };
    }

    if (NodeUtils.isLegacyTableOfTablesData(data)) {
      const templateDef =
        this.getParamsFromOptions(this.Data.templates?.getTolDefinition('Table')?.properties) || {};
      return {
        label: 'Table',
        showPageNumber: data.properties.spn || false,
        alignNumberRight: data.properties.anr || false,
        leader: data.properties.tabs?.[0]?.l || 'n',
        useLink: data.properties.l || false,
        ...templateDef,
      };
    }

    if (NodeUtils.isTableOfLabelsData(data)) {
      return {
        label: data.properties.cpt,
        showPageNumber: data.properties.spn || false,
        alignNumberRight: data.properties.anr || false,
        leader: data.properties.tabs?.[0]?.l || 'n',
        useLink: data.properties.l || false,
      };
    }

    return null;
  }

  stop(): void {}

  destroy(): void {
    if (this.toc) {
      this.toc.destroy();
    }
  }
}
