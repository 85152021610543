import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import {
  AdvancedFilter,
  FilterOption,
  OPTION_SEQUENCE,
} from '_common/components/AdvancedFilterRow/AdvancedFilterOptions';

const useOldFilterParser = () => {
  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const getSanitizedString = (value: string) => value.toLowerCase().replace(' ', '_');
  const oldFilterParser = useCallback(
    (filter: SearchCondition | LegacySearchCondition) => {
      //Old filter parse
      if (typeof filter.name !== 'string') {
        let oldFilter = filter as LegacySearchCondition;
        if (
          !(
            oldFilter.name.name in OPTION_SEQUENCE ||
            //Field overrides 'mime_type' for some file types
            oldFilter.name.name === 'type' ||
            //Old option replaced by 'deadline'
            oldFilter.name.name === 'due'
          )
        ) {
          return;
        }

        const newId = uuid();
        let parsedFilter: AdvancedFilter = {
          id: newId,
          option: oldFilter.name.name as FilterOption,
        };
        if (oldFilter.name.name === 'type') {
          parsedFilter.option = 'mime_type';
        }
        if (oldFilter.name.name === 'due') {
          parsedFilter.option = 'deadline';
        }

        if (oldFilter.metadataField) {
          const metadataName = Object.values(metadata).find(
            (option) => option.id === oldFilter.metadataField?.name,
          )?.name;
          //If old metadata cannot be handled
          if (!metadataName) {
            return;
          }

          parsedFilter.metadataOption = getSanitizedString(metadataName);

          if (oldFilter.operator.name) {
            parsedFilter.operator = oldFilter.operator.name;
          }
          if (
            !oldFilter.isEmpty &&
            (oldFilter.value.label || oldFilter.startDate || oldFilter.textInput)
          ) {
            parsedFilter.value =
              oldFilter.value.label || oldFilter.startDate || oldFilter.textInput;
          }
          if (oldFilter.isEmpty || oldFilter.endDate) {
            parsedFilter.auxValue = oldFilter.isEmpty ? 'true' : '' || oldFilter.endDate;
          }
        } else if (oldFilter.startDate) {
          parsedFilter.operator = oldFilter.operator.name;
          parsedFilter.value = oldFilter.startDate;
          parsedFilter.auxValue = oldFilter.endDate;
        } else if (oldFilter.document) {
          //Is Reference Priority
          parsedFilter.value = oldFilter.operator.name;
          parsedFilter.auxValue = oldFilter.document ? 'is' : '';
        } else if (oldFilter.name.name === 'mime_type' || oldFilter.name.name === 'type') {
          parsedFilter.value = oldFilter.operator.name;
          if (oldFilter.value.name) {
            parsedFilter.auxValue = oldFilter.value.name;
          }
        } else if (oldFilter.name.name === 'status') {
          parsedFilter.value = oldFilter.operator.label;
        } else if (oldFilter.textInput) {
          parsedFilter.operator = oldFilter.operator.name;
          parsedFilter.value = oldFilter.textInput;
        }

        return parsedFilter;
      } else {
        let oldFilter = filter as SearchCondition;
        const newId = uuid();
        return {
          id: newId,
          option: oldFilter.name === 'type' ? 'mime_type' : (oldFilter.name as FilterOption),
          operator: oldFilter.operator,
          value: oldFilter.value,
          metadataOption: oldFilter.metadataField,
          auxValue: oldFilter.endDate,
        };
      }
    },
    [metadata],
  );

  return oldFilterParser;
};

export default useOldFilterParser;
