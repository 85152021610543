import { createContext, ReactNode, useContext } from 'react';
import useValidAccounts from './hooks/useValidAccounts';

type AuthContextValue = {
  accounts: Record<string, ParsedProfile<Account>>;
};

const AuthContext = createContext<AuthContextValue>({ accounts: {} });

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const accounts = useValidAccounts();
  return <AuthContext.Provider value={{ accounts }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export default AuthContextProvider;
