import { useMemo, useState } from 'react';

import ViewCommentCard from './ViewCommentCard';
import EditableCommentCard from './EditableCommentCard';
import { useVirtualizedList } from '_common/suite/components/Card/VirtualizedList/VirtualizedListContext';

export type CommentCardProps = {
  id?: string;
  user: UserId;
  mainComment?: MyAny;
  sidebar?: boolean;
  subcomment?: boolean;
  isTask?: boolean;
  selected?: boolean;
  isReadOnlyMode?: boolean;
} & (
  | { isTemporary: true; comment?: undefined }
  | { isTemporary?: false | undefined; comment: Editor.Comment }
);

const CommentCard = ({
  id,
  comment,
  user,
  isReadOnlyMode,
  isTemporary,
  selected,
  sidebar,
}: CommentCardProps) => {
  const { editingCards, setEditingCards, virtualized } = useVirtualizedList();

  const [localEditMode, setLocalEditMode] = useState(false);
  const editMode = useMemo(
    () => isTemporary || (virtualized ? editingCards[comment.id] : localEditMode),
    [isTemporary, virtualized, editingCards, comment, localEditMode],
  );

  const handleSetEditMode = (isEdit: boolean) => {
    if (virtualized) {
      setEditingCards(isTemporary ? 'temporary' : comment.id, isEdit);
    } else {
      setLocalEditMode(isEdit);
    }
  };

  if (editMode) {
    if (isTemporary) {
      return (
        <EditableCommentCard
          id={id}
          isTemporary={isTemporary}
          user={user}
          setEditMode={handleSetEditMode}
          selected={selected}
          sidebar={sidebar}
          testId={`insert-comment-temporary-card`}
        />
      );
    } else {
      return (
        <EditableCommentCard
          id={id}
          comment={comment}
          user={user}
          setEditMode={handleSetEditMode}
          selected={selected}
          sidebar={sidebar}
          testId={comment.id}
        />
      );
    }
  }

  if (comment) {
    return (
      <ViewCommentCard
        id={id}
        comment={comment}
        user={user}
        isReadOnlyMode={isReadOnlyMode}
        selected={selected}
        sidebar={sidebar}
        setEditMode={handleSetEditMode}
        testId={comment.id}
      />
    );
  }

  return null;
};

export default CommentCard;
