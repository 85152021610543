import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Spinner from '_common/components/Spinner/Spinner';
import { LogoContainer } from '_common/components';
import AuthFooter from 'Auth/components/AuthFooter/AuthFooter';
import NotFoundElement from './NotFoundElement/NotFoundElement';
import PasswordRequiredElement from './PasswordRequiredElement/PasswordRequiredElement';
import DownloadElement from './DownloadElement/DownloadElement';
import styles from './PublicLinkPage.module.scss';
import { usePublicLinkInfoQuery } from './PublicLinkPageApi';

const LINK_STATE = {
  LOADING: 'LOADING',
  NOT_FOUND: 'NOT_FOUND',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  DOWNLOAD: 'DOWNLOAD',
};

const PublicLinkPage = () => {
  const [state, setState] = useState('');
  const { hash } = useParams<{ type: string; hash: string }>();
  const [password, setPassword] = useState('');
  const { data, isLoading: loading, error } = usePublicLinkInfoQuery({ hash, password });
  useEffect(() => {
    if (loading) {
      setState(LINK_STATE.LOADING);
    } else if (error && 'data' in error) {
      switch (error.status) {
        case 400:
          setState(LINK_STATE.PASSWORD_REQUIRED);
          break;

        case 404:
          setState(LINK_STATE.NOT_FOUND);
          break;

        default:
          setState(LINK_STATE.NOT_FOUND);
          break;
      }
    } else if (data) {
      setState(LINK_STATE.DOWNLOAD);
    } else {
      setState(LINK_STATE.NOT_FOUND);
    }
  }, [loading, data, error]);

  const info = {
    ...data,
    password,
  };

  const renderElement = () => {
    switch (state) {
      case LINK_STATE.LOADING:
        return <Spinner color="blue" />;

      case LINK_STATE.PASSWORD_REQUIRED:
        return (
          <PasswordRequiredElement
            password={password}
            setPassword={setPassword}
            errorState={error && 'data' in error && error.data.password?.[0]}
          />
        );

      case LINK_STATE.NOT_FOUND:
        return <NotFoundElement />;

      case LINK_STATE.DOWNLOAD:
        return <DownloadElement data={info} />;

      default:
        return <NotFoundElement />;
    }
  };

  return (
    <div className={styles.root}>
      <LogoContainer size="large" expanded color="blue" testId="public-link-page-logo" />
      <div className={styles.content}>{renderElement()}</div>
      <AuthFooter />
    </div>
  );
};

export default PublicLinkPage;
