import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { usePDFContext } from 'PDF/PDFContext';
import { notify } from '_common/components/ToastSystem';
import { useAnnotationContext } from './AnnotationContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

import FreeTextAnnotation from './FreeTextAnnotation';
import ROIAnnotation from './ROIAnnotation';
import SVGAnnotation from './SVGAnnotation';

const AnnotationOverlay = () => {
  const intl = useIntl();
  const manager = usePDFContext();
  const { annotation, isSelected, pageNum } = useAnnotationContext();
  const { canDeleteAnnotation } = usePDFPermissions();

  const handleDeleteKey = async (e: KeyboardEvent) => {
    if (canDeleteAnnotation(annotation)) {
      if (e.key === 'Delete') {
        try {
          await manager.deleteAnnotation(pageNum, annotation.id);
          notify({
            type: 'success',
            title: intl.formatMessage({ id: 'COMMENT_DELETED' }),
            message: intl.formatMessage({ id: 'THE_COMMENT_WAS_SUCCESSFULLY_DELETED' }),
          });
        } catch (e) {
          throw e;
        }
      }
    }
  };

  useEffect(() => {
    if (isSelected) {
      document.addEventListener('keydown', handleDeleteKey);
      return () => {
        document.removeEventListener('keydown', handleDeleteKey);
      };
    }
  }, [isSelected]);

  switch (annotation.subtype) {
    case 'Highlight':
    case 'Underline':
    case 'StrikeOut':
      return <ROIAnnotation />;
    case 'Ink':
    case 'Circle':
    case 'Line':
    case 'Polygon':
    case 'PolyLine':
    case 'Square':
      return <SVGAnnotation />;
    case 'Task':
      return annotation.quadPoints ? <ROIAnnotation /> : null;
    case 'FreeText':
      return <FreeTextAnnotation />;
    default:
      logger.debug('No overlay for annotation of type ' + annotation.subtype, annotation);
      return null;
  }
};

export default memo(AnnotationOverlay);
