import NormalMode from './NormalMode/NormalMode';
import SuggestionMode from './SuggestionMode/SuggestionMode';
import DisabledMode from './DisabledMode.js/DisabledMode';

export default class EditionModeFactory {
  static MODE_NORMAL = 'NORMAL';
  static MODE_SUGGESTING = 'SUGGESTING';
  static MODE_DISABLED = 'DISABLED';

  static getEditionMode(
    mode,
    page,
    selectionManager,
    navigationManager,
    renderer,
    dataManager,
    changeTracker,
    clipboard,
    stylesHandler,
    documentParser,
    visualizerManager,
  ) {
    switch (mode) {
      case EditionModeFactory.MODE_NORMAL:
        return new NormalMode(
          page,
          selectionManager,
          navigationManager,
          renderer,
          dataManager,
          changeTracker,
          clipboard,
          stylesHandler,
          documentParser,
          visualizerManager,
        );
      case EditionModeFactory.MODE_SUGGESTING:
        return new SuggestionMode(
          page,
          selectionManager,
          navigationManager,
          renderer,
          dataManager,
          changeTracker,
          clipboard,
          stylesHandler,
          documentParser,
          visualizerManager,
        );

      case EditionModeFactory.MODE_DISABLED:
        return new DisabledMode(
          page,
          selectionManager,
          navigationManager,
          renderer,
          dataManager,
          changeTracker,
          clipboard,
          stylesHandler,
          documentParser,
          visualizerManager,
        );
      default:
        throw new Error(`Invalid edition mode : ${mode}`);
    }
  }

  static availableModes() {
    return {
      MODE_NORMAL: EditionModeFactory.MODE_NORMAL,
      MODE_SUGGESTING: EditionModeFactory.MODE_SUGGESTING,
      MODE_DISABLED: EditionModeFactory.MODE_DISABLED,
    };
  }
}
