import { BaseOperation } from '../BaseOperation';
import { ParseFigureForParagaphOperation } from './ParseFigureForParagaphOperation';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { UpdateImageSizeOperation } from './UpdateImageSizeOperation';

export class UpdateImageSourceOperation extends BaseOperation<Editor.Data.Node.Model> {
  protected path: Editor.Selection.Path;
  private imageData: Editor.Data.Node.ImageData;
  private source: string;
  private height: number;
  private width: number;

  constructor(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    imageData: Editor.Data.Node.ImageData,
    source: string,
    height: number, // in points
    width: number, // in points
  ) {
    super(baseModel);
    this.imageData = imageData;
    this.source = source;
    this.height = height;
    this.width = width;
    this.path = path;

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const baseData = this.model.selectedData();
    if (
      !this.model ||
      !this.path ||
      !this.source ||
      this.height == null ||
      this.width == null ||
      !baseData
    ) {
      return this;
    }

    const closestFigure = NodeUtils.closestOfTypeByPath(baseData, this.path, 'figure');

    // check if level0 is a Figure and change to paragraph
    if (closestFigure && NodeUtils.isFigureData(closestFigure.data)) {
      const parseFigureForParagaphOperation = new ParseFigureForParagaphOperation(
        this.model,
        closestFigure.path,
      );
      this.ops.push(...parseFigureForParagaphOperation.getOps());
    }

    const editorSource = this.imageData.properties?.es;

    let op = this.getObjectOperationforPathValue(editorSource, this.source, [
      ...this.path,
      this.model.KEYS.PROPERTIES,
      'es',
    ]);
    if (op) {
      this.ops.push(op);
    }

    if (this.imageData.properties?.os != null) {
      op = this.getObjectOperationforPathValue(this.imageData.properties?.os, null, [
        ...this.path,
        this.model.KEYS.PROPERTIES,
        'os',
      ]);
      if (op) {
        this.ops.push(op);
      }
    }

    if (this.imageData.properties?.w) {
      // maintain width update height if needed
      const newHeight = (this.imageData.properties.w * this.height) / this.width;

      op = this.getObjectOperationforPathValue(this.imageData.properties?.h, newHeight, [
        ...this.path,
        'properties',
        'h',
      ]);
      if (op) {
        this.ops.push(op);
      }
    } else {
      const updateImageSizeOperation = new UpdateImageSizeOperation(
        this.model,
        this.path,
        this.imageData,
        this.height,
        this.width,
      );
      this.ops.push(...updateImageSizeOperation.getOps());
    }

    return this;
  }
}
