import { Avatar } from 'dodoc-design-system';
import type { AvatarProps } from 'dodoc-design-system/build/types/Components/Avatar/Avatar';

/**
 * margin
text
size
color
 */

type GroupAvatarProps = {
  text: string;
} & Pick<AvatarProps, 'size' | 'color' | 'margin'>;

const GroupAvatar = ({ text, size = 'medium', color, margin }: GroupAvatarProps) => {
  return (
    <Avatar size={size} color={color} text={text} margin={margin} testId={`avatar-group-${text}`} />
  );
};

export default GroupAvatar;
