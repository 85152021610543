import { Select, Slider } from 'dodoc-design-system';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from '_common/hooks';

type ZoomControlsProps = {
  zoom: number;
  changeZoom: (newZoom: number) => void;
  zoomValues?: number[];
};

const ZoomControls = ({
  zoom,
  changeZoom,
  zoomValues = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 4, 8, 16, 24, 32, 64],
}: ZoomControlsProps) => {
  const zoomSelectValue = useMemo(() => {
    return { label: `${(zoom * 100).toFixed(0)}%`, value: `${zoom}` };
  }, [zoom]);

  const [localZoomValue, setLocalZoomValue] = useState(zoom);
  const [newZoom, setNewZoom] = useState('');
  const debouncedZoom = useDebounce(localZoomValue, 500);
  const zoomOptions = useMemo(
    () => zoomValues.map((zoomValue) => ({ label: `${zoomValue * 100}%`, value: `${zoomValue}` })),
    [zoomValues],
  );

  useEffect(() => {
    setLocalZoomValue(zoom);
  }, [zoom]);

  useEffect(() => {
    changeZoom(debouncedZoom);
  }, [debouncedZoom]);

  return (
    <>
      <Slider
        variant="discrete"
        min={+zoomOptions[0].value * 100}
        middle={100}
        max={+zoomOptions[zoomOptions.length - 1].value * 100}
        step={5.5555}
        value={Number((zoom * 100).toFixed(0))}
        onChange={(value) => {
          setLocalZoomValue(value / 100);
        }}
        rangeValues={false}
        testId="footer-zoom"
      />
      <div style={{ marginLeft: '2rem' }}>
        <Select
          clearable={false}
          creatable
          width="14rem"
          size="medium"
          options={zoomOptions}
          onChange={({ value }, meta) => {
            if (meta?.action === 'create-option') {
              const zoom = +value / 100;
              changeZoom(+zoom);
            } else {
              changeZoom(+value);
            }
          }}
          value={
            zoomOptions.find((defualtValue) => defualtValue.value === zoomSelectValue.value) ||
            zoomSelectValue
          }
          menuPlacement="top"
          menuPosition="fixed"
          onInputChange={(e: string) => {
            setNewZoom(e);
          }}
          inputValue={newZoom}
          formatCreateLabel={(label) => (label.endsWith('%') ? label : `${label}%`)}
          isValidNewOption={(option) =>
            !!option && zoomOptions.every((zoomOption) => `${+zoomOption.value * 100}` !== option)
          }
          customStyles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 1052,
            }),
          }}
          testId="zoom"
        />
      </div>
    </>
  );
};

export default ZoomControls;
