import type { ConfigType } from 'dayjs';

import { dayjsWithTimezone } from 'utils';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

export type FormattedDateProps = {
  /**
   * short: 20/03/21
   *
   * numeric: 20/03/2021
   *
   * expansive: 20 Jan, 2021
   *
   * extended: 20 January 2021
   *
   * simplistic: 20 Jan 2021
   *
   * noyear: 20 Jan
   *
   * @default "numeric"
   */
  type?: 'short' | 'numeric' | 'expansive' | 'extended' | 'simplistic' | 'noyear';
  date: ConfigType;
};

export const DATE_FORMATS = {
  md: {
    short: 'MM/DD/YY',
    numeric: 'MM/DD/YYYY',
    expansive: 'MMM DD, YYYY',
    extended: 'MMMM DD YYYY',
    simplistic: 'MMM DD YYYY',
    noyear: 'MMM DD',
  },
  dm: {
    short: 'DD/MM/YY',
    numeric: 'DD/MM/YYYY',
    expansive: 'DD MMM, YYYY',
    extended: 'DD MMMM YYYY',
    simplistic: 'DD MMM YYYY',
    noyear: 'DD MMM',
  },
};

const FormattedDate = ({ date, type = 'numeric' }: FormattedDateProps) => {
  const { data: user } = useGetCurrentUserQuery();

  if (!user) {
    return null;
  }

  const dayjs = dayjsWithTimezone(date, user.profile.timezone);

  return <span>{dayjs.format(DATE_FORMATS[user.profile.date_format || 'dm'][type])}</span>;
};

export default FormattedDate;
