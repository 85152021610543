import useOutline from '../../useOutline';
import ChartBase from '../ChartBase/ChartBase';
import { useChartColor, useChartPlotArea } from '../hooks';
import useFunnelSeries from '../series/useFunnelSeries';
import * as echarts from 'echarts';

const defaultAxisLabelColor = {
  fill: {
    color: { mods: [], base: '595959' },
    type: 'solid',
  },
};

const defaultAxisLineColor = {
  fill: {
    color: { mods: [], base: 'D9D9D9' },
    type: 'solid',
  },
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.FunnelSeriesOption[] | undefined => {
  const { funnelSeries } = useFunnelSeries();
  const series: echarts.FunnelSeriesOption[] = [];

  shape.chartSpace.chart.plotArea.plotAreaRegion?.series.forEach((chart) => {
    if (chart.layoutId === 'funnel') {
      const chartSeries = funnelSeries({ chart: shape });
      series.push(chartSeries[0]);
    }
  });
  return series;
};

const FunnelChart = ({ shape }: { shape: Presentation.Data.ChartShape }) => {
  //@ts-expect-error this isn't supported yet
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea?.plotAreaRegion?.plotSurface);
  const series = useSeries({ shape });
  const { chartColor } = useChartColor();
  const { parseOutline } = useOutline();
  //@ts-expect-error axis is not supported yet
  const axisInfo = shape.chartSpace.chart.plotArea?.axis?.[0];
  const outline = parseOutline(axisInfo?.properties?.ln);
  const textOutline = parseOutline(
    axisInfo?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln,
  );

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: {
          show: false,
        },
        //@ts-expect-error echarts types
        yAxis: {
          show: axisInfo?.hidden ? false : true,
          //@ts-expect-error this isn't supported yet
          data: shape.chartSpace.chartData?.data[0].dim[0].lvl[0].pt
            .map((pt: { content: string }) => pt.content)
            .reverse(),
          axisTick: {
            show: axisInfo?.majorTickMarks ? true : false,
            inside: axisInfo?.majorTickMarks?.type === 'in' ? true : false,
          },
          minorTick: {
            show: axisInfo?.minorTickMarks ? true : false,
          },
          axisLine: {
            lineStyle: {
              ...outline,
              //@ts-expect-error color only supports string (solid color)
              color: outline.stroke ?? chartColor(defaultAxisLineColor?.fill),
            },
          },
          axisLabel: {
            color: chartColor(
              axisInfo?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill ??
                defaultAxisLabelColor?.fill,
            ),
            textBorderColor: textOutline?.stroke,
            textBorderWidth: textOutline?.strokeWidth ?? 0.75,
            textBorderType: [textOutline?.strokeDasharray],
            textBorderCap: textOutline?.strokeLinecap,
          },
        },
        series,
      }}
    />
  );
};

export default FunnelChart;
