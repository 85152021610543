//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { notify } from '_common/components/ToastSystem';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { ELEMENTS } from 'Editor/services/consts';
import { Logger } from '_common/services';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import ReduxInterface from 'Editor/services/ReduxInterface';

export default Mixin(
  (superclass: any) =>
    class CitationsEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      handleInsertCitation(citationId: string) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext;

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            actionContext = new ActionContext(ActionContext.ACTION.INSERT_INLINE);

            const range = EditorSelectionUtils.getRange();

            if (range) {
              let closestCitationGroup;
              if (range.collapsed) {
                closestCitationGroup = EditorDOMUtils.findClosestCitationGroup(
                  range.startContainer,
                  range.startOffset,
                ) as HTMLElement;
              } else {
                closestCitationGroup = EditorDOMUtils.findClosestCitationGroup(
                  range.commonAncestorContainer,
                  0,
                ) as HTMLElement;
              }

              if (
                closestCitationGroup &&
                closestCitationGroup.querySelector(
                  `citation-element[element_reference="${citationId}"]`,
                )
              ) {
                notify({
                  type: 'warning',
                  title: 'global.warning',
                  message: 'editor.warnings.insert',
                });
              } else {
                // insert citation

                const elementId = EditorDOMUtils.generateRandomNodeId();
                this.dataManager.citations.addCitationToDocument(citationId);

                const citationElement = DOMElementFactory.buildElement(
                  ELEMENTS.CitationElement.TAG,
                  {
                    element_type: ELEMENTS.CitationElement.IDENTIFIER,
                    element_reference: citationId,
                    id: elementId,
                  },
                );

                const level0Node = EditorDOMUtils.findFirstLevelChildNode(
                  this.page,
                  range.startContainer,
                );

                if (range.collapsed) {
                  // selection collapsed
                  this.handleInsertInlineNodeOnCollapsedSelection(
                    actionContext,
                    citationElement,
                    level0Node,
                    range.startContainer,
                  );
                } else {
                  // selection not collapsed (multi selection)
                  this.handleInsertInlineNodeOnMultiSelection(actionContext, citationElement);
                }

                // save changes to dom
                this.changeTracker.saveActionChanges(actionContext);
              }
            }
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            ReduxInterface.stopEditorLoading();
          }
        }
      }
    },
);
