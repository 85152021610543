import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';

import { openModal } from '_common/modals/ModalsSlice';

import { Setting } from 'Settings/components';

import styles from './UserSecurity.module.scss';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import SessionsHeading from './SessionHeading/SessionHeading';
import SessionTable from './SessionTable/SessionTable';
import DevicesHeading from './DevicesHeading/DevicesHeading';
import DevicesTable from './DevicesTable/DevicesTable';

const UserSecurity = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <SectionHeading
          sticky
          title={intl.formatMessage({ id: 'settings.security.password' })}
          testId="password-heading"
        />
      </IntlErrorBoundary>
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <Setting
          style={{ alignItems: 'flex-start' }}
          label={intl.formatMessage({ id: 'settings.security.password' })}
          onEdit={() => dispatch(openModal('ChangePasswordModal'))}
          onEditLabel="global.change"
          testId="password"
        />
      </IntlErrorBoundary>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <SessionsHeading />
      </IntlErrorBoundary>
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <SessionTable />
      </IntlErrorBoundary>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <DevicesHeading />
      </IntlErrorBoundary>
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <DevicesTable />
      </IntlErrorBoundary>
    </div>
  );
};

export default UserSecurity;
