import { NoteModel } from 'Editor/services/DataManager/controllers/Notes/NotesList';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { BaseViewModel } from '../BaseViewModel';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class NoteViewModel extends BaseViewModel {
  typeName = 'NoteViewModel';

  protected model?: NoteModel;
  view?: HTMLElement;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);

    this.model = this.Data.notes.get(this.id);
    this.model?.on('UPDATED', this.handleModelUpdate);
    this.handleModelUpdate();
  }

  private handleModelUpdate() {
    // this.Visualizer.hooks.afterSectionUpdate?.trigger(this.model.id);
    if (this.model) {
      const renderedFootnotes = document.querySelectorAll(
        `note-element[element-reference="${this.id}"]`,
      );
      for (let index = 0; index < renderedFootnotes.length; index++) {
        const element = renderedFootnotes.item(index);
        element.setAttribute('number', `${this.model.serial}`);
        const closestTracked = EditorDOMUtils.closest(element, [
          'TRACK-INS-ELEMENT',
          'TRACK-DEL-ELEMENT',
        ]);
        if (closestTracked instanceof Element) {
          const ref = closestTracked.getAttribute('element_reference');
          if (ref) {
            this.Data?.suggestions?.updateSuggestionContent(ref, {
              deleted: ActionContext.suggestedDeletedContent(ref) as any,
              inserted: ActionContext.suggestedInsertedContent(ref) as any,
            });
          }
        }
      }
    }
  }

  dispose() {
    if (this.model) {
      // unbind model
      this.model.off('UPDATED', this.handleModelUpdate);
    }
    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
