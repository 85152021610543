import { memo } from 'react';
import { Panel } from '_common/suite/components';
import Navigation from './Navigation';

const NavigationBoundary = () => {
  return (
    <Panel side="left" width="33rem">
      <Navigation />
    </Panel>
  );
};

export default memo(NavigationBoundary);
