import { useFavicon, useSelector, useDispatch } from '_common/hooks';
import { SuiteProvider } from '_common/suite';
import Title from './Title';
import Toolbar from './Toolbar';
import Navigation from './Navigation';
import SidebarPanel from './Sidebar/SidebarPanel';
import Slides from './Slides';
import Sidebar from './Sidebar';
import Footer from './Footer';
import PresentationModalConductor from './PresentationModalConductor';
import styles from './Presentation.module.scss';
import { useComments, useTasks } from './SyncStore';
import { useEffect, useRef } from 'react';
import { setElementToFocus } from './PresentationSlice';
import Onboarding from './Onboarding/Onboarding';
import { InstanceService } from '_common/services';
import { setCurrentDocument } from 'App/redux/onboardingSlice';
import usePresentationPersist from './hooks/usePresentationPersist';
import { DocumentTitle } from '_common/components';
import { useFocusCard } from './hooks';
import { clearFontValidationData } from 'App/redux/FontsSlice';

const Presentation = () => {
  useFavicon('/favicon-orange.ico');
  usePresentationPersist();
  const dispatch = useDispatch();
  const { focusCard } = useFocusCard();

  const timeoutRef = useRef<number>();

  const presentationId = useSelector((state) => state.presentation.general.presentationId);
  const pptData = useSelector((state) => (presentationId ? state.app.data[presentationId] : null));
  const active = useSelector((state) => state.onboarding.active.presentation);
  const started = useSelector((state) => state.onboarding.started.presentation);
  const zoom = useSelector((state) => state.presentation.general.zoom);
  const showNav = useSelector((state) => state.presentation.general.showNav);
  const rightPanel = useSelector((state) => state.presentation.general.rightPanel);
  const elementToFocus = useSelector((state) => state.presentation.general.elementToFocus);
  const comments = useComments();
  const tasks = useTasks();

  useEffect(() => {
    return () => {
      dispatch(clearFontValidationData());
    };
  }, []);

  useEffect(() => {
    if (elementToFocus && elementToFocus.documentId) {
      const { objectType, objectId } = elementToFocus;
      switch (objectType) {
        case 'comment': {
          if (comments && comments?.list.length > 0) {
            if (comments?.list.includes(objectId)) {
              focusCard({ objectId, objectType });
            }

            dispatch(setElementToFocus(undefined));
          }
          break;
        }
        case 'task': {
          if (tasks && tasks?.list.length > 0) {
            if (tasks?.list.includes(objectId)) {
              focusCard({ objectId, objectType });
            }

            dispatch(setElementToFocus(undefined));
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [elementToFocus, comments, tasks]);

  const handleObjectProcessing = ({
    objectId,
    objectType,
  }: {
    objectId: ObjectId;
    objectType: ObjectType;
  }) => {
    timeoutRef.current = window.setTimeout(() => {
      new InstanceService().getObjectStatus(objectType, objectId).then(({ data }) => {
        if (data.status !== 'processing' && presentationId) {
          if (data.id !== presentationId) {
            dispatch(setCurrentDocument({ target: 'presentation', id: presentationId, zoom }));
            window.open(`/presentation/${data.id}`, '_self');
          } else {
            dispatch(setCurrentDocument({ target: 'presentation', zoom, isOnboarding: true }));
          }
        } else {
          handleObjectProcessing({ objectId: data.id, objectType: objectType });
        }
      });
    }, 2500);
  };

  useEffect(() => {
    if ((active || started) && presentationId) {
      new InstanceService().getPresentationOnboarding().then(({ data }) => {
        if (data.status === 'processing') {
          handleObjectProcessing({ objectId: data.id, objectType: 'presentation' });
        }
        if (data.status === 'active') {
          if (data.id !== presentationId) {
            dispatch(setCurrentDocument({ target: 'presentation', id: presentationId, zoom }));
            window.open(`/presentation/${data.id}`, '_self');
          } else {
            dispatch(setCurrentDocument({ target: 'presentation', zoom, isOnboarding: true }));
          }
        }
      });
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [active, started, presentationId]);

  return (
    <DocumentTitle title={pptData?.name ?? 'doDOC'}>
      <SuiteProvider app="presentation">
        <div className={styles.root}>
          <Title />
          <Toolbar />
          {showNav && <Navigation />}
          {rightPanel && <SidebarPanel />}
          <Slides />
          <Sidebar />
          <Footer />
          <PresentationModalConductor />
          <Onboarding />
        </div>
      </SuiteProvider>
    </DocumentTitle>
  );
};

export default Presentation;
