import { useState, useEffect, FocusEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, Checkbox, Input, InputField } from 'dodoc-design-system';

import { INDENT_TYPE } from 'Editor/services/consts';
import Units from 'Editor/services/_Common/Units';
import NumberingUtils from '_common/utils/NumberingUtils';
import styles from 'Editor/modals/NewListModal/NewListModal.module.scss';
import type { Props } from './FormattingPane';

const RegularBox = ({
  type,
  leftText,
  rightText,
  listItems,
  setListItems,
  selectedLine,
  getLabels,
  specialIndentations,
  numberingTypes,
}: Props & {
  leftText: 'numbering_type' | 'indentation_left' | 'char_before';
  rightText: 'char_after' | 'indentation_right' | 'start_from';
}) => {
  const intl = useIntl();

  const currentItem = (listItems && listItems[selectedLine]) || {};

  const [leftIndentVal, setLeftIndentVal] = useState(
    leftText === 'indentation_left' ? currentItem[leftText] : '',
  );
  const [rightIndentVal, setRightIndentVal] = useState(
    rightText === 'indentation_right' ? currentItem[rightText] : '',
  );
  const [specialIndentValue, setSpecialIndentValue] = useState(currentItem.special_indent_value);
  const [startFromInputVal, setStartFromInputVal] = useState(currentItem[rightText]);

  useEffect(() => {
    if (currentItem) {
      setLeftIndentVal(leftText === 'indentation_left' ? currentItem[leftText] : '');
      setRightIndentVal(rightText === 'indentation_right' ? currentItem[rightText] : '');
      setSpecialIndentValue(currentItem.special_indent_value);
      if (type !== 'bullet') {
        setStartFromInputVal(currentItem.start_from);
      }
    }
  }, [selectedLine, currentItem]);

  useEffect(() => {
    if (type !== 'bullet') {
      setStartFromInputVal(currentItem.start_from);
    }
  }, [currentItem.numbering_type]);

  if (!listItems || !listItems[selectedLine]) {
    return null;
  }

  const handleSetters = (text: string) => {
    switch (text) {
      case 'indentation_left':
        setLeftIndentVal(currentItem.indentation_left);
        break;
      case 'indentation_right':
        setRightIndentVal(currentItem.indentation_right);
        break;
      case 'special_indent_value':
        setSpecialIndentValue(currentItem.special_indent_value);
        break;
      case 'start_from':
        setStartFromInputVal(currentItem.start_from);
        break;
      default:
    }
  };

  const handleOnBlurIndentationInput = (
    e: FocusEvent<HTMLInputElement>,
    text: 'indentation_left' | 'special_indent_value' | 'indentation_right',
  ) => {
    if (e.target.value !== currentItem[text]) {
      const maxPositivePts = 216;
      const maxNegativePts = -72;
      const valid = ['cm', 'in', 'pt', 'px'];
      const regex = /^\s*(([0-9]+)([\\.,][0-9]+)?)\s*(\S{2})?\s*$/gi;
      const match = regex.exec(e.target.value);

      if (match) {
        let unit = (match[4] || '').toLowerCase();
        const value = Number(match[1]) || 0;

        if (!valid.includes(unit)) {
          unit = 'cm';
        }
        let normalized = Units.convert(unit, Units.TYPE.POINT, value);

        if (normalized < maxNegativePts) {
          normalized = maxNegativePts;
        } else if (normalized > maxPositivePts) {
          normalized = maxPositivePts;
        }

        setListItems(`${Units.convert(Units.TYPE.POINT, Units.TYPE.CM, normalized)} cm`, text);
      }
      handleSetters(text);
    }
  };

  const getLeftContent = () => {
    const handleOnChange = (values: { value: string; label: string }) => {
      setListItems(values, 'numbering_type');
      setListItems(1, 'start_from');
    };

    if (leftText === 'numbering_type') {
      return (
        <>
          <div className={styles.text}>
            <FormattedMessage id={leftText.toUpperCase()} />
          </div>
          <Select
            creatable
            isSearchable={false}
            isClearable={false}
            options={numberingTypes}
            value={getLabels(
              'numberingTypes',
              listItems[selectedLine].numbering_type?.value ||
                listItems[selectedLine].numbering_type,
            )}
            onChange={handleOnChange}
            width="27rem"
            customStyles={{
              control: (provided: any) => ({ ...provided, height: '4rem' }),
            }}
            size="medium"
            testId="numbering-type"
          />
        </>
      );
    }

    if (leftText === 'char_before') {
      return (
        <InputField
          label={intl.formatMessage({ id: leftText.toUpperCase() })}
          testId="character-before"
        >
          <Input
            value={listItems[selectedLine].char_before}
            onChange={(e) => setListItems(e.target.value, 'char_before')}
            placeholder={intl.formatMessage({ id: 'NONE' })}
            size="medium"
            testId="character-before"
          />
        </InputField>
      );
    }

    return (
      <InputField
        label={intl.formatMessage({ id: leftText.toUpperCase() })}
        testId="indentation-left"
      >
        <Input
          value={leftIndentVal}
          onChange={(e) => setLeftIndentVal(e.target.value)}
          onBlur={(e) => handleOnBlurIndentationInput(e, 'indentation_left')}
          placeholder={intl.formatMessage({ id: 'NONE' })}
          size="medium"
          testId="indentation-left"
        />
      </InputField>
    );
  };

  const getRightContent = () => {
    const handleOnBlurStartInput = (e: FocusEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      if (
        !Number.isNaN(
          NumberingUtils.represent(
            currentItem.numbering_type?.value || currentItem.numbering_type,
            inputValue,
          ),
        )
      ) {
        setListItems(inputValue, 'start_from');
      } else {
        handleSetters('start_from');
      }
    };

    if (rightText === 'indentation_right') {
      return (
        <InputField
          label={intl.formatMessage({ id: rightText.toUpperCase() })}
          testId="indentation-right"
        >
          <Input
            value={rightIndentVal}
            onChange={(e) => setRightIndentVal(e.target.value)}
            onBlur={(e) => handleOnBlurIndentationInput(e, 'indentation_right')}
            placeholder={intl.formatMessage({ id: 'NONE' })}
            size="medium"
            testId="indentation-right"
          />
        </InputField>
      );
    }

    if (rightText === 'start_from') {
      return (
        <InputField label={intl.formatMessage({ id: rightText.toUpperCase() })} testId="start-from">
          <Input
            value={
              NumberingUtils.represent(
                listItems[selectedLine].numbering_type?.value ||
                  listItems[selectedLine].numbering_type,
                startFromInputVal,
              ) || startFromInputVal
            }
            onChange={(e) => setStartFromInputVal(e.target.value)}
            onBlur={(e) => handleOnBlurStartInput(e)}
            placeholder={intl.formatMessage({ id: 'NONE' })}
            size="medium"
            testId="start-from"
          />
        </InputField>
      );
    }

    return (
      <InputField
        label={intl.formatMessage({ id: rightText.toUpperCase() })}
        testId="character-after"
      >
        <Input
          value={listItems[selectedLine][rightText]}
          onChange={(e) => setListItems(e.target.value, rightText)}
          placeholder={intl.formatMessage({ id: 'NONE' })}
          size="medium"
          testId="character-after"
        />
      </InputField>
    );
  };

  return (
    <div className={styles.formattingContent}>
      <div className={styles.boxWrapper}>
        <div className={styles.leftBox}>{getLeftContent()}</div>
        <div className={styles.rightBox}>{getRightContent()}</div>
      </div>
      {leftText === 'numbering_type' && (
        <div style={{ marginTop: '1rem', marginBottom: '4rem' }}>
          <Checkbox
            checked={listItems[selectedLine].incl_prev_lvls ? 'checked' : 'unchecked'}
            onChange={() => setListItems(!listItems[selectedLine].incl_prev_lvls, 'incl_prev_lvls')}
            disabled={listItems[selectedLine].level === 0}
            testId="include-previous-numbering-checkbox"
          >
            <FormattedMessage id="INCLUDE_PREVIOUS_NUMBERING" />
          </Checkbox>
        </div>
      )}

      {rightText === 'indentation_right' && (
        <div className={styles.specialIdentation}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.text}>
              <FormattedMessage id="SPECIAL_INDENT" />
            </div>
            <Select
              creatable
              isSearchable={false}
              isClearable={false}
              options={specialIndentations}
              value={getLabels(
                'specialIndentations',
                listItems[selectedLine].special_indent?.value ||
                  listItems[selectedLine].special_indent ||
                  'none',
              )}
              onChange={(values) => {
                setListItems(values, 'special_indent');
                if (values.value === 'none') {
                  setSpecialIndentValue(null);
                }
              }}
              customStyles={{
                container: (provided: any) => ({ ...provided, width: '20rem' }),
                control: (provided: any) => ({ ...provided, height: '4rem' }),
              }}
              menuPosition="fixed"
              size="medium"
              testId="special-indent"
            />
          </div>
          <div style={{ marginLeft: '1.5rem', paddingTop: '5rem' }}>
            <Input
              disabled={
                !(
                  listItems[selectedLine].special_indent?.value ||
                  listItems[selectedLine].special_indent
                ) ||
                //@ts-expect-error fix types
                listItems[selectedLine].special_indent === INDENT_TYPE.NONE
              }
              value={specialIndentValue || ''}
              onBlur={(e) => handleOnBlurIndentationInput(e, 'special_indent_value')}
              onChange={(e) => setSpecialIndentValue(e.target.value)}
              placeholder={
                !(
                  listItems[selectedLine].special_indent?.value ||
                  listItems[selectedLine].special_indent
                ) ||
                //@ts-expect-error fix types
                listItems[selectedLine].special_indent === INDENT_TYPE.NONE
                  ? ''
                  : intl.formatMessage({ id: 'NONE' })
              }
              size="medium"
              testId="special-indent"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RegularBox;
