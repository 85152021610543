import UserAvatar from '_common/components/UserAvatar/UserAvatar';
import UserColors from 'Editor/services/UserColors/UserColors';
import type { AvatarProps } from 'dodoc-design-system/build/types/Components/Avatar/Avatar';

type SuiteAvatarProps = {
  userId: string;
  showTooltip: boolean;
  name: string;
  online?: boolean;
} & Pick<AvatarProps, 'margin' | 'size'>;

const SuiteAvatar = ({ margin, userId, size, showTooltip, name, online }: SuiteAvatarProps) => {
  let color;
  if (userId === 'IMPORTED_USER') {
    color = UserColors.getUserColor(name || userId)?.name;
  } else if (userId || name) {
    color = UserColors.getUserColor(userId || name)?.name;
  }

  return (
    <UserAvatar
      margin={margin}
      size={size}
      userId={userId}
      showTooltip={showTooltip}
      editor={color}
      online={online}
    />
  );
};

SuiteAvatar.defaultProps = {
  size: 'medium',
  showTooltip: false,
  name: '',
  userId: undefined,
};

export default SuiteAvatar;
