import { useSelector } from '_common/hooks';
import type { FilterIdentity } from '../FilterController';

const useTotalMatchedFilters = ({ identity }: { identity: FilterIdentity }) => {
  const filters = useSelector((state) => state.filters[identity]);

  let count = 0;

  Object.typedKeys(filters).forEach((filter) => {
    const result = filters[filter];

    if (result) {
      if (Array.isArray(result) && result.length > 0) {
        count = count + result.length;
      } else if (typeof result === 'string' || typeof result === 'object') {
        count = count + 1;
      } else if (typeof result === 'boolean' && result === true) {
        count = count + 1;
      }
    }
  });

  return count;
};

export default useTotalMatchedFilters;
