import { ChangeEventHandler, Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Divider, Input, Toggle } from 'dodoc-design-system';

import { useDebounce, useDispatch, useSelector } from '_common/hooks';
import { openModal } from 'App/ModalContext/utils';

import {
  addNewAdvancedFilter,
  removeAdvancedFilter,
  setSearchQuery,
  setActiveTab,
  updateAdvancedFilter,
  setAdvancedFilters,
  setSearchMode,
  setAppliedFilterSet,
} from 'Search/redux/SearchPageSlice';

import { AdvancedFilterRow, TableColumnsToggle } from '_common/components';
import {
  AdvancedFilter,
  CONDITION_TYPES,
} from '_common/components/AdvancedFilterRow/AdvancedFilterOptions';

import styles from './AdvancedSearchFilters.module.scss';

const AdvancedSearchFilters = () => {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.search.activeTab);
  const initialParent = useSelector((state) => state.search.initialParent);
  const advancedFilters = useSelector((state) => state.search.advancedFilters);
  const advancedFiltersArray = useMemo(() => Object.values(advancedFilters), [advancedFilters]);
  const searchMode = useSelector((state) => state.search.searchMode);
  const appliedFilterSet = useSelector((state) => state.search.appliedFilterSet);
  const loading = useSelector((state) => state.search.loading);
  const searchTotal = useSelector((state) => state.search.request.total);

  const searchQuery = useSelector((state) => state.search.searchQuery);

  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(localSearchQuery, 500);

  useEffect(() => {
    return () => {
      dispatch(setSearchMode('normal'));
    };
  }, []);

  useEffect(() => {
    try {
      let queryBuild = '';
      advancedFiltersArray.forEach((filter) => {
        if (filter.unprocessed) {
          throw new Error("One or more filters weren't processed, skipping query builing");
        }

        if (filter.query) {
          if (queryBuild) {
            queryBuild += ` ${CONDITION_TYPES[0].value} `;
          }

          queryBuild += filter.query;
        }
      });

      dispatch(setSearchQuery({ query: queryBuild }));
    } catch (e) {}
  }, [advancedFiltersArray]);

  useEffect(() => {
    if (searchMode === 'advanced') {
      dispatch(setSearchQuery({ query: debouncedSearchQuery }));
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setLocalSearchQuery(searchQuery);
  }, [searchQuery]);

  const handleSetSpacesTab = () => dispatch(setActiveTab('spaces'));
  const handleSetSharedTab = () => dispatch(setActiveTab('shared'));
  const handleSetParentTab = () => dispatch(setActiveTab('parent'));

  const handleOnClickSaveFilters = () =>
    openModal({
      modal: 'SaveFiltersModal',
      data: {
        savingFilters: searchMode === 'advanced' ? `query:${searchQuery}` : advancedFiltersArray,
      },
    });
  const handleOnClickAccessSavedFilters = () => openModal({ modal: 'AccessSavedFiltersModal' });
  const handleOnClickAddFilter = () => {
    dispatch(addNewAdvancedFilter());
    dispatch(setAppliedFilterSet(undefined));
  };
  const handleOnClickRemoveAllFilters = () => {
    dispatch(setAdvancedFilters({}));
    dispatch(setAppliedFilterSet(undefined));
    dispatch(setSearchMode('normal'));
  };

  const handleSetNormalSearch = () => {
    dispatch(setSearchMode('normal'));
    dispatch(setSearchQuery({ query: '' }));
  };
  const handleSetAdvancedSearch = () => {
    openModal({
      modal: 'AdvancedSearchModal',
      data: { initialQuery: '' },
    });
  };

  const handleManualQueryChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setLocalSearchQuery(e.target.value);
  const handleEditSearchQuery = () => {
    openModal({
      modal: 'AdvancedSearchModal',
      data: { initialQuery: localSearchQuery },
    });
  };

  const handleFilterChange = (updatedFilter: AdvancedFilter) => {
    dispatch(updateAdvancedFilter({ updatedFilter }));
  };

  const handleRemoveFilter = (filterId: AdvancedFilter['id']) => {
    dispatch(setAppliedFilterSet(undefined));
    dispatch(removeAdvancedFilter({ filterId }));
  };

  const handleFilterManualChange = () => {
    dispatch(setAppliedFilterSet(undefined));
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.parentContainer}>
          <span className={styles.title}>
            <FormattedMessage id="SEARCH_IN" />
          </span>
          <div style={{ display: 'flex' }}>
            <Toggle
              variant="pill"
              size="small"
              margin="0 1rem 0 1rem"
              isToggled={activeTab === 'spaces'}
              onClick={handleSetSpacesTab}
              testId="advanced-search-mode-spaces"
            >
              <FormattedMessage id="storage.sidebar.spaces" />
            </Toggle>
            <Toggle
              variant="pill"
              size="small"
              margin="0 1rem 0 0"
              isToggled={activeTab === 'shared'}
              onClick={handleSetSharedTab}
              testId="advanced-search-mode-shared"
            >
              <FormattedMessage id="storage.sidebar.shared" />
            </Toggle>
            <Toggle
              variant="pill"
              size="small"
              isToggled={activeTab === 'parent'}
              onClick={handleSetParentTab}
              testId="advanced-search-mode-myfiles"
            >
              {initialParent?.type === 'space' && initialParent?.personal ? (
                <FormattedMessage id="spaces.personalSpace" />
              ) : (
                initialParent?.name
              )}
            </Toggle>
          </div>
        </div>
        <div className={styles.filtersHistory}>
          <Button
            size="medium"
            onClick={handleOnClickAccessSavedFilters}
            testId="advanced-search-view-saved-filters"
          >
            <FormattedMessage id="VIEW_SAVED_FILTERS" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            onClick={handleOnClickSaveFilters}
            disabled={
              !!appliedFilterSet ||
              (searchMode === 'normal' && advancedFiltersArray.length) === 0 ||
              (searchMode === 'advanced' && !localSearchQuery)
            }
            testId="advanced-search-save-filters"
          >
            <FormattedMessage id="SAVE_FILTERS" />
          </Button>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            size="medium"
            onClick={handleOnClickRemoveAllFilters}
            disabled={
              (searchMode === 'normal' && advancedFiltersArray.length) === 0 ||
              (searchMode === 'advanced' && !localSearchQuery)
            }
            icon="RemoveRed"
            testId="advanced-search-clear-filters"
          >
            <FormattedMessage id="CLEAR_FILTERS" />
          </Button>
          <Button
            size="medium"
            onClick={handleOnClickAddFilter}
            disabled={searchMode !== 'normal' || advancedFiltersArray.length === 10}
            icon="Add"
            testId="advanced-search-add-filter"
          >
            <FormattedMessage id="ADD_FILTER" />
          </Button>
        </div>
      </div>
      {searchMode === 'advanced' ? (
        <>
          <div className={styles.queryRow}>
            <div className={styles.queryInput}>
              <Input
                size="medium"
                value={localSearchQuery}
                onChange={handleManualQueryChange}
                placeholder="Empty Query"
                testId="advanced-search-advanced-query"
              />
            </div>
            <div className={styles.queryActions}>
              <Button
                size="medium"
                variant="link"
                onClick={handleEditSearchQuery}
                testId="advanced-filter-edit-query"
              >
                <FormattedMessage id="EDIT_SEARCH_QUERY" />
              </Button>
              <Button
                size="medium"
                variant="link"
                icon="CloseGrey"
                onClick={handleSetNormalSearch}
                testId="advanced-filter-remove-query"
              />
            </div>
          </div>
          <Divider />
        </>
      ) : (
        <div className={styles.conditions}>
          {advancedFiltersArray.map((filter, index) => (
            <Fragment key={`AdvancedFilterRow-${filter.id}`}>
              <div className={styles.filterRow}>
                <AdvancedFilterRow
                  type="filter"
                  removable
                  initialFilter={filter}
                  onFilterChange={handleFilterChange}
                  onRemoveFilter={handleRemoveFilter}
                  onManualChange={handleFilterManualChange}
                  testId={`advanced-filter-row-${index}`}
                />
              </div>
              <Divider margin="0" />
            </Fragment>
          ))}
        </div>
      )}

      <div className={styles.footer}>
        <div className={styles.searchModeToggle}>
          {searchMode === 'advanced' ? (
            <Button
              size="medium"
              variant="link"
              onClick={handleSetNormalSearch}
              testId="advanced-search-switch-default"
            >
              <FormattedMessage id="SWITCH_TO_DEFAULT_SEARCH" />
            </Button>
          ) : (
            <Button
              size="medium"
              variant="link"
              onClick={handleSetAdvancedSearch}
              testId="advanced-search-switch-advanced"
            >
              <FormattedMessage id="SWITCH_TO_ADVANCED_SEARCH" />
            </Button>
          )}
        </div>
        {!loading && (searchTotal ?? 0) > 0 && (
          <div className={styles.searchResults}>
            {appliedFilterSet && <div className={styles.filterSetName}>{appliedFilterSet}</div>}
            <FormattedMessage id="SEARCH_RESULTS" values={{ totalResults: searchTotal }} />
          </div>
        )}
        <TableColumnsToggle table="search" />
      </div>
    </div>
  );
};

export default AdvancedSearchFilters;
