import { FormattedMessage, useIntl } from 'react-intl';
import { Switch } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import { selectFilteredComments, setHideComments } from 'Editor/redux/CommentsSlice';

import styles from './HideComments.module.scss';

const HideComments = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorCommentPanel),
  );
  const comments = useSelector(selectFilteredComments);
  const hideAllComments = useSelector((state) => state.editor.comments.hideAll);

  const handleToggleHidden = () => {
    dispatch(setHideComments(!hideAllComments));
    EditorManager.getInstance().filterComments(!hideAllComments ? [] : comments.order);
  };

  const getCommentsNumber = () => {
    if (hasActiveFilters && !hideAllComments) {
      return (
        <FormattedMessage
          id="CURRENT_OUT_OF_TOTAL_COMMENTS"
          values={{ current: comments.order.length, total: comments.total }}
        />
      );
    }

    if (comments.total === 0 || hideAllComments) {
      return <FormattedMessage id="NO_COMMENTS_IN_THE_DOCUMENT" />;
    }

    return <FormattedMessage id="editor.comments.total" values={{ total: comments.total }} />;
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.quantityIndicator}
        data-testid={
          comments.total === 0 || hideAllComments
            ? 'no-comments-in-the-document'
            : 'comments-in-the-document'
        }
      >
        {getCommentsNumber()}
      </div>
      <Switch
        active={hideAllComments}
        onChange={handleToggleHidden}
        testId={'hide-all-comments-switch'}
        labelPlacement="left"
        size='medium'
      >
        {intl.formatMessage({ id: "HIDE_ALL_COMMENTS" })}
      </Switch>
    </div>
  );
};

export default HideComments;
