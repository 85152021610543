import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';
import LogoContainer from '_common/components/LogoContainer/LogoContainer';
import { useSelector } from '_common/hooks/redux';
import { selectIsIEnvision } from 'App/redux/appSlice';
import styles from './ErrorBoundary.module.scss';

type Props = {
  children: ReactNode;
};

export const ErrorScreen = () => {
  const information = useSelector((state) => state.app.information);
  const isiEnvision = useSelector(selectIsIEnvision);
  return (
    <div className={styles.root}>
      <LogoContainer expanded size="large" color="white" testId="bluescreen-logo" />
      <div className={styles.container}>
        <div className={styles.error}>500</div>
        <div className={styles.sorry}>
          <FormattedMessage id="ERROR_SCREEN_HEADER" />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <div className={styles.text}>
            <FormattedMessage
              id={isiEnvision ? 'ERROR_SCREEN_TEXT_ENVISION_1' : 'ERROR_SCREEN_TEXT_1'}
            />
          </div>
          <div className={styles.text}>
            <FormattedMessage
              id={isiEnvision ? 'ERROR_SCREEN_TEXT_ENVISION_2' : 'ERROR_SCREEN_TEXT_2'}
            />
          </div>
        </div>
        <Button
          size="medium"
          margin="4rem 0 0 0"
          onClick={() => {
            if (isiEnvision) {
              window.location.reload();
            } else {
              window.location.href = window.location.origin;
            }
          }}
          testId={`${isiEnvision ? 'refresh-page' : 'back-to-doDOC'}-button`}
        >
          <FormattedMessage id={isiEnvision ? 'REFRESH_PAGE' : 'BACK_TO_DODOC'} />
        </Button>
        {isiEnvision && (
          <Button
            size="medium"
            margin="4rem 0 0 3rem"
            onClick={() => {
              if (information.extra) {
                window.location.href = `${information.extra.source}`;
              }
            }}
            testId="back-to-ienvision-button"
          >
            <FormattedMessage id="BACK_TO_IENVISION" />
          </Button>
        )}
      </div>
    </div>
  );
};

const ErrorBoundary = ({ children }: Props) => {
  return <Sentry.ErrorBoundary fallback={<ErrorScreen />}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
