import { FormattedMessage } from 'react-intl';
import { Modal, Button, Logo } from 'dodoc-design-system';
import dayjs from 'dayjs';
import UIPackageJson from '_common/../../package.json';
import DSPackageJson from 'dodoc-design-system/package.json';

import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';

import styles from './AboutModal.module.scss';
import { useGetApiVersionQuery } from 'App/redux/AppApi';
import { useLoginSetupQuery } from '_common/services/api/authority';
import { useRealtimeSetupQuery } from '_common/services/api/realtime';

const MODAL = 'AboutModal';

const AboutModal = () => {
  const dispatch = useDispatch();
  const { data: authSetup } = useLoginSetupQuery();
  const { data: apiSetup } = useGetApiVersionQuery();
  const { data: rtSetup } = useRealtimeSetupQuery();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const uiversion = process.env.REACT_APP_UI_VERSION || UIPackageJson.version;
  const dsversion = DSPackageJson.version;

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="45rem" open={!!isOpen} onClose={close} testId="about">
      <Modal.Header onClose={close}>
        <FormattedMessage id="ABOUT_DODOC" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.root}>
          <div className={styles.header}>
            <Logo expanded testId="about-modal-logo" />
            <FormattedMessage id="VERSION_NUMBER" values={{ number: authSetup?.dodoc_version }} />
          </div>
          <div className={styles.versionList}>
            <div className={`${styles.container} ${styles.titles}`}>
              <span>UI</span>
              <span>API</span>
              <span>AUTH</span>
              <span>RT</span>
              <span>DS</span>
            </div>
            <div className={`${styles.container} ${styles.values}`}>
              <span>{uiversion}&nbsp;</span>
              <span>{apiSetup?.api_version}&nbsp;</span>
              <span>{authSetup?.authority_version}&nbsp;</span>
              <span>{rtSetup?.rt_version}&nbsp;</span>
              <span>{dsversion}&nbsp;</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer alignment="space-between">
        <span className={styles.copyright}>© doDOC {dayjs().year()}</span>
        <Button size="medium" onClick={close} testId="about-modal-close">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AboutModal;
