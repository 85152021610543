import { FormattedMessage } from 'react-intl';
import { Button, Modal, KeyboardShortcut } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { closeModal } from '_common/modals/ModalsSlice';

import styles from './ClipboardInfoModal.module.scss';

const ClipboardInfoModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open.ClipboardInfoModal);

  const close = () => {
    dispatch(closeModal('ClipboardInfoModal'));
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="clipboard-info">
      <Modal.Header onClose={close}>
        <FormattedMessage id="CLIPBOARD_INFO_HEADER" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.description}>
          <FormattedMessage id="CLIPBOARD_INFO_DESCRIPTION" />
        </div>
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.shortcut}>
              <KeyboardShortcut shortcut="X" separator=" + " testId='X-shortcut' />
            </div>
            <div className={styles.label}>
              <FormattedMessage id="CLIPBOARD_TO_CUT" />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.shortcut}>
              <KeyboardShortcut shortcut="C" separator=" + " testId='C-shortcut' />
            </div>
            <div className={styles.label}>
              <FormattedMessage id="CLIPBOARD_TO_COPY" />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.shortcut}>
              <KeyboardShortcut shortcut="V" separator=" + " testId='V-shortcut' />
            </div>
            <div className={styles.label}>
              <FormattedMessage id="CLIPBOARD_TO_PASTE" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" variant="primary" onClick={close} testId="clipboard-info-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClipboardInfoModal;
