import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { LoadingPage } from '_common/components';

const RedirectIntegrationPage = () => {
  const pathName = useLocation().pathname;
  useEffect(() => {
    if (pathName === '/auth/connector/redirect/ok') {
      window.close();
    }
  }, []);

  return <LoadingPage />;
};

export default RedirectIntegrationPage;
