import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useDispatch } from '_common/hooks';
import { navigateToTenantSettings } from 'router/history';
import { setMenuActiveOption } from '_common/components/Sidebar/SidebarSlice';

import { ActionBar } from '_common/components';
import { PathElement } from '_common/components/ActionBar/ActionBarBreadcrumb/ActionBarBreadcrumb';

type HeaderProps = { roleName: string };

const Header = ({ roleName }: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: objectId } = useParams<RouterParams['roleSettings']>();

  const handleGoTo = (element: PathElement) => {
    dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'roles' }));
    navigateToTenantSettings();
  };

  return (
    <ActionBar>
      <ActionBar.Breadcrumb
        path={[
          {
            id: 'roles',
            name: intl.formatMessage({ id: 'settings.tenant.header' }),
          },
          {
            id: objectId,
            name: intl.formatMessage({ id: 'settings.roles.header' }, { role: roleName }),
          },
        ]}
        onBreadcrumbElementClick={handleGoTo}
      />
    </ActionBar>
  );
};

export default Header;
