import { ReactNode } from 'react';
import { Dropdown, Toggle, usePopper } from 'dodoc-design-system';
import type { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';

type OptionsCellProps = {
  disabled?: boolean;
  items: ReactNode;
  testId: string;
  placement?: PopperProps['placement'];
  stopEventPropagation?: boolean;
};

const OptionsCell = ({
  disabled = false,
  items,
  testId,
  placement = 'bottom-end',
  stopEventPropagation = false,
}: OptionsCellProps) => {
  const { isOpen, referenceProps, popperProps } = usePopper({ placement });

  const onClickCapture = (e: React.MouseEvent<HTMLSpanElement>) => {
    referenceProps.onClickCapture();
    if (stopEventPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <div>
      <Toggle
        size="small"
        variant="link"
        isToggled={isOpen}
        icon="MoreOptions"
        disabled={disabled}
        testId={`${testId}-options-toggle`}
        {...referenceProps}
        onClickCapture={onClickCapture}
      />
      <Dropdown {...popperProps} testId={`${testId}-dropdown`}>
        {items}
      </Dropdown>
    </div>
  );
};

export default OptionsCell;
