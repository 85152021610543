import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import TableContent, { type TableProps } from './TableContent';
import ThunkTable, { type ThunkTableProps } from './ThunkTable';

export const Table = (props: TableProps) => {
  return (
    <IntlErrorBoundary size="large" margin="8.5rem 0 0 0">
      <TableContent {...props} />
    </IntlErrorBoundary>
  );
};

export const ThunksTable = (props: ThunkTableProps) => {
  return (
    <IntlErrorBoundary size="large" margin="8.5rem 0 0 0">
      <ThunkTable {...props} />
    </IntlErrorBoundary>
  );
};
