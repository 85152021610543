import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dropdown, Divider, Toggle, usePopper } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { openAndUpdateModal, openModal } from '_common/modals/ModalsSlice';
import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';

import { ELEMENTS } from 'Editor/services/consts';

const Format = () => {
  const dispatch = useDispatch();
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isTextSelected = useSelector((state) => state.editor.status.selection.TEXT);
  const isFieldSelected = useSelector((state) => state.editor.status.selection.FIELD);

  const isBoldActive = useSelector((state) => state.editor.toolbar.bold);
  const isItalicActive = useSelector((state) => state.editor.toolbar.italic);
  const isUnderLineActive = useSelector((state) => state.editor.toolbar.underline);
  const isVanishActive = useSelector((state) => state.editor.toolbar.vanish);
  const isStrikethroughActive = useSelector((state) => state.editor.toolbar.strikethrough);
  const isSuperscriptActive = useSelector((state) => state.editor.toolbar.superscript);
  const isSubscriptActive = useSelector((state) => state.editor.toolbar.subscript);
  const lineHeight = useSelector((state) => state.editor.toolbar.lineHeight);
  const spaceBefore = useSelector((state) => state.editor.toolbar.spaceBefore);
  const spaceAfter = useSelector((state) => state.editor.toolbar.spaceAfter);
  const autoSpaceBefore = useSelector((state) => state.editor.toolbar.autoSpaceBefore);
  const autoSpaceAfter = useSelector((state) => state.editor.toolbar.autoSpaceAfter);
  const widowControl = useSelector((state) => state.editor.toolbar.widowControl);
  const keepNext = useSelector((state) => state.editor.toolbar.keepNext);
  const keepLines = useSelector((state) => state.editor.toolbar.keepLines);
  const pageBreakBefore = useSelector((state) => state.editor.toolbar.pageBreakBefore);

  const handleJustifyContent = (style: Editor.Elements.TextAlignProperties['style']) => {
    EditorManager.getInstance().alignCurrentSelection({ style });
  };

  const handleLineSpacing = (lineHeight: number) => {
    EditorManager.getInstance().lineSpaceCurrentSelection({
      lineHeight,
    });
  };

  const handleRestartListNumbering = () => {
    const manager = EditorManager.getInstance();
    manager.restartListNumbering();
  };

  const handleContinueListNumbering = () => {
    const manager = EditorManager.getInstance();
    manager.continueListNumbering();
  };

  const handleIncreaseIndent = () => {
    const manager = EditorManager.getInstance();
    manager.increaseIndent();
  };

  const handleDecreaseIndent = () => {
    const manager = EditorManager.getInstance();
    manager.decreaseIndent();
  };

  const handleSectionProperties = () => {
    dispatch(openModal('PageSetupModal'));
    dispatch(setSelectedTab({ menuId: 'pageSetup', selectedTab: 'sections' }));
  };

  const handlePaginationPropertie = (
    prop: ValueOf<typeof StylesUtils.STYLES>,
    newState: boolean,
  ) => {
    EditorManager.getInstance().setLinePaginationProperties({ [prop]: newState });
  };

  const handleCustomSpacing = () => {
    let spacing = {
      [StylesUtils.STYLES.LINEHEIGHT]: lineHeight,
      [StylesUtils.STYLES.SPACEBEFORE]: spaceBefore,
      [StylesUtils.STYLES.SPACEAFTER]: spaceAfter,
      [StylesUtils.STYLES.ASB]: autoSpaceBefore,
      [StylesUtils.STYLES.ASA]: autoSpaceAfter,
    };

    dispatch(
      openAndUpdateModal({
        modal: 'CustomSpacingModal',
        data: {
          spacing,
        },
      }),
    );
  };

  const handleIndentationOptions = () => {
    const manager = EditorManager.getInstance();

    const indentation = manager.parseIndentationValuesForSelectedNodes();

    if (indentation) {
      dispatch(
        openAndUpdateModal({
          modal: 'IndentationOptionsModal',
          data: {
            indentation,
          },
        }),
      );
    }
  };

  const handleTabulations = () => {
    dispatch(openModal('TabulationsModal'));
  };

  const handleCaption = (mode: string) => {
    dispatch(
      openAndUpdateModal({
        modal: 'CaptionsModal',
        data: {
          editMode: mode !== 'insert',
        },
      }),
    );
  };

  const toggleStyle = (style: Editor.Edition.Styles, newState: boolean) => {
    EditorManager.getInstance().toggleSelectionStyle(style, newState);
  };

  const clearFormatting = () => {
    EditorManager.getInstance().clearFormatting();
  };

  return (
    <>
      <Toggle
        size="medium"
        {...referenceProps}
        variant="ghost"
        isToggled={isOpen}
        margin="0 1rem 0 0"
        testId="format-button"
      >
        <FormattedMessage id="editor.menu.format.value" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-format">
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="FONT" />}
          disabled={isReadOnlyMode || isPageLayout}
          testId="topbar-menu-format"
        >
          <Dropdown.Item
            disabled={!isTextSelected}
            onClick={() => toggleStyle(StylesUtils.STYLES.BOLD, !isBoldActive)}
            prefixIcon="Bold"
            testId="topbar-menu-format-bold"
          >
            <FormattedMessage id="editor.menu.format.bold" />
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!isTextSelected}
            onClick={() => toggleStyle(StylesUtils.STYLES.ITALIC, !isItalicActive)}
            prefixIcon="Italic"
            testId="topbar-menu-format-italic"
          >
            <FormattedMessage id="editor.menu.format.italic" />
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!isTextSelected}
            prefixIcon="Underline"
            onClick={() => toggleStyle(StylesUtils.STYLES.UNDERLINE, !isUnderLineActive)}
            testId="topbar-menu-format-underline"
          >
            <FormattedMessage id="editor.menu.format.underline" />
          </Dropdown.Item>
          <Divider />
          <Dropdown.Item
            disabled={!isTextSelected}
            onClick={() => toggleStyle(StylesUtils.STYLES.STRIKETHROUGH, !isStrikethroughActive)}
            testId="topbar-menu-format-strike-through"
          >
            <FormattedMessage id="editor.menu.format.strikethrough" />
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!isTextSelected}
            onClick={() => toggleStyle(StylesUtils.STYLES.SUPERSCRIPT, !isSuperscriptActive)}
            testId="topbar-menu-format-superscript"
          >
            <FormattedMessage id="editor.menu.format.superscript" />
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!isTextSelected}
            onClick={() => toggleStyle(StylesUtils.STYLES.SUBSCRIPT, !isSubscriptActive)}
            testId="topbar-menu-format-subscript"
          >
            <FormattedMessage id="editor.menu.format.subscript" />
          </Dropdown.Item>
          <Divider />
          <Dropdown.Item
            onClick={() => toggleStyle(StylesUtils.STYLES.VANISH, !isVanishActive)}
            testId="topbar-menu-format-hidden"
          >
            <FormattedMessage id="HIDDEN" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="editor.menu.format.alignment" />}
          disabled={isReadOnlyMode || isPageLayout}
          testId="alignment-dropdown-submenu"
        >
          <Dropdown.Item
            onClick={() => handleJustifyContent('left')}
            testId="topbar-menu-format-alignment-left"
          >
            <FormattedMessage id="editor.menu.format.justifyLeft" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleJustifyContent('center')}
            testId="topbar-menu-format-alignment-center"
          >
            <FormattedMessage id="editor.menu.format.justifyCenter" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleJustifyContent('right')}
            testId="topbar-menu-format-alignment-right"
          >
            <FormattedMessage id="editor.menu.format.justifyRight" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleJustifyContent('justify')}
            testId="topbar-menu-format-alignment-justify"
          >
            <FormattedMessage id="editor.menu.format.justifyFull" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        {/* Line spacing sub menu */}
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="SPACING" />}
          disabled={isReadOnlyMode || isPageLayout}
          testId="spacing-dropdown-submenu"
        >
          <Dropdown.Item onClick={() => handleLineSpacing(1)} testId="single-dropdown-item">
            <FormattedMessage id="editor.menu.format.lineSpacing.single" />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLineSpacing(1.15)} testId="1.15-dropdown-item">
            1.15
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLineSpacing(1.5)} testId="1.5-dropdown-item">
            1.5
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLineSpacing(2)} testId="double-dropdown-item">
            <FormattedMessage id="editor.menu.format.lineSpacing.double" />
          </Dropdown.Item>
          <Divider />
          <Dropdown.Item onClick={handleCustomSpacing} testId="custom-spacing-dropdown-item">
            <FormattedMessage id="editor.menu.format.lineSpacing.customSpacing" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="LINE_AND_PAGE_BREAK" />}
          disabled={isReadOnlyMode || isPageLayout}
          testId="line-and-page-break-dropdown-submenu"
        >
          <Dropdown.Item
            onClick={() => {
              handlePaginationPropertie(StylesUtils.STYLES.WC, !widowControl);
            }}
            prefixIcon={widowControl ? 'ResolveComment' : undefined}
            testId="topbar-menu-format-widow-orphan-control"
          >
            <FormattedMessage id="WIDOW_ORPHAN_CONTROL" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handlePaginationPropertie(StylesUtils.STYLES.KN, !keepNext);
            }}
            prefixIcon={keepNext ? 'ResolveComment' : undefined}
            testId="topbar-menu-format-keep-with-next"
          >
            <FormattedMessage id="KEEP_WITH_NEXT" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handlePaginationPropertie(StylesUtils.STYLES.KL, !keepLines);
            }}
            prefixIcon={keepLines ? 'ResolveComment' : undefined}
            testId="topbar-menu-format-keep-lines-together"
          >
            <FormattedMessage id="KEEP_LINES_TOGETHER" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              handlePaginationPropertie(StylesUtils.STYLES.PBB, !pageBreakBefore);
            }}
            prefixIcon={pageBreakBefore ? 'ResolveComment' : undefined}
            testId="topbar-menu-format-page-break-before"
          >
            <FormattedMessage id="PAGE_BREAK_BEFORE" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="INDENTATION" />}
          disabled={isReadOnlyMode || isPageLayout}
          testId="indentation-dropdown-submenu"
        >
          <Dropdown.Item
            onClick={handleIncreaseIndent}
            testId="topbar-menu-format-indentation-increase"
          >
            <FormattedMessage id="editor.menu.format.increaseIndent" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleDecreaseIndent}
            testId="topbar-menu-format-indentation-decrease"
          >
            <FormattedMessage id="editor.menu.format.decreaseOutdent" />
          </Dropdown.Item>
          <Divider />
          <Dropdown.Item
            onClick={handleIndentationOptions}
            testId="topbar-menu-format-indentation-options"
          >
            <FormattedMessage id="INDENTATION_OPTIONS" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        <Dropdown.Item
          onClick={handleTabulations}
          disabled={isReadOnlyMode || isPageLayout}
          testId="topbar-menu-format-tabulations"
        >
          <FormattedMessage id="TABULATIONS" />
        </Dropdown.Item>
        <Dropdown.SubMenu
          itemContent={<FormattedMessage id="editor.menu.format.bulletsAndNumbering" />}
          disabled={isReadOnlyMode || isPageLayout}
          testId="topbar-menu-format-bullets-and-numbering"
        >
          <Dropdown.Item
            onClick={handleRestartListNumbering}
            testId="topbar-menu-format-bullets-and-numbering-restart"
          >
            <FormattedMessage id="editor.menu.format.restartNumbering" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleContinueListNumbering}
            testId="topbar-menu-format-bullets-and-numbering-continue"
          >
            <FormattedMessage id="editor.menu.format.continueNumbering" />
          </Dropdown.Item>
        </Dropdown.SubMenu>
        {isFieldSelected && isFieldSelected.type === ELEMENTS.FieldElement.TYPES.CAPTION && (
          <>
            <Divider />
            <Dropdown.Item
              onClick={() => handleCaption('edit')}
              testId="topbar-menu-format-edit-caption"
            >
              <FormattedMessage id="EDIT_CAPTION" />
            </Dropdown.Item>
          </>
        )}
        <Divider />

        <Dropdown.Item
          onClick={handleSectionProperties}
          testId="topbar-menu-format-section-properties"
        >
          <FormattedMessage id="EDITOR_SECTION_PROPERTIES" />
        </Dropdown.Item>
        <Divider />

        <Dropdown.Item
          onClick={clearFormatting}
          disabled={isReadOnlyMode || isPageLayout}
          testId="clear-formatting-dropdown-item"
        >
          <FormattedMessage id="editor.menu.format.clearFormatting" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Format;
