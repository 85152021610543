import styles from './Annotation.module.scss';
import { useAnnotationBox, useAnnotationContext } from './AnnotationContext';
import BaseAnnotation from './BaseAnnotation';
import TransformWrapper from './TransformWrapper';
import { getPathsForSVG } from './Utils';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';
import { useSelector } from '_common/hooks';

const NO_RESIZE_NODES = [] as const;

const SVGAnnotation = () => {
  const { annotation, viewport } = useAnnotationContext();
  const box = useAnnotationBox();
  const paths = getPathsForSVG(annotation, viewport, box);
  const versionHistory = useSelector((state) => state.pdf.general.versionHistory);

  const { canEditAnnotation } = usePDFPermissions();

  return (
    <BaseAnnotation>
      <TransformWrapper
        allowMove={canEditAnnotation(annotation) && !versionHistory}
        allowResize={canEditAnnotation(annotation) && !versionHistory ? undefined : NO_RESIZE_NODES}
      >
        <svg
          className={styles.svg}
          version="1.1"
          width={box.width}
          height={box.height}
          xmlns="http://www.w3.org/2000/svg"
          style={{ position: 'absolute' }}
        >
          {paths?.map((props) => (
            <path key={props.d} {...props} />
          ))}
        </svg>
      </TransformWrapper>
    </BaseAnnotation>
  );
};

export default SVGAnnotation;
