import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Input, InputField } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { renameObject } from 'App/redux/appSlice';

const MODAL = 'RenameObjectModal';

const RenameObjectModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const id = useSelector((state) => state.modals[MODAL].objectId);
  const data = useSelector((state) => state.app.data);
  const submitting = useSelector((state) => state.modals[MODAL].submitting);

  const [name, setName] = useState('');
  const [validations, setValidations] = useState<{ name?: string }>({});

  const getDocumentName = useCallback(() => {
    const array = data[id].name.split('.');
    return data[id].type !== 'file' ? array.slice(0, array.length - 1).join('.') || array[0] || '' : data[id].name;
  }, [data, id]);

  useEffect(() => {
    if (isOpen) {
      setName(getDocumentName());
    }
  }, [isOpen, getDocumentName]);


  const onNameChanged: InputProps['onChange'] = (e) => {
    const newValidations: typeof validations = {};
    const inputName = e.target.value;
    setName(inputName);

    if (new RegExp(/[<>/:?*"|\\]/).test(inputName)) {
      newValidations.name = intl.formatMessage(
        {
          id: 'UNSUPPORTED_CHARACTER_MESSAGE',
        },
        { type: data[id].type === 'dopdf' ? 'doPDF' : data[id].type },
      );
    } else if (e.target.value.length === 0) {
      newValidations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    } else if (inputName.length > 150) {
      newValidations.name = intl.formatMessage({ id: 'validation.name.longName' }, { length: 150 });
    } else {
      newValidations.name = '';
    }
    if (Object.keys(newValidations).length > 0) {
      setValidations(newValidations);
    }
  };

  const handleRename = () => {
    dispatch(
      renameObject({
        objectId: id,
        objectType: data[id].type,
        newName: name,
      }),
    ).then(() => {
      notify({
        type: 'success',
        title: 'FILE_RENAMED',
        message: 'RENAME_FILE_MESSAGE',
        messageValues: { oldName: data[id].name, newName: name },
      });
    });
  };

  const close = () => {
    setValidations({});
    setName('');
    dispatch(closeAndResetModal(MODAL));
  };

  if (!data[id]) {
    return null;
  }

  return (
    <Modal open={!!isOpen} width="75rem" onClose={close} testId="rename-object">
      <Modal.Header onClose={close}>
        <FormattedMessage id="storage.modals.rename.title" />
      </Modal.Header>

      <Modal.Body>
        <InputField
          label={intl.formatMessage(
            { id: 'EDIT_TITLE' },
            { value: data[id].type === 'dopdf' ? 'doPDF' : data[id].type },
          )}
          size="large"
          feedback={validations.name ? validations.name : undefined}
          testId="rename-object-modal"
        >
          <Input
            value={name}
            onChange={onNameChanged}
            error={!!validations.name}
            placeholder={intl.formatMessage(
              { id: 'EDIT_TITLE_PLACEHOLDER' },
              { value: data[id].type },
            )}
            onEnterKey={validations.name === '' ? handleRename : undefined}
            size="large"
            testId="rename-object-modal"
          />
        </InputField>
      </Modal.Body>

      <Modal.Footer>
        <Button size="medium" onClick={close} testId="rename-object-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          loading={submitting}
          onClick={handleRename}
          disabled={!!validations.name || name === ''}
          testId="rename-object-modal-cta-button"
        >
          <FormattedMessage id="global.rename" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameObjectModal;
