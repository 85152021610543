import { ColorContrastChecker } from 'utils';
import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS, INDENT_TYPE } from 'Editor/services/consts';
import colors, { rgbToHex } from 'assets/colors';
import styles from './DocumentStylePreview.module.scss';

type DocumentStylePreviewProps = {
  id: string;
  style: Editor.Style.ParsedStyle;
  children: string;
};

const BACKGROUND_COLORS = [
  colors['color-white-base'],
  colors['color-dark-blue-tint-20'],
  colors['color-text-base'],
];

const VALID_COLOR_CONTRAST_LEVEL = 4.5;

const DocumentStylePreview = ({ id, style, children }: DocumentStylePreviewProps) => {
  const elementStyles = style && (style.extendedP || style.p || style);
  let fontSize = elementStyles.fontsize;
  let lineHeight = elementStyles.lh || 1;

  let backgroundColor = elementStyles.bg;
  let contrastBackgroundColor = null;

  if (backgroundColor != null) {
    if (backgroundColor === false) {
      backgroundColor = '#ffffff';
    } else if (!backgroundColor.includes('rgb') && !backgroundColor.includes('#')) {
      backgroundColor = `#${backgroundColor}`;
    }
  }

  let color = elementStyles.color;
  if (color != null) {
    if (color === false) {
      color = 'rgba(0,0,0,0)';
    } else if (!color.includes('rgb') && !color.includes('#')) {
      color = `#${color}`;
    }
  }

  if (
    (!backgroundColor ||
      backgroundColor.toLowerCase() === '#ffffff' ||
      rgbToHex(backgroundColor) === '#ffffff') &&
    color
  ) {
    let colorHex = color;
    if (color[0] !== '#' && color.includes('rgb')) {
      colorHex = rgbToHex(color);
    }
    for (let i = 0; i < BACKGROUND_COLORS.length; i++) {
      contrastBackgroundColor = BACKGROUND_COLORS[i];
      if (ColorContrastChecker(colorHex, contrastBackgroundColor, VALID_COLOR_CONTRAST_LEVEL)) {
        break;
      }
    }
  }

  // in case of outline style check list style defenition
  const indentation = EditorManager.getInstance().getIndentationValuesForStyle(style.id);

  let indLeft;

  if (indentation?.sp_ind?.t === INDENT_TYPE.HANGING && indentation?.sp_ind?.v != null) {
    indLeft = Number(indentation.ind.l) - Number(indentation.sp_ind.v);
  } else if (indentation?.ind?.l != null) {
    indLeft = Number(indentation.ind.l);
  }

  const marginLeft = indLeft ? '3rem' : 0;

  if (fontSize < 6) {
    fontSize = 6;
    lineHeight = 1.5;
  }
  if (fontSize > 27) {
    fontSize = 27;
    lineHeight = 1.55;
  }

  return (
    <div
      //@ts-expect-error TS doesnt accept custom attributes like 'x_y'
      //custom attribute to define default styles of the element
      element_type={ELEMENTS.ParagraphElement.ELEMENT_TYPE}
      data-style-id={style.id}
      className={`dodoc-preview dodoc-${id} ${styles.preview} ${
        style && !contrastBackgroundColor ? styles.background : undefined
      }`}
      style={{
        fontSize: `${fontSize}pt`,
        backgroundColor: `${contrastBackgroundColor || backgroundColor}`,
        lineHeight,
        color: `${color || 'inherit'}`,
        fontStyle: `${elementStyles.italic ? 'italic' : 'normal'}`,
        textDecoration: `${elementStyles.underline ? 'underline' : 'none'}`,
        paddingLeft: 0,
        textIndent: 0,
        fontWeight: `${elementStyles.bold ? 'bold' : 'normal'}`,
        fontFamily: elementStyles.fontfamily ? `"${elementStyles.fontfamily}"` : '',
        marginLeft,
        marginRight: 0,
      }}
      title={children}
      data-testid={children}
    >
      {children}
    </div>
  );
};

export default DocumentStylePreview;
