import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  selectTemplateById,
  useInstallTemplateMutation,
} from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import styles from './ActionsCell.module.scss';

type ActionsCellProps = {
  templateId: string;
};

const ActionsCell = ({ templateId }: ActionsCellProps) => {
  const dispatch = useDispatch();

  const template = useSelector((state) => selectTemplateById(state, templateId));
  const [installTemplate] = useInstallTemplateMutation();

  if (!template) {
    return null;
  }

  const handleUninstallClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'TEMPLATE_UNINSTALL_HEADER',
          titleValues: { title: template.name },
          message: 'TEMPLATE_UNINSTALL_MESSAGE',
          messageValues: { title: template.name },
          confirmButtonTextId: 'UNINSTALL',
          confirmButtonTextValues: {},
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'uninstallTemplate',
          actionValue: { id: templateId, name: template.name },
          headerType: 'information',
          cancelButtonShow: true,
        },
      }),
    );
  };
  const handleInstallClick = () => {
    installTemplate({ id: templateId, name: template.name });
  };
  const handleUpdateClick = () => {
    installTemplate({ id: templateId, name: template.name, updating: true });
  };

  const getButtonProps = () => {
    switch (template.status) {
      case 'installed':
      case 'outdated':
        return (
          <div className={styles.button}>
            <Button
              variant="danger"
              onClick={handleUninstallClick}
              size="small"
              fullWidth
              testId="uninstallButton"
            >
              <FormattedMessage id="UNINSTALL" />
            </Button>
          </div>
        );
      case 'not_installed':
        return (
          <div className={styles.button}>
            <Button
              variant="primary"
              onClick={handleInstallClick}
              size="small"
              fullWidth
              testId="installButton"
            >
              <FormattedMessage id="INSTALL" />
            </Button>
          </div>
        );
      default:
        throw Error(`Unknown Extension state ${template.status}`);
    }
  };

  return (
    <div className={styles.root}>
      {template.status === 'outdated' && (
        <div className={styles.button}>
          <Button size="small" onClick={handleUpdateClick} fullWidth testId="updateButton">
            <FormattedMessage id="UPDATE" />
          </Button>
        </div>
      )}
      {getButtonProps()}
    </div>
  );
};

export default ActionsCell;
