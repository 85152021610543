import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Tooltip } from 'dodoc-design-system';
import classNames from 'classnames';

import styles from './GenericHeader.module.scss';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

const ORDER_TYPES: Record<string, Request.OrderParams['order_type']> = {
  ASC: 'asc',
  DESC: 'desc',
};

const ORDER_ICONS: Record<string, IconTypes['24']> = {
  [ORDER_TYPES.ASC]: 'ElementListUpGrey',
  [ORDER_TYPES.DESC]: 'ElementListDownGrey',
};

export type GenericHeaderDTI = {
  root?: string;
  column?: string;
};

type GenericHeaderProps = {
  selectable?: boolean;
  columns: Table.Column[];
  currentOrder: Request.OrderParams;
  onOrderChanged: (newOrder: Request.OrderParams) => void;
  dataTestId?: GenericHeaderDTI;
};

const GenericHeader = ({
  selectable,
  columns,
  currentOrder,
  onOrderChanged,
  dataTestId,
}: GenericHeaderProps) => {
  const intl = useIntl();

  const getNextType = (column: Table.Column) => {
    const typesArray = Object.values(ORDER_TYPES);
    let nextTypeIndex;

    if (column.id !== currentOrder.order_field) {
      nextTypeIndex = 0;
    } else {
      nextTypeIndex = (typesArray.indexOf(currentOrder.order_type) + 1) % (typesArray.length + 1);
    }

    if (nextTypeIndex === typesArray.length) {
      return undefined;
    }

    return typesArray[nextTypeIndex];
  };

  const handleOrderUpdate = (column: Table.Column) => {
    const nextType = getNextType(column);

    if (!nextType) {
      onOrderChanged({
        order_field: '_id',
        order_type: ORDER_TYPES.ASC,
      });
    } else {
      onOrderChanged({
        order_field: column.id,
        order_type: nextType,
      });
    }
  };

  const renderIcon = (column: Table.Column) => {
    const icon =
      currentOrder.order_field === column.id
        ? ORDER_ICONS[currentOrder.order_type]
        : ORDER_ICONS[ORDER_TYPES.ASC];

    return (
      <Icon icon={icon} size={24} opacity={currentOrder.order_field === column.id ? 1 : 0.5} />
    );
  };

  return (
    <div
      className={`${styles.root} ${classNames(selectable && styles.selectable)}`}
      data-testid={dataTestId?.root}
    >
      {columns.map((column) => (
        <div
          key={column.id}
          style={{ width: `${column.size && `${column.size}rem`}` }}
          className={`${styles.header} ${column.size || styles.flex}`}
          data-testid={`${column.id}-column`}
        >
          <Tooltip
            content={intl.formatMessage({ id: 'TOGGLE_SORTING' })}
            placement="bottom"
            disabled={!column.orderable}
            testId={`${dataTestId?.column}-toggle-sorting-tooltip`}
          >
            <span
              onClick={column.orderable ? () => handleOrderUpdate(column) : () => {}}
              className={column.orderable ? styles.cursor : undefined}
            >
              {column.labelId && (
                <FormattedMessage id={column.labelId} values={column?.labelValues} />
              )}
              {column.orderable && (
                <span
                  className={`${styles.icon} ${
                    column.id === currentOrder.order_field ? styles.show : undefined
                  }`}
                >
                  {renderIcon(column)}
                </span>
              )}
            </span>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default GenericHeader;
