export class DOMProcess {
  static isElement(node: HTMLElement) {
    return node && node.nodeType === 1;
  }

  static isText(node: HTMLElement) {
    return node && node.nodeType === 3;
  }

  static parentOf(node: HTMLElement, nodeName: string, limiter?: HTMLElement) {
    if (limiter && node === limiter) {
      return;
    }
    if (node.parentNode) {
      while ((node = node.parentNode as HTMLElement)) {
        if (limiter && node === limiter) {
          return;
        }
        if (node.nodeName === nodeName) {
          return node;
        }
      }
    }
  }

  static getBoundingClientRect(element: HTMLElement) {
    if (!element) {
      return;
    }
    let rect;
    if (typeof element.getBoundingClientRect !== 'undefined') {
      rect = element.getBoundingClientRect();
    } else {
      let range = document.createRange();
      range.selectNode(element);
      rect = range.getBoundingClientRect();
    }
    return rect;
  }

  static getClientRects(element: HTMLElement) {
    if (!element) {
      return;
    }
    let rect;
    if (typeof element.getClientRects !== 'undefined') {
      rect = element.getClientRects();
    } else {
      let range = document.createRange();
      range.selectNode(element);
      rect = range.getClientRects();
    }
    return rect;
  }

  static nodeBefore(node: HTMLElement, limiter: HTMLElement) {
    if (limiter && node === limiter) {
      return;
    }
    let significantNode = DOMProcess.previousSignificantNode(node);
    if (significantNode) {
      return significantNode;
    }
    if (node.parentNode) {
      while ((node = node.parentNode as HTMLElement)) {
        if (limiter && node === limiter) {
          return;
        }
        significantNode = DOMProcess.previousSignificantNode(node);
        if (significantNode) {
          return significantNode;
        }
      }
    }
  }

  static nodeAfter(node: HTMLElement, limiter: HTMLElement) {
    if (limiter && node === limiter) {
      return;
    }
    let significantNode = DOMProcess.nextSignificantNode(node);
    if (significantNode) {
      return significantNode;
    }
    if (node.parentNode) {
      while ((node = node.parentNode as HTMLElement)) {
        if (limiter && node === limiter) {
          return;
        }
        significantNode = DOMProcess.nextSignificantNode(node);
        if (significantNode) {
          return significantNode;
        }
      }
    }
  }

  static previousSignificantNode(sib: HTMLElement) {
    while ((sib = sib.previousSibling as HTMLElement)) {
      if (!DOMProcess.isIgnorable(sib)) return sib;
    }
    return null;
  }

  static nextSignificantNode(sib: HTMLElement) {
    while ((sib = sib.nextSibling as HTMLElement)) {
      if (!DOMProcess.isIgnorable(sib)) return sib;
    }
    return null;
  }

  static isIgnorable(node: HTMLElement) {
    return (
      node.nodeType === 8 || // A comment node
      (node.nodeType === 3 && DOMProcess.isAllWhitespace(node))
    ); // a text node, all whitespace
  }

  static isAllWhitespace(node: HTMLElement) {
    return !/[^\t\n\r ]/.test(node.textContent || '');
  }
}
