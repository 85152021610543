import React from 'react';
import { Helper } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';
import styles from './Error.module.scss';

type ErrorProps = {
  text: string;
  testId: string;
};

const Error = ({ text, testId }: ErrorProps) => {
  return (
    <div className={styles.error_container}>
      <Helper type="error" testId={testId}>
        <FormattedMessage id={text} />
      </Helper>
    </div>
  );
};

export default Error;
