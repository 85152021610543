import SpaceDetails from '../Details/SpaceDetails';
import Description from '../Description/Description';
import Tags from '../Tags/Tags';

type SpacePanelProps = {
  object: Space;
};

const SpacePanel = ({ object }: SpacePanelProps) => {
  const canEdit = object.user_permissions.some((permission) =>
    ['admin', 'owner'].includes(permission),
  );

  return (
    <>
      <SpaceDetails />
      <Description canEdit={canEdit} />
      <Tags />
    </>
  );
};

export default SpacePanel;
