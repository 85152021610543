import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SectionHeading } from 'dodoc-design-system';
import ManualMetadata from './ManualMetadata';
import AutomaticMetadata from './AutomaticMetadata';
import styles from './Metadata.module.scss';
import { openModal } from 'App/ModalContext/utils';
import useMetadataList from '_common/hooks/useMetadataList';

const Metadata = () => {
  const intl = useIntl();
  const [selectedManualMetadata, setSelectedManualMetadata] = useState<number>(-1);
  const [selectedAutomaticMetadata, setSelectedAutomaticMetadata] = useState<number>(-1);
  const { automatic, manual } = useMetadataList();

  const openDeleteModal = (id: string, name: string) => {
    openModal({
      modal: 'DeleteMetadataModal',
      data: { id, name },
    });
  };

  return (
    <div className={styles.page}>
      <SectionHeading
        margin="0 0 4rem 0"
        testId="manual-metadata-heading"
        title={intl.formatMessage({ id: 'MANUAL_METADATA' })}
        buttonRightProps={{
          size: 'medium',
          testId: 'new-manual-metadata-button',
          variant: 'primary',
          children: intl.formatMessage({ id: 'NEW_MANUAL_METADATA' }),
          onClick: () => {
            openModal({ modal: 'ManualMetadataModal' });
          },
        }}
      />
      <ManualMetadata
        metadata={manual}
        selected={selectedManualMetadata}
        select={setSelectedManualMetadata}
        deleteMetadata={openDeleteModal}
      />
      <SectionHeading
        margin="7rem 0 4rem 0"
        testId="automatic-metadata-heading"
        title={intl.formatMessage({ id: 'AUTOMATIC_METADATA' })}
        buttonRightProps={{
          size: 'medium',
          testId: 'new-automatic-metadata-button',
          variant: 'primary',
          children: intl.formatMessage({ id: 'NEW_AUTOMATIC_METADATA' }),
          onClick: () => {
            openModal({ modal: 'AutomaticMetadataModal' });
          },
        }}
      />
      <AutomaticMetadata
        metadata={automatic}
        selected={selectedAutomaticMetadata}
        select={setSelectedAutomaticMetadata}
        deleteMetadata={openDeleteModal}
      />
    </div>
  );
};

export default Metadata;
