import { useDispatch } from '_common/hooks';
import { List } from '_common/components/';

import { updateSelectedObject } from 'Editor/redux/CurrentIntegrationSlice';

import Item from './Item/Item';

import type { ItemProps } from './Item/Item';
import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';

type ItemListProps<ExportType extends ExportIntegrationModal.ExportType> = {
  exportType: ExportType;
  info: ExportIntegrationModal.IntegrationUtils[ExportType];
  searchValue: string;
};

const ItemsList = <ExportType extends ExportIntegrationModal.ExportType>({
  searchValue,
  ...props
}: ItemListProps<ExportType>) => {
  const dispatch = useDispatch();
  const handleClick: ItemProps<ExportType>['onClick'] = (object) => {
    dispatch(updateSelectedObject(object));
  };

  const handleDoubleClick: ItemProps<ExportType>['onDoubleClick'] = (object) => {
    // @ts-expect-error gotta fix this later
    if (object.type === 'workspace') {
      (props.info as ExportIntegrationModal.IntegrationUtils['netdocuments']).listWorkspaceContent(
        object,
      );
    } else if (object.type === 'folder') {
      props.info.listFolderContent({ object, params: { size: 20 } });
    }
  };

  const loadRows = () => {
    switch (props.exportType) {
      case 'netdocuments': {
        const info = props.info as ExportIntegrationModal.IntegrationUtils['netdocuments'];

        if (data.nextSearch) {
          info.lazySearch({
            id: data.path[0].id,
            params: { size: 20, next: data.nextSearch },
            form: {
              query: searchValue,
              object: data.current,
              container: data.current.type === 'folder' ? data.current.id : '',
            },
          });
        } else if (data.next) {
          if (data.current && data.current.type === 'folder') {
            info.lazyFolderContent({
              current: data.current,
              next: {
                next: data.next,
                size: 20,
              },
            });
          } else {
            info.lazyloadCabinetContent({
              current: data.current,
              next: {
                next: data.next,
                size: 20,
              },
            });
          }
        }
        break;
      }

      case 'box': {
        const info = props.info as ExportIntegrationModal.IntegrationUtils['box'];
        if (data.nextSearch) {
          info.lazySearch({
            params: { size: 20, next: data.nextSearch },
            form: {
              query: searchValue,
              object: data.current,
              container: data.current.type === 'folder' ? data.current.id : '',
            },
          });
        } else if (data.next) {
          if (data.current && data.current.type === 'folder') {
            info.lazyFolderContent({
              current: data.current,
              next: {
                next: data.next,
                size: 20,
              },
            });
          } else if (data.current && data.current.id === 'allFiles') {
            info.lazyRootContent({
              current: data.current,
              params: {
                next: data.next,
                size: 20,
              },
            });
          } else if (data.current && data.current.id === 'recent') {
            info.lazyRecentContent({
              current: data.current,
              params: {
                next: data.next,
                size: 20,
              },
            });
          }
        } else if (data.current && data.current.id === 'favourites') {
          info.lazyFavouritesContent({
            current: data.current,
            params: {
              size: 20,
              offset: data.offset + 20,
            },
          });
        }
        break;
      }

      default:
        break;
    }
  };

  const { data } = props.info;
  return (
    <List
      style={{ height: '100%', width: '100%' }}
      objects={{
        list: data.content.list,
        dict: data.content.dict,
        selected: data.selected,
        data: {
          lazyThreshold: 5,
          lazyMinLimit: 1,
        },
        hasNextPage: data.hasNextPage,
        isNextPageLoading: data.isNextPageLoading,
      }}
      list={data.content.list}
      row={{
        Component: Item,
        props: {
          onClick: handleClick,
          onDoubleClick: handleDoubleClick,
          data,
          exportType: props.exportType,
        },
      }}
      loadRows={loadRows}
    />
  );
};

export default ItemsList;
