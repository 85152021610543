/* eslint-disable react/jsx-props-no-spreading */

import { useSelector } from '_common/hooks';
import { ErrorView } from '_common/components';
import EditorFailedConnection from './EditorFailedConnection';

const EditorError = () => {
  const code = useSelector((state) => state.modals.EditorErrorModal.code);
  const data = useSelector((state) => state.modals.EditorErrorModal);
  const isOpen = useSelector((state) => state.modals.open.EditorErrorModal);
  if (!isOpen) {
    return null;
  }
  switch (code) {
    case 'Unknown':
    case 'Gerbil':
    case 'Tyrant':
    case 'Sable':
    case 'Dassie':
    case 'Tayra':
    case 'Drongo':
    case 'Urial':
    case 'Lemming':
      return <EditorFailedConnection />;
    default:
      return <ErrorView error={data} />;
  }
};

export default EditorError;
