import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import { getDocumentObject } from './EditorStatusSlice';
import { setSidebarView } from './SidebarSlice';

export type TaskFilterStatusValue = 'td' | 'pr' | 'd' | 'dlt';
type TasksSliceState = {
  overlay: {
    selected: Editor.Task['id'] | null;
    operation: 'create' | 'view' | 'edit';
    offsets: { top: number; left: number; width: number; height: number } | null;
  };
  tasks: Record<Editor.Task['id'], Editor.Task>;
  order: Editor.Task['id'][];
  deleted: Editor.Task['id'][];
  isListMode: boolean;
  loaded: boolean;
};

const initialState: TasksSliceState = {
  overlay: {
    selected: null,
    operation: 'view',
    offsets: null,
  },
  tasks: {},
  order: [],
  deleted: [],
  isListMode: false,
  loaded: false,
};

const TasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTaskOverlayData: (state, action: PayloadAction<Partial<TasksSliceState['overlay']>>) => {
      state.overlay = { ...state.overlay, ...action.payload };
    },
    loadTasks: (state, action: PayloadAction<Pick<TasksSliceState, 'tasks' | 'order'>>) => {
      const { tasks, order } = action.payload;

      const newTasks = Object.values(tasks).reduce((tempTasks, task) => {
        tempTasks[task.id] = {
          ...task,
          d: typeof task.d === 'object' ? JSON.stringify(task.d) : task.d ?? '',
          r: task.r?.map((reply) => ({
            ...reply,
            content: typeof reply.c === 'object' ? JSON.stringify(reply.c) : reply.c ?? '',
          })),
        };

        return tempTasks;
      }, state.tasks);

      state.order = order;
      state.tasks = newTasks;
      state.loaded = true;
    },
    setTaskDeleted: (state, action: PayloadAction<ObjectId>) => {
      state.deleted = [...state.deleted, action.payload];
    },
    loadDeletedTasks: (
      state,
      action: PayloadAction<Pick<TasksSliceState, 'tasks' | 'deleted'>>,
    ) => {
      const { tasks, deleted } = action.payload;
      const newTasks = Object.values(tasks).reduce((tempTasks, task) => {
        tempTasks[task.id] = {
          ...task,
          d: typeof task.d === 'object' ? JSON.stringify(task.d) : task.d ?? '',
          r: task.r?.map((reply) => ({
            ...reply,
            content: typeof reply.c === 'object' ? JSON.stringify(reply.c) : reply.c ?? '',
          })),
        };

        return tempTasks;
      }, state.tasks);
      state.tasks = { ...state.tasks, ...newTasks };
      state.deleted = deleted;
    },
    updateTask: (state, action: PayloadAction<Editor.Task>) => {
      const task = action.payload;

      state.tasks[task.id] = {
        ...task,
        d: typeof task.d === 'object' ? JSON.stringify(task.d) : task.d ?? '',
        r: task.r?.map((reply) => ({
          ...reply,
          content: typeof reply.c === 'object' ? JSON.stringify(reply.c) : reply.c ?? '',
          author: reply.u,
          votes: reply.v,
        })),
      };
    },
    toggleListMode: (state) => {
      state.isListMode = !state.isListMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSidebarView, (state, action) => {
      if (action.payload) {
        state.overlay = {
          ...state.overlay,
          ...{ selected: null, offsets: null, operation: 'view' },
        };
      }
    });
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

const getTasksActiveState = (state: RootState) => state.editor.status.selection.TASK;
const getSelectedTask = (state: RootState) => state.editor.tasks.overlay.selected;
const getTasks = (state: RootState) => state.editor.tasks.tasks;
const getTasksFilters = (state: RootState) => state.filters.editorTaskPanel;
const getTasksOrder = (state: RootState) => state.editor.tasks.order;
const getTasksDeleted = (state: RootState) => state.editor.tasks.deleted;

export const selectFilteredTasks = createSelector(
  [getDocumentObject, getTasks, getTasksFilters, getTasksOrder, getTasksDeleted],
  (document, tasks, filters, order, deletedTasks) => {
    if (!document) {
      return [];
    }

    const filterValues = {
      taskStatus: filters.taskStatus?.map((filterValue) => filterValue.value),
      assignedUser: filters.assignedUser?.value,
    };

    const filter = (id: Editor.Task['id']) => {
      if (!tasks[id]) {
        return false;
      }

      // By status (only rejects if filter has values and status doesn't correspond to selection)
      if (filterValues.taskStatus && filterValues.taskStatus.length > 0) {
        if (!filterValues.taskStatus?.includes(tasks[id].t?.dlt ? 'dlt' : tasks[id].s)) {
          return false;
        }
        //If there are no active filters and task is deleted, do not show in tab
      } else if (tasks[id].t?.dlt) {
        return false;
      }

      // By Assignee (only rejects if filter has values and status doesn't correspond to selection)
      if (filterValues.assignedUser && filterValues.assignedUser !== tasks[id].asg) {
        return false;
      }

      return true;
    };

    const newArrays = [...order, ...deletedTasks];
    return newArrays.filter(filter).map((id) => tasks[id]);
  },
);

export const selectAssigneeTasks = createSelector(
  [getDocumentObject, getTasks],
  (document, tasks) => {
    if (!document) {
      return null;
    }

    const options = Object.keys(tasks).map((id) => ({ value: tasks[id].asg }));
    const cleanOptions = options.filter((t) => t.value);

    return cleanOptions;
  },
);

export const selectActiveTasks = createSelector([getTasksActiveState], (active) => {
  if (!active) {
    return { blockId: undefined, tasks: [] };
  }
  return { blockId: active.blockId, tasks: active.tasks };
});

export const selectTask = createSelector([getSelectedTask, getTasks], (selected, tasks) => {
  if (selected && tasks[selected]) {
    return {
      ...tasks[selected],
    };
  }
  return null;
});

export const {
  setTaskOverlayData,
  loadTasks,
  setTaskDeleted,
  loadDeletedTasks,
  updateTask,
  toggleListMode,
} = TasksSlice.actions;

export default TasksSlice.reducer;
