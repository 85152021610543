import { FormattedMessage } from 'react-intl';
import styles from './Metadata.module.scss';
import cn from 'classnames';

const NotApplicable = ({ place }: { place: 'table' | 'details' }) => {
  return (
    <div className={cn(styles.notApplicable, styles[place])}>
      <FormattedMessage id="NOT_APPLICABLE" />
    </div>
  );
};

export default NotApplicable;
