import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';
import { isEqual } from 'lodash';

import EditorManager from 'Editor/services/EditorManager';
import { useDispatch, useSelector } from '_common/hooks';
import { closeModal } from '_common/modals/ModalsSlice';

import { MenuItem, TabMenu } from '_common/components';

import { Cell, Column, Row, Table } from './TabContent';
import TablePropertiesContext, { AbsoluteUnit } from './TablePropertiesContext';

import styles from './TableProperties.module.scss';

const TableProperties = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [preferedUnit, setPreferedUnit] = useState<AbsoluteUnit>('cm');
  const [hasValidWidth, setHasValidWidth] = useState(true);
  const [originalData, setOriginalData] = useState<Editor.Styles.TablePropertiesData | null>(null);
  const [unsavedData, setUnsavedData] = useState<Editor.Styles.TablePropertiesData>({
    TABLE: {},
    ROW: {},
    COLUMN: {},
    CELL: {},
  });

  const isOpen = useSelector((state) => state.modals.open.TablePropertiesModal);

  useEffect(() => {
    if (isOpen) {
      const manager = EditorManager.getInstance();
      const tableProperties = manager.getSelectedTableProperties();
      if (tableProperties) {
        setOriginalData(tableProperties);
        setUnsavedData(tableProperties);
      }
    }
  }, [isOpen]);

  const handleClose = () => {
    EditorManager.getInstance().restore();
    dispatch(closeModal('TablePropertiesModal'));
  };

  const handleUpdateData: TabContentProps['updateData'] = ({ element, property, value }) => {
    setUnsavedData((prevState) => {
      let newState = {
        ...prevState,
        [element]: {
          ...prevState[element],
          [property]: value,
        },
      };

      return newState;
    });
  };

  const filterDiffProperties = (
    elementUnsavedData: Editor.Styles.TableProperties,
    elementOriginalData: Editor.Styles.TableProperties | undefined,
  ): Partial<Editor.Styles.TableProperties> => {
    if (!elementOriginalData) {
      return {};
    }

    let diffProperties: Partial<Editor.Styles.TableProperties> = {};

    Object.keys(elementUnsavedData).forEach((property) => {
      const typedProperty = property as keyof typeof elementUnsavedData;
      if (!isEqual(elementUnsavedData[typedProperty], elementOriginalData[typedProperty])) {
        diffProperties = {
          ...diffProperties,
          [typedProperty]: elementUnsavedData[typedProperty],
        };
      }
    });

    return diffProperties;
  };

  const handleSave = () => {
    const diff: Editor.Styles.TablePropertiesData = {
      TABLE: filterDiffProperties(unsavedData.TABLE, originalData?.TABLE),
      ROW: filterDiffProperties(unsavedData.ROW, originalData?.ROW),
      COLUMN: filterDiffProperties(unsavedData.COLUMN, originalData?.COLUMN),
      CELL: filterDiffProperties(unsavedData.CELL, originalData?.CELL),
    };

    EditorManager.getInstance().setTableProperties(diff);
    handleClose();
  };

  if (!originalData) {
    return null;
  }

  return (
    <Modal open={!!isOpen} onClose={handleClose} width={'112rem'} testId="table-properties">
      <Modal.Header onClose={handleClose}>
        <FormattedMessage id="TABLE_PROPERTIES" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <TablePropertiesContext.Provider
          value={{
            pageSize: originalData.PAGE?.width,
            setHasValidWidth,
            preferedUnit,
            setPreferedUnit,
          }}
        >
          <TabMenu menuId="tableProperties">
            <MenuItem
              id="TABLE"
              text={intl.formatMessage({ id: 'TABLE' })}
              className={styles.inheritHeight}
            >
              <Table unsavedData={unsavedData.TABLE} updateData={handleUpdateData} />
            </MenuItem>
            <MenuItem
              id="COLUMN"
              text={intl.formatMessage({ id: 'COLUMN' })}
              className={styles.inheritHeight}
            >
              <Column unsavedData={unsavedData.COLUMN} updateData={handleUpdateData} />
            </MenuItem>
            <MenuItem
              id="ROW"
              text={intl.formatMessage({ id: 'ROW' })}
              className={styles.inheritHeight}
            >
              <Row unsavedData={unsavedData.ROW} updateData={handleUpdateData} />
            </MenuItem>
            <MenuItem
              id="CELL"
              text={intl.formatMessage({ id: 'CELL' })}
              className={styles.inheritHeight}
            >
              <Cell unsavedData={unsavedData.CELL} updateData={handleUpdateData} />
            </MenuItem>
          </TabMenu>
        </TablePropertiesContext.Provider>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={handleClose} testId="table-properties-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          disabled={!hasValidWidth}
          onClick={handleSave}
          testId="table-properties-submit-button"
        >
          <FormattedMessage id="global.save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TableProperties;
