import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';

import { TabMenu, MenuItem, UserCard } from '_common/components';

import History from './History/History';
import ActiveSession from './ActiveSession/ActiveSession';
import styles from './UserTokensModal.module.scss';

import { Modal, Button } from 'dodoc-design-system';

const MODAL = 'UserTokensModal';

const UserTokensModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // redux
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const user = useSelector((state) => state.modals[MODAL].user);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="100rem" testId="user-tokens-modal">
      <div className={styles.form}>
        <Modal.Header onClose={close}>
          <FormattedMessage id="USER_TOKEN_HISTORY" />
        </Modal.Header>
        <Modal.Body>
          <div className={`${styles.body}`}>
            <div className={styles.content}>
              <UserCard userId={user.id} size="large" />
              <div className={styles.page}>
                <TabMenu menuId="userTokenHistory">
                  <MenuItem text={intl.formatMessage({ id: 'TOKEN_HISTORY' })} id="history">
                    <History />
                  </MenuItem>
                  <MenuItem text={intl.formatMessage({ id: 'ACTIVE_SESSION' })} id="activeSession">
                    <ActiveSession />
                  </MenuItem>
                </TabMenu>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer alignment="flex-end">
          <Button size="medium" onClick={close} testId="close-button">
            <FormattedMessage id="global.close" />
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default UserTokensModal;
