import { FormattedMessage } from 'react-intl';
import { Button, Helper } from 'dodoc-design-system';

import { useSelector } from '_common/hooks/redux';
import { useGetObjectQuery } from 'App/redux/objectApi';
import { selectShareModalValues } from 'App/redux/appSlice';

import Table from '_common/components/Permissions/Table/Table';

import styles from './ShareModal.module.scss';

type SharedListContainerProps = {
  applyToDescendants: boolean;
  showGranularPermissions: boolean;
  disabled?: boolean;
  toggleGranularPermissions: () => void;
};

const SharedListContainer = ({
  applyToDescendants,
  showGranularPermissions,
  disabled,
  toggleGranularPermissions,
}: SharedListContainerProps) => {
  const { usersList, groupsList } = useSelector(selectShareModalValues);
  const objectId = useSelector((state) => state.modals.ShareModal.objectId);
  const objectType = useSelector((state) => state.modals.ShareModal.objectType);
  const { data: object } = useGetObjectQuery(
    { objectId, objectType },
    { skip: !objectId && !objectType },
  );

  if (usersList.length === 0 && groupsList.length === 0) {
    return (
      <Helper testId="share-modal-no-collaborators-helper">
        <FormattedMessage
          id="storage.modals.share.notSharedWithGroups"
          values={{ name: object && Object.keys(object).length > 0 ? object?.name : '' }}
        />
      </Helper>
    );
  }
  return (
    <>
      <div className={styles.collaboratorsHeader}>
        <FormattedMessage id="COLLABORATORS" />
        <Button
          variant="link"
          margin="0 0 0 0.5rem"
          size="small"
          onClick={toggleGranularPermissions}
          disabled={disabled}
          testId={`${showGranularPermissions ? 'hide' : 'see'}-granular-permissions-button`}
        >
          {showGranularPermissions ? (
            <FormattedMessage id="HIDE_GRANULAR_PERMISSIONS" />
          ) : (
            <FormattedMessage id="SEE_GRANULAR_PERMISSIONS" />
          )}
        </Button>
      </div>
      <div className={styles.usersContainer}>
        <Table
          objectId={objectId}
          objectType={objectType}
          recursive={applyToDescendants}
          showGranular={showGranularPermissions}
          maxHeight="27rem"
          canEdit={!disabled}
          testId="share-permissions"
        />
      </div>
    </>
  );
};

export default SharedListContainer;
