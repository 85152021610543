import { Tooltip } from 'dodoc-design-system';
import { MathComponent } from 'mathjax-react';

import { Structure, Symbol } from './ToolbarElements';

type MathMLProps = {
  element: (Structure['elements'][number] | Symbol['elements'][number]) & { _equation?: string };
  testId: string;
};

const MathML = ({ element, testId }: MathMLProps) => {
  if (element?.label) {
    return (
      <Tooltip content={element.label} testId={`${testId}-tooltip`}>
        <div>
          <MathComponent mathml={element.mathml} />
        </div>
      </Tooltip>
    );
  }
  return <MathComponent mathml={element.mathml || element._equation} />;
};

export default MathML;
