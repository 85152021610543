export default class ErrorCannotMergeCells extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'ErrorCannotMergeCells';

    if (!this.message || this.message.length === 0) {
      this.message = 'Cannot merge cells!';
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorCannotMergeCells);
    }
  }
}
