import { ReactNode, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState, ProgressBar } from 'dodoc-design-system';
import { Table } from '_common/components/Table2';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToEditor, navigateToMyFiles, navigateToObject } from 'router/history';

import { setCurrentAppPage } from 'App/redux/appSlice';
import {
  searchObjects,
  setAdvancedFilters,
  setInfoPanelOpen,
  setLoading,
  updateRequest,
} from 'Search/redux/SearchPageSlice';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

import { ErrorView, FormattedDate, InformationPanel, UserCard } from '_common/components';

import AdvancedSearchFilters from './AdvancedSearchFilters/AdvancedSearchFilters';
import SearchBreadcrumb from './SearchBreadcrumb/SearchBreadcrumb';

import styles from './SearchPage.module.scss';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import Cell from '_common/components/Table2/Cells';
import MetadataCell from './Cells/MetadataCell';
import AuthorsCell from './Cells/AuthorsCell';
import ReferencePriorityCell from './Cells/ReferencePriorityCell';
import { setObjectPreview } from '_common/components/ObjectPreview/ObjectPreviewSlice';
import { useGetUserSettingsQuery } from '_common/services/api/UserApi';

export const pageIdentity = 'search';

const SearchPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data: userSettings } = useGetUserSettingsQuery();
  const { data: metadata } = useGetMetadataListQuery();
  const data = useSelector((state) => state.app.data);
  const error = useSelector((state) => state.app.error);

  const list = useSelector((state) => state.table.identity.search.list);

  const parent = useSelector((state) => state.search.parent);
  const initialParent = useSelector((state) => state.search.initialParent);
  const searchLoading = useSelector((state) => state.search.loading);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const infoPanelOpen = useSelector((state) => state.search.infoPanelOpen);
  const activeTab = useSelector((state) => state.search.activeTab);

  const params = useSelector((state) => state.search.request.params);
  const isNextPageLoading = useSelector((state) => state.search.request.isNextPageLoading);
  const advancedFilters = useSelector((state) => state.search.advancedFilters);
  const advancedFiltersArray = useMemo(() => Object.values(advancedFilters), [advancedFilters]);
  const extraColumnsValues: string[] = [];

  const handleOrderUpdate = (newOrder: Request.OrderParams) => {
    dispatch(
      updateRequest({
        params: { ...params, order_field: newOrder.order_field, order_type: newOrder.order_type },
      }),
    );
  };

  const handleExtraColumns = () => {
    if (searchQuery) {
      ['deadline', 'authors.name'].forEach((column) => {
        if (searchQuery.includes(column) && !extraColumnsValues.includes(column)) {
          extraColumnsValues.unshift(column === 'deadline' ? 'due' : column);
        }
      });
      if (metadata) {
        const metadataIds = Object.keys(metadata);
        metadataIds.forEach((metadataId) => {
          const name = `metadata.${metadata[metadataId].name.toLowerCase().replace(/ /, '_')}`;
          if (searchQuery.includes(name) && !extraColumnsValues.includes(name)) {
            extraColumnsValues.unshift(`metadata.${metadataId}`);
          }
        });
      }
      if (advancedFilters) {
        const filter = advancedFiltersArray.filter(
          (filter: { option: string }) => filter.option === 'reference_priority',
        );
        if (filter.length > 0) {
          filter.forEach((element) => {
            const name = `${element.option}.${element.value}.${element.auxValue}`;
            if (!extraColumnsValues.includes(name)) {
              extraColumnsValues.unshift(name);
            }
          });
        }
      }
    }
  };

  handleExtraColumns();

  const commonColumns = useMemo<ColumnProps[]>(() => {
    const columns = [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        width: 280,
        flex: true,
        frozen: true,
        orderable: true,
      },
      {
        id: 'time.modification',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        width: 160,
        orderable: true,
        body: (data: any) => {
          return data['time.modification'];
        },
      },
      {
        id: 'status',
        header: intl.formatMessage({ id: 'storage.browserHeader.status' }),
        width: 120,
      },
      {
        id: 'shared_with',
        header: intl.formatMessage({ id: 'storage.browserHeader.sharedWith' }),
        width: 120,
        orderable: true,
      },
      {
        id: 'owner',
        header: intl.formatMessage({ id: 'global.owner' }),
        width: 200,
      },
      {
        id: 'time.creation',
        header: intl.formatMessage({ id: 'CREATION_DATE' }),
        width: 160,
        orderable: true,
        body: (data: any) => {
          return data['time.creation'];
        },
      },
    ] as const;
    return columns.filter((column) => userSettings?.tables.search[column.id]);
  }, [userSettings, extraColumnsValues]);

  const extraColumns = useMemo<ColumnProps[]>(() => {
    if (extraColumnsValues.length > 0) {
      return extraColumnsValues.map((extra: string) => {
        const columnType = extra.split('.');
        switch (columnType[0]) {
          case 'due':
            return {
              id: 'events.due',
              header: intl.formatMessage({ id: 'settings.general.dueDate' }),
              width: 200,
              body: (data) => {
                return data['events.due'];
              },
            };
          case 'authors':
            return {
              id: 'authors',
              header: intl.formatMessage({ id: 'EDITOR_DOCUMENT_AUTHORS' }),
              width: 200,
              orderable: true,
            };
          case 'metadata':
            const metadataId = columnType[1];
            return {
              id: metadata?.[metadataId].name.replaceAll(' ', '_').toLowerCase() ?? 'metadata',
              header: metadata?.[metadataId].name ?? 'metadata',
              width: 200,
              body: (data) => {
                return data[metadataId];
              },
            };
          case 'reference_priority':
            return {
              id: extra,
              header: intl.formatMessage({
                id: columnType[2] === 'is' ? 'REFERENCE_IN_DOCUMENT' : 'REFERENCE_IN_LIBRARY',
              }),
              width: 200,
              body: (data) => {
                return data[extra];
              },
              flex: true,
              orderable: true,
            };
          default:
            return {
              id: 'null',
              header: 'undefined',
              width: 200,
            };
        }
      });
    }
    return [];
  }, [extraColumnsValues]);

  const columns = () => {
    if (extraColumnsValues.length > 0) {
      return commonColumns.concat(extraColumns);
    } else {
      return commonColumns;
    }
  };

  const referencePriorityValue = (object: Objekt, objectId: string) => {
    if (extraColumnsValues.filter((extra) => extra.includes('reference_priority')).length > 0) {
      return {
        'reference_priority.low.is': (
          <div data-testid={`${objectId}-low-reference-priority-in-document-column`}>
            <ReferencePriorityCell object={object} value={'low.is'} />
          </div>
        ),
        'reference_priority.medium.is': (
          <div data-testid={`${objectId}-medium-reference-priority-in-document-column`}>
            <ReferencePriorityCell object={object} value={'medium.is'} />
          </div>
        ),
        'reference_priority.high.is': (
          <div data-testid={`${objectId}-high-reference-priority-in-document-column`}>
            <ReferencePriorityCell object={object} value={'high.is'} />
          </div>
        ),
        'reference_priority.low.not': (
          <div data-testid={`${objectId}-low-reference-priority-in-library-column`}>
            <ReferencePriorityCell object={object} value={'low.not'} />
          </div>
        ),
        'reference_priority.medium.not': (
          <div data-testid={`${objectId}-medium-reference-priority-in-library-column`}>
            <ReferencePriorityCell object={object} value={'medium.not'} />
          </div>
        ),
        'reference_priority.high.not': (
          <div data-testid={`${objectId}-high-reference-priority-in-library-column`}>
            <ReferencePriorityCell object={object} value={'high.not'} />
          </div>
        ),
      };
    }
  };

  const metadataValue = (object: Objekt, objectId: string) => {
    if (metadata && extraColumnsValues.filter((extra) => extra.includes('metadata')).length > 0) {
      return Object.entries(metadata).reduce<Record<string, ReactNode>>((previous, current) => {
        const [id, values] = current;
        const name = values.name.replaceAll(' ', '-').toLowerCase();

        previous[id] = (
          <div data-testid={`${objectId}-${name}-metadata-column`}>
            <MetadataCell object={object} metadata={values} />
          </div>
        );
        return previous;
      }, {});
    }
    return null;
  };

  const value = useMemo(() => {
    return list.map((objectId) => {
      const object = data[objectId];
      return {
        ...metadataValue(object, objectId),
        ...referencePriorityValue(object, objectId),
        id: object.id,
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis noWidth>
            <Cell.ObjectName object={object} testId={`${objectId}-object-name`} />
          </Cell>
        ),
        'time.modification': (
          <Cell testId={`${objectId}-date-modified-column`}>
            <Cell.DateTime
              date={{ date: object.time.modification }}
              time={{ time: object.time.modification }}
            />
          </Cell>
        ),
        status: (
          <Cell testId={`${objectId}-status-column`}>
            <Cell.ObjectStatus object={object} testId="object-status" />
          </Cell>
        ),
        shared_with: (
          <Cell testId={`${objectId}-shared-column`}>
            <Cell.Collaborators object={object} />
          </Cell>
        ),
        owner: (
          <Cell testId={`${objectId}-owner-column`}>
            <UserCard userId={object.owner} />
          </Cell>
        ),
        'time.creation': (
          <Cell testId={`${objectId}-creation-date-column`}>
            <Cell.DateTime
              date={{ date: object.time.creation }}
              time={{ time: object.time.creation }}
            />
          </Cell>
        ),
        'events.due': (
          <Cell testId={`${objectId}-due-date-column`}>
            <FormattedDate date={object.events.due} />
          </Cell>
        ),
        authors: (
          <Cell testId={`${objectId}-authors-column`}>
            <AuthorsCell object={object} />
          </Cell>
        ),
      };
    });
  }, [list, data, extraColumnsValues]);

  useEffect(() => {
    dispatch(setCurrentAppPage('/search'));
  }, []);

  useEffect(() => {
    const promise = triggerSearch();

    return () => {
      promise?.abort?.();
    };
  }, [searchQuery, activeTab, params.order_field, params.order_type]);

  // ------------------------------------------------------
  // ---------------------- Utils -------------------------
  const triggerSearch = (lazyload = false) => {
    if (lazyload && isNextPageLoading) {
      dispatch(setLoading(false));
      return null;
    }

    let tempQuery: string[] = [];
    if (searchQuery) {
      tempQuery.push(searchQuery);
    }
    if (activeTab === 'shared') {
      tempQuery.push(`share = "True"`);
    } else if (activeTab === 'parent' && initialParent) {
      tempQuery.push(`parent = "${initialParent.id}"`);
    }

    return dispatch(
      searchObjects({
        parent,
        query: tempQuery.join(' and '),
        params: { ...params, offset: lazyload ? params.offset : 0, lazyload },
      }),
    );
  };

  const checkErrorStatus = () => {
    return error.status === 400 || error.status === 403 || error.status === 404;
  };

  // ------------------------------------------------------
  // --------------------- Events -------------------------

  const toggleInfoPanel = () => {
    dispatch(setInfoPanelOpen(!infoPanelOpen));
  };

  const handleRowDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({
    data: object,
    originalEvent,
  }) => {
    const currentObject = data[object.id];
    if (currentObject.status === 'processing' || currentObject.status === 'broken') {
      return;
    }

    const type = currentObject.type;
    switch (type) {
      case 'document':
        navigateToEditor(currentObject.id);
        break;
      case 'file':
        dispatch(setObjectPreview({ id: currentObject.id }));
        originalEvent.preventDefault();
        break;
      case 'folder':
        navigateToObject('folder', currentObject.id);
        break;
      case 'dopdf':
        window.open(`/pdf/${currentObject.id}`, '_blank');
        break;
      case 'presentation':
        window.open(`/presentation/${currentObject.id}`, '_blank');
        break;
      default:
        break;
    }
  };

  // ------------------------------------------------------
  // ---------------------- Render ------------------------
  const handleOnClickRemoveAllFilters = () => {
    dispatch(setAdvancedFilters({}));
  };

  const renderEmptyView = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
        data-testid="search-empty"
      >
        <EmptyState
          size="large"
          icon="NoSearchResults"
          title={intl.formatMessage({ id: 'SEARCH_EMPTY_TITLE' })}
          onClick={handleOnClickRemoveAllFilters}
          footer={<FormattedMessage id="CLEAR_FILTERS" />}
          testId="search-page-empty-state"
        >
          {intl.formatMessage({ id: 'SEARCH_EMPTY_DESCRIPTION' })}
        </EmptyState>
      </div>
    );
  };
  //#endregion

  const renderContent = () => {
    if (searchLoading) {
      return (
        <div className={styles.loading}>
          <ProgressBar testId="search-loading-progressBar" />
          <div className={styles.message}>
            <FormattedMessage id="SEARCHING_FOR_RESULTS" />
          </div>
        </div>
      );
    }

    // check for error view
    if (checkErrorStatus()) {
      return <ErrorView error={error} onClick={() => navigateToMyFiles(false)} />;
    }

    return (
      <div className={styles.content} data-testid="search-content">
        <div className={styles.table} data-testid="search-table">
          <Table
            identity="search"
            columns={columns()}
            value={value}
            renderFooter={() => null}
            isLoading={isNextPageLoading}
            loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
            testId="search"
            renderEmptyState={renderEmptyView}
            onRowDoubleClick={handleRowDoubleClick}
            onOrder={handleOrderUpdate}
            order={{ order_field: params.order_field, order_type: params.order_type }}
          />
        </div>
        <InformationPanel
          isOpen={infoPanelOpen}
          onClose={toggleInfoPanel}
          testId={'search-information-panel'}
        />
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <SearchBreadcrumb />
      <AdvancedSearchFilters />
      {renderContent()}
    </div>
  );
};

export default SearchPage;
