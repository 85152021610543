import { useState } from 'react';
import styles from './TableCreator.module.scss';

type TableCreatorProps = { insertTable: (rows: number, columns: number) => void };

const MIN_COLUMNS = 5;
const MAX_COLUMNS = 10;
const MIN_ROWS = 5;
const MAX_ROWS = 10;

const TableCreator = ({ insertTable }: TableCreatorProps) => {
  const [maxRows, setMaxRows] = useState(MIN_ROWS);
  const [maxColumns, setMaxColumns] = useState(MIN_COLUMNS);
  const [hoveredRow, setHoveredRow] = useState(0);
  const [hoveredColumn, setHovereColumn] = useState(0);

  const handleSquareMouseEnter = (i: typeof hoveredRow, j: typeof hoveredColumn) => {
    let newMaxRows = i + 2;
    let newMaxColumns = j + 2;
    if (newMaxRows > MAX_ROWS) {
      newMaxRows = MAX_ROWS;
    }
    if (newMaxRows < MIN_ROWS) {
      newMaxRows = MIN_ROWS;
    }
    if (newMaxColumns > MAX_COLUMNS) {
      newMaxColumns = MAX_COLUMNS;
    }
    if (newMaxColumns < MIN_COLUMNS) {
      newMaxColumns = MIN_COLUMNS;
    }
    setMaxRows(newMaxRows);
    setMaxColumns(newMaxColumns);
    setHoveredRow(i);
    setHovereColumn(j);
  };

  const handleSquareClick = (i: typeof maxRows, j: typeof maxColumns) => {
    insertTable(i + 1, j + 1);
    setHoveredRow(0);
    setHovereColumn(0);
  };

  const renderGrid = () => {
    const renderSquares = () => {
      const squares = [];
      for (let i = 0; i < maxRows; i += 1) {
        for (let j = 0; j < maxColumns; j += 1) {
          squares.push(
            <div
              key={`${i}-${j}`}
              className={`${styles.square} ${
                i <= hoveredRow && j <= hoveredColumn ? styles.selected : undefined
              }`}
              data-testid={`menu-insert-table-grid-${i}-${j}`}
            />,
          );
        }
      }
      return squares;
    };
    return (
      <div style={{ gridTemplateColumns: `repeat(${maxColumns}, 2rem)` }} className={styles.grid}>
        {renderSquares()}
      </div>
    );
  };

  const renderInvisible = () => {
    const renderSquares = () => {
      const squares = [];
      for (let i = 0; i < MAX_ROWS; i += 1) {
        for (let j = 0; j < MAX_COLUMNS; j += 1) {
          squares.push(
            <button
              type="button"
              key={`i-${i}-${j}`}
              className={`${styles.square} ${styles.invisible}`}
              onClick={() => handleSquareClick(i, j)}
              onMouseEnter={() => handleSquareMouseEnter(i, j)}
              aria-label={`table-${i}x${j}`}
            />,
          );
        }
      }
      return squares;
    };
    return (
      <div
        style={{ gridTemplateColumns: `repeat(${MAX_COLUMNS}, 2.5rem)` }}
        className={`${styles.grid} ${styles.invisible}`}
      >
        {renderSquares()}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {renderGrid()}
      {renderInvisible()}
      <div className={styles.label}>{`${hoveredRow + 1}x${hoveredColumn + 1}`}</div>
    </div>
  );
};

export default TableCreator;
