import { createSlice, PayloadAction, createSelector, isAnyOf } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import { setSidebarView } from './SidebarSlice';

const SLICE_NAME = 'EDITOR_NOTES';

type SliceState = {
  overlay: {
    selected: string | null;
    type: Editor.Note['type'];
    offsets: {
      top: number;
      left: number;
      width: number;
      height: number;
    } | null;
    operation: string;
  };
  searchValue: string;
  footnotes: string[];
  endnotes: string[];
  data: {
    [key: string]: Editor.Note;
  };
  p: 'd' | 's';
};

const initialState: SliceState = {
  overlay: {
    selected: null,
    type: 'footnote',
    offsets: null,
    operation: 'create',
  },
  searchValue: '',
  footnotes: [],
  endnotes: [],
  data: {},
  p: 'd',
};

const notesSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setSidebarNotesSearchValue: (state, action: PayloadAction<SliceState['searchValue']>) => {
      state.searchValue = action.payload;
    },
    setNoteOverlayData: (state, action: PayloadAction<Partial<SliceState['overlay']>>) => {
      state.overlay = { ...state.overlay, ...action.payload };
    },
    loadNotes: (state, action: PayloadAction<SliceState>) => {
      return {
        ...state,
        //@ts-expect-error need to connect type with RT
        footnotes: action.payload.footnoteIndex,
        //@ts-expect-error need to connect type with RT
        endnotes: action.payload.endnoteIndex,
        //@ts-expect-error need to connect type with RT
        data: action.payload.nts,
        p: action.payload.p,
      };
    },
    addNote: (state, action: PayloadAction<Editor.Note>) => {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    },
    updateNote: (state, action: PayloadAction<Editor.Note>) => {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSidebarView, (state, action) => {
      if (action.payload) {
        state.overlay = { ...state.overlay, ...{ selected: null, offsets: null } };
      }
    });
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

const getNotesType = (state: RootState) => state.editor.sidebar.tabs.notes;
const getNotes = (state: RootState) => state.editor.notes;
const getSelectedNote = (state: RootState) => state.editor.notes.overlay.selected;

export const selectNotes = createSelector([getNotesType, getNotes], (type, notes) => {
  if (Object.keys(notes.data).length > 0) {
    const formattedNotes: Editor.Note[] = notes[type]
      .filter((noteId: string) => notes.data[noteId])
      .map((noteId: string, index: number) => {
        return {
          ...notes.data[noteId],
          order: index + 1,
          id: noteId,
        };
      });

    return notes.searchValue === ''
      ? formattedNotes
      : formattedNotes.filter((note: { content: string }) =>
          note.content.toLowerCase().includes(notes.searchValue.toLowerCase()),
        );
  }

  return [];
});

export const selectNote = createSelector([getSelectedNote, getNotes], (selected, notes) => {
  if (selected && notes.data[selected]) {
    if (notes.footnotes.includes(selected)) {
      return {
        ...notes.data[selected],
        id: selected,
        order: notes.footnotes.indexOf(selected) + 1,
      };
    }
    if (notes.endnotes.includes(selected)) {
      return {
        ...notes.data[selected],
        id: selected,
        order: notes.endnotes.indexOf(selected) + 1,
      };
    }
  }
  return null;
});

export const { setSidebarNotesSearchValue, setNoteOverlayData, loadNotes, addNote, updateNote } =
  notesSlice.actions;

export default notesSlice.reducer;
