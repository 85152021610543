import { uniq } from 'lodash';
import { SuiteAvatar } from '_common/suite/components';
import styles from './Title.module.scss';

type AvatarListProps = {
  users: UserId[];
};

const AvatarList = ({ users }: AvatarListProps) => {
  return (
    <div className={styles.container}>
      {users.length > 0 && (
        <div className={styles.avatars} data-testid="avatar-list-container">
          {uniq(users).map((userId) => (
            <SuiteAvatar showTooltip key={userId} margin="0 0 0 0.5rem" userId={userId} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AvatarList;
