import { ProgressBar, Icon } from 'dodoc-design-system';

import { FormattedMessage } from 'react-intl';

import style from './Loading.module.scss';

import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type LoadingProps = {
  message: TranslationMessage;
  icon?: IconTypes[96];
};

const Loading = ({ message, icon }: LoadingProps) => {
  return (
    <div className={style.loading}>
      {icon && (
        <div className={style.icon}>
          <Icon icon={icon} size={96} />
        </div>
      )}
      <ProgressBar color="primaryLight" size="medium" testId="users-loading-progressBar" />
      <span>
        <FormattedMessage id={message.id} values={message.values} />
        ...
      </span>
    </div>
  );
};

export default Loading;
