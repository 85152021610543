import { FormattedMessage } from 'react-intl';
import { Icon, Popover, usePopper } from 'dodoc-design-system';

import { Structure, Symbol } from 'Editor/modals/EquationsModal/ToolbarElements';
import CharGridItem from './CharGridItem/CharGridItem';
import GridItem from './GridItem/GridItem';

import styles from './ToolbarSection.module.scss';

type ToolbarSectionProps =
  | {
      type: 'structures';
      element: Structure;
    }
  | {
      type: 'symbols';
      element: Symbol;
    };

const ToolbarSection = ({ type, element }: ToolbarSectionProps) => {
  const { popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
    trigger: 'toggle',
  });
  return (
    <div className={styles.section}>
      <div className={styles.section} {...referenceProps}>
        <Icon icon={element.section} size={40} />
      </div>
      <Popover {...popperProps} testId="toolbar-section-popper">
        {!type || !element.elements ? null : (
          <div className={styles.popover}>
            <div className={styles.label}>
              <FormattedMessage id={element.label} />
            </div>
            <div className={type === 'structures' ? styles.grid : styles.char_grid}>
              {type === 'structures'
                ? element.elements.map((element, i) => (
                    <GridItem key={element.label} element={element} testId={`structure-${i}`} />
                  ))
                : element.elements.map((element, i) => (
                    <CharGridItem key={element.code} element={element} testId={`symbol-${i}`} />
                  ))}
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default ToolbarSection;
