import { Logo } from 'dodoc-design-system';
import { LogoProps } from 'dodoc-design-system/build/types/Components/Logo/Logo';

import styles from './LogoContainer.module.scss';

type Props = {
  expanded: boolean;
  size?: 'small' | 'medium' | 'large';
  color: 'blue' | 'black' | 'white';
} & Pick<LogoProps, 'testId'>;

const LogoContainer = ({ expanded = false, size, color, testId }: Props) => (
  <div className={styles.root} data-testid={testId}>
    <Logo size={size} expanded={expanded} color={color} testId={testId} />
  </div>
);

export default LogoContainer;
