import { useMemo } from 'react';
import cx from 'classnames';

import { RichTextEditor } from '_common/components';

import styles from './MinifiedCard.module.scss';
import SuiteAvatar from '_common/suite/components/SuiteAvatar/SuiteAvatar';

type MinifiedCardProps = {
  status: Card.Task['status'] | Card.Comment.State;
  state: Card.Task['state'] | Card.Comment.State;
  content: Exclude<Card.Task['content'], undefined>['content'] | undefined;
  authorId?: string;
  authorName?: string;
  order: number;
  onClick: () => void;
  testId: string;
};

const MinifiedCard = ({
  status,
  state,
  content,
  order,
  authorId,
  authorName,
  testId,
  onClick,
}: MinifiedCardProps) => {
  const hasAuthor = useMemo(() => {
    return !!(authorId || authorName);
  }, [authorId, authorName]);

  return (
    <div
      onClick={onClick}
      className={cx({
        [styles.root]: true,
        [styles.done]: status === 'd' || state === 'ACCEPTED' || state === 'Completed',
        [styles.deleted]: state === 'Cancelled' || state === 'REJECTED',
      })}
    >
      <div className={styles.order}>{`#${order}`}</div>
      <div
        className={cx({
          [styles.description]: true,
          [styles.hasAuthor]: hasAuthor,
        })}
      >
        <RichTextEditor
          initialValue={content ? JSON.stringify(content) : ''}
          readOnly
          expanded={false}
          maxLines={1}
          testId={`${testId}-rich-text-editor`}
        />
      </div>
      {hasAuthor && (
        <div className={styles.author}>
          <SuiteAvatar
            userId={authorId ?? (authorName ? 'IMPORTED_USER' : undefined)}
            name={authorName}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

export default MinifiedCard;
