import { Toggle, Tooltip } from 'dodoc-design-system';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  EditElementStatusProps,
  selectOrderedElementStatusList,
  useEditElementStatusMutation,
} from '_common/services/api/elementStatusApi';

type MoveIconsCellProps = {
  status: ElementStatus;
  index: number;
};

const MoveIconsCell = ({ status, index }: MoveIconsCellProps) => {
  const intl = useIntl();
  const list = useSelector(selectOrderedElementStatusList);
  const [editElementStatus] = useEditElementStatusMutation();

  const canMoveDown = (index: number) => index !== 0 && index !== list.length - 1;
  const canMoveUp = (index: number) => index > 1;

  const move = (id: string, position: number) => {
    const status = list.find((item) => item.id === id);
    if (status) {
      const payload: EditElementStatusProps['status'] = {
        name: status.name,
        color: status.color,
        visible: status.visible,
        allow_move: status.allow_move,
        allow_delete: status.allow_delete,
        allow_edit: status.allow_edit,
        confirm_input: status.confirm_input,
        position,
      };
      editElementStatus({ id, status: payload });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tooltip
        disabled={!canMoveUp(index)}
        placement="bottom"
        content={intl.formatMessage({ id: 'MOVE_STATUS_UP' })}
        testId="move-status-up-tooltip"
      >
        <Toggle
          variant="link"
          size="medium"
          disabled={!canMoveUp(index)}
          onClick={() => move(status.id, Number(status.position) - 1)}
          testId="move-status-up-toggle"
          id={`move-up-${status.id}`}
          icon="ElementListUpGrey"
          margin="0 1rem 0 0"
        />
      </Tooltip>
      <Tooltip
        disabled={!canMoveDown(index)}
        placement="bottom"
        content={intl.formatMessage({ id: 'MOVE_STATUS_DOWN' })}
        testId="move-status-down-tooltip"
      >
        <Toggle
          variant="link"
          size="medium"
          disabled={!canMoveDown(index)}
          onClick={() => move(status.id, Number(status.position) + 1)}
          testId="move-status-down-toggle"
          id={`move-up-${status.id}`}
          icon="ElementListDownGrey"
        />
      </Tooltip>
    </div>
  );
};

export default MoveIconsCell;
