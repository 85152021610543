import { useMemo } from 'react';
import useMetadataList from './useMetadataList';
import useObject from './useObject';

const useDocumentMetadata = (docId: string) => {
  const { data } = useObject({ object_id: docId, object_type: 'document' });
  const { automatic, manual, ...rest } = useMetadataList();
  return useMemo(() => {
    return {
      manual: manual.map((m) => ({ ...m, value: data?.metadata?.[m.id] })),
      automatic: automatic.map((m) => ({ ...m, value: data?.metadata?.[m.id] })),
      ...rest,
    };
  }, [data, automatic, manual]);
};

export default useDocumentMetadata;
