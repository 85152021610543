import { Popover } from 'dodoc-design-system';
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './ShortcutsPopover.module.scss';
import { useSelector } from '_common/hooks';

type ShortcutsPopoverProps = {
  children: ReactNode;
  testId: string;
  offsets: Rect | null;
};

const ShortcutsPopover = ({ children, testId, offsets }: ShortcutsPopoverProps) => {
  const zoom = useSelector((state) => state.editor.status.zoom);
  const widgetRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ left: number; top: number } | undefined>({
    left: -2,
    top: (offsets?.height ?? 0) / zoom + 8,
  });

  const handleWidgetPosition = useCallback(() => {
    const editorRoot = document.getElementById('EditorRoot');
    const widgetElement = widgetRef?.current;
    if (editorRoot && offsets && widgetElement) {
      const elementMargin = 20 / zoom;
      const padding = 8;
      const widgetTop = offsets.height / zoom;
      const widgetHeight = widgetElement.getBoundingClientRect().height / zoom;
      const widgetSpace = widgetHeight + elementMargin + padding;
      const rootHeight = editorRoot.clientHeight / zoom;
      const rootScroll = editorRoot.scrollTop / zoom;
      const defaultLeft = -2;
      if (rootHeight + rootScroll - widgetSpace <= offsets.top) {
        setPosition({ left: defaultLeft, top: 0 });
      } else {
        const diff = rootHeight + rootScroll - widgetTop - offsets.top - widgetSpace;
        const defaultTop = widgetTop + Math.min(diff, padding);

        const top = defaultTop * zoom;
        const left = defaultLeft * zoom;

        setPosition({ left: left + (defaultLeft - left), top: top + (defaultTop - top) });
      }
    }
  }, [offsets, zoom]);

  const style = useMemo<CSSProperties>(() => {
    const newStyle: CSSProperties = {};
    if (position) {
      newStyle.left = position.left;
      newStyle.top = position.top;
    }

    return newStyle;
  }, [position]);

  useEffect(() => {
    handleWidgetPosition();
  }, [offsets]);

  useEffect(() => {
    const editorRoot = document.getElementById('EditorRoot');

    if (editorRoot) {
      editorRoot.addEventListener('scroll', handleWidgetPosition);
      window.addEventListener('resize', handleWidgetPosition);
    }

    return () => {
      if (editorRoot) {
        editorRoot.removeEventListener('scroll', handleWidgetPosition);
        window.removeEventListener('resize', handleWidgetPosition);
      }
    };
  }, []);

  if (!offsets) {
    return null;
  }

  return (
    <div
      ref={widgetRef}
      className={styles.container}
      style={style}
      data-testid={`${testId}-inline-properties-container`}
    >
      <Popover isOpen={true} close={() => {}} testId={`${testId}-inline-properties-popper`}>
        {children}
      </Popover>
    </div>
  );
};

export default ShortcutsPopover;
