import { setFontValidationData } from 'App/redux/FontsSlice';
import { setCurrentSlide, setLoadedVersion, setZoom } from 'Presentation/PresentationSlice';
import { store } from '_common/redux';

export class ReduxInterface {
  static getPlatform() {
    return store.getState().app.platform;
  }

  static setCurrentSlide(payload: Parameters<typeof setCurrentSlide>[0]) {
    store.dispatch(setCurrentSlide(payload));
  }

  static setLoadedVersion(payload: Parameters<typeof setLoadedVersion>[0]) {
    store.dispatch(setLoadedVersion(payload));
  }

  static zoomIn() {
    return store.dispatch(setZoom(store.getState().presentation.general.zoom + 0.1));
  }

  static zoomOut() {
    return store.dispatch(setZoom(store.getState().presentation.general.zoom - 0.1));
  }

  static setFontValidationData(payload: Parameters<typeof setFontValidationData>[0]) {
    return store.dispatch(setFontValidationData(payload));
  }
}
