/**
 * Returns a tint of a color.
 *
 * @param {array} color - An array of RGB values [0 - 255]
 * @param {integer} factor - The tint factor [1 - 99]
 *
 * @return {array} - Array of RGB values
 */
export const tint = (color: number[], factor: number) =>
  color.map((c) => Math.round(c + ((255 - c) * factor) / 100));

/**
 * Converts an hex string (e.g. #34FF21) to an array of RGB values [0 - 255].
 *
 * @param {string} hex - Hexadecimal color code (in the format: #34FF21)
 *
 * @return {array} - Array of RGB values. If the passed hex string is not
 * valid returns null
 */
export const hexToRgb = (hex: `#${string}` | string) => {
  if (hex.replace) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const newHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);

    if (result) {
      return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
    }
  }
  return null;
};

/**
 * Converts a single Red, Green or Blue value [0 - 255] to a hexadecimal base.
 *
 * @param {integer} val - Red, Green or Blue value [0 - 255]
 *
 * @return {string}
 */
const toHex = (val: string) => {
  const value = parseInt(val, 10);
  const hex = value.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Converts an array of RGB values [0 - 255] to an hex string.
 *
 * @param {array} color - An array of RGB values
 *
 * @return {string} - Hexadecimal color code.
 */
type Rgb = [string, string, string, string] | `rgba(${number},${number},${number},${number})`;
export const rgbToHex = (color: Rgb) => {
  if (Array.isArray(color)) {
    return `#${toHex(color[0])}${toHex(color[1])}${toHex(color[2])}`;
  }
  const rgb = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  if (rgb) {
    return `#${toHex(rgb[1])}${toHex(rgb[2])}${toHex(rgb[3])}`;
  }
  return null;
};

export const rgbToObject = (color: Rgb) => {
  if (Array.isArray(color)) {
    if (color[0] === '0' && color[1] === '0' && color[2] === '0' && color[3] === '0') {
      return {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      };
    }
    return { r: color[0], g: color[1], b: color[2], a: color[3] || 1 };
  }
  const rgb = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  if (rgb) {
    if (rgb[1] === '0' && rgb[2] === '0' && rgb[3] === '0' && rgb[4] === '0') {
      return {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      };
    }
    return {
      r: rgb[1],
      g: rgb[2],
      b: rgb[3],
      a: rgb[4] === undefined ? 1 : rgb[4],
    };
  }
};

const AVATAR_COLORS = [
  'blue',
  'darkBlue',
  'purple',
  'pink',
  'red',
  'darkBrown',
  'brown',
  'green',
  'darkGreen',
] as const;

export const getAvatarColor = (index: number) => {
  index %= 9;
  return AVATAR_COLORS[index];
};

const colors = {
  'button-primary-default-background-color': '#349ff4',
  'button-primary-default-border-color': '#1c8de7',
  'button-primary-hover-background-color': '#1d94f3',
  'button-primary-hover-border-color': '#1a85db',
  'button-primary-pressed-background-color': '#1a85db',
  'button-primary-pressed-border-color': '#1468aa',
  'button-primary-focus-background-color': '#349ff4',
  'button-primary-focus-border-color': '#1776c2',
  'button-primary-focus-box-shadow': '0 0 0 2px rgba(43, 157, 247, 0.45)',
  'button-default-default-background-color': '#f5f5f6',
  'button-default-default-border-color': '#d5d5db',
  'button-default-hover-background-color': '#eaeaed',
  'button-default-hover-border-color': '#d5d5db',
  'button-default-pressed-background-color': '#d5d5db',
  'button-default-pressed-border-color': '#c0c0c9',
  'button-default-focus-background-color': '#f5f5f6',
  'button-default-focus-border-color': '#ababb7',
  'button-default-focus-box-shadow': '0 0 0 2px rgba(0, 0, 0, 0.15)',
  'button-default-toggled-background-color': '#d5d5db',
  'button-default-toggled-border-color': '#828294',
  'button-danger-default-background-color': '#f15546',
  'button-danger-default-border-color': '#e33f2f',
  'button-danger-hover-background-color': '#ef4231',
  'button-danger-hover-border-color': '#d73b2c',
  'button-danger-pressed-background-color': '#d73b2c',
  'button-danger-pressed-border-color': '#a72e22',
  'button-danger-focus-background-color': '#f15546',
  'button-danger-focus-border-color': '#bf3527',
  'button-danger-focus-box-shadow': '0 0 0 2px rgba(240, 75, 59, 0.4)',
  'button-disabled-background-color': '#f5f5f6',
  'button-disabled-border-color': '#eaeaed',
  'button-font-size-small': '1.5rem',
  'button-font-size-medium': '1.75rem',
  'button-font-size-large': '2rem',
  'button-font-color-default': '#6d6d82',
  'button-font-color-primary': '#ffffff',
  'button-font-color-danger': '#ffffff',
  'button-font-color-disabled': '#d5d5db',
  'button-font-weight-default': '600',
  'button-font-weight-action-bar': '500',
  'button-font-line-height-small': '2rem',
  'button-font-line-height-medium': '3rem',
  'button-font-line-height-large': '3rem',
  'button-spacing-small-icon': '0.5rem',
  'button-spacing-small-text': '0.5rem 3rem',
  'button-spacing-medium-icon': '0.5rem',
  'button-spacing-medium-text': '0.5rem 3rem',
  'button-spacing-large-icon': '1rem',
  'button-spacing-large-text': '1rem 3rem',
  'color-blue-base': '#1d94f3',
  'color-blue-tint-5': '#2899f4',
  'color-blue-tint-10': '#349ff4',
  'color-blue-tint-20': '#4aa9f5',
  'color-blue-tint-30': '#61b4f7',
  'color-blue-tint-40': '#77bff8',
  'color-blue-tint-50': '#8ecaf9',
  'color-blue-tint-60': '#a5d4fa',
  'color-blue-tint-70': '#bbdffb',
  'color-blue-tint-80': '#d2eafd',
  'color-blue-tint-90': '#e8f4fe',
  'color-blue-tint-95': '#f4fafe',
  'color-blue-shade-5': '#1c8de7',
  'color-blue-shade-10': '#1a85db',
  'color-blue-shade-20': '#1776c2',
  'color-blue-shade-30': '#1468aa',
  'color-blue-shade-40': '#115992',
  'color-blue-shade-50': '#0f4a7a',
  'color-dark-blue-base': '#0255bb',
  'color-dark-blue-tint-5': '#0d5ebe',
  'color-dark-blue-tint-10': '#1b66c2',
  'color-dark-blue-tint-20': '#3577c9',
  'color-dark-blue-tint-30': '#4e88cf',
  'color-dark-blue-tint-40': '#6799d6',
  'color-dark-blue-tint-50': '#81aadd',
  'color-dark-blue-tint-60': '#9abbe4',
  'color-dark-blue-tint-70': '#b3cceb',
  'color-dark-blue-tint-80': '#ccddf1',
  'color-dark-blue-tint-90': '#e6eef8',
  'color-dark-blue-tint-95': '#f2f7fc',
  'color-dark-blue-shade-5': '#0251b2',
  'color-dark-blue-shade-10': '#024da8',
  'color-dark-blue-shade-20': '#024496',
  'color-dark-blue-shade-30': '#013b83',
  'color-dark-blue-shade-40': '#013370',
  'color-dark-blue-shade-50': '#012b5e',
  'color-text-base': '#2e2e4c',
  'color-text-tint-5': '#383855',
  'color-text-tint-10': '#43435e',
  'color-text-tint-20': '#585870',
  'color-text-tint-30': '#6d6d82',
  'color-text-tint-40': '#828294',
  'color-text-tint-50': '#9797a6',
  'color-text-tint-60': '#ababb7',
  'color-text-tint-70': '#c0c0c9',
  'color-text-tint-80': '#d5d5db',
  'color-text-tint-90': '#eaeaed',
  'color-text-tint-95': '#f5f5f6',
  'color-text-tint-97': '#f9f9fa',
  'color-text-shade-5': '#2c2c48',
  'color-text-shade-10': '#292944',
  'color-text-shade-20': '#25253d',
  'color-text-shade-30': '#202035',
  'color-text-shade-40': '#1c1c2e',
  'color-text-shade-50': '#171726',
  'color-red-base': '#ef4231',
  'color-red-tint-5': '#f04b3b',
  'color-red-tint-10': '#f15546',
  'color-red-tint-20': '#f2685a',
  'color-red-tint-30': '#f47b6f',
  'color-red-tint-40': '#f58e83',
  'color-red-tint-50': '#f7a198',
  'color-red-tint-60': '#f9b3ad',
  'color-red-tint-70': '#fac6c1',
  'color-red-tint-80': '#fcd9d6',
  'color-red-tint-90': '#fdecea',
  'color-red-tint-95': '#fef6f5',
  'color-red-shade-5': '#e33f2f',
  'color-red-shade-10': '#d73b2c',
  'color-red-shade-20': '#bf3527',
  'color-red-shade-30': '#a72e22',
  'color-red-shade-40': '#8f281d',
  'color-red-shade-50': '#782119',
  'color-green-base': '#35b44a',
  'color-green-tint-5': '#3fb853',
  'color-green-tint-10': '#49bc5c',
  'color-green-tint-20': '#5dc36e',
  'color-green-tint-30': '#72cb80',
  'color-green-tint-40': '#86d292',
  'color-green-tint-50': '#9adaa5',
  'color-green-tint-60': '#aee1b7',
  'color-green-tint-70': '#c2e9c9',
  'color-green-tint-80': '#d7f0db',
  'color-green-tint-90': '#ebf8ed',
  'color-green-tint-95': '#f5fbf6',
  'color-green-shade-5': '#32ab46',
  'color-green-shade-10': '#30a243',
  'color-green-shade-20': '#2a903b',
  'color-green-shade-30': '#257e34',
  'color-green-shade-40': '#206c2c',
  'color-green-shade-50': '#1b5a25',
  'color-orange-base': '#fc9e4f',
  'color-orange-tint-5': '#fca358',
  'color-orange-tint-10': '#fca861',
  'color-orange-tint-20': '#fdb172',
  'color-orange-tint-30': '#fdbb84',
  'color-orange-tint-40': '#fdc595',
  'color-orange-tint-50': '#fecfa7',
  'color-orange-tint-60': '#fed8b9',
  'color-orange-tint-70': '#fee2ca',
  'color-orange-tint-80': '#feecdc',
  'color-orange-tint-90': '#fff5ed',
  'color-orange-tint-95': '#fffaf6',
  'color-orange-shade-5': '#ef964b',
  'color-orange-shade-10': '#e38e47',
  'color-orange-shade-20': '#ca7e3f',
  'color-orange-shade-30': '#b06f37',
  'color-orange-shade-40': '#975f2f',
  'color-orange-shade-50': '#7e4f28',
  'color-yellow-base': '#fed608',
  'color-yellow-tint-5': '#fed814',
  'color-yellow-tint-10': '#feda21',
  'color-yellow-tint-20': '#fede39',
  'color-yellow-tint-30': '#fee252',
  'color-yellow-tint-40': '#fee66b',
  'color-yellow-tint-50': '#ffeb84',
  'color-yellow-tint-60': '#ffef9c',
  'color-yellow-tint-70': '#fff3b5',
  'color-yellow-tint-80': '#fff7ce',
  'color-yellow-tint-90': '#fffbe6',
  'color-yellow-tint-95': '#fffdf3',
  'color-yellow-shade-5': '#f1cb08',
  'color-yellow-shade-10': '#e5c107',
  'color-yellow-shade-20': '#cbab06',
  'color-yellow-shade-30': '#b29606',
  'color-yellow-shade-40': '#988005',
  'color-yellow-shade-50': '#7f6b04',
  'color-white-base': '#ffffff',
  'color-black-base': '#000000',
  'font-lineheight-small': '2rem',
  'font-lineheight-medium': '3rem',
  'font-lineheight-large': '4rem',
  'font-lineheight-h-1': '4rem',
  'font-lineheight-h-2': '3rem',
  'font-lineheight-h-3': '3rem',
  'font-lineheight-h-4': '3rem',
  'font-lineheight-h-5': '2rem',
  'font-lineheight-body': '2rem',
  'font-size-xsmall': '1.5rem',
  'font-size-small': '1.75rem',
  'font-size-medium': '2rem',
  'font-size-large': '2.25rem',
  'font-size-xlarge': '3rem',
  'font-size-h-1': '3rem',
  'font-size-h-2': '2.25rem',
  'font-size-h-3': '2rem',
  'font-size-h-4': '1.75rem',
  'font-size-h-5': '1.5rem',
  'font-size-body': '1.5rem',
  'font-weight-regular': '400',
  'font-weight-medium': '500',
  'font-weight-semibold': '600',
  'font-weight-bold': '800',
  'font-weight-h-1': '800',
  'font-weight-h-2': '600',
  'font-weight-h-3': '500',
  'font-weight-h-4': '500',
  'font-weight-h-5': '500',
  'font-weight-body': '400',
  'input-default-background-color': '#ffffff',
  'input-default-border-color': '#d5d5db',
  'input-disabled-background-color': 'rgba (245, 245, 246, 0.35)',
  'input-disabled-border-color': '#d5d5db',
  'input-hover-background-color': '#ffffff',
  'input-hover-border-color': '#ababb7',
  'input-focused-background-color': '#ffffff',
  'input-focused-border-color': '#1d94f3',
  'input-focused-box-shadow': '0px 0px 0px 2px rgba(29, 148, 243, 0.2)',
  'input-error-background-color': '#ffffff',
  'input-error-border-color': '#f2685a',
  'input-error-hover-background-color': '#ffffff',
  'input-error-hover-border-color': '#d73b2c',
  'input-error-focused-background-color': '#ffffff',
  'input-error-focused-border-color': '#f2685a',
  'input-error-focused-box-shadow': '0px 0px 0px 2px rgba(245, 82, 63, 0.2)',
  'input-font-size-medium': '1.5rem',
  'input-font-size-large': '1.75rem',
  'input-font-color-placeholder': '#c0c0c9',
  'input-font-color-default': '#43435e',
  'input-font-color-disabled': '#c0c0c9',
  'input-font-weight-medium': '400',
  'input-font-weight-large': '400',
  'input-font-line-height-medium': '2rem',
  'input-font-line-height-large': '3rem',
  'input-spacing-medium': '1rem 2rem',
  'input-spacing-large': '1rem 2rem',
  'spacing-none': '0rem',
  'spacing-xsmall': '0.5rem',
  'spacing-small': '1rem',
  'spacing-medium': '2rem',
  'spacing-large': '3rem',
  'spacing-xlarge': '4rem',
  'spacing-xxlarge': '5rem',
  'spacing-jumbo': '12rem',
};

export default colors;
