import { MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button } from 'dodoc-design-system';
import { omit } from 'lodash';

import { useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { CitationData } from 'Editor/services/DataManager/models';
import { notify } from '_common/components/ToastSystem';
import { ToastElement } from '_common/components/ToastSystem/Toast';

import {
  closeAndResetModal,
  closeModal,
  openAndUpdateModal,
  updateModal,
} from '_common/modals/ModalsSlice';
import { selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { importCitations } from 'Editor/redux/CitationsSlice';
import { clearSelection, setSelected } from '_common/components/Table/TableSlice';

import { TabMenu, MenuItem } from '_common/components';

import SearchCitations from './SearchCitations/SearchCitations';
import DocumentLibrary from './DocumentLibrary/DocumentLibrary';
import ImportCitations from './ImportCitations/ImportCitations';

const CitationsModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.app.data);
  const isOpen = useSelector((state) => state.modals.open.CitationsModal);
  const { navigation, search } = useSelector((state) => state.modals.CitationsModal);
  const librarySelection = useSelector((state) => state.modals.CitationsModal.library.selected);
  const importedCitations = useSelector((state) => state.modals.CitationsModal.import);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const objectSelected = Object.keys(useSelector((state) => state.table.selected));
  const selectedTab = useSelector((state) => state.tabMenu.citationsManager);
  const insertedCitations = useSelector((state) => state.editor.citations.order);

  const [citationsToExport, setCitationsToExport] = useState<ObjectId[]>([]);

  const [navigationDoDOC, setNavigationDoDOC] = useState(false);
  const [libraryExportMode, setLibraryExportMode] = useState(false);
  const [librarySaveEndNoteMode, setLibrarySaveEndNoteMode] = useState(false);

  useEffect(() => {
    dispatch(clearSelection());
  }, [selectedTab]);

  useEffect(() => {
    dispatch(setSelected(librarySelection ? { [librarySelection]: true } : {}));
  }, [librarySelection]);

  const notifyError = (error: string, quantityFailed: number = 1) => {
    let errorNotification: ToastElement = {
      type: 'error',
      title: 'notifications.genericError.title',
      message: 'notifications.genericError.message',
    };
    switch (error) {
      case 'ALREADY_EXISTS': {
        if (quantityFailed > 1) {
          errorNotification = {
            ...errorNotification,
            title: 'global.error',
            message: 'editor.errors.citations.referenceAlreadyInLibrary',
          };
        } else {
          errorNotification = {
            ...errorNotification,
            title: 'editor.errors.citations.referenceAlreadyInLibrary',
            message: 'REFERENCE_ALREADY_IN_LIBRARY_MESSAGE',
          };
        }

        break;
      }
      default:
        break;
    }

    notify(errorNotification);
  };

  const handleCreateNewCitationClick = (citations?: string, source?: 'pubmed' | 'import') => {
    return EditorManager.getInstance()
      .addCitationsToLibrary(citations ? JSON.parse(citations) : [{ entry: 'article' }], source)
      .then((response) => {
        if (response) {
          if (response.inserted.length > 0) {
            if (response.inserted.length === 1) {
              notify({
                type: 'success',
                title: 'REFERENCE_ADDED_TITLE',
                message: 'REFERENCE_ADDED_DESCRIPTION',
              });
            } else if (citations) {
              notify({
                type: 'success',
                title: 'REFERENCES_IMPORTED_TO_LIBRARY',
                message: 'REFERENCES_IMPORTED_TO_LIBRARY_MESSAGE',
                messageValues: { refNumber: `${response.inserted.length}` },
              });
            }
          } else if (response.rejected.length > 0) {
            const typedRejects = response.rejected as CitationData[];
            typedRejects.forEach(({ error }) => {
              notifyError(error ?? '', typedRejects.length);
            });
          }
        }
      });
  };

  const handleRemoveSelectedCitation = () => {
    return EditorManager.getInstance()
      .removeCitationFromLibrary(objectSelected[0])
      .then(() => {
        notify({
          type: 'success',
          title: 'REFERENCE_REMOVED',
          message: 'REFERENCE_REMOVED_MESSAGE',
        });
      })
      .catch((error) => {
        notifyError(error.type);
      });
  };

  const close = () => {
    dispatch(clearSelection());
    dispatch(closeAndResetModal('CitationsModal'));
  };

  const handleAddCitationToLibrary = (e: MouseEvent<HTMLButtonElement>, citationId?: ObjectId) => {
    const id = citationId || objectSelected[0];
    if (search.dict[id]?.scopus_id) {
      setTimeout(() => handleAddCitationToLibrary(e, id), 300);
    } else {
      const citation = { ...search.dict[id] };
      delete citation.id;
      handleCreateNewCitationClick(JSON.stringify([citation]), 'pubmed');
    }
  };

  const handleCitationsImport = () => {
    dispatch(
      updateModal({
        modal: 'CitationsModal',
        data: {
          import: {
            dropzone: true,
          },
        },
      }),
    );
  };

  const handleAddCitationToLibraryImport = () => {
    handleCreateNewCitationClick(
      JSON.stringify(objectSelected.map((id) => omit(importedCitations.dict[id], ['id']))),
      'import',
    );
  };

  const renderLibraryFooter = () => {
    if (libraryExportMode) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
          <Button
            variant="link"
            margin="2rem 0 2rem 0"
            size="medium"
            onClick={() => {
              setLibraryExportMode(false);
              dispatch(clearSelection());
            }}
            testId="go-back-button"
          >
            <FormattedMessage id="GO_BACK" />
          </Button>
          <div style={{ margin: '0 0 0 auto' }}>
            <Button
              size="medium"
              onClick={() => {
                setLibrarySaveEndNoteMode(true);
                setLibraryExportMode(false);
                setCitationsToExport(objectSelected);
              }}
              disabled={objectSelected.length === 0 || insertedCitations.includes(librarySelection)}
              testId="save-as-endnote-in-doDOC-button"
            >
              <FormattedMessage id="SAVE_AS_ENDNOTE_IN_DODOC" />
            </Button>
            <Button
              size="medium"
              disabled={objectSelected.length === 0 || insertedCitations.includes(librarySelection)}
              onClick={() => {
                dispatch(closeModal('CitationsModal'));
                dispatch(
                  openAndUpdateModal({
                    modal: 'RenameEndNoteFileModal',
                    data: {
                      title: 'DOWNLOAD_ENDNOTE_FILE',
                      actionButtonLabel: 'DOWNLOAD_ENDNOTE_FILE',
                      type: 'download',
                      citations: objectSelected,
                    },
                  }),
                );
              }}
              testId="download-endnote-file-button"
            >
              <FormattedMessage id="DOWNLOAD_ENDNOTE_FILE" />
            </Button>
          </div>
        </div>
      );
    }
    if (librarySaveEndNoteMode) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => {
              setLibrarySaveEndNoteMode(false);
              dispatch(clearSelection());
            }}
            size="medium"
            testId="go-back-button"
          >
            <FormattedMessage id="GO_BACK" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            disabled={!citationsToExport}
            onClick={() => {
              if (data[objectSelected[0] || navigation?.current]?.type === 'file') {
                dispatch(closeModal('CitationsModal'));
                dispatch(
                  openAndUpdateModal({
                    modal: 'ReplaceReferenceLibraryModal',
                    data: {
                      id: citationsToExport,
                      objectId: objectSelected,
                    },
                  }),
                );
              } else {
                dispatch(closeModal('CitationsModal'));
                dispatch(
                  openAndUpdateModal({
                    modal: 'RenameEndNoteFileModal',
                    data: {
                      title: 'SAVE_AS_ENDNOTE_IN_DODOC',
                      actionButtonLabel: 'SAVE_AS_ENDNOTE_IN_DODOC',
                      type: 'save',
                      citations: citationsToExport,
                      target: objectSelected[0]?.length ? objectSelected[0] : navigation?.current,
                    },
                  }),
                );
              }
            }}
            testId="save-button"
          >
            <FormattedMessage
              id={
                navigation?.current && data[objectSelected[0]]?.type === 'folder'
                  ? 'SAVE_HERE'
                  : 'global.save'
              }
            />
          </Button>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
        <Button
          variant="link"
          margin="2rem 0 2rem 0"
          size="medium"
          onClick={handleRemoveSelectedCitation}
          disabled={Object.keys(objectSelected).length !== 1 || isReadOnlyMode}
          testId="remove-reference-button"
        >
          <FormattedMessage id="editor.modals.citations.removeReference" />
        </Button>
        <div style={{ margin: '0 0 0 auto' }}>
          <Button
            size="medium"
            onClick={() => setLibraryExportMode(true)}
            testId="export-reference-library-button"
          >
            <FormattedMessage id="EXPORT_REFERENCE_LIBRARY" />
          </Button>
          <Button
            variant="primary"
            size="medium"
            onClick={() => handleCreateNewCitationClick()}
            disabled={isReadOnlyMode}
            testId="create-new-reference-button"
          >
            <FormattedMessage id="editor.modals.citations.createNewCitations" />
          </Button>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    switch (selectedTab) {
      case 'documentLibrary':
        return renderLibraryFooter();
      case 'searchReferences':
        return (
          <Button
            size="medium"
            margin="2rem 0 2rem auto"
            onClick={handleAddCitationToLibrary}
            disabled={!objectSelected || isReadOnlyMode}
            testId="add-to-library-button"
          >
            <FormattedMessage id="editor.modals.citations.addToLibrary" />
          </Button>
        );
      case 'importReferences':
        if (!importedCitations.dropzone) {
          if (navigationDoDOC) {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    setNavigationDoDOC(false);
                    dispatch(
                      updateModal({
                        modal: 'CitationsModal',
                        data: {
                          import: {
                            ...importedCitations,
                            dropzone: true,
                          },
                          navigation: {
                            current: null,
                            identity: 'storage',
                          },
                        },
                      }),
                    );
                  }}
                  size="medium"
                  testId="go-back-button"
                >
                  <FormattedMessage id="GO_BACK" />
                </Button>
                <Button
                  size="medium"
                  variant="primary"
                  disabled={objectSelected.length !== 1}
                  onClick={() => {
                    dispatch(importCitations({ file: objectSelected[0] }));

                    setNavigationDoDOC(false);
                  }}
                  testId="import-references-button"
                >
                  <FormattedMessage id="editor.modals.citations.tabs.importReferences" />
                </Button>
              </div>
            );
          }
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button
                onClick={handleCitationsImport}
                size="medium"
                disabled={isReadOnlyMode}
                testId="import-from-file-button"
              >
                <FormattedMessage id="editor.modals.citations.importFromFile" />
              </Button>
              <Button
                variant="primary"
                size="medium"
                margin="2rem 0 2rem auto"
                onClick={handleAddCitationToLibraryImport}
                disabled={objectSelected.length === 0 || isReadOnlyMode}
                testId="add-to-library-button"
              >
                <FormattedMessage id="editor.modals.citations.addToLibrary" />
              </Button>
            </div>
          );
        }
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button onClick={close} size="medium" testId="cancel-button">
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button
              size="medium"
              variant="primary"
              disabled={objectSelected.length !== 1}
              onClick={() => {
                dispatch(importCitations({ file: objectSelected[0] }));
              }}
              testId="import-references-button"
            >
              <FormattedMessage id="editor.modals.citations.tabs.importReferences" />
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="145rem" testId="citations">
      <Modal.Header onClose={close}>
        <FormattedMessage id="editor.modals.citations.header" />
      </Modal.Header>
      <Modal.Body overflow={'visible'}>
        <TabMenu menuId="citationsManager">
          <MenuItem
            text={intl.formatMessage({ id: 'editor.modals.citations.tabs.documentReferences' })}
            id="documentLibrary"
          >
            <DocumentLibrary
              libraryExportMode={libraryExportMode}
              librarySaveEndNoteMode={librarySaveEndNoteMode}
            />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({ id: 'editor.modals.citations.tabs.searchReferences' })}
            id="searchReferences"
            noOverflow
          >
            <SearchCitations />
          </MenuItem>
          <MenuItem
            text={intl.formatMessage({ id: 'editor.modals.citations.tabs.importReferences' })}
            id="importReferences"
          >
            <ImportCitations
              navigationDoDOC={navigationDoDOC}
              setNavigationDoDOC={setNavigationDoDOC}
            />
          </MenuItem>
        </TabMenu>
      </Modal.Body>
      <Modal.Footer alignment={selectedTab === 'importReferences' ? 'flex-end' : 'space-between'}>
        {renderFooter()}
      </Modal.Footer>
    </Modal>
  );
};

export default CitationsModal;
