import { useEffect, useState } from 'react';
import { useDispatch } from '_common/hooks';
import SelectedAccountLogin from './SelectedAccountLogin/SelectedAccountLogin';
import AccountsList from './AccountsList/AccountsList';
import { resetLoginErrors, checkActiveAccount } from 'Auth/redux/authSlice';
import { useSelector } from '_common/hooks';
import { useAuthContext } from 'Auth/AuthContext';

export const TEST_IDS = {
  ACCOUNTS_LIST: 'accountsList',
  SELECTED_ACCOUNT: 'selectedAccount',
};

type SavedAccountsLoginProps = {
  removeAccount: (userId: UserId) => void;
  useAnotherAccount: () => void;
  setSignInForm: (form: Auth.SignInState) => void;
};

const SavedAccountsLogin = ({
  removeAccount,
  useAnotherAccount,
  setSignInForm,
}: SavedAccountsLoginProps) => {
  const dispatch = useDispatch();
  const redirectValidSession = useSelector((state) => state.app.redirectValidSession);
  const [removingAccounts, setRemovingAccounts] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<ParsedProfile<Account> | null>(null);
  const { accounts } = useAuthContext();
  const accountIds = Object.keys(accounts);

  useEffect(() => {
    if (!removingAccounts && accountIds.length === 1) {
      if (redirectValidSession && accounts[accountIds[0] as keyof ParsedProfile<Account>].online) {
        dispatch(checkActiveAccount({ account: accounts[Object.keys(accounts)[0]] }));
      }
    }
  }, [removingAccounts, accountIds]);

  const handleChooseAnother = () => {
    if (accountIds.length === 1) {
      setSignInForm('FRESH_LOGIN');
    } else {
      setSelectedAccount(null);
      dispatch(resetLoginErrors());
    }
  };

  const setRemovingAccountsFromSelectedAccountLogin = () => {
    setRemovingAccounts(true);
    setSelectedAccount(null);
    dispatch(resetLoginErrors());
  };

  if (selectedAccount) {
    return (
      <div data-testid={TEST_IDS.SELECTED_ACCOUNT}>
        <SelectedAccountLogin
          account={selectedAccount}
          setRemovingAccounts={setRemovingAccountsFromSelectedAccountLogin}
          chooseAnother={handleChooseAnother}
        />
      </div>
    );
  }
  return (
    <div data-testid={TEST_IDS.ACCOUNTS_LIST}>
      <AccountsList
        removeAccount={removeAccount}
        useAnotherAccount={useAnotherAccount}
        setSelectedAccount={setSelectedAccount}
        removingAccounts={removingAccounts}
        setRemovingAccounts={setRemovingAccounts}
      />
    </div>
  );
};

export default SavedAccountsLogin;
