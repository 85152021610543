export const approved: Omit<doDOC.Object.Status, 'position'> & { position: number | null } = {
  allow_change: true,
  allow_delete: false,
  allow_edit: false,
  allow_move: false,
  color: 'green',
  confirm_input: false,
  deleted: false,
  fade: false,
  id: 'approved',
  name: 'Approved',
  position: 1,
  read_only: false,
  visible: true,
};

export const active: Omit<doDOC.Object.Status, 'position'> & { position: number | null } = {
  allow_change: true,
  allow_delete: true,
  allow_edit: true,
  allow_move: true,
  color: 'grey',
  confirm_input: false,
  deleted: false,
  fade: false,
  id: 'active',
  name: 'Draft',
  position: 0,
  read_only: false,
  visible: false,
};

export const processing: Omit<doDOC.Object.Status, 'position'> & { position: number | null } = {
  allow_change: false,
  allow_delete: true,
  allow_edit: false,
  allow_move: false,
  color: 'grey',
  confirm_input: false,
  deleted: false,
  fade: true,
  id: 'processing',
  name: 'Processing',
  position: null,
  read_only: true,
  visible: false,
};

export const broken: Omit<doDOC.Object.Status, 'position'> & { position: number | null } = {
  allow_change: false,
  allow_delete: true,
  allow_edit: false,
  allow_move: true,
  color: 'red',
  confirm_input: false,
  deleted: false,
  fade: false,
  id: 'broken',
  name: 'Broken',
  position: null,
  read_only: true,
  visible: true,
};

export const migrating: Omit<doDOC.Object.Status, 'position'> & { position: number | null } = {
  allow_change: false,
  allow_delete: false,
  allow_edit: false,
  allow_move: false,
  color: 'grey',
  confirm_input: false,
  deleted: false,
  fade: false,
  id: 'migrating',
  name: 'Migrating',
  position: null,
  read_only: true,
  visible: false,
};

export const checked_out: Omit<doDOC.Object.Status, 'position'> & { position: number | null } = {
  allow_change: false,
  allow_delete: true,
  allow_edit: true,
  allow_move: false,
  color: 'grey',
  confirm_input: false,
  deleted: false,
  fade: false,
  id: 'checked_out',
  name: 'Checked Out',
  position: null,
  read_only: true,
  visible: true,
};
