import { PageViewport } from 'pdfjs-dist';
import { DOMUtils } from '_common/utils';

export class CanvasElement extends HTMLCanvasElement implements PDF.CanvasElement {
  connectedCallback() {}

  disconnectedCallback() {}

  renderAnnotation(annotation: PDF.Annotation, viewport: PageViewport) {
    // if (annotation.hasAppearance) {
    switch (annotation.subtype) {
      case 'Highlight':
        this.renderHighlight(annotation, viewport);
        break;
      case 'Underline':
        this.renderUnderline(annotation, viewport);
        break;
      case 'StrikeOut':
        this.renderStrikeOut(annotation, viewport);
        break;
      case 'Ink':
        this.renderInk(annotation, viewport);
        break;
      case 'Line':
        this.renderLine(annotation, viewport);
        break;
      case 'Polygon':
      case 'PolyLine':
        this.renderPolygon(annotation, viewport);
        break;
      case 'Square':
        this.renderSquare(annotation, viewport);
        break;
      case 'Circle':
        this.renderCircle(annotation, viewport);
        break;
      // case 'FreeText':
      //   this.renderFreeText(annotation, viewport);
      //   break;
      // case 'FileAttachment':
      // case 'Text':
      // case 'Stamp':
      case 'Task':
        this.renderTask(annotation, viewport);
        break;
      default:
        logger.debug('canvas annotation ' + annotation.subtype + ' not rendered!');
        break;
    }
    // }
  }

  private renderTask(annotation: PDF.Annotation.Task, viewport: PageViewport) {
    if (annotation.quadPoints) {
      this.renderHighlight(annotation, viewport);
    }
  }

  private renderHighlight(
    annotation: PDF.Annotation.TextMarkup | PDF.Annotation.Task,
    viewport: PageViewport,
  ) {
    logger.info('renderHighlight', this, annotation, viewport);

    const ctx = this.getContext('2d');
    if (ctx) {
      let color;

      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      const viewHeigh = viewport.viewBox[3];

      let borderWidth;

      if (annotation.border?.width) {
        borderWidth = DOMUtils.convertUnitTo(annotation.border.width, 'px', 'pt', 3);
      } else {
        borderWidth = 1;
      }

      if (annotation.quadPoints && borderWidth) {
        for (let i = 0; i < annotation.quadPoints.length; i++) {
          const quadPoint = annotation.quadPoints[i];

          let x, y, width, height;

          x = quadPoint.topLeft.x;
          y = viewHeigh - quadPoint.topLeft.y + borderWidth / 2;

          height = quadPoint.topLeft.y - quadPoint.bottomLeft.y /* - borderWidth */;
          width = quadPoint.topRight.x - quadPoint.topLeft.x;

          if (x && y && width && height && color) {
            ctx.save();
            ctx.scale(viewport.scale, viewport.scale);
            ctx.globalCompositeOperation = 'multiply';
            ctx.fillStyle = color;
            ctx.fillRect(x, y, width, height);
            ctx.restore();
          }
        }
      }
    }
  }

  private renderLine(annotation: PDF.Annotation.Line, viewport: PageViewport) {
    // logger.info('renderLine', this, annotation, viewport);
    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];
      let color;

      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      if (annotation.lineCoordinates) {
        const coordinates = annotation.lineCoordinates;

        let startX, startY, endX, endY, lineWidth;

        if (annotation.border?.width) {
          lineWidth = DOMUtils.convertUnitTo(annotation.border.width, 'px', 'pt', 3) || 1;
        } else {
          lineWidth = 1;
        }

        startX = coordinates.start.x;
        startY = viewHeigh - coordinates.start.y;
        endX = coordinates.end.x - lineWidth;
        endY = viewHeigh - coordinates.end.y;

        if (startX && startY && endY && endX && color && lineWidth) {
          ctx.save();
          ctx.scale(viewport.scale, viewport.scale);
          ctx.globalCompositeOperation = 'source-over';
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = color;

          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          // ctx.closePath();
          ctx.stroke();
          ctx.restore();

          if (annotation.lineEndings) {
            // start lineEnding
            this.renderLineEnding(
              ctx,
              annotation.lineEndings.start,
              { x: startX, y: startY },
              { x: endX, y: endY },
              lineWidth,
              color,
              viewport.scale,
            );

            // end lineEnding
            this.renderLineEnding(
              ctx,
              annotation.lineEndings.end,
              { x: endX, y: endY },
              { x: startX, y: startY },
              lineWidth,
              color,
              viewport.scale,
            );
          }
        }
      }
    }
  }

  private renderPolygon(
    annotation: PDF.Annotation.Polygon | PDF.Annotation.PolyLine,
    viewport: PageViewport,
  ) {
    logger.info('renderPolygon', this, annotation, viewport);
    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];
      let color;

      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      let lineWidth;
      if (annotation.border?.width) {
        lineWidth = DOMUtils.convertUnitTo(annotation.border.width, 'px', 'pt', 3);
      } else {
        lineWidth = 1;
      }

      if (annotation.vertices && lineWidth && color) {
        ctx.save();

        ctx.scale(viewport.scale, viewport.scale);
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        const length = annotation.vertices.length;

        ctx.beginPath();
        for (let i = 0; i < length; i++) {
          const vertice = annotation.vertices[i];

          const x = vertice.x;
          const y = viewHeigh - vertice.y;

          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }

        if (annotation.subtype === 'Polygon') {
          ctx.closePath();
        }

        ctx.stroke();

        ctx.restore();

        if (annotation.subtype === 'PolyLine' && annotation.lineEndings) {
          // start lineEnding
          this.renderLineEnding(
            ctx,
            annotation.lineEndings.start,
            { x: annotation.vertices[0].x, y: viewHeigh - annotation.vertices[0].y },
            { x: annotation.vertices[1].x, y: viewHeigh - annotation.vertices[1].y },
            lineWidth,
            color,
            viewport.scale,
          );

          // end lineEnding
          this.renderLineEnding(
            ctx,
            annotation.lineEndings.end,
            {
              x: annotation.vertices[length - 1].x,
              y: viewHeigh - annotation.vertices[length - 1].y,
            },
            {
              x: annotation.vertices[length - 2].x,
              y: viewHeigh - annotation.vertices[length - 2].y,
            },
            lineWidth,
            color,
            viewport.scale,
          );
        }
      }
    }
  }

  private renderLineEnding(
    ctx: CanvasRenderingContext2D,
    type: PDF.Annotation.LineEnding,
    pos: PDF.Annotation.Point,
    from: PDF.Annotation.Point,
    lineWidth: number,
    color: string | CanvasGradient | CanvasPattern,
    scale: number,
  ) {
    const difX = pos.x - from.x;
    const difY = pos.y - from.y;
    const angle = Math.atan2(difY, difX);

    switch (type) {
      case 'OpenArrow': {
        const headlen = lineWidth * 10; // length of head in pixels

        ctx.save();
        ctx.scale(scale, scale);
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x - headlen * Math.cos(angle - Math.PI / 6),
          pos.y - headlen * Math.sin(angle - Math.PI / 6),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x - headlen * Math.cos(angle + Math.PI / 6),
          pos.y - headlen * Math.sin(angle + Math.PI / 6),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.restore();
        break;
      }
      case 'ClosedArrow':
        //TODO:
        break;
      case 'Diamond':
        //TODO:
        break;
      case 'Circle':
        //TODO:
        break;
      case 'Square':
        //TODO:
        break;
      case 'Butt': {
        const headlen = lineWidth * 5; // length of head in pixels

        ctx.save();
        ctx.scale(scale, scale);
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x - headlen * Math.cos(angle - Math.PI / 2),
          pos.y - headlen * Math.sin(angle - Math.PI / 2),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x - headlen * Math.cos(angle + Math.PI / 2),
          pos.y - headlen * Math.sin(angle + Math.PI / 2),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.restore();
        break;
      }
      case 'ROpenArrow':
        const headlen = lineWidth * 10; // length of head in pixels

        ctx.save();
        ctx.scale(scale, scale);
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x + headlen * Math.cos(angle - Math.PI / 6),
          pos.y + headlen * Math.sin(angle - Math.PI / 6),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x + headlen * Math.cos(angle + Math.PI / 6),
          pos.y + headlen * Math.sin(angle + Math.PI / 6),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.restore();
        break;
      case 'RClosedArrow':
        //TODO:
        break;
      case 'Slash': {
        const headlen = lineWidth * 5; // length of head in pixels

        ctx.save();
        ctx.scale(scale, scale);
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x - headlen * Math.cos(angle + Math.PI / 3),
          pos.y - headlen * Math.sin(angle + Math.PI / 3),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(
          pos.x + headlen * Math.cos(angle + Math.PI / 3),
          pos.y + headlen * Math.sin(angle + Math.PI / 3),
        );
        // ctx.closePath();
        ctx.stroke();

        ctx.restore();
        break;
      }
      case 'None':
        break;
      default:
        break;
    }
  }

  private renderUnderline(annotation: PDF.Annotation.TextMarkup, viewport: PageViewport) {
    // logger.info('renderUnderline', this, annotation, viewport);

    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];
      let color;

      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      if (annotation.quadPoints) {
        let startX, startY, endX, endY, lineWidth;

        for (let i = 0; i < annotation.quadPoints.length; i++) {
          const quadPoint = annotation.quadPoints[i];

          const height = quadPoint.topLeft.y - quadPoint.bottomLeft.y;
          lineWidth = height / 15;

          startX = quadPoint.topLeft.x;
          endX = quadPoint.topRight.x;

          startY = endY = viewHeigh - quadPoint.bottomLeft.y - lineWidth * 2;

          if (startX && startY && endY && endX && color && lineWidth) {
            ctx.save();
            ctx.scale(viewport.scale, viewport.scale);
            ctx.globalCompositeOperation = 'source-over';
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;

            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);
            // ctx.closePath();
            ctx.stroke();
            ctx.restore();
          }
        }
      }
    }
  }

  private renderStrikeOut(annotation: PDF.Annotation.TextMarkup, viewport: PageViewport) {
    // logger.info('renderStrikeOut', this, annotation, viewport);

    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];
      let color;

      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      if (annotation.quadPoints) {
        let startX, startY, endX, endY, lineWidth;

        for (let i = 0; i < annotation.quadPoints.length; i++) {
          const quadPoint = annotation.quadPoints[i];

          const height = quadPoint.topLeft.y - quadPoint.bottomLeft.y;
          lineWidth = height / 15;

          startX = quadPoint.topLeft.x;
          endX = quadPoint.topRight.x;

          startY = endY =
            viewHeigh - (quadPoint.topLeft.y + quadPoint.bottomLeft.y) / 2 + lineWidth;

          if (startX && startY && endY && endX && color && lineWidth) {
            ctx.save();
            ctx.scale(viewport.scale, viewport.scale);
            ctx.globalCompositeOperation = 'source-over';
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;

            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);
            // ctx.closePath();
            ctx.stroke();
            ctx.restore();
          }
        }
      }
    }
  }

  private renderCircle(annotation: PDF.Annotation.Base, viewport: PageViewport) {
    // logger.info('renderCircle', this, annotation, viewport);

    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];

      let color;
      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      // TODO fill color

      if (annotation.rect) {
        const rect = annotation.rect;

        let x, y, width, height, lineWidth;

        if (annotation.border?.width) {
          lineWidth = DOMUtils.convertUnitTo(annotation.border.width, 'px', 'pt', 3) || 1;
        } else {
          lineWidth = 1;
        }

        x = rect.left + rect.width / 2;
        y = viewHeigh - (rect.bottom + rect.height / 2);

        width = rect.width - lineWidth * 2;
        height = rect.height - lineWidth * 2;

        if (x && y && width && height && lineWidth && color) {
          ctx.save();
          ctx.scale(viewport.scale, viewport.scale);
          ctx.globalCompositeOperation = 'source-over';
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = color;

          ctx.beginPath();
          ctx.ellipse(x, y, width / 2, height / 2, 0, 0, Math.PI * 2);
          // ctx.closePath();
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  }

  private renderSquare(annotation: PDF.Annotation.Base, viewport: PageViewport) {
    // logger.info('renderSquare', this, annotation, viewport);

    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];

      let color;
      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      // TODO fill color

      if (annotation.rect) {
        const rect = annotation.rect;

        let x, y, width, height, lineWidth;

        if (annotation.border?.width) {
          lineWidth = DOMUtils.convertUnitTo(annotation.border.width, 'px', 'pt', 3) || 1;
        } else {
          lineWidth = 1;
        }

        x = rect.left + lineWidth;
        y = viewHeigh - (rect.bottom + rect.height) + lineWidth;

        width = rect.width - lineWidth * 2;
        height = rect.height - lineWidth * 2;

        if (x && y && width && height && lineWidth && color) {
          ctx.save();
          ctx.scale(viewport.scale, viewport.scale);
          ctx.globalCompositeOperation = 'source-over';
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = color;

          ctx.beginPath();
          // This sets border style, replicate in other types
          // if (annotation.borderStyle.dashArray) {
          //   ctx.setLineDash(annotation.borderStyle.dashArray.join(' '));
          // } else {
          //   ctx.setLineDash([]);
          // }
          ctx.rect(x, y, width, height);
          // ctx.closePath();
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  }

  // private renderFreeText(annotation: any, viewport: any) {
  //   // logger.info('renderFreeText', this, annotation, viewport);
  // }

  private renderInk(annotation: PDF.Annotation.Ink, viewport: PageViewport) {
    logger.info('renderInk', this, annotation, viewport);

    const ctx = this.getContext('2d');
    if (ctx) {
      const viewHeigh = viewport.viewBox[3];

      let color;
      if (annotation.color?.stroke) {
        color = annotation.color.stroke;
      }

      let lineWidth;
      if (annotation.border?.width) {
        lineWidth = DOMUtils.convertUnitTo(annotation.border.width, 'px', 'pt', 3);
      } else {
        lineWidth = 1;
      }

      if (annotation.inkLists && lineWidth && color) {
        ctx.save();

        ctx.scale(viewport.scale, viewport.scale);
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        for (let i = 0; i < annotation.inkLists.length; i++) {
          const inkList = annotation.inkLists[i];

          ctx.beginPath();
          for (let j = 0; j < inkList.length; j++) {
            const ink = inkList[j];

            const x = ink.x;
            const y = viewHeigh - ink.y;

            if (j === 0) {
              ctx.moveTo(x, y);
            } else {
              ctx.lineTo(x, y);
            }
          }
          // ctx.closePath();
          ctx.stroke();
        }

        ctx.restore();
      }
    }
  }
}

// register element
if (!window.customElements.get('canvas-element')) {
  window.customElements.define('canvas-element', CanvasElement, {
    extends: 'canvas',
  });
}
