import { useEffect, useMemo, useState } from 'react';

import { usePDFContext, usePDFDataEvents } from 'PDF/PDFContext';
import { useDispatch, useSelector } from '_common/hooks';
import { setSelectedInstance } from 'PDF/redux/PDFFindSlice';

import { Find } from '_common/suite/components';

const Search = () => {
  const dispatch = useDispatch();
  const manager = usePDFContext();

  const currentPageNumber = useSelector((state) => state.pdf.general.currentPageNumber);
  const selectedInstance = useSelector((state) => state.pdf.find.selected);

  const [findResult, setFindResult] = useState<PDF.Find.CycleResultType | null>(null);

  const currentInstanceIndex = useMemo(() => {
    return selectedInstance && findResult ? findResult.list.indexOf(selectedInstance) : 1;
  }, [selectedInstance, findResult]);

  usePDFDataEvents('LOAD_FIND_HITS', (res) => {
    setFindResult(res);
  });

  useEffect(() => {
    if (findResult) {
      const instanceIndex = findResult.list.findIndex(
        (id) => findResult?.data[id]?.numPage >= currentPageNumber,
      );
      if (instanceIndex >= 0) {
        onChangeInstance(instanceIndex);
      }
    }
  }, [findResult, findResult?.list]);

  useEffect(() => {
    if (
      selectedInstance &&
      findResult?.data[selectedInstance]?.numPage !== undefined &&
      findResult?.data[selectedInstance]?.numPage !== currentPageNumber
    ) {
      pdfManager?.navigation?.goTo(findResult?.data[selectedInstance]?.numPage);
    }
  }, [selectedInstance, findResult]);

  const find = ({
    matchCase,
    wholeWord,
    query,
  }: {
    matchCase: boolean;
    wholeWord: boolean;
    query: string;
  }) => {
    manager.find({
      caseSensitive: matchCase,
      entireWord: wholeWord,
      query,
    });
  };

  const resetFind = () => {
    if (manager.isFindRunning()) {
      manager.resetFind();
    }
  };

  const onChangeInstance = (newIndex: number) => {
    const instance = findResult?.list[newIndex];
    if (instance) {
      dispatch(setSelectedInstance(instance));
    }
  };

  return (
    <Find
      find={find}
      resetFind={resetFind}
      instancesBoundary={document.getElementById('pdfContainer')}
      currentInstanceIndex={currentInstanceIndex}
      totalInstances={findResult?.list.length}
      onChangeInstance={onChangeInstance}
    />
  );
};

export default Search;
