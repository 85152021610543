import { faker } from '@faker-js/faker';
import { createMockedObjectId, createMockedTimeObject, createMockedUserId } from './common';

export function createMockedPublicLink(overwrites?: Partial<PublicLink>): PublicLink {
  const creator = overwrites?.creator || createMockedUserId();
  return {
    accesses: 0,
    active: false,
    creator,
    downloads: 0,
    expiration: {
      active: false,
      expiration: null,
    },
    granted: {
      can_download: true,
    },
    hash: '',
    id: createMockedObjectId(),
    last_access: faker.date.recent(5),
    last_download: faker.date.recent(5),
    last_view: faker.date.recent(5),
    log: [],
    name: '',
    object: createMockedObjectId(),
    owner: creator,
    password: {
      active: true,
      set: true,
    },
    permissions: {
      groups: {},
      roles: {
        groups: {},
        users: {},
      },
      users: {},
    },
    time: createMockedTimeObject(),
    type: '',
    user_permissions: [],
    views: 0,
    ...overwrites,
  };
}
