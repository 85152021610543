import { Logger } from '_common/services';
import { DefaultCommand } from '../KeyDownCommands';
import { EditorSelectionUtils, JsonRange } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';

export class CompositionCommand extends DefaultCommand {
  async handleExec() {
    if (this.context.debug) {
      Logger.trace('CompositionCommand exec', this);
    }

    let dataToInsert = this.getEventData();

    if (dataToInsert && dataToInsert.length > 0 && this.context.DataManager) {
      let editorRange = EditorSelectionUtils.getRange();

      if (editorRange) {
        const startContainer = editorRange.startContainer;
        const startOffset = editorRange.startOffset;

        const rangeData = this.context.DataManager.selection.current;
        const jsonRange = JsonRange.buildFromRangeData(rangeData[0]);

        const baseModel = this.context.DataManager.nodes.getNodeModelById(jsonRange.start.b);
        const baseData = baseModel?.selectedData();

        if (!baseModel || !baseData || !baseData.id) {
          throw new Error('CompositionCommand: baseModel or baseData is null');
        }

        if (startContainer instanceof Text) {
          startContainer.deleteData(startOffset - dataToInsert.length, dataToInsert.length);
        }

        const paragraph = NodeUtils.closestOfTypeByPath(baseData, jsonRange.start.p, ['p']);

        if (paragraph) {
          if (!paragraph.data.childNodes?.length) {
            this.buildActionContext(
              new JsonRange({
                b: jsonRange.start.b,
                p: [...paragraph.path, 'childNodes', 0],
              }),
            );
          } else {
            const text = NodeUtils.getChildDataByPath(baseData, jsonRange.start.p);
            const content = NodeUtils.getContentFromData(text);

            let offset = jsonRange.start.p.pop();
            if (offset !== undefined && typeof offset === 'number') {
              if (offset > content.length) {
                offset = content.length;
              }
              jsonRange.start.p.push(offset);

              this.buildActionContext(
                new JsonRange({
                  b: jsonRange.start.b,
                  p: jsonRange.start.p,
                }),
              );
            }
          }
        }
      }
    }
    await super.handleExec();
  }
}
