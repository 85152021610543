import { FormattedMessage } from 'react-intl';

import { Button } from 'dodoc-design-system';
import styles from 'Editor/modals/NewListModal/NewListModal.module.scss';
import { ReactNode } from 'react';

type EditionBoxProps = {
  header: string;
  children: ReactNode;
  applyStylesToAll?: () => void;
};

const EditionBox = ({ header, children, applyStylesToAll }: EditionBoxProps) => {
  return (
    <>
      <div className={styles.header}>
        <FormattedMessage id={header} />
      </div>
      {children}
      {applyStylesToAll && (
        <div className={styles.applyStyles}>
          <Button
            size="small"
            onClick={applyStylesToAll}
            testId="apply-character-formatting-button"
          >
            <FormattedMessage id="APPLY_CHARACTER_FORMATTING" />
          </Button>
        </div>
      )}
    </>
  );
};

export default EditionBox;
