import { CSSProperties, ReactNode } from 'react';
import { usePrimitiveUpdate, useSelector } from '_common/hooks';
import NotificationRow from './NotificationRow/NotificationRow';
import { useGetPublicProfileQuery } from '_common/services/api/publicProfilesApi';
import { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

type DynamicHeightProps = {
  renderSection: () => ReactNode;
  notificationId: NotificationRecord['id'];
  style: CSSProperties;
  closePopover: () => void;
} & CellMeasurerChildProps;

const DynamicHeight = ({
  measure,
  registerChild,
  renderSection,
  notificationId,
  style,
  closePopover,
}: DynamicHeightProps) => {
  const userId = useSelector((state) => state.notifications.dict[notificationId].action.user);
  const { isLoading } = useGetPublicProfileQuery(userId || '', {
    skip: !userId,
  });

  //#region Card measure handling
  usePrimitiveUpdate(() => {
    measure();
  }, isLoading);
  //#endregion

  return (
    <div ref={(el) => registerChild && registerChild(el as Element)} style={{ ...style }}>
      {renderSection()}
      <NotificationRow
        notificationId={notificationId}
        closePopover={closePopover}
        testId={notificationId}
        measure={measure}
      />
    </div>
  );
};

export default DynamicHeight;
