import { InteractionController, IntlErrorBoundary } from '_common/components';

import TaskCard, { TaskCardProps } from './TaskCard';

const TaskCardBoundary = (props: TaskCardProps) => {
  return (
    <IntlErrorBoundary
      fallbackType="card"
      mockProps={{ sidebar: !!props.sidebar, width: props.sidebar ? '100%' : '43rem' }}
    >
      <InteractionController environment="editor">
        <TaskCard {...props} />
      </InteractionController>
    </IntlErrorBoundary>
  );
};

export default TaskCardBoundary;
