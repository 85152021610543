import { ProgressBar, Icon } from 'dodoc-design-system';
import { FormattedMessage } from 'react-intl';

import styles from './LoadingExport.module.scss';

type LoadingExportProps = {
  exported: boolean;
  error: boolean;
  messageExported?: string;
  download: () => void;
  testId: string;
};

const LoadingExport = ({
  exported,
  download,
  error,
  messageExported,
  testId,
}: LoadingExportProps) => {
  let message;
  if (error) {
    message = (
      <>
        <FormattedMessage id="ERROR_WITH_COMPRESSION1" />
        <br />
        <FormattedMessage id="ERROR_WITH_COMPRESSION2" />
      </>
    );
  } else if (exported) {
    message = messageExported || (
      <>
        <FormattedMessage id="DOCUMENT_SUCESSFULLY_EXPORTED1" />
        <br />

        <FormattedMessage id="DOCUMENT_SUCESSFULLY_EXPORTED2" />
        <br />
        <span className={styles.link} onClick={download}>
          <FormattedMessage id="DOCUMENT_SUCESSFULLY_EXPORTED3" />
        </span>
      </>
    );
  } else {
    message = <FormattedMessage id="PREPARING_EXPORT" />;
  }
  return (
    <div className={styles.root}>
      <Icon
        icon="Document"
        size={96}
        badge={error ? { icon: 'Warning', size: 32, position: 'bottom-right' } : undefined}
      />
      <div className={styles.message}>{message}</div>
      {!exported && !error && <ProgressBar testId={`${testId}-progressBar`} />}
    </div>
  );
};

export default LoadingExport;
