import { useIntl } from 'react-intl';
import { InputField } from 'dodoc-design-system';

import PageLayoutSelect, { PageLayoutSelectProps } from './PageLayoutSelect/PageLayoutSelect';

import type { InputFieldProps } from 'dodoc-design-system/build/types/Components/InputField/InputField';

export type PageSizeFieldProps = Omit<PageLayoutSelectProps, 'testId'> &
  Pick<InputFieldProps, 'disabled' | 'margin'> & {
    testId: {
      inputField: Exclude<InputFieldProps['testId'], undefined>;
      select: Exclude<PageLayoutSelectProps['testId'], undefined>;
    };
  };

const PageSizeField = ({ disabled, margin, type, testId, ...selectProps }: PageSizeFieldProps) => {
  const intl = useIntl();

  return (
    <InputField
      disabled={disabled}
      margin={margin}
      testId={testId.inputField}
      label={
        type === 'size'
          ? intl.formatMessage({ id: 'PAGE_SIZE' })
          : intl.formatMessage({ id: 'MARGIN_SIZE' })
      }
      size="large"
    >
      <PageLayoutSelect {...selectProps} disabled={disabled} type={type} testId={testId.select} />
    </InputField>
  );
};

export default PageSizeField;
