import { useIntl } from 'react-intl';

import { useSelector } from '_common/hooks';
import { selectIsIEnvision } from 'App/redux/appSlice';

import styles from './Title.module.scss';

const IEnvisionLabel = () => {
  const intl = useIntl();
  const isIEnvision = useSelector(selectIsIEnvision);

  if (!isIEnvision) {
    return null;
  }

  return (
    <div className={styles.iEnvision}>{intl.formatMessage({ id: 'OPENED_THROUGH_IENVISION' })}</div>
  );
};

export default IEnvisionLabel;
