import { FormattedMessage } from 'react-intl';
import { tint } from 'assets/colors';
import styles from './ColorsMenu.module.scss';

export type ColorsMenuProps = {
  setOpenColorPickerModal: () => void;
  toggle: () => void;
  clearBackgroundColor: () => void;
  documentColors: {
    r?: string | number;
    g?: string | number;
    b?: string | number;
    rgb?: string;
    empty?: number;
  }[];
  selected: string | null | undefined;
  changeBackgroundColor: (value: string) => void;
  attribute?: string;
};

const ColorsMenu = ({
  setOpenColorPickerModal,
  toggle,
  clearBackgroundColor,
  documentColors,
  selected,
  changeBackgroundColor,
  attribute,
}: ColorsMenuProps) => {
  const openCustomModal = () => {
    setOpenColorPickerModal();
    toggle();
  };

  const renderNoColor = () => {
    if (attribute !== 'color' && attribute !== 'borderColor') {
      return (
        <div className={styles.noColor} onClick={clearBackgroundColor}>
          <div className={styles.square} />
          <div className={styles.label}>
            <FormattedMessage id="NO_COLOR" />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderBlacks = () => {
    return (
      <div className={styles.blacks}>
        {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((opacity) => {
          const value = tint([0, 0, 0], opacity);
          return renderColorSquare([value[0], value[1], value[2]]);
        })}
      </div>
    );
  };

  const renderColors = () => {
    return (
      <div style={{ marginBottom: '2rem' }} className={styles.colors}>
        {[
          { r: 192, g: 0, b: 0 },
          { r: 255, g: 0, b: 0 },
          { r: 255, g: 192, b: 0 },
          { r: 255, g: 252, b: 3 },
          { r: 146, g: 208, b: 80 },
          { r: 0, g: 176, b: 80 },
          { r: 2, g: 176, b: 240 },
          { r: 1, g: 112, b: 192 },
          { r: 0, g: 32, b: 96 },
          { r: 112, g: 48, b: 160 },
        ].map((color) => (
          <div key={`row-${color.r},${color.g},${color.b}`} className={styles.color}>
            {[0, 20, 40, 60, 80].map((opacity) => {
              const value = tint([color.r, color.g, color.b], opacity);
              return renderColorSquare([value[0], value[1], value[2]]);
            })}
          </div>
        ))}
      </div>
    );
  };

  const renderDocumentColors = () => {
    const colors = [...documentColors];
    let extra = 1;
    const maxColors = Math.max(Math.ceil(documentColors.length / 10) * 10, 20);
    while (colors.length < maxColors) {
      colors.push({ empty: extra });
      extra += 1;
    }

    return (
      <div className={styles.documentColors}>
        <div style={{ marginLeft: '1rem', marginBottom: '1rem' }} className={styles.label}>
          <FormattedMessage id="DOCUMENT_COLORS" />
        </div>
        <div className={styles.colors}>
          {colors.map((color) => {
            const key = color.empty
              ? `empty-${color.empty}`
              : `row_${color.r},${color.g},${color.b}`;
            return (
              <div key={key} style={{ marginBottom: '1rem' }} className={styles.color}>
                {color.empty
                  ? renderColorSquare([color.r, color.g, color.b], true)
                  : renderColorSquare([color.r, color.g, color.b])}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderColorSquare = (
    [r, g, b]: [
      r: string | number | undefined,
      g: string | number | undefined,
      b: string | number | undefined,
    ],
    isSquareEmpty = false,
  ) => {
    const isSelected = selected === `rgb(${r},${g},${b})`;
    return (
      <div
        key={`${r},${g},${b}`}
        className={`${styles.colorWrapper} ${isSelected && styles.selected}`}
        onClick={
          isSquareEmpty ? openCustomModal : () => changeBackgroundColor(`rgb(${r},${g},${b})`)
        }
      >
        <div style={{ background: `rgb(${r}, ${g}, ${b})` }} className={styles.square} />
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {renderNoColor()}
      {renderBlacks()}
      {renderColors()}
      {renderDocumentColors()}
      <div onClick={openCustomModal} className={styles.customColor}>
        <div className={styles.label}>
          <FormattedMessage id="CUSTOM_COLOR" />
        </div>
      </div>
    </div>
  );
};

export default ColorsMenu;
