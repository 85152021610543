import { NodeUtils } from '../DataManager';

export class ActionContext {
  event?: Event;
  actionType?: Editor.Edition.ActionType;
  range: Editor.Selection.JsonRange;
  baseModel: Editor.Data.Node.Model;
  baseData: Editor.Data.Node.Data;

  avoidNextNonCollapsedAction: boolean = false;

  suggestionRef: string;
  suggestionLocations: Editor.Edition.SuggestionLocations;

  constructor(
    range: Editor.Selection.JsonRange,
    baseModel: Editor.Data.Node.Model,
    baseData: Editor.Data.Node.Data,
    event?: Event,
    action?: Editor.Edition.ActionType,
  ) {
    this.baseModel = baseModel;
    this.baseData = baseData;
    this.range = range;
    this.event = event;

    if (action) {
      this.setActionType(action);
    }

    this.suggestionRef = NodeUtils.generateUUID();

    this.suggestionLocations = [];
  }

  setRange(range: Editor.Selection.JsonRange) {
    this.range = range;
    // if (this.baseRange) {
    //   this.blockNode = this.baseRange.blockNode;
    // }
  }

  setModelAndData(baseModel: Editor.Data.Node.Model, baseData: Editor.Data.Node.Data) {
    this.baseModel = baseModel;
    this.baseData = baseData;
  }

  refreshBaseData() {
    if (this.baseModel) {
      const baseData = this.baseModel.selectedData();
      if (baseData) {
        this.baseData = baseData;
      }
    }
  }

  setActionType(action: Editor.Edition.ActionType) {
    this.actionType = action;
  }

  addSuggestionLocation(blockId: string, path: Editor.Selection.Path) {
    this.suggestionLocations.push({
      blockId,
      pathToElement: path,
    });
  }
}
