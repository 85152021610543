import { selectFilteredTasks } from 'Editor/redux/TasksSlice';
import { useRef } from 'react';
import { EmptyFilteredState } from '_common/components';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';
import { useSelector } from '_common/hooks';
import TaskCard from 'Editor/pages/EditorPage/SidebarComponent/TasksTab/TaskCard';
import TasksEmptyState from './TasksEmptyState';
import styles from './TasksTab.module.scss';

const TasksTabContent = () => {
  const tasks = useSelector(selectFilteredTasks);
  const containerRef = useRef(null);

  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorTaskPanel),
  );

  if (tasks.length === 0) {
    if (hasActiveFilters) {
      return <EmptyFilteredState identity="editorTaskPanel" size="medium" />;
    }

    return <TasksEmptyState />;
  }

  return (
    <div ref={containerRef} className={styles.tasksContainer}>
      {tasks.map((task) => (
        <div key={task.id} style={{ margin: '1rem 0' }}>
          <TaskCard sidebar task={task} />
        </div>
      ))}
    </div>
  );
};

export default TasksTabContent;
