import { Mixin } from 'mixwith';

const _debouncers = Symbol('debouncers');

export default Mixin(
  (superclass) =>
    class extends superclass {
      constructor(...args) {
        super(...args);
        this[_debouncers] = {};
      }

      destroy() {
        super.destroy();
        this.drain();
      }

      addDebouncer(id, callback, debounceTime) {
        this[_debouncers][id] = {
          id,
          callback,
          timer: setTimeout(() => {
            callback();
            this.clearDebouncer(id);
          }, debounceTime),
        };
      }

      refreshDebouncer(id, callback, debounceTime) {
        if (this.hasDebouncer(id)) {
          this.clearDebouncer(id);
        }
        this.addDebouncer(id, callback, debounceTime);
      }

      clearDebouncer(id) {
        if (this.hasDebouncer(id)) {
          clearTimeout(this[_debouncers][id].timer);
          this[_debouncers][id] = undefined;
        }
      }

      hasDebouncer(id) {
        return (
          this[_debouncers] && this[_debouncers][id] !== null && this[_debouncers][id] !== undefined
        );
      }

      drain() {
        if (this[_debouncers]) {
          Object.keys(this[_debouncers]).forEach((debouncer) => this.clearDebouncer(debouncer.id));
        }
      }
    },
);
