import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Checkbox, Input, SectionHeader, Tooltip } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDebounce, useDispatch, useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { setInstances, setSelectedText } from 'Editor/redux/FindAndReplaceSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import { PanelContent, PanelHeading } from '_common/suite/components';
import Instances from './Instances/Instances';

import styles from './FindAndReplaceTab.module.scss';

const FindAndReplaceTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [find, setFind] = useState('');
  const [replace, setReplace] = useState('');
  const [matchCase, setMatchCase] = useState(false);
  const [wholeWords, setWholeWords] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const totalResults = useSelector((state) => state.editor.findAndReplace.totalResults);
  const currentResult = useSelector((state) => state.editor.findAndReplace.currentResult);
  const currentCanBeReplaced = useSelector(
    (state) => state.editor.findAndReplace.currentCanBeReplaced,
  );
  const selectedText = useSelector((state) => state.editor.findAndReplace.selectedText); //This is set when find and replace shortcut is emitted

  const disabledActions = isReadOnlyMode || isPageLayout;

  const debouncedInputValue = useDebounce(inputValue, 200);
  const canFind = find !== '' && totalResults > 0;
  const canReplaceAll = canFind && replace !== '' && totalResults >= 0 && !disabledActions;
  const canReplace =
    canReplaceAll && currentResult !== null && currentResult >= 0 && currentCanBeReplaced;

  useEffect(() => {
    if (!debouncedInputValue) {
      clearSearch();
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    if (selectedText) {
      if (selectedText === inputValue) {
        handleFind(selectedText);
      }
      setFind(selectedText);
      setInputValue(selectedText);
    }
  }, [selectedText]);

  useEffect(() => {
    if (find?.length) {
      handleFind(find);
    }
  }, [find, wholeWords, matchCase]);

  const clearSearch = () => {
    setFind('');
    setInputValue('');
    dispatch(setInstances({ instances: [], currentResult: -1, currentCanBeReplaced: true }));
  };

  const handleInputSearch = (value: typeof inputValue) => {
    if (value === find) {
      handleFind(value);
    } else {
      setFind(value);
    }
  };

  const handleFind = async (find: string) => {
    if (find) {
      setLoading(true);

      try {
        const res = await EditorManager.getInstance().findMatch(find, matchCase, wholeWords);
        if (res) {
          dispatch(
            setInstances({
              instances: res.matches,
              currentResult: res.currentMatchIndex,
              currentCanBeReplaced: res.editable,
            }),
          );
        }
      } catch (e) {
        notify({
          type: 'error',
          title: 'CANNOT_COMPLETE_THIS_ACTION',
          message: 'IF_PROBLEM_PERSISTS_CONTACT_SUPPORT',
        });
      } finally {
        setLoading(false);
      }
    }
    dispatch(setSelectedText({ selectedText: '' })); //To be able to ctr+f the same word again
  };

  const handlePrevious = async () => {
    EditorManager.getInstance().findPrevious();
  };

  const handleNext = async () => {
    EditorManager.getInstance().findNext();
  };

  const handleReplaceAll = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ReplaceAllModal',
        data: {
          find,
          replace,
          number: totalResults,
          matchCase,
          wholeWords,
        },
      }),
    );
  };

  const handleReplace = async () => {
    const res = await EditorManager.getInstance().replaceMatch(
      replace,
      matchCase,
      wholeWords,
      false,
    );

    const totalWords = find.split(' ').length;

    if (res) {
      dispatch(
        setInstances({
          instances: res.matches,
          currentResult: res.currentMatchIndex,
          currentCanBeReplaced: res.editable,
        }),
      );

      notify({
        type: 'success',
        title: 'WORD_REPLACED',
        message: 'THE_WORD_WERE_SUCCESSFULLY_REPLACED_WITH',
        messageValues: { totalWords: totalWords + '', find, replace },
      });
    }
  };

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="FIND_AND_REPLACE" />
      </PanelHeading>
      <PanelContent testId="find-and-replace-panel">
        <SectionHeader>
          <FormattedMessage id="FIND" />
        </SectionHeader>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onEnterKey={() => {
            handleInputSearch(inputValue);
          }}
          onPrefixClick={() => {
            handleInputSearch(inputValue);
          }}
          margin="2rem 0 0 0"
          placeholder={intl.formatMessage({ id: 'SEARCH_TEXT' })}
          prefix="NavSearchBlue"
          testId="find-and-replace-search"
        />
        <Checkbox
          checked={matchCase ? 'checked' : 'unchecked'}
          margin="3rem 0 0 0"
          size="small"
          onChange={() => setMatchCase(!matchCase)}
          testId="find-and-replace-match-case-checkbox"
        >
          <FormattedMessage id="MATCH_CASE" />
        </Checkbox>
        <Checkbox
          checked={wholeWords ? 'checked' : 'unchecked'}
          margin="1rem 0 0 0"
          size="small"
          onChange={() => setWholeWords(!wholeWords)}
          testId="find-and-replace-whole-words-checkbox"
        >
          <FormattedMessage id="FIND_WHOLE_WORDS" />
        </Checkbox>
        {!loading && <Instances word={find} />}
        <div className={styles.buttons}>
          <Button
            size="medium"
            fullWidth
            onClick={handlePrevious}
            disabled={!canFind}
            testId="find-and-replace-previous-button"
          >
            <FormattedMessage id="PREVIOUS" />
          </Button>
          <Button
            size="medium"
            fullWidth
            onClick={handleNext}
            margin="0 0 0 1rem"
            disabled={!canFind}
            testId="find-and-replace-next-button"
          >
            <FormattedMessage id="NEXT" />
          </Button>
        </div>
        <SectionHeader>
          <FormattedMessage id="REPLACE_WITH" />
        </SectionHeader>
        <Input
          value={replace}
          onChange={(e) => setReplace(e.target.value)}
          margin="2rem 0 0 0"
          placeholder={intl.formatMessage({ id: 'INSERT_TEXT' })}
          disabled={disabledActions}
          testId="find-and-replace-replace"
        />
        <div className={styles.buttons}>
          <Button
            size="medium"
            fullWidth
            onClick={handleReplaceAll}
            disabled={!canReplaceAll}
            testId="find-and-replace-replace-all-button"
          >
            <FormattedMessage id="REPLACE_ALL" />
          </Button>
          <Tooltip
            placement="bottom"
            content={intl.formatMessage({ id: 'OCCURRENCE_CANNOT_BE_REPLACED' })}
            disabled={currentCanBeReplaced}
            testId="find-and-replace-replace-tooltip"
          >
            <Button
              size="medium"
              fullWidth
              onClick={handleReplace}
              margin="0 0 0 1rem"
              disabled={!canReplace}
              testId="find-and-replace-replace-button"
            >
              <FormattedMessage id="REPLACE" />
            </Button>
          </Tooltip>
        </div>
      </PanelContent>
    </>
  );
};

export default FindAndReplaceTab;
