import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

const SLICE_NAME = 'EDITOR_TRACKING';

type TrackingState = {
  state: boolean;
  thirdParty?: boolean;
  lock: boolean;
  lock_time: ISODate | null;
  lock_user: UserId | null;
};

type TrackingSliceState = {
  [userId in UserId]: {
    [documentId in ObjectId]: TrackingState;
  };
};

const initialState: TrackingSliceState = {};

const TrackingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    toggleDocumentTracking: (
      state,
      action: PayloadAction<{
        userId: keyof TrackingSliceState;
        documentId: keyof TrackingSliceState[keyof TrackingSliceState];
      }>,
    ) => {
      const { userId, documentId } = action.payload;

      if (!state[userId]) {
        state[userId] = {};
      }

      if (!state[userId][documentId]) {
        state[userId][documentId] = {
          state: false,
          lock: false,
          lock_time: null,
          lock_user: null,
        };
      }

      state[userId][documentId].state = !state[userId][documentId].state;
    },
    setDocumentTracking: (
      state,
      action: PayloadAction<
        {
          userId: keyof TrackingSliceState;
          documentId: keyof TrackingSliceState[keyof TrackingSliceState];
        } & Pick<TrackingState, 'state' | 'lock' | 'lock_time' | 'lock_user'>
      >,
    ) => {
      const {
        userId,
        documentId,
        state: trackingState,
        lock,
        lock_time,
        lock_user,
      } = action.payload;

      if (!state[userId]) {
        state[userId] = {};
      }

      state[userId][documentId] = {
        state: trackingState,
        lock,
        lock_time,
        lock_user,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const getTrackingState = createSelector(
  [
    (state: RootState) => state.editor.tracking,
    (state: RootState) => state.app.information.actions,
    (state: RootState) => state.auth.userId,
    (state: RootState) => state.editor.status.documentId,
  ],
  (editorTracking, actions, userId, documentId) => {
    const trackingState: TrackingState = {
      state: false,
      lock: false,
      lock_time: null,
      lock_user: null,
    };

    //@ts-expect-error state.app.information.actions type its wrong, not sure what it should be
    const userTracking = actions?.editor?.suggestions;

    if (userTracking?.locked) {
      trackingState.state = userTracking.active;
      trackingState.lock = userTracking.locked;
      trackingState.thirdParty = true;
    } else if (
      editorTracking &&
      editorTracking[userId] != null &&
      editorTracking[userId][documentId] != null
    ) {
      trackingState.state = editorTracking[userId][documentId].state || false;
      trackingState.lock = editorTracking[userId][documentId].lock || false;
      trackingState.lock_time = editorTracking[userId][documentId].lock_time;
      trackingState.lock_user = editorTracking[userId][documentId].lock_user;
    }

    return trackingState;
  },
);

export const { toggleDocumentTracking, setDocumentTracking } = TrackingSlice.actions;

// Persistence
const persistConfig = {
  key: 'tracking',
  storage,
};

const trackingSliceReducer = persistReducer(persistConfig, TrackingSlice.reducer);

export default trackingSliceReducer;
