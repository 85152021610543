/* eslint-disable class-methods-use-this */
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class PDFStructure extends RealtimeObject<PDF.Structure.PDFDocumentDimensionData> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'pdfStructure');
  }

  handleLoad(): void {
    //
  }
  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }
  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    logger.info('PDFStructure handleBatchOperations', ops, source);
    const data = super.get() as PDF.Structure.PDFDocumentDimensionData;
    this.emit('UPDATED', data, ops, source);
  }
  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }
  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  pageDimensions(numPage: string): PDF.Structure.DimensionsData | undefined {
    return this.selectedData()?.p[numPage]?.d;
  }

  pageWidth(numPage: string): number | undefined {
    return this.selectedData()?.p[numPage]?.d.w;
  }

  pageHeight(numPage: string): number | undefined {
    return this.selectedData()?.p[numPage]?.d.h;
  }
}
