import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

type ResolveButtonProps = { onClick: () => void; testId: string; disabled: boolean };

const ResolveButton = ({ disabled, testId, onClick }: ResolveButtonProps) => {
  return (
    <Button
      variant="link"
      size="small"
      disabled={disabled}
      onClick={onClick}
      testId={`${testId}-resolve-button`}
    >
      <FormattedMessage id="RESOLVE" />
    </Button>
  );
};

export default ResolveButton;
