import { useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from '_common/hooks';
import { InputField, Input, PasswordInput, Button } from 'dodoc-design-system';
import { resetLoginErrors, signIn } from 'Auth/redux/authSlice';
import { useSelector } from '_common/hooks';

export const TEST_IDS = {
  INPUT: {
    USERNAME: 'username',
    PASSWORD: 'password',
  },
  BUTTON: 'button',
};

const FreshLogin = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const usernameRef = useRef<HTMLInputElement>(null);
  const loading = useSelector((state) => state.auth.loading);
  useEffect(() => {
    usernameRef.current?.focus();

    return () => {
      dispatch(resetLoginErrors());
    };
  }, []);
  const handleSignin = () => {
    if (username.length > 0 && password.length > 0) {
      dispatch(signIn({ params: { username, password } }));
    }
  };
  return (
    <div>
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'EMAIL_ADDRESS_OR_USERNAME' })}
        testId="email-username-field"
      >
        <span data-testid={TEST_IDS.INPUT.USERNAME}>
          <Input
            ref={usernameRef}
            size="large"
            placeholder={intl.formatMessage({ id: 'ENTER_EMAIL_ADDRESS_OR_USERNAME' })}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            testId={TEST_IDS.INPUT.USERNAME}
            tabIndex={1}
          />
        </span>
      </InputField>
      {/** This form wrap avoids browser verbose displaying password value */}
      <form>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'PASSWORD' })}
          link={`${intl.formatMessage({ id: 'FORGOT_PASSWORD' })}`}
          linkHref="/auth/recover"
          linkTabIndex={3}
          testId="password-field"
        >
          <span data-testid={TEST_IDS.INPUT.PASSWORD}>
            <PasswordInput
              size="large"
              placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD' })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onEnterKey={handleSignin}
              testId={TEST_IDS.INPUT.PASSWORD}
              tabIndex={1}
            />
          </span>
        </InputField>
      </form>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant="primary"
          size="large"
          margin="1rem 0 0 0"
          fullWidth
          onClick={handleSignin}
          disabled={username.length === 0 || password.length === 0}
          testId={TEST_IDS.BUTTON}
          loading={loading}
          tabIndex={1}
        >
          <FormattedMessage id="SIGN_IN" />
        </Button>
      </div>
    </div>
  );
};

export default FreshLogin;
