import { useGetImageQuery } from 'Presentation/api';
import { useSuiteObject } from '_common/suite';
import { useSlideData } from '../SlideData';
import { indexedModEffects } from 'Presentation/utils';
import { useEffect } from 'react';

type PictureBackgroundProps = {
  background: Presentation.Data.Common.PictureFillType;
  size: Presentation.Data.Common.Size;
  position: Presentation.Data.Common.Position;
};

const PictureBackground = ({ background, size, position }: PictureBackgroundProps) => {
  const { id } = useSuiteObject();
  const { addUnsupportedElement } = useSlideData();
  const { data } = useGetImageQuery({ presentation_id: id, reference_id: background.source });

  const { alphaModFix } = indexedModEffects(background.effects);

  useEffect(() => {
    if (background.tile) {
      //If any value is different than 0 (default)
      if (background.tile.tx || background.tile.ty) {
        //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:TEXTURE:OFFSET
        addUnsupportedElement('Slide - Texture Fill - Offset');
      }
      //If any value is different than 100 (default)
      if (background.tile.sx !== 100 || background.tile.sy !== 100) {
        //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:TEXTURE:SCALE
        addUnsupportedElement('Slide - Texture Fill - Scale');
      }
      //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:TEXTURE:ALIGNMENT
      addUnsupportedElement('Slide - Texture Fill - Alignment');
      //If flip is anything but none (default)
      if (background.tile.flip !== 'none') {
        //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:TEXTURE:MIRRORTYPE
        addUnsupportedElement('Slide - Texture Fill - Mirror Type');
      }
    } else {
      if (Object.keys(background.stretch.fillRect).length) {
        //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:PICTURE:OFFSET
        addUnsupportedElement('Slide - Picture Fill - Offset');
      }
    }
  }, []);

  if (background.tile) {
    return (
      <>
        <defs>
          <pattern
            id={background.source}
            x="0"
            y="0"
            width={background.tile.sx}
            height={background.tile.sy}
            patternUnits="userSpaceOnUse"
          >
            <image
              href={data}
              width={background.tile.sx}
              height={background.tile.sy}
              opacity={alphaModFix ? alphaModFix.value / 100 : 1}
            />
          </pattern>
        </defs>
        <rect width={size.width} height={size.height} fill={`url(#${background.source})`} />
      </>
    );
  }

  return (
    <>
      <defs>
        <pattern id={background.source} x="0" y="0" width="1" height="1">
          <image
            href={data}
            preserveAspectRatio="xMidYMid slice"
            width="100%"
            height="100%"
            opacity={alphaModFix ? alphaModFix.value / 100 : 1}
          />
        </pattern>
      </defs>
      <rect
        x={position.left}
        y={position.top}
        width={size.width}
        height={size.height}
        fill={`url(#${background.source})`}
      />
    </>
  );
};

export default PictureBackground;
