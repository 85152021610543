import { Command } from '../Command';
import { UpdateColumnWidthsOperation } from '../../Operations/TableOperations';
import { ErrorElementNotEditable } from '../../Errors';

export class UpdateColumnWidthsCommand extends Command {
  tableId: string;
  columnWidths: Editor.Edition.ColumnWidths;

  constructor(
    context: Editor.Edition.Context,
    tableId: string,
    columnWidths: Editor.Edition.ColumnWidths,
  ) {
    super(context);

    this.tableId = tableId;
    this.columnWidths = columnWidths;
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }
    if (
      !(
        this.actionContext.baseModel.id === this.tableId ||
        this.actionContext.baseModel.getChildInfoById(this.tableId)
      )
    ) {
      throw new Error('Invalid data');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    const pageWidth = this.context.DataManager?.sections.getPageWidthForBlockId(this.tableId);
    const operation = this.getOperation(this.actionContext.baseModel, pageWidth);
    operation.apply();

    // create patch
    this.createPatch();
  }

  private getOperation(baseModel: Editor.Data.Node.Model, pageWidth: number) {
    return new UpdateColumnWidthsOperation(baseModel, this.tableId, this.columnWidths, pageWidth);
  }
}
