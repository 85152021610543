import { createSlice, PayloadAction, createSelector, isAnyOf } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

type SliceState = {
  [key: string]: Editor.ListItem[];
};

const SLICE_NAME = 'EDITOR_LIST_STYLES';
const initialState: SliceState = {};

const listStylesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    loadListStyles: (state, action: PayloadAction<SliceState>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

const getListStyles = (state: RootState) => state.editor.listStyles;

export const getBulletList = createSelector([getListStyles], (listStyles) => {
  const data = Object.keys(listStyles).reduce((acc: SliceState, item: string) => {
    if (listStyles[item][0]?.list_type === 'u') {
      return { ...acc, [item]: listStyles[item] };
    }

    return acc;
  }, {});
  return data;
});

export const getNumberedList = createSelector([getListStyles], (listStyles) => {
  const data = Object.keys(listStyles).reduce((acc: SliceState, item: string) => {
    if (listStyles[item][0]?.list_type === 'o') {
      return { ...acc, [item]: listStyles[item] };
    }

    return acc;
  }, {});
  return data;
});

export const getOutlineList = createSelector([getListStyles], (listStyles) => {
  const data = Object.keys(listStyles).reduce((acc: SliceState, item: string) => {
    if (listStyles[item][0]?.list_type === 'ml') {
      return { ...acc, [item]: listStyles[item] };
    }

    return acc;
  }, {});
  return data;
});

export const { loadListStyles } = listStylesSlice.actions;

export default listStylesSlice.reducer;
