import { createApi } from '@reduxjs/toolkit/query/react';
import { closeModal, openAndUpdateModal } from '_common/modals/ModalsSlice';
import { axiosBaseQuery } from '../axios/axios';

type APIError = {
  error: { data: any; status: number };
};

// Define a service using a base URL and expected endpoints
const realtime = createApi({
  reducerPath: 'realtime',
  baseQuery: axiosBaseQuery({
    app: 'rt_rest',
    apiRoute: '/rt/rest',
  }),
  tagTypes: ['Setup', 'Navigation', 'Template'],
  endpoints: (builder) => ({
    realtimeSetup: builder.query<{ rt_version: string }, void>({
      query: () => ({
        url: '/setup',
      }),
      providesTags: ['Setup'],
    }),
    listNavigation: builder.query<
      {
        list: Editor.ToCItem['id'][];
        data: Record<Editor.ToCItem['id'], Editor.ToCItem>;
      },
      ObjectId
    >({
      query: (id) => ({
        url: `/document/${id}/toc`,
      }),
      providesTags: ['Navigation'],
    }),
    applyTemplate: builder.mutation<
      boolean,
      { template: Template['id']; forceReopen?: boolean; removeInlineStyles?: boolean; objectId: ObjectId }
    >({
      query: ({template, forceReopen, objectId}) => ({
        url: `/document/${objectId}/template`,
        method: 'POST',
        errorsExpected: [400],
        body: {
          template, forceReopen
        },
      }),
      invalidatesTags: (_, __, arg) => [
        {type: 'Template', template: arg.template, forceReopen: arg.forceReopen, removeInlineStyles: arg.removeInlineStyles,  objectId: arg.objectId},
      ],
      onQueryStarted: async ({template, removeInlineStyles, objectId}, {dispatch, queryFulfilled}) => {
        try {
          await queryFulfilled;
        } catch (e) {
          const error = (e as APIError).error;

          if(error?.status === 400) {
            dispatch(closeModal('ExportDocumentModal'));
            dispatch(
              openAndUpdateModal({
                modal: 'ConfirmationModal',
                data: {
                  message: 'CHANGE_DOCUMENT_TEMPLATE_MESSAGE',
                  title: 'CHANGE_DOCUMENT_TEMPLATE',
                  confirmButtonTextId: 'CHANGE_DOCUMENT_TEMPLATE',
                  cancelButtonTextId: 'global.cancel',
                  confirmButtonType: 'primary',
                  actionCode: 'changeDocumentTemplate',
                  actionValue: {
                    removeInlineStyles,
                    templateId: template,
                    objectId: objectId,
                    forceReopen: true,
                  },
                  width: '60rem',
                  headerType: 'information',
                  cancelButtonShow: true,
                },
              }),
            );
          }
        }
      }
    }),
    listTemplate: builder.query<{template: Template['id']}, ObjectId>({
      query: (id) => ({
        url: `/document/${id}/template`,
      }),
      providesTags: ['Template'],
    }),
  }),
});

export const { useRealtimeSetupQuery, useListNavigationQuery, useApplyTemplateMutation, useListTemplateQuery } = realtime;

export default realtime;
