import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

type TocSliceState = {
  list: Editor.ToCItem['id'][];
  data: Record<Editor.ToCItem['id'], Editor.ToCItem>;
  loading: boolean;
};

const initialState: TocSliceState = {
  list: [],
  data: {},
  loading: true,
};

const TocSlice = createSlice({
  name: 'toc',
  initialState,
  reducers: {
    loadToc: (state, action: PayloadAction<Pick<TocSliceState, 'list' | 'data'>>) => {
      state.list = action.payload.list;
      state.data = action.payload.data;
      state.loading = false;
    },
    setTocLoading: (state, action: PayloadAction<TocSliceState['loading']>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const selectTocItemsParents = createSelector(
  [(state: RootState) => state.editor.toc.data],
  (data) => {
    return Object.keys(data).reduce<Record<Editor.ToCItem['id'], Editor.ToCItem['id']>>(
      (mapper, id) => {
        const element = data[id];
        element.childNodes.forEach((childrenId) => {
          mapper[childrenId] = id;
        });
        return mapper;
      },
      {},
    );
  },
);

export const { loadToc, setTocLoading } = TocSlice.actions;

export default TocSlice.reducer;
