import { useState, useLayoutEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks/redux';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { useEditProfileMutation } from 'Settings/pages/UserSettingsPage/UserSettingsApi';
import { useGetCurrentUserQuery } from '_common/services/api/authority';

import { Modal, Button, Input, InputField } from 'dodoc-design-system';

const MODAL = 'ChangeNameModal';

const ChangeNameModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const disabledFields = useSelector((state) => state.modals[MODAL].disabledFields);
  const { data: currentUser } = useGetCurrentUserQuery();
  const [editProfile] = useEditProfileMutation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (isOpen && currentUser) {
      setFirstName(currentUser.profile.first_name);
      setLastName(currentUser.profile.last_name);

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isOpen, currentUser, currentUser?.profile.first_name, currentUser?.profile.last_name]);

  const handleChangeName = () => {
    if (currentUser) {
      editProfile({
        first_name: firstName,
        last_name: lastName,
        userId: currentUser.profile.id,
      });
      close();
    }
  };

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="change-name">
      <Modal.Header onClose={close}>
        <FormattedMessage id="settings.name.header" />
      </Modal.Header>
      <Modal.Body>
        <InputField
          label={intl.formatMessage({ id: 'settings.name.firstName' })}
          size="large"
          disabled={disabledFields.firstName}
          testId="change-name-modal-first-name-field"
        >
          <Input
            size="large"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            ref={inputRef}
            placeholder={intl.formatMessage({ id: 'FIRST_NAME_PLACEHOLDER' })}
            testId="change-name-modal-first-name"
            disabled={disabledFields.firstName}
          />
        </InputField>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'settings.name.lastName' })}
          disabled={disabledFields.lastName}
          testId="change-name-modal-last-name-field"
        >
          <Input
            size="large"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={intl.formatMessage({ id: 'LAST_NAME_PLACEHOLDER' })}
            testId="change-name-modal-last-name"
            disabled={disabledFields.lastName}
          />
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="change-name-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleChangeName}
          testId="change-name-modal-save-button"
          disabled={disabledFields.lastName && disabledFields.firstName}
        >
          <FormattedMessage id="global.save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeNameModal;
