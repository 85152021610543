import FormattedDate from '_common/components/FormattedDate/FormattedDate';
import FormattedTime from '_common/components/FormattedTime/FormattedTime';

import type { FormattedDateProps } from '_common/components/FormattedDate/FormattedDate';
import type { FormattedTimeProps } from '_common/components/FormattedTime/FormattedTime';

type DateTimeCellProps = {
  date?: FormattedDateProps;
  time?: FormattedTimeProps;
};

const DateTimeCell = ({ date, time }: DateTimeCellProps) => {
  return (
    <>
      {date?.date && <FormattedDate type="short" {...date} />}
      {time?.time && (
        <>
          {date?.date && <>&nbsp;</>}
          <FormattedTime type="meridiem" {...time} />
        </>
      )}
    </>
  );
};

export default DateTimeCell;
