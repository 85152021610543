import { FormattedMessage } from 'react-intl';
import styles from './FileUploadLoading.module.scss';

type FileUploadLoadingProps = {
  error?: string;
};

const FileUploadLoading = ({ error }: FileUploadLoadingProps) => (
  <div className={styles.root}>
    <div className={styles.spinner}>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
    </div>
    {error && (
      <div className={styles.error}>
        <FormattedMessage id={error} />
      </div>
    )}
  </div>
);

export default FileUploadLoading;
