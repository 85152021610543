import { useState } from 'react';
import { Tooltip, usePopper, Popover } from 'dodoc-design-system';
import { useSelector, useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';
import ToolbarToggleButton from 'Editor/components/ToolbarToggleButton/ToolbarToggleButton';
import ColorsMenu from 'Editor/components/ColorsMenu/ColorsMenu';

const ParagraphBackgroundColor = ({ isReadOnlyMode }: { isReadOnlyMode: boolean }) => {
  const dispatch = useDispatch();
  const paragraphBackgroundColor = useSelector((state) =>
    state.editor.toolbar.paragraphColor
      ? state.editor.toolbar.paragraphColor.replace(/ /g, '')
      : '',
  );
  const [documentColors, setDocumentColors] = useState<
    { r: string | number; g: string | number; b: string | number; rgb: string }[]
  >([]);
  const { isOpen, popperProps, referenceProps, close } = usePopper({
    placement: 'bottom-start',
  });

  const onOpenColorsPopover = () => {
    const manager = EditorManager.getInstance();
    const documentColors = manager.getDocumentColors();
    if (documentColors) {
      setDocumentColors(documentColors);
    }
  };

  const onParagraphBackgroundColorChange = (color: string) => {
    EditorManager.getInstance().applyParagraphBackgroundColor(color);
  };

  const onClearParagraphBackgroundColor = () => {
    EditorManager.getInstance().applyParagraphBackgroundColor('transparent');
  };

  const openColorPickerModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ColorPickerModal',
        data: {
          action: 'applyParagraphBackgroundColor',
        },
      }),
    );
  };

  return (
    <>
      <Tooltip content="Paragraph Background" testId="paragraph-background-color">
        <div>
          <ToolbarToggleButton
            disabled={isReadOnlyMode}
            isActive={isOpen}
            toggleButtonClicked={() => {
              onOpenColorsPopover();
              referenceProps.onClickCapture();
            }}
            icon="ParagraphBckColor"
            color={paragraphBackgroundColor}
            testId="paragraph-background-color"
            {...referenceProps}
          />
        </div>
      </Tooltip>
      <Popover {...popperProps} testId="paragraph-background-color-popper">
        <ColorsMenu
          documentColors={documentColors}
          toggle={close}
          changeBackgroundColor={(color) => {
            onParagraphBackgroundColorChange(color);
            close();
          }}
          clearBackgroundColor={() => {
            onClearParagraphBackgroundColor();
            close();
          }}
          selected={paragraphBackgroundColor}
          setOpenColorPickerModal={openColorPickerModal}
        />
      </Popover>
    </>
  );
};

export default ParagraphBackgroundColor;
