import { CSSProperties } from 'react';

export const THUMBNAIL_WIDTH = 200;
export const THUMBNAIL_LABEL_GAP = 16;
export const THUMBNAIL_LABEL_HEIGHT = 8;
export const THUMBNAIL_BACKGROUND_PADDING = 8;

export const OUTLINE_CAP = {
  rnd: 'round',
  sq: 'square',
  flat: 'butt',
} as const;

export const OUTLINE_DASHARRAY = {
  solid: [0],
  dash: [3, 4], //check
  dot: [0, 4],
  dashDot: [3, 4, 1, 4], //check
  lgDash: [7, 4], //check
  lgDashDot: [7, 4, 1, 4], //check
  lgDashDotDot: [7, 4, 1, 4, 1, 4], //check
  sysDot: [1, 2], //check
  sysDash: [2, 2], //check
  sysDashDot: [6, 2, 1, 2],
  sysDashDotDot: [6, 2, 1, 2, 1, 2],
};

export const TEXT_ALIGN_MAP = {
  l: 'left',
  ctr: 'center',
  r: 'right',
  just: 'justify',
  justLow: 'justify',
  dist: 'justify',
  thaiDist: 'justify',
} as const;

export const TEXT_DECORATION_MAP: Record<
  NonNullable<
    Presentation.Data.InlineProperties['u'] | Presentation.Data.InlineProperties['strike']
  >,
  CSSProperties
> = {
  noStrike: {
    textDecorationLine: 'none',
  },
  sngStrike: { textDecoration: 'line-through' },
  dblStrike: { textDecoration: 'line-through', textDecorationStyle: 'double' },
  none: {
    textDecoration: 'none',
  },
  words: {
    textDecoration: 'underline',
  },
  sng: {
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
  },
  dbl: {
    textDecoration: 'underline',
    textDecorationStyle: 'double',
    textDecorationThickness: '1px',
  },
  heavy: {
    textDecoration: 'underline',
    textDecorationThickness: '4px',
  },
  dotted: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: '2px',
  },
  dottedHeavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: '3px',
  },
  dash: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationThickness: '2px',
  },
  dashHeavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationThickness: '3px',
  },
  dashLong: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationThickness: '2px',
  },
  dashLongHeavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationThickness: '3px',
  },
  dotDash: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: '2px',
  },
  dotDashHeavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: '3px',
  },
  dotDotDash: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: '2px',
  },
  dotDotDashHeavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: '3px',
  },
  wavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'wavy',
    textDecorationThickness: '1px',
  },
  wavyHeavy: {
    textDecoration: 'underline',
    textDecorationStyle: 'wavy',
    textDecorationThickness: '2px',
  },
  wavyDbl: {
    textDecoration: 'underline',
    textDecorationLine: 'double',
    textDecorationStyle: 'wavy',
    textDecorationThickness: '1px',
  },
};

export const MAX_HSL_VALUES = {
  h: 360,
  s: 100,
  l: 100,
};
