import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useDispatch, useEffectOnUpdate, useSelector } from '_common/hooks';
import { setCreationSetting, setTextboxPresetStyles } from 'PDF/redux/PDFAnnotationsSlice';
import { setZoomValue } from 'PDF/redux/PDFGeneralSlice';

const usePDFPersist = () => {
  const dispatch = useDispatch();
  const { id: pdfId } = useParams<{ id: string }>();

  const userId = useSelector((state) => state.auth.userId);

  const [hasLoadedZoom, setHasLoadedZoom] = useState(false);

  //Save Textbox preset styles when changed
  const textboxPresetStyles = useSelector((state) => state.pdf.annotations.textbox.preset);
  useEffectOnUpdate(() => {
    if (pdfId && userId) {
      window.localStorage.setItem(
        `${userId}-${pdfId}-textboxPresetStyles`,
        JSON.stringify(textboxPresetStyles),
      );
    }
  }, [textboxPresetStyles]);

  //Save Annotation box styles when changed
  const creation = useSelector((state) => state.pdf.annotations.creation);
  useEffectOnUpdate(() => {
    if (pdfId && userId) {
      window.localStorage.setItem(
        `${userId}-${pdfId}-annotationSettings`,
        JSON.stringify(creation),
      );
    }
  }, [creation]);

  //Save Zoom when changed
  const zoom = useSelector((state) => state.pdf.general.zoom);
  useEffectOnUpdate(() => {
    if (pdfId && userId && hasLoadedZoom) {
      window.localStorage.setItem(`${userId}-${pdfId}-zoom`, `${zoom}`);
    }
  }, [zoom]);

  //Load data persisted in localStorage
  useEffect(() => {
    if (userId && pdfId) {
      try {
        //Get localStorage persisted annotation settings
        const annotationSettings = window.localStorage.getItem(
          `${userId}-${pdfId}-annotationSettings`,
        );
        if (annotationSettings) {
          dispatch(setCreationSetting(JSON.parse(annotationSettings)));
        }

        //Get localStorage persisted textbox preset styles
        const textboxPreset = window.localStorage.getItem(`${userId}-${pdfId}-textboxPresetStyles`);
        if (textboxPreset) {
          dispatch(setTextboxPresetStyles(JSON.parse(textboxPreset)));
        }

        //Get localStorage persisted zoom
        setHasLoadedZoom(true); //Loaded status changed to true even if unsuccessful
        const zoom = window.localStorage.getItem(`${userId}-${pdfId}-zoom`);
        if (zoom) {
          dispatch(setZoomValue(+zoom));
        }
      } catch (e) {
        throw e;
      }
    }
  }, [userId, pdfId]);
};

export default usePDFPersist;
