import { forwardRef, Ref } from 'react';
import { RenderLeafProps } from 'slate-react';
import { DEFAULT_FONT_SIZE } from './consts';
import { RichFormat } from './RichTextEditor';

import styles from './RichTextEditor.module.scss';

type CustomLeafProps = RenderLeafProps & {
  scale?: number;
  overwriteStyles?: Exclude<RichFormat, 'align'>;
  testId?: string;
};

const CustomLeaf = forwardRef(
  (
    { attributes, leaf, children, scale, overwriteStyles, testId }: CustomLeafProps,
    ref: Ref<HTMLSpanElement>,
  ) => {
    const fontColor = leaf.color ? { color: overwriteStyles?.color ?? leaf.color } : {};
    const fontFamily = leaf.fontfamily
      ? { fontFamily: overwriteStyles?.fontfamily ?? leaf.fontfamily }
      : {};
    const fontSize = {
      fontSize: `${
        +(overwriteStyles?.fontsize ?? leaf.fontsize ?? DEFAULT_FONT_SIZE) * (scale ?? 1)
      }px`,
      lineHeight: 'normal',
    };

    return (
      <span
        {...attributes}
        ref={ref}
        style={{
          fontWeight:
            !leaf.initialMention && (overwriteStyles?.bold ?? leaf.bold) ? 'bold' : 'normal',
          fontStyle:
            !leaf.initialMention && (overwriteStyles?.italic ?? leaf.italic) ? 'italic' : 'normal',
          textDecoration:
            !leaf.initialMention && (overwriteStyles?.underline ?? leaf.underline)
              ? 'underline'
              : 'none',
          ...fontColor,
          ...fontFamily,
          ...fontSize,
        }}
        className={`${leaf.initialMention ? styles.inlineMention : null}`}
        data-testid={
          testId
            ? `${!leaf.initialMention && leaf.bold ? `${testId}-leaf-bold ` : ''}${
                !leaf.initialMention && leaf.italic ? `${testId}-leaf-italic ` : ''
              }${!leaf.initialMention && leaf.underline ? `${testId}-leaf-underline ` : ''}${
                leaf.initialMention ? `${testId}-leaf-inlineMention ` : ''
              }
        `
            : ''
        }
      >
        {children}
      </span>
    );
  },
);

export default CustomLeaf;
