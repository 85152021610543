/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-empty-function */
import { TaskBuilder } from '../../models';
import { BaseController } from '../BaseController';

export class TasksController extends BaseController {
  protected tasks?: Presentation.Model.Tasks;

  async start(documentId: string) {
    this.tasks = this.Data.models?.get('TASKS', `PT${documentId}`);
    await this.tasks?.awaitForEvent('LOADED');
    if (this.tasks?.get([]) === undefined) {
      this.tasks?.create({
        parent_id: documentId,
        id: `PT${documentId}`,
        _id: `PT${documentId}`,
        count: 0,
        sld: {},
      });
    }
  }

  builder() {
    return TaskBuilder.new();
  }

  addTask(
    data: Common.OnlyRequire<
      Presentation.Data.Task,
      'content' | 'dueDate' | 'anchor' | 'authorId' | 'assignee'
    >,
  ) {
    if (!this.Data.users?.loggedUserId) {
      return Promise.reject(new Error('No user information available!'));
    }
    return this.tasks?.addTask({ ...data, authorId: this.Data.users?.loggedUserId });
  }

  editTask(
    data: Common.OnlyRequire<
      Presentation.Data.Task,
      'assignee' | 'content' | 'dueDate' | 'anchor' | 'id'
    >,
  ) {
    return this.tasks?.editTask(data);
  }

  addWatcherToTask(slideId: string, taskId: string, userId: string) {
    return this.tasks?.addWatcherToTask(slideId, taskId, userId);
  }

  removeWatcherFromTask(slideId: string, taskId: string, userId: string) {
    return this.tasks?.removeWatcherFromTask(slideId, taskId, userId);
  }

  changeTaskStatus(slideId: string, taskId: string, status: Presentation.Model.Tasks.TaskStatus) {
    return this.tasks?.changeTaskStatus(slideId, taskId, status);
  }

  replyToTask(
    slideId: string,
    taskId: string,
    replyContent: Presentation.Model.Tasks.ContentsType['content'],
  ) {
    return this.tasks?.replyToTask(
      slideId,
      taskId,
      this.Data.users?.loggedUserId as string,
      replyContent,
    );
  }

  deleteReplyToTask(slideId: string, taskId: string, replyId: string) {
    return this.tasks?.deleteReplyToTask(slideId, taskId, replyId);
  }

  editReplyToTask(
    slideId: string,
    taskId: string,
    replyId: string,
    replyContent: Presentation.Model.Tasks.ContentsType['content'],
  ) {
    return this.tasks?.editReplyToTask(slideId, taskId, replyId, replyContent);
  }

  voteReplyToTask(
    slideId: string,
    taskId: string,
    replyId: Presentation.Model.Tasks.Reply['id'],
    value: Presentation.Model.Tasks.Vote['value'],
  ) {
    return this.tasks?.voteReplyToTask(
      slideId,
      taskId,
      this.Data.users?.loggedUserId as string,
      replyId,
      value,
    );
  }

  deleteTask(slideId: string, taskId: string) {
    return this.tasks?.deleteTask(slideId, taskId);
  }

  stop(): void {}

  destroy(): void {
    //
  }
}
