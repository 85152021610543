import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Label } from 'dodoc-design-system';
import { LabelProps } from 'dodoc-design-system/build/types/Components/Label/Label';

type TaskStatusProps = { taskState: Card.Task.State; taskStatus: Card.Task.Status };

const STATUS: {
  [key in Card.Task.Status]: { label: string; color: LabelProps['color'] };
} = {
  td: { label: 'TODO', color: 'grey' },
  pr: { label: 'IN_PROGRESS', color: 'blue' },
  d: { label: 'auth.login.done', color: 'green' },
};

const TaskStatus = ({ taskState, taskStatus }: TaskStatusProps) => {
  const status = useMemo<
    | {
        label: string;
        color: LabelProps['color'];
      }
    | undefined
  >(() => {
    if (taskState === 'Cancelled') {
      return { label: 'editor.sidebar.review.filter.status.deleted', color: 'red' };
    } else if (taskStatus) {
      return STATUS[taskStatus as keyof typeof STATUS];
    } else {
      return undefined;
    }
  }, [taskState, taskStatus]);

  if (status) {
    return (
      <Label size="small" color={status.color} testId="task-status-label">
        <FormattedMessage id={status.label} />
      </Label>
    );
  }

  return null;
};

export default TaskStatus;
