import { ReduxInterface } from 'Editor/services';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewElement } from '../..';

export class CitationElement extends BaseViewElement {
  constructor() {
    super();

    this.clickListener = this.clickListener.bind(this);
  }

  clickListener(e: Event) {
    e.stopPropagation();
    const id = this.getAttribute('element_reference');
    if (id) {
      ReduxInterface.setSelectedCitation(id);
    }
  }

  connectedCallback() {
    this.addEventListener('click', this.clickListener);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.clickListener);
  }
}

if (!window.customElements.get(ELEMENTS.CitationElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.CitationElement.IDENTIFIER, CitationElement);
}
