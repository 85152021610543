import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'EDITOR_LANGUAGE';

export type EditorLanguageSlice = {
  defaultLanguage: string;
  languages: Services.Language[];
};

const initialState: EditorLanguageSlice = {
  defaultLanguage: '',
  languages: [],
};

const EditorLanguageSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setDefaultLanguage: (state, action: PayloadAction<EditorLanguageSlice['defaultLanguage']>) => {
      state.defaultLanguage = action.payload;
    },
    setLanguages: (state, action: PayloadAction<EditorLanguageSlice['languages']>) => {
      state.languages = action.payload;
    },
    loadLanguageData: (state, action: PayloadAction<EditorLanguageSlice>) => {
      state.defaultLanguage = action.payload.defaultLanguage;
      state.languages = action.payload.languages;
    },
  },
});

export const { setDefaultLanguage, setLanguages, loadLanguageData } = EditorLanguageSlice.actions;

export default EditorLanguageSlice.reducer;
