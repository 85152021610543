import { Button, Divider, Tooltip } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { ShortcutsPopover } from '_common/components';
import { useTableOptionsContext } from './TableOptions';

import styles from './TableOptions.module.scss';

const InsertCaptionShortcut = ({ offsets }: { offsets: Rect | null }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { setDeletingTable } = useTableOptionsContext();

  const handleInsertCaption = () => {
    dispatch(openAndUpdateModal({ modal: 'CaptionsModal', data: { editModal: 'insert' } }));
  };

  const handleDeleteTable = () => EditorManager.getInstance().deleteTable();

  return (
    <ShortcutsPopover testId="table" offsets={offsets}>
      <div className={styles.shortcutsWrapper}>
        <Button
          size="medium"
          variant="link"
          onClick={handleInsertCaption}
          testId="table-insert-caption"
        >
          <FormattedMessage id="INSERT_CAPTION" />
        </Button>
        <div>
          <Divider vertical margin="0" />
        </div>
        <span
          onMouseEnter={() => setDeletingTable(true)}
          onMouseLeave={() => setDeletingTable(false)}
        >
          <Tooltip
            content={intl.formatMessage({ id: 'global.delete' })}
            testId="table-delete-table-tooltip"
          >
            <Button
              size="medium"
              variant="link"
              onClick={handleDeleteTable}
              testId="table-delete-table"
              icon="LightRecycleBin"
            />
          </Tooltip>
        </span>
      </div>
    </ShortcutsPopover>
  );
};

export default InsertCaptionShortcut;
