import { FormattedMessage } from 'react-intl';
import { Dropdown, Divider, Toggle, usePopper } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import EditorManager from 'Editor/services/EditorManager';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { setSidebarView } from 'Editor/redux/SidebarSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

const Edit = () => {
  const dispatch = useDispatch();
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-start',
  });

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const handleOpenSynonyms = () => {
    const manager = EditorManager.getInstance();
    if (manager.isSelectionCollapsed()) {
      notify({
        type: 'error',
        title: 'global.error',
        message: 'editor.menu.edit.searchSynonyms',
      });
      return;
    }
    const query = manager.getSelectedText();
    dispatch(openAndUpdateModal({ modal: 'SynonymsModal', data: { query } }));
  };

  const handleCutOption = () => {
    EditorManager.getInstance().handleCutMenuOption();
  };

  const handleCopyOption = () => {
    EditorManager.getInstance().handleCopyMenuOption();
  };

  const handlePasteOption = () => {
    EditorManager.getInstance().handlePasteMenuOption();
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        {...referenceProps}
        isToggled={isOpen}
        margin="0 1rem 0 0"
        testId="edit-button"
      >
        <FormattedMessage id="editor.menu.edit.value" />
      </Toggle>
      <Dropdown {...popperProps} testId="topbar-menu-edit">
        <Dropdown.Item
          prefixIcon="Undo"
          onClick={() => {
            EditorManager.getInstance().undoListener();
          }}
          disabled={isReadOnlyMode || isPageLayout}
          testId="undo-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.undo" />
        </Dropdown.Item>
        <Dropdown.Item
          prefixIcon="Redo"
          onClick={() => {
            EditorManager.getInstance().redoListener();
          }}
          disabled={isReadOnlyMode || isPageLayout}
          testId="redo-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.redo" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          shortcut="X"
          onClick={handleCutOption}
          disabled={isReadOnlyMode || isPageLayout}
          testId="cut-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.cut" />
        </Dropdown.Item>
        <Dropdown.Item
          shortcut="C"
          onClick={handleCopyOption}
          disabled={isReadOnlyMode}
          testId="copy-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.copy" />
        </Dropdown.Item>
        <Dropdown.Item
          shortcut="V"
          onClick={handlePasteOption}
          disabled={isReadOnlyMode || isPageLayout}
          testId="paste-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.paste" />
        </Dropdown.Item>
        <Dropdown.Item
          shortcut="A"
          onClick={() => {
            EditorManager.getInstance().handleSelectAll();
          }}
          testId="select-all-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.selectAll" />
        </Dropdown.Item>
        <Divider />
        <Dropdown.Item
          onClick={handleOpenSynonyms}
          disabled={isReadOnlyMode}
          testId="synonyms-dropdown-item"
        >
          <FormattedMessage id="editor.menu.edit.synonyms" />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => dispatch(setSidebarView('FIND_AND_REPLACE'))}
          testId="find-and-replace-dropdown-item"
        >
          <FormattedMessage id="FIND_AND_REPLACE" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Edit;
