export const AnnotationFlags = {
  Invisible: 0x01,
  Hidden: 0x02,
  Print: 0x04,
  NoZoom: 0x08,
  NoRotate: 0x10,
  NoView: 0x20,
  ReadOnly: 0x40,
  Locked: 0x80,
  ToggleNoView: 0x100,
  LockedContents: 0x200,
} as const;

export type AnnotationFlag = typeof AnnotationFlags[keyof typeof AnnotationFlags];

export const AnnotationTypes = {
  Text: 'Text',
  Link: 'Link',
  FreeText: 'FreeText',
  Line: 'Line',
  Square: 'Square',
  Circle: 'Circle',
  Polygon: 'Polygon',
  PolyLine: 'PolyLine',
  Highlight: 'Highlight',
  Underline: 'Underline',
  Squiggly: 'Squiggly',
  StrikeOut: 'StrikeOut',
  Stamp: 'Stamp',
  Caret: 'Caret',
  Ink: 'Ink',
  Popup: 'Popup',
  FileAttachment: 'FileAttachment',
  Sound: 'Sound',
  Movie: 'Movie',
  Widget: 'Widget',
  Screen: 'Screen',
  PrinterMark: 'PrinterMark',
  TrapNet: 'TrapNet',
  Watermark: 'Watermark',
  '3D': '3D',
  Redact: 'Redact',
  Note: 'Note',
  Task: 'Task',
} as const;

export type AnnotationType = typeof AnnotationTypes[keyof typeof AnnotationTypes];

export const AnnotationStateModels = {
  Marked: 'Marked',
  Review: 'Review',
} as const;

export type AnnotationStateModel = typeof AnnotationStateModels[keyof typeof AnnotationStateModels];

export const AnnotationMarkedStates = {
  Marked: 'Marked',
  Unmarked: 'Unmarked',
} as const;

export type AnnotationMarkedState =
  typeof AnnotationMarkedStates[keyof typeof AnnotationMarkedStates];

export const AnnotationReviewStates = {
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  None: 'None',
} as const;

export type AnnotationReviewState =
  typeof AnnotationReviewStates[keyof typeof AnnotationReviewStates];

export const AnnotationPriorities = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
} as const;

export type AnnotationPriority = typeof AnnotationPriorities[keyof typeof AnnotationPriorities];

export const TaskStatuses = {
  TO_DO: 'td',
  IN_PROGRESS: 'pr',
  DONE: 'd',
} as const;

export type TaskStatus = typeof TaskStatuses[keyof typeof TaskStatuses];
