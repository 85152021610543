import { Logger } from '_common/services';
import { Command } from '../Command';
import { SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';
import { ErrorCannotInsertElement } from '../../Errors';
import { ErrorElementNotEditable } from '../../Errors';

type ExecParams = [Editor.Data.Node.Data];

export class InsertBlockCommand extends Command<ExecParams> {
  private options: Editor.Edition.InsertBlockOptions;

  constructor(context: Editor.Edition.Context, options: Editor.Edition.InsertBlockOptions = {}) {
    super(context);
    this.options = options;
  }

  protected handleCollapsedSelection(
    ctx: Editor.Edition.ActionContext,
    blockData: Editor.Data.Node.Data,
  ): boolean {
    if (
      !this.context.DataManager ||
      !this.context.contentManipulator ||
      !ctx.baseModel ||
      !ctx.baseData
    ) {
      return false;
    }

    const result = NodeUtils.closestOfTypeByPath(ctx.baseData, ctx.range.start.p, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    if (result?.data) {
      // check if element is editable
      if (!this.context.DataManager.nodes.isNodeEditable(ctx.baseModel.id)) {
        throw new ErrorElementNotEditable();
      }

      // normalize text selection
      SelectionFixer.normalizeTextSelection(
        ctx.range,
        {
          suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        },
        this.context.DataManager,
      );

      return (
        !!this.context.contentManipulator.splitBlockContent(ctx, ctx.range.start.p, {
          checkEmpty: false,
        }) && this.context.contentManipulator.insertBlock(ctx, blockData, 'AFTER', this.options)
      );
    } else {
      // insert new node after
      return this.context.contentManipulator.insertBlock(ctx, blockData, 'AFTER', this.options);
    }
  }

  protected handleNonCollapsedSelection(ctx: Editor.Edition.ActionContext): boolean {
    // remove content
    if (this.context.contentManipulator) {
      return this.context.contentManipulator.removeContent(ctx);
    }

    return false;
  }

  async handleExec(...[blockData]: ExecParams): Promise<void> {
    if (this.debug) {
      Logger.trace('InsertBlockCommand exec', this, blockData);
    }

    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.actionContext ||
      !blockData
    ) {
      throw new Error('Invalid data');
    }

    // check if insertion is allowed
    if (
      !NodeUtils.isBlockInsertionAllowed(
        this.actionContext.baseData,
        this.actionContext.range.getCommonAncestorPath(),
        blockData,
      )
    ) {
      //Logger.warn('Block insertion not allowed!', blockData);
      throw new ErrorCannotInsertElement('Block insertion not allowed!');
    }

    if (!this.actionContext.range.collapsed) {
      // handle non collapsed selection
      if (!this.handleNonCollapsedSelection(this.actionContext)) {
        return;
      }
    }

    // handle collapsed selection
    if (this.actionContext.range.collapsed) {
      if (!this.handleCollapsedSelection(this.actionContext, blockData)) {
        return;
      }
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
