import { useEffect, useState, useRef } from 'react';
import getConfig from 'dodoc.config';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import { getObjectData } from 'App/redux/appSlice';
import styles from './WopiPage.module.scss';

type FileUrl = {
  ott: string;
  ttl: number;
  wopisrc: string;
  api: string;
  action: {
    params: {
      ui?: string;
      rs?: string;
    };
    statics: string[];
    url: string;
  };
  favicon: string;
};

const getUrl = (data: FileObject, applicationId: string, actionId: string) => {
  return new Promise<FileUrl>((resolve, reject) => {
    new InstanceService()
      .createOtt()
      .then((response) => {
        // @ts-expect-error Missing endpoint type "/api/ott/create"
        if (response.status === 200 && response.data.token && response.data.expiration) {
          let api = getConfig().api;
          if (data.wopi) {
            const {
              wopi: { wopisrc, actions },
            } = data;

            const appActions = actions.find(
              (action) => action.app === applicationId && action.name === actionId,
            );
            if (appActions) {
              const { action, favicon } = appActions;
              if (!api || api === '/') {
                api = window.location.origin;
              }
              resolve({
                // @ts-expect-error Missing endpoint type "/api/ott/create"
                ott: response.data.token,
                // @ts-expect-error Missing endpoint type "/api/ott/create"
                ttl: Math.trunc(response.data.expiration * 1000), // ttl is in seconds needs to be in milliseconds
                wopisrc,
                api,
                action,
                favicon,
              });
            }
          }
        }
      })
      .catch((error) => reject(error));
  });
};

const Loading = () => <div>Loading</div>;

type WopiPageUrlParams = {
  id: string;
  applicationId: string;
  actionId: string;
};

const WopiPage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.app.data);
  const locale = useSelector((state) => state.intl.locale);
  const { id, applicationId, actionId } = useParams<WopiPageUrlParams>();
  const [url, setUrl] = useState('');
  const ottRef = useRef('');
  const ttlRef = useRef(0);

  useEffect(() => {
    dispatch(getObjectData({ objectId: id, objectType: 'file' }));
  }, [id]);

  useEffect(() => {
    if (data[id]) {
      getUrl(data[id] as FileObject, applicationId, actionId).then(
        ({ ott, action, api, wopisrc, ttl, favicon }) => {
          ottRef.current = ott;
          ttlRef.current = ttl;
          const params = [...action.statics, `wopisrc=${encodeURIComponent(wopisrc)}`];
          if (action.params.ui) {
            params.push(`ui=${locale}`);
          }
          if (action.params.rs) {
            params.push(`rs=${locale}`);
          }
          const url = `${action.url}?${params.join('&')}`;
          // TODO: Add meta tags with favicon and viewport
          // <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no">
          const meta = document.createElement('meta');
          meta.setAttribute('name', 'viewport');
          meta.setAttribute(
            'content',
            'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no',
          );
          // <link rel="shortcut icon" href={action.favicon} />
          const link = document.createElement('link');
          link.setAttribute('rel', 'shortcut icon');
          link.setAttribute('href', favicon);

          document.head.appendChild(meta);
          document.head.appendChild(link);

          setUrl(url);
        },
      );
    }
  }, [data]);
  useEffect(() => {
    if (url) {
      (document.getElementById('office_form') as HTMLFormElement).submit();
    }
  }, [url]);
  return (
    <div className={styles.root}>
      {url ? (
        <>
          <form
            id="office_form"
            name="office_form"
            target="office_frame"
            action={url}
            method="post"
          >
            <input name="access_token" value={ottRef.current} type="hidden" />
            <input name="access_token_ttl" value={ttlRef.current} type="hidden" />
          </form>
          <iframe
            id="office_frame"
            name="office_frame"
            className={styles.iframe}
            title={data[id]?.name}
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default WopiPage;
