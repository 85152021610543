import { NodeUtils } from 'Editor/services/DataManager';
import { Command } from '../Command';
import { SelectionFixer } from 'Editor/services/_Common/Selection';

export class DeleteHyperlinkCommand extends Command {
  async handleExec(): Promise<void> {
    this.buildActionContext();

    this.getSuggestionRefFromContent();

    if (
      !this.context.DataManager ||
      !this.context.DataManager.selection ||
      !this.context.contentManipulator ||
      !this.actionContext
    ) {
      throw new Error('Invalid Context');
    }

    const range = this.actionContext.range;

    const link = NodeUtils.firstOfTypeByPath(this.actionContext.baseData, range.start.p, 'link');

    if (link && link.path) {
      const length = link.data.childNodes?.length || 0;

      let clonedData: Editor.Data.Node.Data[] = [];

      if (length) {
        clonedData = NodeUtils.cloneData(
          this.actionContext.baseData,
          [...link.path, 'childNodes', 0],
          [...link.path, 'childNodes', length],
        );
      }

      let startLink = [...link.path];
      let endLink = [...link.path];
      let offset = Number(endLink[endLink.length - 1]);
      if (!isNaN(offset)) {
        endLink[endLink.length - 1] = offset + 1;
      }

      range.updateRangePositions(
        { b: range.start.b, p: startLink },
        { b: range.end.b, p: endLink },
      );

      this.context.contentManipulator.removeContent(this.actionContext, {
        selectionDirection: 'forward',
        mergeText: false,
      });

      if (clonedData.length) {
        this.actionContext.refreshBaseData();

        // normalize text selection
        SelectionFixer.normalizeTextSelection(
          range,
          {
            suggestionMode: this.context.editionMode === 'SUGGESTIONS',
          },
          this.context.DataManager,
        );

        for (let i = 0; i < clonedData.length; i++) {
          this.context.contentManipulator.insertContent(
            this.actionContext,
            range.start.p,
            clonedData[i],
          );
        }
      }
    }

    this.handleSuggestionsUpdate();

    this.applySelection();

    this.createPatch();
  }
}
