import { useAnnotationBox, useAnnotationContext } from './AnnotationContext';
import AnnotationToggle from './AnnotationToggle';
import styles from './Annotation.module.scss';
import { useScrollToAnnotationElement, useSelector } from '_common/hooks';
import { PageViewport } from 'pdfjs-dist';
import { useMemo } from 'react';

type IconAnnotationProps = {
  viewport: PageViewport;
};

const TOGGLE_SIZE = 32; //toggle size doesnt change with zoom
const PADDING = 8;

const IconAnnotation = ({ viewport }: IconAnnotationProps) => {
  const { isSelected, annotation } = useAnnotationContext();
  const { left, bottom, height } = useAnnotationBox();
  useScrollToAnnotationElement(annotation, isSelected);

  const started = useSelector((state) => state.onboarding.started.dopdf);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const interactions = useSelector((state) => state.onboarding.interactions);

  const remainOpen = useMemo(() => {
    return !!(
      started &&
      interactions.pdf_annotation_save &&
      annotation.id === pulseData.commentId &&
      !actionsCompleted.pdf_comments_createComment
    );
  }, [started, interactions, actionsCompleted, pulseData]);

  return (
    <div
      className={styles.base}
      style={{
        left: Math.min(left - PADDING, viewport.width - TOGGLE_SIZE - PADDING),
        top: Math.min(viewport.height - bottom - height, viewport.height - TOGGLE_SIZE),
      }}
      id={annotation.id}
      data-testid={`${annotation.subtype}-${annotation.id}`}
    >
      <AnnotationToggle isOpen={isSelected || remainOpen} />
    </div>
  );
};

export default IconAnnotation;
