import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Icon } from 'dodoc-design-system';

import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

import { SearchUser, UserCard } from '_common/components';

import styles from './TaskWatchersModal.module.scss';

const TaskWatchersModal = () => {
  const intl = useIntl();

  const [selectedWatchers, setSelectedWatchers] = useState<
    {
      value: string;
      label: string;
      type: string;
    }[]
  >([]);

  const modalData = useModalData('TaskWatchersModal');

  const possibleWatchers = useMemo(() => {
    if (!modalData) {
      return [];
    }

    const { watchers, collaborators, assigneeId, authorId } = modalData;

    return collaborators
      .filter(
        (id) => id !== assigneeId && id !== authorId && !watchers.some((watcher) => watcher === id),
      )
      .map((id) => ({ id, type: 'user' }));
  }, [modalData]);

  if (!modalData) {
    return null;
  }

  const { removeWatcher, addWatcher, canEditWatch, watchers, authorId, assigneeId } = modalData;

  const close = () => {
    closeModal('TaskWatchersModal');
  };

  return (
    <Modal open onClose={close} width="60rem" testId="task-watch">
      <Modal.Header onClose={close}>
        <FormattedMessage id="WATCH_OPTION" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <SearchUser
          escapeClearsValue
          value={selectedWatchers}
          onChange={(users, actionMeta) => {
            setSelectedWatchers(users);
            if (actionMeta?.action === 'select-option' && actionMeta?.option) {
              addWatcher(actionMeta.option.value);
            } else if (actionMeta?.action === 'deselect-option' && actionMeta.option) {
              removeWatcher(actionMeta.option.value);
            } else if (actionMeta?.action === 'remove-value' && actionMeta.removedValue) {
              removeWatcher(actionMeta.removedValue.value);
            } else if (actionMeta?.action === 'clear' && actionMeta.removedValues) {
              actionMeta.removedValues.forEach((removedWatcher) => {
                removeWatcher(removedWatcher.value);
              });
            }
          }}
          size="medium"
          placeholder={intl.formatMessage({ id: 'SEARCH_USERS_WHO_ARE_WATCHING_THIS_TASK' })}
          width="100%"
          menuPosition="fixed"
          options={possibleWatchers}
          editor
          isMulti
          disabled={!canEditWatch}
          searchable
          onMenuClose={() => {
            setSelectedWatchers([]);
          }}
          testId="task-watch"
        />
        <div className={styles.list}>
          <div className={styles.row}>
            <UserCard userId={authorId ?? ''} />
            <div className={styles.user_label}>
              <FormattedMessage id="TASK_CREATOR" />
            </div>
          </div>
          {assigneeId && (
            <div className={styles.row}>
              <UserCard userId={assigneeId} />
              <div className={styles.user_label}>
                <FormattedMessage id="TASK_ASSIGNEE" />
              </div>
            </div>
          )}
          {watchers &&
            watchers.length > 0 &&
            watchers
              .filter((id: UserId) => id !== assigneeId && id !== authorId)
              .map((id: UserId) => (
                <div className={styles.row} key={id}>
                  <UserCard userId={id} />
                  <div
                    style={{ cursor: canEditWatch ? 'pointer' : 'inherit' }}
                    onClick={() => {
                      if (canEditWatch) {
                        removeWatcher(id);
                      }
                    }}
                  >
                    <Icon icon="CloseGrey" size={24} disabled={!canEditWatch} />
                  </div>
                </div>
              ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="task-watch-modal-done-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskWatchersModal;
