import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { navigateToMyFiles } from 'router/history';

import { ErrorView, IntlErrorBoundary, Sidebar } from '_common/components';

import SearchPage from './pages/SearchPage/SearchPage';

import styles from './SearchContainer.module.scss';

const SearchContainer = () => {
  const match = useRouteMatch();

  return (
    <div className={styles.root}>
      <Sidebar />

      <Switch>
        <Route
          exact
          path={match.path}
          render={() => (
            <IntlErrorBoundary size="large" margin="21rem auto 0 auto">
              <SearchPage />
            </IntlErrorBoundary>
          )}
        />
        <Route path="*">
          <ErrorView
            error={{
              status: 404,
            }}
            onClick={navigateToMyFiles}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default SearchContainer;
