import { faker } from '@faker-js/faker';
import { createMockedUserId } from './common';
import { parseProfile } from 'Auth/redux/utils';

export function createMockedCurrentUser(overwrites?: Partial<UserProfile>): UserProfile {
  return {
    is_superuser: false,
    is_admin: false,
    onboarding: {
      explorer: 'ending',
      editor: 'ending',
      dopdf: 'ending',
      presentation: 'ending',
      explorer_journey: 'personalGeneralUser',
      editor_journey: 'general',
      dopdf_journey: 'generalUser',
    },
    other: {
      password_validators: [
        { min_length: 8 },
        { contains_lower_case: true },
        { contains_upper_case: true },
        { contains_symbol: true },
      ],
    },
    has_avatar: false,
    ...overwrites,
    profile: createMockedUserProfile(overwrites?.profile),
  };
}

export function createMockedUserProfile(
  overwrites?: Partial<UserProfile['profile']>,
): UserProfile['profile'] {
  const firstName = overwrites?.first_name || faker.name.firstName();
  const lastName = overwrites?.last_name || faker.name.lastName();
  const email = overwrites?.email || faker.internet.email(firstName, lastName);
  return {
    id: 'current_user',
    username: faker.internet.userName(firstName, lastName),
    email: email,
    last_name: lastName,
    first_name: firstName,
    company: faker.company.name(),
    department: faker.name.jobArea(),
    language: 'en-US',
    timezone: 'UTC',
    date_format: 'dm',
    time_format: '24h',
    ...overwrites,
  };
}

export function createMockedUserPublicProfile(
  overwrites?: Partial<UserPublicProfile>,
): UserPublicProfile {
  const firstName = overwrites?.first_name ?? faker.name.firstName();
  const lastName = overwrites?.last_name ?? faker.name.lastName();
  const email = overwrites?.email || faker.internet.email(firstName, lastName);

  return {
    id: createMockedUserId(),
    username: faker.internet.userName(firstName, lastName),
    email: email,
    last_name: lastName,
    first_name: firstName,
    is_superuser: false,
    has_avatar: false,
    ...overwrites,
  };
}

export function createMockedParsedUserPublicProfile(
  overwrites?: Partial<ParsedProfile<UserPublicProfile>>,
): ParsedProfile<UserPublicProfile> {
  const userPublicProfile = createMockedUserPublicProfile(overwrites);

  return parseProfile(userPublicProfile);
}

function createMockedUserNotificationsSettings(
  overwrites?: Partial<ApiSchemas['UserSettingsSchema']['notifications']>,
): ApiSchemas['UserSettingsSchema']['notifications'] {
  return {
    profile: {
      email: true, //this property cant be changed
      app: false, //this property cant be changed
    },
    obj_deadline: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    obj_status: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    obj_permissions: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    container_updates: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    node_status: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    comments: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    tasks: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    suggestions: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    mentions: {
      email: faker.datatype.boolean(),
      app: true, //this property cant be changed
    },
    pdf_annotations: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    pdf_tasks: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    presentation_comments: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    presentation_tasks: {
      email: faker.datatype.boolean(),
      app: faker.datatype.boolean(),
    },
    ...overwrites,
  };
}

function createMockedUserWorkingHoursSettings(
  overwrites?: Partial<ApiSchemas['UserSettingsSchema']['working_hours']>,
): ApiSchemas['UserSettingsSchema']['working_hours'] {
  return {
    active: false,
    days: {
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      friday: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      monday: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      saturday: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      sunday: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      thursday: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      tuesday: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['days'] should allow null
      wednesday: null,
    },
    time: {
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['time'] should allow null
      end: null,
      //@ts-expect-error ApiSchemas['UserSettingsSchema']['working_hours']['time'] should allow null
      start: null,
    },
    ...overwrites,
  };
}

function createMockedUserTablesSettings(
  overwrites?: Partial<ApiSchemas['UserSettingsSchema']['tables']>,
): ApiSchemas['UserSettingsSchema']['tables'] {
  return {
    explorer: {
      name: true,
      due_date: true,
      status: true,
      'time.modification': true,
      shared_with: true,
      owner: false,
      'time.creation': false,
      ...overwrites?.explorer,
    },
    search: {
      name: true,
      due_date: true,
      status: true,
      'time.modification': true,
      shared_with: true,
      owner: false,
      'time.creation': false,
      ...overwrites?.search,
    },
  };
}

export function createMockedUserSettings(
  overwrites?: Partial<ApiSchemas['UserSettingsSchema']>,
): ApiSchemas['UserSettingsSchema'] {
  return {
    notifications: createMockedUserNotificationsSettings(overwrites?.notifications),
    working_hours: createMockedUserWorkingHoursSettings(overwrites?.working_hours),
    tables: createMockedUserTablesSettings(overwrites?.tables),
    ...overwrites,
  };
}
