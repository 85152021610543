import { FormattedMessage } from 'react-intl';

import styles from './Label.module.scss';

type LabelProps = {
  messageId: string;
};

const Label = ({ messageId }: LabelProps) => {
  return (
    <div className={styles.root}>
      <FormattedMessage id={messageId} />
    </div>
  );
};

export default Label;
