import { Command } from '../Command';
import { PathUtils } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { UpdateImageSizeOperation } from '../../Operations/ImageOperations/UpdateImageSizeOperation';

export class UpdateImageSizeCommand extends Command {
  imageId: string;
  height: number;
  width: number;

  constructor(context: Editor.Edition.Context, imageId: string, height: number, width: number) {
    super(context);

    this.imageId = imageId;
    this.height = height;
    this.width = width;
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new Error('Element is not editable');
    }

    const imageInfo = this.actionContext.baseModel.getChildInfoById(this.imageId);

    if (PathUtils.isValidSelectionPath(imageInfo.path)) {
      if (NodeUtils.isImageData(imageInfo.data)) {
        this.applyOperations(this.actionContext.baseModel, imageInfo.path, imageInfo.data);
      }
    }

    this.createPatch();
  }

  applyOperations(
    baseModel: Editor.Data.Node.Model,
    path: Editor.Selection.Path,
    imageData: Editor.Data.Node.ImageData,
  ) {
    const operation = new UpdateImageSizeOperation(
      baseModel,
      path,
      imageData,
      this.height,
      this.width,
    );

    operation.apply();
  }
}
