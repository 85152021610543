import { RouteProps } from 'react-router';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from '_common/hooks';

const PrivateRoute = ({ exact, path, children }: RouteProps) => {
  const location = useLocation();
  const authenticated = useSelector((state) => state.auth.authenticated);
  const forcePasswordUpdate = useSelector((state) => state.auth.isUpdatePasswordForced);
  const renderRoute = () => {
    if (!authenticated || (forcePasswordUpdate && !path?.includes('updatepassword'))) {
      return (
        <Redirect
          to={{
            pathname: '/auth/signin',
            state: { from: location },
          }}
        />
      );
    }
    return children;
  };
  return (
    <Route exact={exact} path={path}>
      {renderRoute()}
    </Route>
  );
};

export default PrivateRoute;
