import { ChangeEventHandler, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Select } from 'dodoc-design-system';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useDebounce, useDispatch, useSelector } from '_common/hooks';

import { updateModal } from '_common/modals/ModalsSlice';
import { selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import { searchCitations } from 'Editor/redux/CitationsSlice';

import CitationsList from '../CitationsList/CitationsList';
import CitationInformation from '../CitationInformation/CitationInformation';

import styles from './SearchCitations.module.scss';
import { clearSelection } from '_common/components/Table/TableSlice';

const SearchCitations = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const documentId = useSelector((state) => state.editor.status.documentId);
  const { loading, ...citations } = useSelector((state) => state.modals.CitationsModal.search);
  const selected = useSelector((state) => state.table.selected);

  const [target, setTarget] = useState<SelectOption>({ value: 'pubmed', label: 'PubMed' });
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (debouncedQuery === '') {
      dispatch(
        updateModal({
          modal: 'CitationsModal',
          data: {
            search: {
              loading: false,
              list: [],
              dict: {},
              total: 0,
            },
          },
        }),
      );
      dispatch(clearSelection());
      return;
    }

    dispatch(searchCitations({ documentId, query: debouncedQuery, source: target.value }));
  }, [debouncedQuery, target]);

  const handleQueryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);
  };

  const handleTargetChange = (target: SelectOption) => {
    setTarget(target);
  };

  return (
    <div className={styles.root}>
      <div className={styles.search}>
        <div className={styles.inputs}>
          <Input
            prefix="NavSearchBlue"
            name="query"
            value={query}
            onChange={handleQueryChange}
            placeholder={intl.formatMessage({ id: 'global.search' })}
            disabled={isReadOnlyMode}
            width="100%"
            margin="0 2rem 0 0"
            size="medium"
            testId="search-citations"
          />
          <Select
            clearable={false}
            size="medium"
            width="25rem"
            onChange={handleTargetChange}
            value={target}
            defaultValue={{ value: 'pubmed', label: 'PubMed' }}
            options={
              [
                { value: 'pubmed', label: 'PubMed' },
                { value: 'scopus', label: 'Scopus' },
              ] as SelectOption[]
            }
            disabled={isReadOnlyMode}
            testId="target"
          />
        </div>
        <CitationsList citations={citations} loading={citations.loading || loading} hasFilters />
        <div className={styles.results}>
          {!citations.loading && !loading && debouncedQuery && (
            <FormattedMessage
              id={
                +citations.total > citations.list.length
                  ? 'editor.modals.citations.referencesFoundWithTotal'
                  : 'editor.modals.citations.referencesFound'
              }
              values={{
                count: citations.list.length,
                target: target.label,
                query: debouncedQuery,
                total: citations.total,
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.information}>
        <CitationInformation disabled citationId={Object.keys(selected)[0]} />
      </div>
    </div>
  );
};

export default SearchCitations;
