import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from '_common/hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Button, Input, InputField } from 'dodoc-design-system';
import { closeAndResetModal } from '_common/modals/ModalsSlice';
import EditorManager from 'Editor/services/EditorManager';

const RenameDocumentStylesModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open.RenameDocumentStylesModal);
  const name = useSelector((state) => state.modals.RenameDocumentStylesModal.name);
  const documentStyles = useSelector((state) => state.editor.styles);
  const styleId = useSelector((state) => state.modals.RenameDocumentStylesModal.styleId);
  const [newName, setName] = useState('');
  const [validations, setValidations] = useState<{ newName?: string }>({});
  const newDocumentStyleName = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (newDocumentStyleName.current) {
      newDocumentStyleName.current.focus();
    }
  }, []);

  const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const errors = {
      newName: '',
    };
    const name = e.target.value;
    setName(e.target.value);
    if (newName.length > 256) {
      errors.newName = intl.formatMessage({ id: 'PARAGRAPH_STYLE_ERROR' }, { length: 256 });
    }
    if (
      Object.values(documentStyles.data)
        .map((style) => style.n)
        .includes(name)
    ) {
      errors.newName = intl.formatMessage({
        id: 'STYLE_NAME_ALREADY_EXISTS',
      });
    }

    if (Object.keys(errors).length > 0) {
      setValidations(errors);
    } else {
      setValidations(errors);
    }
  };

  const handleRename = () => {
    const errors: any = {};

    if (newName.length > 256) {
      errors.newName = intl.formatMessage({ id: 'PARAGRAPH_STYLE_ERROR' }, { length: 256 });
    }

    if (Object.keys(errors).length > 0) {
      setValidations(errors);
    } else {
      const manager = EditorManager.getInstance();
      if (manager) {
        manager.renameDocumentStyle(styleId, newName);
      }
    }

    close();
  };

  const close = () => {
    setName('');
    dispatch(closeAndResetModal('RenameDocumentStylesModal'));
  };

  return (
    <Modal width="75rem" open={!!isOpen} onClose={close} testId="rename-document-styles">
      <Modal.Header onClose={close}>
        <FormattedMessage id="RENAME_PARAGRAPH_STYLE" values={{ name }} />
      </Modal.Header>

      <Modal.Body>
        <InputField
          size="large"
          feedback={validations.newName ? validations.newName : undefined}
          label={intl.formatMessage({ id: 'PARAGRAPH_STYLE_LABEL' })}
          testId="paragraph-style-field"
        >
          <Input
            value={newName}
            placeholder={name}
            onChange={onNameChanged}
            error={!!validations.newName}
            ref={newDocumentStyleName}
            onEnterKey={validations.newName === '' ? handleRename : undefined}
            size="large"
            testId="paragraph-style"
          />
        </InputField>
      </Modal.Body>

      <Modal.Footer>
        <Button size="medium" onClick={close} testId="rename-document-styles-close-button">
          <FormattedMessage id="global.close" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleRename}
          disabled={newName === '' || !!validations.newName}
          testId="rename-document-styles-submit-button"
        >
          <FormattedMessage id="global.rename" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameDocumentStylesModal;
