import qs from 'qs';
import BaseService from './BaseService';
import type { BaseServiceConstructor } from './BaseService';
import getConfig from 'dodoc.config';
import { paths } from '_types/authority';

export default class AuthService extends BaseService {
  // -----------------------------------------------------------------
  // auth endpoint
  readonly LOGIN = '/api/authority/login';
  readonly LOGIN_FIRST = '/api/authority/login/first';
  readonly RECOVER_VALIDATORS = (hash: string) =>
    `/api/authority/account/password/recover/${hash}/validators`;
  readonly UNLOCK_ACCOUNT = (hash: string) => `/api/authority/account/unlock/${hash}`;
  readonly ACTIVATE_DEVICE = '/api/authority/device/activate';
  readonly RESEND_CODE = '/api/authority/device/code/resend';
  readonly PASSWORD_RECOVER = (hash: string) => `/api/authority/account/password/recover/${hash}`;
  readonly USER_AUTOCOMPLETE = (query: string) => `/api/authority/user/autocomplete?query=${query}`;
  readonly EDIT_PROFILE = '/api/authority/user/profile/edit';
  readonly LIST_SESSIONS = '/api/authority/token/list';
  readonly LIST_DEVICES = '/api/authority/device/list';
  readonly LIST_CONNECTIONS = '/api/authority/authenticator/connection/list';
  readonly REMOVE_CONNECTION = (connection: string) =>
    `/api/authority/authenticator/connection/${connection}/delete`;
  readonly LIST_SERVICES = '/api/authority/authenticator/service/list';
  readonly PASSWORD_CHANGE = '/api/authority/user/password/change';
  readonly PASSWORD_EXPIRED_CHANGE = '/api/authority/user/password/expired/change';
  readonly REMOVE_SESSION = (session: string) => `/api/authority/token/${session}/delete`;
  readonly REMOVE_DEVICE = (device: string) => `/api/authority/device/${device}/delete`;
  readonly PUBLIC_PROFILES = '/api/authority/user/profiles';
  readonly CHANGE_EMAIL = '/api/authority/user/email/change';
  readonly GET_EMAIL_CHANGE_STATUS = '/api/authority/user/email/change/status';
  readonly RESEND_NEW_EMAIL = '/api/authority/user/email/change/resend/new';

  readonly LIST_USERS = '/api/authority/user/list';
  readonly CREATE_USER = '/api/authority/user/create';
  readonly EDIT_USER = (userId: string) => `/api/authority/user/profile/${userId}/edit/admin`;
  readonly EDIT_USER_STATE = (userId: string) => `/api/authority/user/${userId}/activate`;
  readonly REMOVE_USER = (userId: string) => `/api/authority/user/${userId}/remove`;
  readonly VALIDATE_USERS_CSV = `/api/authority/user/validate/csv`;
  readonly CREATE_USERS_CSV = `/api/authority/user/create/csv`;
  readonly LIST_USER_TOKENS = (userId: string) => `/api/authority/${userId}/token/list`;
  readonly EXPIRE_USER_TOKEN = ({ userId, tokenId }: { userId: string; tokenId: string }) =>
    `/api/authority/${userId}/${tokenId}/expire`;

  constructor(params?: Omit<BaseServiceConstructor, 'baseURL'> & { baseURL?: string }) {
    super({ baseURL: getConfig().authority, ...params });
  }

  signIn(
    params: paths['/api/authority/login']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<paths['/api/authority/login']['post']['responses']['200']>(
      this.LOGIN,
      qs.stringify(params),
    );
  }

  firstLogin(
    params: paths['/api/authority/login/first']['post']['requestBody']['content']['application/json'],
  ) {
    return this.post<paths['/api/authority/login/first']['post']['responses']['200']>(
      this.LOGIN_FIRST,
      qs.stringify(params),
    );
  }

  getLogin(token?: paths['/api/authority/login']['get']['parameters']['header']['X-DoDOC-Tenant']) {
    return this.get<paths['/api/authority/login']['get']['responses']['200']>(this.LOGIN, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  unlockAccount(
    hash: paths['/api/authority/account/unlock/{token}']['post']['parameters']['path']['token'],
  ) {
    return this.post<paths['/api/authority/account/unlock/{token}']['post']['responses']['200']>(
      this.UNLOCK_ACCOUNT(hash),
    );
  }

  activateDevice(
    values: paths['/api/authority/device/activate']['post']['requestBody']['content']['application/json'],
  ) {
    return this.post<paths['/api/authority/device/activate']['post']['responses']['200']>(
      this.ACTIVATE_DEVICE,
      qs.stringify(values),
    );
  }

  resendCode() {
    return this.post<paths['/api/authority/device/code/resend']['post']['responses']['200']>(
      this.RESEND_CODE,
    );
  }

  changePassword(
    hash: paths['/api/authority/account/password/recover/{hash}']['post']['parameters']['path']['hash'],
    params: MyAny,
  ) {
    return this.post<
      paths['/api/authority/account/password/recover/{hash}']['post']['responses']['200']
    >(this.PASSWORD_RECOVER(hash), qs.stringify(params));
  }

  getUserAutocomplete(query: MyAny) {
    return this.get<
      paths['/api/authority/user/autocomplete']['get']['responses']['200']['content']['application/json']
    >(this.USER_AUTOCOMPLETE(query));
  }

  editProfile(
    parameters: paths['/api/authority/user/profile/edit']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<paths['/api/authority/user/profile/edit']['post']['responses']['200']>(
      this.EDIT_PROFILE,
      qs.stringify(parameters),
    );
  }

  loadDevices() {
    return this.get<
      paths['/api/authority/device/list']['post']['responses']['200']['content']['application/json']
    >(this.LIST_DEVICES);
  }

  loadSessions() {
    return this.get<
      paths['/api/authority/token/list']['post']['responses']['200']['content']['application/json']
    >(this.LIST_SESSIONS);
  }

  loadConnections() {
    return this.get<
      paths['/api/authority/authenticator/connection/list']['get']['responses']['200']['content']['application/json']
    >(this.LIST_CONNECTIONS);
  }

  removeConnection(
    connection: paths['/api/authority/authenticator/connection/{connection_id}/delete']['get']['parameters']['path']['connection_id'],
  ) {
    return this.post<
      paths['/api/authority/authenticator/connection/{connection_id}/delete']['get']['responses']['200']
    >(this.REMOVE_CONNECTION(connection));
  }

  loadServices() {
    return this.get<
      paths['/api/authority/authenticator/service/list']['get']['responses']['200']['content']['application/json']
    >(this.LIST_SERVICES);
  }

  passwordChange(
    body: paths['/api/authority/user/password/change']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<paths['/api/authority/user/password/change']['post']['responses']['200']>(
      this.PASSWORD_CHANGE,
      qs.stringify(body),
    );
  }

  removeSession(
    session: paths['/api/authority/token/{token_id}/delete']['post']['parameters']['path']['token_id'],
  ) {
    return this.post<paths['/api/authority/token/{token_id}/delete']['post']['responses']['200']>(
      this.REMOVE_SESSION(session),
    );
  }

  removeDevice(
    device: paths['/api/authority/device/{device_id}/delete']['post']['parameters']['path']['device_id'],
  ) {
    return this.post<paths['/api/authority/device/{device_id}/delete']['post']['responses']['200']>(
      this.REMOVE_DEVICE(device),
    );
  }

  changeEmail(newEmail: string) {
    return this.post<paths['/api/authority/user/email/change']['post']['responses']['200']>(
      this.CHANGE_EMAIL,
      qs.stringify({ email: newEmail }),
    );
  }

  resendNewEmail() {
    return this.post<
      paths['/api/authority/user/email/change/resend/new']['get']['responses']['200']
    >(this.RESEND_NEW_EMAIL);
  }

  checkEmailStatus() {
    return this.get<paths['/api/authority/user/email/change/status']['get']['responses']['200']>(
      this.GET_EMAIL_CHANGE_STATUS,
    );
  }

  passwordExpiredChange(
    newPassword: paths['/api/authority/user/password/expired/change']['post']['requestBody']['content']['multipart/form-data']['new'],
  ) {
    return this.post<
      paths['/api/authority/user/password/expired/change']['post']['responses']['200']
    >(this.PASSWORD_EXPIRED_CHANGE, qs.stringify({ new: newPassword }));
  }

  getRecoverValidators(
    hash: paths['/api/authority/account/password/recover/{hash}/validators']['post']['parameters']['path']['hash'],
  ) {
    return this.get<
      paths['/api/authority/account/password/recover/{hash}/validators']['post']['responses']['200']['content']['application/json']
    >(this.RECOVER_VALIDATORS(hash));
  }

  getUsersList(params: MyAny) {
    return this.get(this.LIST_USERS, { params });
  }

  createUser(params: MyAny) {
    return this.post(this.CREATE_USER, qs.stringify(params));
  }

  editUser(userId: UserId, params: MyAny) {
    return this.post(this.EDIT_USER(userId), qs.stringify(params));
  }

  editUserState(userId: UserId, params: MyAny) {
    return this.post(this.EDIT_USER_STATE(userId), qs.stringify(params));
  }

  removeUser(userId: UserId) {
    return this.post(this.REMOVE_USER(userId));
  }

  validateUsersCsv(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(this.VALIDATE_USERS_CSV, formData);
  }

  createUsersCsv(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(this.CREATE_USERS_CSV, formData);
  }

  getUserTokens(userId: UserId, params: MyAny) {
    return this.get(this.LIST_USER_TOKENS(userId), { params });
  }

  removeUserActiveSession({ userId, tokenId }: { userId: UserId; tokenId: string }) {
    return this.post(this.EXPIRE_USER_TOKEN({ userId, tokenId }));
  }

  sendHelpTicket(data: { user_name: string; email: string; message: string }) {
    return this.post('/api/authority/help/ticket', qs.stringify(data));
  }
}
