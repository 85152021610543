import GroupDetails from '../Details/GroupDetails';
import Description from '../Description/Description';
import Tags from '../Tags/Tags';

type GroupPanelProps = {
  object: Group;
};

const GroupPanel = ({ object }: GroupPanelProps) => {
  const canEdit = object.user_permissions.some((permission) =>
    ['superuser', 'admin', 'owner'].includes(permission),
  );

  return (
    <>
      <GroupDetails />
      <Description canEdit={canEdit} />
      <Tags />
    </>
  );
};

export default GroupPanel;
