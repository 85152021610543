export default class ErrorElementNotEditable extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = 'ErrorElementNotEditable';
    this.message = 'Element is not editable!';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorElementNotEditable);
    }
  }
}
