import { useSelector } from '_common/hooks';
import EditableCommentCard from './EditableCommentCard/EditableCommentCard';
import ViewCommentCard from './ViewCommentCard/ViewCommentCard';
import { useEffect, useRef, useState } from 'react';

export type CommentCardProps = {
  id?: string;
  sidebar?: boolean;
} & (
  | { isTemporary: true; comment?: undefined }
  | { isTemporary?: false | undefined; comment: Presentation.Data.Comment }
);

const CommentCard = ({ id, comment, sidebar, isTemporary }: CommentCardProps) => {
  const [editMode, setEditMode] = useState(isTemporary);
  const selected = useSelector((state) => state.presentation.general.selectedCard);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sidebar && selected === comment?.id && cardRef.current) {
      cardRef.current.scrollIntoView({ block: 'center' });
    }
  }, [selected, sidebar]);

  if (editMode) {
    if (isTemporary) {
      return (
        <EditableCommentCard
          id={id}
          sidebar={sidebar}
          testId="editable-comment-card"
          setEditMode={setEditMode}
          isTemporary={isTemporary}
          ref={cardRef}
        />
      );
    } else {
      return (
        <EditableCommentCard
          id={id}
          sidebar={sidebar}
          testId={comment?.id}
          comment={comment}
          setEditMode={setEditMode}
          ref={cardRef}
        />
      );
    }
  }

  if (comment) {
    return (
      <ViewCommentCard
        id={id}
        comment={comment}
        sidebar={sidebar}
        testId={comment.id}
        setEditMode={setEditMode}
        ref={cardRef}
      />
    );
  }
  return null;
};

export default CommentCard;
