import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { setNodeToFocus } from 'Editor/redux/EditorStatusSlice';
import { setAnnotationToFocus } from 'PDF/redux/PDFGeneralSlice';
import { useDispatch } from './redux';

type ElementType = 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';

const useFocusElementByUrl = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace('#', '').split('=');
    if (hash && hash.length === 2) {
      const objectType = hash[0];
      const objectId = hash[1];
      if (objectType && objectId) {
        if (typeof objectType === 'string' && typeof objectId === 'string') {
          const allowedTypes: ElementType[] = [
            'comment',
            'suggestion',
            'task',
            'node',
            'annotation',
          ];

          if (allowedTypes.includes(objectType as ElementType)) {
            if (objectType === 'annotation') {
              dispatch(
                setAnnotationToFocus({
                  documentId: id,
                  objectId: objectId,
                }),
              );
            } else {
              dispatch(
                setNodeToFocus({
                  documentId: id,
                  objectType: objectType as Exclude<ElementType, 'annotation'>,
                  objectId: objectId,
                }),
              );
            }

            //Clear url parameters
            window.history.pushState(null, '', location.pathname);
          }
        }
      }
    }
  }, [location, id]);
};

export default useFocusElementByUrl;
