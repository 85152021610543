export default class ErrorPasteParserNotFound extends Error {
  constructor(...args) {
    super(...args);
    this.name = 'ErrorPasteParserNotFound';
    this.message = 'Parser not found!';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorPasteParserNotFound);
    }
  }
}
