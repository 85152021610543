/* eslint-disable class-methods-use-this */
import { v4 as uuid } from 'uuid';
import { RealtimeObject, RealtimeOpsBuilder } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class Tasks extends RealtimeObject<Presentation.Model.Tasks.Data> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'presTasks');
  }

  private static defaultValues(
    data: Common.OnlyRequire<
      Presentation.Data.Task,
      'content' | 'dueDate' | 'anchor' | 'authorId' | 'assignee'
    >,
  ): Presentation.Data.Task {
    const watchers = [];
    watchers.push(data.authorId);
    if (data.assignee !== '') {
      watchers.push(data.assignee);
    }

    return {
      id: data.id || uuid(),
      creationDate: new Date().toISOString(),
      deletionDate: '',
      status: 'td',
      watchers,
      replies: [],
      ...data,
    };
  }

  private static replyDefaultValues(
    userAuthor: string,
    replyContent: Presentation.Model.Tasks.ContentsType['content'],
  ): Presentation.Model.Tasks.Reply {
    return {
      id: uuid(),
      creationDate: new Date().toISOString(),
      modificationDate: '',
      content: {
        content: replyContent,
        dir: 'ltr',
      },
      authorId: userAuthor,
      votes: [],
    };
  }

  get allTaskIds() {
    const data = this.selectedData();
    const slidesIds = Object.keys(data?.sld || {});
    const tasksIds: string[] = [];
    for (let index = 0; index < slidesIds.length; index++) {
      const slideId = slidesIds[index];
      tasksIds.push(...Object.keys(data?.sld?.[slideId] || {}));
    }
    return tasksIds;
  }

  getTasksData(): {
    list: string[];
    data: Record<string, Presentation.Data.Task>;
  } {
    const data = this.selectedData();
    const slidesIds = Object.keys(data?.sld || {});
    let tasksData = {};
    const taskIds: string[] = [];
    for (let index = 0; index < slidesIds.length; index++) {
      const slideId = slidesIds[index];
      const tasks = Object.keys(data?.sld?.[slideId] || {});
      taskIds.push(...tasks);
      tasksData = {
        ...tasksData,
        ...(data?.sld?.[slideId] || {}),
      };
    }
    return {
      list: taskIds,
      data: tasksData,
    };
  }

  subscribe(): Promise<RealtimeObject<Presentation.Model.Tasks.Data>> {
    return new Promise((resolve, reject) => {
      this.model.subscribe((error) => {
        if (error) {
          reject(error);
        } else {
          resolve(this);
        }
      });
    });
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as Presentation.Model.Tasks.Data;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  async addTask(
    data: Pick<Presentation.Data.Task, 'content' | 'dueDate' | 'anchor' | 'authorId' | 'assignee'>,
  ) {
    const properData = Tasks.defaultValues(data);

    if (!this.get(['sld', data.anchor[0].id])) {
      await this.apply([
        RealtimeOpsBuilder.objectInsert(
          {
            [properData.id]: properData,
          },
          ['sld', properData.anchor[0].id],
        ),
      ]);
    }
    await this.apply([
      RealtimeOpsBuilder.objectInsert(properData, ['sld', properData.anchor[0].id, properData.id]),
    ]);

    return properData.id;
  }

  editTask(
    data: Common.OnlyRequire<
      Presentation.Data.Task,
      'assignee' | 'content' | 'dueDate' | 'anchor' | 'id'
    >,
  ) {
    const anchorId = data.anchor[0].id;
    const taskId = data.id;

    const task = this.get(['sld', anchorId, taskId]);

    if (!task) {
      return Promise.resolve(this);
    }
    const ops = [];
    let props = Object.keys(data) as (keyof Presentation.Data.Task)[];

    for (let index = 0; index < props.length; index++) {
      const prop = props[index];
      if (prop === 'id' || prop === 'anchor') {
        continue;
      }
      if (task[prop] !== undefined) {
        if (data[prop] === null) {
          ops.push(RealtimeOpsBuilder.objectDelete(task[prop], ['sld', anchorId, taskId, prop]));
        } else {
          ops.push(
            RealtimeOpsBuilder.objectReplace(task[prop], data[prop], [
              'sld',
              anchorId,
              taskId,
              prop,
            ]),
          );
        }
      } else {
        if (data[prop] !== null) {
          ops.push(RealtimeOpsBuilder.objectInsert(data[prop], ['sld', anchorId, taskId, prop]));
        }
      }
    }

    if (ops.length) {
      ops.push({
        od: task.modificationDate,
        oi: new Date(),
        p: ['sld', anchorId, taskId, 'modificationDate'],
      });
      return this.apply(ops);
    }
    return Promise.resolve(this);
  }

  addWatcherToTask(slideId: string, taskId: string, userId: string) {
    const task = this.get(['sld', slideId, taskId, 'watchers']);

    if (task?.length >= 0) {
      return this.apply([
        RealtimeOpsBuilder.listInsert(userId, ['sld', slideId, taskId, 'watchers', task.length]),
      ]);
    }
    return Promise.resolve(this);
  }

  removeWatcherFromTask(slideId: string, taskId: string, userId: string) {
    const task: string[] = this.get(['sld', slideId, taskId, 'watchers']);
    const userFoundIndex = task?.indexOf(userId);
    if (userFoundIndex >= 0) {
      return this.apply([
        RealtimeOpsBuilder.listDelete(userId, ['sld', slideId, taskId, 'watchers', userFoundIndex]),
      ]);
    }
    return Promise.resolve(this);
  }

  changeTaskStatus(slideId: string, taskId: string, status: Presentation.Model.Tasks.TaskStatus) {
    if (this.get(['sld', slideId, taskId])) {
      const oldValue = this.get(['sld', slideId, taskId, 'status']);
      const newValue = status;

      return this.apply([
        RealtimeOpsBuilder.objectReplace(oldValue, newValue, ['sld', slideId, taskId, 'status']),
      ]);
    }
    return Promise.resolve(this);
  }

  replyToTask(
    slideId: string,
    taskId: string,
    authorId: string,
    replyContent: Presentation.Model.Tasks.ContentsType['content'],
  ) {
    const task: Presentation.Data.Task | undefined = this.get(['sld', slideId, taskId]);
    if (!task) {
      return Promise.resolve(this);
    }
    const reply = Tasks.replyDefaultValues(authorId, replyContent);
    return this.apply([
      RealtimeOpsBuilder.listInsert(reply, [
        'sld',
        slideId,
        taskId,
        'replies',
        task.replies.length,
      ]),
    ]);
  }

  editReplyToTask(
    slideId: string,
    taskId: string,
    replyId: string,
    replyContent: Presentation.Model.Tasks.ContentsType['content'],
  ) {
    const replies: Presentation.Model.Tasks.Reply[] = this.get(['sld', slideId, taskId, 'replies']);
    if (!replies) {
      return Promise.resolve(this);
    }
    const replyIndex = replies.findIndex((reply) => reply.id === replyId);

    if (replyIndex >= 0) {
      const newValue = replyContent;
      const oldValue = replies[replyIndex].content.content;

      return this.apply([
        RealtimeOpsBuilder.objectReplace(oldValue, newValue, [
          'sld',
          slideId,
          taskId,
          'replies',
          replyIndex,
          'content',
          'content',
        ]),
      ]);
    }

    return Promise.resolve(this);
  }

  deleteReplyToTask(slideId: string, taskId: string, replyId: string) {
    const replies: Presentation.Model.Tasks.Reply[] = this.get(['sld', slideId, taskId, 'replies']);
    if (!replies) {
      return Promise.resolve(this);
    }
    const replyIndex = replies.findIndex((reply) => reply.id === replyId);

    if (replyIndex >= 0) {
      const oldValue = replies[replyIndex];

      return this.apply([
        RealtimeOpsBuilder.listDelete(oldValue, ['sld', slideId, taskId, 'replies', replyIndex]),
      ]);
    }

    return Promise.resolve(this);
  }

  voteReplyToTask(
    slideId: string,
    taskId: string,
    authorId: string,
    replyId: Presentation.Model.Tasks.Reply['id'],
    value: Presentation.Model.Tasks.Vote['value'],
  ) {
    const task = this.get(['sld', slideId, taskId]);

    if (!task) {
      return Promise.resolve(this);
    }

    const replyIndex = task.replies.findIndex(
      (reply: Presentation.Model.Tasks.Reply) => reply.id === replyId,
    );

    if (replyIndex === -1) {
      return Promise.resolve(this);
    }

    const reply: Presentation.Model.Tasks.Reply = task.replies[replyIndex];

    const voteIndex = reply.votes.findIndex(
      (vote: Presentation.Model.Tasks.Vote) => vote.user === authorId,
    );
    if (voteIndex === -1) {
      const vote: Presentation.Model.Tasks.Vote = {
        user: authorId,
        value: value,
        time: new Date().toISOString(),
      };
      return this.apply([
        RealtimeOpsBuilder.listInsert(vote, [
          'sld',
          slideId,
          taskId,
          'replies',
          replyIndex,
          'votes',
          reply.votes.length,
        ]),
      ]);
    } else {
      const oldVote: Presentation.Model.Tasks.Vote = reply.votes[voteIndex];
      const newVote: Presentation.Model.Tasks.Vote = JSON.parse(
        JSON.stringify(reply.votes[voteIndex]),
      );
      newVote.value = value;
      return this.apply([
        RealtimeOpsBuilder.listReplace(oldVote, newVote, [
          'sld',
          slideId,
          taskId,
          'replies',
          replyIndex,
          'votes',
          voteIndex,
        ]),
      ]);
    }
  }

  deleteTask(slideId: string, taskId: string) {
    const task = this.get(['sld', slideId, taskId]);
    if (!task) {
      return Promise.resolve(this);
    }

    const newValue = new Date().toISOString();
    const oldValue = this.get(['sld', slideId, taskId, 'deletionDate']);

    return this.apply([
      RealtimeOpsBuilder.objectReplace(oldValue, newValue, [
        'sld',
        slideId,
        taskId,
        'deletionDate',
      ]),
    ]);
  }
}
