import { MoreOptions, Padding, Size, Style, TableAlignment, TextAlignment } from '../Section';
import { EMPTY_WIDTH } from '../utils';

import styles from './TabContent.module.scss';

const Table = ({ unsavedData, updateData }: TabContentProps) => {
  const handleUpdateData: SectionProps['updateData'] = (updateDataProps) => {
    updateData({ element: 'TABLE', ...updateDataProps });
  };

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <Size width={unsavedData?.width ?? EMPTY_WIDTH} updateData={handleUpdateData} />
        <Padding padding={unsavedData?.padding} updateData={handleUpdateData} />
        <TableAlignment
          alignment={unsavedData?.alignment}
          leftIndentation={unsavedData?.leftIndentation}
          updateData={handleUpdateData}
        />
      </div>
      <div className={styles.column}>
        <TextAlignment
          textAlignment={unsavedData?.textAlignment}
          verticalAlignment={unsavedData?.verticalAlignment}
          updateData={handleUpdateData}
        />
        <Style
          background={unsavedData?.background}
          border={unsavedData?.border}
          updateData={handleUpdateData}
        />
        <MoreOptions autoResize={unsavedData?.autoResize} updateData={handleUpdateData} />
      </div>
    </div>
  );
};

export default Table;
