import { MouseEventHandler, useState } from 'react';
import { Icon } from 'dodoc-design-system';
import { CSSTransition } from 'react-transition-group';

import { useDispatch, useSelector } from '_common/hooks';
import { setSidebarAnimatedState, toggleSidebarExpanded } from './SidebarSlice';

import Menu from './Menu/Menu';
import SidebarContent from './SidebarContent';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';

import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const dispatch = useDispatch();

  const sidebarExpanded = useSelector((state) => state.sidebar.expanded);
  const sidebarAnimated = useSelector((state) => state.sidebar.animated);
  const sidebarMenuOpen = useSelector((state) => state.sidebar.menu.isOpen);
  const appLoading = useSelector((state) => state.app.loading.isOpen);

  const [expanderVisible, setExpanderVisible] = useState(true);

  const handleToggleSidebar: MouseEventHandler<HTMLDivElement> = (e) => {
    setExpanderVisible(false);
    setTimeout(() => {
      setExpanderVisible(true);
    }, 250);

    dispatch(toggleSidebarExpanded());

    e.stopPropagation();
  };

  const onEnteredAnimation = () => {
    dispatch(setSidebarAnimatedState(false));
  };

  const animationsClassNames = {
    enter: styles.enter,
    enterActive: styles.enterActive,
    enterDone: styles.enterDone,
    exit: styles.exit,
    exitActive: styles.exitActive,
    exitDone: styles.exitDone,
  };

  return (
    <>
      <CSSTransition
        in={sidebarExpanded}
        timeout={150}
        classNames={animationsClassNames}
        onEntered={onEnteredAnimation}
      >
        <div
          className={`${styles.root} ${!sidebarExpanded ? styles.collapsed : styles.expanded} ${
            sidebarAnimated ? styles.animated : undefined
          }`}
        >
          <div className={styles.filler}>
            <IntlErrorBoundary
              variant="white"
              size={sidebarExpanded ? 'medium' : 'xsmall'}
              margin="auto"
              collapsed={!sidebarExpanded}
              tooltipPlacement="left"
            >
              <SidebarContent />
            </IntlErrorBoundary>
          </div>
          {expanderVisible && !appLoading && !sidebarMenuOpen && (
            <div
              className={`
              ${styles.expander}
              ${!expanderVisible ? styles.expanderHidden : undefined}
            `}
              onMouseDown={handleToggleSidebar}
            >
              <span>
                <Icon icon={sidebarExpanded ? 'AngleLeftBlue' : 'AngleRightBlue'} size={24} />
              </span>
            </div>
          )}
        </div>
      </CSSTransition>
      <Menu />
    </>
  );
};

export default Sidebar;
