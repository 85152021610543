import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { DOMUtils } from '_common/utils';

const STYLES: Editor.Styles.StylesMap = {
  FONTFAMILY: 'fontFamily',
  FONTSIZE: 'fontSize',
  COLOR: 'color',
  HIGHLIGHTCOLOR: 'highlightColor',
  PARAGRAPHCOLOR: 'paragraphColor',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough',
  SUPERSCRIPT: 'superscript',
  SUBSCRIPT: 'subscript',
  ALIGNMENT: 'alignment',
  LINEHEIGHT: 'lineHeight',
  SPACEBEFORE: 'spaceBefore',
  SPACEAFTER: 'spaceAfter',
  LEFTINDENTATION: 'leftIndentation',
  RIGHTINDENTATION: 'rightIndentation',
  SPECIALINDENT: 'specialIndent',
  SPECIALINDENTVALUE: 'specialIndentValue',
  VANISH: 'vanish',
  LISTID: 'listId',
  LISTLEVEL: 'listLevel',
  ASB: 'asb', // auto space before
  ASA: 'asa', // auto space after
  WC: 'wc', // widow control
  KN: 'kn', // keep with next
  KL: 'kl', // keep lines together
  CTS: 'cts', // contextual spacing
  PBB: 'pbb', // page break before
  OTL: 'otl', // outline level
} as const;

const DOCUMENT_STYLES_LIST: Editor.Styles.DocumentStylesList = [
  STYLES.FONTFAMILY,
  STYLES.FONTSIZE,
  STYLES.COLOR,
  STYLES.HIGHLIGHTCOLOR,
  STYLES.PARAGRAPHCOLOR,
  STYLES.BOLD,
  STYLES.ITALIC,
  STYLES.UNDERLINE,
  STYLES.ALIGNMENT,
  STYLES.LINEHEIGHT,
  STYLES.SPACEBEFORE,
  STYLES.SPACEAFTER,
  STYLES.LEFTINDENTATION,
  STYLES.RIGHTINDENTATION,
  STYLES.SPECIALINDENT,
  STYLES.SPECIALINDENTVALUE,
  STYLES.VANISH,
  STYLES.LISTID,
  STYLES.LISTLEVEL,
  STYLES.ASB,
  STYLES.ASA,
  STYLES.WC,
  STYLES.KN,
  STYLES.KL,
  STYLES.CTS,
  STYLES.PBB,
  STYLES.OTL,
];

const INLINE_STYLES_LIST: Editor.Styles.InlineStylesList = [
  STYLES.FONTFAMILY,
  STYLES.FONTSIZE,
  STYLES.COLOR,
  STYLES.HIGHLIGHTCOLOR,
  STYLES.BOLD,
  STYLES.ITALIC,
  STYLES.UNDERLINE,
  STYLES.STRIKETHROUGH,
  STYLES.SUPERSCRIPT,
  STYLES.SUBSCRIPT,
  STYLES.VANISH,
];

const ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT: Editor.Styles.BlockAttributesList = {
  [ELEMENTS.ParagraphElement.TAG]: [
    STYLES.BOLD,
    STYLES.ITALIC,
    STYLES.FONTFAMILY,
    STYLES.FONTSIZE,
    STYLES.COLOR,
    STYLES.UNDERLINE,
    STYLES.STRIKETHROUGH,
    STYLES.SUPERSCRIPT,
    STYLES.SUBSCRIPT,
    STYLES.VANISH,
    STYLES.PARAGRAPHCOLOR,
    STYLES.SPACEBEFORE,
    STYLES.SPACEAFTER,
    STYLES.ALIGNMENT,
    STYLES.LINEHEIGHT,
    STYLES.LEFTINDENTATION,
    STYLES.RIGHTINDENTATION,
    STYLES.SPECIALINDENT,
    STYLES.SPECIALINDENTVALUE,
    STYLES.LISTID,
    STYLES.LISTLEVEL,
    STYLES.ASB,
    STYLES.ASA,
    STYLES.WC,
    STYLES.KN,
    STYLES.KL,
    STYLES.CTS,
    STYLES.PBB,
    STYLES.OTL,
  ],
  [ELEMENTS.TableElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.FigureElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.PageBreakElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.SectionBreakElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.TableOfContentsElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.ListOfFiguresElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.ListOfTablesElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.KeywordsElement.TAG]: [STYLES.VANISH],
  [ELEMENTS.AuthorsElement.TAG]: [STYLES.VANISH],
};

const ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT: Editor.Styles.InlineAttributeList = {
  [ELEMENTS.ParagraphElement.TAG]: [
    STYLES.BOLD,
    STYLES.ITALIC,
    STYLES.FONTFAMILY,
    STYLES.FONTSIZE,
    STYLES.COLOR,
    STYLES.HIGHLIGHTCOLOR,
    STYLES.UNDERLINE,
    STYLES.STRIKETHROUGH,
    STYLES.SUPERSCRIPT,
    STYLES.SUBSCRIPT,
    STYLES.VANISH,
  ],
};

const INDENTATION_LIMITS = {
  INDENTATION_STEP: 36,
  LEFT: {
    MANIPULATION_MIN: -1440,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: -72,
    RENDER_MAX: 216,
  },
  RIGHT: {
    MANIPULATION_MIN: -1440,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: -72,
    RENDER_MAX: 216,
  },
  SPECIAL_INDENT: {
    MANIPULATION_MIN: 0,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: 0,
    RENDER_MAX: 216,
  },
} as const;

class StylesUtils {
  // ----------------------------------------------------------------------------
  //                                  Styles Utils
  // ----------------------------------------------------------------------------

  // units in points (pt)
  static get INDENTATION_LIMITS() {
    return INDENTATION_LIMITS;
  }

  static get STYLES() {
    return STYLES;
  }

  static get DOCUMENT_STYLES_LIST() {
    return DOCUMENT_STYLES_LIST;
  }

  static get INLINE_STYLES_LIST() {
    return INLINE_STYLES_LIST;
  }

  static get ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT() {
    return ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT;
  }

  static get ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT() {
    return ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT;
  }

  // get all unique values from allowed stylable elements
  static STYLABLE_BLOCK_ELEMENTS = Array.from(
    new Set([
      ...Object.keys(StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT),
      ...Object.keys(StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT),
    ]),
  );

  static PARSE_STYLES: Editor.Styles.ParseStylesMap = {
    [StylesUtils.STYLES.FONTFAMILY]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.FONTFAMILY,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.FONTFAMILY) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.FONTFAMILY);
      }
      return computedStyles.fontFamily.replace(/"/g, '');
    },
    [StylesUtils.STYLES.FONTSIZE]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.FONTSIZE,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.FONTSIZE) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.FONTSIZE);
      }
      return EditorDOMUtils.convertUnitTo(computedStyles.fontSize, undefined, 'pt', 3);
    },
    [StylesUtils.STYLES.COLOR]: (computedStyles: CSSStyleDeclaration, nodeToCheck: HTMLElement) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.COLOR,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.COLOR) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.COLOR);
      }

      let color;

      if (
        nodeToCheck.tagName !== ELEMENTS.TrackInsertElement.TAG &&
        nodeToCheck.tagName !== ELEMENTS.TrackDeleteElement.TAG
      ) {
        color = computedStyles.color;
      }

      let node: Node | null = nodeToCheck;
      while (
        node &&
        EditorDOMUtils.parentContainsNode(EditorDOMUtils.getContentContainer(node), node)
      ) {
        if (node instanceof Element) {
          const styles = getComputedStyle(node);
          if (
            color === styles.color &&
            (node.nodeName === ELEMENTS.TrackInsertElement.TAG ||
              node.nodeName === ELEMENTS.TrackDeleteElement.TAG)
          ) {
            color = null;
          }

          if (
            color == null &&
            node.nodeName !== ELEMENTS.TrackInsertElement.TAG &&
            node.nodeName !== ELEMENTS.TrackDeleteElement.TAG
          ) {
            color = styles.color;
          }
        }

        node = node.parentNode;
      }

      return color;
    },
    [StylesUtils.STYLES.HIGHLIGHTCOLOR]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      let backgroundColor = computedStyles.backgroundColor;
      if (
        computedStyles.backgroundColor === 'rgba(0, 0, 0, 0)' ||
        computedStyles.backgroundColor === 'transparent'
      ) {
        let node: Node | null = nodeToCheck;
        while (
          node &&
          EditorDOMUtils.parentContainsNode(EditorDOMUtils.getContentContainer(node), node) &&
          EditorDOMElements.isInlineNode(node)
        ) {
          if (EditorDOMElements.isFormatElement(node)) {
            const styles = getComputedStyle(node);
            if (
              styles.backgroundColor !== 'rgba(0, 0, 0, 0)' &&
              styles.backgroundColor !== 'transparent'
            ) {
              backgroundColor = styles.backgroundColor;
              break;
            }
          }

          node = node.parentNode;
        }
      }
      return backgroundColor;
    },
    [StylesUtils.STYLES.PARAGRAPHCOLOR]: (computedStyles: CSSStyleDeclaration) =>
      computedStyles.backgroundColor,
    [StylesUtils.STYLES.BOLD]: (computedStyles: CSSStyleDeclaration, nodeToCheck: HTMLElement) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.BOLD,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.BOLD) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.BOLD) === 'true';
      }

      return parseInt(computedStyles.fontWeight, 10) > 500;
    },
    [StylesUtils.STYLES.ITALIC]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.ITALIC,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.ITALIC) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.ITALIC) === 'true';
      }

      return computedStyles.fontStyle === 'italic';
    },
    [StylesUtils.STYLES.UNDERLINE]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.UNDERLINE,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.UNDERLINE) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.UNDERLINE) === 'true';
      }

      let underline =
        computedStyles.textDecorationLine === 'underline' &&
        computedStyles.textDecorationStyle === 'solid';

      if (!underline) {
        let node: Node | null = nodeToCheck;

        while (
          node &&
          EditorDOMUtils.parentContainsNode(EditorDOMUtils.getContentContainer(node), node)
        ) {
          if (
            EditorDOMElements.isSupportedElement(node) &&
            (node.nodeName === ELEMENTS.FormatElement.TAG ||
              StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[node.nodeName]?.includes(
                StylesUtils.STYLES.UNDERLINE,
              ))
          ) {
            const styles = getComputedStyle(node);
            if (
              styles.textDecorationLine === 'underline' &&
              styles.textDecorationStyle === 'solid'
            ) {
              underline = true;
              break;
            }
          }

          node = node.parentNode;
        }
      }

      return underline;
    },
    [StylesUtils.STYLES.ALIGNMENT]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.ALIGNMENT)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.ALIGNMENT);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.a != null) {
          return docStyle.extendedP.a;
        }
      }
      return computedStyles.textAlign;
    },
    [StylesUtils.STYLES.LINEHEIGHT]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.LINEHEIGHT)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.LINEHEIGHT);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.lh != null) {
          return docStyle.extendedP.lh;
        }
      }

      const lineHeight = DOMUtils.convertUnitTo(computedStyles.lineHeight, undefined, 'px', 3);
      const fontSize = DOMUtils.convertUnitTo(computedStyles.fontSize, undefined, 'px', 3);

      return +(lineHeight / fontSize).toFixed(2);
    },
    [StylesUtils.STYLES.SPACEBEFORE]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.SPACEBEFORE)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.SPACEBEFORE);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.sb != null) {
          return docStyle.extendedP.sb;
        }
      }
      return EditorDOMUtils.convertUnitTo(computedStyles.marginTop, undefined, 'pt', 3);
    },
    [StylesUtils.STYLES.ASB]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.ASB)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.ASB) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.ASB]) {
          return docStyle.extendedP[StylesUtils.STYLES.ASB] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.SPACEAFTER]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.SPACEAFTER)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.SPACEAFTER);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.sa != null) {
          return docStyle.extendedP.sa;
        }
      }
      return EditorDOMUtils.convertUnitTo(computedStyles.marginBottom, undefined, 'pt', 3);
    },
    [StylesUtils.STYLES.ASA]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.ASA)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.ASA) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.ASA]) {
          return docStyle.extendedP[StylesUtils.STYLES.ASA] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.STRIKETHROUGH]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.STRIKETHROUGH,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.STRIKETHROUGH) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.STRIKETHROUGH) === 'true';
      }

      return computedStyles.textDecorationLine === 'line-through';
    },
    [StylesUtils.STYLES.SUPERSCRIPT]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.SUPERSCRIPT,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.SUPERSCRIPT) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.SUPERSCRIPT) === 'true';
      }

      return computedStyles.verticalAlign === 'super';
    },
    [StylesUtils.STYLES.SUBSCRIPT]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.SUBSCRIPT,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.SUBSCRIPT) &&
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.SUBSCRIPT) === 'true';
      }

      return computedStyles.verticalAlign === 'sub';
    },
    [StylesUtils.STYLES.LEFTINDENTATION]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (nodeToCheck.dataset.leftIndentation != null) {
        const indentation = parseInt(nodeToCheck.dataset.leftIndentation, 10);

        if (!isNaN(indentation)) {
          return indentation;
        }
      }
    },
    [StylesUtils.STYLES.RIGHTINDENTATION]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (nodeToCheck.dataset.rightIndentation != null) {
        const indentation = parseInt(nodeToCheck.dataset.rightIndentation, 10);

        if (!isNaN(indentation)) {
          return indentation;
        }
      }
    },
    [StylesUtils.STYLES.SPECIALINDENT]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => nodeToCheck.dataset.specialIndent,
    [StylesUtils.STYLES.SPECIALINDENTVALUE]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => nodeToCheck.dataset.specialIndentValue,
    [StylesUtils.STYLES.VANISH]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[nodeToCheck.tag]?.includes(
          StylesUtils.STYLES.VANISH,
        ) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.VANISH) && // TODO: section elemnts do not have this function
        !nodeToCheck.hasContent?.()
      ) {
        // non computed properties defined in paragraph
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.VANISH) === 'true';
      }

      return (
        computedStyles.textDecorationLine === 'underline' &&
        computedStyles.textDecorationStyle === 'dashed'
      );
    },
    [StylesUtils.STYLES.LISTID]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      const { dataManager } = dependencies;
      let listId;
      if (dataManager?.numbering.isListElement?.(nodeToCheck.id)) {
        listId = dataManager?.numbering.getListIdFromBlock(nodeToCheck.id);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        // if its not a list, check document style to compare values
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.lst?.lId != null) {
          listId = null;
        }
      }
      return listId;
    },
    [StylesUtils.STYLES.LISTLEVEL]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      const { dataManager } = dependencies;
      let listLevel;
      if (dataManager?.numbering.isListElement?.(nodeToCheck.id)) {
        listLevel = dataManager?.numbering.getListLevelFromBlock(nodeToCheck.id);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        // if its not a list, check document style to compare values
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.lst?.lLv != null) {
          listLevel = null;
        }
      }
      return listLevel;
    },
    [StylesUtils.STYLES.WC]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.WC)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.WC) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.WC]) {
          return docStyle.extendedP[StylesUtils.STYLES.WC] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.KN]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.KN)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.KN) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.KN]) {
          return docStyle.extendedP[StylesUtils.STYLES.KN] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.KL]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.KL)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.KL) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.KL]) {
          return docStyle.extendedP[StylesUtils.STYLES.KL] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.CTS]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.CTS)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.CTS) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.CTS]) {
          return docStyle.extendedP[StylesUtils.STYLES.CTS] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.PBB]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.PBB)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.PBB) === 'true';
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.PBB]) {
          return docStyle.extendedP[StylesUtils.STYLES.PBB] === true;
        }
      }
      return false;
    },
    [StylesUtils.STYLES.OTL]: (
      computedStyles: CSSStyleDeclaration,
      nodeToCheck: HTMLElement,
      dependencies: Editor.Styles.StylesUtilsDependencies,
    ) => {
      if (
        EditorDOMElements.isSupportedBlockElement(nodeToCheck) &&
        nodeToCheck.hasStyleAttribute?.(StylesUtils.STYLES.OTL)
      ) {
        return nodeToCheck.getStyleAttribute(StylesUtils.STYLES.OTL);
      } else if (EditorDOMElements.isParagraphElement(nodeToCheck) && nodeToCheck.styleId) {
        const { dataManager } = dependencies;
        const docStyle = dataManager?.styles.getDocumentStyleFromId(nodeToCheck.styleId);
        if (docStyle?.extendedP?.[StylesUtils.STYLES.OTL] != null) {
          return docStyle.extendedP[StylesUtils.STYLES.OTL];
        }
      }
    },
  };

  static getStylesAppliedToNode(
    nodeToCheck: Node,
    styleList: Editor.Styles.Styles[] = [],
    dependencies: Editor.Styles.StylesUtilsDependencies = {},
  ): Editor.Styles.StylesApplied {
    const styles: Editor.Styles.StylesApplied = {};

    const pageNode = EditorDOMUtils.getContentContainer(nodeToCheck);

    if (nodeToCheck instanceof Text && nodeToCheck.parentNode) {
      nodeToCheck = nodeToCheck.parentNode;
    }

    if (nodeToCheck instanceof HTMLElement) {
      let blockNode: Element;

      let computedInlineStyles;
      let computedParagraphStyles;

      if (StylesUtils.STYLABLE_BLOCK_ELEMENTS.includes(nodeToCheck.nodeName)) {
        blockNode = nodeToCheck;
      } else {
        computedInlineStyles = getComputedStyle(nodeToCheck);

        blockNode = nodeToCheck;
        while (
          blockNode &&
          blockNode.parentNode &&
          blockNode.parentNode !== pageNode &&
          !EditorDOMElements.isNodeContainerElement(blockNode.parentNode) &&
          !StylesUtils.STYLABLE_BLOCK_ELEMENTS.includes(blockNode.tagName)
        ) {
          if (blockNode.parentNode instanceof Element) {
            blockNode = blockNode.parentNode;
          }
        }
      }

      if (blockNode) {
        computedParagraphStyles = getComputedStyle(blockNode);
      }
      let i;
      for (i = 0; i < styleList.length; i++) {
        if (
          blockNode &&
          computedParagraphStyles &&
          EditorDOMElements.isSupportedBlockElement(blockNode) &&
          StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(styleList[i]) &&
          !StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(styleList[i])
        ) {
          // only block styles
          styles[styleList[i]] = StylesUtils.PARSE_STYLES[styleList[i]]?.(
            computedParagraphStyles,
            blockNode,
            dependencies,
          );
        } else if (
          nodeToCheck &&
          computedInlineStyles &&
          EditorDOMElements.isSupportedBlockElement(blockNode) &&
          !StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(styleList[i]) &&
          StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(styleList[i])
        ) {
          // only inline styles
          styles[styleList[i]] = StylesUtils.PARSE_STYLES[styleList[i]]?.(
            computedInlineStyles,
            nodeToCheck,
            dependencies,
          );
        } else if (
          EditorDOMElements.isSupportedBlockElement(blockNode) &&
          StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(styleList[i]) &&
          StylesUtils.ALLOWED_INLINE_ATTRIBUTES_BY_ELEMENT[blockNode.tag]?.includes(styleList[i])
        ) {
          if (computedInlineStyles) {
            // common styles
            styles[styleList[i]] = StylesUtils.PARSE_STYLES[styleList[i]]?.(
              computedInlineStyles,
              nodeToCheck,
              dependencies,
            );
          } else if (computedParagraphStyles) {
            // common styles
            styles[styleList[i]] = StylesUtils.PARSE_STYLES[styleList[i]]?.(
              computedParagraphStyles,
              nodeToCheck,
              dependencies,
            );
          }
        }
      }
    }

    return styles;
  }
}

export default StylesUtils;
