import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popover, Toggle, Tooltip, usePopper, Button, Divider } from 'dodoc-design-system';
import type { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';
import type { ToggleProps } from 'dodoc-design-system/build/types/Components/Toggle/Toggle';

import { useDispatch, useSelector } from '_common/hooks';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';
import { clearFilterIdentity, useFilterSelector } from '../../FilterSlice';
import Filters, { FiltersProps } from '../../Filters';

import styles from '../FilterPopover/FilterPopover.module.scss';

type FilterPopoverProps = FiltersProps & {
  popperSettings?: PopperProps;
  //Exclude variant that cannot have an icon
  toggleVariant?: Exclude<ToggleProps<'medium', 'Filters'>['variant'], 'pill' | 'ghost' | 'group'>;
  persist?: boolean;
  variant?: 'default' | 'reviewer';
};

const FilterPopover = ({
  identity,
  popperSettings,
  toggleVariant = 'standard',
  persist,
  variant = 'default',
  ...props
}: FilterPopoverProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { selectAppliedFilterCount } = useFilterSelector();

  const { isOpen, popperProps, referenceProps } = usePopper(popperSettings);

  const appliedFilterCount = useSelector((state) =>
    selectAppliedFilterCount(state, state.filters[identity]),
  );

  useEffect(() => {
    if (!persist) {
      return () => {
        dispatch(clearFilterIdentity({ identity, noFeedback: true }));
      };
    }
  }, [persist]);

  const handleClearAllFilters = () => {
    dispatch(clearFilterIdentity({ identity }));
  };

  const getIcon = () => {
    if (variant === 'default') {
      return 'Filters';
    }

    return isOpen ? 'FilterBlue' : 'FilterGrey';
  };

  const getIconBadge = () => {
    if (appliedFilterCount) {
      return variant === 'default'
        ? ({ icon: 'StatusApprove', size: 24, position: 'bottom-right' } as const)
        : ({
            icon: 'Badge',
            size: 12,
            number: appliedFilterCount,
            position: 'top-right',
          } as const);
    }
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        content={intl.formatMessage({ id: 'global.filter' })}
        testId={`${identity}-filterPopover-tooltip`}
      >
        {/* @ts-expect-error odd type error due to icon */}
        <Toggle
          size="medium"
          variant={toggleVariant}
          isToggled={isOpen}
          icon={getIcon()}
          iconBadge={getIconBadge()}
          margin="0 0 0 1rem"
          testId={`${identity}-filterPopover-toggle`}
          {...referenceProps}
        />
      </Tooltip>
      <Popover {...popperProps} testId={`${identity}-filterPopover-popper`}>
        <IntlErrorBoundary margin="auto">
          <div className={styles.root}>
            <div className={styles.header}>
              <div className={styles.title}>
                <FormattedMessage id="FILTERS" />
              </div>
              <Button
                size="small"
                variant="link"
                margin="0 0 0 0.5rem"
                onClick={handleClearAllFilters}
                disabled={!appliedFilterCount}
                testId={`${identity}-filterPopover-clearAll`}
              >
                <FormattedMessage id="CLEAR_ALL" />
              </Button>
            </div>
            <Divider margin="0" />
            <div className={styles.filterList}>
              <Filters identity={identity} testId={`${identity}-filterPopover`} {...props} />
            </div>
          </div>
        </IntlErrorBoundary>
      </Popover>
    </>
  );
};

export default FilterPopover;
