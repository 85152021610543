import { useMemo } from 'react';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

const useMetadataList = () => {
  const { data, ...rest } = useGetMetadataListQuery();

  return useMemo(() => {
    const automatic: OrderedMetadata[] = [];
    const manual: OrderedMetadata[] = [];
    if (data) {
      const ids = Object.keys(data);
      for (let i = 0; i < ids.length; i++) {
        const metadata = data[ids[i]];
        if (metadata.automatic) {
          automatic.push(metadata);
        } else {
          manual.push(metadata);
        }
      }
    }

    return { automatic, manual, ...rest };
  }, [data]);
};

export default useMetadataList;
