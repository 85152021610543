//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { EditorService } from '_common/services';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EquationElement } from 'Editor/services/VisualizerManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass: any) =>
    class EquationsEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      getSelectedEquation() {
        this.dataManager?.selection?.restore();
        const element = EditorDOMUtils.closest(
          EditorSelectionUtils.getSelection()?.focusNode || null,
          'EQUATION-ELEMENT',
        ) as EquationElement;
        return {
          element,
          equation: element.equation,
        };
      }

      handleInsertEquation(value: any) {
        const element = DOMElementFactory.buildElement('equation-element') as EquationElement;
        element.equation = value;
        element.preRender();
        this.handleInsertInlineNode(element);
      }

      handleEditEquation() {
        const isEditable = this.selectionManager?.isCurrentSelectionEditable();
        if (isEditable) {
          const equationProps = this.getSelectedEquation();
          if (equationProps.equation) {
            new EditorService().convertEquation(equationProps.equation).then(({ data }) => {
              ReduxInterface.openAndUpdateModal({
                modal: 'EquationsModal',
                data: {
                  isEdit: true,
                  equation: {
                    elementId: equationProps.element.id,
                    tex: data,
                    mathml: equationProps.equation,
                  },
                },
              });
            });
          }
        }
      }

      handleUpdateEquation(elementId: string, value: any) {
        const element = document.getElementById(elementId);
        if (!element) {
          return;
        }

        EditorSelectionUtils.selectNode(element);
        this.handleInsertEquation(value);
      }
    },
);
