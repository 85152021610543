import { forwardRef, Ref } from 'react';
import { useSelector } from '_common/hooks';

import ViewTaskCard from './ViewTaskCard/';
import EditableTaskCard from './EditableTaskCard/';
import { InteractionController } from '_common/components';

export type TaskCardProps = {
  task: PDF.Annotation.Task;
  sidebar: boolean;
  index?: number;
  order?: number;
  testId: string;
};

const TaskCard = forwardRef(
  ({ task, sidebar, index, order, testId }: TaskCardProps, ref: Ref<HTMLDivElement>) => {
    const editing = useSelector((state) => state.pdf.annotations.editing);

    if (!task.authorId) {
      return null;
    }

    return (
      <span data-testid={testId ? `${testId}-root` : undefined}>
        {editing === task.id ? (
          <EditableTaskCard mode="edit" task={task} sidebar={sidebar} testId={testId} />
        ) : (
          <InteractionController environment="dopdf">
            <ViewTaskCard
              task={task}
              sidebar={sidebar}
              index={index}
              order={order}
              ref={ref}
              testId={testId}
            />
          </InteractionController>
        )}
      </span>
    );
  },
);

export default TaskCard;
