import { useDispatch, useSelector } from '_common/hooks';

import { useSuiteObject } from '_common/suite';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { setVersionHistory } from 'Presentation/PresentationSlice';

import { VersionHistory } from '_common/suite/components';
import { VersionHistoryProps } from '_common/suite/components/Panel/VersionHistory/VersionHistory';
import { openModal } from 'App/ModalContext/utils';
import { downloadOriginalFile, setAppLoading } from 'App/redux/appSlice';
import { notify } from '_common/components/ToastSystem';
import ObjectApi from '_common/services/api/ObjectApi';

const PresentationVersionHistory = () => {
  const dispatch = useDispatch();
  const presentationManager = usePresentationManager();
  const object = useSuiteObject();

  const loadedVersion = useSelector((state) => state.presentation.general.loadedVersion);

  const handleVersionHistoryChange: VersionHistoryProps['onVersionHistoryChange'] = (newValue) => {
    dispatch(setVersionHistory(newValue));
  };

  const handleLoadVersion: VersionHistoryProps['onLoadVersion'] = (version) => {
    presentationManager.loadVersion(version);
  };

  const handleDownloadOriginal: VersionHistoryProps['onDownloadOriginal'] = () => {
    openModal({
      modal: 'ConfirmationModal',
      data: {
        title: 'DOWNLOAD_ORIGINAL_FILE',
        message: 'DOWNLOAD_ORIGINAL_PRESENTATION_FILE_CONFIRMATION',
        confirmButtonTextId: 'DOWNLOAD_ORIGINAL_FILE',
        cancelButtonTextId: 'global.cancel',
        confirmButtonType: 'primary',
        onConfirm: () => {
          dispatch(setAppLoading({ isOpen: true }));
          dispatch(
            downloadOriginalFile({
              objectId: object.id,
              filename: object.name,
              objectType: object.type,
            }),
          )
            .then(() => {
              notify({
                type: 'success',
                title: 'ORIGINAL_FILE_DOWNLOADED',
                message: 'ORIGINAL_FILE_FOR_PRESENTATION_WAS_DOWNLOADED',
                messageValues: { docName: object.name },
              });
            })
            .finally(() => {
              dispatch(setAppLoading({ isOpen: false }));
            });
        },
        modalWidth: '52.5rem',
        headerType: 'information',
      },
    });
  };

  const handleRestoreClick: VersionHistoryProps['onRestoreClick'] = (versionIndex) => {
    openModal({
      modal: 'ConfirmationModal',
      data: {
        onConfirm: async () => {
          try {
            await presentationManager.restoreVersion(versionIndex);
            notify({
              type: 'success',
              title: 'DOCUMENT_VERSION_RESTORED',
              message: 'THE_DOCUMENT_VERSION_WAS_SUCCESSFULLY_RESTORED',
            });
            dispatch(ObjectApi.util.invalidateTags([{ type: object.type, id: object.id }]));
          } catch (e) {
            throw e;
          }
        },
        title: 'RESTORE_DOCUMENT_VERSION',
        //Message key includes 'PDF' but the message itself doesn't
        message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_PDF_DOCUMENT_VERSION',
        messageValues: { versionNumber: versionIndex },
        confirmButtonTextId: 'RESTORE_VERSION',
        confirmButtonType: 'primary',
        modalWidth: '60rem',
        cancelButtonTextId: 'global.cancel',
      },
    });
  };

  return (
    <VersionHistory
      onVersionHistoryChange={handleVersionHistoryChange}
      onLoadVersion={handleLoadVersion}
      onDownloadOriginal={handleDownloadOriginal}
      loadedVersion={loadedVersion}
      onRestoreClick={handleRestoreClick}
      supportOriginalVersion
    />
  );
};

export default PresentationVersionHistory;
