export default class ErrorCannotInsertElement extends Error {
  constructor(...args: any) {
    super(...args);
    this.name = 'ErrorCannotInsertElement';
    this.message = 'Block insertion not allowed!';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorCannotInsertElement);
    }
  }
}
