import io from 'socket.io-client';
import { BaseTypedEmitter } from '../../BaseTypedEmitter';
import {
  TransportError,
  NoDocumentProvided,
  ConnectionError,
  TimeoutError,
  UnknownError,
} from '../Errors';

export default class SocketIO extends BaseTypedEmitter {
  static EVENT = Object.freeze({
    CONNECTED: 'SOI_CONNECT',
    CONNECT_ERROR: 'SOI_CONNECT_ERROR',
    CONNECT_TIMEOUT: 'SOI_CONNECT_TIMEOUT',
    ERROR: 'SOI_ERROR',
    DISCONNECTED: 'SOI_DISCONNECT',
    DESTROYED: 'SOI_DESTROYED',
  });

  constructor(config) {
    super();
    this._config = config;
    this._eventHandlers = {};
    this._isDestroying = false;
  }

  get connected() {
    return !!this._socket?.connected;
  }

  connect(tenant, documentId, token) {
    this._documentId = documentId;
    this._token = token;

    if (this._socket) {
      this._socket.disconnect();
    }

    if (!this._documentId) {
      throw new NoDocumentProvided();
    }

    this._socket = io(
      `${this._config.protos.socketio}${this._config.rt}/editor/${this._documentId}`,
      {
        path: `${this._config.locations.socketio}`,
        query: {
          documentId: this._documentId,
          tenant,
          token: this._token,
        },
        forceNew: true,
        reconnection: false,
        transports: ['websocket'],
      },
    );

    this._socket.on('connect', () => {
      this.emit(SocketIO.EVENT.CONNECTED);
    });
    this._socket.on('connect_error', (error) => {
      this.emit(SocketIO.EVENT.ERROR, SocketIO.EVENT.CONNECT_ERROR, new ConnectionError(error));
    });
    this._socket.on('connect_timeout', (error) => {
      this.emit(SocketIO.EVENT.ERROR, SocketIO.EVENT.CONNECT_TIMEOUT, new TimeoutError(error));
    });
    // Event: 'error'
    this._socket.on('error', (event) => {
      this.emit(SocketIO.EVENT.ERROR, SocketIO.EVENT.ERROR, new UnknownError(event, event.status));
    });
    this._socket.on('disconnect', () => {
      if (this._isDestroying) {
        this.emit(SocketIO.EVENT.DESTROYED);
      } else {
        this.emit(SocketIO.EVENT.DISCONNECTED, new TransportError('disconnected'));
      }
    });

    // Event: 'reconnect'
    // this._socket.on('reconnect', () => {});

    // Event: 'reconnect_attempt'
    // this._socket.on('reconnect_attempt', () => {});

    // Event: 'reconnecting'
    // this._socket.on('reconnecting', () => {});

    // Event: 'reconnect_error'
    // this._socket.on('reconnect_error', () => {});

    // Event: 'reconnect_failed'
    // this._socket.on('reconnect_failed', () => {});

    // add internally managed events if any
    const keys = Object.keys(this._eventHandlers);
    const length = keys.length;
    let handlers;
    let handlersLength;
    for (let index = 0; index < length; index++) {
      handlers = this._eventHandlers[keys[index]];
      handlersLength = handlers.length;
      for (let j = 0; j < handlersLength; j++) {
        this._socket.on(keys[index], handlers[j]);
      }
    }
  }

  disconnect() {
    if (this.connected) {
      this._socket.disconnect();
    }
  }

  destroy() {
    this._isDestroying = true;
    if (this.connected) {
      this._socket.disconnect();
    }
    this._socket = undefined;
    super.destroy();
  }

  dispatchEvent(event, message, acknowledgment = undefined) {
    if (this.connected) {
      this._socket.emit(event, message, acknowledgment);
    } else {
      throw new TransportError('unable to dispatch event, socket not connected');
    }
  }

  handleEvent(event, callback) {
    // handle these events internally. to be able to add them back
    // if we ever decide to re-instantiate the socket
    if (this._eventHandlers[event] === undefined) {
      this._eventHandlers[event] = [];
    }
    this._eventHandlers[event].push(callback);
    if (this._socket) {
      this._socket.on(event, callback);
    }
  }

  removeEvent(event, callback) {
    if (this._eventHandlers[event]?.length > 0) {
      this._eventHandlers[event].splice(this._eventHandlers[event].indexOf(callback), 1);
    }

    if (this._socket) {
      this._socket.off(event, callback);
    }
  }

  clearAllEventListeners() {
    if (this._socket) {
      const eventKeys = Object.keys(this._eventHandlers);

      for (let i = 0; i < eventKeys.length; i++) {
        const event = eventKeys[i];
        this._socket.removeAllListeners(event);
      }
    }

    this._eventHandlers = {};
  }

  // shortcuts
  onError(callback) {
    this.on(SocketIO.EVENT.ERROR, callback);
  }

  onConnect(callback) {
    this.on(SocketIO.EVENT.CONNECTED, callback);
  }

  onDisconnect(callback) {
    this.on(SocketIO.EVENT.DISCONNECTED, callback);
  }

  onDestroy(callback) {
    this.on(SocketIO.EVENT.DISCONNECTED, callback);
  }
}
