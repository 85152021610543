import Options, { Option } from '_common/suite/components/Card/Options/Options';

export type VersionOption = Pick<Option, 'onClick' | 'disabled' | 'icon'>;

type VersionOptionsProps = {
  restoreVersion: VersionOption;
  testId: string;
  editDescription?: VersionOption;
};

const VersionOptions = ({ restoreVersion, testId, editDescription }: VersionOptionsProps) => {
  const restoreOption = {
    label: { id: 'RESTORE_VERSION' },
    disabled: restoreVersion.disabled,
    onClick: restoreVersion.onClick,
    order: 0,
    testId: 'restore-version',
  };
  return (
    <Options
      editOption={{
        inactive: true,
      }}
      deleteOption={{
        inactive: true,
      }}
      moreOptions={
        editDescription
          ? [
            {
              ...restoreOption,
              icon: restoreVersion.icon,
              size: 'large',
            },
            {
              label: { id: 'settings.description.header' },
              disabled: editDescription.disabled,
              onClick: editDescription.onClick,
              icon: editDescription.icon,
              order: 1,
              testId: 'edit-description',
              size: 'large',
            },
          ]
          : [restoreOption]
      }
      testId={testId}
    />
  );
};

export default VersionOptions;
