import qs from 'qs';

import { paths } from '_types/api';
import InstanceService from './InstanceService';

/**
 * Extensions related requests
 * @todo Good candidate for early RTK Query refactor target
 */
export default class ExtensionsService extends InstanceService {
  EXTENSION_LIST = (type: string) => `/api/object/extension/${type}/list`;
  EXTENSION_INSTALL = (type: string, id: string) => `/api/object/extension/${type}/${id}/install`;
  EXTENSION_UNINSTALL = (type: string, id: string) =>
    `/api/object/extension/${type}/${id}/uninstall`;
  EXTENSION_PREVIEW = (id: string) => `/api/object/extension/template/${id}/preview`;

  list(type: string) {
    return this.get<
      paths['/api/object/extension/template/list']['get']['responses']['200']['content']['application/json']
    >(this.EXTENSION_LIST(type));
  }

  install(
    type: string,
    id: paths['/api/object/extension/template/{template_id}/install']['post']['parameters']['path']['template_id'],
  ) {
    return this.post<
      paths['/api/object/extension/template/{template_id}/install']['post']['responses']['200']['content']['application/json']
    >(this.EXTENSION_INSTALL(type, id), qs.stringify(id));
  }

  uninstall(
    type: string,
    id: paths['/api/object/extension/template/{template_id}/uninstall']['post']['parameters']['path']['template_id'],
  ) {
    return this.post<
      paths['/api/object/extension/template/{template_id}/uninstall']['post']['responses']['200']
    >(this.EXTENSION_UNINSTALL(type, id), qs.stringify(id));
  }

  previewTemplate(
    id: paths['/api/object/extension/template/{template_id}/preview']['post']['parameters']['path']['template_id'],
  ) {
    return this.get<Blob>(this.EXTENSION_PREVIEW(id), { responseType: 'blob' });
  }
}
