import { path } from 'd3';
import { constantToRad, ellipseArcTo } from './utils';

type FlowchartProps = {
  size: Presentation.Data.Common.Size;
  type:
    | 'flowChartProcess'
    | 'flowChartAlternateProcess'
    | 'flowChartDecision'
    | 'flowChartInputOutput'
    | 'flowChartPredefinedProcess'
    | 'flowChartInternalStorage'
    | 'flowChartDocument'
    | 'flowChartMultidocument'
    | 'flowChartTerminator'
    | 'flowChartPreparation'
    | 'flowChartManualInput'
    | 'flowChartManualOperation'
    | 'flowChartConnector'
    | 'flowChartOffpageConnector'
    | 'flowChartPunchedCard'
    | 'flowChartPunchedTape'
    | 'flowChartSummingJunction'
    | 'flowChartOr'
    | 'flowChartCollate'
    | 'flowChartSort'
    | 'flowChartExtract'
    | 'flowChartMerge'
    | 'flowChartOnlineStorage'
    | 'flowChartDelay'
    | 'flowChartMagneticTape'
    | 'flowChartMagneticDisk'
    | 'flowChartMagneticDrum'
    | 'flowChartDisplay';
  // | 'flowChartOfflineStorage'; // Unsure what this shape is (29th shape and powerpoint only has 28)
};

const generateFlowchartPath = ({
  size,
  type,
}: FlowchartProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;
  /** Height / 3 */
  const hd3 = h / 3;
  /** Width / 4 */
  const wd4 = w / 4;
  /** Height / 4 */
  const hd4 = h / 4;
  /** Width / 5 */
  const wd5 = w / 5;
  /** Height / 5 */
  const hd5 = h / 5;
  /** Width / 6 */
  const wd6 = w / 6;
  /** Height / 6 */
  const hd6 = h / 6;
  /** Width / 8 */
  const wd8 = w / 8;
  /** Height / 8 */
  const hd8 = h / 8;
  /** Height / 10 */
  const hd10 = h / 10;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side Divided by 6 */
  const ssd6 = Math.min(w, h) / 6;

  switch (type) {
    case 'flowChartProcess': {
      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l, t, r, b },
      };
    }
    case 'flowChartAlternateProcess': {
      const x2 = r - ssd6;
      const y2 = b - ssd6;
      const il = (ssd6 * 29289) / 100000;
      const ir = r - il;
      const ib = b - il;

      const dMain = path();
      dMain.moveTo(l, ssd6);
      ellipseArcTo(dMain, ssd6, ssd6, 'cd2', 'cd4', l, ssd6);
      dMain.lineTo(x2, t);
      ellipseArcTo(dMain, ssd6, ssd6, '3cd4', 'cd4', x2, t);
      dMain.lineTo(r, y2);
      ellipseArcTo(dMain, ssd6, ssd6, 0, 'cd4', r, y2);
      dMain.lineTo(ssd6, b);
      ellipseArcTo(dMain, ssd6, ssd6, 'cd4', 'cd4', ssd6, b);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: il, r: ir, b: ib },
      };
    }
    case 'flowChartDecision': {
      const ir = (w * 3) / 4;
      const ib = (h * 3) / 4;

      const d = path();
      d.moveTo(l, hd2);
      d.lineTo(wd2, t);
      d.lineTo(r, hd2);
      d.lineTo(wd2, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd4, t: hd4, r: ir, b: ib },
      };
    }
    case 'flowChartInputOutput': {
      const x5 = (w * 4) / 5;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(wd5, t);
      d.lineTo(r, t);
      d.lineTo(4 * wd5 /* 4/5 of width */, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd5, t, r: x5, b },
      };
    }
    case 'flowChartPredefinedProcess': {
      //Based on definitions, this shape will be drawn with a ratio of 8x8
      const x2 = (w * 7) / 8;

      const dMain = path();
      dMain.rect(l, t, r, b);

      const dInnerLines = path();
      dInnerLines.moveTo(wd8, t);
      dInnerLines.lineTo(wd8, b);
      dInnerLines.moveTo(r - wd8, t);
      dInnerLines.lineTo(r - wd8, b);

      const dOutline = path();
      dOutline.rect(l, t, r, b);

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          { d: dInnerLines.toString(), fill: 'none' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: wd8, t, r: x2, b },
      };
    }
    case 'flowChartInternalStorage': {
      //Based on definitions, this shape will be drawn with a ratio of 8x8

      const dMain = path();
      dMain.rect(l, t, r, b);

      const dInnerLines = path();
      dInnerLines.moveTo(wd8, t);
      dInnerLines.lineTo(wd8, b);
      dInnerLines.moveTo(l, hd8);
      dInnerLines.lineTo(r, hd8);

      const dOutline = path();
      dOutline.rect(l, t, r, b);

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          { d: dInnerLines.toString(), fill: 'none' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: wd8, t: hd8, r, b },
      };
    }
    case 'flowChartDocument': {
      /** Based on definitions
       * w = h = 21600
       * 17322 =/= 17322 / 21600 = 0.8019
       * 23922 =/= 23922 / 21600 = 1.1075
       * 20172 =/= 20172 / 21600 = 0.9339
       */

      const y1 = (h * 17322) / 21600;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r, h * 0.8019);
      d.bezierCurveTo(wd2, h * 0.8019, wd2, h * 1.1075, l, h * 0.9339);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l, t, r, b: y1 },
      };
    }
    case 'flowChartMultidocument': {
      /** Based on definitions
       * w = h = 21600
       * 1532 =/= 1532 / 21600 = 0.0709
       * 1815 =/= 1815 / 21600 = 0.0840
       * 2972 =/= 2972 / 21600 = 0.1376
       * 3675 =/= 3675 / 21600 = 0.1701
       * 9298 =/= 9298 / 21600 = 0.4305
       * 14392 =/= 14392 / 21600 = 0.6663
       * 14467 =/= 14467 / 21600 = 0.6698
       * 16252 =/= 16252 / 21600 = 0.7524
       * 16352 =/= 16352 / 21600 = 0.7570
       * 18022 =/= 18022 / 21600 = 0.8344
       * 18595 =/= 18595 / 21600 = 0.8609
       * 19298 =/= 19298 / 21600 = 0.8934
       * 20000 =/= 20000 / 21600 = 0.9259
       * 20800 =/= 20800 / 21600 = 0.9630
       * 20782 =/= 20782 / 21600 = 0.9621
       * 23542 =/= 23542 / 21600 = 1.0899
       */

      const y2 = (h * 3675) / 21600;
      const y8 = (h * 20782) / 21600;
      const x5 = (w * 18595) / 21600;

      const d = path();
      d.moveTo(l, h * 0.9621);
      d.bezierCurveTo(w * 0.4305, h * 1.0899, w * 0.4305, h * 0.8344, w * 0.8609, h * 0.8344);
      d.lineTo(w * 0.8609, h * 0.1701);
      d.lineTo(l, h * 0.1701);
      d.closePath();
      d.moveTo(w * 0.0709, h * 0.1701);
      d.lineTo(w * 0.0709, h * 0.084);
      d.lineTo(w * 0.9259, h * 0.084);
      d.lineTo(w * 0.9259, h * 0.7524);
      d.bezierCurveTo(w * 0.8934, h * 0.7524, w * 0.8609, h * 0.757, w * 0.8609, h * 0.757);
      d.lineTo(w * 0.8609, h * 0.1701);
      d.closePath();
      d.moveTo(w * 0.1376, h * 0.084);
      d.lineTo(w * 0.1376, t);
      d.lineTo(w, t);
      d.lineTo(w, h * 0.6663);
      d.bezierCurveTo(w * 0.963, h * 0.6663, w * 0.9259, h * 0.6698, w * 0.9259, h * 0.6698);
      d.lineTo(w * 0.9259, h * 0.084);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l, t: y2, r: x5, b: y8 },
      };
    }
    case 'flowChartTerminator': {
      /** Based on definitions
       * w = h = 21600
       * 3475 =/= 3475 / 21600 = 0.1609
       * 10800 =/= 10800 / 21600 = 0.5 (w or h /2)
       * 18125 =/= 18125 / 21600 = 0.8391
       */

      const il = (w * 1018) / 21600;
      const ir = (w * 20582) / 21600;
      const it = (h * 3163) / 21600;
      const ib = (h * 18437) / 21600;

      const dMain = path();
      dMain.moveTo(w * 0.1609, t);
      dMain.lineTo(w * 0.8391, t);
      ellipseArcTo(dMain, w * 0.1609, hd2, '3cd4', 'cd2', w * 0.8391, t);
      dMain.lineTo(w * 0.1609, h);
      ellipseArcTo(dMain, w * 0.1609, hd2, 'cd4', 'cd2', w * 0.1609, h);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'flowChartPreparation': {
      //Based on definitions, this shape will be drawn with a ratio of 10x10
      const x2 = (w * 4) / 5;

      const d = path();
      d.moveTo(l, hd2);
      d.lineTo(wd5, t);
      d.lineTo(r - wd5, t);
      d.lineTo(r, hd2);
      d.lineTo(r - wd5, b);
      d.lineTo(wd5, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd5, t, r: x2, b },
      };
    }
    case 'flowChartManualInput': {
      //Based on definitions, this shape will be drawn with a ratio of 5x5
      const d = path();
      d.moveTo(l, hd5);
      d.lineTo(r, t);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l, t: hd5, r, b },
      };
    }
    case 'flowChartManualOperation': {
      //Based on definitions, this shape will be drawn with a ratio of 5x5
      const x3 = (w * 4) / 5;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r - wd5, b);
      d.lineTo(wd5, b);

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd5, t, r: x3, b },
      };
    }
    case 'flowChartConnector': {
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(l, vc);
      const topLeftArc = ellipseArcTo(dMain, wd2, hd2, 'cd2', 'cd4', l, vc);
      const topRightArc = ellipseArcTo(
        dMain,
        wd2,
        hd2,
        '3cd4',
        'cd4',
        topLeftArc.eX,
        topLeftArc.eY,
      );
      const bottomRightArc = ellipseArcTo(
        dMain,
        wd2,
        hd2,
        0,
        'cd4',
        topRightArc.eX,
        topRightArc.eY,
      );
      ellipseArcTo(dMain, wd2, hd2, 'cd4', 'cd4', bottomRightArc.eX, bottomRightArc.eY);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'flowChartOffpageConnector': {
      //Based on definitions, this shape will be drawn with a ratio of 10x10
      const y1 = (h * 4) / 5;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r, b - hd5);
      d.lineTo(wd2, b);
      d.lineTo(l, b - hd5);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l, t, r, b: y1 },
      };
    }
    case 'flowChartPunchedCard': {
      //Based on definitions, this shape will be drawn with a ratio of 5x5
      const d = path();
      d.moveTo(l, hd5);
      d.lineTo(wd5, t);
      d.lineTo(r, t);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l, t: hd5, r, b },
      };
    }
    case 'flowChartPunchedTape': {
      /** This shape will be drawn with a ratio of 20x20 */
      const ib = (h * 4) / 5;

      const dMain = path();
      dMain.moveTo(l, hd10);
      const topLeftArc = ellipseArcTo(dMain, wd4, hd10, 'cd2', constantToRad(-10800000), l, hd10);
      ellipseArcTo(dMain, wd4, hd10, 'cd2', 'cd2', topLeftArc.eX, topLeftArc.eY);
      dMain.lineTo(r, b - hd10);
      const bottomRightArc = ellipseArcTo(
        dMain,
        wd4,
        hd10,
        0,
        constantToRad(-10800000),
        r,
        b - hd10,
      );
      ellipseArcTo(dMain, wd4, hd10, 0, 'cd2', bottomRightArc.eX, bottomRightArc.eY);

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l, t: hd5, r, b: ib },
      };
    }
    case 'flowChartSummingJunction': {
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dEllipse = path();
      dEllipse.moveTo(l, vc);
      const topLeftArc = ellipseArcTo(dEllipse, wd2, hd2, 'cd2', 'cd4', l, vc);
      const topRightArc = ellipseArcTo(
        dEllipse,
        wd2,
        hd2,
        '3cd4',
        'cd4',
        topLeftArc.eX,
        topLeftArc.eY,
      );
      const bottomRightArc = ellipseArcTo(
        dEllipse,
        wd2,
        hd2,
        0,
        'cd4',
        topRightArc.eX,
        topRightArc.eY,
      );
      ellipseArcTo(dEllipse, wd2, hd2, 'cd4', 'cd4', bottomRightArc.eX, bottomRightArc.eY);
      dEllipse.closePath();

      const dLines = path();
      dLines.moveTo(il, it);
      dLines.lineTo(ir, ib);
      dLines.moveTo(ir, it);
      dLines.lineTo(il, ib);

      const dOutline = dEllipse;

      return {
        paths: [
          { d: dEllipse.toString(), stroke: 'false' },
          { d: dLines.toString(), fill: 'none' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'flowChartOr': {
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dEllipse = path();
      dEllipse.moveTo(l, vc);
      const topLeftArc = ellipseArcTo(dEllipse, wd2, hd2, 'cd2', 'cd4', l, vc);
      const topRightArc = ellipseArcTo(
        dEllipse,
        wd2,
        hd2,
        '3cd4',
        'cd4',
        topLeftArc.eX,
        topLeftArc.eY,
      );
      const bottomRightArc = ellipseArcTo(
        dEllipse,
        wd2,
        hd2,
        0,
        'cd4',
        topRightArc.eX,
        topRightArc.eY,
      );
      ellipseArcTo(dEllipse, wd2, hd2, 'cd4', 'cd4', bottomRightArc.eX, bottomRightArc.eY);
      dEllipse.closePath();

      const dLines = path();
      dLines.moveTo(hc, t);
      dLines.lineTo(hc, b);
      dLines.moveTo(l, vc);
      dLines.lineTo(r, vc);

      const dOutline = dEllipse;

      return {
        paths: [
          { d: dEllipse.toString(), stroke: 'false' },
          { d: dLines.toString(), fill: 'none' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'flowChartCollate': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const ir = (w * 3) / 4;
      const ib = (h * 3) / 4;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(hc, vc);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.lineTo(hc, vc);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd4, t: hd4, r: ir, b: ib },
      };
    }
    case 'flowChartSort': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const ir = (w * 3) / 4;
      const ib = (h * 3) / 4;

      const dMain = path();
      dMain.moveTo(l, vc);
      dMain.lineTo(hc, t);
      dMain.lineTo(r, vc);
      dMain.lineTo(hc, b);
      dMain.closePath();

      const dLine = path();
      dLine.moveTo(l, vc);
      dLine.lineTo(r, vc);

      const dOutline = dMain;

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          { d: dLine.toString(), fill: 'none' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: wd4, t: hd4, r: ir, b: ib },
      };
    }
    case 'flowChartExtract': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const x2 = (w * 3) / 4;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(hc, t);
      d.lineTo(r, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd4, t: vc, r: x2, b },
      };
    }
    case 'flowChartMerge': {
      //Based on definitions, this shape will be drawn with a ratio of 2x2
      const x2 = (w * 3) / 4;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(hc, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: wd4, t, r: x2, b: vc },
      };
    }
    case 'flowChartOnlineStorage': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const x2 = (w * 5) / 6;

      const dMain = path();
      dMain.moveTo(wd6, t);
      dMain.lineTo(r, t);
      ellipseArcTo(dMain, wd6, hd2, '3cd4', constantToRad(-10800000), r, t);
      dMain.lineTo(wd6, b);
      ellipseArcTo(dMain, wd6, hd2, 'cd4', 'cd2', wd6, b);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: wd6, t, r: x2, b },
      };
    }
    case 'flowChartDelay': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(l, t);
      dMain.lineTo(hc, t);
      ellipseArcTo(dMain, wd2, hd2, '3cd4', 'cd2', hc, t);
      dMain.lineTo(l, b);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l, t: it, r: ir, b: ib },
      };
    }
    case 'flowChartMagneticTape': {
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;
      const ang1 = Math.atan2(h, w);

      const dMain = path();
      dMain.moveTo(hc, b);
      const blArc = ellipseArcTo(dMain, wd2, hd2, 'cd4', 'cd4', hc, b);
      const tlArc = ellipseArcTo(dMain, wd2, hd2, 'cd2', 'cd4', blArc.eX, blArc.eY);
      const topRightArc = ellipseArcTo(dMain, wd2, hd2, '3cd4', 'cd4', tlArc.eX, tlArc.eY);
      ellipseArcTo(dMain, wd2, hd2, 0, ang1, topRightArc.eX, topRightArc.eY);
      dMain.lineTo(r, ib);
      dMain.lineTo(r, b);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'flowChartMagneticDisk': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const y3 = (h * 5) / 6;

      const dMain = path();
      dMain.moveTo(l, hd6);
      ellipseArcTo(dMain, wd2, hd6, 'cd2', 'cd2', l, hd6);
      dMain.lineTo(r, b - hd6);
      ellipseArcTo(dMain, wd2, hd6, 0, 'cd2', r, b - hd6);
      dMain.closePath();

      const dTopOutline = path();
      dTopOutline.moveTo(r, hd6);
      ellipseArcTo(dTopOutline, wd2, hd6, 0, 'cd2', r, hd6);

      const dMainOutline = dMain;

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          { d: dTopOutline.toString(), fill: 'none' },
          { d: dMainOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t: hd3, r, b: y3 },
      };
    }
    case 'flowChartMagneticDrum': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const x2 = (w * 2) / 3;

      const dMain = path();
      dMain.moveTo(wd6, t);
      dMain.lineTo(r - wd6, t);
      ellipseArcTo(dMain, wd6, hd2, '3cd4', 'cd2', r - wd6, t);
      dMain.lineTo(wd6, b);
      ellipseArcTo(dMain, wd6, hd2, 'cd4', 'cd2', wd6, b);
      dMain.closePath();

      const dInnerArc = path();
      dInnerArc.moveTo(r - wd6, b);
      ellipseArcTo(dInnerArc, wd6, hd2, 'cd4', 'cd2', r - wd6, b);

      const dMainOutline = dMain;

      return {
        paths: [
          { d: dMain.toString(), stroke: 'false' },
          { d: dInnerArc.toString(), fill: 'none' },
          { d: dMainOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: wd6, t, r: x2, b },
      };
    }
    case 'flowChartDisplay': {
      //Based on definitions, this shape will be drawn with a ratio of 6x6
      const x2 = (w * 5) / 6;

      const dMain = path();
      dMain.moveTo(l, hd2);
      dMain.lineTo(wd6, t);
      dMain.lineTo(r - wd6, t);
      ellipseArcTo(dMain, wd6, hd2, '3cd4', 'cd2', r - wd6, t);
      dMain.lineTo(wd6, b);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: wd6, t, r: x2, b },
      };
    }
  }
};

export default generateFlowchartPath;
