import GradientDefinition from '../GradientDefinition';
import PatternDefs from './PatternDefs';

type BackgroundDefsProps = {
  id: string;
  fill: Presentation.Data.Common.FillType | undefined;
};

const BackgroundDefs = ({ id, fill }: BackgroundDefsProps) => {
  switch (fill?.type) {
    case 'gradient': {
      return <GradientDefinition id={id} gradientFill={fill} />;
    }
    case 'pattern': {
      return <PatternDefs id={id} background={fill} />;
    }
    default: {
      return null;
    }
  }
};

export default BackgroundDefs;
