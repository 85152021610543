import { selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';
import EditorManager from 'Editor/services/EditorManager';
import { useSelector } from '_common/hooks';
import { useSuitePermissions } from '_common/suite';

const useCanUserPerform = ({
  action,
  byBlocks,
}: {
  action: Permissions.ObjectPermissionsType | Permissions.BlockPermissionsType;
  byBlocks?: boolean;
}) => {
  const permissions = useSuitePermissions();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const selectedBlocks = useSelector((state) => state.editor.status.selection.BLOCK_IDS);

  //Check permissions for the selected blocks
  if (byBlocks) {
    return EditorManager.getInstance().canUserPerform(selectedBlocks, action);
  }

  //Check permissions for the whole document
  switch (action) {
    case 'access': {
      return permissions.canAccess;
    }
    case 'edit': {
      return permissions.canEdit && !isReadOnlyMode;
    }
    default: {
      return false;
    }
  }
};

export default useCanUserPerform;
