import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useDispatch, useEffectOnUpdate, useSelector } from '_common/hooks';
import { setZoom } from 'Presentation/PresentationSlice';

const usePresentationPersist = () => {
  const dispatch = useDispatch();
  const { id: presentationId } = useParams<{ id: string }>();

  const userId = useSelector((state) => state.auth.userId);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  const [hasLoadedZoom, setHasLoadedZoom] = useState(false);

  //Save Zoom when changed
  useEffectOnUpdate(() => {
    if (presentationId && userId && hasLoadedZoom) {
      window.localStorage.setItem(`${userId}-${presentationId}-zoom`, `${zoom}`);
    }
  }, [zoom]);

  //Load data persisted in localStorage
  useEffect(() => {
    if (userId && presentationId) {
      try {
        //Get localStorage persisted zoom
        setHasLoadedZoom(true); //Loaded status changed to true even if unsuccessful
        const zoom = window.localStorage.getItem(`${userId}-${presentationId}-zoom`);
        if (zoom) {
          dispatch(setZoom(+zoom));
        }
      } catch (e) {
        throw e;
      }
    }
  }, [userId, presentationId]);
};

export default usePresentationPersist;
