import { useParams } from 'react-router';
import { navigateToError403, navigateToError404 } from 'router/history';
import { useObject } from '_common/hooks';

const usePDFData = (props?: { open?: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const { data, error } = useObject(
    { object_id: id, object_type: 'dopdf', open: props?.open },
    { skip: !id },
  );
  if (error) {
    // @ts-expect-error error type doesn't seem right
    if (error.status === 404) {
      navigateToError404();
      // @ts-expect-error error type doesn't seem right
    } else if (error.status === 403) {
      navigateToError403();
    }
  }
  if (data) {
    return data;
  }
  return null;
};

export default usePDFData;
