import { TabulationUtils } from 'Editor/services/_Common/TabulationUtils';
import { merge, cloneDeep, isEqual } from 'lodash';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { DocumentStyleData, DocumentStyleProperties } from '../../models';

export const DEFAULT_STYLE_OBJECT: { [index: string]: DocumentStyleData } = Object.freeze({
  p: {
    p: {
      a: 'l',
      bg: false,
      color: 'rgb(0, 0, 0)',
      fontfamily: 'Times New Roman',
      fontsize: 10,
      italic: false,
      lh: 1,
      sa: 0,
      sb: 0,
      underline: false,
      bold: false,
      v: false,
      asb: false,
      asa: false,
      wc: true,
      kn: false,
      kl: false,
      cts: false,
      pbb: false,
      // indentation can't exist by default because overrides outline lists with 0
      // ind: {
      //   l: 0,
      //   r: 0,
      // },
    },
    n: 'Paragraph',
    id: 'p',
    b: true,
    a: '',
    e: '',
  },
});

export type MergedStyleData = DocumentStyleData & {
  originalP: DocumentStyleProperties;
  extendedP: DocumentStyleProperties;
  templateOnly: boolean;
};

export default class DocumentStyle extends BaseTypedEmitter<{
  READY: () => void;
  LOADED: (data: Partial<DocumentStyleData> | null) => void;
  UPDATED: (data: Partial<DocumentStyleData> | null) => void;
}> {
  id: string;
  private templateStyle: Partial<DocumentStyleData> | null = null;
  private mergedStyle: Partial<MergedStyleData> = {};
  private documentStyle: Partial<DocumentStyleData> | null = null;
  private parentProperties: DocumentStyleProperties = {};
  children: DocumentStyle[] = [];

  static DATA_MAPPER: { [index: string]: (...args: any[]) => void } = {
    // name
    n: (
      result: Partial<DocumentStyleData> = {},
      originalData: DocumentStyleData,
      newData: DocumentStyleData,
    ) => {
      if (originalData.n !== newData?.n && newData?.n != null) {
        result.n = newData.n;
        const _name = `${newData.n}${newData.a ? `;${newData.a}` : ''}`;

        const rawElements = _name.split(new RegExp('[,;]', 'g')).map((element) => element.trim());
        if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 't'].includes(newData.id)) {
          if (rawElements[0] === newData.n) {
            result.a = rawElements.slice(1, rawElements.length).join(',');
          } else {
            result.a = rawElements.join(',');
          }
        } else {
          result.a = rawElements.slice(1, rawElements.length).join(',');
        }
      } else {
        result.n = originalData.n;
        result.a = originalData.a;
      }
    },
    // id not to change
    id: (
      result: Partial<DocumentStyleData> = {},
      originalData: DocumentStyleData,
      newData: DocumentStyleData,
    ) => {
      result.id = originalData.id || newData.id;
    },
    // base not to change
    cs: (result: Partial<DocumentStyleData> = {}, originalData: DocumentStyleData) => {
      if (originalData.cs != null) {
        result.cs = originalData.cs;
      }
    },
    // base not to change
    b: (result: Partial<DocumentStyleData> = {}, originalData: DocumentStyleData) => {
      result.b = !!originalData.b;
    },
    // extend
    e: (
      result: Partial<DocumentStyleData> = {},
      originalData: DocumentStyleData,
      newData: DocumentStyleData,
    ) => {
      if (newData.e != null) {
        result.e = newData.e;
      } else {
        result.e = originalData.e;
      }
    },
    // styles object
    p: (result: any = {}, originalData: DocumentStyleData, newData: DocumentStyleData) => {
      result.p = { ...originalData.p };

      if (newData.p != null) {
        const styleKeys = Object.keys(newData.p);
        let i;
        for (i = 0; i < styleKeys.length; i++) {
          switch (styleKeys[i]) {
            case 'fontFamily':
            case 'fontfamily':
              result.p.fontfamily = newData.p[styleKeys[i]];
              break;
            case 'fontSize':
            case 'fontsize':
              result.p.fontsize = newData.p[styleKeys[i]];
              break;
            case 'color':
              result.p.color = newData.p[styleKeys[i]];
              break;
            case 'backgroundColor':
            case 'backgroundcolor':
            case 'bg':
              result.p.bg = newData.p[styleKeys[i]];
              break;
            case 'bold':
              result.p.bold = newData.p[styleKeys[i]];
              break;
            case 'italic':
              result.p.italic = newData.p[styleKeys[i]];
              break;
            case 'underline':
              result.p.underline = newData.p[styleKeys[i]];
              break;
            case 'alignment':
            case 'a':
              result.p.a = newData.p[styleKeys[i]];
              break;
            case 'lineHeight':
            case 'lh':
              result.p.lh = newData.p[styleKeys[i]];
              break;
            case 'spaceBefore':
            case 'sb':
              result.p.sb = newData.p[styleKeys[i]];
              break;
            case 'spaceAfter':
            case 'sa':
              result.p.sa = newData.p[styleKeys[i]];
              break;
            case 'asa':
              result.p.asa = newData.p[styleKeys[i]];
              break;
            case 'asb':
              result.p.asb = newData.p[styleKeys[i]];
              break;
            case 'v':
              result.p.v = newData.p[styleKeys[i]];
              break;
            case 'ind':
              if (result.p.ind == null && (newData.p.ind?.l != null || newData.p.ind?.r != null)) {
                result.p.ind = {};
              }

              if (newData.p.ind?.l != null) {
                result.p.ind.l = newData.p.ind?.l;
              }

              if (newData.p.ind?.r != null) {
                result.p.ind.r = newData.p.ind?.r;
              }
              break;
            case 'sp_ind':
              if (newData.p.sp_ind?.t != null) {
                result.p.sp_ind = {
                  t: newData.p.sp_ind.t,
                  v: Number(newData.p.sp_ind.v) || 0,
                };
              }
              break;
            case 'lst':
              if (newData.p.lst) {
                result.p.lst = {
                  lId: newData.p.lst.lId,
                  lLv: Number(newData.p.lst.lLv) || 0,
                };
              }
              break;
            case 'wc':
              result.p.wc = newData.p[styleKeys[i]];
              break;
            case 'kn':
              result.p.kn = newData.p[styleKeys[i]];
              break;
            case 'kl':
              result.p.kl = newData.p[styleKeys[i]];
              break;
            case 'cts':
              result.p.cts = newData.p[styleKeys[i]];
              break;
            case 'pbb':
              result.p.pbb = newData.p[styleKeys[i]];
              break;
            case 'otl':
              result.p.otl = newData.p[styleKeys[i]];
              break;
            default:
              break;
          }
        }
      }
    },
  };

  static validateName(style: Partial<DocumentStyleData>, name: string) {
    if (style && style.n != null) {
      let _name = name;
      if (!_name) {
        _name = `${style.n}${style.a ? `;${style.a}` : ''}`;
      }

      const rawElements = _name.split(new RegExp('[,;]', 'g')).map((element) => element.trim());
      if (style.id && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 't'].includes(style.id)) {
        if (rawElements[0] === style.n) {
          style.a = rawElements.slice(1, rawElements.length).join(',');
        } else {
          style.a = rawElements.join(',');
        }
      } else {
        style.n = rawElements[0];
        style.a = rawElements.slice(1, rawElements.length).join(',');
      }
    }
    return style;
  }

  static parseBack(originalStyle: Partial<DocumentStyleData>, styleToParse: any) {
    const result: any = {};

    const mapperKeys = Object.keys(DocumentStyle.DATA_MAPPER);

    let i;
    for (i = 0; i < mapperKeys.length; i++) {
      DocumentStyle.DATA_MAPPER[mapperKeys[i]](result, originalStyle, styleToParse);
    }
    return result;
  }

  static populateProperties(newProperties: DocumentStyleProperties): DocumentStyleProperties {
    return { ...DEFAULT_STYLE_OBJECT.p.p, ...newProperties };
  }

  static generateStyleId() {
    return `${Math.random().toString(36).substring(4)}`;
  }

  constructor(
    id: string,
    templateStyle: Partial<DocumentStyleData> | null,
    documentStyle: Partial<DocumentStyleData> | null,
  ) {
    super();
    this.id = id;
    if (templateStyle) {
      this.templateStyle = cloneDeep(templateStyle);
    }
    if (documentStyle) {
      this.documentStyle = cloneDeep(documentStyle);
    }
    this.joinStyleData();
  }

  get data() {
    return cloneDeep(this.mergedStyle);
  }

  get n() {
    return this.mergedStyle.n;
  }

  get a() {
    return this.mergedStyle.a;
  }

  get cs() {
    return this.mergedStyle.cs;
  }

  get b() {
    return this.mergedStyle.b;
  }

  get e() {
    return this.mergedStyle.e;
  }

  get p() {
    return this.mergedStyle.p;
  }

  get t() {
    return this.mergedStyle.t;
  }

  get tabs() {
    return this.mergedStyle?.p?.tabs || [];
  }

  get templateOnly() {
    return this.templateStyle && !this.documentStyle;
  }

  get hasDocumentDefinition() {
    return !!this.documentStyle;
  }

  get hasTemplateDefinition() {
    return !!this.templateStyle;
  }

  get originalP() {
    return this.documentStyle;
  }

  get extends() {
    return this.mergedStyle.e || null;
  }

  get extendedP() {
    return this.mergedStyle.extendedP;
  }

  get hasList() {
    return this.mergedStyle.p?.lst && this.mergedStyle.p?.lst.lId != null;
  }

  get styleDefinedListId() {
    return this.mergedStyle.p?.lst?.lId || null;
  }

  get outlineValue() {
    return this.mergedStyle.extendedP?.otl !== undefined ? this.mergedStyle.extendedP.otl : null;
  }

  private extendFromParentStyleProperties(
    parentProperties: DocumentStyleProperties,
    styleProperties: DocumentStyleProperties = this.mergedStyle.p || {},
  ) {
    const extended: DocumentStyleProperties = {};
    merge(extended, parentProperties, styleProperties);
    if (extended) {
      extended.tabs = this.joinTabStops({ p: parentProperties }, { p: styleProperties });
    }
    return extended;
  }

  private joinTabStops(...args: Partial<DocumentStyleData>[]) {
    let data: Editor.Data.TabStop[][] = [];
    for (let index = 0; index < args.length; index++) {
      data.push(args[index].p?.tabs || []);
    }
    return TabulationUtils.mergeTabStops(...data);
  }

  private joinStyleData() {
    let merged: Partial<MergedStyleData> = {};
    if (this.id === 'p') {
      merge(merged, DEFAULT_STYLE_OBJECT.p, this.templateStyle || {}, this.documentStyle || {});
      if (merged.p) {
        merged.p.tabs = this.joinTabStops(
          DEFAULT_STYLE_OBJECT.p,
          this.templateStyle || {},
          this.documentStyle || {},
        );
      }
    } else {
      merge(merged, this.templateStyle || {}, this.documentStyle || {});
      if (merged.p) {
        merged.p.tabs = this.joinTabStops(this.templateStyle || {}, this.documentStyle || {});
      }
    }
    merged.extendedP = this.extendFromParentStyleProperties(this.parentProperties, merged.p);
    this.updateMergedData(merged);
  }

  private updateMergedData(newData: Partial<MergedStyleData>) {
    if (!isEqual(this.mergedStyle, newData)) {
      this.mergedStyle = newData;
      this.emit('UPDATED', this.mergedStyle);
      for (let index = 0; index < this.children.length; index++) {
        this.children[index].loadNewParentProperties(this.mergedStyle.extendedP);
      }
    }
  }

  loadStyleData(
    templateStyle: Partial<DocumentStyleData> | null,
    documentStyle: Partial<DocumentStyleData> | null,
  ) {
    this.resetParentProperties();

    let updated = false;

    if (!isEqual(this.templateStyle, templateStyle)) {
      this.templateStyle = cloneDeep(templateStyle);
      updated = true;
    }

    if (!isEqual(this.documentStyle, documentStyle)) {
      this.documentStyle = cloneDeep(documentStyle);
      updated = true;
    }

    if (updated) {
      this.joinStyleData();
    }

    return this;
  }

  resetParentProperties() {
    this.parentProperties = {};
  }

  loadNewParentProperties(
    parentProperties?: DocumentStyleProperties,
    shouldUpdateChildren: boolean = true,
  ) {
    if (!isEqual(this.parentProperties, parentProperties)) {
      this.parentProperties = cloneDeep(parentProperties || {});
      let extendedP = this.extendFromParentStyleProperties(this.parentProperties);
      if (!isEqual(this.mergedStyle.extendedP, extendedP)) {
        this.mergedStyle.extendedP = extendedP;
        this.emit('UPDATED', this.mergedStyle);
        if (shouldUpdateChildren) {
          for (let index = 0; index < this.children.length; index++) {
            this.children[index].loadNewParentProperties(this.mergedStyle.extendedP);
          }
        }
      }
    }
    return this;
  }
}
