import api from '_common/services/api/api';

const SystemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTenantNotificationSettings: builder.query<ApiSchemas['SystemSettingsSchema'], void>({
      query: () => ({ url: '/system/settings' }),
      providesTags: [{ type: 'Settings', id: 'system' }],
    }),
    editTenantNotificationSetting: builder.mutation<
      void,
      {
        setting: TenantNotificationsSetting;
        value: boolean;
      }
    >({
      query: ({ setting, value }) => ({
        url: '/system/settings',
        method: 'POST',
        body: { [`notifications.${setting}`]: value },
      }),
      invalidatesTags: [{ type: 'Settings', id: 'system' }],
    }),
  }),
});

export const { useGetTenantNotificationSettingsQuery, useEditTenantNotificationSettingMutation } =
  SystemApi;

export default SystemApi;
