import { TextLineIterator } from '../../../Iterators/HTMLIterators';
import { BaseModifier } from '../BaseModifier';

export class MoveLineModifier extends BaseModifier {
  constructor(Data: Editor.Data.API, direction: Editor.Selection.ModifierDirection) {
    super(Data, 'move', 'line', direction);
  }

  private moveLineForward(
    range: Editor.Selection.EditorRange,
    modifiersData: Editor.Data.Selection.Modifiers,
  ) {
    const lineIterator = new TextLineIterator(range.endContainer, range.endOffset, modifiersData);

    const htmlPosition = lineIterator.next();

    if (htmlPosition.node != null && htmlPosition.offset != null) {
      range.setStart(htmlPosition.node, htmlPosition.offset);
      range.setEnd(htmlPosition.node, htmlPosition.offset);
    } else {
      logger.info('Invalid position!');
      // throw new Error('Invalid position!');
    }
  }

  private moveLineBackward(
    range: Editor.Selection.EditorRange,
    modifiersData: Editor.Data.Selection.Modifiers,
  ) {
    const lineIterator = new TextLineIterator(
      range.startContainer,
      range.startOffset,
      modifiersData,
    );

    const htmlPosition = lineIterator.previous();

    if (htmlPosition.node != null && htmlPosition.offset != null) {
      range.setStart(htmlPosition.node, htmlPosition.offset);
      range.setEnd(htmlPosition.node, htmlPosition.offset);
    } else {
      logger.info('Invalid position!');
      // throw new Error('Invalid position!');
    }
  }

  visitDoDOCRange(range: Editor.Selection.EditorRange): void {
    const modifiersData: Editor.Data.Selection.Modifiers = this.Data.selection?.modifiersData || {};

    modifiersData.direction = this.direction;

    switch (this.direction) {
      case 'forward':
        this.moveLineForward(range, modifiersData);
        break;
      case 'backward':
        this.moveLineBackward(range, modifiersData);
        break;
      default:
        break;
    }

    this.Data.selection?.updateModifiers(modifiersData);
  }

  visitJsonRange(range: Editor.Selection.JsonRange): void {
    const editorRange = range.serializeToDOMRange();
    this.visitDoDOCRange(editorRange);
    range.updateFromDOMRange(editorRange);
  }
}
