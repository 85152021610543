import { Command } from '../Command';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { TableStylesOperations } from '../../Operations/TableOperations';
import { EditorDOMElements } from 'Editor/services/_Common/DOM';
import { ErrorElementNotEditable } from '../../Errors';

export class UpdateTablePropertiesCommand extends Command {
  propertiesData: Editor.Styles.TablePropertiesData;

  constructor(context: Editor.Edition.Context, propertiesData: Editor.Styles.TablePropertiesData) {
    super(context);
    this.propertiesData = propertiesData;
  }

  async handleExec(): Promise<void> {
    if (!this.askUserAboutThis()) {
      return;
    }

    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    // check if element is editable
    if (!this.context.DataManager.nodes.isNodeEditable(this.actionContext.baseModel.id)) {
      throw new ErrorElementNotEditable();
    }

    // TODO FIND ANOTHER SOLUTION TO GET SELECTION
    const elements = EditorSelectionUtils.getSelectedTableElements();

    if (
      !elements ||
      !EditorDOMElements.isTableElement(elements.selectedTable) ||
      !(
        this.actionContext.baseModel.id === elements.selectedTable.id ||
        this.actionContext.baseModel.getChildInfoById(elements.selectedTable.id)
      )
    ) {
      throw new Error('Invalid data');
    }

    const operation = this.applyOperations(
      this.actionContext.baseModel,
      elements.selectedTable.id,
      elements.selectedCellsIds,
      elements.rowsIndex,
      elements.columnsIndex,
    );

    if (operation) {
      operation.apply();

      this.createPatch();
    }
  }

  private applyOperations(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCellsIds: string[],
    rowsIndex: number[],
    columnsIndex: number[],
  ) {
    return new TableStylesOperations(
      baseModel,
      tableId,
      this.propertiesData,
      selectedCellsIds,
      rowsIndex,
      columnsIndex,
    );
  }
}
