import { FormattedMessage } from 'react-intl';

import styles from './Title.module.scss';

type VersionTitleProps = {
  versions?: Card.Version.Versions;
  versionIndex?: number;
  currentVersion?: boolean;
  originalVersion?: boolean;
  supportOriginalVersion?: boolean;
  user?: string;
};

const VersionTitle = ({
  versions,
  versionIndex,
  currentVersion,
  originalVersion,
  user,
  supportOriginalVersion,
}: VersionTitleProps) => {
  const versionNumber = `V.${
    (versions?.length || 0) - (versionIndex ?? -1) - (supportOriginalVersion ? 1 : 0)
  }`;

  return (
    <div className={styles.root}>
      {currentVersion ? (
        <>
          <FormattedMessage id="CURRENT_VERSION" />
          {` (${versionNumber})`}
          {user && <div className={styles.userName}>{user}</div>}
        </>
      ) : originalVersion ? (
        <>
          <FormattedMessage id="ORIGINAL_FILE" />
          {` (${versionNumber})`}
        </>
      ) : (
        versionNumber
      )}
    </div>
  );
};

export default VersionTitle;
