import List from './List/List';

import style from './ActiveSession.module.scss';

const ActiveSession = () => {
  return (
    <div className={style.activeSession}>
      <List />
    </div>
  );
};

export default ActiveSession;
