import { Logger } from '_common/services';
import { RealtimeOpsBuilder } from '_common/services/Realtime';

export abstract class BaseOperation<
  T extends Realtime.Core.RealtimeObject = Realtime.Core.RealtimeObject,
> implements Editor.Edition.IOperationBuilder
{
  protected model: T;

  protected ops: Realtime.Core.RealtimeOps;
  protected preOpPath?: Editor.Selection.Path; // operation start path
  protected resultPath?: Editor.Selection.Path; // operation resultPath
  protected preOpPosition?: Editor.Selection.Position;
  protected posOpPosition?: Editor.Selection.Position;

  private debug: boolean = true;

  constructor(model: T) {
    this.model = model;
    this.ops = [];
  }

  protected abstract build(): Editor.Edition.IOperationBuilder;

  apply(): Editor.Edition.IOperationBuilder {
    if (this.ops.length) {
      if (this.debug) {
        logger.trace(`Apply ops`, this.ops);
      }
      this.model.apply(this.ops, { source: 'LOCAL_RENDER' });
    } else {
      if (this.debug) {
        Logger.warn('No operations to apply!!');
      }
    }
    return this;
  }

  hasOpsToApply(): boolean {
    return this.ops.length > 0;
  }

  getOps(): Realtime.Core.RealtimeOps {
    return this.ops;
  }

  getAdjustedPath(): Editor.Selection.Path | undefined {
    return this.resultPath;
  }

  getPostOpPath(): Editor.Selection.Path | undefined {
    return this.resultPath;
  }

  getPreOpPath(): Editor.Selection.Path | undefined {
    return this.preOpPath;
  }

  getPosOpPosition(): Editor.Selection.Position | undefined {
    return this.posOpPosition;
  }

  getPreOpPosition(): Editor.Selection.Position | undefined {
    return this.preOpPosition;
  }

  protected getObjectOperationforPathValue(
    oldValue: unknown | undefined,
    newValue: unknown | null,
    path: (string | number)[],
  ) {
    if (newValue != null) {
      if (oldValue !== undefined) {
        if (oldValue !== newValue) {
          // this condition should not have an else
          return RealtimeOpsBuilder.objectReplace(oldValue, newValue, path);
        }
      } else {
        return RealtimeOpsBuilder.objectInsert(newValue, path);
      }
    } else if (oldValue !== undefined) {
      return RealtimeOpsBuilder.objectDelete(oldValue, path);
    }
  }
}
