import { path } from 'd3';
import { constantToRad, ellipseArcTo, mod, pin } from './utils';
import { SHAPE_CONST } from './consts';

type BlockArrowProps = {
  size: Presentation.Data.Common.Size;
  adjst?: Record<`adj${string}`, string>;
  type:
    | 'ellipse'
    | 'triangle'
    | 'rtTriangle'
    | 'parallelogram'
    | 'trapezoid'
    | 'diamond'
    | 'pentagon'
    | 'hexagon'
    | 'heptagon'
    | 'octagon'
    | 'decagon'
    | 'dodecagon'
    | 'pie'
    | 'chord'
    | 'teardrop'
    | 'frame'
    | 'halfFrame'
    | 'corner'
    | 'diagStripe'
    | 'plus'
    | 'plaque'
    | 'can'
    | 'cube'
    | 'bevel'
    | 'donut'
    | 'noSmoking'
    | 'blockArc'
    | 'foldedCorner'
    | 'smileyFace'
    | 'heart'
    | 'lightningBolt'
    | 'sun'
    | 'moon'
    | 'cloud'
    | 'arc'
    | 'bracketPair'
    | 'bracePair'
    | 'leftBracket'
    | 'rightBracket'
    | 'leftBrace'
    | 'rightBrace';
};

const getAdjstByType = (type: BlockArrowProps['type']) => {
  switch (type) {
    case 'triangle':
    case 'corner':
    case 'diagStripe':
    case 'moon':
      return {
        adj1: 50000,
        adj2: 50000,
        adj3: 0,
      };
    case 'parallelogram':
    case 'trapezoid':
    case 'hexagon':
    case 'plus':
    case 'can':
    case 'cube':
    case 'donut':
    case 'sun':
      return {
        adj1: 25000,
        adj2: 0,
        adj3: 0,
      };
    case 'octagon':
      return {
        adj1: 29289,
        adj2: 0,
        adj3: 0,
      };
    case 'pie':
      return {
        adj1: 0,
        adj2: 16200000,
        adj3: 0,
      };
    case 'chord':
      return {
        adj1: 2700000,
        adj2: 16200000,
        adj3: 0,
      };
    case 'teardrop':
      return {
        adj1: 100000,
        adj2: 0,
        adj3: 0,
      };
    case 'frame':
    case 'bevel':
      return {
        adj1: 12500,
        adj2: 12500,
        adj3: 0,
      };
    case 'halfFrame':
      return {
        adj1: 33333,
        adj2: 33333,
        adj3: 0,
      };
    case 'plaque':
    case 'foldedCorner':
    case 'bracketPair':
      return {
        adj1: 16667,
        adj2: 0,
        adj3: 0,
      };
    case 'noSmoking':
      return {
        adj1: 18750,
        adj2: 0,
        adj3: 0,
      };
    case 'blockArc':
      return {
        adj1: 10800000,
        adj2: 0,
        adj3: 25000,
      };
    case 'smileyFace':
      return {
        adj1: 4653,
        adj2: 0,
        adj3: 0,
      };
    case 'arc':
      return {
        adj1: 16200000,
        adj2: 0,
        adj3: 0,
      };
    case 'bracePair':
    case 'leftBracket':
    case 'rightBracket':
    case 'leftBrace':
    case 'rightBrace':
      return {
        adj1: 8333,
        adj2: 50000,
        adj3: 0,
      };
    case 'ellipse':
    case 'rtTriangle':
    case 'diamond':
    case 'pentagon':
    case 'heptagon':
    case 'decagon':
    case 'dodecagon':
    case 'heart':
    case 'lightningBolt':
    case 'cloud':
      return {
        adj1: 0,
        adj2: 0,
        adj3: 0,
      };
  }
};

const generateBlockArrowPath = ({
  size,
  type,
  adjst,
}: BlockArrowProps): Presentation.Data.ParsedGeometry => {
  /** Width */
  const w = size.width;
  /** Height */
  const h = size.height;

  /** Width / 2 */
  const wd2 = w / 2;
  /** Height / 2 */
  const hd2 = h / 2;
  /** Width / 3 */
  const wd3 = w / 3;
  /** Height / 3 */
  const hd3 = h / 3;
  /** Width / 4 */
  const wd4 = w / 4;
  /** Height / 4 */
  const hd4 = h / 4;
  /** Width / 12 */
  const wd12 = w / 12;

  /** Horizontal center */
  const hc = wd2;
  /** Vertical Center */
  const vc = hd2;

  /** Top */
  const t = 0;
  /** Right */
  const r = w;
  /** Bottom */
  const b = h;
  /** Left */
  const l = 0;

  /** Shortest Side */
  const ss = Math.min(w, h);

  const defaultAdjLst = getAdjstByType(type);

  if (adjst) {
    adjst.adj1 = adjst.adj || adjst.adj1;
  }

  const adj1 = adjst?.adj1 ? +adjst.adj1 : defaultAdjLst.adj1;
  const adj2 = adjst?.adj2 ? +adjst.adj2 : defaultAdjLst.adj2;
  const adj3 = adjst?.adj3 ? +adjst.adj3 : defaultAdjLst.adj3;

  switch (type) {
    case 'ellipse': {
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dEllipse = path();
      dEllipse.moveTo(l, vc);
      const topLeftArc = ellipseArcTo(dEllipse, wd2, hd2, 'cd2', 'cd4', l, vc);
      const topRightArc = ellipseArcTo(
        dEllipse,
        wd2,
        hd2,
        '3cd4',
        'cd4',
        topLeftArc.eX,
        topLeftArc.eY,
      );
      const bottomRightArc = ellipseArcTo(
        dEllipse,
        wd2,
        hd2,
        0,
        'cd4',
        topRightArc.eX,
        topRightArc.eY,
      );
      ellipseArcTo(dEllipse, wd2, hd2, 'cd4', 'cd4', bottomRightArc.eX, bottomRightArc.eY);
      dEllipse.closePath();

      return {
        paths: [{ d: dEllipse.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'triangle': {
      const a = pin(0, adj1, 100000);
      const x1 = (w * a) / 200000;
      const x2 = (w * a) / 100000;
      const x3 = x1 + wd2;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(x2, t);
      d.lineTo(r, b);
      d.closePath();

      return {
        paths: [{ d: d.toString() }],
        textBounds: { l: x1, t: vc, r: x3, b },
      };
    }
    case 'rtTriangle': {
      const it = (h * 7) / 12;
      const ir = (w * 7) / 12;
      const ib = (h * 11) / 12;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: wd12, t: it, r: ir, b: ib } };
    }
    case 'parallelogram': {
      const maxAdj = (100000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const x2 = (ss * a) / 100000;
      const x5 = r - x2;
      const q1 = (5 * a) / maxAdj;
      const q2 = (1 + q1) / 12;
      const il = (q2 * w) / 1;
      const it = (q2 * h) / 1;
      const ir = r - il;
      const ib = b - it;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(x2, t);
      d.lineTo(r, t);
      d.lineTo(x5, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'trapezoid': {
      const maxAdj = (50000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const x2 = (ss * a) / 100000;
      const x3 = r - x2;
      const il = (wd3 * a) / maxAdj;
      const it = (hd3 * a) / maxAdj;
      const ir = r - il;

      const d = path();
      d.moveTo(l, b);
      d.lineTo(x2, t);
      d.lineTo(x3, t);
      d.lineTo(r, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b } };
    }
    case 'diamond': {
      const ir = (w * 3) / 4;
      const ib = (h * 3) / 4;

      const d = path();
      d.moveTo(t, vc);
      d.lineTo(hc, t);
      d.lineTo(r, vc);
      d.lineTo(hc, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: wd4, t: hd4, r: ir, b: ib } };
    }
    case 'pentagon': {
      const hf = 105146;
      const vf = 110557;

      const swd2 = (wd2 * hf) / 100000;
      const shd2 = (hd2 * vf) / 100000;
      const svc = (vc * vf) / 100000;
      const dx1 = swd2 * Math.cos(constantToRad(1080000));
      const dx2 = swd2 * Math.cos(constantToRad(18360000));
      const dy1 = shd2 * Math.sin(constantToRad(1080000));
      const dy2 = shd2 * Math.sin(constantToRad(18360000));
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = svc - dy1;
      const y2 = svc - dy2;
      const it = (y1 * dx2) / dx1;

      const d = path();
      d.moveTo(x1, y1);
      d.lineTo(hc, t);
      d.lineTo(x4, y1);
      d.lineTo(x3, y2);
      d.lineTo(x2, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x2, t: it, r: x3, b: y2 } };
    }
    case 'hexagon': {
      const vf = 115470;

      const maxAdj = (50000 * w) / ss;
      const a = pin(0, adj1, maxAdj);
      const shd2 = (hd2 * vf) / 100000;
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const dy1 = shd2 * Math.sin(constantToRad(3600000));
      const y1 = vc - dy1;
      const y2 = vc + dy1;
      const q1 = (maxAdj * -1) / 2;
      const q2 = a + q1;
      const q3 = q2 > 0 ? 4 : 2;
      const q4 = q2 > 0 ? 3 : 2;
      const q5 = q2 > 0 ? q1 : 0;
      const q6 = (a + q5) / q1;
      const q7 = (q6 * q4) / -1;
      const q8 = q3 + q7;
      const il = (w * q8) / 24;
      const it = (h * q8) / 24;
      const ir = r - il;
      const ib = b - it;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x1, y1);
      d.lineTo(x2, y1);
      d.lineTo(r, vc);
      d.lineTo(x2, y2);
      d.lineTo(x1, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'heptagon': {
      const hf = 102572;
      const vf = 105210;

      const swd2 = (wd2 * hf) / 100000;
      const shd2 = (hd2 * vf) / 100000;
      const svc = (vc * vf) / 100000;
      const dx1 = (swd2 * 97493) / 100000;
      const dx2 = (swd2 * 78183) / 100000;
      const dx3 = (swd2 * 43388) / 100000;
      const dy1 = (shd2 * 62349) / 100000;
      const dy2 = (shd2 * 22252) / 100000;
      const dy3 = (shd2 * 90097) / 100000;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc - dx3;
      const x4 = hc + dx3;
      const x5 = hc + dx2;
      const x6 = hc + dx1;
      const y1 = svc - dy1;
      const y2 = svc + dy2;
      const y3 = svc + dy3;
      const ib = b - y1;

      const d = path();
      d.moveTo(x1, y2);
      d.lineTo(x2, y1);
      d.lineTo(hc, t);
      d.lineTo(x5, y1);
      d.lineTo(x6, y2);
      d.lineTo(x4, y3);
      d.lineTo(x3, y3);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x2, t: y1, r: x5, b: ib } };
    }
    case 'octagon': {
      const a = pin(0, adj1, 50000);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const y2 = b - x1;
      const il = x1 / 2;
      const ir = r - il;
      const ib = b - il;

      const d = path();

      d.moveTo(l, x1);
      d.lineTo(x1, t);
      d.lineTo(x2, t);
      d.lineTo(r, x1);
      d.lineTo(r, y2);
      d.lineTo(x2, b);
      d.lineTo(x1, b);
      d.lineTo(l, y2);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: il, r: ir, b: ib } };
    }
    case 'decagon': {
      const vf = 105146;

      const shd2 = (hd2 * vf) / 100000;
      const dx1 = wd2 * Math.cos(constantToRad(2160000));
      const dx2 = wd2 * Math.cos(constantToRad(4320000));
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const dy1 = shd2 * Math.sin(constantToRad(4320000));
      const dy2 = shd2 * Math.sin(constantToRad(2160000));
      const y1 = vc - dy1;
      const y2 = vc - dy2;
      const y3 = vc + dy2;
      const y4 = vc + dy1;

      const d = path();
      d.moveTo(l, vc);
      d.lineTo(x1, y2);
      d.lineTo(x2, y1);
      d.lineTo(x3, y1);
      d.lineTo(x4, y2);
      d.lineTo(r, vc);
      d.lineTo(x4, y3);
      d.lineTo(x3, y4);
      d.lineTo(x2, y4);
      d.lineTo(x1, y3);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y2, r: x4, b: y3 } };
    }
    case 'dodecagon': {
      const x1 = (w * 2894) / 21600;
      const x2 = (w * 7906) / 21600;
      const x3 = (w * 13694) / 21600;
      const x4 = (w * 18706) / 21600;
      const y1 = (h * 2894) / 21600;
      const y2 = (h * 7906) / 21600;
      const y3 = (h * 13694) / 21600;
      const y4 = (h * 18706) / 21600;

      const d = path();
      d.moveTo(l, y2);
      d.lineTo(x1, y1);
      d.lineTo(x2, t);
      d.lineTo(x3, t);
      d.lineTo(x4, y1);
      d.lineTo(r, y2);
      d.lineTo(r, y3);
      d.lineTo(x4, y4);
      d.lineTo(x3, b);
      d.lineTo(x2, b);
      d.lineTo(x1, y4);
      d.lineTo(l, y3);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: y1, r: x4, b: y4 } };
    }
    case 'pie': {
      const stAng = constantToRad(pin(0, adj1, 21599999));
      const enAng = constantToRad(pin(0, adj2, 21599999));
      const sw1 = enAng - stAng;
      const sw2 = sw1 + constantToRad(21600000);
      const swAng = sw1 > 0 ? sw1 : sw2;
      const wt1 = wd2 * Math.sin(stAng);
      const ht1 = hd2 * Math.cos(stAng);
      const dx1 = wd2 * Math.cos(Math.atan2(wt1, ht1));
      const dy1 = hd2 * Math.sin(Math.atan2(wt1, ht1));
      const x1 = hc + dx1;
      const y1 = vc + dy1;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(x1, y1);
      ellipseArcTo(dMain, wd2, hd2, stAng, swAng, x1, y1);
      dMain.lineTo(hc, vc);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'chord': {
      const stAng = constantToRad(pin(0, adj1, 21599999));
      const enAng = constantToRad(pin(0, adj2, 21599999));
      const sw1 = enAng - stAng;
      const sw2 = sw1 + constantToRad(21600000);
      const swAng = sw1 > 0 ? sw1 : sw2;
      const wt1 = wd2 * Math.sin(stAng);
      const ht1 = hd2 * Math.cos(stAng);
      const dx1 = wd2 * Math.cos(Math.atan2(wt1, ht1));
      const dy1 = hd2 * Math.sin(Math.atan2(wt1, ht1));
      const x1 = hc + dx1;
      const y1 = vc + dy1;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(x1, y1);
      ellipseArcTo(dMain, wd2, hd2, stAng, swAng, x1, y1);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'teardrop': {
      const a = pin(0, adj1, 200000);
      const r2 = Math.sqrt(2);
      const tw = wd2 * r2;
      const th = hd2 * r2;
      const sw = (tw * a) / 100000;
      const sh = (th * a) / 100000;
      const dx1 = sw * Math.cos(constantToRad(2700000));
      const dy1 = sh * Math.sin(constantToRad(2700000));
      const x1 = hc + dx1;
      const y1 = vc - dy1;
      const x2 = (hc + x1) / 2;
      const y2 = (vc + y1) / 2;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(l, vc);
      ellipseArcTo(dMain, wd2, hd2, 'cd2', 'cd4', l, vc);
      dMain.quadraticCurveTo(x2, t, x1, y1);
      dMain.quadraticCurveTo(r, y2, r, vc);
      const bottomRightArc = ellipseArcTo(dMain, wd2, hd2, 0, 'cd4', r, vc);
      ellipseArcTo(dMain, wd2, hd2, 'cd4', 'cd4', bottomRightArc.eX, bottomRightArc.eY);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'frame': {
      const a1 = pin(0, adj1, 50000);
      const x1 = (ss * a1) / 100000;
      const x4 = r - x1;
      const y4 = b - x1;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(r, b);
      d.lineTo(l, b);
      d.closePath();
      d.moveTo(x1, x1);
      d.lineTo(x1, y4);
      d.lineTo(x4, y4);
      d.lineTo(x4, x1);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x1, t: x1, r: x4, b: y4 } };
    }
    case 'halfFrame': {
      const maxAdj2 = (100000 * w) / ss;
      const a2 = pin(0, adj2, maxAdj2);
      const x1 = (ss * a2) / 100000;
      const g1 = (h * x1) / w;
      const g2 = h - g1;
      const maxAdj1 = (100000 * g2) / ss;
      const a1 = pin(0, adj1, maxAdj1);
      const y1 = (ss * a1) / 100000;
      const dx2 = (y1 * w) / h;
      const x2 = w - dx2;
      const dy2 = (x1 * h) / w;
      const y2 = h - dy2;

      const d = path();
      d.moveTo(l, t);
      d.lineTo(r, t);
      d.lineTo(x2, y1);
      d.lineTo(x1, y1);
      d.lineTo(x1, y2);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r, b } };
    }
    case 'corner': {
      const maxAdj1 = (100000 * h) / ss;
      const maxAdj2 = (100000 * w) / ss;
      const a1 = pin(0, adj1, maxAdj1);
      const a2 = pin(0, adj2, maxAdj2);
      const x1 = (ss * a2) / 100000;
      const dy1 = (ss * a1) / 100000;
      const y1 = b - dy1;
      const d = w - h;
      const it = d > 0 ? y1 : t;
      const ir = d > 0 ? r : x1;

      const dPath = path();
      dPath.moveTo(l, t);
      dPath.lineTo(x1, t);
      dPath.lineTo(x1, y1);
      dPath.lineTo(r, y1);
      dPath.lineTo(r, b);
      dPath.lineTo(l, b);
      dPath.closePath();

      return { paths: [{ d: dPath.toString() }], textBounds: { l, t: it, r: ir, b } };
    }
    case 'diagStripe': {
      const a = pin(0, adj1, 100000);
      const x2 = (w * a) / 100000;
      const y2 = (h * a) / 100000;
      const x3 = (x2 + r) / 2;
      const y3 = (y2 + b) / 2;

      const d = path();
      d.moveTo(l, y2);
      d.lineTo(x2, t);
      d.lineTo(r, t);
      d.lineTo(l, b);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l, t, r: x3, b: y3 } };
    }
    case 'plus': {
      const a = pin(0, adj1, 50000);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const y2 = b - x1;
      const d = w - h;
      const il = d > 0 ? l : x1;
      const ir = d > 0 ? r : x2;
      const it = d > 0 ? x1 : t;
      const ib = d > 0 ? y2 : b;

      const dPath = path();
      dPath.moveTo(l, x1);
      dPath.lineTo(x1, x1);
      dPath.lineTo(x1, t);
      dPath.lineTo(x2, t);
      dPath.lineTo(x2, x1);
      dPath.lineTo(r, x1);
      dPath.lineTo(r, y2);
      dPath.lineTo(x2, y2);
      dPath.lineTo(x2, b);
      dPath.lineTo(x1, b);
      dPath.lineTo(x1, y2);
      dPath.lineTo(l, y2);
      dPath.closePath();

      return { paths: [{ d: dPath.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'plaque': {
      const a = pin(0, adj1, 50000);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const y2 = b - x1;
      const il = (x1 * 70711) / 100000;
      const ir = r - il;
      const ib = b - il;

      const dMain = path();
      dMain.moveTo(l, x1);
      ellipseArcTo(dMain, x1, x1, 'cd4', constantToRad(-5400000), l, x1);
      dMain.lineTo(x2, t);
      ellipseArcTo(dMain, x1, x1, 'cd2', constantToRad(-5400000), x2, t);
      dMain.lineTo(r, y2);
      ellipseArcTo(dMain, x1, x1, '3cd4', constantToRad(-5400000), r, y2);
      dMain.lineTo(x1, b);
      ellipseArcTo(dMain, x1, x1, 0, constantToRad(-5400000), x1, b);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: il, r: ir, b: ib },
      };
    }
    case 'can': {
      const maxAdj = (50000 * h) / ss;
      const a = pin(0, adj1, maxAdj);
      const y1 = (ss * a) / 200000;
      const y2 = y1 + y1;
      const y3 = b - y1;

      const dBody = path();
      dBody.moveTo(l, y1);
      ellipseArcTo(dBody, wd2, y1, 'cd2', constantToRad(-10800000), l, y1);
      dBody.lineTo(r, y3);
      ellipseArcTo(dBody, wd2, y1, 0, 'cd2', r, y3);
      dBody.closePath();

      const dTop = path();
      dTop.moveTo(l, y1);
      const topTopHalf = ellipseArcTo(dTop, wd2, y1, 'cd2', 'cd2', l, y1);
      ellipseArcTo(dTop, wd2, y1, 0, 'cd2', topTopHalf.eX, topTopHalf.eY);
      dTop.closePath();

      const dOutline = path();
      dOutline.moveTo(r, y1);
      const outlineTopHalf = ellipseArcTo(dOutline, wd2, y1, 0, 'cd2', r, y1);
      ellipseArcTo(dOutline, wd2, y1, 'cd2', 'cd2', outlineTopHalf.eX, outlineTopHalf.eY);
      dOutline.lineTo(r, y3);
      ellipseArcTo(dOutline, wd2, y1, 0, 'cd2', r, y3);
      dOutline.lineTo(l, y1);

      return {
        paths: [
          { d: dBody.toString(), stroke: 'false' },
          {
            d: dTop.toString(),
            stroke: 'false',
            fillModifier: 'lighten',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
          },
        ],
        textBounds: { l, t: y2, r, b: y3 },
      };
    }
    case 'cube': {
      const a = pin(0, adj1, 100000);
      const y1 = (ss * a) / 100000;
      const y4 = b - y1;
      const x4 = r - y1;

      const dFront = path();
      dFront.moveTo(l, y1);
      dFront.lineTo(x4, y1);
      dFront.lineTo(x4, b);
      dFront.lineTo(l, b);
      dFront.closePath();

      const dSide = path();
      dSide.moveTo(x4, y1);
      dSide.lineTo(r, t);
      dSide.lineTo(r, y4);
      dSide.lineTo(x4, b);
      dSide.closePath();

      const dTop = path();
      dTop.moveTo(l, y1);
      dTop.lineTo(y1, t);
      dTop.lineTo(r, t);
      dTop.lineTo(x4, y1);
      dTop.closePath();

      const dOutline = path();
      dOutline.moveTo(l, y1);
      dOutline.lineTo(y1, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, y4);
      dOutline.lineTo(x4, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();
      dOutline.moveTo(l, y1);
      dOutline.lineTo(x4, y1);
      dOutline.lineTo(r, t);
      dOutline.moveTo(x4, y1);
      dOutline.lineTo(x4, b);

      return {
        paths: [
          { d: dFront.toString(), stroke: 'false' },
          { d: dSide.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dTop.toString(), stroke: 'false', fillModifier: 'lightenLess' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t: y1, r: x4, b },
      };
    }
    case 'bevel': {
      const a = pin(0, adj1, 50000);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const y2 = b - x1;

      const dCenter = path();
      dCenter.moveTo(x1, x1);
      dCenter.lineTo(x2, x1);
      dCenter.lineTo(x2, y2);
      dCenter.lineTo(x1, y2);
      dCenter.closePath();

      const dTop = path();
      dTop.moveTo(l, t);
      dTop.lineTo(r, t);
      dTop.lineTo(x2, x1);
      dTop.lineTo(x1, x1);
      dTop.closePath();

      const dBottom = path();
      dBottom.moveTo(l, b);
      dBottom.lineTo(x1, y2);
      dBottom.lineTo(x2, y2);
      dBottom.lineTo(r, b);
      dBottom.closePath();

      const dLeft = path();
      dLeft.moveTo(l, t);
      dLeft.lineTo(x1, x1);
      dLeft.lineTo(x1, y2);
      dLeft.lineTo(l, b);
      dLeft.closePath();

      const dRight = path();
      dRight.moveTo(r, t);
      dRight.lineTo(r, b);
      dRight.lineTo(x2, y2);
      dRight.lineTo(x2, x1);
      dRight.closePath();

      const dOutline = path();
      dOutline.moveTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, b);
      dOutline.lineTo(l, b);
      dOutline.closePath();
      dOutline.moveTo(x1, x1);
      dOutline.lineTo(x2, x1);
      dOutline.lineTo(x2, y2);
      dOutline.lineTo(x1, y2);
      dOutline.closePath();
      dOutline.moveTo(l, t);
      dOutline.lineTo(x1, x1);
      dOutline.moveTo(l, b);
      dOutline.lineTo(x1, y2);
      dOutline.moveTo(r, t);
      dOutline.lineTo(x2, x1);
      dOutline.moveTo(r, b);
      dOutline.lineTo(x2, y2);

      return {
        paths: [
          { d: dCenter.toString(), stroke: 'false' },
          { d: dTop.toString(), stroke: 'false', fillModifier: 'lightenLess' },
          { d: dBottom.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dLeft.toString(), stroke: 'false', fillModifier: 'lighten' },
          { d: dRight.toString(), stroke: 'false', fillModifier: 'darken' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: x1, t: x1, r: x2, b: y2 },
      };
    }
    case 'donut': {
      const a = pin(0, adj1, 50000);
      const dr = (ss * a) / 100000;
      const iwd2 = w / 2 - dr;
      const ihd2 = h / 2 - dr;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(l, vc);
      const outerTopLeftArc = ellipseArcTo(dMain, wd2, hd2, 'cd2', 'cd4', l, vc);
      const outerTopRightArc = ellipseArcTo(
        dMain,
        wd2,
        hd2,
        '3cd4',
        'cd4',
        outerTopLeftArc.eX,
        outerTopLeftArc.eY,
      );
      const outerBottomRightArc = ellipseArcTo(
        dMain,
        wd2,
        hd2,
        0,
        'cd4',
        outerTopRightArc.eX,
        outerTopRightArc.eY,
      );
      ellipseArcTo(dMain, wd2, hd2, 'cd4', 'cd4', outerBottomRightArc.eX, outerBottomRightArc.eY);
      dMain.closePath();
      dMain.moveTo(dr, vc);
      const innerBottomLeftArc = ellipseArcTo(
        dMain,
        iwd2,
        ihd2,
        'cd2',
        constantToRad(-5400000),
        dr,
        vc,
      );
      const innerBottomRightArc = ellipseArcTo(
        dMain,
        iwd2,
        ihd2,
        'cd4',
        constantToRad(-5400000),
        innerBottomLeftArc.eX,
        innerBottomLeftArc.eY,
      );
      const innerTopRightArc = ellipseArcTo(
        dMain,
        iwd2,
        ihd2,
        0,
        constantToRad(-5400000),
        innerBottomRightArc.eX,
        innerBottomRightArc.eY,
      );
      ellipseArcTo(
        dMain,
        iwd2,
        ihd2,
        '3cd4',
        constantToRad(-5400000),
        innerTopRightArc.eX,
        innerTopRightArc.eY,
      );
      dMain.closePath();

      return { paths: [{ d: dMain.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'noSmoking': {
      const a = pin(0, adj1, 50000);
      const dr = (ss * a) / 100000;
      const iwd2 = wd2 - dr;
      const ihd2 = hd2 - dr;
      const ang = Math.atan2(h, w);
      const ct = ihd2 * Math.cos(ang);
      const st = iwd2 * Math.sin(ang);
      const m = mod(ct, st, 0);
      const n = (iwd2 * ihd2) / m;
      const drd2 = dr / 2;
      const dang = Math.atan2(drd2, n);
      const dang2 = dang * 2;
      const swAng = constantToRad(-10800000) + dang2;
      const t3 = Math.atan2(h, w);
      const stAng1 = t3 - dang;
      const stAng2 = stAng1 - SHAPE_CONST.cd2;
      const ct1 = ihd2 * Math.cos(stAng1);
      const st1 = iwd2 * Math.sin(stAng1);
      const m1 = mod(ct1, st1, 0);
      const n1 = (iwd2 * ihd2) / m1;
      const dx1 = n1 * Math.cos(stAng1);
      const dy1 = n1 * Math.sin(stAng1);
      const x1 = hc + dx1;
      const y1 = vc + dy1;
      const x2 = hc - dx1;
      const y2 = vc - dy1;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;

      const dMain = path();
      dMain.moveTo(l, vc);
      const tlArc = ellipseArcTo(dMain, wd2, hd2, 'cd2', 'cd4', l, vc);
      const trArc = ellipseArcTo(dMain, wd2, hd2, '3cd4', 'cd4', tlArc.eX, tlArc.eY);
      const brArc = ellipseArcTo(dMain, wd2, hd2, 0, 'cd4', trArc.eX, trArc.eY);
      ellipseArcTo(dMain, wd2, hd2, 'cd4', 'cd4', brArc.eX, brArc.eY);
      dMain.closePath();
      dMain.moveTo(x1, y1);
      ellipseArcTo(dMain, iwd2, ihd2, stAng1, swAng, x1, y1);
      dMain.closePath();
      dMain.moveTo(x2, y2);
      ellipseArcTo(dMain, iwd2, ihd2, stAng2, swAng, x2, y2);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'blockArc': {
      const stAng = constantToRad(pin(0, adj1, 21599999));
      const istAng = constantToRad(pin(0, adj2, 21599999));
      const a3 = pin(0, adj3, 50000);
      const sw11 = istAng - stAng;
      const sw12 = sw11 + constantToRad(21600000);
      const swAng = sw11 > 0 ? sw11 : sw12;
      const iswAng = -swAng;
      const wt1 = wd2 * Math.sin(stAng);
      const ht1 = hd2 * Math.cos(stAng);
      const wt3 = wd2 * Math.sin(istAng);
      const ht3 = hd2 * Math.cos(istAng);
      const dx1 = wd2 * Math.cos(Math.atan2(wt1, ht1));
      const dy1 = hd2 * Math.sin(Math.atan2(wt1, ht1));
      const dx3 = wd2 * Math.cos(Math.atan2(wt3, ht3));
      const dy3 = hd2 * Math.sin(Math.atan2(wt3, ht3));
      const x1 = hc + dx1;
      const y1 = vc + dy1;
      const x3 = hc + dx3;
      const y3 = vc + dy3;
      const dr = (ss * a3) / 100000;
      const iwd2 = wd2 - dr;
      const ihd2 = hd2 - dr;
      const wt2 = iwd2 * Math.sin(istAng);
      const ht2 = ihd2 * Math.cos(istAng);
      const wt4 = iwd2 * Math.sin(stAng);
      const ht4 = ihd2 * Math.cos(stAng);
      const dx2 = iwd2 * Math.cos(Math.atan2(wt2, ht2));
      const dy2 = ihd2 * Math.sin(Math.atan2(wt2, ht2));
      const dx4 = iwd2 * Math.cos(Math.atan2(wt4, ht4));
      const dy4 = ihd2 * Math.sin(Math.atan2(wt4, ht4));
      const x2 = hc + dx2;
      const y2 = vc + dy2;
      const x4 = hc + dx4;
      const y4 = vc + dy4;
      const sw0 = constantToRad(21600000) - stAng;
      const da1 = swAng - sw0;
      const g1 = Math.max(x1, x2);
      const g2 = Math.max(x3, x4);
      const g3 = Math.max(g1, g2);
      const ir = da1 > 0 ? r : g3;
      const sw1 = SHAPE_CONST.cd4 - stAng;
      const sw2 = constantToRad(27000000) - stAng;
      const sw3 = sw1 > 0 ? sw1 : sw2;
      const da2 = swAng - sw3;
      const g5 = Math.max(y1, y2);
      const g6 = Math.max(y3, y4);
      const g7 = Math.max(g5, g6);
      const ib = da2 > 0 ? b : g7;
      const sw4 = SHAPE_CONST.cd2 - stAng;
      const sw5 = constantToRad(32400000) - stAng;
      const sw6 = sw4 > 0 ? sw4 : sw5;
      const da3 = swAng - sw6;
      const g9 = Math.min(x1, x2);
      const g10 = Math.min(x3, x4);
      const g11 = Math.min(g9, g10);
      const il = da3 > 0 ? l : g11;
      const sw7 = SHAPE_CONST['3cd4'] - stAng;
      const sw8 = constantToRad(37800000) - stAng;
      const sw9 = sw7 > 0 ? sw7 : sw8;
      const da4 = swAng - sw9;
      const g13 = Math.min(y1, y2);
      const g14 = Math.min(y3, y4);
      const g15 = Math.min(g13, g14);
      const it = da4 > 0 ? t : g15;

      const dMain = path();
      dMain.moveTo(x1, y1);
      ellipseArcTo(dMain, wd2, hd2, stAng, swAng, x1, y1);
      dMain.lineTo(x2, y2);
      ellipseArcTo(dMain, iwd2, ihd2, istAng, iswAng, x2, y2);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'foldedCorner': {
      const a = pin(0, adj1, 50000);
      const dy2 = (ss * a) / 100000;
      const dy1 = dy2 / 5;
      const x1 = r - dy2;
      const x2 = x1 + dy1;
      const y2 = b - dy2;
      const y1 = y2 + dy1;

      const dPaper = path();
      dPaper.moveTo(l, t);
      dPaper.lineTo(r, t);
      dPaper.lineTo(r, y2);
      dPaper.lineTo(x1, b);
      dPaper.lineTo(l, b);
      dPaper.closePath();

      const dCorner = path();
      dCorner.moveTo(x1, b);
      dCorner.lineTo(x2, y1);
      dCorner.lineTo(r, y2);
      dCorner.closePath();

      const dOutline = path();
      dOutline.moveTo(x1, b);
      dOutline.lineTo(x2, y1);
      dOutline.lineTo(r, y2);
      dOutline.lineTo(x1, b);
      dOutline.lineTo(l, b);
      dOutline.lineTo(l, t);
      dOutline.lineTo(r, t);
      dOutline.lineTo(r, y2);

      return {
        paths: [
          { d: dPaper.toString(), stroke: 'false' },
          { d: dCorner.toString(), stroke: 'false', fillModifier: 'darkenLess' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l, t, r, b: y2 },
      };
    }
    case 'smileyFace': {
      const a = pin(-4653, adj1, 4653);
      const x1 = (w * 4969) / 21699;
      const x2 = (w * 6215) / 21600;
      const x3 = (w * 13135) / 21600;
      const x4 = (w * 16640) / 21600;
      const y1 = (h * 7570) / 21600;
      const y3 = (h * 16515) / 21600;
      const dy2 = (h * a) / 100000;
      const y2 = y3 - dy2;
      const y4 = y3 + dy2;
      const dy3 = (h * a) / 50000;
      const y5 = y4 + dy3;
      const idx = wd2 * Math.cos(constantToRad(2700000));
      const idy = hd2 * Math.sin(constantToRad(2700000));
      const il = hc - idx;
      const ir = hc + idx;
      const it = vc - idy;
      const ib = vc + idy;
      const wR = (w * 1125) / 21600;
      const hR = (h * 1125) / 21600;

      const dHead = path();
      dHead.moveTo(l, vc);
      ellipseArcTo(dHead, wd2, hd2, 'cd2', 'cd', l, vc);
      dHead.closePath();

      const dEyes = path();
      dEyes.moveTo(x2, y1);
      ellipseArcTo(dEyes, wR, hR, SHAPE_CONST.cd2, 'cd', x2, y1);
      dEyes.moveTo(x3, y1);
      ellipseArcTo(dEyes, wR, hR, SHAPE_CONST.cd2, 'cd', x3, y1);

      const dMouth = path();
      dMouth.moveTo(x1, y2);
      dMouth.quadraticCurveTo(hc, y5, x4, y2);

      const dOutline = dHead;

      return {
        paths: [
          { d: dHead.toString(), stroke: 'false' },
          { d: dEyes.toString(), fillModifier: 'darkenLess' },
          { d: dMouth.toString(), fill: 'none' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'heart': {
      const dx1 = (w * 49) / 48;
      const dx2 = (w * 10) / 48;
      const x1 = hc - dx1;
      const x2 = hc - dx2;
      const x3 = hc + dx2;
      const x4 = hc + dx1;
      const y1 = -h / 3;
      const il = w / 6;
      const ir = (w * 5) / 6;
      const ib = (h * 2) / 3;

      const d = path();
      d.moveTo(hc, hd4);
      d.bezierCurveTo(x3, y1, x4, hd4, hc, b);
      d.bezierCurveTo(x1, hd4, x2, y1, hc, hd4);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: hd4, r: ir, b: ib } };
    }
    case 'lightningBolt': {
      const x1 = (w * 5022) / 21600;
      const x2 = (w * 11050) / 21600;
      const x3 = (w * 8472) / 21600;
      const x4 = (w * 8757) / 21600;
      const x5 = (w * 10012) / 21600;
      const x6 = (w * 14767) / 21600;
      const x7 = (w * 12222) / 21600;
      const x8 = (w * 12860) / 21600;
      const x9 = (w * 13917) / 21600;
      const x10 = (w * 7602) / 21600;
      const x11 = (w * 16577) / 21600;
      const y1 = (h * 3890) / 21600;
      const y2 = (h * 6080) / 21600;
      const y3 = (h * 6797) / 21600;
      const y4 = (h * 7437) / 21600;
      const y5 = (h * 12877) / 21600;
      const y6 = (h * 9705) / 21600;
      const y7 = (h * 12007) / 21600;
      const y8 = (h * 13987) / 21600;
      const y9 = (h * 8382) / 21600;
      const y10 = (h * 14277) / 21600;
      const y11 = (h * 14915) / 21600;

      const d = path();
      d.moveTo(x3, t);
      d.lineTo(x8, y2);
      d.lineTo(x2, y3);
      d.lineTo(x11, y7);
      d.lineTo(x6, y5);
      d.lineTo(r, b);
      d.lineTo(x5, y11);
      d.lineTo(x7, y8);
      d.lineTo(x1, y6);
      d.lineTo(x10, y9);
      d.lineTo(l, y1);
      d.closePath();

      return { paths: [{ d: d.toString() }], textBounds: { l: x4, t: y4, r: x9, b: y10 } };
    }
    case 'sun': {
      const a = pin(12500, adj1, 46875);
      const g0 = 50000 - a;
      const g1 = (g0 * 30274) / 32768;
      const g2 = (g0 * 12540) / 32768;
      const g5 = 50000 - g1;
      const g6 = 50000 - g2;
      const g7 = (g0 * 23170) / 32768;
      const g8 = 50000 + g7;
      const g9 = 50000 - g7;
      const g10 = (g5 * 3) / 4;
      const g11 = (g6 * 3) / 4;
      const g12 = g10 + 3662;
      const g13 = g11 + 3662;
      const g14 = g11 + 12500;
      const g15 = 100000 - g10;
      const g16 = 100000 - g12;
      const g17 = 100000 - g13;
      const g18 = 100000 - g14;
      const ox1 = (w * 18436) / 21600;
      const oy1 = (h * 3163) / 21600;
      const ox2 = (w * 3163) / 21600;
      const oy2 = (h * 18436) / 21600;
      const x8 = (w * g8) / 100000;
      const x9 = (w * g9) / 100000;
      const x10 = (w * g10) / 100000;
      const x12 = (w * g12) / 100000;
      const x13 = (w * g13) / 100000;
      const x14 = (w * g14) / 100000;
      const x15 = (w * g15) / 100000;
      const x16 = (w * g16) / 100000;
      const x17 = (w * g17) / 100000;
      const x18 = (w * g18) / 100000;
      const x19 = (w * a) / 100000;
      const wR = (w * g0) / 100000;
      const hR = (h * g0) / 100000;
      const y8 = (h * g8) / 100000;
      const y9 = (h * g9) / 100000;
      const y10 = (h * g10) / 100000;
      const y12 = (h * g12) / 100000;
      const y13 = (h * g13) / 100000;
      const y14 = (h * g14) / 100000;
      const y15 = (h * g15) / 100000;
      const y16 = (h * g16) / 100000;
      const y17 = (h * g17) / 100000;
      const y18 = (h * g18) / 100000;

      const dRays = path();
      //Right
      dRays.moveTo(r, vc);
      dRays.lineTo(x15, y18);
      dRays.lineTo(x15, y14);
      dRays.closePath();
      //Top-Right
      dRays.moveTo(ox1, oy1);
      dRays.lineTo(x16, y13);
      dRays.lineTo(x17, y12);
      dRays.closePath();
      //Top
      dRays.moveTo(hc, t);
      dRays.lineTo(x18, y10);
      dRays.lineTo(x14, y10);
      dRays.closePath();
      //Top-Left
      dRays.moveTo(ox2, oy1);
      dRays.lineTo(x13, y12);
      dRays.lineTo(x12, y13);
      dRays.closePath();
      //Left
      dRays.moveTo(l, vc);
      dRays.lineTo(x10, y14);
      dRays.lineTo(x10, y18);
      dRays.closePath();
      //Bottom-Left
      dRays.moveTo(ox2, oy2);
      dRays.lineTo(x12, y17);
      dRays.lineTo(x13, y16);
      dRays.closePath();
      //Bottom
      dRays.moveTo(hc, b);
      dRays.lineTo(x14, y15);
      dRays.lineTo(x18, y15);
      dRays.closePath();
      //Bottom-Right
      dRays.moveTo(ox1, oy2);
      dRays.lineTo(x17, y16);
      dRays.lineTo(x16, y17);
      dRays.closePath();

      const dCenter = path();
      dCenter.moveTo(x19, vc);
      ellipseArcTo(dCenter, wR, hR, 'cd2', 360, x19, vc);
      dCenter.closePath();

      return {
        paths: [{ d: dRays.toString() }, { d: dCenter.toString() }],
        textBounds: { l: x9, t: y9, r: x8, b: y8 },
      };
    }
    case 'moon': {
      const a = pin(0, adj1, 87500);
      const g0 = (ss * a) / 100000;
      const g0w = (g0 * w) / ss;
      const g1 = ss - g0;
      const g2 = (g0 * g0) / g1;
      const g3 = (ss * ss) / g1;
      const g4 = g3 * 2;
      const g5 = g4 - g2;
      const g6 = g5 - g0;
      const g6w = (g6 * w) / ss;
      const g7 = g5 / 2;
      const g8 = g7 - g0;
      const dy1 = (g8 * hd2) / ss;
      const g12 = (g0 * 9598) / 32768;
      const g12w = (g12 * w) / ss;
      const g13 = ss - g12;
      const q1 = ss * ss;
      const q2 = g13 * g13;
      const q3 = q1 - q2;
      const q4 = Math.sqrt(q3);
      const dy4 = (q4 * hd2) / ss;
      const g15h = vc - dy4;
      const g16h = vc + dy4;
      const g17w = g6w - g0w;
      const g18w = g17w / 2;
      const dx2p = g0w + g18w - w;
      const dx2 = dx2p * -1;
      const dy2 = hd2 * -1;
      const stAng1 = Math.atan2(dy2, dx2);
      const enAngp1 = Math.atan2(hd2, dx2);
      const enAng1 = enAngp1 - constantToRad(21600000);
      const swAng1 = enAng1 - stAng1;

      const dMain = path();
      dMain.moveTo(r, b);
      const outerArc = ellipseArcTo(dMain, w, hd2, 'cd4', 'cd2', r, b);
      ellipseArcTo(dMain, g18w, dy1, stAng1, swAng1, outerArc.eX, outerArc.eY);
      dMain.closePath();

      return {
        paths: [{ d: dMain.toString() }],
        textBounds: { l: g12w, t: g15h, r: g0w, b: g16h },
      };
    }
    case 'cloud': {
      /**
       * Due to angle issues, the same will be drawn as 43200x43200 (based on definitions)
       * After the drawing, scale to the correct size
       */
      const drawW = 43200;
      const drawH = 43200;

      const il = (w * 2977) / 21600;
      const it = (h * 3262) / 21600;
      const ir = (w * 17087) / 21600;
      const ib = (h * 17337) / 21600;

      /**
       * Renaming to allow each ellipseArcTo to be in a single line
       * c2r: constantToRad
       * ca: CloudArc
       */
      const c2r = constantToRad;

      const dCloud = path();
      dCloud.moveTo(3900, 14370);
      const ca1 = ellipseArcTo(dCloud, 6753, 9190, c2r(-11429249), c2r(7426832), 3900, 14370);
      const ca2 = ellipseArcTo(dCloud, 5333, 7267, c2r(-8646143), c2r(5396714), ca1.eX, ca1.eY);
      const ca3 = ellipseArcTo(dCloud, 4365, 5945, c2r(-8748475), c2r(5983381), ca2.eX, ca2.eY);
      const ca4 = ellipseArcTo(dCloud, 4857, 6595, c2r(-7859164), c2r(7034504), ca3.eX, ca3.eY);
      const ca5 = ellipseArcTo(dCloud, 5333, 7273, c2r(-4722533), c2r(6541615), ca4.eX, ca4.eY);
      const ca6 = ellipseArcTo(dCloud, 6775, 9220, c2r(-2776035), c2r(7816140), ca5.eX, ca5.eY);
      const ca7 = ellipseArcTo(dCloud, 5785, 7867, c2r(37501), c2r(6842000), ca6.eX, ca6.eY);
      const ca8 = ellipseArcTo(dCloud, 6752, 9215, c2r(1347096), c2r(6910353), ca7.eX, ca7.eY);
      const ca9 = ellipseArcTo(dCloud, 7720, 10543, c2r(3974558), c2r(4542661), ca8.eX, ca8.eY);
      const ca10 = ellipseArcTo(dCloud, 4360, 5918, c2r(-16496525), c2r(8804134), ca9.eX, ca9.eY);
      ellipseArcTo(dCloud, 4345, 5945, c2r(-14809710), c2r(9151131), ca10.eX, ca10.eY);

      const dCloudOutline = dCloud;

      const dOutline = path();
      dOutline.moveTo(4693, 26177);
      ellipseArcTo(dOutline, 4345, 5945, c2r(5204520), c2r(1585770), 4693, 26177);
      dOutline.moveTo(6928, 34899);
      ellipseArcTo(dOutline, 4360, 5918, c2r(4416628), c2r(686848), 6928, 34899);
      dOutline.moveTo(16478, 39090);
      ellipseArcTo(dOutline, 6752, 9215, c2r(8257449), c2r(844866), 16478, 39090);
      dOutline.moveTo(28827, 34751);
      ellipseArcTo(dOutline, 6752, 9215, c2r(387196), c2r(959901), 28827, 34751);
      dOutline.moveTo(34129, 22954);
      ellipseArcTo(dOutline, 5785, 7867, c2r(-4217541), c2r(4255042), 34129, 22954);
      dOutline.moveTo(41798, 15354);
      ellipseArcTo(dOutline, 5333, 7273, c2r(1819082), c2r(1665090), 41798, 15354);
      dOutline.moveTo(38324, 5426);
      ellipseArcTo(dOutline, 4857, 6595, c2r(-824660), c2r(891534), 38324, 5426);
      dOutline.moveTo(29078, 3952);
      ellipseArcTo(dOutline, 4857, 6595, c2r(-8950887), c2r(1091722), 29078, 3952);
      dOutline.moveTo(22141, 4720);
      ellipseArcTo(dOutline, 4365, 5945, c2r(-9809656), c2r(1061181), 22141, 4720);
      dOutline.moveTo(14000, 5192);
      ellipseArcTo(dOutline, 6753, 9190, c2r(-4002417), c2r(739161), 14000, 5192);
      dOutline.moveTo(4127, 15789);
      ellipseArcTo(dOutline, 6753, 9190, c2r(9459261), c2r(711490), 4127, 15789);

      /**
       * vectorEffect property:
       * This property will assure the border maintains the intended size after scale
       */

      return {
        paths: [
          {
            d: dCloud.toString(),
            stroke: 'false',
            transform: `scale(${w / drawW}, ${h / drawH})`,
            vectorEffect: 'non-scaling-size',
            fillIdSuffix: 'transform',
          },
          {
            d: dCloudOutline.toString(),
            fill: 'none',
            transform: `scale(${w / drawW}, ${h / drawH})`,
            vectorEffect: 'non-scaling-stroke',
          },
          {
            d: dOutline.toString(),
            fill: 'none',
            transform: `scale(${w / drawW}, ${h / drawH})`,
            vectorEffect: 'non-scaling-stroke',
          },
        ],
        textBounds: { l: il, t: it, r: ir, b: ib },
        inverseTransform: { x: drawW / w, y: drawH / h },
      };
    }
    case 'arc': {
      const stAng = constantToRad(pin(0, adj1, 21599999));
      const enAng = constantToRad(pin(0, adj2, 21599999));
      const sw11 = enAng - stAng;
      const sw12 = sw11 + constantToRad(21600000);
      const swAng = sw11 > 0 ? sw11 : sw12;
      const wt1 = wd2 * Math.sin(stAng);
      const ht1 = hd2 * Math.cos(stAng);
      const dx1 = wd2 * Math.cos(Math.atan2(wt1, ht1));
      const dy1 = hd2 * Math.sin(Math.atan2(wt1, ht1));
      const wt2 = wd2 * Math.sin(enAng);
      const ht2 = hd2 * Math.cos(enAng);
      const dx2 = wd2 * Math.cos(Math.atan2(wt2, ht2));
      const dy2 = hd2 * Math.sin(Math.atan2(wt2, ht2));
      const x1 = hc + dx1;
      const y1 = vc + dy1;
      const x2 = hc + dx2;
      const y2 = vc + dy2;
      const sw0 = constantToRad(21600000) - stAng;
      const da1 = swAng - sw0;
      const g1 = Math.max(x1, x2);
      const ir = da1 > 0 ? r : g1;
      const sw1 = SHAPE_CONST.cd4 - stAng;
      const sw2 = constantToRad(27000000) - stAng;
      const sw3 = sw1 > 0 ? sw1 : sw2;
      const da2 = swAng - sw3;
      const g5 = Math.max(y1, y2);
      const ib = da2 > 0 ? b : g5;
      const sw4 = SHAPE_CONST.cd2 - stAng;
      const sw5 = constantToRad(32400000) - stAng;
      const sw6 = sw4 > 0 ? sw4 : sw5;
      const da3 = swAng - sw6;
      const g9 = Math.min(x1, x2);
      const il = da3 > 0 ? l : g9;
      const sw7 = SHAPE_CONST['3cd4'] - stAng;
      const sw8 = constantToRad(37800000) - stAng;
      const sw9 = sw7 > 0 ? sw7 : sw8;
      const da4 = swAng - sw9;
      const g13 = Math.min(y1, y2);
      const it = da4 > 0 ? t : g13;

      const dArc = path();
      dArc.moveTo(x1, y1);
      ellipseArcTo(dArc, wd2, hd2, stAng, swAng, x1, y1);
      dArc.lineTo(hc, vc);
      dArc.closePath();

      const dOutline = path();
      dOutline.moveTo(x1, y1);
      ellipseArcTo(dOutline, wd2, hd2, stAng, swAng, x1, y1);

      return {
        paths: [
          { d: dArc.toString(), stroke: 'false' },
          { d: dOutline.toString(), fill: 'none' },
        ],
        textBounds: { l: il, t: it, r: ir, b: ib },
      };
    }
    case 'bracketPair': {
      const a = pin(0, adj1, 50000);
      const x1 = (ss * a) / 100000;
      const x2 = r - x1;
      const y2 = b - x1;
      const il = (x1 * 29289) / 100000;
      const ir = r - il;
      const ib = b - il;

      const d = path();
      d.moveTo(x1, b);
      ellipseArcTo(d, x1, x1, 'cd4', 'cd4', x1, b);
      d.lineTo(l, x1);
      ellipseArcTo(d, x1, x1, 'cd2', 'cd4', l, x1);
      d.moveTo(x2, t);
      ellipseArcTo(d, x1, x1, '3cd4', 'cd4', x2, t);
      d.lineTo(r, y2);
      ellipseArcTo(d, x1, x1, 0, 'cd4', r, y2);

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: il, r: ir, b: ib } };
    }
    case 'bracePair': {
      const a = pin(0, adj1, 25000);
      const x1 = (ss * a) / 100000;
      const x2 = (ss * a) / 50000;
      const x3 = r - x2;
      const x4 = r - x1;
      const y2 = vc - x1;
      const y3 = vc + x1;
      const y4 = b - x1;
      const it = (x1 * 29289) / 100000;
      const il = x1 + it;
      const ir = r - il;
      const ib = b - it;

      const d = path();
      d.moveTo(x2, b);
      ellipseArcTo(d, x1, x1, 'cd4', 'cd4', x2, b);
      d.lineTo(x1, y3);
      const leftBraceEdgeTopArc = ellipseArcTo(d, x1, x1, 0, constantToRad(-5400000), x1, y3);
      ellipseArcTo(
        d,
        x1,
        x1,
        'cd4',
        constantToRad(-5400000),
        leftBraceEdgeTopArc.eX,
        leftBraceEdgeTopArc.eY,
      );
      d.lineTo(x1, x1);
      ellipseArcTo(d, x1, x1, 'cd2', 'cd4', x1, x1);
      d.moveTo(x3, t);
      ellipseArcTo(d, x1, x1, '3cd4', 'cd4', x3, t);
      d.lineTo(x4, y2);
      const rightBraceEdgeTopArc = ellipseArcTo(d, x1, x1, 'cd2', constantToRad(-5400000), x4, y2);
      ellipseArcTo(
        d,
        x1,
        x1,
        '3cd4',
        constantToRad(-5400000),
        rightBraceEdgeTopArc.eX,
        rightBraceEdgeTopArc.eY,
      );
      d.lineTo(x4, y4);
      ellipseArcTo(d, x1, x1, 0, 'cd4', x4, y4);

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r: ir, b: ib } };
    }
    case 'leftBracket': {
      const maxAdj = (50000 * h) / ss;
      const a = pin(0, adj1, maxAdj);
      const y1 = (ss * a) / 100000;
      const dx1 = w * Math.cos(constantToRad(2700000));
      const dy1 = y1 * Math.sin(constantToRad(2700000));
      const il = r - dx1;
      const it = y1 - dy1;
      const ib = b + dy1 - y1;

      const d = path();
      d.moveTo(r, b);
      ellipseArcTo(d, w, y1, 'cd4', 'cd4', r, b);
      d.lineTo(l, y1);
      ellipseArcTo(d, w, y1, 'cd2', 'cd4', l, y1);

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r, b: ib } };
    }
    case 'rightBracket': {
      const maxAdj = (50000 * h) / ss;
      const a = pin(0, adj1, maxAdj);
      const y1 = (ss * a) / 100000;
      const y2 = b - y1;
      const dx1 = w * Math.cos(constantToRad(2700000));
      const dy1 = y1 * Math.sin(constantToRad(2700000));
      const ir = l + dx1;
      const it = y1 - dy1;
      const ib = b + dy1 - y1;

      const d = path();
      d.moveTo(l, t);
      ellipseArcTo(d, w, y1, '3cd4', 'cd4', l, t);
      d.lineTo(r, y2);
      ellipseArcTo(d, w, y1, 0, 'cd4', r, y2);

      return { paths: [{ d: d.toString() }], textBounds: { l, t: it, r: ir, b: ib } };
    }
    case 'leftBrace': {
      const a2 = pin(0, adj2, 100000);
      const q1 = 100000 - a2;
      const q2 = Math.min(q1, a2);
      const q3 = q2 / 2;
      const maxAdj1 = (q3 * h) / ss;
      const a1 = pin(0, adj1, maxAdj1);
      const y1 = (ss * a1) / 100000;
      const y3 = (h * a2) / 100000;
      const y4 = y3 + y1;
      const dx1 = wd2 * Math.cos(constantToRad(2700000));
      const dy1 = y1 * Math.sin(constantToRad(2700000));
      const il = r - dx1;
      const it = y1 - dy1;
      const ib = b + dy1 - y1;

      const d = path();
      d.moveTo(r, b);
      ellipseArcTo(d, wd2, y1, 'cd4', 'cd4', r, b);
      d.lineTo(hc, y4);
      const braceEdgeTopArc = ellipseArcTo(d, wd2, y1, 0, constantToRad(-5400000), hc, y4);
      ellipseArcTo(
        d,
        wd2,
        y1,
        'cd4',
        constantToRad(-5400000),
        braceEdgeTopArc.eX,
        braceEdgeTopArc.eY,
      );
      d.lineTo(hc, y1);
      ellipseArcTo(d, wd2, y1, 'cd2', 'cd4', hc, y1);

      return { paths: [{ d: d.toString() }], textBounds: { l: il, t: it, r, b: ib } };
    }
    case 'rightBrace': {
      const a2 = pin(0, adj2, 100000);
      const q1 = 100000 - a2;
      const q2 = Math.min(q1, a2);
      const q3 = q2 / 2;
      const maxAdj1 = (q3 * h) / ss;
      const a1 = pin(0, adj1, maxAdj1);
      const y1 = (ss * a1) / 100000;
      const y3 = (h * a2) / 100000;
      const y2 = y3 - y1;
      const y4 = b - y1;
      const dx1 = wd2 * Math.cos(constantToRad(2700000));
      const dy1 = y1 * Math.sin(constantToRad(2700000));
      const ir = l + dx1;
      const it = y1 - dy1;
      const ib = b + dy1 - y1;

      const d = path();
      d.moveTo(l, t);
      ellipseArcTo(d, wd2, y1, '3cd4', 'cd4', l, t);
      d.lineTo(hc, y2);
      const braceEdgeTopArc = ellipseArcTo(d, wd2, y1, 'cd2', constantToRad(-5400000), hc, y2);
      ellipseArcTo(
        d,
        wd2,
        y1,
        '3cd4',
        constantToRad(-5400000),
        braceEdgeTopArc.eX,
        braceEdgeTopArc.eY,
      );
      d.lineTo(hc, y4);
      ellipseArcTo(d, wd2, y1, 0, 'cd4', hc, y4);

      return { paths: [{ d: d.toString() }], textBounds: { l, t: it, r: ir, b: ib } };
    }
  }
};

export default generateBlockArrowPath;
