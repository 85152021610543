import { Proofing } from '../../models';
import BaseController from '../BaseController';
export class ProofingController extends BaseController {
  private proofing?: Proofing;

  start(documentId?: string): void {
    this.proofing = this.Data.models?.get(this.Data?.models.TYPE_NAME.PROOFING, `PR${documentId}`);
    this.proofing?.on('LOADED', (data) => {
      if (data) {
        this.Data.events?.emit('UPDATE_WORD_COUNT', data.summary);
      }
    });
    this.proofing?.on('UPDATED', (data) => {
      if (data) {
        this.Data.events?.emit('UPDATE_WORD_COUNT', data.summary);
      }
    });
  }

  stop(): void {}

  destroy(): void {
    if (this.proofing) {
      this.Data.models?.disposeModel(this.Data?.models.TYPE_NAME.PROOFING, this.proofing.id);
      delete this.proofing;
    }
  }

  getCountSelectionElements(range: Editor.Selection.RangeData[]) {
    return new Promise<Editor.Data.Proofing.FullSummary>((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'COUNT:SELECTED:ELEMENTS',
        { range },
        (response: Realtime.Transport.RealtimeResponse) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  getCountWords() {
    return this.proofing?.getWordCount();
  }
}
