import { Dropdown, Toggle, Tooltip, usePopper } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from '_common/hooks';
import { openModal } from '_common/modals/ModalsSlice';

type FooterHelpProps = {
  resetOnboarding: () => void;
};

const FooterHelp = ({ resetOnboarding }: FooterHelpProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isOpen, popperProps, referenceProps } = usePopper();
  const handleOpenAboutModal = () => {
    dispatch(openModal('AboutModal'));
  };
  return (
    <>
      <Tooltip
        content={intl.formatMessage({ id: 'HELP_AND_SUPPORT' })}
        placement="left"
        disabled={popperProps.isOpen}
        testId="help-tooltip"
      >
        <Toggle
          size="medium"
          variant="ghost"
          icon={isOpen ? 'HelpBlue' : 'HelpGrey'}
          isToggled={isOpen}
          testId="help-toggle"
          {...referenceProps}
        />
      </Tooltip>
      <Dropdown {...popperProps} testId="help-support-dropdown">
        <Dropdown.Item size="medium" onClick={handleOpenAboutModal} testId="help-open-about-modal">
          <FormattedMessage id="ABOUT_DODOC" />
        </Dropdown.Item>
        <Dropdown.Item size="medium" onClick={resetOnboarding} testId="help-reset-onboarding">
          <FormattedMessage id="RESET_ONBOARDING" />
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default FooterHelp;
