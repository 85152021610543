import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Link, Button, Card, usePopper, Dropdown, Toggle } from 'dodoc-design-system';
import type { ButtonProps } from 'dodoc-design-system/build/types/Components/Button/Button';

import { notify } from '_common/components/ToastSystem';
import EditorManager from 'Editor/services/EditorManager';
import { CITATION_PRIORITY } from 'Editor/services/consts';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectDocumentCitations } from 'Editor/redux/CitationsSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import { getSanitizedEntityName } from '_common/modals/CitationsModal/utils';
import { ENTRIES } from '_common/modals/CitationsModal/CitationInformation/CitationInformation';
import PageLayoutTooltip from '../PageLayoutTooltip/PageLayoutTooltip';

import styles from './ReferenceCard.module.scss';

export type ReferenceCardProps = {
  citationId: Citation['id'];

  edit: (id: Citation['id']) => void;
  insert: (id: Citation['id']) => void;
} & (
  | {
      isDocumentList?: false;
      handleCitationFocus?: undefined;
      remove?: undefined;
    }
  | {
      isDocumentList: true;
      handleCitationFocus: (id: Citation['id']) => void;
      remove: (id: Citation['id']) => void;
    }
);

const ReferenceCardContent = ({ citationId, edit, insert, ...props }: ReferenceCardProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const order = useSelector((state) => state.editor.citations.order);
  const citations = useSelector((state) => state.editor.citations.citations);
  const selected = useSelector((state) => state.editor.citations.selected);
  const docCitations = useSelector(selectDocumentCitations).dict;
  const citationsDict = { ...docCitations, ...citations };
  const citation = citationsDict[citationId];

  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isOpen, popperProps, referenceProps } = usePopper({
    disabled: isReadOnlyMode,
    placement: 'bottom-end',
  });

  const entryHasField = (citation: Citation, field: Citation.Field) => {
    const entry =
      citation.entry && citation.entry in ENTRIES ? (citation.entry as Citation.Entry) : undefined;
    if (entry && ENTRIES[entry].some((supportedField) => supportedField === field)) {
      return !!citation[field];
    }

    return false;
  };

  const handleChangePriority = (priority: Citation['priority']) => {
    EditorManager.getInstance().defineCitationPriority(citation.id, priority);
  };

  const getAuthor = (citation: Citation) => {
    if (
      citation.entry === 'data_file' ||
      citation.entry === 'package_insert' ||
      citation.entry === 'press_release'
    ) {
      return '';
    }

    if (citation.author) {
      return citation.author
        .map((author) => getSanitizedEntityName(author))
        .filter((authorName) => authorName)
        .join(', ');
    }

    return (
      <span className={styles.emptyData}>
        <FormattedMessage id="editor.templatePreview.properties.authors.noAuthors" />
      </span>
    );
  };

  const getPriorityIcon = (priority: Citation['priority']) => {
    switch (priority) {
      case CITATION_PRIORITY.HIGH:
        return 'High';
      case CITATION_PRIORITY.MEDIUM:
        return 'Medium';
      case CITATION_PRIORITY.LOW:
        return 'Low';
      default:
        return 'Medium';
    }
  };

  const getEmptyHeader = (citation: Citation) => {
    switch (citation.entry) {
      case 'webpage':
        return <FormattedMessage id="NO_WEB_PAGE_TITLE" />;
      case 'book':
        return <FormattedMessage id="NO_CHAPTER_TITLE" />;
      case 'data_file':
        return <FormattedMessage id="NO_INTERNAL_DOCUMENT_TITLE" />;
      case 'package_insert':
        return <FormattedMessage id="NO_PRODUCT_TRADE_NAME" />;
      default:
        return <FormattedMessage id="global.noTitle" />;
    }
  };

  const getEmptyBody = (citation: Citation) => {
    switch (citation.entry) {
      case 'journal':
      case 'ejournal':
      case 'abstract':
        return <FormattedMessage id="NO_JOURNAL_TITLE" />;
      case 'book':
        return <FormattedMessage id="NO_BOOK_TITLE" />;
      case 'inbook':
      case 'webpage':
        return <FormattedMessage id="NO_SOURCE_TITLE" />;
      case 'article':
      case 'report':
      case 'proceedings':
      case 'misc':
        return <FormattedMessage id="NO_PUBLISHER" />;
      case 'package_insert':
        return <FormattedMessage id="NO_MANUFACTURER" />;
      case 'press_release':
        return <FormattedMessage id="NO_SOURCE_PRESS_RELEASE" />;
      default:
    }
  };

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div title={citation.title} className={styles.title}>
          {props.isDocumentList && <span className={styles.id}>{`#${citation.serial}`}</span>}
          {citation.title ? (
            citation.title
          ) : (
            <span className={styles.noTitle}>{getEmptyHeader(citation)}</span>
          )}
        </div>
        <>
          <PageLayoutTooltip
            content={intl.formatMessage({
              id: isReadOnlyMode ? 'CHANGE_PRIORITY_CARD' : 'global.priority',
            })}
            type="reference"
            testId={`citation-card-${citationId}-priority-tooltip`}
          >
            <Toggle
              variant="link"
              disabled={isReadOnlyMode || isPageLayout}
              size="small"
              isToggled={isOpen}
              icon={getPriorityIcon(citation.priority)}
              {...referenceProps}
              testId={`citation-card-${citationId}-priority-toggle`}
            />
          </PageLayoutTooltip>
          <Dropdown {...popperProps} testId="priority-dropdown">
            <Dropdown.Item
              prefixIcon={getPriorityIcon(CITATION_PRIORITY.HIGH)}
              onClick={() => handleChangePriority(CITATION_PRIORITY.HIGH)}
              testId="high-dropdown-item"
            >
              <FormattedMessage id="editor.sidebar.review.filter.priority.high" />
            </Dropdown.Item>
            <Dropdown.Item
              prefixIcon={getPriorityIcon(CITATION_PRIORITY.MEDIUM)}
              onClick={() => handleChangePriority(CITATION_PRIORITY.MEDIUM)}
              testId="medium-dropdown-item"
            >
              <FormattedMessage id="editor.sidebar.review.filter.priority.medium" />
            </Dropdown.Item>
            <Dropdown.Item
              prefixIcon={getPriorityIcon(CITATION_PRIORITY.LOW)}
              onClick={() => handleChangePriority(CITATION_PRIORITY.LOW)}
              testId="low-dropdown-item"
            >
              <FormattedMessage id="editor.sidebar.review.filter.priority.low" />
            </Dropdown.Item>
          </Dropdown>
        </>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={styles.information}>
        <div>
          {getAuthor(citation)}
          {entryHasField(citation, 'year') && ` (${citation.year}).`}
        </div>
        <div>
          {citation.entry !== 'data_file' &&
            ((entryHasField(citation, 'source_title') && citation.source_title) ||
              (entryHasField(citation, 'publisher') && citation.publisher) ||
              (entryHasField(citation, 'organization') && citation.organization) || (
                <span className={styles.emptyData}>{getEmptyBody(citation)}</span>
              ))}
        </div>
        {entryHasField(citation, 'url') && (
          <Link href={citation.url} size="small" testId="see-full-text-link">
            <FormattedMessage id="SEE_FULL_TEXT" />
          </Link>
        )}
        {/*@ts-expect-error APISpec: CitationSchema is missing 'source'*/}
        {entryHasField(citation, 'source') && citation.source === 'doc' && (
          <div className={styles.imported}>
            <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
          </div>
        )}
      </div>
    );
  };

  const handleRemove: ButtonProps<'small'>['onClick'] = (e) => {
    if (props.isDocumentList) {
      e.stopPropagation();
      props.remove(citationId);
    } else {
      if (order.includes(citationId)) {
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              headerType: 'error',
              title: 'editor.modals.citations.removeReference',
              message: 'REMOVING_THIS_REFERENCE_WILL_REMOVE_FROM_DOCUMENT_CONFIRM',
              confirmButtonTextId: 'editor.modals.citations.removeReference',
              confirmButtonType: 'danger',
              cancelButtonTextId: 'global.cancel',
              cancelButtonShow: true,
              actionCode: 'removeReferenceLibrary',
              actionValue: { citationId },
              width: '60rem',
            },
          }),
        );
      } else {
        EditorManager.getInstance()
          .removeCitationFromLibrary(citationId)
          .then(() => {
            notify({
              type: 'success',
              title: 'REFERENCE_REMOVED',
              message: 'REFERENCE_REMOVED_MESSAGE',
            });
          });
      }
    }
  };

  const renderFooter = () => (
    <>
      <div style={{ marginRight: 'auto' }}>
        <PageLayoutTooltip type="reference" testId={`citation-card-${citationId}-edit-tooltip`}>
          <Button
            variant="link"
            disabled={isReadOnlyMode || isPageLayout}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              edit(citationId);
              buttonRef.current?.blur();
            }}
            ref={buttonRef}
            testId={`citation-card-${citationId}-edit-button`}
          >
            <FormattedMessage id="global.edit" />
          </Button>
        </PageLayoutTooltip>
        <PageLayoutTooltip type="reference" testId={`citation-card-${citationId}-remove-tooltip`}>
          <Button
            variant="link"
            disabled={isReadOnlyMode || isPageLayout}
            margin="0 0 0 1rem"
            size="small"
            onClick={(e) => {
              handleRemove(e);
            }}
            testId={`citation-card-${citationId}-remove-button`}
          >
            <FormattedMessage id="global.remove" />
          </Button>
        </PageLayoutTooltip>
      </div>
      <PageLayoutTooltip type="reference" testId={`citation-card-${citationId}-insert-tooltip`}>
        <Button
          variant="primary"
          disabled={isReadOnlyMode || isPageLayout}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            insert(citationId);
          }}
          testId={`citation-card-${citationId}-insert-button`}
        >
          <FormattedMessage id="global.insert" />
        </Button>
      </PageLayoutTooltip>
    </>
  );

  return (
    <Card
      id={`Citation#${citationId}`}
      testId={`Citation#${citationId}`}
      onClick={props.isDocumentList ? () => props.handleCitationFocus(citationId) : undefined}
      sidebar
      width="100%"
      selected={selected === citationId}
    >
      <Card.Header size="small">{renderHeader()}</Card.Header>
      {citation.entry === 'data_file' && !citation.url ? '' : <Card.Body>{renderBody()}</Card.Body>}
      <Card.Footer size="large">{renderFooter()}</Card.Footer>
    </Card>
  );
};

export default ReferenceCardContent;
