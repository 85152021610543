import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import { navigateToSignIn } from 'router/history';
import { useUnlockAccountMutation } from '_common/services/api/authority';

import { LoadingPage } from '_common/components';

/**
 * Unlock component.
 * Renders when entering the unlock route.
 *
 * @author    Carlos Oliveira <coliveira@dodoc.com>
 * @copyright 2017 doDOC
 */
const Unlock = () => {
  const { hash } = useParams<{ hash: string }>();
  const timeout = useRef<number>();

  const [unlockAccount] = useUnlockAccountMutation();

  /**
   * Reads the query hash from URL and then dispatches the unlock
   * thunk to try to unlock the user account.
   */
  useEffect(() => {
    if (hash) {
      unlockAccount({ token: hash });
    }
    timeout.current = window.setTimeout(() => {
      navigateToSignIn();
    }, 500);
    return () => {
      /**
       * Clears the timeout on dismount, if there is one
       */
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  /**
   * Renders a LoadingPage component.
   *
   * @return `LoadingPage` component (see {@link LoadingPage}).
   */
  return <LoadingPage />;
};

export default Unlock;
