export default class KeydownEventValidator {
  static DEFAULT_ALLOWED_KEYS = [
    'CapsLock',
    'Fn',
    'FnLock',
    'NumLock',
    'End',
    'Home',
    'PageDown',
    'PageUp',
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12',
    'F13',
    'F14',
    'F15',
    'F16',
    'F17',
    'F18',
    'F19',
    'F20',
    'Control',
  ];

  static ALLOWED_SHORTCUT_KEYS = {
    ctrlKey: true /* { c: 'c', x: 'x', v: 'v', r: 'r', shiftKey: { r: 'r' } } */,
    shiftKey: [],
    altKey: [],
  };

  static OVERRIDED_SHORTCUT_KEYS = {
    ctrlKey: [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Backspace',
      'Delete',
      'Enter',
      'Tab',
      'V',
      'K',
      'k',
    ],
    shiftKey: [],
    altKey: [],
  };

  /**
   * validates if a keydown default action is allowed
   * @param {Event} event
   * @menberof KeydownEventValidator
   */
  static isKeyDefaultAllowed(event) {
    if (event.ctrlKey || event.metaKey) {
      if (typeof KeydownEventValidator.ALLOWED_SHORTCUT_KEYS.ctrlKey === 'boolean') {
        return (
          KeydownEventValidator.ALLOWED_SHORTCUT_KEYS.ctrlKey &&
          !KeydownEventValidator.OVERRIDED_SHORTCUT_KEYS.ctrlKey.includes(event.key)
        );
      }
      return (
        KeydownEventValidator.ALLOWED_SHORTCUT_KEYS.ctrlKey.includes(event.key) &&
        !KeydownEventValidator.OVERRIDED_SHORTCUT_KEYS.ctrlKey.includes(event.key)
      );
    }
    if (event.shiftKey) {
      return (
        KeydownEventValidator.ALLOWED_SHORTCUT_KEYS.shiftKey.includes(event.key) &&
        !KeydownEventValidator.OVERRIDED_SHORTCUT_KEYS.shiftKey.includes(event.key)
      );
    }
    if (event.altKey) {
      return (
        KeydownEventValidator.ALLOWED_SHORTCUT_KEYS.altKey.includes(event.key) &&
        !KeydownEventValidator.OVERRIDED_SHORTCUT_KEYS.altKey.includes(event.key)
      );
    }
    return KeydownEventValidator.DEFAULT_ALLOWED_KEYS.includes(event.key);
  }

  /**
   * validates if a keydown event is printable
   * @param {Event} event
   * @menberof KeydownEventValidator
   */
  static isKeyPrintable(event) {
    return event.key && event.key.length === 1;
  }
}
