import { useMemo } from 'react';
import ChartBase from '../ChartBase/ChartBase';
import { useChartAxis, useChartPlotArea } from '../hooks';
import { useCandlestickSeries, useStockSeries } from '../series';

const useSeries = ({ shape }: { shape: Presentation.Data.ChartShape }) => {
  /*
   * When there is more than 1 axis, the stock/candlestick axis will always be the one on the right
   * Otherwise, it will be the only existing axis in its defined position
   */
  const yAxis = shape.chartSpace.chart.plotArea.chartAxes.find((axis) => axis.axPos === 'r');

  const yAxisId = useMemo(() => {
    return yAxis != null ? `${yAxis.axId}` : undefined;
  }, [yAxis]);

  const stockSeries = useStockSeries({
    shape,
    yAxisId,
  });

  const candleStickSeries = useCandlestickSeries({
    shape,
    yAxisId,
  });

  const chartTypes = shape.chartSpace.chart.plotArea.chartTypes;

  if (!chartTypes?.find((chart) => chart.type === 'stock')) {
    return undefined;
  }

  return [...stockSeries, ...candleStickSeries];
};

const StockChart = ({ shape }: { shape: Presentation.Data.ChartShape }) => {
  const axis = useChartAxis(shape);
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: grid,
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        series,
      }}
    />
  );
};

export default StockChart;
