import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Checkbox } from 'dodoc-design-system';

import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

const ExportModal = () => {
  const [includeTasks, setIncludeTasks] = useState(true);

  const modalData = useModalData('ExportModal');
  if (!modalData) {
    return null;
  }

  const { objectName, message, checkboxMessage, onExport } = modalData;

  const close = () => {
    closeModal('ExportModal');
  };

  const handleExport = () => {
    onExport(includeTasks);
    close();
  };

  return (
    <Modal width="62rem" open onClose={close} testId="export-pdf">
      <Modal.Header onClose={close}>
        <FormattedMessage id={'EXPORT'} values={{ name: objectName }} />
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormattedMessage id={message} values={{ name: objectName }} />
          <Checkbox
            checked={includeTasks ? 'checked' : 'unchecked'}
            onChange={() => setIncludeTasks(!includeTasks)}
            margin="1rem 0 1rem 0"
            testId="export-pdf-include-tasks"
          >
            <FormattedMessage id={checkboxMessage} />
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="export-pdf-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleExport}
          testId="export-pdf-submit-button"
        >
          <FormattedMessage id="global.export" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportModal;
