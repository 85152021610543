import { useState } from 'react';

import { useGetReferenceStylesListQuery } from '../ReferenceStylesApi';

import { FilterDisplay, IntlErrorBoundary } from '_common/components';

import RefStyleHeading from './RefStyleHeading';
import RefStyleTable from './RefStyleTable/RefStyleTable';

import styles from './RefStyleSection.module.scss';

export type ReferencesStylesProps = {
  category: string;
  testId: string;
};

const RefStyleSection = ({ category, testId }: ReferencesStylesProps) => {
  const { isLoading } = useGetReferenceStylesListQuery();

  const [search, setSearch] = useState('');

  const renderContent = () => {
    return (
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <FilterDisplay identity={`referenceStyles-${category}`} />
        <RefStyleTable category={category} search={search} />
      </IntlErrorBoundary>
    );
  };

  return (
    <div className={styles.root}>
      <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
        <RefStyleHeading
          category={category}
          search={search}
          onSearchChange={setSearch}
          testId={`${testId}-heading`}
        />
      </IntlErrorBoundary>
      {!isLoading && renderContent()}
    </div>
  );
};

export default RefStyleSection;
