import { useSelector } from '_common/hooks';
import TrackingState from './TrackingState';
import styles from './ToolbarToggles.module.scss';

const ToolbarToggles = () => {
  const versionHistory = useSelector((state) => state.editor.status.versionHistory);

  return <div className={styles.root}>{!versionHistory && <TrackingState />}</div>;
};

export default ToolbarToggles;
