import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip, Button, InputField, Input } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { paths } from '_types/api';

import {
  getDocumentObject,
  selectReadOnlyMode,
  addMetaInformation,
  removeMetaInformation,
  updateMetaInformation,
} from 'Editor/redux/EditorStatusSlice';

import Affiliation from './Affiliation/Affiliation';

import styles from './Author.module.scss';

type AuthorFormProps = {
  author: Author;
  authorIndex: number;
};

const AuthorForm = ({ author, authorIndex }: AuthorFormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const docObject = useSelector(getDocumentObject);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);

  const [firstName, setFirstName] = useState(author.first_name);
  const [middleName, setMiddleName] = useState(author.middle_name);
  const [lastName, setLastName] = useState(author.last_name);
  const [affiliation, setAffiliation] = useState(author.affiliation);
  const [email, setEmail] = useState(author.email);
  const [phone, setPhone] = useState(author.phone);

  useEffect(() => {
    setAffiliation(author.affiliation);
  }, [author.affiliation]);

  const handleAddMetaInformation = (
    params: paths['/api/object/document/{document_id}/author/information/add']['post']['requestBody']['content']['multipart/form-data'],
  ) => {
    dispatch(addMetaInformation({ documentId: docObject.id, params }));
  };

  const handleRemoveAffiliation = (affiliationIndex: number) => {
    dispatch(
      removeMetaInformation({
        documentId: docObject.id,
        params: {
          field: 'affiliation',
          author: authorIndex,
          index: affiliationIndex,
        },
      }),
    );
  };

  const handleUpdateMetaInformation = ({
    path,
    value,
  }: paths['/api/object/document/{document_id}/author/update']['post']['requestBody']['content']['multipart/form-data']) => {
    dispatch(
      updateMetaInformation({
        documentId: docObject.id,
        params: { path: `${authorIndex}.${path}`, value },
      }),
    );
  };

  const handleRemoveMetaInformation = (
    params: paths['/api/object/document/{document_id}/author/information/remove']['post']['requestBody']['content']['multipart/form-data'],
  ) => {
    dispatch(removeMetaInformation({ documentId: docObject.id, params }));
  };

  return (
    <div className={styles.formContainer}>
      {/* Name */}
      <div className={styles.nameSection}>
        <InputField
          disabled={isReadOnlyMode}
          size="large"
          label={intl.formatMessage({ id: 'FIRST_NAME' })}
          testId="author-first-name-field"
        >
          <Input
            disabled={isReadOnlyMode}
            size="large"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={() => handleUpdateMetaInformation({ path: 'first_name', value: firstName })}
            placeholder="Jane"
            testId="author-first-name"
          />
        </InputField>
        <InputField
          disabled={isReadOnlyMode}
          size="large"
          label={intl.formatMessage({ id: 'MIDDLE_NAME' })}
          testId="author-middle-name-field"
        >
          <Input
            disabled={isReadOnlyMode}
            size="large"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            onBlur={() => handleUpdateMetaInformation({ path: 'middle_name', value: middleName })}
            placeholder="Marie"
            testId="author-middle-name"
          />
        </InputField>
        <InputField
          disabled={isReadOnlyMode}
          size="large"
          label={intl.formatMessage({ id: 'LAST_NAME' })}
          testId="author-last-name-field"
        >
          <Input
            disabled={isReadOnlyMode}
            size="large"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={() => handleUpdateMetaInformation({ path: 'last_name', value: lastName })}
            placeholder="Doe"
            testId="author-last-name"
          />
        </InputField>
      </div>
      {/* Phone Number */}
      <InputField
        disabled={isReadOnlyMode}
        size="large"
        label={intl.formatMessage({ id: 'PHONE_NUMBER' })}
        primaryAction={intl.formatMessage({ id: 'global.add' })}
        primaryActionOnClick={() => {
          const newPhone = [...phone, ''];
          setPhone(newPhone);
          handleAddMetaInformation({ field: 'phone', author: authorIndex, value: '' });
        }}
        testId="author-phone-number"
      >
        {phone.map((element, i) => (
          <div className={styles.elementsContainer} key={i}>
            <Input
              disabled={isReadOnlyMode}
              size="large"
              value={element}
              onChange={(e) => {
                const newPhone = [...phone];
                newPhone[i] = e.target.value;
                setPhone(newPhone);
              }}
              onBlur={() =>
                handleUpdateMetaInformation({
                  path: `phone.${i}`,
                  value: phone[i],
                })
              }
              placeholder="123456789"
              testId={`author-phone-number-${i}`}
            />
            <div className={styles.buttonsContainer}>
              {i !== 0 && (
                <Tooltip
                  content={intl.formatMessage({ id: 'REMOVE_FIELD' })}
                  placement="bottom"
                  testId={`author-phone-number-${i}-remove-field-tooltip`}
                >
                  <span>
                    <Button
                      variant="link"
                      size="large"
                      onClick={() => {
                        const newPhone = [...phone];
                        setPhone(newPhone.filter((element, id) => id !== i));
                        handleRemoveMetaInformation({
                          field: 'phone',
                          author: authorIndex,
                          index: i,
                        });
                      }}
                      icon="RemoveRed"
                      disabled={isReadOnlyMode}
                      testId={`author-phone-number-${i}-remove-field-button`}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </InputField>
      {/* Email */}
      <InputField
        disabled={isReadOnlyMode}
        size="large"
        label={intl.formatMessage({ id: 'EMAIL_ADDRESS' })}
        primaryAction={intl.formatMessage({ id: 'global.add' })}
        primaryActionOnClick={() => {
          const newEmail = [...email, ''];
          setEmail(newEmail);
          handleAddMetaInformation({ field: 'email', author: authorIndex, value: '' });
        }}
        testId="author-email-address"
      >
        {email.map((element, i) => (
          <div className={styles.elementsContainer} key={i}>
            <Input
              disabled={isReadOnlyMode}
              size="large"
              value={element}
              onChange={(e) => {
                const newEmail = [...email];
                newEmail[i] = e.target.value;
                setEmail(newEmail);
              }}
              onBlur={() =>
                handleUpdateMetaInformation({
                  path: `email.${i}`,
                  value: email[i],
                })
              }
              placeholder="accountemail@email.com"
              testId={`author-email-address-${i}`}
            />
            <div className={styles.buttonsContainer}>
              {i !== 0 && (
                <Tooltip
                  content={intl.formatMessage({ id: 'REMOVE_FIELD' })}
                  placement="bottom"
                  testId={`author-email-address-${i}-remove-field-tooltip`}
                >
                  <span>
                    <Button
                      variant="link"
                      size="large"
                      onClick={() => {
                        const newEmail = [...email];
                        setEmail(newEmail.filter((element, id) => id !== i));
                        handleRemoveMetaInformation({
                          field: 'email',
                          author: authorIndex,
                          index: i,
                        });
                      }}
                      icon="RemoveRed"
                      disabled={isReadOnlyMode}
                      testId={`author-email-address-${i}-remove-field-button`}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </InputField>
      {/* Affiliation */}
      <InputField
        feedback={false}
        disabled={isReadOnlyMode}
        size="large"
        label={intl.formatMessage({ id: 'AFFILIATION' })}
        primaryAction={intl.formatMessage({ id: 'global.add' })}
        primaryActionOnClick={() => {
          setAffiliation([...affiliation]);
          handleAddMetaInformation({ field: 'affiliation', author: authorIndex });
        }}
        testId="author-affiliation"
      >
        {affiliation.map((affiliation, i) => (
          <Affiliation
            key={`author-affiliation-${i}`}
            affiliation={affiliation}
            index={i}
            remove={handleRemoveAffiliation}
            updateAffiliation={handleUpdateMetaInformation}
          />
        ))}
      </InputField>
    </div>
  );
};

export default AuthorForm;
