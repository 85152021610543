import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Icon, Link } from 'dodoc-design-system';

import { closeAndResetModal, openAndUpdateModal, openModal } from '_common/modals/ModalsSlice';

import styles from './ValidMetadataWarning.module.scss';
import { setSelectedTab } from '_common/components/TabMenu/TabMenuSlice';

const ValidMetadataWarning = () => {
  const dispatch = useDispatch();
  const explorer = useSelector((state) => state.modals.PublishVeevaModal.explorer);
  const id = useSelector((state) => state.modals.PublishVeevaModal.id);
  const goToMetadata = () => {
    dispatch(closeAndResetModal('PublishVeevaModal'));
    if (explorer) {
      dispatch(
        openAndUpdateModal({
          modal: 'EditMetadata',
          data: {
            id,
            export: true,
          },
        }),
      );
    } else {
      dispatch(setSelectedTab({ menuId: 'documentDetails', selectedTab: 'metadata' }));
      dispatch(openModal('DocumentDetailsModal'));
    }
  };

  return (
    <div className={styles.root}>
      <Icon icon="Warning" size={32} />
      <div className={styles.text}>
        <div>
          <FormattedMessage id="EXPORT_DOCUMENT_TO_VEEVA_WARNING" />
        </div>
        <Link
          size="small"
          onClick={goToMetadata}
          testId="go-to-document-metadata-link"
          margin="0.5rem 0"
        >
          <FormattedMessage id="GO_TO_DOCUMENT_METADATA" />
        </Link>
      </div>
    </div>
  );
};

export default ValidMetadataWarning;
