import TextBody from './TextBody/TextBody';
import ShapeDataProvider from './ShapeData';
import ShapeForeignObject from './ShapeForeignObject';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const NoteShape = ({ shape }: ShapeProps) => {
  return (
    <ShapeDataProvider shape={shape}>
      <g id={shape.id} data-type="shape">
        {shape.text && (
          <ShapeForeignObject
            overflow="auto"
            textBounds={{ top: 0, left: 0, width: '100%', height: '100%' }}
          >
            <TextBody text={shape.text} />
          </ShapeForeignObject>
        )}
      </g>
    </ShapeDataProvider>
  );
};

export default NoteShape;
