export const orderField = {
  EMAIL: 'email',
  FIRSTNAME: 'first_name',
  LASTNAME: 'last_name',
  DATE: 'date_joined',
  EXPIRES: 'expires',
  BROWSER: 'browser',
} as const;

export const DEFAULT_FILTERS_TOKENS: {
  order_field: typeof orderField[keyof typeof orderField];
  order_type: 'DESC' | 'ASC';
} = {
  order_field: orderField.EXPIRES,
  order_type: 'DESC',
};

export const filterType = {
  SEARCH: 'user',
  ADMIN: 'tenant_admin',
  DATE_START: 'date_joined.start',
  DATE_END: 'date_joined.end',
} as const;

export const LOADING_STATE: Record<Table.Loading, TranslationId> = {
  INITIAL: 'LOADING_USERS',
  ORDER: 'REORDERING_USERS',
  FILTER: 'FILTERING_USERS',
} as const;

export const FIELD_LIMIT = 150;
export const DEBOUNCE_DELAY = 500;
