import api from './api';

export const FileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    download: builder.query<
      ApiOperations['download_file']['responses']['200'],
      ApiOperations['download_file']['parameters']['path']
    >({
      query: ({ object_id }) => {
        return {
          url: `/object/file/${object_id}/download`,
          config: {
            responseType: 'blob',
          },
        };
      },
      providesTags: (result, error, arg) => [{ type: 'download', id: arg.object_id }],
    }),
  }),
});

// Export queries
export const { useDownloadQuery, useLazyDownloadQuery } = FileApi;

export default FileApi;
