/* eslint-disable class-methods-use-this */
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class Structure extends RealtimeObject<Presentation.Data.Structure> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'presStructure');
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as Presentation.Data.Structure;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  get slideCount() {
    return this.get(['sldIdLst']).length || 0;
  }

  addSlide(id: string) {
    return this.apply([
      {
        p: ['sldIdLst', this.get(['sldIdLst']).length],
        li: id,
      },
    ]);
  }

  removeSlide(index: number) {
    return this.apply([
      {
        p: ['sldIdLst', index],
        ld: this.get(['sldIdLst', index]),
      },
    ]);
  }
}
