import { useMemo } from 'react';
import { useGroupData } from './Group/GroupData';

const usePosition = (shape: Presentation.Data.Shape) => {
  const { parentGroup } = useGroupData();
  return useMemo(() => {
    /**
     * If the shape is a child of a group, we need to calculate the position
     * relative to the group's position.
     */
    if (shape.properties.xfrm?.off && parentGroup) {
      const shapeOff = shape.properties.xfrm.off;
      const chOff = parentGroup.properties.xfrm?.chOff ?? { x: 0, y: 0 };

      //Subtract the child offset provided in the group transform
      const shapeX = shapeOff.x - chOff.x;
      const shapeY = shapeOff.y - chOff.y;

      return {
        left: shapeX,
        top: shapeY,
      };
    }

    if (shape.properties.xfrm?.off) {
      return {
        left: shape.properties.xfrm.off.x,
        top: shape.properties.xfrm.off.y,
      };
    }

    return { left: 0, top: 0 };
  }, [shape, parentGroup]);
};

export default usePosition;
