import { Divider } from 'dodoc-design-system';

import styles from './Header.module.scss';

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <Divider margin="0 0 1rem 0" />
    </div>
  );
};

export default Header;
