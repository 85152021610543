import { Link } from 'dodoc-design-system';

type OriginalLocationCellProps = {
  object: Objekt;
};

const OriginalLocationCell = ({ object }: OriginalLocationCellProps) => {
  return object.parent ? (
    <div>
      <Link
        size="small"
        href={`/storage/${object.parent.type}/${object.parent.id}`}
        testId="original-location-link"
      >
        {object.parent.name}
      </Link>
    </div>
  ) : null;
};

export default OriginalLocationCell;
