import { Icon } from 'dodoc-design-system';
import styles from './ElementTwister.module.scss';

type ElementTwisterProps = {
  isOpen?: boolean;
  hide?: boolean;
  setOpen: (isOpen: boolean) => void;
};

const ElementTwister = ({ isOpen = false, setOpen, hide = false }: ElementTwisterProps) => (
  <span
    className={`${styles.twister} ${isOpen && styles.open} ${hide && styles.hidden}`}
    onClick={(e) => {
      setOpen(!isOpen);
      e.stopPropagation();
    }}
  >
    <Icon icon="AngleDownBlue" size={24} />
  </span>
);

export default ElementTwister;
