import FindHighlights from './FindHighlights';
import InlineCards from './InlineCards/InlineCards';
import ShapeSelection from './ShapeSelection/ShapeSelection';

import styles from './SlideOverlay.module.scss';
import interactionControllerStyles from '_common/components/OnboardingOverlay/InteractionController.module.scss';

const SlideOverlay = () => {
  return (
    <div className={styles.root} id={interactionControllerStyles.skipControl}>
      <InlineCards />
      <FindHighlights />
      <ShapeSelection />
    </div>
  );
};

export default SlideOverlay;
