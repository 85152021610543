import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Input, InputField, Select } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { parseMeasurement } from 'utils';

import styles from './MeasureField.module.scss';

type Unit = 'cm' | 'in' | 'pt' | 'px';
export type Measure = Editor.Styles.IncoherentProperty<number> & { unit: Unit };

export type MeasureFieldProps<I extends string = string> = Measure & {
  id: I;
  fieldLabel?: string;
  valuePlaceholder?: string;
  unitPlaceholder?: string;
  disabled?: boolean;
  clearable?: boolean;
  min: Measure;
  max: Measure;
  onChange: (data: Measure & { id: I; hasError: boolean }) => void;
};

const DEFAULT_MEASURE: Unit = 'cm';

const MeasureField = <I extends string = string>({
  id,
  value,
  unit,
  fieldLabel,
  disabled,
  valuePlaceholder = '',
  clearable = false,
  min,
  max,
  onChange,
}: MeasureFieldProps<I>) => {
  const intl = useIntl();

  const MEASURES_OPTIONS: {
    value: Editor.Tabulations.Measure;
    label: string | Editor.Tabulations.Measure;
  }[] = [
    { value: 'cm', label: `${intl.formatMessage({ id: 'CENTIMETERS' })} (cm)` },
    { value: 'in', label: `${intl.formatMessage({ id: 'INCHES' })} (in)` },
    { value: 'pt', label: `${intl.formatMessage({ id: 'POINTS' })} (pt)` },
    { value: 'px', label: `${intl.formatMessage({ id: 'PIXELS' })} (px)` },
  ];

  const [localValue, setLocalValue] = useState<number | string>(value != null ? `${value}` : 0);
  const [localUnit, setLocalUnit] = useState<Measure['unit']>(unit ?? DEFAULT_MEASURE);
  const [localMin, setLocalMin] = useState(min);
  const [localMax, setLocalMax] = useState(max);
  const [feedback, setFeedback] = useState<string>();

  useEffect(() => {
    const parsedMin =
      parseMeasurement(`${min.value}`, localUnit, {
        defaultUnit: min.unit,
        min: undefined,
      }) ?? min.value;
    const parsedMax =
      parseMeasurement(`${max.value}`, localUnit, {
        defaultUnit: max.unit,
        max: undefined,
      }) ?? max.value;

    setLocalMin({ ...min, value: parsedMin });
    setLocalMax({ ...max, value: parsedMax });
  }, [min, max, localUnit]);

  useEffect(() => {
    let hasError = false;

    if (localValue != null && localUnit != null && localValue !== '') {
      if (+localValue < localMin.value || +localValue > localMax.value) {
        hasError = true;
      } else {
        hasError = false;
      }
    } else {
      hasError = true;
    }
    if (hasError) {
      setFeedback(
        intl.formatMessage(
          { id: 'CHOOSE_A_VALUE_BETWEEN' },
          { min: localMin.value, max: localMax.value },
        ),
      );
    } else {
      setFeedback(undefined);
    }
    onChange({ value: +localValue, unit: localUnit, id, hasError });
  }, [localValue, localUnit, id, localMin, localMax]);

  useEffect(() => {
    setLocalValue(value != null ? `${value}` : localMin.value);
    setLocalUnit(unit ?? DEFAULT_MEASURE);
  }, [value, unit]);

  const handleValueChange: InputProps['onChange'] = (e) => {
    setLocalValue(e.target.value === '' ? localMin.value : e.target.value);
  };

  return (
    <InputField
      width="100%"
      size="large"
      label={fieldLabel}
      disabled={disabled}
      feedback={feedback}
      testId={`${id}-field`}
    >
      <div className={styles.inputs}>
        <Input
          type="number"
          allowDecimal
          value={localValue !== undefined ? `${localValue}` : undefined}
          onChange={handleValueChange}
          size="large"
          placeholder={valuePlaceholder}
          margin="0 1rem 0 0"
          width="100%"
          disabled={disabled}
          clearable={clearable}
          testId={`${id}-field`}
        />
        <Select
          width="11rem"
          //@ts-expect-error Value is be different from an option item
          options={MEASURES_OPTIONS}
          value={
            MEASURES_OPTIONS.find((option) => option.value === localUnit)
              ? {
                  value: localUnit,
                  label: localUnit,
                }
              : undefined
          }
          onChange={(newValue) => {
            const parsedValue = parseMeasurement(`${localValue}`, newValue.value, {
              defaultUnit: localUnit,
              max: undefined,
            });

            setLocalValue(parsedValue ?? localMin.value);
            setLocalUnit(newValue.value as Unit);
          }}
          menuPosition="fixed"
          menuWidth="22rem"
          clearable={clearable}
          disabled={disabled}
          testId={`${id}-field`}
        />
      </div>
    </InputField>
  );
};

export default MeasureField;
