import { FormattedMessage } from 'react-intl';
import styles from './Description.module.scss';
import { Link } from 'dodoc-design-system';

type NoteDescriptionProps = {
  content: string;
  isClamped: boolean;
  readMore: boolean;
  setReadMore: (value: React.SetStateAction<boolean>) => void;
  contentRef?: React.RefObject<HTMLSpanElement>;
};

const NoteDescription = ({
  content,
  isClamped,
  readMore,
  setReadMore,
  contentRef,
}: NoteDescriptionProps) => {
  return (
    <>
      {/* ~TODO: talk with Filipe to send a flag like has been done in Comments (application) */}
      {/* {note.application && (
          <div className={styles.imported}>
            <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
          </div>
        )} */}
      <span
        ref={contentRef}
        style={{ lineHeight: '2rem' }}
        className={`${styles.text} ${!readMore && styles.readLess}`}
      >
        {content}
      </span>
      {isClamped && (
        <Link
          testId={`note-card-description-${readMore ? 'read-less' : 'read-more'}`}
          size="small"
          margin="1rem 0 0 0"
          onClick={(e) => {
            e.stopPropagation();
            setReadMore(!readMore);
          }}
        >
          <FormattedMessage
            id={readMore ? 'editor.comments.readLess' : 'editor.comments.readMore'}
          />
        </Link>
      )}
    </>
  );
};

export default NoteDescription;
