import { PDFRange } from './PDFRange';
import { DOMUtils } from '_common/utils/DOMUtils';

type Timeouts = {
  selectionChanged: NodeJS.Timeout | null;
};

const SELECTION_CHANGE_TIMEOUT = 100;

export class SelectionManager {
  private timeouts: Timeouts;

  private savedRange: PDF.Selection.Range | null;

  constructor() {
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.selectionChanged = this.selectionChanged.bind(this);

    this.savedRange = null;

    this.timeouts = {
      selectionChanged: null,
    };
  }

  start() {
    this.addListener();
  }

  stop() {
    this.removeListener();
  }

  destroy() {
    this.removeListener();
  }

  private addListener() {
    document.addEventListener('selectionchange', this.onSelectionChange);
  }

  private removeListener() {
    document.removeEventListener('selectionchange', this.onSelectionChange);
  }

  private onSelectionChange(event: Event) {
    if (this.timeouts.selectionChanged) {
      clearTimeout(this.timeouts.selectionChanged);
    }

    this.timeouts.selectionChanged = setTimeout(this.selectionChanged, SELECTION_CHANGE_TIMEOUT);
  }

  private selectionChanged() {
    logger.info('selectionChanged');

    this.saveRange();
  }

  saveRange() {
    const range = this.getRange();
    if (range && this.isSelectionInPDF(range)) {
      this.savedRange = new PDFRange(range);
      logger.info('savedRange', this.savedRange);
    }
  }

  restoreRange() {
    if (!this.isSelectionInPDF()) {
      const range = this.savedRange?.getNativeRange();
      if (range) {
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  }

  isSelectionInPDF(range: Range | null = this.getRange()) {
    if (range) {
      let node = range.commonAncestorContainer;
      while (!this.isNodeAnElement(node) && node.parentElement) {
        node = node.parentElement;
      }
      if (this.isNodeAnElement(node)) {
        const popper = node.closest('[data-popper-type="popper"]');
        if (popper) {
          return false;
        }
        const pdfContainer = node.closest('div#pdfContainer');
        if (pdfContainer) {
          return true;
        }
      }
    }

    return false;
  }

  isSelectionCollapsed(range: Range | null = this.getRange()) {
    return range && range.collapsed;
  }

  getRange(): Range | null {
    const selection = window.getSelection();
    if (selection && selection.type !== 'None' && selection.rangeCount > 0) {
      return selection.getRangeAt(0);
    }

    return null;
  }

  getPageFromSelection(range: Range | null = this.getRange()): HTMLDivElement | null {
    if (range && this.isSelectionInPDF(range)) {
      let page = range.commonAncestorContainer;
      while (page.parentElement) {
        if (
          page instanceof HTMLDivElement &&
          page.hasAttribute('data-page-num') &&
          page.hasAttribute('data-viewport-scale')
        ) {
          return page;
        }
        page = page.parentElement;
      }
    }
    return null;
  }

  getSelectionDOMRects(range: Range | null = this.getRange()) {
    if (range && !range.collapsed) {
      const closestAncestor = DOMUtils.closest(range.commonAncestorContainer, ['DIV']);
      if (
        closestAncestor instanceof HTMLDivElement &&
        closestAncestor.dataset.overlaysLayer === 'true'
      ) {
        return { list: range.getClientRects(), bounding: range.getBoundingClientRect() };
      }
    }
    return null;
  }

  getPageFromNumber(pageNum: number): HTMLDivElement | null {
    const pdfContainer = document.getElementById('pdfContainer');
    if (pdfContainer) {
      return pdfContainer.querySelector(`div[data-page-num="${pageNum}"]`);
    }
    return null;
  }

  isNodeAnElement(node: Node): node is Element {
    return node.nodeType === Node.ELEMENT_NODE;
  }
}
