import { createContext, useContext } from 'react';

export type AbsoluteUnit = 'cm' | 'in';

type TablePropertiesContextType = {
  //In points
  pageSize: NonNullable<Editor.Styles.TablePropertiesData['PAGE']>['width'];
  setHasValidWidth: React.Dispatch<React.SetStateAction<boolean>>;
  preferedUnit: AbsoluteUnit;
  setPreferedUnit: React.Dispatch<React.SetStateAction<AbsoluteUnit>>;
};

const TablePropertiesContext = createContext<TablePropertiesContextType>({
  pageSize: undefined,
  setHasValidWidth: () => {},
  preferedUnit: 'cm',
  setPreferedUnit: () => {},
});

export const useTableProperties = () => {
  return useContext(TablePropertiesContext);
};

export default TablePropertiesContext;
