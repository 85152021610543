import authority from '_common/services/api/authority';
import { paths } from '_types/authority';

export const onboardingApi = authority.injectEndpoints({
  endpoints: (builder) => ({
    onboardingStatus: builder.mutation<
      paths['/api/authority/user/profile/edit/onboarding']['post']['responses']['200'],
      paths['/api/authority/user/profile/edit/onboarding']['post']['requestBody']['content']['multipart/form-data']
    >({
      query: ({ target, step, skip, journey }) => {
        return {
          url: '/user/profile/edit/onboarding',
          method: 'POST',
          body: { target, step, skip, journey },
        };
      },
      invalidatesTags: [{ type: 'User', id: 'Current' }],
    }),
  }),
});

// Export queries and mutations
export const { useOnboardingStatusMutation } = onboardingApi;

export default onboardingApi;
