import { useState } from 'react';
import { Input } from 'dodoc-design-system';
import { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { useDebounce, useEffectOnUpdate } from '_common/hooks';

type DebouncedInputProps = InputProps & { debounceDelay?: number };

const DebouncedInput = ({ onChange, debounceDelay = 500, ...inputProps }: DebouncedInputProps) => {
  const [changeEvent, setChangeEvent] = useState<React.ChangeEvent<HTMLInputElement> | null>(null);

  const debouncedChange = useDebounce<React.ChangeEvent<HTMLInputElement> | null>(
    changeEvent,
    debounceDelay,
  );

  useEffectOnUpdate(() => {
    if (debouncedChange) {
      onChange?.(debouncedChange);
    }
  }, [debouncedChange]);

  return <Input onChange={setChangeEvent} {...inputProps} />;
};

export default DebouncedInput;
