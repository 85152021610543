import { CSSProperties, ReactNode, useRef, useState } from 'react';
import cx from 'classnames';

import { useIsNodeClamped } from '_common/hooks';

import styles from './LineEllipsis.module.scss';
import { FormattedMessage } from 'react-intl';

type LineEllipsisProps = {
  children: ReactNode;
  /**
   * Line height is required so useIsNoteClamped hook can work properly
   *
   * Default value: '3rem'
   */
  lineHeight?: CSSProperties['lineHeight'];
  maxLines?: number;
  className?: string;
};

const LineEllipsis = ({
  children,
  lineHeight = '3rem',
  maxLines = 3,
  className,
}: LineEllipsisProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const isClamped = useIsNodeClamped({
    ref: textRef,
    clamp: maxLines,
    dependencies: [textRef, children],
  });

  const [expanded, setExpanded] = useState(false);

  const handleToggleExpanded = () => setExpanded((prev) => !prev);

  return (
    <>
      <div
        ref={textRef}
        className={cx(className, styles.root, { [styles.ellipsis]: !expanded })}
        style={{ lineHeight, WebkitLineClamp: expanded ? 'none' : maxLines }}
      >
        {children}
      </div>
      {isClamped && (
        <span onClick={handleToggleExpanded} className={styles.underlinedText}>
          <FormattedMessage id={expanded ? 'global.seeLess' : 'global.seeMore'} />
        </span>
      )}
    </>
  );
};

export default LineEllipsis;
