import { NodeDataBuilder } from 'Editor/services/DataManager';
import { InsertBlockCommand } from '../GenericCommands';
import { ELEMENTS } from 'Editor/services/consts';
import { TableCellElement } from 'Editor/services/VisualizerManager';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';

export class InsertTableCommand extends InsertBlockCommand {
  rows: number;
  columns: number;

  constructor(context: Editor.Edition.Context, rows: number, columns: number) {
    super(context, { pathAfterInsert: 'START' });

    this.rows = rows;
    this.columns = columns;
  }

  async handleExec(): Promise<void> {
    this.buildActionContext();

    if (!this.context.DataManager || !this.context.DataManager.selection || !this.actionContext) {
      throw new Error('Invalid context');
    }

    let tableWidth: number = 0;

    let range = this.actionContext.range.serializeToDOMRange();

    const closestCell = EditorDOMUtils.closest(range.startContainer, [
      ELEMENTS.TableCellElement.TAG,
    ]) as TableCellElement;

    if (closestCell) {
      tableWidth =
        EditorDOMUtils.convertUnitTo(getComputedStyle(closestCell).width, 'px', 'pt') || 0;
      tableWidth -= 5;
    } else {
      tableWidth = this.context.DataManager?.sections.getPageWidthForBlockId(
        this.actionContext.baseModel.id,
      );
    }

    if (tableWidth != null) {
      const headerRow = !!this.context.DataManager.templates.getHeaderRow();
      const table = NodeDataBuilder.buildTable({
        headerRow: headerRow,
        tableWidth: tableWidth,
        rows: this.rows,
        cells: this.columns,
      });

      if (table && table.id) {
        await super.handleExec(table);
      }
    }
  }
}
