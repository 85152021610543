import BaseController from '../BaseController';

export class HistoryController extends BaseController {
  constructor(Data: Editor.Data.State) {
    super(Data);
    this.handleUndoStatusChanged = this.handleUndoStatusChanged.bind(this);
    this.handleRedoStatusChanged = this.handleRedoStatusChanged.bind(this);
  }

  start(documentId: string): void {
    this.Data.models?.undoManager?.hooks.onUndoStatusChanged.register(this.handleUndoStatusChanged);
    this.Data.models?.undoManager?.hooks.onRedoStatusChanged.register(this.handleRedoStatusChanged);
  }

  applyChangeAction(action: { patch: { updatedOps: any } }) {
    try {
      const nodeUpdateOps = action.patch.updatedOps;
      const nodeUpdateKeys = Object.keys(nodeUpdateOps);
      if (nodeUpdateKeys.length > 0) {
        for (let i = 0; i < nodeUpdateKeys.length; i++) {
          const nodeId = nodeUpdateKeys[i];
          this.Data.nodes?.applyOpsToNode(nodeId, nodeUpdateOps[nodeId]);
        }
      }
    } catch (error) {
      logger.captureException(error);
    }
  }

  private handleUndoStatusChanged(status: Realtime.Core.UndoManager.StackStatus) {
    this.Data.emit?.('UNDO_STATUS_CHANGED', status);
  }

  private handleRedoStatusChanged(status: Realtime.Core.UndoManager.StackStatus) {
    this.Data.emit?.('REDO_STATUS_CHANGED', status);
  }

  get hooks() {
    return this.Data.models?.undoManager?.hooks;
  }

  get manager() {
    return this.Data.models?.undoManager;
  }

  undo() {
    return this.Data.models?.undoManager?.undo();
  }

  redo() {
    return this.Data.models?.undoManager?.redo();
  }

  canUndo() {
    return this.Data.models?.undoManager?.canUndo();
  }

  canRedo() {
    return this.Data.models?.undoManager?.canRedo();
  }

  createPatch() {
    return this.Data.models?.undoManager?.createPatch();
  }

  stop(): void {}

  destroy(): void {}
}
