import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'dodoc-design-system';

import {
  SelectOption,
  REF_PRIORITY_BEHAVIOUR,
  SequenceOptions,
  HandlerProps,
} from '../AdvancedFilterOptions';
import { useAdvancedFilterContext } from '../AdvancedFilterContext';

import styles from './Handlers.module.scss';

const documentSequence: SequenceOptions = {
  preceding: 'AND',
  type: 'dynamic',
  options: [
    {
      value: 'is',
      label: 'storage.actionBar.search.rules.conditions.is',
    },
    {
      value: 'not',
      label: 'storage.actionBar.search.rules.conditions.is_not',
    },
  ],
};

type RefPriorityHandlerProps = {
  testId?: string;
};

const RefPriorityHandler = ({ sequence, testId }: HandlerProps & RefPriorityHandlerProps) => {
  const intl = useIntl();

  const { advancedFilter, setValue, setAuxValue, setQuery } = useAdvancedFilterContext();

  const { option, metadataOption, value, auxValue } = advancedFilter;
  const mergedOption = metadataOption ? `${option}.${metadataOption}` : option;

  const TRANSLATED_OPTIONS: SelectOption[] = useMemo(
    () =>
      Object.values(documentSequence.options).map((option: SelectOption) => ({
        value: option.value,
        label: intl.formatMessage({ id: option.label }),
      })),
    [],
  );

  useEffect(() => {
    if (!sequence.type || !value) {
      setQuery(undefined);
      return;
    }

    setQuery(
      REF_PRIORITY_BEHAVIOUR({
        field: mergedOption,
        value,
        auxValue: auxValue || undefined,
      }),
    );
  }, [value, auxValue]);

  const handleChangeValue = (newSelectValue: SelectOption) => {
    setValue(newSelectValue.value);
  };

  const handleChangeAuxValue = (newSelectValue: SelectOption) => {
    setAuxValue(newSelectValue.value);
  };

  const renderDocumentSequence = () => {
    if (!value) {
      return null;
    }

    return (
      <>
        {sequence.preceding && (
          <div className={styles.preceding}>
            <FormattedMessage id={documentSequence.preceding} />
          </div>
        )}

        <Select
          clearable={false}
          width="20rem"
          size="medium"
          options={TRANSLATED_OPTIONS}
          onChange={handleChangeAuxValue}
          value={TRANSLATED_OPTIONS.find((option) => option.value === auxValue) || null} //Force select UI cleanup by sending null
          testId={`${testId}-sequence-options`}
        />

        <div className={styles.preceding}>
          <FormattedMessage id={'INSERTED_IN_THE_DOCUMENT'} />
        </div>
      </>
    );
  };

  return (
    <div className={styles.handlerRoot} data-testid={testId}>
      <Select
        clearable={false}
        width="20rem"
        size="medium"
        options={sequence.options}
        onChange={handleChangeValue}
        value={sequence.options.find((option) => option.value === value) || null} //Force select UI cleanup by sending null
        testId={`${testId}-options`}
      />

      {renderDocumentSequence()}
    </div>
  );
};

export default RefPriorityHandler;
