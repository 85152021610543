import { MouseEventHandler } from 'react';
import { Toggle } from 'dodoc-design-system';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type EditorIconButtonProps = {
  icon: IconTypes[24];
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLSpanElement>;
  id?: string;
  testId: string;
};

const EditorIconButton = ({ icon, disabled, onClick, id, testId }: EditorIconButtonProps) => (
  <Toggle
    size="medium"
    variant="ghost"
    disabled={disabled}
    icon={icon}
    onClick={onClick}
    id={id}
    testId={testId}
  />
);

export default EditorIconButton;
