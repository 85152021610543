import { useMemo } from 'react';

import { useGetImageQuery } from 'Presentation/api';
import { useSuiteObject } from '_common/suite';
import { useShapeData } from './ShapeData';
import { indexedModEffects } from 'Presentation/utils';

type ImageProps = {
  fill: Presentation.Data.Common.PictureFillType;
  clip?: string;
};

const Image = ({ fill, clip }: ImageProps) => {
  const { id } = useSuiteObject();
  const { size, shape } = useShapeData();
  const { data } = useGetImageQuery({
    presentation_id: id,
    reference_id: fill.source,
  });

  const uncropedSize = useMemo(() => {
    const uncropedWidthRelative = 100 - (fill.sr?.l ?? 0) - (fill.sr?.r ?? 0);
    const uncropedWidth = (size.width * 100) / uncropedWidthRelative;

    const uncropedHeightRelative = 100 - (fill.sr?.t ?? 0) - (fill.sr?.b ?? 0);
    const uncropedHeight = (size.height * 100) / uncropedHeightRelative;

    return { width: uncropedWidth, height: uncropedHeight };
  }, [size, fill]);

  const relativeCrop = useMemo<Required<Presentation.Data.Common.PictureFillModCrop>>(() => {
    const sanitized = { t: 0, r: 0, b: 0, l: 0, ...fill.sr };

    const t = (sanitized.t * uncropedSize.height) / 100;
    // const r = (sanitized.r * uncropedSize.width) / 100; //Unnecessary for now
    // const b = (sanitized.b * uncropedSize.height) / 100; //Unnecessary for now
    const l = (sanitized.l * uncropedSize.width) / 100;

    return { ...sanitized, t, l };
  }, [fill, uncropedSize]);

  const alphaModFix = useMemo(() => {
    if (shape.type === 'picture') {
      const alphaModFix = indexedModEffects(shape.fill.effects).alphaModFix;
      if (alphaModFix) {
        return alphaModFix.value / 100;
      }
    }
  }, [shape]);

  if (!data) {
    return null;
  }

  return (
    <foreignObject
      width={fill.tile?.sx ?? size.width}
      height={fill.tile?.sy ?? size.height}
      clipPath={clip}
    >
      <img
        src={data}
        alt=""
        width={uncropedSize.width}
        height={uncropedSize.height}
        style={{
          marginLeft: -relativeCrop.l,
          marginTop: -relativeCrop.t,
          pointerEvents: 'none',
          opacity: alphaModFix,
        }}
      />
    </foreignObject>
  );
};

export default Image;
