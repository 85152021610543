import { CSSProperties, ReactNode, useMemo } from 'react';
import { useShapeData } from './ShapeData';
import useGeometry from './useGeometry';

type ShapeForeignObjectProps = {
  textBounds?: {
    width: string;
    height: string;
  } & Presentation.Data.Common.Position;
  overflow?: CSSProperties['overflow'];
  children: ReactNode;
};

const ShapeForeignObject = ({ textBounds, overflow, children }: ShapeForeignObjectProps) => {
  const { position, size, shape } = useShapeData();
  const geometry = useGeometry(shape.properties.geom, size, position, shape.properties);

  const bounds = useMemo(() => {
    if (textBounds) {
      return textBounds;
    }

    if (geometry.textBounds) {
      return {
        left: position.left + geometry.textBounds.l,
        top: position.top + geometry.textBounds.t,
        width: geometry.textBounds.r - geometry.textBounds.l,
        height: geometry.textBounds.b - geometry.textBounds.t,
      };
    }

    return {
      ...position,
      ...size,
    };
  }, [textBounds, geometry, position, size]);

  return (
    <foreignObject
      x={bounds.left}
      y={bounds.top}
      width={bounds.width}
      height={bounds.height}
      overflow={overflow ?? 'visible'}
      //TODO:PRESENTATION Indicator for text bounds for easier development, enable it for debugging
      // style={geometry.textBounds ? { backgroundColor: 'red', opacity: 0.2 } : undefined}
    >
      {children}
    </foreignObject>
  );
};

export default ShapeForeignObject;
