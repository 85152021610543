import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { NodeUtils } from 'Editor/services/DataManager';
import { InsertRowOperation, InsertColumnOperation } from '../../Operations/TableOperations';
import { PathUtils } from 'Editor/services/_Common/Selection';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

type UpdateMergedCellsArgs = {
  rowSpan?: number;
  colSpan?: number;
  headId?: string | null;
  display?: boolean | null;
};
export class SplitCellsOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  selectedCells: Editor.Edition.CellInfo[];
  splitColumns: number;
  splitRows: number;
  mergeCells: boolean;
  pageWidth?: number;
  ctx: Editor.Edition.ActionContext;

  headCells: Editor.Edition.CellInfo[] = [];
  headCellsIds: string[] = [];

  cellIndexes: number[] = [];
  rowIndexes: number[] = [];

  tableInfo?: {
    data: Editor.Data.Node.Data;
    path: Editor.Selection.Path;
  };
  table?: Editor.Data.Node.Data;
  tableBody?: Editor.Data.Node.Data;
  rows?: Editor.Data.Node.Data[];

  constructor(
    ctx: Editor.Edition.ActionContext,
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    selectedCells: Editor.Edition.CellInfo[],
    splitColumns: number,
    splitRows: number,
    mergeCells: boolean,
    pageWidth: number | undefined,
  ) {
    super(baseModel);

    this.ctx = ctx;
    this.tableId = tableId;
    this.selectedCells = selectedCells;
    this.splitColumns = splitColumns;
    this.splitRows = splitRows;
    this.mergeCells = mergeCells;
    this.pageWidth = pageWidth;
    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.selectedCells.length > 0) {
      this.updateTableInfo();
      if (NodeUtils.isTableData(this.table) && this.rows) {
        const cellWidths = TableUtils.getColumnWidths(this.table);

        this.getHeadCellsAndCellIndexs();

        if (this.mergeCells === true) {
          // TODO: TO BE IMPLEMENTED
        }

        if (this.headCells.length === 0) {
          // check split rows value
          if (this.rowIndexes.length > 1 && this.mergeCells === false) {
            this.splitRows = 1;
          }

          let updateBaseData = false;

          // iterate throw selected cell indexes
          for (let c = 0; c < this.cellIndexes.length; c++) {
            const cellIndex = this.cellIndexes[c];

            const splitCellWidth = cellWidths[cellIndex];
            if (splitCellWidth && (splitCellWidth.t === 'abs' || splitCellWidth.t === 'pct')) {
              splitCellWidth.v = splitCellWidth.v / this.splitColumns;
            }

            const columnCells: {
              data: Editor.Data.Node.TableCellData;
              path: Editor.Selection.Path;
            }[] = [];

            const columnCellsIds: string[] = [];

            for (let r = 0; r < this.rowIndexes.length; r++) {
              const cell =
                this.rows[this.rowIndexes[r]].childNodes?.[
                  this.cellIndexes[0] + c * this.splitColumns
                ];

              if (NodeUtils.isTableCellData(cell) && cell.id && !columnCellsIds.includes(cell.id)) {
                const cellPath: Realtime.Core.AbstractPath = this.model.findPath('id', cell.id);
                cellPath.pop(); // remove id

                if (PathUtils.isValidSelectionPath(cellPath)) {
                  columnCells.push({
                    data: cell,
                    path: cellPath,
                  });

                  columnCellsIds.push(cell.id);
                }
              }
            }

            const cellsInfo = TableUtils.getCellInfo(columnCells);

            // insert columns
            for (let j = 1; j < this.splitColumns; j++) {
              const operation = new InsertColumnOperation(
                this.model,
                this.tableId,
                cellsInfo,
                this.pageWidth,
                [],
                {
                  before: false,
                  columnWidth: splitCellWidth,
                  mergeUnselectedCells: true,
                },
              );
              operation.apply();
              updateBaseData = true;
            }
          }

          if (updateBaseData) {
            this.ctx.refreshBaseData();
            this.updateTableInfo();
            this.getHeadCellsAndCellIndexs();
            updateBaseData = false;
          }

          for (let r = 0; r < this.rowIndexes.length; r++) {
            const rowIndex = this.rowIndexes[r];

            const rowPath = this.model.findPath(this.model.KEYS.ID, this.rows[rowIndex].id);
            rowPath.pop(); // remove path id

            const rowData = this.model.getChildDataByPath(rowPath);

            const splitRowHeight =
              (rowData[this.model.KEYS.PROPERTIES]?.rh || TableUtils.DEFAULT_ROW_HEIGHT) /
              this.splitRows;

            const rowCells: {
              data: Editor.Data.Node.TableCellData;
              path: Editor.Selection.Path;
            }[] = [];

            const rowCellsIds: string[] = [];

            for (let c = 0; c < this.cellIndexes.length; c++) {
              // add recent added columns to selected cells
              for (let sc = 0; sc < this.splitColumns; sc++) {
                const cell =
                  this.rows[rowIndex].childNodes?.[
                    this.cellIndexes[0] + c * this.splitColumns + sc
                  ];

                if (NodeUtils.isTableCellData(cell) && cell.id && !rowCellsIds.includes(cell.id)) {
                  const cellPath: Realtime.Core.AbstractPath = this.model.findPath('id', cell.id);
                  cellPath.pop(); // remove id

                  if (PathUtils.isValidSelectionPath(cellPath)) {
                    rowCells.push({
                      data: cell,
                      path: cellPath,
                    });

                    rowCellsIds.push(cell.id);
                  }
                }
              }
            }

            const cellsInfo = TableUtils.getCellInfo(rowCells);

            for (let j = 1; j < this.splitRows; j++) {
              const operation = new InsertRowOperation(this.model, this.tableId, cellsInfo, [], {
                before: false,
                rowHeigth: splitRowHeight,
                mergeUnselectedCells: true,
              });
              operation.apply();
            }

            // update this row heigth
            this.ops.push(
              RealtimeOpsBuilder.objectReplace(
                rowData[this.model.KEYS.PROPERTIES]?.rh,
                splitRowHeight,
                [...rowPath, this.model.KEYS.PROPERTIES, 'rh'],
              ),
            );
          }
        } else {
          // split merged cells
          for (let i = 0; i < this.headCells.length; i++) {
            const headCell = this.headCells[i];

            const headCellPath: Realtime.Core.AbstractPath = this.model.findPath('id', headCell.id);
            const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);

            if (headCellIndexs.parentIndex != null) {
              // temp
              // fix values for split
              if (this.splitColumns !== 1 && this.splitColumns !== headCell.colSpan) {
                this.splitColumns = headCell.colSpan;
              }
              if (this.splitRows !== 1 && this.splitRows !== headCell.rowSpan) {
                this.splitRows = headCell.rowSpan;
              }

              if (headCell.colSpan === this.splitColumns && headCell.rowSpan === this.splitRows) {
                // just unmerge

                for (let x = 0; x < headCell.rowSpan; x++) {
                  for (let y = 0; y < headCell.colSpan; y++) {
                    const cell =
                      this.rows[headCellIndexs.parentIndex + x].childNodes?.[
                        headCell.cellIndex + y
                      ];

                    if (cell) {
                      const cellPath = this.model.findPath(this.model.KEYS.ID, cell.id);
                      cellPath.pop(); // remove path id

                      const cellData = this.model.get(cellPath);

                      const dataToUpdate = {
                        rowSpan: undefined,
                        colSpan: undefined,
                        headId: undefined,
                        display: undefined,
                      };

                      if (PathUtils.isValidSelectionPath(cellPath)) {
                        this.ops.push(
                          ...this.getOpsToUpdateMergedCellProperties(
                            cellData,
                            cellPath,
                            dataToUpdate,
                          ),
                        );
                      }
                    }
                  }
                }
              } else {
                // handle columns
                if (headCell.colSpan === this.splitColumns) {
                  // unmerge columns but keep rows merged

                  for (let y = 0; y < headCell.colSpan; y++) {
                    const newHeadCell =
                      this.rows[headCellIndexs.parentIndex].childNodes?.[headCell.cellIndex + y];

                    for (let x = 0; x < headCell.rowSpan; x++) {
                      const cell =
                        this.rows[headCellIndexs.parentIndex + x].childNodes?.[
                          headCell.cellIndex + y
                        ];

                      if (cell && newHeadCell) {
                        const cellPath = this.model.findPath(this.model.KEYS.ID, cell.id);
                        cellPath.pop(); // remove path id

                        const cellData = this.model.get(cellPath);

                        if (x === 0) {
                          const dataToUpdate = {
                            rowSpan: headCell.rowSpan,
                            colSpan: 1,
                            headId: undefined,
                            display: undefined,
                          };

                          if (PathUtils.isValidSelectionPath(cellPath)) {
                            // update new head cell data
                            this.ops.push(
                              ...this.getOpsToUpdateMergedCellProperties(
                                cellData,
                                cellPath,
                                dataToUpdate,
                              ),
                            );
                          }
                        } else {
                          const dataToUpdate = {
                            rowSpan: undefined,
                            colSpan: undefined,
                            headId: newHeadCell.id,
                            display: false,
                          };

                          if (PathUtils.isValidSelectionPath(cellPath)) {
                            // update head id
                            this.ops.push(
                              ...this.getOpsToUpdateMergedCellProperties(
                                cellData,
                                cellPath,
                                dataToUpdate,
                              ),
                            );
                          }
                        }
                      }
                    }
                  }
                } else {
                  // TODO: to be impemented
                  if (headCell.colSpan < this.splitColumns) {
                    // insert columns
                    // let imparColumns = headCell.colSpan
                  }

                  // rearrange merges
                }

                // handle rows
                if (headCell.rowSpan === this.splitRows) {
                  // unmerge rows but keep columns merged
                  for (let x = 0; x < headCell.rowSpan; x++) {
                    const newHeadCell =
                      this.rows[headCellIndexs.parentIndex + x].childNodes?.[headCell.cellIndex];

                    for (let y = 0; y < headCell.colSpan; y++) {
                      const cell =
                        this.rows[headCellIndexs.parentIndex + x].childNodes?.[
                          headCell.cellIndex + y
                        ];

                      if (cell && newHeadCell) {
                        const cellPath = this.model.findPath(this.model.KEYS.ID, cell.id);
                        cellPath.pop(); // remove path id

                        const cellData = this.model.get(cellPath);

                        if (PathUtils.isValidSelectionPath(cellPath)) {
                          if (x === 0) {
                            const dataToUpdate: any = {
                              rowSpan: 1,
                              colSpan: headCell.colSpan,
                            };

                            // remove headId
                            if (cellData.properties['head-id'] != null) {
                              dataToUpdate.headId = null;
                            }

                            // remove display
                            if (cellData.properties.d != null) {
                              dataToUpdate.display = null;
                            }

                            // update new head cell data
                            this.ops.push(
                              ...this.getOpsToUpdateMergedCellProperties(
                                cellData,
                                cellPath,
                                dataToUpdate,
                              ),
                            );
                          } else {
                            const dataToUpdate: any = {
                              headId: newHeadCell.id,
                              display: false,
                            };

                            // remove rowspan
                            if (cellData.properties.rs != null) {
                              dataToUpdate.rowSpan = undefined;
                            }

                            // remove colspan
                            if (cellData.properties.cs != null) {
                              dataToUpdate.colSpan = undefined;
                            }

                            // update head id
                            this.ops.push(
                              ...this.getOpsToUpdateMergedCellProperties(
                                cellData,
                                cellPath,
                                dataToUpdate,
                              ),
                            );
                          }
                        }
                      }
                    }
                  }
                } else {
                  // TODO: to be implemented
                  // rearrange merges
                }
              }
            }
          }
        }
      }
    }

    return this;
  }

  private getOpsToUpdateMergedCellProperties(
    cellData: Editor.Data.Node.Data,
    cellPath: Editor.Selection.Path,
    { rowSpan, colSpan, headId, display }: UpdateMergedCellsArgs,
  ) {
    const ops = [];

    let op;

    op = this.model.buildOp([...cellPath, this.model.KEYS.PROPERTIES, 'rs'], rowSpan);
    if (op != null) {
      ops.push(op);
    }

    op = this.model.buildOp([...cellPath, this.model.KEYS.PROPERTIES, 'cs'], colSpan);
    if (op != null) {
      ops.push(op);
    }

    op = this.model.buildOp([...cellPath, this.model.KEYS.PROPERTIES, 'head-id'], headId);
    if (op != null) {
      ops.push(op);
    }

    // remove display
    op = this.model.buildOp([...cellPath, this.model.KEYS.PROPERTIES, 'd'], display);
    if (op != null) {
      ops.push(op);

      if (display !== false && cellData.childNodes?.length === 0) {
        // check child nodes
        const paragraph = NodeDataBuilder.buildData({
          type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
          parent_id: cellData.id,
        });

        ops.push(
          RealtimeOpsBuilder.listInsert(paragraph, [...cellPath, this.model.KEYS.CHILDNODES, 0]),
        );
      }
    }

    return ops;
  }

  private getHeadCellsAndCellIndexs() {
    for (let i = 0; i < this.selectedCells.length; i++) {
      let selectedCell = this.selectedCells[i];
      const selectedCellPath: Realtime.Core.AbstractPath = this.model.findPath(
        'id',
        selectedCell.id,
      );
      const selectedCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(selectedCellPath);

      if (
        (selectedCell.colSpan > 1 || selectedCell.rowSpan > 1) &&
        !this.headCellsIds.includes(selectedCell.id) &&
        selectedCellIndexs.parentIndex != null
      ) {
        this.headCells.push(selectedCell);
        this.headCellsIds.push(selectedCell.id);

        for (let r = 0; r < selectedCell.rowSpan; r++) {
          const rowIndex = selectedCellIndexs.parentIndex + r;

          for (let c = 0; c < selectedCell.colSpan; c++) {
            const cellIndex = selectedCell.cellIndex + c;

            const cell = this.rows?.[rowIndex].childNodes?.[cellIndex];

            if (cell) {
              if (!this.cellIndexes.includes(cellIndex) && cell != null) {
                this.cellIndexes.push(cellIndex);
              }
              if (!this.rowIndexes.includes(rowIndex) && cell != null) {
                this.rowIndexes.push(rowIndex);
              }
            }
          }
        }
      } else {
        if (!this.cellIndexes.includes(selectedCell.cellIndex)) {
          this.cellIndexes.push(selectedCell.cellIndex);
        }

        const rowIndex = selectedCellIndexs?.parentIndex;

        if (rowIndex != null && !this.rowIndexes.includes(rowIndex)) {
          this.rowIndexes.push(rowIndex);
        }
      }
    }
  }

  private updateTableInfo() {
    if (!this.ctx.baseModel) {
      return this;
    }
    this.model = this.ctx.baseModel;
    this.tableInfo = this.model.getChildInfoById(this.tableId);
    this.table = this.tableInfo.data;
    this.tableBody = this.table.childNodes?.[0];
    this.rows = this.tableBody?.childNodes;
  }
}
