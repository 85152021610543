import { useSelector } from '_common/hooks';
import IntlErrorBoundary from '../IntlErrorBoundary/IntlErrorBoundary';
import InformationPanel, { InformationPanelProps } from './InformationPanel';

import styles from './InformationPanel.module.scss';

type WrapperProps = {
  isOpen?: boolean;
} & InformationPanelProps;

const InformationPanelWrapper = ({ isOpen, testId, ...panelProps }: WrapperProps) => {
  const selectedElements = Object.keys(useSelector((state) => state.table.selected));

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.root} data-testid={testId}>
      <IntlErrorBoundary
        size="medium"
        margin="10rem 0 0 0"
        key={`information-panel-${selectedElements[0]}`}
      >
        <InformationPanel {...panelProps} testId={testId} />
      </IntlErrorBoundary>
    </div>
  );
};

export default InformationPanelWrapper;
