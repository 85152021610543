import { ReactNode } from 'react';
import { Card } from 'dodoc-design-system';

import styles from './Body.module.scss';

type BodyProps = {
  children: ReactNode;
};

const Body = ({ children }: BodyProps) => {
  return (
    <Card.Body>
      <div className={styles.root}>{children}</div>
    </Card.Body>
  );
};

const Header = ({ children }: BodyProps) => {
  return <div className={styles.header}>{children}</div>;
};

export default Object.assign(Body, {
  Header,
});
