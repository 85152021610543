import { useGetImageQuery } from 'Presentation/api';
import { useSuiteObject } from '_common/suite';

type ShapeFallbackProps = {
  fallback: Presentation.Data.ShapeFallback;
};

const ShapeFallback = ({ fallback }: ShapeFallbackProps) => {
  const { id } = useSuiteObject();
  const { data } = useGetImageQuery({
    presentation_id: id,
    reference_id: fallback.fill.source,
  });

  return (
    <image
      href={data}
      {...fallback.properties.xfrm?.off}
      width={fallback.properties.xfrm?.ext?.cx}
      height={fallback.properties.xfrm?.ext?.cy}
    />
  );
};

export default ShapeFallback;
