import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

const AppOverlay = ({ children }: { children: ReactNode }) => {
  const overlay = document.getElementById('app-overlay');
  if (overlay) {
    return ReactDOM.createPortal(children, overlay);
  }
  return null;
};

export default AppOverlay;
