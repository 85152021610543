import { useEffect, useMemo } from 'react';
import KDBush from 'kdbush';
import polylabel from 'polylabel';
import { PageViewport } from 'pdfjs-dist';
import { useDispatch, useSelector } from '_common/hooks';
import AnnotationContextProvider from '../AnnotationsLayer/Annotation/AnnotationContext';
import AnnotationToggle from '../AnnotationsLayer/Annotation/AnnotationToggle';
import { getBoxFromAnnotation } from '../AnnotationsLayer/Annotation/Utils';
import AnnotationsCluster from './AnnotationsCluster';
import { setZoomValue } from 'PDF/redux/PDFGeneralSlice';
import { selectAnnotation, setClusteredAnnotations } from 'PDF/redux/PDFAnnotationsSlice';
import IconAnnotation from '../AnnotationsLayer/Annotation/IconAnnotation';
import { isContentEmpty } from 'utils';

type AnnotationsTogglesLayerProps = {
  pageNum: number;
  viewport: PageViewport;
  annotations: PDF.Annotation[];
};

const getX = (a: PDF.Annotation) => a.rect.right + 8 + 16;
const getY = (a: PDF.Annotation) => a.rect.top - 16;
const MAX_ZOOM = 64;

const AnnotationsTogglesLayer = ({
  pageNum,
  viewport,
  annotations,
}: AnnotationsTogglesLayerProps) => {
  const selectedAnnotation = useSelector((state) => state.pdf.annotations.selected);
  const zoom = useSelector((state) => state.pdf.general.zoom);
  const annotationBeingTransformed = useSelector((state) => state.pdf.annotations.beingTransformed);
  const annotationBeingEdited = useSelector((state) => state.pdf.annotations.editing);

  const dispatch = useDispatch();
  const { clusters, clustered } = useMemo(() => {
    const clustered: Record<string, { clusterId: string; pageNum: number }> = {};
    const clusters: {
      id: string;
      pageNum: number;
      annotations: PDF.Annotation[];
      coords: PDF.Annotation.Point;
    }[] = [];
    const radius = 32 / viewport.scale; // 44 is the size of the big circle of aggregation
    const minRadius = 32 / (MAX_ZOOM * 1.33);
    const index = new KDBush(annotations, getX, getY); // make an index
    annotations
      .filter((annotation) => annotation.subtype !== 'FreeText')
      .forEach((annotation, i) => {
        if (clustered[annotation.id]) {
        } else {
          const indexes = index
            .within(getX(annotation), getY(annotation), radius)
            .filter((annotationIndex) => !clustered[annotations[annotationIndex].id]); // radius search - x, y, radius
          if (indexes.length > 1 && radius > minRadius) {
            const clusterId = `${clusters.length + 1}`;
            const vertices: number[][] = [];
            const clusteredAnnotations: PDF.Annotation[] = [];
            indexes.forEach((annotationIndex) => {
              vertices.push([
                annotations[annotationIndex].rect.right,
                annotations[annotationIndex].rect.top,
              ]);
              clusteredAnnotations.push(annotations[annotationIndex]);
              clustered[annotations[annotationIndex].id] = {
                clusterId,
                pageNum: annotations[annotationIndex].pageNumber,
              }; // Which annotation cluster this point belongs to
            });
            var p =
              vertices.length > 2
                ? polylabel([vertices])
                : [
                    Math.abs(vertices[0][0] + vertices[1][0]) / 2,
                    Math.abs(vertices[0][1] + vertices[1][1]) / 2,
                  ];

            clusters.push({
              id: clusterId,
              pageNum: clusteredAnnotations[0].pageNumber,
              annotations: clusteredAnnotations,
              coords: { x: p[0] * viewport.scale, y: p[1] * viewport.scale },
            });
          }
        }
      });

    return { clusters, clustered };
  }, [annotations, viewport.scale]);

  useEffect(() => {
    const clusteredByPage: Record<number, Record<string, string>> = {};
    Object.typedKeys(clustered).forEach((annotationId) => {
      const clusteredAnnotation = clustered[annotationId];
      clusteredByPage[clusteredAnnotation.pageNum] = {
        ...clusteredByPage[clusteredAnnotation.pageNum],
        [annotationId]: clusteredAnnotation.clusterId,
      };
    });

    dispatch(setClusteredAnnotations(clusteredByPage));
  }, [clustered]);

  const onClusterClick = (annotations: PDF.Annotation[]) => {
    const index = new KDBush(annotations, getX, getY);
    let newZoom = zoom;
    while (newZoom < MAX_ZOOM) {
      newZoom *= 1.2;
      const radius = 32 / (newZoom * 1.33);
      if (
        annotations.every((annotation) => {
          return index.within(getX(annotation), getY(annotation), radius).length <= 1;
        })
      ) {
        break;
      }
    }
    dispatch(selectAnnotation(annotations[0].id));
    dispatch(setZoomValue(Math.min(newZoom, MAX_ZOOM)));
  };

  return (
    <>
      {annotations
        .filter((annotation) => {
          // always hide icon if icon annotation is clustered
          // never show icon if annotation is a freetext box
          if (clustered[annotation.id] || annotation.subtype === 'FreeText') {
            return false;
          }
          //never show icon if annotation isnt Task nor Note and doesnt have content
          if (
            annotation.subtype !== 'Task' &&
            annotation.subtype !== 'Note' &&
            isContentEmpty(annotation.content) &&
            annotation.id !== annotationBeingEdited
          ) {
            return false;
          }
          if (
            annotation.subtype === 'Note' ||
            (annotation.subtype === 'Task' && !annotation.quadPoints)
          ) {
            // never hide if annotation is a comment or task with no quadpoints
            return true;
          }
          return annotation.id !== annotationBeingTransformed;
        })
        .map((annotation) => {
          const box = getBoxFromAnnotation(annotation, viewport.scale);
          const isIconAnnotation =
            annotation.subtype === 'Note' ||
            (annotation.subtype === 'Task' && !annotation.quadPoints);

          const handleLeftValue = () => {
            if (annotation.subtype === 'Task' && !annotation.quadPoints) {
              const toggleWidth = 32; //toggle size doesnt change with zoom
              const left = Math.min(box.left, viewport.width - toggleWidth);

              return left;
            } else {
              return box.left + box.width + 8;
            }
          };

          const handleTopValue = () => {
            const toggleHeight = 32; //toggle size doesnt change with zoom
            return Math.min(
              viewport.height - box.bottom - box.height,
              viewport.height - toggleHeight,
            );
          };

          return (
            <AnnotationContextProvider
              key={annotation.id}
              annotation={annotation}
              viewport={viewport}
              pageNum={pageNum}
              isSelected={selectedAnnotation === annotation.id}
            >
              {isIconAnnotation ? (
                <IconAnnotation viewport={viewport} />
              ) : (
                <AnnotationToggle
                  key={annotation.id}
                  isOpen={selectedAnnotation === annotation.id}
                  style={{
                    left: handleLeftValue(),
                    top: handleTopValue(),
                  }}
                />
              )}
            </AnnotationContextProvider>
          );
        })}
      {clusters.map((cluster, i) => {
        return <AnnotationsCluster key={i} cluster={cluster} handleOnClick={onClusterClick} />;
      })}
    </>
  );
};

export default AnnotationsTogglesLayer;
