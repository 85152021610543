import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import * as Sentry from '@sentry/react';
import rootReducer from './rootReducer';
import { validateAppVersion } from './storeUtils';
import api from '_common/services/api/api';
import authority from '_common/services/api/authority';
import realtime from '_common/services/api/realtime';
import { injectStoreForLocalStorage } from '_common/utils/LocalStorage';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // stateTransformer: state => {
  //   // Transform the state to remove sensitive information
  //   const transformedState = {
  //     ...state,
  //     auth: {
  //       ...state.auth,
  //       publicProfiles: null,
  //       user: {
  //         ...state.auth.user,
  //         profile: null,
  //       },
  //     },
  //   };
  //   return transformedState;
  // },
  // configureScopeWithState: (scope, state) => {
  //   // Set tag to filter users.
  //   scope.setTag('user', state.auth.userId);
  //   scope.setTag('username', state.auth.user.profile.username);
  // },
});

type ReduxStoreMiddlewares = {
  serializableCheck: boolean | { ignoredActions: string[] };
  immutableCheck: boolean;
};

export function getStore(
  preloadedState: PreloadedState<RootState> | undefined = undefined,
  middlewares: ReduxStoreMiddlewares = { serializableCheck: false, immutableCheck: false },
) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: process.env.NODE_ENV !== 'test' && middlewares.serializableCheck,
        immutableCheck: process.env.NODE_ENV !== 'test' && middlewares.immutableCheck, // Enable this when testing immutability
      })
        .concat(validateAppVersion)
        .concat(api.middleware)
        .concat(authority.middleware)
        .concat(realtime.middleware),
    enhancers: [sentryReduxEnhancer],
  });
}

const store = getStore();

setupListeners(store.dispatch);
injectStoreForLocalStorage(store);

export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;

export default store;
