import { Divider } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import DueDateSystem from '_common/components/DueDateSystem/DueDateSystem';

import styles from './DueDate.module.scss';

type DueDateProps = {
  canEdit?: boolean;
};

const DueDate = ({ canEdit }: DueDateProps) => {
  const elementId = Object.keys(useSelector((state) => state.table.selected))[0];

  return (
    <div className={styles.root}>
      <Divider margin="0 1rem" />
      <div className={styles.dueDateWrapper}>
        <DueDateSystem objectId={elementId} showLabels canEdit={canEdit} />
      </div>
    </div>
  );
};

export default DueDate;
