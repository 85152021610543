import { usePresentationData } from "Presentation/PresentationData";
import { useSelector } from "_common/hooks"

const useSlideId = () => {
  const currentSlide = useSelector((state) => state.presentation.general.currentSlide) - 1;
  const { structure } = usePresentationData();

  return structure.sldIdLst[currentSlide];
}

export default useSlideId;