import { createContext, useContext } from "react";

type NotificationsCenterContextType = {
  navigateToObject: ({ objectType, objectId, documentId }: {
    objectType: 'comment' | 'suggestion' | 'task' | 'node' | 'annotation';
    objectId: ObjectId;
    documentId: ObjectId;
  }) => void;
};

const NotificationsCenterContext = createContext<NotificationsCenterContextType>({
  navigateToObject: () => { }
});

export const useNotificationsCenter = () => {
  return useContext(NotificationsCenterContext);
}

export default NotificationsCenterContext;