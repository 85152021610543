import { useEffect, useState } from 'react';
import { LocalStorage } from '_common/utils';

const useListParams = (identity: Table.Identity) => {
  const persistedParams = LocalStorage.getListParams(identity);
  const [params, setParams] = useState(persistedParams);
  useEffect(() => {
    LocalStorage.setListParams(identity, params);
  }, [params]);
  return [params, setParams] as const;
};

export default useListParams;
