import { FormattedMessage } from 'react-intl';
import { Icon, Tooltip } from 'dodoc-design-system';

import { dayjs } from 'utils';
import FormattedDate from '_common/components/FormattedDate/FormattedDate';

import type { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

type ObjectDueDateCellProps = {
  object: Objekt;
  testId: string;
};

export const objectIsOverdue = (object: Objekt) => {
  if (!object.events) {
    return false;
  }
  if (object.type === 'space') {
    return false;
  }
  if (object.status === 4) {
    return false;
  }
  let dueDate;
  if (object.events.due) {
    dueDate = dayjs(object.events.due);

    if (!dueDate.isValid()) {
      return false;
    }
    if (dayjs().isAfter(dueDate)) {
      return true;
    }
  }

  return false;
};

const ObjectDueDateCell = ({ object, testId }: ObjectDueDateCellProps) => {
  const handleShowReminder = () => {
    if (!object.events) {
      return false;
    }
    if (object.type === 'space') {
      return false;
    }
    if (object.status === 4) {
      return false;
    }
    const warningDate = object.events.warnings;
    if (warningDate) {
      for (let i = 0; i < warningDate.length; i += 1) {
        const warning = dayjs(warningDate[i]);
        if (objectIsOverdue(object)) {
          return false;
        }
        if (!warning.isValid()) {
          return false;
        }
        if (dayjs().isAfter(warning)) {
          return true;
        }
      }
    }

    return false;
  };

  const handleShowCalendar = () => {
    const dueDate = dayjs(object.events.due);

    if (!object.events) {
      return false;
    }
    if (!object.events.due) {
      return false;
    }
    if (object.type === 'space') {
      return false;
    }
    if (object.status === 4) {
      return false;
    }

    const warnings = object.events.warnings;
    if (warnings) {
      for (let i = 0; i < warnings.length; i += 1) {
        const warning = dayjs(warnings[i]);
        if (warning.isValid()) {
          if (dayjs().isBefore(warning, 'days')) {
            return true;
          }
        }
      }
    } else if (dayjs().isBefore(dueDate)) {
      return true;
    }

    return false;
  };

  const getDueDate = () => {
    const isOverdue = objectIsOverdue(object);
    const showReminder = handleShowReminder();
    const showCalendar = handleShowCalendar();

    if (isOverdue || showReminder || showCalendar) {
      let html;
      let icon: IconTypes['32'];

      if (isOverdue) {
        const delay = dayjs().diff(object.events.due, 'days');
        icon = 'CalendarWarning';
        html =
          delay === 0 ? (
            <FormattedMessage id="storage.dueDate.today" />
          ) : (
            <FormattedMessage id="storage.dueDate.overdue" values={{ delay }} />
          );
      } else {
        icon = showReminder ? 'CalendarReminder' : 'Calendar';
        const warnings = object.events.warnings;
        let nextReminder;

        if (warnings) {
          for (let i = 0; i < warnings.length; i += 1) {
            const warning = dayjs(warnings[i]);
            if (warning.isValid()) {
              if (dayjs().isBefore(warning, 'days')) {
                nextReminder = warning;
                break;
              }
            }
          }
        }
        html = (
          <>
            <div>
              <FormattedMessage id="storage.dueDate.dueDate" />
              <FormattedDate date={object.events.due} />
            </div>
            {nextReminder && (
              <div>
                <FormattedMessage id="storage.dueDate.nextReminder" />
                <FormattedDate date={nextReminder} />
              </div>
            )}
          </>
        );
      }

      return (
        <Tooltip content={html} testId={`${testId}-dude-date-tooltip`}>
          <Icon icon={icon} size={32} testId={isOverdue ? 'overdue-icon' : 'reminder-icon'} />
        </Tooltip>
      );
    }

    return null;
  };

  return getDueDate();
};

export default ObjectDueDateCell;
