//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { notify } from '_common/components/ToastSystem';
import { ReduxInterface } from 'Editor/services';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import ActionContext from '../models/ActionContext';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { Logger } from '_common/services';

export default Mixin(
  (superclass: any) =>
    class NotesEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      startCreatingNote(type: string) {
        if (this.navigationManager.isMarkerRendered()) {
          this.navigationManager.scrollIntoSelection();

          // check if selection is editable
          if (
            (!EditorSelectionUtils.isSelectionCollapsed() &&
              this.selectionManager.isCurrentSelectionDeletable()) ||
            (EditorSelectionUtils.isSelectionCollapsed() &&
              this.selectionManager.isCurrentSelectionEditable())
          ) {
            let offsets: any = {};
            let range = EditorSelectionUtils.getRange();
            if (range) {
              if (range.endContainer.nodeType !== Node.TEXT_NODE) {
                const textNode = document.createTextNode('\u200B');
                range.insertNode(textNode);
                range.setCaret(textNode, 'INDEX', 1);

                offsets = EditorDOMUtils.getOffsets(range);
                textNode.remove();
              } else {
                offsets = EditorDOMUtils.getOffsets(range);
              }
            }

            ReduxInterface.setNoteOverlayData({
              offsets,
              type,
              operation: 'create',
            });
          } else {
            notify({
              type: 'error',
              title: 'global.error',
              message: 'editor.errors.selectionNonEditable',
            });
          }
        }
      }

      handleInsertNote(type: string, id: string) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();
          let actionContext;
          try {
            this.navigationManager.scrollIntoSelection();
            this.clipboard.removePasteOptions();

            actionContext = new ActionContext();
            const note = DOMElementFactory.buildElement('note-element', {
              type,
              element_reference: id,
            });

            // emit event to insert node inline
            // this.emit(this.constructor.EVENT_INSERT_INLINE, note);
            this.handleInsertInlineNodeOnMultiSelection(actionContext, note);

            // save changes to dom
            this.changeTracker.saveActionChanges(actionContext);
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            ReduxInterface.setNoteOverlayData({
              offsets: null,
              selected: null,
            });
            this.visualizerManager?.getWidgetsManager()?.rebuildWidgets();
            this.visualizerManager.selection.debounceStartSelectionTracker();
            // ReduxInterface.stopEditorLoading();
          }
        }
      }
    },
);
