import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { dayjs } from 'utils';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

export type TrackedActionsSliceState = {
  byId: Record<Editor.TrackedAction['id'], Editor.TrackedAction>;
  order: Editor.TrackedAction['id'][];
  loaded: boolean;
  hideAll: boolean;
};

const SLICE_NAME = 'TrackedSlice';
const initialState: TrackedActionsSliceState = {
  byId: {},
  order: [],
  loaded: false,
  hideAll: false,
};

// #region Slice
const TrackedActionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateTrackedActions(state, action: PayloadAction<Editor.TrackedAction[]>) {
      const trackedActions = action.payload.reduce<TrackedActionsSliceState['byId']>(
        (previous, trackedAction) => {
          if (trackedAction?.id) {
            previous[trackedAction.id] = {
              ...trackedAction,
              comments: trackedAction.comments.map((comment) => ({
                ...comment,
                content:
                  typeof comment.content === 'object'
                    ? JSON.stringify(comment.content)
                    : comment.content,
              })),
            };
          }
          return previous;
        },
        {},
      );
      state.byId = { ...state.byId, ...trackedActions };
    },
    loadTrackedActions(
      state,
      action: PayloadAction<Pick<TrackedActionsSliceState, 'byId' | 'order'>>,
    ) {
      const { byId, order } = action.payload;
      state.order = order;
      state.loaded = true;

      const trackedActions = Object.values(byId).reduce<TrackedActionsSliceState['byId']>(
        (previous, trackedAction) => {
          if (trackedAction?.id) {
            previous[trackedAction.id] = {
              ...trackedAction,
              comments: trackedAction.comments.map((comment) => ({
                ...comment,
                content:
                  typeof comment.content === 'object'
                    ? JSON.stringify(comment.content)
                    : comment.content,
              })),
            };
          }
          return previous;
        },
        {},
      );
      state.byId = { ...state.byId, ...trackedActions };
    },
    setHideTrackedActions(state, action: PayloadAction<boolean>) {
      state.hideAll = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

const getTrackedActionsList = (state: RootState) => state.editor.trackedActions.byId;
const getTrackedActionsOrder = (state: RootState) => state.editor.trackedActions.order;
const getTrackedActionsFilters = (state: RootState) => state.filters.editorTrackPanel;

const getDocumentObject = createSelector(
  [(state: RootState) => state.app.data, (state: RootState) => state.editor.status.documentId],
  (data, documentId) => {
    return data[documentId];
  },
);

const subCommentsDontMatchAuthor = (userId: UserId, comments: Editor.TrackedAction['comments']) => {
  for (const comment of comments) {
    if (userId === comment.author || userId === comment.user) {
      return false;
    }
  }
  return true;
};

export const selectFilteredTrackedActions = createSelector(
  [getTrackedActionsOrder, getTrackedActionsList, getTrackedActionsFilters, getDocumentObject],
  (order, actions, filters, document) => {
    if (!document || !document.user_permissions) {
      return {
        actions,
        order: [],
        total: 0,
      };
    }

    const filterValues = {
      trackedActionType: filters.trackedActionType?.map((filterValue) => filterValue.value),
      cardPriority: filters.cardPriority?.map((filterValue) => filterValue.value),
      authors: filters.author?.map((filterValue) => filterValue.value),
      creationDate: filters.creationDate,
    };

    const filterTrackedAction = (id: Editor.TrackedAction['id']) => {
      if (!actions[id]) {
        return false;
      }

      // // By type
      if (
        filterValues.trackedActionType &&
        !filterValues.trackedActionType.includes(actions[id].type)
      ) {
        return false;
      }

      // By priority
      if (filterValues.cardPriority && !filterValues.cardPriority.includes(actions[id].priority)) {
        return false;
      }

      // By authors
      if (filterValues.authors) {
        if (
          filterValues.authors.every(
            (author) =>
              author !== actions[id].author &&
              author !== actions[id].user &&
              subCommentsDontMatchAuthor(author, actions[id].comments),
          )
        ) {
          return false;
        }
      }

      // By date
      if (filterValues.creationDate?.startISO) {
        if (!dayjs(filterValues.creationDate.startISO).isSameOrBefore(actions[id].time, 'day')) {
          return false;
        }
      }

      if (filterValues.creationDate?.endISO) {
        if (!dayjs(filterValues.creationDate.endISO).isSameOrAfter(actions[id].time, 'day')) {
          return false;
        }
      }

      return true;
    };

    return {
      actions,
      order: order.filter(filterTrackedAction),
      total: order.length,
    };
  },
);

export const selectSuggestionsAuthors = createSelector(
  [getTrackedActionsList, getTrackedActionsOrder],
  (dict, list) => {
    const users: { id: string; imported?: boolean }[] = [];
    const addUser = (trackedAction: Editor.TrackedAction | Editor.Reply) => {
      for (let i = 0; i < users.length; i++) {
        const u = users[i];
        if (u.id === trackedAction.author || u.id === trackedAction.user) {
          return;
        }
      }
      const user: { id: string; imported?: boolean } = { id: '' };
      if (trackedAction.user) {
        // Imported user
        user.id = trackedAction.user;
        user.imported = true;
      } else if (trackedAction.author) {
        // dodoc user
        user.id = trackedAction.author;
      }

      users.push(user);
    };
    for (let i = 0; i < list.length; i++) {
      let trackedAction = dict[list[i]];
      addUser(trackedAction);
      for (let j = 0; j < trackedAction.comments.length; j++) {
        const subcomment = trackedAction.comments[j];
        addUser(subcomment);
      }
    }
    return users;
  },
);

export default TrackedActionsSlice.reducer;
// #endregion

// #region Actions
export const { updateTrackedActions, loadTrackedActions, setHideTrackedActions } =
  TrackedActionsSlice.actions;
// #endregion
