import { useChartColor, useChartOutline } from '.';
import useChartSVG from '../useChartSVG';

type LineDataItemOption = Exclude<
  NonNullable<echarts.LineSeriesOption['data']>[number],
  string | number | Date | any[]
>;

const LIB_SYMBOLS: Partial<Record<Presentation.Data.Marker['symbol'], string | null>> = {
  circle: 'circle',
  square: 'rect',
  diamond: 'diamond',
  triangle: 'triangle',
  none: null,
};

const useMarkerProperties = () => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { markerSymbol } = useChartSVG();

  const markerProperties = ({ marker }: { marker: Presentation.Data.Marker | undefined }) => {
    if (!marker) {
      return undefined;
    }

    let symbolBase64: string | undefined = undefined;

    //@ts-expect-error
    let properties: Pick<LineDataItemOption, 'symbol' | 'symbolSize' | 'itemStyle'> = {};

    if (marker?.properties) {
      const outline = chartOutline(marker.properties.ln);
      properties.symbol = marker.symbol;
      properties.symbolSize = marker.size;
      properties.itemStyle = {
        ...outline,
        //CHARTS:LIMITATION color doesnt support picture
        color: chartColor(marker.properties.fill),
      };
    }

    if (marker?.symbol) {
      if (!LIB_SYMBOLS[marker?.symbol]) {
        const symbolSVG = markerSymbol({
          symbol: marker?.symbol,
          properties: marker?.properties,
          height: marker?.size ?? 0,
          width: marker?.size ?? 0,
        });

        if (symbolSVG) {
          symbolBase64 = window.btoa(symbolSVG);
        }
      }
    }

    //"symbol" cant be null, but this is a hack to display data labels without symbol.
    properties.symbol = marker?.symbol
      ? symbolBase64
        ? `image://data:image/svg+xml;base64,${symbolBase64}`
        : LIB_SYMBOLS[marker.symbol]
      : null;

    if (properties.symbol === null) {
      properties.symbolSize = 0;
    }

    return properties;
  };

  return { markerProperties };
};

export default useMarkerProperties;
