import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useDispatch } from '_common/hooks';
import { navigateTo, navigateToMyFiles, navigateToSignIn } from 'router/history';

import { gotFeedback } from 'Auth/redux/authSlice';
import { useGetTokenInfoQuery } from '_common/services/api/authority';

import { LoadingPage } from '_common/components';
import { LocalStorage } from '_common/utils';

/**
 * Dummy component. Handles ThirdParty auth route
 */
const RedirectRoot = () => {
  const dispatch = useDispatch();
  const { status, token, device, redirect, tenant } = useParams<{
    status: string;
    token: string;
    device: string;
    redirect: string;
    tenant: string;
  }>();

  const { isSuccess } = useGetTokenInfoQuery(token);
  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const redirectSso = sessionStorage.getItem('redirect-sso');
    if (redirect && redirect.includes('-')) {
      const [route, id] = redirect.split('-');
      navigateTo(`/${route}/${id}`);
    } else if (redirectSso !== null) {
      navigateTo(redirectSso);
    } else {
      navigateToMyFiles();
    }
  }, [isSuccess]);

  // Navigates user to the root
  useEffect(() => {
    if (status === 'ok' && token && device) {
      if (tenant) {
        LocalStorage.setTenant(tenant);
      }
    } else {
      let message = '';
      switch (status) {
        case 'general':
        case 'unknown':
        case 'jwtinvalid':
          message = 'auth.errors3p.general';
          break;
        case 'deactivated':
          message = 'auth.errors3p.deactivated';
          break;
        case 'nolink':
          message = 'auth.errors3p.nolink';
          break;
        default:
          message = '';
      }
      if (message) {
        dispatch(gotFeedback({ message, type: 'error' }));
      }
      navigateToSignIn();
    }
  }, []);

  /**
   * Renders the Loading component
   *
   * @return `Loading` component (see {@link Loading}).
   */
  return <LoadingPage />;
};

export default RedirectRoot;
