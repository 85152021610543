import Options from 'Editor/modals/ExportIntegrationModal/Menus/Options/Options';
import styles from 'Editor/modals/ExportIntegrationModal/Menus/Menus.module.scss';

import type { ExportIntegrationModal } from 'Editor/modals/ExportIntegrationModal/ExportIntegrationModalTypes';
import type { Menu } from 'Editor/modals/ExportIntegrationModal/Menus/MenuTypes';
import type { OptionsProps } from 'Editor/modals/ExportIntegrationModal/Menus/Options/Options';

export type ExpansibleMenusProps<T extends ExportIntegrationModal.ExportType> = Menu.BaseProps<T> &
  Required<Pick<OptionsProps<T>, 'selectedSubOption' | 'setSelectedSubOption'>>;

const ExpansibleMenus = <T extends ExportIntegrationModal.ExportType>({
  info,
  selectedOption,
  setSelectedOption,
  selectedSubOption,
  setSelectedSubOption,
  utilsMenusComponent,
  isOptionOpen,
  setOptionState,
}: ExpansibleMenusProps<T>) => {
  const handleChangeOption = (elem: ExportIntegrationModal.Element) => {
    if (selectedOption.id !== elem.id || selectedSubOption.id) {
      setSelectedOption(elem);
      setSelectedSubOption({ id: '', name: '' });
      info.getContent(elem, { size: 20 });
    }
  };

  return (
    <div className={styles.menus}>
      {info.instances.map((element) => (
        <div key={element.key} className={styles.instancesContainer}>
          <div className={styles.label}>{element.key}</div>
          {element.value.map((elem) => (
            <Options
              info={info}
              elem={elem}
              key={elem.name}
              selectedOption={selectedOption}
              selectedSubOption={selectedSubOption}
              setSelectedOption={setSelectedOption}
              onClick={() => handleChangeOption(elem)}
              setSelectedSubOption={setSelectedSubOption}
              utilsMenusComponent={utilsMenusComponent}
              isOptionOpen={isOptionOpen}
              setOptionState={setOptionState}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
export default ExpansibleMenus;
