
type AuthorsCellProps = {
  object: Objekt;
};

const AuthorsCell = ({ object }: AuthorsCellProps) => {
  const authors = object.authors
    ?.map((author: Author) =>
      [author.first_name, author.middle_name, author.last_name]
        .filter((name) => name)
        .join(' '),
    )
    .join(', ');

  return (
    <span title={authors}>{authors}</span>
  )

};

export default AuthorsCell;
