import { useIntl } from 'react-intl';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { FormattedDate, LineEllipsis } from '_common/components';
import { Setting } from 'Settings/components';

import styles from './RoleGeneralTab.module.scss';

type RoleGeneralTabProps = { role: Role };

const RoleGeneralTab = ({ role }: RoleGeneralTabProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleChangeRoleName = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({ id: 'settings.roles.editRoleName' }),
          label: intl.formatMessage({ id: 'settings.roles.roleName' }),
          value: role.name,
          inputPlaceholder: intl.formatMessage({ id: 'ROLE_NAME_PLACEHOLDER' }),
          inputType: 'text',
          type: role.name && 'name',
          action: 'renameRole',
          actionValues: { id: role.id },
        },
      }),
    );
  };

  const handleChangeRoleDescription = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'GenericSettingModal',
        data: {
          header: intl.formatMessage({ id: 'settings.roles.editRoleDescription' }),
          label: intl.formatMessage({ id: 'settings.roles.roleDescription' }),
          value: role.description,
          inputPlaceholder: intl.formatMessage({ id: 'ROLE_DESCRIPTION_PLACEHOLDER' }),
          inputType: 'textarea',
          type: 'description',
          action: 'editRoleDescription',
          actionValues: { id: role.id, type: 'role' },
        },
      }),
    );
  };

  return (
    <div className={styles.root}>
      <Setting
        style={{ alignItems: 'flex-start' }}
        label={intl.formatMessage({ id: 'global.name' })}
        onEdit={handleChangeRoleName}
        onEditLabel="global.edit"
        testId="name"
      >
        {role.name}
      </Setting>
      <Setting
        style={{ alignItems: 'flex-start' }}
        label={intl.formatMessage({ id: 'global.creationDate' })}
        testId="creation-date"
      >
        {<FormattedDate date={role.time.creation} />}
      </Setting>
      <Setting
        style={{ alignItems: 'flex-start' }}
        label={intl.formatMessage({ id: 'global.description' })}
        onEdit={handleChangeRoleDescription}
        onEditLabel="global.edit"
        testId="description"
      >
        <LineEllipsis>
          {role?.description ?? intl.formatMessage({ id: 'NO_DESCRIPTION' })}
        </LineEllipsis>
      </Setting>
    </div>
  );
};

export default RoleGeneralTab;
