import { BaseService } from '_common/services';
import SocketIO from './SocketIO/SocketIO';
import ShareDB from './ShareDB/ShareDB';
import { BaseTypedEmitter } from '../BaseTypedEmitter';
import { LocalStorage, SessionStorage } from '_common/utils';

type TransportEvent = keyof Realtime.Transport.TransportEvents;

class Transport extends BaseTypedEmitter<Realtime.Transport.TransportEvents> {
  _documentId: string | null;
  _token: string | null;

  _status: TransportEvent;
  _disconnect: boolean;
  _isDestroying: boolean;

  _RESTService: BaseService;

  // TODO: specify these variables
  _shareDB: any;
  _socketIO: any;

  get connected(): boolean {
    return this._status === 'TS_CONNECTED';
  }

  __handleSocketIOConnection() {
    if (this._shareDB.connected === true) {
      this._status = 'TS_CONNECTED';
      this.emit(this._status);
    }
  }

  __handleShareDBConnection() {
    if (this._socketIO.connected === true) {
      this._status = 'TS_CONNECTED';
      this.emit(this._status);
    }
  }

  __handleDisconnection() {
    if (this._disconnect !== true && this._status !== 'TS_DISCONNECTED') {
      // if the disconnection wasn't forced
      this._status = 'TS_DISCONNECTED';
      this.emit(this._status);
    }
  }

  __handleError(event: string, error: any) {
    // We are assuming that if the error caused a disconnection
    // it would send a disconnect event. Log this transport error.
    this.emit('TS_ERROR', event, error);
  }

  __handleDestroy() {
    if (!this._isDestroying) {
      this._isDestroying = true;
      if (this._socketIO != null) {
        this._socketIO.destroy();
      }

      if (this._shareDB != null) {
        this._shareDB.destroy();
      }
    } else {
      this.emit('TS_DESTROYED');
    }
  }

  constructor(config: any) {
    super();
    this._disconnect = false;
    this._isDestroying = false;

    this.__handleSocketIOConnection = this.__handleSocketIOConnection.bind(this);
    this.__handleShareDBConnection = this.__handleShareDBConnection.bind(this);
    this.__handleDisconnection = this.__handleDisconnection.bind(this);
    this.__handleError = this.__handleError.bind(this);
    this.__handleDestroy = this.__handleDestroy.bind(this);

    // manage socketIO and sharedb separately
    this._socketIO = new SocketIO(config);
    this._socketIO.onConnect(this.__handleSocketIOConnection);
    this._socketIO.onDisconnect(this.__handleDisconnection);
    this._socketIO.onError(this.__handleError);
    this._socketIO.onDestroy(this.__handleDestroy);

    this._shareDB = new ShareDB(config);
    this._shareDB.onConnect(this.__handleShareDBConnection);
    this._shareDB.onDisconnect(this.__handleDisconnection);
    this._shareDB.onError(this.__handleError);
    this._shareDB.onDestroy(this.__handleDestroy);

    this._status = 'TS_INITIALIZED';

    this._RESTService = new BaseService(config.api);

    this._documentId = null;
    this._token = null;
  }

  get sharedb() {
    return this._shareDB;
  }

  get socketio() {
    return this._socketIO;
  }

  get API() {
    return {
      get: this._RESTService.get,
      post: this._RESTService.post,
    };
  }

  isConnected() {
    return this._status === 'TS_CONNECTED';
  }

  connect(documentId: string, token: string) {
    if (this._status === 'TS_INITIALIZED' || this._status === 'TS_DISCONNECTED') {
      this._documentId = documentId;
      this._token = SessionStorage.getToken();
      this._disconnect = false;
      this._status = 'TS_CONNECTING';
      this.emit(this._status);
      this._socketIO.connect(LocalStorage.getTenant(), documentId, this._token);
      this._shareDB.connect(LocalStorage.getTenant(), documentId, this._token);
    } else {
      throw new Error(`cannot call transport.connect with the status: ${this._status}`);
    }
  }

  disconnect() {
    this._disconnect = true;
    // Might be usefull to let the editor know that
    // the transport is disconnecting
    this.emit('TS_DISCONNECTING');
    // this flag lets us know that this was forced
    this._socketIO.disconnect();
    this._shareDB.disconnect();
    this._status = 'TS_DISCONNECTED';
    this.emit(this._status);
  }

  destroy() {
    this._isDestroying = true;
    this._socketIO.destroy();
    this._socketIO = undefined;
    this._shareDB.destroy();
    this._shareDB = undefined;
    this._status = 'TS_DESTROYED';
    super.destroy();
  }

  reconnect(documentId: string, token: string) {
    this.disconnect();
    this.connect(documentId, token);
  }

  dispatchEvent<
    E extends Realtime.Transport.ClientEventName,
    P extends Realtime.Transport.ClientEvents[E],
    R extends Realtime.Transport.ClientEventResponse[E],
  >(event: E, message: P, acknowledgment?: (response: R) => void) {
    this._socketIO.dispatchEvent(
      event,
      {
        document: this._documentId,
        ...(message as object),
      },
      acknowledgment || (() => {}),
    );
  }

  handleEvent<
    E extends Realtime.Transport.ServerEventName,
    H extends Realtime.Transport.ServerEvents[E],
  >(event: E, callback: H) {
    this._socketIO.handleEvent(event, callback);
  }

  removeEvent<
    E extends Realtime.Transport.ServerEventName,
    H extends Realtime.Transport.ServerEvents[E],
  >(event: E, callback: H) {
    this._socketIO.removeEvent(event, callback);
  }

  clearAllEventListeners() {
    this._socketIO.clearAllEventListeners();
  }

  get(...args: any[]) {
    return this._shareDB.get(...args);
  }

  getPresence(...args: any[]) {
    return this._shareDB.getPresence(...args);
  }

  createFetchQuery(...args: any[]) {
    return this._shareDB.createFetchQuery(...args);
  }

  createSubscribeQuery(...args: any[]) {
    return this._shareDB.createSubscribeQuery(...args);
  }

  fetchSnapshot(...args: any[]) {
    return this._shareDB.fetchSnapshot(...args);
  }

  getLastVersionOf(collection: any, docId: string, v: any, callback?: (...args: any[]) => void) {
    return this._shareDB.getLastVersionOf(collection, docId, v, callback);
  }

  fetchSnapshotByTimestamp(...args: any[]) {
    return this._shareDB.fetchSnapshotByTimestamp(...args);
  }

  checkConnectionStrength(): Promise<number> {
    return new Promise((resolve) => {
      const timeout = setTimeout(resolve, 1000, 1000);
      const start = new Date().getTime();
      this.dispatchEvent('ECHO', {}, () => {
        clearTimeout(timeout);
        const end = new Date().getTime();
        resolve(end - start);
      });
    });
  }
}

export default Transport;
