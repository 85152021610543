import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import { InstanceService } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import { addData } from 'App/redux/appSlice';

import {
  addToList,
  singleSelection,
  multipleSelection,
  clearSelection,
} from '_common/components/Table/TableSlice';
import { openAndUpdateModal, updateModal, closeAndResetModal } from '_common/modals/ModalsSlice';
import { AxiosError, AxiosResponse } from 'axios';
import { setExplorerFiles } from 'App/redux/onboardingSlice';

type CreateObjectParams = {
  name: string;
  description: string;
  //Space parent of object
  space?: ObjectId;
  //Parent of object
  parent?: ObjectId;
  pageSize?: PageSize;
  pageOrientation?: PageOrientation;
};

type ImportDocParams = {
  name: string;
  description: string;
  //Space parent of object
  space?: ObjectId;
  //Parent of object
  parent?: ObjectId;
  file: File;
};

type UploadFileParams = {
  description: string;
  //Space parent of object
  space?: ObjectId;
  //Parent of object
  parent?: ObjectId;
  files: File[];
};

type BreadcrumbState = {
  parents: { id: ObjectId; personal: boolean }[];
  shared?: boolean;
};

type StoragePageSliceState = {
  current?: ObjectId;
  infoPanelOpen?: boolean;
  permissions: { folder?: boolean; document?: boolean; file?: boolean };
  breadcrumb: BreadcrumbState;
};

const SLICE_NAME = 'STORAGE_PAGE';

const INITIAL_BREADCRUMB: BreadcrumbState = {
  parents: [],
  shared: false,
};

const INITIAL_STATE: StoragePageSliceState = {
  current: undefined,
  infoPanelOpen: false,
  permissions: {},
  breadcrumb: INITIAL_BREADCRUMB,
};

// #region AsyncThunks
export const loadStorageData = createAsyncThunk(
  `${SLICE_NAME}/loadStorageData`,
  async (
    { id, type, parameters }: { id: ObjectId; type: ObjectType; parameters: Request.AllListParams },
    { dispatch },
  ) => {
    if (!id) {
      const { data } = await new InstanceService().getPersonalSpaceInfo();
      // @ts-expect-error Missing endpoint type "/api/object/space/get"
      id = data.id;
      // @ts-expect-error Missing endpoint type "/api/object/space/get"
      type = data.type;
    }

    const { data } = await new InstanceService().listObject(id, type, parameters);

    // @ts-expect-error Generic endpoint
    dispatch(addData({ [data.source.id]: data.source }));

    const breadcrumb = { ...INITIAL_BREADCRUMB };
    if (type === 'folder') {
      const { data } = await new InstanceService().listObjectPath(id);
      // @ts-expect-error Missing endpoint type "/api/object/space/get"
      breadcrumb.parents = data.parents;
    }

    return {
      // @ts-expect-error Generic endpoint
      permissions: data.permissions,
      breadcrumb,
      // @ts-expect-error Generic endpoint
      current: data.source.id,
    };
  },
);

export const loadPersonalSpace = createAsyncThunk(
  `${SLICE_NAME}/LOAD_PERSONAL_SPACE`,
  async (parameters: Request.AllListParams, { dispatch }) => {
    const { data } = await new InstanceService().getPersonalSpaceInfo();
    // @ts-expect-error Missing endpoint type "/api/object/space/get"
    dispatch(loadStorageData({ id: data.id, type: data.type, parameters }));
  },
);

export const createObject = createAsyncThunk(
  `${SLICE_NAME}/createObject`,
  async (
    {
      identity,
      parameters,
      type,
      skipSelection,
    }: {
      identity: Table.Identity;
      parameters: CreateObjectParams;
      type: ObjectType;
      skipSelection?: boolean;
    },
    { dispatch, getState },
  ) => {
    try {
      const { pageSize, pageOrientation } = parameters;
      const pageParams: { page_size?: PageSize; orientation?: string } = {};
      if (pageSize) {
        pageParams.page_size = pageSize;
        delete parameters.pageSize;
      }
      if (pageOrientation) {
        pageParams.orientation = pageOrientation.charAt(0).toUpperCase();
        delete parameters.pageOrientation;
      }

      const { data } = await new InstanceService({
        errorsExpected: [400],
      }).createObject({ ...parameters, ...pageParams }, type);

      // @ts-expect-error Generic endpoint
      dispatch(addData({ [data.id]: data }));

      notify({
        type: 'success',
        title: 'NEW_ELEMENT_TYPE_CREATED',
        titleValues: { elementType: type },
        message: 'NEW_ELEMENT_TYPE_SUCCESSFULLY_CREATED',
        messageValues: { elementType: type },
      });

      const currentObject = (getState() as RootState).storage.current;
      const appData = (getState() as RootState).app.data;
      const active = (getState() as RootState).onboarding.active.explorer;
      const started = (getState() as RootState).onboarding.started.explorer;

      if (active || started) {
        // @ts-expect-error Generic endpoint
        dispatch(setExplorerFiles(data.id));
      }

      const isPersonalSpace =
        currentObject &&
        !parameters.space &&
        appData[currentObject]?.personal &&
        appData[currentObject]?.type === 'space';

      if (identity) {
        if (
          currentObject === parameters.space ||
          currentObject === parameters.parent ||
          isPersonalSpace
        ) {
          // @ts-expect-error Generic endpoint
          dispatch(addToList({ identity, objectId: data.id }));
        }
        if (!skipSelection) {
          // @ts-expect-error Generic endpoint
          dispatch(singleSelection({ identity, objectId: data.id }));
        }
      }

      return data;
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (
          error?.response?.status === 400 &&
          error?.response?.data.name[0] === 'invalid_character'
        ) {
          notify({
            type: 'error',
            title: 'UNSUPPORTED_CHARACTER',
            message: 'DOCUMENT_UNSUPPORTED_CHARACTER_MESSAGE',
          });
        }
      }
    }
  },
);

export const importFile = createAsyncThunk(
  `${SLICE_NAME}/importFile`,
  async (
    {
      identity,
      parameters,
      objectType,
    }: {
      identity: Table.Identity;
      parameters: ImportDocParams;
      objectType: 'document' | 'dopdf' | 'presentation';
    },
    { dispatch },
  ) => {
    if (objectType === 'dopdf') {
      if (!parameters.name.endsWith('.pdf')) {
        parameters.name = `${parameters.name}${'.pdf'}`;
      }
    }

    dispatch(
      openAndUpdateModal({
        modal: 'UploadProgressModal',
        data: {
          name: parameters.name,
          size: parameters.file.size,
          percentage: 0,
        },
      }),
    );

    try {
      const { data } = await new InstanceService({
        errorsExpected: [400],
      }).importFileContent(
        parameters,
        (progressObject) => {
          dispatch(
            updateModal({
              modal: 'UploadProgressModal',
              data: {
                percentage: progressObject.percentage,
              },
            }),
          );
        },
        objectType,
      );

      dispatch(addData({ [data.id]: data }));
      dispatch(closeAndResetModal('UploadProgressModal'));

      if (identity) {
        dispatch(addToList({ identity, objectId: data.id }));
        dispatch(singleSelection({ identity, objectId: data.id }));
      }

      notify({
        type: 'success',
        title: 'FILE_IMPORTED',
        message: 'THE_FILE_WAS_SUCCESSFULLY_IMPORTED',
      });
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error?.response?.status === 400 && error?.response?.data?.file[0] === 'not_supported') {
          dispatch(closeAndResetModal('UploadProgressModal'));

          let message;
          switch (objectType) {
            case 'document':
              message = 'storage.notifications.importDocument.invalidFormat';
              break;
            case 'dopdf':
              message = 'FILE_NOT_IMPORTED_MAKE_SURE_ITS_A_VALID_PDF';
              break;
            case 'presentation':
              message = 'FILE_NOT_IMPORTED_MAKE_SURE_ITS_A_VALID_PPT';
              break;
          }

          notify({
            type: 'error',
            title: 'storage.notifications.uploadFile.message1',
            message,
          });
        } else {
          dispatch(closeAndResetModal('UploadProgressModal'));
        }
      }
    }
  },
);

export const uploadFile = createAsyncThunk(
  `${SLICE_NAME}/uploadFile`,
  async (
    { identity, parameters }: { identity: Table.Identity; parameters: UploadFileParams },
    { dispatch },
  ) => {
    dispatch(clearSelection());
    dispatch(
      openAndUpdateModal({
        modal: 'UploadProgressModal',
        data: {
          name: parameters.files[0].name,
          size: parameters.files[0].size,
          percentage: 0,
        },
      }),
    );

    const files = parameters.files;

    const uploadProgress = (progressEvent: ProgressEvent, file: File, index: number) => {
      const partialProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      const elementProgress = Math.floor(100 / files.length);
      const totalProgress = elementProgress * index + (partialProgress * elementProgress) / 100;

      dispatch(
        updateModal({
          modal: 'UploadProgressModal',
          data: {
            percentage: totalProgress,
            name: file.name,
            size: file.size,
          },
        }),
      );
    };

    const requestsData: {
      request: 'success' | 'error' | 'cancelled';
      data?: AxiosResponse<any>['data'];
      error?: AxiosError<any>;
    }[] = [];

    const promises = files.reduce(
      (previousPromise, file, index) =>
        previousPromise.then(
          () =>
            new Promise<void>(async (resolve) => {
              try {
                const response = await new InstanceService({ errorsExpected: [400] }).uploadFile(
                  {
                    file,
                    description: parameters.description,
                    space: parameters.space,
                    parent: parameters.parent,
                  },
                  (progressEvent) => {
                    uploadProgress(progressEvent, file, index);
                  },
                );

                requestsData.push({
                  request: 'success',
                  data: response.data,
                });
                resolve();
              } catch (error) {
                if (InstanceService.isAxiosError(error)) {
                  if (error?.response?.status === 400) {
                    requestsData.push({
                      request: 'error',
                      error: error.response.data,
                    });
                    resolve();
                  } else {
                    requestsData.push({
                      request: 'error',
                      error,
                    });
                    resolve();
                  }
                }
              }
            }),
        ),
      Promise.resolve(),
    );

    return new Promise<void>((resolve) => {
      promises
        .then(() => {
          const status = requestsData.reduce((status, element) => {
            if (element.request === 'error') {
              return 'error';
            }
            if (element.request === 'cancelled') {
              return status;
            }
            dispatch(addData({ [element.data.id]: element.data }));

            if (identity) {
              dispatch(addToList({ identity, objectId: element.data.id }));
              dispatch(singleSelection({ identity, objectId: element.data.id }));
            }

            return 'success';
          }, 'cancelled');

          dispatch(closeAndResetModal('UploadProgressModal'));

          if (status === 'error') {
            notify({
              type: 'error',
              title: 'notifications.uploadFile.title',
              message: 'notifications.uploadFile.messageErrorSome',
            });
          } else if (status === 'success') {
            notify({
              type: 'success',
              title: 'FILE_UPLOADED',
              titleValues: { length: `${requestsData.length}` },
              message: 'THE_FILE_WAS_SUCCESSFULLY_UPLOADED',
              messageValues: { length: `${requestsData.length}` },
            });
          }

          dispatch(
            multipleSelection({
              objectsId: requestsData.map((request) => request.data.id),
            }),
          );

          resolve();
        })
        .catch(() => {
          dispatch(closeAndResetModal('UploadProgressModal'));
        });
    });
  },
);
// #endregion

// #region Slice
const StoragePageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    toggleInfoPanel: (state) => {
      state.infoPanelOpen = !state.infoPanelOpen;
    },
    setInfoPanelOpenValue: (
      state,
      action: PayloadAction<StoragePageSliceState['infoPanelOpen']>,
    ) => {
      state.infoPanelOpen = !!action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadStorageData.fulfilled, (state, action) => {
      const { permissions, breadcrumb, current } = action.payload;

      state.permissions = permissions;
      state.breadcrumb = breadcrumb;
      state.current = current;
    });
  },
});

// Persistence
const persistConfig = {
  key: 'storage',
  storage: localStorage,
  whitelist: ['infoPanelOpen'],
};

const reducer = persistReducer(persistConfig, StoragePageSlice.reducer);

export default reducer;
// #endregion

// #region Actions
export const { toggleInfoPanel, setInfoPanelOpenValue } = StoragePageSlice.actions;
// #endregion
