import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import styles from './TemplateEmptyState.module.scss';

const TemplateEmptyState = () => {
  const intl = useIntl();

  return (
    <div className={styles.root}>
      <EmptyState size="large" icon="Templates" title={intl.formatMessage({ id: 'NO_TEMPLATES' })} testId="no-templates">
        <FormattedMessage id="TENANT_DOESNT_HAVE_TEMPLATES_UPLOADED" />
      </EmptyState>
    </div>
  );
};

export default TemplateEmptyState;
