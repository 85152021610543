import { ColorContrastChecker } from 'utils';
import { ELEMENTS } from 'Editor/services/consts';
import colors, { rgbToHex } from 'assets/colors';
import styles from './CurrentStylePreview.module.scss';
import { useSelector } from '_common/hooks';
import { selectDocumentStyles } from 'Editor/redux/StylesSlice';

const BACKGROUND_COLORS = [
  colors['color-white-base'],
  colors['color-dark-blue-tint-20'],
  colors['color-text-base'],
];

const VALID_COLOR_CONTRAST_LEVEL = 4.5;

//TODO: Swap this to propper type when RT provides Style type
type TempStyle = {
  extendedP: MyAny;
  p: MyAny;
  n: MyAny;
};

const CurrentStylePreview = () => {
  const {
    id: styleId,
    changed,
    p: changedProperties,
  } = useSelector((state) => state.editor.status.selectedStyle) as {
    //TODO: Swap this to propper type when RT provides Style type
    id: ObjectId;
    changed: boolean;
    p: MyAny;
  };
  const style = (
    useSelector(selectDocumentStyles) as {
      list: ObjectId[];
      data: { [key in ObjectId]: TempStyle };
    }
  ).data[styleId];

  const elementStyles = (style && (style.extendedP || style.p || style)) || {};

  const fontFamily = (changed && changedProperties.fontfamily) || elementStyles.fontfamily;

  let color = (changed && changedProperties.color) || elementStyles.color;
  if (color && !color.includes('rgb') && !color.includes('#')) {
    color = `#${color}`;
  }

  let backgroundColor =
    changed && changedProperties.bg != null ? changedProperties.bg : elementStyles.bg;
  let contrastBackgroundColor = null;

  if (backgroundColor != null) {
    if (backgroundColor === false) {
      backgroundColor = '#ffffff';
    } else if (!backgroundColor.includes('rgb') && !backgroundColor.includes('#')) {
      backgroundColor = `#${backgroundColor}`;
    }
  }

  const bold = (changed && changedProperties.bold) || elementStyles.bold;
  const italic = (changed && changedProperties.italic) || elementStyles.italic;
  const underline = (changed && changedProperties.underline) || elementStyles.underline;
  const lineHeight = 1.33;
  const marginLeft =
    (elementStyles.sp_ind && Number(elementStyles.sp_ind.v) !== 0) ||
    (elementStyles.ind && Number(elementStyles.ind.l) !== 0) ||
    (changedProperties &&
      ((changedProperties.ind && Number(changedProperties.ind.l) !== 0) ||
        (changedProperties.sp_ind && Number(changedProperties.sp_ind.v) !== 0)))
      ? '48px'
      : 0;

  if (
    (!backgroundColor ||
      backgroundColor.toLowerCase() === '#ffffff' ||
      rgbToHex(backgroundColor) === '#ffffff') &&
    color &&
    color[0] !== '#'
  ) {
    const colorHex = rgbToHex(color);
    for (let i = 0; i < BACKGROUND_COLORS.length; i++) {
      contrastBackgroundColor = BACKGROUND_COLORS[i];
      if (
        ColorContrastChecker(colorHex ?? '', contrastBackgroundColor, VALID_COLOR_CONTRAST_LEVEL)
      ) {
        break;
      }
    }
  }

  return (
    <div
      //@ts-expect-error custom attribute usage
      element_type={ELEMENTS.ParagraphElement.ELEMENT_TYPE}
      className={`dodoc-${styleId} ${styles.preview} ${
        style && !contrastBackgroundColor ? styles.background : undefined
      }`}
      style={{
        fontFamily: fontFamily ? `"${fontFamily}"` : undefined,
        fontSize: '12px',
        backgroundColor: `${contrastBackgroundColor || backgroundColor}`,
        color: `${color || 'inherit'}`,
        fontWeight: `${bold ? 'bold' : 'normal'}`,
        fontStyle: `${italic ? 'italic' : 'normal'}`,
        textDecoration: `${underline ? 'underline' : 'none'}`,
        paddingLeft: 0,
        marginLeft,
        lineHeight,
        textIndent: 0,
      }}
    >
      {`${style ? style.n : ''}${changed ? '*' : ''}`}
    </div>
  );
};

export default CurrentStylePreview;
