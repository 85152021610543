import { useNoteData } from './NoteData';
import { useSelector } from '_common/hooks';
import { usePresentationData } from 'Presentation/PresentationData';
import ShapeDataProvider from '../Slide/Shapes/ShapeData';
import SlideDataProvider from '../Slide/SlideData';
import styles from '../Slide/Slide.module.scss';
import NoteShape from '../Slide/Shapes/NoteShape';

const Note = () => {
  const { structure } = usePresentationData();
  const { notesSlide } = useNoteData();

  const currentSlide = useSelector((state) => state.presentation.general.currentSlide) - 1;

  return (
    <SlideDataProvider slideId={structure.sldIdLst[currentSlide]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="100%"
        height="100%"
        className={styles.slide}
      >
        {notesSlide.spTree.shapes.length > 0 &&
          notesSlide.spTree.shapes.map((shape) => {
            return (
              <ShapeDataProvider key={shape.id} shape={shape}>
                <NoteShape shape={shape} />
              </ShapeDataProvider>
            );
          })}
      </svg>
    </SlideDataProvider>
  );
};

export default Note;
