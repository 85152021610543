import { FormattedMessage } from 'react-intl';

import styles from './TasksTab.module.scss';

const TasksEmptyState = () => (
  <div className={styles.emptyState}>
    <div className={styles.emptyTitle}>
      <FormattedMessage id="TASKS_EMPTY_TITLE" />
    </div>
    <div className={styles.emptyDesc}>
      <FormattedMessage id="TASKS_EMPTY_DESCRIPTION" />
    </div>
  </div>
);
export default TasksEmptyState;
