import { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { InputField, Input, Button } from 'dodoc-design-system';

import { navigateToSignIn } from 'router/history';
import { useDebounce, useDispatch } from '_common/hooks';
import { gotFeedback } from 'Auth/redux/authSlice';
import SecondaryAuthPageContainer from 'Auth/components/SecondaryAuthPageContainer/SecondaryAuthPageContainer';
import styles from './RecoverPage.module.scss';

import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import { useRecoverPasswordMutation } from '_common/services/api/authority';

/**
 * Renders a recover password form.
 *
 * @param {object} props - Recover's props.
 * @param {function} props.submitFunction - Function called on form submission.
 * @param {string} props.renderMessage - String with message to show in UI.
 *
 * @author    José Nunes <jnunes@dodoc.com>
 * @copyright 2017 doDOC
 */

export const TEST_IDS = {
  EMAIL: 'email',
  BUTTON: 'button',
};

const RecoverPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [recoverPassword, { isSuccess }] = useRecoverPasswordMutation();

  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [validations, setValidations] = useState({ email: '' });
  const [disabled, setDisabled] = useState(true);
  const [touched, setTouched] = useState(false);

  const debouncedEmail = useDebounce(email);

  useEffect(() => {
    dispatch(gotFeedback(null));
  }, []);

  useEffect(() => {
    validate(debouncedEmail);
  }, [debouncedEmail]);

  useEffect(() => {
    if (isSuccess) {
      setSubmitting(false);
    }
  }, [isSuccess]);

  const goBack = () => {
    dispatch(gotFeedback(null));
    navigateToSignIn();
  };

  const handleOnEmailChange: InputProps['onChange'] = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleRecoverPassword = () => {
    if (!disabled) {
      setSubmitting(true);
      recoverPassword({ email });
    }
  };

  const validate = (email: string, force?: boolean) => {
    if (email === '') {
      if (touched || force) {
        setValidations({ email: '' });
        setDisabled(true);
        setTouched(true);
      }
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        email,
      )
    ) {
      // Regex from most accepted answer
      // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      if (touched || force) {
        setValidations({ email: intl.formatMessage({ id: 'ERROR_EMAIL_INVALID' }) });
        setDisabled(true);
        setTouched(true);
      }
    } else {
      setValidations({ email: '' });
      setDisabled(false);
      setTouched(true);
    }
  };

  return (
    <SecondaryAuthPageContainer title="FORGOT_PASSWORD" description="FORGOT_PASSWORD_DESCRIPTION">
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'EMAIL_ADDRESS_LABEL' })}
        feedback={validations.email}
        testId="email-field"
      >
        <Input
          size="large"
          value={email}
          onChange={handleOnEmailChange}
          placeholder={intl.formatMessage({ id: 'EMAIL_ADDRESS_PLACEHOLDER' })}
          onEnterKey={handleRecoverPassword}
          error={!!validations.email}
          onBlur={(e) => {
            validate(e.target.value, true);
          }}
          testId={TEST_IDS.EMAIL}
        />
      </InputField>
      <div className={styles.submit}>
        <Button
          variant="primary"
          fullWidth
          onClick={handleRecoverPassword}
          loading={submitting}
          size="large"
          disabled={disabled}
          testId={TEST_IDS.BUTTON}
        >
          <FormattedMessage id="RESET_PASSWORD" />
        </Button>
      </div>

      <Button size="medium" variant="link" onClick={goBack} disabled={submitting} testId="back-to-sign-in-button">
        <FormattedMessage id="BACK_TO_SIGN_IN" />
      </Button>
    </SecondaryAuthPageContainer>
  );
};

export default RecoverPage;
