import { forwardRef, Ref } from 'react';

import ViewTaskCard from './ViewTaskCard';
import EditableTaskCard from './EditableTaskCard';

export type TaskCardProps = {
  sidebar?: boolean;
} & (
  | {
      mode: 'create' | 'edit';
      task?: undefined;
    }
  | {
      mode?: never;
      task: Editor.Task;
    }
);

const TaskCard = forwardRef(({ task, sidebar, mode }: TaskCardProps, ref: Ref<HTMLDivElement>) => {
  if (mode != null) {
    return <EditableTaskCard sidebar={sidebar} mode={mode} />;
  }

  if (task && task.u) {
    return <ViewTaskCard task={task} sidebar={sidebar} ref={ref} testId={`Task#${task.id}`} />;
  }
  return null;
});

export default TaskCard;
