import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InstanceService } from '_common/services';

type PublicSliceState = {
  groups: {
    requested: Record<UserId, string>;
    removed: Record<UserId, Boolean>;
    profiles: Record<UserId, Group>;
  };
};

const SLICE_NAME = 'Public';
const INITIAL_STATE: PublicSliceState = {
  groups: {
    removed: {},
    requested: {},
    profiles: {},
  },
};

export const getPublicGroups = createAsyncThunk(
  `${SLICE_NAME}/getPublicGroups`,
  async (groups: ObjectId[]) => {
    const { data } = await new InstanceService().getPublicGroups({
      groups,
    });

    let removed = {};

    removed = groups.filter(
      (groupId) => !(Object.values(data) as Group[]).find((group) => group.id === groupId),
    );

    return { data, removed } as {
      data: PublicSliceState['groups']['profiles'];
      removed: ObjectId[];
    };
  },
);

export const getPublicGroup = createAsyncThunk(
  `${SLICE_NAME}/getPublicGroup`,
  async (groupId: ObjectId, { getState, requestId }) => {
    const { requested, removed, profiles } = (getState() as RootState).public.groups;
    if (profiles[groupId] || removed[groupId] || requested[groupId] !== requestId) {
      return {};
    }
    const { data } = await new InstanceService().getPublicGroups({
      groups: [groupId],
    });
    return data;
  },
);

const profiles = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    resetPublicGroups: (state) => {
      state.groups = {
        removed: {},
        requested: {},
        profiles: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicGroups.fulfilled, (state, action) => {
      const { data, removed } = action.payload;

      state.groups.profiles = { ...state.groups.profiles, ...data };

      if (removed) {
        const removedProfiles: PublicSliceState['groups']['removed'] = {};
        removed.forEach((userId) => {
          removedProfiles[userId] = true;
        });
        state.groups.removed = { ...state.groups.removed, ...removedProfiles };
      }
    });
    builder.addCase(getPublicGroup.pending, (state, action) => {
      if (!state.groups.requested[action.meta.arg]) {
        state.groups.requested[action.meta.arg] = action.meta.requestId;
      }
    });
    builder.addCase(getPublicGroup.fulfilled, (state, action) => {
      state.groups.profiles = { ...state.groups.profiles, ...action.payload };

      if (!action.payload[action.meta.arg]) {
        state.groups.removed[action.meta.arg] = true;
      }
    });
  },
});

export const { resetPublicGroups } = profiles.actions;

export default profiles.reducer;
