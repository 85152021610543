import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from '_common/hooks';

import styles from './Footer.module.scss';

type SelectionCounterProps = {
  dataTestId?: {
    quantityDisplay: string;
  };
  identity: Table.Identity;
};

const SelectionCounter = ({ identity, dataTestId }: SelectionCounterProps) => {
  const intl = useIntl();
  const selectedState = useSelector((state) => state.table.selected);
  const data = useSelector((state) => state.app.data);
  const current = useSelector((state) => data[state.storage.current ?? '']);
  const length = Object.keys(selectedState).length;
  const totalResults = useSelector((state) => state.table.identity[identity].total);
  const tableLoading = useSelector((state) => state.table.contentLoading);

  if (tableLoading) {
    return null;
  }

  const getLocationName = () => {
    if (identity === 'shared') {
      return intl.formatMessage({ id: 'shared.sharedTitle' });
    } else if (identity === 'recycle') {
      return intl.formatMessage({
        id: 'storage.sidebar.recycleBin',
      });
    } else if (identity === 'groups') {
      return intl.formatMessage({ id: 'storage.sidebar.groups' });
    } else if (identity === 'spaces') {
      return intl.formatMessage({ id: 'storage.sidebar.spaces' });
    } else {
      if (current) {
        if (current.type === 'space' && current.personal) {
          return intl.formatMessage({
            id: 'spaces.personalSpace',
          });
        }
        return current?.name;
      }
    }
  };

  if (totalResults && length === 0) {
    return (
      <div className={styles.selectedItems} data-testid={dataTestId?.quantityDisplay}>
        <FormattedMessage
          id="FOOTER_TOTAL_ELEMENTS_IN"
          values={{ totalResults, location: getLocationName() }}
        />
      </div>
    );
  }
  if (length > 0) {
    return (
      <div className={styles.selectedItems} data-testid={dataTestId?.quantityDisplay}>
        <FormattedMessage id="storage.footer.selectedItems" values={{ selected: length }} />
      </div>
    );
  }
  return (
    <div className={styles.selectedItems} data-testid={dataTestId?.quantityDisplay}>
      <FormattedMessage id="FOOTER_NO_ELEMENTS_IN" values={{ location: getLocationName() }} />
    </div>
  );
};

export default SelectionCounter;
