const getNameFromUser = (user: {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
}) => {
  let name = user.id;
  let initials = '';
  if (user.first_name && user.last_name) {
    name = `${user.first_name} ${user.last_name}`;
    initials = `${user.first_name[0]}${user.last_name[0]}`;
  } else if (user.first_name) {
    name = `${user.first_name}`;
    initials = `${user.first_name[0]}${user.first_name[1]}`;
  } else if (user.username) {
    name = user.username;
    initials = `${user.username[0]}${user.username[1]}`;
  }

  return { name, initials: initials.toUpperCase() };
};

export const parseProfile = <T extends UserPublicProfile | UserProfile['profile']>(user: T) => {
  const { name, initials } = getNameFromUser(user);
  return { ...user, name, initials };
};

export const parseAccount = (account: Account): ParsedProfile<Account> => {
  const { name, initials } = getNameFromUser(account);
  let online = false;
  if (account.token && account.expires) {
    online = new Date(account.expires) > new Date();
  }
  return { ...account, name, initials, online };
};
