import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

import StyleContent, { StyleProps } from './StyleContent';

const Style = (props: StyleProps) => {
  return (
    <IntlErrorBoundary fallbackType="card" mockProps={{ sidebar: true, width: '100%' }}>
      <StyleContent {...props} />
    </IntlErrorBoundary>
  );
};

export default Style;
