import { SelectionTracker } from '.';
import { EditorSelectionUtils, JsonRange } from 'Editor/services/_Common/Selection';
import { BaseViewModel } from '../BaseViewModel';
import { Logger } from '_common/services';

export class SelectionViewModel extends BaseViewModel {
  typeName = 'SelectionViewModel';

  model?: Editor.Data.Selection.Model;

  selectionTracker: SelectionTracker;

  DEBUG: boolean = false;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State, id: string) {
    super(Data, Visualizer, id);

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleModelUpdate = this.handleModelUpdate.bind(this);

    this.selectionTracker = new SelectionTracker();
    this.selectionTracker.on('SELECTION_CHANGED', this.handleSelectionChange);

    this.model = this.Data.selection?.getSelectionModel(id);
    this.model?.on('UPDATED', this.handleModelUpdate);
  }

  bindView(rootContainer: Editor.Visualizer.BaseView) {
    this.view = rootContainer;

    this.selectionTracker.bindView(this.view);
    this.debounceStartSelectionTracker();
  }

  protected showDebugMessage(message: string, ...args: any) {
    if (this.DEBUG) {
      Logger.info(message, ...args);
    }
  }

  startSelectionTracker() {
    this.selectionTracker?.start();
  }

  debounceStartSelectionTracker() {
    this.selectionTracker?.debounceStart();
  }

  stopSelectionTracker() {
    this.selectionTracker?.stop();
  }

  private handleModelUpdate(
    source: Realtime.Core.RealtimeSourceType,
    id: string,
    data: Editor.Data.Selection.Data | null,
  ) {
    if (source === 'LOCAL_RENDER' || source === 'LOCAL_RENDER_OLD' || source === 'RESTORE') {
      this.showDebugMessage('SelectionViewModel handleModelUpdate: source ' + source, data);
      this.render(data);
    }
  }

  triggerSelectionChanged(updateModifiers: boolean = true) {
    this.selectionTracker?.selectionChanged(updateModifiers);
  }

  debounceSelectionChanged(updateModifiers: boolean = true) {
    this.selectionTracker?.debounceSelectionChanged(updateModifiers);
  }

  handleSelectionChange(
    range: Editor.Selection.JsonRange,
    modifiersData: Editor.Data.Selection.Modifiers,
  ) {
    this.Data.selection?.updateModifiers(modifiersData);
    this.Data.selection?.update([range.serializeToRangeData()]);
  }

  render(data: Editor.Data.Selection.Data | null) {
    if (!data && this.model) {
      data = this.model.getLocalSelectionData();
    }

    if (data?.ranges[0]) {
      this.stopSelectionTracker();

      try {
        const range = JsonRange.buildFromRangeData(data.ranges[0]);
        this.view?.focus({
          preventScroll: true,
        });
        EditorSelectionUtils.applyRangeToSelection(
          range.serializeToDOMRange({ selectionPath: true }),
        );

        // scroll into selection
        const modifiersData = this.Data.selection?.modifiersData;
        if (modifiersData) {
          EditorSelectionUtils.scrollIntoSelection(modifiersData);
        }
      } catch (error) {
        Logger.warn(error);
      } finally {
        this.debounceStartSelectionTracker();
      }
    }
  }

  dispose() {
    // TODO: dispose

    this.selectionTracker?.off('SELECTION_CHANGED', this.handleSelectionChange);

    this.selectionTracker?.destroy();

    this.Visualizer.viewModelFactory?.remove(this.id);
  }
}
