import { ErrorType } from './ErrorTypes';

export type DataManagerErrorPayload = {
  type?: ErrorType;
};

export class DataManagerError extends Error {
  data: DataManagerErrorPayload;

  constructor(msg: string) {
    super(msg);
    this.data = {
      type: 'GENERIC',
    };
  }

  get type() {
    return this.data.type;
  }
}
