import { theme } from 'dodoc-design-system';
import { useState } from 'react';

const pallete = {
  neutral: {
    background: theme.colors['neutral-5'],
    border: theme.colors['neutral-50'],
    plus: theme.colors['neutral-50'],
  },
  hover: {
    background: theme.colors['blue-20'],
    border: theme.colors['blue-90'],
    plus: theme.colors['blue-90'],
  },
};

const AddIcon = () => {
  const [state, setState] = useState<'neutral' | 'hover'>('neutral');
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setState('hover')}
      onMouseLeave={() => setState('neutral')}
    >
      <rect x="0.5" y="0.5" width="12" height="12" rx="6" fill={pallete[state].background} />
      <line
        x1="6.5"
        y1="4.5"
        x2="6.5"
        y2="8.5"
        stroke={pallete[state].plus}
        strokeLinecap="round"
      />
      <line
        x1="4.5"
        y1="6.5"
        x2="8.5"
        y2="6.5"
        stroke={pallete[state].plus}
        strokeLinecap="round"
      />
      <rect x="0.5" y="0.5" width="12" height="12" rx="6" stroke={pallete[state].border} />
    </svg>
  );
};

export default AddIcon;
