import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import EditorManager from 'Editor/services/EditorManager';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { CurrentStylePreview } from 'Editor/components';

import styles from './CurrentStyle.module.scss';

export type CurrentStyleProps = {
  testId: string;
};

const CurrentStyleContent = ({ testId }: CurrentStyleProps) => {
  const intl = useIntl();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const editorStatus = useSelector((state) => state.editor.status);

  const updateStyleFromSelection = () => {
    if (editorStatus.selectedStyle.id) {
      EditorManager.getInstance().updateDocumentStyleFromSelection(editorStatus.selectedStyle.id);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>{intl.formatMessage({ id: 'CURRENT_STYLE' })}</div>
      <Card sidebar testId={`${testId}-card`}>
        <Card.Body>
          <div className={styles.currentStyle}>
            {editorStatus.selectedStyle ? (
              <>
                <CurrentStylePreview />
                {editorStatus.selectedStyle.changed && (
                  <Button
                    variant="primary"
                    size="small"
                    onClick={updateStyleFromSelection}
                    disabled={isReadOnlyMode || isPageLayout}
                    testId="update-button"
                  >
                    <FormattedMessage id="UPDATE" />
                  </Button>
                )}
              </>
            ) : (
              <div className={styles.multipleStyles}>
                <FormattedMessage id="VALID_DOCUMENT_STYLES" />
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CurrentStyleContent;
