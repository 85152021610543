import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { dayjs } from 'utils';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

export type CommentsSliceState = {
  comments: { [id: string]: Editor.Comment };
  order: Editor.Comment['id'][];
  insert: {
    inserting: boolean;
    level0?: string;
    reference?: string;
    shouldFocus: boolean;
  };
  loaded: boolean;
  hideAll: boolean;
};

const SLICE_NAME = 'COMMENTS';
const initialState: CommentsSliceState = {
  comments: {},
  order: [],
  insert: {
    inserting: false,
    level0: undefined,
    reference: undefined,
    shouldFocus: false,
  },
  loaded: false,
  hideAll: false,
};

// #region Selectors
const getCommentsList = (state: RootState) => state.editor.comments.comments;
const getCommentsOrder = (state: RootState) => state.editor.comments.order;
const getCommentsInsert = (state: RootState) => state.editor.comments.insert;
const getCommentsFilters = (state: RootState) => state.filters.editorCommentPanel;

const subCommentsDontMatchAuthor = (userId: UserId, comments: Editor.Comment[]) => {
  for (const comment of comments) {
    if (userId === comment.author || userId === comment.user) {
      return false;
    }
  }
  return true;
};

export const selectFilteredComments = createSelector(
  [getCommentsOrder, getCommentsList, getCommentsFilters, getCommentsInsert],
  (order, comments, filters, insertComments) => {
    const filterValues = {
      cardPriority: filters.cardPriority?.map((filterValue) => filterValue.value),
      authors: filters.author?.map((filterValue) => filterValue.value),
      creationDate: filters.creationDate,
    };

    const filterComment = (id: Editor.Comment['id']) => {
      if (!comments[id]) {
        return false;
      }

      // By priority
      if (filterValues.cardPriority && !filterValues.cardPriority.includes(comments[id].priority)) {
        return false;
      }

      // By authors
      if (filterValues.authors) {
        if (
          filterValues.authors.every(
            (author) =>
              author !== comments[id].author &&
              author !== comments[id].user &&
              subCommentsDontMatchAuthor(author, comments[id].comments),
          )
        ) {
          return false;
        }
      }

      // By date
      if (filterValues.creationDate?.startISO) {
        if (!dayjs(filterValues.creationDate.startISO).isSameOrBefore(comments[id].time, 'day')) {
          return false;
        }
      }

      if (filterValues.creationDate?.endISO) {
        if (!dayjs(filterValues.creationDate.endISO).isSameOrAfter(comments[id].time, 'day')) {
          return false;
        }
      }

      return true;
    };

    const filteredOrder = order.filter(filterComment);

    return {
      comments,
      order: filteredOrder,
      total: order.length,
      insert: insertComments,
    };
  },
);

export const selectCommentsAuthors = createSelector(
  [getCommentsList, getCommentsOrder],
  (dict, list) => {
    const users: Editor.Comment.Author[] = [];
    const addUser = (comment: Editor.Comment) => {
      for (let i = 0; i < users.length; i++) {
        const u = users[i];
        if (u.id === comment.author || u.id === comment.user) {
          return;
        }
      }
      const user: typeof users[number] = { id: '' };
      if (comment.user) {
        // Imported user
        user.id = comment.user;
        user.imported = true;
      } else if (comment.author) {
        // dodoc user
        user.id = comment.author;
      } else {
        return;
      }

      users.push(user);
    };
    for (let i = 0; i < list.length; i++) {
      let comment = dict[list[i]];
      addUser(comment);
      for (let j = 0; j < comment.comments?.length; j++) {
        const subcomment = comment.comments[j];
        addUser(subcomment);
      }
    }
    return users;
  },
);
//#endregion

// #region Slice
const CommentsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    loadedComments: (
      state,
      action: PayloadAction<Pick<CommentsSliceState, 'order' | 'comments'>>,
    ) => {
      const { comments, order } = action.payload;

      const newComments = Object.values(comments).reduce((tempComments, comment) => {
        tempComments[comment.id] = {
          ...comment,
          content:
            typeof comment.content === 'object' ? JSON.stringify(comment.content) : comment.content,
          comments: comment.comments.map((reply) => ({
            ...reply,
            content:
              typeof reply.content === 'object' ? JSON.stringify(reply.content) : reply.content,
          })),
        };

        return tempComments;
      }, state.comments);

      state.order = order;
      state.comments = newComments;
      state.loaded = true;
    },
    updateComment: (state, action: PayloadAction<Editor.Comment>) => {
      const comment = action.payload;

      const newOrder = state.order;
      if (!state.order.includes(comment.id)) {
        newOrder.push(comment.id);
      }

      state.order = newOrder;
      state.comments[comment.id] = {
        ...comment,
        content:
          typeof comment.content === 'object' ? JSON.stringify(comment.content) : comment.content,
        comments: comment.comments.map((reply) => ({
          ...reply,
          content:
            typeof reply.content === 'object' ? JSON.stringify(reply.content) : reply.content,
        })),
      };
    },
    addTemporaryComment: (
      state,
      action: PayloadAction<
        Required<Omit<CommentsSliceState['insert'], 'inserting' | 'shouldFocus'>>
      >,
    ) => {
      const { level0, reference } = action.payload;

      if (state.insert.reference !== reference) {
        state.insert.shouldFocus = true;
      }

      state.insert.level0 = level0;
      state.insert.reference = reference;
      state.insert.inserting = true;
    },
    cancelTemporaryComment: (state) => {
      state.insert.inserting = false;
      state.insert.reference = undefined;
      state.insert.level0 = undefined;
      state.insert.shouldFocus = false;
    },
    focusedTemporaryComment: (state) => {
      state.insert.shouldFocus = false;
    },
    setHideComments(state, action: PayloadAction<boolean>) {
      state.hideAll = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export default CommentsSlice.reducer;
// #endregion

export const {
  loadedComments,
  updateComment,
  addTemporaryComment,
  cancelTemporaryComment,
  focusedTemporaryComment,
  setHideComments,
} = CommentsSlice.actions;
