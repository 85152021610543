import { useRef, useEffect, CSSProperties } from 'react';
import * as echarts from 'echarts';

type EChartsWrapperProps = {
  options: echarts.EChartsOption
  style: CSSProperties;
};

function EChartsWrapper({ options, style }: EChartsWrapperProps) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current, '', { renderer: 'svg' });

    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [options]);

  return <div ref={chartRef} style={style}></div>;
}

export default EChartsWrapper;
