import { Logger } from '_common/services';
import { BaseManipulator } from '../Common/Base';
import { JsonRange, PathUtils, SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeDataBuilder, NodeUtils } from 'Editor/services/DataManager';
import { InsertElementOperation, RemoveContentOperation } from '../../Operations';
import { ELEMENTS } from 'Editor/services/consts';
import { InsertBlockOperation, RemoveBlockOperation } from '../../Operations/StructureOperations';
import ReduxInterface from 'Editor/services/ReduxInterface';

type WrapDeleteContentResponse = {
  nodeId?: string;
  path: Editor.Selection.Path;
};

export class RemoveManipulator
  extends BaseManipulator
  implements Editor.Edition.IRemoveManipulator
{
  private filterTrackedElements(
    childNodes: Editor.Data.Node.Data[],
    isParentTrackInsert: boolean = false,
  ) {
    const filteredNodes: Editor.Data.Node.Data[] = [];

    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i];

      if (NodeUtils.isElementData(child)) {
        if (NodeUtils.isTrackedData(child)) {
          if (this.isUserAuthor(child)) {
            if (child.childNodes?.length) {
              const childNodes = this.filterTrackedElements(
                child.childNodes,
                !!NodeUtils.isTrackInsertData(child),
              );
              filteredNodes.push(...childNodes);
            }
          } else {
            filteredNodes.push(child);
          }
        } else {
          if (child.childNodes?.length) {
            const childNodes = this.filterTrackedElements(child.childNodes, isParentTrackInsert);
            if (childNodes.length) {
              if (!isParentTrackInsert) {
                child.childNodes = childNodes;
                filteredNodes.push(child);
              } else {
                filteredNodes.push(...childNodes);
              }
            }
          } else if (!isParentTrackInsert) {
            filteredNodes.push(child);
          }
        }
      } else if (NodeUtils.isTextData(child) && !isParentTrackInsert) {
        filteredNodes.push(child);
      }
    }

    // merge contiguous text nodes
    let length = filteredNodes.length;
    for (let i = length - 1; i > 0; i--) {
      const current = filteredNodes[i];
      const previous = filteredNodes[i - 1];
      if (NodeUtils.isTextData(previous) && NodeUtils.isTextData(current)) {
        previous.content += current.content;
        filteredNodes.splice(i, 1);
      }
    }

    return filteredNodes;
  }

  private handleDeleteSplitMarker(
    previousModel: Editor.Data.Node.Model,
    previousPath: Editor.Selection.Path,
    currentModel: Editor.Data.Node.Model,
    currentPath: Editor.Selection.Path,
    refId: string | undefined,
  ) {
    if (!this.editionContext.DataManager) {
      return false;
    }

    const previousData = previousModel.selectedData();
    if (!previousData) {
      return false;
    }

    const currentData = currentModel.selectedData();
    if (!currentData) {
      return false;
    }

    const previousBlock = NodeUtils.closestOfTypeByPath(previousData, previousPath, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    const currentBlock = NodeUtils.closestOfTypeByPath(currentData, currentPath, [
      ...NodeUtils.BLOCK_TEXT_TYPES,
    ]);

    if (
      previousBlock &&
      currentBlock &&
      NodeUtils.isBlockTextData(previousBlock.data) &&
      NodeUtils.isBlockTextData(currentBlock.data)
    ) {
      let previousLastChild: Editor.Data.Node.Data | undefined;

      let length = previousBlock.data.childNodes?.length || 0;
      previousLastChild = previousBlock.data.childNodes?.[length - 1];

      if (!NodeUtils.isParagraphMarker(previousLastChild)) {
        const loggedUserId = this.editionContext.DataManager.users.loggedUserId;

        if (refId && loggedUserId) {
          const markerBuilder = new NodeDataBuilder(ELEMENTS.TrackDeleteElement.ELEMENT_TYPE)
            .addProperty('element_reference', refId)
            .addProperty('author', loggedUserId);

          if (previousModel.id === currentModel.id && previousModel.id !== previousBlock.data.id) {
            // container element
            markerBuilder.addProperty('replacewithsibling', currentBlock.data.id);
          } else {
            markerBuilder.addProperty('replacewith', currentBlock.data.id);
          }

          const markerData = markerBuilder.build();

          if (markerData) {
            let pathToInsert: Editor.Selection.Path = [
              ...previousBlock.path,
              'childNodes',
              previousBlock.data.childNodes?.length || 0,
            ];
            let op = new InsertElementOperation(previousModel, pathToInsert, markerData);
            op.apply();
          }
        }
      } else if (
        NodeUtils.isParagraphMarker(previousLastChild) &&
        NodeUtils.isTrackInsertData(previousLastChild) &&
        !this.isUserAuthor(previousLastChild)
      ) {
        // tracked insert markers from other users
        // TODO discuss with product

        const loggedUserId = this.editionContext.DataManager.users.loggedUserId;

        if (refId && loggedUserId) {
          const markerBuilder = new NodeDataBuilder(ELEMENTS.TrackDeleteElement.ELEMENT_TYPE)
            .addProperty('element_reference', refId)
            .addProperty('author', loggedUserId);

          if (previousModel.id === currentModel.id && previousModel.id !== previousBlock.data.id) {
            // container element
            markerBuilder.addProperty('replacewithsibling', currentBlock.data.id);
          } else {
            markerBuilder.addProperty('replacewith', currentBlock.data.id);
          }

          const previousBlockLength = previousBlock.data.childNodes?.length || 0;

          const lastChildStart: Editor.Selection.Path = [
            ...previousBlock.path,
            'childNodes',
            previousBlockLength - 1,
          ];
          const lastChildEnd: Editor.Selection.Path = [
            ...previousBlock.path,
            'childNodes',
            previousBlockLength,
          ];

          const clonedPreviousLastChild = NodeUtils.cloneData(
            previousData,
            lastChildStart,
            lastChildEnd,
          );

          let workingPath: Editor.Selection.Path = [
            ...previousBlock.path,
            'childNodes',
            previousBlock.data.childNodes?.length || 0,
          ];

          if (clonedPreviousLastChild.length) {
            const removeOp = new RemoveContentOperation(
              previousModel,
              lastChildStart,
              lastChildEnd,
            );
            removeOp.apply();

            let resultPath = removeOp.getPostOpPath();
            if (resultPath) {
              workingPath = resultPath;
            }

            markerBuilder.addChildData(...clonedPreviousLastChild);
          }

          const markerData = markerBuilder.build();

          if (markerData) {
            let op = new InsertElementOperation(previousModel, workingPath, markerData);
            op.apply();
          }
        }
      }
    }
  }

  private shouldDeleteContents(
    contents: Editor.Data.Node.Data[],
    commonAncestor?: Editor.Data.Node.Data,
  ) {
    // cehck if common ancestor is track delete
    //TODO: all users or only for this user ?????
    if (NodeUtils.isTrackDeleteData(commonAncestor)) {
      return false;
    }

    // check if all nodes are track delete nodes and if they were made by this author
    for (let i = 0; i < contents.length; i++) {
      let data = contents[i];
      if (!(NodeUtils.isTrackDeleteData(data) && this.isUserAuthor(data))) {
        return true;
      }
    }

    return false;
  }

  private wrapDeleteContent(
    baseModel: Editor.Data.Node.Model,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
    refId?: string,
    opts: Editor.Edition.RemoveContentOptions = {},
  ): WrapDeleteContentResponse {
    const structureModel = this.editionContext.DataManager?.structure.structureModel;

    let baseData = baseModel.selectedData();
    if (!baseData || !structureModel || !this.editionContext.DataManager) {
      return {
        path: startPath,
      };
    }

    let ops: Editor.Edition.IOperationBuilder[] = [];

    const commonAncestorPath = PathUtils.getCommonAncestorPath(startPath, endPath);

    let closestContainer = NodeUtils.closestOfTypeByPath(
      baseData,
      commonAncestorPath,
      NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
    );

    let dataToSearch: Editor.Data.Node.Data = baseData;
    let pathToSearch: Editor.Selection.Path = [];

    if (closestContainer) {
      dataToSearch = closestContainer.data;
      pathToSearch = closestContainer.path;
    }

    let closestBlockElement: Editor.Data.Node.DataPathInfo | null = NodeUtils.closestOfTypeByPath(
      dataToSearch,
      commonAncestorPath.slice(pathToSearch.length),
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
    );

    if (!closestBlockElement) {
      closestBlockElement = NodeUtils.closestOfTypeByPath(
        dataToSearch,
        commonAncestorPath.slice(pathToSearch.length),
        ['tracked-insert', 'tracked-delete'],
      );
    } else {
      // check block elements and if there are tracked inserts level 0
      const blockParentChildInfo = NodeUtils.getParentChildInfoByPath(
        dataToSearch,
        closestBlockElement.path,
      );
      if (blockParentChildInfo && NodeUtils.isTrackedData(blockParentChildInfo.parentData)) {
        closestBlockElement = {
          data: blockParentChildInfo.parentData,
          path: blockParentChildInfo.parentPath,
        };
      }
    }

    if (!closestBlockElement) {
      return {
        path: startPath,
      };
    } else {
      closestBlockElement.path = [...pathToSearch, ...closestBlockElement.path];
    }

    // if has a closest tracked
    // TODO: move track insert remove code to removeTrackedParagraphMarkers?????????

    let resultPath: Editor.Selection.Path = startPath;
    if (opts.selectionDirection === 'forward') {
      resultPath = endPath;
    }

    // adjust paths for delete
    // let { adjustedStartPath, adjustedEndPath } = this.adjustPathsForDelete(
    //   baseData,
    //   closestBlockElement,
    //   startPath,
    //   endPath,
    // );

    if (NodeUtils.BLOCK_TEXT_TYPES.includes(closestBlockElement.data.type)) {
      // TEXT BLOCKS
      if (PathUtils.isPathEqual(startPath, endPath)) {
        return {
          path: startPath,
        };
      }

      const closestCommonTracked = NodeUtils.closestOfTypeByPath(baseData, commonAncestorPath, [
        'tracked-insert',
        'tracked-delete',
      ]);

      const clonedNodes = NodeUtils.cloneData(baseData, startPath, endPath);

      // check if should delete content
      if (!this.shouldDeleteContents(clonedNodes, closestCommonTracked?.data)) {
        return {
          path: startPath,
        };
      }

      // remove content
      if (clonedNodes.length) {
        let op = new RemoveContentOperation(baseModel, startPath, endPath, {
          ...opts,
          pathFix: 'AFTER', // should be after to avoid scenarios where deleted suggestion are wrongfully inserted inside format elements;
        });

        op.apply();
        ops.push(op);
        let p = op.getPostOpPath();
        if (p) {
          resultPath = p;
        }

        // refresh basedata
        baseData = baseModel.selectedData();
        if (!baseData) {
          return {
            path: resultPath,
          };
        }
      }

      // WARN: filtered nodes should always be after remove for cases where is deleting own suggestion
      // remove track insert from cloned data
      const filteredNodes = this.filterTrackedElements(
        clonedNodes,
        !!(
          closestCommonTracked &&
          NodeUtils.isTrackInsertData(closestCommonTracked.data) &&
          this.isUserAuthor(closestCommonTracked.data)
        ),
      );

      // check if there are nodes to insert
      if (filteredNodes.length === 0) {
        return {
          path: resultPath,
        };
      }

      let nodesToInsert: Editor.Data.Node.Data[] = [];

      // check closest start
      const closestTracked = NodeUtils.closestOfTypeByPath(baseData, resultPath, [
        ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
      ]);

      // check siblings to "merge" content
      const previousAncestor = NodeUtils.getPreviousSibling(baseData, resultPath);
      const nextAncestor = NodeUtils.getNextSibling(baseData, resultPath);

      let currentAncestor: Editor.Data.Node.DataPathInfo | null = null;
      if (previousAncestor) {
        currentAncestor = NodeUtils.getNextSibling(baseData, previousAncestor.path);
      } else if (nextAncestor) {
        currentAncestor = NodeUtils.getPreviousSibling(baseData, nextAncestor.path);
      }
      let currentSubPath: Editor.Selection.Path = [];
      if (currentAncestor) {
        currentSubPath = resultPath.slice(currentAncestor.path.length);
      }

      // insert content wrapped with track delete
      // prepare content to insert and adjust insert path
      if (
        closestTracked &&
        NodeUtils.isTrackDeleteData(closestTracked.data) &&
        !NodeUtils.isParagraphMarker(closestTracked.data) &&
        !NodeUtils.isBlockTrackedData(closestTracked.data) &&
        this.isUserAuthor(closestTracked.data)
      ) {
        if (PathUtils.isPathEqual(closestTracked.path, resultPath)) {
          resultPath.push('childNodes', 0);
        }
        nodesToInsert = filteredNodes;
      } else if (
        currentAncestor &&
        NodeUtils.isPathAtContentStart(currentAncestor.data, currentSubPath) &&
        previousAncestor &&
        NodeUtils.isTrackDeleteData(previousAncestor.data) &&
        !NodeUtils.isParagraphMarker(previousAncestor.data) &&
        !NodeUtils.isBlockTrackedData(previousAncestor.data) &&
        this.isUserAuthor(previousAncestor.data)
      ) {
        // insert content in previous ancestor
        resultPath = [
          ...previousAncestor.path,
          'childNodes',
          previousAncestor.data.childNodes?.length || 0,
        ];
        nodesToInsert = filteredNodes;
      } else if (
        currentAncestor &&
        NodeUtils.isPathAtContentEnd(currentAncestor.data, currentSubPath) &&
        nextAncestor &&
        NodeUtils.isTrackDeleteData(nextAncestor.data) &&
        !NodeUtils.isParagraphMarker(nextAncestor.data) &&
        !NodeUtils.isBlockTrackedData(nextAncestor.data) &&
        this.isUserAuthor(nextAncestor.data)
      ) {
        // insert content in next ancestor
        resultPath = [...nextAncestor.path, 'childNodes', 0];
        nodesToInsert = filteredNodes;
      } else {
        // insert new tracked delete element
        const trackedDelete = this.buildNewTrackedDelete(filteredNodes, refId);
        if (trackedDelete) {
          nodesToInsert.push(trackedDelete);
        }

        // check initial selection for certain scenarios (Ex: start inside format elements and end outside)
        // if (
        //   !PathUtils.isPathEqual(startPath, endPath) &&
        //   !PathUtils.isPathEqual(startPath, commonAncestorPath)
        // ) {
        //   // normalize the path after the content removal to be outside formats
        //   let normalizedPath = SelectionFixer.normalizeJsonPath(baseData, resultPath, {
        //     suggestionMode: this.editionContext.editionMode === 'SUGGESTIONS',
        //     forceTextAsWrap: true,
        //   });
        //   if (normalizedPath) {
        //     resultPath = normalizedPath;
        //   }
        // }
      }

      // insert nodes
      let insertOptions: Editor.Edition.InsertContentOptions = {};
      for (let i = 0; i < nodesToInsert.length; i++) {
        if (i === nodesToInsert.length - 1) {
          insertOptions.pathFix = 'TEXT_END';
        } else {
          insertOptions.pathFix = 'AFTER';
        }

        let op = new InsertElementOperation(baseModel, resultPath, nodesToInsert[i], insertOptions);
        op.apply();
        ops.push(op);
        let p: Editor.Selection.Path | undefined;
        if (opts.selectionDirection === 'backward') {
          p = op.getPreOpPath();
        } else {
          p = op.getAdjustedPath();
        }
        if (p) {
          resultPath = p;
        }
      }
    } else if (
      NodeUtils.isTrackInsertData(closestBlockElement.data) &&
      this.isUserAuthor(closestBlockElement.data)
    ) {
      // TRACK INSERT SAME AUTHOR
      const removeOp = this.getRemoveBlockOperation(
        baseModel,
        closestBlockElement.data,
        closestBlockElement.path,
      );
      if (removeOp) {
        removeOp.apply();
      }
    } else if (
      NodeUtils.isTrackDeleteData(closestBlockElement.data) &&
      this.isUserAuthor(closestBlockElement.data)
    ) {
      return {
        path: startPath,
      };
    } else if (
      NodeUtils.isBlockNonTextData(closestBlockElement.data) ||
      (NodeUtils.isTrackInsertData(closestBlockElement.data) &&
        !this.isUserAuthor(closestBlockElement.data))
    ) {
      // NON TEXT BLOCKS

      if (closestBlockElement.data.id === baseData.id) {
        // is level0 block
        const trackedDelete = this.buildNewTrackedDelete(
          JSON.parse(JSON.stringify(closestBlockElement.data)),
          refId,
        );

        if (trackedDelete && trackedDelete.id) {
          const insertOp = new InsertBlockOperation(
            this.editionContext.DataManager,
            structureModel,
            trackedDelete,
            baseModel.id,
            'BEFORE',
          );
          insertOp.apply();

          const removeOp = new RemoveBlockOperation(
            this.editionContext,
            structureModel,
            baseModel.id,
          );
          removeOp.apply();

          // return with new nodeId inserted
          return {
            nodeId: trackedDelete.id,
            path: resultPath,
          };
        }
      } else {
        // non level 0 blocks
        const trackedDelete = this.buildNewTrackedDelete(
          JSON.parse(JSON.stringify(closestBlockElement.data)),
          refId,
        );

        if (trackedDelete) {
          let sPath = [...closestBlockElement.path];

          let ePath = [...closestBlockElement.path];
          let endOffset = Number(ePath[ePath.length - 1]);
          if (!isNaN(endOffset)) {
            ePath[ePath.length - 1] = endOffset + 1;
          }

          const removeOp = new RemoveContentOperation(baseModel, sPath, ePath, opts);
          removeOp.apply();

          const insertOp = new InsertElementOperation(
            baseModel,
            [...closestBlockElement.path],
            trackedDelete,
          );
          insertOp.apply();
        }
      }
    }

    return {
      path: resultPath,
    };
  }

  private fixSelection(
    range: Editor.Selection.JsonRange,
    baseData: Editor.Data.Node.Data,
    blockDataPath: Editor.Data.Node.DataPathInfo,
    opts: Editor.Edition.RemoveContentOptions = {},
  ) {
    const blockData = blockDataPath.data;
    const blockPath = blockDataPath.path;

    if (NodeUtils.isBlockTextData(blockData) && !range.isCollapsed()) {
      let blockStartPath = range.start.p.slice(blockPath.length);
      let blockEndPath = range.end.p.slice(blockPath.length);

      const closestStartChild = NodeUtils.closestOfTypeByPath(blockData, blockStartPath, [
        ...NodeUtils.INLINE_TYPES,
        ...NodeUtils.INLINE_LAST_CHILD_TYPES,
      ]);

      const closestEndChild = NodeUtils.closestOfTypeByPath(blockData, blockEndPath, [
        ...NodeUtils.INLINE_TYPES,
        ...NodeUtils.INLINE_LAST_CHILD_TYPES,
      ]);

      const parentStartChildInfo = NodeUtils.getParentChildInfoByPath(blockData, blockStartPath);
      const parentEndChildInfo = NodeUtils.getParentChildInfoByPath(blockData, blockEndPath);

      let childLength = blockData.childNodes?.length || 0;
      let lastChild = blockData.childNodes?.[childLength - 1];

      let subStartPath = blockStartPath.slice(closestStartChild?.path.length || 0);
      let subEndPath = blockEndPath.slice(closestEndChild?.path.length || 0);

      if (
        closestStartChild &&
        closestEndChild &&
        PathUtils.isPathEqual(closestStartChild.path, closestEndChild.path) &&
        !NodeUtils.isLastChildElementData(closestEndChild.data) &&
        NodeUtils.isPathAtContentStart(closestStartChild.data, subStartPath) &&
        NodeUtils.isPathAtContentEnd(closestEndChild.data, subEndPath)
      ) {
        // closest start === closest end
        SelectionFixer.normalizeTextSelection(
          range,
          {
            suggestionMode: true,
            // forceWrapAsText: true, // WARN: avoid this prop here
            isDelete: opts.selectionDirection === 'forward',
            isBackspace: opts.selectionDirection === 'backward',
            forceTextAsWrap: true,
          },
          this.editionContext.DataManager,
        );

        return range;
      } else {
        // closest start !== closest end

        // HANDLE START PATH
        if (
          closestStartChild &&
          NodeUtils.isPathAtContentEnd(closestStartChild.data, subStartPath)
        ) {
          // is at end of start closest element
          let fixedPath = SelectionFixer.normalizeJsonPath(baseData, range.start.p, {
            suggestionMode: true,
            isDelete: opts.selectionDirection === 'forward',
            isBackspace: opts.selectionDirection === 'backward',
            forceTextAsWrap: true,
          });
          if (fixedPath) {
            range.start.p = fixedPath;
          }
        } else if (
          !closestStartChild &&
          parentStartChildInfo &&
          NodeUtils.isTextData(parentStartChildInfo.childData) &&
          parentStartChildInfo.contentIndex === parentStartChildInfo.childData.content.length
        ) {
          // is at end of start text element
          const nextAncestor = NodeUtils.getNextAncestor(baseData, range.start.p);
          if (nextAncestor && !NodeUtils.isNonEditableInlineData(nextAncestor.data)) {
            let path = [...nextAncestor.path];
            let child: Editor.Data.Node.Data | undefined = nextAncestor.data;

            while (child && !NodeUtils.isNonEditableInlineData(child)) {
              if (NodeUtils.isTextData(child)) {
                path.push('content', 0);
                child = undefined;
                break;
              } else {
                path.push('childNodes', 0);

                if (child.childNodes?.length) {
                  child = child.childNodes[0];
                } else {
                  child = undefined;
                  break;
                }
              }
            }

            range.start.p = path;
          }
        } else {
          let fixedPath = SelectionFixer.normalizeJsonPath(baseData, range.start.p, {
            suggestionMode: true,
            isDelete: opts.selectionDirection === 'forward',
            isBackspace: opts.selectionDirection === 'backward',
            forceWrapAsText: true,
            forceNonEditableDirection: 'BACKWARD',
          });
          if (fixedPath) {
            range.start.p = fixedPath;
          }
        }

        // HANDLE END PATH
        if (closestEndChild && NodeUtils.isPathAtContentStart(closestEndChild.data, subEndPath)) {
          let fixedPath = SelectionFixer.normalizeJsonPath(baseData, range.end.p, {
            suggestionMode: true,
            isDelete: opts.selectionDirection === 'forward',
            isBackspace: opts.selectionDirection === 'backward',
            forceTextAsWrap: true,
          });
          if (fixedPath) {
            range.end.p = fixedPath;
          }
        } else if (
          closestEndChild &&
          (NodeUtils.isLastChildElementData(closestEndChild.data) ||
            NodeUtils.isParagraphMarker(closestEndChild?.data))
        ) {
          // check for tracked markers at the end and adjust path
          range.end.p = [...blockPath, ...closestEndChild.path];
        } else if (
          NodeUtils.isParagraphMarker(lastChild) &&
          parentEndChildInfo?.childData == null &&
          parentEndChildInfo?.childIndex === childLength
        ) {
          const offset = Number(blockEndPath[blockEndPath.length - 1]);
          if (!isNaN(offset)) {
            blockEndPath[blockEndPath.length - 1] = offset - 1;
            range.end.p = [...blockPath, ...blockEndPath];
          }
        } else {
          let fixedPath = SelectionFixer.normalizeJsonPath(baseData, range.end.p, {
            suggestionMode: true,
            isDelete: opts.selectionDirection === 'forward',
            isBackspace: opts.selectionDirection === 'backward',
            forceWrapAsText: true,
            forceNonEditableDirection: 'FORWARD',
          });
          if (fixedPath) {
            range.end.p = fixedPath;
          }
        }

        return range;
      }
    }

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      range,
      {
        suggestionMode: true,
        // forceWrapAsText: true, // WARN: avoid this prop here
        isDelete: opts.selectionDirection === 'forward',
        isBackspace: opts.selectionDirection === 'backward',
        // forceTextAsWrap: true,
      },
      this.editionContext.DataManager,
    );

    return range;
  }

  removeContent(
    ctx: Editor.Edition.ActionContext,
    opts: Editor.Edition.RemoveContentOptions = {},
  ): boolean {
    if (this.editionContext.debug) {
      Logger.trace('SuggestionManipulator removeContent', ctx);
    }

    if (!this.editionContext.DataManager) {
      return false;
    }
    let options: Editor.Edition.RemoveContentOptions = {
      selectionDirection: 'forward',
      useSelectedCells: false,
      ...opts,
    };

    // check if selection is editable
    if (!JsonRange.isSelectionEditable(this.editionContext.DataManager, ctx.range)) {
      Logger.error('Selection is not editable!');
      return false;
    }

    if (!opts.confirmDeleteCaption && this.validateCaptionsOnRange(ctx)) {
      ctx.avoidNextNonCollapsedAction = true;

      // trigger confirmation modal
      ReduxInterface.openDeleteCaptionConfirmationModal();

      return false;
    }

    this.removeTrackedParagraphMarkers(ctx, true, options.useSelectedCells);

    // TODO: check for figures and other elements

    let startModel = this.editionContext.DataManager.nodes.getNodeModelById(ctx.range.start.b);
    if (!startModel) {
      return false;
    }

    let refId: string = ctx.suggestionRef;

    let resultBlockId: string = ctx.range.start.b;
    let resultPath: Editor.Selection.Path | undefined = ctx.range.start.p;

    // get base level ranges
    let rangesToRemove = JsonRange.splitRangeByTypes(
      this.editionContext.DataManager,
      ctx.range,
      [...NodeUtils.BLOCK_TEXT_TYPES, ...NodeUtils.BLOCK_NON_TEXT_TYPES],
      {
        onlyContainerLevel: true,
        useSelectedCells: options.useSelectedCells,
      },
    );

    let previousTextModel: Editor.Data.Node.Model | undefined;
    let previousTextPath: Editor.Selection.Path | undefined;
    let previousContainer: Editor.Data.Node.Data | undefined;

    for (let i = 0; i < rangesToRemove.length; i++) {
      let range = rangesToRemove[i].range;

      let baseModel = this.editionContext.DataManager.nodes.getNodeModelById(range.start.b);

      let baseData = baseModel?.selectedData();
      if (!baseModel || !baseData) {
        continue;
      }

      range = this.fixSelection(
        range,
        baseData,
        {
          data: rangesToRemove[i].filteredData,
          path: rangesToRemove[i].filteredDataPath,
        },
        opts,
      );

      let tempResultPath: Editor.Selection.Path | undefined;

      const tempResult = this.wrapDeleteContent(
        baseModel,
        range.start.p,
        range.end.p,
        refId,
        options,
      );

      tempResultPath = tempResult.path;

      // update baseModel and baseData because the Wrap on BLOCKS
      if (tempResult.nodeId && tempResult.nodeId !== baseData.id) {
        baseModel = this.editionContext.DataManager.nodes.getNodeModelById(tempResult.nodeId);
        baseData = baseModel?.selectedData();

        if (!baseModel || !baseData) {
          continue;
        }
        resultBlockId = baseModel.id;
      }

      if (tempResultPath) {
        // only if both elements are text elements
        const closestContainer = NodeUtils.closestOfTypeByPath(
          baseData,
          tempResultPath,
          NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
        );

        if (
          i !== 0 &&
          previousTextModel &&
          previousTextPath &&
          previousContainer?.id === closestContainer?.data.id
        ) {
          // NOT FIRST ELEMENT
          this.handleDeleteSplitMarker(
            previousTextModel,
            previousTextPath,
            baseModel,
            tempResultPath,
            refId,
          );
        }

        if (closestContainer) {
          previousContainer = closestContainer.data;
        }

        if (options.selectionDirection === 'forward') {
          resultBlockId = baseModel.id;
          resultPath = tempResultPath;
        }
      }

      if (tempResultPath) {
        // TODO improve this
        // split markers will not be registered here, because they will only be inserted in the next interation
        ctx.addSuggestionLocation(baseModel.id, tempResultPath);
      }

      const closestText = NodeUtils.closestOfTypeByPath(
        baseData,
        tempResultPath,
        NodeUtils.BLOCK_TEXT_TYPES,
      );

      if (closestText) {
        previousTextModel = baseModel;
        previousTextPath = tempResultPath;
      }
    }

    if (resultPath) {
      const resultModel = this.editionContext.DataManager.nodes.getNodeModelById(resultBlockId);
      const resultData = resultModel?.selectedData();
      if (resultData) {
        let path = SelectionFixer.normalizeJsonPath(resultData, resultPath, {
          suggestionMode: true,
        });
        if (path) {
          resultPath = path;
        }
      }

      ctx.range.updateRangePositions({
        b: resultBlockId,
        p: resultPath,
      });
    } else {
      ctx.range.collapse(true);
    }

    return true;
  }

  removeBlock(ctx: Editor.Edition.ActionContext, opts: Editor.Edition.RemoveContentOptions = {}) {
    // TODO: to be defined and implemented
    return true;
  }
}
