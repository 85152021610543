import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { GroupsService, Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import { addData } from 'App/redux/appSlice';
import { addToList, removeFromList } from '_common/components/Table/TableSlice';

type SliceState = {
  infoPanelOpen: boolean;
};

const SLICE_NAME = 'groups';

// #region State
export const INITIAL_STATE: SliceState = {
  infoPanelOpen: false,
};
// #endregion

// #region AsyncThunks
export const getGroups = createAsyncThunk(`${SLICE_NAME}/getGroups`, async (_, { dispatch }) => {
  let data = {};
  try {
    const { data } = await new GroupsService().getGroupsList();

    const objects = data.nodes.reduce((previous: any, object: { id: string | number }) => {
      const next = { ...previous };
      next[object.id] = object;
      return next;
    }, {});

    dispatch(addData(objects));
  } catch (error) {
    Logger.captureException(error);
  }
  return data;
});

export const createGroup = createAsyncThunk(
  `${SLICE_NAME}/createGroup`,
  async ({ name, userId }: { name: string; userId?: UserId }, { dispatch }) => {
    try {
      const { data } = await new GroupsService({
        errorsExpected: [400],
      }).createGroup(name);

      if (userId) {
        await new GroupsService().addUserToGroup(data.id, userId);
        data.users = [userId];
      }

      dispatch(addData({ [data.id]: data as Objekt }));
      dispatch(addToList({ identity: 'groups', objectId: data.id }));
    } catch (error) {
      if (GroupsService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'INCORRECT_NAME',
            message: 'GROUP_INCORRECT_NAME_MESSAGE',
          });
        }
      }
    }
  },
);

export const deleteGroup = createAsyncThunk(
  `${SLICE_NAME}/deleteGroup`,
  async ({ groupId }: { groupId: ObjectId }, { dispatch }) => {
    new GroupsService().deleteGroup(groupId).then(() => {
      dispatch(removeFromList({ identity: 'groups', objectId: groupId }));
    });
  },
);
// #endregion

// #region Slice
const groupsPageSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    toggleInfoPanelOpen: (state) => {
      state.infoPanelOpen = !state.infoPanelOpen;
    },
  },
});

// Actions
export const { toggleInfoPanelOpen } = groupsPageSlice.actions;

// Persistence
const persistConfig = {
  key: 'groups',
  storage,
  whitelist: ['infoPanelOpen'],
};

const groupsReducer = persistReducer(persistConfig, groupsPageSlice.reducer);

export default groupsReducer;
// #endregion
