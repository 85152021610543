import { useState, useEffect } from 'react';

const useDebounce = <T>(value: T, delay: number = 500, callback?: (value: T) => void) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (callback) {
        callback(value);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebounce;
