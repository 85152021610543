import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, ToggleGroup, Toggle, Select, InputField } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

import { useDispatch, useSelector, useDebounce } from '_common/hooks';
import { useSuiteObject } from '_common/suite';
import { notify } from '_common/components/ToastSystem';

import { setSidebarPanelTab } from 'Editor/redux/SidebarSlice';
import { selectNotes, setSidebarNotesSearchValue } from 'Editor/redux/NotesSlice';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import NotesNumber from './NotesNumber/NotesNumber';
import EmptyView from './EmptyView/EmptyView';

import styles from './NotesPanel.module.scss';
import EditorManager from 'Editor/services/EditorManager';
import NoteCard from 'Editor/components/ViewNoteCard/NoteCard';

const NotesPanel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const object = useSuiteObject();

  const type = useSelector((state) => state.editor.sidebar.tabs.notes);
  const selected = useSelector((state) => state.editor.notes.overlay.selected);
  const placement = useSelector((state) => state.editor.notes.p);
  const notes = useSelector(selectNotes);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);

  const [searchValue, setSearchValue] = useState(''); // Might have to initialize from the redux value
  const debouncedSearchValue = useDebounce(searchValue, 200);
  const ENDNOTES_LOCATION = [
    { value: 'd', label: intl.formatMessage({ id: 'END_OF_DOCUMENT' }) },
    { value: 's', label: intl.formatMessage({ id: 'END_OF_SECTION' }) },
  ];

  useEffect(() => {
    dispatch(setSidebarNotesSearchValue(debouncedSearchValue));
  }, [debouncedSearchValue]);

  const onSearchValueChange: InputProps['onChange'] = (e) => {
    setSearchValue(e.target.value);
  };

  const checkPermissions = () =>
    object.user_permissions.includes('owner') ||
    object.user_permissions.includes('edit') ||
    object.user_permissions.includes('admin');

  return (
    <>
      <div className={styles.notes}>
        <ToggleGroup>
          <Toggle
            variant="group"
            size="small"
            fullWidth
            onClick={() => {
              dispatch(setSidebarPanelTab({ view: 'notes', tab: 'footnotes' }));
            }}
            isToggled={type === 'footnotes'}
            testId="footNotes-toggle"
          >
            <FormattedMessage id="FOOTNOTES" />
          </Toggle>
          <Toggle
            variant="group"
            size="small"
            fullWidth
            onClick={() => {
              dispatch(setSidebarPanelTab({ view: 'notes', tab: 'endnotes' }));
            }}
            isToggled={type === 'endnotes'}
            testId="endNotes-toggle"
          >
            <FormattedMessage id="ENDNOTES" />
          </Toggle>
        </ToggleGroup>
        <Input
          prefix="NavSearchBlue"
          value={searchValue}
          placeholder={intl.formatMessage({ id: 'global.search' })}
          onChange={onSearchValueChange}
          margin="2rem 0 1rem 0"
          size="medium"
          testId="notes-search"
        />
        {type === 'endnotes' && (
          <InputField
            label={intl.formatMessage({ id: 'ENDNOTE_LOCATION' })}
            feedback={false}
            margin="0 0 2rem 0"
            testId="end-note-location"
          >
            <Select
              width="43rem"
              value={ENDNOTES_LOCATION.filter((endnote) => endnote.value === placement)[0]}
              onChange={(value) => {
                EditorManager.getInstance()
                  .setPlacement(value.value as Notes.NotePlacement)
                  .then(() => {
                    notify({
                      type: 'success',
                      title: 'ENDNOTES_LOCATION_EDITED',
                      message: 'THE_LOCATION_OF_ENDNOTES_IN_DOCUMENT_WAS_EDITED',
                    });
                  });
              }}
              size="medium"
              options={ENDNOTES_LOCATION}
              disabled={!checkPermissions() || notes.length === 0}
              clearable={false}
              testId="endNote-location"
            />
          </InputField>
        )}
        {notes.length !== 0 && (
          <NotesNumber searchValue={searchValue} number={notes.length} type={type} />
        )}
      </div>
      <div className={styles.notesList}>
        {notes.length > 0 ? (
          notes.map((note) => (
            <NoteCard
              key={note.id}
              note={note}
              selected={selected === note.id}
              sidebar
              isReadOnlyMode={isReadOnlyMode || isPageLayout}
              doc={object}
            />
          ))
        ) : (
          <div className={styles.empty_state}>
            <EmptyView type={type} searchValue={searchValue} />
          </div>
        )}
      </div>
    </>
  );
};

export default NotesPanel;
