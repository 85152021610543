import { useDispatch, useSelector } from '_common/hooks';

import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { VersionHistory } from '_common/suite/components';
import { VersionHistoryProps } from '_common/suite/components/Panel/VersionHistory/VersionHistory';
import EditorManager from 'Editor/services/EditorManager/EditorManager';
import { setVersionHistoryValue } from 'Editor/redux/EditorStatusSlice';
import { useSuiteObject } from '_common/suite';

const EditorVersionHistory = () => {
  const dispatch = useDispatch();
  const manager = EditorManager.getInstance();
  const object = useSuiteObject();
  const versions = object.lifecycle.versions;

  const loadedVersion = useSelector((state) => state.editor.status.loadedVersion);

  const handleVersionHistoryChange: VersionHistoryProps['onVersionHistoryChange'] = (newValue) => {
    dispatch(setVersionHistoryValue(newValue));
  };

  const handleLoadVersion: VersionHistoryProps['onLoadVersion'] = (version) => {
    manager.loadVersion(version);
  };

  const handleRestoreClick: VersionHistoryProps['onRestoreClick'] = (versionIndex) => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'RESTORE_DOCUMENT_VERSION',
          message: 'ARE_YOU_SURE_YOU_WANT_TO_RESTORE_THIS_DOCUMENT_VERSION',
          messageValues: { versionNumber: versionIndex + 1 },
          confirmButtonTextId: 'RESTORE_VERSION',
          confirmButtonType: 'primary',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'restoreDocumentVersion',
          actionValue: { loadedVersion: versionIndex },
          headerType: 'information',
          cancelButtonShow: true,
          width: '60rem',
        },
      }),
    );
  };

  const handleEditDescription: VersionHistoryProps['onEditDescription'] = (versionIndex) => {
    if (versions) {
      dispatch(
        openAndUpdateModal({
          modal: 'SaveVersionModal',
          data: {
            type: 'editDescription',
            index: versionIndex,
            description: versions[versionIndex].description ?? '',
          },
        }),
      );
    }
  };

  return (
    <VersionHistory
      onVersionHistoryChange={handleVersionHistoryChange}
      onLoadVersion={handleLoadVersion}
      loadedVersion={loadedVersion}
      onRestoreClick={handleRestoreClick}
      onEditDescription={handleEditDescription}
    />
  );
};

export default EditorVersionHistory;
