import { FormattedMessage } from 'react-intl';

import styles from './EndOfDocument.module.scss';

const EndOfDocument = () => {
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <FormattedMessage id="SPELL_CHECKER_REACHED_THE_END_OF_THE_DOCUMENT" />
      </div>
    </div>
  );
};

export default EndOfDocument;
