import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTableStyle } from 'Presentation/PresentationData';
import TableDataProvider from './TableData';
import ShapeForeignObject from '../ShapeForeignObject';

import TableCell from './TableCell';
import useStyle from './useStyle';
import Background from '../Background';
import styles from './TableShape.module.scss';
import { useSlideData } from '../../SlideData';

type TableShapeProps = {
  shape: Presentation.Data.TableShape;
};

const TableShape = ({ shape }: TableShapeProps) => {
  const tblStyle = useTableStyle(shape.tbl);
  const { slideScale } = useSlideData();

  // TODO: Abstract this from here
  // const style = useMemo(() => {
  //   const style: Record<string, string | undefined> = {};
  //   if (tblStyle?.wholeTbl) {
  //     style['--color'] = tblStyle.wholeTbl?.tcTxStyle?.color
  //       ? color(tblStyle.wholeTbl.tcTxStyle.color)
  //       : undefined;
  //   }

  //   if (shape.tbl.tblPr.effects) {
  //     for (let i = 0; i < shape.tbl.tblPr.effects.length; i++) {
  //       const effect = shape.tbl.tblPr.effects[i];
  //       if (effect.type === 'outerShadow') {
  //         const sx = (Math.max(effect.sx - 100, 0) / 100) * size.height;
  //         const sy = (Math.max(effect.sy - 100, 0) / 100) * size.width;
  //         style.boxShadow = `${sx}px ${sy}px ${effect.blurRad}px ${color(effect.color)}`;
  //       }
  //     }
  //   }
  //   return style as CSSProperties;
  // }, [tblStyle]);
  const { style } = useStyle(tblStyle?.wholeTbl);

  const ref = useRef<HTMLTableElement | null>(null);
  const [bounds, setBounds] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setBounds(ref.current.getBoundingClientRect());
    }
  }, [slideScale]);

  const tableColIds = useMemo(
    () => shape.tbl.tblGrid.map((tblGridEl) => tblGridEl.id),
    [shape.tbl],
  );

  return (
    <TableDataProvider shape={shape}>
      <g id={shape.id}>
        <ShapeForeignObject>
          <table ref={ref} style={style} className={styles.table}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width={bounds?.width != null ? bounds.width : '100%'}
              height={bounds?.height != null ? bounds?.height : '100%'}
            >
              <Background
                position={{ top: 0, left: 0 }}
                size={{
                  ...bounds,
                  width: (bounds?.width ?? 0) / slideScale,
                  height: (bounds?.height ?? 0) / slideScale,
                }}
                fill={
                  tblStyle?.tblBg?.themeableFill?.fill ?? tblStyle?.tblBg?.themeableFill?.fillRef
                }
              />
            </svg>
            {/**
             * visibility: 'collapse' its so that the thead does not take up space
             * otherwise the tbody will be pushed down by the thead because the thead will have a height defined
             */}
            <thead style={{ visibility: 'collapse' }}>
              <tr>
                {shape.tbl.tblGrid.map(({ w }) => (
                  <th style={{ width: w }} />
                ))}
              </tr>
            </thead>
            <tbody>
              {shape.tbl.tr.map((tr, rowIndex) => {
                return (
                  <tr id={tr.id} style={{ height: tr.h < 12.829 ? 35.435 : tr.h }} key={tr.id}>
                    {tr.cells.map((tc, colIndex) => {
                      if (!tc.hMerge && !tc.vMerge) {
                        return (
                          <TableCell
                            key={tc.id}
                            tc={tc}
                            tblStyle={tblStyle}
                            rowIndex={rowIndex}
                            colIndex={colIndex}
                            rowId={tr.id}
                            colId={tableColIds[colIndex]}
                          />
                        );
                      }
                      return null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ShapeForeignObject>
      </g>
    </TableDataProvider>
  );
};

export default TableShape;
