import { Icon } from 'dodoc-design-system';
import cx from 'classnames';
import styles from './ThumbnailCards.module.scss';

type ThumbnailCardsProps = {
  comments?: number;
  tasks?: number;
  notes?: boolean;
};

const ThumbnailCards = ({ comments, tasks, notes }: ThumbnailCardsProps) => {
  return (
    <>
      <div className={styles.bottomWrapper}>
        {comments ? (
          <div className={styles.element}>
            <Icon size={16} icon="CommentGrey" />
            <span>{comments > 9 ? '9+' : comments}</span>
          </div>
        ) : null}
        {tasks ? (
          <div className={styles.element}>
            <Icon size={16} icon="Task" />
            <span>{tasks > 9 ? '9+' : tasks}</span>
          </div>
        ) : null}
      </div>

      {notes ? (
        <div className={cx(styles.element, styles.note)}>
          <Icon size={16} icon="Note" />
        </div>
      ) : null}
    </>
  );
};

export default ThumbnailCards;
