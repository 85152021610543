import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Divider, usePopper, Tooltip, Card, Toggle } from 'dodoc-design-system';

import EditorManager from 'Editor/services/EditorManager';
import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';
import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { DocumentStylePreview } from 'Editor/components';

import styles from './Style.module.scss';

export type StyleProps = {
  id: string;
  style: Editor.Style.ParsedStyle;
  name: string;
  selected: boolean;
  testId: string;
};
const StyleContent = ({ id, style, name, selected, testId }: StyleProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const { isOpen, popperProps, referenceProps } = usePopper({
    disabled: isReadOnlyMode || isPageLayout,
    placement: 'left-end',
  });

  const openRenameModal = (name: string) => {
    dispatch(
      openAndUpdateModal({
        modal: 'RenameDocumentStylesModal',
        data: {
          isOpen: true,
          styleId: style.id,
          name,
        },
      }),
    );
  };

  const openConfirmationModalForDeleteStyle = (name: string) => {
    EditorManager.getInstance()
      .getApprovedNodesByStyleId(style.id)
      .then((result) => {
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'DELETE_PARAGRAPH_STYLE_EXTENDED',
              message:
                result.length > 0
                  ? 'THE_CUSTOM_PARAGRAPH_STYLE_IN_APPROVED_BLOCK_REMOVE_CONFIRM'
                  : 'DELETING_THE_CUSTOM_PARAGRAPH_STYLE_IS_PERMANENT_CONFIRM',
              messageValues: { style: name },
              confirmButtonTextId: 'DELETE_STYLE',
              confirmButtonType: 'danger',
              cancelButtonTextId: 'global.cancel',
              headerType: 'error',
              width: '60rem',
              actionCode: 'deleteCustomStyle',
              actionValue: {
                styleId: style.id,
              },
              cancelButtonShow: true,
            },
          }),
        );
      });
  };

  const openConfirmationModalForResetStyle = (name: string) => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'RESET_PARAGRAPH_STYLE',
          message: 'RESETTING_THE_PARAGRAPH_STYLE_WILL_REVERT_TO_ORIGINAL_CONFIRM',
          messageValues: { style: name },
          confirmButtonTextId: 'RESET_STYLE',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          headerType: 'error',
          width: '60rem',
          actionCode: 'resetTemplateStyle',
          actionValue: {
            styleId: style.id,
          },
          cancelButtonShow: true,
        },
      }),
    );
  };

  const applyToSelection = () => {
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.applyDocumentStyleToSelection({ style: style.id });
    }
  };

  const updateStyleFromSelection = () => {
    const manager = EditorManager.getInstance();
    if (manager) {
      manager.updateDocumentStyleFromSelection(style.id);
    }
  };

  const isSelectionCollapsed = () => {
    const manager = EditorManager.getInstance();
    if (manager) {
      return manager.isSelectionCollapsed();
    }
    return true;
  };

  const isTemplateStyle = EditorManager.getInstance()?.isStyleFromTemplate(style.id);

  return (
    <div
      onClick={!isReadOnlyMode && !isPageLayout ? applyToSelection : undefined}
      className={`${styles.cardWrapper} ${
        !isReadOnlyMode && !isPageLayout ? styles.cardPointer : null
      }`}
    >
      <Card width="100%" sidebar selected={selected} testId={`${testId}-card`}>
        <Card.Body>
          <div className={styles.body}>
            <DocumentStylePreview id={id} style={style}>
              {style.fullName}
            </DocumentStylePreview>
            <div
              onClick={(e) => e.stopPropagation()} // Avoid setting paragraph on dropdown click
              className={styles.dropdown}
            >
              <>
                <Tooltip
                  content={intl.formatMessage({ id: 'global.moreActions' })}
                  testId={`${testId}-options-tooltip`}
                >
                  <span>
                    <Toggle
                      size="medium"
                      variant="link"
                      disabled={isReadOnlyMode || isPageLayout}
                      margin="0 0 0 1rem"
                      icon="Options"
                      isToggled={isOpen}
                      {...referenceProps}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      testId={`${testId}-options-toggle`}
                    />
                  </span>
                </Tooltip>
                <Dropdown {...popperProps} testId={`${testId}-dropdown`}>
                  <Dropdown.Item onClick={applyToSelection} testId="apply-dropdown-item">
                    <FormattedMessage id="APPLY" values={{ name: style.fullName }} />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={updateStyleFromSelection}
                    disabled={!isSelectionCollapsed()}
                    testId="update-from-selection-dropdown-item"
                  >
                    <FormattedMessage id="UPDATE_FROM_SELECTION" />
                  </Dropdown.Item>
                  <Divider />
                  <Dropdown.Item
                    onClick={(e) => {
                      openRenameModal(name);
                      e.stopPropagation();
                    }}
                    testId="rename-style-dropdown-item"
                  >
                    <FormattedMessage id="RENAME_STYLE" />
                  </Dropdown.Item>
                  {isTemplateStyle ? (
                    <Dropdown.Item
                      onClick={(e) => {
                        openConfirmationModalForResetStyle(name);
                        e.stopPropagation();
                      }}
                      testId="reset-style-dropdown-item"
                    >
                      <FormattedMessage id="RESET_STYLE" />
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={(e) => {
                        openConfirmationModalForDeleteStyle(name);
                        e.stopPropagation();
                      }}
                      testId="delete-style-dropdown-item"
                    >
                      <FormattedMessage id="DELETE_STYLE" />
                    </Dropdown.Item>
                  )}
                </Dropdown>
              </>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StyleContent;
