import { DOMUtils } from '_common/utils';

export class PDFRange {
  start: PDF.Selection.Position | null;
  end: PDF.Selection.Position | null;

  constructor(range: Range) {
    this.start = this.parseNodeOffsetToPosition(range.startContainer, range.startOffset);
    this.end = this.parseNodeOffsetToPosition(range.endContainer, range.endOffset);
  }

  getNativeRange() {
    const range = document.createRange();

    const start = this.parsePositionToNodeOffset(this.start);
    if (start.container != null && start.offset != null) {
      range.setStart(start.container, start.offset);
    }

    const end = this.parsePositionToNodeOffset(this.end);
    if (end.container != null && end.offset != null) {
      range.setEnd(end.container, end.offset);
    }

    return range;
  }

  private parseNodeOffsetToPosition(node: Node, offset: number) {
    let position: PDF.Selection.Position | null = null;

    let pageContainer: HTMLElement | null = DOMUtils.closest(node, ['DIV']) as HTMLElement;

    if (pageContainer?.dataset.overlaysLayer === 'true') {
      pageContainer = pageContainer.parentElement;
    }

    if (pageContainer && pageContainer.dataset.pageNum != null) {
      const path: PDF.Selection.Path = [];

      path.push(offset);

      let workNode: Node | null = node;
      while (workNode != null && workNode !== pageContainer.parentNode) {
        if (workNode.nodeType === Node.TEXT_NODE) {
          path.unshift('content');
        }

        if (workNode.nodeType === Node.ELEMENT_NODE) {
          path.unshift('childNodes');
        }

        if (workNode.parentNode && workNode !== pageContainer) {
          const index = Array.from(workNode.parentNode.childNodes as Iterable<Node>).indexOf(
            workNode,
          );
          path.unshift(index);
        }

        workNode = workNode.parentNode;
      }

      position = {
        page: +pageContainer.dataset.pageNum,
        path,
      };
    }

    return position;
  }

  private parsePositionToNodeOffset(position: PDF.Selection.Position | null) {
    let result: { container: Node | null; offset: number } = {
      container: null,
      offset: 0,
    };

    if (position) {
      let node: Node | null = null;
      let offset: number;

      node = document.getElementById(`page_${position.page}`);
      offset = 0;

      let lastKey;

      for (let i = 0; i < position.path.length; i++) {
        const key = position.path[i];

        if ((lastKey === 'childNodes' || lastKey === 'content') && !isNaN(+key)) {
          if (node?.nodeType === Node.ELEMENT_NODE) {
            if (node.childNodes[+key] != null) {
              node = node.childNodes[+key];
            } else {
              node = null;
              break;
            }
          } else if (node?.nodeType === Node.TEXT_NODE) {
            offset = +key;
          }
        }

        if (key === 'childNodes' || key === 'content') {
          lastKey = key;
        } else {
          lastKey = null;
        }
      }

      result.container = node;
      result.offset = offset;
    }

    return result;
  }
}
