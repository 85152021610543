import { Mixin } from 'mixwith';

export default Mixin(
  (superclass) =>
    class extends superclass {
      // eslint-disable-next-line
      parseTextNode(textContent) {
        return {
          type: 'text',
          content: textContent || '',
        };
      }
    },
);
