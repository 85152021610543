import { NodeUtils } from 'Editor/services/DataManager';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

export class DeleteColumnsOperation extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  cellsIndex: number[] = [];
  pageWidth?: number;

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    pageWidth: number | undefined,
    selectedCells?: Editor.Edition.CellInfo[],
    cellIndex?: number[],
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.pageWidth = pageWidth;

    if (cellIndex?.length) {
      this.cellsIndex = cellIndex;
    } else if (selectedCells?.length) {
      this.cellsIndex = TableUtils.getCellsIndex(selectedCells);
    }

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    if (this.tableId && this.cellsIndex) {
      const baseData = this.model.selectedData();

      if (!baseData) {
        return this;
      }

      const tableInfo: {
        data: Editor.Data.Node.Data;
        path: Realtime.Core.RealtimePath;
      } = this.model.getChildInfoById(this.tableId);
      const table: Editor.Data.Node.Data = tableInfo.data;
      const tablePath = tableInfo.path;
      const tbody = table.childNodes?.[0];

      if (NodeUtils.isTableData(table) && tbody) {
        // build ops to remove columns

        const headCellToUpdate: any = {};
        const headCellToRemove: string[] = [];

        const rows = tbody.childNodes;

        if (rows) {
          const rowsLength = rows.length;

          for (let i = 0; i < this.cellsIndex.length; i++) {
            const cellIndex = this.cellsIndex[i];

            // head cells checked per column
            const headCellChecked: string[] = [];

            for (let j = 0; j < rowsLength; j++) {
              const cell = rows[j].childNodes?.[cellIndex];

              if (cell && cell.id && cell.properties) {
                const cellPath = this.model.findPath('id', cell.id);

                if (cell.properties?.['head-id']) {
                  const headCellPath = this.model.findPath('id', cell.properties?.['head-id']);
                  const headCell = NodeUtils.getChildDataByPath(baseData, headCellPath);

                  const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);
                  const refCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(cellPath);

                  if (
                    headCell?.id &&
                    headCell?.properties &&
                    headCellIndexs.currentIndex !== refCellIndexs.currentIndex &&
                    !headCellChecked.includes(headCell.id)
                  ) {
                    // add headcell to update
                    if (!headCellToRemove.includes(headCell.id)) {
                      if (headCellToUpdate[headCell.id] != null) {
                        headCellToUpdate[headCell.id].properties.cs -= 1;
                      } else {
                        headCellToUpdate[headCell.id] = {
                          properties: { cs: headCell.properties.cs - 1 },
                        };
                      }
                    }

                    headCellChecked.push(headCell.id);
                  }
                } else if (cell.properties.cs > 1) {
                  // add headcell to remove
                  headCellToRemove.push(cell.id);

                  if (headCellToUpdate[cell.id]) {
                    delete headCellToUpdate[cell.id];
                  }
                }

                const cellData = this.model.getChildDataById(cell.id);

                // remove cell
                cellPath.pop(); // remove path id

                // adjust cell path based on delete offset
                // i works has cell offset
                cellPath[cellPath.length - 1] = +cellPath[cellPath.length - 1] - i;

                this.ops.push(RealtimeOpsBuilder.listDelete(cellData, cellPath));
              }
            }
          }

          // build ops for removed headcells
          const removeLength = headCellToRemove.length;
          for (let i = 0; i < removeLength; i++) {
            const headCellPath = this.model.findPath('id', headCellToRemove[i]);
            const headCell = NodeUtils.getChildDataByPath(baseData, headCellPath);

            const headCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(headCellPath);

            if (headCell?.properties && headCellIndexs.parentIndex != null) {
              const cellToUpdate =
                rows[headCellIndexs.parentIndex].childNodes?.[
                  this.cellsIndex[this.cellsIndex.length - 1] + 1
                ];

              if (cellToUpdate != null && cellToUpdate.id) {
                const newHeadCellPath = this.model.findPath(this.model.KEYS.ID, cellToUpdate.id);
                const newHeadCellIndexs = NodeUtils.getCurrentAndParentIndexByPath(newHeadCellPath);

                newHeadCellPath.pop(); // remove path id
                newHeadCellPath.pop(); // remove cell index

                newHeadCellPath.push(this.cellsIndex[0]); // add cell index with offset
                newHeadCellPath.push(this.model.KEYS.PROPERTIES); // add path to properties

                const newHeadCellData = this.model.getChildDataById(cellToUpdate.id);

                if (newHeadCellIndexs.currentIndex != null && headCellIndexs.currentIndex != null) {
                  const newColSpanValue =
                    headCell.properties.cs -
                    (newHeadCellIndexs.currentIndex - headCellIndexs.currentIndex);

                  // if new row span value is equal or inferior to 0,
                  // means that all rows merged were deleted so no cells need update
                  if (newColSpanValue > 0) {
                    // remove display false
                    this.ops.push(
                      RealtimeOpsBuilder.objectDelete(
                        newHeadCellData[this.model.KEYS.PROPERTIES]?.d,
                        [...newHeadCellPath, 'd'],
                      ),
                    );

                    // remove head-id
                    this.ops.push(
                      RealtimeOpsBuilder.objectDelete(
                        newHeadCellData[this.model.KEYS.PROPERTIES]?.['head-id'],
                        [...newHeadCellPath, 'head-id'],
                      ),
                    );

                    // add new col span
                    if (newColSpanValue > 1) {
                      this.ops.push(
                        RealtimeOpsBuilder.objectInsert(newColSpanValue, [
                          ...newHeadCellPath,
                          'cs',
                        ]),
                      );
                    }

                    // copy row span
                    const rowSpan = headCell.properties.rs;
                    if (rowSpan > 1) {
                      this.ops.push(
                        RealtimeOpsBuilder.objectInsert(rowSpan, [...newHeadCellPath, 'rs']),
                      );
                    }

                    // update headIds value
                    for (let x = 0; x < rowSpan; x++) {
                      for (let y = 0; y < newColSpanValue; y++) {
                        if (x !== 0 || y !== 0) {
                          this.ops.push(
                            RealtimeOpsBuilder.objectReplace(
                              headCellToRemove[i],
                              newHeadCellData.id,
                              [
                                ...tablePath,
                                this.model.KEYS.CHILDNODES,
                                0,
                                this.model.KEYS.CHILDNODES,
                                headCellIndexs.parentIndex + x,
                                this.model.KEYS.CHILDNODES,
                                this.cellsIndex[0] + y, // path offset
                                this.model.KEYS.PROPERTIES,
                                'head-id',
                              ],
                            ),
                          );
                        }
                      }
                    }

                    // if new head cell is empty, add an empty paragraph
                    if (newHeadCellData.childNodes && newHeadCellData.childNodes.length === 0) {
                      const paragraph = NodeDataBuilder.buildData({
                        type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                        parent_id: newHeadCellData.id,
                      });

                      newHeadCellPath.pop(); // remove properties path
                      newHeadCellPath.push(this.model.KEYS.CHILDNODES); // add child nodes path
                      newHeadCellPath.push(0); // add base index

                      this.ops.push(RealtimeOpsBuilder.listInsert(paragraph, newHeadCellPath));
                    }
                  }
                }
              }
            }
          }

          // build ops for updated headcells
          const keyIds = Object.keys(headCellToUpdate);
          for (let i = 0; i < keyIds.length; i++) {
            const updatedData = headCellToUpdate[keyIds[i]];

            const headCellPropPath = this.model.findPath(this.model.KEYS.ID, keyIds[i]);
            headCellPropPath.pop(); // remove path id
            headCellPropPath.push(this.model.KEYS.PROPERTIES); // path to properties
            headCellPropPath.push('cs');

            if (updatedData.properties.cs > 0) {
              const op = this.model.buildOp(headCellPropPath, updatedData.properties.cs);
              if (op != null) {
                this.ops.push(op);
              }
            } else {
              const op = this.model.buildOp(headCellPropPath, null);
              if (op != null) {
                this.ops.push(op);
              }
            }
          }

          // update table width
          if (this.cellsIndex.length && this.pageWidth) {
            const columnsWeights = TableUtils.findColumnsWeights(
              table,
              this.cellsIndex,
              this.pageWidth,
            );
            const tableWidth = table.properties?.w;

            let op;
            const indexes = Object.keys(columnsWeights);
            if (
              tableWidth &&
              (tableWidth.t === 'abs' || tableWidth.t === 'pct') &&
              this.model.id === this.tableId &&
              indexes.length > 0
            ) {
              const newTableWidth = {
                t: tableWidth.t,
                v: tableWidth.v,
              };

              for (let i = 0; i < indexes.length; i++) {
                newTableWidth.v = +(
                  newTableWidth.v -
                  newTableWidth.v * columnsWeights[+indexes[i]]
                ).toFixed(3);
              }

              op = this.getObjectOperationforPathValue(tableWidth, newTableWidth, [
                ...tablePath,
                this.model.KEYS.PROPERTIES,
                'w',
              ]);
            } else if (!tableWidth || tableWidth.t !== 'auto') {
              op = this.getObjectOperationforPathValue(tableWidth, { t: 'auto', v: 0 }, [
                ...tablePath,
                this.model.KEYS.PROPERTIES,
                'w',
              ]);
            }

            if (op) {
              this.ops.push(op);
            }
          }
        }
      }
    }

    return this;
  }
}
