import { AnnotationMode, PageViewport, RenderingCancelledException, RenderTask } from 'pdfjs-dist';
import { memo, useEffect, useRef, useState } from 'react';
import { Logger } from '_common/services';
import { CanvasElement } from '.';

const CANVAS_MAX_AREA = 205981432;

type CanvasLayerProps = {
  viewport: PageViewport;
  pdfPage: PDF.Page | null;
  pageNum: number;
};

const CanvasLayer = ({ viewport, pdfPage, pageNum }: CanvasLayerProps) => {
  const layerRef = useRef<HTMLDivElement | null>(null);
  const [renderTask, setRenderTask] = useState<RenderTask | null>(null);

  const id = `canvasLayer_${pageNum}`;

  const canvasViewport =
    viewport.width * viewport.height > CANVAS_MAX_AREA
      ? pdfPage?.getViewport({ scale: 1.33 })
      : viewport;

  useEffect(() => {
    if (layerRef.current && layerRef.current instanceof HTMLDivElement && pdfPage) {
      const renderCanvas = async () => {
        if (canvasViewport && viewport) {
          const canvasElement = new CanvasElement();
          canvasElement.style.width = `${viewport.width}px`;
          canvasElement.style.height = `${viewport.height}px`;
          canvasElement.width = canvasViewport.width;
          canvasElement.height = canvasViewport.height;
          canvasElement.dataset.pageNum = `${pageNum}`;
          canvasElement.id = `canvasElement_${pageNum}`;

          if (layerRef.current?.firstElementChild) {
            layerRef.current.removeChild(layerRef.current.firstElementChild);
          }

          layerRef.current?.appendChild(canvasElement);
          const ctx = canvasElement.getContext?.('2d');

          if (ctx) {
            const renderTask = pdfPage.render({
              canvasContext: ctx,
              viewport: canvasViewport,
              annotationMode: AnnotationMode.DISABLE, // create state
            });

            setRenderTask(renderTask);

            try {
              await renderTask.promise;
            } catch (error) {
              if (!(error instanceof RenderingCancelledException)) {
                Logger.captureException(error);
              }
            }
          }
        }
      };

      if (viewport && pdfPage) {
        renderCanvas();
      }
    }
    return () => {
      if (renderTask) {
        renderTask.cancel();

        if (layerRef.current?.firstElementChild) {
          layerRef.current.removeChild(layerRef.current.firstElementChild);
        }
      }
    };
  }, [viewport, pdfPage]);

  return <div id={id} key={id} ref={layerRef}></div>;
};

export default memo(CanvasLayer);
