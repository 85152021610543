import { useIntl } from 'react-intl';
import { CSSProperties, useEffect, useState } from 'react';
import { Select } from 'dodoc-design-system';
import type {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

type Measure = { value: number; unit: string };
type Size = { width?: Measure; height?: Measure };
type Margin = { top?: Measure; right?: Measure; bottom?: Measure; left?: Measure };
type Dimension = Size & Margin;

type OptionType = 'size' | 'margin';
export type PageLayoutOption = SelectOption & Dimension;

export type PageLayoutSelectProps<O extends PageLayoutOption = PageLayoutOption> = Pick<
  SelectProps<O>,
  'size' | 'disabled' | 'width' | 'fullWidth' | 'onChange' | 'testId'
> &
  Pick<CSSProperties, 'margin'> & {
    value?: PageSize | PageLayoutOption | string;
    newTemplates?: Record<string, PageLayoutOption>;
    type?: OptionType;
  };

const PageSizeSelect = <O extends PageLayoutOption = PageLayoutOption>({
  margin,
  value,
  newTemplates,
  type,
  onChange,
  ...props
}: PageLayoutSelectProps<O>) => {
  const intl = useIntl();

  const [templates, setTemplates] = useState<Record<string, PageLayoutOption>>(
    type === 'size'
      ? {
          a4: {
            label: 'A4',
            value: 'a4',
            width: { value: 21, unit: 'cm' },
            height: { value: 29.7, unit: 'cm' },
          },
          letter: {
            label: intl.formatMessage({ id: 'LETTER' }),
            value: 'letter',
            width: { value: 21.59, unit: 'cm' },
            height: { value: 27.94, unit: 'cm' },
          },
        }
      : {
          nl: {
            value: 'nl',
            label: intl.formatMessage({ id: 'NORMAL' }),
            top: { value: 2.54, unit: 'cm' },
            right: { value: 2.54, unit: 'cm' },
            bottom: { value: 2.54, unit: 'cm' },
            left: { value: 2.54, unit: 'cm' },
          },
          nw: {
            value: 'nw',
            label: intl.formatMessage({ id: 'NARROW' }),
            top: { value: 1.27, unit: 'cm' },
            right: { value: 1.27, unit: 'cm' },
            bottom: { value: 1.27, unit: 'cm' },
            left: { value: 1.27, unit: 'cm' },
          },
          m: {
            value: 'm',
            label: intl.formatMessage({ id: 'MODERATE' }),
            top: { value: 2.54, unit: 'cm' },
            right: { value: 1.905, unit: 'cm' },
            bottom: { value: 2.54, unit: 'cm' },
            left: { value: 1.905, unit: 'cm' },
          },
          w: {
            value: 'w',
            label: intl.formatMessage({ id: 'WIDE' }),
            top: { value: 2.54, unit: 'cm' },
            right: { value: 5.08, unit: 'cm' },
            bottom: { value: 2.54, unit: 'cm' },
            left: { value: 5.08, unit: 'cm' },
          },
        },
  );

  const [options, setOptions] = useState<O[]>([]);

  const [selectedOption, setSelectedOption] = useState<O | undefined>(undefined);

  const isSameDimension = (dimensionA: Partial<Dimension>, dimensionB: Partial<Dimension>) => {
    let isSame = false;
    const marginDimensions = (Object.keys(dimensionA) as Array<keyof typeof dimensionA>).filter(
      (dim) => dim !== 'width' && dim !== 'height',
    );

    //Size dimension
    if (
      dimensionA.width !== undefined &&
      dimensionB.width !== undefined &&
      dimensionA.height !== undefined &&
      dimensionB.height !== undefined
    ) {
      const widthACentimeters = EditorDOMUtils.convertUnitTo(
        dimensionA.width.value,
        //@ts-expect-error - dimensionA.width.unit does yet have Common.Utils.MeasureUnit typing
        dimensionA.width.unit,
        'cm',
        4,
      );
      const heightACentimeters = EditorDOMUtils.convertUnitTo(
        dimensionA.height.value,
        //@ts-expect-error - dimensionA.height.unit does yet have Common.Utils.MeasureUnit typing
        dimensionA.height.unit,
        'cm',
        4,
      );
      const widthBCentimeters = EditorDOMUtils.convertUnitTo(
        dimensionB.width.value,
        //@ts-expect-error - dimensionB.width.unit does yet have Common.Utils.MeasureUnit typing
        dimensionB.width?.unit,
        'cm',
        4,
      );
      const heightBCentimeters = EditorDOMUtils.convertUnitTo(
        dimensionB.height.value,
        //@ts-expect-error - dimensionB.height.unit does yet have Common.Utils.MeasureUnit typing
        dimensionB.height.unit,
        'cm',
        4,
      );

      if (
        Number(Math.abs(widthACentimeters - widthBCentimeters).toFixed(1)) <= 0.4 &&
        Number(Math.abs(heightACentimeters - heightBCentimeters).toFixed(1)) <= 0.4
      ) {
        isSame = true;
      }

      if (
        Number(Math.abs(widthACentimeters - heightBCentimeters).toFixed(1)) <= 0.4 &&
        Number(Math.abs(heightACentimeters - widthBCentimeters).toFixed(1)) <= 0.4
      ) {
        isSame = true;
      }

      return isSame;
    }

    //Margin dimension
    isSame = true;
    marginDimensions.forEach((a) => {
      const valueACentimeters = EditorDOMUtils.convertUnitTo(
        dimensionA[a]?.value,
        //@ts-expect-error - dimensionA[a].unit does yet have Common.Utils.MeasureUnit typing
        dimensionA[a]?.unit,
        'cm',
        4,
      );
      const valueBCentimeters = EditorDOMUtils.convertUnitTo(
        dimensionB[a]?.value,
        //@ts-expect-error - dimensionB[a].unit does yet have Common.Utils.MeasureUnit typing
        dimensionB[a]?.unit,
        'cm',
        4,
      );

      if (valueACentimeters !== undefined && valueBCentimeters !== undefined) {
        if (Number(Math.abs(valueACentimeters - valueBCentimeters).toFixed(1)) > 0.4) {
          isSame = false;
          return;
        }
      }
    });

    return isSame;
  };

  useEffect(() => {
    const selectOptions: typeof options = [];
    let description = '';
    let dimensions = {};
    (Object.keys(templates) as Array<keyof typeof templates>).forEach((template) => {
      if (
        !options.find((option) => {
          return (
            option.value === templates[template]?.value ||
            isSameDimension(
              {
                width: option.width,
                height: option.height,
                top: option.top,
                right: option.right,
                bottom: option.bottom,
                left: option.left,
              },
              {
                width: templates[template].width,
                height: templates[template].height,
                top: templates[template].top,
                right: templates[template].right,
                bottom: templates[template].bottom,
                left: templates[template].left,
              },
            )
          );
        })
      ) {
        if (type === 'margin') {
          description = `${intl.formatMessage({ id: 'TOP' })}: ${templates[template]?.top?.value}${
            templates[template]?.top?.unit
          } ${intl.formatMessage({ id: 'BOTTOM' })}: ${templates[template]?.bottom?.value}${
            templates[template]?.bottom?.unit
          } ${intl.formatMessage({ id: 'LEFT' })}: ${templates[template]?.left?.value}${
            templates[template]?.left?.unit
          } ${intl.formatMessage({ id: 'Right' })}: ${templates[template]?.right?.value}${
            templates[template]?.right?.unit
          }`;
          dimensions = {
            top: templates[template]?.top,
            right: templates[template]?.right,
            bottom: templates[template]?.bottom,
            left: templates[template]?.left,
          };
        } else {
          description = `${templates[template]?.width?.value}${templates[template]?.width?.unit} x ${templates[template]?.height?.value}${templates[template]?.height?.unit}`;
          dimensions = { width: templates[template]?.width, height: templates[template]?.height };
        }
        selectOptions.push({
          value: templates[template]?.value,
          label: templates[template]?.label,
          description,
          ...dimensions,
        } as O);
      }
    });
    if (selectOptions.length > 0) {
      setOptions((prevState) => [...prevState, ...selectOptions]);
    }
  }, [templates]);

  useEffect(() => {
    if (newTemplates) {
      setTemplates((prevState) => {
        return { ...prevState, ...newTemplates };
      });
    }
  }, [newTemplates]);

  useEffect(() => {
    if (value) {
      //If value is O
      if ((value as O).value !== undefined) {
        const existingOptionWithSameDimension = options.find((option) =>
          isSameDimension(
            {
              width: option.width,
              height: option.height,
              top: option.top,
              right: option.right,
              bottom: option.bottom,
              left: option.left,
            },
            {
              width: (value as O).width,
              height: (value as O).height,
              top: (value as O).top,
              right: (value as O).right,
              bottom: (value as O).bottom,
              left: (value as O).left,
            },
          ),
        );

        if (existingOptionWithSameDimension) {
          setSelectedOption(existingOptionWithSameDimension);
        } else {
          //If its a value with dimensions that aren't in the menu, set the value as a custom option
          setSelectedOption({
            ...(value as O),
            value: 'custom',
            label: intl.formatMessage({ id: 'CUSTOM' }),
          });
        }
      }
      //If value is PageSize
      else {
        setSelectedOption(
          options.find(
            (option) => option.value.toLowerCase() === (value as PageSize).toLowerCase(),
          ),
        );
      }
    }
    //Default selected option
    else {
      setSelectedOption(
        options.find(
          (option) => option.value.toLowerCase() === 'a4' || option.value.toLowerCase() === 'nl',
        ),
      );
    }
  }, [value, options]);

  return (
    <div style={{ margin }}>
      <Select
        {...props}
        clearable={false}
        options={options}
        value={selectedOption}
        onChange={(newValue, actionMeta) => {
          setSelectedOption(newValue);
          if (onChange) {
            onChange(newValue, actionMeta);
          }
        }}
      />
    </div>
  );
};

export default PageSizeSelect;
