import { CSSProperties, useMemo } from 'react';
import styles from './Annotation.module.scss';
import { getBoxesFromQuadPoints } from './Utils';
import BaseAnnotation from './BaseAnnotation';
import { useAnnotationContext } from './AnnotationContext';
import UserColors from 'Editor/services/UserColors/UserColors';

const ROIAnnotation = () => {
  const { annotation, viewport, isSelected } = useAnnotationContext();
  const divs = useMemo(() => {
    const boxes = getBoxesFromQuadPoints(annotation, viewport.scale);

    return boxes.map(({ width, height, left, bottom }) => {
      const style: CSSProperties = {
        width,
        height,
        left,
        bottom,
      };

      const strokeWidth = (annotation.border?.width || 1) * viewport.scale;

      if (annotation.subtype === 'Highlight') {
        style.mixBlendMode = 'multiply';
        style.backgroundColor = annotation.color?.stroke;
      } else if (annotation.subtype === 'Underline') {
        style.boxShadow = ` inset 0 -${1}px 0 white, inset 0 -${strokeWidth + 1}px 0 ${
          annotation.color?.stroke
        }`;
      }
      if (annotation.subtype === 'Task') {
        style.mixBlendMode = 'multiply';
        if (annotation.authorId) {
          style.backgroundColor = UserColors.getUserColor(annotation.authorId)?.tint30;
        } else if (annotation.title) {
          style.backgroundColor = UserColors.getUserColor(annotation.title)?.tint30;
        }
      }

      const strikethrough =
        annotation.subtype === 'StrikeOut' ? (
          <div
            className={styles.strikethrough}
            style={{
              borderBottomColor: annotation.color?.stroke,
              borderBottomWidth: strokeWidth,
              height: strokeWidth,
            }}
          />
        ) : null;

      return (
        <div
          key={left + bottom}
          style={style}
          className={styles.base}
          data-testid={`${annotation.subtype}-${annotation.id}`}
        >
          {strikethrough}
        </div>
      );
    });
  }, [annotation, isSelected, viewport]);

  return <BaseAnnotation>{divs}</BaseAnnotation>;
};

export default ROIAnnotation;
