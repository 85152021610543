import { createContext, memo, ReactNode, useContext, useMemo } from 'react';

type GroupContextValue = {
  parentGroup: Presentation.Data.Shape;
  totalScale: { scaleX: number; scaleY: number };
};

const GroupContext = createContext<GroupContextValue | undefined>(undefined);

type GroupDataProps = {
  children: ReactNode;
  shape: Presentation.Data.GroupShape;
};

const GroupDataProvider = ({ children, shape }: GroupDataProps) => {
  const { totalScale: prevTotal } = useGroupData();

  const totalScale = useMemo(() => {
    const { ext, chExt } = shape?.properties.xfrm ?? {};
    if (!ext || !chExt) {
      return { scaleX: 1, scaleY: 1 };
    }

    if (!prevTotal) {
      return { scaleX: ext.cx / chExt.cx, scaleY: ext.cy / chExt.cy };
    }

    return {
      scaleX: prevTotal.scaleX * (ext.cx / chExt.cx),
      scaleY: prevTotal.scaleX * (ext.cy / chExt.cy),
    };
  }, []);

  return (
    <GroupContext.Provider value={{ parentGroup: shape, totalScale }}>
      {children}
    </GroupContext.Provider>
  );
};

export const useGroupData = () => {
  return useContext(GroupContext) || { parentGroup: null, totalScale: null };
};

export default memo(GroupDataProvider);
