import { faker } from '@faker-js/faker';
import { createMockedObjectId, createMockedTimeObject, createMockedUserId } from './common';

export function createNotification({
  overwrites,
  actionOverwrites,
}: {
  overwrites?: Partial<NotificationRecord>;
  actionOverwrites?: Partial<NotificationRecord['action']>;
}): NotificationRecord {
  return {
    id: createMockedObjectId(),
    time: createMockedTimeObject(),
    owner: createMockedUserId(),
    read: false,
    type: 'notification',
    event: 'deadline_expired',
    action: {
      id: createMockedObjectId(),
      ...actionOverwrites,
    },
    ...overwrites,
  };
}

type CurrentUser = { currentUserId: string };
type Target = {
  targetName: ApiSchemas['RolePermissionActionExtra']['target_name'];
  targetType: ApiSchemas['RolePermissionActionExtra']['type'];
  targetId: NotificationRecord['action']['target'];
};
type Status = { newStatus: string; oldStatus: string };
type Snapshot = { textContent: string };
type Annotation = { subType: ApiSchemas['ActionExtra']['sub_type'] };
type Deadline = Pick<ApiSchemas['TaskDeadlineActionExtra'], 'deadline'>;
type Priority = Pick<ApiSchemas['CommentPriorityActionExtra'], 'old_priority' | 'new_priority'>;

type PresetNotifications = {
  shareObject: (params: CurrentUser & Target) => Partial<NotificationRecord>;
  taskStatusChanged: (
    params: CurrentUser & Target & Status & Snapshot,
  ) => Partial<NotificationRecord>;
  editedAnnotationContent: (
    params: CurrentUser & Omit<Target, 'targetType'> & Snapshot & Annotation,
  ) => Partial<NotificationRecord>;
  taskDeadlineReminder: (params: CurrentUser & Deadline & Snapshot) => Partial<NotificationRecord>;
  changedCommentPriority: (
    params: CurrentUser & Priority & Snapshot,
  ) => Partial<NotificationRecord>;
};

export const presetNotificationOverwrites: PresetNotifications = {
  shareObject: ({ currentUserId, targetType, targetName, targetId }) => ({
    action: {
      action: 'role_added',
      collection: 'objects',
      extra: {
        recursive: true,
        role: 'noRole',
        subject: 'targetUserId',
        target_name: targetName,
        to: 'users',
        type: targetType,
      },
      user: currentUserId,
      target: targetId,
    },
    event: 'shared',
  }),
  taskStatusChanged: ({
    currentUserId,
    newStatus,
    oldStatus,
    textContent,
    targetId,
    targetName,
    targetType,
  }) => ({
    action: {
      action: 'task_status_changed',
      collection: 'objects',
      extra: {
        assignee: currentUserId,
        new_status: newStatus,
        old_status: oldStatus,
        page_num: 1,
        snapshot: [
          {
            children: [
              {
                text: textContent,
              },
            ],
            type: 'paragraph',
          },
        ],
        target_name: targetName,
        task: 'taskId',
        type: targetType,
      },
      id: createMockedObjectId(),
      target: targetId,
      time: faker.date.recent(5).toISOString(),
      user: currentUserId,
    },
    event: 'task_status_changed',
  }),
  editedAnnotationContent: ({ currentUserId, textContent, targetId, targetName, subType }) => ({
    action: {
      action: 'annotation_content_edited',
      collection: 'objects',
      extra: {
        annotation: 'annotationId',
        new_snapshot: [
          {
            children: [
              {
                text: textContent,
              },
            ],
            type: 'paragraph',
          },
        ],
        old_snapshot: [],
        sub_type: subType,
        target_name: targetName,
        type: 'dopdf',
      },
      id: createMockedObjectId(),
      target: targetId,
      time: faker.date.recent(5).toISOString(),
      user: currentUserId,
    },
    event: 'annotation_content_edited',
  }),
  taskDeadlineReminder: ({ currentUserId, deadline, textContent }) => ({
    action: {
      collection: 'objects',
      extra: {
        task: 'taskId',
        deadline,
        snapshot: [
          {
            children: [
              {
                text: textContent,
              },
            ],
            type: 'paragraph',
          },
        ],
      },
      id: createMockedObjectId(),
      time: faker.date.recent(5).toISOString(),
      user: currentUserId,
    },
    event: 'task_deadline_reminder',
  }),
  changedCommentPriority: ({ currentUserId, old_priority, new_priority, textContent }) => ({
    action: {
      collection: 'objects',
      extra: {
        comment: 'commentId',
        snapshot: [
          {
            children: [
              {
                text: textContent,
              },
            ],
            type: 'paragraph',
          },
        ],
        nodes: [],
        old_priority,
        new_priority,
      },
      id: createMockedObjectId(),
      time: faker.date.recent(5).toISOString(),
      user: currentUserId,
    },
    event: 'comment_priority_changed',
  }),
};
