import { setConnectivityIssuesState } from 'App/redux/appSlice';
import { Banner } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';

const BannerContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const connectivityIssues = useSelector((state) => state.app.connectivityIssues);

  const handleRefreshPage = () => {
    window.location.reload();
    dispatch(setConnectivityIssuesState(false));
  };

  return (
    <Banner
      isOpen={connectivityIssues}
      buttonProps={{
        disabled: false,
        loading: false,
        onClick: handleRefreshPage,
        children: intl.formatMessage({ id: 'REFRESH' }),
      }}
      variant="warning"
      testId="connectivity-issues-banner"
    >
      <FormattedMessage id="CONNECTIVITY_ISSUES_WARNING" />
    </Banner>
  );
};

export default BannerContent;
