import { useIntl } from 'react-intl';
import { useDispatch } from '_common/hooks';
import { Icon, Tooltip } from 'dodoc-design-system';
import { openModal } from '_common/modals/ModalsSlice';
import styles from './SupportButton.module.scss';

/**
 * SupportButton
 *
 * Replaces the native Zendesk widget trigger button
 */
const SupportButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <Tooltip
      content={intl.formatMessage({ id: 'global.support' })}
      placement="left"
      testId="login-help-tooltip"
    >
      <div
        className={styles.root}
        onClick={() => {
          dispatch(openModal('SupportFormModal'));
        }}
        data-testid="login-help-button"
      >
        <Icon icon="Help" size={24} />
      </div>
    </Tooltip>
  );
};

export default SupportButton;
