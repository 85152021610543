import api from '_common/services/api/api';

const metadataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetadataList: builder.query<Record<string, OrderedMetadata>, void>({
      query: () => ({
        url: `/object/metadata/list`,
      }),
      transformResponse: (metadataArray: ApiOperations['list_metadata']['responses']['200']) => {
        let autoCount = 0;
        let manualCount = 0;

        return metadataArray.reduce<Record<string, OrderedMetadata>>(
          (indexedMetadata, currMetadata) => {
            if (currMetadata.automatic) {
              indexedMetadata[currMetadata.id] = { ...currMetadata, order: autoCount++ };
            } else {
              indexedMetadata[currMetadata.id] = { ...currMetadata, order: manualCount++ };
            }

            return indexedMetadata;
          },
          {},
        );
      },
      providesTags: ['Metadata'],
    }),
    createMetadata: builder.mutation<
      ApiOperations['create_metadata']['responses']['200']['content']['application/json'],
      ApiOperations['create_metadata']['requestBody']['content']['multipart/form-data']
    >({
      query: (body) => ({
        url: `/object/metadata/create`,
        method: 'POST',
        body,
        arrayFormat: 'path',
      }),
      invalidatesTags: ['Metadata'],
    }),
    deleteMetadata: builder.mutation({
      query: (id: string) => ({
        url: `/object/metadata/${id}/delete`,
        method: 'POST',
        body: undefined,
      }),
      invalidatesTags: ['Metadata'],
    }),
    editMetadata: builder.mutation<
      string,
      ({
        id: string;
      } & ApiOperations['edit_metadata']['requestBody']['content']['multipart/form-data'])[]
    >({
      async queryFn(editOperationList, _, __, baseQuery) {
        for (const editOperation of editOperationList) {
          const { id, path, value } = editOperation;
          await baseQuery({
            url: `/object/metadata/${id}/edit`,
            method: 'POST',
            body: { path, value },
            arrayFormat: 'indices',
          });
        }

        return { data: 'All operations succeeded' };
      },
      invalidatesTags: ['Metadata'],
    }),
    orderMetadata: builder.mutation<
      ApiOperations['order_metadata']['responses']['200'],
      ApiOperations['order_metadata']['parameters']['path'] &
        ApiOperations['order_metadata']['requestBody']['content']['multipart/form-data']
    >({
      query: ({ object_id, direction }) => ({
        url: `/object/metadata/${object_id}/order`,
        method: 'POST',
        body: { direction },
      }),
      invalidatesTags: ['Metadata'],
    }),
    editDocumentMetadata: builder.mutation<
      ApiOperations['edit_document_metadata']['responses']['200'],
      { element_type: string } & ApiOperations['edit_document_metadata']['parameters']['path'] &
        ApiOperations['edit_document_metadata']['requestBody']['content']['multipart/form-data']
    >({
      query: ({ element_type, document_id, field, value, add }) => ({
        url: `/object/${element_type}/${document_id}/metadata/edit`,
        method: 'POST',
        body: { field, value, add },
      }),
      invalidatesTags: (params) => [{ type: 'Object', id: params?.id }],
    }),
  }),
});

// Export queries and mutations
export const {
  useGetMetadataListQuery,
  useCreateMetadataMutation,
  useDeleteMetadataMutation,
  useEditMetadataMutation,
  useOrderMetadataMutation,
  useEditDocumentMetadataMutation,
} = metadataApi;

export default metadataApi;
