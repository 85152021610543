import { selectPDFZoom } from 'PDF/redux/PDFGeneralSlice';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from '_common/hooks';

const usePan = (isActive: boolean) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isPanning, setIsPanning] = useState(false);
  const zoom = useSelector(selectPDFZoom);

  useEffect(() => {
    if (isActive) {
      let anchorY = 0;
      let anchorX = 0;

      const handleMouseDown = (e: MouseEvent) => {
        setIsPanning(true);
        e.preventDefault();
        anchorY = e.pageY;
        anchorX = e.pageX;
        if (ref.current) {
          document.addEventListener('mouseup', handleMouseUp);
          document.addEventListener('mousemove', handleMouseMove);
        }
      };

      const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        if (anchorY) {
          let deltaY = anchorY - e.pageY;
          if (deltaY > 0) {
            deltaY *= 2;
          } else {
            deltaY *= 1.5;
          }

          anchorY = e.pageY;

          if (ref.current) {
            const diff = ref.current.scrollTop + deltaY;

            ref.current.scrollTop = diff;
          }
        }
        if (anchorX) {
          let deltaX = anchorX - e.pageX;
          if (deltaX > 0) {
            deltaX *= 1.5;
          } else {
            deltaX *= 1;
          }

          anchorX = e.pageX;

          if (ref.current) {
            const diff = ref.current.scrollLeft + deltaX;

            ref.current.scrollLeft = diff;
          }
        }
      };

      const handleMouseUp = (e: MouseEvent) => {
        setIsPanning(false);
        e.preventDefault();
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      const removeEvents = () => {
        if (ref.current) {
          ref.current.removeEventListener('mousedown', handleMouseDown);
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        }
      };

      if (ref.current) {
        ref.current.addEventListener('mousedown', handleMouseDown);
        return () => {
          removeEvents();
        };
      }
    }
  }, [isActive, zoom]);

  return { ref, isPanning };
};

export default usePan;
