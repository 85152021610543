import { useEffect, useMemo, useRef } from 'react';
import useChartSVG from '../../useChartSVG';

type MarkerProps = {
  marker: Presentation.Data.Marker;
};

const Marker = ({ marker }: MarkerProps) => {
  const symbolRef = useRef<HTMLDivElement>(null);

  const { markerSymbol } = useChartSVG();

  const size = useMemo(() => {
    const maxSize = 8;
    return Math.min(marker.size ? marker.size : maxSize, maxSize);
  }, [marker]);

  const position = useMemo(() => {
    return {
      top: `calc(50% - ${size / 2}px)`,
      left: `calc(50% - ${size / 2}px)`,
    };
  }, [size]);

  useEffect(() => {
    if (symbolRef.current) {
      if (!symbolRef.current.children.length) {
        const symbolSVG = markerSymbol({
          symbol: marker.symbol,
          properties: marker.properties,
          width: size,
          height: size,
        });
        const parser = new DOMParser();
        if (symbolSVG) {
          const symbol = parser.parseFromString(symbolSVG, 'image/svg+xml');
          symbolRef.current.appendChild(symbol.documentElement);
        }
      }
    }
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        width: size,
        height: size,
      }}
    >
      <div ref={symbolRef} />
    </div>
  );
};

export default Marker;
