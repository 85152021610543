import { ReactNode, useMemo, useRef } from 'react';
import { NODE, useAnnotationBox, useAnnotationContext } from './AnnotationContext';

import styles from './Annotation.module.scss';

type TransformWrapperProps = {
  children: ReactNode;
  allowMove?: boolean;
  allowResize?: readonly NODE[];
};

const NODES = ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'] as const;

const EDGES = ['t', 'r', 'b', 'l'] as const;

const TransformWrapper = ({
  children,
  allowMove = true,
  allowResize = NODES,
}: TransformWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { annotation, viewport, move, resize, isSelected, onResizeDown, onMoveDown } =
    useAnnotationContext();
  const box = useAnnotationBox();

  const nodes = useMemo(() => {
    if (isSelected && !move.isOn && allowResize?.length) {
      if (annotation.subtype === 'Line') {
        const startNode = (
          <span
            key="start"
            className={styles.node}
            style={{
              left:
                (annotation.lineCoordinates.start.x - annotation.rect.left) * viewport.scale - 4,
              top: (annotation.rect.top - annotation.lineCoordinates.start.y) * viewport.scale - 4,
            }}
            onMouseDown={(e) => onResizeDown(e, 'start')}
          />
        );
        const endNode = (
          <span
            key="end"
            className={styles.node}
            style={{
              left: (annotation.lineCoordinates.end.x - annotation.rect.left) * viewport.scale - 4,
              top: (annotation.rect.top - annotation.lineCoordinates.end.y) * viewport.scale - 4,
            }}
            onMouseDown={(e) => onResizeDown(e, 'end')}
          />
        );
        return [startNode, endNode];
      } else {
        return NODES.filter((node) => allowResize.includes(node)).map((node) => {
          return (
            <span
              key={node}
              className={`${styles.node} ${styles[node]}`}
              onMouseDown={(e) => onResizeDown(e, node)}
            />
          );
        });
      }
    }

    return [];
  }, [isSelected, move.start, allowResize, resize, annotation]);

  const edges = useMemo(() => {
    if (isSelected && !resize.node && allowMove)
      return EDGES.map((edge) => {
        return <span key={edge} className={`${styles.edge} ${styles[edge]}`} />;
      });
  }, [isSelected, annotation.subtype, resize.node, allowMove]);

  const area = useMemo(() => {
    if (isSelected && !resize.node && allowMove) {
      return <span className={styles.area} onMouseDown={(e) => onMoveDown(e)} />;
    }
    return null;
  }, [isSelected, annotation.subtype, resize.node, allowMove]);

  if (!isSelected) {
    return <>{children}</>;
  }

  return (
    <div
      ref={ref}
      className={styles.resize}
      style={{
        width: box.width,
        height: annotation.subtype === 'FreeText' ? 'auto' : box.height,
        position: 'absolute',
      }}
    >
      {children}
      {edges}
      {area}
      {nodes}
    </div>
  );
};

export default TransformWrapper;
