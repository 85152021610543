import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute/PrivateRoute';
import SignInPage from './pages/SignInPage/SignInPage';
import RecoverPage from './pages/RecoverPage/RecoverPage';
import RedirectPage from './pages/RedirectPage/RedirectPage';
import RedirectIntegrationPage from './pages/RedirectIntegrationPage/RedirectIntegrationPage';
import UnlockPage from './pages/UnlockPage/UnlockPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import DeviceRegistrationPage from './pages/DeviceRegistrationPage/DeviceRegistrationPage';
import ResetPage from './pages/ResetPage/ResetPage';
import UpdatePasswordPage from './pages/UpdatePasswordPage/UpdatePasswordPage';
import AuthContextProvider from './AuthContext';

const AuthContainer = () => {
  return (
    <AuthContextProvider>
      <Switch>
        <Route exact path="/auth" render={() => <Redirect to="/auth/signin" />} />
        <Route path="/auth/signin" component={SignInPage} />
        <Route path="/auth/recover" component={RecoverPage} />
        <Route
          path="/auth/redirect/:status/:token([a-f0-9]{64,256})?/:device([a-f0-9]{64,256})?/:redirect?/:tenant?"
          component={RedirectPage}
        />
        <Route path="/auth/connector/redirect/ok" component={RedirectIntegrationPage} />
        <Route path="/auth/unlock/:hash" component={UnlockPage} />
        <PrivateRoute path="/auth/updatepassword">
          <UpdatePasswordPage />
        </PrivateRoute>
        <PrivateRoute path="/auth/deviceregistration">
          <DeviceRegistrationPage />
        </PrivateRoute>
        <Route path="/auth/welcome" component={WelcomePage} />
        <Route path="/auth/reset/:hash" component={ResetPage} />
        <Route path="/auth/*" component={() => <Redirect to="/auth/signin" />} />
      </Switch>
    </AuthContextProvider>
  );
};

export default AuthContainer;
