import UserCard from '_common/components/UserCard/UserCard';
import GroupCard from '_common/components/GroupCard/GroupCard';

type CollaboratorColumnProps = {
  collaboratorId: Permission.Collaborator['collaboratorId'];
  collaboratorType: Permission.Collaborator['collaboratorType'];
};

const CollaboratorColumn = ({ collaboratorId, collaboratorType }: CollaboratorColumnProps) => {
  return collaboratorType === 'user' ? (
    <UserCard userId={collaboratorId} titleStyle={{ fontWeight: 500 }} size="medium" />
  ) : (
    <GroupCard id={collaboratorId} />
  );
};

export default CollaboratorColumn;
