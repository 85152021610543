import { ChangeEventHandler, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Select, InputField, TextArea, DatePicker, Tag } from 'dodoc-design-system';
import type { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import SearchUser from '_common/components/SearchUser/SearchUser';
import { useEditDocumentMetadataMutation } from 'App/redux/MetadataApi';
import dayjs from 'dayjs';

import styles from './MetadataFields.module.scss';

type MetadataFieldsProps = {
  validations?: string;
  metadata: ApiSchemas['MetadataObjectSchema'];
  element: doDOC.Document | doDOC.File | doDOC.PDF | doDOC.Presentation;
  setValidations: (value: string) => void;
  exportVeeva?: boolean;
};

const MetadataFields = ({
  validations,
  metadata,
  element,
  setValidations,
}: MetadataFieldsProps) => {
  const intl = useIntl();
  const [editDocumentMetadata] = useEditDocumentMetadataMutation();

  const isRequired = metadata.required;

  //@ts-expect-error Check if the other types of elements will have metadata
  const documentMetadata = useMemo(() => element?.metadata?.[metadata.id], [element, metadata.id]);

  const [contentName, setContentName] = useState(
    documentMetadata && !Array.isArray(documentMetadata) ? documentMetadata : '',
  );
  const [date, setDate] = useState<dayjs.Dayjs | null>(
    documentMetadata && !Array.isArray(documentMetadata) && dayjs(documentMetadata).isValid()
      ? dayjs(documentMetadata)
      : null,
  );
  const [contentNameFeedback, setContentNameFeedback] = useState(
    isRequired && !contentName
      ? intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_ENTER_VALUE' })
      : false,
  );
  const [dateFeedback, setDateFeedback] = useState(
    isRequired && !date
      ? intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_ENTER_VALUE' })
      : false,
  );
  const handleContentNameChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.target.value;
    setContentName(value);
    handleUpdateField(metadata.id, {
      value,
    });
    if (isRequired && value.length < 1) {
      setContentNameFeedback(
        intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_ENTER_VALUE' }),
      );
    } else if (metadata.data_type === 'string' && value?.length > metadata.char_limit) {
      setValidations(
        intl.formatMessage({ id: 'EXCEEDED_CHARACTER_LIMIT' }, { value: metadata.char_limit }),
      );
      setContentNameFeedback(
        intl.formatMessage({ id: 'EXCEEDED_CHARACTER_LIMIT' }, { value: metadata.char_limit }),
      );
    } else {
      setValidations('');
      setContentNameFeedback(false);
    }
  };

  const handleDateChange = (newDate: Date | null) => {
    setDate(newDate ? dayjs(newDate) : null);
    handleUpdateField(metadata.id, { value: newDate?.toISOString() ?? '' });

    if (isRequired && !newDate) {
      setDateFeedback(intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_ENTER_VALUE' }));
    } else {
      setDateFeedback(false);
    }
  };

  const [selectedOption, setSelectedOption] = useState<
    Omit<SelectOption, 'value'> & {
      value: string | string[] | string[][] | undefined;
      type?: string;
    }
  >(
    Array.isArray(documentMetadata)
      ? {
          value: documentMetadata.join('-'),
          label:
            metadata.data_type === 'user' && !Array.isArray(documentMetadata) ? (
              <UsernameLabel userId={documentMetadata} />
            ) : (
              documentMetadata.join('-')
            ),
        }
      : {
          value: documentMetadata,
          label: <UsernameLabel userId={documentMetadata} />,
          type: metadata.data_type === 'user' ? 'user' : '',
        },
  );

  const handleUpdateField = (id: ObjectId, value: { value: string; remove?: boolean }) => {
    if (!validations) {
      editDocumentMetadata({
        element_type: element.type,
        document_id: element.id,
        field: id,
        value: value.value ?? '',
        add: !value.remove,
      });
    }
  };

  const selectOptions = useMemo(() => {
    if (metadata.data_type === 'multi-select') {
      return metadata?.options
        .filter((option) =>
          Array.isArray(documentMetadata)
            ? !documentMetadata
                .map((docOption) => JSON.stringify(docOption))
                .includes(JSON.stringify(option.fields))
            : true,
        )
        .map((option) => ({
          label: option.fields.join('-'),
          value: option.fields.join('-'),
          valueArray: option.fields,
        }));
    } else if (metadata.data_type === 'select') {
      return metadata?.options.map((option) => ({
        label: option.fields.join('-'),
        value: option.fields.join('-'),
        valueArray: option.fields,
      }));
    }
  }, [metadata, documentMetadata]);

  const selectValue = useMemo(
    () =>
      selectedOption?.value && !Array.isArray(selectedOption.value)
        ? { value: selectedOption.value, label: selectedOption.label, valueArray: [''] }
        : null,
    [selectedOption],
  );

  const fieldName = metadata?.name.replace(' ', '-').toLowerCase();
  const fieldTestId = `edit-metadata-${fieldName}`;
  switch (metadata.data_type) {
    case 'user':
      return (
        <InputField
          size="large"
          label={metadata?.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={
            isRequired &&
            !selectedOption?.value &&
            intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_SELECT_OPTION' })
          }
          testId={fieldTestId}
        >
          <SearchUser
            width="100%"
            size="large"
            menuPosition="fixed"
            placeholder={intl.formatMessage({ id: 'SELECT_A_DODOC_USER' })}
            hideSelectedOptions={false}
            value={
              selectedOption?.value && !Array.isArray(selectedOption.value)
                ? {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    type: selectedOption.type,
                  }
                : null
            }
            onChange={(value) => {
              setSelectedOption(value);
              if (value) {
                handleUpdateField(metadata.id, value);
              } else {
                handleUpdateField(metadata.id, { value: '' });
              }
            }}
            error={isRequired && !selectedOption?.value}
            testId={fieldTestId}
            menuLabel={intl.formatMessage({ id: 'START_TYPING_TO_SEE_THE_LIST_OF_USERS' })}
          />
        </InputField>
      );

    case 'multi-select':
    case 'select':
      return (
        <InputField
          size="large"
          label={metadata?.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={
            isRequired &&
            (!selectedOption || !selectedOption.value || documentMetadata?.length === 0) &&
            intl.formatMessage({ id: 'REQUIRED_FIELD_IS_EMPTY_PLEASE_SELECT_OPTION' })
          }
          testId={fieldTestId}
        >
          <>
            <Select
              clearable={false}
              menuPosition="fixed"
              searchable
              size="large"
              width="100%"
              showOptionTooltip
              value={metadata.data_type !== 'multi-select' ? selectValue : null}
              options={selectOptions}
              onChange={(value) => {
                setSelectedOption(value ?? { label: '', value: '' });
                handleUpdateField(metadata.id, {
                  value: JSON.stringify(value.valueArray ?? []),
                });
              }}
              placeholder={
                metadata.automatic
                  ? intl.formatMessage({
                      id: `SELECT_${metadata?.name.split(' ')[0].toUpperCase()}`,
                    })
                  : intl.formatMessage(
                      {
                        id: `SELECT_SOMETHING`,
                      },
                      { label: metadata?.name },
                    )
              }
              error={isRequired && !selectedOption?.value}
              testId={fieldTestId}
            />
            {metadata.data_type === 'multi-select' && Array.isArray(documentMetadata) && (
              <div className={styles.multiSelectValues}>
                {documentMetadata.map(
                  (option) =>
                    Array.isArray(option) && (
                      <Tag
                        key={option.join('-')}
                        testId={`multi-select-${option.join('-')}`}
                        onClick={() =>
                          handleUpdateField(metadata.id, {
                            value: JSON.stringify(option ?? []),
                            remove: true,
                          })
                        }
                      >
                        {option.join('-')}
                      </Tag>
                    ),
                )}
              </div>
            )}
          </>
        </InputField>
      );
    case 'string':
      return (
        <InputField
          size="large"
          label={metadata.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={contentNameFeedback}
          testId={fieldTestId}
        >
          <TextArea
            size="large"
            placeholder={intl.formatMessage(
              {
                id: 'INSERT_HERE',
              },
              {
                value: metadata?.name,
              },
            )}
            onChange={handleContentNameChange}
            value={contentName}
            error={!!contentNameFeedback}
            onBlur={() =>
              handleUpdateField(metadata.id, {
                value: contentName,
              })
            }
            testId={`${fieldTestId}-textarea`}
          />
        </InputField>
      );
    case 'date':
      return (
        <InputField
          size="large"
          label={metadata.name}
          required={isRequired && intl.formatMessage({ id: 'REQUIRED' })}
          feedback={dateFeedback}
          testId={fieldTestId}
        >
          <DatePicker
            size="large"
            placeholder={intl.formatMessage(
              {
                id: 'SELECT_SOMETHING',
              },
              {
                label: metadata?.name,
              },
            )}
            onChange={handleDateChange}
            selectedDate={date?.toDate()}
            error={!!dateFeedback}
            testId={`${fieldTestId}-date`}
          />
        </InputField>
      );
    default:
      return null;
  }
};

export default MetadataFields;
