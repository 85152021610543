export class CSSRuleFormatter {
  protected selectors: string[] = [];
  protected attributes: Map<string, string> = new Map();

  static concatRules(...args: string[]) {
    let result = '';
    for (let index = 0; index < args.length; index++) {
      result += args[index];
    }
    return result;
  }

  setAttribute(name: string, value?: string | number | null, unit?: string) {
    if (name != null && value != null) {
      if (unit) {
        this.attributes.set(name, `${value}${unit}`);
      } else {
        this.attributes.set(name, `${value}`);
      }
    }
    return this;
  }

  setSelector(selector: string) {
    this.selectors.push(selector);
    return this;
  }

  getRule() {
    if (!this.selectors.length) {
      return '';
    }
    let attributeString = '';
    this.attributes.forEach((value: string, key: string) => {
      attributeString += `
      ${key}: ${value};`;
    });
    let selectorsString = '';
    for (let index = 0; index < this.selectors.length; index++) {
      if (index === 0 && index === this.selectors.length - 1) {
        selectorsString += `${this.selectors[index]}`;
      } else if (index === this.selectors.length - 1) {
        selectorsString += `${this.selectors[index]}`;
      } else {
        selectorsString += `${this.selectors[index]},
        `;
      }
    }
    return `
    ${selectorsString} {${attributeString}
    }`;
  }
}
