export class TransportError extends Error {
  constructor(message) {
    super(message || 'Transport error');
  }
}

export class NoDocumentProvided extends TransportError {
  constructor() {
    super('No document identification provided');
  }
}

export class ConnectionError extends TransportError {
  constructor(original) {
    super();
    this.original = original;
  }

  get code() {
    return this.original?.data?.code || 500;
  }

  get type() {
    return this.original?.data?.type || 'Gerbil';
  }
}

export class TimeoutError extends TransportError {
  constructor(original) {
    super();
    this.code = 4;
    this.original = original;
  }
}

export class UnknownError extends TransportError {
  constructor(original, reason) {
    super();
    this.code = 5;
    this.reason = reason;
    this.original = original;
  }
}
