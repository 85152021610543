import { forwardRef, Ref } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ELEMENT_TYPES } from 'Editor/services/consts';

import styles from './TrackedActionCard.module.scss';

type TrackedActionContentProps = {
  type?: Editor.TrackedAction['type'];
  content: { type: string; value: string }[];
  contentRef?: Ref<HTMLSpanElement>;
};

export const CONTENT_TEXT_LABELS = {
  INSERT: 'global.add',
  DELETE: 'global.delete',
  REPLACE: 'global.replace',
  FORMAT: 'editor.menu.format.value',
  ADD_PARAGRAPH: 'global.add_paragraph',
  ADD_PAGEBREAK: 'global.add_pagebreak',
  ADD_TABLE: 'global.add_table',
  ADD_IMAGE: 'global.add_image',
  ADD_TABLE_CONTENTS: 'global.add_table_contents',
  ADD_LIST_FIGURES: 'global.add_list_figures',
  ADD_LIST_TABLES: 'global.add_list_tables',
  DELETE_PARAGRAPH: 'global.delete_paragraph',
  REPLACE_WITH_SYNONYM: 'global.replace_with_synonym',
  ADD_PASTED_CONTENT: 'global.add_pasted_content',
  DELETE_FIGURE: 'global.delete_figure',
};

const HANDLED_ACTIONS_ENUM = [
  ELEMENT_TYPES.FigureElement,
  ELEMENT_TYPES.TableElement,
  ELEMENT_TYPES.EquationElement,
  ELEMENT_TYPES.CitationsGroupElement,
  ELEMENT_TYPES.NoteElement,
  ELEMENT_TYPES.Text,
];
const HANDLED_ACTIONS_LABEL: { [x: string]: string } = {
  [ELEMENT_TYPES.FigureElement]: 'FIGURE',
  [ELEMENT_TYPES.TableElement]: 'TABLE',
  [ELEMENT_TYPES.EquationElement]: 'EQUATION',
  [ELEMENT_TYPES.CitationsGroupElement]: 'editor.menu.insert.reference',
  [ELEMENT_TYPES.NoteElement]: 'NOTE',
};

// add support for odler tracked content
const parseTrackedValue = (trackedValue: string | { type: string; value: string }) => {
  let result = {
    type: '',
    value: '',
  };

  if (typeof trackedValue === 'string') {
    switch (trackedValue) {
      case 'Figure':
        result.type = ELEMENT_TYPES.FigureElement;
        break;
      case 'Table':
        result.type = ELEMENT_TYPES.TableElement;
        break;
      case 'Equation':
        result.type = ELEMENT_TYPES.EquationElement;
        break;
      case 'Reference':
        result.type = ELEMENT_TYPES.CitationsGroupElement;
        break;
      case 'Note':
        result.type = ELEMENT_TYPES.NoteElement;
        break;
      default:
        result.type = ELEMENT_TYPES.Text;
        result.value = trackedValue;
        break;
    }
  } else if (typeof trackedValue === 'object') {
    result = trackedValue;
  }

  return result;
};

export const getContentValue = ({
  content,
  type,
}: {
  content?:
    | {
        [key: string]: {
          type: string;
          value: string;
        };
      }
    | string;
  type?: Editor.TrackedAction['type'];
}) => {
  const contentValue: {
    type: string;
    value: string;
  }[] = [];
  const trackInserted = parseTrackedValue((typeof content === 'object' && content?.inserted) || '');
  const trackDeleted = parseTrackedValue((typeof content === 'object' && content?.deleted) || '');

  switch (type) {
    case 'INSERT':
    case 'ADD_PARAGRAPH':
      contentValue.push(trackInserted);
      break;
    case 'DELETE':
      contentValue.push(trackDeleted);
      break;
    case 'REPLACE':
      contentValue.push(trackDeleted);
      contentValue.push(trackInserted);
      break;
    default:
      break;
  }

  return contentValue;
};

const TrackedActionBody = forwardRef(
  ({ type, content }: TrackedActionContentProps, contentRef: Ref<HTMLSpanElement>) => {
    const intl = useIntl();

    const getActionLabel = () => {
      return intl.formatMessage({
        id: type ? CONTENT_TEXT_LABELS[type] : 'global.change',
      });
    };

    const renderSingleAction = (label: string, value: string, type: string) => {
      if (type === ELEMENT_TYPES.Text) {
        return (
          <>
            <span ref={contentRef}>
              <span className={`${styles.text} ${styles.bold}`}>{label}&nbsp;</span>
              <span className={`${styles.text}`}>{value}</span>
            </span>
          </>
        );
      }

      if (HANDLED_ACTIONS_ENUM.some((action) => action === type)) {
        return (
          <span ref={contentRef} className={`${styles.text} ${styles.bold}`}>{`${label} ${intl
            .formatMessage({
              id: HANDLED_ACTIONS_LABEL[type],
            })
            .toLowerCase()}`}</span>
        );
      }

      return (
        <span ref={contentRef}>
          <span className={`${styles.text} ${styles.bold}`}>{label}</span>
        </span>
      );
    };

    const renderReplaceAction = () => {
      return (
        <>
          {renderSingleAction(
            getActionLabel(),
            content[0]?.type === ELEMENT_TYPES.Text ? `"${content[0]?.value}"` : content[0]?.value,
            content[0]?.type,
          )}
          &nbsp;
          <span>
            {renderSingleAction(
              intl.formatMessage({ id: 'global.with' }),
              content[1]?.type === ELEMENT_TYPES.Text
                ? `"${content[1]?.value}"`
                : content[1]?.value,
              content[1]?.type,
            )}
          </span>
        </>
      );
    };

    const renderCardContent = () => {
      if (type === 'INSERT' || type === 'DELETE') {
        const append = content[0]?.value ? ':' : '';
        return renderSingleAction(getActionLabel() + append, content[0]?.value, content[0]?.type);
      }

      if (type === 'REPLACE') {
        return renderReplaceAction();
      }

      return (
        <span className={styles.undefined}>
          <FormattedMessage id="UNDEFINED" />
        </span>
      );
    };

    return <>{renderCardContent()}</>;
  },
);

export default TrackedActionBody;
