export class SelectionHistory {
  private list: {
    flag: string | null;
    ranges: Editor.Selection.RangeData[];
  }[] = [];
  private limit: number | null = null;

  get last() {
    return this.list[this.list.length - 1]?.ranges;
  }

  push(ranges: Editor.Selection.RangeData[]): void {
    this.list.push({
      flag: null,
      ranges,
    });
    if (this.limit !== null && this.list.length >= this.limit) {
      this.list.splice(0, 1);
    }
  }

  flag(flag: string, offset: number = 0): void {
    const index = this.list.length - (1 + offset);
    if (index >= 0 && index < this.list.length) {
      this.list[index].flag = flag;
    }
  }

  get(index: number) {
    return this.list[index]?.ranges || [];
  }

  getLast() {
    return this.last;
  }

  getLastWithFlag(flag?: string) {
    if (!flag) {
      return this.last;
    }
    for (let index = this.list.length - 1; index >= 0; index--) {
      if (this.list[index].flag === flag) {
        return this.list[index].ranges;
      }
    }
    return null;
  }
}
