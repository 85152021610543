import { SectionHeading } from 'dodoc-design-system';
import { useIntl } from 'react-intl';

const DevicesHeading = () => {
  const intl = useIntl();

  return (
    <SectionHeading
      sticky
      margin="3rem 0 0 0"
      title={intl.formatMessage({ id: 'settings.security.devices' })}
      testId="devices-heading"
    />
  );
};

export default DevicesHeading;
