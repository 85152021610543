import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

type EditorPermissionsState = {
  summary: {
    byUser: { [key in UserId]: Permission.Block };
    owners: { [key in UserId]: string };
    users: { [key in UserId]: Permission.Block };
  };
  selected: string[];
  allSelected?: boolean;
};

const SLICE_NAME = 'EDITOR_PERMISSIONS';
const initialState: EditorPermissionsState = {
  summary: {
    byUser: {},
    owners: {},
    users: {},
  },
  selected: [],
  allSelected: false,
};

const PermissionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    cleanPermissionsState: (state) => {
      state.selected = initialState.selected;
      state.allSelected = initialState.allSelected;
    },
    loadedPermissions: (
      state,
      action: PayloadAction<Pick<EditorPermissionsState, 'summary' | 'selected' | 'allSelected'>>,
    ) => {
      const { summary, selected, allSelected } = action.payload;

      state.summary = summary;
      state.selected = selected;
      state.allSelected = allSelected;
    },
    setSelectedPermissions: (state, action: PayloadAction<EditorPermissionsState['selected']>) => {
      state.selected = action.payload;
    },
    deselectAllPermissions: (state) => {
      state.summary = initialState.summary;
      state.selected = initialState.selected;
      state.allSelected = initialState.allSelected;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const {
  cleanPermissionsState,
  loadedPermissions,
  setSelectedPermissions,
  deselectAllPermissions,
} = PermissionsSlice.actions;

export default PermissionsSlice.reducer;
