import { ChangeEvent, ChangeEventHandler, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Toggle, Tooltip } from 'dodoc-design-system';
import { useParams } from 'react-router';

import { useDispatch, usePublicProfile, useSelector } from '_common/hooks';
import { navigateToObject, navigateToSettings, navigateToSpaces } from 'router/history';
import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { importFile, uploadFile } from '../StoragePageSlice';
import { completeAction } from 'App/redux/onboardingSlice';

import { ActionBar, ActionsToggle, FilterPopover, NewElementDropdown } from '_common/components';
import SearchInput from 'Search/pages/SearchPage/SearchInput/SearchInput';
import { NEW_TYPES } from '_common/modals/NewFolderDocumentModal/NewFolderDocumentModal';
import { InteractionController } from '_common/components';

import styles from './Header.module.scss';

const PAGE_IDENTITY = 'storage';

type HeaderProps = {
  hasError?: boolean;
  loading?: boolean;
  setResetScroll: any;
  infoPanelOpen?: boolean;
  handleCloseInfoPanel: () => void;
};

const Header = ({
  hasError,
  loading,
  setResetScroll,
  infoPanelOpen,
  handleCloseInfoPanel,
}: HeaderProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const params: { id: ObjectId; type: ObjectType } = useParams();
  const data = useSelector((state) => state.app.data);
  const selected = Object.keys(useSelector((state) => state.table.selected));
  const current = useSelector((state) => data[state.storage.current ?? '']);
  const permissions = useSelector((state) => state.storage.permissions);
  const currentPage = useSelector((state) => state.app.currentPage);
  const ownerProfile = usePublicProfile(current?.owner);
  const breadcrumb = useSelector((state) => state.storage.breadcrumb);

  const { data: statuses } = useGetElementStatusListQuery();
  const memoStatuses = useMemo(
    () =>
      statuses?.ids
        .filter((id) => statuses.entities[id]?.allow_change)
        .map((id) => ({
          value: `${id}`,
          label: statuses.entities[id]?.name || '',
        })),
    [statuses],
  );
  const { data: currentUser } = useGetCurrentUserQuery();

  const conditionsToEnableNewElements = ['admin', 'owner', 'edit'];

  const getBreadcrumbPath = useCallback(() => {
    if (!current || !currentUser) {
      return [];
    }

    const breadCrumbPath: {
      id: string;
      personal?: boolean;
      name?: string;
    }[] = [
      // Avoid preventExtensions set by immer
      ...breadcrumb.parents.map((parent) => {
        return { ...parent };
      }),
    ];

    breadCrumbPath.push({
      id: current.id,
      name: current.name,
      personal: current.type === 'space' && current.personal,
    });

    if (breadCrumbPath[0].personal && current.owner === currentUser.profile.id) {
      breadCrumbPath[0].name = intl.formatMessage({
        id: 'spaces.personalSpace',
      });
    } else if (currentPage === '/storage/spaces') {
      breadCrumbPath.unshift({
        id: 'spaces',
        name: intl.formatMessage({ id: 'global.space' }),
        personal: false,
      });
    } else if (breadCrumbPath[0].personal && current.owner !== currentUser.profile.id) {
      const user = ownerProfile.name;
      breadCrumbPath[0].name = intl.formatMessage({ id: 'USER_PERSONAL_SPACE_NAME' }, { user });
    }
    return breadCrumbPath;
  }, [currentUser?.profile.id, breadcrumb, currentPage]);

  const handleDisabledShareButton = () => {
    const conditionsToEnableShare = ['admin', 'owner', 'add_permission', 'remove_permission'];

    if (
      selected.length !== 1 ||
      data[selected[0]].status === 'processing' ||
      data[selected[0]].status === 'broken' ||
      hasError ||
      !conditionsToEnableShare.some(
        (permission) =>
          data[selected[0]] && data[selected[0]].user_permissions.includes(permission),
      )
    ) {
      return true;
    }

    return false;
  };

  const handleUploadFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    if (files) {
      const parameterName = () => {
        if (params.type === 'space') {
          return 'space';
        } else return 'parent';
      };

      dispatch(
        uploadFile({
          identity: PAGE_IDENTITY,
          parameters: {
            files: [...Object.values(files)],
            description: '',
            [parameterName()]: params.id,
          },
        }),
      );

      setResetScroll(true);
    }
  };

  const handleImportFile = (e: ChangeEvent<HTMLInputElement>, type: 'dopdf' | 'presentation') => {
    const files = e.target.files;
    if (files) {
      const file = Object.values(files)[0];

      if (file) {
        const location = current.type === 'space' ? { space: params.id } : { parent: params.id };

        dispatch(
          importFile({
            identity: PAGE_IDENTITY,
            parameters: {
              name: file.name.substring(0, file.name.lastIndexOf('.')),
              description: '',
              ...location,
              file,
            },
            objectType: type,
          }),
        );
      }

      setResetScroll(true);
    }
  };

  const handleImportContentFromFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files;

    if (file) {
      dispatch(
        openAndUpdateModal({
          modal: 'NewFolderDocumentModal',
          data: {
            id: params.id,
            newType: NEW_TYPES.DOCUMENT,
            file: file[0],
            current,
            identity: PAGE_IDENTITY,
          },
        }),
      );
    }
  };

  // Action bar onClick functions
  const onBreadcrumbElementClick = (element: {
    id: string;
    name?: string;
    type?: 'folder' | 'space';
  }) => {
    if (element.id === 'spaces') {
      navigateToSpaces();
    } else {
      navigateToObject(element.type || 'space', element.id);
    }
  };

  // ----------------------------------------------------------
  // ---------------------- Navigation ------------------------
  const handleGoToSettings = () => {
    let type;
    let id;

    if (selected.length === 1) {
      const selectedObject = data[selected[0]];
      type = selectedObject.type;
      id = selectedObject.id;
    } else {
      type = current.type;
      id = current.id;
    }

    navigateToSettings(type, id);
  };

  // ----------------------------------------------------------
  // ------------------------ Modals --------------------------
  const handleOpenNewDocumentModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'NewFolderDocumentModal',
        data: {
          id: params.id,
          newType: NEW_TYPES.DOCUMENT,
          file: {},
          current,
          identity: PAGE_IDENTITY,
        },
      }),
    );
  };

  const handleOpenNewFolderModal = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'NewFolderDocumentModal',
        data: {
          id: params.id,
          newType: NEW_TYPES.FOLDER,
          file: {},
          current,
          identity: PAGE_IDENTITY,
        },
      }),
    );
  };

  // Share modal
  const handleShareClicked = () => {
    if (selected.length === 1) {
      dispatch(
        openAndUpdateModal({
          modal: 'ShareModal',
          data: {
            view: 'users',
            objectId: selected[0],
            objectType: data[selected[0]].type,
            editor: false,
          },
        }),
      );
      dispatch(completeAction('explorer_document_share_opened'));
    }
  };

  return (
    <ActionBar>
      {current.id && (
        <ActionBar.Breadcrumb
          path={getBreadcrumbPath()}
          onBreadcrumbElementClick={onBreadcrumbElementClick}
          pageLoading={loading}
        />
      )}
      <div className={styles.innerContainer}>
        <NewElementDropdown
          disabled={
            loading ||
            hasError ||
            current.status === 'approved' ||
            !conditionsToEnableNewElements.some(
              (permission) =>
                current.user_permissions && current.user_permissions.includes(permission),
            ) ||
            (permissions && !permissions.document && !permissions.file && !permissions.folder)
          }
          handleFileUploadChange={handleUploadFile}
          handleImportContentFromFile={handleImportContentFromFile}
          openNewFolderModal={handleOpenNewFolderModal}
          openNewDocumentModal={handleOpenNewDocumentModal}
          handleImportFile={handleImportFile}
        />
        <InteractionController environment="explorer">
          <Toggle
            size="medium"
            variant="standard"
            onClick={handleGoToSettings}
            icon="Settings"
            disabled={selected.length !== 1 || hasError}
            margin="0 0 0 0.5rem"
            testId="storage-page-settings-button"
          >
            <FormattedMessage id="global.settings" />
          </Toggle>
        </InteractionController>
        <InteractionController
          environment="explorer"
          rules={[
            {
              interaction: 'explorer_my_files_action_bar_share',
              actions: ['explorer_document_share_opened'],
            },
          ]}
        >
          <Toggle
            size="medium"
            variant="standard"
            onClick={handleShareClicked}
            disabled={handleDisabledShareButton()}
            icon="Share"
            margin="0 0 0 0.5rem"
            testId="storage-page-share-button"
          >
            <FormattedMessage id="storage.actionBar.actions.share" />
          </Toggle>
        </InteractionController>
        <InteractionController environment="explorer">
          <ActionsToggle
            disabled={selected.length === 0 || hasError}
            selected={selected}
            current={current}
            identity={PAGE_IDENTITY}
          />
          <div style={{ marginLeft: '10rem', marginRight: '0.5rem' }}>
            <Tooltip
              placement="bottom"
              content={intl.formatMessage({ id: 'global.information' })}
              testId="storage-page-information-tooltip"
            >
              <Toggle
                size="medium"
                variant="standard"
                icon="Information"
                isToggled={infoPanelOpen}
                onClick={handleCloseInfoPanel}
                disabled={loading || hasError}
                testId="storage-page-information-toggle"
              />
            </Tooltip>
          </div>

          <FilterPopover
            identity="storage"
            popperSettings={{
              placement: 'bottom-end',
              skidding: 0,
              distance: 20,
            }}
            /*testId="storage-page-filters-toggle"*/
            status={{ options: memoStatuses, settings: { placeholder: 'SELECT_ELEMENT_STATUS' } }}
            objectType={{
              options: [
                {
                  value: 'folder',
                  label: 'storage.actionBar.filters.folder',
                },
                {
                  value: 'file',
                  label: 'storage.actionBar.filters.file',
                },
                {
                  value: 'document',
                  label: 'storage.actionBar.filters.document',
                },
                {
                  value: 'dopdf',
                  label: 'doPDF',
                },
                {
                  value: 'presentation',
                  label: 'doPPT',
                },
              ],
              settings: { placeholder: 'SELECT_OBJECT_TYPE' },
            }}
          />
          <SearchInput
            disabled={loading || hasError}
            current={current}
            style={{ marginLeft: '2rem' }}
          />
        </InteractionController>
      </div>
    </ActionBar>
  );
};

export default Header;
