import { RefObject } from 'react';
import NoteOverlay from './NoteOverlay';
import Overlay from './Overlay';

import { useSelector } from '_common/hooks';
import { selectFilteredComments } from 'Editor/redux/CommentsSlice';

import styles from './EditorOverlay.module.scss';

type EditorOverlayProps = {
  editorInputRef: RefObject<HTMLDivElement>;
};

const EditorOverlay = ({ editorInputRef }: EditorOverlayProps) => {
  const comments = useSelector(selectFilteredComments);
  const operation = useSelector((state) => state.editor.tasks.overlay.operation);
  const active = useSelector((state) => state.onboarding.active);
  const started = useSelector((state) => state.onboarding.started);
  const renderAbove = useSelector((state) => state.onboarding.renderAbove);
  return (
    <div
      className={`${styles.root} ${
        !renderAbove &&
        (active.editor || started.editor) &&
        (comments.insert.inserting || operation === 'create' || operation === 'edit') &&
        styles.overlay
      }`}
      data-testid="editor-overlay-container"
    >
      <NoteOverlay />
      <Overlay editorInputRef={editorInputRef} />
    </div>
  );
};

export default EditorOverlay;
