import { DoDOCRange } from './DoDOCRange';

export class SelectionUtils {
  static getRange(): DoDOCRange | undefined {
    const selection = SelectionUtils.getSelection();
    if (selection && selection.type !== 'None' && selection.rangeCount > 0) {
      return DoDOCRange.fromNativeRange(selection.getRangeAt(0));
    }
    return undefined;
  }

  static getSelection() {
    return window.getSelection();
  }

  static applyRangeToSelection(range: Range) {
    const selection = SelectionUtils.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  static isSelctionBackwards(selection: Selection | null) {
    let isBackwards: boolean = false;
    if (selection) {
      const range = selection.getRangeAt(0);
      if (
        selection.focusNode === range.startContainer &&
        selection.focusOffset === range.startOffset
      ) {
        isBackwards = true;
      }
    }

    return isBackwards;
  }

  static isSelectionCollapsed(range = SelectionUtils.getRange()) {
    return !!range?.collapsed;
  }
}
