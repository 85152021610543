import { IntlErrorBoundary } from '_common/components';
import { Details } from '_common/suite/components';

const DetailsPanel = () => {
  return (
    <IntlErrorBoundary size="medium" margin="16rem 0 0 0">
      <Details />
    </IntlErrorBoundary>
  );
};

export default DetailsPanel;
