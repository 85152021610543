import { Popover, Toggle, usePopper } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';
import { selectIsPageLayout, selectReadOnlyMode } from 'Editor/redux/EditorStatusSlice';

import styles from './Alignment.module.scss';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

const Alignment = () => {
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const isImageSelected = useSelector((state) => state.editor.status.selection.IMAGE);
  const selectedStyle = useSelector((state) => state.editor.toolbar.alignment);

  const { isOpen, popperProps, referenceProps } = usePopper({
    disabled: isReadOnlyMode,
  });

  // TODO: Set style type given by RT once provided
  const handleJustifyContent = (style: any) => {
    EditorManager.getInstance().alignCurrentSelection({ style });
  };

  const getToggledIcon = (): IconTypes['24'] => {
    switch (selectedStyle) {
      case 'c':
        return 'CenterGrey';
      case 'r':
        return 'RightGrey';
      case 'j':
        return 'JustifyGrey';
      default:
        return 'LeftGrey';
    }
  };

  return (
    <>
      <Toggle
        size="medium"
        variant="ghost"
        isToggled={isOpen}
        icon={getToggledIcon()}
        dropdown
        disabled={isReadOnlyMode || isPageLayout}
        testId="alignment-toggle"
        {...referenceProps}
      />

      <Popover {...popperProps} testId="alignment-popper">
        <div className={styles.popoverRoot}>
          <Toggle
            size="medium"
            variant="link"
            icon="LeftGrey"
            isToggled={selectedStyle === 'l'}
            onClick={() => handleJustifyContent('left')}
            testId="alignment-left-toggle"
          />
          <Toggle
            size="medium"
            variant="link"
            icon="CenterGrey"
            isToggled={selectedStyle === 'c'}
            onClick={() => handleJustifyContent('center')}
            testId="alignment-center-toggle"
          />
          <Toggle
            size="medium"
            variant="link"
            icon="RightGrey"
            isToggled={selectedStyle === 'r'}
            onClick={() => handleJustifyContent('right')}
            testId="alignment-right-toggle"
          />
          {!isImageSelected && (
            <Toggle
              size="medium"
              variant="link"
              icon="JustifyGrey"
              isToggled={selectedStyle === 'j'}
              onClick={() => handleJustifyContent('justify')}
              testId="alignment-justify-toggle"
            />
          )}
        </div>
      </Popover>
    </>
  );
};

export default Alignment;
