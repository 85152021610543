import { SVGProps, useEffect } from 'react';
import { useSlideData } from './SlideData';

type GradientDefsProps = {
  id: string;
  gradientFill: Presentation.Data.Common.GradientFillType;
  forShape?: boolean;
  shadeToTitle?: boolean;
};

const GradientDefinition = ({ id, gradientFill, forShape, shadeToTitle }: GradientDefsProps) => {
  const { color, addUnsupportedElement } = useSlideData();
  const stops = [...gradientFill.stops].sort((a, b) => a.pos - b.pos);
  const deg = (gradientFill?.linear?.angle || gradientFill?.angle) ?? 0;

  useEffect(() => {
    //Property needs to exist and be true
    if (gradientFill.rotateWithShape !== undefined && !gradientFill.rotateWithShape) {
      //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:GRADIENT:NOROTATE
      addUnsupportedElement("Gradient Fill - Don't rotate with shape");
    }

    switch (gradientFill.path?.type) {
      case 'rect':
        //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:GRADIENT:RECT
        addUnsupportedElement('Gradient Fill - Rectangle');
        break;
      case 'shape':
        shadeToTitle
          ? //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:GRADIENT:SHAPE
            addUnsupportedElement('Gradient Fill - Shade from Title')
          : //TODO:PRESENTATION:UNSUPPORTED:SLIDE:FILL:GRADIENT:SHAPE
            addUnsupportedElement('Gradient Fill - Path');
        break;
    }
  }, []);

  if (gradientFill.path?.type === 'circle') {
    const fillToRect: Required<Presentation.Data.Common.FillToRect> = {
      t: gradientFill.path.fillToRect?.t || 0,
      r: gradientFill.path.fillToRect?.r || 0,
      b: gradientFill.path.fillToRect?.b || 0,
      l: gradientFill.path.fillToRect?.l || 0,
    };

    const endCircle: Pick<SVGProps<SVGRadialGradientElement>, 'cx' | 'cy'> | null = gradientFill
      .path.fillToRect
      ? {
          cx: fillToRect.l ?? 100 - fillToRect.r,
          cy: fillToRect.t ?? 100 - fillToRect.b,
        }
      : null;

    return (
      <radialGradient
        id={id}
        gradientTransform={`rotate(${deg} 0.5 0.5)`}
        cx={`${endCircle?.cx}%`}
        cy={`${endCircle?.cy}%`}
        r="100%"
        gradientUnits={forShape ? undefined : 'userSpaceOnUse'}
      >
        {stops.map((stop, index) => {
          return <stop key={index} offset={`${stop.pos}%`} stopColor={color(stop.color)} />;
        })}
      </radialGradient>
    );
  }

  /**
   * SVG does not support Rectangle, Path and ShadeFromTitle gradients
   * Render as linear gradient
   */
  return (
    <linearGradient id={id} gradientTransform={`rotate(${deg} 0.5 0.5)`}>
      {stops.map((stop, index) => {
        return <stop key={index} offset={`${stop.pos}%`} stopColor={color(stop.color)} />;
      })}
    </linearGradient>
  );
};

export default GradientDefinition;
