import { Mixin } from 'mixwith';
import { ELEMENTS } from 'Editor/services/consts';
import { ReduxInterface } from 'Editor/services';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class BackspaceHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * @description selects a function to handle backspace based on baseNode
       * @param {ActionContext} actionContext
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnCollapsedSelection(actionContext, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        if (baseNode) {
          if (anchorNode) {
            if (
              baseNode.tagName === ELEMENTS.ParagraphElement.TAG &&
              this.dataManager.numbering.isListElement(baseNode.id)
            ) {
              // SELECTION IS A LIST
              this.handleBackspaceOnListElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
              EditorSelectionUtils.fixCollapsedTextSelection({
                forceWrapAsText: true,
                isBackspace: true,
              });
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;

              // SELECTION IS A DEFAULT TEXT ELEMENT
              const selectionCheck = EditorDOMUtils.closest(
                anchorNode,
                EditorDOMElements.BLOCK_TEXT_ELEMENTS,
              );
              this.handleBackspaceOnTextElement(
                actionContext,
                baseNode,
                selectionCheck,
                anchorNode,
                anchorOffset,
              );
            } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableElementBackspace(actionContext, baseNode);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleBackspaceOnFigureElement(
                actionContext,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleBackspaceOnTableElement(actionContext, baseNode, anchorNode, anchorOffset);
            } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
              this.handleBackspaceOnContainerElement(
                actionContext,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      /**
       * @description handle backspace on multi selection
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       */
      handleBackspaceOnMultiSelection(actionContext, level0Node) {
        if (level0Node && level0Node.tagName === ELEMENTS.TableElement.TAG) {
          this.removeSelectionContent(actionContext);
          EditorSelectionUtils.collapseToStart();
        } else {
          this.joinSelectionContent(actionContext);
        }
      }

      /**
       * handle backspace event on container element
       * @param {ActionContext} actionContext
       * @param {Node} listNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnContainerElement(actionContext, baseNode, anchorNode, anchorOffset) {
        if (baseNode === anchorNode) {
          if (anchorOffset === anchorNode.childNodes.length) {
            EditorSelectionUtils.selectNode(anchorNode);
            return;
          } else if (anchorOffset === 0) {
            if (baseNode.previousSibling) {
              EditorSelectionUtils.setCaret(baseNode.previousSibling, 'INSIDE_END');
              return;
            }
          } else {
            if (EditorSelectionUtils.fixSelection()) {
              const selection = EditorSelectionUtils.getSelection();
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
            }
          }
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleBackspaceOnCollapsedSelection(
            actionContext,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle backspace event on list elements
       * @param {ActionContext} actionContext
       * @param {Node} listNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnListElement(actionContext, listNode, anchorNode, anchorOffset) {
        if (EditorSelectionUtils.isSelectionAtStart(listNode)) {
          this.stylesHandler.removeListStyleFromBlock(actionContext, listNode);
        } else {
          this.handleBackspaceOnTextElement(
            actionContext,
            listNode,
            listNode,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle backspace event on table element
       * @param {ActionContext} actionContext
       * @param {Node} tableNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnTableElement(actionContext, tableNode, anchorNode, anchorOffset) {
        const closest = EditorDOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);

        if (anchorNode.tagName === ELEMENTS.TableElement.TAG) {
          if (anchorOffset === anchorNode.childNodes.length) {
            EditorSelectionUtils.selectLevel0Node(anchorNode);
          } else if (anchorOffset === 0) {
            this._handleBackspaceAtElementStart(actionContext, anchorNode);
          }
        } else if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = EditorDOMUtils.findFirstLevelChildNode(closest, anchorNode);
            if (tdLevel0Node) {
              if (tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
                this.handleBackspaceOnCollapsedSelection(
                  actionContext,
                  tdLevel0Node,
                  anchorNode,
                  anchorOffset,
                );
              } else if (tdLevel0Node !== tableNode) {
                EditorSelectionUtils.selectNode(tdLevel0Node);
              }
            }
          }
        }
      }

      /**
       * handle backspace event on a figure element
       * @param {ActionContext} actionContext
       * @param {Node} figureNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnFigureElement(actionContext, figureNode, anchorNode, anchorOffset) {
        const closest = EditorDOMUtils.closest(anchorNode, ['FIGCAPTION', 'IMAGE-ELEMENT']);
        if (closest) {
          if (closest.tagName === 'IMAGE-ELEMENT') {
            if (
              (closest === anchorNode && anchorOffset === closest.childNodes.length) ||
              (closest !== anchorNode && !anchorNode.nextSibling)
            ) {
              EditorSelectionUtils.selectLevel0Node(figureNode);
            } else if (
              (closest === anchorNode && anchorOffset === 0) ||
              (closest !== anchorNode && !anchorNode.previousSibling)
            ) {
              this._handleBackspaceAtElementStart(actionContext, figureNode);
            }
          }
        } else if (
          anchorNode.tagName === 'IMG' ||
          anchorNode.tagName === ELEMENTS.FigureElement.TAG
        ) {
          if (anchorOffset === anchorNode.childNodes.length) {
            EditorSelectionUtils.selectLevel0Node(figureNode);
          } else if (anchorOffset === 0) {
            this._handleBackspaceAtElementStart(actionContext, figureNode);
          }
        }
      }

      /**
       * handle backsapce event on a default text element
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       * @param {Node} textElementNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleBackspaceOnTextElement(
        actionContext,
        level0Node,
        textElementNode,
        anchorNode,
        anchorOffset,
      ) {
        if (textElementNode) {
          // fix when selection is after suggestion paragraph marker
          if (
            EditorSelectionUtils.isSelectionAtEnd(textElementNode) &&
            (this.isAddParagraphMarker(anchorNode) || this.isDeleteParagraphMarker(anchorNode))
          ) {
            if (anchorNode.previousSibling) {
              EditorSelectionUtils.setCaret(anchorNode.previousSibling, 'INSIDE_END');
            } else {
              EditorSelectionUtils.setCaret(anchorNode, 'PRE');
            }
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }

          if (EditorSelectionUtils.isSelectionAtStart(textElementNode)) {
            // selection is at start
            this._handleBackspaceAtElementStart(actionContext, level0Node, textElementNode);
          } else {
            // selection is at mid or end

            const closest = EditorDOMUtils.closest(
              anchorNode,
              EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
            );

            let previousClosest;
            if (!closest) {
              let previousSibling;

              if (EditorSelectionUtils.isSelectionAtStart(anchorNode)) {
                previousSibling = anchorNode.previousSibling;
              } else if (!(anchorNode instanceof Text)) {
                previousSibling = anchorNode.childNodes[anchorOffset - 1];
              }

              if (previousSibling) {
                if (
                  previousSibling.nodeType === Node.ELEMENT_NODE &&
                  (previousSibling.tagName === ELEMENTS.TrackInsertElement.TAG ||
                    previousSibling.tagName === ELEMENTS.TrackDeleteElement.TAG) &&
                  previousSibling.childNodes.length > 0
                ) {
                  previousClosest = EditorDOMUtils.closest(previousSibling.lastChild, [
                    ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                } else {
                  previousClosest = EditorDOMUtils.closest(previousSibling, [
                    ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
                    ELEMENTS.FieldElement.TAG,
                  ]);
                }
              }
            }

            const nonEditableElement = closest || previousClosest;

            // handle non-editable text elements
            // citation-element, etc
            if (nonEditableElement) {
              if (nonEditableElement.tagName === ELEMENTS.CitationElement.TAG) {
                const citationGroup = EditorDOMUtils.closest(
                  nonEditableElement,
                  ELEMENTS.CitationsGroupElement.TAG,
                );
                let elementToHandle = nonEditableElement;

                if (
                  citationGroup &&
                  citationGroup.getCitationsCount &&
                  citationGroup.getCitationsCount() <= 1
                ) {
                  // if parent citatio group has only one child
                  citationGroup.remove();
                  actionContext.addChangeUpdatedNode(level0Node);
                } else {
                  // check if is inserted or deleted citation from multiple users
                  while (
                    EditorDOMUtils.parentContainsNode(citationGroup, elementToHandle.parentNode)
                  ) {
                    if (
                      elementToHandle.tagName === ELEMENTS.TrackInsertElement.TAG ||
                      elementToHandle.tagName === ELEMENTS.TrackDeleteElement.TAG
                    ) {
                      actionContext.addReferenceToRefresh(
                        elementToHandle.getAttribute('element_reference'),
                      );
                    }

                    elementToHandle = elementToHandle.parentNode;
                  }

                  if (elementToHandle.previousSibling) {
                    EditorSelectionUtils.setCaret(elementToHandle.previousSibling, 'INSIDE_END');
                  } else {
                    EditorSelectionUtils.setCaret(elementToHandle, 'PRE');
                  }
                  elementToHandle.remove();
                  actionContext.addChangeUpdatedNode(level0Node);
                }
              } else if (nonEditableElement.tagName === ELEMENTS.CitationsGroupElement.TAG) {
                EditorSelectionUtils.selectNode(nonEditableElement);
              } else if (
                nonEditableElement.tagName === ELEMENTS.EquationElement.TAG ||
                nonEditableElement.tagName === ELEMENTS.FieldElement.TAG
              ) {
                nonEditableElement.selected = true;
                EditorSelectionUtils.selectNode(nonEditableElement);

                // trigger confirmation modal
                if (nonEditableElement.isTypeCaption()) {
                  ReduxInterface.openDeleteCaptionConfirmationModal();
                }
              } else {
                EditorSelectionUtils.selectNode(nonEditableElement);
                this.joinSelectionContent(actionContext);
              }
            } else {
              // handle normal characters
              this.selectionManager.modifySelection('expand', 'backward', 'character');
              this.joinSelectionContent(actionContext);
            }
          }
        }
      }

      /**
       * handle backspace on a non-editable element
       * @param {ActionContext} actionContext
       * @param {Node} level0Node
       */
      handleNonEditableElementBackspace(actionContext, level0Node) {
        if (this.hasEditPermissions()) {
          const contentEditable = level0Node && level0Node.selectableContent;

          if (contentEditable) {
            if (EditorSelectionUtils.isSelectionAtStart(contentEditable)) {
              this._handleBackspaceAtElementStart(actionContext, level0Node);
            } else if (EditorSelectionUtils.isSelectionAtEnd(contentEditable)) {
              if (EditorDOMUtils.isBlockNodeDeletable(level0Node)) {
                if (level0Node.parentNode === EditorDOMUtils.getContentContainer()) {
                  if (level0Node.previousSibling) {
                    const nodeToSelect = level0Node.previousSibling.selectableContent
                      ? level0Node.previousSibling.selectableContent
                      : level0Node.previousSibling;
                    EditorSelectionUtils.setCaret(nodeToSelect, 'INSIDE_END');
                    this.removeBlockElementFromDOM(actionContext, level0Node);
                  } else {
                    const data = NodeDataBuilder.buildParagraph({
                      parent_id: level0Node.parentNode.id,
                    });

                    this.inserBlockNodeOperation(actionContext, data, level0Node, 'BEFORE');

                    this.removeBlockElementFromDOM(actionContext, level0Node);

                    EditorSelectionUtils.setCaret(
                      EditorDOMUtils.getBlockNode(data.id),
                      'INSIDE_END',
                    );
                  }
                } else if (level0Node.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG) {
                  actionContext.addReferenceToRefresh(
                    level0Node.parentNode.getAttribute('element_reference'),
                  );
                  this.removeBlockElementFromDOM(actionContext, level0Node.parentNode);
                }
              }
            }
          }
        }
      }

      _handleBackspaceAtElementStart(actionContext, level0Node, textElementNode) {
        let previousSibling;

        if (textElementNode?.previousSibling || level0Node.previousSibling) {
          previousSibling = textElementNode
            ? textElementNode.previousSibling
            : level0Node.previousSibling;
        } else if (
          EditorDOMElements.isNodeContainerElement(level0Node.parentNode) &&
          level0Node.parentNode.tagName !== ELEMENTS.TableCellElement.TAG
        ) {
          previousSibling = EditorDOMUtils.findPreviousAncestorSibling(level0Node);
        }

        if (previousSibling) {
          if (!EditorDOMUtils.isBlockNodeDeletable(previousSibling)) {
            // previous sibling is non-deletable
            if (EditorDOMUtils.isEmptyElement(level0Node) && previousSibling.selectableContent) {
              EditorSelectionUtils.setCaret(previousSibling.selectableContent, 'INSIDE_END');
              this.removeBlockElementFromDOM(actionContext, level0Node);
            } else {
              EditorSelectionUtils.selectNodeContents(level0Node);
            }
          } else if (!EditorDOMUtils.isClosestBlockNodeEditable(previousSibling)) {
            EditorSelectionUtils.selectNode(previousSibling);
          } else if (
            previousSibling.tagName === ELEMENTS.FigureElement.TAG ||
            previousSibling.tagName === ELEMENTS.TableElement.TAG ||
            EditorDOMElements.isNodeContainerElement(previousSibling)
          ) {
            EditorSelectionUtils.selectNode(previousSibling);
          } else if (EditorDOMUtils.isClosestTextElementEditable(textElementNode || level0Node)) {
            // previous sibling is a default text element

            // eslint-disable-next-line no-lonely-if
            if (
              EditorDOMUtils.isEmptyElement(previousSibling) &&
              !this.isAddParagraphMarker(previousSibling.lastChild) &&
              !this.dataManager.numbering.isListElement(previousSibling.id)
            ) {
              this.removeBlockElementFromDOM(actionContext, previousSibling);
            } else if (
              EditorDOMElements.INLINE_LAST_CHILD_ELEMENTS.includes(
                previousSibling.lastChild.tagName,
              )
            ) {
              EditorSelectionUtils.selectNode(previousSibling.lastChild);
            } else {
              this.selectionManager.setRangeStart(
                previousSibling,
                'INDEX',
                previousSibling.childNodes.length,
              );
              this.selectionManager.setRangeEnd(textElementNode, 'INDEX', 0);
              this.joinSelectionContent(actionContext);
            }
          }
        }
      }
    },
);
