import { Mixin } from 'mixwith';
import { ElementNodeBuilder } from 'Editor/services/Model';

export default Mixin(
  (superclass) =>
    class extends superclass {
      parsePageNode(representation) {
        const builder = new ElementNodeBuilder();
        if (representation.childNodes !== null && representation.childNodes.length) {
          Array.from(representation.childNodes).forEach(async (child) => {
            builder.addChild(this.parseElement(child));
          });
        }
        /* if (representation.attributes.parent_id) {
          builder.addParentId(representation.attributes.parent_id.nodeValue);
        }
        if (representation.attributes.id) {
          builder.addId(representation.attributes.id.nodeValue);
        } */
        return builder.getNode();
      }
    },
);
