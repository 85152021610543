import { FormattedMessage } from 'react-intl';

import styles from './NotesNumber.module.scss';

type NotesNumberProps = { searchValue: string; number: number; type: string };

const NotesNumber = ({ searchValue, number, type }: NotesNumberProps) => {
  const getIntl = () => {
    if (type === 'footnotes') {
      return searchValue === '' ? 'FOOTNOTES_IN_DOCUMENT' : 'FOOTNOTES_FOUND_IN_DOCUMENT';
    }
    return searchValue === '' ? 'ENDNOTES_IN_DOCUMENT' : 'ENDNOTES_FOUND_IN_DOCUMENT';
  };
  return (
    <div className={styles.search_results}>
      <FormattedMessage id={getIntl()} values={{ number, search_value: searchValue }} />
    </div>
  );
};

export default NotesNumber;
