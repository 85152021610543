import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import styles from './OpenHyperlinkModal.module.scss';

const MODAL = 'OpenHyperlinkModal';

const OpenHyperlinkModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const url = useSelector((state) => state.modals[MODAL].url);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const handleSubmit = () => {
    try {
      window.open(url, '_blank');
    } catch (_) {
      notify({
        type: 'error',
        title: 'COULD_NOT_OPEN_HYPERLINK',
        message: 'HYPERLINK_COULD_NOT_BE_OPENED_DUE_TO_AN_ERROR',
      });
    }
    close();
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="60rem" testId="open-hyperlink">
      <Modal.Header onClose={close}>
        <FormattedMessage id="LEAVING_DODOC" />
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="YOU_ARE_ABOUT_TO_LEAVE_DODOC_AND_GO_TO" />
        <div className={styles.url}>{url}</div>
        <FormattedMessage id="ARE_YOU_SURE" />
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="open-hyperlink-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button size="medium" variant="primary" onClick={handleSubmit} testId="open-hyperlink-modal-submit-button">
          <FormattedMessage id="OPEN_HYPERLINK" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OpenHyperlinkModal;
