import { BaseTypedEmitter } from '_common/services/Realtime';

export class PageVisibility extends BaseTypedEmitter<Editor.PageVisibility.EventTypes> {
  private static instance: PageVisibility;
  private visible: boolean;
  private hidden: string = 'hidden';
  private visibilityChange: string = 'visibilitychange';
  constructor() {
    super();
    this.visible = true;
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleWindowFocus = this.handleWindowFocus.bind(this);
    this.handleWindowBlur = this.handleWindowBlur.bind(this);
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = 'hidden';
      this.visibilityChange = 'visibilitychange';
      //@ts-expect-error
    } else if (typeof document.msHidden !== 'undefined') {
      this.hidden = 'msHidden';
      this.visibilityChange = 'msvisibilitychange';
      //@ts-expect-error
    } else if (typeof document.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden';
      this.visibilityChange = 'webkitvisibilitychange';
    }
    this.startVisibilityChecks();
  }

  static getInstance() {
    if (!PageVisibility.instance) {
      PageVisibility.instance = new PageVisibility();
    }
    return PageVisibility.instance;
  }

  start() {
    this.startVisibilityChecks();
  }

  private startVisibilityChecks() {
    if (
      typeof document.addEventListener === 'undefined' ||
      typeof document.hidden === 'undefined'
    ) {
      throw new Error('Page Visibility requires a browser that supports the Page Visibility API.');
    } else {
      // Handle page visibility change
      document.addEventListener(this.visibilityChange, this.handleVisibilityChange, false);
      window.addEventListener('focus', this.handleWindowFocus);
      window.addEventListener('blur', this.handleWindowBlur);
    }
  }

  private handleWindowFocus() {
    this.emit('WINDOW_FOCUS');
  }

  private handleWindowBlur() {
    this.emit('WINDOW_HIDDEN');
  }

  private handleVisibilityChange() {
    //@ts-expect-error
    if (document[this.hidden]) {
      this.emit('TAB_HIDDEN');
      this.visible = false;
    } else {
      this.emit('TAB_FOCUS');
      this.visible = true;
    }
  }

  stopEventsManager() {
    document.removeEventListener(this.visibilityChange, this.handleVisibilityChange, false);
    window.removeEventListener('focus', this.handleWindowFocus);
    window.removeEventListener('blur', this.handleWindowBlur);
    this.removeAllEventEmitterListeners();
  }

  destroy() {
    this.stopEventsManager();
  }

  isVisible() {
    return this.visible;
  }
}
