import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const SLICE_NAME = 'TabMenu';

export type TabMenuIdentity =
  | 'userSettingsPage'
  | 'citationsManager'
  | 'TemplateGallery'
  | 'objectSettingsPage_space_personal'
  | 'objectSettingsPage_space'
  | 'objectSettingsPage_folder'
  | 'objectSettingsPage_document'
  | 'objectSettingsPage_file'
  | 'documentDetails'
  | 'groupSettings'
  | 'roleSettings'
  | 'userTokenHistory'
  | 'pageSetup'
  | 'tableProperties';

const INITIAL_STATE: { [key in TabMenuIdentity]?: string | number } = {};

// #region Slice
const TabMenuSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedTab: (
      state,
      action: PayloadAction<{ menuId: TabMenuIdentity; selectedTab: string | number }>,
    ) => {
      const { menuId, selectedTab } = action.payload;

      state[menuId] = selectedTab;
    },
  },
});

const persistConfig = {
  key: 'tabMenu',
  storage: storageSession,
};
const tabMenuReducer = persistReducer(persistConfig, TabMenuSlice.reducer);

export default tabMenuReducer;
// #endregion

// #region Actions
export const { setSelectedTab } = TabMenuSlice.actions;
// #endregion
