//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { FigureElement, ImageElement } from 'Editor/services/VisualizerManager';
import { ReduxInterface, UploadManager } from 'Editor/services';
import { Logger } from '_common/services';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { ELEMENTS } from 'Editor/services/consts';
import ActionContext from '../models/ActionContext';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

export default Mixin(
  (superclass: any) =>
    class ImagesEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      private uploadImage(
        image: File,
        callback: Parameters<typeof ReduxInterface.saveImage>[0]['callback'],
      ) {
        if (UploadManager.acceptImageFileSize(image)) {
          ReduxInterface.saveImage({
            params: {
              id: this.dataManager.document.getDocumentId(),
              image,
            },
            callback,
          });
        } else {
          throw new Error('Invalid image size!');
        }
      }

      handleInsertImage(image: File) {
        try {
          this.uploadImage(image, (imageData) => {
            const image = this.visualizerManager.getViewFactory().get({
              type: ELEMENTS.ImageElement.ELEMENT_TYPE,
            });

            image.setImageSource(imageData.reference);
            image.setImageDimensions(imageData.dimensions.width, imageData.dimensions.height);

            const range = EditorSelectionUtils.getRange();

            if (range) {
              const closestText = EditorDOMUtils.isClosestTextElementEditable(range.startContainer);

              if (closestText) {
                this.handleInsertInlineNode(image);
              } else {
                const paragraph = this.visualizerManager.getViewFactory().get({
                  type: ELEMENTS.ParagraphElement.ELEMENT_TYPE,
                });
                EditorDOMUtils.appendNode(paragraph, image);
                this.handleInsertBlockNode(paragraph);
              }
            }
          });
        } catch (error) {
          Logger.captureException(error);
        }
      }

      handleChangeImage(image: File) {
        if (this.navigationManager.isMarkerRendered()) {
          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            let range = EditorSelectionUtils.getRange();

            let blockElement: Node | null;
            let imageElement: Node | null;
            let sameUserSuggestion: boolean = false;

            if (range) {
              imageElement = EditorDOMUtils.closest(range.startContainer, [
                ELEMENTS.FigureElement.TAG,
                ELEMENTS.ImageElement.TAG,
              ]);
              blockElement = EditorDOMUtils.findFirstLevelChildNode(this.page, imageElement);

              let closestSuggestion = EditorDOMUtils.closest(imageElement, [
                ELEMENTS.TrackInsertElement.TAG,
                ELEMENTS.TrackDeleteElement.TAG,
              ]);

              sameUserSuggestion =
                closestSuggestion !== null && this.isUserAuthor(closestSuggestion);
            }

            if (ReduxInterface.isEditorTrackingStateOn() && !sameUserSuggestion) {
              // track changes
              this.handleInsertImage(image);
            } else {
              this.uploadImage(image, (imageData) => {
                if (this.navigationManager.isMarkerRendered()) {
                  try {
                    if (blockElement && imageElement) {
                      let actionContext = new ActionContext();

                      this.updateImageSourceOperation(
                        blockElement,
                        imageElement,
                        imageData.reference,
                        imageData.dimensions.height,
                        imageData.dimensions.width,
                      );

                      actionContext.jsonChanges = true;

                      this.changeTracker.saveActionChanges(actionContext);
                    }
                  } catch (error) {
                    Logger.captureException(error);
                  }
                }
              });
            }
          } catch (error) {
            Logger.captureException(error);
          }
        }
      }

      handleUpdateImageSize(image: FigureElement | ImageElement, height: number, width: number) {
        if (!this.askUserAboutThis()) {
          return false;
        }

        let actionContext = new ActionContext();
        if (image && EditorDOMUtils.isClosestBlockNodeEditable(image)) {
          this.clipboard.removePasteOptions();
          let level0Node;
          if (image.parentNode === this.page) {
            level0Node = image;
          } else {
            level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, image);
          }

          if (image instanceof FigureElement && image.firstChild instanceof ImageElement) {
            image = image.firstChild;
          }

          this.updateImageSizeOperation(level0Node, image, height, width);
          actionContext.jsonChanges = true;

          this.changeTracker.saveActionChanges(actionContext);
        }
      }

      handleUpdateImageProperties(properties: Editor.Styles.ImageProperties) {
        if (!this.askUserAboutThis()) {
          return false;
        }

        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            let range = EditorSelectionUtils.getRange();

            if (range) {
              const image = EditorDOMUtils.closest(range.startContainer, [
                ELEMENTS.FigureElement.TAG,
                ELEMENTS.ImageElement.TAG,
              ]);
              const block = EditorDOMUtils.findFirstLevelChildNode(this.page, image);

              if (block && image instanceof HTMLElement) {
                let actionContext = new ActionContext();

                this.updateImageProperties(block, image, properties);

                actionContext.jsonChanges = true;

                this.changeTracker.saveActionChanges(actionContext);
                this.visualizerManager?.getWidgetsManager().rebuildWidgetForView(image.id);
              }
            }

            this.visualizerManager?.selection.debounceSelectionChanged();
          } catch (error) {
            Logger.captureException(error);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }
    },
);
