import { BaseTypedEmitter } from '_common/services/Realtime';
import { ReadyState } from '../../utils';
import { SectionsManager } from './SectionsManager';
import { ListStylesManager } from './ListStylesManager';
import { ParagraphStylesManager } from './ParagraphStylesManager';

export class StylesManager extends BaseTypedEmitter<{
  READY: () => {};
}> {
  sections: SectionsManager;
  lists: ListStylesManager;
  paragraphStyles: ParagraphStylesManager;

  constructor(Data: Editor.Data.API, Visualizer: Editor.Visualizer.State) {
    super();
    this.sections = new SectionsManager(Data, Visualizer);
    this.lists = new ListStylesManager(Data, Visualizer);
    this.paragraphStyles = new ParagraphStylesManager(Data, Visualizer);
  }

  start() {
    let readyState = new ReadyState();
    readyState.register(SectionsManager.name);
    readyState.register(ListStylesManager.name);
    readyState.register(ParagraphStylesManager.name);
    this.sections.start(readyState);
    this.lists.start(readyState);
    this.paragraphStyles.start(readyState);
    return readyState;
  }

  destroy() {
    this.sections.destroy();
    this.lists.destroy();
    this.paragraphStyles.destroy();
  }
}
