import { useState, useEffect } from 'react';

import { useDispatch } from '_common/hooks';

import { updateFilters } from './redux/userManagementSlice';

import { useDebounce } from '_common/hooks';
import { FilterDisplay, IntlErrorBoundary } from '_common/components';

import { DEBOUNCE_DELAY } from './constants';
import Header from './Header/Header';
import List from './List/List';
import type { HeaderProps } from './Header/HeaderContent';

import styles from './Users.module.scss';

const Users = () => {
  const dispatch = useDispatch();

  // List of filters
  const [searchValue, setSearchValue] = useState<string>();
  const debouncedSearchValue = useDebounce(searchValue, DEBOUNCE_DELAY);

  // Update the user search value
  const handleSearchChange: HeaderProps['onSearchChange'] = (newSearch) => {
    setSearchValue(newSearch);
  };

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue !== '') {
      dispatch(updateFilters({ filter_fields: ['user'], filter_values: [debouncedSearchValue] }));
    } else {
      dispatch(updateFilters({ filter_fields: [], filter_values: [] }));
    }
  }, [debouncedSearchValue]);

  return (
    <div className={styles.content}>
      <Header onSearchChange={handleSearchChange} searchValue={searchValue} />
      <FilterDisplay identity="tenantSettings_users" />
      <IntlErrorBoundary size="large" margin="10rem 0 0 0">
        <List />
      </IntlErrorBoundary>
    </div>
  );
};

export default Users;
