import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Checkbox, Tooltip } from 'dodoc-design-system';

import styles from './Section.module.scss';
import { CheckboxProps } from 'dodoc-design-system/build/types/Components/Checkbox/Checkbox';

type TableHeaderProps = Pick<Editor.Styles.TableProperties, 'headerRow' | 'fromStart'> &
  SectionProps;

const TableHeader = ({ headerRow, fromStart, updateData }: TableHeaderProps) => {
  const intl = useIntl();

  const getCheckState = (): CheckboxProps['checked'] => {
    if (headerRow?.incoherent) {
      return 'indeterminate';
    }

    return headerRow?.value ? 'checked' : 'unchecked';
  };

  const [checkState, setCheckState] = useState(getCheckState());

  const handleHeaderRowChange = () => {
    setCheckState((prevState) => {
      switch (prevState) {
        case 'checked':
          updateData({ property: 'headerRow', value: { value: false } });
          return 'unchecked';
        case 'unchecked':
        case 'indeterminate':
          updateData({ property: 'headerRow', value: { value: true } });
          return 'checked';
      }
    });
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: 'TABLE_HEADER' })}
      initialCollapsed={false}
      contentMargin="2rem 1rem 0"
      testId="tableProperties-table-header-accordion"
    >
      <Tooltip
        content={intl.formatMessage({ id: 'SET_ROW_AS_TABLE_HEADER_DISABLED_WARNING' })}
        disabled={fromStart}
        testId="set-row-as-table-header-tooltip"
      >
        <div className={`${styles.root} ${styles.fitContent}`}>
          <Checkbox
            size="x-small"
            checked={checkState}
            onChange={handleHeaderRowChange}
            disabled={!fromStart}
            testId="set-row-as-table-header-checkbox"
          />
          <FormattedMessage id="SET_ROW_AS_TABLE_HEADER" />
        </div>
      </Tooltip>
    </Accordion>
  );
};

export default TableHeader;
