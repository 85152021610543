import { useSlideData } from '../SlideData';
import Pattern from './Pattern';

type PatternDefsProps = {
  id: string;
  background: Presentation.Data.Common.PatternFillType;
};

const PatternDefs = ({ id, background }: PatternDefsProps) => {
  const { color } = useSlideData();

  return (
    <Pattern
      id={id}
      pattern={background.preset}
      backgroundColor={color(background.background)}
      foregroundColor={color(background.foreground)}
    />
  );
};

export default PatternDefs;
