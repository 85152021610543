type HookHandlerType = (...args: unknown[]) => any;

export class Hook<V extends HookHandlerType = (...args: any) => void> {
  protected hooks: V[];
  constructor() {
    this.hooks = [];
  }

  hasRegistries() {
    return this.hooks.length > 0;
  }

  /**
   * Adds a function to be run before a hook completes
   * @example this.content.register(function(){...});
   * @return {undefined} void
   */
  register(...fncs: V[]) {
    for (var i = 0; i < arguments.length; ++i) {
      if (typeof arguments[i] === 'function') {
        this.hooks.push(arguments[i]);
      } else {
        // unpack array
        for (var j = 0; j < arguments[i].length; ++j) {
          this.hooks.push(arguments[i][j]);
        }
      }
    }
  }

  /**
   * Triggers a hook to run all functions
   * @example this.content.trigger(args).then(function(){...});
   * @return {Promise} results
   */
  trigger(...args: Parameters<V>): Promise<ReturnType<V>[]> {
    var promises: Promise<ReturnType<V>>[] = [];
    this.hooks.forEach(function (task) {
      var executing = task(...args);

      if (executing && executing instanceof Promise) {
        // Task is a function that returns a promise
        promises.push(executing);
      }
      // Otherwise Task resolves immediately, add resolved promise with result
      promises.push(Promise.resolve(executing));
    });

    return Promise.all(promises);
  }

  // Adds a function to be run before a hook completes
  list() {
    return this.hooks;
  }

  clear() {
    this.hooks = [];
    return;
  }
}
