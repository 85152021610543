import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement, FigureElement } from '..';

export class FigureViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<FigureElement, Editor.Data.Node.FigureData>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<FigureElement> = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json, node) => {
        node.setAttribute('element_type', ELEMENTS.FigureElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // lock status
    lock: this.GENERIC_ATTRIBUTE_MAPPER.lock,
    // tasks
    tasks: this.GENERIC_ATTRIBUTE_MAPPER.tasks,
    // alignment
    a: this.GENERIC_ATTRIBUTE_MAPPER.a,
    // vanish / hidden
    v: this.GENERIC_ATTRIBUTE_MAPPER.v,
    //section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.FigureData | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.FigureData, model?: Editor.Data.Node.Model) {
    const node = DOMElementFactory.buildElement(ELEMENTS.FigureElement.TAG) as FigureElement;

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}
