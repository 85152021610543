import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axios/axios';
import { createSelector } from '@reduxjs/toolkit';
import { parseProfile } from 'Auth/redux/utils';
import { navigateToMyFiles } from 'router/history';
import { notify } from '_common/components/ToastSystem';
import { SessionStorage } from '_common/utils';
import type { paths } from '_types/authority';

// Define a service using a base URL and expected endpoints
const authority = createApi({
  reducerPath: 'authority',
  baseQuery: axiosBaseQuery({
    app: 'authority',
    apiRoute: '/api/authority',
  }),
  tagTypes: [
    'PublicProfile',
    'User',
    'Session',
    'Device',
    'Connection',
    'Service',
    'Avatar',
    'TenantSettings',
    'Link',
  ],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<ParsedUserProfile, void>({
      query: () => ({
        url: '/user/profile',
      }),
      transformResponse: (responseData: UserProfile) => {
        return { ...responseData, profile: parseProfile(responseData.profile) };
      },
      providesTags: () => [
        {
          type: 'User',
          id: 'Current',
        },
      ],
    }),
    loginSetup: builder.query<
      paths['/api/authority/setup']['get']['responses']['200']['content']['application/json'],
      void
    >({
      query: () => ({
        url: '/setup',
      }),
    }),
    signOut: builder.mutation<void, UserId>({
      query: (userId) => ({
        url: '/logout',
        method: 'POST',
        body: {},
      }),
    }),
    recoverPassword: builder.mutation<
      void,
      paths['/api/authority/account/password/recovery']['post']['requestBody']['content']['multipart/form-data']
    >({
      query: (params) => ({
        url: '/account/password/recovery',
        method: 'POST',
        body: params,
      }),
    }),
    unlockAccount: builder.mutation<
      void,
      paths['/api/authority/account/unlock/{token}']['post']['parameters']['path']
    >({
      query: (params) => ({ url: `/account/unlock/${params.token}`, method: 'POST', body: {} }),
    }),
    activateDevice: builder.mutation<
      void,
      paths['/api/authority/device/activate']['post']['requestBody']['content']['application/json']
    >({
      query: (params) => ({ url: '/device/activate', method: 'POST', body: params }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          navigateToMyFiles();
        } catch (error) {
          const typedError = (
            error as {
              error: { data: any; status: number };
            }
          ).error;
          if (typedError?.status === 417) {
            navigateToMyFiles();
          } else {
            notify({
              type: 'error',
              title: 'auth.notifications.submitDevice.error.title',
              message: 'auth.notifications.submitDevice.error.desc',
            });
          }
        }
      },
    }),
    getTokenInfo: builder.query<
      paths['/api/authority/login']['get']['responses']['200'],
      paths['/api/authority/login']['get']['parameters']['header']['X-DoDOC-Tenant']
    >({
      query: (token) => ({
        url: '/login',
        config: {
          headers: {
            Authorization: `Token ${token}`,
          },
        },
      }),
      onQueryStarted: async (token, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          SessionStorage.setToken(token);
        } catch {}
      },
      transformResponse: (
        responseData: paths['/api/authority/login']['get']['responses']['200'],
        meta,
        arg,
      ) => {
        responseData.token = arg;
        return responseData;
      },
    }),

    getTenantSettings: builder.query<
      paths['/api/authority/tenant/settings']['get']['responses']['200'],
      void
    >({
      query: () => ({ url: '/tenant/settings' }),
      providesTags: [{ type: 'TenantSettings' }],
    }),
    getLinks: builder.query<
      paths['/api/authority/authenticator/service/list']['get']['responses']['200']['content']['application/json'],
      { userId: UserId; tenant: string }
    >({
      query: () => ({
        url: `/authenticator/connector/list`,
      }),
      providesTags: (_, __, arg) => {
        return [{ type: 'Link', id: `${arg.tenant}-${arg.userId}` }];
      },
    }),
    updatePassword: builder.mutation<
      paths['/api/authority/user/password/expired/change']['post']['responses']['200'],
      paths['/api/authority/user/password/expired/change']['post']['requestBody']['content']['multipart/form-data']
    >({
      query: (params) => ({
        url: `/user/password/expired/change`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

// Select data from where RTK Query stores the data from the list endpoint
export const selectCurrentUser = authority.endpoints.getCurrentUser.select();
export const selectSetupData = authority.endpoints.loginSetup.select();

export const selectUserIsAdmin = createSelector(
  [selectCurrentUser],
  ({ data: user }) => user?.is_admin || user?.is_superuser,
);

export const selectUserCurrentTenant = createSelector([selectCurrentUser], ({ data: user }) =>
  user?.other.tenants?.find((tenant) => tenant.current),
);

// Export queries
export const {
  useGetCurrentUserQuery,
  useLoginSetupQuery,
  useSignOutMutation,
  useRecoverPasswordMutation,
  useUnlockAccountMutation,
  useActivateDeviceMutation,
  useGetTokenInfoQuery,
  useGetTenantSettingsQuery,
  useGetLinksQuery,
  useUpdatePasswordMutation,
} = authority;

export default authority;
