import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { Parser } from '../../../../Clipboard/Parser';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { notify } from '_common/components/ToastSystem';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { TableElement } from 'Editor/services/VisualizerManager';

export default Mixin(
  (superclass) =>
    class CopyPasteHandler extends superclass {
      destroy() {
        super.destroy();
      }

      // #################################################
      //                       CUT
      // #################################################
      handleCutOnNonCollapsedSelection(actionContext, event) {
        if (EditorSelectionUtils.isCurrentSelectionEditable()) {
          EditorSelectionUtils.fixNonCollapsedTextSelection({
            suggestionMode: true,
            forceTextAsWrap: true,
            // isDelete: true, // avoid these properties here with multiselection
          });

          this.clipboard.handleCut(event);

          this.removeSelectionContent(actionContext);
        } else {
          notify({
            type: 'warning',
            title: 'global.warning',
            message: 'editor.errors.cut',
          });
        }
      }

      // #################################################
      //                       PASTE
      // #################################################
      handlePasteOnNonCollapsedSelection(actionContext, parsedData, pasteOption) {
        actionContext.selectionType = ActionContext.SELECTION.RANGE;
        actionContext.selectionBackwards = EditorSelectionUtils.isSelectionBackwards();

        EditorSelectionUtils.fixNonCollapsedTextSelection({
          suggestionMode: true,
          forceTextAsWrap: true,
        });

        if (this.removeSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const level0Node = EditorDOMUtils.findFirstLevelChildNode(
            this.page,
            selection.anchorNode,
          );

          this.handlePasteOnCollapsedSelection(
            actionContext,
            parsedData,
            pasteOption,
            level0Node,
            selection.anchorNode,
          );
        }
      }

      handlePasteOnCollapsedSelection(
        actionContext,
        parsedData,
        pasteOption,
        baseNode,
        anchorNode,
      ) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
            // anchorNode = selection.anchorNode;
            // anchorOffset = selection.anchorOffset;
          }
        }

        // handle insert data
        // this._handlePasteOperation(actionContext, parsedData, baseNode, anchorNode);

        if (baseNode) {
          if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
            // SELECTION IS A DEFAULT TEXT ELEMENT
            this._handlePasteOnTextElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
            // SELECTION IS A NON-EDITABLE ELEMENT
            this._handlePasteOnNonEditableElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
            // SELECTION IS A FIGURE
            this._handlePasteOnFigureElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
            // SELECTION IS A TABLE
            this._handlePasteOnTableElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
            this._handlePasteOnContainerElement(
              actionContext,
              parsedData,
              baseNode,
              anchorNode,
              pasteOption,
            );
          }
        }
      }

      _handlePasteOnContainerElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        if (EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
          }
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handlePasteOnCollapsedSelection(
            actionContext,
            parsedData,
            pasteOption,
            subLevel0Node,
            anchorNode,
          );
        }
      }

      _handlePasteOnNonEditableElement(
        actionContext,
        parsedData,
        baseNode,
        anchorNode,
        pasteOption,
      ) {
        const p = DOMElementFactory.createNewParagraphElement();

        this.handleInsertBlockNodeOnCollapsedSelection(
          actionContext,
          p,
          baseNode,
          anchorNode,
          pasteOption,
        );

        EditorSelectionUtils.setCaret(p, 'INSIDE_END');

        const selection = EditorSelectionUtils.getSelection();
        baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);

        this._handlePasteOnTextElement(
          actionContext,
          parsedData,
          baseNode,
          selection.anchorNode,
          pasteOption,
        );
      }

      _handlePasteOnTableElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        // TODO: this needs to be reviewed

        if (
          parsedData.childNodes.length === 1 &&
          parsedData.firstChild.nodeName === ELEMENTS.TableElement.TAG
        ) {
          this.pasteFromTableToTable(
            actionContext,
            parsedData.firstChild,
            pasteOption,
            baseNode,
            anchorNode,
          );
        } else {
          const closest = EditorDOMUtils.closest(anchorNode, EditorDOMElements.BLOCK_TEXT_ELEMENTS);
          if (closest) {
            this._handlePasteOnTextElement(
              actionContext,
              parsedData,
              closest,
              anchorNode,
              pasteOption,
            );
          }
        }
      }

      _handlePasteOnFigureElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        // TODO: this needs to be reviewed

        this._handlePasteOnTextElement(
          actionContext,
          parsedData,
          baseNode,
          anchorNode,
          pasteOption,
        );
      }

      canMergeContens(baseNode, nodeToMerge) {
        if (
          EditorDOMUtils.isClosestTextElementEditable(baseNode) &&
          EditorDOMUtils.isClosestTextElementEditable(nodeToMerge)
        ) {
          // both are lists
          if (
            (this.dataManager.numbering.isListElement(baseNode.id) ||
              baseNode.hasAttribute('cp_list_id')) &&
            (this.dataManager.numbering.isListElement(nodeToMerge.id) ||
              nodeToMerge.hasAttribute('cp_list_id'))
          ) {
            return true;
          }

          // both are not lists
          if (
            !this.dataManager.numbering.isListElement(baseNode.id) &&
            !baseNode.hasAttribute('cp_list_id') &&
            !this.dataManager.numbering.isListElement(nodeToMerge.id) &&
            !nodeToMerge.hasAttribute('cp_list_id')
          ) {
            return true;
          }
        }

        return false;
      }

      _handleInsertInline(actionContext, elementToInset, selectionOptions) {
        // insert inline node
        let selection = EditorSelectionUtils.getSelection();
        let level0 = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);

        this.clipboard.prepareForInlinePaste(actionContext, elementToInset);

        this.handleInsertInlineNodeOnCollapsedSelection(
          actionContext,
          elementToInset,
          level0,
          selection.anchorNode,
          selectionOptions,
        );
        if (elementToInset.nodeType !== Node.TEXT_NODE) {
          EditorSelectionUtils.setCaret(elementToInset, 'POST');
        }

        // if (childElement === spliSelectionAfterElement) {
        //   this.splitSelectionContent(actionContext, [], true);
        // }
      }

      _handleInsertBlock(actionContext, elementToInsert) {
        // TODO: insert block node to json (check apply patch function)
        // parse html element to json
        // create node model
        // build insertion op
        // apply
        // find solution for after paste

        // insert block nodes
        let selection = EditorSelectionUtils.getSelection();
        let level0 = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);

        this.clipboard.prepareForPaste(actionContext, elementToInsert);

        this.handleInsertBlockNodeOnCollapsedSelection(
          actionContext,
          elementToInsert,
          level0,
          selection.anchorNode,
        );
      }

      _handlePasteOnTextElement(actionContext, parsedData, baseNode, anchorNode, pasteOption) {
        // if markers don't exist create markers

        let isFirstChild = true;

        if (parsedData) {
          let childElement, nextChild;
          const auxBin = DOMElementFactory.buildElement('div');

          while (parsedData.firstChild) {
            childElement = parsedData.firstChild;
            nextChild = parsedData.childNodes[1];

            auxBin.appendChild(childElement);

            const selectionOptions = {};

            if (pasteOption === Parser.ORIGINAL_STYLES) {
              if (childElement === auxBin.firstChild) {
                // split all format elements and insert directly in block element
                selectionOptions.forceInlineSplit = true;
              }
              // handle all format elements as wrap elements
              selectionOptions.forceTextAsWrap = true;
            } else if (
              pasteOption === Parser.MATCH_DESTINATION &&
              !childElement === auxBin.firstChild
            ) {
              // handle format elements as wrap elements
              selectionOptions.forceTextAsWrap = true;
            }

            if (EditorDOMElements.isNodeInlineElement(childElement)) {
              // insert inline
              this._handleInsertInline(actionContext, childElement, selectionOptions);
            } else {
              // insert block

              if (isFirstChild && this.canMergeContens(baseNode, childElement)) {
                while (childElement.firstChild) {
                  // inset inline
                  this._handleInsertInline(
                    actionContext,
                    childElement.firstChild,
                    selectionOptions,
                  );
                }

                // copy attributes
                if (childElement instanceof HTMLElement) {
                  const attributes = childElement.attributes;
                  for (let a = 0; a < attributes.length; a++) {
                    const key = attributes[a];
                    if (
                      key !== 'id' &&
                      key !== 'parent_id' &&
                      key !== 'element_type' &&
                      key !== 'style'
                    ) {
                      baseNode.attributes[key] = attributes[key];
                    }
                  }
                }

                this.clipboard.prepareForPaste(actionContext, baseNode);

                if (nextChild != null && EditorDOMElements.isNodeInlineElement(nextChild)) {
                  this.insertRootNodeOnText(actionContext, null, null, (before, after) => true);
                }
              } else {
                this._handleInsertBlock(actionContext, childElement);
              }
            }

            isFirstChild = false;
          }

          this.clipboard.afterCompletePaste();

          if (this.clipboard.allowOpenPasteOptions) {
            const range = EditorSelectionUtils.getRange();
            let level0 = EditorDOMUtils.findFirstLevelChildNode(
              this.page,
              range.commonAncestorContainer,
            );

            this.clipboard.openPasteOptions(level0, range);
          }

          // fix selection after paste
          EditorSelectionUtils.fixCollapsedTextSelection({ suggestionMode: true });
        }
      }

      pasteFromTableToTable(actionContext, parsedTable, pasteOption, baseNode, anchorNode) {
        let parsedTableBody;
        let parsedTableRows;

        if (!(baseNode instanceof TableElement) || !(parsedTable instanceof HTMLTableElement)) {
          return;
        }

        this.clipboard.allowOpenPasteOptions = false;

        parsedTableBody = parsedTable.tBodies[0];
        parsedTableRows = parsedTableBody.childNodes;

        // Get parsedTable total number of columns
        const numColumns = parsedTableRows[0].cells.length;

        // Get parsedTable total number of rows
        const numRows = parsedTableRows.length;

        // Get baseNode table total number of columns
        let totalColumns;

        for (let r = 0; r < baseNode.rows.length; r++) {
          if (!totalColumns || totalColumns < baseNode.rows[r].childNodes.length) {
            totalColumns = baseNode.rows[r].childNodes.length;
          }
        }

        // Get baseNode table total number of rows
        const tableRows = baseNode.tBodies[0].rows;
        const totalRows = tableRows.length;

        const selectedCells = baseNode.getSelectedCells();
        const selectedRows = baseNode.getSelectedRows(selectedCells);

        // Get selected row index
        const startSelectedRowIndex = selectedRows[0].sectionRowIndex;
        const endSelectedRowIndex = selectedRows[selectedRows.length - 1].sectionRowIndex;

        // Get selected cell index
        const startSelectedCellIndex = selectedCells[0].cellIndex;
        let endSelectedCellIndex = selectedCells[0].cellIndex;

        // find max cell index (irregular tables)
        for (let c = 0; c < selectedCells.length; c++) {
          if (selectedCells[c].cellIndex > endSelectedCellIndex) {
            endSelectedCellIndex = selectedCells[c].cellIndex;
          }
        }

        if (selectedCells.length < 2) {
          if (numRows > totalRows - startSelectedRowIndex) {
            const numberRowsToAdd = numRows - (totalRows - startSelectedRowIndex);

            // add missing rows to the baseNode table
            for (let i = 0; i < numberRowsToAdd; i++) {
              this.handleTableOperation?.(
                {
                  operation: this.TABLE_OPERATIONS.INSERT_ROW_INDEX,
                  index: [totalRows],
                },
                actionContext,
              );
            }
          }

          if (numColumns > totalColumns - startSelectedCellIndex) {
            const numberColumnsToAdd = numColumns - (totalColumns - startSelectedCellIndex);

            // add missing columns to the baseNode table
            for (let i = 0; i < numberColumnsToAdd; i++) {
              this.handleTableOperation?.(
                {
                  operation: this.TABLE_OPERATIONS.INSERT_COLUMN_INDEX,
                  index: [totalColumns],
                },
                actionContext,
              );
            }
          }

          // iterate parsedTable rows
          for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
            // iterate parsedTable cells
            for (let cellIndex = 0; cellIndex < numColumns; cellIndex++) {
              const cell =
                baseNode.rows[rowIndex + startSelectedRowIndex].cells[
                  cellIndex + startSelectedCellIndex
                ];

              if (!cell) {
                continue;
              }

              EditorSelectionUtils.setSelection(cell, 0, cell, cell.childNodes.length);
              this.handlePasteOnNonCollapsedSelection(
                actionContext,
                parsedTableRows[rowIndex].cells[cellIndex],
                pasteOption,
              );
            }
          }
        } else {
          for (
            let r = startSelectedRowIndex, pr = 0;
            r <= endSelectedRowIndex && pr < numRows;
            r++, pr++
          ) {
            if (tableRows[r] && parsedTableRows[pr]) {
              const cells = tableRows[r].cells;
              const parsedCells = parsedTableRows[pr].cells;
              for (
                let c = startSelectedCellIndex, pc = 0;
                c <= endSelectedCellIndex && pc < numColumns;
                c++, pc++
              ) {
                if (cells[c] && parsedCells[pc]) {
                  EditorSelectionUtils.setSelection(
                    cells[c],
                    0,
                    cells[c],
                    cells[c].childNodes.length,
                  );
                  this.handlePasteOnNonCollapsedSelection(
                    actionContext,
                    parsedCells[pc],
                    pasteOption,
                  );
                }
              }
            }
          }
        }
      }
    },
);
