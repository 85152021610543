import { useIntl } from 'react-intl';

import { useGetTemplatesListQuery } from './TemplatesApi';

import { Loading } from 'Settings/components';
import TemplateSection from './TemplateSection/TemplateSection';

import styles from './Templates.module.scss';

const Templates = () => {
  const intl = useIntl();

  const { isLoading, categories } = useGetTemplatesListQuery(undefined, {
    selectFromResult: (result) => {
      const { order, extensions } = result.data || {
        order: [],
        extensions: {},
      };

      return {
        ...result,
        categories: Object.keys(
          order.reduce((indexedCat, index) => {
            indexedCat[extensions[index].category] = true;
            return indexedCat;
          }, {} as Record<Template['category'], boolean>),
        ),
      };
    },
  });

  if (isLoading) {
    return (
      <div data-testid="loading">
        <Loading
          label={intl.formatMessage({ id: 'LOADING_TEMPLATES' })}
          testId="loading-templates"
        />
      </div>
    );
  }

  return (
    <div className={styles.templates}>
      {categories.map((category) => (
        <TemplateSection key={category} category={category} testId={category} />
      ))}
    </div>
  );
};

export default Templates;
