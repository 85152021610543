import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import UsersList from './UsersList/UsersList';

import styles from './PermissionsPanel.module.scss';

const PermissionsPanel = () => {
  const selected = useSelector((state) => state.editor.permissions.selected);
  const allSelected = useSelector((state) => state.editor.permissions.allSelected);

  const manager = EditorManager.getInstance();

  const renderSelectionButtons = () => {
    return (
      <div className={styles.buttons}>
        <Button
          style={{ width: '100%' }}
          size="small"
          onClick={() => manager.selectAllForPermissions()}
          disabled={allSelected}
          testId="select-all-button"
        >
          <FormattedMessage id="editor.sidebar.permissions.selectAll" />
        </Button>
        <Button
          style={{ marginLeft: '1rem', width: '100%' }}
          size="small"
          onClick={() => manager.deselectAllForPermissions()}
          disabled={selected.length === 0}
          testId="deselect-button"
        >
          <FormattedMessage id="editor.sidebar.permissions.deselect" />
        </Button>
      </div>
    );
  };
  return (
    <div className={styles.root}>
      {renderSelectionButtons()}

      {selected.length === 0 ? (
        <div className={styles.empty}>
          <FormattedMessage id="editor.sidebar.permissions.empty" />
        </div>
      ) : (
        <UsersList />
      )}
    </div>
  );
};

export default PermissionsPanel;
