import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import EditorManager from 'Editor/services/EditorManager';

import { selectReadOnlyMode, selectIsPageLayout } from 'Editor/redux/EditorStatusSlice';

import { PanelContent, PanelHeading } from '_common/suite/components';

import CurrentStyle from './CurrentStyle/CurrentStyle';
import StyleList from './StyleList/StyleList';
import DocumentTemplatePreview from './DocumentTemplatePreview/DocumentTemplatePreview';

import styles from './DocumentStylesTab.module.scss';

const DocumentStylesTab = () => {
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const editorStatus = useSelector((state) => state.editor.status);

  const onCreateNewStyle = () => {
    const manager = EditorManager.getInstance();
    if (manager && editorStatus.selectedStyle.id) {
      manager.createNewDocumentStyleFromId(editorStatus.selectedStyle.id);
    }
  };

  return (
    <>
      <PanelHeading>
        <FormattedMessage id="PARAGRAPH_STYLES" />
      </PanelHeading>
      <PanelContent testId="document-styles-panel">
        <div className={styles.template}>
          <DocumentTemplatePreview />
          <div className={styles.divider} />
          <CurrentStyle testId="document-styles-panel-current-style" />
          <Button
            size="small"
            onClick={onCreateNewStyle}
            disabled={isReadOnlyMode || isPageLayout || !editorStatus.selectedStyle}
            testId="document-styles-panel-create-new-style-button"
          >
            <FormattedMessage id="CREATE_NEW_STYLE" />
          </Button>
        </div>
        <StyleList />
      </PanelContent>
    </>
  );
};

export default DocumentStylesTab;
