import { useState, useLayoutEffect, DependencyList, RefObject } from 'react';

const useIsEllipsed = (ref: RefObject<HTMLElement>, dependencies?: DependencyList) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  useLayoutEffect(() => {
    if (ref && ref.current) {
      setIsOverflowed(ref.current.clientWidth - ref.current.scrollWidth < 0);
    }
    return () => {
      setIsOverflowed(false);
    };
  }, dependencies);
  return isOverflowed;
};

export default useIsEllipsed;
