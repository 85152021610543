import { FormattedMessage } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { selectTemplateById } from 'Settings/pages/TenantSettingsPage/Templates/TemplatesApi';

import styles from './NameCell.module.scss';

type NameCellProps = {
  templateId: Template['id'];
};

const NameCell = ({ templateId }: NameCellProps) => {
  const dispatch = useDispatch();

  const template = useSelector((state) => selectTemplateById(state, templateId));

  const handleLinkClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ExtensionUpdateInformationModal',
        data: {
          id: templateId,
          type: 'template',
        },
      }),
    );
  };

  return (
    <div className={styles.name}>
      <div className={styles.label}>{template?.name}</div>
      {template?.status === 'outdated' && (
        <Button variant="link" size="small" onClick={handleLinkClick} testId="whatsNew">
          <FormattedMessage id="WHATS_NEW" />
        </Button>
      )}
    </div>
  );
};

export default NameCell;
