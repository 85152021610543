import { UsernameLabel } from '_common/components';

type UserNameCellProps = {
  userId?: string;
}

const UserNameCell = ({ userId }: UserNameCellProps) => {
  return (
    <UsernameLabel userId={userId} />
  );
}

export default UserNameCell;