import { usePresentationData } from 'Presentation/PresentationData';
import Background from './Background/Background';
import { useSlideData } from './SlideData';

const SlideBackground = () => {
  const { size } = usePresentationData();
  const { slide, slideLayout, slideMaster } = useSlideData();

  return (
    <Background
      fill={slide.bg?.fill || slideLayout.bg?.fill || slideMaster.bg?.fill}
      size={{ width: size.width, height: size.height }}
      position={{ left: 0, top: 0 }}
      shadeToTitle={
        slide.bg?.shadeToTitle || slideLayout.bg?.shadeToTitle || slideMaster.bg?.shadeToTitle
      }
    />
  );
};
export default SlideBackground;
