import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Icon } from 'dodoc-design-system';
import { usePDFContext } from 'PDF/PDFContext';
import { usePDFPermissions } from 'PDF/PDFPermissionsContext';

import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';
import { selectPDFTaskById } from 'PDF/redux/PDFAnnotationsSlice';

import { SearchUser, UserCard } from '_common/components';

import styles from './PDFTaskWatchModal.module.scss';
import usePDFCollaborators from 'PDF/hooks/usePDFCollaborators';

const PDFTaskWatchModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pdfManager = usePDFContext();
  const isOpen = useSelector((state) => state.modals.open.PDFTaskWatchModal);
  const task = useSelector((state) =>
    selectPDFTaskById(state, state.modals.PDFTaskWatchModal.taskId),
  );
  const collaborators = usePDFCollaborators();

  const [selectedWatchers, setSelectedWatchers] = useState<
    {
      value: string;
      label: string;
      type: string;
    }[]
  >([]);

  const watchers = useMemo(() => {
    return task
      ? task.watchers
          .filter((id) => id !== task.assignee && id !== task.authorId)
          .map((id) => ({ value: id, label: id, type: 'user' }))
      : [];
  }, [task?.watchers, task?.assignee, task?.authorId]);

  const possibleWatchers = useMemo(() => {
    return task
      ? collaborators
          .filter(
            (id) =>
              id !== task.assignee &&
              id !== task.authorId &&
              !watchers.some((watcher) => watcher.value === id),
          )
          .map((id) => ({ id, type: 'user' }))
      : [];
  }, [collaborators, task?.assignee, task?.authorId, watchers]);

  const { canComment } = usePDFPermissions();

  const close = () => {
    dispatch(closeAndResetModal('PDFTaskWatchModal'));
  };

  if (!task) {
    if (isOpen) {
      close();
    }
    return null;
  }

  const handleRemoveWatch = (user: string) => {
    pdfManager.removeWatchFromTask(task.pageNumber, task.id, user);
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="54rem" testId="task-watch">
      <Modal.Header onClose={close}>
        {/* @ts-expect-error missing property 'orderNumber' */}
        <FormattedMessage id="TASK_WATCH_MODAL_HEADER" values={{ number: task.orderNumber }} />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <SearchUser
          escapeClearsValue
          value={selectedWatchers}
          onChange={(users, actionMeta) => {
            setSelectedWatchers(users);
            if (actionMeta?.action === 'select-option' && actionMeta?.option) {
              pdfManager.watchTask(task.pageNumber, task.id, actionMeta.option.value);
            } else if (actionMeta?.action === 'deselect-option' && actionMeta.option) {
              pdfManager.removeWatchFromTask(task.pageNumber, task.id, actionMeta.option.value);
            } else if (actionMeta?.action === 'remove-value' && actionMeta.removedValue) {
              pdfManager.removeWatchFromTask(
                task.pageNumber,
                task.id,
                actionMeta.removedValue.value,
              );
            } else if (actionMeta?.action === 'clear' && actionMeta.removedValues) {
              actionMeta.removedValues.forEach((removedWatcher) => {
                pdfManager.removeWatchFromTask(task.pageNumber, task.id, removedWatcher.value);
              });
            }
          }}
          size="medium"
          placeholder={intl.formatMessage({ id: 'USER_OR_EMAIL_ADDRESS' })}
          width="100%"
          menuPosition="fixed"
          options={possibleWatchers}
          editor
          isMulti
          disabled={!canComment}
          searchable
          onMenuClose={() => {
            setSelectedWatchers([]);
          }}
          testId="task-watch"
        />
        <div className={styles.label}>
          <FormattedMessage id="TASK_WATCH_MODAL_LABEL" />:
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <UserCard userId={task.authorId ?? ''} />
            <div className={styles.user_label}>
              <FormattedMessage id="TASK_CREATOR" />
            </div>
          </div>
          {task.assignee && (
            <div className={styles.row}>
              <UserCard userId={task.assignee} />
              <div className={styles.user_label}>
                <FormattedMessage id="TASK_ASSIGNEE" />
              </div>
            </div>
          )}
          {task.watchers &&
            task.watchers.length > 0 &&
            task.watchers
              .filter((id: UserId) => id !== task.assignee && id !== task.authorId)
              .map((id: UserId) => (
                <div className={styles.row} key={id}>
                  <UserCard userId={id} editor />
                  <div
                    style={{ cursor: canComment ? 'pointer' : 'inherit' }}
                    onClick={() => {
                      if (canComment) {
                        handleRemoveWatch(id);
                      }
                    }}
                  >
                    <Icon icon="CloseGrey" size={24} disabled={!canComment} />
                  </div>
                </div>
              ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="task-watch-modal-done-button">
          <FormattedMessage id="DONE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PDFTaskWatchModal;
