import ReactDOM from 'react-dom';

import tippy, { Instance } from 'tippy.js';
import { Provider } from 'react-redux';
import { useIntl } from 'react-intl';
import { IntlProvider } from 'Intl/Intl';
import { usePublicProfile } from '_common/hooks';

import { BaseBlockElement } from '../../..';

import styles from './ApprovedElement.module.scss';
import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';

type TooltipContentArgs = { userId: string };

const TooltipContent = ({ userId }: TooltipContentArgs) => {
  const intl = useIntl();
  const profile = usePublicProfile(userId);

  return <>{`${intl.formatMessage({ id: 'CONTENT_APPROVED_BY' })} ${profile.name}`}</>;
};

export class ApprovedViewElement extends BaseBlockElement {
  private overlay?: HTMLElement;
  private content?: HTMLElement;

  protected approvedTooltipElement?: HTMLElement;
  protected approvedTippyInstance: Instance | null = null;

  static onClickListener(e: Event) {
    e.stopPropagation();
  }

  static onMouseUpListener(e: Event) {
    e.stopPropagation();
  }

  static get observedAttributes() {
    return [
      ...BaseBlockElement.observedAttributes,
      'data-space-after',
      'data-space-before',
      'data-asb',
      'data-asa',
    ];
  }

  constructor() {
    super();

    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return false;
  }

  get isSplitable() {
    return false;
  }

  get isContentWrapper() {
    return true;
  }

  get isSelectable() {
    return true;
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case 'data-space-after':
        case 'data-space-before':
        case 'data-asa':
        case 'data-asb':
          this.handleSpacing();
          break;
        default:
          super.attributeChangedCallback(attribute, oldValue, newValue);
          break;
      }
    }
  }

  private handleSpacing() {
    if (this.dataset.asb === 'true') {
      this.style.marginTop = '14pt';
    } else if (this.dataset.spaceBefore != null && this.dataset.spaceBefore !== '') {
      this.style.marginTop = this.dataset.spaceBefore ? `${this.dataset.spaceBefore}pt` : '';
    } else if (this.dataset.asb === 'false') {
      this.style.marginTop = '0pt';
    } else {
      this.style.marginTop = '';
    }

    if (this.dataset.asa === 'true') {
      this.style.marginBottom = '14pt';
    } else if (this.dataset.spaceAfter != null && this.dataset.spaceAfter !== '') {
      this.style.marginBottom = this.dataset.spaceAfter ? `${this.dataset.spaceAfter}pt` : '';
    } else if (this.dataset.asa === 'false') {
      this.style.marginBottom = '0pt';
    } else {
      this.style.marginBottom = '';
    }
  }

  get contentContainer(): Element | null {
    if (this.content) {
      return this.content;
    } else {
      const content = this.querySelector('[data-content-container="true"]');
      if (content) {
        return content;
      }
    }
    return null;
  }

  get selectableContent(): Node | null {
    if (this.content?.childNodes[0]) {
      return this.content.childNodes[0];
    } else {
      const content = this.querySelector('[data-content-container="true"]');
      if (content?.childNodes[0]) {
        return content.childNodes[0];
      }
    }

    return null;
  }

  onMouseOver() {
    if (this.approvedTippyInstance && !this.approvedTippyInstance.state.isShown) {
      this.approvedTippyInstance.show();
    }
  }

  onMouseLeave() {
    if (this.approvedTippyInstance) {
      this.approvedTippyInstance.hide();
    }
  }

  setupStructure(
    nodeContent: HTMLElement,
    layoutType: Editor.Visualizer.LayoutType,
    approvingUsers?: any[],
    userPermissions?: string[],
  ) {
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }

    const userId = approvingUsers?.[0].user;

    this.setAttribute('spellcheck', 'false');

    this.overlay = DOMElementFactory.buildElement('div');
    this.overlay.setAttribute('class', styles.overlay);
    this.overlay.setAttribute('contenteditable', 'false');

    this.content = DOMElementFactory.buildElement('div');
    this.content.setAttribute('class', styles.content);
    this.content.dataset.contentContainer = 'true';

    if (nodeContent) {
      this.content.appendChild(nodeContent);
    }

    this.appendChild(this.overlay);
    this.appendChild(this.content);

    // tooltip
    this.approvedTooltipElement = DOMElementFactory.buildElement('div');
    this.approvedTippyInstance = tippy(this, {
      content: this.approvedTooltipElement,
      duration: 1,
      placement: 'right',
      followCursor: 'vertical',
      arrow: true,
      multiple: true,
      trigger: 'manual',
    }) as Instance;
    ReactDOM.unmountComponentAtNode(this.approvedTooltipElement);
    ReactDOM.render(
      <Provider store={ReduxInterface.getStore()}>
        <IntlProvider locale={ReduxInterface.getLocale()}>
          <TooltipContent userId={userId} />
        </IntlProvider>
      </Provider>,
      this.approvedTooltipElement,
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', ApprovedViewElement.onClickListener);
    this.addEventListener('mouseup', ApprovedViewElement.onMouseUpListener);

    this.addEventListener('mouseover', this.onMouseOver);
    this.addEventListener('mouseleave', this.onMouseLeave);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', ApprovedViewElement.onClickListener);
    this.removeEventListener('mouseup', ApprovedViewElement.onMouseUpListener);

    this.removeEventListener('mouseover', this.onMouseOver);
    this.removeEventListener('mouseleave', this.onMouseLeave);

    if (this.approvedTippyInstance) {
      this.approvedTippyInstance.destroy();
      this.approvedTippyInstance = null;
    }
  }
}

if (!window.customElements.get(ELEMENTS.ApprovedElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.ApprovedElement.IDENTIFIER, ApprovedViewElement);
}
